import { memo, ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useIntl } from 'react-intl';

import { Tooltip } from 'src/components/Tooltip';
import { Margins, Responsive } from 'src/design-system/style-types';
import { Colors } from 'src/design-system/theme';
import { messages } from 'src/messages';

import { Span, Text } from '../Text';
import { style } from './SquarePill.style';

interface Props {
  size?: 'sm' | 'md';
  text: ReactNode;
  color: Colors;
  showTooltip?: boolean;
  tooltipText?: ReactNode;
  disabled?: boolean;
  mr?: Responsive<Margins>;
  ml?: Responsive<Margins>;
}

export const SquarePill = memo(
  ({ ml, mr, size = 'md', text, color, disabled = false, tooltipText, showTooltip = false }: Props) => {
    const inner = (
      <Span marginLeft={ml} marginRight={mr} size="xs" css={style.squarePill({ color, size, disabled })}>
        {text}
      </Span>
    );

    if (showTooltip && tooltipText) {
      return (
        <Tooltip placement="bottom" button={inner}>
          <Text size="sm" color="light">
            {tooltipText}
          </Text>
        </Tooltip>
      );
    }
    return inner;
  }
);

export const BetaPill = () => {
  const { formatMessage } = useIntl();

  return (
    <SquarePill
      size="sm"
      text={formatMessage(messages.beta)}
      tooltipText={formatMessage({
        id: 'beta_tag_description',
        defaultMessage:
          'This is a new feature and has been thoroughly tested however, some bugs may be present. If you experience any issues please report them to us.',
      })}
      showTooltip
      color="fuschia"
    />
  );
};
