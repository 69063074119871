import { createActionCreator } from 'deox';

const namespace = `@devtools`;

const toggleDevtools = createActionCreator(
  `toggleDevtools(${namespace})`,
  (resolve) => (payload: { open: boolean }) => resolve(payload)
);

const toggleTranslationHighlights = createActionCreator(
  `toggleTranslationHighlights(${namespace})`,
  (resolve) => (payload: { highlighted: boolean }) => resolve(payload)
);

const toggleReactQueryDevtools = createActionCreator(
  `toggleReactQueryDevtools(${namespace})`,
  (resolve) => (payload: { open: boolean }) => resolve(payload)
);

export const devtoolsActions = {
  toggleDevtools,
  toggleTranslationHighlights,
  toggleReactQueryDevtools,
};
