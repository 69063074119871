export interface PoppersState {
  [name: string]: boolean;
}

export const INIT = '@@INIT';
export const ADD_POPPER = 'ADD_POPPER';
export const REMOVE_POPPER = 'REMOVE_POPPER';
export const SHOW_POPPER = 'SHOW_POPPER';
export const HIDE_POPPER = 'HIDE_POPPER';
export const HIDE_ALL_POPPERS = 'HIDE_ALL_POPPERS';

export interface InitAction {
  type: typeof INIT;
}

export interface AddPopperAction {
  type: typeof ADD_POPPER;
  name: string;
}

export interface RemovePopperAction {
  type: typeof REMOVE_POPPER;
  name: string;
}

export interface ShowPopperAction {
  type: typeof SHOW_POPPER;
  name: string;
}

export interface HidePopperAction {
  type: typeof HIDE_POPPER;
  name: string;
}

export interface HideAllPoppersAction {
  type: typeof HIDE_ALL_POPPERS;
}

export type PoppersActionTypes =
  | InitAction
  | AddPopperAction
  | RemovePopperAction
  | ShowPopperAction
  | HidePopperAction
  | HideAllPoppersAction;
