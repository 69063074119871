import React, { ReactNode } from 'react';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { Props as TextProps } from 'src/components/Text/Text';
import { Tooltip } from 'src/components/Tooltip';

interface Props {
  inModal?: boolean;
  text: ReactNode;
  maxWidth?: string;
  transform?: TextProps['transform'];
}

export const InformationTooltip = ({ inModal = false, maxWidth = '250px', text, transform }: Props) => (
  <Tooltip inModal={inModal} placement="bottom" button={<Icon marginLeft={{ xs: 'md', sm: 'sm' }} name="info" />}>
    <Text size="sm" maxWidth={maxWidth} color="light" transform={transform}>
      {text}
    </Text>
  </Tooltip>
);
