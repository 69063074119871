import { createActionCreator } from 'deox';

import { RecentSearchItem, SearchState } from './types';

const namespace = '@searches';

export const debounceSaveSearchToRecents = createActionCreator(
  `debounceSaveSearchToRecents(${namespace})`,
  (resolve) => (payload: { key: string; term: string }) => resolve(payload)
);

export const saveSearchToRecents = createActionCreator(
  `saveSearchToRecents(${namespace})`,
  (resolve) => (payload: RecentSearchItem) => resolve(payload)
);

export const clearRecentSearches = createActionCreator(
  `clearRecentSearches(${namespace})`,
  (resolve) => (payload: { key: string }) => resolve(payload)
);

export const clearRecentSearch = createActionCreator(
  `clearRecentSearch(${namespace})`,
  (resolve) => (payload: RecentSearchItem) => resolve(payload)
);

export const hydrate = createActionCreator(
  `hydrate(${namespace})`,
  (resolve) => (payload?: SearchState) => resolve(payload)
);
