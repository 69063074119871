import { css } from '@emotion/core';
import { rgba } from 'polished';

import { fadeIn } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const overlay = (theme: Theme) => css`
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${rgba(theme.colors.dark, 0.65)};
  z-index: ${zIndex.ModalOverlay};
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fadeIn()}
`;

const content = ({ width = 600 }: { width?: number }) => css`
  position: fixed;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
  width: calc(100% - 12px);
  max-width: ${width}px;
  max-height: 100vh;
  pointer-events: '';
  z-index: ${zIndex.Modal};
  ${fadeIn()}
`;

export const style = { overlay, content };
