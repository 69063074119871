import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

// * keep all dayjs extensions imports in this file
export const extendDayJs = () => {
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.extend(isBetween);
  dayjs.extend(localizedFormat);
  dayjs.extend(customParseFormat);
};

export const getDurationAsMilliseconds = (count: number, unit: duration.DurationUnitType) => {
  return dayjs.duration(count, unit).asMilliseconds();
};

export const getDurationAsSeconds = (count: number, unit: duration.DurationUnitType) => {
  return dayjs.duration(count, unit).asSeconds();
};

export const getDurationAsMinutes = (count: number, unit: duration.DurationUnitType) => {
  return dayjs.duration(count, unit).asMinutes();
};

export const isoTimeFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const getLabel = (
  options: {
    value: number;
    label: JSX.Element;
  }[],
  duration: number
) => {
  const foundOption = options.find((option) => option.value === duration);
  if (foundOption) {
    return foundOption.label;
  }
  return '';
};
