import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { moveServerActions } from './actions';

export const moveServerPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(moveServerActions.addMoveServerWebsites):
    case getType(moveServerActions.removeMoveServerWebsites):
    case getType(moveServerActions.toggleMoveServerWebsite):
    case getType(moveServerActions.clearMoveServers):
      persister.set({ key: 'moveServer', item: store.getState().moveServer, isPerUser: true });
  }

  return res;
};
