import React, { ReactNode } from 'react';
import { FormatOptionLabelMeta, OptionProps } from 'react-select';

import { FormField } from 'src/components/FormField';
import { InformationTooltip } from 'src/components/InformationTooltip/InformationTooltip';
import { Margins, Responsive } from 'src/design-system/style-types';

import Select, { VariantsKind } from './Select';
import { SelectOnChange, SelectOption, SelectValue } from './Select.types';

export interface Props {
  autoFocus?: boolean;
  onChange: SelectOnChange;
  value: SelectValue;
  name: string;
  options: SelectOption[];
  infoText?: ReactNode;
  infoMaxWidth?: string;
  className?: string;
  err?: any;
  touched?: any;
  placeholder?: ReactNode;
  variant?: VariantsKind;
  disabled?: boolean;
  multi?: boolean;
  help?: ReactNode;
  label?: ReactNode;
  marginBottom?: Responsive<Margins>;
  onInputChange?: (value: string) => void;
  onCreateOption?: () => void;
  filterOption?: ((option: SelectOption, value: string) => boolean) | undefined;
  creatable?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  loading?: boolean;
  requiredIndicator?: ReactNode;
  width?: 'fit-content';
  optionComponent?: (props: OptionProps<any, false>) => JSX.Element;
  formatOptionLabel?: (option: any, labelMeta: FormatOptionLabelMeta<any, boolean>) => ReactNode;
}

const SelectField = ({
  autoFocus = false,
  className = '',
  clearable = false,
  creatable = false,
  disabled = false,
  err = '',
  filterOption = undefined,
  formatOptionLabel,
  help = '',
  infoMaxWidth,
  infoText,
  label = '',
  loading = false,
  marginBottom = '0',
  multi = false,
  name,
  onChange,
  onCreateOption = () => {},
  onInputChange = () => {},
  optionComponent,
  options,
  placeholder = '',
  requiredIndicator = '',
  searchable = false,
  touched = false,
  variant = 'normal',
  value,
  width,
}: Props) => (
  <FormField
    label={label}
    help={help}
    name={name}
    err={err}
    touched={touched}
    requiredIndicator={requiredIndicator}
    marginBottom={marginBottom}
    role="listbox"
  >
    <Select
      autoFocus={autoFocus}
      data-qa="select"
      className={className}
      multi={multi}
      name={name}
      onChange={onChange}
      onCreateOption={onCreateOption}
      value={value}
      options={options}
      err={err}
      touched={touched}
      disabled={disabled}
      placeholder={placeholder}
      variant={variant}
      onInputChange={onInputChange}
      filterOption={filterOption}
      creatable={creatable}
      clearable={clearable}
      searchable={searchable}
      loading={loading}
      width={width}
      optionComponent={optionComponent}
      formatOptionLabel={formatOptionLabel}
    />
    {infoText && <InformationTooltip text={infoText} maxWidth={infoMaxWidth} />}
  </FormField>
);

/**
 * @deprecated
 */
export default SelectField;
