import { Dir } from 'src/filerd-client';

import { removeLeadingSlash } from 'src/utils/helpers';

import { FileEntry } from './constants';

export class Directory {
  public path: string;

  public directories: Directory[] = [];

  public files: File[] = [];

  constructor(public root: Dir) {
    this.path = root.path || '';

    if (root.entries) {
      this.directories = root.entries.filter((entry) => entry.dir).map((entry) => new Directory(entry.dir as Dir));
      this.files = root.entries.filter((entry) => entry.file).map((entry) => new File(entry.file));
    }
  }

  public get name(): string {
    const match = /\/([^/]+)$/.exec(this.path);

    return match ? match[1] : this.path;
  }

  public get size(): number {
    return this.root.metadata?.size || 0;
  }

  public get modified(): number {
    return this.root.metadata?.modified || 0;
  }

  public get permissions(): number {
    return this.root.metadata?.permissions || 0;
  }

  public set permissions(code: number) {
    this.root.metadata = {
      ...this.root.metadata,
      permissions: code,
    };
  }

  public get isTop(): boolean {
    return this.directories.length === 0;
  }

  public entries(): FileEntry[] {
    const directoryEntries = this.directories.map((directory) => ({
      id: directory.path,
      name: directory.name,
      size: directory.size,
      modified: directory.modified,
      permissions: directory.permissions,
      type: 'folder',
    }));

    const fileEntries = this.files.map((file) => ({
      id: file.path,
      name: file.name,
      size: file.size,
      modified: file.modified,
      permissions: file.permissions,
      type: file.extension,
    }));

    return directoryEntries.concat(fileEntries);
  }

  public containsDirectory(name: string): boolean {
    return !!this.directories.find((directory) => directory.name === name);
  }

  public containsFile(name: string): boolean {
    return !!this.files.find((file) => file.name === name);
  }

  public open(path: string): Directory {
    if (path === this.path) {
      return this;
    }

    return path.split('/').reduce<Directory>((carry, item) => {
      const childDirectory = carry.directories.find((directory) => directory.name === item);

      if (!childDirectory) {
        return carry;
      }

      return childDirectory;
    }, this);
  }

  public append(directory: Directory): Directory {
    if (directory.path === '') {
      // If there are matching directories in the new tree, use our current stored ones.
      const existingDirectories = this.directories.filter((dir) => directory.containsDirectory(dir.path));
      const newDirectories = directory.directories.filter((dir) => !this.containsDirectory(dir.path));

      // Update metadata on existing directories and concat new ones.
      directory.directories = existingDirectories
        .map((dir) => {
          const updated = directory.directories.find((item) => item.path === dir.path);

          if (!updated) {
            return dir;
          }

          dir.permissions = updated.permissions;

          return dir;
        })
        .concat(newDirectories);

      return directory;
    }

    directory.path.split('/').reduce<Directory>((carry, item) => {
      const path = removeLeadingSlash(`${carry.path}/${item}`);

      if (path === directory.path) {
        if (!carry.containsDirectory(item)) {
          carry.directories.push(directory);

          return carry;
        }

        carry.directories = carry.directories.map((dir) => {
          if (dir.name !== item) {
            return dir;
          }

          return directory;
        });

        return carry;
      }

      if (!carry.containsDirectory(item)) {
        carry.directories.push(new Directory({ path }));
      }

      return carry.open(item);
    }, this);

    return this;
  }

  public static from(root: Dir): Directory {
    return new Directory(root);
  }
}

export class File {
  public path: string;

  // Unfortunately, filerd file type is any as of 1.0.0-alpha.34.
  constructor(private file: any) {
    this.path = file.path;
  }

  public get name(): string {
    const match = /\/([^/]+)$/.exec(this.path);

    return match ? match[1] : this.path;
  }

  public get extension(): string {
    const match = /((?:\.[\w]*[a-z]+[\w]*)+)$/i.exec(this.name);
    const afterDot = match ? match[1] : '';
    // Limit extensions to 4 characters.
    const afterDotMatch = /((?:\.\w{0,4})+)$/i.exec(afterDot);
    const extension = afterDotMatch ? afterDotMatch[1] : '';

    return extension.substring(1);
  }

  public get size(): number {
    return this.file.metadata?.size || 0;
  }

  public get modified(): number {
    return this.file.metadata?.modified || 0;
  }

  public get permissions(): number {
    return this.file.metadata?.permissions || 0;
  }
}
