import { createActionCreator } from 'deox';

const namespace = `@globalUi`;

const setDarkMode = createActionCreator(
  `setDarkMode(${namespace})`,
  (resolve) => (payload: { darkMode: boolean }) => resolve(payload)
);

export const globalUiActions = {
  setDarkMode,
};
