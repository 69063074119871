import { ReactComponent as AddPackage } from 'src/images/add_package.svg';
import { ReactComponent as AlphabeticalAsc } from 'src/images/alphabetical-asc.svg';
import { ReactComponent as AlphabeticalDesc } from 'src/images/alphabetical-desc.svg';
import { ReactComponent as App } from 'src/images/application.svg';
import { ReactComponent as Bento6 } from 'src/images/bento-6.svg';
import { ReactComponent as Bento9 } from 'src/images/bento-9.svg';
import { ReactComponent as BoxedMinusFilled } from 'src/images/boxed-minus-filled.svg';
import { ReactComponent as BoxedPlusOutline } from 'src/images/boxed-plus-outline.svg';
import { ReactComponent as CancelAlt } from 'src/images/cancel-alt.svg';
import { ReactComponent as Cancel } from 'src/images/cancel.svg';
import { ReactComponent as CheckCircleAlt } from 'src/images/check-circle-alt.svg';
import { ReactComponent as CheckCircle } from 'src/images/check-circle.svg';
import { ReactComponent as CheckSmall } from 'src/images/check-small.svg';
import { ReactComponent as ChevronDownAlt } from 'src/images/chevron-down-alt.svg';
import { ReactComponent as ChevronDownFill } from 'src/images/chevron-down-fill.svg';
import { ReactComponent as ChevronLeft } from 'src/images/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'src/images/chevron-right.svg';
import { ReactComponent as ChevronUpFill } from 'src/images/chevron-up-fill.svg';
import { ReactComponent as CircleAlt } from 'src/images/circle-alt.svg';
import { ReactComponent as Circle } from 'src/images/circle.svg';
import { ReactComponent as CloseBigAlt } from 'src/images/close-big-alt.svg';
import { ReactComponent as CloseBig } from 'src/images/close-big.svg';
import { ReactComponent as Completed } from 'src/images/completed.svg';
import { ReactComponent as Confetti } from 'src/images/confetti.svg';
import { ReactComponent as DotDotDot } from 'src/images/dot-dot-dot.svg';
import { ReactComponent as Down } from 'src/images/down.svg';
import { ReactComponent as EmailAdd } from 'src/images/email-add.svg';
import { ReactComponent as AccessTokensEmptyState } from 'src/images/empty-states/access-tokens.svg';
import { ReactComponent as AppsEmptyState } from 'src/images/empty-states/apps.svg';
import { ReactComponent as MyCnfEmptyState } from 'src/images/empty-states/cnf.svg';
import { ReactComponent as PhpFpmEmptyState } from 'src/images/empty-states/conf.svg';
import { ReactComponent as CronJobsEmptyState } from 'src/images/empty-states/cron-jobs.svg';
import { ReactComponent as CustomersEmptyState } from 'src/images/empty-states/customers.svg';
import { ReactComponent as DatabasesEmptyState } from 'src/images/empty-states/databases.svg';
import { ReactComponent as DirectivesEmptyState } from 'src/images/empty-states/directives.svg';
import { ReactComponent as DnsTemplatingEmptyState } from 'src/images/empty-states/dns-templating.svg';
import { ReactComponent as DomainsEmptyState } from 'src/images/empty-states/domains.svg';
import { ReactComponent as EmailsEmptyState } from 'src/images/empty-states/emails.svg';
import { ReactComponent as FtpsEmptyState } from 'src/images/empty-states/ftps.svg';
import { ReactComponent as GraphDataEmptyState } from 'src/images/empty-states/graph-data.svg';
import { ReactComponent as MigrationsEmptyState } from 'src/images/empty-states/migrations.svg';
import { ReactComponent as OoosEmptyState } from 'src/images/empty-states/ooos.svg';
import { ReactComponent as PackagesEmptyState } from 'src/images/empty-states/packages.svg';
import { ReactComponent as PluginsEmptyState } from 'src/images/empty-states/plugins.svg';
import { ReactComponent as RedirectsEmptyState } from 'src/images/empty-states/redirects.svg';
import { ReactComponent as RewritesEmptyState } from 'src/images/empty-states/rewrites.svg';
import { ReactComponent as SearchEmptyState } from 'src/images/empty-states/search.svg';
import { ReactComponent as SshEmptyState } from 'src/images/empty-states/ssh.svg';
import { ReactComponent as SslMappingEmptyState } from 'src/images/empty-states/ssl-mappings.svg';
import { ReactComponent as SslEmptyState } from 'src/images/empty-states/ssl.svg';
import { ReactComponent as UsersEmptyState } from 'src/images/empty-states/users.svg';
import { ReactComponent as WebsiteImportsEmptyState } from 'src/images/empty-states/website-imports.svg';
import { ReactComponent as WebsitesEmptyState } from 'src/images/empty-states/websites.svg';
import { ReactComponent as ErrorAlt } from 'src/images/error-alt.svg';
import { ReactComponent as Filter } from 'src/images/filter.svg';
import { ReactComponent as FolderFilled } from 'src/images/folder_filled.svg';
import { ReactComponent as HamburgerAlt } from 'src/images/hamburger-alt.svg';
import { ReactComponent as Hamburger } from 'src/images/hamburger.svg';
import { ReactComponent as Handshake } from 'src/images/handshake.svg';
import { ReactComponent as HostingPackage } from 'src/images/hosting-package.svg';
import { ReactComponent as Infinite } from 'src/images/infinite.svg';
import { ReactComponent as InviteUser } from 'src/images/invite-user.svg';
import { ReactComponent as Loading } from 'src/images/loading.svg';
import { ReactComponent as LockSsl } from 'src/images/lock-ssl.svg';
import { ReactComponent as LogoSm } from 'src/images/logo-sm.svg';
// material design start
// icons from link below using 200 weight 16 size
// https://fonts.google.com/icons?selected=Material+Symbols+Outlined:search:FILL@0;wght@200;GRAD@0;opsz@20&icon.size=16&icon.color=%23e8eaed
// trimmed and minimized via
// https://www.svgviewer.dev/
//
import { ReactComponent as AccountCircle } from 'src/images/material-design/account_circle.svg';
import { ReactComponent as Acute } from 'src/images/material-design/acute.svg';
import { ReactComponent as Add } from 'src/images/material-design/add.svg';
import { ReactComponent as AddCircle } from 'src/images/material-design/add_circle.svg';
import { ReactComponent as ArrowBack } from 'src/images/material-design/arrow_back.svg';
import { ReactComponent as ArrowForward } from 'src/images/material-design/arrow_forward.svg';
import { ReactComponent as Block } from 'src/images/material-design/block.svg';
import { ReactComponent as Build } from 'src/images/material-design/build.svg';
import { ReactComponent as Check } from 'src/images/material-design/check.svg';
import { ReactComponent as Close } from 'src/images/material-design/close.svg';
import { ReactComponent as Code } from 'src/images/material-design/code.svg';
import { ReactComponent as CodeBlocks } from 'src/images/material-design/code_blocks.svg';
import { ReactComponent as Compress } from 'src/images/material-design/compress.svg';
import { ReactComponent as ContentCopy } from 'src/images/material-design/content_copy.svg';
import { ReactComponent as ContentCut } from 'src/images/material-design/content_cut.svg';
import { ReactComponent as ContentPaste } from 'src/images/material-design/content_paste.svg';
import { ReactComponent as DarkMode } from 'src/images/material-design/dark_mode.svg';
import { ReactComponent as DataUsage } from 'src/images/material-design/data_usage.svg';
import { ReactComponent as Database } from 'src/images/material-design/database.svg';
import { ReactComponent as DateRange } from 'src/images/material-design/date_range.svg';
import { ReactComponent as DiscoverExisting } from 'src/images/material-design/discover_existing.svg';
import { ReactComponent as Doc } from 'src/images/material-design/doc.svg';
import { ReactComponent as Download } from 'src/images/material-design/download.svg';
import { ReactComponent as Edit } from 'src/images/material-design/edit.svg';
import { ReactComponent as Mail } from 'src/images/material-design/email.svg';
import { ReactComponent as Error } from 'src/images/material-design/error.svg';
import { ReactComponent as Event } from 'src/images/material-design/event.svg';
import { ReactComponent as EventNote } from 'src/images/material-design/event_note.svg';
import { ReactComponent as Expand } from 'src/images/material-design/expand.svg';
import { ReactComponent as Extension } from 'src/images/material-design/extension.svg';
import { ReactComponent as Folder } from 'src/images/material-design/folder.svg';
import { ReactComponent as FormatListBulleted } from 'src/images/material-design/format_list_bulleted.svg';
import { ReactComponent as Forward } from 'src/images/material-design/forward.svg';
import { ReactComponent as GridOn } from 'src/images/material-design/grid_on.svg';
import { ReactComponent as Group } from 'src/images/material-design/group.svg';
import { ReactComponent as History } from 'src/images/material-design/history.svg';
import { ReactComponent as Home } from 'src/images/material-design/home.svg';
import { ReactComponent as Info } from 'src/images/material-design/info.svg';
import { ReactComponent as InsertChart } from 'src/images/material-design/insert_chart.svg';
import { ReactComponent as Key } from 'src/images/material-design/key.svg';
import { ReactComponent as KeyboardArrowDown } from 'src/images/material-design/keyboard_arrow_down.svg';
import { ReactComponent as KeyboardArrowUp } from 'src/images/material-design/keyboard_arrow_up.svg';
import { ReactComponent as Language } from 'src/images/material-design/language.svg';
import { ReactComponent as Staging } from 'src/images/material-design/layers.svg';
import { ReactComponent as LeftClick } from 'src/images/material-design/left_click.svg';
import { ReactComponent as LightMode } from 'src/images/material-design/light_mode.svg';
import { ReactComponent as Link } from 'src/images/material-design/link.svg';
import { ReactComponent as LocationOn } from 'src/images/material-design/location_on.svg';
import { ReactComponent as Lock } from 'src/images/material-design/lock.svg';
import { ReactComponent as LockOpen } from 'src/images/material-design/lock_open.svg';
import { ReactComponent as ManageAccounts } from 'src/images/material-design/manage_accounts.svg';
import { ReactComponent as Monitoring } from 'src/images/material-design/monitoring.svg';
import { ReactComponent as Mood } from 'src/images/material-design/mood.svg';
import { ReactComponent as MovedLocation } from 'src/images/material-design/moved_location.svg';
import { ReactComponent as Notifications } from 'src/images/material-design/notifications.svg';
import { ReactComponent as OpenInNew } from 'src/images/material-design/open_in_new.svg';
import { ReactComponent as Payments } from 'src/images/material-design/payments.svg';
import { ReactComponent as PendingActions } from 'src/images/material-design/pending_actions.svg';
import { ReactComponent as Php } from 'src/images/material-design/php.svg';
import { ReactComponent as PowerSettingsNew } from 'src/images/material-design/power_settings_new.svg';
import { ReactComponent as Question } from 'src/images/material-design/question.svg';
import { ReactComponent as Refresh } from 'src/images/material-design/refresh.svg';
import { ReactComponent as Schedule } from 'src/images/material-design/schedule.svg';
import { ReactComponent as Search } from 'src/images/material-design/search.svg';
import { ReactComponent as Security } from 'src/images/material-design/security.svg';
import { ReactComponent as Server } from 'src/images/material-design/server.svg';
import { ReactComponent as Settings } from 'src/images/material-design/settings.svg';
import { ReactComponent as SettingsBackupRestore } from 'src/images/material-design/settings_backup_restore.svg';
import { ReactComponent as ShieldPerson } from 'src/images/material-design/shield_person.svg';
import { ReactComponent as ShoppingCart } from 'src/images/material-design/shopping_cart.svg';
import { ReactComponent as SupervisedUserCircle } from 'src/images/material-design/supervised_user_circle.svg';
import { ReactComponent as SupportAgent } from 'src/images/material-design/support_agent.svg';
import { ReactComponent as Upload } from 'src/images/material-design/upload.svg';
import { ReactComponent as ViewList } from 'src/images/material-design/view_list.svg';
import { ReactComponent as Visibility } from 'src/images/material-design/visibility.svg';
import { ReactComponent as VisibilityOff } from 'src/images/material-design/visibility_off.svg';
import { ReactComponent as Warning } from 'src/images/material-design/warning.svg';
import { ReactComponent as WebAsset } from 'src/images/material-design/web_asset.svg';
import { ReactComponent as Websites } from 'src/images/material-design/websites.svg';
import { ReactComponent as Widgets } from 'src/images/material-design/widgets.svg';
import { ReactComponent as Work } from 'src/images/material-design/work.svg';
// material design end
import { ReactComponent as Delete } from 'src/images/md-delete.svg';
import { ReactComponent as MenuOptions } from 'src/images/menu-options.svg';
import { ReactComponent as Migration } from 'src/images/migration.svg';
import { ReactComponent as Minus } from 'src/images/minus.svg';
import { ReactComponent as Move } from 'src/images/move.svg';
import { ReactComponent as NavImports } from 'src/images/nav-imports.svg';
import { ReactComponent as PencilCircle } from 'src/images/pencil-circle.svg';
import { ReactComponent as PencilSquare } from 'src/images/pencil-square.svg';
import { ReactComponent as Pin } from 'src/images/pin.svg';
import { ReactComponent as PlatformSettings } from 'src/images/platform-settings.svg';
import { ReactComponent as PlusBig } from 'src/images/plus-big.svg';
import { ReactComponent as PlusCircle } from 'src/images/plus-circle.svg';
import { ReactComponent as Plus } from 'src/images/plus.svg';
import { ReactComponent as Rename } from 'src/images/rename.svg';
import { ReactComponent as ServerStorage } from 'src/images/server-storage.svg';
import { ReactComponent as SettingsAlt } from 'src/images/settings-alt.svg';
import { ReactComponent as Shared } from 'src/images/shared.svg';
import { ReactComponent as ShieldAlt } from 'src/images/shield-alt.svg';
import { ReactComponent as ShieldCross } from 'src/images/shield-cross.svg';
import { ReactComponent as ShieldTickAlt } from 'src/images/shield-tick-alt.svg';
import { ReactComponent as ShieldTick } from 'src/images/shield-tick.svg';
import { ReactComponent as Shield } from 'src/images/shield.svg';
import { ReactComponent as CircleDouble } from 'src/images/slider-dragged.svg';
import { ReactComponent as SquareAlt } from 'src/images/square-alt.svg';
import { ReactComponent as Square } from 'src/images/square.svg';
import { ReactComponent as Status } from 'src/images/status.svg';
import { ReactComponent as SupportCopy } from 'src/images/support-copy.svg';
import { ReactComponent as TopRight } from 'src/images/top-right.svg';
import { ReactComponent as Up } from 'src/images/up.svg';
import { ReactComponent as UpdateAvailable } from 'src/images/update_available.svg';
import { ReactComponent as User } from 'src/images/user.svg';
import { ReactComponent as Whitelabel } from 'src/images/whitelabel.svg';
import { ReactComponent as WooCommerce } from 'src/images/woo.svg';
import { ReactComponent as WooCommerceWide } from 'src/images/woocommerce-wide.svg';
import { ReactComponent as WordPress } from 'src/images/wordpress.svg';

export const ICON_COMPONENT_MAP = {
  acute: Acute,
  add: Add,
  'add package': AddPackage,
  'alphabetical asc': AlphabeticalAsc,
  'alphabetical desc': AlphabeticalDesc,
  app: App,
  application: WebAsset,
  backup: SettingsBackupRestore,
  bandwidth: InsertChart,
  'bento-6': Bento6,
  'bento-9': Bento9,
  block: Block,
  'boxed minus filled': BoxedMinusFilled,
  'boxed plus outline': BoxedPlusOutline,
  'calendar range': DateRange,
  calendar: Event,
  'cancel alt': CancelAlt,
  cancel: Cancel,
  chat: SupportAgent,
  'check circle alt': CheckCircleAlt,
  'check circle': CheckCircle,
  'check small': CheckSmall,
  check: Check,
  'chevron down alt': ChevronDownAlt,
  'chevron down fill': ChevronDownFill,
  'chevron down': KeyboardArrowDown,
  'chevron left': ChevronLeft,
  'chevron right': ChevronRight,
  'chevron up fill': ChevronUpFill,
  'chevron up': KeyboardArrowUp,
  'circle add': AddCircle,
  'circle alt': CircleAlt,
  'circle double': CircleDouble,
  circle: Circle,
  'close big alt': CloseBigAlt,
  'close big': CloseBig,
  close: Close,
  code_blocks: CodeBlocks,
  code: Code,
  completed: Completed,
  confetti: Confetti,
  'copy alt': ContentCopy,
  cut: ContentCut,
  'data usage': DataUsage,
  database: Database,
  delete: Delete,
  dev: Code,
  'discover existing': DiscoverExisting,
  doc: Doc,
  domains: Language,
  'dot dot dot': DotDotDot,
  down: Down,
  download: Download,
  edit: Edit,
  'email add': EmailAdd,
  'empty-state access-tokens': AccessTokensEmptyState,
  'empty-state apps': AppsEmptyState,
  'empty-state cron': CronJobsEmptyState,
  'empty-state customers': CustomersEmptyState,
  'empty-state databases': DatabasesEmptyState,
  'empty-state directives': DirectivesEmptyState,
  'empty-state dns-templating': DnsTemplatingEmptyState,
  'empty-state domains': DomainsEmptyState,
  'empty-state emails': EmailsEmptyState,
  'empty-state ftps': FtpsEmptyState,
  'empty-state graph-data': GraphDataEmptyState,
  'empty-state migrations': MigrationsEmptyState,
  'empty-state my-cnf': MyCnfEmptyState,
  'empty-state ooos': OoosEmptyState,
  'empty-state packages': PackagesEmptyState,
  'empty-state php-fpm': PhpFpmEmptyState,
  'empty-state plugins': PluginsEmptyState,
  'empty-state redirects': RedirectsEmptyState,
  'empty-state rewrites': RewritesEmptyState,
  'empty-state search': SearchEmptyState,
  'empty-state ssh': SshEmptyState,
  'empty-state ssl-mapping': SslMappingEmptyState,
  'empty-state ssl': SslEmptyState,
  'empty-state users': UsersEmptyState,
  'empty-state website-imports': WebsiteImportsEmptyState,
  'empty-state websites': WebsitesEmptyState,
  'error alt': ErrorAlt,
  error: Warning,
  'expand alt': Expand,
  extension: Extension,
  filter: Filter,
  'folder filled': FolderFilled,
  folder: Folder,
  generate_backup: LeftClick,
  'hamburger alt': HamburgerAlt,
  hamburger: Hamburger,
  handshake: Handshake,
  hide: VisibilityOff,
  home: Home,
  'hosting-package': HostingPackage,
  infinite: Infinite,
  'info alt': Info,
  info: Info,
  'invite-user': InviteUser,
  key: Key,
  left: ArrowBack,
  link: Link,
  loading: Loading,
  locale: LocationOn,
  'lock open': LockOpen,
  'lock ssl': LockSsl,
  lock: Lock,
  logo: LogoSm,
  logs: FormatListBulleted,
  mail: Mail,
  'menu-options': MenuOptions,
  migration: Migration,
  minus: Minus,
  moon: DarkMode,
  move: Move,
  'moved location': MovedLocation,
  'nav customers': ManageAccounts,
  'nav email': Mail,
  'nav imports': NavImports,
  'nav websites': Websites,
  nav_integrations: Widgets,
  notebook: EventNote,
  notification: Notifications,
  'open in new': OpenInNew,
  paste: ContentPaste,
  payments: Payments,
  'pencil circle': PencilCircle,
  'pencil square': PencilSquare,
  pencil: Edit,
  php: Php,
  pin: Pin,
  'platform-settings': PlatformSettings,
  'plus big': PlusBig,
  'plus circle': PlusCircle,
  plus: Plus,
  'question alt': Question,
  question: Question,
  redo: Forward,
  reduce: Compress,
  refresh: Refresh,
  rename: Rename,
  restart: PowerSettingsNew,
  restore_backup: History,
  right: ArrowForward,
  schedule: Schedule,
  search: Search,
  security: Security,
  'server-storage': ServerStorage,
  server: Server,
  'settings alt': SettingsAlt,
  settings: Settings,
  shared: Shared,
  'shield alt': ShieldAlt,
  'shield cross': ShieldCross,
  'shield tick alt': ShieldTickAlt,
  'shield tick': ShieldTick,
  shield: Shield,
  'shopping cart': ShoppingCart,
  show: Visibility,
  smiley: Mood,
  'square alt': SquareAlt,
  square: Square,
  staging: Staging,
  star: ShieldPerson,
  status: Status,
  sun: LightMode,
  'supervised user': SupervisedUserCircle,
  'support copy': SupportCopy,
  support: Build,
  'top right': TopRight,
  up: Up,
  'update-available': UpdateAvailable,
  update: PendingActions,
  upload: Upload,
  'user alt': AccountCircle,
  user: User,
  'users alt': Group,
  users: Group,
  'view grid': GridOn,
  'view list': ViewList,
  visitors: Monitoring,
  'warning alt': Warning,
  warning: Error,
  whitelabel: Whitelabel,
  woocommerce_wide: WooCommerceWide,
  woocommerce: WooCommerce,
  wordpress: WordPress,
  work: Work,
};
