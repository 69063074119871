import { css } from '@emotion/core';

import { margin } from 'src/design-system/mixins';
import { Margins, Responsive } from 'src/design-system/style-types';
import { Theme } from 'src/design-system/theme';

const container = css`
  width: 100%;
`;

const panelList =
  ({
    isScrollable,
    height,
    marginBottom,
  }: {
    isScrollable: boolean;
    height: number;
    marginBottom: Responsive<Margins>;
  }) =>
  (theme: Theme) =>
    css`
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      ${margin({ marginBottom })(theme)}

      ${isScrollable &&
      css`
        height: ${height}px;
        overflow-y: scroll;
      `};
    `;

const panelListInner = ({ height }: { height: number }) => css`
  position: relative;
  width: 100%;
  height: ${height}px;
`;

const panel =
  ({
    noHover,
    noLastBorder,
    isStatic,
    rowStart,
  }: {
    noHover?: boolean;
    noLastBorder?: boolean;
    isStatic?: boolean;
    rowStart?: number;
  }) =>
  (theme: Theme) =>
    css`
      border-bottom: 1px solid ${theme.colors.grey10};
      ${rowStart !== undefined &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(${rowStart}px);
      `}
      ${noLastBorder &&
      css`
        &:last-of-type {
          border-bottom: none;
        }
      `}
      .panel-list-single-action {
        min-width: 32px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ${isStatic &&
      css`
        &:nth-child(2n + 1) {
          background-color: ${theme.colors.grey3};
        }
        &:hover:nth-child(2n) {
          background-color: ${theme.colors.light};
        }
        border-bottom: none;
      `}
      &:hover {
        ${!noHover &&
        css`
          background: ${theme.colors.grey3};
        `}
        .panel-list-single-action {
          svg {
            cursor: pointer;
          }
        }
      }
      .panel-list-single-action.del-style {
        &:hover {
          svg {
            fill: ${theme.colors.error};
          }
        }
      }
    `;

export const style = { container, panel, panelList, panelListInner };
