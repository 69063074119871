import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';

const card = (theme: Theme) => css`
  max-width: 600px;
  margin: 0 auto;
  border-top: 1px solid ${theme.colors.warning};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: calc(100vh - 12px);
  overflow-y: auto;
`;

export const style = { card };
