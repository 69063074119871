import clamp from 'lodash/clamp';

export const middleTruncationHelper = ({
  text,
  splitRatio = 0.5,
}: {
  text: string;
  splitRatio?: number;
}): { firstSection: string; secondSection: string } => {
  const middlePoint = splitRatio < 0 ? splitRatio : Math.floor(text.length * splitRatio);
  const firstSection = text.slice(0, middlePoint);
  const secondSection = text.slice(middlePoint, text.length);

  return { firstSection, secondSection };
};

interface TruncateName {
  text: string;
  width?: number;
  splitRatio?: number;
  maxCharactersRatio?: number;
}

export const truncateName = ({ text, width, splitRatio = 0.8, maxCharactersRatio = 7 }: TruncateName) => {
  if (!width || splitRatio === 1) {
    return text;
  }

  const { firstSection, secondSection } = middleTruncationHelper({ text, splitRatio });

  const maxCharacters = Math.floor(width / maxCharactersRatio);

  if (text.length <= maxCharacters) {
    return text;
  }

  const suffixLength = secondSection.length;

  const sliceValue = clamp(maxCharacters - suffixLength - 3, 0, Infinity);

  return `${firstSection.slice(0, sliceValue)}...${secondSection}`;
};
