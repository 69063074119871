import { ActionType, getType } from 'deox';
import { all, call, put, select, take, takeEvery, takeLatest } from 'typed-redux-saga/macro';

import { serversApi } from 'src/api_services/servers/service';
import { TEN_SECONDS } from 'src/utils/constants';
import { isAxiosError } from 'src/utils/errors';

import { createRequestStateSaga } from '../helpers';
import { serversActions } from './actions';
import { serversSelectors } from './selectors';

const [getServersSaga] = createRequestStateSaga(serversActions.getServers, serversApi.getServers);
const [getGroupedServersSaga] = createRequestStateSaga(serversActions.getGroupedServers, serversApi.getServers);
const [getServerInfoSaga] = createRequestStateSaga(serversActions.getServerInfo, serversApi.getServerInfo);
const [configureServerSaga] = createRequestStateSaga(serversActions.configureServer, serversApi.configureServer);
const [getInstallCmdSaga] = createRequestStateSaga(serversActions.getInstallCmd, serversApi.getInstallCmd);

/* Deprecated
const [getControlPanelRoleInfoSaga] = createRequestStateSaga(
  serversActions.getControlPanelRoleInfo,
  serversApi.getControlPanelRoleInfo
);
*/

const [installServerRoleSaga] = createRequestStateSaga(serversActions.installServerRole, serversApi.installServerRole);
const [setServerStatusSaga] = createRequestStateSaga(serversActions.setServerStatus, serversApi.setServerStatus);

const [getServiceSettingSaga] = createRequestStateSaga(serversActions.getServiceSetting, serversApi.getServiceSetting);

const [setServiceSettingSaga] = createRequestStateSaga(serversActions.setServiceSetting, serversApi.setServiceSetting);

const [deleteServiceSettingSaga] = createRequestStateSaga(
  serversActions.deleteServiceSetting,
  serversApi.deleteServiceSetting
);

const [getServerRolesSaga] = createRequestStateSaga(serversActions.getServerRoles, serversApi.getServerRoles);

const [createServerDomainSaga] = createRequestStateSaga(
  serversActions.createServerDomain,
  serversApi.createServerDomain
);

const [deleteServerDomainSaga] = createRequestStateSaga(
  serversActions.deleteServerDomain,
  serversApi.deleteServerDomain
);

const [updateServerPrimaryIpSaga] = createRequestStateSaga(
  serversActions.updateServerPrimaryIp,
  serversApi.updateServerPrimaryIp
);

const [getWebserverKindSaga] = createRequestStateSaga(serversActions.getWebserverKind, serversApi.getWebserverKind);

const [setWebserverKindSaga] = createRequestStateSaga(serversActions.setWebserverKind, serversApi.setWebserverKind);

export function* getAllServerStatusesSaga({ type }: ActionType<typeof serversActions.getAllServerStatuses>) {
  try {
    yield put(serversActions.getServers.request({ params: {} }));
    yield take(serversActions.getServers.success);

    const items = yield* select(serversSelectors.serversItems);
    yield put(serversActions.setAllServerStatusesLoading({ servers: items }));

    yield all(items.map((server) => call(getRoleAndInfo, server.id)));
  } catch {
    // do nothing
  }
}

export function* getRoleAndInfo(serverId: string) {
  try {
    const { rolesResp, infoResp } = yield* all({
      rolesResp: call(serversApi.getServerRoles, { serverId }, { timeout: TEN_SECONDS }),
      infoResp: call(serversApi.getServerInfo, { serverId }, { timeout: TEN_SECONDS }),
    });

    if (rolesResp.data && infoResp.data) {
      yield put(
        serversActions.setSingleServerStatuses({
          serverId,
          resp: { error: '', loading: false, data: { roles: rolesResp.data, info: infoResp.data } },
        })
      );
    } else {
      throw new Error();
    }
  } catch {
    yield put(
      serversActions.setSingleServerStatuses({
        serverId,
        resp: { error: 'something went wrong', loading: false, data: undefined },
      })
    );
  }
}

export function* deleteServerRequestSaga({ payload }: ActionType<typeof serversActions.deleteServer.request>) {
  const { id, onSuccess, onError } = payload;

  try {
    yield call(serversApi.deleteSlave, { serverId: id, force: true });
    onSuccess?.();
  } catch (e) {
    if (onError && isAxiosError(e)) {
      onError(e);
    }
  }
}

export function* serversSaga() {
  yield takeEvery(getType(serversActions.getServers.request), getServersSaga);
  yield takeEvery(getType(serversActions.getGroupedServers.request), getGroupedServersSaga);
  yield takeEvery(getType(serversActions.getServerInfo.request), getServerInfoSaga);
  yield takeEvery(getType(serversActions.configureServer.request), configureServerSaga);
  yield takeEvery(getType(serversActions.getInstallCmd.request), getInstallCmdSaga);
  // deprecated yield takeEvery(getType(serversActions.getControlPanelRoleInfo.request), getControlPanelRoleInfoSaga);
  yield takeEvery(getType(serversActions.installServerRole.request), installServerRoleSaga);
  yield takeEvery(getType(serversActions.setServerStatus.request), setServerStatusSaga);
  yield takeEvery(getType(serversActions.getServiceSetting.request), getServiceSettingSaga);
  yield takeEvery(getType(serversActions.setServiceSetting.request), setServiceSettingSaga);
  yield takeEvery(getType(serversActions.deleteServiceSetting.request), deleteServiceSettingSaga);
  yield takeEvery(getType(serversActions.getServerRoles.request), getServerRolesSaga);
  yield takeEvery(getType(serversActions.createServerDomain.request), createServerDomainSaga);
  yield takeEvery(getType(serversActions.deleteServerDomain.request), deleteServerDomainSaga);
  yield takeLatest(getType(serversActions.getAllServerStatuses), getAllServerStatusesSaga);
  yield takeLatest(getType(serversActions.deleteServer.request), deleteServerRequestSaga);
  yield takeEvery(getType(serversActions.updateServerPrimaryIp.request), updateServerPrimaryIpSaga);
  yield takeEvery(getType(serversActions.getWebserverKind.request), getWebserverKindSaga);
  yield takeEvery(getType(serversActions.setWebserverKind.request), setWebserverKindSaga);
}
