import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'h6',
  weight: 'light',
  size: 'lg',
};

/**
 * # Text H6
 * Use H6to present the main headline
 */
const H6 = (props: Props) => <Text {...props} />;

H6.defaultProps = updatedDefaultProps;

export default H6;
