import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'h4',
  weight: 'normal',
  size: 'lg',
};

/**
 * # Text H4
 * Use H4 to present the main headline
 */
const H4 = (props: Props) => <Text {...props} />;

H4.defaultProps = updatedDefaultProps;

export default H4;
