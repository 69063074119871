import { ReactNode } from 'react';
import { createActionCreator } from 'deox';

import { ToastItem } from 'src/components/Toast/types';

const customToast = createActionCreator('customToast', (resolve) => (payload: ToastItem) => {
  return resolve(payload);
});

const successToast = createActionCreator('successToast', (resolve) => (payload: ReactNode) => {
  return resolve(payload);
});

const errorToast = createActionCreator('errorToast', (resolve) => (payload: ReactNode) => {
  return resolve(payload);
});

const warningToast = createActionCreator('warningToast', (resolve) => (payload: ReactNode) => {
  return resolve(payload);
});

const infoToast = createActionCreator('infoToast', (resolve) => (payload: ReactNode) => {
  return resolve(payload);
});

const neutralToast = createActionCreator('neutralToast', (resolve) => (payload: ReactNode) => {
  return resolve(payload);
});

const dismissToast = createActionCreator('dismissToast', (resolve) => (payload: string) => {
  return resolve(payload);
});
const dismissAllToasts = createActionCreator('dismissAllToasts', (resolve) => () => {
  return resolve();
});

export const toastActions = {
  customToast,
  errorToast,
  infoToast,
  neutralToast,
  successToast,
  warningToast,
  dismissToast,
  dismissAllToasts,
};
