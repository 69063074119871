import React, { memo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { LoginMembership } from 'src/orchd-client';
import { connect } from 'react-redux';

import { Icon } from 'src/components/Icon';
import { Colors } from 'src/design-system/theme';
import { AppState } from 'src/store';
import { loginsSelectors } from 'src/store/logins/selectors';

import { IconButton } from '../IconButton';
import { Popover } from '../Popover/Popover';
import { DashboardProfileContent } from './DashboardProfileContent/DashboardProfileContent';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  member: LoginMembership;
  loginMemberships: LoginMembership[];
  logout: () => void;
  iconColor?: 'light' | 'dark';
  hoverBackgroundColor?: Colors;
}

export const _DashboardProfile = ({
  member,
  loginMemberships,
  logout,
  login,
  avatarCacheInvalidate,
  iconColor,
  hoverBackgroundColor,
}: Props) => {
  if (!login) {
    return null;
  }

  return (
    <Popover
      forwarded
      button={
        <div role="button" css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <IconButton
            hoverBackgroundColor={hoverBackgroundColor}
            iconColor={iconColor}
            icon="user alt"
            variant="tertiary"
          />

          <Icon name="chevron down" marginLeft="-xs" hide={{ xs: true, sm: false }} />
        </div>
      }
    >
      {({ hide }) => (
        <DashboardProfileContent
          hide={hide}
          member={member}
          loginMemberships={loginMemberships}
          logout={logout}
          login={login}
          avatarCacheInvalidate={avatarCacheInvalidate}
        />
      )}
    </Popover>
  );
};

const stateToProps = (state: AppState) => ({
  login: loginsSelectors.selectLogin(state),
  avatarCacheInvalidate: loginsSelectors.selectAvatarCacheInvalidate(state),
});

export const DashboardProfile = connect(stateToProps)(memo(_DashboardProfile));
