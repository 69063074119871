import { createActionCreator } from 'deox';

const namespace = '@updatingServers';

const addUpdatingServer = createActionCreator(
  `addUpdatingServer(${namespace})`,
  (resolve) => (payload: string) => resolve(payload)
);

const removeUpdatingServer = createActionCreator(
  `removeUpdatingServer(${namespace})`,
  (resolve) => (payload: string) => resolve(payload)
);

export const updatingServersActions = {
  addUpdatingServer,
  removeUpdatingServer,
};
