import { css } from '@emotion/core';

const formContainer = (fullWidth: boolean, fullFlex: boolean) =>
  css`
    ${fullFlex &&
    `
      flex: 1;
    `}

    ${fullWidth &&
    `
      width: 100%;
    `}
  `;

export const style = { formContainer };
