import throttle from 'lodash/throttle';
import { channel, Channel } from 'redux-saga';
import { call, put, take } from 'typed-redux-saga/macro';

import { updateViewport, ViewportPayload } from './actions';

export function* watchViewportSaga() {
  const viewportChannel: Channel<{ type: string; payload: ViewportPayload }> = yield call(channel);

  const update = () => {
    viewportChannel.put(
      updateViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    );
  };

  update();

  const throttledUpdate = throttle(update, 200);

  window.addEventListener('resize', throttledUpdate);

  while (true) {
    const action = yield* take(viewportChannel);
    yield* put(action);
  }
}
