import React, { lazy } from 'react';
import { LoginMembership, Role } from 'src/orchd-client';
import { IntlShape } from 'react-intl';
import { Redirect } from 'react-router-dom';

import { messages } from 'src/messages';
import { hasAtLeastOneRole, permissions } from 'src/utils/permissions/utils';
import { NavItem } from 'src/utils/types';

const Notifications = lazy(() => import('../pages/notifications/Notifications'));
const ProfileRoutes = lazy(() => import('../pages/user-profile/UserProfile/ProfileRoutes'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const Search = lazy(() => import('../pages/search'));
const Settings = lazy(() => import('../pages/settings/Settings'));
const Websites = lazy(() => import('../pages/websites/Websites'));
const WebsiteEmailsDashboard = lazy(
  () => import('../pages/websites/website/emails/dashboard/WebsiteEmailsDashboard/WebsiteEmailsDashboard')
);
const Users = lazy(() => import('../pages/users/Users'));
const Customers = lazy(() => import('../pages/customers'));
const Packages = lazy(() => import('../pages/packages'));
const Servers = lazy(() => import('../pages/servers/Servers'));
const IntegrationsRoutes = lazy(() => import('../pages/integrations/IntegrationsRoutes'));
const MoveServer = lazy(() => import('../pages/migrations/MoveServerRoutes'));
const WebsiteImports = lazy(() => import('../pages/website-imports/WebsiteImportsRoutes'));
const Logs = lazy(() => import('../pages/logs/Logs'));

interface Props {
  intl: IntlShape;
  hasEmail: boolean;
  isReseller: boolean;
  member?: LoginMembership;
  impersonationMember?: LoginMembership;
}

export const calcLoggedInNavigationItems = ({ intl, hasEmail, isReseller, member, impersonationMember }: Props) => {
  const { formatMessage } = intl;

  if (!member) return [];
  const { isMasterOrg } = member;

  const isImpersonatingEndUser = !!impersonationMember && !isReseller;
  const isEndUser = !isMasterOrg || isImpersonatingEndUser;
  const isMoOrReseller = !isEndUser || isReseller;

  const { Owner, SuperAdmin, Sysadmin, Support, SiteAccess, Business } = Role;

  /**
   * @see https://docs.google.com/spreadsheets/d/1FL5rWHZ4tvW6bWtRAf2DbpVpdRfW4kOy54JsHGuHBG8/edit#gid=0
   */
  const showDash = hasAtLeastOneRole([Owner, SuperAdmin, Sysadmin, Support, SiteAccess], member);
  const showServers = hasAtLeastOneRole(permissions.showServers, member) && isMasterOrg;
  const showCustomers = hasAtLeastOneRole(permissions.showCustomers, member) && isMoOrReseller;
  const showSettings = hasAtLeastOneRole([Owner, SuperAdmin, Sysadmin, Business], member) && isMoOrReseller;
  const showWebsites = hasAtLeastOneRole([Owner, SuperAdmin, Sysadmin, SiteAccess], member);
  const showEmail = hasAtLeastOneRole([Owner, SuperAdmin, Sysadmin, SiteAccess], member) && hasEmail;
  const showUsers = hasAtLeastOneRole([Owner, SuperAdmin], member);
  const showPackages = hasAtLeastOneRole([Owner, SuperAdmin], member) && isEndUser;
  const showWebsiteImports = hasAtLeastOneRole(permissions.showWebsiteImports, member);
  const showLogs = hasAtLeastOneRole(permissions.showLogs, member);
  const showIntegrations = hasAtLeastOneRole(permissions.admin, member);

  // spine items
  const items: NavItem[] = [
    { path: '/profile', component: ProfileRoutes },
    { path: '/notifications', component: Notifications },
    {
      path: '/search',
      component: Search,
    },
  ];
  if (showDash) {
    items.push({
      url: '/',
      component: Dashboard,
      exact: true,
      label: formatMessage(messages.home),
      icon: 'home',
    });
  } else {
    items.push({
      path: '/',
      component: () => <Redirect to="/settings" />,
      exact: true,
    });
  }

  if (showServers) {
    const servers: NavItem = {
      url: '/servers',
      component: Servers,
      label: formatMessage(messages.servers),
      icon: 'server',
      nested: [],
    };

    items.push(servers);
  }
  if (showCustomers) {
    items.push({
      url: '/customers',
      component: Customers,
      label: formatMessage(messages.customers),
      icon: 'nav customers',
    });
  }
  if (showWebsites) {
    const websites: NavItem = {
      url: '/websites',
      component: Websites,
      label: formatMessage(messages.websites),
      icon: 'nav websites',
      nested: [],
    };

    if (showWebsiteImports) {
      websites.nested?.push({
        url: '/website-imports',
        component: WebsiteImports,
        label: formatMessage(messages.website_importer),
        icon: 'nav imports',
      });
    }

    if (showServers) {
      websites.nested?.push({
        url: '/migrations',
        component: MoveServer,
        label: formatMessage(messages.move_server),
        icon: 'refresh',
      });
    }

    items.push(websites);
  }
  if (showEmail) {
    items.push({
      url: '/emails',
      component: WebsiteEmailsDashboard,
      label: formatMessage(messages.emails),
      icon: 'nav email',
    });
  }
  if (showLogs) {
    items.push({
      url: '/logs',
      component: Logs,
      label: formatMessage(messages.logs),
      icon: 'notebook',
    });
  }
  if (showPackages) {
    items.push({
      url: '/packages',
      component: Packages,
      label: formatMessage(messages.packages),
      icon: 'data usage',
    });
  }
  if (showUsers) {
    items.push({
      url: '/users',
      component: Users,
      label: formatMessage(messages.users),
      icon: 'users alt',
    });
  }

  if (showIntegrations) {
    items.push({
      url: '/integrations',
      component: IntegrationsRoutes,
      label: formatMessage(messages.integrations),
      icon: 'nav_integrations',
    });
  }

  if (showSettings) {
    items.push({
      url: '/settings',
      component: Settings,
      label: formatMessage(messages.settings),
      icon: 'settings',
    });
  }

  return items;
};
