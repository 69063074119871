import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Measure, { BoundingRect } from 'react-measure';
import { connect } from 'react-redux';

import { Colors } from 'src/design-system/style-types';
import { AppState } from 'src/store';
import { zIndex } from 'src/utils/zIndex';

import { viewportSelectors } from '../Viewport/_redux/selectors';
import { style } from './Portal.style';

export interface State {
  dimensions: Partial<BoundingRect>;
  mounted: boolean;
}

interface RequiredProps {
  isMobile: boolean;
}

interface DefaultProps {
  zIndex?: number;
  children: React.ReactNode;
  top: string | undefined;
  bottom: string | undefined;
  backgroundColor: Colors;
  root: 'mobile' | 'content';
  overflow: CSSProperties['overflow'];
}

export type Props = RequiredProps & DefaultProps;

const mobilePortalRoot = document.getElementById('mobile-portal-root');

class UnwrappedPortal extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    zIndex: zIndex.Portal,
    children: undefined,
    top: '64px',
    bottom: '0',
    backgroundColor: 'transparent',
    overflow: 'auto',
    root: 'mobile',
  };

  state = {
    dimensions: {
      left: 0,
      width: 0,
    },
    mounted: false,
  };

  el: HTMLDivElement;

  contentPortalRoot: HTMLElement | null;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
    this.contentPortalRoot = document.getElementById('content-portal-root');
  }

  componentDidMount() {
    const { root, isMobile } = this.props;
    if (mobilePortalRoot && isMobile && root === 'mobile') {
      mobilePortalRoot.appendChild(this.el);
    } else if (this.contentPortalRoot && !isMobile && root === 'content') {
      this.contentPortalRoot.appendChild(this.el);
    }

    this.setState({
      mounted: true,
    });
  }

  componentWillUnmount() {
    const { root } = this.props;
    if (mobilePortalRoot && root === 'mobile' && this.el.parentNode === mobilePortalRoot) {
      mobilePortalRoot.removeChild(this.el);
    }
    if (this.contentPortalRoot && root === 'content' && this.el.parentNode === this.contentPortalRoot) {
      this.contentPortalRoot.removeChild(this.el);
    }
  }

  render() {
    const { isMobile, children, root } = this.props;
    const { dimensions, mounted } = this.state;

    if (isMobile && root === 'mobile') {
      return ReactDOM.createPortal(
        mounted && (
          <div data-testid="portal" css={style.container(this.props, dimensions)}>
            {children}
          </div>
        ),
        this.el
      );
    }

    if (!isMobile && root === 'content') {
      return ReactDOM.createPortal(
        mounted && (
          <Measure
            bounds
            onResize={(contentRect) => {
              if (contentRect.bounds) {
                this.setState({ dimensions: contentRect.bounds });
              }
            }}
          >
            {({ measureRef }) => {
              return dimensions ? (
                <div ref={measureRef}>
                  {!!dimensions.width && <div css={style.container(this.props, dimensions)}>{children}</div>}
                </div>
              ) : null;
            }}
          </Measure>
        ),
        this.el
      );
    }

    return children;
  }
}

const mapStateToProps = (state: AppState) => ({
  isMobile: viewportSelectors.isMobile(state),
});

const Portal = connect(mapStateToProps)(UnwrappedPortal);

export { UnwrappedPortal, Portal };
