import { useMemo } from 'react';
import { LoginMembership, Member, Role, Website } from 'src/orchd-client';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetWebsiteBackupsQuery } from 'src/api_services/backups/query';
import { useGetWebsiteWebserverKindQuery } from 'src/api_services/websites/query';
import { AvatarStack } from 'src/components/AvatarStack';
import { UserAvatarConfig } from 'src/components/AvatarStack/AvatarStack';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CardCollapse } from 'src/components/CardCollapse';
import { Copy } from 'src/components/Copy/Copy';
import { Icon } from 'src/components/Icon';
import { Panel, PanelList } from 'src/components/PanelList';
import { ServiceKindImage } from 'src/components/ServiceKindImage/ServiceKindImage';
import { Span, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { dateConfig, timeConfig } from 'src/translations/constants';
import { hasAtLeastOneRole } from 'src/utils/permissions/utils';

import { phpVersionToLabelMap } from '../../devtools/PhpEngineManager/constants';

interface Props {
  member: LoginMembership;
  website: Website;
}

export const SimpleAtAGlance = ({ website, member }: Props) => {
  const { orgId } = member;
  const { id: websiteId } = website;
  const { formatDate } = useIntl();
  const members = (website.siteAccessMembers || []) as Member[];

  const { data: webserverKind } = useGetWebsiteWebserverKindQuery({ websiteId: website.id });
  const { data: websiteBackups = [] } = useGetWebsiteBackupsQuery({ orgId, websiteId });

  const appIPv4Address = website?.serverIps?.[0]?.ip;
  const dbIPv4Address = website?.dbServerIps?.[0]?.ip;
  const appIPv6Address = website?.appServerIpv6;
  const dbIPv6Address = website?.dbServerIpv6;
  const mailIp = website?.emailServerIps?.[0]?.ip;

  const avatarMembers: UserAvatarConfig[] = members.map(({ id, name, avatarPath }) => ({
    id,
    name,
    src: avatarPath,
  }));

  const latestBackup = useMemo(() => {
    if (websiteBackups.length === 0) {
      return undefined;
    }

    return [...websiteBackups]?.sort((a, b) => {
      if (a.startedAt < b.startedAt) return 1;
      if (a.startedAt > b.startedAt) return -1;
      return 0;
    })?.[0];
  }, [websiteBackups]);

  const panels = useMemo(() => {
    const panels: Panel[] = [];

    if (webserverKind) {
      panels.push({
        content: (
          <Box a="center" mr="2xl">
            <Box w={6}>
              <Text size="xs" transform="uppercase">
                <FormattedMessage id="websites.website_dashboard.overview.webserver" defaultMessage="Webserver" />
              </Text>
            </Box>
            <Box w={6}>
              <ServiceKindImage kind={webserverKind} />
            </Box>
          </Box>
        ),
        noHover: true,
      });
    }

    if (website.phpVersion) {
      panels.push({
        content: (
          <Box a="center">
            <Box w={6}>
              <Text size="xs" transform="uppercase">
                <FormattedMessage id="websites.website_dashboard.overview.php_version" defaultMessage="PHP version" />
              </Text>
            </Box>
            <Box w={6}>
              <Text size="sm">{phpVersionToLabelMap[website.phpVersion]}</Text>
            </Box>
          </Box>
        ),
        action: {
          icon: <Icon name="chevron right" />,
          url: `/websites/${website.id}/devtools`,
        },
      });
    }

    if (website.backupServerId) {
      panels.push({
        content: (
          <Box a="center">
            <Box w={6}>
              <Text size="xs" transform="uppercase">
                <FormattedMessage id="websites.website_dashboard.overview.last_backup" defaultMessage="Last backup" />
              </Text>
            </Box>
            <Box w={6}>
              {latestBackup && (
                <Text size="sm" ellipsis>
                  <FormattedMessage
                    {...messages.date_at_time}
                    values={{
                      date: formatDate(latestBackup.startedAt, dateConfig),
                      time: formatDate(latestBackup.startedAt, timeConfig),
                    }}
                  />
                </Text>
              )}

              {!latestBackup && <Icon dataQa="noBackupsIcon" name="minus" />}
            </Box>
          </Box>
        ),
        action: {
          icon: <Icon name="chevron right" />,
          url: `/websites/${website.id}/backups`,
        },
      });
    }

    if (hasAtLeastOneRole([Role.Owner, Role.SuperAdmin], member)) {
      panels.push({
        content: (
          <Box a="center">
            <Box w={6}>
              <Text size="xs" transform="uppercase">
                <FormattedMessage
                  id="websites.website_dashboard.overview.collaborators"
                  defaultMessage="Collaborators"
                />
              </Text>
            </Box>
            <Box w={6}>
              {members.length === 0 ? (
                <Button size="tiny" variant="secondary">
                  <FormattedMessage
                    id="websites.website_dashboard.overview.invite_collaborator"
                    defaultMessage="Invite collaborator"
                  />
                </Button>
              ) : (
                <AvatarStack size="xs" users={avatarMembers} border shadow totalUsers={members.length} />
              )}
            </Box>
          </Box>
        ),
        action: {
          icon: members.length > 1 && <Icon name="chevron right" />,
          url: '/users',
        },
      });
    }

    panels.push({
      content: (
        <Box a="center" mr="2xl" my="lg">
          <Box w={6}>
            <Text size="xs" transform="uppercase" marginBottom="sm">
              <FormattedMessage {...messages.website} />
            </Text>

            <Text size="xs" color="grey" transform="uppercase" marginBottom="xs" fullWidth>
              <FormattedMessage id="website.overview.ipv4_address" defaultMessage="IPv4 address" />
            </Text>

            {!!appIPv6Address && (
              <Text size="xs" color="grey" transform="uppercase" fullWidth>
                <FormattedMessage id="website.overview.ipv6_address" defaultMessage="IPv6 address" />
              </Text>
            )}
          </Box>

          <Box w={6}>
            {/* Consistent spacer */}
            <Text size="xs" marginBottom="sm" fullWidth>
              &nbsp;
            </Text>

            <Box>
              {appIPv4Address ? (
                <Copy
                  value={appIPv4Address}
                  label={
                    <Span size="sm" ellipsis mono>
                      {appIPv4Address}
                    </Span>
                  }
                />
              ) : (
                <Icon dataQa="noIpIcon" name="minus" />
              )}
            </Box>

            {!!appIPv6Address && (
              <Box>
                <Copy
                  value={appIPv6Address}
                  label={
                    <Span size="sm" ellipsis mono>
                      {appIPv6Address}
                    </Span>
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      ),
      noHover: true,
    });

    if (dbIPv4Address) {
      panels.push({
        content: (
          <Box a="center" mr="2xl" my="lg">
            <Box w={6}>
              <Text size="xs" transform="uppercase" marginBottom="sm" fullWidth>
                <FormattedMessage id="website.overview.mysql" defaultMessage="MySQL" />
              </Text>

              <Text size="xs" color="grey" transform="uppercase" marginBottom="xs" fullWidth>
                <FormattedMessage id="website.overview.ipv4_address" defaultMessage="IPv4 address" />
              </Text>

              {!!dbIPv6Address && (
                <Text size="xs" color="grey" transform="uppercase" fullWidth>
                  <FormattedMessage id="website.overview.ipv6_address" defaultMessage="IPv6 address" />
                </Text>
              )}
            </Box>

            <Box w={6}>
              {/* Consistent spacer */}
              <Text size="xs" marginBottom="sm" fullWidth>
                &nbsp;
              </Text>

              <Box>
                {dbIPv4Address ? (
                  <Copy
                    value={dbIPv4Address}
                    label={
                      <Span size="sm" ellipsis mono>
                        {dbIPv4Address}
                      </Span>
                    }
                  />
                ) : (
                  <Icon dataQa="noIpIcon" name="minus" />
                )}
              </Box>

              {!!dbIPv6Address && (
                <Box>
                  <Copy
                    value={dbIPv6Address}
                    label={
                      <Span size="sm" ellipsis mono>
                        {dbIPv6Address}
                      </Span>
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        ),
        noHover: true,
      });
    }

    if (mailIp) {
      panels.push({
        content: (
          <Box a="center" mr="2xl">
            <Box w={6}>
              <Text size="xs" transform="uppercase">
                <FormattedMessage id="mail_ip" defaultMessage="Mail IP" />
              </Text>
            </Box>
            <Box w={6}>
              <Copy
                value={mailIp}
                label={
                  <Span size="sm" ellipsis mono>
                    {mailIp}
                  </Span>
                }
              />
            </Box>
          </Box>
        ),
        noHover: true,
      });
    }

    panels.push({
      content: (
        <Box a="center" mr="2xl">
          <Box w={6}>
            <Text size="xs" transform="uppercase">
              <FormattedMessage {...messages.created_at} />
            </Text>
          </Box>

          <Box w={6}>
            <Text size="sm" ellipsis>
              <FormattedMessage
                {...messages.date_at_time}
                values={{
                  date: formatDate(website.createdAt, dateConfig),
                  time: formatDate(website.createdAt, timeConfig),
                }}
              />
            </Text>
          </Box>
        </Box>
      ),
      noHover: true,
    });

    return panels;
  }, [
    webserverKind,
    website.phpVersion,
    website.backupServerId,
    website.createdAt,
    website.id,
    member,
    appIPv6Address,
    appIPv4Address,
    dbIPv4Address,
    mailIp,
    formatDate,
    latestBackup,
    members.length,
    avatarMembers,
    dbIPv6Address,
  ]);

  return (
    <CardCollapse
      noShadow
      startCollapsed={false}
      headerBackground="grey75"
      iconColor="light"
      marginBottom="0"
      header={
        <Text size="lg" color="light" ellipsis>
          <FormattedMessage id="websites.website_dashboard.overview.title" defaultMessage="At a glance" />
        </Text>
      }
    >
      <PanelList name="website-overview" noLastBorder itemPaddingX="sm" panels={panels} />
    </CardCollapse>
  );
};
