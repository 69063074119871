import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { installActions } from './actions';

const initialState = {
  toastDismissed: false,
};

export type InstallState = typeof initialState;

export const installReducer = combineReducers({
  orchdVersion: createRequestStateReducer(installActions.getOrchdVersion),
});
