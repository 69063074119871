/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { FontSizes, Margins, Responsive } from 'src/design-system/style-types';

import { Icon } from '../Icon';
import { style } from './Search.style';

interface RequiredProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxWidth?: number;
}

interface DefaultProps {
  hoverName?: string;
  autoFocus?: boolean;
  className: string;
  alwaysExpanded: boolean;
  placeholder: string;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  marginLeft: Responsive<Margins>;
  marginBottom: Responsive<Margins>;
  fontSize: FontSizes;
  name: undefined | string;
  autoComplete: string;
  disabled: boolean;
}

export type Props = RequiredProps & DefaultProps;

interface State {
  focused: boolean;
}

class Search extends React.Component<Props, State> {
  state = {
    focused: false,
  };

  inputRef = React.createRef<HTMLInputElement>();

  static defaultProps: DefaultProps = {
    autoFocus: false,
    className: '',
    alwaysExpanded: false,
    placeholder: '',
    onBlur: () => {},
    onKeyUp: () => {},
    onKeyDown: () => {},
    marginBottom: '0',
    marginLeft: '0',
    fontSize: 'sm',
    name: undefined,
    autoComplete: '',
    disabled: false,
  };

  componentDidMount = () => {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.focusInput();
    }
  };

  focusInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
      this.setState({ focused: true });
    }
  };

  handleSearchIconClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    this.focusInput();
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;

    onBlur(event);
    this.setState({ focused: false });
  };

  render() {
    const { focused } = this.state;
    const {
      className,
      onChange,
      value,
      placeholder,
      alwaysExpanded,
      name,
      hoverName,
      autoComplete,
      disabled,
      onKeyUp,
      onKeyDown,
    } = this.props;
    return (
      <div className={className} css={style.container(this.props)}>
        <input
          id={name}
          name={name}
          autoComplete={autoComplete}
          data-qa="input"
          placeholder={placeholder}
          aria-disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={this.handleBlur}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          ref={this.inputRef}
          css={style.field(this.props)}
          type="search"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={name}
          css={css`
            cursor: pointer;
            height: 16px;
            width: 16px;
          `}
        >
          <Icon
            data-qa="search-icon"
            onClick={this.handleSearchIconClick}
            onKeyDown={(e) => {
              if (e.key !== ' ' && e.key !== 'Enter') {
                return;
              }

              this.handleSearchIconClick(e);
            }}
            css={style.icon({ disabled })}
            name="search"
            hoverName={hoverName}
            size="lg"
            color={focused || alwaysExpanded || value ? 'dark' : 'grey'}
          />
        </label>
      </div>
    );
  }
}

export default Search;
