import { domainsApi } from 'src/api_services/domains/service';

import { createActionsFromMethod } from '../helpers';

const namespace = '@domains';

const createWebsiteMappedDomain = createActionsFromMethod<typeof domainsApi.createWebsiteMappedDomain>(
  `createWebsiteMappedDomain(${namespace})`
);
const createWebsiteDomainLetsencryptCerts = createActionsFromMethod<
  typeof domainsApi.createWebsiteDomainLetsencryptCerts
>(`createWebsiteDomainLetsencryptCerts(${namespace})`);
const deleteDomain = createActionsFromMethod<typeof domainsApi.deleteDomain>(`deleteDomain(${namespace})`);

const getWebsiteDomainMapping = createActionsFromMethod<typeof domainsApi.getWebsiteDomainMapping>(
  `getWebsiteDomainMapping(${namespace})`
);
const getWebsiteDomainMappings = createActionsFromMethod<typeof domainsApi.getWebsiteDomainMappings>(
  `getWebsiteDomainMappings(${namespace})`
);
const updateWebsiteDomainMapping = createActionsFromMethod<typeof domainsApi.updateWebsiteDomainMapping>(
  `updateWebsiteDomainMapping(${namespace})`
);
const updateWebsitePrimaryDomain = createActionsFromMethod<typeof domainsApi.updateWebsitePrimaryDomain>(
  `updateWebsitePrimaryDomain(${namespace})`
);
const checkDomain = createActionsFromMethod<typeof domainsApi.checkDomain>(`checkDomain(${namespace})`);

export const domainsActions = {
  createWebsiteMappedDomain,
  createWebsiteDomainLetsencryptCerts,
  deleteDomain,
  getWebsiteDomainMapping,
  getWebsiteDomainMappings,
  updateWebsiteDomainMapping,
  updateWebsitePrimaryDomain,
  checkDomain,
};
