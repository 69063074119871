import { membersApi } from 'src/api_services/members/service';

import { createActionsFromMethod } from '../helpers';

const namespace = '@members';

const createMember = createActionsFromMethod<typeof membersApi.createMember>(`createMember(${namespace})`);

const getMember = createActionsFromMethod<typeof membersApi.getMember>(`getMember(${namespace})`);

const getMembers = createActionsFromMethod<typeof membersApi.getMembers>(`getMembers(${namespace})`);

const getAccessTokens = createActionsFromMethod<typeof membersApi.getAccessTokens>(`getAccessTokens(${namespace})`);

const createAccessToken = createActionsFromMethod<typeof membersApi.createAccessToken>(
  `createAccessToken(${namespace})`
);

const deleteAccessToken = createActionsFromMethod<typeof membersApi.deleteAccessToken>(
  `deleteAccessToken(${namespace})`
);

export const membersActions = {
  createMember,
  getMember,
  getMembers,
  getAccessTokens,
  createAccessToken,
  deleteAccessToken,
};
