/** @jsxRuntime classic */
/** @jsx jsx */
import { memo, useState } from 'react';
import { css, jsx } from '@emotion/core';
import { Login, LoginMembership } from 'src/orchd-client';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar } from 'src/components/Avatar';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import Pill from 'src/components/Pill';
import { Text } from 'src/components/Text';
import { Portal } from 'src/containers/Portal';
import { Theme } from 'src/design-system/theme';
import { messages } from 'src/messages';
import { promisifyRequestAction } from 'src/store/helpers';
import { sessionActions } from 'src/store/session/actions';
import { getColorFromHex } from 'src/utils/helpers';
import { rolesWithSiteAccessAdded } from 'src/utils/permissions/utils';

type ActionProps = typeof dispatchToProps;

export interface Props extends ActionProps {
  hide: () => void;
  logout: () => void;
  member: LoginMembership;
  login: Login;
  avatarCacheInvalidate: number;
  loginMemberships: LoginMembership[];
}

export const dispatchToProps = {
  setCurrentMemberId: sessionActions.setCurrentMemberId,
  findActiveMember: promisifyRequestAction(sessionActions.findActiveMember.request),
};

const _DashboardProfileContent = ({
  avatarCacheInvalidate,
  hide,
  login,
  loginMemberships,
  logout,
  member,
  setCurrentMemberId,
  findActiveMember,
}: Props) => {
  const [loadingMemberId, setLoadingMemberId] = useState('');
  const history = useHistory();

  const onMembershipClick = async (memberId: string, hide: () => void) => {
    if (memberId === member.memberId) {
      return;
    }

    try {
      setLoadingMemberId(memberId);

      setCurrentMemberId({ id: memberId });

      await findActiveMember({});

      hide();
    } finally {
      setLoadingMemberId('');
      history.push('/');
    }
  };

  const multi = loginMemberships.length > 1;

  if (!login) {
    return null;
  }

  return (
    <Portal top="0">
      <Box
        wrap="nowrap"
        a="stretch"
        d={{ xs: 'column', sm: 'row' }}
        w={{ xs: '100%', sm: multi ? '520px' : '260px' }}
        bg="light"
        h={{ xs: '100vh', sm: 'auto' }}
      >
        <Box
          w={{ xs: 12, sm: multi ? 6 : 12 }}
          h={{ xs: '50%', sm: 'auto' }}
          bg={{ xs: 'grey95', sm: 'light' }}
          d="column"
          a="center"
          py={{ xs: 'xl', sm: '2xl' }}
          p={{ xs: '2xl', sm: 'xl' }}
        >
          <Box role="button" w="auto" alignSelf="flex-end" mt="-md" mr="-xl" p="md" hide={{ sm: true }} onClick={hide}>
            <Icon ariaLabel="closeAccountMenu" name="close big alt" color="light" size="md" />
          </Box>
          <Box d="column" a="center" my={{ xs: 'auto', sm: '0' }} minW="0">
            <Avatar
              colorCode={getColorFromHex(login.colorCode)}
              name={login.name || ''}
              size="lg"
              src={login.avatarPath ? `/${login.avatarPath}?${avatarCacheInvalidate}` : undefined}
              shadow
              marginBottom="lg"
            />
            <Text
              align="center"
              size="lg"
              weight="bold"
              ellipsis
              fullWidth
              marginBottom="xs"
              color={{ xs: 'light', sm: 'dark' }}
            >
              {login.name}
            </Text>

            <Text
              size="sm"
              color={{ xs: 'light', sm: 'dark' }}
              marginBottom={multi ? 'lg' : 'sm'}
              ellipsis
              fullWidth
              align="center"
            >
              {login.email}
            </Text>
            {!multi && (
              <Box j="center" w="100%" mb="lg" minW="0">
                {loginMemberships[0]?.roles.map((role) => (
                  <Pill
                    key={role}
                    marginBottom="xs"
                    marginRight="xs"
                    borderColor={{ xs: 'light', sm: 'dark' }}
                    fontColor={{ xs: 'light', sm: 'dark' }}
                    shrink={0}
                  >
                    <FormattedMessage {...messages[role]} />
                  </Pill>
                ))}
              </Box>
            )}
          </Box>
          <Box d={{ xs: 'row', sm: 'column' }} j="space-between" a="center">
            <Button
              size="small"
              variant="primary"
              marginBottom={{ xs: '0', sm: 'lg' }}
              onClick={() => {
                history.push('/profile');
                hide();
              }}
            >
              <FormattedMessage {...messages.my_account} />
            </Button>
            <Button
              variant="tertiary"
              color={{ xs: 'light', sm: 'dark' }}
              borderColorHover={{ xs: 'light', sm: 'dark' }}
              onClick={logout}
              size="small"
            >
              <FormattedMessage {...messages.logout} />
            </Button>
          </Box>
        </Box>
        {multi && (
          <Box
            w={{ xs: 12, sm: 6 }}
            h={{ xs: '50%', sm: '290px' }}
            bl={{ xs: 0, sm: 1 }}
            css={css`
              overflow-y: auto;
            `}
          >
            <Box>
              {loginMemberships.map((membership) => {
                return (
                  <Box
                    role="button"
                    bb={1}
                    key={membership.orgName}
                    css={(theme: Theme) => css`
                      cursor: pointer;
                      &:hover {
                        background: ${theme.colors.grey3};
                      }
                    `}
                    onClick={() => onMembershipClick(membership.memberId, hide)}
                  >
                    <Box
                      bl={membership.memberId === member.memberId ? 3 : 0}
                      bc="accent"
                      h="68px"
                      minH="68px"
                      a="center"
                      pr="lg"
                      wrap="nowrap"
                    >
                      <Icon
                        name="loading"
                        color="grey"
                        size="lg"
                        hide={membership.memberId !== loadingMemberId}
                        marginLeft="xl"
                      />
                      <Box d="column" a="flex-end">
                        <Text size="sm" marginBottom="xs">
                          {membership.orgName}
                        </Text>
                        <Box j="flex-end" wrap="nowrap" w="100%" minW="0" maxW="180px">
                          {rolesWithSiteAccessAdded(membership).map((role) => (
                            <Pill
                              key={role}
                              marginBottom="xs"
                              marginLeft="xs"
                              borderColor={{ xs: 'light', sm: 'dark' }}
                              fontColor={{ xs: 'light', sm: 'dark' }}
                              shrink={0}
                            >
                              <FormattedMessage {...messages[role]} />
                            </Pill>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Portal>
  );
};

export const DashboardProfileContent = connect(null, dispatchToProps)(memo(_DashboardProfileContent));
