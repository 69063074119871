import { Website } from 'src/orchd-client';
import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { isDomainAlias } from 'src/utils/website';

import { moveServerActions } from './actions';

export interface MoveServerState {
  selectedWebsites: SelectedWebsitesState;
}

export interface SelectedWebsitesState {
  items: Website[];
}

export const initialSelectedWebsitesState: SelectedWebsitesState = {
  items: [],
};

const selectedWebsitesReducer = createReducer(initialSelectedWebsitesState, (handleAction) => [
  handleAction(moveServerActions.addMoveServerWebsites, (state, { payload: { websites, truncate = false } }) => {
    const items = (truncate ? [] : state.items)
      .concat(websites)
      .filter((website) => !isDomainAlias(website))
      // Make sure we don't select a website twice.
      .reduce<Website[]>(
        (carry, item) => (carry.some((website) => website.id === item.id) ? carry : carry.concat(item)),
        []
      );

    return { items };
  }),
  handleAction(moveServerActions.removeMoveServerWebsites, (state, { payload: { websites } }) => {
    return { items: state.items.filter((item) => !websites.some((website) => item.id === website.id)) };
  }),
  handleAction(moveServerActions.toggleMoveServerWebsite, (state, { payload: { website } }) => {
    if (state.items.some((item) => item.id === website.id)) {
      return { items: state.items.filter((item) => item.id !== website.id) };
    }

    return { items: state.items.concat(website) };
  }),
  handleAction(moveServerActions.clearMoveServers, () => {
    return initialSelectedWebsitesState;
  }),
]);

export const moveServerReducer = combineReducers({
  selectedWebsites: selectedWebsitesReducer,
});
