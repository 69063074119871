import { AxiosError } from 'axios';

import { store } from 'src/store';
import { updatingServersActions } from 'src/store/servers/updatingServers/actions';
import { updatingServersSelectors } from 'src/store/servers/updatingServers/selectors';
import { sessionActions } from 'src/store/session/actions';
import { isFilerdSessionExpired } from 'src/utils/axios/isFilerdSessionExpired/isFilerdSessionExpired';
import { getServerIdFromUrl } from 'src/utils/servers/servers';

import { handleBackupInProgress } from '../handleBackupInProgress/handleBackupInProgress';
import { handleIsUnlicensed } from '../handleIsUnlicensed/handleIsUnlicensed';
import { handleTokenRefresh } from '../handleTokenRefresh/handleTokenRefresh';
import { isBackupInProgress } from '../isBackupInProgress/isBackupInProgress';
import { isServerUpdating } from '../isServerUpdating/isServerUpdating';
import { isUnauthorised } from '../isUnauthorised/isUnauthorised';
import { isUnlicensed } from '../isUnlicensed/isUnlicensed';

export const handleInterceptorError = (error: AxiosError) => {
  if (isUnauthorised(error)) {
    store.dispatch(sessionActions.logout());
  } else if (isUnlicensed(error)) {
    handleIsUnlicensed();
  } else if (isBackupInProgress(error)) {
    handleBackupInProgress();
  } else if (isServerUpdating(error)) {
    const serverId = getServerIdFromUrl(error.response?.config.url);
    const updatingServerIds = updatingServersSelectors.selectUpdatingServerIds(store.getState());

    if (serverId && !updatingServerIds.includes(serverId)) {
      store.dispatch(updatingServersActions.addUpdatingServer(serverId));
    }
  } else if (isFilerdSessionExpired(error)) {
    return handleTokenRefresh(error);
  }
  return Promise.reject(error);
};
