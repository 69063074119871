/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { BorderStyle, Colors, Margins, Responsive } from '../../design-system/style-types';
import style from './Divider.style';

export interface DefaultProps {
  marginBottom: Responsive<Margins>;
  marginLeft: Responsive<Margins>;
  marginRight: Responsive<Margins>;
  marginTop: Responsive<Margins>;
  borderColor: Colors;
  borderWidth: number;
  borderStyle: BorderStyle;
  type: Responsive<'vertical' | 'horizontal'>;
  'data-name'?: string;
  className?: string;
}

const defaultProps: DefaultProps = {
  marginBottom: '0',
  marginLeft: '0',
  marginRight: '0',
  marginTop: '0',
  borderColor: 'grey10',
  borderWidth: 1,
  borderStyle: 'solid',
  type: 'horizontal',
};

const Divider = (props: DefaultProps) => (
  // eslint-disable-next-line react/destructuring-assignment
  <span className={props.className} data-name={props['data-name']} css={style(props)} />
);

Divider.defaultProps = defaultProps;

export default Divider;
