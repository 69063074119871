import { Theme } from 'src/design-system/theme';

const flag = (theme: Theme) => `
  border-radius: 3px;
  border: 1px solid ${theme.colors.grey20};
  line-height: 18px;
  width: 26px;
`;

export const style = { flag };
