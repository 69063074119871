import { connectRouter } from 'connected-react-router';
import { getType } from 'deox';
import merge from 'lodash/fp/merge';
import { combineReducers } from 'redux';

import { viewportReducer } from 'src/containers/Viewport/_redux/reducer';
import { invitesReducer } from 'src/pages/invites/_redux';
import { moveServerReducer } from 'src/pages/migrations/_redux';
import { serverRolesReducer } from 'src/pages/servers/manage-server/_redux/server-roles/reducer';
import { unconfiguredServersReducer } from 'src/pages/servers/servers-list/_redux/unconfigured-servers/reducer';
import { serviceSettingsReducer } from 'src/pages/settings/Service/_redux/reducer';
import { websitesDashboardReducer } from 'src/pages/websites/dashboard/redux/reducers';
import { sshKeysManagerReducer } from 'src/pages/websites/website/devtools/SshKeysManager/_redux';
import { websiteEmailsPageReducer } from 'src/pages/websites/website/emails/redux/reducer';
import { fileManagerReducer } from 'src/pages/websites/website/files/_redux';
import { ipBlockerReducer } from 'src/pages/websites/website/security/IpBlocker/_redux';
import { globalSearchReducer } from 'src/store/global-search/reducer';
import { notificationsReducer } from 'src/store/notifications/reducer';
import { sessionActions } from 'src/store/session/actions';
import { uiReducer } from 'src/store/ui/reducer';

import { createWebsiteReducer } from '../pages/websites/add-website/redux';
import { appsReducer } from './apps';
import { themeReducer } from './branding/reducer';
import { customersReducer } from './customers/reducer';
import { devtoolsReducer } from './devtools/reducer';
import { domainsReducer } from './domains/reducer';
import { emailsReducer } from './emails/reducer';
import { filerdReducer } from './filerd/reducer';
import { globalUiReducer } from './globalUi';
import { history } from './history';
import { hydrateState } from './hydrate';
import { impersonationReducer } from './impersonation';
import { impersonationActions } from './impersonation/actions';
import { installReducer } from './install/reducer';
import { licenceReducer } from './licence/reducer';
import { loginsActions } from './logins/actions';
import { loginsReducer } from './logins/reducers';
import { membersReducer } from './members/reducer';
import { modalReducer } from './modals/reducer';
import { mysqlReducer } from './mysql/reducer';
import { orgsReducer } from './orgs/reducer';
import { plansReducer } from './plans/reducer';
import { poppersReducer } from './poppers';
import { searchReducer } from './searches';
import { serversReducer } from './servers/reducer';
import { sessionReducer } from './session';
import { settingsReducer } from './settings/reducer';
import { subscriptionsReducer } from './subscriptions/reducer';
import { tableSettingsReducer } from './table-settings/reducer';
import { toastsReducer } from './toast/reducer';
import { websiteBackupsReducer } from './website-backups/reducer';
import { websitesReducer } from './websites/reducer';

const rootReducer = combineReducers({
  apps: appsReducer,
  createWebsite: createWebsiteReducer,
  customers: customersReducer,
  data: combineReducers({
    websites: websitesReducer,
  }),
  devtools: devtoolsReducer,
  domains: domainsReducer,
  emails: emailsReducer,
  fileManager: fileManagerReducer,
  filerd: filerdReducer,
  globalSearch: globalSearchReducer,
  globalUi: globalUiReducer,
  impersonation: impersonationReducer,
  install: installReducer,
  invites: invitesReducer,
  ipBlocker: ipBlockerReducer,
  licence: licenceReducer,
  logins: loginsReducer,
  members: membersReducer,
  modal: modalReducer,
  moveServer: moveServerReducer,
  mysql: mysqlReducer,
  notifications: notificationsReducer,
  orgs: orgsReducer,
  plans: plansReducer,
  poppers: poppersReducer,
  router: connectRouter(history),
  search: searchReducer,
  servers: serversReducer,
  serversManager: combineReducers({
    roles: serverRolesReducer,
    unconfiguredServers: unconfiguredServersReducer,
  }),
  serviceSettings: serviceSettingsReducer,
  session: sessionReducer,
  settings: settingsReducer,
  sshKeysManager: sshKeysManagerReducer,
  subscriptions: subscriptionsReducer,
  tableSettings: tableSettingsReducer,
  theme: themeReducer,
  toasts: toastsReducer,
  ui: uiReducer,
  viewport: viewportReducer,
  websiteBackups: websiteBackupsReducer,
  websiteEmails: websiteEmailsPageReducer,
  websites: combineReducers({
    dashboard: websitesDashboardReducer,
    websites: websitesReducer,
  }),
});

const dataResetActions = [
  getType(impersonationActions.startImpersonatingSuccess),
  getType(impersonationActions.stopImpersonating),
];

const hydrateActions = [
  getType(sessionActions.setCurrentMemberId),
  getType(loginsActions.getLogin.success),
  getType(impersonationActions.stopImpersonatingSuccess),
];

const logoutResetActions = [
  getType(loginsActions.deleteCurrentSession.success),
  getType(loginsActions.deleteCurrentSession.error),
];

const reducer: typeof rootReducer = (state, action) => {
  if (!state) {
    return rootReducer(state, action);
  }

  if (hydrateActions.includes(action.type)) {
    return rootReducer(merge(state, hydrateState()), action);
  }

  if (dataResetActions.includes(action.type)) {
    // The items we want to keep after clearing.
    const {
      poppers,
      modal,
      theme,
      router,
      session,
      impersonation,
      globalUi,
      viewport,
      logins,
      install,
      servers,
      tableSettings,
    } = state;

    const clearedState: Partial<typeof state> = {
      poppers,
      modal,
      theme,
      router,
      session,
      impersonation,
      globalUi,
      viewport,
      logins,
      install,
      servers,
      tableSettings,
    };

    return rootReducer(clearedState as typeof state, action);
  }

  if (logoutResetActions.includes(action.type)) {
    // The items we want to keep after logout.
    const { router, viewport, globalUi } = state;

    const logoutState: Partial<typeof state> = {
      router,
      viewport,
      globalUi,
    };

    return rootReducer(logoutState as typeof state, action);
  }

  return rootReducer(state, action);
};

export default reducer;
