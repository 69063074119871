import { websitesApi } from 'src/api_services/websites/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@websites';

const getWebsites = createActionsFromMethod<typeof websitesApi.getWebsites>(`getWebsites(${namespace})`);

const getWebsite = createActionsFromMethod<typeof websitesApi.getWebsite>(`getWebsite(${namespace})`);

const getAccessToken = createActionsFromMethod<typeof websitesApi.getAccessToken>(`getAccessToken(${namespace})`);

const deleteWebsite = createActionsFromMethod<typeof websitesApi.deleteWebsite>(`deleteWebsite(${namespace})`);

const updateWebsite = createActionsFromMethod<typeof websitesApi.updateWebsite>(`updateWebsite(${namespace})`);

const updateWebsiteDomainMapping = createActionsFromMethod<typeof websitesApi.updateWebsiteDomainMapping>(
  `updateWebsiteDomainMapping(${namespace})`
);

const pushWebsiteLive = createActionsFromMethod<typeof websitesApi.pushWebsiteLive>(`pushWebsiteLive(${namespace})`);

const getWebsiteMySQLDBs = createActionsFromMethod<typeof websitesApi.getWebsiteMySQLDBs>(
  `getWebsiteMySQLDBs(${namespace})`
);

const createWebsiteMySQLDB = createActionsFromMethod<typeof websitesApi.createWebsiteMySQLDB>(
  `createWebsiteMySQLDB(${namespace})`
);

const getWebsiteHtaccessRewrites = createActionsFromMethod<typeof websitesApi.getWebsiteHtaccessRewrites>(
  `getWebsiteHtaccessRewrites(${namespace})`
);

const updateWebsiteHtaccessRewrites = createActionsFromMethod<typeof websitesApi.updateWebsiteHtaccessRewrites>(
  `updateWebsiteHtaccessRewrites(${namespace})`
);

const getWebsiteSetting = createActionsFromMethod<typeof websitesApi.getWebsiteSetting>(
  `getWebsiteSetting(${namespace})`
);

const setWebsiteSetting = createActionsFromMethod<typeof websitesApi.setWebsiteSetting>(
  `setWebsiteSetting(${namespace})`
);

const deleteWebsiteSetting = createActionsFromMethod<typeof websitesApi.deleteWebsiteSetting>(
  `deleteWebsiteSetting(${namespace})`
);

const getWebsiteServerDomains = createActionsFromMethod<typeof websitesApi.getWebsiteServerDomains>(
  `getWebsiteServerDomains(${namespace})`
);

const createPreviewDomain = createActionsFromMethod<typeof websitesApi.createPreviewDomain>(
  `createPreviewDomain(${namespace})`
);

const authorizeWebsiteSshPassword = createActionsFromMethod<typeof websitesApi.authorizeWebsiteSshPassword>(
  `authorizeWebsiteSshPassword(${namespace})`
);

const getScreenshotTimestamp = createActionsFromMethod<typeof websitesApi.getScreenshotTimestamp>(
  `getScreenshotTimestamp(${namespace})`
);

const takeScreenshot = createActionsFromMethod<typeof websitesApi.takeScreenshot>(`takeScreenshot(${namespace})`);

const getWebsiteDomainMappingDnsStatus = createActionsFromMethod<typeof websitesApi.getWebsiteDomainMappingDnsStatus>(
  `getWebsiteDomainMappingDnsStatus(${namespace})`
);

export const websitesActions = {
  createPreviewDomain,
  createWebsiteMySQLDB,
  deleteWebsite,
  deleteWebsiteSetting,
  getAccessToken,
  getWebsite,
  getWebsiteHtaccessRewrites,
  getWebsiteMySQLDBs,
  getWebsites,
  getWebsiteServerDomains,
  getWebsiteSetting,
  pushWebsiteLive,
  setWebsiteSetting,
  updateWebsite,
  updateWebsiteDomainMapping,
  updateWebsiteHtaccessRewrites,
  authorizeWebsiteSshPassword,
  getScreenshotTimestamp,
  takeScreenshot,
  getWebsiteDomainMappingDnsStatus,
};
