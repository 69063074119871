import { css } from '@emotion/core';
import { lighten, shade } from 'polished';

import { fontSize, fontWeight, margin } from 'src/design-system/mixins';
import { getStickyRandomColor, responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';
import { checkColorParses } from 'src/utils/colors/colors';

import type { Props } from './Avatar';

const sizes = {
  xs: 24,
  sm: 32,
  md: 48,
  lg: 72,
  '5xl': 200,
};

const avatar = (props: Props) => (theme: Theme) => {
  const defaultColor = props.name === '' ? 'grey40' : getStickyRandomColor(props.name ?? '');
  const color = props.colorCode || defaultColor;
  const usedColor = checkColorParses(theme.colors[color] ? theme.colors[color] : `#${color}`);

  return css`
    width: ${sizes[props.size]}px;
    min-width: ${sizes[props.size]}px;
    height: ${sizes[props.size]}px;
    border-radius: 9999px;
    background: ${lighten(0.25, usedColor)};
    color: ${shade(0.25, usedColor)};
    display: flex;
    justify-content: center;
    align-items: center;
    ${fontWeight('bold')(theme)}
    ${fontSize(props.size)(theme)}
    ${margin({ bottom: props.marginBottom, right: props.marginRight, left: props.marginLeft })(theme)}
    ${responsive(props.border, (border) =>
      responsive(props.shadow, (shadow) => {
        const shadows = [];
        if (border) {
          shadows.push(`0 0 0 1px ${typeof border === 'string' ? theme.colors[border] : theme.colors.light}`);
        }
        if (shadow) {
          shadows.push('0px 0px 12px 0px rgba(0, 0, 0, 0.1)');
        }
        if (shadows.length) {
          return css`
            box-shadow: ${shadows.join(', ')};
          `;
        }
        return css`
          box-shadow: none;
        `;
      })
    )}

    ${props.src &&
    css`
      background: url(${props.src}) center / cover no-repeat;
      background-color: ${theme.colors.light};
    `}
  `;
};

export const style = { avatar };
