import { ADD_POPPER, HIDE_ALL_POPPERS, HIDE_POPPER, PoppersActionTypes, REMOVE_POPPER, SHOW_POPPER } from './types';

export function addPopper(name: string): PoppersActionTypes {
  return {
    type: ADD_POPPER,
    name,
  };
}

export function removePopper(name: string): PoppersActionTypes {
  return {
    type: REMOVE_POPPER,
    name,
  };
}

export function showPopper(name: string): PoppersActionTypes {
  return {
    type: SHOW_POPPER,
    name,
  };
}

export function hidePopper(name: string): PoppersActionTypes {
  return {
    type: HIDE_POPPER,
    name,
  };
}

export function hideAllPoppers(): PoppersActionTypes {
  return {
    type: HIDE_ALL_POPPERS,
  };
}
