import { getType } from 'deox';
import { Middleware } from 'redux';

import { impersonationActions } from 'src/store/impersonation/actions';
import { persister } from 'src/utils/persister/persister';

import { sessionActions } from '../session/actions';
import { brandingActions } from './actions';

export const brandingPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(brandingActions.getBranding.success): {
      persister.set({ key: 'branding', item: store.getState().theme.branding.data });
      break;
    }
    case getType(brandingActions.getImpersonationBranding.success):
      persister.set({ key: 'impersonationBranding', item: store.getState().theme.impersonationBranding.data });
      break;
    case getType(impersonationActions.stopImpersonating): {
      persister.clear({ key: 'impersonationBranding' });
      break;
    }
    case getType(sessionActions.logout): {
      persister.clear({ key: 'branding' });
      persister.clear({ key: 'impersonationBranding' });
      break;
    }
  }
  return res;
};
