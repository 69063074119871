import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { devtoolsActions } from './actions';

export const devtoolsPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(devtoolsActions.toggleDevtools):
    case getType(devtoolsActions.toggleTranslationHighlights):
    case getType(devtoolsActions.toggleReactQueryDevtools):
      persister.set({ key: 'devtools', item: store.getState().devtools, isPerUser: true });
      break;
  }

  return res;
};
