/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Website, WebsiteDomain, WebsiteStatus } from 'src/orchd-client';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { MiddleTruncate } from 'src/components/MiddleTruncate';
import { StatusPill } from 'src/components/StatusPill';
import { Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { isPrimaryDomain } from 'src/utils/isPrimaryDomain/isPrimaryDomain';
import { getPillStatus, isWebsiteActive } from 'src/utils/website';

import { SelectableWebsite } from '../redux/types';
import { KindPillMap } from './KindPill';

export const AdditionalDomains = ({
  subdomains,
  aliases,
}: {
  subdomains: WebsiteDomain[];
  aliases: WebsiteDomain[];
}) => {
  return (
    <Box wrap="nowrap" mx="sm">
      {subdomains.length > 0 && (
        <Text transform="uppercase" size="xs" color="grey" whiteSpace="nowrap">
          {'+ '}
          {subdomains.length}&nbsp;
          {subdomains.length > 1 ? (
            <FormattedMessage {...messages.subdomains} />
          ) : (
            <FormattedMessage id="subdomain" defaultMessage="Subdomain" />
          )}
        </Text>
      )}
      {aliases.length > 0 && (
        <Text transform="uppercase" size="xs" color="grey" whiteSpace="nowrap">
          {subdomains.length > 0 && ', '}
          {'+ '}
          {aliases.length}&nbsp;
          {aliases.length > 1 ? (
            <FormattedMessage id="aliases" defaultMessage="Aliases" />
          ) : (
            <FormattedMessage {...messages.alias} />
          )}
        </Text>
      )}
    </Box>
  );
};

export const Domains = ({
  website,
  hideAdditionalDomains,
  highlightText = '',
  textSize = 'sm',
  showTooltip = true,
  enableRecalculateWidth = false,
}: {
  hideAdditionalDomains: boolean;
  website: SelectableWebsite | Website;
  highlightText: string;
  textSize?: 'sm' | 'md';
  showTooltip?: boolean;
  enableRecalculateWidth?: boolean;
}) => {
  const pillStatus = getPillStatus(website);

  return (
    <Box gap="xs">
      <MiddleTruncate
        showTooltip={showTooltip}
        text={website.domain.domain}
        highlightText={highlightText}
        size={textSize}
        color={isWebsiteActive(website) ? undefined : 'grey'}
        enableRecalculateWidth={enableRecalculateWidth}
        prefix={
          <KindPillMap
            showTooltip={showTooltip}
            domain={website.domain.domain}
            kind={website.kind}
            disabled={!isWebsiteActive(website)}
          />
        }
        suffix={
          <Box a="center" wrap="nowrap" gap="sm" pl="sm" overflow="hidden">
            {(!hideAdditionalDomains || website.status !== WebsiteStatus.active || website.suspendedBy) && (
              <Box a="center" wrap="nowrap" w="auto">
                {!hideAdditionalDomains && (
                  <AdditionalDomains subdomains={website.subdomains as WebsiteDomain[]} aliases={website.aliases} />
                )}

                <Box wrap="nowrap" minW="0">
                  <StatusPill status={pillStatus} />
                </Box>
              </Box>
            )}

            {(website as SelectableWebsite).isRestoring && (
              <Box a="center" wrap="nowrap" w="auto">
                <Icon name="loading" marginRight="sm" color="grey" />

                <Text color="grey" size="sm">
                  <FormattedMessage id="websites.restoring_backup" defaultMessage="Restoring backup..." />
                </Text>
              </Box>
            )}
          </Box>
        }
      />

      {!isPrimaryDomain(website.domain.kind) && (
        <Box a="center" wrap="nowrap" w="auto">
          <Text size="xs" color="grey" transform="uppercase">
            <FormattedMessage {...messages[website.domain.kind]} />
          </Text>
        </Box>
      )}
    </Box>
  );
};
