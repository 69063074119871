/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CloudFlareStatus, LoginMembership, Website } from 'src/orchd-client';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetDomainResolvesQuery } from 'src/api_services/domains/query';
import { Alert } from 'src/components/Alert';
import { Box } from 'src/components/Box';
import { Container } from 'src/components/Container';
import { Copy } from 'src/components/Copy/Copy';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { ReactComponent as CloudflareDesktop } from 'src/images/cloudflare_desktop.svg';
import { ReactComponent as CloudflareMobile } from 'src/images/cloudflare_mobile.svg';
import { AppState } from 'src/store';

type StateProps = ReturnType<typeof stateToProps>;
export interface Props extends StateProps {
  member: LoginMembership;
  nameServers: string[];
  website: Website;
}

export const _NonResolvedDomainsAlert = ({ website, nameServers, isTabletUp, member }: Props) => {
  const { websiteId } = useParams<{ websiteId: string }>();
  const { orgId } = member;
  const { domain } = website;

  const isCloudflare = [CloudFlareStatus.Connected, CloudFlareStatus.Error].includes(domain.cloudflareStatus);

  const { data: domainResolvesData, isFetching } = useGetDomainResolvesQuery({
    isCloudflare,
    domainId: domain.id,
    orgId,
    websiteId,
  });

  const iconOverride = isTabletUp ? (
    <Box mt="xs" mr="md" w="110px">
      <CloudflareDesktop />
    </Box>
  ) : (
    <Box mt="xs" h="30px" w="30px">
      <CloudflareMobile />
    </Box>
  );

  const usedNameServers =
    typeof domainResolvesData?.data === 'string' ? nameServers : domainResolvesData?.data.nameServers ?? [];

  const noNameServers = usedNameServers.length === 0;

  if (isFetching || domainResolvesData?.resolves || noNameServers) {
    return null;
  }

  return (
    <Container>
      <Alert
        iconOverride={isCloudflare ? iconOverride : undefined}
        variant="warning"
        marginBottom={{ xs: '2xl', md: 'xl' }}
        title={
          isCloudflare ? (
            <FormattedMessage
              id="websites.website_dashboard.cloudflare_instructions_title"
              defaultMessage="To successfully connect to Cloudflare"
            />
          ) : (
            <FormattedMessage
              id="websites.website_dashboard.dns_instructions_title"
              defaultMessage="To connect your domains(s)"
            />
          )
        }
        data-qa="domainsAlert"
      >
        {isCloudflare ? (
          <FormattedMessage
            id="websites.website_dashboard.cloudflare_instructions_body"
            defaultMessage="log in to your domain provider and update your nameservers to your assigned Cloudflare servers:"
          />
        ) : (
          <FormattedMessage
            id="websites.website_dashboard.dns_instructions_body"
            defaultMessage="log in to your domain provider and update your nameservers to:"
          />
        )}

        {usedNameServers?.map((domain) => (
          <Box key={domain} my="md">
            <Copy label={domain} value={domain} id={`dns-${domain}`} />
          </Box>
        ))}
      </Alert>
    </Container>
  );
};

export const stateToProps = (state: AppState) => ({
  isTabletUp: viewportSelectors.isTabletUp(state),
});

export const NonResolvedDomainsAlert = connect(stateToProps)(_NonResolvedDomainsAlert);
