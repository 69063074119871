import { ReactChild, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid';

import { ToastItem } from 'src/components/Toast/types';
import { toastActions } from 'src/store/toast';

export const useToast = () => {
  const dispatch = useDispatch();

  const create = ({
    id,
    message,
    variant,
    persist = false,
    dismissible = true,
  }: { id?: string } & Pick<ToastItem, 'message' | 'variant' | 'persist' | 'dismissible'>) => {
    const toastId = id ?? uuid();

    const toast = {
      id: toastId,
      message,
      variant,
      persist,
      dismissible,
    };

    dispatch(toastActions.customToast(toast));

    return toastId;
  };

  const dismiss = (toastId: string) => {
    dispatch(toastActions.dismissToast(toastId));
  };

  const dismissAll = () => {
    dispatch(toastActions.dismissAllToasts());
  };

  return useMemo(() => {
    return {
      create,
      autosave: ({ id, persist = true }: { id?: string; persist?: boolean }) => {
        const usedId = id ?? uuid();

        return {
          id: usedId,
          success: (text: ReactChild) => {
            dismiss(usedId);
            return create({ id: usedId, message: text, variant: 'success', persist });
          },
          error: (text: ReactChild) => {
            dismiss(usedId);
            return create({ id: usedId, message: text, variant: 'error', persist });
          },
          neutral: (text: ReactChild) => {
            dismiss(usedId);
            return create({ id: usedId, message: text, variant: 'neutral', persist });
          },
          warning: (text: ReactChild) => {
            dismiss(usedId);
            return create({ id: usedId, message: text, variant: 'warning', persist });
          },
          info: (text: ReactChild) => {
            dismiss(usedId);
            return create({ id: usedId, message: text, variant: 'info', persist });
          },
        };
      },
      dismiss,
      dismissAll,
      success: (message: ReactChild) => create({ message, variant: 'success' }),
      error: (message: ReactChild) => create({ message, variant: 'error' }),
      neutral: (message: ReactChild) => create({ message, variant: 'neutral' }),
      warning: (message: ReactChild) => create({ message, variant: 'warning' }),
      info: (message: ReactChild) => create({ message, variant: 'info' }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useAutosave = ({ id, persist = true }: { id?: string; persist?: boolean } = {}) => {
  const toast = useToast();

  return useMemo(() => toast.autosave({ id, persist }), [toast, id, persist]);
};
