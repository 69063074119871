import { css } from '@emotion/core';

import { getColorFromTheme } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { DefaultProps } from './Loading';

const container = (props: DefaultProps) => css`
  ${props.fullWidth &&
  css`
    width: 100%;
  `}

  display: flex;
  justify-content: center;
  align-items: center;

  ${props.absoluteCenter &&
  css`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  height: ${props.height};
`;

const loading = (props: DefaultProps) => (theme: Theme) =>
  css`
    display: inline-block;
    position: relative;
    width: ${theme.sizes[props.size]}px;
    height: ${theme.sizes[props.size]}px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      ${props.top &&
      css`
        top: ${theme.sizes[props.top]}px;
      `}
      ${props.bottom &&
      css`
        bottom: ${theme.sizes[props.bottom]}px;
      `}
    width: ${theme.sizes[props.size]}px;
      height: ${theme.sizes[props.size]}px;
      border: 3px solid #fff;
      border-radius: 50%;
      animation: lds-ring 0.7s ${theme.easings.standard} infinite;
      border-color: ${responsive(props.color, (color) => getColorFromTheme(color, theme))} transparent transparent
        transparent;
    }
    div:nth-of-type(1) {
      animation-delay: -0.15s;
    }
    div:nth-of-type(2) {
      animation-delay: -0.12s;
    }
    div:nth-of-type(3) {
      animation-delay: -0.07s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

const styles = { container, loading };
export default styles;
