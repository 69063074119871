import { LoginMembership } from 'src/orchd-client';
import { createReducer } from 'deox';

import { impersonationActions } from './actions';

export interface ImpersonationState {
  loading: boolean;
  error: string;
  returnUrl: string;
  masterOrgName: string;
  parentId: string;
  member?: LoginMembership;
  startImpersonationRouterKey?: string;
}

export const initialState: ImpersonationState = {
  loading: false,
  error: '',
  returnUrl: '',
  masterOrgName: '',
  parentId: '',
  member: undefined,
  startImpersonationRouterKey: undefined, // random key generated for each new route navigation
};

export const impersonationReducer = createReducer(initialState, (handleAction) => [
  handleAction(impersonationActions.startImpersonating, (state, { payload }) => ({
    ...state,
    loading: true,
    error: '',
    returnUrl: state.returnUrl || payload.returnUrl,
    startImpersonationRouterKey: payload.routerKey,
  })),
  // a new LoginMembership is built inside the saga and passed into here
  handleAction(impersonationActions.startImpersonatingSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    error: '',
    parentId: payload.parentId || '',
    member: payload.impersonation,
    masterOrgName: payload.masterOrgName || '',
  })),
  handleAction(impersonationActions.startImpersonatingError, (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.error,
    member: undefined,
    returnUrl: '',
    masterOrgName: '',
  })),

  handleAction(impersonationActions.stopImpersonating, (state) => ({
    ...state,
    loading: false,
    error: '',
    member: undefined,
    parentId: '',
    returnUrl: '',
    masterOrgName: '',
  })),
]);
