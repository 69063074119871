import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import {
  createWebsiteEmail,
  deleteWebsiteEmail,
  getDomainEmailAuth,
  getWebsiteEmail,
  updateWebsiteEmail,
} from './actions';

export const websiteEmailReducer = createRequestStateReducer(getWebsiteEmail);
export const createWebsiteEmailReducer = createRequestStateReducer(createWebsiteEmail);
export const updateWebsiteEmailReducer = createRequestStateReducer(updateWebsiteEmail);
export const deleteWebsiteEmailReducer = createRequestStateReducer(deleteWebsiteEmail);

export const domainEmailAuthReducer = createRequestStateReducer(getDomainEmailAuth);

export const websiteEmailsPageReducer = combineReducers({
  websiteEmail: websiteEmailReducer,
  createWebsiteEmail: createWebsiteEmailReducer,
  updateWebsiteEmail: updateWebsiteEmailReducer,
  deleteWebsiteEmail: deleteWebsiteEmailReducer,
  domainEmailAuth: domainEmailAuthReducer,
});
