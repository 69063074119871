import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { boxShadowGrey } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const container = css`
  * {
    [type='submit'] {
      opacity: 0.4 !important;

      &,
      & * {
        cursor: not-allowed !important;
      }

      &:active,
      &:focus {
        pointer-events: none !important;
      }
    }
  }

  &[data-demo-disabled] {
    &[type='button'],
    option,
    button,
    [role='switch'],
    [role='radio'],
    [role='checkbox'],
    [role='menu'] > *,
    &[role='menu'] > *,
    [role='button'] {
      opacity: 0.4 !important;

      &,
      & * {
        cursor: not-allowed !important;
        pointer-events: none !important;
      }

      &:active,
      &:focus {
        pointer-events: none !important;
      }
    }

    input {
      opacity: 0.4 !important;
      pointer-events: none !important;
    }
  }

  &[data-demo-enabled] {
    &[type='button'],
    option,
    button,
    [role='switch'],
    [role='radio'],
    [role='checkbox'],
    [role='menu'] > *,
    &[role='menu'] > *,
    [role='button'] {
      opacity: 1 !important;

      &,
      & * {
        cursor: pointer !important;
        pointer-events: auto !important;
      }

      &:active,
      &:focus {
        pointer-events: auto !important;
      }
    }

    input {
      opacity: 1 !important;
      pointer-events: auto !important;
    }
  }
`;

const demoToast = (theme: Theme) => css`
  position: fixed;
  padding: 8px;
  z-index: ${zIndex.DemoModeToast};
  width: 400px;
  left: calc(50% - 200px);
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  background-color: ${theme.colors.light};
  opacity: 0.9;

  @media screen and (max-width: ${breakpoints.sm}px) {
    top: 80px;
    left: 16px;
    width: calc(100% - 32px);
  }

  ${boxShadowGrey(2)}
`;

export const style = { container, demoToast };
