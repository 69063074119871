import { messages } from 'src/messages';
import { store } from 'src/store';
import { alertModalActions } from 'src/store/alert-modal';
import { intl } from 'src/utils/intl/intl';

export const handleBackupInProgress = async () => {
  const { formatMessage } = await intl();

  // Timeout required to allow existing alert modals to close
  setTimeout(() => {
    store.dispatch(
      alertModalActions.showAlertModal({
        variant: 'info',
        titleText: formatMessage({
          id: 'backup_in_progress.title',
          defaultMessage: 'A backup/restore is in progress',
        }),
        bodyText: formatMessage({
          id: 'backup_in_progress.blurb',
          defaultMessage: 'It is not possible to perform this action/access this tool during a backup/restore.',
        }),
        primaryButton: {
          text: formatMessage(messages.ok_got_it),
          onClick: ({ hide }) => hide(),
        },
        hideCloseButton: true,
      })
    );
  }, 500);
};
