import { ServerRole } from 'src/orchd-client';
import { createActionCreator } from 'deox';

import { serversApi } from 'src/api_services/servers/service';
import { SERVERS_NAMESPACE } from 'src/pages/servers/constants';
import { createActionsFromMethod } from 'src/store/helpers';

/**
 * todo - move code/refactor to src/store/servers
 */

export const updateServerRole = createActionsFromMethod<typeof serversApi.updateServerRole>(
  `updateServerRole(${SERVERS_NAMESPACE})`
);

export const addServerRole = createActionsFromMethod<typeof serversApi.installServerRole>(
  `addServerRole(${SERVERS_NAMESPACE})`
);

export const removeServerRole = createActionsFromMethod<typeof serversApi.uninstallServerRole>(
  `removeServerRole(${SERVERS_NAMESPACE})`
);

export const setRolesBeingAdded = createActionCreator(
  `setRolesBeingAdded(${SERVERS_NAMESPACE})`,
  (resolve) => (payload: { serverId: string; roles: Partial<Record<ServerRole, boolean>> }) => resolve(payload)
);

export const setRolesBeingRemoved = createActionCreator(
  `setRolesBeingRemoved(${SERVERS_NAMESPACE})`,
  (resolve) => (payload: { serverId: string; roles: Partial<Record<ServerRole, boolean>> }) => resolve(payload)
);
