/** @jsxRuntime classic */
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';

import defaultTheme, { GenericTheme, Theme } from 'src/design-system/theme';
import { getUrlForFont } from 'src/pages/settings/BrandingSettings/FontPicker';
import { zIndex } from 'src/utils/zIndex';

import notoSansBold from '../../fonts/notosans-bold-webfont.woff';
import notoSansBold2 from '../../fonts/notosans-bold-webfont.woff2';
import notoSansItalic from '../../fonts/notosans-italic-webfont.woff';
import notoSansItalic2 from '../../fonts/notosans-italic-webfont.woff2';
import notoSansLight from '../../fonts/notosans-light-webfont.woff';
import notoSansLight2 from '../../fonts/notosans-light-webfont.woff2';
import notoSansRegular from '../../fonts/notosans-regular-webfont.woff';
import notoSansRegular2 from '../../fonts/notosans-regular-webfont.woff2';
import notoSansSemibold from '../../fonts/notosans-semibold-webfont.woff';
import notoSansSemibold2 from '../../fonts/notosans-semibold-webfont.woff2';
import notoSansThin from '../../fonts/notosans-thin-webfont.woff';
import notoSansThin2 from '../../fonts/notosans-thin-webfont.woff2';
import normalize from './normalize';

import 'flag-icons/css/flag-icons.min.css';

// custom global styles
const custom = (theme: GenericTheme) => css`
  html {
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
    /* for some reason having this uncommented makes popper js break for scrolling */
    /* overflow-y: scroll; */
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    /* not having "height: auto;" makes document.body.offsetHeight incorrect which is used for scrolling */
    height: auto;
    min-height: 100%;
    display: flex;
    background: ${theme.colors.grey5};
  }

  body.no-scroll {
    overflow-y: hidden !important;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline-offset: -1px;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  ${theme.fontFamily !== defaultTheme.fontFamily &&
  `@import url(${getUrlForFont({ fontFamily: theme.fontFamily, weights: [100, 300, 400, 500, 700, 900] })});`}

  @font-face {
    font-family: 'Noto Sans';
    src: url(${notoSansThin2}) format('woff2'), url(${notoSansThin}) format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url(${notoSansLight2}) format('woff2'), url(${notoSansLight}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url(${notoSansRegular2}) format('woff2'), url(${notoSansRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url(${notoSansItalic2}) format('woff2'), url(${notoSansItalic}) format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url(${notoSansSemibold2}) format('woff2'), url(${notoSansSemibold}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Noto Sans';
    src: url(${notoSansBold2}) format('woff2'), url(${notoSansBold}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  button {
    background: transparent;
    border: none;
  }

  #react-charts-portal {
    z-index: ${zIndex.ChartOverlay} !important;
  }
`;

const GlobalStyles = () => <Global styles={(theme: Theme) => [normalize, custom(theme)]} />;

export default GlobalStyles;
