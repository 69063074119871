import { UpdateCrontabValueCmd, WebsitesApiCreateWebsiteReq } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { getDurationAsMilliseconds } from 'src/utils/dateTimes/dateTimes';

import { queryKeys } from '../query-keys';
import { websitesApi } from './service';

export const useGetWebsiteQuery = ({
  orgId,
  websiteId,
  enabled = true,
}: {
  orgId: string;
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.website({ orgId, websiteId }),
    queryFn: () => websitesApi.getWebsite({ orgId, websiteId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useGetWebsitesQuery = ({
  enabled = true,
  ...params
}: Parameters<typeof websitesApi.getWebsites>[0] & { enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.websites(params),
    queryFn: () => websitesApi.getWebsites(params),
    select: ({ data }) => data,
    refetchOnMount: true,
    keepPreviousData: true,
    enabled,
  });

export const useGetWebsitesTableQuery = ({
  enabled = true,
  ...params
}: Parameters<typeof websitesApi.getWebsites>[0] & { enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.websites(params),
    queryFn: () => websitesApi.getWebsites(params),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useGetWebsiteCgroupLimitsQuery = ({
  orgId,
  websiteId,
}: Parameters<typeof websitesApi.getWebsiteCgroupLimits>[0]) =>
  useQuery({
    queryKey: queryKeys.websiteCgroupLimits({ orgId, websiteId }),
    queryFn: () => websitesApi.getWebsiteCgroupLimits({ orgId, websiteId }),
    select: (data) => data.data,
    refetchOnMount: true,
  });

export const useSetWebsiteCgroupLimitsMutation = () => useMutation({ mutationFn: websitesApi.setWebsiteCgroupLimits });

export const useGetUserCrontabQuery = ({
  refetchOnMount,
  ...params
}: {
  websiteId: string;
  orgId: string;
  refetchOnMount?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.userCrontab(params),
    queryFn: () => websitesApi.getUserCrontab(params),
    select: (resp) => resp.data.items as UpdateCrontabValueCmd[],
    refetchOnMount,
  });
};

export const useGetWebsiteDomainMappingDnsStatusQuery = ({
  enabled,
  ...params
}: {
  enabled: boolean;
  websiteId: string;
  orgId: string;
  domainId: string;
}) => {
  return useQuery({
    queryKey: queryKeys.websiteDomainMappingDnsStatus(params),
    queryFn: () => websitesApi.getWebsiteDomainMappingDnsStatus(params),
    select: (resp) => resp.data,
    refetchOnMount: true,
    enabled,
  });
};

export const useUpdateUserCrontabMutation = () => useMutation({ mutationFn: websitesApi.updateUserCrontab });

export const useGetWebsiteFsQuotaLimitsQuery = ({
  orgId,
  websiteId,
}: Parameters<typeof websitesApi.getWebsiteFsQuotaLimits>[0]) =>
  useQuery({
    queryKey: queryKeys.websiteFsQuotaLimits({ orgId, websiteId }),
    queryFn: () => websitesApi.getWebsiteFsQuotaLimits({ orgId, websiteId }),
    select: (data) => data.data.totalAvailable,
    refetchOnMount: true,
  });

export const useSetWebsiteFsQuotaLimitsMutation = () =>
  useMutation({ mutationFn: websitesApi.setWebsiteFsQuotaLimits });

export const useGetWebsiteWebserverKindQuery = (params: { websiteId: string }) => {
  return useQuery({
    queryKey: queryKeys.websiteWebserverKind(params),
    queryFn: () => websitesApi.getWebsiteWebserverKind(params),
    select: (data) => data.data,
    refetchOnMount: true,
    enabled: !!params.websiteId,
  });
};

export const useGetDomainNginxFastCgiQuery = (params: { domainId: string }) => {
  return useQuery({
    queryKey: queryKeys.nginxFastCgi(params),
    queryFn: () => websitesApi.getDomainNginxFastCgi(params),
    select: (data) => data.data,
    refetchOnMount: true,
  });
};

export const useGetDomainNginxFastCgiExcludedPathsQuery = (params: { domainId: string }) => {
  return useQuery({
    queryKey: queryKeys.nginxFastCgiExcludedPaths(params),
    queryFn: () => websitesApi.getDomainNginxFastCgiExcludedPaths(params),
    select: (data) => data.data,
    refetchOnMount: true,
  });
};

export const useGetDomainNginxWebserverRewritesQuery = (params: { domainId: string }) => {
  return useQuery({
    queryKey: queryKeys.nginxWebserverRewrites(params),
    queryFn: () => websitesApi.getDomainWebserverRewrites(params),
    select: (data) => data.data,
    refetchOnMount: true,
  });
};

export const useSetDomainNginxFastCgiMutation = () => useMutation({ mutationFn: websitesApi.setDomainNginxFastCgi });

export const useClearDomainNginxFastCgiMutation = () =>
  useMutation({ mutationFn: websitesApi.clearDomainNginxFastCgi });

export const useAddDomainNginxFastCgiExcludedPathMutation = () =>
  useMutation({ mutationFn: websitesApi.addDomainNginxFastCgiExcludedPath });

export const useDeleteDomainNginxFastCgiExcludedPathMutation = () =>
  useMutation({ mutationFn: websitesApi.deleteDomainNginxFastCgiExcludedPath });

export const useSetDomainNginxWebserverRewriteMutation = () =>
  useMutation({ mutationFn: websitesApi.setDomainWebserverRewrite });

export const useDeleteDomainNginxWebserverRewriteMutation = () =>
  useMutation({ mutationFn: websitesApi.deleteDomainWebserverRewrite });

export const useUpdateWebsiteMutation = () => useMutation({ mutationFn: websitesApi.updateWebsite });

export const useGetWebsiteIonCubeStatusQuery = (params: { websiteId: string }) => {
  return useQuery({
    queryKey: queryKeys.ionCubeStatus(params),
    queryFn: () => websitesApi.getWebsiteIoncubeStatus(params),
    select: (data) => data.data,
    refetchOnMount: true,
  });
};

export const useSetWebsiteIonCubeStatusMutation = () =>
  useMutation({ mutationFn: websitesApi.setWebsiteIoncubeStatus });

export const useGetWebsiteRedisStateQuery = (params: { websiteId: string }) => {
  return useQuery({
    queryKey: queryKeys.redisState(params),
    queryFn: () => websitesApi.getWebsiteRedisState(params),
    select: (data) => data.data,
    refetchOnMount: true,
  });
};

export const useSetWebsiteRedisStateMutation = () => useMutation({ mutationFn: websitesApi.setWebsiteRedisState });

export const useRestartWebsitePhpMutation = () => useMutation({ mutationFn: websitesApi.restartWebsitePhp });

export const useCreateWebsiteMutation = () =>
  useMutation({
    mutationFn: (params: WebsitesApiCreateWebsiteReq) =>
      websitesApi.createWebsite(params, { timeout: getDurationAsMilliseconds(30, 'minutes') }),
  });

export const useGetWebsiteHtaccessRewritesQuery = ({
  orgId,
  websiteId,
  enabled = true,
}: {
  orgId: string;
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.websiteHtaccessRewrites({ orgId, websiteId }),
    queryFn: () => websitesApi.getWebsiteHtaccessRewrites({ orgId, websiteId }),
    select: ({ data }) => data.items,
    refetchOnMount: true,
    enabled,
  });

export const useUpdateWebsiteHtaccessRewritesMutation = () =>
  useMutation({ mutationFn: websitesApi.updateWebsiteHtaccessRewrites });

export const useGetWebsiteAvailablePhpExtensionsQuery = ({
  websiteId,
  enabled = true,
}: {
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.websiteAvailablePhpExtensions({ websiteId }),
    queryFn: () => websitesApi.getWebsiteAvailablePhpExtensions({ websiteId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useGetWebsiteEnabledPhpExtensionsQuery = ({
  websiteId,
  enabled = true,
}: {
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.websiteEnabledPhpExtensions({ websiteId }),
    queryFn: () => websitesApi.getWebsiteEnabledPhpExtensions({ websiteId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useEnableWebsitePhpExtensionMutation = () =>
  useMutation({ mutationFn: websitesApi.enableWebsitePhpExtension });

export const useDisableWebsitePhpExtensionMutation = () =>
  useMutation({ mutationFn: websitesApi.disableWebsitePhpExtension });

export const useGetWebsiteCloneQuery = ({
  orgId,
  cloneId,
  enabled = true,
  refetchInterval,
}: {
  orgId: string;
  cloneId: string;
  enabled?: boolean;
  refetchInterval?: number | false | ((data?: any) => number | false);
}) =>
  useQuery({
    queryKey: queryKeys.websiteClone({ orgId, cloneId }),
    queryFn: () => websitesApi.getWebsiteClone({ orgId, cloneId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
    refetchInterval,
  });

export const useGetWebsiteCloneLogQuery = ({
  orgId,
  cloneId,
  enabled = true,
  refetchInterval,
}: {
  orgId: string;
  cloneId: string;
  enabled?: boolean;
  refetchInterval?: number | false;
}) =>
  useQuery({
    queryKey: queryKeys.websiteCloneLog({ orgId, cloneId }),
    queryFn: () => websitesApi.getWebsiteCloneLog({ orgId, cloneId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
    refetchInterval,
  });

export const useCloneWebsiteMutation = () => useMutation({ mutationFn: websitesApi.cloneWebsite });

export const useDeleteWebsiteMutation = () => useMutation({ mutationFn: websitesApi.deleteWebsite });

// ! do not use the following as they are already implemented in domains/query.ts
// getWebsiteDomainMapping
// getWebsiteDomainMappings
