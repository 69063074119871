import { UiPreferences, UiPreferencesViewKind } from 'src/orchd-client';
import { useQueryClient } from '@tanstack/react-query';

import {
  useGetUiPreferencesQuery,
  useSetUiPreferencesMutation,
  useUpdateUiPreferencesMutation,
} from 'src/api_services/logins/query';
import { queryKeys } from 'src/api_services/query-keys';
import { useEagerUpdate } from 'src/utils/eagerUpdate/useEagerUpdate';

const defaultValues = {
  loginId: '',
  fileManagerView: UiPreferencesViewKind.grid,
  websiteView: UiPreferencesViewKind.list,
  spineOpen: true,
  fileManagerEditorFullscreen: false,
  serverGroups: [],
} satisfies UiPreferences;

export const usePreferences = () => {
  const queryClient = useQueryClient();

  const { data, isError } = useGetUiPreferencesQuery();

  const { update, revert } = useEagerUpdate(queryKeys.uiPreferences());

  const { mutateAsync: updateUiPreferences } = useUpdateUiPreferencesMutation();
  const { mutateAsync: setUiPreferences } = useSetUiPreferencesMutation();

  const setPreference = async <T extends keyof UiPreferences>(preference: T, value: UiPreferences[T]) => {
    try {
      await update({ ...data, [preference]: value });

      isError
        ? await setUiPreferences({ newUiPreferences: { ...defaultValues, [preference]: value } })
        : await updateUiPreferences({ updateUiPreferences: { [preference]: value } });

      queryClient.invalidateQueries(queryKeys.uiPreferences());
    } catch {
      await revert();
    }
  };

  return { setPreference, preferences: data ?? defaultValues };
};
