import { FormEvent, ReactNode, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { Tooltip } from 'src/components/Tooltip';
import { Colors, FontColors, FontSizes, FontWeights, Margins, Responsive, Sizes } from 'src/design-system/style-types';

import { style } from './Checkbox.style';
import { CheckboxChecked, CheckboxOnChange } from './types';

export interface Props {
  name: string;
  label?: ReactNode;
  fontColor?: FontColors;
  fontSize?: Responsive<FontSizes>;
  fontWeight?: FontWeights;
  borderColor?: FontColors;
  borderCheckedColor?: FontColors;
  fontDisabledColor?: FontColors;
  inputBackgroundCheckedColor?: Colors;
  inputBorderDisabledColor?: FontColors;
  inputBackgroundDisabledColor?: Colors;
  inputCheckedColor?: FontColors;
  onChange?: CheckboxOnChange;
  className?: string;
  checked?: CheckboxChecked;
  disabled?: boolean;
  size?: Responsive<Sizes>;
  space?: Responsive<Margins>;
  spaceY?: Responsive<Margins>;
  marginBottom?: Responsive<Margins>;
  marginTop?: Responsive<Margins>;
  marginLeft?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  fontCheckedColor?: FontColors;
  labelLocation?: Responsive<'right' | 'bottom'>;
  fullWidth?: boolean;
  preventWrap?: boolean;
  highlightText?: string;
  infoTooltip?: string | ReactNode;
}

export const Checkbox = ({
  name,
  onChange = () => {},
  label = '',
  className = '',
  checked = false,
  fontColor = 'dark',
  fontSize = 'md',
  fontWeight = 'normal',
  borderColor = 'dark',
  borderCheckedColor = 'dark',
  fontDisabledColor = 'disabled',
  inputBackgroundCheckedColor = 'dark',
  inputBorderDisabledColor = 'disabled',
  inputBackgroundDisabledColor = 'grey40',
  inputCheckedColor = 'light',
  disabled = false,
  size = 'lg',
  space = 'sm',
  spaceY = 'md',
  marginBottom = '0',
  marginLeft = '0',
  marginRight = '0',
  marginTop = '0',
  labelLocation = 'right',
  fullWidth = true,
  preventWrap = false,
  highlightText = '',
  infoTooltip = '',
  fontCheckedColor,
}: Props) => {
  const handleOnChange = (e: FormEvent<HTMLInputElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    onChange({ name, checked: !checked, ...e });
  };

  const color = useMemo(() => {
    let color = checked && fontCheckedColor ? fontCheckedColor : fontColor;

    return disabled ? fontDisabledColor : color;
  }, [checked, fontCheckedColor, fontColor, disabled, fontDisabledColor]);

  return (
    <div css={style.container({ fullWidth, marginBottom, marginLeft, marginRight, marginTop })} className={className}>
      <input
        css={style.input}
        onClick={handleOnChange}
        id={name}
        name={name}
        disabled={disabled}
        type="checkbox"
        checked={!!checked}
      />

      <label
        role="checkbox"
        aria-checked={checked}
        aria-disabled={disabled}
        aria-label={name}
        tabIndex={0}
        htmlFor={name}
        css={style.label({ disabled, labelLocation, fullWidth, label })}
      >
        <span
          css={style.customInput({
            borderColor,
            labelLocation,
            spaceY,
            space,
            checked,
            disabled,
            size,
            inputBackgroundCheckedColor,
            inputBackgroundDisabledColor,
            inputBorderDisabledColor,
            borderCheckedColor,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          {checked === true && <Icon name="check" color={inputCheckedColor} size={size === 'lg' ? 'md' : 'sm'} />}
          {checked === 'mixed' && <Icon name="minus" color={inputCheckedColor} size={size === 'lg' ? 'md' : 'sm'} />}
        </span>

        <Box a="center">
          {label && (
            <Text
              size={fontSize}
              weight={fontWeight}
              color={color}
              ellipsis={preventWrap}
              highlightText={highlightText}
              whiteSpace="pre-line"
            >
              {label}
            </Text>
          )}
          {infoTooltip && (
            <Tooltip placement="top" button={<Icon name="info" marginLeft="sm" />}>
              {infoTooltip}
            </Tooltip>
          )}
        </Box>
      </label>
    </div>
  );
};
