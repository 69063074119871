import { Branding } from 'src/orchd-client';
import { getType } from 'deox';
import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';
import { impersonationActions } from 'src/store/impersonation/actions';

import { brandingActions } from './actions';

export const brandingReducer = createRequestStateReducer(brandingActions.getBranding);

export const impersonationBrandingReducer = createRequestStateReducer(brandingActions.getImpersonationBranding);

export type ImpersonationBrandingState = Branding;
export type BrandingState = Branding;

export const impersonationBrandingReducerWithReset: typeof impersonationBrandingReducer = (state, action) => {
  switch (action.type) {
    // actions which should reset the impersonation branding
    case getType(impersonationActions.stopImpersonating):
      return impersonationBrandingReducer(undefined, action);
  }
  return impersonationBrandingReducer(state, action);
};

export const resetBrandingReducer = createRequestStateReducer(brandingActions.resetBranding);

export const updateBrandingReducer = createRequestStateReducer(brandingActions.updateBranding);

export const updateImpersonationBrandingReducer = createRequestStateReducer(
  brandingActions.updateImpersonationBranding
);

export const themeReducer = combineReducers({
  // branding is the main branding config, fetched when the app is loaded and/or a user logs in
  branding: brandingReducer,
  // a separate state slice for the branding config fetched when entering impersonation mode. This allows an instantaneous transition back to the original branding (i.e. without refetching the initial user's config)
  impersonationBranding: impersonationBrandingReducerWithReset,
  // reducers to keep track of changes performed to branding
  resetBranding: resetBrandingReducer,
  updateBranding: updateBrandingReducer,
  updateImpersonationBranding: updateImpersonationBrandingReducer,
});
