import { PlanType, ResourceName } from 'src/orchd-client';

export enum MailBoxType {
  dynamic = 'dynamic',
  fixed = 'fixed',
}

// export enum WordPressAutoUpdates {
//   allowAutoUpdates = 'allowAutoUpdates',
//   disableAutoUpdates = 'disableAutoUpdates',
//   forceAutoUpdates = 'forceAutoUpdates',
// }

export type PackageFieldItem = string | number | boolean | undefined | PlanType | MailBoxType;

export interface PackageForms {
  [key: string]: // allows indexing like a record
  | PackageDetailsFields
    | PackageWebsiteResourcesFields
    | PackageSystemResourcesFields
    | PackageApplicationsFields
    | PackageEmailsFields
    | PackageDomainsFields
    | PackageBackupsFields
    | PackageFeaturesFields
    | PackageResellingFields;

  details: PackageDetailsFields;
  websiteResources: PackageWebsiteResourcesFields;
  systemResources: PackageSystemResourcesFields;
  applications: PackageApplicationsFields;
  emails: PackageEmailsFields;
  domains: PackageDomainsFields;
  backups: PackageBackupsFields;
  features: PackageFeaturesFields;
  reselling: PackageResellingFields;
}

export interface PackageFormsSubmitValues {
  [key: string]: // allows indexing like a record
  | PackageDetailsFields
    | PackageWebsiteResourcesFields
    | PackageSystemResourcesFields
    | PackageApplicationsFields
    | PackageEmailsFields
    | PackageDomainsFields
    | PackageBackupsFields
    | PackageFeaturesFields
    | PackageResellingFields
    | undefined;
  details: PackageDetailsFields;
  websiteResources?: PackageWebsiteResourcesFields;
  systemResources?: PackageSystemResourcesFields;
  applications?: PackageApplicationsFields;
  emails?: PackageEmailsFields;
  domains?: PackageDomainsFields;
  backups?: PackageBackupsFields;
  features: PackageFeaturesFields;
  reselling?: PackageResellingFields;
}

export interface PackageDetailsFields {
  [key: string]: string | PlanType | undefined | string[] | boolean;
  name: string;
  planType: PlanType;
  serverGroupIds?: string[];
  allowServerGroupSelection?: boolean;
}

export interface PackageWebsiteResourcesFields {
  [key: string]: number;
  websites: number;
  stagingWebsites: number;
  diskspace: number;
  pageViews: number;
  transfer: number;
  mysqlDbs: number;
  ftpUsers: number;
  processes: number;
}

export interface PackageSystemResourcesFields {
  [key: string]: number;
  memoryLimit: number;
  virtualCpus: number;
  iops: number;
  ioBandwidth: number;
  nproc: number;
  hardDisk: number;
}

export interface PackageApplicationsFields {
  [key: string]: string | boolean;
  applicationsSoftwareInstaller: boolean;
  applicationsPreinstalledApp: string;
  applicationsPreinstalledPlugins: string;
  preinstallWordpressTheme: string;
  wptoolkit: boolean;
  wptoolkitShield: boolean;
  wptoolkitLoginObfuscator: boolean;
  wptoolkitRestrictedAccess: boolean;
  wptoolkitAutoUpdatesThemes: boolean;
  // wptoolkitAutoUpdatesPlugins: string;
  wptoolkitThemeManager: boolean;
  wptoolkitPluginManager: boolean;
  wptoolkitUserManager: boolean;
}

export interface PackageEmailsFields {
  [key: string]: number | string | MailBoxType;
  mailboxes: number;
  mailboxType: MailBoxType;
  mailboxSize: number;
  forwarders: number;
}

export interface PackageDomainsFields {
  [key: string]: number | boolean;
  domainAliases: number;
  featureDNSEditor: boolean;
  featureDNSAutoConfigure: boolean;
}

export interface PackageBackupsFields {
  [key: string]: string | boolean;
  backupsAllowAutomatic: boolean;
  backupsAllowManual: boolean;
  backupsAllowRemote: boolean;
  backupsAllowSelfRestore: boolean;
}

export interface PackageFeaturesFields {
  [key: string]: boolean | string | string[];
  featureCronjobs: boolean;
  featureSelfInstallSSL: boolean;
  featureLetsEncrypt: boolean;
  featureSSH: boolean;
  featureWebsiteClone: boolean;
  featureCPanelMigrate: boolean;
  featureWebsiteCaching: boolean;
  featurePhpEditor: boolean;
  allPhpVersions: boolean;
  allowedPhpVersions: string[];
  defaultPhpVersion: string;
  redisAllowed: boolean;
}

export interface PackageResellingFields {
  [key: string]: number | boolean;
  allowReselling: boolean;
  customers: number;
  resellerSubResellers: boolean;
  resellerOverselling: boolean;
  resellerBranding: boolean;
}

export interface BasePackageValues {
  allowances: Record<PackageAllowanceName, boolean>;
  resources: Record<ResourceName, number>;
  selections: Record<PackageSelectionName, string>;
  cgroupLimits: Record<PackageCgroupLimitName, number>;
  quotas: Record<PackageQuotaName, number>;
  allPhpVersions: boolean;
  allowedPhpVersions: string[];
  defaultPhpVersion: string;
  redisAllowed: boolean;
  preinstallWordpressTheme: string;
}

export enum PackageAllowanceName {
  allowReselling = 'allowReselling',
  backupsAllowAutomatic = 'backupsAllowAutomatic',
  backupsAllowManual = 'backupsAllowManual',
  backupsAllowSelfRestore = 'backupsAllowSelfRestore',
  backupsAllowRemote = 'backupsAllowRemote',
  featureCronjobs = 'featureCronjobs',
  featureSelfInstallSSL = 'featureSelfInstallSSL',
  featureLetsEncrypt = 'featureLetsEncrypt',
  featureSSH = 'featureSSH',
  featureDNSEditor = 'featureDNSEditor',
  featureDNSAutoConfigure = 'featureDNSAutoConfigure',
  featurePhpEditor = 'featurePhpEditor',
  featureWebsiteClone = 'featureWebsiteClone',
  featureCPanelMigrate = 'featureCPanelMigrate',
  featureWebsiteCaching = 'featureWebsiteCaching',
  resellerOverselling = 'resellerOverselling',
  resellerSubResellers = 'resellerSubResellers',
  resellerBranding = 'resellerBranding',
  applicationsSoftwareInstaller = 'applicationsSoftwareInstaller',
  wptoolkitShield = 'wptoolkitShield',
  wptoolkitLoginObfuscator = 'wptoolkitLoginObfuscator',
  wptoolkitRestrictedAccess = 'wptoolkitRestrictedAccess',
  wptoolkitThemeManager = 'wptoolkitThemeManager',
  wptoolkitPluginManager = 'wptoolkitPluginManager',
  wptoolkitUserManager = 'wptoolkitUserManager',
  wptoolkit = 'wptoolkit',
  wptoolkitAutoUpdatesThemes = 'wptoolkitAutoUpdatesThemes',
}

export enum PackageSelectionName {
  applicationsPreinstalledApp = 'applicationsPreinstalledApp',
  applicationsPreinstalledPlugins = 'applicationsPreinstalledPlugins',
  mailboxType = 'mailboxType',
  mailboxSize = 'mailboxSize',
}

export enum PackageCgroupLimitName {
  memoryLimit = 'memoryLimit',
  virtualCpus = 'virtualCpus',
  iops = 'iops',
  ioBandwidth = 'ioBandwidth',
  nproc = 'nproc',
}

export enum PackageQuotaName {
  hardDisk = 'hardDisk',
}
