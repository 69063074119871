import React, { useEffect } from 'react';
import { WebsiteCloneEnumStatus } from 'src/orchd-client';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { queryKeys } from 'src/api_services/query-keys';
import { useGetWebsiteCloneQuery, useGetWebsiteQuery } from 'src/api_services/websites/query';
import { Span, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { sessionSelectors } from 'src/store/session/selectors';
import { useAutosave } from 'src/utils/toast/useToast';

import { cloneAtom } from './atoms';

type StateProps = ReturnType<typeof stateToProps>;
type Props = StateProps;

const stateToProps = (state: AppState) => ({
  member: sessionSelectors.getMember(state),
});

export const _CloneToast = ({ member }: Props) => {
  const { formatMessage } = useIntl();
  const { orgId } = member;
  const [{ cloneId, isPushLive }, setCloneAtom] = useAtom(cloneAtom);
  const autosave = useAutosave({ id: `clone-toast-${cloneId}` });
  const queryClient = useQueryClient();

  const { data: clone } = useGetWebsiteCloneQuery({
    orgId,
    cloneId: cloneId ?? '',
    enabled: !!cloneId,
    refetchInterval: (data) => (data?.status === WebsiteCloneEnumStatus.inProgress ? 4000 : false),
  });

  const { data: sourceWebsite } = useGetWebsiteQuery({
    orgId,
    websiteId: clone?.sourceWebsiteId ?? '',
    enabled: !!clone?.sourceWebsiteId,
  });

  const { data: destinationWebsite } = useGetWebsiteQuery({
    orgId,
    websiteId: clone?.destWebsiteId ?? '',
    enabled: !!clone?.destWebsiteId,
  });

  useEffect(() => {
    if (!cloneId || !clone || !sourceWebsite) {
      return;
    }

    if (clone.status === WebsiteCloneEnumStatus.inProgress) {
      return void autosave.neutral(
        <Text color="light" size="sm">
          <FormattedMessage
            id="websites.clone_toast.neutral"
            defaultMessage="{action} {source} to {destination}"
            values={{
              action: isPushLive ? formatMessage(messages.pushing) : formatMessage(messages.cloning),
              source: sourceWebsite.domain.domain,
              destination:
                destinationWebsite?.domain.domain ??
                formatMessage({ id: 'websites.clone_toast.new_website', defaultMessage: 'a new website' }),
            }}
          />
          &nbsp;&middot;&nbsp;
          <Link to={`/websites/clone/${cloneId}`}>
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_progress} />
            </Span>
          </Link>
        </Text>
      );
    }

    setCloneAtom({ cloneId: undefined, isPushLive: undefined });

    if (clone.status === WebsiteCloneEnumStatus.complete) {
      queryClient.invalidateQueries({ queryKey: queryKeys.getSubscriptions({ orgId }) });

      return void autosave.success(
        <Text color="light" size="sm">
          {isPushLive ? (
            <FormattedMessage id="websites.clone_toast.push_live.success" defaultMessage="Website pushed live" />
          ) : (
            <FormattedMessage id="websites.clone_toast.success" defaultMessage="Website cloned" />
          )}
          &nbsp;&middot;&nbsp;
          <Link to={`/websites/clone/${cloneId}`}>
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_status} />
            </Span>
          </Link>
        </Text>
      );
    }

    autosave.error(
      <Text color="light" size="sm">
        {isPushLive ? (
          <FormattedMessage id="websites.clone_toast.push_live.error" defaultMessage="Failed to push website live" />
        ) : (
          <FormattedMessage id="websites.clone_toast.error" defaultMessage="Failed to clone website" />
        )}
        &nbsp;&middot;&nbsp;
        <Link to={`/websites/clone/${cloneId}`}>
          <Span color="light" size="sm" textDecoration="underline">
            <FormattedMessage {...messages.check_status} />
          </Span>
        </Link>
      </Text>
    );
  }, [clone?.status, sourceWebsite, destinationWebsite, isPushLive]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export const CloneToast = connect(stateToProps)(_CloneToast);
