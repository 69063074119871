import { Fragment } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useAtomValue } from 'jotai';
import truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { DropdownMenu } from 'src/components/DropdownMenu';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { Responsive } from 'src/design-system/style-types';
import { breadcrumbsAtom } from 'src/hooks/useBreadcrumb/atoms';

import { style } from './Breadcrumbs.style';

export interface Props {
  hide?: Responsive<boolean>;
}

export const Breadcrumbs = ({ hide = false }: Props) => {
  const breadcrumbs = useAtomValue(breadcrumbsAtom);

  return (
    <Box h="48px" a="center" hide={hide} wrap="nowrap">
      {breadcrumbs.map((breadcrumb, i) => {
        const truncatedLabel =
          typeof breadcrumb.label === 'string' ? truncate(breadcrumb.label, { length: 38 }) : breadcrumb.label;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            {breadcrumb.options && breadcrumb.options.length ? (
              <DropdownMenu
                button={
                  <div css={style.breadcrumb}>
                    <Button
                      size="small"
                      icon={<Icon name="chevron down" size="lg" />}
                      iconPosition="right"
                      variant="tertiary"
                    >
                      {truncatedLabel}
                    </Button>
                  </div>
                }
                options={breadcrumb.options}
                virtualize
                minWidth={350}
              />
            ) : (
              <Link to={breadcrumb.url} css={style.breadcrumb}>
                <Button
                  tabIndex={-1}
                  size="small"
                  icon={i === 0 ? <Icon name="home" size="lg" /> : undefined}
                  variant="tertiary"
                >
                  {truncatedLabel}
                </Button>
              </Link>
            )}
            {i !== breadcrumbs.length - 1 && (
              <Text size="md" marginLeft="lg" marginRight="lg">
                /
              </Text>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};
