import { AriaRole, ReactNode, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { Margins, Responsive } from 'src/design-system/style-types';
import { messages } from 'src/messages';

interface Props {
  children: ReactNode;
  label?: ReactNode;
  labelText?: ReactNode;
  defaultCollapsed?: boolean;
  enableShowHideText?: boolean;
  alignToggle?: 'left' | 'right';
  contentMarginTop?: Responsive<Margins>;
  marginBottom?: Responsive<Margins>;
  marginLeft?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  labelLocation?: 'left' | 'right';
  fullWidth?: boolean;
  showLabel?: boolean;
  onCollapseChange?: (collapsed: boolean) => void;
  role?: AriaRole;
}

export const CollapseContent = ({
  children,
  label,
  labelText,
  defaultCollapsed = true,
  enableShowHideText = true,
  alignToggle = 'left',
  contentMarginTop = 'xs',
  marginBottom = '0',
  marginLeft = '0',
  marginRight = '0',
  labelLocation = 'right',
  fullWidth = false,
  showLabel = true,
  onCollapseChange,
  role,
}: Props) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const toggle = () => {
    onCollapseChange && onCollapseChange(!collapsed);

    setCollapsed(!collapsed);
  };

  const showHideText = useMemo(() => {
    if (!enableShowHideText) {
      return;
    }

    return collapsed ? <FormattedMessage {...messages.show} /> : <FormattedMessage {...messages.hide} />;
  }, [enableShowHideText, collapsed]);

  return (
    <Box role={role} d="column" mr={marginRight} mb={marginBottom} ml={marginLeft}>
      {showLabel && (
        <Box
          ml={alignToggle === 'right' ? 'auto' : undefined}
          a="center"
          onClick={toggle}
          css={{ userSelect: 'none' }}
          d={labelLocation === 'right' ? 'row' : 'row-reverse'}
          j="space-between"
          w={fullWidth ? '100%' : 'auto'}
        >
          <Box w="auto">
            {labelText && (
              <Text size="sm">
                {showHideText} {labelText}
              </Text>
            )}

            {!labelText && label}
          </Box>

          <Box w="auto">
            <Icon
              marginRight={labelLocation === 'left' ? 'sm' : '0'}
              marginLeft={labelLocation === 'right' ? 'sm' : '0'}
              name={collapsed ? 'chevron down' : 'chevron up'}
            />
          </Box>
        </Box>
      )}

      {!collapsed && <Box mt={contentMarginTop}>{children}</Box>}
    </Box>
  );
};
