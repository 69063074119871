import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { wordPressApi } from 'src/api_services/wordpress/service';

import { createRequestStateSaga } from '../helpers';
import { wordPressActions } from './actions';

const [createWordPressUserSaga] = createRequestStateSaga(
  wordPressActions.createWordPressUser,
  wordPressApi.createWordpressUser
);
const [deleteWordPressPluginSaga] = createRequestStateSaga(
  wordPressActions.deleteWordPressPlugin,
  wordPressApi.deleteWordpressPlugin
);
const [deleteWordPressUserSaga] = createRequestStateSaga(
  wordPressActions.deleteWordPressUser,
  wordPressApi.deleteWordpressUser
);

const [updateWordPressAppVersionSaga] = createRequestStateSaga(
  wordPressActions.updateWordPressAppVersion,
  wordPressApi.updateWordpressAppVersion
);
const [updateWordPressPluginSettingsSaga] = createRequestStateSaga(
  wordPressActions.updateWordPressPluginSettings,
  wordPressApi.updateWordpressPluginSettings
);
const [updateWordPressPluginToLatestSaga] = createRequestStateSaga(
  wordPressActions.updateWordPressPluginToLatest,
  wordPressApi.updateWordpressPluginToLatest
);
const [updateWordPressUserSaga] = createRequestStateSaga(
  wordPressActions.updateWordPressUser,
  wordPressApi.updateWordpressUser
);

const [updateWordPressSettingsSaga] = createRequestStateSaga(
  wordPressActions.updateWordPressSettings,
  wordPressApi.updateWordpressSettings
);

const [installWordpressPluginSaga] = createRequestStateSaga(
  wordPressActions.installWordPressPlugin,
  wordPressApi.installWordpressPlugin
);

export interface WordPressItemsMeta {
  appId: string;
}

export function* wordPressSaga() {
  yield takeEvery(getType(wordPressActions.createWordPressUser.request), createWordPressUserSaga);
  yield takeEvery(getType(wordPressActions.deleteWordPressPlugin.request), deleteWordPressPluginSaga);
  yield takeEvery(getType(wordPressActions.deleteWordPressUser.request), deleteWordPressUserSaga);

  yield takeEvery(getType(wordPressActions.updateWordPressAppVersion.request), updateWordPressAppVersionSaga);
  yield takeEvery(getType(wordPressActions.updateWordPressPluginSettings.request), updateWordPressPluginSettingsSaga);
  yield takeEvery(getType(wordPressActions.updateWordPressPluginToLatest.request), updateWordPressPluginToLatestSaga);
  yield takeEvery(getType(wordPressActions.updateWordPressUser.request), updateWordPressUserSaga);
  yield takeEvery(getType(wordPressActions.updateWordPressSettings.request), updateWordPressSettingsSaga);
  yield takeEvery(getType(wordPressActions.installWordPressPlugin.request), installWordpressPluginSaga);
}
