/** @jsxRuntime classic */
/** @jsx jsx */
import { ReactElement } from 'react';
import { jsx } from '@emotion/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { IconButton } from 'src/components/IconButton';
import { IconButtonVariants } from 'src/components/IconButton/IconButton';
import { Tooltip } from 'src/components/Tooltip';
import { FontColors } from 'src/design-system/style-types';
import { messages } from 'src/messages';

export interface Props {
  value: string;
  label?: string | ReactElement;
  iconColor?: FontColors;
  id?: string;
  noLabel?: boolean;
  inline?: boolean;
  inModal?: boolean;
  dark?: boolean;
  variant?: IconButtonVariants;
}

export const Copy = ({
  noLabel = false,
  value,
  label,
  iconColor = 'grey',
  inline = false,
  inModal,
  dark = false,
  variant,
}: Props) => {
  const handleOnCopy = (show: () => void, hide: () => void) => () => {
    show();

    setTimeout(hide, 2000);
  };

  const getLabel = () => {
    if (noLabel) {
      return null;
    }

    return label ?? value;
  };

  return (
    <Box minW="0" w="auto" wrap="nowrap" a="center" css={{ display: inline ? 'inline-flex' : 'flex' }} dataDemoEnabled>
      {getLabel()}

      <Tooltip
        inModal={inModal}
        placement="bottom"
        button={({ show, hide }) => (
          <CopyToClipboard text={value} onCopy={handleOnCopy(show, hide)}>
            <IconButton
              icon="copy alt"
              iconColor={iconColor}
              marginLeft="xs"
              marginRight="xs"
              variant={variant ?? (dark ? 'dark' : 'tertiary')}
              negativeMargin
              onClick={(e) => e.preventDefault()}
            />
          </CopyToClipboard>
        )}
      >
        {<FormattedMessage {...messages.copied} />}
      </Tooltip>
    </Box>
  );
};
