import { css } from '@emotion/core';

import * as mixins from 'src/design-system/mixins';
import { Margins, Responsive } from 'src/design-system/style-types';
import { Theme } from 'src/design-system/theme';

const container =
  ({ disabled, marginBottom }: { disabled: boolean; marginBottom: Responsive<Margins> }) =>
  (theme: Theme) =>
    css`
      ${mixins.margin({ bottom: marginBottom })(theme)};

      label {
        cursor: pointer;
      }

      ${disabled &&
      css`
        label {
          cursor: auto;
        }
      `}
    `;

const input = css`
  display: none;
`;

export const style = { container, input };
