import { BrandingConfig } from 'src/api_services/branding/types';
import defaultTheme, { GenericTheme } from 'src/design-system/theme';
import { ColorBuilder } from 'src/store/branding/colorBuilder';

interface Props {
  (branding?: Partial<BrandingConfig>, rtl?: boolean, darkMode?: boolean): GenericTheme;
}

export const getThemeFromBranding: Props = (branding, rtl = false, darkMode = false) => {
  if (!branding) {
    const { colors, fontColors } = new ColorBuilder(defaultTheme.colors, defaultTheme.fontColors, darkMode).build();

    return { ...defaultTheme, colors, fontColors, settings: { ...defaultTheme.settings, darkMode } };
  }

  const { colors, fontColors } = new ColorBuilder(
    { ...defaultTheme.colors, ...branding.colors },
    { ...defaultTheme.fontColors, ...branding.fontColors },
    darkMode
  ).build();

  const {
    buttonBorderRadius = defaultTheme.buttonBorderRadius,
    fontFamily = defaultTheme.fontFamily,
    colorOverrides = defaultTheme.colorOverrides,
  } = branding;

  return {
    ...defaultTheme,
    colors,
    fontColors,
    fontFamily,
    buttonBorderRadius,
    colorOverrides,
    settings: {
      ...defaultTheme.settings,
      rtl,
      darkMode,
    },
  };
};
