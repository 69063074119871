/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Optional } from 'utility-types';

import { style, StyleProps } from './Grid.style';

export interface Props<T extends keyof HTMLElementTagNameMap = 'div'>
  extends React.HTMLProps<HTMLElementTagNameMap[T]>,
    Optional<StyleProps> {
  el?: T;
}

export const Grid = ({
  el = 'div',
  rowGap = 'sm',
  columnGap = 'lg',
  marginBottom = '0',
  marginTop = '0',
  columns = { xs: 1, sm: 2 },
  ...elementProps
}: Props) => {
  return jsx(el, {
    ...elementProps,
    css: style.grid({
      columnGap,
      columns,
      marginBottom,
      marginTop,
      rowGap,
    }),
    role: 'grid',
  });
};
