import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import { subscriptionsActions } from './actions';

const subscriptions = createRequestStateReducer(subscriptionsActions.getSubscriptionsToParent, {
  loading: false,
  error: '',
  data: {
    total: 0,
    items: [],
  },
});

export const subscriptionsReducer = combineReducers({
  subscriptions,
});
