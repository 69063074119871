import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const logo = (logoPath?: string) => css`
  background-image: url(${logoPath});
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
`;

const navigation =
  ({ isImpersonationActive }: { isImpersonationActive: boolean }) =>
  (theme: Theme) =>
    css`
      overflow-y: auto;
      position: fixed;
      z-index: ${zIndex.DashboardSpine};
      background: ${theme.colors.grey95};
      padding-bottom: ${theme.paddings.lg}px;
      height: 100vh;

      @media screen and (max-width: ${breakpoints.sm}px) {
        top: ${isImpersonationActive ? '48px' : '0'};
        height: ${isImpersonationActive ? 'calc(100vh - 48px)' : '100vh'};
      }
    `;

export const style = {
  logo,
  navigation,
};
