import React, { Fragment, lazy, memo, Suspense } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { LoadingOverlay } from 'src/components/LoadingOverlay';
import LoggedInRoute from 'src/containers/LoggedInRoute';
import LoggedOutRoute from 'src/containers/LoggedOutRoute';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { globalUiSelectors } from 'src/store/globalUi/selectors';
import { isLink, NavItem } from 'src/utils/types';

import { BreadcrumbsItem } from '../containers/Breadcrumbs';

const Login = lazy(() => import('../pages/login'));
const LoginTwoFactorAuth = lazy(() => import('../pages/login/LoginTwoFactorAuth/LoginTwoFactorAuth'));
const NotFound = lazy(() => import('../pages/not-found'));
const PasswordReset = lazy(() => import('../pages/login/password-reset'));
const AcceptInvite = lazy(() => import('../pages/invites/AcceptInvite'));

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  isLoggedIn: boolean;
  loggedInNavigationItems: NavItem[];
}

const _Routes = ({ isLoggedIn, loggedInNavigationItems, darkMode }: Props) => {
  const intl = useIntl();

  const loggedInRoutes = loggedInNavigationItems.map((navItem) => {
    const { exact, component } = navItem;
    const route = isLink(navItem) ? navItem.url : navItem.path;
    const nested = isLink(navItem) ? navItem.nested : undefined;

    return [
      <LoggedInRoute key={route} exact={exact} path={route} isLoggedIn={isLoggedIn} component={component} />,
      ...(nested ?? []).map(({ exact, component, url }) => (
        <LoggedInRoute key={url} exact={exact} path={url} isLoggedIn={isLoggedIn} component={component} />
      )),
    ];
  });

  return (
    <Fragment>
      <BreadcrumbsItem id="home-breadcrumb" label={intl.formatMessage(messages.home)} url="/" />
      <Suspense fallback={null}>
        <Switch>
          {loggedInRoutes}
          <Route exact path="/login" render={() => <Login isLoggedIn={isLoggedIn} />} />
          <Route exact path="/login/demo" component={() => <LoadingOverlay alpha={0} darkMode={darkMode} />} />
          <LoggedOutRoute path="/login/reset" isLoggedIn={isLoggedIn} component={PasswordReset} />
          <Route path="/login/2fa" component={LoginTwoFactorAuth} />
          <Route path="/invites/:inviteKey" component={AcceptInvite} />
          <LoggedInRoute path="" isLoggedIn={isLoggedIn} component={NotFound} />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

const stateToProps = (state: AppState) => ({
  darkMode: globalUiSelectors.darkMode(state),
});

export const Routes = connect(stateToProps)(memo(_Routes));
