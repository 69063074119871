import React from 'react';
import { Button } from './';

export interface RequiredProps {
  children: React.ReactNode;
  gap?: boolean;
}

const modifiedChildren = (children: React.ReactNode) => {
  const components = React.Children.toArray(children);
  const filtered = components.filter((child) => React.isValidElement(child) && child.type === Button);

  return filtered.map((child, index): React.ReactNode => {
    if (!React.isValidElement(child)) {
      return null;
    }

    if (child.type !== Button) {
      return child;
    }

    const isLeftButton = index === 0 && filtered.length > 1;
    const isRightButton = index === filtered.length - 1 && filtered.length > 1;
    const isCenterButton = index !== 0 && index !== filtered.length - 1 && filtered.length > 1;

    return React.cloneElement(child, {
      ...child.props,
      key: index, // eslint-disable-line react/no-array-index-key
      isLeftButton,
      isRightButton,
      isCenterButton,
    });
  });
};

export const ButtonGroup = ({ children, gap = false }: RequiredProps) => (
  <span style={gap ? { display: 'flex', gap: '2px' } : undefined}>{modifiedChildren(children)}</span>
);
