import { ServerRole } from 'src/orchd-client';
import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { setRolesBeingAdded, setRolesBeingRemoved } from './actions';

type PartialRolesRecord = Partial<Record<ServerRole, boolean>>;
type ServerId = string;
export type RolesBeingAddedRecord = Record<ServerId, PartialRolesRecord>;
export type RolesBeingRemovedRecord = Record<ServerId, PartialRolesRecord>;

export const rolesBeingAddedInitialState: RolesBeingAddedRecord = {};
export const rolesBeingRemovedInitialState: RolesBeingRemovedRecord = {};

export const rolesBeingAddedReducer = createReducer(rolesBeingAddedInitialState, (handleAction) => [
  handleAction(setRolesBeingAdded, (state, { payload: { serverId, roles } }) => ({
    ...state,
    [serverId]: {
      ...state[serverId],
      ...roles,
    },
  })),
]);

export const rolesBeingRemovedReducer = createReducer(rolesBeingRemovedInitialState, (handleAction) => [
  handleAction(setRolesBeingRemoved, (state, { payload: { serverId, roles } }) => ({
    ...state,
    [serverId]: {
      ...state[serverId],
      ...roles,
    },
  })),
]);

export const serverRolesReducer = combineReducers({
  rolesBeingAdded: rolesBeingAddedReducer,
  rolesBeingRemoved: rolesBeingRemovedReducer,
});
