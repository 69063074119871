import { getType } from 'deox';
import { Middleware } from 'redux';

import { impersonationSelectors } from 'src/store/impersonation';
import { loginsSelectors } from 'src/store/logins/selectors';

import { brandingActions } from './actions';

// The purpose of this middleware is to avoid duplicating logic across branding-aware components
// since impersonation branding actions should be handled separately.
// This middleware allows components to use the normal `getBranding.request` and `updateBranding.request`
// in both normal mode and impersonation mode, with the case of the latter handled here instead
export const brandingImpersonationMiddleware: Middleware = (store) => (next) => (action) => {
  const isGetBrandingRequestAction = action.type === getType(brandingActions.getBranding.request);
  const isUpdateBrandingRequestAction = action.type === getType(brandingActions.updateBranding.request);

  // check if actions needs to be handed first to avoid expensive getState call
  if (!isGetBrandingRequestAction && !isUpdateBrandingRequestAction) {
    return next(action);
  }

  const state = store.getState();
  const impersonationMember = impersonationSelectors.getImpersonationMember(state);
  // If impersonation is active, substitute the get & update branding actions for their
  // impersonation-specific equivalents, this allows the getBranding & updateBranding actions to be used
  // in components regardless of whether impersonation is active or not.
  if (impersonationMember) {
    // in impersonation mode, the ID of the parent org should be used for branding
    const { orgId } = impersonationMember;

    if (isGetBrandingRequestAction) {
      const origAction = action as ReturnType<typeof brandingActions.getBranding.request>;
      return next(
        brandingActions.getImpersonationBranding.request({
          ...origAction.payload,
          params: { ...origAction.payload.params, orgId },
        })
      );
    }

    if (isUpdateBrandingRequestAction) {
      const origAction = action as ReturnType<typeof brandingActions.updateBranding.request>;
      return next(
        brandingActions.updateImpersonationBranding.request({
          ...origAction.payload,
          params: { ...origAction.payload.params, orgId },
        })
      );
    }
  }

  if (
    action.type === getType(brandingActions.getBranding.success) ||
    action.type === getType(brandingActions.getImpersonationBranding.success)
  ) {
    next({
      ...action,
      payload: {
        ...action.payload,
        locale: loginsSelectors.selectLocale(state),
      },
    });
  }

  return next(action);
};
