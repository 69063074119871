import { createActionCreator } from 'deox';

import type { Props } from 'src/components/AlertModal/AlertModal';

const showAlertModal = createActionCreator('showAlertModal', (resolve) => (payload: Props) => {
  return resolve(payload);
});

const hideAlertModal = createActionCreator('hideAlertModal', (resolve) => () => {
  return resolve();
});

/**
 * @deprecated
 */
export const alertModalActions = {
  showAlertModal,
  hideAlertModal,
};
