import { WebsiteKind, WebsiteStatus } from 'src/orchd-client';
import { createSelector } from 'reselect';

import { AppState } from 'src/store';
import { isWebsiteNormalOrStaging } from 'src/utils/isWebsiteNormal';

const getWebsitesState = (state: AppState) => state.data.websites.getWebsites;
const getWebsitesItems = (state: AppState) => state.data.websites.getWebsites.data.items;
const getWebsitesLoading = (state: AppState) => state.data.websites.getWebsites.loading;
const getWebsitesError = (state: AppState) => state.data.websites.getWebsites.error;
const getWebsitesTotal = (state: AppState) => state.data.websites.getWebsites.data.total;
const getWebsite = (state: AppState) => state.data.websites.getWebsite.data;
const getWebsiteLoading = (state: AppState) => state.data.websites.getWebsite.loading;
const getWebsiteError = (state: AppState) => state.data.websites.getWebsite.error;
const updateWebsite = (state: AppState) => state.data.websites.updateWebsite;
const deleteWebsite = (state: AppState) => state.data.websites.deleteWebsite;
const getAccessToken = (state: AppState) => state.data.websites.getAccessToken;
const accessToken = (state: AppState) => state.data.websites.getAccessToken.data;
const isAccessTokenLoading = (state: AppState) => state.data.websites.getAccessToken.loading;
const getWebsiteMySQLDBs = (state: AppState) => state.data.websites.getWebsiteMySQLDBs;
const getWebsiteHtaccessRewrites = (state: AppState) => state.data.websites.getWebsiteHtaccessRewrites;
const serviceSetting = (state: AppState) => state.data.websites.serviceSetting.data;
const serviceSettingLoading = (state: AppState) => state.data.websites.serviceSetting.loading;
const websiteServerDomains = (state: AppState) => state.data.websites.websiteServerDomains.data;
const websiteServerDomainsLoading = (state: AppState) => state.data.websites.websiteServerDomains.loading;
const screenshotTimestamp = (state: AppState) => state.data.websites.screenshotTimestamp.data?.seconds;
const screenshotTimestampLoading = (state: AppState) => state.data.websites.screenshotTimestamp.loading;

const websiteDomainMappingDnsStatus = (state: AppState) => state.data.websites.websiteDomainMappingDnsStatus?.data;
const websiteDomainMappingDnsStatusLoading = (state: AppState) =>
  state.data.websites.websiteDomainMappingDnsStatus.loading;
const websiteDomainMappingDnsStatusError = (state: AppState) => state.data.websites.websiteDomainMappingDnsStatus.error;

const selectActiveWebsites = createSelector(getWebsitesItems, (websites) =>
  websites.filter(({ status }) => status === WebsiteStatus.active)
);

export const selectActiveAndNormalWebsites = createSelector(selectActiveWebsites, (websites) =>
  websites.filter((website) => isWebsiteNormalOrStaging(website.kind))
);

const selectControlPanel = createSelector(getWebsitesItems, (websites) => {
  const [controlPanel] = websites.filter(({ kind }) => kind === WebsiteKind.controlPanel);
  return controlPanel;
});

const selectControlPanelId = createSelector(selectControlPanel, (controlPanel) => controlPanel?.id || '');
const selectControlPanelDomain = createSelector(
  selectControlPanel,
  (controlPanel) => controlPanel?.domain?.domain || ''
);

export const selectIsStagingWebsite = createSelector(getWebsite, (website) => website?.kind === WebsiteKind.staging);
export const selectHasSubscription = createSelector(getWebsite, (website) => !!website?.subscriptionId);

export const selectWebsitesWithEmailServers = createSelector(getWebsitesItems, (websites = []) =>
  websites.filter(({ emailServerId, status }) => emailServerId && status === WebsiteStatus.active)
);

export const websitesSelectors = {
  accessToken,
  deleteWebsite,
  getAccessToken,
  getWebsite,

  getWebsiteError,
  getWebsiteHtaccessRewrites,
  getWebsiteLoading,
  getWebsiteMySQLDBs,
  getWebsitesError,
  getWebsitesItems,
  getWebsitesLoading,
  getWebsitesState,
  getWebsitesTotal,
  isAccessTokenLoading,
  screenshotTimestamp,
  screenshotTimestampLoading,
  selectActiveAndNormalWebsites,
  selectActiveWebsites,
  selectControlPanelDomain,
  selectControlPanelId,
  selectHasSubscription,
  selectIsStagingWebsite,
  serviceSetting,
  serviceSettingLoading,
  selectWebsitesWithEmailServers,
  updateWebsite,
  websiteDomainMappingDnsStatus,
  websiteDomainMappingDnsStatusError,
  websiteDomainMappingDnsStatusLoading,
  websiteServerDomains,
  websiteServerDomainsLoading,
};
