import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { emailsActions } from './actions';

const getEmailsReducer = createRequestStateReducer(emailsActions.getEmails);
const getWebsiteEmailsReducer = createRequestStateReducer(emailsActions.getWebsiteEmails);
const getOrgEmailsReducer = createRequestStateReducer(emailsActions.getOrgEmails);

export const emailsReducer = combineReducers({
  emails: getEmailsReducer,
  orgEmails: getOrgEmailsReducer,
  websiteEmails: getWebsiteEmailsReducer,
});
