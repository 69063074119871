import { getType } from 'deox';
import { takeLatest } from 'redux-saga/effects';

import { appsApi } from 'src/api_services/apps';
import { createRequestStateSaga } from 'src/store/helpers';

import * as actions from './actions';

export const [getInstallableWebsiteAppsSaga] = createRequestStateSaga(
  actions.getInstallableApps,
  appsApi.getInstallableApps
);

export const [getGlobalInstallableWebsiteAppsSaga] = createRequestStateSaga(
  actions.getGlobalInstallableApps,
  appsApi.getGlobalInstallableApps
);

export function* appsSaga() {
  yield takeLatest(getType(actions.getInstallableApps.request), getInstallableWebsiteAppsSaga);
  yield takeLatest(getType(actions.getGlobalInstallableApps.request), getGlobalInstallableWebsiteAppsSaga);
}
