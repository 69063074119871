import { ComponentType } from 'react';
import { CustomersApiGetOrgCustomersReq, ServerRole } from 'src/orchd-client';

import { IconName } from 'src/components/Icon/Icon.types';

export type StatusTypes = 'error' | 'success' | 'warning' | 'loading' | 'info' | 'unknown' | 'decommissioned';
export type StatusColors = 'error' | 'success' | 'warning' | 'info';

export type RoleIconsMap = Record<string, IconName>;

export type CustomerSortKeys = CustomersApiGetOrgCustomersReq['sortBy'];

export type EmailSortKeys = 'address' | 'forwardersCount' | 'autorespondersCount' | 'size';

export interface Customer {
  id: string;
  name: string;
}

export type ServerStatus = 'online' | 'critical' | 'warning' | 'restarting' | 'no_services' | 'unknown';

export type ServerRoleNames = keyof typeof ServerRole;

export type ServerRolesBoolean = Record<ServerRoleNames, boolean>;

export interface UsageLevel {
  level: 'low' | 'medium' | 'high';
  color: 'success' | 'warning' | 'error';
}

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
// This can be used to extract the payloads from the orchd client
//   e.g.
//   ResolvedPayload<typeof api.getWebsiteMySQLDBs>
export type ResolvedPayload<T extends (...args: any) => any> = ThenArg<ReturnType<T>>;

export type Selectable<T> = T & { selected?: boolean };

export type SslStatusTypes = 'enabled' | 'expired' | 'disabled';

export interface Redirect {
  id: string;
  status: number;
  domains: string[];
  slug: string;
  destinationUrl: string;
}

export interface LinkItem {
  url: string;
  component: ComponentType<any>;
  exact?: boolean;
  label: string;
  icon: IconName;
  nested?: LinkItem[];
}

export interface RouteItem {
  path: string;
  component: ComponentType<any>;
  exact?: boolean;
}

export type NavItem = LinkItem | RouteItem;

export function isLink(item: NavItem): item is LinkItem {
  return (item as LinkItem).url !== undefined;
}

export type ServerRoleState = 'loading' | 'online' | 'offline';

export type LooseAutocomplete<T extends string> = T | Omit<string, T>;

export type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;
