import { WebsiteAppKind } from 'src/orchd-client';

import { AppState } from 'src/store';
import { sortInstallableAppByVersionDesc } from 'src/utils/helpers';

export const selectAppsState = (state: AppState) => state.apps;

export const selectInstallableWebsiteApps = (state: AppState) =>
  selectAppsState(state).installableWebsiteApps.data.items;
export const selectInstallableWebsiteAppsLoading = (state: AppState) =>
  selectAppsState(state).installableWebsiteApps.loading;
export const selectInstallableWebsiteAppsError = (state: AppState) =>
  selectAppsState(state).installableWebsiteApps.error;

export const selectInstallableWebsiteAppsByVersionDesc = (state: AppState) =>
  sortInstallableAppByVersionDesc(selectInstallableWebsiteApps(state));

export const selectLatestAvailableWordpressVersion = (state: AppState) =>
  selectInstallableWebsiteAppsByVersionDesc(state).find((appItem) => appItem.app === WebsiteAppKind.wordpress);

export const isGlobalInstallableAppsLoading = (state: AppState) =>
  selectAppsState(state).globalInstallableWebsiteApps.loading;
export const globalInstallableApps = (state: AppState) =>
  selectAppsState(state).globalInstallableWebsiteApps.data.items;
export const globalInstallableAppsByVersionDesc = (state: AppState) =>
  sortInstallableAppByVersionDesc(globalInstallableApps(state));

export const appsSelectors = {
  globalInstallableApps,
  globalInstallableAppsByVersionDesc,
  isGlobalInstallableAppsLoading,
  selectAppsState,
  selectInstallableWebsiteApps,
  selectInstallableWebsiteAppsByVersionDesc,
  selectInstallableWebsiteAppsError,
  selectInstallableWebsiteAppsLoading,
  selectLatestAvailableWordpressVersion,
};
