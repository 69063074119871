import { NewResourceUuid, Role } from 'src/orchd-client';
import { AxiosResponse } from 'axios';
import { ActionType, getType } from 'deox';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { customersApi } from 'src/api_services/customers/service';
import { loginsApi } from 'src/api_services/logins/service';
import { membersApi } from 'src/api_services/members/service';
import { extendedOrgsApi } from 'src/api_services/orgs/service';
import { websitesApi } from 'src/api_services/websites';
import { createRequestStateSaga } from 'src/store/helpers';

import { customersActions } from './actions';
import { AddCustomerErrorCode } from './types';

export const [getCustomersSaga, refreshCustomersSaga] = createRequestStateSaga(
  customersActions.getCustomers,
  customersApi.getOrgCustomers
);

export const [getCustomerSaga] = createRequestStateSaga(customersActions.getCustomer, extendedOrgsApi.getOrg);
export const [deleteCustomerSaga] = createRequestStateSaga(customersActions.deleteCustomer, extendedOrgsApi.deleteOrg);
export const [updateCustomerSaga] = createRequestStateSaga(customersActions.updateCustomer, extendedOrgsApi.updateOrg);

export const [createCustomerSubscriptionSaga] = createRequestStateSaga(
  customersActions.createCustomerSubscription,
  customersApi.createCustomerSubscription
);
export const [getCustomerSubscriptionsSaga] = createRequestStateSaga(
  customersActions.getCustomerSubscriptions,
  customersApi.getCustomerSubscriptions
);

export const [getCustomerWebsites, refreshCustomerWebsites] = createRequestStateSaga(
  customersActions.getCustomerWebsites,
  websitesApi.getWebsites
);

export function* addCustomerSaga({ payload }: ActionType<typeof customersActions.addCustomer.request>) {
  const { onSuccess, onError } = payload;
  const { email, newUser, orgName, orgId, packages, existingUserId, serverIds } = payload.params;
  let ownerId: string;
  let customerOrgId: string;

  try {
    const res: AxiosResponse<NewResourceUuid> = yield call(customersApi.createCustomer, {
      orgId,
      newCustomer: { name: orgName },
    });
    customerOrgId = res.data.id;
  } catch (e) {
    const error = e as Error & { errorCode?: AddCustomerErrorCode };
    onError && onError(error);
    yield put(customersActions.addCustomer.error({ error: error.message }));
    return;
  }

  for (const planId of packages) {
    try {
      yield call(customersApi.createCustomerSubscription, {
        orgId,
        customerOrgId,
        newSubscription: { planId, dedicatedServers: serverIds },
      });
    } catch (e) {
      const error = e as Error & { errorCode?: AddCustomerErrorCode };
      onError && onError(error);
      yield put(customersActions.addCustomer.error({ error: error.message }));
      return;
    }
  }

  if (newUser) {
    try {
      const res: AxiosResponse<NewResourceUuid> = yield call(loginsApi.createLogin, {
        loginInfo: { ...newUser, email },
        orgId: customerOrgId,
      });
      ownerId = res.data.id;
    } catch (e) {
      const error = e as Error & { errorCode?: AddCustomerErrorCode };
      onError && onError(error);
      yield put(customersActions.addCustomer.error({ error: error.message }));
      return;
    }
  } else if (existingUserId) {
    ownerId = existingUserId;
  } else {
    onSuccess && onSuccess({ newCustomerId: customerOrgId });
    yield put(customersActions.addCustomer.success({ data: { newCustomerId: customerOrgId } }));
    return;
  }

  try {
    yield call(membersApi.createMember, {
      orgId: customerOrgId,
      newMember: { loginId: ownerId, roles: [Role.Owner] },
    });
  } catch (e) {
    const error = e as Error & { errorCode?: AddCustomerErrorCode };
    onError && onError(error);
    yield put(customersActions.addCustomer.error({ error: error.message }));
    return;
  }
  onSuccess && onSuccess({ newCustomerId: customerOrgId });
  yield put(customersActions.addCustomer.success({ data: { newCustomerId: customerOrgId } }));
}

export function* customersSaga() {
  yield takeEvery(getType(customersActions.getCustomers.request), getCustomersSaga);

  yield takeEvery(getType(customersActions.getCustomer.request), getCustomerSaga);
  yield takeEvery(getType(customersActions.addCustomer.request), addCustomerSaga);
  yield takeEvery(getType(customersActions.deleteCustomer.request), deleteCustomerSaga);
  yield takeEvery(getType(customersActions.updateCustomer.request), updateCustomerSaga);

  yield takeEvery(getType(customersActions.getCustomerSubscriptions.request), getCustomerSubscriptionsSaga);
  yield takeEvery(getType(customersActions.createCustomerSubscription.request), createCustomerSubscriptionSaga);

  yield takeEvery(getType(customersActions.getCustomerWebsites.request), getCustomerWebsites);

  yield takeLatest(
    [
      getType(customersActions.updateCustomer.success),
      getType(customersActions.deleteCustomer.success),
      getType(customersActions.addCustomer.success),
    ],
    refreshCustomersSaga
  );
}
