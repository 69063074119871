import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { subscriptionsApi } from 'src/api_services/subscriptions';
import { createRequestStateSaga } from 'src/store/helpers';

import { subscriptionsActions } from './actions';

/** @deprecated */
const [getSubscriptionsSaga] = createRequestStateSaga(
  subscriptionsActions.getSubscriptionsToParent,
  subscriptionsApi.getSubscriptionsToParent
);

export function* subscriptionsSaga() {
  yield takeEvery(getType(subscriptionsActions.getSubscriptionsToParent.request), getSubscriptionsSaga);
}
