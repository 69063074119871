import { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Card, CardBody, CardFooter } from 'src/components/Card';
import { Modal } from 'src/components/Modal';
import { StatusIcon } from 'src/components/Status';
import { H1, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { useHideModal } from 'src/utils/modal/useModal';

import { style } from './AlertModal.style';

export type AlertVariant = 'info' | 'warning' | 'error' | 'success';

export interface Props {
  variant: AlertVariant;
  titleText: React.ReactNode;
  bodyText?: React.ReactNode;
  primaryButton?: {
    text: React.ReactNode;
    onClick: (utils: AlertModalButtonUtils) => void;
    disabled?: boolean;
  };
  secondaryButton?: {
    text: React.ReactNode;
    onClick: (utils: AlertModalButtonUtils) => void;
    disabled?: boolean;
  };
  closeButton?: {
    text: React.ReactNode;
    onClick?: (utils: AlertModalButtonUtils) => void;
    disabled?: boolean;
  };
  hideCloseButton?: boolean;
  onClose?: () => void;
}

export interface AlertModalButtonUtils {
  hide: () => void;
  loadingStart: () => void;
  loadingStop: () => void;
}

export const AlertModal = ({
  variant,
  titleText,
  bodyText,
  primaryButton,
  secondaryButton,
  closeButton,
  hideCloseButton = false,
  onClose,
}: Props) => {
  const hide = useHideModal();
  const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);

  const primaryButtonText = primaryButton?.text;
  const primaryButtonOnClick = primaryButton?.onClick;
  const secondaryButtonText = secondaryButton?.text;
  const secondaryButtonOnClick = secondaryButton?.onClick;
  const closeButtonOnClick = closeButton?.onClick || hide;
  const closeButtonText = closeButton?.text;

  return (
    <Modal onCancel={onClose}>
      <Card css={style.card({ variant })} marginBottom="lg">
        <CardBody padding="xl">
          <Box mb={bodyText ? 'xl' : '0'} css={style.titleTextWrapper}>
            {variant && <StatusIcon marginRight="md" type={variant} css={style.titleTextIcon} />}

            <H1 size="xl" weight="light" fullWidth ellipsis css={style.titleText}>
              {titleText}
            </H1>
          </Box>
          {bodyText && (
            <Text tag="div" marginBottom="md" size="sm" data-qa="bodyText" whiteSpace="pre-line">
              {bodyText}
            </Text>
          )}
        </CardBody>
        <CardFooter padding="lg">
          <Box j="flex-end" a="center" px="lg">
            {!hideCloseButton && (
              <Box w="auto">
                <Button
                  data-qa="closeButton"
                  size="small"
                  variant="tertiary"
                  onClick={() =>
                    closeButtonOnClick({
                      hide,
                      loadingStart: () => setSecondaryButtonLoading(true),
                      loadingStop: () => setSecondaryButtonLoading(false),
                    })
                  }
                  disabled={closeButton?.disabled}
                >
                  {closeButtonText || <FormattedMessage {...messages.close} />}
                </Button>
              </Box>
            )}

            {(primaryButtonText || secondaryButtonText) && (
              <Box w="auto">
                {secondaryButtonText && (
                  <Button
                    data-qa="secondaryButton"
                    size="small"
                    variant="secondary"
                    marginLeft="2xl"
                    onClick={() =>
                      secondaryButtonOnClick &&
                      secondaryButtonOnClick({
                        hide,
                        loadingStart: () => setSecondaryButtonLoading(true),
                        loadingStop: () => setSecondaryButtonLoading(false),
                      })
                    }
                    loading={secondaryButtonLoading}
                    disabled={secondaryButton?.disabled || primaryButtonLoading || secondaryButtonLoading}
                  >
                    {secondaryButtonText}
                  </Button>
                )}

                {primaryButtonText && (
                  <Box w="auto" dataDemoDisabled>
                    <Button
                      data-qa="primaryButton"
                      size="small"
                      variant="primary"
                      marginLeft="2xl"
                      onClick={() =>
                        primaryButtonOnClick &&
                        primaryButtonOnClick({
                          hide,
                          loadingStart: () => setPrimaryButtonLoading(true),
                          loadingStop: () => setPrimaryButtonLoading(false),
                        })
                      }
                      loading={primaryButtonLoading}
                      disabled={primaryButton?.disabled || primaryButtonLoading || secondaryButtonLoading}
                    >
                      {primaryButtonText}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </CardFooter>
      </Card>
    </Modal>
  );
};
