import { ReactNode } from 'react';
import { createReducer } from 'deox';
import { FormattedMessage } from 'react-intl';
import uuid from 'uuid';

import { messages } from 'src/messages';

import { autosaveToast } from '../autosave-toast/actions';
import { toastActions } from './actions';

import type { ToastItem, ToastVariant } from 'src/components/Toast/types';
const { customToast, infoToast, errorToast, neutralToast, successToast, warningToast, dismissToast, dismissAllToasts } =
  toastActions;

interface ToastsReducer {
  toasts: ToastItem[];
}

export const initialState: ToastsReducer = {
  toasts: [],
};

const basicToast = ({ variant = 'success', message }: { variant?: ToastVariant; message: ReactNode }) => {
  return { id: uuid(), message, variant, dismissible: true, persist: false };
};

export const toastsReducer = createReducer(initialState, (handleAction) => [
  handleAction(customToast, (state, { payload }) => {
    const newToast = { ...payload, id: payload.id ?? uuid() };
    return {
      toasts: [...state.toasts, newToast],
    };
  }),
  // normal presets
  handleAction(successToast, (state, { payload }) => {
    return {
      toasts: [...state.toasts, basicToast({ message: payload })],
    };
  }),
  handleAction(errorToast, (state, { payload }) => {
    return {
      toasts: [...state.toasts, basicToast({ message: payload, variant: 'error' })],
    };
  }),
  handleAction(infoToast, (state, { payload }) => {
    return {
      toasts: [...state.toasts, basicToast({ message: payload, variant: 'info' })],
    };
  }),
  handleAction(neutralToast, (state, { payload }) => {
    return {
      toasts: [...state.toasts, basicToast({ message: payload, variant: 'neutral' })],
    };
  }),
  handleAction(warningToast, (state, { payload }) => {
    return {
      toasts: [...state.toasts, basicToast({ message: payload, variant: 'warning' })],
    };
  }),
  // autosave toasts
  handleAction(autosaveToast.show, (state, { payload }) => {
    const toastId = payload.id ?? 'autosave-toast';
    const newToast = {
      id: toastId,
      message: payload.text || <FormattedMessage {...messages.saving} />,
      variant: 'neutral',
      persist: true,
    } satisfies ToastItem;

    return {
      toasts: [...state.toasts.filter(({ id }) => id !== toastId), newToast],
    };
  }),
  handleAction(autosaveToast.success, (state, { payload }) => {
    const toastId = payload.id ?? 'autosave-toast';

    const newToast = {
      id: toastId,
      message: payload.text || <FormattedMessage {...messages.saved} />,
      variant: 'success',
      persist: true,
    } satisfies ToastItem;

    return {
      toasts: [...state.toasts.filter(({ id }) => id !== toastId), newToast],
    };
  }),
  handleAction(autosaveToast.error, (state, { payload }) => {
    const toastId = payload.id ?? 'autosave-toast';

    const newToast = {
      id: toastId,
      message: payload.text || <FormattedMessage {...messages['error.general']} />,
      variant: 'error',
      persist: true,
    } satisfies ToastItem;

    return {
      toasts: [...state.toasts.filter(({ id }) => id !== toastId), newToast],
    };
  }),
  handleAction(autosaveToast.neutral, (state, { payload }) => {
    const toastId = payload.id ?? 'autosave-toast';

    const newToast = {
      id: toastId,
      message: payload.text || <FormattedMessage {...messages.saving} />,
      variant: 'neutral',
      persist: true,
    } satisfies ToastItem;

    return {
      toasts: [...state.toasts.filter(({ id }) => id !== toastId), newToast],
    };
  }),
  // remove toasts
  handleAction(dismissToast, (state, { payload: toastId }) => {
    return {
      toasts: [...state.toasts.filter(({ id }) => id !== toastId)],
    };
  }),
  handleAction(dismissAllToasts, () => {
    return {
      toasts: [],
    };
  }),
]);
