import { AxiosError } from 'axios';

import { uuidRegex } from 'src/utils/regex';

export const isServerUpdating = ({ response, config }: AxiosError) => {
  if (!config.url || !response?.status) {
    return false;
  }

  const regex = new RegExp(`/api/servers/${uuidRegex.source}`);
  return response?.status === 503 && regex.test(config.url);
};
