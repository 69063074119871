import { DeepPartial } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { AppState } from './';

const getUserSpecificState = () => {
  const moveServer = persister.get({ key: 'moveServer', isPerUser: true });
  const notifications = persister.get({ key: 'notifications', isPerUser: true });
  const recentSearches = persister.get({ key: 'globalSearch', isPerUser: true });
  const search = persister.get({ key: 'search', isPerUser: true });
  const ui = persister.get({ key: 'ui', isPerUser: true });
  const devtools = persister.get({ key: 'devtools', isPerUser: true });
  const tableSettings = persister.get({ key: 'tableSettings', isPerUser: true, usePerUserImpersonation: false });

  return {
    globalSearch: {
      recentSearches,
    },
    moveServer,
    notifications,
    search,
    ui: { ...ui, hydrated: true },
    devtools,
    tableSettings,
  };
};

export const hydrateState = (): DeepPartial<AppState> => {
  const globalUi = persister.get({ key: 'globalUi' });
  const session = persister.get({ key: 'session' });
  const branding = persister.get({ key: 'branding' });
  const impersonation = persister.get({ key: 'impersonation' });
  const impersonationBranding = persister.get({ key: 'impersonationBranding' });

  const state = {
    session,
    globalUi,
    impersonation,
    theme: {
      branding: {
        data: branding,
      },
      impersonationBranding: {
        data: impersonationBranding,
      },
    },
  };

  if (session?.currentMemberId) {
    return { ...state, ...getUserSpecificState() };
  }

  return state;
};
