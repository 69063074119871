import { WebsitesApi } from 'src/orchd-client';

import { axiosInstance } from 'src/utils/axios';

import { DeleteWebsiteReq, GetWebsiteReq, UpdateWebsiteReq } from './types';

const api = WebsitesApi({}, '/api', axiosInstance);

const updateWebsite = ({ orgId, websiteId, updateWebsite, options }: UpdateWebsiteReq) =>
  api.updateWebsite({ orgId, websiteId, updateWebsite }, options);

const deleteWebsite = ({ orgId, websiteId, force, options }: DeleteWebsiteReq) =>
  api.deleteWebsite({ orgId, websiteId, force }, options);

// this is provided to filerd to authenticate direct API access to a website's files
const getAccessToken = ({ orgId, websiteId }: GetWebsiteReq) => api.getSiteAccessToken({ orgId, websiteId });

export const websitesApi = {
  ...api,
  deleteWebsite,
  getAccessToken,
  updateWebsite,
};
