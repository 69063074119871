import { ReactNode } from 'react';
import { css } from '@emotion/core';

import { margin } from 'src/design-system/mixins';
import { Margins, Responsive, Sizes } from 'src/design-system/style-types';
import { responsive } from 'src/design-system/style-utils';
import { Colors, FontColors, Theme } from 'src/design-system/theme';

const container =
  ({
    fullWidth,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
  }: {
    fullWidth: boolean;
    marginBottom: Responsive<Margins>;
    marginLeft: Responsive<Margins>;
    marginRight: Responsive<Margins>;
    marginTop: Responsive<Margins>;
  }) =>
  (theme: Theme) =>
    css`
      display: flex;
      user-select: none;
      outline: 0;

      width: ${fullWidth ? '100%' : 'auto'};

      ${margin({ bottom: marginBottom, left: marginLeft, right: marginRight, top: marginTop })(theme)}
    `;

export const input = css`
  display: none;
`;

export const label = ({
  disabled,
  labelLocation,
  fullWidth,
  label,
}: {
  disabled: boolean;
  labelLocation: Responsive<'right' | 'bottom'>;
  fullWidth: boolean;
  label: ReactNode;
}) =>
  css`
    cursor: pointer;
    ${disabled &&
    css`
      cursor: default;
    `}

    ${responsive(labelLocation, (labelLocation) =>
      labelLocation === 'bottom'
        ? css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `
        : css`
            max-width: 100%;
            display: flex;
            align-items: center;
            ${label &&
            css`
              flex-basis: 100%;
              width: ${fullWidth ? '100%' : 'auto'};
            `}
            > * {
              &:not(:first-of-type) {
                display: flex;
              }
              &:last-of-type:not(:first-of-type) {
                width: ${fullWidth ? '100%' : 'auto'};
              }
            }
          `
    )}
  `;

const checkboxSizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
  xl: 24,
  '2xl': 28,
  '3xl': 34,
  '4xl': 40,
  '5xl': 48,
} as const;

export const customInput =
  ({
    borderColor,
    labelLocation,
    spaceY,
    space,
    checked,
    disabled,
    size,
    inputBackgroundCheckedColor,
    inputBackgroundDisabledColor,
    inputBorderDisabledColor,
    borderCheckedColor,
  }: {
    borderColor: FontColors;
    labelLocation: Responsive<'right' | 'bottom'>;
    spaceY: Responsive<Margins>;
    space: Responsive<Margins>;
    checked: boolean | 'mixed';
    disabled: boolean;
    size: Responsive<Sizes>;
    inputBackgroundCheckedColor: Colors;
    inputBackgroundDisabledColor: Colors;
    inputBorderDisabledColor: FontColors;
    borderCheckedColor: FontColors;
  }) =>
  (theme: Theme) =>
    css`
      cursor: pointer;
      user-select: none;
      border: 1px solid ${theme.fontColors[borderColor]};
      display: inline-flex;
      vertical-align: text-bottom;
      align-items: center;
      justify-content: center;
      ${margin({ bottom: '0', right: 'sm' })(theme)}
      ${responsive(labelLocation, (labelLocation) =>
        labelLocation === 'bottom'
          ? margin({ bottom: spaceY, right: '0' })(theme)
          : margin({ bottom: '0', right: space })(theme)
      )}

  ${(checked === 'mixed' || checked) &&
      css`
        background: ${theme.colors[inputBackgroundCheckedColor]};
        border-color: ${theme.fontColors[borderCheckedColor]};
      `}

  ${disabled &&
      css`
        cursor: default;
        border-color: ${theme.fontColors[inputBorderDisabledColor]};
      `}

  ${disabled &&
      (checked === 'mixed' || checked) &&
      css`
        background: ${theme.colors[inputBackgroundDisabledColor]};
      `}

  ${responsive(size, (size) => {
        return css`
          height: ${checkboxSizes[size]}px;
          width: ${checkboxSizes[size]}px;
          min-width: ${checkboxSizes[size]}px;
        `;
      })}
    `;

export const style = { container, input, label, customInput };
