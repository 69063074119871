import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { Heading, Text } from 'src/components/Text';
import { Responsive } from 'src/design-system/style-types';

import { IconName } from '../Icon/Icon.types';

interface Props {
  height?: Responsive<string>;
  fullHeight?: boolean;
  largeHeight?: boolean;
  smallText?: boolean;
  title?: string;
  body?: string;
  icon?: IconName;
  iconHeight?: Responsive<string>;
  iconWidth?: Responsive<string>;
}

export const FailedToLoad = ({
  body,
  fullHeight,
  height,
  icon,
  iconHeight,
  iconWidth,
  largeHeight,
  smallText,
  title,
}: Props) => {
  const getHeight = () => {
    if (height) return height;
    if (fullHeight) return '100%';
    if (largeHeight) return '400px';
  };

  return (
    <Box a="center" j="center" h={getHeight()}>
      <Box a="center" d="column">
        <Icon
          color="grey"
          name={icon || 'cancel'}
          marginBottom="sm"
          size={smallText ? 'lg' : '2xl'}
          height={iconHeight}
          width={iconWidth}
        />

        <Heading color="grey" marginBottom="xs" data-qa="heading" tag="h6" size={smallText ? 'md' : 'lg'}>
          {title || <FormattedMessage id="failed_to_load.title" defaultMessage="Failed to load" />}
        </Heading>

        <Text align="center" color="grey" size={smallText ? 'xs' : 'sm'} data-qa="blurbLine1" marginBottom="xs">
          {body || (
            <FormattedMessage
              id="failed_to_load.blurb.line_1"
              defaultMessage="There has been an error while trying to fetch this data."
            />
          )}
        </Text>

        {!body && (
          <Text align="center" color="grey" size={smallText ? 'xs' : 'sm'} data-qa="blurbLine2">
            <FormattedMessage id="failed_to_load.blurb.line_2" defaultMessage="Refresh the page to try again." />
          </Text>
        )}
      </Box>
    </Box>
  );
};
