import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { notificationsActions } from './actions';

export const notificationsPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(notificationsActions.addReadNotification): {
      persister.set({ key: 'notifications', item: store.getState().notifications, isPerUser: true });
      break;
    }
  }

  return res;
};
