import { MembersListing } from 'src/orchd-client';
import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import { orgsActions } from './actions';

export const orgReducer = createRequestStateReducer(orgsActions.getOrg);

export const orgMembersReducer = createReducer(
  { data: undefined as MembersListing | undefined, loading: true, error: '', updated: -1 },
  (handleAction) => [
    handleAction(orgsActions.getOrgMembers.success, (state, { payload }) => ({
      ...state,
      error: '',
      data: payload.data,
      loading: false,
      updated: Date.now(),
    })),
    handleAction(orgsActions.getOrgMembers.error, (state, { payload }) => ({
      ...state,
      data: undefined,
      error: payload.error,
      loading: false,
    })),
  ]
);

export const orgsReducer = combineReducers({ org: orgReducer, orgMembers: orgMembersReducer });

export type OrgsState = ReturnType<typeof orgsReducer>;
