import { LoginMembership, SubscriptionDedicatedServers, Website, WebsiteKind, WebsiteStatus } from 'src/orchd-client';
import memoize from 'memoize-one';

import { isWebsiteNormalOrStaging } from 'src/utils/isWebsiteNormal';

import { isOwnWebsite } from '../isOwnWebsite/isOwnWebsite';

export const websiteActionNames = [
  'enable',
  'disable',
  'markDeleted',
  'restore',
  'unsuspend',
  'permanentlyDelete',
  'moveServer',
  'changePackage',
  'suspend',
  'updatePhpVersion',
] as const;

export type WebsiteAction = (typeof websiteActionNames)[number];

export const getWebsitesByStatus = memoize((websites: Website[], member: LoginMembership) => {
  return websites.reduce(
    (acc, website) => {
      if (website.status === WebsiteStatus.deleted) {
        return {
          ...acc,
          deleted: [...acc.deleted, website],
        };
      }

      if (website.suspendedBy) {
        return {
          ...acc,
          suspended: [...acc.suspended, website],
        };
      }
      return { ...acc, [website.status]: [...acc[website.status], website] };
    },
    {
      [WebsiteStatus.active]: [],
      suspended: [],
      [WebsiteStatus.disabled]: [],
      [WebsiteStatus.deleted]: [],
    } as Record<WebsiteStatus | 'suspended', Website[]>
  );
});

// * NOTE *
// service websites (roundcube, control, phpmyadmin) can have their email role and backup role moved but not the app/db role
// serverHostname websites cannot be moved
// staging and normal can be moved
export const moveableKind = (website: Website, serverTypeId?: keyof SubscriptionDedicatedServers) => {
  if (website.kind === WebsiteKind.serverHostname) {
    return false;
  }

  if (
    serverTypeId &&
    [WebsiteKind.roundcube, WebsiteKind.controlPanel, WebsiteKind.phpMyAdmin].includes(website.kind)
  ) {
    if (['appServerId', 'dbServerId'].includes(serverTypeId)) {
      return false;
    }
    // email and backup
    return true;
  }
  // staging and normal
  return true;
};

const removeOwnWebsites = (websites: Website[], member: LoginMembership) =>
  websites.filter((website) => website.orgId !== member.orgId);

export const getActionableWebsites = memoize(
  (
    { active, suspended, disabled, deleted }: Record<WebsiteStatus | 'suspended', Website[]>,
    member: LoginMembership
  ): Record<WebsiteAction, Website[]> => {
    const disable = active.filter(({ kind }) => isWebsiteNormalOrStaging(kind));
    const activeOnlyServerHostname = active.filter(({ kind }) => kind === WebsiteKind.serverHostname);
    const activeWithoutServerHostname = active.filter(({ kind }) => kind !== WebsiteKind.serverHostname);

    const markDeleted = [...activeWithoutServerHostname, ...suspended, ...disabled];
    const restore = deleted;
    const unsuspend = removeOwnWebsites(suspended, member).filter(({ kind }) => isWebsiteNormalOrStaging(kind));
    const suspend = removeOwnWebsites([...active, ...disabled], member).filter(({ kind }) =>
      isWebsiteNormalOrStaging(kind)
    );
    const enable = disabled.filter(({ kind }) => isWebsiteNormalOrStaging(kind));
    const permanentlyDelete = [...deleted, ...activeOnlyServerHostname];
    const moveServer = member.isMasterOrg
      ? [...active, ...suspended, ...disabled, ...deleted].filter((website) => moveableKind(website))
      : [];
    const updatePhpVersion = member.isMasterOrg ? [...active, ...disabled] : [];
    const changePackage = active.filter(({ kind }) => isWebsiteNormalOrStaging(kind));
    return {
      disable,
      markDeleted,
      restore,
      unsuspend,
      suspend,
      enable,
      permanentlyDelete,
      moveServer,
      changePackage,
      updatePhpVersion,
    };
  }
);

export function getWebsitesWithRestoringStatus<T extends Website>(
  websites: T[],
  restoringWebsitesIdMap: Record<string, boolean>
): (T & { isRestoring?: boolean })[] {
  return websites.map((website) => ({ ...website, isRestoring: !!restoringWebsitesIdMap[website.id] }));
}

export const getWebsitesWithRestoringStatusMemoized = memoize(getWebsitesWithRestoringStatus);

export function isWebsiteActive<T extends Website & { isRestoring?: boolean }>(website: T) {
  return !website.suspendedBy && !website.isRestoring && website.status === WebsiteStatus.active;
}

export function isWebsiteSuspendedForMember<T extends Website & { isRestoring?: boolean }>(
  member: LoginMembership,
  website?: T
) {
  return Boolean(website?.suspendedBy && website.suspendedBy !== member.orgId);
}

export function isDomainAlias(website?: Website) {
  return !!website && website.aliases.some((alias) => alias.domain === website.domain.domain);
}

export const getPillStatus = (website: Website) => {
  const { status, suspendedBy } = website;
  if (status === WebsiteStatus.deleted) return WebsiteStatus.deleted;
  if (suspendedBy) return 'suspended' as const;
  return status;
};

export const isWebsiteSelectable = (member: LoginMembership, website?: Website) => {
  const isOwnControlPanel = website?.kind === WebsiteKind.controlPanel && isOwnWebsite(member.orgId, website);

  return !isOwnControlPanel && !isDomainAlias(website);
};
