import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { globalUiActions } from './actions';

export const globalUiPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(globalUiActions.setDarkMode):
      persister.set({ key: 'globalUi', item: store.getState().globalUi });
  }

  return res;
};
