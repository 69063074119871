import { css } from '@emotion/core';

import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './CardHeader';

const header =
  ({ backgroundColor = 'light', padding = 'lg', px, py }: Props) =>
  ({ colors, paddings }: Theme) =>
    css`
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      ${responsive(
        padding,
        (padding) => css`
          padding: ${paddings[padding]}px;
        `
      )}

      ${responsive(
        backgroundColor,
        (backgroundColor) => css`
          background-color: ${colors[backgroundColor]};
        `
      )}
    ${px &&
      responsive(
        px,
        (px) => css`
          padding-top: ${paddings[px]}px;
          padding-bottom: ${paddings[px]}px;
        `
      )}
    ${py &&
      responsive(
        py,
        (py) => css`
          padding-top: ${paddings[py]}px;
          padding-bottom: ${paddings[py]}px;
        `
      )}
    `;

export const style = { header };
