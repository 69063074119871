import { AxiosError } from 'axios';

export const isUnlicensed = ({ response }: AxiosError) => {
  if (!response) {
    return false;
  }

  const { status, data } = response;
  return status === 403 && data?.code === 'invalid_licence';
};
