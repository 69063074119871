import { appsApi } from 'src/api_services/apps';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = `@apps`;

export const getInstallableApps = createActionsFromMethod<typeof appsApi.getInstallableApps>(
  `getInstallableApps(${namespace})`
);

export const getGlobalInstallableApps = createActionsFromMethod<typeof appsApi.getGlobalInstallableApps>(
  `getGlobalInstallableApps(${namespace})`
);

export const createWebsiteApp = createActionsFromMethod<typeof appsApi.createWebsiteApp>(
  `createWebsiteApp(${namespace})`
);

export const appsActions = {
  createWebsiteApp,
  getGlobalInstallableApps,
  getInstallableApps,
};
