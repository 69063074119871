import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import { filerdActions } from './actions';

export const filerdReducer = combineReducers({
  checkEntry: createRequestStateReducer(filerdActions.checkEntry),
  getWebsiteEntries: createRequestStateReducer(filerdActions.getWebsiteEntries),
  entryMetadata: createRequestStateReducer(filerdActions.getEntryMetadata),
});
