import { ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Card } from 'src/components/Card';
import { Icon } from 'src/components/Icon';
import { IconName } from 'src/components/Icon/Icon.types';
import { Text } from 'src/components/Text';

import { style } from './QuickLinks.style';

interface Props {
  label: ReactNode;
  url: string;
  icon: IconName;
  highlightText?: string;
}

export const QuickLinkCard = ({ label, url, icon, highlightText }: Props) => {
  return (
    <Link to={url} css={{ width: '100%' }} aria-label={String(label)}>
      <Card noShadow css={style.container}>
        <div css={style.card}>
          <Box pb="sm" j="center">
            <Icon name={icon} size="2xl" />
          </Box>

          <Text size="sm" fullWidth align="center" highlightText={highlightText}>
            {label}
          </Text>
        </div>
      </Card>
    </Link>
  );
};
