import { css } from '@emotion/core';

import { zIndex } from 'src/utils/zIndex';

const devtoolsContainer = () => css`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${zIndex.Devtools};
`;

const devtoolsToggle = () => css`
  width: 200px;
  cursor: pointer;
`;

const devtoolsExpanded = () => css`
  width: 400px;
`;

export const style = {
  devtoolsContainer,
  devtoolsToggle,
  devtoolsExpanded,
};
