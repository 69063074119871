import { FormattedMessage } from 'react-intl';

import { messages } from 'src/messages';
import { alertModalActions } from 'src/store/alert-modal';

import { UploadMode } from '../constants';
import { fileManagerActions } from './actions';

const deleteEntriesModal = ({
  selected,
  type,
  onConfirm,
}: {
  selected: string[];
  type: string;
  onConfirm: ({ hide }: { hide: () => void }) => void;
}) =>
  alertModalActions.showAlertModal({
    variant: 'error',
    titleText: (
      <FormattedMessage
        id="website.files.delete.modal.title"
        defaultMessage="Delete {count, plural, one {{type}} other {items}}"
        values={{ count: selected.length, type }}
      />
    ),
    bodyText: (
      <FormattedMessage
        id="website.files.delete.modal.body"
        defaultMessage="{count, plural, one {''{filename}'' {type} {type, select, folder {and all files and folders within it} other {}}} other {# items}} will be deleted."
        values={{ count: selected.length, type, filename: selected[0] }}
      />
    ),
    primaryButton: {
      text: <FormattedMessage {...messages.delete} />,
      onClick: async ({ hide, loadingStart }) => {
        loadingStart();

        onConfirm({ hide });
      },
    },
  });

const deleteEntries = (
  websiteId: string,
  entries: string[],
  callbacks?: { onSuccess?: () => void; onError?: () => void }
) =>
  fileManagerActions.deleteEntries.request({
    params: { websiteId, inlineObject1: { entries } },
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError,
  });

const pasteEntries = ({
  websiteId,
  entries,
  destination,
  onSuccess,
  onError,
}: {
  websiteId: string;
  entries: string[];
  destination: string;
  onSuccess?: () => void;
  onError?: () => void;
}) =>
  fileManagerActions.mapEntries.request({
    params: { websiteId, paths: entries, destination, action: 'copy', overwrite: true },
    onSuccess,
    onError,
  });

const moveEntries = (
  websiteId: string,
  entries: string[],
  destination: string,
  names?: string[],
  callbacks?: { onSuccess?: () => void; onError?: () => void }
) =>
  fileManagerActions.mapEntries.request({
    params: { websiteId, paths: entries, destination, action: 'rename', overwrite: true, names },
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError,
  });

const createEntries = (
  websiteId: string,
  path: string,
  files?: File[],
  folders?: string[],
  mode: UploadMode = UploadMode.Full,
  callbacks?: { onSuccess?: () => void; onError?: () => void }
) =>
  fileManagerActions.createEntries.request({
    params: { websiteId, files, folders, path, mode, overwrite: true },
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError,
  });

const compressEntries = (
  websiteId: string,
  entries: string[],
  destination: string,
  callbacks?: { onSuccess?: () => void; onError?: () => void }
) =>
  fileManagerActions.modifyEntries.request({
    params: { websiteId, path: destination, action: 'compress', overwrite: true, inlineObject: { entries } },
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError,
  });

const uncompressEntries = (
  websiteId: string,
  path: string,
  callbacks?: { onSuccess?: () => void; onError?: () => void }
) =>
  fileManagerActions.mapEntries.request({
    params: { websiteId, paths: [path], action: 'uncompress', overwrite: true },
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError,
  });

const changePermissions = (
  websiteId: string,
  path: string,
  permissions: number,
  recursive: boolean,
  callbacks?: { onSuccess?: () => void; onError?: () => void }
) =>
  fileManagerActions.setMetadata.request({
    params: { websiteId, path, permissions, recursive },
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError,
  });

export const fileManagerCrudActions = {
  deleteEntries,
  deleteEntriesModal,
  pasteEntries,
  moveEntries,
  createEntries,
  changePermissions,
  compressEntries,
  uncompressEntries,
};
