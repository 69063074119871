export const cnameRegex = /^(?:[a-z0-9_](?:[a-z0-9-_]{0,61}[a-z0-9_])?\.)+[a-z0-9_][a-z0-9-_]{0,61}[a-z0-9_]$/;
export const cnameRegexDot = /^(?:[a-z0-9_](?:[a-z0-9-_]{0,61}[a-z0-9_])?\.)+[a-z0-9_][a-z0-9-_]{0,61}[a-z0-9_]\.?$/i;
export const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const domainRegexDot = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\.?$/i;
export const databaseRegex = /^[a-z0-9_]+$/;
export const noHttpOrWww = /^(?!(http|https):\/\/)(?!www\.)/;
export const ipv4CidrRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(\/(3[0-2]|[1-2][0-9]|[0-9]))?$/;
export const ipv4IpAddressRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
export const ipv4WildcardIpAddressRegex =
  /^((?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]|%)\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9]|%))$|^%$/;
export const ipv6IpAddressRegex =
  /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
export const ipAddressRegex = new RegExp(`${ipv4IpAddressRegex.source}|${ipv6IpAddressRegex.source}`);
export const usernameRegex = /^[\w.-]+$/;
export const hexColorValidationRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
export const ftpAccountRegex = /^[\w_.+-]+$/;
export const dirRegex = /^[/\w_.-]+$/;
export const numericOnlyRegex = /^[0-9]+$/;
export const floatRegex = /^([0-9]*[.])?[0-9]+$/;
export const floatInputRegex = /^([0-9]*\.)?([0-9]+)?$/;
export const subDomainRegexAllowSpecialChars = /[\w\d]+(\.[\w\d])+/;
export const subDomainRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9-._]*[a-zA-Z0-9]+$/;
export const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const emailUsernameRegex = /^[A-Za-z0-9_.+-]+$/;
export const emailFullRegex =
  /^[A-Za-z0-9_.+=-]+@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const unixUserRegex = (unixUser: string) => new RegExp(`^${unixUser}_`);
export const passwordLowerCase = /[a-z]/;
export const passwordUpperCase = /[A-Z]/;
export const passwordNumber = /[\d]/;
export const passwordSpecial = /[#?!@$%^&*-._+/';:]/;
export const localMxRecord = (origin: string) => new RegExp(`^(\\d+\\s)?mail\\.${origin}$`);
export const textFieldNumber = /^[0-9]*\.{0,1}[0-9]*$/;
export const dnsHostnameRegex = /^(?:[\w*@]|(?!.*\*[^.].*)(?!.*__.*)(?!.*\.\..*)[a-z0-9_*][a-z0-9_.-]*[a-z0-9_])$/i;
export const integersOnly = /^[0-9]*$/;
export const cronMinuteRegex =
  /^(\*|[1-5]?[0-9](-[1-5]?[0-9])?)(\/[1-9][0-9]*)?(,(\*|[1-5]?[0-9](-[1-5]?[0-9])?)(\/[1-9][0-9]*)?)*$/;
export const cronHourRegex =
  /^(\*|(1?[0-9]|2[0-3])(-(1?[0-9]|2[0-3]))?)(\/[1-9][0-9]*)?(,(\*|(1?[0-9]|2[0-3])(-(1?[0-9]|2[0-3]))?)(\/[1-9][0-9]*)?)*$/;
export const cronDayRegex =
  /^(\*|([1-9]|[1-2][0-9]?|3[0-1])(-([1-9]|[1-2][0-9]?|3[0-1]))?)(\/[1-9][0-9]*)?(,(\*|([1-9]|[1-2][0-9]?|3[0-1])(-([1-9]|[1-2][0-9]?|3[0-1]))?)(\/[1-9][0-9]*)?)*$/;
export const cronMonthRegex =
  /^(\*|([1-9]|1[0-2]?)(-([1-9]|1[0-2]?))?)(\/[1-9][0-9]*)?(,(\*|([1-9]|1[0-2]?)(-([1-9]|1[0-2]?))?)(\/[1-9][0-9]*)?)*$/;
export const cronWeekdayRegex = /^(\*|[1-7](-[1-7])?)(\/[1-9][0-9]*)?(,(\*|[1-7](-[1-7])?)(\/[1-9][0-9]*)?)*$/;
export const cronCommandRegex = /^[^#\s].+$/;
export const urlRegex =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_+.~#?&!//=]*)$/;
export const uuidRegex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
export const startsWithSlashRegex = /^\//;
export const specialHtmlCharactersRegex = /&[#\w]+;/;
