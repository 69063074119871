import { subscriptionsApi } from 'src/api_services/subscriptions/service';
import { createActionsFromMethod } from 'src/store/helpers';

export const namespace = '@subscriptions';

/** @deprecated */
const getSubscriptionsToParent = createActionsFromMethod<typeof subscriptionsApi.getSubscriptionsToParent>(
  `getSubscriptionsToParent(${namespace})`
);

/** @deprecated */
export const subscriptionsActions = {
  getSubscriptionsToParent,
};
