import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import * as actions from './actions';

const installableWebsiteAppsReducer = createRequestStateReducer(actions.getInstallableApps, {
  loading: false,
  error: '',
  data: {
    items: [],
  },
});

const globalInstallableWebsiteAppsReducer = createRequestStateReducer(actions.getGlobalInstallableApps, {
  loading: false,
  error: '',
  data: {
    items: [],
  },
});

export const appsReducer = combineReducers({
  installableWebsiteApps: installableWebsiteAppsReducer,
  globalInstallableWebsiteApps: globalInstallableWebsiteAppsReducer,
});
