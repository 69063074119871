import { useMutation, useQuery } from '@tanstack/react-query';

import { LooseAutocomplete } from 'src/utils/types';
import { padToPatchVersion } from 'src/utils/version/version';

import { queryKeys } from '../query-keys';
import { wordPressApi } from './service';

export const useGetWordpressPluginsQuery = ({
  appId,
  orgId,
  websiteId,
  refreshCache = false,
  enabled = true,
}: {
  appId: string;
  orgId: string;
  websiteId: string;
  refreshCache?: boolean;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.wordpressPlugins({ appId, orgId, websiteId }),
    queryFn: () => wordPressApi.getWordpressPlugins({ appId, orgId, websiteId, refreshCache }),
    select: (data) => data.data.items,
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useGetWordpressUsersQuery = ({
  appId,
  orgId,
  websiteId,
}: Parameters<typeof wordPressApi.getWordpressUsers>[0]) => {
  return useQuery({
    queryKey: queryKeys.wordpressUsers({ appId, orgId, websiteId }),
    queryFn: () => wordPressApi.getWordpressUsers({ appId, orgId, websiteId }),
    select: (data) => data.data.items,
    refetchOnWindowFocus: false,
  });
};

export const useGetWordpressAppVersionQuery = ({
  appId,
  orgId,
  websiteId,
  enabled = true,
}: Parameters<typeof wordPressApi.getWordpressAppVersion>[0] & { enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.wordpressAppVersion({ appId, orgId, websiteId }),
    queryFn: () => wordPressApi.getWordpressAppVersion({ appId, orgId, websiteId }),
    select: (data) => padToPatchVersion(data.data.version),
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useGetWordpressSettingsQuery = ({
  appId,
  orgId,
  websiteId,
}: Parameters<typeof wordPressApi.getWordpressSettings>[0]) => {
  return useQuery({
    queryKey: queryKeys.wordpressSettings({ appId, orgId, websiteId }),
    queryFn: () => wordPressApi.getWordpressSettings({ appId, orgId, websiteId }),
    select: (data) => data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetDefaultWpSsoUserQuery = ({
  appId,
  orgId,
  websiteId,
}: Parameters<typeof wordPressApi.getDefaultWpSsoUser>[0]) => {
  return useQuery({
    queryKey: queryKeys.defaultWpSsoUser({ appId, orgId, websiteId }),
    queryFn: () => wordPressApi.getDefaultWpSsoUser({ appId, orgId, websiteId }),
    select: (data) => data.data,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useSetDefaultWpSsoUserMutation = () => useMutation({ mutationFn: wordPressApi.setDefaultWpSsoUser });

export const useGetWordpressConfigQuery = ({
  appId,
  orgId,
  websiteId,
  wpOption,
}: {
  appId: string;
  orgId: string;
  websiteId: string;
  wpOption: LooseAutocomplete<'WpDebug' | 'WpDebugLog' | 'WpDebugDisplay'>;
}) => {
  return useQuery({
    queryKey: queryKeys.wordpressConfig({ appId, orgId, websiteId, wpOption }),
    queryFn: () => wordPressApi.getWordpressConfig({ appId, orgId, websiteId, wpOption: wpOption as string }),
    select: (data) => data.data,
  });
};

export const useGetWordpressSiteUrlQuery = ({ appId, enabled = true }: { appId: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.wordpressSiteUrl({ appId }),
    queryFn: () => wordPressApi.getWordPressSiteurl({ appId }),
    select: (data) => data.data,
    staleTime: 60000,
    enabled,
  });
};

export const useGetWordpressMaintenanceModeQuery = ({ appId }: { appId: string }) => {
  return useQuery({
    queryKey: queryKeys.wordpressMaintenanceMode({ appId }),
    queryFn: () => wordPressApi.getWordPressMaintenanceMode({ appId }),
    select: (data) => data.data,
  });
};

export const useSetWordpressSiteUrlMutation = () => useMutation({ mutationFn: wordPressApi.setWordPressSiteurl });

export const useUpdateWordpressSettingsMutation = () =>
  useMutation({ mutationFn: wordPressApi.updateWordpressSettings });

export const useSetWordpressConfigMutation = () => useMutation({ mutationFn: wordPressApi.setWordpressConfig });

export const useCreateWordpressUserMutation = () => useMutation({ mutationFn: wordPressApi.createWordpressUser });

export const useInstallWordpressPluginMutation = () => useMutation({ mutationFn: wordPressApi.installWordpressPlugin });

export const useUpdateWordpressPluginSettingsMutation = () =>
  useMutation({ mutationFn: wordPressApi.updateWordpressPluginSettings });

export const useSetWordpressMaintenanceModeMutation = () =>
  useMutation({ mutationFn: wordPressApi.setWordPressMaintenanceMode });

export const useGetWordpressThemesQuery = ({
  orgId,
  websiteId,
  appId,
  refreshCache,
  enabled = true,
}: {
  orgId: string;
  websiteId: string;
  appId: string;
  refreshCache?: boolean;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.wordpressThemes({ orgId, websiteId, appId }),
    queryFn: () => wordPressApi.getWordpressThemes({ orgId, websiteId, appId, refreshCache }),
    select: (data) => data.data,
    enabled,
    keepPreviousData: true,
  });
};

export const useInstallWordpressThemeMutation = () => useMutation({ mutationFn: wordPressApi.installWordpressTheme });

export const useUpdateWordpressThemeMutation = () => useMutation({ mutationFn: wordPressApi.updateWordpressTheme });

export const useDeleteWordpressThemeMutation = () => useMutation({ mutationFn: wordPressApi.deleteWordpressTheme });

export const useSetWordpressThemeAutoUpdateStatusMutation = () =>
  useMutation({ mutationFn: wordPressApi.setWordpressThemeAutoUpdateStatus });

export const useActivateWordpressThemeMutation = () => useMutation({ mutationFn: wordPressApi.activateWordpressTheme });

export const useGetWordpressInstallationsMutation = () =>
  useMutation({ mutationFn: wordPressApi.getWordpressInstallations });
