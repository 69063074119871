import { messages } from 'src/messages';
import { store } from 'src/store';
import { alertModalActions } from 'src/store/alert-modal';
import { sessionSelectors } from 'src/store/session/selectors';
import { toastActions } from 'src/store/toast/actions';
import { intl } from 'src/utils/intl/intl';

export const handleIsUnlicensed = async () => {
  const { formatMessage } = await intl();
  const isMasterOrg = sessionSelectors.selectIsMasterOrg(store.getState());

  if (isMasterOrg) {
    setTimeout(() => {
      store.dispatch(
        alertModalActions.showAlertModal({
          variant: 'info',
          titleText: formatMessage({
            id: 'invalid_licence.mo.title',
            defaultMessage: 'Renew your licence',
          }),
          bodyText: formatMessage({
            id: 'invalid_licence.mo.blurb',
            defaultMessage:
              'This action failed because you do not have a valid licence. Actions you and your customers perform will continue to fail until your licence is renewed.',
          }),
          primaryButton: {
            text: formatMessage(messages.ok_got_it),
            onClick: ({ hide }) => hide(),
          },
          hideCloseButton: true,
        })
      );
    }, 500); // todo timeout required to allow existing alert modals to close, could be better
  } else {
    store.dispatch(
      toastActions.errorToast(
        formatMessage({
          id: 'invalid_licence.eu',
          defaultMessage: 'Something has gone wrong. Please contact your provider.',
        })
      )
    );
  }
};
