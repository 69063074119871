import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { plansActions } from './actions';

const getPlanReducer = createRequestStateReducer(plansActions.getPlan);
const getPlansReducer = createRequestStateReducer(plansActions.getPlans);

export const plansReducer = combineReducers({
  plan: getPlanReducer,
  plans: getPlansReducer,
});
