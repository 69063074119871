import { AppState } from 'src/store';
import { isResellerSubscription } from 'src/utils/packages/utils';

const selectSubscriptions = (state: AppState) => state.subscriptions.subscriptions.data.items;

/** @deprecated */
const selectIsReseller = (state: AppState): boolean => selectSubscriptions(state).some(isResellerSubscription);

/** @deprecated */
export const subscriptionsSelectors = {
  selectIsReseller,
};
