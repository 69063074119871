import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const toastViewport = (theme: Theme) =>
  css`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: ${theme.paddings.xl}px;
    gap: 10px;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: ${zIndex.ToastHub};
    outline: none;

    ${theme.settings.rtl &&
    `
      left: auto;
      right: 0
    `}
  `;

export const style = { toastViewport };
