import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';

import {
  ADD_POPPER,
  HIDE_ALL_POPPERS,
  HIDE_POPPER,
  PoppersActionTypes,
  PoppersState,
  REMOVE_POPPER,
  SHOW_POPPER,
} from './types';

export const initialState: PoppersState = {};

export function poppersReducer(state: PoppersState = initialState, action: PoppersActionTypes): PoppersState {
  switch (action.type) {
    case ADD_POPPER:
      return {
        ...state,
        [action.name]: false,
      };

    case REMOVE_POPPER:
      return _omit(state, action.name);

    case SHOW_POPPER:
      return {
        ...state,
        [action.name]: true,
      };

    case HIDE_POPPER:
      return {
        ...state,
        [action.name]: false,
      };

    case HIDE_ALL_POPPERS:
      return _mapValues(state, () => false);

    default:
      return state;
  }
}

export default poppersReducer;
