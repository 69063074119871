import { KeyboardEvent, MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/core';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import { useVirtualizer } from '@tanstack/react-virtual';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Checkbox } from 'src/components/Checkbox';
import { Icon } from 'src/components/Icon';
import { Loading } from 'src/components/Loading';
import { Text } from 'src/components/Text';
import { useDebounce } from 'src/hooks/useDebounce/useDebounce';

import { style } from './Select.style';
import { SelectOption, SelectVariant } from './types';

export interface Props {
  name: string;
  value: SelectOption[];
  options: SelectOption[];
  placeholder?: ReactNode;

  onChange: ({ name, value }: { name: string; value: SelectOption[] }) => void;
  onSearch?: (value: string) => void;
  renderValue?: (props: ValueProps) => ReactNode;
  renderOption?: (props: OptionPropsNew) => ReactNode;

  isLoading?: boolean;
  variant?: SelectVariant;
  autoFocus?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  multi?: boolean;
  async?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  touched?: boolean;

  maxWidth?: number;
  maxMenuHeight?: number;
  flex?: number;
  estimatedOptionHeight?: number;
}

export const Select = ({
  name,
  value,
  options,
  placeholder,
  onChange,
  onSearch,
  renderValue = BaseValue,
  renderOption = BaseOption,
  isLoading,
  variant,
  autoFocus = false,
  searchable = false,
  clearable = false,
  multi = false,
  async = false,
  disabled = false,
  invalid = false,
  touched = false,
  maxWidth,
  maxMenuHeight = 340,
  flex = 1,
  estimatedOptionHeight = 40,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [activeItem, setActiveItem] = useState(options.findIndex((option) => !option.isDisabled) ?? 0);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [listRef, setListRef] = useState<HTMLDivElement | null>(null);

  const filteredOptions = useMemo(() => {
    setActiveItem(0);

    if (!search || async || !searchable) {
      return options;
    }

    return options.filter((option) => {
      if (option.searchableBy) {
        return option.searchableBy.toLowerCase().includes(search.toLowerCase());
      }

      const label = option.label?.toString().toLowerCase() ?? '';

      if (!label || typeof option.label !== 'string') {
        return true;
      }

      return label.includes(search.toLowerCase());
    });
  }, [search, options, async, searchable]);

  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => listRef,
    estimateSize: () => estimatedOptionHeight,
    overscan: 4,
  });

  const handleClear = (e?: MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    setSearch('');
    setActiveItem(0);

    onChange({ name, value: [] });
  };

  const handleChange = (optionValue: string | number) => {
    if (multi) {
      if (value.some((selected) => selected.value === optionValue)) {
        return onChange({ name, value: value.filter((selected) => selected.value !== optionValue) });
      }

      return onChange({ name, value: [...value, options.find((option) => option.value === optionValue)!] });
    }

    setOpen(false);

    return onChange({ name, value: [options.find((option) => option.value === optionValue)!] });
  };

  const hideUnlessTriggerClick = (event: CustomEvent) => {
    if (
      triggerRef.current &&
      (triggerRef.current.contains(event.target as Node) || triggerRef.current === event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setSearch('');
      setActiveItem(0);
    }
  }, [open, setSearch, setActiveItem]);

  useDebounce(
    () => {
      onSearch?.(search);
    },
    [search],
    300
  );

  const isActionableKey = (action: () => void) => (e: KeyboardEvent) => {
    if (e.key === 'Enter' || (e.key === ' ' && !search)) {
      e.preventDefault();
      e.stopPropagation();

      action();
    }
  };

  const isEscapeKey = (action: () => void) => (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();

      action();
    }
  };

  const isClearKey = (action: () => void) => (e: KeyboardEvent) => {
    if ((e.key === 'Backspace' || e.key === 'Delete') && clearable && !search) {
      e.preventDefault();
      e.stopPropagation();

      action();
    }
  };

  const handlePositionalKey = (e: KeyboardEvent) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      e.stopPropagation();

      const total = filteredOptions.length;
      const reversedOptions = filteredOptions.slice().reverse();
      const lastActiveItem = reversedOptions.findIndex((option) => !option.isDisabled) ?? 0;
      const newActiveItemIndex = reversedOptions.findIndex(
        (option, key) => key > total - activeItem - 1 && !option.isDisabled
      );
      const newCorrectedIndex = total - newActiveItemIndex - 1;
      const newActiveItem = newCorrectedIndex === total ? total - lastActiveItem - 1 : newCorrectedIndex;

      setActiveItem(newActiveItem);

      document
        .querySelector(`#${name}-dropdown [role="menuitem"]:nth-of-type(${newActiveItem})`)
        ?.scrollIntoView({ block: 'center' });
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation();

      const firstActiveItem = filteredOptions.findIndex((option) => !option.isDisabled) ?? 0;
      const newActiveItemIndex = filteredOptions.findIndex((option, key) => key > activeItem && !option.isDisabled);
      const newActiveItem = newActiveItemIndex === -1 ? firstActiveItem : newActiveItemIndex;

      setActiveItem(newActiveItem);

      document
        .querySelector(`#${name}-dropdown [role="menuitem"]:nth-of-type(${newActiveItem})`)
        ?.scrollIntoView({ block: 'center' });
    }
  };

  const container = document.getElementById('modal-radix-root') ?? document.getElementById('radix-root');

  return (
    <Root open={open}>
      <Trigger asChild>
        <div
          onClick={() => setOpen(true)}
          onKeyDown={(e) => {
            isActionableKey(() => setOpen(true))(e);
            isClearKey(handleClear)(e);
          }}
          css={style.trigger({ open, variant, invalid, touched, searchable, maxWidth, flex })}
          ref={triggerRef}
          role="button"
          aria-disabled={disabled}
          aria-label={name}
          tabIndex={disabled ? -1 : 0}
        >
          <div css={{ flex: 1, minWidth: 0 }}>
            {renderValue({ value, open, variant, placeholder, searchable, handleChange })}
          </div>

          <Box w="auto" a="center" wrap="nowrap" gap="md">
            {isLoading && async && <Loading size="lg" />}

            {clearable && value.length > 0 && (
              <div css={style.closeIcon} tabIndex={0} onClick={handleClear} onKeyDown={isActionableKey(handleClear)}>
                <Icon name="close" size="sm" color="dark" tabIndex={-1} />
              </div>
            )}

            <Icon name="chevron down" color={variant === 'filter' ? 'grey' : 'dark'} />
          </Box>
        </div>
      </Trigger>

      <Portal container={container}>
        <Content
          css={style.dropdown({ width: triggerRef.current?.clientWidth ?? 0, maxHeight: maxMenuHeight })}
          side="bottom"
          align="end"
          sideOffset={-6}
          alignOffset={0}
          id={`${name}-dropdown`}
          role="menu"
          ref={setListRef}
          onInteractOutside={hideUnlessTriggerClick}
          onKeyDown={(e) => {
            isEscapeKey(() => setOpen(false))(e);
            isActionableKey(() => filteredOptions[activeItem] && handleChange(filteredOptions[activeItem].value))(e);
            handlePositionalKey(e);
            isClearKey(() => {
              if (multi && value.length > 0) {
                handleChange(value[value.length - 1].value);
              }
            })(e);
          }}
        >
          <input
            aria-label="selectSearch"
            autoFocus={autoFocus}
            type="text"
            value={search}
            onChange={(e) => searchable && setSearch(e.target.value)}
            css={style.searchable({ hidden: !searchable })}
            onKeyDown={(e) => {
              isEscapeKey(() => setOpen(false))(e);
              isActionableKey(() => filteredOptions[activeItem] && handleChange(filteredOptions[activeItem].value))(e);
              handlePositionalKey(e);
              isClearKey(() => {
                if (multi && value.length > 0) {
                  handleChange(value[value.length - 1].value);
                }
              })(e);
            }}
          />

          {filteredOptions.length === 0 && (!isLoading || !async) && (
            <div css={style.dropdownItem({ active: false })}>
              <Text size="sm" color="grey" align="center" fullWidth>
                <FormattedMessage id="select.no_results" defaultMessage="Not found" />
              </Text>
            </div>
          )}

          <div role="menu" css={style.dropdownInner({ height: virtualizer.getTotalSize() })}>
            {virtualizer.getVirtualItems().map((item, key) => {
              const option = filteredOptions[item.index];

              return (
                <div
                  key={`${name}-${option.value}`}
                  role="menuitem"
                  aria-disabled={option.isDisabled}
                  css={style.dropdownItem({ active: activeItem === key, rowStart: item.start })}
                  onClick={() => handleChange(option.value)}
                  onKeyDown={isActionableKey(() => handleChange(option.value))}
                >
                  {renderOption({ name, option, value, variant, search })}
                </div>
              );
            })}
          </div>

          <Arrow offset={123} css={style.arrow} />
        </Content>
      </Portal>
    </Root>
  );
};

/**
 * Value subcomponents.
 */

export interface ValueProps {
  value: SelectOption[];
  open: boolean;
  handleChange: (optionValue: string | number) => void;
  placeholder?: ReactNode;
  variant?: SelectVariant;
  searchable?: boolean;
}

export const BaseValue = ({ value, open, variant, placeholder, searchable }: ValueProps) => {
  if (open && searchable) {
    return null;
  }

  if (value.length === 0) {
    return (
      <Text size={['filter', 'small'].includes(variant as string) ? 'sm' : 'md'} ellipsis color="grey">
        {placeholder}
      </Text>
    );
  }

  return (
    <Text
      size={['filter', 'small'].includes(variant as string) ? 'sm' : 'md'}
      ellipsis
      color={variant === 'filter' ? 'grey' : 'dark'}
    >
      {value[0].label ?? placeholder}
    </Text>
  );
};

export const PillsValue = ({ value, open, variant, placeholder, searchable, handleChange }: ValueProps) => {
  if (open && searchable) {
    return null;
  }

  if (value.length === 0) {
    return (
      <Text size={['filter', 'small'].includes('variant') ? 'sm' : 'md'} ellipsis color="grey">
        {placeholder}
      </Text>
    );
  }

  return (
    <Box gap="sm">
      {value.map(({ value, label }) => (
        <span
          key={value}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            handleChange(value);
          }}
          css={style.pill({ open, variant })}
        >
          {label}

          <Icon name="close" />
        </span>
      ))}
    </Box>
  );
};

/**
 * Option subcomponents.
 */

export interface OptionPropsNew {
  name: string;
  option: SelectOption;
  value: SelectOption[];
  variant?: SelectVariant;
  search?: string;
}

export const BaseOption = ({ option, variant, search }: OptionPropsNew) => {
  return (
    <Text size={['filter', 'small'].includes('variant') ? 'sm' : 'md'} ellipsis highlightText={search}>
      {option.label}
    </Text>
  );
};

export const CheckboxOption = ({ name, option, value, variant, search }: OptionPropsNew) => {
  return (
    <Checkbox
      css={{ pointerEvents: 'none' }}
      fullWidth
      name={`${name}-${option.value}`}
      size={variant === 'filter' ? 'md' : 'lg'}
      checked={value.some((selected) => selected.value === option.value)}
      inputBackgroundCheckedColor="accent"
      borderCheckedColor="accent"
      label={
        <Text size={['filter', 'small'].includes('variant') ? 'sm' : 'md'} ellipsis highlightText={search}>
          {option.label}
        </Text>
      }
    />
  );
};
