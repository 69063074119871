import { css } from '@emotion/core';
import { transparentize } from 'polished';

import { Theme } from 'src/design-system/theme';
import { checkColorParses } from 'src/utils/colors/colors';

import type { Props } from './Overlay';

const container = (props: Props) => (theme: Theme) =>
  css`
    pointer-events: ${props.unclickable ? 'none' : 'auto'};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: ${props.zIndex};
    transition: background, opacity 0.3s ${theme.easings.standard};
    opacity: ${props.show === 'always' ? 1 : 0};
    &:hover,
    &:focus {
      opacity: 1;
    }
    background: ${transparentize(props.alpha, checkColorParses(theme.colors[props.backgroundColor]))};
    * > * {
      z-index: ${props.zIndex};
      transition: background, opacity 0.3s ${theme.easings.standard};
    }
  `;

export const style = { container };
