import { getType } from 'deox';
import { call, delay, race, take, takeLatest, takeLeading } from 'redux-saga/effects';

import { serversApi } from 'src/api_services/servers/service';
import { websitesApi } from 'src/api_services/websites/service';
import { createRequestStateSaga } from 'src/store/helpers';

import { websitesDashboardActions } from './actions';

export const [getWebsitesSaga, refreshWebsitesSaga] = createRequestStateSaga(
  websitesDashboardActions.getWebsites,
  websitesApi.getWebsites
);

export const [getServersSaga] = createRequestStateSaga(websitesDashboardActions.getServers, serversApi.getServers);

const WEBSITES_REFRESH_INTERVAL_MS = 2 * 60 * 1000;
export function* intermittentlyRefreshWebsites() {
  while (true) {
    const { timeout } = yield race({
      timeout: delay(WEBSITES_REFRESH_INTERVAL_MS),
      getWebsitesRequest: take(getType(websitesDashboardActions.getWebsites.request)),
    });
    if (timeout) {
      yield call(refreshWebsitesSaga);
    }
  }
}

export function* websitesDashboardSaga() {
  yield takeLatest(getType(websitesDashboardActions.getWebsites.request), getWebsitesSaga);
  yield takeLeading(getType(websitesDashboardActions.getWebsites.request), intermittentlyRefreshWebsites);
  yield takeLatest(getType(websitesDashboardActions.getServers.request), getServersSaga);
}
