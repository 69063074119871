import React, { ReactNode } from 'react';
import { FontAlign, FontColors, FontSizes, FontWeights, Margins, Responsive } from 'src/design-system/style-types';

import Text from './Text';

const getWeight = (tag: string): FontWeights => (tag === 'h6' ? 'normal' : 'light');

interface RequiredProps {
  children: ReactNode;
}

interface DefaultProps {
  className: string;
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color: Responsive<FontColors>;
  marginBottom: Responsive<Margins>;
  marginRight: Responsive<Margins>;
  align: Responsive<FontAlign>;
  weight: '' | Responsive<FontWeights>;
  size?: Responsive<FontSizes>;
  ellipsis?: boolean;
  fullWidth?: boolean;
}

const defaultProps: DefaultProps = {
  ellipsis: false,
  className: '',
  tag: 'h1',
  color: 'dark',
  marginBottom: '0',
  marginRight: '0',
  align: 'left',
  weight: '',
};

const Heading = ({
  children,
  tag,
  color,
  marginBottom,
  marginRight,
  align,
  weight,
  className,
  size,
  ellipsis,
  fullWidth,
}: RequiredProps & DefaultProps) => {
  const weightValue = weight || getWeight(tag);

  return (
    <Text
      className={className}
      tag={tag}
      marginBottom={marginBottom}
      marginRight={marginRight}
      weight={weightValue}
      color={color}
      align={align}
      size={size}
      ellipsis={ellipsis}
      role="heading"
      fullWidth={fullWidth}
    >
      {children}
    </Text>
  );
};

Heading.defaultProps = defaultProps;

export default Heading;
