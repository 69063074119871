/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment, ReactNode } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { H3 } from 'src/components/Text';
import { Margins, Responsive } from 'src/design-system/style-types';
import { FontColors } from 'src/design-system/theme';

import { style } from './BackHeading.style';

export interface Props {
  onClick?: () => void;
  to?: string;
  title?: ReactNode;
  children?: ReactNode;
  bottomContent?: ReactNode;
  marginBottom?: Responsive<Margins>;
  marginRight?: Margins;
  fullWidth?: boolean;
  minWidth?: Responsive<string>;
  iconColor?: FontColors;
}

const BackHeading = (props: Props) => {
  const { bottomContent, children, onClick, title = '', to = '', iconColor } = props;

  const linkContent = () => (
    <Fragment>
      <Box wrap="nowrap" a="center" minW="0">
        <Icon name="chevron left" marginRight="md" enableRtl color={iconColor} />
        {title ? (
          <H3 ellipsis size={{ xs: 'md', sm: 'lg', md: 'xl' }}>
            {title}
          </H3>
        ) : (
          children
        )}
      </Box>
      {bottomContent && (
        <div data-qa="bottomContent" css={style.bottomContent}>
          {bottomContent}
        </div>
      )}
    </Fragment>
  );

  return onClick ? (
    <div data-qa="contentClickable" role="button" tabIndex={0} onClick={onClick} css={style.container(props)}>
      {linkContent()}
    </div>
  ) : (
    <Link data-qa="contentLink" to={to} css={style.container(props)}>
      {linkContent()}
    </Link>
  );
};

export { BackHeading };
