import { createActionCreator } from 'deox';

const namespace = '@globalSearch';

const pushRecentSearch = createActionCreator(
  `pushRecentSearch(${namespace})`,
  (resolve) => (payload: { term: string }) => resolve(payload)
);

const removeRecentSearch = createActionCreator(
  `removeRecentSearch(${namespace})`,
  (resolve) => (payload: { term: string }) => resolve(payload)
);

export const globalSearchActions = {
  pushRecentSearch,
  removeRecentSearch,
};
