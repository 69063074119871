import { Fragment, memo, MouseEvent, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Role } from 'src/orchd-client';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { IconButton } from 'src/components/IconButton';
import { Tooltip } from 'src/components/Tooltip';
import { messages } from 'src/messages';
import { LinkItem } from 'src/utils/types';

import { style } from './DashboardSpine.style';
import { DashboardSpineItem } from './DashboardSpineItem/DashboardSpineItem';

export interface Props {
  expanded: boolean;
  handleToggle: () => void;
  shrink: () => void;
  isDesktopUp: boolean;
  logoPath?: string;
  iconPath?: string;
  supportUrl?: string;
  billingUrl?: string;
  roles: Role[];
  spineLinks: LinkItem[];
  isImpersonationActive: boolean;
}

const _DashboardSpine = ({
  expanded,
  handleToggle,
  isDesktopUp,
  logoPath,
  iconPath,
  shrink,
  spineLinks,
  supportUrl,
  billingUrl,
  isImpersonationActive,
}: Props) => {
  const [open, setOpen] = useState(expanded);
  const { formatMessage } = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (expanded !== open) {
      setOpen(expanded);
    }
  }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNavItemClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (!isDesktopUp) {
      shrink();
    }
  };

  const setSpineOpen = (open: boolean) => {
    if (expanded) {
      return;
    }

    setOpen(open);
  };

  return (
    <Box
      w={{ xs: 'auto', sm: '70px', lg: expanded ? '220px' : '70px' }}
      shrink={0}
      wrap="nowrap"
      onMouseEnter={() => setSpineOpen(true)}
      onMouseLeave={() => setSpineOpen(false)}
    >
      <Box
        id="dashboard-spine"
        role="navigation"
        d="column"
        w={open ? '220px' : '70px'}
        shrink={0}
        css={style.navigation({ isImpersonationActive })}
        wrap="nowrap"
        hide={{ xs: !expanded, sm: false }}
      >
        <Box
          a="center"
          h="48px"
          j={open ? 'space-between' : 'center'}
          mt="lg"
          px="lg"
          shrink={0}
          wrap="nowrap"
          gap="lg"
        >
          {open && (
            <Fragment>
              <Box onClick={() => history.push('/')} css={style.logo(logoPath)} h="48px" w="120px" />

              <Tooltip
                button={
                  <IconButton
                    ariaLabel={formatMessage(messages.toggle_navigation_menu)}
                    dataQa="spine-toggle"
                    icon={isDesktopUp ? 'pin' : 'hamburger alt'}
                    iconColor={expanded ? 'light' : undefined}
                    variant="dark"
                    size="md"
                    onClick={handleToggle}
                  />
                }
              >
                {expanded ? (
                  <FormattedMessage id="spine.unpin_menu" defaultMessage="Unpin menu" />
                ) : (
                  <FormattedMessage id="spine.pin_menu" defaultMessage="Pin menu" />
                )}
              </Tooltip>
            </Fragment>
          )}

          {!open && <Box onClick={() => history.push('/')} css={style.logo(iconPath)} h="20px" w="20px" />}
        </Box>
        <Box mb="lg" by={{ xs: 1, sm: 0 }} bc="grey70">
          <DashboardSpineItem
            url="/search"
            text={formatMessage(messages.search)}
            expanded={open}
            icon="search"
            onClick={onNavItemClick}
          />
        </Box>
        <Box d="column" mt="0" wrap="nowrap">
          {spineLinks.map(({ url, icon, label, nested }) => {
            return (
              <DashboardSpineItem
                key={url}
                url={url}
                text={label}
                expanded={open}
                icon={icon}
                onClick={onNavItemClick}
                nested={nested}
              />
            );
          })}
        </Box>

        <Box mt="auto">
          {billingUrl && (
            <DashboardSpineItem
              onClick={onNavItemClick}
              url={billingUrl}
              text={formatMessage(messages.billing)}
              expanded={open}
              icon="payments"
              isExternalLink
            />
          )}

          {supportUrl && (
            <DashboardSpineItem
              onClick={onNavItemClick}
              url={supportUrl}
              text={formatMessage(messages.help)}
              expanded={open}
              icon="question"
              isExternalLink
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const DashboardSpine = memo(_DashboardSpine);
