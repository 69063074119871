import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { licenceApi } from 'src/api_services/licence/service';

import { createRequestStateSaga } from '../helpers';
import { licenceActions } from './actions';

export const [getLicenceInfoSaga] = createRequestStateSaga(licenceActions.getLicenceInfo, licenceApi.getLicenceInfo);

export const [refreshLicenceSaga] = createRequestStateSaga(licenceActions.refreshLicence, licenceApi.refreshLicence);

export function* licenceSaga() {
  yield takeEvery(getType(licenceActions.getLicenceInfo.request), getLicenceInfoSaga);
  yield takeEvery(getType(licenceActions.refreshLicence.request), refreshLicenceSaga);
}
