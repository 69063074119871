import { removeLeadingSlash } from 'src/utils/helpers';
import { middleTruncationHelper } from 'src/utils/middleTruncationHelper/utils';

export const octalChmod = (permissions: number) => {
  return parseInt(permissions.toString(8), 10);
};

export const decimalChmod = (permissions: number) => {
  return parseInt(permissions.toString(10), 8);
};

export const azSort = <T extends { name: string }>(a: T, b: T) =>
  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;

export const getPositionFromEventTarget = (element: HTMLElement) => {
  const rect =
    element.tagName === 'svg' && element.parentElement?.parentElement
      ? element.parentElement.parentElement.getBoundingClientRect()
      : element.getBoundingClientRect();

  return {
    x: Math.floor(rect.left + 16),
    y: Math.floor(rect.bottom - 1),
  };
};

export const isArchive = (path: string) => ['zip', 'tar', 'tar.gz'].some((extension) => path.endsWith(extension));

export const flattenFolderUpload: (items: FileSystemEntry[]) => Promise<File[]> = async (items: FileSystemEntry[]) => {
  return items.reduce<Promise<File[]>>(async (carry, item) => {
    if (item.isFile) {
      const fileHandle = item as FileSystemFileEntry;
      const file: File = await new Promise((resolve, reject) => fileHandle.file(resolve, reject));

      return (await carry).concat([new File([file], removeLeadingSlash(fileHandle.fullPath), { type: file.type })]);
    }

    const folderHandle = item as FileSystemDirectoryEntry;
    const reader = folderHandle.createReader();
    const entries: FileSystemEntry[] = await new Promise((resolve, reject) => reader.readEntries(resolve, reject));

    return (await carry).concat(await flattenFolderUpload(entries));
  }, Promise.resolve([]));
};

export const getEntryName = (path: string) => {
  const match = /\/([^/]+)$/.exec(path);

  return match ? match[1] : path;
};

export const getDirectoryFromFilePath = (path: string) => {
  return path.replace(/\/?[^/]+$/, '');
};

export const parseFileSystemEntries = (items: DataTransferItemList) => {
  const entries = Array.from(items)
    .map((entry) => entry.webkitGetAsEntry())
    .filter(Boolean);

  return entries as FileSystemEntry[];
};

export const truncateName = (name: string, width?: number) => {
  if (!width) {
    return name;
  }

  const parts = middleTruncationHelper({ text: name, splitRatio: 0.8 });

  const maxCharacters = Math.floor(width / 7);

  if (name.length <= maxCharacters) {
    return name;
  }

  const suffixLength = parts.secondSection.length;

  return `${parts.firstSection.slice(0, maxCharacters - suffixLength - 3)}...${parts.secondSection}`;
};

export const isJsonString = (value: string) => {
  try {
    JSON.parse(value);

    return true;
  } catch {
    return false;
  }
};
