/* tslint:disable */
/* eslint-disable */
/**
 * orchd
 * orchd API docs
 *
 * The version of the OpenAPI document: 12.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosResponse, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptInviteBody
 */
export interface AcceptInviteBody {
    /**
     * 
     * @type {string}
     * @memberof AcceptInviteBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptInviteBody
     */
    password?: string;
    /**
     * 
     * @type {CPLocale}
     * @memberof AcceptInviteBody
     */
    locale?: CPLocale;
}
/**
 * 
 * @export
 * @interface ActivitiesListing
 */
export interface ActivitiesListing {
    /**
     * 
     * @type {number}
     * @memberof ActivitiesListing
     */
    total: number;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivitiesListing
     */
    items: Array<Activity>;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    orgId: string;
    /**
     * 
     * @type {ActivityKind}
     * @memberof Activity
     */
    kind: ActivityKind;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    message?: string;
    /**
     * 
     * @type {ActivityContext}
     * @memberof Activity
     */
    context?: ActivityContext;
    /**
     * 
     * @type {ActivityObject}
     * @memberof Activity
     */
    activityObject?: ActivityObject;
}
/**
 * 
 * @export
 * @interface ActivityAccessTokenEntity
 */
export interface ActivityAccessTokenEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityAccessTokenEntity
     */
    type: ActivityAccessTokenEntityTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityAccessTokenEntityTypeEnum {
    accessToken = 'accessToken'
}

/**
 * 
 * @export
 * @interface ActivityContext
 */
export interface ActivityContext {
    /**
     * 
     * @type {ActivityOrgEntity}
     * @memberof ActivityContext
     */
    org?: ActivityOrgEntity;
    /**
     * 
     * @type {ActivityWebsiteEntity}
     * @memberof ActivityContext
     */
    website?: ActivityWebsiteEntity;
    /**
     * 
     * @type {ActivityDomainEntity}
     * @memberof ActivityContext
     */
    domain?: ActivityDomainEntity;
    /**
     * 
     * @type {ActivityLoginEntity | ActivityAccessTokenEntity}
     * @memberof ActivityContext
     */
    actor?: ActivityLoginEntity | ActivityAccessTokenEntity;
    /**
     * 
     * @type {ActivityServerEntity}
     * @memberof ActivityContext
     */
    server?: ActivityServerEntity;
    /**
     * 
     * @type {ActivityErrorEntity}
     * @memberof ActivityContext
     */
    error?: ActivityErrorEntity;
}
/**
 * 
 * @export
 * @interface ActivityDomainEntity
 */
export interface ActivityDomainEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityDomainEntity
     */
    type: ActivityDomainEntityTypeEnum;
    /**
     * 
     * @type {ActivityDomainEntityContent}
     * @memberof ActivityDomainEntity
     */
    content: ActivityDomainEntityContent;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityDomainEntityTypeEnum {
    domain = 'domain'
}

/**
 * 
 * @export
 * @interface ActivityDomainEntityContent
 */
export interface ActivityDomainEntityContent {
    /**
     * 
     * @type {string}
     * @memberof ActivityDomainEntityContent
     */
    id: string;
    /**
     * 
     * @type {ActivityDomainEntityContentDetail}
     * @memberof ActivityDomainEntityContent
     */
    detail: ActivityDomainEntityContentDetail;
}
/**
 * 
 * @export
 * @interface ActivityDomainEntityContentDetail
 */
export interface ActivityDomainEntityContentDetail {
    /**
     * 
     * @type {ActivityDomainEntityDetail}
     * @memberof ActivityDomainEntityContentDetail
     */
    ok?: ActivityDomainEntityDetail;
    /**
     * 
     * @type {HttpError}
     * @memberof ActivityDomainEntityContentDetail
     */
    error?: HttpError;
}
/**
 * 
 * @export
 * @interface ActivityDomainEntityDetail
 */
export interface ActivityDomainEntityDetail {
    /**
     * 
     * @type {string}
     * @memberof ActivityDomainEntityDetail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDomainEntityDetail
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDomainEntityDetail
     */
    websiteId?: string;
    /**
     * 
     * @type {DomainMappingKind}
     * @memberof ActivityDomainEntityDetail
     */
    mappingKind?: DomainMappingKind;
}
/**
 * 
 * @export
 * @interface ActivityErrorEntity
 */
export interface ActivityErrorEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityErrorEntity
     */
    type: ActivityErrorEntityTypeEnum;
    /**
     * 
     * @type {ActivityErrorEntityContent}
     * @memberof ActivityErrorEntity
     */
    content: ActivityErrorEntityContent;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityErrorEntityTypeEnum {
    error = 'error'
}

/**
 * 
 * @export
 * @interface ActivityErrorEntityContent
 */
export interface ActivityErrorEntityContent {
    /**
     * 
     * @type {HttpError}
     * @memberof ActivityErrorEntityContent
     */
    detail: HttpError;
}
/**
 * Different activities that happen in orchd. There will be a lot of changes to the values of this enum as time goes by, consider this non-exhausive and be graceful with unknown values.
 * @export
 * @enum {string}
 */
export enum ActivityKind {
    added = 'added',
    removed = 'removed',
    cloned = 'cloned',
    imported = 'imported',
    backedUp = 'backedUp',
    errorRaised = 'errorRaised',
    backupError = 'backupError'
}

/**
 * 
 * @export
 * @interface ActivityLoginEntity
 */
export interface ActivityLoginEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityLoginEntity
     */
    type: ActivityLoginEntityTypeEnum;
    /**
     * 
     * @type {ActivityLoginEntityContent}
     * @memberof ActivityLoginEntity
     */
    content: ActivityLoginEntityContent;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityLoginEntityTypeEnum {
    login = 'login'
}

/**
 * 
 * @export
 * @interface ActivityLoginEntityContent
 */
export interface ActivityLoginEntityContent {
    /**
     * 
     * @type {string}
     * @memberof ActivityLoginEntityContent
     */
    id: string;
    /**
     * 
     * @type {ActivityLoginEntityContentDetail}
     * @memberof ActivityLoginEntityContent
     */
    detail: ActivityLoginEntityContentDetail;
}
/**
 * 
 * @export
 * @interface ActivityLoginEntityContentDetail
 */
export interface ActivityLoginEntityContentDetail {
    /**
     * 
     * @type {ActivityLoginEntityDetail}
     * @memberof ActivityLoginEntityContentDetail
     */
    ok?: ActivityLoginEntityDetail;
    /**
     * 
     * @type {HttpError}
     * @memberof ActivityLoginEntityContentDetail
     */
    error?: HttpError;
}
/**
 * 
 * @export
 * @interface ActivityLoginEntityDetail
 */
export interface ActivityLoginEntityDetail {
    /**
     * 
     * @type {string}
     * @memberof ActivityLoginEntityDetail
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLoginEntityDetail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLoginEntityDetail
     */
    realmId: string;
}
/**
 * @type ActivityObject
 * @export
 */
export type ActivityObject = ActivityObjectEntity | ActivityObjectOneOf;

/**
 * @type ActivityObjectEntity
 * @export
 */
export type ActivityObjectEntity = ActivityDomainEntity | ActivityLoginEntity | ActivityOrgEntity | ActivityServerEntity | ActivityWebsiteEntity;

/**
 * 
 * @export
 * @interface ActivityObjectOneOf
 */
export interface ActivityObjectOneOf {
    /**
     * 
     * @type {string}
     * @memberof ActivityObjectOneOf
     */
    type: ActivityObjectOneOfTypeEnum;
    /**
     * 
     * @type {ActivityObjectEntity}
     * @memberof ActivityObjectOneOf
     */
    from: ActivityObjectEntity;
    /**
     * 
     * @type {ActivityObjectEntity}
     * @memberof ActivityObjectOneOf
     */
    to: ActivityObjectEntity;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityObjectOneOfTypeEnum {
    fromTo = 'fromTo'
}

/**
 * 
 * @export
 * @interface ActivityOrgEntity
 */
export interface ActivityOrgEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityOrgEntity
     */
    type: ActivityOrgEntityTypeEnum;
    /**
     * 
     * @type {ActivityOrgEntityContent}
     * @memberof ActivityOrgEntity
     */
    content: ActivityOrgEntityContent;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityOrgEntityTypeEnum {
    org = 'org'
}

/**
 * 
 * @export
 * @interface ActivityOrgEntityContent
 */
export interface ActivityOrgEntityContent {
    /**
     * 
     * @type {string}
     * @memberof ActivityOrgEntityContent
     */
    id: string;
    /**
     * 
     * @type {ActivityOrgEntityContentDetail}
     * @memberof ActivityOrgEntityContent
     */
    detail: ActivityOrgEntityContentDetail;
}
/**
 * 
 * @export
 * @interface ActivityOrgEntityContentDetail
 */
export interface ActivityOrgEntityContentDetail {
    /**
     * 
     * @type {ActivityOrgEntityDetail}
     * @memberof ActivityOrgEntityContentDetail
     */
    ok?: ActivityOrgEntityDetail;
    /**
     * 
     * @type {HttpError}
     * @memberof ActivityOrgEntityContentDetail
     */
    error?: HttpError;
}
/**
 * 
 * @export
 * @interface ActivityOrgEntityDetail
 */
export interface ActivityOrgEntityDetail {
    /**
     * 
     * @type {string}
     * @memberof ActivityOrgEntityDetail
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ActivityServerEntity
 */
export interface ActivityServerEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityServerEntity
     */
    type: ActivityServerEntityTypeEnum;
    /**
     * 
     * @type {ActivityServerEntityContent}
     * @memberof ActivityServerEntity
     */
    content: ActivityServerEntityContent;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityServerEntityTypeEnum {
    server = 'server'
}

/**
 * 
 * @export
 * @interface ActivityServerEntityContent
 */
export interface ActivityServerEntityContent {
    /**
     * 
     * @type {string}
     * @memberof ActivityServerEntityContent
     */
    id: string;
    /**
     * 
     * @type {ActivityServerEntityContentDetail}
     * @memberof ActivityServerEntityContent
     */
    detail: ActivityServerEntityContentDetail;
}
/**
 * 
 * @export
 * @interface ActivityServerEntityContentDetail
 */
export interface ActivityServerEntityContentDetail {
    /**
     * 
     * @type {ActivityServerEntityDetail}
     * @memberof ActivityServerEntityContentDetail
     */
    ok?: ActivityServerEntityDetail;
    /**
     * 
     * @type {HttpError}
     * @memberof ActivityServerEntityContentDetail
     */
    error?: HttpError;
}
/**
 * 
 * @export
 * @interface ActivityServerEntityDetail
 */
export interface ActivityServerEntityDetail {
    /**
     * 
     * @type {string}
     * @memberof ActivityServerEntityDetail
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityServerEntityDetail
     */
    hostname: string;
}
/**
 * 
 * @export
 * @interface ActivityWebsiteEntity
 */
export interface ActivityWebsiteEntity {
    /**
     * 
     * @type {string}
     * @memberof ActivityWebsiteEntity
     */
    type: ActivityWebsiteEntityTypeEnum;
    /**
     * 
     * @type {ActivityWebsiteEntityContent}
     * @memberof ActivityWebsiteEntity
     */
    content: ActivityWebsiteEntityContent;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivityWebsiteEntityTypeEnum {
    website = 'website'
}

/**
 * 
 * @export
 * @interface ActivityWebsiteEntityContent
 */
export interface ActivityWebsiteEntityContent {
    /**
     * 
     * @type {string}
     * @memberof ActivityWebsiteEntityContent
     */
    id: string;
    /**
     * 
     * @type {ActivityWebsiteEntityContentDetail}
     * @memberof ActivityWebsiteEntityContent
     */
    detail: ActivityWebsiteEntityContentDetail;
}
/**
 * 
 * @export
 * @interface ActivityWebsiteEntityContentDetail
 */
export interface ActivityWebsiteEntityContentDetail {
    /**
     * 
     * @type {ActivityWebsiteEntityDetail}
     * @memberof ActivityWebsiteEntityContentDetail
     */
    ok?: ActivityWebsiteEntityDetail;
    /**
     * 
     * @type {HttpError}
     * @memberof ActivityWebsiteEntityContentDetail
     */
    error?: HttpError;
}
/**
 * 
 * @export
 * @interface ActivityWebsiteEntityDetail
 */
export interface ActivityWebsiteEntityDetail {
    /**
     * 
     * @type {string}
     * @memberof ActivityWebsiteEntityDetail
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityWebsiteEntityDetail
     */
    subscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityWebsiteEntityDetail
     */
    orgId: string;
}
/**
 * 
 * @export
 * @interface Allowance
 */
export interface Allowance {
    /**
     * 
     * @type {string}
     * @memberof Allowance
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ApplicationRoleInfo
 */
export interface ApplicationRoleInfo {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof ApplicationRoleInfo
     */
    state: ServerRoleState;
    /**
     * 
     * @type {number}
     * @memberof ApplicationRoleInfo
     */
    usage: number;
    /**
     * 
     * @type {ServiceInfo}
     * @memberof ApplicationRoleInfo
     */
    filerd: ServiceInfo;
    /**
     * 
     * @type {ServiceInfo}
     * @memberof ApplicationRoleInfo
     */
    ftpcd: ServiceInfo;
    /**
     * The number of websites that are assigned to be on this application role.
     * @type {number}
     * @memberof ApplicationRoleInfo
     */
    websitesCount: number;
}
/**
 * Authoritative Name Server
 * @export
 * @interface AuthNs
 */
export interface AuthNs {
    /**
     * Server IP address
     * @type {string}
     * @memberof AuthNs
     */
    ip?: string;
    /**
     * list of resolved ip addresses for the domain
     * @type {Array<string>}
     * @memberof AuthNs
     */
    domainIps?: Array<string>;
    /**
     * Tree of delegated AuthNs servers
     * @type {{ [key: string]: AuthNs; }}
     * @memberof AuthNs
     */
    delegations?: { [key: string]: AuthNs; };
}
/**
 * 
 * @export
 * @interface AuthNsResponse
 */
export interface AuthNsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthNsResponse
     */
    matchesPlatform: boolean;
    /**
     * 
     * @type {Array<AuthNsResponseNs>}
     * @memberof AuthNsResponse
     */
    authNs: Array<AuthNsResponseNs>;
}
/**
 * 
 * @export
 * @interface AuthNsResponseNs
 */
export interface AuthNsResponseNs {
    /**
     * 
     * @type {string}
     * @memberof AuthNsResponseNs
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthNsResponseNs
     */
    ips: Array<string>;
}
/**
 * 
 * @export
 * @interface Autoresponder
 */
export interface Autoresponder {
    /**
     * 
     * @type {number}
     * @memberof Autoresponder
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Autoresponder
     */
    emailId: string;
    /**
     * 
     * @type {string}
     * @memberof Autoresponder
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof Autoresponder
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Autoresponder
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Autoresponder
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof Autoresponder
     */
    body: string;
}
/**
 * 
 * @export
 * @interface Backup
 */
export interface Backup {
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    finishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    snapshotDirName: string;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    size?: number;
    /**
     * 
     * @type {OperationStatus}
     * @memberof Backup
     */
    homeDirStatus?: OperationStatus;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    filesSize?: number;
    /**
     * 
     * @type {OperationStatus}
     * @memberof Backup
     */
    mysqlDbsStatus?: OperationStatus;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    mysqlDbsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    mysqlDbsSize?: number;
    /**
     * 
     * @type {OperationStatus}
     * @memberof Backup
     */
    emailsStatus?: OperationStatus;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    emailsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Backup
     */
    emailsSize?: number;
    /**
     * 
     * @type {BackupKind}
     * @memberof Backup
     */
    kind: BackupKind;
    /**
     * 
     * @type {string}
     * @memberof Backup
     */
    description?: string;
    /**
     * 
     * @type {BackupStorageKind}
     * @memberof Backup
     */
    storageKind: BackupStorageKind;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BackupAction {
    backup = 'backup',
    restore = 'restore'
}

/**
 * 
 * @export
 * @interface BackupDetailed
 */
export interface BackupDetailed {
    /**
     * 
     * @type {number}
     * @memberof BackupDetailed
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BackupDetailed
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof BackupDetailed
     */
    finishedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BackupDetailed
     */
    snapshotDirName: string;
    /**
     * 
     * @type {number}
     * @memberof BackupDetailed
     */
    size?: number;
    /**
     * 
     * @type {OperationStatus}
     * @memberof BackupDetailed
     */
    homeDirStatus?: OperationStatus;
    /**
     * 
     * @type {number}
     * @memberof BackupDetailed
     */
    filesSize?: number;
    /**
     * 
     * @type {OperationStatus}
     * @memberof BackupDetailed
     */
    mysqlDbsStatus?: OperationStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof BackupDetailed
     */
    mysqlDbs?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BackupDetailed
     */
    mysqlDbsSize?: number;
    /**
     * 
     * @type {OperationStatus}
     * @memberof BackupDetailed
     */
    emailsStatus?: OperationStatus;
    /**
     * The addresses of the backed up mailboxes.
     * @type {Array<string>}
     * @memberof BackupDetailed
     */
    emails?: Array<string>;
    /**
     * The domain ids of the backed up mailboxes.
     * @type {Array<string>}
     * @memberof BackupDetailed
     */
    emailDomains?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BackupDetailed
     */
    emailsSize?: number;
    /**
     * 
     * @type {BackupKind}
     * @memberof BackupDetailed
     */
    kind: BackupKind;
    /**
     * 
     * @type {string}
     * @memberof BackupDetailed
     */
    description?: string;
    /**
     * 
     * @type {BackupStorageKind}
     * @memberof BackupDetailed
     */
    storageKind: BackupStorageKind;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BackupKind {
    manual = 'manual',
    automatic = 'automatic'
}

/**
 * 
 * @export
 * @interface BackupOptions
 */
export interface BackupOptions {
    /**
     * 
     * @type {string}
     * @memberof BackupOptions
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface BackupRemoteStorageS3
 */
export interface BackupRemoteStorageS3 {
    /**
     * 
     * @type {RemoteStoragePurpose}
     * @memberof BackupRemoteStorageS3
     */
    purpose: RemoteStoragePurpose;
    /**
     * 
     * @type {string}
     * @memberof BackupRemoteStorageS3
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof BackupRemoteStorageS3
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof BackupRemoteStorageS3
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof BackupRemoteStorageS3
     */
    accessKeyId: string;
    /**
     * 
     * @type {string}
     * @memberof BackupRemoteStorageS3
     */
    prefix: string;
}
/**
 * 
 * @export
 * @interface BackupRestoreOptions
 */
export interface BackupRestoreOptions {
    /**
     * If set to false, the backup restoration will not include the website home directory.
     * @type {boolean}
     * @memberof BackupRestoreOptions
     */
    restoreFiles?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BackupRestoreOptions
     */
    restoreEmails?: Array<string>;
    /**
     * Restore all the mailboxes from this backup.  Overrides the `restoreEmails` property.
     * @type {boolean}
     * @memberof BackupRestoreOptions
     */
    restoreAllEmails?: boolean;
    /**
     * The list of databases names that need to be restored. If this list is not specified all the databases found in the backup snapshot will be restored, otherwise if this list is specified as empty, no database will be restored.
     * @type {Array<string>}
     * @memberof BackupRestoreOptions
     */
    restoreDatabases?: Array<string>;
}
/**
 * 
 * @export
 * @interface BackupRoleInfo
 */
export interface BackupRoleInfo {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof BackupRoleInfo
     */
    state: ServerRoleState;
    /**
     * 
     * @type {number}
     * @memberof BackupRoleInfo
     */
    usage: number;
    /**
     * 
     * @type {number}
     * @memberof BackupRoleInfo
     */
    snapshotsCount: number;
    /**
     * 
     * @type {number}
     * @memberof BackupRoleInfo
     */
    last24hSnapshotsCount: number;
    /**
     * 
     * @type {ServiceInfo}
     * @memberof BackupRoleInfo
     */
    bkupd: ServiceInfo;
    /**
     * The number of websites whose backups are assigned to be on this backup role.
     * @type {number}
     * @memberof BackupRoleInfo
     */
    websitesCount: number;
}
/**
 * 
 * @export
 * @interface BackupStatus
 */
export interface BackupStatus {
    /**
     * 
     * @type {number}
     * @memberof BackupStatus
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BackupStatus
     */
    websiteId: string;
    /**
     * 
     * @type {string}
     * @memberof BackupStatus
     */
    startedAt: string;
    /**
     * 
     * @type {BackupAction}
     * @memberof BackupStatus
     */
    action: BackupAction;
    /**
     * 
     * @type {OperationStatus}
     * @memberof BackupStatus
     */
    homeDirStatus?: OperationStatus;
    /**
     * 
     * @type {OperationStatus}
     * @memberof BackupStatus
     */
    mysqlDbsStatus?: OperationStatus;
    /**
     * 
     * @type {OperationStatus}
     * @memberof BackupStatus
     */
    emailsStatus?: OperationStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BackupStorageKind {
    enhance = 'enhance',
    s3 = 's3'
}

/**
 * 
 * @export
 * @interface BackupsFullListing
 */
export interface BackupsFullListing {
    /**
     * 
     * @type {Array<Backup>}
     * @memberof BackupsFullListing
     */
    items: Array<Backup>;
}
/**
 * 
 * @export
 * @interface Blocked
 */
export interface Blocked {
    /**
     * 
     * @type {number}
     * @memberof Blocked
     */
    blockedUntil?: number;
    /**
     * 
     * @type {number}
     * @memberof Blocked
     */
    currentFailures?: number;
    /**
     * 
     * @type {number}
     * @memberof Blocked
     */
    allFailures?: number;
}
/**
 * 
 * @export
 * @interface Branding
 */
export interface Branding {
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    controlPanelDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    phpMyAdminDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    roundcubeDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    logoPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    inverseLogoPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    inverseIconPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    loginImagePath?: string;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    faviconPath?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Branding
     */
    nameServers?: Array<string>;
    /**
     * 
     * @type {Array<Setting>}
     * @memberof Branding
     */
    settings: Array<Setting>;
    /**
     * 
     * @type {string}
     * @memberof Branding
     */
    stagingDomain?: string;
    /**
     * 
     * @type {CPLocale}
     * @memberof Branding
     */
    locale: CPLocale;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CPLocale {
    af = 'af',
    af_ZA = 'af-ZA',
    ar = 'ar',
    ar_AE = 'ar-AE',
    ar_BH = 'ar-BH',
    ar_DZ = 'ar-DZ',
    ar_EG = 'ar-EG',
    ar_IQ = 'ar-IQ',
    ar_JO = 'ar-JO',
    ar_KW = 'ar-KW',
    ar_LB = 'ar-LB',
    ar_LY = 'ar-LY',
    ar_MA = 'ar-MA',
    ar_OM = 'ar-OM',
    ar_QA = 'ar-QA',
    ar_SA = 'ar-SA',
    ar_SY = 'ar-SY',
    ar_TN = 'ar-TN',
    ar_YE = 'ar-YE',
    az = 'az',
    az_AZ = 'az-AZ',
    az_Cyrl_AZ = 'az-Cyrl-AZ',
    be = 'be',
    be_BY = 'be-BY',
    bg = 'bg',
    bg_BG = 'bg-BG',
    bn_IN = 'bn-IN',
    bn_BD = 'bn-BD',
    bs_BA = 'bs-BA',
    ca = 'ca',
    ca_ES = 'ca-ES',
    cs = 'cs',
    cs_CZ = 'cs-CZ',
    cy = 'cy',
    cy_GB = 'cy-GB',
    da = 'da',
    da_DK = 'da-DK',
    de = 'de',
    de_AT = 'de-AT',
    de_CH = 'de-CH',
    de_DE = 'de-DE',
    de_LI = 'de-LI',
    de_LU = 'de-LU',
    dv = 'dv',
    dv_MV = 'dv-MV',
    el = 'el',
    el_GR = 'el-GR',
    en = 'en',
    en_AU = 'en-AU',
    en_BZ = 'en-BZ',
    en_CA = 'en-CA',
    en_CB = 'en-CB',
    en_GB = 'en-GB',
    en_IE = 'en-IE',
    en_JM = 'en-JM',
    en_NZ = 'en-NZ',
    en_PH = 'en-PH',
    en_TT = 'en-TT',
    en_US = 'en-US',
    en_ZA = 'en-ZA',
    en_ZW = 'en-ZW',
    eo = 'eo',
    es = 'es',
    es_AR = 'es-AR',
    es_BO = 'es-BO',
    es_CL = 'es-CL',
    es_CO = 'es-CO',
    es_CR = 'es-CR',
    es_DO = 'es-DO',
    es_EC = 'es-EC',
    es_ES = 'es-ES',
    es_GT = 'es-GT',
    es_HN = 'es-HN',
    es_MX = 'es-MX',
    es_NI = 'es-NI',
    es_PA = 'es-PA',
    es_PE = 'es-PE',
    es_PR = 'es-PR',
    es_PY = 'es-PY',
    es_SV = 'es-SV',
    es_UY = 'es-UY',
    es_VE = 'es-VE',
    et = 'et',
    et_EE = 'et-EE',
    eu = 'eu',
    eu_ES = 'eu-ES',
    fa = 'fa',
    fa_IR = 'fa-IR',
    fi = 'fi',
    fi_FI = 'fi-FI',
    fo = 'fo',
    fo_FO = 'fo-FO',
    fr = 'fr',
    fr_BE = 'fr-BE',
    fr_CA = 'fr-CA',
    fr_CH = 'fr-CH',
    fr_FR = 'fr-FR',
    fr_LU = 'fr-LU',
    fr_MC = 'fr-MC',
    gl = 'gl',
    gl_ES = 'gl-ES',
    gu = 'gu',
    gu_IN = 'gu-IN',
    he = 'he',
    he_IL = 'he-IL',
    hi = 'hi',
    hi_IN = 'hi-IN',
    hr = 'hr',
    hr_BA = 'hr-BA',
    hr_HR = 'hr-HR',
    hu = 'hu',
    hu_HU = 'hu-HU',
    hy = 'hy',
    hy_AM = 'hy-AM',
    id = 'id',
    id_ID = 'id-ID',
    is = 'is',
    is_IS = 'is-IS',
    it = 'it',
    it_CH = 'it-CH',
    it_IT = 'it-IT',
    ja = 'ja',
    ja_JP = 'ja-JP',
    ka = 'ka',
    ka_GE = 'ka-GE',
    kk = 'kk',
    kk_KZ = 'kk-KZ',
    kn = 'kn',
    kn_IN = 'kn-IN',
    ko = 'ko',
    ko_KR = 'ko-KR',
    kok = 'kok',
    kok_IN = 'kok-IN',
    ky = 'ky',
    ky_KG = 'ky-KG',
    lt = 'lt',
    lt_LT = 'lt-LT',
    lv = 'lv',
    lv_LV = 'lv-LV',
    mi = 'mi',
    mi_NZ = 'mi-NZ',
    mk = 'mk',
    mk_MK = 'mk-MK',
    mn = 'mn',
    mn_MN = 'mn-MN',
    mr = 'mr',
    mr_IN = 'mr-IN',
    ms = 'ms',
    ms_BN = 'ms-BN',
    ms_MY = 'ms-MY',
    mt = 'mt',
    mt_MT = 'mt-MT',
    nb = 'nb',
    nb_NO = 'nb-NO',
    nl = 'nl',
    nl_BE = 'nl-BE',
    nl_NL = 'nl-NL',
    nn_NO = 'nn-NO',
    ns = 'ns',
    ns_ZA = 'ns-ZA',
    pa = 'pa',
    pa_IN = 'pa-IN',
    pl = 'pl',
    pl_PL = 'pl-PL',
    ps = 'ps',
    ps_AR = 'ps-AR',
    pt = 'pt',
    pt_BR = 'pt-BR',
    pt_PT = 'pt-PT',
    qu = 'qu',
    qu_BO = 'qu-BO',
    qu_EC = 'qu-EC',
    qu_PE = 'qu-PE',
    ro = 'ro',
    ro_RO = 'ro-RO',
    ru = 'ru',
    ru_RU = 'ru-RU',
    sa = 'sa',
    sa_IN = 'sa-IN',
    se = 'se',
    se_FI = 'se-FI',
    se_NO = 'se-NO',
    se_SE = 'se-SE',
    sk = 'sk',
    sk_SK = 'sk-SK',
    sl = 'sl',
    sl_SI = 'sl-SI',
    sq = 'sq',
    sq_AL = 'sq-AL',
    sr_BA = 'sr-BA',
    sr_Cyrl_BA = 'sr-Cyrl-BA',
    sr_SP = 'sr-SP',
    sr_Cyrl_SP = 'sr-Cyrl-SP',
    sv = 'sv',
    sv_FI = 'sv-FI',
    sv_SE = 'sv-SE',
    sw = 'sw',
    sw_KE = 'sw-KE',
    syr = 'syr',
    syr_SY = 'syr-SY',
    ta = 'ta',
    ta_IN = 'ta-IN',
    te = 'te',
    te_IN = 'te-IN',
    th = 'th',
    th_TH = 'th-TH',
    tl = 'tl',
    tl_PH = 'tl-PH',
    tn = 'tn',
    tn_ZA = 'tn-ZA',
    tr = 'tr',
    tr_TR = 'tr-TR',
    tt = 'tt',
    tt_RU = 'tt-RU',
    ts = 'ts',
    uk = 'uk',
    uk_UA = 'uk-UA',
    ur = 'ur',
    ur_PK = 'ur-PK',
    uz = 'uz',
    uz_UZ = 'uz-UZ',
    uz_Cyrl_UZ = 'uz-Cyrl-UZ',
    vi = 'vi',
    vi_VN = 'vi-VN',
    xh = 'xh',
    xh_ZA = 'xh-ZA',
    zh = 'zh',
    zh_CN = 'zh-CN',
    zh_HK = 'zh-HK',
    zh_MO = 'zh-MO',
    zh_SG = 'zh-SG',
    zh_TW = 'zh-TW',
    zu = 'zu',
    zu_ZA = 'zu-ZA'
}

/**
 * 
 * @export
 * @interface CanUse
 */
export interface CanUse {
    /**
     * 
     * @type {boolean}
     * @memberof CanUse
     */
    ftp: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanUse
     */
    fileManager: boolean;
    /**
     * 
     * @type {Array<PhpVersion>}
     * @memberof CanUse
     */
    phpVersions: Array<PhpVersion>;
    /**
     * 
     * @type {boolean}
     * @memberof CanUse
     */
    redis: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanUse
     */
    modSec: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CanUse
     */
    backup: boolean;
    /**
     * 
     * @type {MysqlKind}
     * @memberof CanUse
     */
    mysqlKind?: MysqlKind;
}
/**
 * If this object is present, the website\'s .htaccess will have a new set of rules which redirect all secondary domains to this primary domain with 301 header. If this object is missing, any existing rules will be removed.
 * @export
 * @interface CanonicalRedirect
 */
export interface CanonicalRedirect {
    /**
     * Whether to put www to the url which is being redirected to. In .htaccess syntax: `http://www.example.com/$1`
     * @type {boolean}
     * @memberof CanonicalRedirect
     */
    useWww: boolean;
    /**
     * Whether to put www to the url which is being redirected to. In .htaccess syntax: `https://example.com/$1`
     * @type {boolean}
     * @memberof CanonicalRedirect
     */
    useHttps: boolean;
}
/**
 * 
 * @export
 * @interface CgroupLimits
 */
export interface CgroupLimits {
    /**
     * 
     * @type {number}
     * @memberof CgroupLimits
     */
    nproc: number | null;
    /**
     * 
     * @type {number}
     * @memberof CgroupLimits
     */
    memoryLimit: number | null;
    /**
     * 
     * @type {number}
     * @memberof CgroupLimits
     */
    iops: number | null;
    /**
     * 
     * @type {number}
     * @memberof CgroupLimits
     */
    ioBandwidth: number | null;
    /**
     * 
     * @type {number}
     * @memberof CgroupLimits
     */
    virtualCpus: number | null;
}
/**
 * 
 * @export
 * @interface ChangeSubscriptionParams
 */
export interface ChangeSubscriptionParams {
    /**
     * 
     * @type {number | Unset}
     * @memberof ChangeSubscriptionParams
     */
    subscriptionId: number | Unset;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CloneStatus {
    cloningWebsite = 'cloningWebsite',
    cloningDatabases = 'cloningDatabases',
    cloningHomeDir = 'cloningHomeDir',
    succeeded = 'succeeded',
    failed = 'failed'
}

/**
 * 
 * @export
 * @interface CloudFlareApiKey
 */
export interface CloudFlareApiKey {
    /**
     * 
     * @type {string}
     * @memberof CloudFlareApiKey
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CloudFlareApiKey
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof CloudFlareApiKey
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CloudFlareApiKey
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof CloudFlareApiKey
     */
    lastSync?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudFlareApiKey
     */
    lastMessage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CloudFlareApiKey
     */
    domains?: Array<string>;
}
/**
 * 
 * @export
 * @interface CloudFlareNameServers
 */
export interface CloudFlareNameServers {
    /**
     * 
     * @type {Array<string>}
     * @memberof CloudFlareNameServers
     */
    nameServers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CloudFlareNameServers
     */
    status: CloudFlareNameServersStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CloudFlareNameServersStatusEnum {
    active = 'active',
    pending = 'pending'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CloudFlareStatus {
    Connected = 'Connected',
    Disconnected = 'Disconnected',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface ControlRoleInfo
 */
export interface ControlRoleInfo {
    /**
     * 
     * @type {number}
     * @memberof ControlRoleInfo
     */
    usage: number;
    /**
     * 
     * @type {CoreServiceInfo}
     * @memberof ControlRoleInfo
     */
    authd: CoreServiceInfo;
    /**
     * 
     * @type {CoreServiceInfo}
     * @memberof ControlRoleInfo
     */
    sged: CoreServiceInfo;
    /**
     * 
     * @type {CoreServiceInfo}
     * @memberof ControlRoleInfo
     */
    logd: CoreServiceInfo;
}
/**
 * 
 * @export
 * @interface CoreServiceInfo
 */
export interface CoreServiceInfo {
    /**
     * 
     * @type {string}
     * @memberof CoreServiceInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CoreServiceInfo
     */
    version?: string;
    /**
     * 
     * @type {NetworkStatus}
     * @memberof CoreServiceInfo
     */
    status: NetworkStatus;
    /**
     * 
     * @type {number}
     * @memberof CoreServiceInfo
     */
    port: number;
    /**
     * 
     * @type {number}
     * @memberof CoreServiceInfo
     */
    usage: number;
    /**
     * 
     * @type {Array<ProcessInfo>}
     * @memberof CoreServiceInfo
     */
    processes?: Array<ProcessInfo>;
}
/**
 * 
 * @export
 * @interface CreateBackupRemoteStorageS3
 */
export interface CreateBackupRemoteStorageS3 {
    /**
     * 
     * @type {RemoteStoragePurpose}
     * @memberof CreateBackupRemoteStorageS3
     */
    purpose: RemoteStoragePurpose;
    /**
     * 
     * @type {string}
     * @memberof CreateBackupRemoteStorageS3
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackupRemoteStorageS3
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackupRemoteStorageS3
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackupRemoteStorageS3
     */
    accessKeyId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackupRemoteStorageS3
     */
    accessKeySecret: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackupRemoteStorageS3
     */
    prefix: string;
}
/**
 * 
 * @export
 * @interface CrontabFullListing
 */
export interface CrontabFullListing {
    /**
     * 
     * @type {Array<CrontabValue>}
     * @memberof CrontabFullListing
     */
    items: Array<CrontabValue>;
}
/**
 * @type CrontabValue
 * @export
 */
export type CrontabValue = CrontabValueCmd | CrontabValueVariable;

/**
 * 
 * @export
 * @interface CrontabValueCmd
 */
export interface CrontabValueCmd {
    /**
     * 
     * @type {CrontabValueCmdCronCmd}
     * @memberof CrontabValueCmd
     */
    cronCmd: CrontabValueCmdCronCmd;
}
/**
 * 
 * @export
 * @interface CrontabValueCmdCronCmd
 */
export interface CrontabValueCmdCronCmd {
    /**
     * 
     * @type {number}
     * @memberof CrontabValueCmdCronCmd
     */
    lineNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CrontabValueCmdCronCmd
     */
    expr: string;
}
/**
 * 
 * @export
 * @interface CrontabValueVariable
 */
export interface CrontabValueVariable {
    /**
     * 
     * @type {CrontabValueVariableVariable}
     * @memberof CrontabValueVariable
     */
    variable: CrontabValueVariableVariable;
}
/**
 * 
 * @export
 * @interface CrontabValueVariableVariable
 */
export interface CrontabValueVariableVariable {
    /**
     * 
     * @type {number}
     * @memberof CrontabValueVariableVariable
     */
    lineNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CrontabValueVariableVariable
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CrontabValueVariableVariable
     */
    val: string;
}
/**
 * 
 * @export
 * @interface CustomersListing
 */
export interface CustomersListing {
    /**
     * 
     * @type {Array<Org>}
     * @memberof CustomersListing
     */
    items: Array<Org>;
    /**
     * 
     * @type {number}
     * @memberof CustomersListing
     */
    total: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DaemonKind {
    mysqld = 'mysqld',
    pdns = 'pdns',
    postfixSmtp = 'postfixSmtp',
    postfixSmtps = 'postfixSmtps',
    postfixSubmissions = 'postfixSubmissions',
    dovcotImap = 'dovcotImap',
    dovcotImaps = 'dovcotImaps',
    dovcotPop3 = 'dovcotPop3',
    dovcotPop3s = 'dovcotPop3s',
    pureFtp = 'pureFtp'
}

/**
 * 
 * @export
 * @interface DatabaseRoleInfo
 */
export interface DatabaseRoleInfo {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof DatabaseRoleInfo
     */
    state: ServerRoleState;
    /**
     * 
     * @type {number}
     * @memberof DatabaseRoleInfo
     */
    usage: number;
    /**
     * 
     * @type {object}
     * @memberof DatabaseRoleInfo
     */
    mysqlStats: object;
    /**
     * 
     * @type {ServiceInfo}
     * @memberof DatabaseRoleInfo
     */
    mysqlcd: ServiceInfo;
    /**
     * The number of websites whose databases are assigned to be on this database role.
     * @type {number}
     * @memberof DatabaseRoleInfo
     */
    websitesCount: number;
}
/**
 * 
 * @export
 * @interface DedicatedSubscriptionInfo
 */
export interface DedicatedSubscriptionInfo {
    /**
     * 
     * @type {number}
     * @memberof DedicatedSubscriptionInfo
     */
    subscriptionId: number;
    /**
     * 
     * @type {string}
     * @memberof DedicatedSubscriptionInfo
     */
    customerOrgId: string;
    /**
     * 
     * @type {string}
     * @memberof DedicatedSubscriptionInfo
     */
    customerOrgName: string;
}
/**
 * 
 * @export
 * @interface DefaultDnsRecord
 */
export interface DefaultDnsRecord {
    /**
     * 
     * @type {string}
     * @memberof DefaultDnsRecord
     */
    id: string;
    /**
     * 
     * @type {DnsRecordKind}
     * @memberof DefaultDnsRecord
     */
    kind: DnsRecordKind;
    /**
     * 
     * @type {string}
     * @memberof DefaultDnsRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DefaultDnsRecord
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof DefaultDnsRecord
     */
    ttl?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DefaultDnsRecord
     */
    overrideConflicting?: boolean;
}
/**
 * Demo mode is a special mode that allows users to try out the application without having to sign up.
 * @export
 * @interface DemoMode
 */
export interface DemoMode {
    /**
     * 
     * @type {boolean}
     * @memberof DemoMode
     */
    status: boolean;
}
/**
 * The type of the btrfs device, if `device` the block device must already exist, otherwise (with `sparseFile`) it will be created from a new (not existing) sparse file. In both cases the block device will be formatted to btrfs and mounted in the given mount point.
 * @export
 * @enum {string}
 */
export enum DeviceKind {
    device = 'device',
    sparseFile = 'sparseFile'
}

/**
 * 
 * @export
 * @interface Disk
 */
export interface Disk {
    /**
     * 
     * @type {string}
     * @memberof Disk
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Disk
     */
    mountPoint: string;
    /**
     * 
     * @type {string}
     * @memberof Disk
     */
    fileSystem: string;
    /**
     * 
     * @type {SpaceUsage}
     * @memberof Disk
     */
    usage: SpaceUsage;
    /**
     * 
     * @type {string}
     * @memberof Disk
     */
    kind?: DiskKindEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DiskKindEnum {
    ssd = 'ssd',
    hdd = 'hdd'
}

/**
 * Detailed DNS query outcome, walking from root DNS to all the way back to IP addresses for a domain
 * @export
 * @interface DnsQueryOutcome
 */
export interface DnsQueryOutcome {
    /**
     * Fully qualified domain name
     * @type {string}
     * @memberof DnsQueryOutcome
     */
    fqdn: string;
    /**
     * name and IP of the root server that was queried
     * @type {{ [key: string]: string; }}
     * @memberof DnsQueryOutcome
     */
    root: { [key: string]: string; };
    /**
     * Tree of top level domain name servers
     * @type {{ [key: string]: TldNs; }}
     * @memberof DnsQueryOutcome
     */
    tldNsMap: { [key: string]: TldNs; };
}
/**
 * 
 * @export
 * @interface DnsRecord
 */
export interface DnsRecord {
    /**
     * 
     * @type {string}
     * @memberof DnsRecord
     */
    id: string;
    /**
     * 
     * @type {DnsRecordKind}
     * @memberof DnsRecord
     */
    kind: DnsRecordKind;
    /**
     * 
     * @type {string}
     * @memberof DnsRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DnsRecord
     */
    value: string;
    /**
     * In seconds
     * @type {number}
     * @memberof DnsRecord
     */
    ttl: number;
    /**
     * Is CloudFlare proxy on?
     * @type {boolean}
     * @memberof DnsRecord
     */
    proxy: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DnsRecordKind {
    A = 'A',
    AAAA = 'AAAA',
    CNAME = 'CNAME',
    TXT = 'TXT',
    SPF = 'SPF',
    SRV = 'SRV',
    NS = 'NS',
    MX = 'MX',
    PTR = 'PTR',
    DS = 'DS',
    CAA = 'CAA'
}

/**
 * 
 * @export
 * @interface DnsRoleInfo
 */
export interface DnsRoleInfo {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof DnsRoleInfo
     */
    state: ServerRoleState;
    /**
     * 
     * @type {number}
     * @memberof DnsRoleInfo
     */
    usage: number;
    /**
     * 
     * @type {number}
     * @memberof DnsRoleInfo
     */
    zonesCount: number;
    /**
     * 
     * @type {ServiceInfo}
     * @memberof DnsRoleInfo
     */
    dnscd: ServiceInfo;
    /**
     * The number of websites whose DNS zones are assigned to be on this dns role.
     * @type {number}
     * @memberof DnsRoleInfo
     */
    websitesCount: number;
}
/**
 * 
 * @export
 * @interface DnsSoa
 */
export interface DnsSoa {
    /**
     * 
     * @type {string}
     * @memberof DnsSoa
     */
    adminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof DnsSoa
     */
    nameServer: string;
    /**
     * 
     * @type {number}
     * @memberof DnsSoa
     */
    expire: number;
    /**
     * 
     * @type {number}
     * @memberof DnsSoa
     */
    refresh: number;
    /**
     * 
     * @type {number}
     * @memberof DnsSoa
     */
    retry: number;
    /**
     * In seconds
     * @type {number}
     * @memberof DnsSoa
     */
    ttl: number;
}
/**
 * Resolved = domain resolves to our servers. ForeignServer = domain resolves but points to somewhere else. Failed = DNS query failed for the domain (potentially not configured). Unknown = Not queried. Error = There was an internal error. Mixed = Website\'s domains resolved with different statuses.
 * @export
 * @enum {string}
 */
export enum DnsStatus {
    Resolved = 'Resolved',
    ForeignServer = 'ForeignServer',
    Failed = 'Failed',
    Mixed = 'Mixed',
    Unknown = 'Unknown',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface DnsThirdPartyProvider
 */
export interface DnsThirdPartyProvider {
    /**
     * 
     * @type {number}
     * @memberof DnsThirdPartyProvider
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DnsThirdPartyProvider
     */
    url: string;
    /**
     * Map of string keys to string values.
     * @type {{ [key: string]: string; }}
     * @memberof DnsThirdPartyProvider
     */
    headers?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface DnsZone
 */
export interface DnsZone {
    /**
     * 
     * @type {string}
     * @memberof DnsZone
     */
    origin: string;
    /**
     * 
     * @type {DnsSoa}
     * @memberof DnsZone
     */
    soa: DnsSoa;
    /**
     * 
     * @type {Array<DnsRecord>}
     * @memberof DnsZone
     */
    records: Array<DnsRecord>;
    /**
     * 
     * @type {string}
     * @memberof DnsZone
     */
    dnssecDsRecords?: string;
    /**
     * 
     * @type {string}
     * @memberof DnsZone
     */
    dnssecDnskeyRecords?: string;
}
/**
 * 
 * @export
 * @interface DockerRegistry
 */
export interface DockerRegistry {
    /**
     * 
     * @type {string}
     * @memberof DockerRegistry
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof DockerRegistry
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof DockerRegistry
     */
    password: string;
}
/**
 * 
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    dkim: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Domain
     */
    spf: boolean;
    /**
     * 
     * @type {CloudFlareStatus}
     * @memberof Domain
     */
    cloudflareStatus: CloudFlareStatus;
    /**
     * 
     * @type {string}
     * @memberof Domain
     */
    cloudflareTokenFriendlyName?: string;
}
/**
 * 
 * @export
 * @interface DomainInUseStatus
 */
export interface DomainInUseStatus {
    /**
     * 
     * @type {string}
     * @memberof DomainInUseStatus
     */
    status: DomainInUseStatusStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DomainInUseStatus
     */
    websiteId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DomainInUseStatusStatusEnum {
    prohibited = 'prohibited',
    notInUse = 'notInUse',
    inUseCurrentOrg = 'inUseCurrentOrg',
    inUseAnotherOrg = 'inUseAnotherOrg',
    inUseDeletedSite = 'inUseDeletedSite'
}

/**
 * 
 * @export
 * @interface DomainIp
 */
export interface DomainIp {
    /**
     * 
     * @type {string}
     * @memberof DomainIp
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof DomainIp
     */
    ip: string;
}
/**
 * 
 * @export
 * @interface DomainMapping
 */
export interface DomainMapping {
    /**
     * 
     * @type {string}
     * @memberof DomainMapping
     */
    domainId: string;
    /**
     * 
     * @type {string}
     * @memberof DomainMapping
     */
    websiteId: string;
    /**
     * 
     * @type {string}
     * @memberof DomainMapping
     */
    domain: string;
    /**
     * 
     * @type {DomainMappingKind}
     * @memberof DomainMapping
     */
    mappingKind: DomainMappingKind;
    /**
     * 
     * @type {string}
     * @memberof DomainMapping
     */
    documentRoot: string;
    /**
     * 
     * @type {DomainSslCert}
     * @memberof DomainMapping
     */
    cert?: DomainSslCert;
    /**
     * 
     * @type {CloudFlareStatus}
     * @memberof DomainMapping
     */
    cloudflareStatus: CloudFlareStatus;
    /**
     * 
     * @type {string}
     * @memberof DomainMapping
     */
    cloudflareFriendlyName?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainMapping
     */
    cloudflareTokenId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DomainMappingKind {
    primary = 'primary',
    preview = 'preview',
    addon = 'addon',
    alias = 'alias',
    subdomain = 'subdomain'
}

/**
 * 
 * @export
 * @interface DomainMappingUpdate
 */
export interface DomainMappingUpdate {
    /**
     * 
     * @type {string}
     * @memberof DomainMappingUpdate
     */
    documentRoot?: string;
    /**
     * 
     * @type {DomainMappingKind}
     * @memberof DomainMappingUpdate
     */
    kind?: DomainMappingKind;
}
/**
 * 
 * @export
 * @interface DomainMappingsFullListing
 */
export interface DomainMappingsFullListing {
    /**
     * 
     * @type {Array<DomainMapping>}
     * @memberof DomainMappingsFullListing
     */
    items: Array<DomainMapping>;
}
/**
 * 
 * @export
 * @interface DomainSslCert
 */
export interface DomainSslCert {
    /**
     * 
     * @type {string}
     * @memberof DomainSslCert
     */
    cn: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCert
     */
    expires: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCert
     */
    issued: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCert
     */
    issuer: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainSslCert
     */
    sans: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DomainSslCert
     */
    forceHttps: boolean;
}
/**
 * 
 * @export
 * @interface DomainSslCertWithData
 */
export interface DomainSslCertWithData {
    /**
     * 
     * @type {string}
     * @memberof DomainSslCertWithData
     */
    cn: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCertWithData
     */
    expires: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCertWithData
     */
    issued: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCertWithData
     */
    issuer: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DomainSslCertWithData
     */
    sans: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCertWithData
     */
    cert: string;
    /**
     * 
     * @type {string}
     * @memberof DomainSslCertWithData
     */
    key: string;
    /**
     * 
     * @type {boolean}
     * @memberof DomainSslCertWithData
     */
    forceHttps?: boolean;
}
/**
 * 
 * @export
 * @interface DomainWithUuid
 */
export interface DomainWithUuid {
    /**
     * 
     * @type {string}
     * @memberof DomainWithUuid
     */
    domainId: string;
    /**
     * 
     * @type {string}
     * @memberof DomainWithUuid
     */
    domainName: string;
    /**
     * 
     * @type {boolean}
     * @memberof DomainWithUuid
     */
    isPrimary: boolean;
}
/**
 * 
 * @export
 * @interface DomainsListing
 */
export interface DomainsListing {
    /**
     * 
     * @type {Array<Domain>}
     * @memberof DomainsListing
     */
    items: Array<Domain>;
    /**
     * 
     * @type {number}
     * @memberof DomainsListing
     */
    total: number;
}
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    websiteId: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    mailboxName?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    address: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Email
     */
    aliases: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    hasMailbox?: boolean;
    /**
     * 
     * @type {EmailStatus}
     * @memberof Email
     */
    status: EmailStatus;
    /**
     * 
     * @type {Quota}
     * @memberof Email
     */
    quota?: Quota;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    autorespondersCount: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    forwardersCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    emailKind?: EmailEmailKindEnum;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    domainId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EmailEmailKindEnum {
    normal = 'normal',
    internal = 'internal'
}

/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    email: string;
}
/**
 * 
 * @export
 * @interface EmailAuth
 */
export interface EmailAuth {
    /**
     * 
     * @type {boolean}
     * @memberof EmailAuth
     */
    dkim: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailAuth
     */
    dkimPublicKey?: string;
}
/**
 * 
 * @export
 * @interface EmailAuthUpdate
 */
export interface EmailAuthUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof EmailAuthUpdate
     */
    dkim?: boolean;
}
/**
 * 
 * @export
 * @interface EmailAuthValidation
 */
export interface EmailAuthValidation {
    /**
     * 
     * @type {boolean}
     * @memberof EmailAuthValidation
     */
    dkimIsValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailAuthValidation
     */
    spfIsValid: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailAuthValidation
     */
    foundTxtRecordsDkim: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailAuthValidation
     */
    foundTxtRecordsSpf?: Array<string>;
}
/**
 * 
 * @export
 * @interface EmailDetailed
 */
export interface EmailDetailed {
    /**
     * 
     * @type {string}
     * @memberof EmailDetailed
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDetailed
     */
    mailboxName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDetailed
     */
    address: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailDetailed
     */
    aliases: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailDetailed
     */
    forwarders?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EmailDetailed
     */
    hasMailbox?: boolean;
    /**
     * 
     * @type {WebsiteStatus}
     * @memberof EmailDetailed
     */
    status: WebsiteStatus;
    /**
     * 
     * @type {Quota}
     * @memberof EmailDetailed
     */
    quota?: Quota;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailDetailed
     */
    blacklist: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailDetailed
     */
    whitelist: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmailDetailed
     */
    autorespondersCount: number;
    /**
     * 
     * @type {string}
     * @memberof EmailDetailed
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface EmailForwardersUpdate
 */
export interface EmailForwardersUpdate {
    /**
     * email addresses to forward to.
     * @type {Array<string>}
     * @memberof EmailForwardersUpdate
     */
    forwarders: Array<string>;
}
/**
 * 
 * @export
 * @interface EmailPasswordUpdate
 */
export interface EmailPasswordUpdate {
    /**
     * 
     * @type {string}
     * @memberof EmailPasswordUpdate
     */
    password: string;
}
/**
 * 
 * @export
 * @interface EmailPublicIp
 */
export interface EmailPublicIp {
    /**
     * 
     * @type {string}
     * @memberof EmailPublicIp
     */
    ipAddress: string;
}
/**
 * 
 * @export
 * @interface EmailRoleInfo
 */
export interface EmailRoleInfo {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof EmailRoleInfo
     */
    state: ServerRoleState;
    /**
     * 
     * @type {number}
     * @memberof EmailRoleInfo
     */
    usage: number;
    /**
     * 
     * @type {number}
     * @memberof EmailRoleInfo
     */
    mailboxCount: number;
    /**
     * 
     * @type {number}
     * @memberof EmailRoleInfo
     */
    failedDeliveryCount: number;
    /**
     * The number of websites whose emails are assigned to be on this email role.
     * @type {number}
     * @memberof EmailRoleInfo
     */
    websitesCount: number;
}
/**
 * 
 * @export
 * @interface EmailServerHostnameOverride
 */
export interface EmailServerHostnameOverride {
    /**
     * 
     * @type {string}
     * @memberof EmailServerHostnameOverride
     */
    domain?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EmailStatus {
    active = 'active',
    disabled = 'disabled',
    deleted = 'deleted',
    suspended = 'suspended'
}

/**
 * 
 * @export
 * @interface EmailsListing
 */
export interface EmailsListing {
    /**
     * 
     * @type {Array<Email>}
     * @memberof EmailsListing
     */
    items: Array<Email>;
    /**
     * 
     * @type {number}
     * @memberof EmailsListing
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ForwardersFullListing
 */
export interface ForwardersFullListing {
    /**
     * 
     * @type {Array<string>}
     * @memberof ForwardersFullListing
     */
    items: Array<string>;
}
/**
 * File system quota info in bytes.
 * @export
 * @interface FsQuotaInfo
 */
export interface FsQuotaInfo {
    /**
     * 
     * @type {number}
     * @memberof FsQuotaInfo
     */
    totalAvailable: number;
    /**
     * 
     * @type {number}
     * @memberof FsQuotaInfo
     */
    used?: number;
}
/**
 * File system quota settings in bytes.
 * @export
 * @interface FsQuotaLimit
 */
export interface FsQuotaLimit {
    /**
     * 
     * @type {number}
     * @memberof FsQuotaLimit
     */
    totalAvailable: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FsQuotaStatus {
    disabled = 'disabled',
    enabling = 'enabling',
    enabled = 'enabled'
}

/**
 * 
 * @export
 * @interface FtpUser
 */
export interface FtpUser {
    /**
     * 
     * @type {string}
     * @memberof FtpUser
     */
    account: string;
    /**
     * Homedir must be a relative path to the website\'s base dir. Set to empty string to use website base dir as FtpUser\'s home.
     * @type {string}
     * @memberof FtpUser
     */
    homeDir: string;
}
/**
 * 
 * @export
 * @interface FtpUserUpdate
 */
export interface FtpUserUpdate {
    /**
     * Homedir must be a relative path to the website\'s base dir. Set to empty string to use website base dir as FtpUser\'s home.
     * @type {string}
     * @memberof FtpUserUpdate
     */
    homeDir?: string;
    /**
     * 
     * @type {string}
     * @memberof FtpUserUpdate
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface FtpUsersFullListing
 */
export interface FtpUsersFullListing {
    /**
     * 
     * @type {Array<FtpUser>}
     * @memberof FtpUsersFullListing
     */
    items: Array<FtpUser>;
}
/**
 * 
 * @export
 * @interface HttpError
 */
export interface HttpError {
    /**
     * 
     * @type {string}
     * @memberof HttpError
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof HttpError
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof HttpError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface HttpdStatus
 */
export interface HttpdStatus {
    /**
     * 
     * @type {string}
     * @memberof HttpdStatus
     */
    rawOutput: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ImportKind {
    cPanel = 'cPanel',
    plesk = 'plesk',
    pleskStreaming = 'pleskStreaming'
}

/**
 * 
 * @export
 * @interface ImportMigrationEntry
 */
export interface ImportMigrationEntry {
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    filename: string;
    /**
     * 
     * @type {number}
     * @memberof ImportMigrationEntry
     */
    filesize: number;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    status: ImportMigrationEntryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    importType: ImportMigrationEntryImportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    updatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof ImportMigrationEntry
     */
    percentageComplete: number;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationEntry
     */
    primaryDomain?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ImportMigrationEntryStatusEnum {
    unprocessed = 'unprocessed',
    processed = 'processed',
    queued = 'queued',
    analyzing = 'analyzing',
    inProgress = 'inProgress',
    failed = 'failed',
    complete = 'complete'
}
/**
    * @export
    * @enum {string}
    */
export enum ImportMigrationEntryImportTypeEnum {
    cPanel = 'cPanel',
    plesk = 'plesk',
    pleskStreaming = 'pleskStreaming'
}

/**
 * 
 * @export
 * @interface ImportMigrationFullListing
 */
export interface ImportMigrationFullListing {
    /**
     * 
     * @type {Array<ImportMigrationEntry>}
     * @memberof ImportMigrationFullListing
     */
    items: Array<ImportMigrationEntry>;
}
/**
 * 
 * @export
 * @interface ImportMigrationLogEntry
 */
export interface ImportMigrationLogEntry {
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationLogEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationLogEntry
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationLogEntry
     */
    migrationId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportMigrationLogEntry
     */
    logData: string;
    /**
     * 
     * @type {number}
     * @memberof ImportMigrationLogEntry
     */
    percentageComplete: number;
    /**
     * 
     * @type {LogLevel}
     * @memberof ImportMigrationLogEntry
     */
    level: LogLevel;
    /**
     * 
     * @type {LogAction}
     * @memberof ImportMigrationLogEntry
     */
    action: LogAction;
    /**
     * 
     * @type {LogKind}
     * @memberof ImportMigrationLogEntry
     */
    kind: LogKind;
}
/**
 * 
 * @export
 * @interface ImportServerDomain
 */
export interface ImportServerDomain {
    /**
     * 
     * @type {string}
     * @memberof ImportServerDomain
     */
    importServerId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportServerDomain
     */
    domain: string;
    /**
     * 
     * @type {DomainMappingKind}
     * @memberof ImportServerDomain
     */
    kind: DomainMappingKind;
    /**
     * 
     * @type {string}
     * @memberof ImportServerDomain
     */
    user: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportServerDomain
     */
    movable: boolean;
}
/**
 * 
 * @export
 * @interface ImportServerDomainsFullListing
 */
export interface ImportServerDomainsFullListing {
    /**
     * 
     * @type {Array<ImportServerDomain>}
     * @memberof ImportServerDomainsFullListing
     */
    items: Array<ImportServerDomain>;
}
/**
 * 
 * @export
 * @interface ImportServerDomainsListing
 */
export interface ImportServerDomainsListing {
    /**
     * 
     * @type {number}
     * @memberof ImportServerDomainsListing
     */
    total: number;
    /**
     * 
     * @type {Array<ImportServerDomain>}
     * @memberof ImportServerDomainsListing
     */
    items: Array<ImportServerDomain>;
}
/**
 * 
 * @export
 * @interface ImportServerSettings
 */
export interface ImportServerSettings {
    /**
     * 
     * @type {string}
     * @memberof ImportServerSettings
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ImportServerSettings
     */
    hostname: string;
    /**
     * 
     * @type {string}
     * @memberof ImportServerSettings
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof ImportServerSettings
     */
    sshUser: string;
    /**
     * 
     * @type {string}
     * @memberof ImportServerSettings
     */
    sshPublicKey: string;
    /**
     * 
     * @type {ImportKind}
     * @memberof ImportServerSettings
     */
    importType: ImportKind;
    /**
     * 
     * @type {ServerMigrationSettingsAuthType}
     * @memberof ImportServerSettings
     */
    authKind: ServerMigrationSettingsAuthType;
    /**
     * 
     * @type {string}
     * @memberof ImportServerSettings
     */
    authUser: string;
    /**
     * 
     * @type {number}
     * @memberof ImportServerSettings
     */
    sshPort: number;
    /**
     * 
     * @type {number}
     * @memberof ImportServerSettings
     */
    apiPort: number;
    /**
     * 
     * @type {boolean}
     * @memberof ImportServerSettings
     */
    allowPartialSync: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportServerSettings
     */
    asUserRoot: boolean;
}
/**
 * 
 * @export
 * @interface ImportServerSettingsFullListing
 */
export interface ImportServerSettingsFullListing {
    /**
     * 
     * @type {Array<ImportServerSettings>}
     * @memberof ImportServerSettingsFullListing
     */
    items: Array<ImportServerSettings>;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedData
 */
export interface ImporterAnalyzedData {
    /**
     * 
     * @type {Array<ImporterAnalyzedDataDomains>}
     * @memberof ImporterAnalyzedData
     */
    domains: Array<ImporterAnalyzedDataDomains>;
    /**
     * 
     * @type {Array<ImporterAnalyzedDataMysqlDatabases>}
     * @memberof ImporterAnalyzedData
     */
    mysqlDatabases?: Array<ImporterAnalyzedDataMysqlDatabases>;
    /**
     * 
     * @type {Array<ImporterAnalyzedDataMysqlUsers>}
     * @memberof ImporterAnalyzedData
     */
    mysqlUsers?: Array<ImporterAnalyzedDataMysqlUsers>;
    /**
     * 
     * @type {Array<ImporterAnalyzedDataMysqlGrants>}
     * @memberof ImporterAnalyzedData
     */
    mysqlGrants?: Array<ImporterAnalyzedDataMysqlGrants>;
    /**
     * 
     * @type {Array<ImporterAnalyzedDataCrontabs>}
     * @memberof ImporterAnalyzedData
     */
    crontabs?: Array<ImporterAnalyzedDataCrontabs>;
    /**
     * 
     * @type {Array<ImporterAnalyzedDataFtps>}
     * @memberof ImporterAnalyzedData
     */
    ftps?: Array<ImporterAnalyzedDataFtps>;
    /**
     * 
     * @type {Array<ImporterAnalyzedDataMailboxes>}
     * @memberof ImporterAnalyzedData
     */
    mailboxes?: Array<ImporterAnalyzedDataMailboxes>;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataCrontabs
 */
export interface ImporterAnalyzedDataCrontabs {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataCrontabs
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataCrontabs
     */
    crontab: string;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataDomains
 */
export interface ImporterAnalyzedDataDomains {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataDomains
     */
    domainName: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataDomains
     */
    domainKind: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataDomains
     */
    phpVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataDomains
     */
    documentRoot?: string;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataFtps
 */
export interface ImporterAnalyzedDataFtps {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataFtps
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataFtps
     */
    homedir: string;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataMailboxes
 */
export interface ImporterAnalyzedDataMailboxes {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMailboxes
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMailboxes
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImporterAnalyzedDataMailboxes
     */
    hasMailbox: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImporterAnalyzedDataMailboxes
     */
    isSuspended: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImporterAnalyzedDataMailboxes
     */
    forwarders?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ImporterAnalyzedDataMailboxes
     */
    quota?: number;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataMysqlDatabases
 */
export interface ImporterAnalyzedDataMysqlDatabases {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlDatabases
     */
    dbName: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlDatabases
     */
    sqlInitPath: string;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataMysqlGrants
 */
export interface ImporterAnalyzedDataMysqlGrants {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlGrants
     */
    dbName: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlGrants
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlGrants
     */
    tableName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImporterAnalyzedDataMysqlGrants
     */
    privilege?: Array<string>;
}
/**
 * 
 * @export
 * @interface ImporterAnalyzedDataMysqlUsers
 */
export interface ImporterAnalyzedDataMysqlUsers {
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlUsers
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterAnalyzedDataMysqlUsers
     */
    authPlugin: string;
}
/**
 * 
 * @export
 * @interface ImporterMigrationReqBody
 */
export interface ImporterMigrationReqBody {
    /**
     * 
     * @type {number}
     * @memberof ImporterMigrationReqBody
     */
    subscriptionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ImporterMigrationReqBody
     */
    forceQueue?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImporterMigrationReqBody
     */
    appServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterMigrationReqBody
     */
    backupServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterMigrationReqBody
     */
    dbServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImporterMigrationReqBody
     */
    emailServerId?: string;
}
/**
 * 
 * @export
 * @interface IniSetting
 */
export interface IniSetting {
    /**
     * 
     * @type {string}
     * @memberof IniSetting
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof IniSetting
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof IniSetting
     */
    section?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {VhostWebserverKind}
     * @memberof InlineObject1
     */
    webserver: VhostWebserverKind;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    version: string;
}
/**
 * 
 * @export
 * @interface Install
 */
export interface Install {
    /**
     * 
     * @type {LoginInfo}
     * @memberof Install
     */
    owner: LoginInfo;
    /**
     * 
     * @type {string}
     * @memberof Install
     */
    orgName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Install
     */
    acceptTerms?: boolean;
    /**
     * 
     * @type {CPLocale}
     * @memberof Install
     */
    locale: CPLocale;
}
/**
 * 
 * @export
 * @interface InstallCmd
 */
export interface InstallCmd {
    /**
     * 
     * @type {string}
     * @memberof InstallCmd
     */
    cmd: string;
}
/**
 * 
 * @export
 * @interface InstallWpPlugin
 */
export interface InstallWpPlugin {
    /**
     * 
     * @type {string}
     * @memberof InstallWpPlugin
     */
    pluginName: string;
}
/**
 * 
 * @export
 * @interface InstallWpThemeRequest
 */
export interface InstallWpThemeRequest {
    /**
     * 
     * @type {string}
     * @memberof InstallWpThemeRequest
     */
    name: string;
    /**
     * Activate theme after installation
     * @type {boolean}
     * @memberof InstallWpThemeRequest
     */
    activate?: boolean;
}
/**
 * 
 * @export
 * @interface InstallableWebsiteApp
 */
export interface InstallableWebsiteApp {
    /**
     * 
     * @type {WebsiteAppKind}
     * @memberof InstallableWebsiteApp
     */
    app: WebsiteAppKind;
    /**
     * 
     * @type {string}
     * @memberof InstallableWebsiteApp
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallableWebsiteApp
     */
    isLatest: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstallableWebsiteApp
     */
    description?: string;
    /**
     * Approximate number of bytes after fresh installation.
     * @type {number}
     * @memberof InstallableWebsiteApp
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallableWebsiteApp
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface InstallableWebsiteAppsFullListing
 */
export interface InstallableWebsiteAppsFullListing {
    /**
     * 
     * @type {Array<InstallableWebsiteApp>}
     * @memberof InstallableWebsiteAppsFullListing
     */
    items: Array<InstallableWebsiteApp>;
}
/**
 * 
 * @export
 * @interface InterfaceIp
 */
export interface InterfaceIp {
    /**
     * 
     * @type {string}
     * @memberof InterfaceIp
     */
    ip: string;
    /**
     * 
     * @type {string}
     * @memberof InterfaceIp
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof InterfaceIp
     */
    kind: InterfaceIpKindEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InterfaceIpKindEnum {
    primary = 'primary',
    secondary = 'secondary'
}

/**
 * 
 * @export
 * @interface InviteValidation
 */
export interface InviteValidation {
    /**
     * 
     * @type {boolean}
     * @memberof InviteValidation
     */
    isValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof InviteValidation
     */
    loginId?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteValidation
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteValidation
     */
    orgName?: string;
    /**
     * 
     * @type {Role}
     * @memberof InviteValidation
     */
    role?: Role;
}
/**
 * 
 * @export
 * @interface LetsEncryptPreflightResult
 */
export interface LetsEncryptPreflightResult {
    /**
     * 
     * @type {boolean}
     * @memberof LetsEncryptPreflightResult
     */
    canIssue: boolean;
    /**
     * 
     * @type {string}
     * @memberof LetsEncryptPreflightResult
     */
    error?: string;
}
/**
 * Enhance Licence information with its status and key (if set)
 * @export
 * @interface LicenceInfo
 */
export interface LicenceInfo {
    /**
     * 
     * @type {string}
     * @memberof LicenceInfo
     */
    key?: string;
    /**
     * 
     * @type {LicenceStatus}
     * @memberof LicenceInfo
     */
    status: LicenceStatus;
}
/**
 * 
 * @export
 * @interface LicenceKey
 */
export interface LicenceKey {
    /**
     * 
     * @type {string}
     * @memberof LicenceKey
     */
    key: string;
}
/**
 * Enhance Licence status
 * @export
 * @enum {string}
 */
export enum LicenceStatus {
    active = 'active',
    cancelled = 'cancelled',
    suspended = 'suspended',
    trial = 'trial',
    unpaid = 'unpaid',
    unknown = 'unknown'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum LocalRemote {
    local = 'local',
    remote = 'remote'
}

/**
 * Whether a domain is treated as local or remote by the MTA
 * @export
 * @interface LocalRemoteBody
 */
export interface LocalRemoteBody {
    /**
     * 
     * @type {LocalRemote}
     * @memberof LocalRemoteBody
     */
    localRemote?: LocalRemote;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LogAction {
    create = 'create',
    update = 'update',
    delete = 'delete'
}

/**
 * The service has returned a payload we didn\'t expect. There\'s a chance of different service running at the standard port.
 * @export
 * @interface LogGreetingsMessageMetadata
 */
export interface LogGreetingsMessageMetadata {
    /**
     * This is a list of bytes which have been returned by the service. For example \"[50,50,48,32]\".
     * @type {string}
     * @memberof LogGreetingsMessageMetadata
     */
    greetingsMessage: string;
}
/**
 * What was the status code of an http request and its truncated body. Used with `failed4xx` and `failed5xx` statuses.
 * @export
 * @interface LogHttpMetadata
 */
export interface LogHttpMetadata {
    /**
     * 
     * @type {number}
     * @memberof LogHttpMetadata
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof LogHttpMetadata
     */
    body: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LogKind {
    migration = 'migration',
    website = 'website',
    websitePrimaryDomain = 'websitePrimaryDomain',
    websiteSecondyDomain = 'websiteSecondyDomain',
    websiteHomeDir = 'websiteHomeDir',
    mysqlDb = 'mysqlDb',
    mysqlUser = 'mysqlUser',
    mysqlAccessHost = 'mysqlAccessHost',
    mysqlGrant = 'mysqlGrant',
    cron = 'cron',
    ftp = 'ftp',
    ftpUser = 'ftpUser',
    email = 'email',
    emailForwarder = 'emailForwarder',
    emailAccount = 'emailAccount',
    emailData = 'emailData',
    emailDataTransfer = 'emailDataTransfer'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum LogLevel {
    info = 'info',
    error = 'error'
}

/**
 * Limit the log level of a service.
 * @export
 * @enum {string}
 */
export enum LogLevelLimit {
    error = 'error',
    warn = 'warn',
    info = 'info',
    trace = 'trace',
    debug = 'debug'
}

/**
 * How long the request took. It\'s used with `ok` and `slow` log statuses.
 * @export
 * @interface LogTimeMetadata
 */
export interface LogTimeMetadata {
    /**
     * 
     * @type {number}
     * @memberof LogTimeMetadata
     */
    requestTimeMs: number;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    colorCode: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    registeredAt: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    avatarPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    passwordLastChangedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    authMethod: LoginAuthMethodEnum;
    /**
     * 
     * @type {CPLocale}
     * @memberof Login
     */
    locale: CPLocale;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginAuthMethodEnum {
    basic = 'basic',
    email = 'email',
    otp = 'otp'
}

/**
 * 
 * @export
 * @interface Login2FA
 */
export interface Login2FA {
    /**
     * 
     * @type {string}
     * @memberof Login2FA
     */
    authMethod: Login2FAAuthMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof Login2FA
     */
    pin: string;
    /**
     * 
     * @type {Login2FARememberMe}
     * @memberof Login2FA
     */
    rememberMe?: Login2FARememberMe;
}

/**
    * @export
    * @enum {string}
    */
export enum Login2FAAuthMethodEnum {
    basic = 'basic',
    email = 'email',
    otp = 'otp'
}

/**
 * 
 * @export
 * @interface Login2FARememberMe
 */
export interface Login2FARememberMe {
    /**
     * The unique ID of the device. It has to be 32 character;
     * @type {string}
     * @memberof Login2FARememberMe
     */
    deviceId: string;
    /**
     * Defines how many seconds deviceId will be remembered. Min 1 hour, max 3 months
     * @type {number}
     * @memberof Login2FARememberMe
     */
    ttl: number;
}
/**
 * 
 * @export
 * @interface LoginCreds
 */
export interface LoginCreds {
    /**
     * 
     * @type {string}
     * @memberof LoginCreds
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginCreds
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginCreds
     */
    deviceId?: string;
}
/**
 * 
 * @export
 * @interface LoginInfo
 */
export interface LoginInfo {
    /**
     * 
     * @type {string}
     * @memberof LoginInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginInfo
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginInfo
     */
    name: string;
}
/**
 * 
 * @export
 * @interface LoginMembership
 */
export interface LoginMembership {
    /**
     * 
     * @type {string}
     * @memberof LoginMembership
     */
    memberId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginMembership
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginMembership
     */
    orgName: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginMembership
     */
    isMasterOrg: boolean;
    /**
     * 
     * @type {Array<Role>}
     * @memberof LoginMembership
     */
    roles: Array<Role>;
    /**
     * 
     * @type {number}
     * @memberof LoginMembership
     */
    siteAccessCount: number;
}
/**
 * 
 * @export
 * @interface LoginMemberships
 */
export interface LoginMemberships {
    /**
     * 
     * @type {Array<LoginMembership>}
     * @memberof LoginMemberships
     */
    memberships: Array<LoginMembership>;
}
/**
 * 
 * @export
 * @interface LoginsListing
 */
export interface LoginsListing {
    /**
     * 
     * @type {Array<Login>}
     * @memberof LoginsListing
     */
    items: Array<Login>;
    /**
     * 
     * @type {number}
     * @memberof LoginsListing
     */
    total: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MaintenanceMode {
    activate = 'activate',
    deactivate = 'deactivate'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MaintenanceModeStatus {
    active = 'active',
    deactivated = 'deactivated'
}

/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    loginId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    name: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof Member
     */
    roles: Array<Role>;
    /**
     * This field is only present if member has \"SiteAccess\" role. In this case, the list contains the ids of the websites to which member has access.
     * @type {Array<string>}
     * @memberof Member
     */
    siteAccesses: Array<string>;
    /**
     * The notifications configured for this member.
     * @type {Array<string>}
     * @memberof Member
     */
    notifications: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    joinedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    avatarPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    colorCode: string;
}
/**
 * 
 * @export
 * @interface MembersListing
 */
export interface MembersListing {
    /**
     * 
     * @type {Array<Member>}
     * @memberof MembersListing
     */
    items: Array<Member>;
    /**
     * 
     * @type {number}
     * @memberof MembersListing
     */
    total: number;
}
/**
 * Each Metrics entry represts an hourly window of banwidth usage Note: depending upon the polling time, most recent hour values might not       be complete yet and are subject to change if fetched at a later time.
 * @export
 * @interface MetricsEntry
 */
export interface MetricsEntry {
    /**
     * Time at the beginning of the unit for this bandwidth consumption entry
     * @type {string}
     * @memberof MetricsEntry
     */
    datetime: string;
    /**
     * 
     * @type {number}
     * @memberof MetricsEntry
     */
    bytesReceived: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsEntry
     */
    bytesSent: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsEntry
     */
    uniqueHits: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsEntry
     */
    botHits: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsEntry
     */
    totalHits: number;
}
/**
 * error and migrationId are mutually exclusive
 * @export
 * @interface MigrationCreationOutcome
 */
export interface MigrationCreationOutcome {
    /**
     * 
     * @type {HttpError}
     * @memberof MigrationCreationOutcome
     */
    error?: HttpError;
    /**
     * 
     * @type {string}
     * @memberof MigrationCreationOutcome
     */
    migrationId?: string;
}
/**
 * 
 * @export
 * @interface MigrationDetails
 */
export interface MigrationDetails {
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    websiteId: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    sourceServerId: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    destServerId: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    destServerName: string;
    /**
     * 
     * @type {ServerRole}
     * @memberof MigrationDetails
     */
    serverRole: ServerRole;
    /**
     * 
     * @type {MigrationStatus}
     * @memberof MigrationDetails
     */
    migrationStatus: MigrationStatus;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    lastUpdated: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    websitePrimaryDomain: string;
    /**
     * 
     * @type {number}
     * @memberof MigrationDetails
     */
    percentageComplete: number;
    /**
     * 
     * @type {string}
     * @memberof MigrationDetails
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface MigrationLog
 */
export interface MigrationLog {
    /**
     * 
     * @type {number}
     * @memberof MigrationLog
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MigrationLog
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationLog
     */
    migrationId: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationLog
     */
    logData: string;
}
/**
 * 
 * @export
 * @interface MigrationSessionCreationError
 */
export interface MigrationSessionCreationError {
    /**
     * 
     * @type {Array<MigrationCreationOutcome>}
     * @memberof MigrationSessionCreationError
     */
    migrations: Array<MigrationCreationOutcome>;
}
/**
 * 
 * @export
 * @interface MigrationSessionCreationOk
 */
export interface MigrationSessionCreationOk {
    /**
     * 
     * @type {string}
     * @memberof MigrationSessionCreationOk
     */
    sessionId: string;
    /**
     * 
     * @type {number}
     * @memberof MigrationSessionCreationOk
     */
    okCount: number;
    /**
     * 
     * @type {Array<MigrationCreationOutcome>}
     * @memberof MigrationSessionCreationOk
     */
    migrations: Array<MigrationCreationOutcome>;
}
/**
 * 
 * @export
 * @interface MigrationSessionDetails
 */
export interface MigrationSessionDetails {
    /**
     * 
     * @type {string}
     * @memberof MigrationSessionDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationSessionDetails
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationSessionDetails
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof MigrationSessionDetails
     */
    updatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof MigrationSessionDetails
     */
    migrationsCount: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationSessionDetails
     */
    percentageComplete: number;
    /**
     * 
     * @type {number}
     * @memberof MigrationSessionDetails
     */
    notReadyCount: number;
}
/**
 * 
 * @export
 * @interface MigrationSessionsListing
 */
export interface MigrationSessionsListing {
    /**
     * 
     * @type {Array<MigrationSessionDetails>}
     * @memberof MigrationSessionsListing
     */
    items: Array<MigrationSessionDetails>;
    /**
     * 
     * @type {number}
     * @memberof MigrationSessionsListing
     */
    total: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MigrationStatus {
    queued = 'queued',
    inProgress = 'inProgress',
    failed = 'failed',
    complete = 'complete'
}

/**
 * 
 * @export
 * @interface MigrationsListing
 */
export interface MigrationsListing {
    /**
     * 
     * @type {Array<MigrationDetails>}
     * @memberof MigrationsListing
     */
    items: Array<MigrationDetails>;
    /**
     * 
     * @type {number}
     * @memberof MigrationsListing
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ModSecStatus
 */
export interface ModSecStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ModSecStatus
     */
    enabled: boolean;
}
/**
 * See https://dev.mysql.com/doc/refman/8.0/en/authentication-plugins.html for list of plugins which MySQL supports. If not provided, the user will use whatever defaults the mysql server it\'s being created uses.
 * @export
 * @enum {string}
 */
export enum MySQLAuthPlugin {
    caching_sha2_password = 'caching_sha2_password',
    mysql_native_password = 'mysql_native_password'
}

/**
 * 
 * @export
 * @interface MySQLDB
 */
export interface MySQLDB {
    /**
     * 
     * @type {string}
     * @memberof MySQLDB
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof MySQLDB
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof MySQLDB
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface MySQLDBsFullListing
 */
export interface MySQLDBsFullListing {
    /**
     * 
     * @type {Array<MySQLDB>}
     * @memberof MySQLDBsFullListing
     */
    items: Array<MySQLDB>;
}
/**
 * 
 * @export
 * @interface MySQLDBsListing
 */
export interface MySQLDBsListing {
    /**
     * 
     * @type {Array<MySQLDB>}
     * @memberof MySQLDBsListing
     */
    items: Array<MySQLDB>;
}
/**
 * 
 * @export
 * @interface MySQLUser
 */
export interface MySQLUser {
    /**
     * 
     * @type {string}
     * @memberof MySQLUser
     */
    dbId?: string;
    /**
     * 
     * @type {string}
     * @memberof MySQLUser
     */
    username: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MySQLUser
     */
    accessHosts: Array<string>;
    /**
     * 
     * @type {MySQLAuthPlugin}
     * @memberof MySQLUser
     */
    authPlugin: MySQLAuthPlugin;
    /**
     * Table names mapped to a list of privileges on that table. The wildcard \"*\" means the privileges are granted for all tables.
     * @type {object}
     * @memberof MySQLUser
     */
    grants: object;
    /**
     * 
     * @type {string}
     * @memberof MySQLUser
     */
    createdAt: string;
    /**
     * A flag which marks short-lived mysql accounts. If an account is created as ephemeral, it will be deleted few hours after it\'s been created. Throwaway accounts are useful for phpMyAdmin logins.
     * @type {boolean}
     * @memberof MySQLUser
     */
    isEphemeral?: boolean;
}
/**
 * 
 * @export
 * @interface MySQLUserAccessHosts
 */
export interface MySQLUserAccessHosts {
    /**
     * 
     * @type {Array<string>}
     * @memberof MySQLUserAccessHosts
     */
    accessHosts: Array<string>;
}
/**
 * 
 * @export
 * @interface MySQLUserGrants
 */
export interface MySQLUserGrants {
    /**
     * 
     * @type {string}
     * @memberof MySQLUserGrants
     */
    dbName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MySQLUserGrants
     */
    grants: Array<string>;
}
/**
 * 
 * @export
 * @interface MySQLUserUpdate
 */
export interface MySQLUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof MySQLUserUpdate
     */
    password: string;
}
/**
 * 
 * @export
 * @interface MySQLUsersFullListing
 */
export interface MySQLUsersFullListing {
    /**
     * 
     * @type {Array<MySQLUser>}
     * @memberof MySQLUsersFullListing
     */
    items: Array<MySQLUser>;
}
/**
 * 
 * @export
 * @interface MysqlInfo
 */
export interface MysqlInfo {
    /**
     * 
     * @type {MysqlKind}
     * @memberof MysqlInfo
     */
    mysqlKind: MysqlKind;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MysqlKind {
    mysql80 = 'mysql80',
    mysql81 = 'mysql81',
    mariaDbLts = 'mariaDbLts',
    mariaDb11 = 'mariaDb11'
}

/**
 * 
 * @export
 * @interface NetworkInterface
 */
export interface NetworkInterface {
    /**
     * 
     * @type {string}
     * @memberof NetworkInterface
     */
    name: string;
    /**
     * 
     * @type {InterfaceIp}
     * @memberof NetworkInterface
     */
    ips: InterfaceIp;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NetworkStatus {
    unknown = 'unknown',
    online = 'online',
    restarting = 'restarting',
    warning = 'warning',
    critical = 'critical'
}

/**
 * 
 * @export
 * @interface NewAccessToken
 */
export interface NewAccessToken {
    /**
     * 
     * @type {Array<Role>}
     * @memberof NewAccessToken
     */
    roles: Array<Role>;
    /**
     * 
     * @type {string}
     * @memberof NewAccessToken
     */
    tokenExpires?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccessToken
     */
    friendlyName?: string;
}
/**
 * 
 * @export
 * @interface NewAccessTokenResponse
 */
export interface NewAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof NewAccessTokenResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccessTokenResponse
     */
    firstFive: string;
    /**
     * 
     * @type {string}
     * @memberof NewAccessTokenResponse
     */
    unencryptedToken: string;
}
/**
 * 
 * @export
 * @interface NewAutoresponder
 */
export interface NewAutoresponder {
    /**
     * 
     * @type {string}
     * @memberof NewAutoresponder
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof NewAutoresponder
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewAutoresponder
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewAutoresponder
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof NewAutoresponder
     */
    body: string;
}
/**
 * The path for backups to be stored.
 * @export
 * @interface NewBackupRole
 */
export interface NewBackupRole {
    /**
     * The full path where the backups will be stored.
     * @type {string}
     * @memberof NewBackupRole
     */
    path: string;
}
/**
 * 
 * @export
 * @interface NewCloudFlareToken
 */
export interface NewCloudFlareToken {
    /**
     * 
     * @type {any}
     * @memberof NewCloudFlareToken
     */
    token: any | null;
    /**
     * 
     * @type {any}
     * @memberof NewCloudFlareToken
     */
    friendlyName: any | null;
}
/**
 * 
 * @export
 * @interface NewCustomer
 */
export interface NewCustomer {
    /**
     * 
     * @type {string}
     * @memberof NewCustomer
     */
    name: string;
}
/**
 * 
 * @export
 * @interface NewDefaultDnsRecord
 */
export interface NewDefaultDnsRecord {
    /**
     * 
     * @type {DnsRecordKind}
     * @memberof NewDefaultDnsRecord
     */
    kind: DnsRecordKind;
    /**
     * 
     * @type {string}
     * @memberof NewDefaultDnsRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewDefaultDnsRecord
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof NewDefaultDnsRecord
     */
    ttl?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewDefaultDnsRecord
     */
    overrideConflicting?: boolean;
}
/**
 * 
 * @export
 * @interface NewDnsRecord
 */
export interface NewDnsRecord {
    /**
     * 
     * @type {DnsRecordKind}
     * @memberof NewDnsRecord
     */
    kind: DnsRecordKind;
    /**
     * 
     * @type {string}
     * @memberof NewDnsRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewDnsRecord
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof NewDnsRecord
     */
    ttl?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewDnsRecord
     */
    proxy?: boolean;
}
/**
 * 
 * @export
 * @interface NewDnsThirdPartyProvider
 */
export interface NewDnsThirdPartyProvider {
    /**
     * 
     * @type {string}
     * @memberof NewDnsThirdPartyProvider
     */
    url: string;
    /**
     * Map of string keys to string values.
     * @type {{ [key: string]: string; }}
     * @memberof NewDnsThirdPartyProvider
     */
    headers?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface NewDomain
 */
export interface NewDomain {
    /**
     * 
     * @type {string}
     * @memberof NewDomain
     */
    domain: string;
}
/**
 * 
 * @export
 * @interface NewEmail
 */
export interface NewEmail {
    /**
     * 
     * @type {string}
     * @memberof NewEmail
     */
    mailboxName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmail
     */
    mailboxPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof NewEmail
     */
    username: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewEmail
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewEmail
     */
    forwarders?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof NewEmail
     */
    quota?: number;
}
/**
 * 
 * @export
 * @interface NewFtpUser
 */
export interface NewFtpUser {
    /**
     * 
     * @type {string}
     * @memberof NewFtpUser
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof NewFtpUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof NewFtpUser
     */
    homeDir: string;
}
/**
 * 
 * @export
 * @interface NewImportServerSettings
 */
export interface NewImportServerSettings {
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    sshPrivateKey?: string;
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    sshPublicKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewImportServerSettings
     */
    allowPartialSync?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewImportServerSettings
     */
    asUserRoot?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    hostname: string;
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    sshUser: string;
    /**
     * 
     * @type {ImportKind}
     * @memberof NewImportServerSettings
     */
    importType: ImportKind;
    /**
     * 
     * @type {ServerMigrationSettingsAuthType}
     * @memberof NewImportServerSettings
     */
    authKind: ServerMigrationSettingsAuthType;
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    authUser: string;
    /**
     * 
     * @type {string}
     * @memberof NewImportServerSettings
     */
    authPass: string;
    /**
     * 
     * @type {number}
     * @memberof NewImportServerSettings
     */
    sshPort: number;
    /**
     * 
     * @type {number}
     * @memberof NewImportServerSettings
     */
    apiPort: number;
}
/**
 * 
 * @export
 * @interface NewInvite
 */
export interface NewInvite {
    /**
     * 
     * @type {string}
     * @memberof NewInvite
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NewInvite
     */
    name: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof NewInvite
     */
    roles: Array<Role>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewInvite
     */
    siteAccesses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewInvite
     */
    notifications?: Array<string>;
    /**
     * Whether to send an email to the invitee. Defaults to true if not provided.
     * @type {boolean}
     * @memberof NewInvite
     */
    sendEmail?: boolean;
}
/**
 * 
 * @export
 * @interface NewMappedDomain
 */
export interface NewMappedDomain {
    /**
     * 
     * @type {string}
     * @memberof NewMappedDomain
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof NewMappedDomain
     */
    documentRoot?: string;
    /**
     * 
     * @type {DomainMappingKind}
     * @memberof NewMappedDomain
     */
    kind?: DomainMappingKind;
}
/**
 * 
 * @export
 * @interface NewMember
 */
export interface NewMember {
    /**
     * 
     * @type {string}
     * @memberof NewMember
     */
    loginId: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof NewMember
     */
    roles: Array<Role>;
}
/**
 * 
 * @export
 * @interface NewMigrationDetails
 */
export interface NewMigrationDetails {
    /**
     * 
     * @type {string}
     * @memberof NewMigrationDetails
     */
    websiteId: string;
    /**
     * 
     * @type {string}
     * @memberof NewMigrationDetails
     */
    destServerId: string;
    /**
     * 
     * @type {ServerRole}
     * @memberof NewMigrationDetails
     */
    serverRole: ServerRole;
}
/**
 * 
 * @export
 * @interface NewMySQLDB
 */
export interface NewMySQLDB {
    /**
     * 
     * @type {string}
     * @memberof NewMySQLDB
     */
    name: string;
}
/**
 * 
 * @export
 * @interface NewMySQLUser
 */
export interface NewMySQLUser {
    /**
     * 
     * @type {string}
     * @memberof NewMySQLUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof NewMySQLUser
     */
    password: string;
    /**
     * 
     * @type {MySQLAuthPlugin}
     * @memberof NewMySQLUser
     */
    authPlugin?: MySQLAuthPlugin;
}
/**
 * 
 * @export
 * @interface NewPassword
 */
export interface NewPassword {
    /**
     * 
     * @type {string}
     * @memberof NewPassword
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface NewPlan
 */
export interface NewPlan {
    /**
     * 
     * @type {string}
     * @memberof NewPlan
     */
    name: string;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof NewPlan
     */
    resources: Array<Resource>;
    /**
     * 
     * @type {Array<Allowance>}
     * @memberof NewPlan
     */
    allowances: Array<Allowance>;
    /**
     * 
     * @type {Array<Selection>}
     * @memberof NewPlan
     */
    selections: Array<Selection>;
    /**
     * 
     * @type {string}
     * @memberof NewPlan
     * @deprecated
     */
    serverGroupId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewPlan
     */
    serverGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof NewPlan
     */
    allowServerGroupSelection?: boolean;
    /**
     * 
     * @type {PlanType}
     * @memberof NewPlan
     */
    planType?: PlanType;
    /**
     * 
     * @type {CgroupLimits}
     * @memberof NewPlan
     */
    cgroupLimits?: CgroupLimits;
    /**
     * 
     * @type {FsQuotaLimit}
     * @memberof NewPlan
     */
    fsQuotaLimit?: FsQuotaLimit;
    /**
     * 
     * @type {Array<PhpVersion>}
     * @memberof NewPlan
     */
    allowedPhpVersions?: Array<PhpVersion>;
    /**
     * 
     * @type {PhpVersion}
     * @memberof NewPlan
     */
    defaultPhpVersion?: PhpVersion;
    /**
     * 
     * @type {boolean}
     * @memberof NewPlan
     */
    redisAllowed?: boolean;
    /**
     * If set, servers from this server group are prioritized by placement algorithm. If no server from the default server group is available, servers from other server groups are tried. The defaultServerGroupId will be automatically added to serverGroupIds if they do not contain it or are not provided. 
     * @type {string}
     * @memberof NewPlan
     */
    defaultServerGroupId?: string;
    /**
     * :> When WordPress is installed on a website under this plan, the chosen theme will be preinstalled.
     * @type {string}
     * @memberof NewPlan
     */
    preinstallWordpressTheme?: string;
}
/**
 * 
 * @export
 * @interface NewPrimaryDomainMapping
 */
export interface NewPrimaryDomainMapping {
    /**
     * 
     * @type {string}
     * @memberof NewPrimaryDomainMapping
     */
    domainId: string;
    /**
     * 
     * @type {CanonicalRedirect}
     * @memberof NewPrimaryDomainMapping
     */
    canonicalRedirect?: CanonicalRedirect;
}
/**
 * 
 * @export
 * @interface NewResourceId
 */
export interface NewResourceId {
    /**
     * 
     * @type {number}
     * @memberof NewResourceId
     */
    id: number;
}
/**
 * 
 * @export
 * @interface NewResourceUuid
 */
export interface NewResourceUuid {
    /**
     * 
     * @type {string}
     * @memberof NewResourceUuid
     */
    id: string;
}
/**
 * 
 * @export
 * @interface NewServerGroup
 */
export interface NewServerGroup {
    /**
     * 
     * @type {string}
     * @memberof NewServerGroup
     */
    name: string;
}
/**
 * 
 * @export
 * @interface NewServerIp
 */
export interface NewServerIp {
    /**
     * 
     * @type {string}
     * @memberof NewServerIp
     */
    ip: string;
    /**
     * 
     * @type {string}
     * @memberof NewServerIp
     */
    prefix: string;
}
/**
 * 
 * @export
 * @interface NewSshKey
 */
export interface NewSshKey {
    /**
     * The SSH key in PEM format.
     * @type {string}
     * @memberof NewSshKey
     */
    value: string;
    /**
     * The friendly name associated with the key.
     * @type {string}
     * @memberof NewSshKey
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface NewSshKeyId
 */
export interface NewSshKeyId {
    /**
     * The unique ID of the SSH key within the same authorized_keys file.
     * @type {string}
     * @memberof NewSshKeyId
     */
    id: string;
}
/**
 * 
 * @export
 * @interface NewSslCert
 */
export interface NewSslCert {
    /**
     * 
     * @type {string}
     * @memberof NewSslCert
     */
    cert: string;
    /**
     * 
     * @type {string}
     * @memberof NewSslCert
     */
    key: string;
}
/**
 * 
 * @export
 * @interface NewSubscription
 */
export interface NewSubscription {
    /**
     * 
     * @type {number}
     * @memberof NewSubscription
     */
    planId: number;
    /**
     * 
     * @type {SubscriptionDedicatedServers}
     * @memberof NewSubscription
     */
    dedicatedServers?: SubscriptionDedicatedServers;
    /**
     * 
     * @type {string}
     * @memberof NewSubscription
     */
    friendlyName?: string;
}
/**
 * 
 * @export
 * @interface NewTag
 */
export interface NewTag {
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof NewTag
     */
    colorCode?: string;
}
/**
 * 
 * @export
 * @interface NewUiPreferences
 */
export interface NewUiPreferences {
    /**
     * 
     * @type {UiPreferencesViewKind}
     * @memberof NewUiPreferences
     */
    fileManagerView: UiPreferencesViewKind;
    /**
     * 
     * @type {UiPreferencesViewKind}
     * @memberof NewUiPreferences
     */
    websiteView: UiPreferencesViewKind;
    /**
     * 
     * @type {boolean}
     * @memberof NewUiPreferences
     */
    spineOpen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewUiPreferences
     */
    fileManagerEditorFullscreen: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewUiPreferences
     */
    serverGroups: Array<string>;
}
/**
 * 
 * @export
 * @interface NewWebsite
 */
export interface NewWebsite {
    /**
     * The domain of the new website.
     * @type {string}
     * @memberof NewWebsite
     */
    domain: string;
    /**
     * 
     * @type {number}
     * @memberof NewWebsite
     */
    subscriptionId?: number;
    /**
     * 
     * @type {string}
     * @memberof NewWebsite
     */
    appServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWebsite
     */
    backupServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWebsite
     */
    dbServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWebsite
     */
    emailServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWebsite
     */
    serverGroupId?: string;
    /**
     * 
     * @type {PhpVersion}
     * @memberof NewWebsite
     */
    phpVersion?: PhpVersion;
}
/**
 * 
 * @export
 * @interface NewWebsiteApp
 */
export interface NewWebsiteApp {
    /**
     * 
     * @type {WebsiteAppKind}
     * @memberof NewWebsiteApp
     */
    app: WebsiteAppKind;
    /**
     * 
     * @type {string}
     * @memberof NewWebsiteApp
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWebsiteApp
     */
    path?: string;
    /**
     * This username is going to be the username of the initial WP user with which the user can login to the WP admin. This is equivalent to going to `wp-admin/install.php` and performing the install from there.
     * @type {string}
     * @memberof NewWebsiteApp
     */
    adminUsername: string;
    /**
     * Complements the admin username. Provide unhashed password.
     * @type {string}
     * @memberof NewWebsiteApp
     */
    adminPassword: string;
    /**
     * Sets the admin email address, required by some applications.
     * @type {string}
     * @memberof NewWebsiteApp
     */
    adminEmail: string;
    /**
     * Install on a specific domain within this website.  Will default to use the primary domain.
     * @type {string}
     * @memberof NewWebsiteApp
     */
    domainId?: string;
}
/**
 * 
 * @export
 * @interface NewWpUser
 */
export interface NewWpUser {
    /**
     * 
     * @type {string}
     * @memberof NewWpUser
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof NewWpUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewWpUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof NewWpUser
     */
    email: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationStatus {
    started = 'started',
    successful = 'successful',
    failed = 'failed'
}

/**
 * Settings for orchd logging
 * @export
 * @interface OrchdLogSettings
 */
export interface OrchdLogSettings {
    /**
     * 
     * @type {LogLevelLimit}
     * @memberof OrchdLogSettings
     */
    limit: LogLevelLimit;
}
/**
 * Email white or black list for orchd login policy
 * @export
 * @interface OrchdLoginPolicyEmailList
 */
export interface OrchdLoginPolicyEmailList {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrchdLoginPolicyEmailList
     */
    emailList?: Array<string>;
}
/**
 * Ip white or black list for orchd login policy
 * @export
 * @interface OrchdLoginPolicyIpList
 */
export interface OrchdLoginPolicyIpList {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrchdLoginPolicyIpList
     */
    ipList?: Array<string>;
}
/**
 * Settings for orchd login policy
 * @export
 * @interface OrchdLoginPolicySettings
 */
export interface OrchdLoginPolicySettings {
    /**
     * 
     * @type {boolean}
     * @memberof OrchdLoginPolicySettings
     */
    enabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrchdLoginPolicySettings
     */
    emailQuota?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrchdLoginPolicySettings
     */
    emailAutoBlockEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrchdLoginPolicySettings
     */
    emailAutoBlockThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof OrchdLoginPolicySettings
     */
    emailAutoBlockDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof OrchdLoginPolicySettings
     */
    ipQuota?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrchdLoginPolicySettings
     */
    ipAutoBlockEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrchdLoginPolicySettings
     */
    ipAutoBlockThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof OrchdLoginPolicySettings
     */
    ipAutoBlockDuration?: number;
}
/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    name: string;
    /**
     * 
     * @type {Status}
     * @memberof Org
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    suspendedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    ownerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    ownerLoginId?: string;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    subscriptionsCount: number;
    /**
     * 
     * @type {number}
     * @memberof Org
     */
    websitesCount: number;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    ownerAvatarPath?: string;
    /**
     * 
     * @type {CPLocale}
     * @memberof Org
     */
    locale: CPLocale;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    slackNotificationWebhookUrl?: string;
}
/**
 * 
 * @export
 * @interface OrgAccessToken
 */
export interface OrgAccessToken {
    /**
     * 
     * @type {string}
     * @memberof OrgAccessToken
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrgAccessToken
     */
    firstFive: string;
    /**
     * 
     * @type {Array<Role>}
     * @memberof OrgAccessToken
     */
    roles: Array<Role>;
    /**
     * 
     * @type {string}
     * @memberof OrgAccessToken
     */
    tokenExpires?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgAccessToken
     */
    friendlyName?: string;
}
/**
 * 
 * @export
 * @interface OrgOwnerUpdate
 */
export interface OrgOwnerUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrgOwnerUpdate
     */
    memberId: string;
}
/**
 * 
 * @export
 * @interface OrgUpdate
 */
export interface OrgUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrgUpdate
     */
    name?: string;
    /**
     * 
     * @type {Status}
     * @memberof OrgUpdate
     */
    status?: Status;
    /**
     * 
     * @type {boolean}
     * @memberof OrgUpdate
     */
    isSuspended?: boolean;
    /**
     * 
     * @type {CPLocale}
     * @memberof OrgUpdate
     */
    locale?: CPLocale;
    /**
     * 
     * @type {string | Unset}
     * @memberof OrgUpdate
     */
    slackNotificationWebhookUrl?: string | Unset;
}
/**
 * 
 * @export
 * @interface OutboundSpamScanningSettings
 */
export interface OutboundSpamScanningSettings {
    /**
     * 
     * @type {boolean}
     * @memberof OutboundSpamScanningSettings
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Outcome {
    success = 'success',
    restartRequired = 'restartRequired',
    updateNotApplied = 'updateNotApplied'
}

/**
 * 
 * @export
 * @interface OwaspVersion
 */
export interface OwaspVersion {
    /**
     * 
     * @type {string}
     * @memberof OwaspVersion
     */
    current: string;
    /**
     * 
     * @type {string}
     * @memberof OwaspVersion
     */
    available: string;
}
/**
 * 
 * @export
 * @interface PhpIni
 */
export interface PhpIni {
    /**
     * 
     * @type {Array<IniSetting>}
     * @memberof PhpIni
     */
    items: Array<IniSetting>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PhpVersion {
    php56 = 'php56',
    php70 = 'php70',
    php71 = 'php71',
    php72 = 'php72',
    php73 = 'php73',
    php74 = 'php74',
    php80 = 'php80',
    php81 = 'php81',
    php82 = 'php82',
    php83 = 'php83',
    php84 = 'php84'
}

/**
 * A plan (or sometimes referred to as a package) defines a set of resources a reseller can offer for customer organizations to subscribe to.
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    orgId: string;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof Plan
     */
    resources: Array<Resource>;
    /**
     * 
     * @type {Array<Allowance>}
     * @memberof Plan
     */
    allowances: Array<Allowance>;
    /**
     * 
     * @type {Array<Selection>}
     * @memberof Plan
     */
    selections: Array<Selection>;
    /**
     * The number of subscriptions to this plan.
     * @type {number}
     * @memberof Plan
     */
    subscriptionsCount: number;
    /**
     * Use serverGroupIds instead
     * @type {string}
     * @memberof Plan
     * @deprecated
     */
    serverGroupId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Plan
     */
    serverGroupIds?: Array<string>;
    /**
     * Whether the customer can select the server group for their websites. 
     * @type {boolean}
     * @memberof Plan
     */
    allowServerGroupSelection?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    createdAt: string;
    /**
     * 
     * @type {PlanType}
     * @memberof Plan
     */
    planType: PlanType;
    /**
     * 
     * @type {CgroupLimits}
     * @memberof Plan
     */
    cgroupLimits?: CgroupLimits;
    /**
     * 
     * @type {FsQuotaLimit}
     * @memberof Plan
     */
    fsQuotaLimit?: FsQuotaLimit;
    /**
     * 
     * @type {Array<PhpVersion>}
     * @memberof Plan
     */
    allowedPhpVersions: Array<PhpVersion>;
    /**
     * 
     * @type {PhpVersion}
     * @memberof Plan
     */
    defaultPhpVersion: PhpVersion;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    redisAllowed: boolean;
    /**
     * :> When WordPress is installed on a website under this plan, the chosen theme will be preinstalled.
     * @type {string}
     * @memberof Plan
     */
    preinstallWordpressTheme?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PlanType {
    shared = 'shared',
    dedicated = 'dedicated'
}

/**
 * 
 * @export
 * @interface PlansListing
 */
export interface PlansListing {
    /**
     * 
     * @type {Array<Plan>}
     * @memberof PlansListing
     */
    items: Array<Plan>;
    /**
     * 
     * @type {number}
     * @memberof PlansListing
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ProcessInfo
 */
export interface ProcessInfo {
    /**
     * 
     * @type {number}
     * @memberof ProcessInfo
     */
    pid: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessInfo
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessInfo
     */
    cpu_usage: number;
    /**
     * 
     * @type {number}
     * @memberof ProcessInfo
     */
    resMemory: number;
}
/**
 * 
 * @export
 * @interface Quota
 */
export interface Quota {
    /**
     * 
     * @type {number}
     * @memberof Quota
     */
    usage: number;
    /**
     * 
     * @type {number}
     * @memberof Quota
     */
    total: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Recursion {
    directCustomers = 'directCustomers',
    infinite = 'infinite'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RemoteStoragePurpose {
    backup = 'backup'
}

/**
 * 
 * @export
 * @interface RequireIpsRule
 */
export interface RequireIpsRule {
    /**
     * 
     * @type {RequireIpsRuleKind}
     * @memberof RequireIpsRule
     */
    kind: RequireIpsRuleKind;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequireIpsRule
     */
    ips: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RequireIpsRuleKind {
    allow = 'allow',
    block = 'block'
}

/**
 * 
 * @export
 * @interface ResendPin
 */
export interface ResendPin {
    /**
     * 
     * @type {string}
     * @memberof ResendPin
     */
    authMethod: ResendPinAuthMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ResendPinAuthMethodEnum {
    basic = 'basic',
    email = 'email',
    otp = 'otp'
}

/**
 * Defines a resource that the MO or resellers can sell.
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {ResourceName}
     * @memberof Resource
     */
    name: ResourceName;
    /**
     * The total quota of the resource. If not set, the resource has unlimited quota. A reseller may only sell unlimited quota if the subscription to which itself is subscribed has the same resource with unlimited quota.
     * @type {number}
     * @memberof Resource
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ResourceCheckConflict
 */
export interface ResourceCheckConflict {
    /**
     * 
     * @type {ResourceCheckConflictKind}
     * @memberof ResourceCheckConflict
     */
    kind: ResourceCheckConflictKind;
    /**
     * 
     * @type {string}
     * @memberof ResourceCheckConflict
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceCheckConflict
     */
    name: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceCheckConflictKind {
    mysqlUser = 'mysqlUser',
    mysqlDb = 'mysqlDb',
    ftpUser = 'ftpUser',
    mailboxForwarder = 'mailboxForwarder',
    mailbox = 'mailbox',
    domain = 'domain'
}

/**
 * 
 * @export
 * @interface ResourceCheckError
 */
export interface ResourceCheckError {
    /**
     * 
     * @type {Array<ResourceCheckConflict>}
     * @memberof ResourceCheckError
     */
    conflicts?: Array<ResourceCheckConflict>;
    /**
     * 
     * @type {Array<ResourceCheckManagerError>}
     * @memberof ResourceCheckError
     */
    resources?: Array<ResourceCheckManagerError>;
}
/**
 * 
 * @export
 * @interface ResourceCheckManagerError
 */
export interface ResourceCheckManagerError {
    /**
     * 
     * @type {ResourceCheckManagerErrorKind}
     * @memberof ResourceCheckManagerError
     */
    kind: ResourceCheckManagerErrorKind;
    /**
     * 
     * @type {ResourceName}
     * @memberof ResourceCheckManagerError
     */
    resourceName: ResourceName;
    /**
     * 
     * @type {number}
     * @memberof ResourceCheckManagerError
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceCheckManagerError
     */
    usage: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceCheckManagerError
     */
    requested: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ResourceCheckManagerErrorKind {
    nonExistentResource = 'nonExistentResource',
    resourceOutOfQuota = 'resourceOutOfQuota'
}

/**
 * 
 * @export
 * @interface ResourceCountByInterval
 */
export interface ResourceCountByInterval {
    /**
     * 
     * @type {string}
     * @memberof ResourceCountByInterval
     */
    interval: string;
    /**
     * 
     * @type {number}
     * @memberof ResourceCountByInterval
     */
    resourceCount: number;
}
/**
 * The name of the resource. Must be unique within a plan. Note: for arbitrary string casting please use `camelCase` format
 * @export
 * @enum {string}
 */
export enum ResourceName {
    customers = 'customers',
    diskspace = 'diskspace',
    domainAliases = 'domainAliases',
    forwarders = 'forwarders',
    ftpUsers = 'ftpUsers',
    mailboxes = 'mailboxes',
    mysqlDbs = 'mysqlDbs',
    pageViews = 'pageViews',
    processes = 'processes',
    stagingWebsites = 'stagingWebsites',
    transfer = 'transfer',
    websites = 'websites',
    addonDomains = 'addonDomains',
    subdomains = 'subdomains'
}

/**
 * 
 * @export
 * @interface RestoreDetailed
 */
export interface RestoreDetailed {
    /**
     * 
     * @type {number}
     * @memberof RestoreDetailed
     */
    backupId: number;
    /**
     * 
     * @type {string}
     * @memberof RestoreDetailed
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof RestoreDetailed
     */
    finishedAt?: string;
    /**
     * 
     * @type {OperationStatus}
     * @memberof RestoreDetailed
     */
    homeDirStatus?: OperationStatus;
    /**
     * 
     * @type {OperationStatus}
     * @memberof RestoreDetailed
     */
    mysqlDbsStatus?: OperationStatus;
    /**
     * 
     * @type {OperationStatus}
     * @memberof RestoreDetailed
     */
    emailsStatus?: OperationStatus;
}
/**
 * 
 * @export
 * @interface RewriteChain
 */
export interface RewriteChain {
    /**
     * 
     * @type {number}
     * @memberof RewriteChain
     */
    lineNumber: number;
    /**
     * 
     * @type {RewriteChainRule}
     * @memberof RewriteChain
     */
    rule: RewriteChainRule;
    /**
     * 
     * @type {Array<RewriteChainConds>}
     * @memberof RewriteChain
     */
    conds: Array<RewriteChainConds>;
}
/**
 * 
 * @export
 * @interface RewriteChainConds
 */
export interface RewriteChainConds {
    /**
     * 
     * @type {string}
     * @memberof RewriteChainConds
     */
    testString: string;
    /**
     * 
     * @type {string}
     * @memberof RewriteChainConds
     */
    condPattern: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RewriteChainConds
     */
    flags: Array<string>;
}
/**
 * 
 * @export
 * @interface RewriteChainFullListing
 */
export interface RewriteChainFullListing {
    /**
     * 
     * @type {Array<RewriteChain>}
     * @memberof RewriteChainFullListing
     */
    items: Array<RewriteChain>;
}
/**
 * 
 * @export
 * @interface RewriteChainRule
 */
export interface RewriteChainRule {
    /**
     * 
     * @type {string}
     * @memberof RewriteChainRule
     */
    pattern: string;
    /**
     * 
     * @type {string}
     * @memberof RewriteChainRule
     */
    substitution: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RewriteChainRule
     */
    flags: Array<string>;
}
/**
 * The role of the member in the organization.
 * @export
 * @enum {string}
 */
export enum Role {
    Owner = 'Owner',
    SuperAdmin = 'SuperAdmin',
    Business = 'Business',
    SiteAccess = 'SiteAccess',
    Support = 'Support',
    Sysadmin = 'Sysadmin'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleInstallationState {
    enabled = 'enabled',
    disabled = 'disabled',
    notInstalled = 'notInstalled'
}

/**
 * 
 * @export
 * @interface RoleInstalledStatusSummary
 */
export interface RoleInstalledStatusSummary {
    /**
     * 
     * @type {RoleInstallationState}
     * @memberof RoleInstalledStatusSummary
     */
    application?: RoleInstallationState;
    /**
     * 
     * @type {RoleInstallationState}
     * @memberof RoleInstalledStatusSummary
     */
    backup?: RoleInstallationState;
    /**
     * 
     * @type {RoleInstallationState}
     * @memberof RoleInstalledStatusSummary
     */
    database?: RoleInstallationState;
    /**
     * 
     * @type {RoleInstallationState}
     * @memberof RoleInstalledStatusSummary
     */
    dns?: RoleInstallationState;
    /**
     * 
     * @type {RoleInstallationState}
     * @memberof RoleInstalledStatusSummary
     */
    email?: RoleInstallationState;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleState {
    enabled = 'enabled',
    disabled = 'disabled'
}

/**
 * 
 * @export
 * @interface RoleSummary
 */
export interface RoleSummary {
    /**
     * 
     * @type {RoleState}
     * @memberof RoleSummary
     */
    state: RoleState;
    /**
     * 
     * @type {NetworkStatus}
     * @memberof RoleSummary
     */
    status?: NetworkStatus;
}
/**
 * 
 * @export
 * @interface RolesInfo
 */
export interface RolesInfo {
    /**
     * 
     * @type {EmailRoleInfo}
     * @memberof RolesInfo
     */
    email?: EmailRoleInfo;
    /**
     * 
     * @type {BackupRoleInfo}
     * @memberof RolesInfo
     */
    backup?: BackupRoleInfo;
    /**
     * 
     * @type {DatabaseRoleInfo}
     * @memberof RolesInfo
     */
    database?: DatabaseRoleInfo;
    /**
     * 
     * @type {ApplicationRoleInfo}
     * @memberof RolesInfo
     */
    application?: ApplicationRoleInfo;
    /**
     * 
     * @type {DnsRoleInfo}
     * @memberof RolesInfo
     */
    dns?: DnsRoleInfo;
    /**
     * 
     * @type {WebserverKind}
     * @memberof RolesInfo
     */
    webserverKind?: WebserverKind;
}
/**
 * 
 * @export
 * @interface RolesSummary
 */
export interface RolesSummary {
    /**
     * 
     * @type {RoleSummary}
     * @memberof RolesSummary
     */
    email?: RoleSummary;
    /**
     * 
     * @type {RoleSummary}
     * @memberof RolesSummary
     */
    backup?: RoleSummary;
    /**
     * 
     * @type {RoleSummary}
     * @memberof RolesSummary
     */
    database?: RoleSummary;
    /**
     * 
     * @type {RoleSummary}
     * @memberof RolesSummary
     */
    application?: RoleSummary;
    /**
     * 
     * @type {RoleSummary}
     * @memberof RolesSummary
     */
    dns?: RoleSummary;
    /**
     * 
     * @type {RoleSummary}
     * @memberof RolesSummary
     */
    controlPanel?: RoleSummary;
}
/**
 * 
 * @export
 * @interface ScreenshotConfig
 */
export interface ScreenshotConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ScreenshotConfig
     */
    isEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScreenshotConfig
     */
    interval?: number;
}
/**
 * 
 * @export
 * @interface ScreenshotConfigUpdate
 */
export interface ScreenshotConfigUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof ScreenshotConfigUpdate
     */
    enable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScreenshotConfigUpdate
     */
    interval?: number;
}
/**
 * 
 * @export
 * @interface Selection
 */
export interface Selection {
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    value: string;
}
/**
 * 
 * @export
 * @interface ServerConf
 */
export interface ServerConf {
    /**
     * 
     * @type {string}
     * @memberof ServerConf
     */
    friendlyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerConf
     */
    group?: string;
}
/**
 * 
 * @export
 * @interface ServerDiskUsage
 */
export interface ServerDiskUsage {
    /**
     * 
     * @type {Array<Disk>}
     * @memberof ServerDiskUsage
     */
    disks?: Array<Disk>;
}
/**
 * 
 * @export
 * @interface ServerGroup
 */
export interface ServerGroup {
    /**
     * 
     * @type {string}
     * @memberof ServerGroup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServerGroup
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ServerGroup
     */
    serverCount: number;
    /**
     * 
     * @type {string}
     * @memberof ServerGroup
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface ServerGroupUpdate
 */
export interface ServerGroupUpdate {
    /**
     * 
     * @type {string}
     * @memberof ServerGroupUpdate
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ServerGroups
 */
export interface ServerGroups {
    /**
     * 
     * @type {Array<ServerGroup>}
     * @memberof ServerGroups
     */
    items?: Array<ServerGroup>;
}
/**
 * 
 * @export
 * @interface ServerHostnameWebsite
 */
export interface ServerHostnameWebsite {
    /**
     * 
     * @type {string}
     * @memberof ServerHostnameWebsite
     */
    websiteId: string;
    /**
     * 
     * @type {Array<DomainWithUuid>}
     * @memberof ServerHostnameWebsite
     */
    domains: Array<DomainWithUuid>;
}
/**
 * 
 * @export
 * @interface ServerInfo
 */
export interface ServerInfo {
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    groupId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfo
     */
    isControlPanel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfo
     */
    isConfigured: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    hostname: string;
    /**
     * 
     * @type {Array<ServerIp>}
     * @memberof ServerInfo
     */
    ips: Array<ServerIp>;
    /**
     * 
     * @type {NetworkStatus}
     * @memberof ServerInfo
     */
    status?: NetworkStatus;
    /**
     * 
     * @type {RolesSummary}
     * @memberof ServerInfo
     */
    roles: RolesSummary;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    controldVersion?: string;
    /**
     * 
     * @type {DedicatedSubscriptionInfo}
     * @memberof ServerInfo
     */
    dedicatedSubscription?: DedicatedSubscriptionInfo;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfo
     */
    isDecommissioned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerInfo
     */
    ipv6Addr?: string;
    /**
     * 
     * @type {Array<Disk>}
     * @memberof ServerInfo
     */
    disks: Array<Disk>;
}
/**
 * 
 * @export
 * @interface ServerInfoBrief
 */
export interface ServerInfoBrief {
    /**
     * 
     * @type {string}
     * @memberof ServerInfoBrief
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoBrief
     */
    groupId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfoBrief
     */
    isControlPanel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfoBrief
     */
    isConfigured: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoBrief
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoBrief
     */
    hostname: string;
    /**
     * 
     * @type {Array<ServerIp>}
     * @memberof ServerInfoBrief
     */
    ips: Array<ServerIp>;
    /**
     * 
     * @type {RoleInstalledStatusSummary}
     * @memberof ServerInfoBrief
     */
    roles: RoleInstalledStatusSummary;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoBrief
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof ServerInfoBrief
     */
    dedicatedSubscription?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ServerInfoBrief
     */
    isDecommissioned: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoBrief
     */
    ipv6Addr?: string;
}
/**
 * 
 * @export
 * @interface ServerIowait
 */
export interface ServerIowait {
    /**
     * 
     * @type {number}
     * @memberof ServerIowait
     */
    ioWait?: number;
}
/**
 * 
 * @export
 * @interface ServerIp
 */
export interface ServerIp {
    /**
     * 
     * @type {string}
     * @memberof ServerIp
     */
    ip: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServerIp
     */
    isPrimary: boolean;
}
/**
 * 
 * @export
 * @interface ServerLoad
 */
export interface ServerLoad {
    /**
     * 
     * @type {number}
     * @memberof ServerLoad
     */
    load: number;
}
/**
 * 
 * @export
 * @interface ServerMemoryUsage
 */
export interface ServerMemoryUsage {
    /**
     * 
     * @type {SpaceUsage}
     * @memberof ServerMemoryUsage
     */
    ram: SpaceUsage;
    /**
     * 
     * @type {SpaceUsage}
     * @memberof ServerMemoryUsage
     */
    swap: SpaceUsage;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ServerMigrationSettingsAuthType {
    whmToken = 'whmToken',
    cpanelToken = 'cpanelToken',
    basic = 'basic'
}

/**
 * 
 * @export
 * @interface ServerNetworkInterfaces
 */
export interface ServerNetworkInterfaces {
    /**
     * 
     * @type {Array<NetworkInterface>}
     * @memberof ServerNetworkInterfaces
     */
    interfaces?: Array<NetworkInterface>;
}
/**
 * 
 * @export
 * @interface ServerNetworkStats
 */
export interface ServerNetworkStats {
    /**
     * Latency to the server in milliseconds.
     * @type {number}
     * @memberof ServerNetworkStats
     */
    latency: number;
    /**
     * Packet loss percentage
     * @type {number}
     * @memberof ServerNetworkStats
     */
    packetLoss: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ServerRole {
    email = 'email',
    backup = 'backup',
    database = 'database',
    application = 'application',
    dns = 'dns'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServerRoleState {
    enabled = 'enabled',
    disabled = 'disabled'
}

/**
 * 
 * @export
 * @interface ServerSniMapping
 */
export interface ServerSniMapping {
    /**
     * 
     * @type {string}
     * @memberof ServerSniMapping
     */
    serverId: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSniMapping
     */
    certId: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSniMapping
     */
    hostname: string;
}
/**
 * 
 * @export
 * @interface ServerSniMappingBody
 */
export interface ServerSniMappingBody {
    /**
     * 
     * @type {string}
     * @memberof ServerSniMappingBody
     */
    certId: string;
    /**
     * 
     * @type {string}
     * @memberof ServerSniMappingBody
     */
    hostname: string;
}
/**
 * Each entry represts server stats recorded periodically
 * @export
 * @interface ServerStatEntry
 */
export interface ServerStatEntry {
    /**
     * 
     * @type {string}
     * @memberof ServerStatEntry
     */
    serverId: string;
    /**
     * 
     * @type {number}
     * @memberof ServerStatEntry
     */
    ioWait: number;
    /**
     * 
     * @type {number}
     * @memberof ServerStatEntry
     */
    ramUsage: number;
    /**
     * 
     * @type {number}
     * @memberof ServerStatEntry
     */
    swapUsage: number;
    /**
     * 
     * @type {number}
     * @memberof ServerStatEntry
     */
    systemLoad: number;
    /**
     * 
     * @type {number}
     * @memberof ServerStatEntry
     */
    uptime: number;
    /**
     * Time when the entry was recorded
     * @type {string}
     * @memberof ServerStatEntry
     */
    recordedAt: string;
}
/**
 * 
 * @export
 * @interface ServerStatsFullListing
 */
export interface ServerStatsFullListing {
    /**
     * 
     * @type {Array<ServerStatEntry>}
     * @memberof ServerStatsFullListing
     */
    items: Array<ServerStatEntry>;
}
/**
 * 
 * @export
 * @interface ServerStatus
 */
export interface ServerStatus {
    /**
     * 
     * @type {NetworkStatus}
     * @memberof ServerStatus
     */
    status: NetworkStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ServerStatusAction {
    gracefulReboot = 'gracefulReboot',
    forceReboot = 'forceReboot'
}

/**
 * 
 * @export
 * @interface ServerStatusUpdate
 */
export interface ServerStatusUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof ServerStatusUpdate
     */
    isDecommissioned?: boolean;
}
/**
 * 
 * @export
 * @interface ServerUptime
 */
export interface ServerUptime {
    /**
     * 
     * @type {number}
     * @memberof ServerUptime
     */
    uptime: number;
}
/**
 * 
 * @export
 * @interface ServersListing
 */
export interface ServersListing {
    /**
     * 
     * @type {Array<ServerInfoBrief>}
     * @memberof ServersListing
     */
    items: Array<ServerInfoBrief>;
    /**
     * 
     * @type {number}
     * @memberof ServersListing
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ServiceInfo
 */
export interface ServiceInfo {
    /**
     * 
     * @type {string}
     * @memberof ServiceInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInfo
     */
    version?: string;
    /**
     * 
     * @type {NetworkStatus}
     * @memberof ServiceInfo
     */
    status: NetworkStatus;
    /**
     * 
     * @type {number}
     * @memberof ServiceInfo
     */
    port: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceInfo
     */
    usage: number;
    /**
     * 
     * @type {Array<ProcessInfo>}
     * @memberof ServiceInfo
     */
    processes?: Array<ProcessInfo>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceKind {
    controld = 'controld',
    orchd = 'orchd',
    authd = 'authd',
    logd = 'logd',
    sged = 'sged',
    bkupd = 'bkupd',
    mysqlcd = 'mysqlcd',
    dnscd = 'dnscd',
    filerd = 'filerd',
    ftpcd = 'ftpcd'
}

/**
 * 
 * @export
 * @interface ServiceLog
 */
export interface ServiceLog {
    /**
     * 
     * @type {string}
     * @memberof ServiceLog
     */
    createdAt: string;
    /**
     * 
     * @type {DaemonKind}
     * @memberof ServiceLog
     */
    serviceKind: DaemonKind;
    /**
     * 
     * @type {ServiceLogStatus}
     * @memberof ServiceLog
     */
    status: ServiceLogStatus;
    /**
     * 
     * @type {LogTimeMetadata | LogGreetingsMessageMetadata}
     * @memberof ServiceLog
     */
    metadata?: LogTimeMetadata | LogGreetingsMessageMetadata;
}
/**
 * The status of the website at the time the log was written. Some statuses have additional metadata (ok, slow, unrecognizedGreetingsMessage)     whereas some don\'t (timeout).
 * @export
 * @enum {string}
 */
export enum ServiceLogStatus {
    ok = 'ok',
    slow = 'slow',
    timeout = 'timeout',
    unrecognizedGreetingsMessage = 'unrecognizedGreetingsMessage'
}

/**
 * 
 * @export
 * @interface ServiceLogsFullListing
 */
export interface ServiceLogsFullListing {
    /**
     * 
     * @type {Array<ServiceLog>}
     * @memberof ServiceLogsFullListing
     */
    items: Array<ServiceLog>;
}
/**
 * @type ServiceSettingValue
 * @export
 */
export type ServiceSettingValue = SmartHostSettings | boolean | number | object | string;

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceStatusAction {
    gracefulRestart = 'gracefulRestart',
    forceRestart = 'forceRestart'
}

/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    ipAddr?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    location?: string;
}
/**
 * 
 * @export
 * @interface SessionResult
 */
export interface SessionResult {
    /**
     * 
     * @type {string}
     * @memberof SessionResult
     */
    authMethod: SessionResultAuthMethodEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SessionResultAuthMethodEnum {
    email = 'email',
    otp = 'otp'
}

/**
 * 
 * @export
 * @interface SessionsFullListing
 */
export interface SessionsFullListing {
    /**
     * 
     * @type {Array<Session>}
     * @memberof SessionsFullListing
     */
    items: Array<Session>;
}
/**
 * 
 * @export
 * @interface SetCgroupLimits
 */
export interface SetCgroupLimits {
    /**
     * 
     * @type {number}
     * @memberof SetCgroupLimits
     */
    nproc?: number;
    /**
     * 
     * @type {number}
     * @memberof SetCgroupLimits
     */
    memoryLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof SetCgroupLimits
     */
    iops?: number;
    /**
     * 
     * @type {number}
     * @memberof SetCgroupLimits
     */
    ioBandwidth?: number;
    /**
     * 
     * @type {number}
     * @memberof SetCgroupLimits
     */
    virtualCpus?: number;
}
/**
 * 
 * @export
 * @interface SetServerStatus
 */
export interface SetServerStatus {
    /**
     * 
     * @type {ServerStatusAction}
     * @memberof SetServerStatus
     */
    action: ServerStatusAction;
}
/**
 * 
 * @export
 * @interface SetServiceStatus
 */
export interface SetServiceStatus {
    /**
     * 
     * @type {ServiceStatusAction}
     * @memberof SetServiceStatus
     */
    action: ServiceStatusAction;
}
/**
 * 
 * @export
 * @interface SetWebserverKind
 */
export interface SetWebserverKind {
    /**
     * 
     * @type {WebserverKind}
     * @memberof SetWebserverKind
     */
    webserverKind: WebserverKind;
    /**
     * 
     * @type {string}
     * @memberof SetWebserverKind
     */
    serial?: string;
}
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    name: string;
    /**
     * An arbitrary JSON serializable value.
     * @type {string | number | boolean | Array<any> | object}
     * @memberof Setting
     */
    value: string | number | boolean | Array<any> | object;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SettingKind {
    phpIni = 'phpIni',
    phpFpm = 'phpFpm',
    apache = 'apache',
    postfix = 'postfix',
    sged = 'sged',
    rspamd = 'rspamd',
    dovecot = 'dovecot',
    websiteBackup = 'websiteBackup',
    screenshotd = 'screenshotd',
    hardDeleteGC = 'hardDeleteGC',
    letsencrypt = 'letsencrypt'
}

/**
 * 
 * @export
 * @interface SettingsFullListing
 */
export interface SettingsFullListing {
    /**
     * 
     * @type {Array<Setting>}
     * @memberof SettingsFullListing
     */
    items: Array<Setting>;
}
/**
 * 
 * @export
 * @interface SetupResult
 */
export interface SetupResult {
    /**
     * 
     * @type {string}
     * @memberof SetupResult
     */
    loginId: string;
    /**
     * 
     * @type {string}
     * @memberof SetupResult
     */
    memberId: string;
    /**
     * 
     * @type {string}
     * @memberof SetupResult
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof SetupResult
     */
    serverId: string;
    /**
     * 
     * @type {string}
     * @memberof SetupResult
     */
    controlPanelWebsiteId: string;
}
/**
 * 
 * @export
 * @interface SiteAccessMember
 */
export interface SiteAccessMember {
    /**
     * 
     * @type {string}
     * @memberof SiteAccessMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SiteAccessMember
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SiteAccessMember
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SiteAccessMember
     */
    avatarPath?: string;
    /**
     * 
     * @type {string}
     * @memberof SiteAccessMember
     */
    colorCode: string;
}
/**
 * 
 * @export
 * @interface SlaveRegistration
 */
export interface SlaveRegistration {
    /**
     * 
     * @type {string}
     * @memberof SlaveRegistration
     */
    hostname: string;
}
/**
 * 
 * @export
 * @interface SmartHostSettings
 */
export interface SmartHostSettings {
    /**
     * 
     * @type {SmartHostSettingsHost}
     * @memberof SmartHostSettings
     */
    host: SmartHostSettingsHost;
    /**
     * 
     * @type {SmartHostSettingsCreds}
     * @memberof SmartHostSettings
     */
    creds: SmartHostSettingsCreds;
}
/**
 * 
 * @export
 * @interface SmartHostSettingsCreds
 */
export interface SmartHostSettingsCreds {
    /**
     * 
     * @type {string}
     * @memberof SmartHostSettingsCreds
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartHostSettingsCreds
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface SmartHostSettingsHost
 */
export interface SmartHostSettingsHost {
    /**
     * 
     * @type {string}
     * @memberof SmartHostSettingsHost
     */
    hostname: string;
    /**
     * 
     * @type {number}
     * @memberof SmartHostSettingsHost
     */
    port?: number;
}
/**
 * 
 * @export
 * @interface SpaceUsage
 */
export interface SpaceUsage {
    /**
     * 
     * @type {number}
     * @memberof SpaceUsage
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SpaceUsage
     */
    used: number;
}
/**
 * 
 * @export
 * @interface SpamThresholds
 */
export interface SpamThresholds {
    /**
     * 
     * @type {number}
     * @memberof SpamThresholds
     */
    greylist?: number;
    /**
     * 
     * @type {number}
     * @memberof SpamThresholds
     */
    reject: number;
    /**
     * 
     * @type {number}
     * @memberof SpamThresholds
     */
    spambox: number;
}
/**
 * 
 * @export
 * @interface SshKey
 */
export interface SshKey {
    /**
     * The unique ID of the SSH key within the same authorized_keys file.
     * @type {string}
     * @memberof SshKey
     */
    id: string;
    /**
     * The datetime of when this SSH key was created.
     * @type {string}
     * @memberof SshKey
     */
    createdAt: string;
    /**
     * The SSH key (without the comment).
     * @type {string}
     * @memberof SshKey
     */
    value: string;
    /**
     * The friendly name associated with the key.
     * @type {string}
     * @memberof SshKey
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface SshKeyFullListing
 */
export interface SshKeyFullListing {
    /**
     * 
     * @type {Array<SshKey>}
     * @memberof SshKeyFullListing
     */
    items: Array<SshKey>;
}
/**
 * 
 * @export
 * @interface SshKeyUpdate
 */
export interface SshKeyUpdate {
    /**
     * The SSH key in PEM format.
     * @type {string}
     * @memberof SshKeyUpdate
     */
    value?: string;
    /**
     * The friendly name associated with the key.
     * @type {string}
     * @memberof SshKeyUpdate
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface SslCert
 */
export interface SslCert {
    /**
     * 
     * @type {string}
     * @memberof SslCert
     */
    cert: string;
    /**
     * 
     * @type {string}
     * @memberof SslCert
     */
    key?: string;
}
/**
 * 
 * @export
 * @interface StagingDomain
 */
export interface StagingDomain {
    /**
     * 
     * @type {string}
     * @memberof StagingDomain
     */
    domain: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Status {
    active = 'active',
    deleted = 'deleted'
}

/**
 * An organization\'s subscription to a plan. This allows the subscriber to use the subscribed to resources up until the quota defined in the plan is exhausted. Includes details about the subscription as well as the current usage of the resources.
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    planId: number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    planName: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    subscriberId: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    vendorId: string;
    /**
     * 
     * @type {Status}
     * @memberof Subscription
     */
    status: Status;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    suspendedBy?: string;
    /**
     * A list of used resources.
     * @type {Array<UsedResource>}
     * @memberof Subscription
     */
    resources: Array<UsedResource>;
    /**
     * 
     * @type {Array<Allowance>}
     * @memberof Subscription
     */
    allowances: Array<Allowance>;
    /**
     * 
     * @type {Array<Selection>}
     * @memberof Subscription
     */
    selections: Array<Selection>;
    /**
     * 
     * @type {SubscriptionDedicatedServersInfo}
     * @memberof Subscription
     */
    dedicatedServers?: SubscriptionDedicatedServersInfo;
    /**
     * 
     * @type {PlanType}
     * @memberof Subscription
     */
    planType: PlanType;
    /**
     * 
     * @type {Array<PhpVersion>}
     * @memberof Subscription
     */
    allowedPhpVersions: Array<PhpVersion>;
    /**
     * 
     * @type {PhpVersion}
     * @memberof Subscription
     */
    defaultPhpVersion: PhpVersion;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    redisAllowed: boolean;
    /**
     * If this field is present, the customer is allowed to chose from the server groups listed here when creating a website.
     * @type {Array<ServerGroup>}
     * @memberof Subscription
     */
    serverGroups?: Array<ServerGroup>;
    /**
     * :> When WordPress is installed on a website under this plan, the chosen theme will be preinstalled.
     * @type {string}
     * @memberof Subscription
     */
    preinstallWordpressTheme?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    friendlyName: string;
}
/**
 * 
 * @export
 * @interface SubscriptionDedicatedServer
 */
export interface SubscriptionDedicatedServer {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDedicatedServer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDedicatedServer
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SubscriptionDedicatedServers
 */
export interface SubscriptionDedicatedServers {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDedicatedServers
     */
    appServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDedicatedServers
     */
    backupServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDedicatedServers
     */
    dbServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDedicatedServers
     */
    emailServerId?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionDedicatedServersInfo
 */
export interface SubscriptionDedicatedServersInfo {
    /**
     * 
     * @type {SubscriptionDedicatedServer}
     * @memberof SubscriptionDedicatedServersInfo
     */
    appServer?: SubscriptionDedicatedServer;
    /**
     * 
     * @type {SubscriptionDedicatedServer}
     * @memberof SubscriptionDedicatedServersInfo
     */
    backupServer?: SubscriptionDedicatedServer;
    /**
     * 
     * @type {SubscriptionDedicatedServer}
     * @memberof SubscriptionDedicatedServersInfo
     */
    dbServer?: SubscriptionDedicatedServer;
    /**
     * 
     * @type {SubscriptionDedicatedServer}
     * @memberof SubscriptionDedicatedServersInfo
     */
    emailServer?: SubscriptionDedicatedServer;
}
/**
 * 
 * @export
 * @interface SubscriptionsListing
 */
export interface SubscriptionsListing {
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof SubscriptionsListing
     */
    items: Array<Subscription>;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionsListing
     */
    total: number;
}
/**
 * 
 * @export
 * @interface SystemPackage
 */
export interface SystemPackage {
    /**
     * 
     * @type {SystemPackageName}
     * @memberof SystemPackage
     */
    name: SystemPackageName;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SystemPackageName {
    appcd = 'appcd',
    git = 'git'
}

/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    colorCode: string;
}
/**
 * 
 * @export
 * @interface TagsFullListing
 */
export interface TagsFullListing {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TagsFullListing
     */
    items: Array<Tag>;
}
/**
 * Tree starting from top level DNS and recursively going down to
 * @export
 * @interface TldNs
 */
export interface TldNs {
    /**
     * 
     * @type {string}
     * @memberof TldNs
     */
    ip: string;
    /**
     * Tree of AuthNs servers
     * @type {{ [key: string]: AuthNs; }}
     * @memberof TldNs
     */
    authNsMap: { [key: string]: AuthNs; };
}
/**
 * 
 * @export
 * @interface TransferPleskDomainReqBody
 */
export interface TransferPleskDomainReqBody {
    /**
     * 
     * @type {number}
     * @memberof TransferPleskDomainReqBody
     */
    subscriptionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferPleskDomainReqBody
     */
    asUserRoot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransferPleskDomainReqBody
     */
    allowPartialSync?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferPleskDomainReqBody
     */
    appServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferPleskDomainReqBody
     */
    backupServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferPleskDomainReqBody
     */
    dbServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferPleskDomainReqBody
     */
    emailServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferPleskDomainReqBody
     */
    domain: string;
}
/**
 * 
 * @export
 * @interface TransferPleskDomainReqBodyAllOf
 */
export interface TransferPleskDomainReqBodyAllOf {
    /**
     * 
     * @type {string}
     * @memberof TransferPleskDomainReqBodyAllOf
     */
    domain: string;
}
/**
 * 
 * @export
 * @interface TransferUserAccountReqBody
 */
export interface TransferUserAccountReqBody {
    /**
     * 
     * @type {number}
     * @memberof TransferUserAccountReqBody
     */
    subscriptionId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferUserAccountReqBody
     */
    asUserRoot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransferUserAccountReqBody
     */
    allowPartialSync?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferUserAccountReqBody
     */
    appServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferUserAccountReqBody
     */
    backupServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferUserAccountReqBody
     */
    dbServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferUserAccountReqBody
     */
    emailServerId?: string;
}
/**
 * 
 * @export
 * @interface UiPreferences
 */
export interface UiPreferences {
    /**
     * 
     * @type {string}
     * @memberof UiPreferences
     */
    loginId: string;
    /**
     * 
     * @type {UiPreferencesViewKind}
     * @memberof UiPreferences
     */
    fileManagerView: UiPreferencesViewKind;
    /**
     * 
     * @type {UiPreferencesViewKind}
     * @memberof UiPreferences
     */
    websiteView: UiPreferencesViewKind;
    /**
     * 
     * @type {boolean}
     * @memberof UiPreferences
     */
    spineOpen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UiPreferences
     */
    fileManagerEditorFullscreen: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UiPreferences
     */
    serverGroups: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UiPreferencesViewKind {
    list = 'list',
    grid = 'grid'
}

/**
 * 
 * @export
 * @interface UnixTimestamp
 */
export interface UnixTimestamp {
    /**
     * 
     * @type {number}
     * @memberof UnixTimestamp
     */
    seconds?: number;
}
/**
 * 
 * @export
 * @interface Unset
 */
export interface Unset {
    /**
     * 
     * @type {boolean}
     * @memberof Unset
     */
    unset: boolean;
}
/**
 * 
 * @export
 * @interface UpdateApplicationRole
 */
export interface UpdateApplicationRole {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof UpdateApplicationRole
     */
    state?: ServerRoleState;
}
/**
 * 
 * @export
 * @interface UpdateAutoresponder
 */
export interface UpdateAutoresponder {
    /**
     * 
     * @type {string}
     * @memberof UpdateAutoresponder
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAutoresponder
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAutoresponder
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAutoresponder
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAutoresponder
     */
    body?: string;
}
/**
 * 
 * @export
 * @interface UpdateBackupRemoteStorageS3
 */
export interface UpdateBackupRemoteStorageS3 {
    /**
     * 
     * @type {string}
     * @memberof UpdateBackupRemoteStorageS3
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackupRemoteStorageS3
     */
    endpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackupRemoteStorageS3
     */
    bucket?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackupRemoteStorageS3
     */
    accessKeyId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackupRemoteStorageS3
     */
    accessKeySecret?: string;
}
/**
 * 
 * @export
 * @interface UpdateBackupRole
 */
export interface UpdateBackupRole {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof UpdateBackupRole
     */
    state?: ServerRoleState;
}
/**
 * 
 * @export
 * @interface UpdateCloudFlareApiKey
 */
export interface UpdateCloudFlareApiKey {
    /**
     * 
     * @type {string}
     * @memberof UpdateCloudFlareApiKey
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCloudFlareApiKey
     */
    friendlyName?: string;
}
/**
 * 
 * @export
 * @interface UpdateCrontabFullListing
 */
export interface UpdateCrontabFullListing {
    /**
     * 
     * @type {Array<UpdateCrontabValue>}
     * @memberof UpdateCrontabFullListing
     */
    items: Array<UpdateCrontabValue>;
}
/**
 * @type UpdateCrontabValue
 * @export
 */
export type UpdateCrontabValue = UpdateCrontabValueCmd | UpdateCrontabValueVariable;

/**
 * 
 * @export
 * @interface UpdateCrontabValueCmd
 */
export interface UpdateCrontabValueCmd {
    /**
     * 
     * @type {UpdateCrontabValueCmdCronCmd}
     * @memberof UpdateCrontabValueCmd
     */
    cronCmd: UpdateCrontabValueCmdCronCmd;
}
/**
 * 
 * @export
 * @interface UpdateCrontabValueCmdCronCmd
 */
export interface UpdateCrontabValueCmdCronCmd {
    /**
     * 
     * @type {number}
     * @memberof UpdateCrontabValueCmdCronCmd
     */
    lineNumber: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrontabValueCmdCronCmd
     */
    expr?: string;
}
/**
 * 
 * @export
 * @interface UpdateCrontabValueVariable
 */
export interface UpdateCrontabValueVariable {
    /**
     * 
     * @type {UpdateCrontabValueVariableVariable}
     * @memberof UpdateCrontabValueVariable
     */
    variable: UpdateCrontabValueVariableVariable;
}
/**
 * 
 * @export
 * @interface UpdateCrontabValueVariableVariable
 */
export interface UpdateCrontabValueVariableVariable {
    /**
     * 
     * @type {number}
     * @memberof UpdateCrontabValueVariableVariable
     */
    lineNumber: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrontabValueVariableVariable
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrontabValueVariableVariable
     */
    val?: string;
}
/**
 * 
 * @export
 * @interface UpdateDatabaseRole
 */
export interface UpdateDatabaseRole {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof UpdateDatabaseRole
     */
    state?: ServerRoleState;
}
/**
 * 
 * @export
 * @interface UpdateDefaultDnsRecord
 */
export interface UpdateDefaultDnsRecord {
    /**
     * 
     * @type {DnsRecordKind}
     * @memberof UpdateDefaultDnsRecord
     */
    kind?: DnsRecordKind;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDnsRecord
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultDnsRecord
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDefaultDnsRecord
     */
    ttl?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDefaultDnsRecord
     */
    overrideConflicting?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDnsRecord
 */
export interface UpdateDnsRecord {
    /**
     * 
     * @type {DnsRecordKind}
     * @memberof UpdateDnsRecord
     */
    kind?: DnsRecordKind;
    /**
     * 
     * @type {string}
     * @memberof UpdateDnsRecord
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDnsRecord
     */
    value?: string;
    /**
     * In seconds
     * @type {number}
     * @memberof UpdateDnsRecord
     */
    ttl?: number;
    /**
     * If CloudFlare enabled, will enable the proxy
     * @type {boolean}
     * @memberof UpdateDnsRecord
     */
    proxy?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDnsRole
 */
export interface UpdateDnsRole {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof UpdateDnsRole
     */
    state?: ServerRoleState;
}
/**
 * 
 * @export
 * @interface UpdateDnsZone
 */
export interface UpdateDnsZone {
    /**
     * 
     * @type {string}
     * @memberof UpdateDnsZone
     */
    nameServer?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDnsZone
     */
    adminEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDnsZone
     */
    expire?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDnsZone
     */
    refresh?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDnsZone
     */
    retry?: number;
    /**
     * In seconds
     * @type {number}
     * @memberof UpdateDnsZone
     */
    ttl?: number;
}
/**
 * 
 * @export
 * @interface UpdateEmail
 */
export interface UpdateEmail {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmail
     */
    mailboxName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmail
     */
    mailboxPassword?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmail
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmail
     */
    forwarders?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmail
     */
    hasMailbox?: boolean;
    /**
     * 
     * @type {EmailStatus}
     * @memberof UpdateEmail
     */
    status?: EmailStatus;
    /**
     * 
     * @type {number}
     * @memberof UpdateEmail
     */
    quota?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmail
     */
    blacklist?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateEmail
     */
    whitelist?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateEmailRole
 */
export interface UpdateEmailRole {
    /**
     * 
     * @type {ServerRoleState}
     * @memberof UpdateEmailRole
     */
    state?: ServerRoleState;
}
/**
 * 
 * @export
 * @interface UpdateImportServerSettings
 */
export interface UpdateImportServerSettings {
    /**
     * 
     * @type {string}
     * @memberof UpdateImportServerSettings
     */
    hostname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImportServerSettings
     */
    friendlyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImportServerSettings
     */
    sshUser?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImportServerSettings
     */
    sshPublicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateImportServerSettings
     */
    sshPrivateKey?: string;
    /**
     * 
     * @type {ServerMigrationSettingsAuthType}
     * @memberof UpdateImportServerSettings
     */
    authKind?: ServerMigrationSettingsAuthType;
    /**
     * 
     * @type {string}
     * @memberof UpdateImportServerSettings
     */
    authUser?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateImportServerSettings
     */
    sshPort?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateImportServerSettings
     */
    apiPort?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateImportServerSettings
     */
    allowPartialSync?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateImportServerSettings
     */
    asUserRoot?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateLogin
 */
export interface UpdateLogin {
    /**
     * 
     * @type {string}
     * @memberof UpdateLogin
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLogin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLogin
     */
    password?: string;
    /**
     * 
     * @type {CPLocale}
     * @memberof UpdateLogin
     */
    locale?: CPLocale;
    /**
     * 
     * @type {string}
     * @memberof UpdateLogin
     */
    authMethod?: UpdateLoginAuthMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLogin
     */
    currentPassword?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateLoginAuthMethodEnum {
    basic = 'basic',
    email = 'email',
    otp = 'otp'
}

/**
 * 
 * @export
 * @interface UpdateLoginResult
 */
export interface UpdateLoginResult {
    /**
     * 
     * @type {string}
     * @memberof UpdateLoginResult
     */
    otpUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLoginResult
     */
    verifyPin?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateMember
 */
export interface UpdateMember {
    /**
     * 
     * @type {Array<Role>}
     * @memberof UpdateMember
     */
    roles?: Array<Role>;
    /**
     * This field is only present if member has \"SiteAccess\" role. In this case, the list contains the ids of the websites to which member has access.
     * @type {Array<string>}
     * @memberof UpdateMember
     */
    siteAccesses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateMember
     */
    notifications?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdatePlan
 */
export interface UpdatePlan {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlan
     */
    name?: string;
    /**
     * 
     * @type {PlanType}
     * @memberof UpdatePlan
     */
    planType?: PlanType;
    /**
     * 
     * @type {CgroupLimits}
     * @memberof UpdatePlan
     */
    cgroupLimits?: CgroupLimits;
    /**
     * 
     * @type {FsQuotaLimit}
     * @memberof UpdatePlan
     */
    fsQuotaLimit?: FsQuotaLimit;
    /**
     * 
     * @type {Array<PhpVersion>}
     * @memberof UpdatePlan
     */
    allowedPhpVersions?: Array<PhpVersion>;
    /**
     * 
     * @type {PhpVersion}
     * @memberof UpdatePlan
     */
    defaultPhpVersion?: PhpVersion;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePlan
     */
    redisAllowed?: boolean;
    /**
     * If provided as an empty array, removes all server groups from the plan. 
     * @type {Array<string>}
     * @memberof UpdatePlan
     */
    serverGroupIds?: Array<string>;
    /**
     * Whether the customer can select the server group for their websites. 
     * @type {boolean}
     * @memberof UpdatePlan
     */
    allowServerGroupSelection?: boolean;
    /**
     * If set, servers from this server group are prioritized by placement algorithm. If no server from the default server group is available, servers from other server groups are tried. If both serverGroupIds and defaultServerGroupId is provided, defaultServerGroupId will be added to serverGroupIds if not there. If only defaultServerGroupId is provided, already existing plan\'s serverGroupIds will be expanded with defaultServerGroupId if not there yet. 
     * @type {string | Unset}
     * @memberof UpdatePlan
     */
    defaultServerGroupId?: string | Unset;
    /**
     * :> When WordPress is installed on a website under this plan, the chosen theme will be preinstalled.
     * @type {string}
     * @memberof UpdatePlan
     */
    preinstallWordpressTheme?: string;
}
/**
 * If the `rule` property is missing, we will delete a rule on the given line number instead. If just the `conds` property is missing, it defauls to an empty array.
 * @export
 * @interface UpdateRewriteChain
 */
export interface UpdateRewriteChain {
    /**
     * 
     * @type {number}
     * @memberof UpdateRewriteChain
     */
    lineNumber: number;
    /**
     * 
     * @type {RewriteChainRule}
     * @memberof UpdateRewriteChain
     */
    rule?: RewriteChainRule;
    /**
     * 
     * @type {Array<RewriteChainConds>}
     * @memberof UpdateRewriteChain
     */
    conds?: Array<RewriteChainConds>;
}
/**
 * 
 * @export
 * @interface UpdateRewriteChainFullListing
 */
export interface UpdateRewriteChainFullListing {
    /**
     * 
     * @type {Array<UpdateRewriteChain>}
     * @memberof UpdateRewriteChainFullListing
     */
    items: Array<UpdateRewriteChain>;
}
/**
 * 
 * @export
 * @interface UpdateSubscription
 */
export interface UpdateSubscription {
    /**
     * 
     * @type {Status}
     * @memberof UpdateSubscription
     */
    status?: Status;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSubscription
     */
    isSuspended?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateSubscription
     */
    planId?: number;
    /**
     * 
     * @type {SubscriptionDedicatedServers}
     * @memberof UpdateSubscription
     */
    dedicatedServers?: SubscriptionDedicatedServers;
    /**
     * 
     * @type {string}
     * @memberof UpdateSubscription
     */
    friendlyName?: string;
}
/**
 * 
 * @export
 * @interface UpdateUiPreferences
 */
export interface UpdateUiPreferences {
    /**
     * 
     * @type {UiPreferencesViewKind}
     * @memberof UpdateUiPreferences
     */
    fileManagerView?: UiPreferencesViewKind;
    /**
     * 
     * @type {UiPreferencesViewKind}
     * @memberof UpdateUiPreferences
     */
    websiteView?: UiPreferencesViewKind;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUiPreferences
     */
    spineOpen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUiPreferences
     */
    fileManagerEditorFullscreen?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUiPreferences
     */
    serverGroups?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateWebsite
 */
export interface UpdateWebsite {
    /**
     * 
     * @type {PhpVersion}
     * @memberof UpdateWebsite
     */
    phpVersion?: PhpVersion;
    /**
     * 
     * @type {WebsiteStatus}
     * @memberof UpdateWebsite
     */
    status?: WebsiteStatus;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWebsite
     */
    isSuspended?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWebsite
     */
    tags?: Array<number>;
    /**
     * The website can be moved to another subscription within the organization by setting this field.
     * @type {number | Unset}
     * @memberof UpdateWebsite
     */
    subscriptionId?: number | Unset;
    /**
     * 
     * @type {string}
     * @memberof UpdateWebsite
     */
    orgId?: string;
}
/**
 * 
 * @export
 * @interface UpdateWpPlugin
 */
export interface UpdateWpPlugin {
    /**
     * 
     * @type {WPPluginStatus}
     * @memberof UpdateWpPlugin
     */
    status?: WPPluginStatus;
    /**
     * 
     * @type {WPPluginAutoUpdateStatus}
     * @memberof UpdateWpPlugin
     */
    autoUpdate?: WPPluginAutoUpdateStatus;
}
/**
 * 
 * @export
 * @interface UpdateWpSettings
 */
export interface UpdateWpSettings {
    /**
     * 
     * @type {WPAutoUpdateCore}
     * @memberof UpdateWpSettings
     */
    autoUpdateCore?: WPAutoUpdateCore;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWpSettings
     */
    disallowNonWpPhp?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWpSettings
     */
    loginAccess?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateWpUser
 */
export interface UpdateWpUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateWpUser
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWpUser
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWpUser
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface UpgradableSystemPackage
 */
export interface UpgradableSystemPackage {
    /**
     * 
     * @type {SystemPackageName}
     * @memberof UpgradableSystemPackage
     */
    name: SystemPackageName;
    /**
     * 
     * @type {string}
     * @memberof UpgradableSystemPackage
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface UsedResource
 */
export interface UsedResource {
    /**
     * 
     * @type {ResourceName}
     * @memberof UsedResource
     */
    name: ResourceName;
    /**
     * The total quota of the resource. If not set, the resource has unlimited quota, but the resource usage is still tracked.
     * @type {number}
     * @memberof UsedResource
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof UsedResource
     */
    usage: number;
}
/**
 * 
 * @export
 * @interface UsedResourcesFullListing
 */
export interface UsedResourcesFullListing {
    /**
     * 
     * @type {Array<UsedResource>}
     * @memberof UsedResourcesFullListing
     */
    items: Array<UsedResource>;
}
/**
 * 
 * @export
 * @interface UuidListing
 */
export interface UuidListing {
    /**
     * 
     * @type {Array<string>}
     * @memberof UuidListing
     */
    ids: Array<string>;
}
/**
 * 
 * @export
 * @interface ValidatedPasswordRecovery
 */
export interface ValidatedPasswordRecovery {
    /**
     * 
     * @type {string}
     * @memberof ValidatedPasswordRecovery
     */
    obfuscatedEmail?: string;
}
/**
 * 
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
    /**
     * 
     * @type {boolean}
     * @memberof ValidationResult
     */
    result: boolean;
}
/**
 * 
 * @export
 * @interface Vhost
 */
export interface Vhost {
    /**
     * 
     * @type {string}
     * @memberof Vhost
     */
    contents: string;
    /**
     * 
     * @type {VhostWebserverKind}
     * @memberof Vhost
     */
    webserver: VhostWebserverKind;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VhostWebserverKind {
    apache = 'apache',
    nginx = 'nginx'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WPAutoUpdateCore {
    major = 'major',
    minor = 'minor'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WPPluginAutoUpdateStatus {
    enabled = 'enabled',
    disabled = 'disabled'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WPPluginStatus {
    active = 'active',
    inactive = 'inactive',
    unknown = 'unknown'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WPPluginUpdateAvailable {
    available = 'available',
    none = 'none'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum WPThemeAutoUpdateStatus {
    on = 'on',
    off = 'off'
}

/**
 * 
 * @export
 * @interface WebServerRewrite
 */
export interface WebServerRewrite {
    /**
     * 
     * @type {string}
     * @memberof WebServerRewrite
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof WebServerRewrite
     */
    destinationFile: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebserverKind {
    liteSpeed = 'liteSpeed',
    openLiteSpeed = 'openLiteSpeed',
    dummyWebServer = 'dummyWebServer',
    apache = 'apache',
    nginx = 'nginx'
}

/**
 * 
 * @export
 * @interface Website
 */
export interface Website {
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    id: string;
    /**
     * 
     * @type {WebsiteDomain}
     * @memberof Website
     */
    domain: WebsiteDomain;
    /**
     * 
     * @type {Array<WebsiteDomain>}
     * @memberof Website
     */
    aliases: Array<WebsiteDomain>;
    /**
     * 
     * @type {Array<any>}
     * @memberof Website
     */
    subdomains: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof Website
     */
    subscriptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof Website
     */
    planId?: number;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    plan?: string;
    /**
     * 
     * @type {WebsiteStatus}
     * @memberof Website
     */
    status: WebsiteStatus;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    suspendedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    colorCode: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Website
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {number}
     * @memberof Website
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    org?: string;
    /**
     * 
     * @type {WebsiteKind}
     * @memberof Website
     */
    kind: WebsiteKind;
    /**
     * 
     * @type {BackupAction}
     * @memberof Website
     */
    pendingBackup?: BackupAction;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    parentId?: string;
    /**
     * The id of the server on which this website is located. This is only returned when websites are queried recursively by an MO member, as the MO is in charge of servers and thus this information only concerns them.
     * @type {string}
     * @memberof Website
     */
    appServerId?: string;
    /**
     * The id of the server on which the backups of this website are located. This is only returned when websites are queried recursively by an MO member, as the MO is in charge of servers and thus this information only concerns them.
     * @type {string}
     * @memberof Website
     */
    backupServerId?: string;
    /**
     * The id of the server on which the databases of this website are located. This is only returned when websites are queried recursively by an MO member, as the MO is in charge of servers and thus this information only concerns them.
     * @type {string}
     * @memberof Website
     */
    dbServerId?: string;
    /**
     * The id of the server on which the emails of this website are located. This is only returned when websites are queried recursively by an MO member, as the MO is in charge of servers and thus this information only concerns them.
     * @type {string}
     * @memberof Website
     */
    emailServerId?: string;
    /**
     * The unix user assigned to this website, used for ssh shells, prefixing website databases and databse users, etc.
     * @type {string}
     * @memberof Website
     */
    unixUser?: string;
    /**
     * 
     * @type {Array<SiteAccessMember>}
     * @memberof Website
     */
    siteAccessMembers?: Array<SiteAccessMember>;
    /**
     * The addresses of the the server on which this website is located.
     * @type {Array<ServerIp>}
     * @memberof Website
     */
    serverIps?: Array<ServerIp>;
    /**
     * The addresses of the the server on which this website\'s backups are located.
     * @type {Array<ServerIp>}
     * @memberof Website
     */
    backupServerIps?: Array<ServerIp>;
    /**
     * The addresses of the the server on which this website\'s databases are located.
     * @type {Array<ServerIp>}
     * @memberof Website
     */
    dbServerIps?: Array<ServerIp>;
    /**
     * The addresses of the the server on which this website\'s emails are located.
     * @type {Array<ServerIp>}
     * @memberof Website
     */
    emailServerIps?: Array<ServerIp>;
    /**
     * The path relative to the control panel domain where filerd can be accessed.
     * @type {string}
     * @memberof Website
     */
    filerdAddress?: string;
    /**
     * 
     * @type {PhpVersion}
     * @memberof Website
     */
    phpVersion?: PhpVersion;
    /**
     * The date the site was first added
     * @type {string}
     * @memberof Website
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    appServerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    dbServerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    emailServerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    backupServerName?: string;
    /**
     * 
     * @type {CanUse}
     * @memberof Website
     */
    canUse?: CanUse;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    appServerIpv6?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    dbServerIpv6?: string;
    /**
     * 
     * @type {string}
     * @memberof Website
     */
    emailServerIpv6?: string;
}
/**
 * 
 * @export
 * @interface WebsiteAndDomainUuid
 */
export interface WebsiteAndDomainUuid {
    /**
     * 
     * @type {string}
     * @memberof WebsiteAndDomainUuid
     */
    websiteId: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteAndDomainUuid
     */
    domainId: string;
}
/**
 * 
 * @export
 * @interface WebsiteApp
 */
export interface WebsiteApp {
    /**
     * 
     * @type {string}
     * @memberof WebsiteApp
     */
    id?: string;
    /**
     * 
     * @type {WebsiteAppKind}
     * @memberof WebsiteApp
     */
    app: WebsiteAppKind;
    /**
     * The path is only present if the app is installed in the root instead of a subfolder. For example if a customer installs Wordpress at \'/blog\', then the path will be present and equal to \'blog\'. But if they install WP in website root, instead of returning \'/\' or empty string, this property is omitted.
     * @type {string}
     * @memberof WebsiteApp
     */
    path?: string;
    /**
     * Only present if default was set by the user. Otherwise, this field isn\'t there.
     * @type {number}
     * @memberof WebsiteApp
     */
    defaultWpUserId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebsiteAppKind {
    wordpress = 'wordpress'
}

/**
 * 
 * @export
 * @interface WebsiteAppsFullListing
 */
export interface WebsiteAppsFullListing {
    /**
     * 
     * @type {Array<WebsiteApp>}
     * @memberof WebsiteAppsFullListing
     */
    items: Array<WebsiteApp>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebsiteCloneEnumStatus {
    queued = 'queued',
    inProgress = 'inProgress',
    failed = 'failed',
    complete = 'complete'
}

/**
 * 
 * @export
 * @interface WebsiteCloneFullListing
 */
export interface WebsiteCloneFullListing {
    /**
     * 
     * @type {Array<WebsiteCloneResponse>}
     * @memberof WebsiteCloneFullListing
     */
    items: Array<WebsiteCloneResponse>;
}
/**
 * 
 * @export
 * @interface WebsiteCloneLogEntry
 */
export interface WebsiteCloneLogEntry {
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneLogEntry
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneLogEntry
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof WebsiteCloneLogEntry
     */
    percentageComplete: number;
}
/**
 * 
 * @export
 * @interface WebsiteCloneNewWebsite
 */
export interface WebsiteCloneNewWebsite {
    /**
     * The domain of the new website.
     * @type {string}
     * @memberof WebsiteCloneNewWebsite
     */
    domain: string;
    /**
     * 
     * @type {number}
     * @memberof WebsiteCloneNewWebsite
     */
    subscriptionId?: number;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneNewWebsite
     */
    appServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneNewWebsite
     */
    backupServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneNewWebsite
     */
    dbServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneNewWebsite
     */
    emailServerId?: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneNewWebsite
     */
    serverGroupId?: string;
    /**
     * 
     * @type {PhpVersion}
     * @memberof WebsiteCloneNewWebsite
     */
    phpVersion?: PhpVersion;
    /**
     * 
     * @type {WebsiteKind}
     * @memberof WebsiteCloneNewWebsite
     */
    kind?: WebsiteKind;
}
/**
 * 
 * @export
 * @interface WebsiteCloneRequest
 */
export interface WebsiteCloneRequest {
    /**
     * 
     * @type {boolean}
     * @memberof WebsiteCloneRequest
     */
    runWpSearchReplace?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneRequest
     */
    sourceWebsiteId: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneRequest
     */
    destWebsiteId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteCloneRequest
     */
    excludePaths: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteCloneRequest
     */
    includeDatabases?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteCloneRequest
     */
    includeDatabaseUsers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WebsiteCloneRequest
     */
    deleteFilesFromDestination: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebsiteCloneRequest
     */
    syncPhpVersion: boolean;
    /**
     * 
     * @type {WebsiteCloneNewWebsite}
     * @memberof WebsiteCloneRequest
     */
    newWebsite?: WebsiteCloneNewWebsite;
}
/**
 * 
 * @export
 * @interface WebsiteCloneResponse
 */
export interface WebsiteCloneResponse {
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneResponse
     */
    sourceWebsiteId: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteCloneResponse
     */
    destWebsiteId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteCloneResponse
     */
    excludePaths: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteCloneResponse
     */
    includeDatabases: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteCloneResponse
     */
    includeDatabaseUsers: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof WebsiteCloneResponse
     */
    deleteFilesFromDestination: boolean;
    /**
     * 
     * @type {WebsiteCloneEnumStatus}
     * @memberof WebsiteCloneResponse
     */
    status: WebsiteCloneEnumStatus;
    /**
     * 
     * @type {boolean}
     * @memberof WebsiteCloneResponse
     */
    syncPhpVersion: boolean;
}
/**
 * 
 * @export
 * @interface WebsiteCloneStatus
 */
export interface WebsiteCloneStatus {
    /**
     * 
     * @type {CloneStatus}
     * @memberof WebsiteCloneStatus
     */
    status: CloneStatus;
}
/**
 * 
 * @export
 * @interface WebsiteDomain
 */
export interface WebsiteDomain {
    /**
     * 
     * @type {string}
     * @memberof WebsiteDomain
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteDomain
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof WebsiteDomain
     */
    documentRoot: string;
    /**
     * 
     * @type {DomainMappingKind}
     * @memberof WebsiteDomain
     */
    kind: DomainMappingKind;
    /**
     * 
     * @type {CloudFlareStatus}
     * @memberof WebsiteDomain
     */
    cloudflareStatus: CloudFlareStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebsiteKind {
    normal = 'normal',
    controlPanel = 'controlPanel',
    phpMyAdmin = 'phpMyAdmin',
    roundcube = 'roundcube',
    staging = 'staging',
    serverHostname = 'serverHostname'
}

/**
 * 
 * @export
 * @interface WebsiteLog
 */
export interface WebsiteLog {
    /**
     * 
     * @type {string}
     * @memberof WebsiteLog
     */
    createdAt: string;
    /**
     * 
     * @type {WebsiteLogStatus}
     * @memberof WebsiteLog
     */
    status: WebsiteLogStatus;
    /**
     * 
     * @type {LogTimeMetadata | LogHttpMetadata}
     * @memberof WebsiteLog
     */
    metadata?: LogTimeMetadata | LogHttpMetadata;
}
/**
 * The status of the website at the time the log was written. Some statuses have additional metadata (ok, slow, failed4xx, failed5xx) whereas some don\'t (timeout, dnsZoneNotFound, appServerMismatch).
 * @export
 * @enum {string}
 */
export enum WebsiteLogStatus {
    ok = 'ok',
    slow = 'slow',
    failed4xx = 'failed4xx',
    failed5xx = 'failed5xx',
    timeout = 'timeout',
    dnsZoneNotFound = 'dnsZoneNotFound',
    appServerMismatch = 'appServerMismatch'
}

/**
 * 
 * @export
 * @interface WebsiteLogsFullListing
 */
export interface WebsiteLogsFullListing {
    /**
     * 
     * @type {Array<WebsiteLog>}
     * @memberof WebsiteLogsFullListing
     */
    items: Array<WebsiteLog>;
}
/**
 * 
 * @export
 * @interface WebsiteMetricsFullListing
 */
export interface WebsiteMetricsFullListing {
    /**
     * 
     * @type {Array<MetricsEntry>}
     * @memberof WebsiteMetricsFullListing
     */
    items: Array<MetricsEntry>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebsiteOperation {
    changeSubscription = 'changeSubscription'
}

/**
 * 
 * @export
 * @interface WebsiteOperationValidation
 */
export interface WebsiteOperationValidation {
    /**
     * 
     * @type {WebsiteOperation}
     * @memberof WebsiteOperationValidation
     */
    operation: WebsiteOperation;
    /**
     * 
     * @type {ChangeSubscriptionParams}
     * @memberof WebsiteOperationValidation
     */
    params?: ChangeSubscriptionParams;
}
/**
 * 
 * @export
 * @interface WebsitePhpSettings
 */
export interface WebsitePhpSettings {
    /**
     * 
     * @type {string}
     * @memberof WebsitePhpSettings
     */
    websiteId: string;
    /**
     * 
     * @type {PhpIni}
     * @memberof WebsitePhpSettings
     */
    phpIni: PhpIni;
}
/**
 * 
 * @export
 * @interface WebsiteServerDomains
 */
export interface WebsiteServerDomains {
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteServerDomains
     */
    appServerDomains: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteServerDomains
     */
    emailServerDomains?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebsiteServerDomains
     */
    dbServerDomains?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum WebsiteStatus {
    active = 'active',
    disabled = 'disabled',
    deleted = 'deleted'
}

/**
 * 
 * @export
 * @interface WebsitesListing
 */
export interface WebsitesListing {
    /**
     * 
     * @type {Array<Website>}
     * @memberof WebsitesListing
     */
    items: Array<Website>;
    /**
     * 
     * @type {number}
     * @memberof WebsitesListing
     */
    total: number;
}
/**
 * @type WordpressConfig
 * @export
 */
export type WordpressConfig = WpDebug | WpDebugDisplay | WpDebugLog;

/**
 * Enable or disable debug mode.
 * @export
 * @interface WpDebug
 */
export interface WpDebug {
    /**
     * 
     * @type {boolean}
     * @memberof WpDebug
     */
    WpDebug: boolean;
}
/**
 * Enable or disable debug display.
 * @export
 * @interface WpDebugDisplay
 */
export interface WpDebugDisplay {
    /**
     * 
     * @type {boolean}
     * @memberof WpDebugDisplay
     */
    WpDebugDisplay: boolean;
}
/**
 * Enable or disable debug logging.
 * @export
 * @interface WpDebugLog
 */
export interface WpDebugLog {
    /**
     * 
     * @type {boolean}
     * @memberof WpDebugLog
     */
    WpDebugLog: boolean;
}
/**
 * 
 * @export
 * @interface WpInstallation
 */
export interface WpInstallation {
    /**
     * 
     * @type {string}
     * @memberof WpInstallation
     */
    dbName: string;
    /**
     * 
     * @type {string}
     * @memberof WpInstallation
     */
    dbUser: string;
    /**
     * 
     * @type {string}
     * @memberof WpInstallation
     */
    tablePrefix: string;
    /**
     * 
     * @type {string}
     * @memberof WpInstallation
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface WpLatestVersion
 */
export interface WpLatestVersion {
    /**
     * 
     * @type {string}
     * @memberof WpLatestVersion
     */
    version: string;
}
/**
 * Describes the filename and additional plugin information. The filename is the name of the plugin php file, e.g. \"bbpress.php\". If the plugin kind is \"file\", then the file name refers to e.g. \"wp-content/plugins/bbpress.php\". If the kind is \"dir\", then the name refers to \"wp-content/plugins/bbpress/bbpress.php\". The name of the dir is always the same as the name of the file without the php suffix. https://developer.wordpress.org/plugins/plugin-basics/header-requirements/#header-fields
 * @export
 * @interface WpPlugin
 */
export interface WpPlugin {
    /**
     * 
     * @type {string}
     * @memberof WpPlugin
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WpPlugin
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof WpPlugin
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WpPlugin
     */
    version: string;
    /**
     * 
     * @type {WPPluginUpdateAvailable}
     * @memberof WpPlugin
     */
    update?: WPPluginUpdateAvailable;
    /**
     * 
     * @type {WPPluginAutoUpdateStatus}
     * @memberof WpPlugin
     */
    autoUpdate?: WPPluginAutoUpdateStatus;
    /**
     * 
     * @type {WPPluginStatus}
     * @memberof WpPlugin
     */
    status?: WPPluginStatus;
    /**
     * 
     * @type {string}
     * @memberof WpPlugin
     */
    author: string;
}
/**
 * 
 * @export
 * @interface WpPluginsFullListing
 */
export interface WpPluginsFullListing {
    /**
     * 
     * @type {Array<WpPlugin>}
     * @memberof WpPluginsFullListing
     */
    items: Array<WpPlugin>;
}
/**
 * 
 * @export
 * @interface WpSettings
 */
export interface WpSettings {
    /**
     * 
     * @type {WPAutoUpdateCore}
     * @memberof WpSettings
     */
    autoUpdateCore?: WPAutoUpdateCore;
    /**
     * 
     * @type {boolean}
     * @memberof WpSettings
     */
    disallowNonWpPhp: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof WpSettings
     */
    loginAccess: Array<string>;
}
/**
 * Describes the theme directory name and additional theme information.
 * @export
 * @interface WpTheme
 */
export interface WpTheme {
    /**
     * 
     * @type {string}
     * @memberof WpTheme
     */
    name: string;
    /**
     * 
     * @type {WPPluginStatus}
     * @memberof WpTheme
     */
    status: WPPluginStatus;
    /**
     * 
     * @type {string}
     * @memberof WpTheme
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof WpTheme
     */
    update?: string;
    /**
     * 
     * @type {WPThemeAutoUpdateStatus}
     * @memberof WpTheme
     */
    autoUpdate?: WPThemeAutoUpdateStatus;
}
/**
 * 
 * @export
 * @interface WpThemesFullListing
 */
export interface WpThemesFullListing {
    /**
     * 
     * @type {Array<WpTheme>}
     * @memberof WpThemesFullListing
     */
    items: Array<WpTheme>;
}
/**
 * 
 * @export
 * @interface WpUser
 */
export interface WpUser {
    /**
     * 
     * @type {number}
     * @memberof WpUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WpUser
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof WpUser
     */
    email: string;
}
/**
 * 
 * @export
 * @interface WpUsersFullListing
 */
export interface WpUsersFullListing {
    /**
     * 
     * @type {Array<WpUser>}
     * @memberof WpUsersFullListing
     */
    items: Array<WpUser>;
}

/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create website applications - Creates a new application for website. Note that if the installed app is WordPress, this endpoint will enable PHP for the website if it isn\'t already. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteApp: async (orgId: string, websiteId: string, newWebsiteApp: NewWebsiteApp, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newWebsiteApp !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newWebsiteApp !== undefined ? newWebsiteApp : {}) : (newWebsiteApp || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website app - Deletes an existing website app. Unless `backupBeforeOperation=false` query param is sent, it runs a website backup before deleting the app. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteApp: async (orgId: string, websiteId: string, appId: string, backupBeforeOperation?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (backupBeforeOperation !== undefined) {
                localVarQueryParameter['backupBeforeOperation'] = backupBeforeOperation;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get all installable applications - Returns all installable applications. Note that what apps can be installed on a website is ultimately decided by customer\'s subscription. See operationId `getInstallableApps`. */
        getGlobalInstallableApps: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/installable-apps`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get installable website applications - Returns all installable applications under the subscription. */
        getInstallableApps: async (orgId: string, subscriptionId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions/{subscription_id}/installable-apps`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website applications - Returns all applications installed on this website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteApps: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createWebsiteApp operation in AppsApi. */
export type AppsApiCreateWebsiteAppReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    newWebsiteApp: NewWebsiteApp
};

/** Response parameters for createWebsiteApp operation in AppsApi. */
export type AppsApiCreateWebsiteAppRes = NewResourceUuid;


/** Request parameters for deleteWebsiteApp operation in AppsApi. */
export type AppsApiDeleteWebsiteAppReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** Whether a backup should be ran before the endpoint operation is executed. */
    backupBeforeOperation?: boolean
};

/** Response parameters for deleteWebsiteApp operation in AppsApi. */
export type AppsApiDeleteWebsiteAppRes = void;


/** Request parameters for getGlobalInstallableApps operation in AppsApi. */
export type AppsApiGetGlobalInstallableAppsReq = {
};

/** Response parameters for getGlobalInstallableApps operation in AppsApi. */
export type AppsApiGetGlobalInstallableAppsRes = InstallableWebsiteAppsFullListing;


/** Request parameters for getInstallableApps operation in AppsApi. */
export type AppsApiGetInstallableAppsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the subscription. */
    subscriptionId: number
};

/** Response parameters for getInstallableApps operation in AppsApi. */
export type AppsApiGetInstallableAppsRes = InstallableWebsiteAppsFullListing;


/** Request parameters for getWebsiteApps operation in AppsApi. */
export type AppsApiGetWebsiteAppsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteApps operation in AppsApi. */
export type AppsApiGetWebsiteAppsRes = WebsiteAppsFullListing;

/**
 * AppsApi - service client interface
 */
export const AppsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create website applications - Creates a new application for website. Note that if the installed app is WordPress, this endpoint will enable PHP for the website if it isn\'t already. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteApp: async (reqParams: AppsApiCreateWebsiteAppReq, options?: any): Promise<AxiosResponse<AppsApiCreateWebsiteAppRes>> => {
        const localVarAxiosArgs = await AppsApiAxiosParamCreator(configuration).createWebsiteApp(reqParams.orgId, reqParams.websiteId, reqParams.newWebsiteApp, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website app - Deletes an existing website app. Unless `backupBeforeOperation=false` query param is sent, it runs a website backup before deleting the app. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteApp: async (reqParams: AppsApiDeleteWebsiteAppReq, options?: any): Promise<AxiosResponse<AppsApiDeleteWebsiteAppRes>> => {
        const localVarAxiosArgs = await AppsApiAxiosParamCreator(configuration).deleteWebsiteApp(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.backupBeforeOperation, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get all installable applications - Returns all installable applications. Note that what apps can be installed on a website is ultimately decided by customer\'s subscription. See operationId `getInstallableApps`. */
    getGlobalInstallableApps: async (reqParams: AppsApiGetGlobalInstallableAppsReq, options?: any): Promise<AxiosResponse<AppsApiGetGlobalInstallableAppsRes>> => {
        const localVarAxiosArgs = await AppsApiAxiosParamCreator(configuration).getGlobalInstallableApps(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get installable website applications - Returns all installable applications under the subscription. */
    getInstallableApps: async (reqParams: AppsApiGetInstallableAppsReq, options?: any): Promise<AxiosResponse<AppsApiGetInstallableAppsRes>> => {
        const localVarAxiosArgs = await AppsApiAxiosParamCreator(configuration).getInstallableApps(reqParams.orgId, reqParams.subscriptionId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website applications - Returns all applications installed on this website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteApps: async (reqParams: AppsApiGetWebsiteAppsReq, options?: any): Promise<AxiosResponse<AppsApiGetWebsiteAppsRes>> => {
        const localVarAxiosArgs = await AppsApiAxiosParamCreator(configuration).getWebsiteApps(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface AppsApiReq {
    createWebsiteApp: AppsApiCreateWebsiteAppReq;
    deleteWebsiteApp: AppsApiDeleteWebsiteAppReq;
    getGlobalInstallableApps: AppsApiGetGlobalInstallableAppsReq;
    getInstallableApps: AppsApiGetInstallableAppsReq;
    getWebsiteApps: AppsApiGetWebsiteAppsReq;
}

export interface AppsApiRes {
    createWebsiteApp: AppsApiCreateWebsiteAppRes;
    deleteWebsiteApp: AppsApiDeleteWebsiteAppRes;
    getGlobalInstallableApps: AppsApiGetGlobalInstallableAppsRes;
    getInstallableApps: AppsApiGetInstallableAppsRes;
    getWebsiteApps: AppsApiGetWebsiteAppsRes;
}


/**
 * BackupsApi - axios parameter creator
 * @export
 */
export const BackupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create a website backup - Creates a new full website backup. This is a long running operation and the request doesn\'t return until all backups are finished, or an error occurs. If you want to see the backup progress, you can periodically query the backup status via `getWebsiteBackupStatus`. Backups consists of several components, and a component is the smallest granularity of a backup. The components are: backing up of the website\'s home directory, the website\'s mysql databases, and the website\'s emails. The backups may be partial, that is, some out of all backup components may succeeded, but e.g. the backup of the mysql databases may fail. In both cases, the backup snapshot is still created, and the mysql backups are marked as failed for the backup record. Email backups are not done automatically, as they need not be backed up in most cases (due to the expectation that customers would want to backup and then roll back a website after filesystem or database changes, which wouldn\'t concern emails). Doing so redundantly is time consuming so it is skipped by default, but emails may be backed up if the `includeEmails` query parameter is set to true. If the backup fails altogether, this endpoint still returns a 201 Created, but in this case only the metadata in `orchd`\'s db is inserted, no actual backup snapshot is created. This is for information purposes, such a backup, perhaps needless to say, cannot be restored. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        backupWebsite: async (orgId: string, websiteId: string, includeEmails?: boolean, backupOptions?: BackupOptions, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/backups`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (includeEmails !== undefined) {
                localVarQueryParameter['includeEmails'] = includeEmails;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof backupOptions !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(backupOptions !== undefined ? backupOptions : {}) : (backupOptions || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a backup - Deletes a backup. If the backup refers to a (partially) successful backup, both its metadata and the backup snapshot on the backup server will be removed, otherwise just the metadata is removed. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteBackup: async (orgId: string, websiteId: string, backupId: number, storageKind?: BackupStorageKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/backups/{backup_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"backup_id"}}`, encodeURIComponent(String(backupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (storageKind !== undefined) {
                localVarQueryParameter['storageKind'] = storageKind;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get detailed metadata of the website backup - Returns detailed information about the backup. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteBackup: async (orgId: string, websiteId: string, backupId: number, storageKind?: BackupStorageKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/backups/{backup_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"backup_id"}}`, encodeURIComponent(String(backupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (storageKind !== undefined) {
                localVarQueryParameter['storageKind'] = storageKind;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get all website backups metadata - Returns a list of all website backups. This includes the backups that were not successful as well, or backups that were only partially successful (e.g. the home directory was backed up but not the databases). Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteBackups: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/backups`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the last detailed metadata of the restored website backup. - Returns the last detailed information about the restored backup. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteRestoreStatus: async (orgId: string, websiteId: string, backupId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/backups/{backup_id}/restore_status`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"backup_id"}}`, encodeURIComponent(String(backupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Restore website from a backup - Restores the website\'s home directory and MySQL databases, and optionally the website\'s email mailboxes if the `includeEmails` query parameter is set to true. Note that if the website was deleted or any of its entities was deleted through `orchd` (via one or more of the DELETE endpoints), this endpoint does not restore those resources. It may only be used when e.g. one wants to restore the previous state of the mailbox of an existing account (but not a deleted account), or the state of an existing website\'s home directory. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        restoreWebsite: async (orgId: string, websiteId: string, backupId: number, storageKind?: BackupStorageKind, backupRestoreOptions?: BackupRestoreOptions, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/backups/{backup_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"backup_id"}}`, encodeURIComponent(String(backupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (storageKind !== undefined) {
                localVarQueryParameter['storageKind'] = storageKind;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof backupRestoreOptions !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(backupRestoreOptions !== undefined ? backupRestoreOptions : {}) : (backupRestoreOptions || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for backupWebsite operation in BackupsApi. */
export type BackupsApiBackupWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The boolean flag used to include emails in the backup. */
    includeEmails?: boolean
    backupOptions?: BackupOptions
};

/** Response parameters for backupWebsite operation in BackupsApi. */
export type BackupsApiBackupWebsiteRes = Backup;


/** Request parameters for deleteWebsiteBackup operation in BackupsApi. */
export type BackupsApiDeleteWebsiteBackupReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the backup. */
    backupId: number
    /** The storage kind of the requested backup. */
    storageKind?: BackupStorageKind
};

/** Response parameters for deleteWebsiteBackup operation in BackupsApi. */
export type BackupsApiDeleteWebsiteBackupRes = void;


/** Request parameters for getWebsiteBackup operation in BackupsApi. */
export type BackupsApiGetWebsiteBackupReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the backup. */
    backupId: number
    /** The storage kind of the requested backup. */
    storageKind?: BackupStorageKind
};

/** Response parameters for getWebsiteBackup operation in BackupsApi. */
export type BackupsApiGetWebsiteBackupRes = BackupDetailed;


/** Request parameters for getWebsiteBackups operation in BackupsApi. */
export type BackupsApiGetWebsiteBackupsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteBackups operation in BackupsApi. */
export type BackupsApiGetWebsiteBackupsRes = BackupsFullListing;


/** Request parameters for getWebsiteRestoreStatus operation in BackupsApi. */
export type BackupsApiGetWebsiteRestoreStatusReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the backup. */
    backupId: number
};

/** Response parameters for getWebsiteRestoreStatus operation in BackupsApi. */
export type BackupsApiGetWebsiteRestoreStatusRes = RestoreDetailed;


/** Request parameters for restoreWebsite operation in BackupsApi. */
export type BackupsApiRestoreWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the backup. */
    backupId: number
    /** The storage kind of the requested backup. */
    storageKind?: BackupStorageKind
    /** The options used to define what will be restored from the backup snapshot. */
    backupRestoreOptions?: BackupRestoreOptions
};

/** Response parameters for restoreWebsite operation in BackupsApi. */
export type BackupsApiRestoreWebsiteRes = void;

/**
 * BackupsApi - service client interface
 */
export const BackupsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create a website backup - Creates a new full website backup. This is a long running operation and the request doesn\'t return until all backups are finished, or an error occurs. If you want to see the backup progress, you can periodically query the backup status via `getWebsiteBackupStatus`. Backups consists of several components, and a component is the smallest granularity of a backup. The components are: backing up of the website\'s home directory, the website\'s mysql databases, and the website\'s emails. The backups may be partial, that is, some out of all backup components may succeeded, but e.g. the backup of the mysql databases may fail. In both cases, the backup snapshot is still created, and the mysql backups are marked as failed for the backup record. Email backups are not done automatically, as they need not be backed up in most cases (due to the expectation that customers would want to backup and then roll back a website after filesystem or database changes, which wouldn\'t concern emails). Doing so redundantly is time consuming so it is skipped by default, but emails may be backed up if the `includeEmails` query parameter is set to true. If the backup fails altogether, this endpoint still returns a 201 Created, but in this case only the metadata in `orchd`\'s db is inserted, no actual backup snapshot is created. This is for information purposes, such a backup, perhaps needless to say, cannot be restored. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    backupWebsite: async (reqParams: BackupsApiBackupWebsiteReq, options?: any): Promise<AxiosResponse<BackupsApiBackupWebsiteRes>> => {
        const localVarAxiosArgs = await BackupsApiAxiosParamCreator(configuration).backupWebsite(reqParams.orgId, reqParams.websiteId, reqParams.includeEmails, reqParams.backupOptions, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a backup - Deletes a backup. If the backup refers to a (partially) successful backup, both its metadata and the backup snapshot on the backup server will be removed, otherwise just the metadata is removed. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteBackup: async (reqParams: BackupsApiDeleteWebsiteBackupReq, options?: any): Promise<AxiosResponse<BackupsApiDeleteWebsiteBackupRes>> => {
        const localVarAxiosArgs = await BackupsApiAxiosParamCreator(configuration).deleteWebsiteBackup(reqParams.orgId, reqParams.websiteId, reqParams.backupId, reqParams.storageKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get detailed metadata of the website backup - Returns detailed information about the backup. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteBackup: async (reqParams: BackupsApiGetWebsiteBackupReq, options?: any): Promise<AxiosResponse<BackupsApiGetWebsiteBackupRes>> => {
        const localVarAxiosArgs = await BackupsApiAxiosParamCreator(configuration).getWebsiteBackup(reqParams.orgId, reqParams.websiteId, reqParams.backupId, reqParams.storageKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get all website backups metadata - Returns a list of all website backups. This includes the backups that were not successful as well, or backups that were only partially successful (e.g. the home directory was backed up but not the databases). Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteBackups: async (reqParams: BackupsApiGetWebsiteBackupsReq, options?: any): Promise<AxiosResponse<BackupsApiGetWebsiteBackupsRes>> => {
        const localVarAxiosArgs = await BackupsApiAxiosParamCreator(configuration).getWebsiteBackups(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the last detailed metadata of the restored website backup. - Returns the last detailed information about the restored backup. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteRestoreStatus: async (reqParams: BackupsApiGetWebsiteRestoreStatusReq, options?: any): Promise<AxiosResponse<BackupsApiGetWebsiteRestoreStatusRes>> => {
        const localVarAxiosArgs = await BackupsApiAxiosParamCreator(configuration).getWebsiteRestoreStatus(reqParams.orgId, reqParams.websiteId, reqParams.backupId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Restore website from a backup - Restores the website\'s home directory and MySQL databases, and optionally the website\'s email mailboxes if the `includeEmails` query parameter is set to true. Note that if the website was deleted or any of its entities was deleted through `orchd` (via one or more of the DELETE endpoints), this endpoint does not restore those resources. It may only be used when e.g. one wants to restore the previous state of the mailbox of an existing account (but not a deleted account), or the state of an existing website\'s home directory. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    restoreWebsite: async (reqParams: BackupsApiRestoreWebsiteReq, options?: any): Promise<AxiosResponse<BackupsApiRestoreWebsiteRes>> => {
        const localVarAxiosArgs = await BackupsApiAxiosParamCreator(configuration).restoreWebsite(reqParams.orgId, reqParams.websiteId, reqParams.backupId, reqParams.storageKind, reqParams.backupRestoreOptions, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface BackupsApiReq {
    backupWebsite: BackupsApiBackupWebsiteReq;
    deleteWebsiteBackup: BackupsApiDeleteWebsiteBackupReq;
    getWebsiteBackup: BackupsApiGetWebsiteBackupReq;
    getWebsiteBackups: BackupsApiGetWebsiteBackupsReq;
    getWebsiteRestoreStatus: BackupsApiGetWebsiteRestoreStatusReq;
    restoreWebsite: BackupsApiRestoreWebsiteReq;
}

export interface BackupsApiRes {
    backupWebsite: BackupsApiBackupWebsiteRes;
    deleteWebsiteBackup: BackupsApiDeleteWebsiteBackupRes;
    getWebsiteBackup: BackupsApiGetWebsiteBackupRes;
    getWebsiteBackups: BackupsApiGetWebsiteBackupsRes;
    getWebsiteRestoreStatus: BackupsApiGetWebsiteRestoreStatusRes;
    restoreWebsite: BackupsApiRestoreWebsiteRes;
}


/**
 * BrandingApi - axios parameter creator
 * @export
 */
export const BrandingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create branding settings -  */
        createBrandingSettings: async (orgId: string, setting: Array<Setting>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setting !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setting !== undefined ? setting : {}) : (setting || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create reseller name server domain -  */
        createNameServerDomain: async (orgId: string, newDomain: NewDomain, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/name-servers`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDomain !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDomain !== undefined ? newDomain : {}) : (newDomain || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete brand favicon - Removes an existing favicon Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteBrandFavicon: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/favicon`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete brand inverse icon - Deletes the brand\'s icon inverse image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteBrandInverseIcon: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/inverse-icon`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete brand login image - Deletes the reseller\'s brand login image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteBrandLoginImage: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/login-image`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete brand logo - Deletes the brand\'s logo image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteBrandLogo: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/logo`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Remove the specified setting -  */
        deleteBrandingSetting: async (orgId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/settings/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete brand inverse logo - Deletes the brand\'s logo inverse image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteInverseBrandLogo: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/inverse-logo`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete reseller name server domain name -  */
        deleteNameServerDomain: async (orgId: string, domain: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/name-servers/{domain}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain"}}`, encodeURIComponent(String(domain)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get default branding - Returns the branding properties of an organization. This endpoint doesn\'t require authentication so that a loginless user may query the control panel\'s reseller\'s branding. In this case, the request\'s Host HTTP header will be matched against an existing reseller\'s control panel host, and that reseller\'s branding returned. To create a \"control panel host\" for the reseller, the reseller needs to create a control panel website using the website creation endpoint, specifying the control panel flag. For more details, please refer to `createWebsite`. If, however, the user is logged in, the frontend should pass the currently logged in organization\'s id. Based on this, this endpoint will return the branding of that organization\'s realm\'s reseller. This means that if the user is currently logged into a reseller, that reseller\'s id would be passed and its branding would be returned. If the user is logged into a customer organization of a reseller, this endpoint looks up the parent of that customer and returns the branding of the parent. An organization may not have any branding at all, in which case an \"empty branding\" is returned. */
        getBranding: async (orgId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/branding`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the specified setting -  */
        getBrandingSetting: async (orgId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/settings/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get all current settings -  */
        getBrandingSettings: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Fetches the staging domain which is currently set -  */
        getStagingDomain: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/staging-domain`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set brand favicon - Sets the reseller\'s brand favicon, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        setBrandFavicon: async (orgId: string, favicon?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/favicon`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (favicon !== undefined) { 
                localVarFormParams.append('favicon', favicon as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set brand inverse icon - Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        setBrandInverseIcon: async (orgId: string, icon?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/inverse-icon`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (icon !== undefined) { 
                localVarFormParams.append('icon', icon as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set brand inverse logo - As per setBrandLogo except this logo will be displayed where there is a dark background. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        setBrandInverseLogo: async (orgId: string, logo?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/inverse-logo`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set brand login image - Sets the reseller\'s brand login image, overwriting any previous one if one exists. The max allowed size is 2 MiB. The image format may be jpeg, png, and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        setBrandLoginImage: async (orgId: string, loginImage?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/login-image`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (loginImage !== undefined) { 
                localVarFormParams.append('loginImage', loginImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set brand logo - Sets the reseller\'s brand logo, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, svg and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        setBrandLogo: async (orgId: string, logo?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/logo`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set or update domain suffix for staging websites created by customers -  */
        setStagingDomain: async (orgId: string, stagingDomain: StagingDomain, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/staging-domain`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stagingDomain !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stagingDomain !== undefined ? stagingDomain : {}) : (stagingDomain || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create or update the specified setting -  */
        updateBrandingSetting: async (orgId: string, name: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/branding/settings/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update reseller name server domain name -  */
        updateNameServerDomain: async (orgId: string, domain: string, newDomain: NewDomain, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/name-servers/{domain}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain"}}`, encodeURIComponent(String(domain)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDomain !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDomain !== undefined ? newDomain : {}) : (newDomain || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createBrandingSettings operation in BrandingApi. */
export type BrandingApiCreateBrandingSettingsReq = {
    /** The id of the organization. */
    orgId: string
    setting: Array<Setting>
};

/** Response parameters for createBrandingSettings operation in BrandingApi. */
export type BrandingApiCreateBrandingSettingsRes = void;


/** Request parameters for createNameServerDomain operation in BrandingApi. */
export type BrandingApiCreateNameServerDomainReq = {
    /** The id of the organization. */
    orgId: string
    newDomain: NewDomain
};

/** Response parameters for createNameServerDomain operation in BrandingApi. */
export type BrandingApiCreateNameServerDomainRes = void;


/** Request parameters for deleteBrandFavicon operation in BrandingApi. */
export type BrandingApiDeleteBrandFaviconReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteBrandFavicon operation in BrandingApi. */
export type BrandingApiDeleteBrandFaviconRes = void;


/** Request parameters for deleteBrandInverseIcon operation in BrandingApi. */
export type BrandingApiDeleteBrandInverseIconReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteBrandInverseIcon operation in BrandingApi. */
export type BrandingApiDeleteBrandInverseIconRes = void;


/** Request parameters for deleteBrandLoginImage operation in BrandingApi. */
export type BrandingApiDeleteBrandLoginImageReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteBrandLoginImage operation in BrandingApi. */
export type BrandingApiDeleteBrandLoginImageRes = void;


/** Request parameters for deleteBrandLogo operation in BrandingApi. */
export type BrandingApiDeleteBrandLogoReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteBrandLogo operation in BrandingApi. */
export type BrandingApiDeleteBrandLogoRes = void;


/** Request parameters for deleteBrandingSetting operation in BrandingApi. */
export type BrandingApiDeleteBrandingSettingReq = {
    /** The id of the organization. */
    orgId: string
    /** The name of the resource. */
    name: string
};

/** Response parameters for deleteBrandingSetting operation in BrandingApi. */
export type BrandingApiDeleteBrandingSettingRes = void;


/** Request parameters for deleteInverseBrandLogo operation in BrandingApi. */
export type BrandingApiDeleteInverseBrandLogoReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteInverseBrandLogo operation in BrandingApi. */
export type BrandingApiDeleteInverseBrandLogoRes = void;


/** Request parameters for deleteNameServerDomain operation in BrandingApi. */
export type BrandingApiDeleteNameServerDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The domain name. */
    domain: string
};

/** Response parameters for deleteNameServerDomain operation in BrandingApi. */
export type BrandingApiDeleteNameServerDomainRes = void;


/** Request parameters for getBranding operation in BrandingApi. */
export type BrandingApiGetBrandingReq = {
    /** The id of the currently logged in organization. */
    orgId?: string
};

/** Response parameters for getBranding operation in BrandingApi. */
export type BrandingApiGetBrandingRes = Branding;


/** Request parameters for getBrandingSetting operation in BrandingApi. */
export type BrandingApiGetBrandingSettingReq = {
    /** The id of the organization. */
    orgId: string
    /** The name of the resource. */
    name: string
};

/** Response parameters for getBrandingSetting operation in BrandingApi. */
export type BrandingApiGetBrandingSettingRes = Setting;


/** Request parameters for getBrandingSettings operation in BrandingApi. */
export type BrandingApiGetBrandingSettingsReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getBrandingSettings operation in BrandingApi. */
export type BrandingApiGetBrandingSettingsRes = SettingsFullListing;


/** Request parameters for getStagingDomain operation in BrandingApi. */
export type BrandingApiGetStagingDomainReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getStagingDomain operation in BrandingApi. */
export type BrandingApiGetStagingDomainRes = StagingDomain;


/** Request parameters for setBrandFavicon operation in BrandingApi. */
export type BrandingApiSetBrandFaviconReq = {
    /** The id of the organization. */
    orgId: string
    favicon?: any
};

/** Response parameters for setBrandFavicon operation in BrandingApi. */
export type BrandingApiSetBrandFaviconRes = void;


/** Request parameters for setBrandInverseIcon operation in BrandingApi. */
export type BrandingApiSetBrandInverseIconReq = {
    /** The id of the organization. */
    orgId: string
    icon?: any
};

/** Response parameters for setBrandInverseIcon operation in BrandingApi. */
export type BrandingApiSetBrandInverseIconRes = void;


/** Request parameters for setBrandInverseLogo operation in BrandingApi. */
export type BrandingApiSetBrandInverseLogoReq = {
    /** The id of the organization. */
    orgId: string
    logo?: any
};

/** Response parameters for setBrandInverseLogo operation in BrandingApi. */
export type BrandingApiSetBrandInverseLogoRes = void;


/** Request parameters for setBrandLoginImage operation in BrandingApi. */
export type BrandingApiSetBrandLoginImageReq = {
    /** The id of the organization. */
    orgId: string
    loginImage?: any
};

/** Response parameters for setBrandLoginImage operation in BrandingApi. */
export type BrandingApiSetBrandLoginImageRes = void;


/** Request parameters for setBrandLogo operation in BrandingApi. */
export type BrandingApiSetBrandLogoReq = {
    /** The id of the organization. */
    orgId: string
    logo?: any
};

/** Response parameters for setBrandLogo operation in BrandingApi. */
export type BrandingApiSetBrandLogoRes = void;


/** Request parameters for setStagingDomain operation in BrandingApi. */
export type BrandingApiSetStagingDomainReq = {
    /** The id of the organization. */
    orgId: string
    stagingDomain: StagingDomain
};

/** Response parameters for setStagingDomain operation in BrandingApi. */
export type BrandingApiSetStagingDomainRes = void;


/** Request parameters for updateBrandingSetting operation in BrandingApi. */
export type BrandingApiUpdateBrandingSettingReq = {
    /** The id of the organization. */
    orgId: string
    /** The name of the resource. */
    name: string
    body: string
};

/** Response parameters for updateBrandingSetting operation in BrandingApi. */
export type BrandingApiUpdateBrandingSettingRes = void;


/** Request parameters for updateNameServerDomain operation in BrandingApi. */
export type BrandingApiUpdateNameServerDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The domain name. */
    domain: string
    newDomain: NewDomain
};

/** Response parameters for updateNameServerDomain operation in BrandingApi. */
export type BrandingApiUpdateNameServerDomainRes = void;

/**
 * BrandingApi - service client interface
 */
export const BrandingApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create branding settings -  */
    createBrandingSettings: async (reqParams: BrandingApiCreateBrandingSettingsReq, options?: any): Promise<AxiosResponse<BrandingApiCreateBrandingSettingsRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).createBrandingSettings(reqParams.orgId, reqParams.setting, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create reseller name server domain -  */
    createNameServerDomain: async (reqParams: BrandingApiCreateNameServerDomainReq, options?: any): Promise<AxiosResponse<BrandingApiCreateNameServerDomainRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).createNameServerDomain(reqParams.orgId, reqParams.newDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete brand favicon - Removes an existing favicon Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteBrandFavicon: async (reqParams: BrandingApiDeleteBrandFaviconReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteBrandFaviconRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteBrandFavicon(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete brand inverse icon - Deletes the brand\'s icon inverse image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteBrandInverseIcon: async (reqParams: BrandingApiDeleteBrandInverseIconReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteBrandInverseIconRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteBrandInverseIcon(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete brand login image - Deletes the reseller\'s brand login image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteBrandLoginImage: async (reqParams: BrandingApiDeleteBrandLoginImageReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteBrandLoginImageRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteBrandLoginImage(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete brand logo - Deletes the brand\'s logo image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteBrandLogo: async (reqParams: BrandingApiDeleteBrandLogoReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteBrandLogoRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteBrandLogo(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Remove the specified setting -  */
    deleteBrandingSetting: async (reqParams: BrandingApiDeleteBrandingSettingReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteBrandingSettingRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteBrandingSetting(reqParams.orgId, reqParams.name, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete brand inverse logo - Deletes the brand\'s logo inverse image Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteInverseBrandLogo: async (reqParams: BrandingApiDeleteInverseBrandLogoReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteInverseBrandLogoRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteInverseBrandLogo(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete reseller name server domain name -  */
    deleteNameServerDomain: async (reqParams: BrandingApiDeleteNameServerDomainReq, options?: any): Promise<AxiosResponse<BrandingApiDeleteNameServerDomainRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).deleteNameServerDomain(reqParams.orgId, reqParams.domain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get default branding - Returns the branding properties of an organization. This endpoint doesn\'t require authentication so that a loginless user may query the control panel\'s reseller\'s branding. In this case, the request\'s Host HTTP header will be matched against an existing reseller\'s control panel host, and that reseller\'s branding returned. To create a \"control panel host\" for the reseller, the reseller needs to create a control panel website using the website creation endpoint, specifying the control panel flag. For more details, please refer to `createWebsite`. If, however, the user is logged in, the frontend should pass the currently logged in organization\'s id. Based on this, this endpoint will return the branding of that organization\'s realm\'s reseller. This means that if the user is currently logged into a reseller, that reseller\'s id would be passed and its branding would be returned. If the user is logged into a customer organization of a reseller, this endpoint looks up the parent of that customer and returns the branding of the parent. An organization may not have any branding at all, in which case an \"empty branding\" is returned. */
    getBranding: async (reqParams: BrandingApiGetBrandingReq, options?: any): Promise<AxiosResponse<BrandingApiGetBrandingRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).getBranding(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the specified setting -  */
    getBrandingSetting: async (reqParams: BrandingApiGetBrandingSettingReq, options?: any): Promise<AxiosResponse<BrandingApiGetBrandingSettingRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).getBrandingSetting(reqParams.orgId, reqParams.name, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get all current settings -  */
    getBrandingSettings: async (reqParams: BrandingApiGetBrandingSettingsReq, options?: any): Promise<AxiosResponse<BrandingApiGetBrandingSettingsRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).getBrandingSettings(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Fetches the staging domain which is currently set -  */
    getStagingDomain: async (reqParams: BrandingApiGetStagingDomainReq, options?: any): Promise<AxiosResponse<BrandingApiGetStagingDomainRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).getStagingDomain(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set brand favicon - Sets the reseller\'s brand favicon, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    setBrandFavicon: async (reqParams: BrandingApiSetBrandFaviconReq, options?: any): Promise<AxiosResponse<BrandingApiSetBrandFaviconRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).setBrandFavicon(reqParams.orgId, reqParams.favicon, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set brand inverse icon - Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    setBrandInverseIcon: async (reqParams: BrandingApiSetBrandInverseIconReq, options?: any): Promise<AxiosResponse<BrandingApiSetBrandInverseIconRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).setBrandInverseIcon(reqParams.orgId, reqParams.icon, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set brand inverse logo - As per setBrandLogo except this logo will be displayed where there is a dark background. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    setBrandInverseLogo: async (reqParams: BrandingApiSetBrandInverseLogoReq, options?: any): Promise<AxiosResponse<BrandingApiSetBrandInverseLogoRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).setBrandInverseLogo(reqParams.orgId, reqParams.logo, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set brand login image - Sets the reseller\'s brand login image, overwriting any previous one if one exists. The max allowed size is 2 MiB. The image format may be jpeg, png, and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    setBrandLoginImage: async (reqParams: BrandingApiSetBrandLoginImageReq, options?: any): Promise<AxiosResponse<BrandingApiSetBrandLoginImageRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).setBrandLoginImage(reqParams.orgId, reqParams.loginImage, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set brand logo - Sets the reseller\'s brand logo, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, svg and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    setBrandLogo: async (reqParams: BrandingApiSetBrandLogoReq, options?: any): Promise<AxiosResponse<BrandingApiSetBrandLogoRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).setBrandLogo(reqParams.orgId, reqParams.logo, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set or update domain suffix for staging websites created by customers -  */
    setStagingDomain: async (reqParams: BrandingApiSetStagingDomainReq, options?: any): Promise<AxiosResponse<BrandingApiSetStagingDomainRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).setStagingDomain(reqParams.orgId, reqParams.stagingDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create or update the specified setting -  */
    updateBrandingSetting: async (reqParams: BrandingApiUpdateBrandingSettingReq, options?: any): Promise<AxiosResponse<BrandingApiUpdateBrandingSettingRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).updateBrandingSetting(reqParams.orgId, reqParams.name, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update reseller name server domain name -  */
    updateNameServerDomain: async (reqParams: BrandingApiUpdateNameServerDomainReq, options?: any): Promise<AxiosResponse<BrandingApiUpdateNameServerDomainRes>> => {
        const localVarAxiosArgs = await BrandingApiAxiosParamCreator(configuration).updateNameServerDomain(reqParams.orgId, reqParams.domain, reqParams.newDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface BrandingApiReq {
    createBrandingSettings: BrandingApiCreateBrandingSettingsReq;
    createNameServerDomain: BrandingApiCreateNameServerDomainReq;
    deleteBrandFavicon: BrandingApiDeleteBrandFaviconReq;
    deleteBrandInverseIcon: BrandingApiDeleteBrandInverseIconReq;
    deleteBrandLoginImage: BrandingApiDeleteBrandLoginImageReq;
    deleteBrandLogo: BrandingApiDeleteBrandLogoReq;
    deleteBrandingSetting: BrandingApiDeleteBrandingSettingReq;
    deleteInverseBrandLogo: BrandingApiDeleteInverseBrandLogoReq;
    deleteNameServerDomain: BrandingApiDeleteNameServerDomainReq;
    getBranding: BrandingApiGetBrandingReq;
    getBrandingSetting: BrandingApiGetBrandingSettingReq;
    getBrandingSettings: BrandingApiGetBrandingSettingsReq;
    getStagingDomain: BrandingApiGetStagingDomainReq;
    setBrandFavicon: BrandingApiSetBrandFaviconReq;
    setBrandInverseIcon: BrandingApiSetBrandInverseIconReq;
    setBrandInverseLogo: BrandingApiSetBrandInverseLogoReq;
    setBrandLoginImage: BrandingApiSetBrandLoginImageReq;
    setBrandLogo: BrandingApiSetBrandLogoReq;
    setStagingDomain: BrandingApiSetStagingDomainReq;
    updateBrandingSetting: BrandingApiUpdateBrandingSettingReq;
    updateNameServerDomain: BrandingApiUpdateNameServerDomainReq;
}

export interface BrandingApiRes {
    createBrandingSettings: BrandingApiCreateBrandingSettingsRes;
    createNameServerDomain: BrandingApiCreateNameServerDomainRes;
    deleteBrandFavicon: BrandingApiDeleteBrandFaviconRes;
    deleteBrandInverseIcon: BrandingApiDeleteBrandInverseIconRes;
    deleteBrandLoginImage: BrandingApiDeleteBrandLoginImageRes;
    deleteBrandLogo: BrandingApiDeleteBrandLogoRes;
    deleteBrandingSetting: BrandingApiDeleteBrandingSettingRes;
    deleteInverseBrandLogo: BrandingApiDeleteInverseBrandLogoRes;
    deleteNameServerDomain: BrandingApiDeleteNameServerDomainRes;
    getBranding: BrandingApiGetBrandingRes;
    getBrandingSetting: BrandingApiGetBrandingSettingRes;
    getBrandingSettings: BrandingApiGetBrandingSettingsRes;
    getStagingDomain: BrandingApiGetStagingDomainRes;
    setBrandFavicon: BrandingApiSetBrandFaviconRes;
    setBrandInverseIcon: BrandingApiSetBrandInverseIconRes;
    setBrandInverseLogo: BrandingApiSetBrandInverseLogoRes;
    setBrandLoginImage: BrandingApiSetBrandLoginImageRes;
    setBrandLogo: BrandingApiSetBrandLogoRes;
    setStagingDomain: BrandingApiSetStagingDomainRes;
    updateBrandingSetting: BrandingApiUpdateBrandingSettingRes;
    updateNameServerDomain: BrandingApiUpdateNameServerDomainRes;
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create a customer organization - Once customer is successfully created under parent organization (identified by org_id), the customer organization\'s id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        createCustomer: async (orgId: string, newCustomer: NewCustomer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newCustomer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newCustomer !== undefined ? newCustomer : {}) : (newCustomer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a subscriptions for a customer - Creates a subscription for customer to the org\'s plan. Only a reseller org or the MO may subscribe another org to one of its plans. If the organization is a reseller (and thus not the MO), it needs to have a suitable subscription to a reseller plan of its parent. It is verified that the reseller\'s reseller subscription has quota left to create the new subscription (because the new subscription draws from the reseller\'s reseller subscription). After this call, the sold resources are recorded in the reseller subscription by increasing each sold resource\'s usage by the sold amount. In combination with the quota check, this is how it is ensured that the reseller doesn\'t sell more resources than it has paid for. The MO may optionally override the package default servers or server group. All resources of this subscription will then be created on those servers. Otherwise the subscribed to plan\'s servers are used, or if those aren\'t defined either, the usual website placement rules are used every time a website is created under this subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createCustomerSubscription: async (orgId: string, customerOrgId: string, newSubscription: NewSubscription, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers/{customer_org_id}/subscriptions`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"customer_org_id"}}`, encodeURIComponent(String(customerOrgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newSubscription !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newSubscription !== undefined ? newSubscription : {}) : (newSubscription || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get customer subscriptions - Lists a customer\'s subscriptions to packages belonging to the selected organization. */
        getCustomerSubscriptions: async (orgId: string, customerOrgId: string, offset?: number, limit?: number, sortBy?: 'plan' | 'subscriberId' | 'vendorId', sortOrder?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers/{customer_org_id}/subscriptions`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"customer_org_id"}}`, encodeURIComponent(String(customerOrgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization customers - Returns the list of customers of a reseller organization. If the recursive flag is set, the customers of reseller customers are returned as well recursively, up to an optional max depth value. Note, for performance it is not checked whether org is a reseller. If it is not, an empty list is returned. */
        getOrgCustomers: async (orgId: string, offset?: number, limit?: number, sortBy?: 'id' | 'name' | 'status' | 'subscriptionsCount' | 'websitesCount' | 'createdAt', sortOrder?: 'asc' | 'desc', recursive?: boolean, maxDepth?: number, status?: 'active' | 'suspended' | 'deleted', planId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['maxDepth'] = maxDepth;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createCustomer operation in CustomersApi. */
export type CustomersApiCreateCustomerReq = {
    /** The id of the organization. */
    orgId: string
    /** Customer organization details. */
    newCustomer: NewCustomer
};

/** Response parameters for createCustomer operation in CustomersApi. */
export type CustomersApiCreateCustomerRes = NewResourceUuid;


/** Request parameters for createCustomerSubscription operation in CustomersApi. */
export type CustomersApiCreateCustomerSubscriptionReq = {
    /** The id of the organization. */
    orgId: string
    /** The organization id of the organization\'s customer. */
    customerOrgId: string
    /** Subscription details. */
    newSubscription: NewSubscription
};

/** Response parameters for createCustomerSubscription operation in CustomersApi. */
export type CustomersApiCreateCustomerSubscriptionRes = NewResourceId;


/** Request parameters for getCustomerSubscriptions operation in CustomersApi. */
export type CustomersApiGetCustomerSubscriptionsReq = {
    /** The id of the organization. */
    orgId: string
    /** The organization id of the organization\'s customer. */
    customerOrgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'plan' | 'subscriberId' | 'vendorId'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
};

/** Response parameters for getCustomerSubscriptions operation in CustomersApi. */
export type CustomersApiGetCustomerSubscriptionsRes = SubscriptionsListing;


/** Request parameters for getOrgCustomers operation in CustomersApi. */
export type CustomersApiGetOrgCustomersReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'id' | 'name' | 'status' | 'subscriptionsCount' | 'websitesCount' | 'createdAt'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** If set to true, the endpoint will return resources in some hierarchy recursively, that is, several or all levels of the hierarchy, depending on whether `maxDepth` is set. E.g. for customers this means direct and indirect customers are returned. For websites, this returns websites of all direct and indirect customers. */
    recursive?: boolean
    /** If recursive is set to true, this can be specified to limit the recursion depth. By default there is no recursion bound. */
    maxDepth?: number
    /** Filters the customers list by its status. */
    status?: 'active' | 'suspended' | 'deleted'
    /** Limit the result set to resources under subscriptions to the plan. */
    planId?: number
};

/** Response parameters for getOrgCustomers operation in CustomersApi. */
export type CustomersApiGetOrgCustomersRes = CustomersListing;

/**
 * CustomersApi - service client interface
 */
export const CustomersApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create a customer organization - Once customer is successfully created under parent organization (identified by org_id), the customer organization\'s id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    createCustomer: async (reqParams: CustomersApiCreateCustomerReq, options?: any): Promise<AxiosResponse<CustomersApiCreateCustomerRes>> => {
        const localVarAxiosArgs = await CustomersApiAxiosParamCreator(configuration).createCustomer(reqParams.orgId, reqParams.newCustomer, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a subscriptions for a customer - Creates a subscription for customer to the org\'s plan. Only a reseller org or the MO may subscribe another org to one of its plans. If the organization is a reseller (and thus not the MO), it needs to have a suitable subscription to a reseller plan of its parent. It is verified that the reseller\'s reseller subscription has quota left to create the new subscription (because the new subscription draws from the reseller\'s reseller subscription). After this call, the sold resources are recorded in the reseller subscription by increasing each sold resource\'s usage by the sold amount. In combination with the quota check, this is how it is ensured that the reseller doesn\'t sell more resources than it has paid for. The MO may optionally override the package default servers or server group. All resources of this subscription will then be created on those servers. Otherwise the subscribed to plan\'s servers are used, or if those aren\'t defined either, the usual website placement rules are used every time a website is created under this subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createCustomerSubscription: async (reqParams: CustomersApiCreateCustomerSubscriptionReq, options?: any): Promise<AxiosResponse<CustomersApiCreateCustomerSubscriptionRes>> => {
        const localVarAxiosArgs = await CustomersApiAxiosParamCreator(configuration).createCustomerSubscription(reqParams.orgId, reqParams.customerOrgId, reqParams.newSubscription, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get customer subscriptions - Lists a customer\'s subscriptions to packages belonging to the selected organization. */
    getCustomerSubscriptions: async (reqParams: CustomersApiGetCustomerSubscriptionsReq, options?: any): Promise<AxiosResponse<CustomersApiGetCustomerSubscriptionsRes>> => {
        const localVarAxiosArgs = await CustomersApiAxiosParamCreator(configuration).getCustomerSubscriptions(reqParams.orgId, reqParams.customerOrgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization customers - Returns the list of customers of a reseller organization. If the recursive flag is set, the customers of reseller customers are returned as well recursively, up to an optional max depth value. Note, for performance it is not checked whether org is a reseller. If it is not, an empty list is returned. */
    getOrgCustomers: async (reqParams: CustomersApiGetOrgCustomersReq, options?: any): Promise<AxiosResponse<CustomersApiGetOrgCustomersRes>> => {
        const localVarAxiosArgs = await CustomersApiAxiosParamCreator(configuration).getOrgCustomers(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.recursive, reqParams.maxDepth, reqParams.status, reqParams.planId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface CustomersApiReq {
    createCustomer: CustomersApiCreateCustomerReq;
    createCustomerSubscription: CustomersApiCreateCustomerSubscriptionReq;
    getCustomerSubscriptions: CustomersApiGetCustomerSubscriptionsReq;
    getOrgCustomers: CustomersApiGetOrgCustomersReq;
}

export interface CustomersApiRes {
    createCustomer: CustomersApiCreateCustomerRes;
    createCustomerSubscription: CustomersApiCreateCustomerSubscriptionRes;
    getCustomerSubscriptions: CustomersApiGetCustomerSubscriptionsRes;
    getOrgCustomers: CustomersApiGetOrgCustomersRes;
}


/**
 * DnsApi - axios parameter creator
 * @export
 */
export const DnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create a default DNS record - Creates a default record at a platform level which will be added to all newly created DNS zones.  In the value you can use $$origin$$ which will be substituted for the origin domain. */
        createDefaultDnsRecord: async (newDefaultDnsRecord: NewDefaultDnsRecord, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/dns/default-records`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDefaultDnsRecord !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDefaultDnsRecord !== undefined ? newDefaultDnsRecord : {}) : (newDefaultDnsRecord || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create new third party provider. - This operation can only be done by an MO admin. Third party providers are notified about changes to dns zones within Enhance. This endpoint creates a new provider which is going to be notified on provided URL about dns updates. Please not that after adding a new provider using this endpoint, the provider will initially receive a request to delete all its current data and then Enhance will send a request with all existing dns zones. */
        createDnsThirdPartyProvider: async (newDnsThirdPartyProvider: NewDnsThirdPartyProvider, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dns/third-party-providers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDnsThirdPartyProvider !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDnsThirdPartyProvider !== undefined ? newDnsThirdPartyProvider : {}) : (newDnsThirdPartyProvider || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a new dns record for website domain - Creates a new dns record for a website\'s domain dns zone. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteDomainDnsZoneRecord: async (orgId: string, websiteId: string, domainId: string, newDnsRecord: NewDnsRecord, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone/records`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDnsRecord !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDnsRecord !== undefined ? newDnsRecord : {}) : (newDnsRecord || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a default DNS record - Delete a default DNS record.  Will not remove from existing zones. */
        deleteDefaultDnsRecord: async (recordId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/dns/default-records/{record_id}`
                .replace(`{${"record_id"}}`, encodeURIComponent(String(recordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes a third party dns provider. - This operation can only be done by an MO admin. Third party providers are notified about changes to dns zones within Enhance. This endpoint removes an existing provider. After this endpoint resolves, no new dns zones are going to be replicated to the provider. */
        deleteDnsThirdPartyProvider: async (providerId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dns/third-party-providers/{provider_id}`
                .replace(`{${"provider_id"}}`, encodeURIComponent(String(providerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete dns zone record - Deletes a dns zone record for given domain. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteDomainDnsZoneRecord: async (orgId: string, websiteId: string, domainId: string, recordId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone/records/{record_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)))
                .replace(`{${"record_id"}}`, encodeURIComponent(String(recordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Disable DNSSEC on this domain - Will disable DNSSEC on this domain.  The DS records must be removed from the upstream DNS provider first to avoid downtime. */
        disableDomainDnsSec: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone/dnssec`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Enable DNSSEC on this domain - Will enable DNSSEC and return the relevant DS records */
        enableDomainDnsSec: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone/dnssec`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Lists all third party providers. - This operation can only be done by an MO admin. Third party providers are notified about changes to dns zones within Enhance. This endpoint lists all registered URLs which listen to these changes. */
        getDnsThirdPartyProviders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dns/third-party-providers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get a dns zone for given domain - Returns details about the dns zone of given domain. Returns Soa record and all other records. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteDomainDnsZone: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** List default DNS records - Lists the DNS records which will be added to all newly created DNS zones. */
        listDefaultDnsRecords: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/dns/default-records`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update a default DNS record - Updates a default DNS record, all fields are optional. */
        updateDefaultDnsRecord: async (recordId: string, updateDefaultDnsRecord: UpdateDefaultDnsRecord, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/dns/default-records/{record_id}`
                .replace(`{${"record_id"}}`, encodeURIComponent(String(recordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateDefaultDnsRecord !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateDefaultDnsRecord !== undefined ? updateDefaultDnsRecord : {}) : (updateDefaultDnsRecord || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates a dns zone SOA for website domain - Partially updates dns zone SOA record for existing zone. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWebsiteDomainDnsZone: async (orgId: string, websiteId: string, domainId: string, updateDnsZone: UpdateDnsZone, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateDnsZone !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateDnsZone !== undefined ? updateDnsZone : {}) : (updateDnsZone || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates a dns record for given domain - Partially updates a dns record for existing zone. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWebsiteDomainDnsZoneRecord: async (orgId: string, websiteId: string, domainId: string, recordId: string, updateDnsRecord: UpdateDnsRecord, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-zone/records/{record_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)))
                .replace(`{${"record_id"}}`, encodeURIComponent(String(recordId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateDnsRecord !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateDnsRecord !== undefined ? updateDnsRecord : {}) : (updateDnsRecord || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createDefaultDnsRecord operation in DnsApi. */
export type DnsApiCreateDefaultDnsRecordReq = {
    newDefaultDnsRecord: NewDefaultDnsRecord
};

/** Response parameters for createDefaultDnsRecord operation in DnsApi. */
export type DnsApiCreateDefaultDnsRecordRes = string;


/** Request parameters for createDnsThirdPartyProvider operation in DnsApi. */
export type DnsApiCreateDnsThirdPartyProviderReq = {
    /** Url where the updates are sent and map of header names to their values. */
    newDnsThirdPartyProvider: NewDnsThirdPartyProvider
};

/** Response parameters for createDnsThirdPartyProvider operation in DnsApi. */
export type DnsApiCreateDnsThirdPartyProviderRes = void;


/** Request parameters for createWebsiteDomainDnsZoneRecord operation in DnsApi. */
export type DnsApiCreateWebsiteDomainDnsZoneRecordReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** New dns record details. */
    newDnsRecord: NewDnsRecord
};

/** Response parameters for createWebsiteDomainDnsZoneRecord operation in DnsApi. */
export type DnsApiCreateWebsiteDomainDnsZoneRecordRes = NewResourceUuid;


/** Request parameters for deleteDefaultDnsRecord operation in DnsApi. */
export type DnsApiDeleteDefaultDnsRecordReq = {
    recordId: string
};

/** Response parameters for deleteDefaultDnsRecord operation in DnsApi. */
export type DnsApiDeleteDefaultDnsRecordRes = void;


/** Request parameters for deleteDnsThirdPartyProvider operation in DnsApi. */
export type DnsApiDeleteDnsThirdPartyProviderReq = {
    /** The id of the third party provider which can be obtained by querying the GET /dns/third-party-providers endpoint. */
    providerId: number
};

/** Response parameters for deleteDnsThirdPartyProvider operation in DnsApi. */
export type DnsApiDeleteDnsThirdPartyProviderRes = void;


/** Request parameters for deleteWebsiteDomainDnsZoneRecord operation in DnsApi. */
export type DnsApiDeleteWebsiteDomainDnsZoneRecordReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** The id of the record. */
    recordId: string
};

/** Response parameters for deleteWebsiteDomainDnsZoneRecord operation in DnsApi. */
export type DnsApiDeleteWebsiteDomainDnsZoneRecordRes = void;


/** Request parameters for disableDomainDnsSec operation in DnsApi. */
export type DnsApiDisableDomainDnsSecReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for disableDomainDnsSec operation in DnsApi. */
export type DnsApiDisableDomainDnsSecRes = void;


/** Request parameters for enableDomainDnsSec operation in DnsApi. */
export type DnsApiEnableDomainDnsSecReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for enableDomainDnsSec operation in DnsApi. */
export type DnsApiEnableDomainDnsSecRes = string;


/** Request parameters for getDnsThirdPartyProviders operation in DnsApi. */
export type DnsApiGetDnsThirdPartyProvidersReq = {
};

/** Response parameters for getDnsThirdPartyProviders operation in DnsApi. */
export type DnsApiGetDnsThirdPartyProvidersRes = Array<DnsThirdPartyProvider>;


/** Request parameters for getWebsiteDomainDnsZone operation in DnsApi. */
export type DnsApiGetWebsiteDomainDnsZoneReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainDnsZone operation in DnsApi. */
export type DnsApiGetWebsiteDomainDnsZoneRes = DnsZone;


/** Request parameters for listDefaultDnsRecords operation in DnsApi. */
export type DnsApiListDefaultDnsRecordsReq = {
};

/** Response parameters for listDefaultDnsRecords operation in DnsApi. */
export type DnsApiListDefaultDnsRecordsRes = Array<DefaultDnsRecord>;


/** Request parameters for updateDefaultDnsRecord operation in DnsApi. */
export type DnsApiUpdateDefaultDnsRecordReq = {
    recordId: string
    updateDefaultDnsRecord: UpdateDefaultDnsRecord
};

/** Response parameters for updateDefaultDnsRecord operation in DnsApi. */
export type DnsApiUpdateDefaultDnsRecordRes = void;


/** Request parameters for updateWebsiteDomainDnsZone operation in DnsApi. */
export type DnsApiUpdateWebsiteDomainDnsZoneReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** Fields to update. */
    updateDnsZone: UpdateDnsZone
};

/** Response parameters for updateWebsiteDomainDnsZone operation in DnsApi. */
export type DnsApiUpdateWebsiteDomainDnsZoneRes = void;


/** Request parameters for updateWebsiteDomainDnsZoneRecord operation in DnsApi. */
export type DnsApiUpdateWebsiteDomainDnsZoneRecordReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** The id of the record. */
    recordId: string
    /** Fields to update. */
    updateDnsRecord: UpdateDnsRecord
};

/** Response parameters for updateWebsiteDomainDnsZoneRecord operation in DnsApi. */
export type DnsApiUpdateWebsiteDomainDnsZoneRecordRes = void;

/**
 * DnsApi - service client interface
 */
export const DnsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create a default DNS record - Creates a default record at a platform level which will be added to all newly created DNS zones.  In the value you can use $$origin$$ which will be substituted for the origin domain. */
    createDefaultDnsRecord: async (reqParams: DnsApiCreateDefaultDnsRecordReq, options?: any): Promise<AxiosResponse<DnsApiCreateDefaultDnsRecordRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).createDefaultDnsRecord(reqParams.newDefaultDnsRecord, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create new third party provider. - This operation can only be done by an MO admin. Third party providers are notified about changes to dns zones within Enhance. This endpoint creates a new provider which is going to be notified on provided URL about dns updates. Please not that after adding a new provider using this endpoint, the provider will initially receive a request to delete all its current data and then Enhance will send a request with all existing dns zones. */
    createDnsThirdPartyProvider: async (reqParams: DnsApiCreateDnsThirdPartyProviderReq, options?: any): Promise<AxiosResponse<DnsApiCreateDnsThirdPartyProviderRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).createDnsThirdPartyProvider(reqParams.newDnsThirdPartyProvider, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a new dns record for website domain - Creates a new dns record for a website\'s domain dns zone. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteDomainDnsZoneRecord: async (reqParams: DnsApiCreateWebsiteDomainDnsZoneRecordReq, options?: any): Promise<AxiosResponse<DnsApiCreateWebsiteDomainDnsZoneRecordRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).createWebsiteDomainDnsZoneRecord(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.newDnsRecord, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a default DNS record - Delete a default DNS record.  Will not remove from existing zones. */
    deleteDefaultDnsRecord: async (reqParams: DnsApiDeleteDefaultDnsRecordReq, options?: any): Promise<AxiosResponse<DnsApiDeleteDefaultDnsRecordRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).deleteDefaultDnsRecord(reqParams.recordId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes a third party dns provider. - This operation can only be done by an MO admin. Third party providers are notified about changes to dns zones within Enhance. This endpoint removes an existing provider. After this endpoint resolves, no new dns zones are going to be replicated to the provider. */
    deleteDnsThirdPartyProvider: async (reqParams: DnsApiDeleteDnsThirdPartyProviderReq, options?: any): Promise<AxiosResponse<DnsApiDeleteDnsThirdPartyProviderRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).deleteDnsThirdPartyProvider(reqParams.providerId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete dns zone record - Deletes a dns zone record for given domain. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteDomainDnsZoneRecord: async (reqParams: DnsApiDeleteWebsiteDomainDnsZoneRecordReq, options?: any): Promise<AxiosResponse<DnsApiDeleteWebsiteDomainDnsZoneRecordRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).deleteWebsiteDomainDnsZoneRecord(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.recordId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Disable DNSSEC on this domain - Will disable DNSSEC on this domain.  The DS records must be removed from the upstream DNS provider first to avoid downtime. */
    disableDomainDnsSec: async (reqParams: DnsApiDisableDomainDnsSecReq, options?: any): Promise<AxiosResponse<DnsApiDisableDomainDnsSecRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).disableDomainDnsSec(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Enable DNSSEC on this domain - Will enable DNSSEC and return the relevant DS records */
    enableDomainDnsSec: async (reqParams: DnsApiEnableDomainDnsSecReq, options?: any): Promise<AxiosResponse<DnsApiEnableDomainDnsSecRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).enableDomainDnsSec(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Lists all third party providers. - This operation can only be done by an MO admin. Third party providers are notified about changes to dns zones within Enhance. This endpoint lists all registered URLs which listen to these changes. */
    getDnsThirdPartyProviders: async (reqParams: DnsApiGetDnsThirdPartyProvidersReq, options?: any): Promise<AxiosResponse<DnsApiGetDnsThirdPartyProvidersRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).getDnsThirdPartyProviders(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get a dns zone for given domain - Returns details about the dns zone of given domain. Returns Soa record and all other records. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteDomainDnsZone: async (reqParams: DnsApiGetWebsiteDomainDnsZoneReq, options?: any): Promise<AxiosResponse<DnsApiGetWebsiteDomainDnsZoneRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).getWebsiteDomainDnsZone(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** List default DNS records - Lists the DNS records which will be added to all newly created DNS zones. */
    listDefaultDnsRecords: async (reqParams: DnsApiListDefaultDnsRecordsReq, options?: any): Promise<AxiosResponse<DnsApiListDefaultDnsRecordsRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).listDefaultDnsRecords(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update a default DNS record - Updates a default DNS record, all fields are optional. */
    updateDefaultDnsRecord: async (reqParams: DnsApiUpdateDefaultDnsRecordReq, options?: any): Promise<AxiosResponse<DnsApiUpdateDefaultDnsRecordRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).updateDefaultDnsRecord(reqParams.recordId, reqParams.updateDefaultDnsRecord, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates a dns zone SOA for website domain - Partially updates dns zone SOA record for existing zone. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWebsiteDomainDnsZone: async (reqParams: DnsApiUpdateWebsiteDomainDnsZoneReq, options?: any): Promise<AxiosResponse<DnsApiUpdateWebsiteDomainDnsZoneRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).updateWebsiteDomainDnsZone(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.updateDnsZone, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates a dns record for given domain - Partially updates a dns record for existing zone. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWebsiteDomainDnsZoneRecord: async (reqParams: DnsApiUpdateWebsiteDomainDnsZoneRecordReq, options?: any): Promise<AxiosResponse<DnsApiUpdateWebsiteDomainDnsZoneRecordRes>> => {
        const localVarAxiosArgs = await DnsApiAxiosParamCreator(configuration).updateWebsiteDomainDnsZoneRecord(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.recordId, reqParams.updateDnsRecord, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface DnsApiReq {
    createDefaultDnsRecord: DnsApiCreateDefaultDnsRecordReq;
    createDnsThirdPartyProvider: DnsApiCreateDnsThirdPartyProviderReq;
    createWebsiteDomainDnsZoneRecord: DnsApiCreateWebsiteDomainDnsZoneRecordReq;
    deleteDefaultDnsRecord: DnsApiDeleteDefaultDnsRecordReq;
    deleteDnsThirdPartyProvider: DnsApiDeleteDnsThirdPartyProviderReq;
    deleteWebsiteDomainDnsZoneRecord: DnsApiDeleteWebsiteDomainDnsZoneRecordReq;
    disableDomainDnsSec: DnsApiDisableDomainDnsSecReq;
    enableDomainDnsSec: DnsApiEnableDomainDnsSecReq;
    getDnsThirdPartyProviders: DnsApiGetDnsThirdPartyProvidersReq;
    getWebsiteDomainDnsZone: DnsApiGetWebsiteDomainDnsZoneReq;
    listDefaultDnsRecords: DnsApiListDefaultDnsRecordsReq;
    updateDefaultDnsRecord: DnsApiUpdateDefaultDnsRecordReq;
    updateWebsiteDomainDnsZone: DnsApiUpdateWebsiteDomainDnsZoneReq;
    updateWebsiteDomainDnsZoneRecord: DnsApiUpdateWebsiteDomainDnsZoneRecordReq;
}

export interface DnsApiRes {
    createDefaultDnsRecord: DnsApiCreateDefaultDnsRecordRes;
    createDnsThirdPartyProvider: DnsApiCreateDnsThirdPartyProviderRes;
    createWebsiteDomainDnsZoneRecord: DnsApiCreateWebsiteDomainDnsZoneRecordRes;
    deleteDefaultDnsRecord: DnsApiDeleteDefaultDnsRecordRes;
    deleteDnsThirdPartyProvider: DnsApiDeleteDnsThirdPartyProviderRes;
    deleteWebsiteDomainDnsZoneRecord: DnsApiDeleteWebsiteDomainDnsZoneRecordRes;
    disableDomainDnsSec: DnsApiDisableDomainDnsSecRes;
    enableDomainDnsSec: DnsApiEnableDomainDnsSecRes;
    getDnsThirdPartyProviders: DnsApiGetDnsThirdPartyProvidersRes;
    getWebsiteDomainDnsZone: DnsApiGetWebsiteDomainDnsZoneRes;
    listDefaultDnsRecords: DnsApiListDefaultDnsRecordsRes;
    updateDefaultDnsRecord: DnsApiUpdateDefaultDnsRecordRes;
    updateWebsiteDomainDnsZone: DnsApiUpdateWebsiteDomainDnsZoneRes;
    updateWebsiteDomainDnsZoneRecord: DnsApiUpdateWebsiteDomainDnsZoneRecordRes;
}


/**
 * DomainsApi - axios parameter creator
 * @export
 */
export const DomainsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Check if a domain can be created - An organisation can perform a pre-flight check to see if a domain can be added.  This is useful for form validation.  It will return a status indicating if the domain is in use and, if so, whether it is on the current organisation or another.  If it is in use on the current organisation we will also return the websiteId the domain belongs to. */
        checkDomain: async (orgId: string, newDomain: NewDomain, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/check`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newDomain !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newDomain !== undefined ? newDomain : {}) : (newDomain || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Generate and setup letsencrypt ssl certificates for website\'s domain - Generates letsencrypt certificates for the domain. This is a longer running task, that will do a complete ssl setup for a given domain. Once completed any given domain will get served over `https`. Given domain must be publicly accessible and being served from our service. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteDomainLetsencryptCerts: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Generate and setup letsencrypt ssl certificates for website\'s domain with mail. prefix. - Generates letsencrypt certificate for the mail server at mail.customerdomain. */
        createWebsiteMailDomainLetsencryptCerts: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt_mail`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create website mapped domain - Creates a domain mapping, where subscription resources are sufficient. The mapping kind will default to \'alias\' if unspecified. */
        createWebsiteMappedDomain: async (orgId: string, websiteId: string, newMappedDomain: NewMappedDomain, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMappedDomain !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMappedDomain !== undefined ? newMappedDomain : {}) : (newMappedDomain || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete CloudFlare API key, domain level - Deletes the CloudFlare API key for a domain */
        deleteCloudflareApiKeyId: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/cloudflare`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete domain - Deletes a domain. Only privileged org members or parent org admins may do this. */
        deleteDomain: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website domain mapping - Deletes the domain mapping and decrements the domain alias quota usage in the website\'s subscription, if applicable. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteDomainMapping: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes domain\'s custom vhost file if any -  */
        deleteWebsiteDomainVhost: async (domainId: string, inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/vhost`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {}) : (inlineObject1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get CloudFlare API key, domain level - Returns the CloudFlare API key for a domain (obfuscated for security). This key will override an org level key. */
        getCloudflareApiKeyDomain: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/cloudflare`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get CloudFlare name servers - Returns the CloudFlare nameservers for a given domain, if it exists in CloudFlare. */
        getCloudflareNameServers: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/cloudflare/nameservers`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get authoritative nameservers for domain. - Get authoritative nameservers for domain and check if they match known DNS IPs of the Enhance cluster */
        getDomainAuthNs: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/auth-ns`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get domains - Lists the domains belonging to this organization. */
        getDomains: async (orgId: string, offset?: number, limit?: number, sortBy?: 'domain', sortOrder?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Recursively query Dns servers for given domain - Returns details about the dns servers of given domain. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteDomainDnsQuery: async (orgId: string, websiteId: string, domainId: string, resolveDepth?: 'short' | 'detailed' | 'queryAllTldNs', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-query`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (resolveDepth !== undefined) {
                localVarQueryParameter['resolveDepth'] = resolveDepth;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns website domain mapping - Returns a domain by its id that is mapped to this website. Requires login to have admin privileges in this org. Since only the MO can create standalone domains, session holder must be at least a `SuperAdmin` in the MO. */
        getWebsiteDomainMapping: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns website domain mapping DNS status - Returns an indication of whether the domain correctly resolves to the server this website is on. */
        getWebsiteDomainMappingDnsStatus: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-status`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website\'s mapped domains - Returns a list of domains that are mapped to this website. Requires login to have admin privileges in this org. */
        getWebsiteDomainMappings: async (orgId: string, websiteId: string, withSsl?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (withSsl !== undefined) {
                localVarQueryParameter['withSsl'] = withSsl;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get mod security status for a single domain -  */
        getWebsiteDomainModSecStatus: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/modsec_status`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get domain\'s custom vhost file, if the file does not exist return empty string  -  */
        getWebsiteDomainVhost: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/vhost`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Perform the LetsEncrypt preflight check - Will attempt to verify that the domain will successfully achieve a LetsEncrypt certificate if attempted.  Provides debug information. */
        performLetsEncryptPreflightCheck: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt_preflight`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set CloudFlare API key, domain level - Sets the CloudFlare API key for a domain.  This key will override an org level key. */
        setCloudflareApiKeyId: async (orgId: string, domainId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/cloudflare`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set mod security status on a single domain -  */
        setWebsiteDomainModSecStatus: async (domainId: string, modSecStatus: ModSecStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/modsec_status`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof modSecStatus !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modSecStatus !== undefined ? modSecStatus : {}) : (modSecStatus || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a custom vhost file -  */
        setWebsiteDomainVhost: async (domainId: string, vhost: Vhost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/vhost`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof vhost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(vhost !== undefined ? vhost : {}) : (vhost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update website domain mapping - Partially updates the domain mapping. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWebsiteDomainMapping: async (orgId: string, websiteId: string, domainId: string, domainMappingUpdate: DomainMappingUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof domainMappingUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(domainMappingUpdate !== undefined ? domainMappingUpdate : {}) : (domainMappingUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update primary domain mapping - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). Domain and website has to belong to this organization. */
        updateWebsitePrimaryDomain: async (orgId: string, websiteId: string, newPrimaryDomainMapping: NewPrimaryDomainMapping, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/primary`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPrimaryDomainMapping !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPrimaryDomainMapping !== undefined ? newPrimaryDomainMapping : {}) : (newPrimaryDomainMapping || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for checkDomain operation in DomainsApi. */
export type DomainsApiCheckDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** Domain details. */
    newDomain: NewDomain
};

/** Response parameters for checkDomain operation in DomainsApi. */
export type DomainsApiCheckDomainRes = DomainInUseStatus;


/** Request parameters for createWebsiteDomainLetsencryptCerts operation in DomainsApi. */
export type DomainsApiCreateWebsiteDomainLetsencryptCertsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for createWebsiteDomainLetsencryptCerts operation in DomainsApi. */
export type DomainsApiCreateWebsiteDomainLetsencryptCertsRes = void;


/** Request parameters for createWebsiteMailDomainLetsencryptCerts operation in DomainsApi. */
export type DomainsApiCreateWebsiteMailDomainLetsencryptCertsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for createWebsiteMailDomainLetsencryptCerts operation in DomainsApi. */
export type DomainsApiCreateWebsiteMailDomainLetsencryptCertsRes = void;


/** Request parameters for createWebsiteMappedDomain operation in DomainsApi. */
export type DomainsApiCreateWebsiteMappedDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Domain details. */
    newMappedDomain: NewMappedDomain
};

/** Response parameters for createWebsiteMappedDomain operation in DomainsApi. */
export type DomainsApiCreateWebsiteMappedDomainRes = NewResourceUuid;


/** Request parameters for deleteCloudflareApiKeyId operation in DomainsApi. */
export type DomainsApiDeleteCloudflareApiKeyIdReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for deleteCloudflareApiKeyId operation in DomainsApi. */
export type DomainsApiDeleteCloudflareApiKeyIdRes = void;


/** Request parameters for deleteDomain operation in DomainsApi. */
export type DomainsApiDeleteDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for deleteDomain operation in DomainsApi. */
export type DomainsApiDeleteDomainRes = void;


/** Request parameters for deleteWebsiteDomainMapping operation in DomainsApi. */
export type DomainsApiDeleteWebsiteDomainMappingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for deleteWebsiteDomainMapping operation in DomainsApi. */
export type DomainsApiDeleteWebsiteDomainMappingRes = void;


/** Request parameters for deleteWebsiteDomainVhost operation in DomainsApi. */
export type DomainsApiDeleteWebsiteDomainVhostReq = {
    /** The id of the domain. */
    domainId: string
    inlineObject1: InlineObject1
};

/** Response parameters for deleteWebsiteDomainVhost operation in DomainsApi. */
export type DomainsApiDeleteWebsiteDomainVhostRes = void;


/** Request parameters for getCloudflareApiKeyDomain operation in DomainsApi. */
export type DomainsApiGetCloudflareApiKeyDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getCloudflareApiKeyDomain operation in DomainsApi. */
export type DomainsApiGetCloudflareApiKeyDomainRes = CloudFlareApiKey;


/** Request parameters for getCloudflareNameServers operation in DomainsApi. */
export type DomainsApiGetCloudflareNameServersReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getCloudflareNameServers operation in DomainsApi. */
export type DomainsApiGetCloudflareNameServersRes = CloudFlareNameServers;


/** Request parameters for getDomainAuthNs operation in DomainsApi. */
export type DomainsApiGetDomainAuthNsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getDomainAuthNs operation in DomainsApi. */
export type DomainsApiGetDomainAuthNsRes = AuthNsResponse;


/** Request parameters for getDomains operation in DomainsApi. */
export type DomainsApiGetDomainsReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'domain'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
};

/** Response parameters for getDomains operation in DomainsApi. */
export type DomainsApiGetDomainsRes = DomainsListing;


/** Request parameters for getWebsiteDomainDnsQuery operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainDnsQueryReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** DNS query resolve depth, defaults to `short` if not provided. `short` -> takes the shortest path to resolve domain IP. `detailed` -> queries and returns output from all found Authoritative name servers. `queryAllTldNs` -> queries and returns results from all TLD name servers (very expensive). */
    resolveDepth?: 'short' | 'detailed' | 'queryAllTldNs'
};

/** Response parameters for getWebsiteDomainDnsQuery operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainDnsQueryRes = DnsQueryOutcome;


/** Request parameters for getWebsiteDomainMapping operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainMappingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainMapping operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainMappingRes = DomainMapping;


/** Request parameters for getWebsiteDomainMappingDnsStatus operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainMappingDnsStatusReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainMappingDnsStatus operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainMappingDnsStatusRes = DnsStatus;


/** Request parameters for getWebsiteDomainMappings operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainMappingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** If set to true, domains are returned with applicable ssl certificates. */
    withSsl?: boolean
};

/** Response parameters for getWebsiteDomainMappings operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainMappingsRes = DomainMappingsFullListing;


/** Request parameters for getWebsiteDomainModSecStatus operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainModSecStatusReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainModSecStatus operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainModSecStatusRes = ModSecStatus;


/** Request parameters for getWebsiteDomainVhost operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainVhostReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainVhost operation in DomainsApi. */
export type DomainsApiGetWebsiteDomainVhostRes = Vhost;


/** Request parameters for performLetsEncryptPreflightCheck operation in DomainsApi. */
export type DomainsApiPerformLetsEncryptPreflightCheckReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for performLetsEncryptPreflightCheck operation in DomainsApi. */
export type DomainsApiPerformLetsEncryptPreflightCheckRes = LetsEncryptPreflightResult;


/** Request parameters for setCloudflareApiKeyId operation in DomainsApi. */
export type DomainsApiSetCloudflareApiKeyIdReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
    /** Cloudflare API key ID. */
    body: string
};

/** Response parameters for setCloudflareApiKeyId operation in DomainsApi. */
export type DomainsApiSetCloudflareApiKeyIdRes = void;


/** Request parameters for setWebsiteDomainModSecStatus operation in DomainsApi. */
export type DomainsApiSetWebsiteDomainModSecStatusReq = {
    /** The id of the domain. */
    domainId: string
    modSecStatus: ModSecStatus
};

/** Response parameters for setWebsiteDomainModSecStatus operation in DomainsApi. */
export type DomainsApiSetWebsiteDomainModSecStatusRes = void;


/** Request parameters for setWebsiteDomainVhost operation in DomainsApi. */
export type DomainsApiSetWebsiteDomainVhostReq = {
    /** The id of the domain. */
    domainId: string
    vhost: Vhost
};

/** Response parameters for setWebsiteDomainVhost operation in DomainsApi. */
export type DomainsApiSetWebsiteDomainVhostRes = void;


/** Request parameters for updateWebsiteDomainMapping operation in DomainsApi. */
export type DomainsApiUpdateWebsiteDomainMappingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    domainMappingUpdate: DomainMappingUpdate
};

/** Response parameters for updateWebsiteDomainMapping operation in DomainsApi. */
export type DomainsApiUpdateWebsiteDomainMappingRes = void;


/** Request parameters for updateWebsitePrimaryDomain operation in DomainsApi. */
export type DomainsApiUpdateWebsitePrimaryDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Domain mapping details. */
    newPrimaryDomainMapping: NewPrimaryDomainMapping
};

/** Response parameters for updateWebsitePrimaryDomain operation in DomainsApi. */
export type DomainsApiUpdateWebsitePrimaryDomainRes = void;

/**
 * DomainsApi - service client interface
 */
export const DomainsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Check if a domain can be created - An organisation can perform a pre-flight check to see if a domain can be added.  This is useful for form validation.  It will return a status indicating if the domain is in use and, if so, whether it is on the current organisation or another.  If it is in use on the current organisation we will also return the websiteId the domain belongs to. */
    checkDomain: async (reqParams: DomainsApiCheckDomainReq, options?: any): Promise<AxiosResponse<DomainsApiCheckDomainRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).checkDomain(reqParams.orgId, reqParams.newDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Generate and setup letsencrypt ssl certificates for website\'s domain - Generates letsencrypt certificates for the domain. This is a longer running task, that will do a complete ssl setup for a given domain. Once completed any given domain will get served over `https`. Given domain must be publicly accessible and being served from our service. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteDomainLetsencryptCerts: async (reqParams: DomainsApiCreateWebsiteDomainLetsencryptCertsReq, options?: any): Promise<AxiosResponse<DomainsApiCreateWebsiteDomainLetsencryptCertsRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).createWebsiteDomainLetsencryptCerts(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Generate and setup letsencrypt ssl certificates for website\'s domain with mail. prefix. - Generates letsencrypt certificate for the mail server at mail.customerdomain. */
    createWebsiteMailDomainLetsencryptCerts: async (reqParams: DomainsApiCreateWebsiteMailDomainLetsencryptCertsReq, options?: any): Promise<AxiosResponse<DomainsApiCreateWebsiteMailDomainLetsencryptCertsRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).createWebsiteMailDomainLetsencryptCerts(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create website mapped domain - Creates a domain mapping, where subscription resources are sufficient. The mapping kind will default to \'alias\' if unspecified. */
    createWebsiteMappedDomain: async (reqParams: DomainsApiCreateWebsiteMappedDomainReq, options?: any): Promise<AxiosResponse<DomainsApiCreateWebsiteMappedDomainRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).createWebsiteMappedDomain(reqParams.orgId, reqParams.websiteId, reqParams.newMappedDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete CloudFlare API key, domain level - Deletes the CloudFlare API key for a domain */
    deleteCloudflareApiKeyId: async (reqParams: DomainsApiDeleteCloudflareApiKeyIdReq, options?: any): Promise<AxiosResponse<DomainsApiDeleteCloudflareApiKeyIdRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).deleteCloudflareApiKeyId(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete domain - Deletes a domain. Only privileged org members or parent org admins may do this. */
    deleteDomain: async (reqParams: DomainsApiDeleteDomainReq, options?: any): Promise<AxiosResponse<DomainsApiDeleteDomainRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).deleteDomain(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website domain mapping - Deletes the domain mapping and decrements the domain alias quota usage in the website\'s subscription, if applicable. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteDomainMapping: async (reqParams: DomainsApiDeleteWebsiteDomainMappingReq, options?: any): Promise<AxiosResponse<DomainsApiDeleteWebsiteDomainMappingRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).deleteWebsiteDomainMapping(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes domain\'s custom vhost file if any -  */
    deleteWebsiteDomainVhost: async (reqParams: DomainsApiDeleteWebsiteDomainVhostReq, options?: any): Promise<AxiosResponse<DomainsApiDeleteWebsiteDomainVhostRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).deleteWebsiteDomainVhost(reqParams.domainId, reqParams.inlineObject1, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get CloudFlare API key, domain level - Returns the CloudFlare API key for a domain (obfuscated for security). This key will override an org level key. */
    getCloudflareApiKeyDomain: async (reqParams: DomainsApiGetCloudflareApiKeyDomainReq, options?: any): Promise<AxiosResponse<DomainsApiGetCloudflareApiKeyDomainRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getCloudflareApiKeyDomain(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get CloudFlare name servers - Returns the CloudFlare nameservers for a given domain, if it exists in CloudFlare. */
    getCloudflareNameServers: async (reqParams: DomainsApiGetCloudflareNameServersReq, options?: any): Promise<AxiosResponse<DomainsApiGetCloudflareNameServersRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getCloudflareNameServers(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get authoritative nameservers for domain. - Get authoritative nameservers for domain and check if they match known DNS IPs of the Enhance cluster */
    getDomainAuthNs: async (reqParams: DomainsApiGetDomainAuthNsReq, options?: any): Promise<AxiosResponse<DomainsApiGetDomainAuthNsRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getDomainAuthNs(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get domains - Lists the domains belonging to this organization. */
    getDomains: async (reqParams: DomainsApiGetDomainsReq, options?: any): Promise<AxiosResponse<DomainsApiGetDomainsRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getDomains(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Recursively query Dns servers for given domain - Returns details about the dns servers of given domain. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteDomainDnsQuery: async (reqParams: DomainsApiGetWebsiteDomainDnsQueryReq, options?: any): Promise<AxiosResponse<DomainsApiGetWebsiteDomainDnsQueryRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getWebsiteDomainDnsQuery(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.resolveDepth, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns website domain mapping - Returns a domain by its id that is mapped to this website. Requires login to have admin privileges in this org. Since only the MO can create standalone domains, session holder must be at least a `SuperAdmin` in the MO. */
    getWebsiteDomainMapping: async (reqParams: DomainsApiGetWebsiteDomainMappingReq, options?: any): Promise<AxiosResponse<DomainsApiGetWebsiteDomainMappingRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getWebsiteDomainMapping(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns website domain mapping DNS status - Returns an indication of whether the domain correctly resolves to the server this website is on. */
    getWebsiteDomainMappingDnsStatus: async (reqParams: DomainsApiGetWebsiteDomainMappingDnsStatusReq, options?: any): Promise<AxiosResponse<DomainsApiGetWebsiteDomainMappingDnsStatusRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getWebsiteDomainMappingDnsStatus(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website\'s mapped domains - Returns a list of domains that are mapped to this website. Requires login to have admin privileges in this org. */
    getWebsiteDomainMappings: async (reqParams: DomainsApiGetWebsiteDomainMappingsReq, options?: any): Promise<AxiosResponse<DomainsApiGetWebsiteDomainMappingsRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getWebsiteDomainMappings(reqParams.orgId, reqParams.websiteId, reqParams.withSsl, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get mod security status for a single domain -  */
    getWebsiteDomainModSecStatus: async (reqParams: DomainsApiGetWebsiteDomainModSecStatusReq, options?: any): Promise<AxiosResponse<DomainsApiGetWebsiteDomainModSecStatusRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getWebsiteDomainModSecStatus(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get domain\'s custom vhost file, if the file does not exist return empty string  -  */
    getWebsiteDomainVhost: async (reqParams: DomainsApiGetWebsiteDomainVhostReq, options?: any): Promise<AxiosResponse<DomainsApiGetWebsiteDomainVhostRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).getWebsiteDomainVhost(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Perform the LetsEncrypt preflight check - Will attempt to verify that the domain will successfully achieve a LetsEncrypt certificate if attempted.  Provides debug information. */
    performLetsEncryptPreflightCheck: async (reqParams: DomainsApiPerformLetsEncryptPreflightCheckReq, options?: any): Promise<AxiosResponse<DomainsApiPerformLetsEncryptPreflightCheckRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).performLetsEncryptPreflightCheck(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set CloudFlare API key, domain level - Sets the CloudFlare API key for a domain.  This key will override an org level key. */
    setCloudflareApiKeyId: async (reqParams: DomainsApiSetCloudflareApiKeyIdReq, options?: any): Promise<AxiosResponse<DomainsApiSetCloudflareApiKeyIdRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).setCloudflareApiKeyId(reqParams.orgId, reqParams.domainId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set mod security status on a single domain -  */
    setWebsiteDomainModSecStatus: async (reqParams: DomainsApiSetWebsiteDomainModSecStatusReq, options?: any): Promise<AxiosResponse<DomainsApiSetWebsiteDomainModSecStatusRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).setWebsiteDomainModSecStatus(reqParams.domainId, reqParams.modSecStatus, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a custom vhost file -  */
    setWebsiteDomainVhost: async (reqParams: DomainsApiSetWebsiteDomainVhostReq, options?: any): Promise<AxiosResponse<DomainsApiSetWebsiteDomainVhostRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).setWebsiteDomainVhost(reqParams.domainId, reqParams.vhost, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update website domain mapping - Partially updates the domain mapping. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWebsiteDomainMapping: async (reqParams: DomainsApiUpdateWebsiteDomainMappingReq, options?: any): Promise<AxiosResponse<DomainsApiUpdateWebsiteDomainMappingRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).updateWebsiteDomainMapping(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.domainMappingUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update primary domain mapping - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). Domain and website has to belong to this organization. */
    updateWebsitePrimaryDomain: async (reqParams: DomainsApiUpdateWebsitePrimaryDomainReq, options?: any): Promise<AxiosResponse<DomainsApiUpdateWebsitePrimaryDomainRes>> => {
        const localVarAxiosArgs = await DomainsApiAxiosParamCreator(configuration).updateWebsitePrimaryDomain(reqParams.orgId, reqParams.websiteId, reqParams.newPrimaryDomainMapping, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface DomainsApiReq {
    checkDomain: DomainsApiCheckDomainReq;
    createWebsiteDomainLetsencryptCerts: DomainsApiCreateWebsiteDomainLetsencryptCertsReq;
    createWebsiteMailDomainLetsencryptCerts: DomainsApiCreateWebsiteMailDomainLetsencryptCertsReq;
    createWebsiteMappedDomain: DomainsApiCreateWebsiteMappedDomainReq;
    deleteCloudflareApiKeyId: DomainsApiDeleteCloudflareApiKeyIdReq;
    deleteDomain: DomainsApiDeleteDomainReq;
    deleteWebsiteDomainMapping: DomainsApiDeleteWebsiteDomainMappingReq;
    deleteWebsiteDomainVhost: DomainsApiDeleteWebsiteDomainVhostReq;
    getCloudflareApiKeyDomain: DomainsApiGetCloudflareApiKeyDomainReq;
    getCloudflareNameServers: DomainsApiGetCloudflareNameServersReq;
    getDomainAuthNs: DomainsApiGetDomainAuthNsReq;
    getDomains: DomainsApiGetDomainsReq;
    getWebsiteDomainDnsQuery: DomainsApiGetWebsiteDomainDnsQueryReq;
    getWebsiteDomainMapping: DomainsApiGetWebsiteDomainMappingReq;
    getWebsiteDomainMappingDnsStatus: DomainsApiGetWebsiteDomainMappingDnsStatusReq;
    getWebsiteDomainMappings: DomainsApiGetWebsiteDomainMappingsReq;
    getWebsiteDomainModSecStatus: DomainsApiGetWebsiteDomainModSecStatusReq;
    getWebsiteDomainVhost: DomainsApiGetWebsiteDomainVhostReq;
    performLetsEncryptPreflightCheck: DomainsApiPerformLetsEncryptPreflightCheckReq;
    setCloudflareApiKeyId: DomainsApiSetCloudflareApiKeyIdReq;
    setWebsiteDomainModSecStatus: DomainsApiSetWebsiteDomainModSecStatusReq;
    setWebsiteDomainVhost: DomainsApiSetWebsiteDomainVhostReq;
    updateWebsiteDomainMapping: DomainsApiUpdateWebsiteDomainMappingReq;
    updateWebsitePrimaryDomain: DomainsApiUpdateWebsitePrimaryDomainReq;
}

export interface DomainsApiRes {
    checkDomain: DomainsApiCheckDomainRes;
    createWebsiteDomainLetsencryptCerts: DomainsApiCreateWebsiteDomainLetsencryptCertsRes;
    createWebsiteMailDomainLetsencryptCerts: DomainsApiCreateWebsiteMailDomainLetsencryptCertsRes;
    createWebsiteMappedDomain: DomainsApiCreateWebsiteMappedDomainRes;
    deleteCloudflareApiKeyId: DomainsApiDeleteCloudflareApiKeyIdRes;
    deleteDomain: DomainsApiDeleteDomainRes;
    deleteWebsiteDomainMapping: DomainsApiDeleteWebsiteDomainMappingRes;
    deleteWebsiteDomainVhost: DomainsApiDeleteWebsiteDomainVhostRes;
    getCloudflareApiKeyDomain: DomainsApiGetCloudflareApiKeyDomainRes;
    getCloudflareNameServers: DomainsApiGetCloudflareNameServersRes;
    getDomainAuthNs: DomainsApiGetDomainAuthNsRes;
    getDomains: DomainsApiGetDomainsRes;
    getWebsiteDomainDnsQuery: DomainsApiGetWebsiteDomainDnsQueryRes;
    getWebsiteDomainMapping: DomainsApiGetWebsiteDomainMappingRes;
    getWebsiteDomainMappingDnsStatus: DomainsApiGetWebsiteDomainMappingDnsStatusRes;
    getWebsiteDomainMappings: DomainsApiGetWebsiteDomainMappingsRes;
    getWebsiteDomainModSecStatus: DomainsApiGetWebsiteDomainModSecStatusRes;
    getWebsiteDomainVhost: DomainsApiGetWebsiteDomainVhostRes;
    performLetsEncryptPreflightCheck: DomainsApiPerformLetsEncryptPreflightCheckRes;
    setCloudflareApiKeyId: DomainsApiSetCloudflareApiKeyIdRes;
    setWebsiteDomainModSecStatus: DomainsApiSetWebsiteDomainModSecStatusRes;
    setWebsiteDomainVhost: DomainsApiSetWebsiteDomainVhostRes;
    updateWebsiteDomainMapping: DomainsApiUpdateWebsiteDomainMappingRes;
    updateWebsitePrimaryDomain: DomainsApiUpdateWebsitePrimaryDomainRes;
}


/**
 * EmailClientApi - axios parameter creator
 * @export
 */
export const EmailClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create new email autoresponder - Creates a new autoresponder for the given email. Client must send Authorization token, email address and its current password for authentication */
        createEmailAutoresponder: async (authorization: string, address: string, password: string, newAutoresponder: NewAutoresponder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/autoresponders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newAutoresponder !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newAutoresponder !== undefined ? newAutoresponder : {}) : (newAutoresponder || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete email autoresponder - Deletes the autoresponder belonging to the given email account. Client must send Authorization token, email address and its current   password for authentication */
        deleteEmailAutoresponder: async (authorization: string, address: string, password: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/autoresponders/{autoresponder_id}`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get email account autoresponder - Returns autoresponders configured for the given email. Client must send Authorization token, email address and its current password for authentication */
        getEmailAutoresponders: async (authorization: string, address: string, password: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/autoresponders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns email account\'s forwarders - Returns forwarders list for the given email account. Client must send Authorization token, email address and its current password for authentication */
        getEmailForwarders: async (authorization: string, address: string, password: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/forwarders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns public Ip Address of the email server -  */
        getEmailPublicIp: async (authorization: string, address: string, password: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/public-ip`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update email autoresponder - Updates the autoresponder belonging to the given email account. Client must send Authorization token, email address and its current password for authentication */
        updateEmailAutoresponder: async (authorization: string, address: string, password: string, updateAutoresponder: UpdateAutoresponder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/autoresponders/{autoresponder_id}`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateAutoresponder !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateAutoresponder !== undefined ? updateAutoresponder : {}) : (updateAutoresponder || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates email account\'s forwarders - Accepts forwarders list in body. Client must send Authorization token, email address and its current password for authentication */
        updateEmailForwarders: async (authorization: string, address: string, password: string, emailForwardersUpdate: EmailForwardersUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/forwarders`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof emailForwardersUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailForwardersUpdate !== undefined ? emailForwardersUpdate : {}) : (emailForwardersUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates email account\'s password - Accepts a new password in body. Client must send Authorization token, email address and its current password for authentication */
        updateEmailPassword: async (authorization: string, address: string, password: string, emailPasswordUpdate: EmailPasswordUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/email-client/password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (address !== undefined && address !== null) {
                localVarHeaderParameter['Address'] = String(address);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['Password'] = String(password);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof emailPasswordUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailPasswordUpdate !== undefined ? emailPasswordUpdate : {}) : (emailPasswordUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createEmailAutoresponder operation in EmailClientApi. */
export type EmailClientApiCreateEmailAutoresponderReq = {
    authorization: string
    address: string
    password: string
    /** Autoresponder details. */
    newAutoresponder: NewAutoresponder
};

/** Response parameters for createEmailAutoresponder operation in EmailClientApi. */
export type EmailClientApiCreateEmailAutoresponderRes = NewResourceId;


/** Request parameters for deleteEmailAutoresponder operation in EmailClientApi. */
export type EmailClientApiDeleteEmailAutoresponderReq = {
    authorization: string
    address: string
    password: string
};

/** Response parameters for deleteEmailAutoresponder operation in EmailClientApi. */
export type EmailClientApiDeleteEmailAutoresponderRes = void;


/** Request parameters for getEmailAutoresponders operation in EmailClientApi. */
export type EmailClientApiGetEmailAutorespondersReq = {
    authorization: string
    address: string
    password: string
};

/** Response parameters for getEmailAutoresponders operation in EmailClientApi. */
export type EmailClientApiGetEmailAutorespondersRes = Autoresponder;


/** Request parameters for getEmailForwarders operation in EmailClientApi. */
export type EmailClientApiGetEmailForwardersReq = {
    authorization: string
    address: string
    password: string
};

/** Response parameters for getEmailForwarders operation in EmailClientApi. */
export type EmailClientApiGetEmailForwardersRes = ForwardersFullListing;


/** Request parameters for getEmailPublicIp operation in EmailClientApi. */
export type EmailClientApiGetEmailPublicIpReq = {
    authorization: string
    address: string
    password: string
};

/** Response parameters for getEmailPublicIp operation in EmailClientApi. */
export type EmailClientApiGetEmailPublicIpRes = EmailPublicIp;


/** Request parameters for updateEmailAutoresponder operation in EmailClientApi. */
export type EmailClientApiUpdateEmailAutoresponderReq = {
    authorization: string
    address: string
    password: string
    /** Autoresponder update details. */
    updateAutoresponder: UpdateAutoresponder
};

/** Response parameters for updateEmailAutoresponder operation in EmailClientApi. */
export type EmailClientApiUpdateEmailAutoresponderRes = void;


/** Request parameters for updateEmailForwarders operation in EmailClientApi. */
export type EmailClientApiUpdateEmailForwardersReq = {
    authorization: string
    address: string
    password: string
    emailForwardersUpdate: EmailForwardersUpdate
};

/** Response parameters for updateEmailForwarders operation in EmailClientApi. */
export type EmailClientApiUpdateEmailForwardersRes = void;


/** Request parameters for updateEmailPassword operation in EmailClientApi. */
export type EmailClientApiUpdateEmailPasswordReq = {
    authorization: string
    address: string
    password: string
    emailPasswordUpdate: EmailPasswordUpdate
};

/** Response parameters for updateEmailPassword operation in EmailClientApi. */
export type EmailClientApiUpdateEmailPasswordRes = void;

/**
 * EmailClientApi - service client interface
 */
export const EmailClientApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create new email autoresponder - Creates a new autoresponder for the given email. Client must send Authorization token, email address and its current password for authentication */
    createEmailAutoresponder: async (reqParams: EmailClientApiCreateEmailAutoresponderReq, options?: any): Promise<AxiosResponse<EmailClientApiCreateEmailAutoresponderRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).createEmailAutoresponder(reqParams.authorization, reqParams.address, reqParams.password, reqParams.newAutoresponder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete email autoresponder - Deletes the autoresponder belonging to the given email account. Client must send Authorization token, email address and its current   password for authentication */
    deleteEmailAutoresponder: async (reqParams: EmailClientApiDeleteEmailAutoresponderReq, options?: any): Promise<AxiosResponse<EmailClientApiDeleteEmailAutoresponderRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).deleteEmailAutoresponder(reqParams.authorization, reqParams.address, reqParams.password, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get email account autoresponder - Returns autoresponders configured for the given email. Client must send Authorization token, email address and its current password for authentication */
    getEmailAutoresponders: async (reqParams: EmailClientApiGetEmailAutorespondersReq, options?: any): Promise<AxiosResponse<EmailClientApiGetEmailAutorespondersRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).getEmailAutoresponders(reqParams.authorization, reqParams.address, reqParams.password, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns email account\'s forwarders - Returns forwarders list for the given email account. Client must send Authorization token, email address and its current password for authentication */
    getEmailForwarders: async (reqParams: EmailClientApiGetEmailForwardersReq, options?: any): Promise<AxiosResponse<EmailClientApiGetEmailForwardersRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).getEmailForwarders(reqParams.authorization, reqParams.address, reqParams.password, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns public Ip Address of the email server -  */
    getEmailPublicIp: async (reqParams: EmailClientApiGetEmailPublicIpReq, options?: any): Promise<AxiosResponse<EmailClientApiGetEmailPublicIpRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).getEmailPublicIp(reqParams.authorization, reqParams.address, reqParams.password, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update email autoresponder - Updates the autoresponder belonging to the given email account. Client must send Authorization token, email address and its current password for authentication */
    updateEmailAutoresponder: async (reqParams: EmailClientApiUpdateEmailAutoresponderReq, options?: any): Promise<AxiosResponse<EmailClientApiUpdateEmailAutoresponderRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).updateEmailAutoresponder(reqParams.authorization, reqParams.address, reqParams.password, reqParams.updateAutoresponder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates email account\'s forwarders - Accepts forwarders list in body. Client must send Authorization token, email address and its current password for authentication */
    updateEmailForwarders: async (reqParams: EmailClientApiUpdateEmailForwardersReq, options?: any): Promise<AxiosResponse<EmailClientApiUpdateEmailForwardersRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).updateEmailForwarders(reqParams.authorization, reqParams.address, reqParams.password, reqParams.emailForwardersUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates email account\'s password - Accepts a new password in body. Client must send Authorization token, email address and its current password for authentication */
    updateEmailPassword: async (reqParams: EmailClientApiUpdateEmailPasswordReq, options?: any): Promise<AxiosResponse<EmailClientApiUpdateEmailPasswordRes>> => {
        const localVarAxiosArgs = await EmailClientApiAxiosParamCreator(configuration).updateEmailPassword(reqParams.authorization, reqParams.address, reqParams.password, reqParams.emailPasswordUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface EmailClientApiReq {
    createEmailAutoresponder: EmailClientApiCreateEmailAutoresponderReq;
    deleteEmailAutoresponder: EmailClientApiDeleteEmailAutoresponderReq;
    getEmailAutoresponders: EmailClientApiGetEmailAutorespondersReq;
    getEmailForwarders: EmailClientApiGetEmailForwardersReq;
    getEmailPublicIp: EmailClientApiGetEmailPublicIpReq;
    updateEmailAutoresponder: EmailClientApiUpdateEmailAutoresponderReq;
    updateEmailForwarders: EmailClientApiUpdateEmailForwardersReq;
    updateEmailPassword: EmailClientApiUpdateEmailPasswordReq;
}

export interface EmailClientApiRes {
    createEmailAutoresponder: EmailClientApiCreateEmailAutoresponderRes;
    deleteEmailAutoresponder: EmailClientApiDeleteEmailAutoresponderRes;
    getEmailAutoresponders: EmailClientApiGetEmailAutorespondersRes;
    getEmailForwarders: EmailClientApiGetEmailForwardersRes;
    getEmailPublicIp: EmailClientApiGetEmailPublicIpRes;
    updateEmailAutoresponder: EmailClientApiUpdateEmailAutoresponderRes;
    updateEmailForwarders: EmailClientApiUpdateEmailForwardersRes;
    updateEmailPassword: EmailClientApiUpdateEmailPasswordRes;
}


/**
 * EmailsApi - axios parameter creator
 * @export
 */
export const EmailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create an email under website\'s domain - Creates a new email under the given website. The email may have a mailbox or it may be a forwarder, which means it merely serves to forward incoming emails to the specified email addresses. If a password is supplied, a mailbox is created. Otherwise, forwarders must be specified as an email must always have a delivery route. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteEmail: async (orgId: string, websiteId: string, domainId: string, newEmail: NewEmail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/emails`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newEmail !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newEmail !== undefined ? newEmail : {}) : (newEmail || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create new website email autoresponder - Creates a new autoresponder for the given email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteEmailAutoresponder: async (orgId: string, websiteId: string, emailId: string, newAutoresponder: NewAutoresponder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}/autoresponder`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newAutoresponder !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newAutoresponder !== undefined ? newAutoresponder : {}) : (newAutoresponder || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website email - Deletes the email belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteEmail: async (orgId: string, websiteId: string, emailId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website email autoresponder - Deletes the autoresponder belonging to the given website email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteEmailAutoresponder: async (orgId: string, websiteId: string, emailId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}/autoresponder`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get email authentication preferences - Fetch DKIM setting for the mailboxes on a given domain. */
        getDomainEmailAuth: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/email-auth`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the current local/remote status - Fetches the current status of the domain, whether it is treated as local or remote by the MTA */
        getDomainLocalRemote: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/local_remote`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get spam thresholds for an email address -  */
        getEmailSpamThresholds: async (emailId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/emails/{email_id}/spam_thresholds`
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get org emails - Returns all emails belonging to this organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getEmails: async (orgId: string, offset?: number, limit?: number, sortBy?: 'domain' | 'subscription' | 'org' | 'size', sortOrder?: 'asc' | 'desc', search?: string, recursive?: boolean, maxDepth?: number, status?: WebsiteStatus, domainId?: string, planId?: number, subscriptionId?: number, includeInternal?: boolean, showDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/emails`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['maxDepth'] = maxDepth;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (domainId !== undefined) {
                localVarQueryParameter['domainId'] = domainId;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }

            if (includeInternal !== undefined) {
                localVarQueryParameter['includeInternal'] = includeInternal;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website email - Returns details about the given email belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteEmail: async (orgId: string, websiteId: string, emailId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website email autoresponder - Returns autoresponders configured for the given email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteEmailAutoresponder: async (orgId: string, websiteId: string, emailId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}/autoresponder`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website email client configuration - Returns the client configuration for the given email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteEmailClientConf: async (orgId: string, websiteId: string, emailId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}/client-conf`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website emails - Returns all emails belonging to a website. The results may optionally be sorted and paginated. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteEmails: async (orgId: string, websiteId: string, offset?: number, limit?: number, sortBy?: 'address' | 'mailbox' | 'forwarders' | 'autoresponders' | 'size', sortOrder?: 'asc' | 'desc', search?: string, includeInternal?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (includeInternal !== undefined) {
                localVarQueryParameter['includeInternal'] = includeInternal;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update email local/remote status - Sets the MTA to treat this domain as either local or remote. */
        setDomainLocalRemote: async (orgId: string, websiteId: string, domainId: string, localRemoteBody: LocalRemoteBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/local_remote`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof localRemoteBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(localRemoteBody !== undefined ? localRemoteBody : {}) : (localRemoteBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set spam thresholds for an email address -  */
        setEmailSpamThresholds: async (emailId: string, spamThresholds: SpamThresholds, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/emails/{email_id}/spam_thresholds`
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof spamThresholds !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(spamThresholds !== undefined ? spamThresholds : {}) : (spamThresholds || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update email authentication preferences - Update DKIM setting for the mailboxes on a given domain. */
        updateDomainEmailAuth: async (orgId: string, domainId: string, emailAuthUpdate: EmailAuthUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/email-auth`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof emailAuthUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailAuthUpdate !== undefined ? emailAuthUpdate : {}) : (emailAuthUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update website email - Updates the given email belonging to the given website. The email may have a mailbox or it may be a forwarder, which means it merely serves to forward incoming emails to the specified email addresses. If `hasMailbox` is set to false, the mailbox is deleted if it hadn\'t existed before, and vice versa. The email must either have a mailbox or forwarders an it must always have a delivery route. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWebsiteEmail: async (orgId: string, websiteId: string, emailId: string, updateEmail: UpdateEmail, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/emails/{email_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"email_id"}}`, encodeURIComponent(String(emailId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateEmail !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateEmail !== undefined ? updateEmail : {}) : (updateEmail || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Validate email authentication DNS records - Validate DKIM and SPF. */
        validateDomainEmailAuth: async (orgId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/domains/{domain_id}/email-auth/validate`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createWebsiteEmail operation in EmailsApi. */
export type EmailsApiCreateWebsiteEmailReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** New email details. */
    newEmail: NewEmail
};

/** Response parameters for createWebsiteEmail operation in EmailsApi. */
export type EmailsApiCreateWebsiteEmailRes = NewResourceUuid;


/** Request parameters for createWebsiteEmailAutoresponder operation in EmailsApi. */
export type EmailsApiCreateWebsiteEmailAutoresponderReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
    /** Autoresponder details. */
    newAutoresponder: NewAutoresponder
};

/** Response parameters for createWebsiteEmailAutoresponder operation in EmailsApi. */
export type EmailsApiCreateWebsiteEmailAutoresponderRes = NewResourceId;


/** Request parameters for deleteWebsiteEmail operation in EmailsApi. */
export type EmailsApiDeleteWebsiteEmailReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
};

/** Response parameters for deleteWebsiteEmail operation in EmailsApi. */
export type EmailsApiDeleteWebsiteEmailRes = void;


/** Request parameters for deleteWebsiteEmailAutoresponder operation in EmailsApi. */
export type EmailsApiDeleteWebsiteEmailAutoresponderReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
};

/** Response parameters for deleteWebsiteEmailAutoresponder operation in EmailsApi. */
export type EmailsApiDeleteWebsiteEmailAutoresponderRes = void;


/** Request parameters for getDomainEmailAuth operation in EmailsApi. */
export type EmailsApiGetDomainEmailAuthReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getDomainEmailAuth operation in EmailsApi. */
export type EmailsApiGetDomainEmailAuthRes = EmailAuth;


/** Request parameters for getDomainLocalRemote operation in EmailsApi. */
export type EmailsApiGetDomainLocalRemoteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getDomainLocalRemote operation in EmailsApi. */
export type EmailsApiGetDomainLocalRemoteRes = LocalRemoteBody;


/** Request parameters for getEmailSpamThresholds operation in EmailsApi. */
export type EmailsApiGetEmailSpamThresholdsReq = {
    /** The id of the email. */
    emailId: string
};

/** Response parameters for getEmailSpamThresholds operation in EmailsApi. */
export type EmailsApiGetEmailSpamThresholdsRes = SpamThresholds;


/** Request parameters for getEmails operation in EmailsApi. */
export type EmailsApiGetEmailsReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'domain' | 'subscription' | 'org' | 'size'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Limit the result set to the resources whose names, partially and case insensitively, match the specified search term. E.g. for websites, this is their domain or tag, for databases the database name, for emails the email address or mailbox name, etc. A website will also be returned if the search term exactly matches the website\'s uuid. */
    search?: string
    /** If set to true, the endpoint will return resources in some hierarchy recursively, that is, several or all levels of the hierarchy, depending on whether `maxDepth` is set. E.g. for customers this means direct and indirect customers are returned. For websites, this returns websites of all direct and indirect customers. */
    recursive?: boolean
    /** If recursive is set to true, this can be specified to limit the recursion depth. By default there is no recursion bound. */
    maxDepth?: number
    /** Limit the result set to emails with the specified status. Only applicable if `recursive` is set to true. */
    status?: WebsiteStatus
    /** Limit the result set to emails under domain. */
    domainId?: string
    /** Limit the result set to resources under subscriptions to the plan. */
    planId?: number
    /** Limit the result set to resources under subscription. */
    subscriptionId?: number
    /** Include internal emails in response */
    includeInternal?: boolean
    /** Filters out deleted emails, which are otherwise returned in the result. Defaults to `showDeleted=true` if not set. Can only be set by MO, if set by others, a 403 is returned. */
    showDeleted?: boolean
};

/** Response parameters for getEmails operation in EmailsApi. */
export type EmailsApiGetEmailsRes = EmailsListing;


/** Request parameters for getWebsiteEmail operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
};

/** Response parameters for getWebsiteEmail operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailRes = EmailDetailed;


/** Request parameters for getWebsiteEmailAutoresponder operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailAutoresponderReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
};

/** Response parameters for getWebsiteEmailAutoresponder operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailAutoresponderRes = Autoresponder;


/** Request parameters for getWebsiteEmailClientConf operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailClientConfReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
};

/** Response parameters for getWebsiteEmailClientConf operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailClientConfRes = object;


/** Request parameters for getWebsiteEmails operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'address' | 'mailbox' | 'forwarders' | 'autoresponders' | 'size'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Limit the result set to the resources whose names, partially and case insensitively, match the specified search term. E.g. for websites, this is their domain or tag, for databases the database name, for emails the email address or mailbox name, etc. A website will also be returned if the search term exactly matches the website\'s uuid. */
    search?: string
    /** Include internal emails in response */
    includeInternal?: boolean
};

/** Response parameters for getWebsiteEmails operation in EmailsApi. */
export type EmailsApiGetWebsiteEmailsRes = EmailsListing;


/** Request parameters for setDomainLocalRemote operation in EmailsApi. */
export type EmailsApiSetDomainLocalRemoteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    localRemoteBody: LocalRemoteBody
};

/** Response parameters for setDomainLocalRemote operation in EmailsApi. */
export type EmailsApiSetDomainLocalRemoteRes = void;


/** Request parameters for setEmailSpamThresholds operation in EmailsApi. */
export type EmailsApiSetEmailSpamThresholdsReq = {
    /** The id of the email. */
    emailId: string
    /** Spam thresholds. */
    spamThresholds: SpamThresholds
};

/** Response parameters for setEmailSpamThresholds operation in EmailsApi. */
export type EmailsApiSetEmailSpamThresholdsRes = void;


/** Request parameters for updateDomainEmailAuth operation in EmailsApi. */
export type EmailsApiUpdateDomainEmailAuthReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
    /** Email auth details. */
    emailAuthUpdate: EmailAuthUpdate
};

/** Response parameters for updateDomainEmailAuth operation in EmailsApi. */
export type EmailsApiUpdateDomainEmailAuthRes = void;


/** Request parameters for updateWebsiteEmail operation in EmailsApi. */
export type EmailsApiUpdateWebsiteEmailReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the email. */
    emailId: string
    /** Email update details. */
    updateEmail: UpdateEmail
};

/** Response parameters for updateWebsiteEmail operation in EmailsApi. */
export type EmailsApiUpdateWebsiteEmailRes = void;


/** Request parameters for validateDomainEmailAuth operation in EmailsApi. */
export type EmailsApiValidateDomainEmailAuthReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for validateDomainEmailAuth operation in EmailsApi. */
export type EmailsApiValidateDomainEmailAuthRes = EmailAuthValidation;

/**
 * EmailsApi - service client interface
 */
export const EmailsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create an email under website\'s domain - Creates a new email under the given website. The email may have a mailbox or it may be a forwarder, which means it merely serves to forward incoming emails to the specified email addresses. If a password is supplied, a mailbox is created. Otherwise, forwarders must be specified as an email must always have a delivery route. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteEmail: async (reqParams: EmailsApiCreateWebsiteEmailReq, options?: any): Promise<AxiosResponse<EmailsApiCreateWebsiteEmailRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).createWebsiteEmail(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.newEmail, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create new website email autoresponder - Creates a new autoresponder for the given email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteEmailAutoresponder: async (reqParams: EmailsApiCreateWebsiteEmailAutoresponderReq, options?: any): Promise<AxiosResponse<EmailsApiCreateWebsiteEmailAutoresponderRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).createWebsiteEmailAutoresponder(reqParams.orgId, reqParams.websiteId, reqParams.emailId, reqParams.newAutoresponder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website email - Deletes the email belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteEmail: async (reqParams: EmailsApiDeleteWebsiteEmailReq, options?: any): Promise<AxiosResponse<EmailsApiDeleteWebsiteEmailRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).deleteWebsiteEmail(reqParams.orgId, reqParams.websiteId, reqParams.emailId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website email autoresponder - Deletes the autoresponder belonging to the given website email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteEmailAutoresponder: async (reqParams: EmailsApiDeleteWebsiteEmailAutoresponderReq, options?: any): Promise<AxiosResponse<EmailsApiDeleteWebsiteEmailAutoresponderRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).deleteWebsiteEmailAutoresponder(reqParams.orgId, reqParams.websiteId, reqParams.emailId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get email authentication preferences - Fetch DKIM setting for the mailboxes on a given domain. */
    getDomainEmailAuth: async (reqParams: EmailsApiGetDomainEmailAuthReq, options?: any): Promise<AxiosResponse<EmailsApiGetDomainEmailAuthRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getDomainEmailAuth(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the current local/remote status - Fetches the current status of the domain, whether it is treated as local or remote by the MTA */
    getDomainLocalRemote: async (reqParams: EmailsApiGetDomainLocalRemoteReq, options?: any): Promise<AxiosResponse<EmailsApiGetDomainLocalRemoteRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getDomainLocalRemote(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get spam thresholds for an email address -  */
    getEmailSpamThresholds: async (reqParams: EmailsApiGetEmailSpamThresholdsReq, options?: any): Promise<AxiosResponse<EmailsApiGetEmailSpamThresholdsRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getEmailSpamThresholds(reqParams.emailId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get org emails - Returns all emails belonging to this organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getEmails: async (reqParams: EmailsApiGetEmailsReq, options?: any): Promise<AxiosResponse<EmailsApiGetEmailsRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getEmails(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.search, reqParams.recursive, reqParams.maxDepth, reqParams.status, reqParams.domainId, reqParams.planId, reqParams.subscriptionId, reqParams.includeInternal, reqParams.showDeleted, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website email - Returns details about the given email belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteEmail: async (reqParams: EmailsApiGetWebsiteEmailReq, options?: any): Promise<AxiosResponse<EmailsApiGetWebsiteEmailRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getWebsiteEmail(reqParams.orgId, reqParams.websiteId, reqParams.emailId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website email autoresponder - Returns autoresponders configured for the given email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteEmailAutoresponder: async (reqParams: EmailsApiGetWebsiteEmailAutoresponderReq, options?: any): Promise<AxiosResponse<EmailsApiGetWebsiteEmailAutoresponderRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getWebsiteEmailAutoresponder(reqParams.orgId, reqParams.websiteId, reqParams.emailId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website email client configuration - Returns the client configuration for the given email. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteEmailClientConf: async (reqParams: EmailsApiGetWebsiteEmailClientConfReq, options?: any): Promise<AxiosResponse<EmailsApiGetWebsiteEmailClientConfRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getWebsiteEmailClientConf(reqParams.orgId, reqParams.websiteId, reqParams.emailId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website emails - Returns all emails belonging to a website. The results may optionally be sorted and paginated. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteEmails: async (reqParams: EmailsApiGetWebsiteEmailsReq, options?: any): Promise<AxiosResponse<EmailsApiGetWebsiteEmailsRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).getWebsiteEmails(reqParams.orgId, reqParams.websiteId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.search, reqParams.includeInternal, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update email local/remote status - Sets the MTA to treat this domain as either local or remote. */
    setDomainLocalRemote: async (reqParams: EmailsApiSetDomainLocalRemoteReq, options?: any): Promise<AxiosResponse<EmailsApiSetDomainLocalRemoteRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).setDomainLocalRemote(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.localRemoteBody, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set spam thresholds for an email address -  */
    setEmailSpamThresholds: async (reqParams: EmailsApiSetEmailSpamThresholdsReq, options?: any): Promise<AxiosResponse<EmailsApiSetEmailSpamThresholdsRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).setEmailSpamThresholds(reqParams.emailId, reqParams.spamThresholds, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update email authentication preferences - Update DKIM setting for the mailboxes on a given domain. */
    updateDomainEmailAuth: async (reqParams: EmailsApiUpdateDomainEmailAuthReq, options?: any): Promise<AxiosResponse<EmailsApiUpdateDomainEmailAuthRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).updateDomainEmailAuth(reqParams.orgId, reqParams.domainId, reqParams.emailAuthUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update website email - Updates the given email belonging to the given website. The email may have a mailbox or it may be a forwarder, which means it merely serves to forward incoming emails to the specified email addresses. If `hasMailbox` is set to false, the mailbox is deleted if it hadn\'t existed before, and vice versa. The email must either have a mailbox or forwarders an it must always have a delivery route. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWebsiteEmail: async (reqParams: EmailsApiUpdateWebsiteEmailReq, options?: any): Promise<AxiosResponse<EmailsApiUpdateWebsiteEmailRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).updateWebsiteEmail(reqParams.orgId, reqParams.websiteId, reqParams.emailId, reqParams.updateEmail, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Validate email authentication DNS records - Validate DKIM and SPF. */
    validateDomainEmailAuth: async (reqParams: EmailsApiValidateDomainEmailAuthReq, options?: any): Promise<AxiosResponse<EmailsApiValidateDomainEmailAuthRes>> => {
        const localVarAxiosArgs = await EmailsApiAxiosParamCreator(configuration).validateDomainEmailAuth(reqParams.orgId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface EmailsApiReq {
    createWebsiteEmail: EmailsApiCreateWebsiteEmailReq;
    createWebsiteEmailAutoresponder: EmailsApiCreateWebsiteEmailAutoresponderReq;
    deleteWebsiteEmail: EmailsApiDeleteWebsiteEmailReq;
    deleteWebsiteEmailAutoresponder: EmailsApiDeleteWebsiteEmailAutoresponderReq;
    getDomainEmailAuth: EmailsApiGetDomainEmailAuthReq;
    getDomainLocalRemote: EmailsApiGetDomainLocalRemoteReq;
    getEmailSpamThresholds: EmailsApiGetEmailSpamThresholdsReq;
    getEmails: EmailsApiGetEmailsReq;
    getWebsiteEmail: EmailsApiGetWebsiteEmailReq;
    getWebsiteEmailAutoresponder: EmailsApiGetWebsiteEmailAutoresponderReq;
    getWebsiteEmailClientConf: EmailsApiGetWebsiteEmailClientConfReq;
    getWebsiteEmails: EmailsApiGetWebsiteEmailsReq;
    setDomainLocalRemote: EmailsApiSetDomainLocalRemoteReq;
    setEmailSpamThresholds: EmailsApiSetEmailSpamThresholdsReq;
    updateDomainEmailAuth: EmailsApiUpdateDomainEmailAuthReq;
    updateWebsiteEmail: EmailsApiUpdateWebsiteEmailReq;
    validateDomainEmailAuth: EmailsApiValidateDomainEmailAuthReq;
}

export interface EmailsApiRes {
    createWebsiteEmail: EmailsApiCreateWebsiteEmailRes;
    createWebsiteEmailAutoresponder: EmailsApiCreateWebsiteEmailAutoresponderRes;
    deleteWebsiteEmail: EmailsApiDeleteWebsiteEmailRes;
    deleteWebsiteEmailAutoresponder: EmailsApiDeleteWebsiteEmailAutoresponderRes;
    getDomainEmailAuth: EmailsApiGetDomainEmailAuthRes;
    getDomainLocalRemote: EmailsApiGetDomainLocalRemoteRes;
    getEmailSpamThresholds: EmailsApiGetEmailSpamThresholdsRes;
    getEmails: EmailsApiGetEmailsRes;
    getWebsiteEmail: EmailsApiGetWebsiteEmailRes;
    getWebsiteEmailAutoresponder: EmailsApiGetWebsiteEmailAutoresponderRes;
    getWebsiteEmailClientConf: EmailsApiGetWebsiteEmailClientConfRes;
    getWebsiteEmails: EmailsApiGetWebsiteEmailsRes;
    setDomainLocalRemote: EmailsApiSetDomainLocalRemoteRes;
    setEmailSpamThresholds: EmailsApiSetEmailSpamThresholdsRes;
    updateDomainEmailAuth: EmailsApiUpdateDomainEmailAuthRes;
    updateWebsiteEmail: EmailsApiUpdateWebsiteEmailRes;
    validateDomainEmailAuth: EmailsApiValidateDomainEmailAuthRes;
}


/**
 * FtpApi - axios parameter creator
 * @export
 */
export const FtpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Creates a new FTP user for a given website - Endpoint for creating a new FTP user. NOTE: user.account well get appended with website\'s primary domain. i.e. `username` will become `username@domain.com` Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createFtpUser: async (orgId: string, websiteId: string, newFtpUser: NewFtpUser, createHome?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (createHome !== undefined) {
                localVarQueryParameter['createHome'] = createHome;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newFtpUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newFtpUser !== undefined ? newFtpUser : {}) : (newFtpUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes given FTP user - Endpoint for deleting FTP user for a given website. User homeDir can only be deleted if it is a subdir for the website home. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteFtpUser: async (orgId: string, websiteId: string, username: string, deleteHome?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users/{username}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (deleteHome !== undefined) {
                localVarQueryParameter['deleteHome'] = deleteHome;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns all ftp users data for a given website - Endpoint for retreaving ftp users for a given website Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getFtpUsers: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update given FTP user - Endpoint for updating FTP user for a given website We only allow user\'s homeDir and password to be updated. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateFtpUser: async (orgId: string, websiteId: string, username: string, ftpUserUpdate: FtpUserUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users/{username}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof ftpUserUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ftpUserUpdate !== undefined ? ftpUserUpdate : {}) : (ftpUserUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createFtpUser operation in FtpApi. */
export type FtpApiCreateFtpUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** FTP User */
    newFtpUser: NewFtpUser
    /** If set to true we will try to crete a new home_dir for the user if does not exist. */
    createHome?: boolean
};

/** Response parameters for createFtpUser operation in FtpApi. */
export type FtpApiCreateFtpUserRes = NewResourceUuid;


/** Request parameters for deleteFtpUser operation in FtpApi. */
export type FtpApiDeleteFtpUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    username: string
    /** If set to true we will try to delete the homeDir for the user. User homeDir can only be deleted if it is a subdir for the website home. */
    deleteHome?: boolean
};

/** Response parameters for deleteFtpUser operation in FtpApi. */
export type FtpApiDeleteFtpUserRes = void;


/** Request parameters for getFtpUsers operation in FtpApi. */
export type FtpApiGetFtpUsersReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getFtpUsers operation in FtpApi. */
export type FtpApiGetFtpUsersRes = FtpUsersFullListing;


/** Request parameters for updateFtpUser operation in FtpApi. */
export type FtpApiUpdateFtpUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    username: string
    /** FTP User */
    ftpUserUpdate: FtpUserUpdate
};

/** Response parameters for updateFtpUser operation in FtpApi. */
export type FtpApiUpdateFtpUserRes = void;

/**
 * FtpApi - service client interface
 */
export const FtpApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Creates a new FTP user for a given website - Endpoint for creating a new FTP user. NOTE: user.account well get appended with website\'s primary domain. i.e. `username` will become `username@domain.com` Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createFtpUser: async (reqParams: FtpApiCreateFtpUserReq, options?: any): Promise<AxiosResponse<FtpApiCreateFtpUserRes>> => {
        const localVarAxiosArgs = await FtpApiAxiosParamCreator(configuration).createFtpUser(reqParams.orgId, reqParams.websiteId, reqParams.newFtpUser, reqParams.createHome, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes given FTP user - Endpoint for deleting FTP user for a given website. User homeDir can only be deleted if it is a subdir for the website home. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteFtpUser: async (reqParams: FtpApiDeleteFtpUserReq, options?: any): Promise<AxiosResponse<FtpApiDeleteFtpUserRes>> => {
        const localVarAxiosArgs = await FtpApiAxiosParamCreator(configuration).deleteFtpUser(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.deleteHome, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns all ftp users data for a given website - Endpoint for retreaving ftp users for a given website Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getFtpUsers: async (reqParams: FtpApiGetFtpUsersReq, options?: any): Promise<AxiosResponse<FtpApiGetFtpUsersRes>> => {
        const localVarAxiosArgs = await FtpApiAxiosParamCreator(configuration).getFtpUsers(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update given FTP user - Endpoint for updating FTP user for a given website We only allow user\'s homeDir and password to be updated. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateFtpUser: async (reqParams: FtpApiUpdateFtpUserReq, options?: any): Promise<AxiosResponse<FtpApiUpdateFtpUserRes>> => {
        const localVarAxiosArgs = await FtpApiAxiosParamCreator(configuration).updateFtpUser(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.ftpUserUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface FtpApiReq {
    createFtpUser: FtpApiCreateFtpUserReq;
    deleteFtpUser: FtpApiDeleteFtpUserReq;
    getFtpUsers: FtpApiGetFtpUsersReq;
    updateFtpUser: FtpApiUpdateFtpUserReq;
}

export interface FtpApiRes {
    createFtpUser: FtpApiCreateFtpUserRes;
    deleteFtpUser: FtpApiDeleteFtpUserRes;
    getFtpUsers: FtpApiGetFtpUsersRes;
    updateFtpUser: FtpApiUpdateFtpUserRes;
}


/**
 * ImportersApi - axios parameter creator
 * @export
 */
export const ImportersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Analyze imported migration - Analyze import and store results into database.  Note: the endpoint returns immediately, and you have to poll status via `getImportMigration` endpoint. If you want to see detailed error, please call `getImportMigrationLog`.  */
        analyzeImportMigration: async (orgId: string, importMigrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}/analyze`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Check if all resources from the imported migration could be created. - Check if all resources from the imported migration could be created.  If all resources could be created, 200 is returned with an empty `ResourceCheckError`. However, if any error occurs, 200 is returned with a non-empty `ResourceCheckError`.  In case of import failure, you can rerun importing by setting forceQueue to true. Before doing so, it\'s required to remove any already imported resources. Otherwise, the import will fail.  */
        checkImportMigrationResources: async (orgId: string, importMigrationId: string, importerMigrationReqBody?: ImporterMigrationReqBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}/resource`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof importerMigrationReqBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(importerMigrationReqBody !== undefined ? importerMigrationReqBody : {}) : (importerMigrationReqBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a new import migration. - Create a new import migration for a given import type. */
        createImportMigration: async (orgId: string, importMigrationId: string, importerMigrationReqBody?: ImporterMigrationReqBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof importerMigrationReqBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(importerMigrationReqBody !== undefined ? importerMigrationReqBody : {}) : (importerMigrationReqBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create settings for the server import - Create settings for the server import. */
        createImportServerSettings: async (orgId: string, newImportServerSettings: NewImportServerSettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newImportServerSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newImportServerSettings !== undefined ? newImportServerSettings : {}) : (newImportServerSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete single migration - Delete a single migration with the uploaded file. */
        deleteImportMigration: async (orgId: string, importMigrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete settings for the server import - Delete settings for the server import. */
        deleteImportServerSettings: async (orgId: string, serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/{server_id}/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Fetches single migration details -  */
        getImportMigration: async (orgId: string, importMigrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get import migration information - Get analyzed informations about import.  Information contains details about domains, ftp users, databases, crontabs and mailboxes.  */
        getImportMigrationData: async (orgId: string, importMigrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}/analyze`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the log for an import migration - Fetches the import migration log for a single import migration. */
        getImportMigrationLog: async (orgId: string, importMigrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/{import_migration_id}/log`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_id"}}`, encodeURIComponent(String(importMigrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** List all import migrations -  */
        getImportMigrations: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns cached domains - Returns cached domains. */
        getImportServerDomainsCached: async (orgId: string, serverId: string, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', searchDomain?: string, sortBy?: 'domain' | 'user' | 'kind', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/{server_id}/cached-domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (searchDomain !== undefined) {
                localVarQueryParameter['searchDomain'] = searchDomain;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Pull domains form the remote server. - Pull domains form the remote server, and cache them. To get cached domains, use getImportServerDomainsCached. */
        getImportServerPullDomains: async (orgId: string, serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/{server_id}/pull-domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get settings for the server import - Get settings for the server import. Note: returned data does not contain ssh private key or any passwords/tokens. */
        getImportServerSettings: async (orgId: string, serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/{server_id}/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** List all server import settings -  */
        listImportServerSettings: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Scan for manually uploaded cPanel backups. - Will scan /var/local/enhance/orchd/importer for files matching cpmove*.tar.gz or backup*.tar.gz and will add them to the importer database for analysis.  An array of migration IDs will be returned. Master organisation only.  */
        scanImportMigrations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/import/scan`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Transfer user account from remote cPanel server - Transfer user account from remote cPanel server to Enhance server. It\'s an async endpoint. To get transfer result, you have to call getImportMigration. */
        transferCPanelUserAccount: async (orgId: string, serverId: string, userId: string, transferUserAccountReqBody?: TransferUserAccountReqBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/{server_id}/account/{user_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof transferUserAccountReqBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(transferUserAccountReqBody !== undefined ? transferUserAccountReqBody : {}) : (transferUserAccountReqBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Transfer user account from remote cPanel server - Transfer user account from remote Plesk server to Enhance server. It\'s an async endpoint. To get transfer result, you have to call getImportMigration. */
        transferPleskDomain: async (orgId: string, serverId: string, transferPleskDomainReqBody?: TransferPleskDomainReqBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/plesk-server/{server_id}/domain`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof transferPleskDomainReqBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(transferPleskDomainReqBody !== undefined ? transferPleskDomainReqBody : {}) : (transferPleskDomainReqBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update settings for the server import - Update settings for the server import. */
        updateImportServerSettings: async (orgId: string, serverId: string, updateImportServerSettings: UpdateImportServerSettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/import/server/{server_id}/settings`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateImportServerSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateImportServerSettings !== undefined ? updateImportServerSettings : {}) : (updateImportServerSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload file for analyzing and processing. - Uploads an import file. File must be in `tar.gz` format, and only cPanel and Plesk uploads are allowed.  The max allowed size is 100 GB.  */
        uploadImportMigration: async (orgId: string, importMigrationKind: 'cpanel' | 'plesk', backup?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/import/upload/{import_migration_kind}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"import_migration_kind"}}`, encodeURIComponent(String(importMigrationKind)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (backup !== undefined) { 
                localVarFormParams.append('backup', backup as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for analyzeImportMigration operation in ImportersApi. */
export type ImportersApiAnalyzeImportMigrationReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
};

/** Response parameters for analyzeImportMigration operation in ImportersApi. */
export type ImportersApiAnalyzeImportMigrationRes = void;


/** Request parameters for checkImportMigrationResources operation in ImportersApi. */
export type ImportersApiCheckImportMigrationResourcesReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
    importerMigrationReqBody?: ImporterMigrationReqBody
};

/** Response parameters for checkImportMigrationResources operation in ImportersApi. */
export type ImportersApiCheckImportMigrationResourcesRes = ResourceCheckError;


/** Request parameters for createImportMigration operation in ImportersApi. */
export type ImportersApiCreateImportMigrationReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
    importerMigrationReqBody?: ImporterMigrationReqBody
};

/** Response parameters for createImportMigration operation in ImportersApi. */
export type ImportersApiCreateImportMigrationRes = void;


/** Request parameters for createImportServerSettings operation in ImportersApi. */
export type ImportersApiCreateImportServerSettingsReq = {
    /** The id of the organization. */
    orgId: string
    newImportServerSettings: NewImportServerSettings
};

/** Response parameters for createImportServerSettings operation in ImportersApi. */
export type ImportersApiCreateImportServerSettingsRes = NewResourceUuid;


/** Request parameters for deleteImportMigration operation in ImportersApi. */
export type ImportersApiDeleteImportMigrationReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
};

/** Response parameters for deleteImportMigration operation in ImportersApi. */
export type ImportersApiDeleteImportMigrationRes = void;


/** Request parameters for deleteImportServerSettings operation in ImportersApi. */
export type ImportersApiDeleteImportServerSettingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for deleteImportServerSettings operation in ImportersApi. */
export type ImportersApiDeleteImportServerSettingsRes = void;


/** Request parameters for getImportMigration operation in ImportersApi. */
export type ImportersApiGetImportMigrationReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
};

/** Response parameters for getImportMigration operation in ImportersApi. */
export type ImportersApiGetImportMigrationRes = ImportMigrationEntry;


/** Request parameters for getImportMigrationData operation in ImportersApi. */
export type ImportersApiGetImportMigrationDataReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
};

/** Response parameters for getImportMigrationData operation in ImportersApi. */
export type ImportersApiGetImportMigrationDataRes = ImporterAnalyzedData;


/** Request parameters for getImportMigrationLog operation in ImportersApi. */
export type ImportersApiGetImportMigrationLogReq = {
    /** The id of the organization. */
    orgId: string
    /** The ID of the import migration being acted upon. */
    importMigrationId: string
};

/** Response parameters for getImportMigrationLog operation in ImportersApi. */
export type ImportersApiGetImportMigrationLogRes = Array<ImportMigrationLogEntry>;


/** Request parameters for getImportMigrations operation in ImportersApi. */
export type ImportersApiGetImportMigrationsReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getImportMigrations operation in ImportersApi. */
export type ImportersApiGetImportMigrationsRes = ImportMigrationFullListing;


/** Request parameters for getImportServerDomainsCached operation in ImportersApi. */
export type ImportersApiGetImportServerDomainsCachedReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Look for a specific domain in the result set. */
    searchDomain?: string
    /** The field by which to sort. */
    sortBy?: 'domain' | 'user' | 'kind'
};

/** Response parameters for getImportServerDomainsCached operation in ImportersApi. */
export type ImportersApiGetImportServerDomainsCachedRes = ImportServerDomainsListing;


/** Request parameters for getImportServerPullDomains operation in ImportersApi. */
export type ImportersApiGetImportServerPullDomainsReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getImportServerPullDomains operation in ImportersApi. */
export type ImportersApiGetImportServerPullDomainsRes = ImportServerDomainsFullListing;


/** Request parameters for getImportServerSettings operation in ImportersApi. */
export type ImportersApiGetImportServerSettingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getImportServerSettings operation in ImportersApi. */
export type ImportersApiGetImportServerSettingsRes = ImportServerSettings;


/** Request parameters for listImportServerSettings operation in ImportersApi. */
export type ImportersApiListImportServerSettingsReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for listImportServerSettings operation in ImportersApi. */
export type ImportersApiListImportServerSettingsRes = ImportServerSettingsFullListing;


/** Request parameters for scanImportMigrations operation in ImportersApi. */
export type ImportersApiScanImportMigrationsReq = {
};

/** Response parameters for scanImportMigrations operation in ImportersApi. */
export type ImportersApiScanImportMigrationsRes = Array<string>;


/** Request parameters for transferCPanelUserAccount operation in ImportersApi. */
export type ImportersApiTransferCPanelUserAccountReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
    /** The ID of the remote cPanel user */
    userId: string
    transferUserAccountReqBody?: TransferUserAccountReqBody
};

/** Response parameters for transferCPanelUserAccount operation in ImportersApi. */
export type ImportersApiTransferCPanelUserAccountRes = NewResourceUuid;


/** Request parameters for transferPleskDomain operation in ImportersApi. */
export type ImportersApiTransferPleskDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
    transferPleskDomainReqBody?: TransferPleskDomainReqBody
};

/** Response parameters for transferPleskDomain operation in ImportersApi. */
export type ImportersApiTransferPleskDomainRes = NewResourceUuid;


/** Request parameters for updateImportServerSettings operation in ImportersApi. */
export type ImportersApiUpdateImportServerSettingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The UUID of the server */
    serverId: string
    updateImportServerSettings: UpdateImportServerSettings
};

/** Response parameters for updateImportServerSettings operation in ImportersApi. */
export type ImportersApiUpdateImportServerSettingsRes = void;


/** Request parameters for uploadImportMigration operation in ImportersApi. */
export type ImportersApiUploadImportMigrationReq = {
    /** The id of the organization. */
    orgId: string
    /** The type of migration file being uploaded. */
    importMigrationKind: 'cpanel' | 'plesk'
    backup?: any
};

/** Response parameters for uploadImportMigration operation in ImportersApi. */
export type ImportersApiUploadImportMigrationRes = NewResourceUuid;

/**
 * ImportersApi - service client interface
 */
export const ImportersApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Analyze imported migration - Analyze import and store results into database.  Note: the endpoint returns immediately, and you have to poll status via `getImportMigration` endpoint. If you want to see detailed error, please call `getImportMigrationLog`.  */
    analyzeImportMigration: async (reqParams: ImportersApiAnalyzeImportMigrationReq, options?: any): Promise<AxiosResponse<ImportersApiAnalyzeImportMigrationRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).analyzeImportMigration(reqParams.orgId, reqParams.importMigrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Check if all resources from the imported migration could be created. - Check if all resources from the imported migration could be created.  If all resources could be created, 200 is returned with an empty `ResourceCheckError`. However, if any error occurs, 200 is returned with a non-empty `ResourceCheckError`.  In case of import failure, you can rerun importing by setting forceQueue to true. Before doing so, it\'s required to remove any already imported resources. Otherwise, the import will fail.  */
    checkImportMigrationResources: async (reqParams: ImportersApiCheckImportMigrationResourcesReq, options?: any): Promise<AxiosResponse<ImportersApiCheckImportMigrationResourcesRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).checkImportMigrationResources(reqParams.orgId, reqParams.importMigrationId, reqParams.importerMigrationReqBody, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a new import migration. - Create a new import migration for a given import type. */
    createImportMigration: async (reqParams: ImportersApiCreateImportMigrationReq, options?: any): Promise<AxiosResponse<ImportersApiCreateImportMigrationRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).createImportMigration(reqParams.orgId, reqParams.importMigrationId, reqParams.importerMigrationReqBody, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create settings for the server import - Create settings for the server import. */
    createImportServerSettings: async (reqParams: ImportersApiCreateImportServerSettingsReq, options?: any): Promise<AxiosResponse<ImportersApiCreateImportServerSettingsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).createImportServerSettings(reqParams.orgId, reqParams.newImportServerSettings, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete single migration - Delete a single migration with the uploaded file. */
    deleteImportMigration: async (reqParams: ImportersApiDeleteImportMigrationReq, options?: any): Promise<AxiosResponse<ImportersApiDeleteImportMigrationRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).deleteImportMigration(reqParams.orgId, reqParams.importMigrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete settings for the server import - Delete settings for the server import. */
    deleteImportServerSettings: async (reqParams: ImportersApiDeleteImportServerSettingsReq, options?: any): Promise<AxiosResponse<ImportersApiDeleteImportServerSettingsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).deleteImportServerSettings(reqParams.orgId, reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Fetches single migration details -  */
    getImportMigration: async (reqParams: ImportersApiGetImportMigrationReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportMigrationRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportMigration(reqParams.orgId, reqParams.importMigrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get import migration information - Get analyzed informations about import.  Information contains details about domains, ftp users, databases, crontabs and mailboxes.  */
    getImportMigrationData: async (reqParams: ImportersApiGetImportMigrationDataReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportMigrationDataRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportMigrationData(reqParams.orgId, reqParams.importMigrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the log for an import migration - Fetches the import migration log for a single import migration. */
    getImportMigrationLog: async (reqParams: ImportersApiGetImportMigrationLogReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportMigrationLogRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportMigrationLog(reqParams.orgId, reqParams.importMigrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** List all import migrations -  */
    getImportMigrations: async (reqParams: ImportersApiGetImportMigrationsReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportMigrationsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportMigrations(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns cached domains - Returns cached domains. */
    getImportServerDomainsCached: async (reqParams: ImportersApiGetImportServerDomainsCachedReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportServerDomainsCachedRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportServerDomainsCached(reqParams.orgId, reqParams.serverId, reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.searchDomain, reqParams.sortBy, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Pull domains form the remote server. - Pull domains form the remote server, and cache them. To get cached domains, use getImportServerDomainsCached. */
    getImportServerPullDomains: async (reqParams: ImportersApiGetImportServerPullDomainsReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportServerPullDomainsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportServerPullDomains(reqParams.orgId, reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get settings for the server import - Get settings for the server import. Note: returned data does not contain ssh private key or any passwords/tokens. */
    getImportServerSettings: async (reqParams: ImportersApiGetImportServerSettingsReq, options?: any): Promise<AxiosResponse<ImportersApiGetImportServerSettingsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).getImportServerSettings(reqParams.orgId, reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** List all server import settings -  */
    listImportServerSettings: async (reqParams: ImportersApiListImportServerSettingsReq, options?: any): Promise<AxiosResponse<ImportersApiListImportServerSettingsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).listImportServerSettings(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Scan for manually uploaded cPanel backups. - Will scan /var/local/enhance/orchd/importer for files matching cpmove*.tar.gz or backup*.tar.gz and will add them to the importer database for analysis.  An array of migration IDs will be returned. Master organisation only.  */
    scanImportMigrations: async (reqParams: ImportersApiScanImportMigrationsReq, options?: any): Promise<AxiosResponse<ImportersApiScanImportMigrationsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).scanImportMigrations(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Transfer user account from remote cPanel server - Transfer user account from remote cPanel server to Enhance server. It\'s an async endpoint. To get transfer result, you have to call getImportMigration. */
    transferCPanelUserAccount: async (reqParams: ImportersApiTransferCPanelUserAccountReq, options?: any): Promise<AxiosResponse<ImportersApiTransferCPanelUserAccountRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).transferCPanelUserAccount(reqParams.orgId, reqParams.serverId, reqParams.userId, reqParams.transferUserAccountReqBody, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Transfer user account from remote cPanel server - Transfer user account from remote Plesk server to Enhance server. It\'s an async endpoint. To get transfer result, you have to call getImportMigration. */
    transferPleskDomain: async (reqParams: ImportersApiTransferPleskDomainReq, options?: any): Promise<AxiosResponse<ImportersApiTransferPleskDomainRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).transferPleskDomain(reqParams.orgId, reqParams.serverId, reqParams.transferPleskDomainReqBody, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update settings for the server import - Update settings for the server import. */
    updateImportServerSettings: async (reqParams: ImportersApiUpdateImportServerSettingsReq, options?: any): Promise<AxiosResponse<ImportersApiUpdateImportServerSettingsRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).updateImportServerSettings(reqParams.orgId, reqParams.serverId, reqParams.updateImportServerSettings, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload file for analyzing and processing. - Uploads an import file. File must be in `tar.gz` format, and only cPanel and Plesk uploads are allowed.  The max allowed size is 100 GB.  */
    uploadImportMigration: async (reqParams: ImportersApiUploadImportMigrationReq, options?: any): Promise<AxiosResponse<ImportersApiUploadImportMigrationRes>> => {
        const localVarAxiosArgs = await ImportersApiAxiosParamCreator(configuration).uploadImportMigration(reqParams.orgId, reqParams.importMigrationKind, reqParams.backup, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface ImportersApiReq {
    analyzeImportMigration: ImportersApiAnalyzeImportMigrationReq;
    checkImportMigrationResources: ImportersApiCheckImportMigrationResourcesReq;
    createImportMigration: ImportersApiCreateImportMigrationReq;
    createImportServerSettings: ImportersApiCreateImportServerSettingsReq;
    deleteImportMigration: ImportersApiDeleteImportMigrationReq;
    deleteImportServerSettings: ImportersApiDeleteImportServerSettingsReq;
    getImportMigration: ImportersApiGetImportMigrationReq;
    getImportMigrationData: ImportersApiGetImportMigrationDataReq;
    getImportMigrationLog: ImportersApiGetImportMigrationLogReq;
    getImportMigrations: ImportersApiGetImportMigrationsReq;
    getImportServerDomainsCached: ImportersApiGetImportServerDomainsCachedReq;
    getImportServerPullDomains: ImportersApiGetImportServerPullDomainsReq;
    getImportServerSettings: ImportersApiGetImportServerSettingsReq;
    listImportServerSettings: ImportersApiListImportServerSettingsReq;
    scanImportMigrations: ImportersApiScanImportMigrationsReq;
    transferCPanelUserAccount: ImportersApiTransferCPanelUserAccountReq;
    transferPleskDomain: ImportersApiTransferPleskDomainReq;
    updateImportServerSettings: ImportersApiUpdateImportServerSettingsReq;
    uploadImportMigration: ImportersApiUploadImportMigrationReq;
}

export interface ImportersApiRes {
    analyzeImportMigration: ImportersApiAnalyzeImportMigrationRes;
    checkImportMigrationResources: ImportersApiCheckImportMigrationResourcesRes;
    createImportMigration: ImportersApiCreateImportMigrationRes;
    createImportServerSettings: ImportersApiCreateImportServerSettingsRes;
    deleteImportMigration: ImportersApiDeleteImportMigrationRes;
    deleteImportServerSettings: ImportersApiDeleteImportServerSettingsRes;
    getImportMigration: ImportersApiGetImportMigrationRes;
    getImportMigrationData: ImportersApiGetImportMigrationDataRes;
    getImportMigrationLog: ImportersApiGetImportMigrationLogRes;
    getImportMigrations: ImportersApiGetImportMigrationsRes;
    getImportServerDomainsCached: ImportersApiGetImportServerDomainsCachedRes;
    getImportServerPullDomains: ImportersApiGetImportServerPullDomainsRes;
    getImportServerSettings: ImportersApiGetImportServerSettingsRes;
    listImportServerSettings: ImportersApiListImportServerSettingsRes;
    scanImportMigrations: ImportersApiScanImportMigrationsRes;
    transferCPanelUserAccount: ImportersApiTransferCPanelUserAccountRes;
    transferPleskDomain: ImportersApiTransferPleskDomainRes;
    updateImportServerSettings: ImportersApiUpdateImportServerSettingsRes;
    uploadImportMigration: ImportersApiUploadImportMigrationRes;
}


/**
 * InstallApi - axios parameter creator
 * @export
 */
export const InstallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Get the readiness status of the orchd service -  */
        orchdStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the SemVer of the API service -  */
        orchdVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for orchdStatus operation in InstallApi. */
export type InstallApiOrchdStatusReq = {
};

/** Response parameters for orchdStatus operation in InstallApi. */
export type InstallApiOrchdStatusRes = string;


/** Request parameters for orchdVersion operation in InstallApi. */
export type InstallApiOrchdVersionReq = {
};

/** Response parameters for orchdVersion operation in InstallApi. */
export type InstallApiOrchdVersionRes = string;

/**
 * InstallApi - service client interface
 */
export const InstallApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Get the readiness status of the orchd service -  */
    orchdStatus: async (reqParams: InstallApiOrchdStatusReq, options?: any): Promise<AxiosResponse<InstallApiOrchdStatusRes>> => {
        const localVarAxiosArgs = await InstallApiAxiosParamCreator(configuration).orchdStatus(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the SemVer of the API service -  */
    orchdVersion: async (reqParams: InstallApiOrchdVersionReq, options?: any): Promise<AxiosResponse<InstallApiOrchdVersionRes>> => {
        const localVarAxiosArgs = await InstallApiAxiosParamCreator(configuration).orchdVersion(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface InstallApiReq {
    orchdStatus: InstallApiOrchdStatusReq;
    orchdVersion: InstallApiOrchdVersionReq;
}

export interface InstallApiRes {
    orchdStatus: InstallApiOrchdStatusRes;
    orchdVersion: InstallApiOrchdVersionRes;
}


/**
 * InvitesApi - axios parameter creator
 * @export
 */
export const InvitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Accept invite - Accepts the invite. There are four cases that need to be handled: No login session is present, user is newly invited and does not yet have a password. The password submitted in the body is set on the new user and the login\'s member is marked as active within the organization. A session is created and set in the returned cookie. No login session is present but user already exists and has a password. The password submitted in the request body is used to validate the login and the login\'s member is marked as active within the organization. A session is created and set in the returned cookie. A login session is present for the user to whom the invite belongs. The user is marked active within the organisation to which it was invited. A login session is present for a login to whom the invite does not belong, resulting in an error. */
        acceptInvite: async (inviteId: string, acceptInviteBody?: AcceptInviteBody, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/invites/{invite_id}`
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof acceptInviteBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(acceptInviteBody !== undefined ? acceptInviteBody : {}) : (acceptInviteBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create invite - Issues an invite for the user with the given email. The user may or may not have an account in the realm that the organization is in: If the user is not registered yet, a pending login entry is created for them along with a pending member entry, and the login is \"activated\" once the user accepts the invite. In this case they need to supply a password. If the user is already registered, their existing login is linked to a pending member entry that gets \"activated\" once the user accepts the invite. The sent out invite contains the following URL: http://{control_panel_domain}/invites/{invite_key}?email={user_email} The invite is also returned as a string response for immediate display to the creator of the invite. Where control panel domain is the domain name of the reseller\'s or the MO\'s control panel, the invite key is randomly generated, and the user email is the email address to which the invite is sent (and can be used by the API consumer e.g. for display purposes). If the invite is issued for the same organization and email more than once, the invite email is simply sent out again, with a different invite token. The routine will fail if the login already has membership in the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createInvite: async (orgId: string, newInvite: NewInvite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/invites`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newInvite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newInvite !== undefined ? newInvite : {}) : (newInvite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Validate invite - Validates the invite. If successful, it returns the invite details: the organization\'s id and name, and the to-be-member\'s role. No session required. */
        validateInvite: async (inviteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/invites/{invite_id}/validate`
                .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for acceptInvite operation in InvitesApi. */
export type InvitesApiAcceptInviteReq = {
    /** The id of the invite. */
    inviteId: string
    /** Login credentials. */
    acceptInviteBody?: AcceptInviteBody
};

/** Response parameters for acceptInvite operation in InvitesApi. */
export type InvitesApiAcceptInviteRes = void;


/** Request parameters for createInvite operation in InvitesApi. */
export type InvitesApiCreateInviteReq = {
    /** The id of the organization. */
    orgId: string
    /** Invite details. */
    newInvite: NewInvite
};

/** Response parameters for createInvite operation in InvitesApi. */
export type InvitesApiCreateInviteRes = string;


/** Request parameters for validateInvite operation in InvitesApi. */
export type InvitesApiValidateInviteReq = {
    /** The id of the invite. */
    inviteId: string
};

/** Response parameters for validateInvite operation in InvitesApi. */
export type InvitesApiValidateInviteRes = InviteValidation;

/**
 * InvitesApi - service client interface
 */
export const InvitesApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Accept invite - Accepts the invite. There are four cases that need to be handled: No login session is present, user is newly invited and does not yet have a password. The password submitted in the body is set on the new user and the login\'s member is marked as active within the organization. A session is created and set in the returned cookie. No login session is present but user already exists and has a password. The password submitted in the request body is used to validate the login and the login\'s member is marked as active within the organization. A session is created and set in the returned cookie. A login session is present for the user to whom the invite belongs. The user is marked active within the organisation to which it was invited. A login session is present for a login to whom the invite does not belong, resulting in an error. */
    acceptInvite: async (reqParams: InvitesApiAcceptInviteReq, options?: any): Promise<AxiosResponse<InvitesApiAcceptInviteRes>> => {
        const localVarAxiosArgs = await InvitesApiAxiosParamCreator(configuration).acceptInvite(reqParams.inviteId, reqParams.acceptInviteBody, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create invite - Issues an invite for the user with the given email. The user may or may not have an account in the realm that the organization is in: If the user is not registered yet, a pending login entry is created for them along with a pending member entry, and the login is \"activated\" once the user accepts the invite. In this case they need to supply a password. If the user is already registered, their existing login is linked to a pending member entry that gets \"activated\" once the user accepts the invite. The sent out invite contains the following URL: http://{control_panel_domain}/invites/{invite_key}?email={user_email} The invite is also returned as a string response for immediate display to the creator of the invite. Where control panel domain is the domain name of the reseller\'s or the MO\'s control panel, the invite key is randomly generated, and the user email is the email address to which the invite is sent (and can be used by the API consumer e.g. for display purposes). If the invite is issued for the same organization and email more than once, the invite email is simply sent out again, with a different invite token. The routine will fail if the login already has membership in the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createInvite: async (reqParams: InvitesApiCreateInviteReq, options?: any): Promise<AxiosResponse<InvitesApiCreateInviteRes>> => {
        const localVarAxiosArgs = await InvitesApiAxiosParamCreator(configuration).createInvite(reqParams.orgId, reqParams.newInvite, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Validate invite - Validates the invite. If successful, it returns the invite details: the organization\'s id and name, and the to-be-member\'s role. No session required. */
    validateInvite: async (reqParams: InvitesApiValidateInviteReq, options?: any): Promise<AxiosResponse<InvitesApiValidateInviteRes>> => {
        const localVarAxiosArgs = await InvitesApiAxiosParamCreator(configuration).validateInvite(reqParams.inviteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface InvitesApiReq {
    acceptInvite: InvitesApiAcceptInviteReq;
    createInvite: InvitesApiCreateInviteReq;
    validateInvite: InvitesApiValidateInviteReq;
}

export interface InvitesApiRes {
    acceptInvite: InvitesApiAcceptInviteRes;
    createInvite: InvitesApiCreateInviteRes;
    validateInvite: InvitesApiValidateInviteRes;
}


/**
 * LetsencryptApi - axios parameter creator
 * @export
 */
export const LetsencryptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Generate and setup letsencrypt ssl certificates for website\'s domain - Generates letsencrypt certificates for the domain. This is a longer running task, that will do a complete ssl setup for a given domain. Once completed any given domain will get served over `https`. Given domain must be publicly accessible and being served from our service. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteDomainLetsencryptCerts: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Generate and setup letsencrypt ssl certificates for website\'s domain with mail. prefix. - Generates letsencrypt certificate for the mail server at mail.customerdomain. */
        createWebsiteMailDomainLetsencryptCerts: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt_mail`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Perform the LetsEncrypt preflight check - Will attempt to verify that the domain will successfully achieve a LetsEncrypt certificate if attempted.  Provides debug information. */
        performLetsEncryptPreflightCheck: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt_preflight`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createWebsiteDomainLetsencryptCerts operation in LetsencryptApi. */
export type LetsencryptApiCreateWebsiteDomainLetsencryptCertsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for createWebsiteDomainLetsencryptCerts operation in LetsencryptApi. */
export type LetsencryptApiCreateWebsiteDomainLetsencryptCertsRes = void;


/** Request parameters for createWebsiteMailDomainLetsencryptCerts operation in LetsencryptApi. */
export type LetsencryptApiCreateWebsiteMailDomainLetsencryptCertsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for createWebsiteMailDomainLetsencryptCerts operation in LetsencryptApi. */
export type LetsencryptApiCreateWebsiteMailDomainLetsencryptCertsRes = void;


/** Request parameters for performLetsEncryptPreflightCheck operation in LetsencryptApi. */
export type LetsencryptApiPerformLetsEncryptPreflightCheckReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for performLetsEncryptPreflightCheck operation in LetsencryptApi. */
export type LetsencryptApiPerformLetsEncryptPreflightCheckRes = LetsEncryptPreflightResult;

/**
 * LetsencryptApi - service client interface
 */
export const LetsencryptApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Generate and setup letsencrypt ssl certificates for website\'s domain - Generates letsencrypt certificates for the domain. This is a longer running task, that will do a complete ssl setup for a given domain. Once completed any given domain will get served over `https`. Given domain must be publicly accessible and being served from our service. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteDomainLetsencryptCerts: async (reqParams: LetsencryptApiCreateWebsiteDomainLetsencryptCertsReq, options?: any): Promise<AxiosResponse<LetsencryptApiCreateWebsiteDomainLetsencryptCertsRes>> => {
        const localVarAxiosArgs = await LetsencryptApiAxiosParamCreator(configuration).createWebsiteDomainLetsencryptCerts(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Generate and setup letsencrypt ssl certificates for website\'s domain with mail. prefix. - Generates letsencrypt certificate for the mail server at mail.customerdomain. */
    createWebsiteMailDomainLetsencryptCerts: async (reqParams: LetsencryptApiCreateWebsiteMailDomainLetsencryptCertsReq, options?: any): Promise<AxiosResponse<LetsencryptApiCreateWebsiteMailDomainLetsencryptCertsRes>> => {
        const localVarAxiosArgs = await LetsencryptApiAxiosParamCreator(configuration).createWebsiteMailDomainLetsencryptCerts(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Perform the LetsEncrypt preflight check - Will attempt to verify that the domain will successfully achieve a LetsEncrypt certificate if attempted.  Provides debug information. */
    performLetsEncryptPreflightCheck: async (reqParams: LetsencryptApiPerformLetsEncryptPreflightCheckReq, options?: any): Promise<AxiosResponse<LetsencryptApiPerformLetsEncryptPreflightCheckRes>> => {
        const localVarAxiosArgs = await LetsencryptApiAxiosParamCreator(configuration).performLetsEncryptPreflightCheck(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface LetsencryptApiReq {
    createWebsiteDomainLetsencryptCerts: LetsencryptApiCreateWebsiteDomainLetsencryptCertsReq;
    createWebsiteMailDomainLetsencryptCerts: LetsencryptApiCreateWebsiteMailDomainLetsencryptCertsReq;
    performLetsEncryptPreflightCheck: LetsencryptApiPerformLetsEncryptPreflightCheckReq;
}

export interface LetsencryptApiRes {
    createWebsiteDomainLetsencryptCerts: LetsencryptApiCreateWebsiteDomainLetsencryptCertsRes;
    createWebsiteMailDomainLetsencryptCerts: LetsencryptApiCreateWebsiteMailDomainLetsencryptCertsRes;
    performLetsEncryptPreflightCheck: LetsencryptApiPerformLetsEncryptPreflightCheckRes;
}


/**
 * LicenceApi - axios parameter creator
 * @export
 */
export const LicenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Get current licence status -  */
        getLicenceInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/licence`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates licence key if provided, and refresh licence status by calling home servers. NOTE: calling without any licence_key body, only refreshes the current licence status -  */
        refreshLicence: async (licenceKey?: LicenceKey, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/licence`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof licenceKey !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(licenceKey !== undefined ? licenceKey : {}) : (licenceKey || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for getLicenceInfo operation in LicenceApi. */
export type LicenceApiGetLicenceInfoReq = {
};

/** Response parameters for getLicenceInfo operation in LicenceApi. */
export type LicenceApiGetLicenceInfoRes = LicenceInfo;


/** Request parameters for refreshLicence operation in LicenceApi. */
export type LicenceApiRefreshLicenceReq = {
    licenceKey?: LicenceKey
};

/** Response parameters for refreshLicence operation in LicenceApi. */
export type LicenceApiRefreshLicenceRes = LicenceInfo;

/**
 * LicenceApi - service client interface
 */
export const LicenceApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Get current licence status -  */
    getLicenceInfo: async (reqParams: LicenceApiGetLicenceInfoReq, options?: any): Promise<AxiosResponse<LicenceApiGetLicenceInfoRes>> => {
        const localVarAxiosArgs = await LicenceApiAxiosParamCreator(configuration).getLicenceInfo(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates licence key if provided, and refresh licence status by calling home servers. NOTE: calling without any licence_key body, only refreshes the current licence status -  */
    refreshLicence: async (reqParams: LicenceApiRefreshLicenceReq, options?: any): Promise<AxiosResponse<LicenceApiRefreshLicenceRes>> => {
        const localVarAxiosArgs = await LicenceApiAxiosParamCreator(configuration).refreshLicence(reqParams.licenceKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface LicenceApiReq {
    getLicenceInfo: LicenceApiGetLicenceInfoReq;
    refreshLicence: LicenceApiRefreshLicenceReq;
}

export interface LicenceApiRes {
    getLicenceInfo: LicenceApiGetLicenceInfoRes;
    refreshLicence: LicenceApiRefreshLicenceRes;
}


/**
 * LoginsApi - axios parameter creator
 * @export
 */
export const LoginsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create a new login - Creates a login in the realm. The login will be created in the same realm that the organization is in. Session holder must have admin or support privileges in the given organization or any parent thereof. */
        createLogin: async (orgId: string, loginInfo: LoginInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logins`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginInfo !== undefined ? loginInfo : {}) : (loginInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a new session for login with a one-time-password - Creates a new session for the login in a specific login realm, using a short lived one time password. This creates a session as well, with the difference that realmId is required and any 2FA will be bypassed. */
        createOtpSession: async (otp: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/sessions/sso`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (otp !== undefined) {
                localVarQueryParameter['otp'] = otp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a new session for login - Creates a new session for the login in a specific login realm. By default, the realm in which to look up a given login is dictated by the domain on which the request is made.  If it matches a mapped to a ControlPanel website belonging to an MO or a reseller then that MO or reseller is used as the relam from which to select the login.  If the login exists directly as a member of the chosen realm then that login will be selected in preference to any which exists within the realm itself. The realm derived from the control panel domain can be overridden with the realmId parameter to specify a particular reseller or the MO. The authentication result is a JWT session token and a list detailing the organizations in which login is a member. In case of 2FA, the authentication result is a JWT session token with empty body and 201 http status code. */
        createSession: async (loginCreds: LoginCreds, realmId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/sessions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (realmId !== undefined) {
                localVarQueryParameter['realmId'] = realmId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginCreds !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginCreds !== undefined ? loginCreds : {}) : (loginCreds || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete current session -  */
        deleteCurrentSession: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/sessions/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Remove login avatar - Deletes the user\'s avatar. The user is implicitly assumed to be the session holder, so no login id is supplied. */
        deleteLoginAvatar: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/avatar`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete current session -  */
        deleteSession: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/sessions/{session_id}`
                .replace(`{${"session_id"}}`, encodeURIComponent(String(sessionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete sessions -  */
        deleteSessions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/sessions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Finish a password recovery - Finishes the password recovery for the recovery key. This operation only succeeds if the key hasn\'t expired. If it has, the user must initiate a new password recovery. */
        finishPasswordRecovery: async (secret: string, newPassword: NewPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/password-recovery`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (secret !== undefined) {
                localVarQueryParameter['secret'] = secret;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPassword !== undefined ? newPassword : {}) : (newPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** List customer logins for org -  */
        getCustomerLogins: async (orgId: string, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'email' | 'name' | 'registrationDate', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/customers/logins`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get login info -  */
        getLogin: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get login memberships - Returns a list of info about all organization\'s the login is a member of. The return value is the same as that of successful session creation. */
        getLoginMemberships: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/memberships`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Query all logins - Returns all logins registered in the control panel. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getLogins: async (realmId?: string, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'email' | 'name' | 'registrationDate', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logins`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (realmId !== undefined) {
                localVarQueryParameter['realmId'] = realmId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Query logins belonging to organization - Returns all logins registered in given organization. Session holder must be an `Owner`, `SuperAdmin` or `Sysadmin` in the org or the MO. */
        getOrgLogins: async (orgId: string, offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'email' | 'name' | 'registrationDate', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/logins`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get all login sessions -  */
        getSessions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/sessions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get UI prefereces for login - The operation allows users to get UI prefereces. */
        getUiPreferences: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logins/ui-preferences`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Resends 2FA sign-in code. - On success, standard session with a new pin returned, otherwise 401 Unauthorized is returned with an empty cookie and the session is removed. Note that 2FA can only be used with the session cookie. */
        resendPin: async (resendPin: ResendPin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/2fa/resend-pin`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resendPin !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resendPin !== undefined ? resendPin : {}) : (resendPin || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set password for login - This operation allows admins to reset the password for a login. */
        setCustomerLoginPassword: async (loginId: string, newPassword: NewPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/logins/{login_id}/password`
                .replace(`{${"login_id"}}`, encodeURIComponent(String(loginId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPassword !== undefined ? newPassword : {}) : (newPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set login avatar - Sets the user\'s avatar, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, and ico. The user is implicitly assumed to be the session holder, so no login id is supplied. */
        setLoginAvatar: async (avatar?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/avatar`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (avatar !== undefined) { 
                localVarFormParams.append('avatar', avatar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set new UI prefereces for login - The operation allows users to set a new UI prefereces. */
        setUiPreferences: async (newUiPreferences: NewUiPreferences, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logins/ui-preferences`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newUiPreferences !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newUiPreferences !== undefined ? newUiPreferences : {}) : (newUiPreferences || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Start a new password recovery for login - Initiates a new password recovery for the given email address, or fails silently (returning a `200 Ok`) if no login corresponded to the email. Note that only logins who are registered may initiate a password recovery. Users who were invited by an org member and haven\'t finished their signup may not start a recovery. */
        startPasswordRecovery: async (emailAddress: EmailAddress, realmId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/password-recovery`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (realmId !== undefined) {
                localVarQueryParameter['realmId'] = realmId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof emailAddress !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailAddress !== undefined ? emailAddress : {}) : (emailAddress || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update login info - Updates some or all login information and credentials. Only the currently authenticated login may do this (thus there is no explicit login id in the input). If the email or password are to be updated, the current password must be provided. In order to disable MFA, a user has to validate the PIN code required by the enabled method. If users want to switch to a different MFA method, they need to disable MFA and enable the desired MFA method. */
        updateLoginInfo: async (updateLogin: UpdateLogin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateLogin !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateLogin !== undefined ? updateLogin : {}) : (updateLogin || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update UI prefereces for login - The operation allows users to update UI prefereces. */
        updateUiPreferences: async (updateUiPreferences: UpdateUiPreferences, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logins/ui-preferences`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUiPreferences !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUiPreferences !== undefined ? updateUiPreferences : {}) : (updateUiPreferences || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Validate a password recovery secret - Returns the obfuscated email address belonging to the user initiating the password recovery if the secret is correct and hasn\'t expired yet. */
        validatePasswordRecovery: async (secret: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/password-recovery/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (secret !== undefined) {
                localVarQueryParameter['secret'] = secret;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Verifies 2FA sign-in code. - On success, standard session with cookie is returned, otherwise 401 Unauthorized is returned with an empty cookie and the session is removed. Note that 2FA can only be used with the session cookie. */
        verify2FA: async (login2FA: Login2FA, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/2fa`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof login2FA !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(login2FA !== undefined ? login2FA : {}) : (login2FA || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createLogin operation in LoginsApi. */
export type LoginsApiCreateLoginReq = {
    /** The mandatory organization id to denote in which realm to create the login in. The login will be created in the same realm that the organization is in. */
    orgId: string
    loginInfo: LoginInfo
};

/** Response parameters for createLogin operation in LoginsApi. */
export type LoginsApiCreateLoginRes = NewResourceUuid;


/** Request parameters for createOtpSession operation in LoginsApi. */
export type LoginsApiCreateOtpSessionReq = {
    /** Contains a short lived otp for direct login bypassing any 2FA. */
    otp: string
};

/** Response parameters for createOtpSession operation in LoginsApi. */
export type LoginsApiCreateOtpSessionRes = LoginMemberships;


/** Request parameters for createSession operation in LoginsApi. */
export type LoginsApiCreateSessionReq = {
    /** Login credentials. */
    loginCreds: LoginCreds
    /** If set with the given realm\'s id (the parent id of an organization), then the login is authenticated in this realm. See the endpoint description for more info. */
    realmId?: string
};

/** Response parameters for createSession operation in LoginsApi. */
export type LoginsApiCreateSessionRes = LoginMemberships;


/** Request parameters for deleteCurrentSession operation in LoginsApi. */
export type LoginsApiDeleteCurrentSessionReq = {
};

/** Response parameters for deleteCurrentSession operation in LoginsApi. */
export type LoginsApiDeleteCurrentSessionRes = void;


/** Request parameters for deleteLoginAvatar operation in LoginsApi. */
export type LoginsApiDeleteLoginAvatarReq = {
};

/** Response parameters for deleteLoginAvatar operation in LoginsApi. */
export type LoginsApiDeleteLoginAvatarRes = void;


/** Request parameters for deleteSession operation in LoginsApi. */
export type LoginsApiDeleteSessionReq = {
    /** The id of the login session. */
    sessionId: string
};

/** Response parameters for deleteSession operation in LoginsApi. */
export type LoginsApiDeleteSessionRes = void;


/** Request parameters for deleteSessions operation in LoginsApi. */
export type LoginsApiDeleteSessionsReq = {
};

/** Response parameters for deleteSessions operation in LoginsApi. */
export type LoginsApiDeleteSessionsRes = void;


/** Request parameters for finishPasswordRecovery operation in LoginsApi. */
export type LoginsApiFinishPasswordRecoveryReq = {
    /** The secret key for the password recovery. */
    secret: string
    /** Login\'s new password. */
    newPassword: NewPassword
};

/** Response parameters for finishPasswordRecovery operation in LoginsApi. */
export type LoginsApiFinishPasswordRecoveryRes = void;


/** Request parameters for getCustomerLogins operation in LoginsApi. */
export type LoginsApiGetCustomerLoginsReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** The field by which to sort. */
    sortBy?: 'email' | 'name' | 'registrationDate'
};

/** Response parameters for getCustomerLogins operation in LoginsApi. */
export type LoginsApiGetCustomerLoginsRes = LoginsListing;


/** Request parameters for getLogin operation in LoginsApi. */
export type LoginsApiGetLoginReq = {
};

/** Response parameters for getLogin operation in LoginsApi. */
export type LoginsApiGetLoginRes = Login;


/** Request parameters for getLoginMemberships operation in LoginsApi. */
export type LoginsApiGetLoginMembershipsReq = {
};

/** Response parameters for getLoginMemberships operation in LoginsApi. */
export type LoginsApiGetLoginMembershipsRes = LoginMemberships;


/** Request parameters for getLogins operation in LoginsApi. */
export type LoginsApiGetLoginsReq = {
    /** The id of the realm to query. Realm ids map to org ids. */
    realmId?: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** The field by which to sort. */
    sortBy?: 'email' | 'name' | 'registrationDate'
};

/** Response parameters for getLogins operation in LoginsApi. */
export type LoginsApiGetLoginsRes = LoginsListing;


/** Request parameters for getOrgLogins operation in LoginsApi. */
export type LoginsApiGetOrgLoginsReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** The field by which to sort. */
    sortBy?: 'email' | 'name' | 'registrationDate'
};

/** Response parameters for getOrgLogins operation in LoginsApi. */
export type LoginsApiGetOrgLoginsRes = LoginsListing;


/** Request parameters for getSessions operation in LoginsApi. */
export type LoginsApiGetSessionsReq = {
};

/** Response parameters for getSessions operation in LoginsApi. */
export type LoginsApiGetSessionsRes = SessionsFullListing;


/** Request parameters for getUiPreferences operation in LoginsApi. */
export type LoginsApiGetUiPreferencesReq = {
};

/** Response parameters for getUiPreferences operation in LoginsApi. */
export type LoginsApiGetUiPreferencesRes = UiPreferences;


/** Request parameters for resendPin operation in LoginsApi. */
export type LoginsApiResendPinReq = {
    /** Verification details. */
    resendPin: ResendPin
};

/** Response parameters for resendPin operation in LoginsApi. */
export type LoginsApiResendPinRes = void;


/** Request parameters for setCustomerLoginPassword operation in LoginsApi. */
export type LoginsApiSetCustomerLoginPasswordReq = {
    /** The id of a login. */
    loginId: string
    /** The new unhashed password to set for the login */
    newPassword: NewPassword
};

/** Response parameters for setCustomerLoginPassword operation in LoginsApi. */
export type LoginsApiSetCustomerLoginPasswordRes = void;


/** Request parameters for setLoginAvatar operation in LoginsApi. */
export type LoginsApiSetLoginAvatarReq = {
    avatar?: any
};

/** Response parameters for setLoginAvatar operation in LoginsApi. */
export type LoginsApiSetLoginAvatarRes = void;


/** Request parameters for setUiPreferences operation in LoginsApi. */
export type LoginsApiSetUiPreferencesReq = {
    newUiPreferences: NewUiPreferences
};

/** Response parameters for setUiPreferences operation in LoginsApi. */
export type LoginsApiSetUiPreferencesRes = void;


/** Request parameters for startPasswordRecovery operation in LoginsApi. */
export type LoginsApiStartPasswordRecoveryReq = {
    /** Login\'s email address. */
    emailAddress: EmailAddress
    /** If set, the login is looked up in the specified realm. If unset then the chosen realm will be based on the control panel hostname. */
    realmId?: string
};

/** Response parameters for startPasswordRecovery operation in LoginsApi. */
export type LoginsApiStartPasswordRecoveryRes = void;


/** Request parameters for updateLoginInfo operation in LoginsApi. */
export type LoginsApiUpdateLoginInfoReq = {
    updateLogin: UpdateLogin
};

/** Response parameters for updateLoginInfo operation in LoginsApi. */
export type LoginsApiUpdateLoginInfoRes = UpdateLoginResult;


/** Request parameters for updateUiPreferences operation in LoginsApi. */
export type LoginsApiUpdateUiPreferencesReq = {
    updateUiPreferences: UpdateUiPreferences
};

/** Response parameters for updateUiPreferences operation in LoginsApi. */
export type LoginsApiUpdateUiPreferencesRes = void;


/** Request parameters for validatePasswordRecovery operation in LoginsApi. */
export type LoginsApiValidatePasswordRecoveryReq = {
    /** The secret key for the password recovery. */
    secret: string
};

/** Response parameters for validatePasswordRecovery operation in LoginsApi. */
export type LoginsApiValidatePasswordRecoveryRes = ValidatedPasswordRecovery;


/** Request parameters for verify2FA operation in LoginsApi. */
export type LoginsApiVerify2FAReq = {
    /** Verification details. */
    login2FA: Login2FA
};

/** Response parameters for verify2FA operation in LoginsApi. */
export type LoginsApiVerify2FARes = void;

/**
 * LoginsApi - service client interface
 */
export const LoginsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create a new login - Creates a login in the realm. The login will be created in the same realm that the organization is in. Session holder must have admin or support privileges in the given organization or any parent thereof. */
    createLogin: async (reqParams: LoginsApiCreateLoginReq, options?: any): Promise<AxiosResponse<LoginsApiCreateLoginRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).createLogin(reqParams.orgId, reqParams.loginInfo, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a new session for login with a one-time-password - Creates a new session for the login in a specific login realm, using a short lived one time password. This creates a session as well, with the difference that realmId is required and any 2FA will be bypassed. */
    createOtpSession: async (reqParams: LoginsApiCreateOtpSessionReq, options?: any): Promise<AxiosResponse<LoginsApiCreateOtpSessionRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).createOtpSession(reqParams.otp, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a new session for login - Creates a new session for the login in a specific login realm. By default, the realm in which to look up a given login is dictated by the domain on which the request is made.  If it matches a mapped to a ControlPanel website belonging to an MO or a reseller then that MO or reseller is used as the relam from which to select the login.  If the login exists directly as a member of the chosen realm then that login will be selected in preference to any which exists within the realm itself. The realm derived from the control panel domain can be overridden with the realmId parameter to specify a particular reseller or the MO. The authentication result is a JWT session token and a list detailing the organizations in which login is a member. In case of 2FA, the authentication result is a JWT session token with empty body and 201 http status code. */
    createSession: async (reqParams: LoginsApiCreateSessionReq, options?: any): Promise<AxiosResponse<LoginsApiCreateSessionRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).createSession(reqParams.loginCreds, reqParams.realmId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete current session -  */
    deleteCurrentSession: async (reqParams: LoginsApiDeleteCurrentSessionReq, options?: any): Promise<AxiosResponse<LoginsApiDeleteCurrentSessionRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).deleteCurrentSession(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Remove login avatar - Deletes the user\'s avatar. The user is implicitly assumed to be the session holder, so no login id is supplied. */
    deleteLoginAvatar: async (reqParams: LoginsApiDeleteLoginAvatarReq, options?: any): Promise<AxiosResponse<LoginsApiDeleteLoginAvatarRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).deleteLoginAvatar(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete current session -  */
    deleteSession: async (reqParams: LoginsApiDeleteSessionReq, options?: any): Promise<AxiosResponse<LoginsApiDeleteSessionRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).deleteSession(reqParams.sessionId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete sessions -  */
    deleteSessions: async (reqParams: LoginsApiDeleteSessionsReq, options?: any): Promise<AxiosResponse<LoginsApiDeleteSessionsRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).deleteSessions(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Finish a password recovery - Finishes the password recovery for the recovery key. This operation only succeeds if the key hasn\'t expired. If it has, the user must initiate a new password recovery. */
    finishPasswordRecovery: async (reqParams: LoginsApiFinishPasswordRecoveryReq, options?: any): Promise<AxiosResponse<LoginsApiFinishPasswordRecoveryRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).finishPasswordRecovery(reqParams.secret, reqParams.newPassword, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** List customer logins for org -  */
    getCustomerLogins: async (reqParams: LoginsApiGetCustomerLoginsReq, options?: any): Promise<AxiosResponse<LoginsApiGetCustomerLoginsRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getCustomerLogins(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.sortBy, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get login info -  */
    getLogin: async (reqParams: LoginsApiGetLoginReq, options?: any): Promise<AxiosResponse<LoginsApiGetLoginRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getLogin(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get login memberships - Returns a list of info about all organization\'s the login is a member of. The return value is the same as that of successful session creation. */
    getLoginMemberships: async (reqParams: LoginsApiGetLoginMembershipsReq, options?: any): Promise<AxiosResponse<LoginsApiGetLoginMembershipsRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getLoginMemberships(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Query all logins - Returns all logins registered in the control panel. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getLogins: async (reqParams: LoginsApiGetLoginsReq, options?: any): Promise<AxiosResponse<LoginsApiGetLoginsRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getLogins(reqParams.realmId, reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.sortBy, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Query logins belonging to organization - Returns all logins registered in given organization. Session holder must be an `Owner`, `SuperAdmin` or `Sysadmin` in the org or the MO. */
    getOrgLogins: async (reqParams: LoginsApiGetOrgLoginsReq, options?: any): Promise<AxiosResponse<LoginsApiGetOrgLoginsRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getOrgLogins(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.sortBy, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get all login sessions -  */
    getSessions: async (reqParams: LoginsApiGetSessionsReq, options?: any): Promise<AxiosResponse<LoginsApiGetSessionsRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getSessions(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get UI prefereces for login - The operation allows users to get UI prefereces. */
    getUiPreferences: async (reqParams: LoginsApiGetUiPreferencesReq, options?: any): Promise<AxiosResponse<LoginsApiGetUiPreferencesRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).getUiPreferences(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Resends 2FA sign-in code. - On success, standard session with a new pin returned, otherwise 401 Unauthorized is returned with an empty cookie and the session is removed. Note that 2FA can only be used with the session cookie. */
    resendPin: async (reqParams: LoginsApiResendPinReq, options?: any): Promise<AxiosResponse<LoginsApiResendPinRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).resendPin(reqParams.resendPin, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set password for login - This operation allows admins to reset the password for a login. */
    setCustomerLoginPassword: async (reqParams: LoginsApiSetCustomerLoginPasswordReq, options?: any): Promise<AxiosResponse<LoginsApiSetCustomerLoginPasswordRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).setCustomerLoginPassword(reqParams.loginId, reqParams.newPassword, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set login avatar - Sets the user\'s avatar, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, and ico. The user is implicitly assumed to be the session holder, so no login id is supplied. */
    setLoginAvatar: async (reqParams: LoginsApiSetLoginAvatarReq, options?: any): Promise<AxiosResponse<LoginsApiSetLoginAvatarRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).setLoginAvatar(reqParams.avatar, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set new UI prefereces for login - The operation allows users to set a new UI prefereces. */
    setUiPreferences: async (reqParams: LoginsApiSetUiPreferencesReq, options?: any): Promise<AxiosResponse<LoginsApiSetUiPreferencesRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).setUiPreferences(reqParams.newUiPreferences, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Start a new password recovery for login - Initiates a new password recovery for the given email address, or fails silently (returning a `200 Ok`) if no login corresponded to the email. Note that only logins who are registered may initiate a password recovery. Users who were invited by an org member and haven\'t finished their signup may not start a recovery. */
    startPasswordRecovery: async (reqParams: LoginsApiStartPasswordRecoveryReq, options?: any): Promise<AxiosResponse<LoginsApiStartPasswordRecoveryRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).startPasswordRecovery(reqParams.emailAddress, reqParams.realmId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update login info - Updates some or all login information and credentials. Only the currently authenticated login may do this (thus there is no explicit login id in the input). If the email or password are to be updated, the current password must be provided. In order to disable MFA, a user has to validate the PIN code required by the enabled method. If users want to switch to a different MFA method, they need to disable MFA and enable the desired MFA method. */
    updateLoginInfo: async (reqParams: LoginsApiUpdateLoginInfoReq, options?: any): Promise<AxiosResponse<LoginsApiUpdateLoginInfoRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).updateLoginInfo(reqParams.updateLogin, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update UI prefereces for login - The operation allows users to update UI prefereces. */
    updateUiPreferences: async (reqParams: LoginsApiUpdateUiPreferencesReq, options?: any): Promise<AxiosResponse<LoginsApiUpdateUiPreferencesRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).updateUiPreferences(reqParams.updateUiPreferences, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Validate a password recovery secret - Returns the obfuscated email address belonging to the user initiating the password recovery if the secret is correct and hasn\'t expired yet. */
    validatePasswordRecovery: async (reqParams: LoginsApiValidatePasswordRecoveryReq, options?: any): Promise<AxiosResponse<LoginsApiValidatePasswordRecoveryRes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).validatePasswordRecovery(reqParams.secret, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Verifies 2FA sign-in code. - On success, standard session with cookie is returned, otherwise 401 Unauthorized is returned with an empty cookie and the session is removed. Note that 2FA can only be used with the session cookie. */
    verify2FA: async (reqParams: LoginsApiVerify2FAReq, options?: any): Promise<AxiosResponse<LoginsApiVerify2FARes>> => {
        const localVarAxiosArgs = await LoginsApiAxiosParamCreator(configuration).verify2FA(reqParams.login2FA, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface LoginsApiReq {
    createLogin: LoginsApiCreateLoginReq;
    createOtpSession: LoginsApiCreateOtpSessionReq;
    createSession: LoginsApiCreateSessionReq;
    deleteCurrentSession: LoginsApiDeleteCurrentSessionReq;
    deleteLoginAvatar: LoginsApiDeleteLoginAvatarReq;
    deleteSession: LoginsApiDeleteSessionReq;
    deleteSessions: LoginsApiDeleteSessionsReq;
    finishPasswordRecovery: LoginsApiFinishPasswordRecoveryReq;
    getCustomerLogins: LoginsApiGetCustomerLoginsReq;
    getLogin: LoginsApiGetLoginReq;
    getLoginMemberships: LoginsApiGetLoginMembershipsReq;
    getLogins: LoginsApiGetLoginsReq;
    getOrgLogins: LoginsApiGetOrgLoginsReq;
    getSessions: LoginsApiGetSessionsReq;
    getUiPreferences: LoginsApiGetUiPreferencesReq;
    resendPin: LoginsApiResendPinReq;
    setCustomerLoginPassword: LoginsApiSetCustomerLoginPasswordReq;
    setLoginAvatar: LoginsApiSetLoginAvatarReq;
    setUiPreferences: LoginsApiSetUiPreferencesReq;
    startPasswordRecovery: LoginsApiStartPasswordRecoveryReq;
    updateLoginInfo: LoginsApiUpdateLoginInfoReq;
    updateUiPreferences: LoginsApiUpdateUiPreferencesReq;
    validatePasswordRecovery: LoginsApiValidatePasswordRecoveryReq;
    verify2FA: LoginsApiVerify2FAReq;
}

export interface LoginsApiRes {
    createLogin: LoginsApiCreateLoginRes;
    createOtpSession: LoginsApiCreateOtpSessionRes;
    createSession: LoginsApiCreateSessionRes;
    deleteCurrentSession: LoginsApiDeleteCurrentSessionRes;
    deleteLoginAvatar: LoginsApiDeleteLoginAvatarRes;
    deleteSession: LoginsApiDeleteSessionRes;
    deleteSessions: LoginsApiDeleteSessionsRes;
    finishPasswordRecovery: LoginsApiFinishPasswordRecoveryRes;
    getCustomerLogins: LoginsApiGetCustomerLoginsRes;
    getLogin: LoginsApiGetLoginRes;
    getLoginMemberships: LoginsApiGetLoginMembershipsRes;
    getLogins: LoginsApiGetLoginsRes;
    getOrgLogins: LoginsApiGetOrgLoginsRes;
    getSessions: LoginsApiGetSessionsRes;
    getUiPreferences: LoginsApiGetUiPreferencesRes;
    resendPin: LoginsApiResendPinRes;
    setCustomerLoginPassword: LoginsApiSetCustomerLoginPasswordRes;
    setLoginAvatar: LoginsApiSetLoginAvatarRes;
    setUiPreferences: LoginsApiSetUiPreferencesRes;
    startPasswordRecovery: LoginsApiStartPasswordRecoveryRes;
    updateLoginInfo: LoginsApiUpdateLoginInfoRes;
    updateUiPreferences: LoginsApiUpdateUiPreferencesRes;
    validatePasswordRecovery: LoginsApiValidatePasswordRecoveryRes;
    verify2FA: LoginsApiVerify2FARes;
}


/**
 * MembersApi - axios parameter creator
 * @export
 */
export const MembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create organisation access token - Create an access token with rights within an organisation. */
        createAccessToken: async (orgId: string, newAccessToken: NewAccessToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/access_tokens`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newAccessToken !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newAccessToken !== undefined ? newAccessToken : {}) : (newAccessToken || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create organization member - A login for the member needs to exist before establishing membership. On success, the member id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        createMember: async (orgId: string, newMember: NewMember, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMember !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMember !== undefined ? newMember : {}) : (newMember || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete access token member - Delete an access token */
        deleteAccessToken: async (orgId: string, tokenId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/access_tokens/{token_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"token_id"}}`, encodeURIComponent(String(tokenId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete organization member - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        deleteMember: async (orgId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete organization owner - This operation can only be done by a logged in superadmin of the organization\'s parent organization (TODO it\'s unclear as of this writing whether organization owners may delete themselves). The previous owner is demoted to the \"SupperAdmin\" role. */
        deleteOwner: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/owner`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get access token members - Returns all access tokens belonging to the organisation */
        getAccessTokens: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/access_tokens`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization member - Returns details about this organization member. This operation can only be done by the member itself, a logged in superadmin or owner of the organization or its parent organization(s). */
        getMember: async (orgId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization members - Returns all (both pending and full) members of the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getMembers: async (orgId: string, offset?: number, limit?: number, sortBy?: 'status' | 'joinedAt', sortOrder?: 'asc' | 'desc', role?: Role, siteAccess?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (siteAccess !== undefined) {
                localVarQueryParameter['siteAccess'] = siteAccess;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get a One-Time-Password link for the member - Returns a short lived one time password link for direct log-ins via the users realm. Session holder must be an `Owner`, `SuperAdmin` or `Sysadmin` in the org or the MO. */
        getOrgMemberLogin: async (orgId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}/sso`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Overwrite organization member settings - Updates member information, such as their roles, site access permissions, and notification settings. This operation can only be done by the logged in superadmin or owner of the organization or its parent organization(s). This operation overwrites existing settings, so all existing values that are meant to be kept should be included in the response body. */
        updateMember: async (orgId: string, memberId: string, updateMember: UpdateMember, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateMember !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateMember !== undefined ? updateMember : {}) : (updateMember || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update organization owner - The new owner must already be a member in the organization before establishing ownership. This operation can only be done by a logged in superadmin of the organization\'s parent organization, or the owner of the organization. In both cases, the previous owner (if they existed) is demoted to SuperAdmin after this operation. */
        updateOwner: async (orgId: string, orgOwnerUpdate: OrgOwnerUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/owner`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orgOwnerUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orgOwnerUpdate !== undefined ? orgOwnerUpdate : {}) : (orgOwnerUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createAccessToken operation in MembersApi. */
export type MembersApiCreateAccessTokenReq = {
    /** The id of the organization. */
    orgId: string
    /** Access token details */
    newAccessToken: NewAccessToken
};

/** Response parameters for createAccessToken operation in MembersApi. */
export type MembersApiCreateAccessTokenRes = NewAccessTokenResponse;


/** Request parameters for createMember operation in MembersApi. */
export type MembersApiCreateMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** New member details */
    newMember: NewMember
};

/** Response parameters for createMember operation in MembersApi. */
export type MembersApiCreateMemberRes = NewResourceUuid;


/** Request parameters for deleteAccessToken operation in MembersApi. */
export type MembersApiDeleteAccessTokenReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of an org access token. */
    tokenId: string
};

/** Response parameters for deleteAccessToken operation in MembersApi. */
export type MembersApiDeleteAccessTokenRes = void;


/** Request parameters for deleteMember operation in MembersApi. */
export type MembersApiDeleteMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
};

/** Response parameters for deleteMember operation in MembersApi. */
export type MembersApiDeleteMemberRes = void;


/** Request parameters for deleteOwner operation in MembersApi. */
export type MembersApiDeleteOwnerReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteOwner operation in MembersApi. */
export type MembersApiDeleteOwnerRes = void;


/** Request parameters for getAccessTokens operation in MembersApi. */
export type MembersApiGetAccessTokensReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getAccessTokens operation in MembersApi. */
export type MembersApiGetAccessTokensRes = Array<OrgAccessToken>;


/** Request parameters for getMember operation in MembersApi. */
export type MembersApiGetMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
};

/** Response parameters for getMember operation in MembersApi. */
export type MembersApiGetMemberRes = Member;


/** Request parameters for getMembers operation in MembersApi. */
export type MembersApiGetMembersReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'status' | 'joinedAt'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Return only members with this role. */
    role?: Role
    /** Return only collaborator members that have access to this website. Note that super admins and owners are not returned because they implicitly have access. */
    siteAccess?: string
};

/** Response parameters for getMembers operation in MembersApi. */
export type MembersApiGetMembersRes = MembersListing;


/** Request parameters for getOrgMemberLogin operation in MembersApi. */
export type MembersApiGetOrgMemberLoginReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
};

/** Response parameters for getOrgMemberLogin operation in MembersApi. */
export type MembersApiGetOrgMemberLoginRes = string;


/** Request parameters for updateMember operation in MembersApi. */
export type MembersApiUpdateMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
    /** Member settings */
    updateMember: UpdateMember
};

/** Response parameters for updateMember operation in MembersApi. */
export type MembersApiUpdateMemberRes = void;


/** Request parameters for updateOwner operation in MembersApi. */
export type MembersApiUpdateOwnerReq = {
    /** The id of the organization. */
    orgId: string
    /** Membership id of the to-be owner */
    orgOwnerUpdate: OrgOwnerUpdate
};

/** Response parameters for updateOwner operation in MembersApi. */
export type MembersApiUpdateOwnerRes = void;

/**
 * MembersApi - service client interface
 */
export const MembersApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create organisation access token - Create an access token with rights within an organisation. */
    createAccessToken: async (reqParams: MembersApiCreateAccessTokenReq, options?: any): Promise<AxiosResponse<MembersApiCreateAccessTokenRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).createAccessToken(reqParams.orgId, reqParams.newAccessToken, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create organization member - A login for the member needs to exist before establishing membership. On success, the member id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    createMember: async (reqParams: MembersApiCreateMemberReq, options?: any): Promise<AxiosResponse<MembersApiCreateMemberRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).createMember(reqParams.orgId, reqParams.newMember, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete access token member - Delete an access token */
    deleteAccessToken: async (reqParams: MembersApiDeleteAccessTokenReq, options?: any): Promise<AxiosResponse<MembersApiDeleteAccessTokenRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).deleteAccessToken(reqParams.orgId, reqParams.tokenId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete organization member - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    deleteMember: async (reqParams: MembersApiDeleteMemberReq, options?: any): Promise<AxiosResponse<MembersApiDeleteMemberRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).deleteMember(reqParams.orgId, reqParams.memberId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete organization owner - This operation can only be done by a logged in superadmin of the organization\'s parent organization (TODO it\'s unclear as of this writing whether organization owners may delete themselves). The previous owner is demoted to the \"SupperAdmin\" role. */
    deleteOwner: async (reqParams: MembersApiDeleteOwnerReq, options?: any): Promise<AxiosResponse<MembersApiDeleteOwnerRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).deleteOwner(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get access token members - Returns all access tokens belonging to the organisation */
    getAccessTokens: async (reqParams: MembersApiGetAccessTokensReq, options?: any): Promise<AxiosResponse<MembersApiGetAccessTokensRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).getAccessTokens(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization member - Returns details about this organization member. This operation can only be done by the member itself, a logged in superadmin or owner of the organization or its parent organization(s). */
    getMember: async (reqParams: MembersApiGetMemberReq, options?: any): Promise<AxiosResponse<MembersApiGetMemberRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).getMember(reqParams.orgId, reqParams.memberId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization members - Returns all (both pending and full) members of the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getMembers: async (reqParams: MembersApiGetMembersReq, options?: any): Promise<AxiosResponse<MembersApiGetMembersRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).getMembers(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.role, reqParams.siteAccess, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get a One-Time-Password link for the member - Returns a short lived one time password link for direct log-ins via the users realm. Session holder must be an `Owner`, `SuperAdmin` or `Sysadmin` in the org or the MO. */
    getOrgMemberLogin: async (reqParams: MembersApiGetOrgMemberLoginReq, options?: any): Promise<AxiosResponse<MembersApiGetOrgMemberLoginRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).getOrgMemberLogin(reqParams.orgId, reqParams.memberId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Overwrite organization member settings - Updates member information, such as their roles, site access permissions, and notification settings. This operation can only be done by the logged in superadmin or owner of the organization or its parent organization(s). This operation overwrites existing settings, so all existing values that are meant to be kept should be included in the response body. */
    updateMember: async (reqParams: MembersApiUpdateMemberReq, options?: any): Promise<AxiosResponse<MembersApiUpdateMemberRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).updateMember(reqParams.orgId, reqParams.memberId, reqParams.updateMember, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update organization owner - The new owner must already be a member in the organization before establishing ownership. This operation can only be done by a logged in superadmin of the organization\'s parent organization, or the owner of the organization. In both cases, the previous owner (if they existed) is demoted to SuperAdmin after this operation. */
    updateOwner: async (reqParams: MembersApiUpdateOwnerReq, options?: any): Promise<AxiosResponse<MembersApiUpdateOwnerRes>> => {
        const localVarAxiosArgs = await MembersApiAxiosParamCreator(configuration).updateOwner(reqParams.orgId, reqParams.orgOwnerUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface MembersApiReq {
    createAccessToken: MembersApiCreateAccessTokenReq;
    createMember: MembersApiCreateMemberReq;
    deleteAccessToken: MembersApiDeleteAccessTokenReq;
    deleteMember: MembersApiDeleteMemberReq;
    deleteOwner: MembersApiDeleteOwnerReq;
    getAccessTokens: MembersApiGetAccessTokensReq;
    getMember: MembersApiGetMemberReq;
    getMembers: MembersApiGetMembersReq;
    getOrgMemberLogin: MembersApiGetOrgMemberLoginReq;
    updateMember: MembersApiUpdateMemberReq;
    updateOwner: MembersApiUpdateOwnerReq;
}

export interface MembersApiRes {
    createAccessToken: MembersApiCreateAccessTokenRes;
    createMember: MembersApiCreateMemberRes;
    deleteAccessToken: MembersApiDeleteAccessTokenRes;
    deleteMember: MembersApiDeleteMemberRes;
    deleteOwner: MembersApiDeleteOwnerRes;
    getAccessTokens: MembersApiGetAccessTokensRes;
    getMember: MembersApiGetMemberRes;
    getMembers: MembersApiGetMembersRes;
    getOrgMemberLogin: MembersApiGetOrgMemberLoginRes;
    updateMember: MembersApiUpdateMemberRes;
    updateOwner: MembersApiUpdateOwnerRes;
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Get website metrics - Returns website metrics between the optional start and end date. Defaults to last 24 hours. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteMetrics: async (orgId: string, websiteId: string, start?: string, end?: string, granularity?: 'hour' | 'day', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/metrics`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for getWebsiteMetrics operation in MetricsApi. */
export type MetricsApiGetWebsiteMetricsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Start datetime UTC. */
    start?: string
    /** End datetime UTC. */
    end?: string
    /** Takes one of `hour`, `day`, defaults to `day` */
    granularity?: 'hour' | 'day'
};

/** Response parameters for getWebsiteMetrics operation in MetricsApi. */
export type MetricsApiGetWebsiteMetricsRes = WebsiteMetricsFullListing;

/**
 * MetricsApi - service client interface
 */
export const MetricsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Get website metrics - Returns website metrics between the optional start and end date. Defaults to last 24 hours. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteMetrics: async (reqParams: MetricsApiGetWebsiteMetricsReq, options?: any): Promise<AxiosResponse<MetricsApiGetWebsiteMetricsRes>> => {
        const localVarAxiosArgs = await MetricsApiAxiosParamCreator(configuration).getWebsiteMetrics(reqParams.orgId, reqParams.websiteId, reqParams.start, reqParams.end, reqParams.granularity, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface MetricsApiReq {
    getWebsiteMetrics: MetricsApiGetWebsiteMetricsReq;
}

export interface MetricsApiRes {
    getWebsiteMetrics: MetricsApiGetWebsiteMetricsRes;
}


/**
 * MigrationsApi - axios parameter creator
 * @export
 */
export const MigrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create website role migration - Create a new role website migration from one server to another. Deprecated in favor of createMigrationSession. */
        createMigration: async (newMigrationDetails: NewMigrationDetails, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMigrationDetails !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMigrationDetails !== undefined ? newMigrationDetails : {}) : (newMigrationDetails || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create bulk website role migrations - Create many new role website migration from one server to another. The migrations are grouped with a session id. If any migration fails to schedule, 206 is returned. If all migrations fail to schedule, 400 is returned. See the body for error messages in both cases. */
        createMigrationSession: async (newMigrationDetails: Array<NewMigrationDetails>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/sessions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMigrationDetails !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMigrationDetails !== undefined ? newMigrationDetails : {}) : (newMigrationDetails || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get a single migration - Fetches the details of a single server migration. */
        getMigration: async (migrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/{migrationId}`
                .replace(`{${"migrationId"}}`, encodeURIComponent(String(migrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the log for a migration - Fetches the migration log for a single server migration. */
        getMigrationLog: async (migrationId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/{migrationId}/log`
                .replace(`{${"migrationId"}}`, encodeURIComponent(String(migrationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website role migration sessions - Migration sessions group server migrations together under one id. This endpoint lists sessions, when were they created and when was any migration in the session last updated. If searching by domain, the resulting migrationCount is the number of migrations in the session that match the search criteria. If searching with createdBy, you can search by the email of the user who created the session. If the session was created with an API key, createdBy will be set to \"via API\". Legacy migrations created with deprecated \"createMigration\" endpoint are assigned under session that has createdBy set to \"legacy migrations\" */
        getMigrationSessions: async (offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'createdAt' | 'updatedAt', createdBy?: string, searchDomain?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/sessions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (searchDomain !== undefined) {
                localVarQueryParameter['searchDomain'] = searchDomain;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website role migrations - Lists all server migrations, whether pending, in progress, failed or completed. */
        getMigrations: async (offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'websitePrimaryDomain' | 'websiteId' | 'createdAt' | 'lastUpdated', searchDomain?: string, migrationStatus?: MigrationStatus, sessionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchDomain !== undefined) {
                localVarQueryParameter['searchDomain'] = searchDomain;
            }

            if (migrationStatus !== undefined) {
                localVarQueryParameter['migrationStatus'] = migrationStatus;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createMigration operation in MigrationsApi. */
export type MigrationsApiCreateMigrationReq = {
    /** Migration details. */
    newMigrationDetails: NewMigrationDetails
};

/** Response parameters for createMigration operation in MigrationsApi. */
export type MigrationsApiCreateMigrationRes = NewResourceUuid;


/** Request parameters for createMigrationSession operation in MigrationsApi. */
export type MigrationsApiCreateMigrationSessionReq = {
    /** Migrations details. */
    newMigrationDetails: Array<NewMigrationDetails>
};

/** Response parameters for createMigrationSession operation in MigrationsApi. */
export type MigrationsApiCreateMigrationSessionRes = MigrationSessionCreationOk;


/** Request parameters for getMigration operation in MigrationsApi. */
export type MigrationsApiGetMigrationReq = {
    /** The ID of the migration being acted upon. */
    migrationId: string
};

/** Response parameters for getMigration operation in MigrationsApi. */
export type MigrationsApiGetMigrationRes = MigrationDetails;


/** Request parameters for getMigrationLog operation in MigrationsApi. */
export type MigrationsApiGetMigrationLogReq = {
    /** The ID of the migration being acted upon. */
    migrationId: string
};

/** Response parameters for getMigrationLog operation in MigrationsApi. */
export type MigrationsApiGetMigrationLogRes = Array<MigrationLog>;


/** Request parameters for getMigrationSessions operation in MigrationsApi. */
export type MigrationsApiGetMigrationSessionsReq = {
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** The field by which to sort. */
    sortBy?: 'createdAt' | 'updatedAt'
    /** Look for a specific session creator in the result set. */
    createdBy?: string
    /** Look for a specific domain in the result set. */
    searchDomain?: string
};

/** Response parameters for getMigrationSessions operation in MigrationsApi. */
export type MigrationsApiGetMigrationSessionsRes = MigrationSessionsListing;


/** Request parameters for getMigrations operation in MigrationsApi. */
export type MigrationsApiGetMigrationsReq = {
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** The field by which to sort. */
    sortBy?: 'websitePrimaryDomain' | 'websiteId' | 'createdAt' | 'lastUpdated'
    /** Look for a specific domain in the result set. */
    searchDomain?: string
    /** Filter by a particular migration status */
    migrationStatus?: MigrationStatus
    /** Filter for a specific migration session. */
    sessionId?: string
};

/** Response parameters for getMigrations operation in MigrationsApi. */
export type MigrationsApiGetMigrationsRes = MigrationsListing;

/**
 * MigrationsApi - service client interface
 */
export const MigrationsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create website role migration - Create a new role website migration from one server to another. Deprecated in favor of createMigrationSession. */
    createMigration: async (reqParams: MigrationsApiCreateMigrationReq, options?: any): Promise<AxiosResponse<MigrationsApiCreateMigrationRes>> => {
        const localVarAxiosArgs = await MigrationsApiAxiosParamCreator(configuration).createMigration(reqParams.newMigrationDetails, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create bulk website role migrations - Create many new role website migration from one server to another. The migrations are grouped with a session id. If any migration fails to schedule, 206 is returned. If all migrations fail to schedule, 400 is returned. See the body for error messages in both cases. */
    createMigrationSession: async (reqParams: MigrationsApiCreateMigrationSessionReq, options?: any): Promise<AxiosResponse<MigrationsApiCreateMigrationSessionRes>> => {
        const localVarAxiosArgs = await MigrationsApiAxiosParamCreator(configuration).createMigrationSession(reqParams.newMigrationDetails, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get a single migration - Fetches the details of a single server migration. */
    getMigration: async (reqParams: MigrationsApiGetMigrationReq, options?: any): Promise<AxiosResponse<MigrationsApiGetMigrationRes>> => {
        const localVarAxiosArgs = await MigrationsApiAxiosParamCreator(configuration).getMigration(reqParams.migrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the log for a migration - Fetches the migration log for a single server migration. */
    getMigrationLog: async (reqParams: MigrationsApiGetMigrationLogReq, options?: any): Promise<AxiosResponse<MigrationsApiGetMigrationLogRes>> => {
        const localVarAxiosArgs = await MigrationsApiAxiosParamCreator(configuration).getMigrationLog(reqParams.migrationId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website role migration sessions - Migration sessions group server migrations together under one id. This endpoint lists sessions, when were they created and when was any migration in the session last updated. If searching by domain, the resulting migrationCount is the number of migrations in the session that match the search criteria. If searching with createdBy, you can search by the email of the user who created the session. If the session was created with an API key, createdBy will be set to \"via API\". Legacy migrations created with deprecated \"createMigration\" endpoint are assigned under session that has createdBy set to \"legacy migrations\" */
    getMigrationSessions: async (reqParams: MigrationsApiGetMigrationSessionsReq, options?: any): Promise<AxiosResponse<MigrationsApiGetMigrationSessionsRes>> => {
        const localVarAxiosArgs = await MigrationsApiAxiosParamCreator(configuration).getMigrationSessions(reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.sortBy, reqParams.createdBy, reqParams.searchDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website role migrations - Lists all server migrations, whether pending, in progress, failed or completed. */
    getMigrations: async (reqParams: MigrationsApiGetMigrationsReq, options?: any): Promise<AxiosResponse<MigrationsApiGetMigrationsRes>> => {
        const localVarAxiosArgs = await MigrationsApiAxiosParamCreator(configuration).getMigrations(reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.sortBy, reqParams.searchDomain, reqParams.migrationStatus, reqParams.sessionId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface MigrationsApiReq {
    createMigration: MigrationsApiCreateMigrationReq;
    createMigrationSession: MigrationsApiCreateMigrationSessionReq;
    getMigration: MigrationsApiGetMigrationReq;
    getMigrationLog: MigrationsApiGetMigrationLogReq;
    getMigrationSessions: MigrationsApiGetMigrationSessionsReq;
    getMigrations: MigrationsApiGetMigrationsReq;
}

export interface MigrationsApiRes {
    createMigration: MigrationsApiCreateMigrationRes;
    createMigrationSession: MigrationsApiCreateMigrationSessionRes;
    getMigration: MigrationsApiGetMigrationRes;
    getMigrationLog: MigrationsApiGetMigrationLogRes;
    getMigrationSessions: MigrationsApiGetMigrationSessionsRes;
    getMigrations: MigrationsApiGetMigrationsRes;
}


/**
 * MysqlApi - axios parameter creator
 * @export
 */
export const MysqlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create a MySQL database for website - Creates a new MySQL database for the given website. The supplied name must conform to the following regular expression: `^[0-9a-z$_]+$`. That is, a name may only contain alphanumerical characters, dollar signs, and underscores. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteMySQLDB: async (orgId: string, websiteId: string, newMySQLDB: NewMySQLDB, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMySQLDB !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMySQLDB !== undefined ? newMySQLDB : {}) : (newMySQLDB || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create website MySQL database user - Creates a new MySQL database user for the given website database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteMySQLUser: async (orgId: string, websiteId: string, newMySQLUser: NewMySQLUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMySQLUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMySQLUser !== undefined ? newMySQLUser : {}) : (newMySQLUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create website MySQL database user access hosts - Adds for the given user new access hosts to website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteMySQLUserAccessHosts: async (orgId: string, websiteId: string, username: string, mySQLUserAccessHosts: MySQLUserAccessHosts, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users/{username}/access-hosts`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mySQLUserAccessHosts !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mySQLUserAccessHosts !== undefined ? mySQLUserAccessHosts : {}) : (mySQLUserAccessHosts || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website MySQL database - Delete website\'s MySQL database. NOTE: All data will be lost after this endpoint returns. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteMySQLDB: async (orgId: string, websiteId: string, dbName: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs/{db_name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"db_name"}}`, encodeURIComponent(String(dbName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website MySQL database user - Delete website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteMySQLUser: async (orgId: string, websiteId: string, username: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users/{username}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website MySQL database user access hosts - Removes from the given user access hosts to website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteMySQLUserAccessHosts: async (orgId: string, websiteId: string, username: string, mySQLUserAccessHosts: MySQLUserAccessHosts, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users/{username}/access-hosts`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mySQLUserAccessHosts !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mySQLUserAccessHosts !== undefined ? mySQLUserAccessHosts : {}) : (mySQLUserAccessHosts || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Takes a backup of given database and returns it gziped - Performs a database backup into an sql, gzips the sql and returns the file system path for subsequent download with filerd. */
        downloadSql: async (orgId: string, websiteId: string, dbName: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs/{db_name}/sql`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"db_name"}}`, encodeURIComponent(String(dbName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get phpMyAdmin SSO URL - Fetches a single sign-on URL to access phpMyAdmin for this database without the need to log in. */
        getPhpMyAdminSSOUrl: async (orgId: string, websiteId: string, dbName: string, shouldRedirect?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs/{db_name}/sso`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"db_name"}}`, encodeURIComponent(String(dbName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (shouldRedirect !== undefined) {
                localVarQueryParameter['shouldRedirect'] = shouldRedirect;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website MySQL databases - Returns all MySQL databases belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteMySQLDBs: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website MySQL database users - Returns all MySQL users belonging to the given website database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteMySQLUsers: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create website MySQL database user privileges - Sets the privileges for a user on a given MySQL database.  This will override their current privileges. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        setWebsiteMySQLUserPrivileges: async (orgId: string, websiteId: string, username: string, mySQLUserGrants: MySQLUserGrants, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users/{username}/privileges`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mySQLUserGrants !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mySQLUserGrants !== undefined ? mySQLUserGrants : {}) : (mySQLUserGrants || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update website MySQL database user - Updates website\'s MySQL database user\'s password (username update coming later). Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWebsiteMySQLUser: async (orgId: string, websiteId: string, username: string, mySQLUserUpdate: MySQLUserUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users/{username}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mySQLUserUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mySQLUserUpdate !== undefined ? mySQLUserUpdate : {}) : (mySQLUserUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Uploads sql file and executes it against db - Uploads an sql file which is then executed against given db. Allowed file types are \'.sql\', \'.gz\' and \'.zip\'. The gzip-ed file must be a valid sql. The zip archive may contain only one \'.sql\' file, however the file can be within a directory. If the force flag is set to true (default is false), the SQL execution will not stop when an error is raised (corresponds to the --force option of mysql cli). The max allowed size is 500 MB. */
        uploadSql: async (websiteId: string, dbName: string, sql: any, force?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{websiteId}/mysql/{db_id}/sql`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"db_name"}}`, encodeURIComponent(String(dbName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();
            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


            if (sql !== undefined) { 
                localVarFormParams.append('sql', sql as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createWebsiteMySQLDB operation in MysqlApi. */
export type MysqlApiCreateWebsiteMySQLDBReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** New database details. */
    newMySQLDB: NewMySQLDB
};

/** Response parameters for createWebsiteMySQLDB operation in MysqlApi. */
export type MysqlApiCreateWebsiteMySQLDBRes = void;


/** Request parameters for createWebsiteMySQLUser operation in MysqlApi. */
export type MysqlApiCreateWebsiteMySQLUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** New user details. */
    newMySQLUser: NewMySQLUser
};

/** Response parameters for createWebsiteMySQLUser operation in MysqlApi. */
export type MysqlApiCreateWebsiteMySQLUserRes = void;


/** Request parameters for createWebsiteMySQLUserAccessHosts operation in MysqlApi. */
export type MysqlApiCreateWebsiteMySQLUserAccessHostsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The user of the database user. */
    username: string
    /** User access hosts. */
    mySQLUserAccessHosts: MySQLUserAccessHosts
};

/** Response parameters for createWebsiteMySQLUserAccessHosts operation in MysqlApi. */
export type MysqlApiCreateWebsiteMySQLUserAccessHostsRes = void;


/** Request parameters for deleteWebsiteMySQLDB operation in MysqlApi. */
export type MysqlApiDeleteWebsiteMySQLDBReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The name of the database. */
    dbName: string
};

/** Response parameters for deleteWebsiteMySQLDB operation in MysqlApi. */
export type MysqlApiDeleteWebsiteMySQLDBRes = void;


/** Request parameters for deleteWebsiteMySQLUser operation in MysqlApi. */
export type MysqlApiDeleteWebsiteMySQLUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The user of the database user. */
    username: string
};

/** Response parameters for deleteWebsiteMySQLUser operation in MysqlApi. */
export type MysqlApiDeleteWebsiteMySQLUserRes = void;


/** Request parameters for deleteWebsiteMySQLUserAccessHosts operation in MysqlApi. */
export type MysqlApiDeleteWebsiteMySQLUserAccessHostsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The user of the database user. */
    username: string
    /** User access hosts. */
    mySQLUserAccessHosts: MySQLUserAccessHosts
};

/** Response parameters for deleteWebsiteMySQLUserAccessHosts operation in MysqlApi. */
export type MysqlApiDeleteWebsiteMySQLUserAccessHostsRes = void;


/** Request parameters for downloadSql operation in MysqlApi. */
export type MysqlApiDownloadSqlReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The name of the database. */
    dbName: string
};

/** Response parameters for downloadSql operation in MysqlApi. */
export type MysqlApiDownloadSqlRes = string;


/** Request parameters for getPhpMyAdminSSOUrl operation in MysqlApi. */
export type MysqlApiGetPhpMyAdminSSOUrlReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The name of the database. */
    dbName: string
    /** If set to true, the endpoint will send a 307 redirect to the SSO URL. */
    shouldRedirect?: boolean
};

/** Response parameters for getPhpMyAdminSSOUrl operation in MysqlApi. */
export type MysqlApiGetPhpMyAdminSSOUrlRes = string;


/** Request parameters for getWebsiteMySQLDBs operation in MysqlApi. */
export type MysqlApiGetWebsiteMySQLDBsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteMySQLDBs operation in MysqlApi. */
export type MysqlApiGetWebsiteMySQLDBsRes = MySQLDBsFullListing;


/** Request parameters for getWebsiteMySQLUsers operation in MysqlApi. */
export type MysqlApiGetWebsiteMySQLUsersReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteMySQLUsers operation in MysqlApi. */
export type MysqlApiGetWebsiteMySQLUsersRes = MySQLUsersFullListing;


/** Request parameters for setWebsiteMySQLUserPrivileges operation in MysqlApi. */
export type MysqlApiSetWebsiteMySQLUserPrivilegesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The user of the database user. */
    username: string
    /** User privilege grants. */
    mySQLUserGrants: MySQLUserGrants
};

/** Response parameters for setWebsiteMySQLUserPrivileges operation in MysqlApi. */
export type MysqlApiSetWebsiteMySQLUserPrivilegesRes = void;


/** Request parameters for updateWebsiteMySQLUser operation in MysqlApi. */
export type MysqlApiUpdateWebsiteMySQLUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The user of the database user. */
    username: string
    /** User update details. */
    mySQLUserUpdate: MySQLUserUpdate
};

/** Response parameters for updateWebsiteMySQLUser operation in MysqlApi. */
export type MysqlApiUpdateWebsiteMySQLUserRes = void;


/** Request parameters for uploadSql operation in MysqlApi. */
export type MysqlApiUploadSqlReq = {
    /** The id of the website. */
    websiteId: string
    /** The name of the database. */
    dbName: string
    /** Upload either a raw sql file (must be utf8 valid string) or .zip or .gz file with the sql string. */
    sql: any
    force?: boolean
};

/** Response parameters for uploadSql operation in MysqlApi. */
export type MysqlApiUploadSqlRes = void;

/**
 * MysqlApi - service client interface
 */
export const MysqlApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create a MySQL database for website - Creates a new MySQL database for the given website. The supplied name must conform to the following regular expression: `^[0-9a-z$_]+$`. That is, a name may only contain alphanumerical characters, dollar signs, and underscores. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteMySQLDB: async (reqParams: MysqlApiCreateWebsiteMySQLDBReq, options?: any): Promise<AxiosResponse<MysqlApiCreateWebsiteMySQLDBRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).createWebsiteMySQLDB(reqParams.orgId, reqParams.websiteId, reqParams.newMySQLDB, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create website MySQL database user - Creates a new MySQL database user for the given website database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteMySQLUser: async (reqParams: MysqlApiCreateWebsiteMySQLUserReq, options?: any): Promise<AxiosResponse<MysqlApiCreateWebsiteMySQLUserRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).createWebsiteMySQLUser(reqParams.orgId, reqParams.websiteId, reqParams.newMySQLUser, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create website MySQL database user access hosts - Adds for the given user new access hosts to website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteMySQLUserAccessHosts: async (reqParams: MysqlApiCreateWebsiteMySQLUserAccessHostsReq, options?: any): Promise<AxiosResponse<MysqlApiCreateWebsiteMySQLUserAccessHostsRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).createWebsiteMySQLUserAccessHosts(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.mySQLUserAccessHosts, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website MySQL database - Delete website\'s MySQL database. NOTE: All data will be lost after this endpoint returns. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteMySQLDB: async (reqParams: MysqlApiDeleteWebsiteMySQLDBReq, options?: any): Promise<AxiosResponse<MysqlApiDeleteWebsiteMySQLDBRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).deleteWebsiteMySQLDB(reqParams.orgId, reqParams.websiteId, reqParams.dbName, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website MySQL database user - Delete website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteMySQLUser: async (reqParams: MysqlApiDeleteWebsiteMySQLUserReq, options?: any): Promise<AxiosResponse<MysqlApiDeleteWebsiteMySQLUserRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).deleteWebsiteMySQLUser(reqParams.orgId, reqParams.websiteId, reqParams.username, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website MySQL database user access hosts - Removes from the given user access hosts to website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteMySQLUserAccessHosts: async (reqParams: MysqlApiDeleteWebsiteMySQLUserAccessHostsReq, options?: any): Promise<AxiosResponse<MysqlApiDeleteWebsiteMySQLUserAccessHostsRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).deleteWebsiteMySQLUserAccessHosts(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.mySQLUserAccessHosts, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Takes a backup of given database and returns it gziped - Performs a database backup into an sql, gzips the sql and returns the file system path for subsequent download with filerd. */
    downloadSql: async (reqParams: MysqlApiDownloadSqlReq, options?: any): Promise<AxiosResponse<MysqlApiDownloadSqlRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).downloadSql(reqParams.orgId, reqParams.websiteId, reqParams.dbName, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get phpMyAdmin SSO URL - Fetches a single sign-on URL to access phpMyAdmin for this database without the need to log in. */
    getPhpMyAdminSSOUrl: async (reqParams: MysqlApiGetPhpMyAdminSSOUrlReq, options?: any): Promise<AxiosResponse<MysqlApiGetPhpMyAdminSSOUrlRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).getPhpMyAdminSSOUrl(reqParams.orgId, reqParams.websiteId, reqParams.dbName, reqParams.shouldRedirect, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website MySQL databases - Returns all MySQL databases belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteMySQLDBs: async (reqParams: MysqlApiGetWebsiteMySQLDBsReq, options?: any): Promise<AxiosResponse<MysqlApiGetWebsiteMySQLDBsRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).getWebsiteMySQLDBs(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website MySQL database users - Returns all MySQL users belonging to the given website database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteMySQLUsers: async (reqParams: MysqlApiGetWebsiteMySQLUsersReq, options?: any): Promise<AxiosResponse<MysqlApiGetWebsiteMySQLUsersRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).getWebsiteMySQLUsers(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create website MySQL database user privileges - Sets the privileges for a user on a given MySQL database.  This will override their current privileges. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    setWebsiteMySQLUserPrivileges: async (reqParams: MysqlApiSetWebsiteMySQLUserPrivilegesReq, options?: any): Promise<AxiosResponse<MysqlApiSetWebsiteMySQLUserPrivilegesRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).setWebsiteMySQLUserPrivileges(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.mySQLUserGrants, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update website MySQL database user - Updates website\'s MySQL database user\'s password (username update coming later). Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWebsiteMySQLUser: async (reqParams: MysqlApiUpdateWebsiteMySQLUserReq, options?: any): Promise<AxiosResponse<MysqlApiUpdateWebsiteMySQLUserRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).updateWebsiteMySQLUser(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.mySQLUserUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Uploads sql file and executes it against db - Uploads an sql file which is then executed against given db. Allowed file types are \'.sql\', \'.gz\' and \'.zip\'. The gzip-ed file must be a valid sql. The zip archive may contain only one \'.sql\' file, however the file can be within a directory. If the force flag is set to true (default is false), the SQL execution will not stop when an error is raised (corresponds to the --force option of mysql cli). The max allowed size is 500 MB. */
    uploadSql: async (reqParams: MysqlApiUploadSqlReq, options?: any): Promise<AxiosResponse<MysqlApiUploadSqlRes>> => {
        const localVarAxiosArgs = await MysqlApiAxiosParamCreator(configuration).uploadSql(reqParams.websiteId, reqParams.dbName, reqParams.sql, reqParams.force, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface MysqlApiReq {
    createWebsiteMySQLDB: MysqlApiCreateWebsiteMySQLDBReq;
    createWebsiteMySQLUser: MysqlApiCreateWebsiteMySQLUserReq;
    createWebsiteMySQLUserAccessHosts: MysqlApiCreateWebsiteMySQLUserAccessHostsReq;
    deleteWebsiteMySQLDB: MysqlApiDeleteWebsiteMySQLDBReq;
    deleteWebsiteMySQLUser: MysqlApiDeleteWebsiteMySQLUserReq;
    deleteWebsiteMySQLUserAccessHosts: MysqlApiDeleteWebsiteMySQLUserAccessHostsReq;
    downloadSql: MysqlApiDownloadSqlReq;
    getPhpMyAdminSSOUrl: MysqlApiGetPhpMyAdminSSOUrlReq;
    getWebsiteMySQLDBs: MysqlApiGetWebsiteMySQLDBsReq;
    getWebsiteMySQLUsers: MysqlApiGetWebsiteMySQLUsersReq;
    setWebsiteMySQLUserPrivileges: MysqlApiSetWebsiteMySQLUserPrivilegesReq;
    updateWebsiteMySQLUser: MysqlApiUpdateWebsiteMySQLUserReq;
    uploadSql: MysqlApiUploadSqlReq;
}

export interface MysqlApiRes {
    createWebsiteMySQLDB: MysqlApiCreateWebsiteMySQLDBRes;
    createWebsiteMySQLUser: MysqlApiCreateWebsiteMySQLUserRes;
    createWebsiteMySQLUserAccessHosts: MysqlApiCreateWebsiteMySQLUserAccessHostsRes;
    deleteWebsiteMySQLDB: MysqlApiDeleteWebsiteMySQLDBRes;
    deleteWebsiteMySQLUser: MysqlApiDeleteWebsiteMySQLUserRes;
    deleteWebsiteMySQLUserAccessHosts: MysqlApiDeleteWebsiteMySQLUserAccessHostsRes;
    downloadSql: MysqlApiDownloadSqlRes;
    getPhpMyAdminSSOUrl: MysqlApiGetPhpMyAdminSSOUrlRes;
    getWebsiteMySQLDBs: MysqlApiGetWebsiteMySQLDBsRes;
    getWebsiteMySQLUsers: MysqlApiGetWebsiteMySQLUsersRes;
    setWebsiteMySQLUserPrivileges: MysqlApiSetWebsiteMySQLUserPrivilegesRes;
    updateWebsiteMySQLUser: MysqlApiUpdateWebsiteMySQLUserRes;
    uploadSql: MysqlApiUploadSqlRes;
}


/**
 * OrgsApi - axios parameter creator
 * @export
 */
export const OrgsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create organisation access token - Create an access token with rights within an organisation. */
        createAccessToken: async (orgId: string, newAccessToken: NewAccessToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/access_tokens`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newAccessToken !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newAccessToken !== undefined ? newAccessToken : {}) : (newAccessToken || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set CloudFlare API key, org level - Adds a CloudFlare API key for an org. */
        createCloudflareApiKey: async (orgId: string, newCloudFlareToken: NewCloudFlareToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/cloudflare`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newCloudFlareToken !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newCloudFlareToken !== undefined ? newCloudFlareToken : {}) : (newCloudFlareToken || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a customer organization - Once customer is successfully created under parent organization (identified by org_id), the customer organization\'s id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        createCustomer: async (orgId: string, newCustomer: NewCustomer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newCustomer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newCustomer !== undefined ? newCustomer : {}) : (newCustomer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create organization member - A login for the member needs to exist before establishing membership. On success, the member id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        createMember: async (orgId: string, newMember: NewMember, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMember !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMember !== undefined ? newMember : {}) : (newMember || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan - Creates a new plan for organization. If the organization is a reseller and not the MO, the reseller must itself have a subscription to a reseller plan. It is verified that the reseller is not trying to create a plan offering more resources than the reseller has available through its reseller plan. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlan: async (orgId: string, newPlan: NewPlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPlan !== undefined ? newPlan : {}) : (newPlan || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan allowances - Creates new allowances for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer allowances that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlanAllowances: async (orgId: string, planId: number, allowance: Array<Allowance>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/allowances`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof allowance !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(allowance !== undefined ? allowance : {}) : (allowance || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan resources - Creates new resources for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlanResources: async (orgId: string, planId: number, resource: Array<Resource>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/resources`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resource !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resource !== undefined ? resource : {}) : (resource || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan selections - Creates new selections for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer selections that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlanSelections: async (orgId: string, planId: number, selection: Array<Selection>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/selections`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof selection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(selection !== undefined ? selection : {}) : (selection || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create tag - Creates a new tag for the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createTag: async (orgId: string, newTag: NewTag, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/tags`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newTag !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newTag !== undefined ? newTag : {}) : (newTag || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete CloudFlare API key, org level - Delete a CloudFlare API key for an org. */
        deleteCloudflareApiKey: async (orgId: string, cloudflareKey: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/cloudflare/{cloudflare_key}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"cloudflare_key"}}`, encodeURIComponent(String(cloudflareKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete organization member - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        deleteMember: async (orgId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete organization - Soft or force deletes the organization and its resources. All resources under the organization (websites, customers in case of a reseller) will be deleted too. If the organization is soft-deleted (or marked as deleted), its data is not removed.  For removing all data and metadata, pass the `force=true` query parameter. This can only be done by a privileged MO member. In this case, all data is wiped, so use carefully. If the `force` parameter is set, session holder must be an MO Owner, SuperAdmin, or Support member, otherwise it suffices for them to be such a member in a parent org. */
        deleteOrg: async (orgId: string, force?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Remove org avatar - Deletes the org\'s avatar. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteOrgAvatar: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/avatar`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete organization owner - This operation can only be done by a logged in superadmin of the organization\'s parent organization (TODO it\'s unclear as of this writing whether organization owners may delete themselves). The previous owner is demoted to the \"SupperAdmin\" role. */
        deleteOwner: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/owner`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete plan - Deletes a plan if no subscriptions exist to it. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deletePlan: async (orgId: string, planId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete plan allowance - Deletes the plan allowance. Session holder must have admin privileges in this org or a parent. That is, to have Owner or SuperAdmin roles in the current org or a parent, or to have the Support role in a parent. org. */
        deletePlanAllowance: async (orgId: string, planId: number, name: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/allowances/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website MySQL database user access hosts - Removes from the given user access hosts to website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteMySQLUserAccessHosts: async (orgId: string, websiteId: string, username: string, mySQLUserAccessHosts: MySQLUserAccessHosts, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-users/{username}/access-hosts`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mySQLUserAccessHosts !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mySQLUserAccessHosts !== undefined ? mySQLUserAccessHosts : {}) : (mySQLUserAccessHosts || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get affected domains for a CloudFlare key - Get affected domains for an organisation\'s CloudFlare key */
        getCloudFlareKeyAffectedDomains: async (orgId: string, cloudflareKey: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/cloudflare/{cloudflare_key}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"cloudflare_key"}}`, encodeURIComponent(String(cloudflareKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get CloudFlare API keys, org level - Returns the CloudFlare API keys for an org (obfuscated for security). */
        getCloudflareApiKeys: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/cloudflare`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get customers added over a given time period - Returns the number of customers added over a given time period. Includes only direct customers, not customers of customers. */
        getCustomersAdded: async (orgId: string, timeInterval?: 'hour' | 'day' | 'month', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/stats/customers/added`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (timeInterval !== undefined) {
                localVarQueryParameter['timeInterval'] = timeInterval;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get org emails - Returns all emails belonging to this organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getEmails: async (orgId: string, offset?: number, limit?: number, sortBy?: 'domain' | 'subscription' | 'org' | 'size', sortOrder?: 'asc' | 'desc', search?: string, recursive?: boolean, maxDepth?: number, status?: WebsiteStatus, domainId?: string, planId?: number, subscriptionId?: number, includeInternal?: boolean, showDeleted?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/emails`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['maxDepth'] = maxDepth;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (domainId !== undefined) {
                localVarQueryParameter['domainId'] = domainId;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }

            if (includeInternal !== undefined) {
                localVarQueryParameter['includeInternal'] = includeInternal;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization member - Returns details about this organization member. This operation can only be done by the member itself, a logged in superadmin or owner of the organization or its parent organization(s). */
        getMember: async (orgId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization members - Returns all (both pending and full) members of the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getMembers: async (orgId: string, offset?: number, limit?: number, sortBy?: 'status' | 'joinedAt', sortOrder?: 'asc' | 'desc', role?: Role, siteAccess?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (siteAccess !== undefined) {
                localVarQueryParameter['siteAccess'] = siteAccess;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization info - Returns basic organization information. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getOrg: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get organization\'s activity log. - Returns organization\'s activity log which is a human readable list of events that happened in orchd. Only accessible to Owner, SuperAdmin and Sysadmin. */
        getOrgActivities: async (orgId: string, offset?: number, limit?: number, createdBefore?: string, createdAfter?: string, activityKinds?: Array<ActivityKind>, anyEntityId?: Array<string>, entityKind?: 'website' | 'login' | 'org' | 'domain', search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/orgs/{org_id}/activities`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = (createdBefore as any instanceof Date) ?
                    (createdBefore as any).toISOString().substr(0,10) :
                    createdBefore;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = (createdAfter as any instanceof Date) ?
                    (createdAfter as any).toISOString().substr(0,10) :
                    createdAfter;
            }

            if (activityKinds !== undefined) {
                localVarQueryParameter['activityKinds'] = activityKinds;
            }

            if (anyEntityId !== undefined) {
                localVarQueryParameter['anyEntityId'] = anyEntityId;
            }

            if (entityKind !== undefined) {
                localVarQueryParameter['entityKind'] = entityKind;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get a One-Time-Password link for the member - Returns a short lived one time password link for direct log-ins via the users realm. Session holder must be an `Owner`, `SuperAdmin` or `Sysadmin` in the org or the MO. */
        getOrgMemberLogin: async (orgId: string, memberId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}/sso`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get plan - Returns info on a single plan offered by this organization. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
        getPlan: async (orgId: string, planId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get plans - Returns the organization\'s plans, optionally filtered by query parameters. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
        getPlans: async (orgId: string, offset?: number, limit?: number, sortBy?: 'name', sortOrder?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get tags - Returns all tags belonging to the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getTags: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/tags`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns the SSL for this website domain - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
        getWebsiteDomainSslCert: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns the SSL for this website domain with the mail.prefix - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
        getWebsiteMailDomainSslCert: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/mail_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get websites added over a given time period - Returns the number of websites added each day over a given timeframe. */
        getWebsitesAdded: async (orgId: string, timeInterval?: 'hour' | 'day' | 'month', recursion?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/stats/websites/added`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (timeInterval !== undefined) {
                localVarQueryParameter['timeInterval'] = timeInterval;
            }

            if (recursion !== undefined) {
                localVarQueryParameter['recursion'] = recursion;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set org avatar - Sets the org\'s avatar, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        setOrgAvatar: async (orgId: string, avatar: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/avatar`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (avatar !== undefined) { 
                localVarFormParams.append('avatar', avatar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set \"force ssl\" status for domain mapping -  */
        setWebsiteDomainForceSsl: async (domainId: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl/force_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update CloudFlare API key - Update a CloudFlare API key for an org. */
        updateCloudflareApiKey: async (orgId: string, cloudflareKey: string, updateCloudFlareApiKey: UpdateCloudFlareApiKey, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/cloudflare/{cloudflare_key}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"cloudflare_key"}}`, encodeURIComponent(String(cloudflareKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateCloudFlareApiKey !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCloudFlareApiKey !== undefined ? updateCloudFlareApiKey : {}) : (updateCloudFlareApiKey || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Overwrite organization member settings - Updates member information, such as their roles, site access permissions, and notification settings. This operation can only be done by the logged in superadmin or owner of the organization or its parent organization(s). This operation overwrites existing settings, so all existing values that are meant to be kept should be included in the response body. */
        updateMember: async (orgId: string, memberId: string, updateMember: UpdateMember, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/members/{member_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"member_id"}}`, encodeURIComponent(String(memberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateMember !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateMember !== undefined ? updateMember : {}) : (updateMember || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update organization - Updates the given org\'s name. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updateOrg: async (orgId: string, orgUpdate: OrgUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orgUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orgUpdate !== undefined ? orgUpdate : {}) : (orgUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update organization owner - The new owner must already be a member in the organization before establishing ownership. This operation can only be done by a logged in superadmin of the organization\'s parent organization, or the owner of the organization. In both cases, the previous owner (if they existed) is demoted to SuperAdmin after this operation. */
        updateOwner: async (orgId: string, orgOwnerUpdate: OrgOwnerUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/owner`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orgOwnerUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orgOwnerUpdate !== undefined ? orgOwnerUpdate : {}) : (orgOwnerUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan name - Updates a plan\'s name of plan type Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlan: async (orgId: string, planId: number, updatePlan: UpdatePlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updatePlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updatePlan !== undefined ? updatePlan : {}) : (updatePlan || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan allowance - Updates the plan allowance. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of allowance than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlanAllowance: async (orgId: string, planId: number, name: string, allowance: Allowance, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/allowances/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof allowance !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(allowance !== undefined ? allowance : {}) : (allowance || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan resource - Updates the plan resource. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlanResource: async (orgId: string, planId: number, name: string, resource: Resource, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/resources/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resource !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resource !== undefined ? resource : {}) : (resource || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan selection - Updates the plan selection. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of selection than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlanSelection: async (orgId: string, planId: number, name: string, selection: Selection, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/selections/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof selection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(selection !== undefined ? selection : {}) : (selection || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload custom ssl certificate, key and optional fullchain for a given website - Endpoint for uploading custom SSL certificate for a given website. Verifies the cert key and maps to relevant domains that the certificate can be applied to. Returns error if no domain match is found. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        uploadWebsiteDomainSslCert: async (domainId: string, sslCert: SslCert, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sslCert !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sslCert !== undefined ? sslCert : {}) : (sslCert || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload SSL for mail.customerdomain. -  */
        uploadWebsiteMailDomainSslCert: async (domainId: string, sslCert: SslCert, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/mail_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sslCert !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sslCert !== undefined ? sslCert : {}) : (sslCert || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createAccessToken operation in OrgsApi. */
export type OrgsApiCreateAccessTokenReq = {
    /** The id of the organization. */
    orgId: string
    /** Access token details */
    newAccessToken: NewAccessToken
};

/** Response parameters for createAccessToken operation in OrgsApi. */
export type OrgsApiCreateAccessTokenRes = NewAccessTokenResponse;


/** Request parameters for createCloudflareApiKey operation in OrgsApi. */
export type OrgsApiCreateCloudflareApiKeyReq = {
    /** The id of the organization. */
    orgId: string
    /** Key in plain text. */
    newCloudFlareToken: NewCloudFlareToken
};

/** Response parameters for createCloudflareApiKey operation in OrgsApi. */
export type OrgsApiCreateCloudflareApiKeyRes = string;


/** Request parameters for createCustomer operation in OrgsApi. */
export type OrgsApiCreateCustomerReq = {
    /** The id of the organization. */
    orgId: string
    /** Customer organization details. */
    newCustomer: NewCustomer
};

/** Response parameters for createCustomer operation in OrgsApi. */
export type OrgsApiCreateCustomerRes = NewResourceUuid;


/** Request parameters for createMember operation in OrgsApi. */
export type OrgsApiCreateMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** New member details */
    newMember: NewMember
};

/** Response parameters for createMember operation in OrgsApi. */
export type OrgsApiCreateMemberRes = NewResourceUuid;


/** Request parameters for createPlan operation in OrgsApi. */
export type OrgsApiCreatePlanReq = {
    /** The id of the organization. */
    orgId: string
    /** New plan details */
    newPlan: NewPlan
};

/** Response parameters for createPlan operation in OrgsApi. */
export type OrgsApiCreatePlanRes = NewResourceId;


/** Request parameters for createPlanAllowances operation in OrgsApi. */
export type OrgsApiCreatePlanAllowancesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    allowance: Array<Allowance>
};

/** Response parameters for createPlanAllowances operation in OrgsApi. */
export type OrgsApiCreatePlanAllowancesRes = void;


/** Request parameters for createPlanResources operation in OrgsApi. */
export type OrgsApiCreatePlanResourcesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    resource: Array<Resource>
};

/** Response parameters for createPlanResources operation in OrgsApi. */
export type OrgsApiCreatePlanResourcesRes = void;


/** Request parameters for createPlanSelections operation in OrgsApi. */
export type OrgsApiCreatePlanSelectionsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    selection: Array<Selection>
};

/** Response parameters for createPlanSelections operation in OrgsApi. */
export type OrgsApiCreatePlanSelectionsRes = void;


/** Request parameters for createTag operation in OrgsApi. */
export type OrgsApiCreateTagReq = {
    /** The id of the organization. */
    orgId: string
    /** New tag details */
    newTag: NewTag
};

/** Response parameters for createTag operation in OrgsApi. */
export type OrgsApiCreateTagRes = NewResourceId;


/** Request parameters for deleteCloudflareApiKey operation in OrgsApi. */
export type OrgsApiDeleteCloudflareApiKeyReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of a CloudFlare key to be acted upon. */
    cloudflareKey: string
};

/** Response parameters for deleteCloudflareApiKey operation in OrgsApi. */
export type OrgsApiDeleteCloudflareApiKeyRes = void;


/** Request parameters for deleteMember operation in OrgsApi. */
export type OrgsApiDeleteMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
};

/** Response parameters for deleteMember operation in OrgsApi. */
export type OrgsApiDeleteMemberRes = void;


/** Request parameters for deleteOrg operation in OrgsApi. */
export type OrgsApiDeleteOrgReq = {
    /** The id of the organization. */
    orgId: string
    force?: boolean
};

/** Response parameters for deleteOrg operation in OrgsApi. */
export type OrgsApiDeleteOrgRes = void;


/** Request parameters for deleteOrgAvatar operation in OrgsApi. */
export type OrgsApiDeleteOrgAvatarReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteOrgAvatar operation in OrgsApi. */
export type OrgsApiDeleteOrgAvatarRes = void;


/** Request parameters for deleteOwner operation in OrgsApi. */
export type OrgsApiDeleteOwnerReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteOwner operation in OrgsApi. */
export type OrgsApiDeleteOwnerRes = void;


/** Request parameters for deletePlan operation in OrgsApi. */
export type OrgsApiDeletePlanReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
};

/** Response parameters for deletePlan operation in OrgsApi. */
export type OrgsApiDeletePlanRes = void;


/** Request parameters for deletePlanAllowance operation in OrgsApi. */
export type OrgsApiDeletePlanAllowanceReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the allowance. */
    name: string
};

/** Response parameters for deletePlanAllowance operation in OrgsApi. */
export type OrgsApiDeletePlanAllowanceRes = void;


/** Request parameters for deleteWebsiteMySQLUserAccessHosts operation in OrgsApi. */
export type OrgsApiDeleteWebsiteMySQLUserAccessHostsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The user of the database user. */
    username: string
    /** User access hosts. */
    mySQLUserAccessHosts: MySQLUserAccessHosts
};

/** Response parameters for deleteWebsiteMySQLUserAccessHosts operation in OrgsApi. */
export type OrgsApiDeleteWebsiteMySQLUserAccessHostsRes = void;


/** Request parameters for getCloudFlareKeyAffectedDomains operation in OrgsApi. */
export type OrgsApiGetCloudFlareKeyAffectedDomainsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of a CloudFlare key to be acted upon. */
    cloudflareKey: string
};

/** Response parameters for getCloudFlareKeyAffectedDomains operation in OrgsApi. */
export type OrgsApiGetCloudFlareKeyAffectedDomainsRes = Array<string>;


/** Request parameters for getCloudflareApiKeys operation in OrgsApi. */
export type OrgsApiGetCloudflareApiKeysReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getCloudflareApiKeys operation in OrgsApi. */
export type OrgsApiGetCloudflareApiKeysRes = Array<CloudFlareApiKey>;


/** Request parameters for getCustomersAdded operation in OrgsApi. */
export type OrgsApiGetCustomersAddedReq = {
    /** The id of the organization. */
    orgId: string
    timeInterval?: 'hour' | 'day' | 'month'
};

/** Response parameters for getCustomersAdded operation in OrgsApi. */
export type OrgsApiGetCustomersAddedRes = Array<ResourceCountByInterval>;


/** Request parameters for getEmails operation in OrgsApi. */
export type OrgsApiGetEmailsReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'domain' | 'subscription' | 'org' | 'size'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Limit the result set to the resources whose names, partially and case insensitively, match the specified search term. E.g. for websites, this is their domain or tag, for databases the database name, for emails the email address or mailbox name, etc. A website will also be returned if the search term exactly matches the website\'s uuid. */
    search?: string
    /** If set to true, the endpoint will return resources in some hierarchy recursively, that is, several or all levels of the hierarchy, depending on whether `maxDepth` is set. E.g. for customers this means direct and indirect customers are returned. For websites, this returns websites of all direct and indirect customers. */
    recursive?: boolean
    /** If recursive is set to true, this can be specified to limit the recursion depth. By default there is no recursion bound. */
    maxDepth?: number
    /** Limit the result set to emails with the specified status. Only applicable if `recursive` is set to true. */
    status?: WebsiteStatus
    /** Limit the result set to emails under domain. */
    domainId?: string
    /** Limit the result set to resources under subscriptions to the plan. */
    planId?: number
    /** Limit the result set to resources under subscription. */
    subscriptionId?: number
    /** Include internal emails in response */
    includeInternal?: boolean
    /** Filters out deleted emails, which are otherwise returned in the result. Defaults to `showDeleted=true` if not set. Can only be set by MO, if set by others, a 403 is returned. */
    showDeleted?: boolean
};

/** Response parameters for getEmails operation in OrgsApi. */
export type OrgsApiGetEmailsRes = EmailsListing;


/** Request parameters for getMember operation in OrgsApi. */
export type OrgsApiGetMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
};

/** Response parameters for getMember operation in OrgsApi. */
export type OrgsApiGetMemberRes = Member;


/** Request parameters for getMembers operation in OrgsApi. */
export type OrgsApiGetMembersReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'status' | 'joinedAt'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Return only members with this role. */
    role?: Role
    /** Return only collaborator members that have access to this website. Note that super admins and owners are not returned because they implicitly have access. */
    siteAccess?: string
};

/** Response parameters for getMembers operation in OrgsApi. */
export type OrgsApiGetMembersRes = MembersListing;


/** Request parameters for getOrg operation in OrgsApi. */
export type OrgsApiGetOrgReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getOrg operation in OrgsApi. */
export type OrgsApiGetOrgRes = Org;


/** Request parameters for getOrgActivities operation in OrgsApi. */
export type OrgsApiGetOrgActivitiesReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** Only return resources which have been created earlier than provided date. */
    createdBefore?: string
    /** Only return resources which have been created after provided date. */
    createdAfter?: string
    /** Select only activities matching the given kinds. If not provided or provided empty array, all kinds are selected as it makes no sense for an activity to not have a kind. */
    activityKinds?: Array<ActivityKind>
    /** Filter activities maching any of the provided uuids. Since an activity can have 0 or more entities, providing an empty array is not the same as not providing this parameter. An empty array will match activities with 0 entities, while not providing this parameter will ignore this filter. */
    anyEntityId?: Array<string>
    /** Activities which contain the given entity kind either as object or context entity. */
    entityKind?: 'website' | 'login' | 'org' | 'domain'
    /** Limit the result set to the resources whose names, partially and case insensitively, match the specified search term. E.g. for websites, this is their domain or tag, for databases the database name, for emails the email address or mailbox name, etc. A website will also be returned if the search term exactly matches the website\'s uuid. */
    search?: string
};

/** Response parameters for getOrgActivities operation in OrgsApi. */
export type OrgsApiGetOrgActivitiesRes = ActivitiesListing;


/** Request parameters for getOrgMemberLogin operation in OrgsApi. */
export type OrgsApiGetOrgMemberLoginReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
};

/** Response parameters for getOrgMemberLogin operation in OrgsApi. */
export type OrgsApiGetOrgMemberLoginRes = string;


/** Request parameters for getPlan operation in OrgsApi. */
export type OrgsApiGetPlanReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
};

/** Response parameters for getPlan operation in OrgsApi. */
export type OrgsApiGetPlanRes = Plan;


/** Request parameters for getPlans operation in OrgsApi. */
export type OrgsApiGetPlansReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'name'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
};

/** Response parameters for getPlans operation in OrgsApi. */
export type OrgsApiGetPlansRes = PlansListing;


/** Request parameters for getTags operation in OrgsApi. */
export type OrgsApiGetTagsReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getTags operation in OrgsApi. */
export type OrgsApiGetTagsRes = TagsFullListing;


/** Request parameters for getWebsiteDomainSslCert operation in OrgsApi. */
export type OrgsApiGetWebsiteDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainSslCert operation in OrgsApi. */
export type OrgsApiGetWebsiteDomainSslCertRes = DomainSslCertWithData;


/** Request parameters for getWebsiteMailDomainSslCert operation in OrgsApi. */
export type OrgsApiGetWebsiteMailDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteMailDomainSslCert operation in OrgsApi. */
export type OrgsApiGetWebsiteMailDomainSslCertRes = DomainSslCertWithData;


/** Request parameters for getWebsitesAdded operation in OrgsApi. */
export type OrgsApiGetWebsitesAddedReq = {
    /** The id of the organization. */
    orgId: string
    timeInterval?: 'hour' | 'day' | 'month'
    recursion?: boolean
};

/** Response parameters for getWebsitesAdded operation in OrgsApi. */
export type OrgsApiGetWebsitesAddedRes = Array<ResourceCountByInterval>;


/** Request parameters for setOrgAvatar operation in OrgsApi. */
export type OrgsApiSetOrgAvatarReq = {
    /** The id of the organization. */
    orgId: string
    avatar: any
};

/** Response parameters for setOrgAvatar operation in OrgsApi. */
export type OrgsApiSetOrgAvatarRes = void;


/** Request parameters for setWebsiteDomainForceSsl operation in OrgsApi. */
export type OrgsApiSetWebsiteDomainForceSslReq = {
    /** The id of the domain. */
    domainId: string
    /** Boolean \"force ssl\" setting */
    body: boolean
};

/** Response parameters for setWebsiteDomainForceSsl operation in OrgsApi. */
export type OrgsApiSetWebsiteDomainForceSslRes = void;


/** Request parameters for updateCloudflareApiKey operation in OrgsApi. */
export type OrgsApiUpdateCloudflareApiKeyReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of a CloudFlare key to be acted upon. */
    cloudflareKey: string
    /** Key in plain text. */
    updateCloudFlareApiKey: UpdateCloudFlareApiKey
};

/** Response parameters for updateCloudflareApiKey operation in OrgsApi. */
export type OrgsApiUpdateCloudflareApiKeyRes = void;


/** Request parameters for updateMember operation in OrgsApi. */
export type OrgsApiUpdateMemberReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the member. */
    memberId: string
    /** Member settings */
    updateMember: UpdateMember
};

/** Response parameters for updateMember operation in OrgsApi. */
export type OrgsApiUpdateMemberRes = void;


/** Request parameters for updateOrg operation in OrgsApi. */
export type OrgsApiUpdateOrgReq = {
    /** The id of the organization. */
    orgId: string
    /** Organization details. */
    orgUpdate: OrgUpdate
};

/** Response parameters for updateOrg operation in OrgsApi. */
export type OrgsApiUpdateOrgRes = void;


/** Request parameters for updateOwner operation in OrgsApi. */
export type OrgsApiUpdateOwnerReq = {
    /** The id of the organization. */
    orgId: string
    /** Membership id of the to-be owner */
    orgOwnerUpdate: OrgOwnerUpdate
};

/** Response parameters for updateOwner operation in OrgsApi. */
export type OrgsApiUpdateOwnerRes = void;


/** Request parameters for updatePlan operation in OrgsApi. */
export type OrgsApiUpdatePlanReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    updatePlan: UpdatePlan
};

/** Response parameters for updatePlan operation in OrgsApi. */
export type OrgsApiUpdatePlanRes = void;


/** Request parameters for updatePlanAllowance operation in OrgsApi. */
export type OrgsApiUpdatePlanAllowanceReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the allowance. */
    name: string
    allowance: Allowance
};

/** Response parameters for updatePlanAllowance operation in OrgsApi. */
export type OrgsApiUpdatePlanAllowanceRes = void;


/** Request parameters for updatePlanResource operation in OrgsApi. */
export type OrgsApiUpdatePlanResourceReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the resource. */
    name: string
    resource: Resource
};

/** Response parameters for updatePlanResource operation in OrgsApi. */
export type OrgsApiUpdatePlanResourceRes = void;


/** Request parameters for updatePlanSelection operation in OrgsApi. */
export type OrgsApiUpdatePlanSelectionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the selection. */
    name: string
    selection: Selection
};

/** Response parameters for updatePlanSelection operation in OrgsApi. */
export type OrgsApiUpdatePlanSelectionRes = void;


/** Request parameters for uploadWebsiteDomainSslCert operation in OrgsApi. */
export type OrgsApiUploadWebsiteDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
    /** Cert, private key and optional fullchain. */
    sslCert: SslCert
};

/** Response parameters for uploadWebsiteDomainSslCert operation in OrgsApi. */
export type OrgsApiUploadWebsiteDomainSslCertRes = NewSslCert;


/** Request parameters for uploadWebsiteMailDomainSslCert operation in OrgsApi. */
export type OrgsApiUploadWebsiteMailDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
    /** Cert, private key and optional fullchain. */
    sslCert: SslCert
};

/** Response parameters for uploadWebsiteMailDomainSslCert operation in OrgsApi. */
export type OrgsApiUploadWebsiteMailDomainSslCertRes = NewSslCert;

/**
 * OrgsApi - service client interface
 */
export const OrgsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create organisation access token - Create an access token with rights within an organisation. */
    createAccessToken: async (reqParams: OrgsApiCreateAccessTokenReq, options?: any): Promise<AxiosResponse<OrgsApiCreateAccessTokenRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createAccessToken(reqParams.orgId, reqParams.newAccessToken, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set CloudFlare API key, org level - Adds a CloudFlare API key for an org. */
    createCloudflareApiKey: async (reqParams: OrgsApiCreateCloudflareApiKeyReq, options?: any): Promise<AxiosResponse<OrgsApiCreateCloudflareApiKeyRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createCloudflareApiKey(reqParams.orgId, reqParams.newCloudFlareToken, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a customer organization - Once customer is successfully created under parent organization (identified by org_id), the customer organization\'s id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    createCustomer: async (reqParams: OrgsApiCreateCustomerReq, options?: any): Promise<AxiosResponse<OrgsApiCreateCustomerRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createCustomer(reqParams.orgId, reqParams.newCustomer, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create organization member - A login for the member needs to exist before establishing membership. On success, the member id is returned. This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    createMember: async (reqParams: OrgsApiCreateMemberReq, options?: any): Promise<AxiosResponse<OrgsApiCreateMemberRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createMember(reqParams.orgId, reqParams.newMember, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan - Creates a new plan for organization. If the organization is a reseller and not the MO, the reseller must itself have a subscription to a reseller plan. It is verified that the reseller is not trying to create a plan offering more resources than the reseller has available through its reseller plan. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlan: async (reqParams: OrgsApiCreatePlanReq, options?: any): Promise<AxiosResponse<OrgsApiCreatePlanRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createPlan(reqParams.orgId, reqParams.newPlan, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan allowances - Creates new allowances for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer allowances that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlanAllowances: async (reqParams: OrgsApiCreatePlanAllowancesReq, options?: any): Promise<AxiosResponse<OrgsApiCreatePlanAllowancesRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createPlanAllowances(reqParams.orgId, reqParams.planId, reqParams.allowance, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan resources - Creates new resources for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlanResources: async (reqParams: OrgsApiCreatePlanResourcesReq, options?: any): Promise<AxiosResponse<OrgsApiCreatePlanResourcesRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createPlanResources(reqParams.orgId, reqParams.planId, reqParams.resource, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan selections - Creates new selections for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer selections that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlanSelections: async (reqParams: OrgsApiCreatePlanSelectionsReq, options?: any): Promise<AxiosResponse<OrgsApiCreatePlanSelectionsRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createPlanSelections(reqParams.orgId, reqParams.planId, reqParams.selection, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create tag - Creates a new tag for the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createTag: async (reqParams: OrgsApiCreateTagReq, options?: any): Promise<AxiosResponse<OrgsApiCreateTagRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).createTag(reqParams.orgId, reqParams.newTag, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete CloudFlare API key, org level - Delete a CloudFlare API key for an org. */
    deleteCloudflareApiKey: async (reqParams: OrgsApiDeleteCloudflareApiKeyReq, options?: any): Promise<AxiosResponse<OrgsApiDeleteCloudflareApiKeyRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deleteCloudflareApiKey(reqParams.orgId, reqParams.cloudflareKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete organization member - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    deleteMember: async (reqParams: OrgsApiDeleteMemberReq, options?: any): Promise<AxiosResponse<OrgsApiDeleteMemberRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deleteMember(reqParams.orgId, reqParams.memberId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete organization - Soft or force deletes the organization and its resources. All resources under the organization (websites, customers in case of a reseller) will be deleted too. If the organization is soft-deleted (or marked as deleted), its data is not removed.  For removing all data and metadata, pass the `force=true` query parameter. This can only be done by a privileged MO member. In this case, all data is wiped, so use carefully. If the `force` parameter is set, session holder must be an MO Owner, SuperAdmin, or Support member, otherwise it suffices for them to be such a member in a parent org. */
    deleteOrg: async (reqParams: OrgsApiDeleteOrgReq, options?: any): Promise<AxiosResponse<OrgsApiDeleteOrgRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deleteOrg(reqParams.orgId, reqParams.force, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Remove org avatar - Deletes the org\'s avatar. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteOrgAvatar: async (reqParams: OrgsApiDeleteOrgAvatarReq, options?: any): Promise<AxiosResponse<OrgsApiDeleteOrgAvatarRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deleteOrgAvatar(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete organization owner - This operation can only be done by a logged in superadmin of the organization\'s parent organization (TODO it\'s unclear as of this writing whether organization owners may delete themselves). The previous owner is demoted to the \"SupperAdmin\" role. */
    deleteOwner: async (reqParams: OrgsApiDeleteOwnerReq, options?: any): Promise<AxiosResponse<OrgsApiDeleteOwnerRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deleteOwner(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete plan - Deletes a plan if no subscriptions exist to it. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deletePlan: async (reqParams: OrgsApiDeletePlanReq, options?: any): Promise<AxiosResponse<OrgsApiDeletePlanRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deletePlan(reqParams.orgId, reqParams.planId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete plan allowance - Deletes the plan allowance. Session holder must have admin privileges in this org or a parent. That is, to have Owner or SuperAdmin roles in the current org or a parent, or to have the Support role in a parent. org. */
    deletePlanAllowance: async (reqParams: OrgsApiDeletePlanAllowanceReq, options?: any): Promise<AxiosResponse<OrgsApiDeletePlanAllowanceRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deletePlanAllowance(reqParams.orgId, reqParams.planId, reqParams.name, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website MySQL database user access hosts - Removes from the given user access hosts to website\'s MySQL database. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteMySQLUserAccessHosts: async (reqParams: OrgsApiDeleteWebsiteMySQLUserAccessHostsReq, options?: any): Promise<AxiosResponse<OrgsApiDeleteWebsiteMySQLUserAccessHostsRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).deleteWebsiteMySQLUserAccessHosts(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.mySQLUserAccessHosts, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get affected domains for a CloudFlare key - Get affected domains for an organisation\'s CloudFlare key */
    getCloudFlareKeyAffectedDomains: async (reqParams: OrgsApiGetCloudFlareKeyAffectedDomainsReq, options?: any): Promise<AxiosResponse<OrgsApiGetCloudFlareKeyAffectedDomainsRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getCloudFlareKeyAffectedDomains(reqParams.orgId, reqParams.cloudflareKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get CloudFlare API keys, org level - Returns the CloudFlare API keys for an org (obfuscated for security). */
    getCloudflareApiKeys: async (reqParams: OrgsApiGetCloudflareApiKeysReq, options?: any): Promise<AxiosResponse<OrgsApiGetCloudflareApiKeysRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getCloudflareApiKeys(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get customers added over a given time period - Returns the number of customers added over a given time period. Includes only direct customers, not customers of customers. */
    getCustomersAdded: async (reqParams: OrgsApiGetCustomersAddedReq, options?: any): Promise<AxiosResponse<OrgsApiGetCustomersAddedRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getCustomersAdded(reqParams.orgId, reqParams.timeInterval, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get org emails - Returns all emails belonging to this organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getEmails: async (reqParams: OrgsApiGetEmailsReq, options?: any): Promise<AxiosResponse<OrgsApiGetEmailsRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getEmails(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.search, reqParams.recursive, reqParams.maxDepth, reqParams.status, reqParams.domainId, reqParams.planId, reqParams.subscriptionId, reqParams.includeInternal, reqParams.showDeleted, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization member - Returns details about this organization member. This operation can only be done by the member itself, a logged in superadmin or owner of the organization or its parent organization(s). */
    getMember: async (reqParams: OrgsApiGetMemberReq, options?: any): Promise<AxiosResponse<OrgsApiGetMemberRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getMember(reqParams.orgId, reqParams.memberId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization members - Returns all (both pending and full) members of the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getMembers: async (reqParams: OrgsApiGetMembersReq, options?: any): Promise<AxiosResponse<OrgsApiGetMembersRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getMembers(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.role, reqParams.siteAccess, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization info - Returns basic organization information. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getOrg: async (reqParams: OrgsApiGetOrgReq, options?: any): Promise<AxiosResponse<OrgsApiGetOrgRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getOrg(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get organization\'s activity log. - Returns organization\'s activity log which is a human readable list of events that happened in orchd. Only accessible to Owner, SuperAdmin and Sysadmin. */
    getOrgActivities: async (reqParams: OrgsApiGetOrgActivitiesReq, options?: any): Promise<AxiosResponse<OrgsApiGetOrgActivitiesRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getOrgActivities(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.createdBefore, reqParams.createdAfter, reqParams.activityKinds, reqParams.anyEntityId, reqParams.entityKind, reqParams.search, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get a One-Time-Password link for the member - Returns a short lived one time password link for direct log-ins via the users realm. Session holder must be an `Owner`, `SuperAdmin` or `Sysadmin` in the org or the MO. */
    getOrgMemberLogin: async (reqParams: OrgsApiGetOrgMemberLoginReq, options?: any): Promise<AxiosResponse<OrgsApiGetOrgMemberLoginRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getOrgMemberLogin(reqParams.orgId, reqParams.memberId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get plan - Returns info on a single plan offered by this organization. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
    getPlan: async (reqParams: OrgsApiGetPlanReq, options?: any): Promise<AxiosResponse<OrgsApiGetPlanRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getPlan(reqParams.orgId, reqParams.planId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get plans - Returns the organization\'s plans, optionally filtered by query parameters. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
    getPlans: async (reqParams: OrgsApiGetPlansReq, options?: any): Promise<AxiosResponse<OrgsApiGetPlansRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getPlans(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get tags - Returns all tags belonging to the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getTags: async (reqParams: OrgsApiGetTagsReq, options?: any): Promise<AxiosResponse<OrgsApiGetTagsRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getTags(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns the SSL for this website domain - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
    getWebsiteDomainSslCert: async (reqParams: OrgsApiGetWebsiteDomainSslCertReq, options?: any): Promise<AxiosResponse<OrgsApiGetWebsiteDomainSslCertRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getWebsiteDomainSslCert(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns the SSL for this website domain with the mail.prefix - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
    getWebsiteMailDomainSslCert: async (reqParams: OrgsApiGetWebsiteMailDomainSslCertReq, options?: any): Promise<AxiosResponse<OrgsApiGetWebsiteMailDomainSslCertRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getWebsiteMailDomainSslCert(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get websites added over a given time period - Returns the number of websites added each day over a given timeframe. */
    getWebsitesAdded: async (reqParams: OrgsApiGetWebsitesAddedReq, options?: any): Promise<AxiosResponse<OrgsApiGetWebsitesAddedRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).getWebsitesAdded(reqParams.orgId, reqParams.timeInterval, reqParams.recursion, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set org avatar - Sets the org\'s avatar, overwriting any previous one if one exists. The max allowed size is 200 KiB. The image format may be jpeg, png, and ico. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    setOrgAvatar: async (reqParams: OrgsApiSetOrgAvatarReq, options?: any): Promise<AxiosResponse<OrgsApiSetOrgAvatarRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).setOrgAvatar(reqParams.orgId, reqParams.avatar, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set \"force ssl\" status for domain mapping -  */
    setWebsiteDomainForceSsl: async (reqParams: OrgsApiSetWebsiteDomainForceSslReq, options?: any): Promise<AxiosResponse<OrgsApiSetWebsiteDomainForceSslRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).setWebsiteDomainForceSsl(reqParams.domainId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update CloudFlare API key - Update a CloudFlare API key for an org. */
    updateCloudflareApiKey: async (reqParams: OrgsApiUpdateCloudflareApiKeyReq, options?: any): Promise<AxiosResponse<OrgsApiUpdateCloudflareApiKeyRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updateCloudflareApiKey(reqParams.orgId, reqParams.cloudflareKey, reqParams.updateCloudFlareApiKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Overwrite organization member settings - Updates member information, such as their roles, site access permissions, and notification settings. This operation can only be done by the logged in superadmin or owner of the organization or its parent organization(s). This operation overwrites existing settings, so all existing values that are meant to be kept should be included in the response body. */
    updateMember: async (reqParams: OrgsApiUpdateMemberReq, options?: any): Promise<AxiosResponse<OrgsApiUpdateMemberRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updateMember(reqParams.orgId, reqParams.memberId, reqParams.updateMember, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update organization - Updates the given org\'s name. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updateOrg: async (reqParams: OrgsApiUpdateOrgReq, options?: any): Promise<AxiosResponse<OrgsApiUpdateOrgRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updateOrg(reqParams.orgId, reqParams.orgUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update organization owner - The new owner must already be a member in the organization before establishing ownership. This operation can only be done by a logged in superadmin of the organization\'s parent organization, or the owner of the organization. In both cases, the previous owner (if they existed) is demoted to SuperAdmin after this operation. */
    updateOwner: async (reqParams: OrgsApiUpdateOwnerReq, options?: any): Promise<AxiosResponse<OrgsApiUpdateOwnerRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updateOwner(reqParams.orgId, reqParams.orgOwnerUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan name - Updates a plan\'s name of plan type Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlan: async (reqParams: OrgsApiUpdatePlanReq, options?: any): Promise<AxiosResponse<OrgsApiUpdatePlanRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updatePlan(reqParams.orgId, reqParams.planId, reqParams.updatePlan, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan allowance - Updates the plan allowance. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of allowance than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlanAllowance: async (reqParams: OrgsApiUpdatePlanAllowanceReq, options?: any): Promise<AxiosResponse<OrgsApiUpdatePlanAllowanceRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updatePlanAllowance(reqParams.orgId, reqParams.planId, reqParams.name, reqParams.allowance, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan resource - Updates the plan resource. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlanResource: async (reqParams: OrgsApiUpdatePlanResourceReq, options?: any): Promise<AxiosResponse<OrgsApiUpdatePlanResourceRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updatePlanResource(reqParams.orgId, reqParams.planId, reqParams.name, reqParams.resource, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan selection - Updates the plan selection. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of selection than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlanSelection: async (reqParams: OrgsApiUpdatePlanSelectionReq, options?: any): Promise<AxiosResponse<OrgsApiUpdatePlanSelectionRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).updatePlanSelection(reqParams.orgId, reqParams.planId, reqParams.name, reqParams.selection, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload custom ssl certificate, key and optional fullchain for a given website - Endpoint for uploading custom SSL certificate for a given website. Verifies the cert key and maps to relevant domains that the certificate can be applied to. Returns error if no domain match is found. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    uploadWebsiteDomainSslCert: async (reqParams: OrgsApiUploadWebsiteDomainSslCertReq, options?: any): Promise<AxiosResponse<OrgsApiUploadWebsiteDomainSslCertRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).uploadWebsiteDomainSslCert(reqParams.domainId, reqParams.sslCert, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload SSL for mail.customerdomain. -  */
    uploadWebsiteMailDomainSslCert: async (reqParams: OrgsApiUploadWebsiteMailDomainSslCertReq, options?: any): Promise<AxiosResponse<OrgsApiUploadWebsiteMailDomainSslCertRes>> => {
        const localVarAxiosArgs = await OrgsApiAxiosParamCreator(configuration).uploadWebsiteMailDomainSslCert(reqParams.domainId, reqParams.sslCert, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface OrgsApiReq {
    createAccessToken: OrgsApiCreateAccessTokenReq;
    createCloudflareApiKey: OrgsApiCreateCloudflareApiKeyReq;
    createCustomer: OrgsApiCreateCustomerReq;
    createMember: OrgsApiCreateMemberReq;
    createPlan: OrgsApiCreatePlanReq;
    createPlanAllowances: OrgsApiCreatePlanAllowancesReq;
    createPlanResources: OrgsApiCreatePlanResourcesReq;
    createPlanSelections: OrgsApiCreatePlanSelectionsReq;
    createTag: OrgsApiCreateTagReq;
    deleteCloudflareApiKey: OrgsApiDeleteCloudflareApiKeyReq;
    deleteMember: OrgsApiDeleteMemberReq;
    deleteOrg: OrgsApiDeleteOrgReq;
    deleteOrgAvatar: OrgsApiDeleteOrgAvatarReq;
    deleteOwner: OrgsApiDeleteOwnerReq;
    deletePlan: OrgsApiDeletePlanReq;
    deletePlanAllowance: OrgsApiDeletePlanAllowanceReq;
    deleteWebsiteMySQLUserAccessHosts: OrgsApiDeleteWebsiteMySQLUserAccessHostsReq;
    getCloudFlareKeyAffectedDomains: OrgsApiGetCloudFlareKeyAffectedDomainsReq;
    getCloudflareApiKeys: OrgsApiGetCloudflareApiKeysReq;
    getCustomersAdded: OrgsApiGetCustomersAddedReq;
    getEmails: OrgsApiGetEmailsReq;
    getMember: OrgsApiGetMemberReq;
    getMembers: OrgsApiGetMembersReq;
    getOrg: OrgsApiGetOrgReq;
    getOrgActivities: OrgsApiGetOrgActivitiesReq;
    getOrgMemberLogin: OrgsApiGetOrgMemberLoginReq;
    getPlan: OrgsApiGetPlanReq;
    getPlans: OrgsApiGetPlansReq;
    getTags: OrgsApiGetTagsReq;
    getWebsiteDomainSslCert: OrgsApiGetWebsiteDomainSslCertReq;
    getWebsiteMailDomainSslCert: OrgsApiGetWebsiteMailDomainSslCertReq;
    getWebsitesAdded: OrgsApiGetWebsitesAddedReq;
    setOrgAvatar: OrgsApiSetOrgAvatarReq;
    setWebsiteDomainForceSsl: OrgsApiSetWebsiteDomainForceSslReq;
    updateCloudflareApiKey: OrgsApiUpdateCloudflareApiKeyReq;
    updateMember: OrgsApiUpdateMemberReq;
    updateOrg: OrgsApiUpdateOrgReq;
    updateOwner: OrgsApiUpdateOwnerReq;
    updatePlan: OrgsApiUpdatePlanReq;
    updatePlanAllowance: OrgsApiUpdatePlanAllowanceReq;
    updatePlanResource: OrgsApiUpdatePlanResourceReq;
    updatePlanSelection: OrgsApiUpdatePlanSelectionReq;
    uploadWebsiteDomainSslCert: OrgsApiUploadWebsiteDomainSslCertReq;
    uploadWebsiteMailDomainSslCert: OrgsApiUploadWebsiteMailDomainSslCertReq;
}

export interface OrgsApiRes {
    createAccessToken: OrgsApiCreateAccessTokenRes;
    createCloudflareApiKey: OrgsApiCreateCloudflareApiKeyRes;
    createCustomer: OrgsApiCreateCustomerRes;
    createMember: OrgsApiCreateMemberRes;
    createPlan: OrgsApiCreatePlanRes;
    createPlanAllowances: OrgsApiCreatePlanAllowancesRes;
    createPlanResources: OrgsApiCreatePlanResourcesRes;
    createPlanSelections: OrgsApiCreatePlanSelectionsRes;
    createTag: OrgsApiCreateTagRes;
    deleteCloudflareApiKey: OrgsApiDeleteCloudflareApiKeyRes;
    deleteMember: OrgsApiDeleteMemberRes;
    deleteOrg: OrgsApiDeleteOrgRes;
    deleteOrgAvatar: OrgsApiDeleteOrgAvatarRes;
    deleteOwner: OrgsApiDeleteOwnerRes;
    deletePlan: OrgsApiDeletePlanRes;
    deletePlanAllowance: OrgsApiDeletePlanAllowanceRes;
    deleteWebsiteMySQLUserAccessHosts: OrgsApiDeleteWebsiteMySQLUserAccessHostsRes;
    getCloudFlareKeyAffectedDomains: OrgsApiGetCloudFlareKeyAffectedDomainsRes;
    getCloudflareApiKeys: OrgsApiGetCloudflareApiKeysRes;
    getCustomersAdded: OrgsApiGetCustomersAddedRes;
    getEmails: OrgsApiGetEmailsRes;
    getMember: OrgsApiGetMemberRes;
    getMembers: OrgsApiGetMembersRes;
    getOrg: OrgsApiGetOrgRes;
    getOrgActivities: OrgsApiGetOrgActivitiesRes;
    getOrgMemberLogin: OrgsApiGetOrgMemberLoginRes;
    getPlan: OrgsApiGetPlanRes;
    getPlans: OrgsApiGetPlansRes;
    getTags: OrgsApiGetTagsRes;
    getWebsiteDomainSslCert: OrgsApiGetWebsiteDomainSslCertRes;
    getWebsiteMailDomainSslCert: OrgsApiGetWebsiteMailDomainSslCertRes;
    getWebsitesAdded: OrgsApiGetWebsitesAddedRes;
    setOrgAvatar: OrgsApiSetOrgAvatarRes;
    setWebsiteDomainForceSsl: OrgsApiSetWebsiteDomainForceSslRes;
    updateCloudflareApiKey: OrgsApiUpdateCloudflareApiKeyRes;
    updateMember: OrgsApiUpdateMemberRes;
    updateOrg: OrgsApiUpdateOrgRes;
    updateOwner: OrgsApiUpdateOwnerRes;
    updatePlan: OrgsApiUpdatePlanRes;
    updatePlanAllowance: OrgsApiUpdatePlanAllowanceRes;
    updatePlanResource: OrgsApiUpdatePlanResourceRes;
    updatePlanSelection: OrgsApiUpdatePlanSelectionRes;
    uploadWebsiteDomainSslCert: OrgsApiUploadWebsiteDomainSslCertRes;
    uploadWebsiteMailDomainSslCert: OrgsApiUploadWebsiteMailDomainSslCertRes;
}


/**
 * OwnerApi - axios parameter creator
 * @export
 */
export const OwnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Delete organization owner - This operation can only be done by a logged in superadmin of the organization\'s parent organization (TODO it\'s unclear as of this writing whether organization owners may delete themselves). The previous owner is demoted to the \"SupperAdmin\" role. */
        deleteOwner: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/owner`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update organization owner - The new owner must already be a member in the organization before establishing ownership. This operation can only be done by a logged in superadmin of the organization\'s parent organization, or the owner of the organization. In both cases, the previous owner (if they existed) is demoted to SuperAdmin after this operation. */
        updateOwner: async (orgId: string, orgOwnerUpdate: OrgOwnerUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/owner`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orgOwnerUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orgOwnerUpdate !== undefined ? orgOwnerUpdate : {}) : (orgOwnerUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for deleteOwner operation in OwnerApi. */
export type OwnerApiDeleteOwnerReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for deleteOwner operation in OwnerApi. */
export type OwnerApiDeleteOwnerRes = void;


/** Request parameters for updateOwner operation in OwnerApi. */
export type OwnerApiUpdateOwnerReq = {
    /** The id of the organization. */
    orgId: string
    /** Membership id of the to-be owner */
    orgOwnerUpdate: OrgOwnerUpdate
};

/** Response parameters for updateOwner operation in OwnerApi. */
export type OwnerApiUpdateOwnerRes = void;

/**
 * OwnerApi - service client interface
 */
export const OwnerApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Delete organization owner - This operation can only be done by a logged in superadmin of the organization\'s parent organization (TODO it\'s unclear as of this writing whether organization owners may delete themselves). The previous owner is demoted to the \"SupperAdmin\" role. */
    deleteOwner: async (reqParams: OwnerApiDeleteOwnerReq, options?: any): Promise<AxiosResponse<OwnerApiDeleteOwnerRes>> => {
        const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).deleteOwner(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update organization owner - The new owner must already be a member in the organization before establishing ownership. This operation can only be done by a logged in superadmin of the organization\'s parent organization, or the owner of the organization. In both cases, the previous owner (if they existed) is demoted to SuperAdmin after this operation. */
    updateOwner: async (reqParams: OwnerApiUpdateOwnerReq, options?: any): Promise<AxiosResponse<OwnerApiUpdateOwnerRes>> => {
        const localVarAxiosArgs = await OwnerApiAxiosParamCreator(configuration).updateOwner(reqParams.orgId, reqParams.orgOwnerUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface OwnerApiReq {
    deleteOwner: OwnerApiDeleteOwnerReq;
    updateOwner: OwnerApiUpdateOwnerReq;
}

export interface OwnerApiRes {
    deleteOwner: OwnerApiDeleteOwnerRes;
    updateOwner: OwnerApiUpdateOwnerRes;
}


/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create plan - Creates a new plan for organization. If the organization is a reseller and not the MO, the reseller must itself have a subscription to a reseller plan. It is verified that the reseller is not trying to create a plan offering more resources than the reseller has available through its reseller plan. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlan: async (orgId: string, newPlan: NewPlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPlan !== undefined ? newPlan : {}) : (newPlan || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan allowances - Creates new allowances for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer allowances that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlanAllowances: async (orgId: string, planId: number, allowance: Array<Allowance>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/allowances`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof allowance !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(allowance !== undefined ? allowance : {}) : (allowance || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan resources - Creates new resources for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlanResources: async (orgId: string, planId: number, resource: Array<Resource>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/resources`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resource !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resource !== undefined ? resource : {}) : (resource || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create plan selections - Creates new selections for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer selections that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createPlanSelections: async (orgId: string, planId: number, selection: Array<Selection>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/selections`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof selection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(selection !== undefined ? selection : {}) : (selection || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete plan - Deletes a plan if no subscriptions exist to it. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deletePlan: async (orgId: string, planId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete plan allowance - Deletes the plan allowance. Session holder must have admin privileges in this org or a parent. That is, to have Owner or SuperAdmin roles in the current org or a parent, or to have the Support role in a parent. org. */
        deletePlanAllowance: async (orgId: string, planId: number, name: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/allowances/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get plan - Returns info on a single plan offered by this organization. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
        getPlan: async (orgId: string, planId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get plans - Returns the organization\'s plans, optionally filtered by query parameters. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
        getPlans: async (orgId: string, offset?: number, limit?: number, sortBy?: 'name', sortOrder?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan name - Updates a plan\'s name of plan type Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlan: async (orgId: string, planId: number, updatePlan: UpdatePlan, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updatePlan !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updatePlan !== undefined ? updatePlan : {}) : (updatePlan || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan allowance - Updates the plan allowance. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of allowance than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlanAllowance: async (orgId: string, planId: number, name: string, allowance: Allowance, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/allowances/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof allowance !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(allowance !== undefined ? allowance : {}) : (allowance || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan resource - Updates the plan resource. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlanResource: async (orgId: string, planId: number, name: string, resource: Resource, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/resources/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resource !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resource !== undefined ? resource : {}) : (resource || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update plan selection - Updates the plan selection. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of selection than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updatePlanSelection: async (orgId: string, planId: number, name: string, selection: Selection, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/plans/{plan_id}/selections/{name}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"plan_id"}}`, encodeURIComponent(String(planId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof selection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(selection !== undefined ? selection : {}) : (selection || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createPlan operation in PlansApi. */
export type PlansApiCreatePlanReq = {
    /** The id of the organization. */
    orgId: string
    /** New plan details */
    newPlan: NewPlan
};

/** Response parameters for createPlan operation in PlansApi. */
export type PlansApiCreatePlanRes = NewResourceId;


/** Request parameters for createPlanAllowances operation in PlansApi. */
export type PlansApiCreatePlanAllowancesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    allowance: Array<Allowance>
};

/** Response parameters for createPlanAllowances operation in PlansApi. */
export type PlansApiCreatePlanAllowancesRes = void;


/** Request parameters for createPlanResources operation in PlansApi. */
export type PlansApiCreatePlanResourcesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    resource: Array<Resource>
};

/** Response parameters for createPlanResources operation in PlansApi. */
export type PlansApiCreatePlanResourcesRes = void;


/** Request parameters for createPlanSelections operation in PlansApi. */
export type PlansApiCreatePlanSelectionsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    selection: Array<Selection>
};

/** Response parameters for createPlanSelections operation in PlansApi. */
export type PlansApiCreatePlanSelectionsRes = void;


/** Request parameters for deletePlan operation in PlansApi. */
export type PlansApiDeletePlanReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
};

/** Response parameters for deletePlan operation in PlansApi. */
export type PlansApiDeletePlanRes = void;


/** Request parameters for deletePlanAllowance operation in PlansApi. */
export type PlansApiDeletePlanAllowanceReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the allowance. */
    name: string
};

/** Response parameters for deletePlanAllowance operation in PlansApi. */
export type PlansApiDeletePlanAllowanceRes = void;


/** Request parameters for getPlan operation in PlansApi. */
export type PlansApiGetPlanReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
};

/** Response parameters for getPlan operation in PlansApi. */
export type PlansApiGetPlanRes = Plan;


/** Request parameters for getPlans operation in PlansApi. */
export type PlansApiGetPlansReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'name'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
};

/** Response parameters for getPlans operation in PlansApi. */
export type PlansApiGetPlansRes = PlansListing;


/** Request parameters for updatePlan operation in PlansApi. */
export type PlansApiUpdatePlanReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    updatePlan: UpdatePlan
};

/** Response parameters for updatePlan operation in PlansApi. */
export type PlansApiUpdatePlanRes = void;


/** Request parameters for updatePlanAllowance operation in PlansApi. */
export type PlansApiUpdatePlanAllowanceReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the allowance. */
    name: string
    allowance: Allowance
};

/** Response parameters for updatePlanAllowance operation in PlansApi. */
export type PlansApiUpdatePlanAllowanceRes = void;


/** Request parameters for updatePlanResource operation in PlansApi. */
export type PlansApiUpdatePlanResourceReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the resource. */
    name: string
    resource: Resource
};

/** Response parameters for updatePlanResource operation in PlansApi. */
export type PlansApiUpdatePlanResourceRes = void;


/** Request parameters for updatePlanSelection operation in PlansApi. */
export type PlansApiUpdatePlanSelectionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the plan. */
    planId: number
    /** The name of the selection. */
    name: string
    selection: Selection
};

/** Response parameters for updatePlanSelection operation in PlansApi. */
export type PlansApiUpdatePlanSelectionRes = void;

/**
 * PlansApi - service client interface
 */
export const PlansApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create plan - Creates a new plan for organization. If the organization is a reseller and not the MO, the reseller must itself have a subscription to a reseller plan. It is verified that the reseller is not trying to create a plan offering more resources than the reseller has available through its reseller plan. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlan: async (reqParams: PlansApiCreatePlanReq, options?: any): Promise<AxiosResponse<PlansApiCreatePlanRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).createPlan(reqParams.orgId, reqParams.newPlan, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan allowances - Creates new allowances for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer allowances that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlanAllowances: async (reqParams: PlansApiCreatePlanAllowancesReq, options?: any): Promise<AxiosResponse<PlansApiCreatePlanAllowancesRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).createPlanAllowances(reqParams.orgId, reqParams.planId, reqParams.allowance, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan resources - Creates new resources for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlanResources: async (reqParams: PlansApiCreatePlanResourcesReq, options?: any): Promise<AxiosResponse<PlansApiCreatePlanResourcesRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).createPlanResources(reqParams.orgId, reqParams.planId, reqParams.resource, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create plan selections - Creates new selections for plan. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer selections that the reseller doesn\'t have available in their reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createPlanSelections: async (reqParams: PlansApiCreatePlanSelectionsReq, options?: any): Promise<AxiosResponse<PlansApiCreatePlanSelectionsRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).createPlanSelections(reqParams.orgId, reqParams.planId, reqParams.selection, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete plan - Deletes a plan if no subscriptions exist to it. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deletePlan: async (reqParams: PlansApiDeletePlanReq, options?: any): Promise<AxiosResponse<PlansApiDeletePlanRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).deletePlan(reqParams.orgId, reqParams.planId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete plan allowance - Deletes the plan allowance. Session holder must have admin privileges in this org or a parent. That is, to have Owner or SuperAdmin roles in the current org or a parent, or to have the Support role in a parent. org. */
    deletePlanAllowance: async (reqParams: PlansApiDeletePlanAllowanceReq, options?: any): Promise<AxiosResponse<PlansApiDeletePlanAllowanceRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).deletePlanAllowance(reqParams.orgId, reqParams.planId, reqParams.name, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get plan - Returns info on a single plan offered by this organization. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
    getPlan: async (reqParams: PlansApiGetPlanReq, options?: any): Promise<AxiosResponse<PlansApiGetPlanRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).getPlan(reqParams.orgId, reqParams.planId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get plans - Returns the organization\'s plans, optionally filtered by query parameters. Note that the endpoint does not require authentication as anyone should be able to view an organization\'s plans on offer so that the viewer may decide to subscribe to a plan. */
    getPlans: async (reqParams: PlansApiGetPlansReq, options?: any): Promise<AxiosResponse<PlansApiGetPlansRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).getPlans(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan name - Updates a plan\'s name of plan type Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlan: async (reqParams: PlansApiUpdatePlanReq, options?: any): Promise<AxiosResponse<PlansApiUpdatePlanRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).updatePlan(reqParams.orgId, reqParams.planId, reqParams.updatePlan, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan allowance - Updates the plan allowance. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of allowance than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlanAllowance: async (reqParams: PlansApiUpdatePlanAllowanceReq, options?: any): Promise<AxiosResponse<PlansApiUpdatePlanAllowanceRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).updatePlanAllowance(reqParams.orgId, reqParams.planId, reqParams.name, reqParams.allowance, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan resource - Updates the plan resource. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of resource than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlanResource: async (reqParams: PlansApiUpdatePlanResourceReq, options?: any): Promise<AxiosResponse<PlansApiUpdatePlanResourceRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).updatePlanResource(reqParams.orgId, reqParams.planId, reqParams.name, reqParams.resource, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update plan selection - Updates the plan selection. If the organization is a reseller and not the MO, it is verified that the reseller is not trying to update a plan to offer more of selection than the reseller has available through its reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updatePlanSelection: async (reqParams: PlansApiUpdatePlanSelectionReq, options?: any): Promise<AxiosResponse<PlansApiUpdatePlanSelectionRes>> => {
        const localVarAxiosArgs = await PlansApiAxiosParamCreator(configuration).updatePlanSelection(reqParams.orgId, reqParams.planId, reqParams.name, reqParams.selection, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface PlansApiReq {
    createPlan: PlansApiCreatePlanReq;
    createPlanAllowances: PlansApiCreatePlanAllowancesReq;
    createPlanResources: PlansApiCreatePlanResourcesReq;
    createPlanSelections: PlansApiCreatePlanSelectionsReq;
    deletePlan: PlansApiDeletePlanReq;
    deletePlanAllowance: PlansApiDeletePlanAllowanceReq;
    getPlan: PlansApiGetPlanReq;
    getPlans: PlansApiGetPlansReq;
    updatePlan: PlansApiUpdatePlanReq;
    updatePlanAllowance: PlansApiUpdatePlanAllowanceReq;
    updatePlanResource: PlansApiUpdatePlanResourceReq;
    updatePlanSelection: PlansApiUpdatePlanSelectionReq;
}

export interface PlansApiRes {
    createPlan: PlansApiCreatePlanRes;
    createPlanAllowances: PlansApiCreatePlanAllowancesRes;
    createPlanResources: PlansApiCreatePlanResourcesRes;
    createPlanSelections: PlansApiCreatePlanSelectionsRes;
    deletePlan: PlansApiDeletePlanRes;
    deletePlanAllowance: PlansApiDeletePlanAllowanceRes;
    getPlan: PlansApiGetPlanRes;
    getPlans: PlansApiGetPlansRes;
    updatePlan: PlansApiUpdatePlanRes;
    updatePlanAllowance: PlansApiUpdatePlanAllowanceRes;
    updatePlanResource: PlansApiUpdatePlanResourceRes;
    updatePlanSelection: PlansApiUpdatePlanSelectionRes;
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Get blocked ips - Returns a list of all currently active blocked ips. */
        getLoginPolicyBlockedIps: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/orchd/login-policy/blocked-ips`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get blocked logins - Returns a list of all currently active blocked logins and ips. */
        getLoginPolicyBlockedLogins: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/orchd/login-policy/blocked-logins`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for getLoginPolicyBlockedIps operation in ReportsApi. */
export type ReportsApiGetLoginPolicyBlockedIpsReq = {
};

/** Response parameters for getLoginPolicyBlockedIps operation in ReportsApi. */
export type ReportsApiGetLoginPolicyBlockedIpsRes = { [key: string]: Blocked; };


/** Request parameters for getLoginPolicyBlockedLogins operation in ReportsApi. */
export type ReportsApiGetLoginPolicyBlockedLoginsReq = {
};

/** Response parameters for getLoginPolicyBlockedLogins operation in ReportsApi. */
export type ReportsApiGetLoginPolicyBlockedLoginsRes = { [key: string]: Blocked; };

/**
 * ReportsApi - service client interface
 */
export const ReportsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Get blocked ips - Returns a list of all currently active blocked ips. */
    getLoginPolicyBlockedIps: async (reqParams: ReportsApiGetLoginPolicyBlockedIpsReq, options?: any): Promise<AxiosResponse<ReportsApiGetLoginPolicyBlockedIpsRes>> => {
        const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).getLoginPolicyBlockedIps(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get blocked logins - Returns a list of all currently active blocked logins and ips. */
    getLoginPolicyBlockedLogins: async (reqParams: ReportsApiGetLoginPolicyBlockedLoginsReq, options?: any): Promise<AxiosResponse<ReportsApiGetLoginPolicyBlockedLoginsRes>> => {
        const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).getLoginPolicyBlockedLogins(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface ReportsApiReq {
    getLoginPolicyBlockedIps: ReportsApiGetLoginPolicyBlockedIpsReq;
    getLoginPolicyBlockedLogins: ReportsApiGetLoginPolicyBlockedLoginsReq;
}

export interface ReportsApiRes {
    getLoginPolicyBlockedIps: ReportsApiGetLoginPolicyBlockedIpsRes;
    getLoginPolicyBlockedLogins: ReportsApiGetLoginPolicyBlockedLoginsRes;
}


/**
 * ServersApi - axios parameter creator
 * @export
 */
export const ServersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Add to the IP whitelist -  */
        addSpamIpWhitelist: async (serverId: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/ip_whitelist`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Configure a server - Configures a server with roles to enable or disable, a friendly name to give (for identification purposes), and/or to assign server to a group. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        configureServer: async (serverId: string, serverConf: ServerConf, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof serverConf !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serverConf !== undefined ? serverConf : {}) : (serverConf || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a domain which is mapped to a server - Maps a hostname to a server by creating a special kind of website with kind `ServerHostname`.  This can be used for POP/IMAP/SMTP as well as HTTP. LetsEncrypt certificates will be issued if the DNS resolves.  Only one server hostname website will be created, additional domains will be added as aliases. */
        createServerDomain: async (serverId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/domains`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Creates a new server group - Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        createServerGroup: async (newServerGroup: NewServerGroup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newServerGroup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newServerGroup !== undefined ? newServerGroup : {}) : (newServerGroup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create server network interface secondary IP - Creates a new secondary IP address for the server\'s network interface. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        createServerNetworkInterfaceIp: async (serverId: string, _interface: string, newServerIp: NewServerIp, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/interfaces/{interface}/ips`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"interface"}}`, encodeURIComponent(String(_interface)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newServerIp !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newServerIp !== undefined ? newServerIp : {}) : (newServerIp || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a slave node - This endpoint is called by the slave server installation script and not by the API. Only included for completeness. */
        createSlave: async (key: string, slaveRegistration: SlaveRegistration, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/slaves`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof slaveRegistration !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(slaveRegistration !== undefined ? slaveRegistration : {}) : (slaveRegistration || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete from the IP whitelist -  */
        deleteFromSpamIpWhitelist: async (serverId: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/ip_whitelist`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a mapped server domain - Deletes a server domain mapping and the domain itself.  Does not delete associated SSLs. */
        deleteServerDomain: async (serverId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/domains/{domain_id}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete server from group - Deletes a server from the server group it is in, if any. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        deleteServerFromGroup: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/group`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes an existing server group - Does not delete servers in the group, but instead simply unlinks those servers from this group. These servers, if any, will end up not being in any group after this call succeeds. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        deleteServerGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete server network interface secondary IP - Deletes a secondary IP address from the server\'s network interface. Fails if the IP address is the primary address of the server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        deleteServerNetworkInterfaceIp: async (serverId: string, _interface: string, ip: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/interfaces/{interface}/ips/{ip}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"interface"}}`, encodeURIComponent(String(_interface)))
                .replace(`{${"ip"}}`, encodeURIComponent(String(ip)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes/unsets the primary IPv6 address for a server. -  */
        deleteServerPrimaryIpv6: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/primary-ipv6`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a single override setting - Delete a single service setting value */
        deleteServiceSetting: async (serverId: string, settingKind: SettingKind, settingKey: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/settings/{setting_kind}/{setting_key}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)))
                .replace(`{${"setting_key"}}`, encodeURIComponent(String(settingKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a (slave) server - Removes a given server from the Enhance cluster. The server to be removed may only be a slave server as the master server cannot be removed (the error code `invalid_argument` is returned in such a case). Moreover, the server cannot be deleted if it has any data (such as running/suspended website, emails, etc) on it. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        deleteSlave: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Enable FS quota limits on the server -  */
        enableFsQuotaLimits: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/fs-quota-limits`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the screenshot config of the running appcd - Returns the screenshot config of the running appcd instance on this server. */
        getAppcdScreenshotConfig: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/appcd/screenshot/config`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the version of the running appcd - Returns the version of the running appcd instance on this server. */
        getAppcdVersion: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/appcd/version`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Reflect back the IP of the API consumer -  */
        getClientIp: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client_ip`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the MySQL kind for a given server. -  */
        getDatabaseRoleMysqlKind: async (serverId: string, mysqlKind?: MysqlKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/database-role`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (mysqlKind !== undefined) {
                localVarQueryParameter['mysqlKind'] = mysqlKind;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get DNS pool IPs -  */
        getDnsPoolIps: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/dns_pool`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the hostname override for the email server (postfix) if set - If unset, this defaults to the system hostname */
        getEmailServerHostnameOverride: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/email/hostname_override`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get whether FS quota was enabled on the server -  */
        getFsQuotaStatus: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/fs-quota-limits`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get status of a running httpd server. - `httpd` exposes some server stats through https://httpd.apache.org/docs/2.4/mod/mod_status.html API. Complete response from `httpd` is returned as a string. */
        getHttpdStatus: async (serverId: string, role: ServerRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/roles/{role}/httpd_status`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get slave installation command - Returns the install script that can be used to register more servers in the Enhance cluster. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getInstallCmd: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/install-cmd`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Download my.cnf for a given server -  */
        getMysqlMyCnf: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/my-cnf`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the status of outbound spam scanning -  */
        getOutboundSpamScanningSettings: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/email/spam/outbound_scanning`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the current and available version of the OWASP rules -  */
        getOwaspRulesVersion: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/owasp`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get slave registration key - Key may be used to install yet more servers in the Enhance cluster. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getRegistrationKey: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/registration-key`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server disk usage - Returns disk usage of all disks on the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerDiskUsage: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/disk-usage`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get php-fpm config for all the websites on a server - Returns a list of php-fpm settings for each website on the server. Settings are queried from the running PHP instance for each website. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerFpmSettings: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/php/fpm`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns all server groups - Each group object has a list of ids of the servers that are part of this group. Note that pagination may not be applied so this endpoint always returns all server groups. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/groups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get domains which are mapped to a server - Returns the server hostname website for this server */
        getServerHostnameWebsite: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/domains`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server info - Returns info about the selected server, if it exists. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerInfo: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server iowait - Returns system iowait of the given server in number of elapsed seconds since power-on. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerIowait: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/iowait`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server system load - Returns one minute system load of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerLoad: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/load`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server memory usage - Returns RAM and swap space usage on the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerMemoryUsage: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/memory-usage`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get mod security config -  */
        getServerModSecurityConfig: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/modsec_conf`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get mod security status for a server -  */
        getServerModSecurityStatus: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/modsec_status`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server network interfaces - Returns the network interfaces of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerNetworkInterfaces: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/interfaces`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server network stats - Returns network stats of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerNetworkStats: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/network-stats`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server role info - Returns information about the given role on the server. This includes generic information about the role as well as each service in the role. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerRole: async (serverId: string, role: ServerRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/roles/{role}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server roles info - Returns all configured roles of the given server. This includes generic information about the role as well as each service in the role. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerRoles: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/roles`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get Server stats - Returns server stats between the optional start and end date. Defaults to last 24 hours. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getServerStats: async (serverId: string, start?: string, end?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/historic-stats`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server status - Returns system (online or offline) status of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerStatus: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/status`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get server uptime in seconds - Returns system iowait of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServerUptime: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/uptime`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get installed servers - Returns a list of all servers in this Enhance cluster (including the master server and all slaves). The result set of servers may optionally be sorted, paginated, as well as grouped by a server\'s group id. If not grouped, the returned items are a flat list of server resource objects, whereas if grouped, the returned items are an object (map) of list of servers mapped to their group ids. Grouping is applied after pagination and sorting, and in the latter case the servers within one group will be sorted. Therefore, if paginated, the last group in items, i.e. the group on the \"border\", may not contain all servers belonging to that group if the given limit was such as would be exceeded if all its servers were included. In such a case, the remaining servers of the group are returned in the next batch. Example: Assume servers server1, server2, server3, server4, server5 and groups group1, group2, where group1 contains server1 and group2 contains server2, server3, and server4 is not in a group. If the request specifies an offset of 0 and a limit of 2, then the returned structure may be as follows: ```json {     items: {         group1_id: [             server1,         ],         group2_id: [             server2,         ],     },     total: 4 } ``` Then, in the next request, if offset is changed to 2, the returned items may be: ```json {     items: {         group2_id: [             server3,         ],         \"ungrouped\": [             server4         ],     },     total: 4 } ``` Containing the rest of group2\'s servers as well as the ungrouped servers. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getServers: async (offset?: number, limit?: number, sortOrder?: 'asc' | 'desc', sortBy?: 'address' | 'mailbox' | 'forwarders' | 'autoresponders', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the value for a particular setting - Returns the value for a particular setting as a JSON object */
        getServiceSetting: async (serverId: string, settingKind: SettingKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/settings/{setting_kind}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the hourly SMTP rate limit -  */
        getSmtpRateLimitHourly: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/smtp_rate_limit_hourly`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the IP whitelist -  */
        getSpamIpWhitelist: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/ip_whitelist`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns a map of upgradable packages. - Map of upgradable system packages is returned along with latest Version if the package update is available. */
        getSystemPackageUpdateInfo: async (serverId: string, systemPackageName?: SystemPackageName, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/packages/update`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (systemPackageName !== undefined) {
                localVarQueryParameter['systemPackageName'] = systemPackageName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get web server - Fetches the current web server kind for this server. */
        getWebserverKind: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/webserver`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get php-fpm config for the specified website - Returns a list of php-fpm settings for specified website on the server. Settings are queried from the running PHP instance for each website. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        getWebsiteFpmSettings: async (serverId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/php/fpm/{website_id}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the hourly website generated email rate limit -  */
        getWebsiteGeneratedRateLimitHourly: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/website_generated_rate_limit_hourly`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Enables the database role on a given ServerUuid -  */
        installDatabaseRole: async (serverId: string, mysqlKind?: MysqlKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/database-role`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (mysqlKind !== undefined) {
                localVarQueryParameter['mysqlKind'] = mysqlKind;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Install server role - Installs a role on the server. The backup role takes additional parameters, but all other roles take no parameters. The block device size is optional and defaults to 100 GiB if not set. In this case, the mount point has to be the absolute path where the backup volume should be mounted. If there is already a valid btrfs backup volume mounted at this path, besides installing the `bkupd` service, this is a noop. In the case of an existing mount point, it is verified that it has at least the block device size bytes available. The block device is an absolute path that may or may not exist. If it exists, the block device size is used to verify if the device has at least the specified space available, if it doesn\'t exist, a loopback device is created with the this size. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        installServerRole: async (serverId: string, role: ServerRole, newBackupRole?: NewBackupRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/roles/{role}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newBackupRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newBackupRole !== undefined ? newBackupRole : {}) : (newBackupRole || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete custom mod_security config and reset to default -  */
        resetServerModSecurityConfig: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/modsec_conf`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Reset the config for the web server to default. - Will reset the config for the running web server to \"known good\" defaults.  Currently only available for LiteSpeed. */
        resetWebServerConfig: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/webserver/config/reset`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Restart MySQL gracefully -  */
        restartMysql: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/database-role/restart`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Save a new my.cnf -  */
        saveMysqlMyCnf: async (serverId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/my-cnf`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the hostname override for the email server (postfix) - If unset, this defaults to the system hostname */
        setEmailServerHostnameOverride: async (serverId: string, emailServerHostnameOverride?: EmailServerHostnameOverride, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/email/hostname_override`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof emailServerHostnameOverride !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(emailServerHostnameOverride !== undefined ? emailServerHostnameOverride : {}) : (emailServerHostnameOverride || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a new LiteSpeed admin password. - Will reset the LiteSpeed or OpenLiteSpeed admin password.  The username is always \"admin\". */
        setLiteSpeedAdminPassword: async (serverId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/webserver/litespeed/password`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the settings for outbound spam scanning -  */
        setOutboundSpamScanningSettings: async (serverId: string, outboundSpamScanningSettings?: OutboundSpamScanningSettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/email/spam/outbound_scanning`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundSpamScanningSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundSpamScanningSettings !== undefined ? outboundSpamScanningSettings : {}) : (outboundSpamScanningSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set server to decommissioned - If a server was decommissioned outside of Enhance, set its status to decommissioned.  This will remove the server from any status checks, will prevent website placement and will enable websites to be moved from it to another server using backups if available. */
        setServerDecommissioned: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/decommissioned`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set mod security config - This config is included in the web server config when mod_security is enabled. */
        setServerModSecurityConfig: async (serverId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/modsec_conf`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set mod security status for a server - If enabled, all websites on this server by default will have mod security enabled, unless explicitly disabled. */
        setServerModSecurityStatus: async (serverId: string, modSecStatus: ModSecStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/modsec_status`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof modSecStatus !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modSecStatus !== undefined ? modSecStatus : {}) : (modSecStatus || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the status of one server. - Set the status of one server by rebooting it for example. */
        setServerStatus: async (serverId: string, setServerStatus: SetServerStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/status`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setServerStatus !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setServerStatus !== undefined ? setServerStatus : {}) : (setServerStatus || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a single service setting - Set or replace a single service level override setting */
        setServiceSetting: async (serverId: string, settingKind: SettingKind, settingKey: string, serviceSettingValue: ServiceSettingValue, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/settings/{setting_kind}/{setting_key}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)))
                .replace(`{${"setting_key"}}`, encodeURIComponent(String(settingKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof serviceSettingValue !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serviceSettingValue !== undefined ? serviceSettingValue : {}) : (serviceSettingValue || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the hourly SMTP rate limit -  */
        setSmtpRateLimitHourly: async (serverId: string, body?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/smtp_rate_limit_hourly`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the web server kind for one server. - Changes the web server kind for this server.  This will rebuild any application containers if required.  It may be a long running operation. */
        setWebserverKind: async (serverId: string, setWebserverKind: SetWebserverKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/webserver`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setWebserverKind !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setWebserverKind !== undefined ? setWebserverKind : {}) : (setWebserverKind || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the hourly SMTP rate limit -  */
        setWebsiteGeneratedRateLimitHourly: async (serverId: string, body?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/spam/website_generated_rate_limit_hourly`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Uninstall a server role - Uninstalls role from server, if role has no websites assigned to it. If the role to be uninstalled is the control panel application role, the request returns an error, since this role may only be disabled but not uninstalled (since it is required to serve the control panel). Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        uninstallServerRole: async (serverId: string, role: ServerRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/roles/{role}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update the screenshot config of the running appcd - Update the screenshot config of the running appcd instance on this server. */
        updateAppcdScreenshotConfig: async (serverId: string, screenshotConfigUpdate: ScreenshotConfigUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/appcd/screenshot/config`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof screenshotConfigUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(screenshotConfigUpdate !== undefined ? screenshotConfigUpdate : {}) : (screenshotConfigUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upgrade OWASP rules - Will update the owasp rules to the latest version.  If the current version is the latest, it will be reinstalled. */
        updateOwaspRules: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/owasp`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates an existing server group\'s name - Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        updateServerGroup: async (groupId: string, serverGroupUpdate: ServerGroupUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/groups/{group_id}`
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof serverGroupUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serverGroupUpdate !== undefined ? serverGroupUpdate : {}) : (serverGroupUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates the primary IP of the server in the database and in-memory metadata. This operation will not affect the IP used for service communication until the next restart of orchd. The new IP will be used for creation of new resources such as websites on this server but existing websites will not have their IP changed. -  */
        updateServerPrimaryIp: async (serverId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/primary-ip`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates or sets the primary ipv6 address of the server.  This endpoint will not change existing websites\' DNS but the new record will be applied to all future zones. -  */
        updateServerPrimaryIpv6: async (serverId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/servers/{server_id}/primary-ipv6`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update server role - Updates role and role state. A role, if activated on a server, may be in a state of enabled or disabled. If enabled, it means that new resources (e.g. websites for the application role) may be installed on the server, but if it\'s disabled, existing resources (e.g. websites) are kept but no new resources may be added. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        updateServerRole: async (serverId: string, role: ServerRole, updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole: UpdateEmailRole | UpdateBackupRole | UpdateDatabaseRole | UpdateApplicationRole | UpdateDnsRole, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/roles/{role}`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole !== undefined ? updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole : {}) : (updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates a system package to its latest version. - Can only update installed package to its latest version. */
        updateSystemPackage: async (serverId: string, systemPackage: SystemPackage, systemPackageName?: SystemPackageName, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/{server_id}/packages/update`
                .replace(`{${"server_id"}}`, encodeURIComponent(String(serverId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (systemPackageName !== undefined) {
                localVarQueryParameter['systemPackageName'] = systemPackageName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof systemPackage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(systemPackage !== undefined ? systemPackage : {}) : (systemPackage || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Validate slave registration key - Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
        validateRegistrationKey: async (key: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/servers/registration-key/validate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for addSpamIpWhitelist operation in ServersApi. */
export type ServersApiAddSpamIpWhitelistReq = {
    /** The UUID of the server */
    serverId: string
    body?: string
};

/** Response parameters for addSpamIpWhitelist operation in ServersApi. */
export type ServersApiAddSpamIpWhitelistRes = void;


/** Request parameters for configureServer operation in ServersApi. */
export type ServersApiConfigureServerReq = {
    /** The UUID of the server */
    serverId: string
    serverConf: ServerConf
};

/** Response parameters for configureServer operation in ServersApi. */
export type ServersApiConfigureServerRes = void;


/** Request parameters for createServerDomain operation in ServersApi. */
export type ServersApiCreateServerDomainReq = {
    /** The UUID of the server */
    serverId: string
    body: string
};

/** Response parameters for createServerDomain operation in ServersApi. */
export type ServersApiCreateServerDomainRes = WebsiteAndDomainUuid;


/** Request parameters for createServerGroup operation in ServersApi. */
export type ServersApiCreateServerGroupReq = {
    /** The name of the new server group. */
    newServerGroup: NewServerGroup
};

/** Response parameters for createServerGroup operation in ServersApi. */
export type ServersApiCreateServerGroupRes = NewResourceUuid;


/** Request parameters for createServerNetworkInterfaceIp operation in ServersApi. */
export type ServersApiCreateServerNetworkInterfaceIpReq = {
    /** The UUID of the server */
    serverId: string
    /** The name of the network interface */
    _interface: string
    newServerIp: NewServerIp
};

/** Response parameters for createServerNetworkInterfaceIp operation in ServersApi. */
export type ServersApiCreateServerNetworkInterfaceIpRes = void;


/** Request parameters for createSlave operation in ServersApi. */
export type ServersApiCreateSlaveReq = {
    /** The secret registration key */
    key: string
    slaveRegistration: SlaveRegistration
};

/** Response parameters for createSlave operation in ServersApi. */
export type ServersApiCreateSlaveRes = void;


/** Request parameters for deleteFromSpamIpWhitelist operation in ServersApi. */
export type ServersApiDeleteFromSpamIpWhitelistReq = {
    /** The UUID of the server */
    serverId: string
    body?: string
};

/** Response parameters for deleteFromSpamIpWhitelist operation in ServersApi. */
export type ServersApiDeleteFromSpamIpWhitelistRes = void;


/** Request parameters for deleteServerDomain operation in ServersApi. */
export type ServersApiDeleteServerDomainReq = {
    /** The UUID of the server */
    serverId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for deleteServerDomain operation in ServersApi. */
export type ServersApiDeleteServerDomainRes = void;


/** Request parameters for deleteServerFromGroup operation in ServersApi. */
export type ServersApiDeleteServerFromGroupReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for deleteServerFromGroup operation in ServersApi. */
export type ServersApiDeleteServerFromGroupRes = void;


/** Request parameters for deleteServerGroup operation in ServersApi. */
export type ServersApiDeleteServerGroupReq = {
    /** The id of the server group. */
    groupId: string
};

/** Response parameters for deleteServerGroup operation in ServersApi. */
export type ServersApiDeleteServerGroupRes = void;


/** Request parameters for deleteServerNetworkInterfaceIp operation in ServersApi. */
export type ServersApiDeleteServerNetworkInterfaceIpReq = {
    /** The UUID of the server */
    serverId: string
    /** The name of the network interface */
    _interface: string
    /** The IP address in quad dot notation */
    ip: string
};

/** Response parameters for deleteServerNetworkInterfaceIp operation in ServersApi. */
export type ServersApiDeleteServerNetworkInterfaceIpRes = void;


/** Request parameters for deleteServerPrimaryIpv6 operation in ServersApi. */
export type ServersApiDeleteServerPrimaryIpv6Req = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for deleteServerPrimaryIpv6 operation in ServersApi. */
export type ServersApiDeleteServerPrimaryIpv6Res = void;


/** Request parameters for deleteServiceSetting operation in ServersApi. */
export type ServersApiDeleteServiceSettingReq = {
    /** The UUID of the server */
    serverId: string
    /** The type of setting being applied */
    settingKind: SettingKind
    /** A key for updating an existing setting, some known values are - hard_delete_after_secs - letsencrypt_enabled - org_websites_same_server - screenshot_driver_pool_size - screenshot_interval - sged_smtp - smtp_smart_host - website_backup - default_dns_ttl */
    settingKey: string
};

/** Response parameters for deleteServiceSetting operation in ServersApi. */
export type ServersApiDeleteServiceSettingRes = Outcome;


/** Request parameters for deleteSlave operation in ServersApi. */
export type ServersApiDeleteSlaveReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for deleteSlave operation in ServersApi. */
export type ServersApiDeleteSlaveRes = void;


/** Request parameters for enableFsQuotaLimits operation in ServersApi. */
export type ServersApiEnableFsQuotaLimitsReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for enableFsQuotaLimits operation in ServersApi. */
export type ServersApiEnableFsQuotaLimitsRes = void;


/** Request parameters for getAppcdScreenshotConfig operation in ServersApi. */
export type ServersApiGetAppcdScreenshotConfigReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getAppcdScreenshotConfig operation in ServersApi. */
export type ServersApiGetAppcdScreenshotConfigRes = ScreenshotConfig;


/** Request parameters for getAppcdVersion operation in ServersApi. */
export type ServersApiGetAppcdVersionReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getAppcdVersion operation in ServersApi. */
export type ServersApiGetAppcdVersionRes = string;


/** Request parameters for getClientIp operation in ServersApi. */
export type ServersApiGetClientIpReq = {
};

/** Response parameters for getClientIp operation in ServersApi. */
export type ServersApiGetClientIpRes = string;


/** Request parameters for getDatabaseRoleMysqlKind operation in ServersApi. */
export type ServersApiGetDatabaseRoleMysqlKindReq = {
    /** The UUID of the server */
    serverId: string
    mysqlKind?: MysqlKind
};

/** Response parameters for getDatabaseRoleMysqlKind operation in ServersApi. */
export type ServersApiGetDatabaseRoleMysqlKindRes = MysqlKind;


/** Request parameters for getDnsPoolIps operation in ServersApi. */
export type ServersApiGetDnsPoolIpsReq = {
};

/** Response parameters for getDnsPoolIps operation in ServersApi. */
export type ServersApiGetDnsPoolIpsRes = Array<string>;


/** Request parameters for getEmailServerHostnameOverride operation in ServersApi. */
export type ServersApiGetEmailServerHostnameOverrideReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getEmailServerHostnameOverride operation in ServersApi. */
export type ServersApiGetEmailServerHostnameOverrideRes = EmailServerHostnameOverride;


/** Request parameters for getFsQuotaStatus operation in ServersApi. */
export type ServersApiGetFsQuotaStatusReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getFsQuotaStatus operation in ServersApi. */
export type ServersApiGetFsQuotaStatusRes = FsQuotaStatus;


/** Request parameters for getHttpdStatus operation in ServersApi. */
export type ServersApiGetHttpdStatusReq = {
    /** The UUID of the server */
    serverId: string
    /** The role of the server. */
    role: ServerRole
};

/** Response parameters for getHttpdStatus operation in ServersApi. */
export type ServersApiGetHttpdStatusRes = HttpdStatus;


/** Request parameters for getInstallCmd operation in ServersApi. */
export type ServersApiGetInstallCmdReq = {
};

/** Response parameters for getInstallCmd operation in ServersApi. */
export type ServersApiGetInstallCmdRes = InstallCmd;


/** Request parameters for getMysqlMyCnf operation in ServersApi. */
export type ServersApiGetMysqlMyCnfReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getMysqlMyCnf operation in ServersApi. */
export type ServersApiGetMysqlMyCnfRes = string;


/** Request parameters for getOutboundSpamScanningSettings operation in ServersApi. */
export type ServersApiGetOutboundSpamScanningSettingsReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getOutboundSpamScanningSettings operation in ServersApi. */
export type ServersApiGetOutboundSpamScanningSettingsRes = OutboundSpamScanningSettings;


/** Request parameters for getOwaspRulesVersion operation in ServersApi. */
export type ServersApiGetOwaspRulesVersionReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getOwaspRulesVersion operation in ServersApi. */
export type ServersApiGetOwaspRulesVersionRes = OwaspVersion;


/** Request parameters for getRegistrationKey operation in ServersApi. */
export type ServersApiGetRegistrationKeyReq = {
};

/** Response parameters for getRegistrationKey operation in ServersApi. */
export type ServersApiGetRegistrationKeyRes = string;


/** Request parameters for getServerDiskUsage operation in ServersApi. */
export type ServersApiGetServerDiskUsageReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerDiskUsage operation in ServersApi. */
export type ServersApiGetServerDiskUsageRes = ServerDiskUsage;


/** Request parameters for getServerFpmSettings operation in ServersApi. */
export type ServersApiGetServerFpmSettingsReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerFpmSettings operation in ServersApi. */
export type ServersApiGetServerFpmSettingsRes = Array<WebsitePhpSettings>;


/** Request parameters for getServerGroups operation in ServersApi. */
export type ServersApiGetServerGroupsReq = {
};

/** Response parameters for getServerGroups operation in ServersApi. */
export type ServersApiGetServerGroupsRes = ServerGroups;


/** Request parameters for getServerHostnameWebsite operation in ServersApi. */
export type ServersApiGetServerHostnameWebsiteReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerHostnameWebsite operation in ServersApi. */
export type ServersApiGetServerHostnameWebsiteRes = ServerHostnameWebsite;


/** Request parameters for getServerInfo operation in ServersApi. */
export type ServersApiGetServerInfoReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerInfo operation in ServersApi. */
export type ServersApiGetServerInfoRes = ServerInfo;


/** Request parameters for getServerIowait operation in ServersApi. */
export type ServersApiGetServerIowaitReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerIowait operation in ServersApi. */
export type ServersApiGetServerIowaitRes = ServerIowait;


/** Request parameters for getServerLoad operation in ServersApi. */
export type ServersApiGetServerLoadReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerLoad operation in ServersApi. */
export type ServersApiGetServerLoadRes = ServerLoad;


/** Request parameters for getServerMemoryUsage operation in ServersApi. */
export type ServersApiGetServerMemoryUsageReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerMemoryUsage operation in ServersApi. */
export type ServersApiGetServerMemoryUsageRes = ServerMemoryUsage;


/** Request parameters for getServerModSecurityConfig operation in ServersApi. */
export type ServersApiGetServerModSecurityConfigReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerModSecurityConfig operation in ServersApi. */
export type ServersApiGetServerModSecurityConfigRes = string;


/** Request parameters for getServerModSecurityStatus operation in ServersApi. */
export type ServersApiGetServerModSecurityStatusReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerModSecurityStatus operation in ServersApi. */
export type ServersApiGetServerModSecurityStatusRes = ModSecStatus;


/** Request parameters for getServerNetworkInterfaces operation in ServersApi. */
export type ServersApiGetServerNetworkInterfacesReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerNetworkInterfaces operation in ServersApi. */
export type ServersApiGetServerNetworkInterfacesRes = ServerNetworkInterfaces;


/** Request parameters for getServerNetworkStats operation in ServersApi. */
export type ServersApiGetServerNetworkStatsReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerNetworkStats operation in ServersApi. */
export type ServersApiGetServerNetworkStatsRes = ServerNetworkStats;


/** Request parameters for getServerRole operation in ServersApi. */
export type ServersApiGetServerRoleReq = {
    /** The UUID of the server */
    serverId: string
    /** The role of the server. */
    role: ServerRole
};

/** Response parameters for getServerRole operation in ServersApi. */
export type ServersApiGetServerRoleRes = EmailRoleInfo | BackupRoleInfo | DatabaseRoleInfo | ApplicationRoleInfo | DnsRoleInfo;


/** Request parameters for getServerRoles operation in ServersApi. */
export type ServersApiGetServerRolesReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerRoles operation in ServersApi. */
export type ServersApiGetServerRolesRes = RolesInfo;


/** Request parameters for getServerStats operation in ServersApi. */
export type ServersApiGetServerStatsReq = {
    /** The UUID of the server */
    serverId: string
    /** Start datetime UTC. */
    start?: string
    /** End datetime UTC. */
    end?: string
};

/** Response parameters for getServerStats operation in ServersApi. */
export type ServersApiGetServerStatsRes = ServerStatsFullListing;


/** Request parameters for getServerStatus operation in ServersApi. */
export type ServersApiGetServerStatusReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerStatus operation in ServersApi. */
export type ServersApiGetServerStatusRes = ServerStatus;


/** Request parameters for getServerUptime operation in ServersApi. */
export type ServersApiGetServerUptimeReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getServerUptime operation in ServersApi. */
export type ServersApiGetServerUptimeRes = ServerUptime;


/** Request parameters for getServers operation in ServersApi. */
export type ServersApiGetServersReq = {
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** The field by which to sort. */
    sortBy?: 'address' | 'mailbox' | 'forwarders' | 'autoresponders'
};

/** Response parameters for getServers operation in ServersApi. */
export type ServersApiGetServersRes = ServersListing;


/** Request parameters for getServiceSetting operation in ServersApi. */
export type ServersApiGetServiceSettingReq = {
    /** The UUID of the server */
    serverId: string
    /** The type of setting being applied */
    settingKind: SettingKind
};

/** Response parameters for getServiceSetting operation in ServersApi. */
export type ServersApiGetServiceSettingRes = any;


/** Request parameters for getSmtpRateLimitHourly operation in ServersApi. */
export type ServersApiGetSmtpRateLimitHourlyReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getSmtpRateLimitHourly operation in ServersApi. */
export type ServersApiGetSmtpRateLimitHourlyRes = number;


/** Request parameters for getSpamIpWhitelist operation in ServersApi. */
export type ServersApiGetSpamIpWhitelistReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getSpamIpWhitelist operation in ServersApi. */
export type ServersApiGetSpamIpWhitelistRes = Array<string>;


/** Request parameters for getSystemPackageUpdateInfo operation in ServersApi. */
export type ServersApiGetSystemPackageUpdateInfoReq = {
    /** The UUID of the server */
    serverId: string
    systemPackageName?: SystemPackageName
};

/** Response parameters for getSystemPackageUpdateInfo operation in ServersApi. */
export type ServersApiGetSystemPackageUpdateInfoRes = UpgradableSystemPackage;


/** Request parameters for getWebserverKind operation in ServersApi. */
export type ServersApiGetWebserverKindReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getWebserverKind operation in ServersApi. */
export type ServersApiGetWebserverKindRes = WebserverKind;


/** Request parameters for getWebsiteFpmSettings operation in ServersApi. */
export type ServersApiGetWebsiteFpmSettingsReq = {
    /** The UUID of the server */
    serverId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteFpmSettings operation in ServersApi. */
export type ServersApiGetWebsiteFpmSettingsRes = PhpIni;


/** Request parameters for getWebsiteGeneratedRateLimitHourly operation in ServersApi. */
export type ServersApiGetWebsiteGeneratedRateLimitHourlyReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for getWebsiteGeneratedRateLimitHourly operation in ServersApi. */
export type ServersApiGetWebsiteGeneratedRateLimitHourlyRes = number;


/** Request parameters for installDatabaseRole operation in ServersApi. */
export type ServersApiInstallDatabaseRoleReq = {
    /** The UUID of the server */
    serverId: string
    mysqlKind?: MysqlKind
};

/** Response parameters for installDatabaseRole operation in ServersApi. */
export type ServersApiInstallDatabaseRoleRes = void;


/** Request parameters for installServerRole operation in ServersApi. */
export type ServersApiInstallServerRoleReq = {
    /** The UUID of the server */
    serverId: string
    /** The role of the server. */
    role: ServerRole
    /** Information for the backup role. */
    newBackupRole?: NewBackupRole
};

/** Response parameters for installServerRole operation in ServersApi. */
export type ServersApiInstallServerRoleRes = void;


/** Request parameters for resetServerModSecurityConfig operation in ServersApi. */
export type ServersApiResetServerModSecurityConfigReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for resetServerModSecurityConfig operation in ServersApi. */
export type ServersApiResetServerModSecurityConfigRes = void;


/** Request parameters for resetWebServerConfig operation in ServersApi. */
export type ServersApiResetWebServerConfigReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for resetWebServerConfig operation in ServersApi. */
export type ServersApiResetWebServerConfigRes = void;


/** Request parameters for restartMysql operation in ServersApi. */
export type ServersApiRestartMysqlReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for restartMysql operation in ServersApi. */
export type ServersApiRestartMysqlRes = void;


/** Request parameters for saveMysqlMyCnf operation in ServersApi. */
export type ServersApiSaveMysqlMyCnfReq = {
    /** The UUID of the server */
    serverId: string
    /** New my.cnf to be applied */
    body: string
};

/** Response parameters for saveMysqlMyCnf operation in ServersApi. */
export type ServersApiSaveMysqlMyCnfRes = void;


/** Request parameters for setEmailServerHostnameOverride operation in ServersApi. */
export type ServersApiSetEmailServerHostnameOverrideReq = {
    /** The UUID of the server */
    serverId: string
    /** Hostname override or null */
    emailServerHostnameOverride?: EmailServerHostnameOverride
};

/** Response parameters for setEmailServerHostnameOverride operation in ServersApi. */
export type ServersApiSetEmailServerHostnameOverrideRes = void;


/** Request parameters for setLiteSpeedAdminPassword operation in ServersApi. */
export type ServersApiSetLiteSpeedAdminPasswordReq = {
    /** The UUID of the server */
    serverId: string
    body: string
};

/** Response parameters for setLiteSpeedAdminPassword operation in ServersApi. */
export type ServersApiSetLiteSpeedAdminPasswordRes = void;


/** Request parameters for setOutboundSpamScanningSettings operation in ServersApi. */
export type ServersApiSetOutboundSpamScanningSettingsReq = {
    /** The UUID of the server */
    serverId: string
    /** Outbound spam settings */
    outboundSpamScanningSettings?: OutboundSpamScanningSettings
};

/** Response parameters for setOutboundSpamScanningSettings operation in ServersApi. */
export type ServersApiSetOutboundSpamScanningSettingsRes = void;


/** Request parameters for setServerDecommissioned operation in ServersApi. */
export type ServersApiSetServerDecommissionedReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for setServerDecommissioned operation in ServersApi. */
export type ServersApiSetServerDecommissionedRes = void;


/** Request parameters for setServerModSecurityConfig operation in ServersApi. */
export type ServersApiSetServerModSecurityConfigReq = {
    /** The UUID of the server */
    serverId: string
    body: string
};

/** Response parameters for setServerModSecurityConfig operation in ServersApi. */
export type ServersApiSetServerModSecurityConfigRes = void;


/** Request parameters for setServerModSecurityStatus operation in ServersApi. */
export type ServersApiSetServerModSecurityStatusReq = {
    /** The UUID of the server */
    serverId: string
    modSecStatus: ModSecStatus
};

/** Response parameters for setServerModSecurityStatus operation in ServersApi. */
export type ServersApiSetServerModSecurityStatusRes = void;


/** Request parameters for setServerStatus operation in ServersApi. */
export type ServersApiSetServerStatusReq = {
    /** The UUID of the server */
    serverId: string
    /** The action to be taken for a specific server. When rebooting a server if the reboot is graceful before the server itself is rebooted all the installed services which make use of an underlying daemon will be asked to shutdown the daemon in question (such as mysqld, httpd or pdns). If the reboot is forced the underlying daemon stop will be forced as well. Note: Primary server reboots are never allowed. The server reboot will only happens 1 minute after the request is sent. */
    setServerStatus: SetServerStatus
};

/** Response parameters for setServerStatus operation in ServersApi. */
export type ServersApiSetServerStatusRes = void;


/** Request parameters for setServiceSetting operation in ServersApi. */
export type ServersApiSetServiceSettingReq = {
    /** The UUID of the server */
    serverId: string
    /** The type of setting being applied */
    settingKind: SettingKind
    /** A key for updating an existing setting, some known values are - hard_delete_after_secs - letsencrypt_enabled - org_websites_same_server - screenshot_driver_pool_size - screenshot_interval - sged_smtp - smtp_smart_host - website_backup - default_dns_ttl */
    settingKey: string
    serviceSettingValue: ServiceSettingValue
};

/** Response parameters for setServiceSetting operation in ServersApi. */
export type ServersApiSetServiceSettingRes = Outcome;


/** Request parameters for setSmtpRateLimitHourly operation in ServersApi. */
export type ServersApiSetSmtpRateLimitHourlyReq = {
    /** The UUID of the server */
    serverId: string
    body?: number
};

/** Response parameters for setSmtpRateLimitHourly operation in ServersApi. */
export type ServersApiSetSmtpRateLimitHourlyRes = void;


/** Request parameters for setWebserverKind operation in ServersApi. */
export type ServersApiSetWebserverKindReq = {
    /** The UUID of the server */
    serverId: string
    setWebserverKind: SetWebserverKind
};

/** Response parameters for setWebserverKind operation in ServersApi. */
export type ServersApiSetWebserverKindRes = void;


/** Request parameters for setWebsiteGeneratedRateLimitHourly operation in ServersApi. */
export type ServersApiSetWebsiteGeneratedRateLimitHourlyReq = {
    /** The UUID of the server */
    serverId: string
    body?: number
};

/** Response parameters for setWebsiteGeneratedRateLimitHourly operation in ServersApi. */
export type ServersApiSetWebsiteGeneratedRateLimitHourlyRes = void;


/** Request parameters for uninstallServerRole operation in ServersApi. */
export type ServersApiUninstallServerRoleReq = {
    /** The UUID of the server */
    serverId: string
    /** The role of the server. */
    role: ServerRole
};

/** Response parameters for uninstallServerRole operation in ServersApi. */
export type ServersApiUninstallServerRoleRes = void;


/** Request parameters for updateAppcdScreenshotConfig operation in ServersApi. */
export type ServersApiUpdateAppcdScreenshotConfigReq = {
    /** The UUID of the server */
    serverId: string
    screenshotConfigUpdate: ScreenshotConfigUpdate
};

/** Response parameters for updateAppcdScreenshotConfig operation in ServersApi. */
export type ServersApiUpdateAppcdScreenshotConfigRes = void;


/** Request parameters for updateOwaspRules operation in ServersApi. */
export type ServersApiUpdateOwaspRulesReq = {
    /** The UUID of the server */
    serverId: string
};

/** Response parameters for updateOwaspRules operation in ServersApi. */
export type ServersApiUpdateOwaspRulesRes = void;


/** Request parameters for updateServerGroup operation in ServersApi. */
export type ServersApiUpdateServerGroupReq = {
    /** The id of the server group. */
    groupId: string
    /** Server group info. */
    serverGroupUpdate: ServerGroupUpdate
};

/** Response parameters for updateServerGroup operation in ServersApi. */
export type ServersApiUpdateServerGroupRes = void;


/** Request parameters for updateServerPrimaryIp operation in ServersApi. */
export type ServersApiUpdateServerPrimaryIpReq = {
    /** The UUID of the server */
    serverId: string
    body: string
};

/** Response parameters for updateServerPrimaryIp operation in ServersApi. */
export type ServersApiUpdateServerPrimaryIpRes = void;


/** Request parameters for updateServerPrimaryIpv6 operation in ServersApi. */
export type ServersApiUpdateServerPrimaryIpv6Req = {
    /** The UUID of the server */
    serverId: string
    body: string
};

/** Response parameters for updateServerPrimaryIpv6 operation in ServersApi. */
export type ServersApiUpdateServerPrimaryIpv6Res = void;


/** Request parameters for updateServerRole operation in ServersApi. */
export type ServersApiUpdateServerRoleReq = {
    /** The UUID of the server */
    serverId: string
    /** The role of the server. */
    role: ServerRole
    /** Info for updating the server role. */
    updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole: UpdateEmailRole | UpdateBackupRole | UpdateDatabaseRole | UpdateApplicationRole | UpdateDnsRole
};

/** Response parameters for updateServerRole operation in ServersApi. */
export type ServersApiUpdateServerRoleRes = void;


/** Request parameters for updateSystemPackage operation in ServersApi. */
export type ServersApiUpdateSystemPackageReq = {
    /** The UUID of the server */
    serverId: string
    /** Package to be updated. */
    systemPackage: SystemPackage
    systemPackageName?: SystemPackageName
};

/** Response parameters for updateSystemPackage operation in ServersApi. */
export type ServersApiUpdateSystemPackageRes = void;


/** Request parameters for validateRegistrationKey operation in ServersApi. */
export type ServersApiValidateRegistrationKeyReq = {
    /** The secret registration key */
    key: string
};

/** Response parameters for validateRegistrationKey operation in ServersApi. */
export type ServersApiValidateRegistrationKeyRes = ValidationResult;

/**
 * ServersApi - service client interface
 */
export const ServersApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Add to the IP whitelist -  */
    addSpamIpWhitelist: async (reqParams: ServersApiAddSpamIpWhitelistReq, options?: any): Promise<AxiosResponse<ServersApiAddSpamIpWhitelistRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).addSpamIpWhitelist(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Configure a server - Configures a server with roles to enable or disable, a friendly name to give (for identification purposes), and/or to assign server to a group. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    configureServer: async (reqParams: ServersApiConfigureServerReq, options?: any): Promise<AxiosResponse<ServersApiConfigureServerRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).configureServer(reqParams.serverId, reqParams.serverConf, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a domain which is mapped to a server - Maps a hostname to a server by creating a special kind of website with kind `ServerHostname`.  This can be used for POP/IMAP/SMTP as well as HTTP. LetsEncrypt certificates will be issued if the DNS resolves.  Only one server hostname website will be created, additional domains will be added as aliases. */
    createServerDomain: async (reqParams: ServersApiCreateServerDomainReq, options?: any): Promise<AxiosResponse<ServersApiCreateServerDomainRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).createServerDomain(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Creates a new server group - Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    createServerGroup: async (reqParams: ServersApiCreateServerGroupReq, options?: any): Promise<AxiosResponse<ServersApiCreateServerGroupRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).createServerGroup(reqParams.newServerGroup, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create server network interface secondary IP - Creates a new secondary IP address for the server\'s network interface. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    createServerNetworkInterfaceIp: async (reqParams: ServersApiCreateServerNetworkInterfaceIpReq, options?: any): Promise<AxiosResponse<ServersApiCreateServerNetworkInterfaceIpRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).createServerNetworkInterfaceIp(reqParams.serverId, reqParams._interface, reqParams.newServerIp, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a slave node - This endpoint is called by the slave server installation script and not by the API. Only included for completeness. */
    createSlave: async (reqParams: ServersApiCreateSlaveReq, options?: any): Promise<AxiosResponse<ServersApiCreateSlaveRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).createSlave(reqParams.key, reqParams.slaveRegistration, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete from the IP whitelist -  */
    deleteFromSpamIpWhitelist: async (reqParams: ServersApiDeleteFromSpamIpWhitelistReq, options?: any): Promise<AxiosResponse<ServersApiDeleteFromSpamIpWhitelistRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteFromSpamIpWhitelist(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a mapped server domain - Deletes a server domain mapping and the domain itself.  Does not delete associated SSLs. */
    deleteServerDomain: async (reqParams: ServersApiDeleteServerDomainReq, options?: any): Promise<AxiosResponse<ServersApiDeleteServerDomainRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteServerDomain(reqParams.serverId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete server from group - Deletes a server from the server group it is in, if any. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    deleteServerFromGroup: async (reqParams: ServersApiDeleteServerFromGroupReq, options?: any): Promise<AxiosResponse<ServersApiDeleteServerFromGroupRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteServerFromGroup(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes an existing server group - Does not delete servers in the group, but instead simply unlinks those servers from this group. These servers, if any, will end up not being in any group after this call succeeds. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    deleteServerGroup: async (reqParams: ServersApiDeleteServerGroupReq, options?: any): Promise<AxiosResponse<ServersApiDeleteServerGroupRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteServerGroup(reqParams.groupId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete server network interface secondary IP - Deletes a secondary IP address from the server\'s network interface. Fails if the IP address is the primary address of the server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    deleteServerNetworkInterfaceIp: async (reqParams: ServersApiDeleteServerNetworkInterfaceIpReq, options?: any): Promise<AxiosResponse<ServersApiDeleteServerNetworkInterfaceIpRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteServerNetworkInterfaceIp(reqParams.serverId, reqParams._interface, reqParams.ip, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes/unsets the primary IPv6 address for a server. -  */
    deleteServerPrimaryIpv6: async (reqParams: ServersApiDeleteServerPrimaryIpv6Req, options?: any): Promise<AxiosResponse<ServersApiDeleteServerPrimaryIpv6Res>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteServerPrimaryIpv6(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a single override setting - Delete a single service setting value */
    deleteServiceSetting: async (reqParams: ServersApiDeleteServiceSettingReq, options?: any): Promise<AxiosResponse<ServersApiDeleteServiceSettingRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteServiceSetting(reqParams.serverId, reqParams.settingKind, reqParams.settingKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a (slave) server - Removes a given server from the Enhance cluster. The server to be removed may only be a slave server as the master server cannot be removed (the error code `invalid_argument` is returned in such a case). Moreover, the server cannot be deleted if it has any data (such as running/suspended website, emails, etc) on it. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    deleteSlave: async (reqParams: ServersApiDeleteSlaveReq, options?: any): Promise<AxiosResponse<ServersApiDeleteSlaveRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).deleteSlave(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Enable FS quota limits on the server -  */
    enableFsQuotaLimits: async (reqParams: ServersApiEnableFsQuotaLimitsReq, options?: any): Promise<AxiosResponse<ServersApiEnableFsQuotaLimitsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).enableFsQuotaLimits(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the screenshot config of the running appcd - Returns the screenshot config of the running appcd instance on this server. */
    getAppcdScreenshotConfig: async (reqParams: ServersApiGetAppcdScreenshotConfigReq, options?: any): Promise<AxiosResponse<ServersApiGetAppcdScreenshotConfigRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getAppcdScreenshotConfig(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the version of the running appcd - Returns the version of the running appcd instance on this server. */
    getAppcdVersion: async (reqParams: ServersApiGetAppcdVersionReq, options?: any): Promise<AxiosResponse<ServersApiGetAppcdVersionRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getAppcdVersion(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Reflect back the IP of the API consumer -  */
    getClientIp: async (reqParams: ServersApiGetClientIpReq, options?: any): Promise<AxiosResponse<ServersApiGetClientIpRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getClientIp(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the MySQL kind for a given server. -  */
    getDatabaseRoleMysqlKind: async (reqParams: ServersApiGetDatabaseRoleMysqlKindReq, options?: any): Promise<AxiosResponse<ServersApiGetDatabaseRoleMysqlKindRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getDatabaseRoleMysqlKind(reqParams.serverId, reqParams.mysqlKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get DNS pool IPs -  */
    getDnsPoolIps: async (reqParams: ServersApiGetDnsPoolIpsReq, options?: any): Promise<AxiosResponse<ServersApiGetDnsPoolIpsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getDnsPoolIps(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the hostname override for the email server (postfix) if set - If unset, this defaults to the system hostname */
    getEmailServerHostnameOverride: async (reqParams: ServersApiGetEmailServerHostnameOverrideReq, options?: any): Promise<AxiosResponse<ServersApiGetEmailServerHostnameOverrideRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getEmailServerHostnameOverride(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get whether FS quota was enabled on the server -  */
    getFsQuotaStatus: async (reqParams: ServersApiGetFsQuotaStatusReq, options?: any): Promise<AxiosResponse<ServersApiGetFsQuotaStatusRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getFsQuotaStatus(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get status of a running httpd server. - `httpd` exposes some server stats through https://httpd.apache.org/docs/2.4/mod/mod_status.html API. Complete response from `httpd` is returned as a string. */
    getHttpdStatus: async (reqParams: ServersApiGetHttpdStatusReq, options?: any): Promise<AxiosResponse<ServersApiGetHttpdStatusRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getHttpdStatus(reqParams.serverId, reqParams.role, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get slave installation command - Returns the install script that can be used to register more servers in the Enhance cluster. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getInstallCmd: async (reqParams: ServersApiGetInstallCmdReq, options?: any): Promise<AxiosResponse<ServersApiGetInstallCmdRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getInstallCmd(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Download my.cnf for a given server -  */
    getMysqlMyCnf: async (reqParams: ServersApiGetMysqlMyCnfReq, options?: any): Promise<AxiosResponse<ServersApiGetMysqlMyCnfRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getMysqlMyCnf(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the status of outbound spam scanning -  */
    getOutboundSpamScanningSettings: async (reqParams: ServersApiGetOutboundSpamScanningSettingsReq, options?: any): Promise<AxiosResponse<ServersApiGetOutboundSpamScanningSettingsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getOutboundSpamScanningSettings(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the current and available version of the OWASP rules -  */
    getOwaspRulesVersion: async (reqParams: ServersApiGetOwaspRulesVersionReq, options?: any): Promise<AxiosResponse<ServersApiGetOwaspRulesVersionRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getOwaspRulesVersion(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get slave registration key - Key may be used to install yet more servers in the Enhance cluster. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getRegistrationKey: async (reqParams: ServersApiGetRegistrationKeyReq, options?: any): Promise<AxiosResponse<ServersApiGetRegistrationKeyRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getRegistrationKey(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server disk usage - Returns disk usage of all disks on the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerDiskUsage: async (reqParams: ServersApiGetServerDiskUsageReq, options?: any): Promise<AxiosResponse<ServersApiGetServerDiskUsageRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerDiskUsage(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get php-fpm config for all the websites on a server - Returns a list of php-fpm settings for each website on the server. Settings are queried from the running PHP instance for each website. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerFpmSettings: async (reqParams: ServersApiGetServerFpmSettingsReq, options?: any): Promise<AxiosResponse<ServersApiGetServerFpmSettingsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerFpmSettings(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns all server groups - Each group object has a list of ids of the servers that are part of this group. Note that pagination may not be applied so this endpoint always returns all server groups. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerGroups: async (reqParams: ServersApiGetServerGroupsReq, options?: any): Promise<AxiosResponse<ServersApiGetServerGroupsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerGroups(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get domains which are mapped to a server - Returns the server hostname website for this server */
    getServerHostnameWebsite: async (reqParams: ServersApiGetServerHostnameWebsiteReq, options?: any): Promise<AxiosResponse<ServersApiGetServerHostnameWebsiteRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerHostnameWebsite(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server info - Returns info about the selected server, if it exists. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerInfo: async (reqParams: ServersApiGetServerInfoReq, options?: any): Promise<AxiosResponse<ServersApiGetServerInfoRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerInfo(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server iowait - Returns system iowait of the given server in number of elapsed seconds since power-on. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerIowait: async (reqParams: ServersApiGetServerIowaitReq, options?: any): Promise<AxiosResponse<ServersApiGetServerIowaitRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerIowait(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server system load - Returns one minute system load of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerLoad: async (reqParams: ServersApiGetServerLoadReq, options?: any): Promise<AxiosResponse<ServersApiGetServerLoadRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerLoad(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server memory usage - Returns RAM and swap space usage on the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerMemoryUsage: async (reqParams: ServersApiGetServerMemoryUsageReq, options?: any): Promise<AxiosResponse<ServersApiGetServerMemoryUsageRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerMemoryUsage(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get mod security config -  */
    getServerModSecurityConfig: async (reqParams: ServersApiGetServerModSecurityConfigReq, options?: any): Promise<AxiosResponse<ServersApiGetServerModSecurityConfigRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerModSecurityConfig(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get mod security status for a server -  */
    getServerModSecurityStatus: async (reqParams: ServersApiGetServerModSecurityStatusReq, options?: any): Promise<AxiosResponse<ServersApiGetServerModSecurityStatusRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerModSecurityStatus(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server network interfaces - Returns the network interfaces of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerNetworkInterfaces: async (reqParams: ServersApiGetServerNetworkInterfacesReq, options?: any): Promise<AxiosResponse<ServersApiGetServerNetworkInterfacesRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerNetworkInterfaces(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server network stats - Returns network stats of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerNetworkStats: async (reqParams: ServersApiGetServerNetworkStatsReq, options?: any): Promise<AxiosResponse<ServersApiGetServerNetworkStatsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerNetworkStats(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server role info - Returns information about the given role on the server. This includes generic information about the role as well as each service in the role. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerRole: async (reqParams: ServersApiGetServerRoleReq, options?: any): Promise<AxiosResponse<ServersApiGetServerRoleRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerRole(reqParams.serverId, reqParams.role, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server roles info - Returns all configured roles of the given server. This includes generic information about the role as well as each service in the role. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerRoles: async (reqParams: ServersApiGetServerRolesReq, options?: any): Promise<AxiosResponse<ServersApiGetServerRolesRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerRoles(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get Server stats - Returns server stats between the optional start and end date. Defaults to last 24 hours. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getServerStats: async (reqParams: ServersApiGetServerStatsReq, options?: any): Promise<AxiosResponse<ServersApiGetServerStatsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerStats(reqParams.serverId, reqParams.start, reqParams.end, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server status - Returns system (online or offline) status of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerStatus: async (reqParams: ServersApiGetServerStatusReq, options?: any): Promise<AxiosResponse<ServersApiGetServerStatusRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerStatus(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get server uptime in seconds - Returns system iowait of the given server. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServerUptime: async (reqParams: ServersApiGetServerUptimeReq, options?: any): Promise<AxiosResponse<ServersApiGetServerUptimeRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServerUptime(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get installed servers - Returns a list of all servers in this Enhance cluster (including the master server and all slaves). The result set of servers may optionally be sorted, paginated, as well as grouped by a server\'s group id. If not grouped, the returned items are a flat list of server resource objects, whereas if grouped, the returned items are an object (map) of list of servers mapped to their group ids. Grouping is applied after pagination and sorting, and in the latter case the servers within one group will be sorted. Therefore, if paginated, the last group in items, i.e. the group on the \"border\", may not contain all servers belonging to that group if the given limit was such as would be exceeded if all its servers were included. In such a case, the remaining servers of the group are returned in the next batch. Example: Assume servers server1, server2, server3, server4, server5 and groups group1, group2, where group1 contains server1 and group2 contains server2, server3, and server4 is not in a group. If the request specifies an offset of 0 and a limit of 2, then the returned structure may be as follows: ```json {     items: {         group1_id: [             server1,         ],         group2_id: [             server2,         ],     },     total: 4 } ``` Then, in the next request, if offset is changed to 2, the returned items may be: ```json {     items: {         group2_id: [             server3,         ],         \"ungrouped\": [             server4         ],     },     total: 4 } ``` Containing the rest of group2\'s servers as well as the ungrouped servers. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getServers: async (reqParams: ServersApiGetServersReq, options?: any): Promise<AxiosResponse<ServersApiGetServersRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServers(reqParams.offset, reqParams.limit, reqParams.sortOrder, reqParams.sortBy, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the value for a particular setting - Returns the value for a particular setting as a JSON object */
    getServiceSetting: async (reqParams: ServersApiGetServiceSettingReq, options?: any): Promise<AxiosResponse<ServersApiGetServiceSettingRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getServiceSetting(reqParams.serverId, reqParams.settingKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the hourly SMTP rate limit -  */
    getSmtpRateLimitHourly: async (reqParams: ServersApiGetSmtpRateLimitHourlyReq, options?: any): Promise<AxiosResponse<ServersApiGetSmtpRateLimitHourlyRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getSmtpRateLimitHourly(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the IP whitelist -  */
    getSpamIpWhitelist: async (reqParams: ServersApiGetSpamIpWhitelistReq, options?: any): Promise<AxiosResponse<ServersApiGetSpamIpWhitelistRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getSpamIpWhitelist(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns a map of upgradable packages. - Map of upgradable system packages is returned along with latest Version if the package update is available. */
    getSystemPackageUpdateInfo: async (reqParams: ServersApiGetSystemPackageUpdateInfoReq, options?: any): Promise<AxiosResponse<ServersApiGetSystemPackageUpdateInfoRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getSystemPackageUpdateInfo(reqParams.serverId, reqParams.systemPackageName, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get web server - Fetches the current web server kind for this server. */
    getWebserverKind: async (reqParams: ServersApiGetWebserverKindReq, options?: any): Promise<AxiosResponse<ServersApiGetWebserverKindRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getWebserverKind(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get php-fpm config for the specified website - Returns a list of php-fpm settings for specified website on the server. Settings are queried from the running PHP instance for each website. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    getWebsiteFpmSettings: async (reqParams: ServersApiGetWebsiteFpmSettingsReq, options?: any): Promise<AxiosResponse<ServersApiGetWebsiteFpmSettingsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getWebsiteFpmSettings(reqParams.serverId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the hourly website generated email rate limit -  */
    getWebsiteGeneratedRateLimitHourly: async (reqParams: ServersApiGetWebsiteGeneratedRateLimitHourlyReq, options?: any): Promise<AxiosResponse<ServersApiGetWebsiteGeneratedRateLimitHourlyRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).getWebsiteGeneratedRateLimitHourly(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Enables the database role on a given ServerUuid -  */
    installDatabaseRole: async (reqParams: ServersApiInstallDatabaseRoleReq, options?: any): Promise<AxiosResponse<ServersApiInstallDatabaseRoleRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).installDatabaseRole(reqParams.serverId, reqParams.mysqlKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Install server role - Installs a role on the server. The backup role takes additional parameters, but all other roles take no parameters. The block device size is optional and defaults to 100 GiB if not set. In this case, the mount point has to be the absolute path where the backup volume should be mounted. If there is already a valid btrfs backup volume mounted at this path, besides installing the `bkupd` service, this is a noop. In the case of an existing mount point, it is verified that it has at least the block device size bytes available. The block device is an absolute path that may or may not exist. If it exists, the block device size is used to verify if the device has at least the specified space available, if it doesn\'t exist, a loopback device is created with the this size. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    installServerRole: async (reqParams: ServersApiInstallServerRoleReq, options?: any): Promise<AxiosResponse<ServersApiInstallServerRoleRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).installServerRole(reqParams.serverId, reqParams.role, reqParams.newBackupRole, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete custom mod_security config and reset to default -  */
    resetServerModSecurityConfig: async (reqParams: ServersApiResetServerModSecurityConfigReq, options?: any): Promise<AxiosResponse<ServersApiResetServerModSecurityConfigRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).resetServerModSecurityConfig(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Reset the config for the web server to default. - Will reset the config for the running web server to \"known good\" defaults.  Currently only available for LiteSpeed. */
    resetWebServerConfig: async (reqParams: ServersApiResetWebServerConfigReq, options?: any): Promise<AxiosResponse<ServersApiResetWebServerConfigRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).resetWebServerConfig(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Restart MySQL gracefully -  */
    restartMysql: async (reqParams: ServersApiRestartMysqlReq, options?: any): Promise<AxiosResponse<ServersApiRestartMysqlRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).restartMysql(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Save a new my.cnf -  */
    saveMysqlMyCnf: async (reqParams: ServersApiSaveMysqlMyCnfReq, options?: any): Promise<AxiosResponse<ServersApiSaveMysqlMyCnfRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).saveMysqlMyCnf(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the hostname override for the email server (postfix) - If unset, this defaults to the system hostname */
    setEmailServerHostnameOverride: async (reqParams: ServersApiSetEmailServerHostnameOverrideReq, options?: any): Promise<AxiosResponse<ServersApiSetEmailServerHostnameOverrideRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setEmailServerHostnameOverride(reqParams.serverId, reqParams.emailServerHostnameOverride, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a new LiteSpeed admin password. - Will reset the LiteSpeed or OpenLiteSpeed admin password.  The username is always \"admin\". */
    setLiteSpeedAdminPassword: async (reqParams: ServersApiSetLiteSpeedAdminPasswordReq, options?: any): Promise<AxiosResponse<ServersApiSetLiteSpeedAdminPasswordRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setLiteSpeedAdminPassword(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the settings for outbound spam scanning -  */
    setOutboundSpamScanningSettings: async (reqParams: ServersApiSetOutboundSpamScanningSettingsReq, options?: any): Promise<AxiosResponse<ServersApiSetOutboundSpamScanningSettingsRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setOutboundSpamScanningSettings(reqParams.serverId, reqParams.outboundSpamScanningSettings, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set server to decommissioned - If a server was decommissioned outside of Enhance, set its status to decommissioned.  This will remove the server from any status checks, will prevent website placement and will enable websites to be moved from it to another server using backups if available. */
    setServerDecommissioned: async (reqParams: ServersApiSetServerDecommissionedReq, options?: any): Promise<AxiosResponse<ServersApiSetServerDecommissionedRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setServerDecommissioned(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set mod security config - This config is included in the web server config when mod_security is enabled. */
    setServerModSecurityConfig: async (reqParams: ServersApiSetServerModSecurityConfigReq, options?: any): Promise<AxiosResponse<ServersApiSetServerModSecurityConfigRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setServerModSecurityConfig(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set mod security status for a server - If enabled, all websites on this server by default will have mod security enabled, unless explicitly disabled. */
    setServerModSecurityStatus: async (reqParams: ServersApiSetServerModSecurityStatusReq, options?: any): Promise<AxiosResponse<ServersApiSetServerModSecurityStatusRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setServerModSecurityStatus(reqParams.serverId, reqParams.modSecStatus, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the status of one server. - Set the status of one server by rebooting it for example. */
    setServerStatus: async (reqParams: ServersApiSetServerStatusReq, options?: any): Promise<AxiosResponse<ServersApiSetServerStatusRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setServerStatus(reqParams.serverId, reqParams.setServerStatus, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a single service setting - Set or replace a single service level override setting */
    setServiceSetting: async (reqParams: ServersApiSetServiceSettingReq, options?: any): Promise<AxiosResponse<ServersApiSetServiceSettingRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setServiceSetting(reqParams.serverId, reqParams.settingKind, reqParams.settingKey, reqParams.serviceSettingValue, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the hourly SMTP rate limit -  */
    setSmtpRateLimitHourly: async (reqParams: ServersApiSetSmtpRateLimitHourlyReq, options?: any): Promise<AxiosResponse<ServersApiSetSmtpRateLimitHourlyRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setSmtpRateLimitHourly(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the web server kind for one server. - Changes the web server kind for this server.  This will rebuild any application containers if required.  It may be a long running operation. */
    setWebserverKind: async (reqParams: ServersApiSetWebserverKindReq, options?: any): Promise<AxiosResponse<ServersApiSetWebserverKindRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setWebserverKind(reqParams.serverId, reqParams.setWebserverKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the hourly SMTP rate limit -  */
    setWebsiteGeneratedRateLimitHourly: async (reqParams: ServersApiSetWebsiteGeneratedRateLimitHourlyReq, options?: any): Promise<AxiosResponse<ServersApiSetWebsiteGeneratedRateLimitHourlyRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).setWebsiteGeneratedRateLimitHourly(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Uninstall a server role - Uninstalls role from server, if role has no websites assigned to it. If the role to be uninstalled is the control panel application role, the request returns an error, since this role may only be disabled but not uninstalled (since it is required to serve the control panel). Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    uninstallServerRole: async (reqParams: ServersApiUninstallServerRoleReq, options?: any): Promise<AxiosResponse<ServersApiUninstallServerRoleRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).uninstallServerRole(reqParams.serverId, reqParams.role, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update the screenshot config of the running appcd - Update the screenshot config of the running appcd instance on this server. */
    updateAppcdScreenshotConfig: async (reqParams: ServersApiUpdateAppcdScreenshotConfigReq, options?: any): Promise<AxiosResponse<ServersApiUpdateAppcdScreenshotConfigRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateAppcdScreenshotConfig(reqParams.serverId, reqParams.screenshotConfigUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upgrade OWASP rules - Will update the owasp rules to the latest version.  If the current version is the latest, it will be reinstalled. */
    updateOwaspRules: async (reqParams: ServersApiUpdateOwaspRulesReq, options?: any): Promise<AxiosResponse<ServersApiUpdateOwaspRulesRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateOwaspRules(reqParams.serverId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates an existing server group\'s name - Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    updateServerGroup: async (reqParams: ServersApiUpdateServerGroupReq, options?: any): Promise<AxiosResponse<ServersApiUpdateServerGroupRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateServerGroup(reqParams.groupId, reqParams.serverGroupUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates the primary IP of the server in the database and in-memory metadata. This operation will not affect the IP used for service communication until the next restart of orchd. The new IP will be used for creation of new resources such as websites on this server but existing websites will not have their IP changed. -  */
    updateServerPrimaryIp: async (reqParams: ServersApiUpdateServerPrimaryIpReq, options?: any): Promise<AxiosResponse<ServersApiUpdateServerPrimaryIpRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateServerPrimaryIp(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates or sets the primary ipv6 address of the server.  This endpoint will not change existing websites\' DNS but the new record will be applied to all future zones. -  */
    updateServerPrimaryIpv6: async (reqParams: ServersApiUpdateServerPrimaryIpv6Req, options?: any): Promise<AxiosResponse<ServersApiUpdateServerPrimaryIpv6Res>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateServerPrimaryIpv6(reqParams.serverId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update server role - Updates role and role state. A role, if activated on a server, may be in a state of enabled or disabled. If enabled, it means that new resources (e.g. websites for the application role) may be installed on the server, but if it\'s disabled, existing resources (e.g. websites) are kept but no new resources may be added. Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    updateServerRole: async (reqParams: ServersApiUpdateServerRoleReq, options?: any): Promise<AxiosResponse<ServersApiUpdateServerRoleRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateServerRole(reqParams.serverId, reqParams.role, reqParams.updateEmailRoleUpdateBackupRoleUpdateDatabaseRoleUpdateApplicationRoleUpdateDnsRole, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates a system package to its latest version. - Can only update installed package to its latest version. */
    updateSystemPackage: async (reqParams: ServersApiUpdateSystemPackageReq, options?: any): Promise<AxiosResponse<ServersApiUpdateSystemPackageRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).updateSystemPackage(reqParams.serverId, reqParams.systemPackage, reqParams.systemPackageName, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Validate slave registration key - Session holder must be an `Owner`, `SuperAdmin`, or `Sysadmin` in the MO. */
    validateRegistrationKey: async (reqParams: ServersApiValidateRegistrationKeyReq, options?: any): Promise<AxiosResponse<ServersApiValidateRegistrationKeyRes>> => {
        const localVarAxiosArgs = await ServersApiAxiosParamCreator(configuration).validateRegistrationKey(reqParams.key, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface ServersApiReq {
    addSpamIpWhitelist: ServersApiAddSpamIpWhitelistReq;
    configureServer: ServersApiConfigureServerReq;
    createServerDomain: ServersApiCreateServerDomainReq;
    createServerGroup: ServersApiCreateServerGroupReq;
    createServerNetworkInterfaceIp: ServersApiCreateServerNetworkInterfaceIpReq;
    createSlave: ServersApiCreateSlaveReq;
    deleteFromSpamIpWhitelist: ServersApiDeleteFromSpamIpWhitelistReq;
    deleteServerDomain: ServersApiDeleteServerDomainReq;
    deleteServerFromGroup: ServersApiDeleteServerFromGroupReq;
    deleteServerGroup: ServersApiDeleteServerGroupReq;
    deleteServerNetworkInterfaceIp: ServersApiDeleteServerNetworkInterfaceIpReq;
    deleteServerPrimaryIpv6: ServersApiDeleteServerPrimaryIpv6Req;
    deleteServiceSetting: ServersApiDeleteServiceSettingReq;
    deleteSlave: ServersApiDeleteSlaveReq;
    enableFsQuotaLimits: ServersApiEnableFsQuotaLimitsReq;
    getAppcdScreenshotConfig: ServersApiGetAppcdScreenshotConfigReq;
    getAppcdVersion: ServersApiGetAppcdVersionReq;
    getClientIp: ServersApiGetClientIpReq;
    getDatabaseRoleMysqlKind: ServersApiGetDatabaseRoleMysqlKindReq;
    getDnsPoolIps: ServersApiGetDnsPoolIpsReq;
    getEmailServerHostnameOverride: ServersApiGetEmailServerHostnameOverrideReq;
    getFsQuotaStatus: ServersApiGetFsQuotaStatusReq;
    getHttpdStatus: ServersApiGetHttpdStatusReq;
    getInstallCmd: ServersApiGetInstallCmdReq;
    getMysqlMyCnf: ServersApiGetMysqlMyCnfReq;
    getOutboundSpamScanningSettings: ServersApiGetOutboundSpamScanningSettingsReq;
    getOwaspRulesVersion: ServersApiGetOwaspRulesVersionReq;
    getRegistrationKey: ServersApiGetRegistrationKeyReq;
    getServerDiskUsage: ServersApiGetServerDiskUsageReq;
    getServerFpmSettings: ServersApiGetServerFpmSettingsReq;
    getServerGroups: ServersApiGetServerGroupsReq;
    getServerHostnameWebsite: ServersApiGetServerHostnameWebsiteReq;
    getServerInfo: ServersApiGetServerInfoReq;
    getServerIowait: ServersApiGetServerIowaitReq;
    getServerLoad: ServersApiGetServerLoadReq;
    getServerMemoryUsage: ServersApiGetServerMemoryUsageReq;
    getServerModSecurityConfig: ServersApiGetServerModSecurityConfigReq;
    getServerModSecurityStatus: ServersApiGetServerModSecurityStatusReq;
    getServerNetworkInterfaces: ServersApiGetServerNetworkInterfacesReq;
    getServerNetworkStats: ServersApiGetServerNetworkStatsReq;
    getServerRole: ServersApiGetServerRoleReq;
    getServerRoles: ServersApiGetServerRolesReq;
    getServerStats: ServersApiGetServerStatsReq;
    getServerStatus: ServersApiGetServerStatusReq;
    getServerUptime: ServersApiGetServerUptimeReq;
    getServers: ServersApiGetServersReq;
    getServiceSetting: ServersApiGetServiceSettingReq;
    getSmtpRateLimitHourly: ServersApiGetSmtpRateLimitHourlyReq;
    getSpamIpWhitelist: ServersApiGetSpamIpWhitelistReq;
    getSystemPackageUpdateInfo: ServersApiGetSystemPackageUpdateInfoReq;
    getWebserverKind: ServersApiGetWebserverKindReq;
    getWebsiteFpmSettings: ServersApiGetWebsiteFpmSettingsReq;
    getWebsiteGeneratedRateLimitHourly: ServersApiGetWebsiteGeneratedRateLimitHourlyReq;
    installDatabaseRole: ServersApiInstallDatabaseRoleReq;
    installServerRole: ServersApiInstallServerRoleReq;
    resetServerModSecurityConfig: ServersApiResetServerModSecurityConfigReq;
    resetWebServerConfig: ServersApiResetWebServerConfigReq;
    restartMysql: ServersApiRestartMysqlReq;
    saveMysqlMyCnf: ServersApiSaveMysqlMyCnfReq;
    setEmailServerHostnameOverride: ServersApiSetEmailServerHostnameOverrideReq;
    setLiteSpeedAdminPassword: ServersApiSetLiteSpeedAdminPasswordReq;
    setOutboundSpamScanningSettings: ServersApiSetOutboundSpamScanningSettingsReq;
    setServerDecommissioned: ServersApiSetServerDecommissionedReq;
    setServerModSecurityConfig: ServersApiSetServerModSecurityConfigReq;
    setServerModSecurityStatus: ServersApiSetServerModSecurityStatusReq;
    setServerStatus: ServersApiSetServerStatusReq;
    setServiceSetting: ServersApiSetServiceSettingReq;
    setSmtpRateLimitHourly: ServersApiSetSmtpRateLimitHourlyReq;
    setWebserverKind: ServersApiSetWebserverKindReq;
    setWebsiteGeneratedRateLimitHourly: ServersApiSetWebsiteGeneratedRateLimitHourlyReq;
    uninstallServerRole: ServersApiUninstallServerRoleReq;
    updateAppcdScreenshotConfig: ServersApiUpdateAppcdScreenshotConfigReq;
    updateOwaspRules: ServersApiUpdateOwaspRulesReq;
    updateServerGroup: ServersApiUpdateServerGroupReq;
    updateServerPrimaryIp: ServersApiUpdateServerPrimaryIpReq;
    updateServerPrimaryIpv6: ServersApiUpdateServerPrimaryIpv6Req;
    updateServerRole: ServersApiUpdateServerRoleReq;
    updateSystemPackage: ServersApiUpdateSystemPackageReq;
    validateRegistrationKey: ServersApiValidateRegistrationKeyReq;
}

export interface ServersApiRes {
    addSpamIpWhitelist: ServersApiAddSpamIpWhitelistRes;
    configureServer: ServersApiConfigureServerRes;
    createServerDomain: ServersApiCreateServerDomainRes;
    createServerGroup: ServersApiCreateServerGroupRes;
    createServerNetworkInterfaceIp: ServersApiCreateServerNetworkInterfaceIpRes;
    createSlave: ServersApiCreateSlaveRes;
    deleteFromSpamIpWhitelist: ServersApiDeleteFromSpamIpWhitelistRes;
    deleteServerDomain: ServersApiDeleteServerDomainRes;
    deleteServerFromGroup: ServersApiDeleteServerFromGroupRes;
    deleteServerGroup: ServersApiDeleteServerGroupRes;
    deleteServerNetworkInterfaceIp: ServersApiDeleteServerNetworkInterfaceIpRes;
    deleteServerPrimaryIpv6: ServersApiDeleteServerPrimaryIpv6Res;
    deleteServiceSetting: ServersApiDeleteServiceSettingRes;
    deleteSlave: ServersApiDeleteSlaveRes;
    enableFsQuotaLimits: ServersApiEnableFsQuotaLimitsRes;
    getAppcdScreenshotConfig: ServersApiGetAppcdScreenshotConfigRes;
    getAppcdVersion: ServersApiGetAppcdVersionRes;
    getClientIp: ServersApiGetClientIpRes;
    getDatabaseRoleMysqlKind: ServersApiGetDatabaseRoleMysqlKindRes;
    getDnsPoolIps: ServersApiGetDnsPoolIpsRes;
    getEmailServerHostnameOverride: ServersApiGetEmailServerHostnameOverrideRes;
    getFsQuotaStatus: ServersApiGetFsQuotaStatusRes;
    getHttpdStatus: ServersApiGetHttpdStatusRes;
    getInstallCmd: ServersApiGetInstallCmdRes;
    getMysqlMyCnf: ServersApiGetMysqlMyCnfRes;
    getOutboundSpamScanningSettings: ServersApiGetOutboundSpamScanningSettingsRes;
    getOwaspRulesVersion: ServersApiGetOwaspRulesVersionRes;
    getRegistrationKey: ServersApiGetRegistrationKeyRes;
    getServerDiskUsage: ServersApiGetServerDiskUsageRes;
    getServerFpmSettings: ServersApiGetServerFpmSettingsRes;
    getServerGroups: ServersApiGetServerGroupsRes;
    getServerHostnameWebsite: ServersApiGetServerHostnameWebsiteRes;
    getServerInfo: ServersApiGetServerInfoRes;
    getServerIowait: ServersApiGetServerIowaitRes;
    getServerLoad: ServersApiGetServerLoadRes;
    getServerMemoryUsage: ServersApiGetServerMemoryUsageRes;
    getServerModSecurityConfig: ServersApiGetServerModSecurityConfigRes;
    getServerModSecurityStatus: ServersApiGetServerModSecurityStatusRes;
    getServerNetworkInterfaces: ServersApiGetServerNetworkInterfacesRes;
    getServerNetworkStats: ServersApiGetServerNetworkStatsRes;
    getServerRole: ServersApiGetServerRoleRes;
    getServerRoles: ServersApiGetServerRolesRes;
    getServerStats: ServersApiGetServerStatsRes;
    getServerStatus: ServersApiGetServerStatusRes;
    getServerUptime: ServersApiGetServerUptimeRes;
    getServers: ServersApiGetServersRes;
    getServiceSetting: ServersApiGetServiceSettingRes;
    getSmtpRateLimitHourly: ServersApiGetSmtpRateLimitHourlyRes;
    getSpamIpWhitelist: ServersApiGetSpamIpWhitelistRes;
    getSystemPackageUpdateInfo: ServersApiGetSystemPackageUpdateInfoRes;
    getWebserverKind: ServersApiGetWebserverKindRes;
    getWebsiteFpmSettings: ServersApiGetWebsiteFpmSettingsRes;
    getWebsiteGeneratedRateLimitHourly: ServersApiGetWebsiteGeneratedRateLimitHourlyRes;
    installDatabaseRole: ServersApiInstallDatabaseRoleRes;
    installServerRole: ServersApiInstallServerRoleRes;
    resetServerModSecurityConfig: ServersApiResetServerModSecurityConfigRes;
    resetWebServerConfig: ServersApiResetWebServerConfigRes;
    restartMysql: ServersApiRestartMysqlRes;
    saveMysqlMyCnf: ServersApiSaveMysqlMyCnfRes;
    setEmailServerHostnameOverride: ServersApiSetEmailServerHostnameOverrideRes;
    setLiteSpeedAdminPassword: ServersApiSetLiteSpeedAdminPasswordRes;
    setOutboundSpamScanningSettings: ServersApiSetOutboundSpamScanningSettingsRes;
    setServerDecommissioned: ServersApiSetServerDecommissionedRes;
    setServerModSecurityConfig: ServersApiSetServerModSecurityConfigRes;
    setServerModSecurityStatus: ServersApiSetServerModSecurityStatusRes;
    setServerStatus: ServersApiSetServerStatusRes;
    setServiceSetting: ServersApiSetServiceSettingRes;
    setSmtpRateLimitHourly: ServersApiSetSmtpRateLimitHourlyRes;
    setWebserverKind: ServersApiSetWebserverKindRes;
    setWebsiteGeneratedRateLimitHourly: ServersApiSetWebsiteGeneratedRateLimitHourlyRes;
    uninstallServerRole: ServersApiUninstallServerRoleRes;
    updateAppcdScreenshotConfig: ServersApiUpdateAppcdScreenshotConfigRes;
    updateOwaspRules: ServersApiUpdateOwaspRulesRes;
    updateServerGroup: ServersApiUpdateServerGroupRes;
    updateServerPrimaryIp: ServersApiUpdateServerPrimaryIpRes;
    updateServerPrimaryIpv6: ServersApiUpdateServerPrimaryIpv6Res;
    updateServerRole: ServersApiUpdateServerRoleRes;
    updateSystemPackage: ServersApiUpdateSystemPackageRes;
    validateRegistrationKey: ServersApiValidateRegistrationKeyRes;
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Set the orchd login policy email blacklist as a whole -  */
        addOrchdLoginPolicyEmailBlacklist: async (orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/email-blacklist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyEmailList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyEmailList !== undefined ? orchdLoginPolicyEmailList : {}) : (orchdLoginPolicyEmailList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the orchd login policy email whitelist as a whole -  */
        addOrchdLoginPolicyEmailWhitelist: async (orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/email-whitelist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyEmailList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyEmailList !== undefined ? orchdLoginPolicyEmailList : {}) : (orchdLoginPolicyEmailList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the orchd login policy ip blacklist as a whole -  */
        addOrchdLoginPolicyIpBlacklist: async (orchdLoginPolicyIpList: OrchdLoginPolicyIpList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/ip-blacklist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyIpList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyIpList !== undefined ? orchdLoginPolicyIpList : {}) : (orchdLoginPolicyIpList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the orchd login policy ip whitelist as a whole -  */
        addOrchdLoginPolicyIpWhitelist: async (orchdLoginPolicyIpList: OrchdLoginPolicyIpList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/ip-whitelist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyIpList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyIpList !== undefined ? orchdLoginPolicyIpList : {}) : (orchdLoginPolicyIpList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a single orchd login policy setting -  */
        addOrchdLoginPolicySettings: async (orchdLoginPolicySettings: OrchdLoginPolicySettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicySettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicySettings !== undefined ? orchdLoginPolicySettings : {}) : (orchdLoginPolicySettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create S3 object storage settings at platform level. -  */
        createBackupRemoteStorageS3: async (createBackupRemoteStorageS3: CreateBackupRemoteStorageS3, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/backup/remote_storage/s3`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createBackupRemoteStorageS3 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createBackupRemoteStorageS3 !== undefined ? createBackupRemoteStorageS3 : {}) : (createBackupRemoteStorageS3 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create settings -  */
        createSettings: async (setting: Array<Setting>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setting !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setting !== undefined ? setting : {}) : (setting || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete S3 object storage settings at platform level. -  */
        deleteBackupRemoteStorageS3: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/backup/remote_storage/s3`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a single global service setting - Delete a single global service setting value */
        deleteGlobalServiceSetting: async (settingKind: SettingKind, settingKey: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/service/{setting_kind}/{setting_key}`
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)))
                .replace(`{${"setting_key"}}`, encodeURIComponent(String(settingKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete an orchd login policy email blacklist -  */
        deleteOrchdLoginPolicyEmailBlacklist: async (orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/email-blacklist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyEmailList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyEmailList !== undefined ? orchdLoginPolicyEmailList : {}) : (orchdLoginPolicyEmailList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete an orchd login policy email whitelist -  */
        deleteOrchdLoginPolicyEmailWhitelist: async (orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/email-whitelist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyEmailList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyEmailList !== undefined ? orchdLoginPolicyEmailList : {}) : (orchdLoginPolicyEmailList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete an orchd login policy ip blacklist -  */
        deleteOrchdLoginPolicyIpBlacklist: async (orchdLoginPolicyIpList: OrchdLoginPolicyIpList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/ip-blacklist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyIpList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyIpList !== undefined ? orchdLoginPolicyIpList : {}) : (orchdLoginPolicyIpList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete an orchd login policy ip whitelist -  */
        deleteOrchdLoginPolicyIpWhitelist: async (orchdLoginPolicyIpList: OrchdLoginPolicyIpList, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/ip-whitelist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLoginPolicyIpList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLoginPolicyIpList !== undefined ? orchdLoginPolicyIpList : {}) : (orchdLoginPolicyIpList || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Remove the specified setting -  */
        deleteSetting: async (name: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get S3 object storage settings at platform level. -  */
        getBackupRemoteStorageS3: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/backup/remote_storage/s3`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the demo mode status of the orchd service -  */
        getDemoMode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/demo_mode`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the value for a particular global service setting - Returns the value for a particular global service setting as a JSON object */
        getGlobalServiceSetting: async (settingKind: SettingKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/service/{setting_kind}`
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the orchd log settings -  */
        getOrchdLogSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/logs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the orchd login policy email blacklist -  */
        getOrchdLoginPolicyEmailBlacklist: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/email-blacklist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the orchd login policy email whitelist -  */
        getOrchdLoginPolicyEmailWhitelist: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/email-whitelist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the orchd login policy ip blacklist -  */
        getOrchdLoginPolicyIpBlacklist: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/ip-blacklist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the orchd login policy ip whitelist -  */
        getOrchdLoginPolicyIpWhitelist: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/ip-whitelist`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the orchd login policy settings -  */
        getOrchdLoginPolicySettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/login-policy/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the platform level prohibited domains as a newline separated list -  */
        getProhibitedDomains: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/prohibited_domains`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the specified setting -  */
        getSetting: async (name: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get all current settings -  */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a single global service setting - Set or replace a single global service setting */
        setGlobalServiceSetting: async (settingKind: SettingKind, settingKey: string, serviceSettingValue: ServiceSettingValue, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/service/{setting_kind}/{setting_key}`
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)))
                .replace(`{${"setting_key"}}`, encodeURIComponent(String(settingKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof serviceSettingValue !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serviceSettingValue !== undefined ? serviceSettingValue : {}) : (serviceSettingValue || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the orchd log settings -  */
        setOrchdLogSettings: async (orchdLogSettings: OrchdLogSettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/logs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof orchdLogSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(orchdLogSettings !== undefined ? orchdLogSettings : {}) : (orchdLogSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the platform level prohibited domains -  */
        setProhibitedDomains: async (body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/orchd/prohibited_domains`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update S3 object storage settings at platform level. -  */
        updateBackupRemoteStorageS3: async (updateBackupRemoteStorageS3: UpdateBackupRemoteStorageS3, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/settings/backup/remote_storage/s3`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateBackupRemoteStorageS3 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateBackupRemoteStorageS3 !== undefined ? updateBackupRemoteStorageS3 : {}) : (updateBackupRemoteStorageS3 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create or update the specified setting -  */
        updateSetting: async (name: string, stringNumberBooleanArrayanyObject: string | number | boolean | Array<any> | object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stringNumberBooleanArrayanyObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stringNumberBooleanArrayanyObject !== undefined ? stringNumberBooleanArrayanyObject : {}) : (stringNumberBooleanArrayanyObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for addOrchdLoginPolicyEmailBlacklist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyEmailBlacklistReq = {
    orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList
};

/** Response parameters for addOrchdLoginPolicyEmailBlacklist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyEmailBlacklistRes = void;


/** Request parameters for addOrchdLoginPolicyEmailWhitelist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyEmailWhitelistReq = {
    orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList
};

/** Response parameters for addOrchdLoginPolicyEmailWhitelist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyEmailWhitelistRes = void;


/** Request parameters for addOrchdLoginPolicyIpBlacklist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyIpBlacklistReq = {
    orchdLoginPolicyIpList: OrchdLoginPolicyIpList
};

/** Response parameters for addOrchdLoginPolicyIpBlacklist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyIpBlacklistRes = void;


/** Request parameters for addOrchdLoginPolicyIpWhitelist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyIpWhitelistReq = {
    orchdLoginPolicyIpList: OrchdLoginPolicyIpList
};

/** Response parameters for addOrchdLoginPolicyIpWhitelist operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicyIpWhitelistRes = void;


/** Request parameters for addOrchdLoginPolicySettings operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicySettingsReq = {
    orchdLoginPolicySettings: OrchdLoginPolicySettings
};

/** Response parameters for addOrchdLoginPolicySettings operation in SettingsApi. */
export type SettingsApiAddOrchdLoginPolicySettingsRes = void;


/** Request parameters for createBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiCreateBackupRemoteStorageS3Req = {
    createBackupRemoteStorageS3: CreateBackupRemoteStorageS3
};

/** Response parameters for createBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiCreateBackupRemoteStorageS3Res = void;


/** Request parameters for createSettings operation in SettingsApi. */
export type SettingsApiCreateSettingsReq = {
    setting: Array<Setting>
};

/** Response parameters for createSettings operation in SettingsApi. */
export type SettingsApiCreateSettingsRes = void;


/** Request parameters for deleteBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiDeleteBackupRemoteStorageS3Req = {
};

/** Response parameters for deleteBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiDeleteBackupRemoteStorageS3Res = void;


/** Request parameters for deleteGlobalServiceSetting operation in SettingsApi. */
export type SettingsApiDeleteGlobalServiceSettingReq = {
    /** The type of setting being applied */
    settingKind: SettingKind
    /** A key for updating an existing setting, some known values are - hard_delete_after_secs - letsencrypt_enabled - org_websites_same_server - screenshot_driver_pool_size - screenshot_interval - sged_smtp - smtp_smart_host - website_backup - default_dns_ttl */
    settingKey: string
};

/** Response parameters for deleteGlobalServiceSetting operation in SettingsApi. */
export type SettingsApiDeleteGlobalServiceSettingRes = Outcome;


/** Request parameters for deleteOrchdLoginPolicyEmailBlacklist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyEmailBlacklistReq = {
    orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList
};

/** Response parameters for deleteOrchdLoginPolicyEmailBlacklist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyEmailBlacklistRes = void;


/** Request parameters for deleteOrchdLoginPolicyEmailWhitelist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyEmailWhitelistReq = {
    orchdLoginPolicyEmailList: OrchdLoginPolicyEmailList
};

/** Response parameters for deleteOrchdLoginPolicyEmailWhitelist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyEmailWhitelistRes = void;


/** Request parameters for deleteOrchdLoginPolicyIpBlacklist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyIpBlacklistReq = {
    orchdLoginPolicyIpList: OrchdLoginPolicyIpList
};

/** Response parameters for deleteOrchdLoginPolicyIpBlacklist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyIpBlacklistRes = void;


/** Request parameters for deleteOrchdLoginPolicyIpWhitelist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyIpWhitelistReq = {
    orchdLoginPolicyIpList: OrchdLoginPolicyIpList
};

/** Response parameters for deleteOrchdLoginPolicyIpWhitelist operation in SettingsApi. */
export type SettingsApiDeleteOrchdLoginPolicyIpWhitelistRes = void;


/** Request parameters for deleteSetting operation in SettingsApi. */
export type SettingsApiDeleteSettingReq = {
    /** The name of the resource. */
    name: string
};

/** Response parameters for deleteSetting operation in SettingsApi. */
export type SettingsApiDeleteSettingRes = void;


/** Request parameters for getBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiGetBackupRemoteStorageS3Req = {
};

/** Response parameters for getBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiGetBackupRemoteStorageS3Res = BackupRemoteStorageS3;


/** Request parameters for getDemoMode operation in SettingsApi. */
export type SettingsApiGetDemoModeReq = {
};

/** Response parameters for getDemoMode operation in SettingsApi. */
export type SettingsApiGetDemoModeRes = DemoMode;


/** Request parameters for getGlobalServiceSetting operation in SettingsApi. */
export type SettingsApiGetGlobalServiceSettingReq = {
    /** The type of setting being applied */
    settingKind: SettingKind
};

/** Response parameters for getGlobalServiceSetting operation in SettingsApi. */
export type SettingsApiGetGlobalServiceSettingRes = any;


/** Request parameters for getOrchdLogSettings operation in SettingsApi. */
export type SettingsApiGetOrchdLogSettingsReq = {
};

/** Response parameters for getOrchdLogSettings operation in SettingsApi. */
export type SettingsApiGetOrchdLogSettingsRes = OrchdLogSettings;


/** Request parameters for getOrchdLoginPolicyEmailBlacklist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyEmailBlacklistReq = {
};

/** Response parameters for getOrchdLoginPolicyEmailBlacklist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyEmailBlacklistRes = OrchdLoginPolicyEmailList;


/** Request parameters for getOrchdLoginPolicyEmailWhitelist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyEmailWhitelistReq = {
};

/** Response parameters for getOrchdLoginPolicyEmailWhitelist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyEmailWhitelistRes = OrchdLoginPolicyEmailList;


/** Request parameters for getOrchdLoginPolicyIpBlacklist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyIpBlacklistReq = {
};

/** Response parameters for getOrchdLoginPolicyIpBlacklist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyIpBlacklistRes = OrchdLoginPolicyIpList;


/** Request parameters for getOrchdLoginPolicyIpWhitelist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyIpWhitelistReq = {
};

/** Response parameters for getOrchdLoginPolicyIpWhitelist operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicyIpWhitelistRes = OrchdLoginPolicyIpList;


/** Request parameters for getOrchdLoginPolicySettings operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicySettingsReq = {
};

/** Response parameters for getOrchdLoginPolicySettings operation in SettingsApi. */
export type SettingsApiGetOrchdLoginPolicySettingsRes = OrchdLoginPolicySettings;


/** Request parameters for getProhibitedDomains operation in SettingsApi. */
export type SettingsApiGetProhibitedDomainsReq = {
};

/** Response parameters for getProhibitedDomains operation in SettingsApi. */
export type SettingsApiGetProhibitedDomainsRes = string;


/** Request parameters for getSetting operation in SettingsApi. */
export type SettingsApiGetSettingReq = {
    /** The name of the resource. */
    name: string
};

/** Response parameters for getSetting operation in SettingsApi. */
export type SettingsApiGetSettingRes = Setting;


/** Request parameters for getSettings operation in SettingsApi. */
export type SettingsApiGetSettingsReq = {
};

/** Response parameters for getSettings operation in SettingsApi. */
export type SettingsApiGetSettingsRes = SettingsFullListing;


/** Request parameters for setGlobalServiceSetting operation in SettingsApi. */
export type SettingsApiSetGlobalServiceSettingReq = {
    /** The type of setting being applied */
    settingKind: SettingKind
    /** A key for updating an existing setting, some known values are - hard_delete_after_secs - letsencrypt_enabled - org_websites_same_server - screenshot_driver_pool_size - screenshot_interval - sged_smtp - smtp_smart_host - website_backup - default_dns_ttl */
    settingKey: string
    serviceSettingValue: ServiceSettingValue
};

/** Response parameters for setGlobalServiceSetting operation in SettingsApi. */
export type SettingsApiSetGlobalServiceSettingRes = Outcome;


/** Request parameters for setOrchdLogSettings operation in SettingsApi. */
export type SettingsApiSetOrchdLogSettingsReq = {
    orchdLogSettings: OrchdLogSettings
};

/** Response parameters for setOrchdLogSettings operation in SettingsApi. */
export type SettingsApiSetOrchdLogSettingsRes = void;


/** Request parameters for setProhibitedDomains operation in SettingsApi. */
export type SettingsApiSetProhibitedDomainsReq = {
    body: string
};

/** Response parameters for setProhibitedDomains operation in SettingsApi. */
export type SettingsApiSetProhibitedDomainsRes = void;


/** Request parameters for updateBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiUpdateBackupRemoteStorageS3Req = {
    updateBackupRemoteStorageS3: UpdateBackupRemoteStorageS3
};

/** Response parameters for updateBackupRemoteStorageS3 operation in SettingsApi. */
export type SettingsApiUpdateBackupRemoteStorageS3Res = void;


/** Request parameters for updateSetting operation in SettingsApi. */
export type SettingsApiUpdateSettingReq = {
    /** The name of the resource. */
    name: string
    stringNumberBooleanArrayanyObject: string | number | boolean | Array<any> | object
};

/** Response parameters for updateSetting operation in SettingsApi. */
export type SettingsApiUpdateSettingRes = void;

/**
 * SettingsApi - service client interface
 */
export const SettingsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Set the orchd login policy email blacklist as a whole -  */
    addOrchdLoginPolicyEmailBlacklist: async (reqParams: SettingsApiAddOrchdLoginPolicyEmailBlacklistReq, options?: any): Promise<AxiosResponse<SettingsApiAddOrchdLoginPolicyEmailBlacklistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).addOrchdLoginPolicyEmailBlacklist(reqParams.orchdLoginPolicyEmailList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the orchd login policy email whitelist as a whole -  */
    addOrchdLoginPolicyEmailWhitelist: async (reqParams: SettingsApiAddOrchdLoginPolicyEmailWhitelistReq, options?: any): Promise<AxiosResponse<SettingsApiAddOrchdLoginPolicyEmailWhitelistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).addOrchdLoginPolicyEmailWhitelist(reqParams.orchdLoginPolicyEmailList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the orchd login policy ip blacklist as a whole -  */
    addOrchdLoginPolicyIpBlacklist: async (reqParams: SettingsApiAddOrchdLoginPolicyIpBlacklistReq, options?: any): Promise<AxiosResponse<SettingsApiAddOrchdLoginPolicyIpBlacklistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).addOrchdLoginPolicyIpBlacklist(reqParams.orchdLoginPolicyIpList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the orchd login policy ip whitelist as a whole -  */
    addOrchdLoginPolicyIpWhitelist: async (reqParams: SettingsApiAddOrchdLoginPolicyIpWhitelistReq, options?: any): Promise<AxiosResponse<SettingsApiAddOrchdLoginPolicyIpWhitelistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).addOrchdLoginPolicyIpWhitelist(reqParams.orchdLoginPolicyIpList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a single orchd login policy setting -  */
    addOrchdLoginPolicySettings: async (reqParams: SettingsApiAddOrchdLoginPolicySettingsReq, options?: any): Promise<AxiosResponse<SettingsApiAddOrchdLoginPolicySettingsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).addOrchdLoginPolicySettings(reqParams.orchdLoginPolicySettings, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create S3 object storage settings at platform level. -  */
    createBackupRemoteStorageS3: async (reqParams: SettingsApiCreateBackupRemoteStorageS3Req, options?: any): Promise<AxiosResponse<SettingsApiCreateBackupRemoteStorageS3Res>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).createBackupRemoteStorageS3(reqParams.createBackupRemoteStorageS3, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create settings -  */
    createSettings: async (reqParams: SettingsApiCreateSettingsReq, options?: any): Promise<AxiosResponse<SettingsApiCreateSettingsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).createSettings(reqParams.setting, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete S3 object storage settings at platform level. -  */
    deleteBackupRemoteStorageS3: async (reqParams: SettingsApiDeleteBackupRemoteStorageS3Req, options?: any): Promise<AxiosResponse<SettingsApiDeleteBackupRemoteStorageS3Res>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteBackupRemoteStorageS3(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a single global service setting - Delete a single global service setting value */
    deleteGlobalServiceSetting: async (reqParams: SettingsApiDeleteGlobalServiceSettingReq, options?: any): Promise<AxiosResponse<SettingsApiDeleteGlobalServiceSettingRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteGlobalServiceSetting(reqParams.settingKind, reqParams.settingKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete an orchd login policy email blacklist -  */
    deleteOrchdLoginPolicyEmailBlacklist: async (reqParams: SettingsApiDeleteOrchdLoginPolicyEmailBlacklistReq, options?: any): Promise<AxiosResponse<SettingsApiDeleteOrchdLoginPolicyEmailBlacklistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteOrchdLoginPolicyEmailBlacklist(reqParams.orchdLoginPolicyEmailList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete an orchd login policy email whitelist -  */
    deleteOrchdLoginPolicyEmailWhitelist: async (reqParams: SettingsApiDeleteOrchdLoginPolicyEmailWhitelistReq, options?: any): Promise<AxiosResponse<SettingsApiDeleteOrchdLoginPolicyEmailWhitelistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteOrchdLoginPolicyEmailWhitelist(reqParams.orchdLoginPolicyEmailList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete an orchd login policy ip blacklist -  */
    deleteOrchdLoginPolicyIpBlacklist: async (reqParams: SettingsApiDeleteOrchdLoginPolicyIpBlacklistReq, options?: any): Promise<AxiosResponse<SettingsApiDeleteOrchdLoginPolicyIpBlacklistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteOrchdLoginPolicyIpBlacklist(reqParams.orchdLoginPolicyIpList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete an orchd login policy ip whitelist -  */
    deleteOrchdLoginPolicyIpWhitelist: async (reqParams: SettingsApiDeleteOrchdLoginPolicyIpWhitelistReq, options?: any): Promise<AxiosResponse<SettingsApiDeleteOrchdLoginPolicyIpWhitelistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteOrchdLoginPolicyIpWhitelist(reqParams.orchdLoginPolicyIpList, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Remove the specified setting -  */
    deleteSetting: async (reqParams: SettingsApiDeleteSettingReq, options?: any): Promise<AxiosResponse<SettingsApiDeleteSettingRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).deleteSetting(reqParams.name, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get S3 object storage settings at platform level. -  */
    getBackupRemoteStorageS3: async (reqParams: SettingsApiGetBackupRemoteStorageS3Req, options?: any): Promise<AxiosResponse<SettingsApiGetBackupRemoteStorageS3Res>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getBackupRemoteStorageS3(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the demo mode status of the orchd service -  */
    getDemoMode: async (reqParams: SettingsApiGetDemoModeReq, options?: any): Promise<AxiosResponse<SettingsApiGetDemoModeRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getDemoMode(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the value for a particular global service setting - Returns the value for a particular global service setting as a JSON object */
    getGlobalServiceSetting: async (reqParams: SettingsApiGetGlobalServiceSettingReq, options?: any): Promise<AxiosResponse<SettingsApiGetGlobalServiceSettingRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getGlobalServiceSetting(reqParams.settingKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the orchd log settings -  */
    getOrchdLogSettings: async (reqParams: SettingsApiGetOrchdLogSettingsReq, options?: any): Promise<AxiosResponse<SettingsApiGetOrchdLogSettingsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getOrchdLogSettings(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the orchd login policy email blacklist -  */
    getOrchdLoginPolicyEmailBlacklist: async (reqParams: SettingsApiGetOrchdLoginPolicyEmailBlacklistReq, options?: any): Promise<AxiosResponse<SettingsApiGetOrchdLoginPolicyEmailBlacklistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getOrchdLoginPolicyEmailBlacklist(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the orchd login policy email whitelist -  */
    getOrchdLoginPolicyEmailWhitelist: async (reqParams: SettingsApiGetOrchdLoginPolicyEmailWhitelistReq, options?: any): Promise<AxiosResponse<SettingsApiGetOrchdLoginPolicyEmailWhitelistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getOrchdLoginPolicyEmailWhitelist(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the orchd login policy ip blacklist -  */
    getOrchdLoginPolicyIpBlacklist: async (reqParams: SettingsApiGetOrchdLoginPolicyIpBlacklistReq, options?: any): Promise<AxiosResponse<SettingsApiGetOrchdLoginPolicyIpBlacklistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getOrchdLoginPolicyIpBlacklist(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the orchd login policy ip whitelist -  */
    getOrchdLoginPolicyIpWhitelist: async (reqParams: SettingsApiGetOrchdLoginPolicyIpWhitelistReq, options?: any): Promise<AxiosResponse<SettingsApiGetOrchdLoginPolicyIpWhitelistRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getOrchdLoginPolicyIpWhitelist(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the orchd login policy settings -  */
    getOrchdLoginPolicySettings: async (reqParams: SettingsApiGetOrchdLoginPolicySettingsReq, options?: any): Promise<AxiosResponse<SettingsApiGetOrchdLoginPolicySettingsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getOrchdLoginPolicySettings(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the platform level prohibited domains as a newline separated list -  */
    getProhibitedDomains: async (reqParams: SettingsApiGetProhibitedDomainsReq, options?: any): Promise<AxiosResponse<SettingsApiGetProhibitedDomainsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getProhibitedDomains(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the specified setting -  */
    getSetting: async (reqParams: SettingsApiGetSettingReq, options?: any): Promise<AxiosResponse<SettingsApiGetSettingRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getSetting(reqParams.name, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get all current settings -  */
    getSettings: async (reqParams: SettingsApiGetSettingsReq, options?: any): Promise<AxiosResponse<SettingsApiGetSettingsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getSettings(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a single global service setting - Set or replace a single global service setting */
    setGlobalServiceSetting: async (reqParams: SettingsApiSetGlobalServiceSettingReq, options?: any): Promise<AxiosResponse<SettingsApiSetGlobalServiceSettingRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).setGlobalServiceSetting(reqParams.settingKind, reqParams.settingKey, reqParams.serviceSettingValue, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the orchd log settings -  */
    setOrchdLogSettings: async (reqParams: SettingsApiSetOrchdLogSettingsReq, options?: any): Promise<AxiosResponse<SettingsApiSetOrchdLogSettingsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).setOrchdLogSettings(reqParams.orchdLogSettings, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the platform level prohibited domains -  */
    setProhibitedDomains: async (reqParams: SettingsApiSetProhibitedDomainsReq, options?: any): Promise<AxiosResponse<SettingsApiSetProhibitedDomainsRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).setProhibitedDomains(reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update S3 object storage settings at platform level. -  */
    updateBackupRemoteStorageS3: async (reqParams: SettingsApiUpdateBackupRemoteStorageS3Req, options?: any): Promise<AxiosResponse<SettingsApiUpdateBackupRemoteStorageS3Res>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateBackupRemoteStorageS3(reqParams.updateBackupRemoteStorageS3, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create or update the specified setting -  */
    updateSetting: async (reqParams: SettingsApiUpdateSettingReq, options?: any): Promise<AxiosResponse<SettingsApiUpdateSettingRes>> => {
        const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateSetting(reqParams.name, reqParams.stringNumberBooleanArrayanyObject, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface SettingsApiReq {
    addOrchdLoginPolicyEmailBlacklist: SettingsApiAddOrchdLoginPolicyEmailBlacklistReq;
    addOrchdLoginPolicyEmailWhitelist: SettingsApiAddOrchdLoginPolicyEmailWhitelistReq;
    addOrchdLoginPolicyIpBlacklist: SettingsApiAddOrchdLoginPolicyIpBlacklistReq;
    addOrchdLoginPolicyIpWhitelist: SettingsApiAddOrchdLoginPolicyIpWhitelistReq;
    addOrchdLoginPolicySettings: SettingsApiAddOrchdLoginPolicySettingsReq;
    createBackupRemoteStorageS3: SettingsApiCreateBackupRemoteStorageS3Req;
    createSettings: SettingsApiCreateSettingsReq;
    deleteBackupRemoteStorageS3: SettingsApiDeleteBackupRemoteStorageS3Req;
    deleteGlobalServiceSetting: SettingsApiDeleteGlobalServiceSettingReq;
    deleteOrchdLoginPolicyEmailBlacklist: SettingsApiDeleteOrchdLoginPolicyEmailBlacklistReq;
    deleteOrchdLoginPolicyEmailWhitelist: SettingsApiDeleteOrchdLoginPolicyEmailWhitelistReq;
    deleteOrchdLoginPolicyIpBlacklist: SettingsApiDeleteOrchdLoginPolicyIpBlacklistReq;
    deleteOrchdLoginPolicyIpWhitelist: SettingsApiDeleteOrchdLoginPolicyIpWhitelistReq;
    deleteSetting: SettingsApiDeleteSettingReq;
    getBackupRemoteStorageS3: SettingsApiGetBackupRemoteStorageS3Req;
    getDemoMode: SettingsApiGetDemoModeReq;
    getGlobalServiceSetting: SettingsApiGetGlobalServiceSettingReq;
    getOrchdLogSettings: SettingsApiGetOrchdLogSettingsReq;
    getOrchdLoginPolicyEmailBlacklist: SettingsApiGetOrchdLoginPolicyEmailBlacklistReq;
    getOrchdLoginPolicyEmailWhitelist: SettingsApiGetOrchdLoginPolicyEmailWhitelistReq;
    getOrchdLoginPolicyIpBlacklist: SettingsApiGetOrchdLoginPolicyIpBlacklistReq;
    getOrchdLoginPolicyIpWhitelist: SettingsApiGetOrchdLoginPolicyIpWhitelistReq;
    getOrchdLoginPolicySettings: SettingsApiGetOrchdLoginPolicySettingsReq;
    getProhibitedDomains: SettingsApiGetProhibitedDomainsReq;
    getSetting: SettingsApiGetSettingReq;
    getSettings: SettingsApiGetSettingsReq;
    setGlobalServiceSetting: SettingsApiSetGlobalServiceSettingReq;
    setOrchdLogSettings: SettingsApiSetOrchdLogSettingsReq;
    setProhibitedDomains: SettingsApiSetProhibitedDomainsReq;
    updateBackupRemoteStorageS3: SettingsApiUpdateBackupRemoteStorageS3Req;
    updateSetting: SettingsApiUpdateSettingReq;
}

export interface SettingsApiRes {
    addOrchdLoginPolicyEmailBlacklist: SettingsApiAddOrchdLoginPolicyEmailBlacklistRes;
    addOrchdLoginPolicyEmailWhitelist: SettingsApiAddOrchdLoginPolicyEmailWhitelistRes;
    addOrchdLoginPolicyIpBlacklist: SettingsApiAddOrchdLoginPolicyIpBlacklistRes;
    addOrchdLoginPolicyIpWhitelist: SettingsApiAddOrchdLoginPolicyIpWhitelistRes;
    addOrchdLoginPolicySettings: SettingsApiAddOrchdLoginPolicySettingsRes;
    createBackupRemoteStorageS3: SettingsApiCreateBackupRemoteStorageS3Res;
    createSettings: SettingsApiCreateSettingsRes;
    deleteBackupRemoteStorageS3: SettingsApiDeleteBackupRemoteStorageS3Res;
    deleteGlobalServiceSetting: SettingsApiDeleteGlobalServiceSettingRes;
    deleteOrchdLoginPolicyEmailBlacklist: SettingsApiDeleteOrchdLoginPolicyEmailBlacklistRes;
    deleteOrchdLoginPolicyEmailWhitelist: SettingsApiDeleteOrchdLoginPolicyEmailWhitelistRes;
    deleteOrchdLoginPolicyIpBlacklist: SettingsApiDeleteOrchdLoginPolicyIpBlacklistRes;
    deleteOrchdLoginPolicyIpWhitelist: SettingsApiDeleteOrchdLoginPolicyIpWhitelistRes;
    deleteSetting: SettingsApiDeleteSettingRes;
    getBackupRemoteStorageS3: SettingsApiGetBackupRemoteStorageS3Res;
    getDemoMode: SettingsApiGetDemoModeRes;
    getGlobalServiceSetting: SettingsApiGetGlobalServiceSettingRes;
    getOrchdLogSettings: SettingsApiGetOrchdLogSettingsRes;
    getOrchdLoginPolicyEmailBlacklist: SettingsApiGetOrchdLoginPolicyEmailBlacklistRes;
    getOrchdLoginPolicyEmailWhitelist: SettingsApiGetOrchdLoginPolicyEmailWhitelistRes;
    getOrchdLoginPolicyIpBlacklist: SettingsApiGetOrchdLoginPolicyIpBlacklistRes;
    getOrchdLoginPolicyIpWhitelist: SettingsApiGetOrchdLoginPolicyIpWhitelistRes;
    getOrchdLoginPolicySettings: SettingsApiGetOrchdLoginPolicySettingsRes;
    getProhibitedDomains: SettingsApiGetProhibitedDomainsRes;
    getSetting: SettingsApiGetSettingRes;
    getSettings: SettingsApiGetSettingsRes;
    setGlobalServiceSetting: SettingsApiSetGlobalServiceSettingRes;
    setOrchdLogSettings: SettingsApiSetOrchdLogSettingsRes;
    setProhibitedDomains: SettingsApiSetProhibitedDomainsRes;
    updateBackupRemoteStorageS3: SettingsApiUpdateBackupRemoteStorageS3Res;
    updateSetting: SettingsApiUpdateSettingRes;
}


/**
 * SslApi - axios parameter creator
 * @export
 */
export const SslApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Returns the SSL for this website domain - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
        getWebsiteDomainSslCert: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns the SSL for this website domain with the mail.prefix - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
        getWebsiteMailDomainSslCert: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/mail_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set \"force ssl\" status for domain mapping -  */
        setWebsiteDomainForceSsl: async (domainId: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl/force_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload custom ssl certificate, key and optional fullchain for a given website - Endpoint for uploading custom SSL certificate for a given website. Verifies the cert key and maps to relevant domains that the certificate can be applied to. Returns error if no domain match is found. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        uploadWebsiteDomainSslCert: async (domainId: string, sslCert: SslCert, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sslCert !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sslCert !== undefined ? sslCert : {}) : (sslCert || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload SSL for mail.customerdomain. -  */
        uploadWebsiteMailDomainSslCert: async (domainId: string, sslCert: SslCert, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/mail_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sslCert !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sslCert !== undefined ? sslCert : {}) : (sslCert || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for getWebsiteDomainSslCert operation in SslApi. */
export type SslApiGetWebsiteDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainSslCert operation in SslApi. */
export type SslApiGetWebsiteDomainSslCertRes = DomainSslCertWithData;


/** Request parameters for getWebsiteMailDomainSslCert operation in SslApi. */
export type SslApiGetWebsiteMailDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteMailDomainSslCert operation in SslApi. */
export type SslApiGetWebsiteMailDomainSslCertRes = DomainSslCertWithData;


/** Request parameters for setWebsiteDomainForceSsl operation in SslApi. */
export type SslApiSetWebsiteDomainForceSslReq = {
    /** The id of the domain. */
    domainId: string
    /** Boolean \"force ssl\" setting */
    body: boolean
};

/** Response parameters for setWebsiteDomainForceSsl operation in SslApi. */
export type SslApiSetWebsiteDomainForceSslRes = void;


/** Request parameters for uploadWebsiteDomainSslCert operation in SslApi. */
export type SslApiUploadWebsiteDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
    /** Cert, private key and optional fullchain. */
    sslCert: SslCert
};

/** Response parameters for uploadWebsiteDomainSslCert operation in SslApi. */
export type SslApiUploadWebsiteDomainSslCertRes = NewSslCert;


/** Request parameters for uploadWebsiteMailDomainSslCert operation in SslApi. */
export type SslApiUploadWebsiteMailDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
    /** Cert, private key and optional fullchain. */
    sslCert: SslCert
};

/** Response parameters for uploadWebsiteMailDomainSslCert operation in SslApi. */
export type SslApiUploadWebsiteMailDomainSslCertRes = NewSslCert;

/**
 * SslApi - service client interface
 */
export const SslApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Returns the SSL for this website domain - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
    getWebsiteDomainSslCert: async (reqParams: SslApiGetWebsiteDomainSslCertReq, options?: any): Promise<AxiosResponse<SslApiGetWebsiteDomainSslCertRes>> => {
        const localVarAxiosArgs = await SslApiAxiosParamCreator(configuration).getWebsiteDomainSslCert(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns the SSL for this website domain with the mail.prefix - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
    getWebsiteMailDomainSslCert: async (reqParams: SslApiGetWebsiteMailDomainSslCertReq, options?: any): Promise<AxiosResponse<SslApiGetWebsiteMailDomainSslCertRes>> => {
        const localVarAxiosArgs = await SslApiAxiosParamCreator(configuration).getWebsiteMailDomainSslCert(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set \"force ssl\" status for domain mapping -  */
    setWebsiteDomainForceSsl: async (reqParams: SslApiSetWebsiteDomainForceSslReq, options?: any): Promise<AxiosResponse<SslApiSetWebsiteDomainForceSslRes>> => {
        const localVarAxiosArgs = await SslApiAxiosParamCreator(configuration).setWebsiteDomainForceSsl(reqParams.domainId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload custom ssl certificate, key and optional fullchain for a given website - Endpoint for uploading custom SSL certificate for a given website. Verifies the cert key and maps to relevant domains that the certificate can be applied to. Returns error if no domain match is found. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    uploadWebsiteDomainSslCert: async (reqParams: SslApiUploadWebsiteDomainSslCertReq, options?: any): Promise<AxiosResponse<SslApiUploadWebsiteDomainSslCertRes>> => {
        const localVarAxiosArgs = await SslApiAxiosParamCreator(configuration).uploadWebsiteDomainSslCert(reqParams.domainId, reqParams.sslCert, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload SSL for mail.customerdomain. -  */
    uploadWebsiteMailDomainSslCert: async (reqParams: SslApiUploadWebsiteMailDomainSslCertReq, options?: any): Promise<AxiosResponse<SslApiUploadWebsiteMailDomainSslCertRes>> => {
        const localVarAxiosArgs = await SslApiAxiosParamCreator(configuration).uploadWebsiteMailDomainSslCert(reqParams.domainId, reqParams.sslCert, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface SslApiReq {
    getWebsiteDomainSslCert: SslApiGetWebsiteDomainSslCertReq;
    getWebsiteMailDomainSslCert: SslApiGetWebsiteMailDomainSslCertReq;
    setWebsiteDomainForceSsl: SslApiSetWebsiteDomainForceSslReq;
    uploadWebsiteDomainSslCert: SslApiUploadWebsiteDomainSslCertReq;
    uploadWebsiteMailDomainSslCert: SslApiUploadWebsiteMailDomainSslCertReq;
}

export interface SslApiRes {
    getWebsiteDomainSslCert: SslApiGetWebsiteDomainSslCertRes;
    getWebsiteMailDomainSslCert: SslApiGetWebsiteMailDomainSslCertRes;
    setWebsiteDomainForceSsl: SslApiSetWebsiteDomainForceSslRes;
    uploadWebsiteDomainSslCert: SslApiUploadWebsiteDomainSslCertRes;
    uploadWebsiteMailDomainSslCert: SslApiUploadWebsiteMailDomainSslCertRes;
}


/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Re-Calculates all subscription resources - Recursively Re-Calculates organization\'s subscription resources. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        calculateResourceUsage: async (orgId: string, subscriptionId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions/{subscription_id}/calculate-resource-usage`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a subscriptions for a customer - Creates a subscription for customer to the org\'s plan. Only a reseller org or the MO may subscribe another org to one of its plans. If the organization is a reseller (and thus not the MO), it needs to have a suitable subscription to a reseller plan of its parent. It is verified that the reseller\'s reseller subscription has quota left to create the new subscription (because the new subscription draws from the reseller\'s reseller subscription). After this call, the sold resources are recorded in the reseller subscription by increasing each sold resource\'s usage by the sold amount. In combination with the quota check, this is how it is ensured that the reseller doesn\'t sell more resources than it has paid for. The MO may optionally override the package default servers or server group. All resources of this subscription will then be created on those servers. Otherwise the subscribed to plan\'s servers are used, or if those aren\'t defined either, the usual website placement rules are used every time a website is created under this subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createCustomerSubscription: async (orgId: string, customerOrgId: string, newSubscription: NewSubscription, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers/{customer_org_id}/subscriptions`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"customer_org_id"}}`, encodeURIComponent(String(customerOrgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newSubscription !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newSubscription !== undefined ? newSubscription : {}) : (newSubscription || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete subscription - Soft or force deletes the subscription and its resources. All resources under the subscription (websites, customers in case of a reseller) will be deleted too. If the subscription is soft-deleted (or marked as deleted), its data is not removed.  For removing all data and metadata, pass the `force=true` query parameter. This can only be done by a privileged MO member. In this case, all data is wiped, so use carefully. If the `force` parameter is set, session holder must be an MO Owner, SuperAdmin, or Support member, otherwise it suffices for them to be such a member in a parent org. */
        deleteSubscription: async (orgId: string, subscriptionId: number, force?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions/{subscription_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get customer subscriptions - Lists a customer\'s subscriptions to packages belonging to the selected organization. */
        getCustomerSubscriptions: async (orgId: string, customerOrgId: string, offset?: number, limit?: number, sortBy?: 'plan' | 'subscriberId' | 'vendorId', sortOrder?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/customers/{customer_org_id}/subscriptions`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"customer_org_id"}}`, encodeURIComponent(String(customerOrgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get subscription - Queries the organization\'s subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getSubscription: async (orgId: string, subscriptionId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions/{subscription_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get subscription bandwidth - Queries the organization\'s subscription bandwidth for the current month. This includes all customer subscriptions if this subscription is a reseller. By default the usage is cached for 12 hours unless `refreshCache` is `true`. The value is in bytes. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getSubscriptionBandwidthUsage: async (orgId: string, subscriptionId: number, refreshCache?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions/{subscription_id}/bandwidth`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (refreshCache !== undefined) {
                localVarQueryParameter['refreshCache'] = refreshCache;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get subscriptions to parent - Lists subscriptions to the packages of the parent organization to which the currently selected organization is subscribed. */
        getSubscriptionsToParent: async (orgId: string, offset?: number, limit?: number, sortBy?: 'plan' | 'subscriberId' | 'vendorId', sortOrder?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update subscription - Updates the organization\'s subscription. This endpoint is used to update the subscription\'s status and suspension, by a parent organization admin. */
        updateSubscription: async (orgId: string, subscriptionId: number, updateSubscription: UpdateSubscription, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/subscriptions/{subscription_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"subscription_id"}}`, encodeURIComponent(String(subscriptionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateSubscription !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateSubscription !== undefined ? updateSubscription : {}) : (updateSubscription || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for calculateResourceUsage operation in SubscriptionsApi. */
export type SubscriptionsApiCalculateResourceUsageReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the subscription. */
    subscriptionId: number
};

/** Response parameters for calculateResourceUsage operation in SubscriptionsApi. */
export type SubscriptionsApiCalculateResourceUsageRes = UsedResourcesFullListing;


/** Request parameters for createCustomerSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiCreateCustomerSubscriptionReq = {
    /** The id of the organization. */
    orgId: string
    /** The organization id of the organization\'s customer. */
    customerOrgId: string
    /** Subscription details. */
    newSubscription: NewSubscription
};

/** Response parameters for createCustomerSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiCreateCustomerSubscriptionRes = NewResourceId;


/** Request parameters for deleteSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiDeleteSubscriptionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the subscription. */
    subscriptionId: number
    force?: boolean
};

/** Response parameters for deleteSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiDeleteSubscriptionRes = void;


/** Request parameters for getCustomerSubscriptions operation in SubscriptionsApi. */
export type SubscriptionsApiGetCustomerSubscriptionsReq = {
    /** The id of the organization. */
    orgId: string
    /** The organization id of the organization\'s customer. */
    customerOrgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'plan' | 'subscriberId' | 'vendorId'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
};

/** Response parameters for getCustomerSubscriptions operation in SubscriptionsApi. */
export type SubscriptionsApiGetCustomerSubscriptionsRes = SubscriptionsListing;


/** Request parameters for getSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiGetSubscriptionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the subscription. */
    subscriptionId: number
};

/** Response parameters for getSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiGetSubscriptionRes = Subscription;


/** Request parameters for getSubscriptionBandwidthUsage operation in SubscriptionsApi. */
export type SubscriptionsApiGetSubscriptionBandwidthUsageReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the subscription. */
    subscriptionId: number
    /** If set to true, it will bypass internal caching. */
    refreshCache?: boolean
};

/** Response parameters for getSubscriptionBandwidthUsage operation in SubscriptionsApi. */
export type SubscriptionsApiGetSubscriptionBandwidthUsageRes = number;


/** Request parameters for getSubscriptionsToParent operation in SubscriptionsApi. */
export type SubscriptionsApiGetSubscriptionsToParentReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'plan' | 'subscriberId' | 'vendorId'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
};

/** Response parameters for getSubscriptionsToParent operation in SubscriptionsApi. */
export type SubscriptionsApiGetSubscriptionsToParentRes = SubscriptionsListing;


/** Request parameters for updateSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiUpdateSubscriptionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the subscription. */
    subscriptionId: number
    updateSubscription: UpdateSubscription
};

/** Response parameters for updateSubscription operation in SubscriptionsApi. */
export type SubscriptionsApiUpdateSubscriptionRes = void;

/**
 * SubscriptionsApi - service client interface
 */
export const SubscriptionsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Re-Calculates all subscription resources - Recursively Re-Calculates organization\'s subscription resources. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    calculateResourceUsage: async (reqParams: SubscriptionsApiCalculateResourceUsageReq, options?: any): Promise<AxiosResponse<SubscriptionsApiCalculateResourceUsageRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).calculateResourceUsage(reqParams.orgId, reqParams.subscriptionId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a subscriptions for a customer - Creates a subscription for customer to the org\'s plan. Only a reseller org or the MO may subscribe another org to one of its plans. If the organization is a reseller (and thus not the MO), it needs to have a suitable subscription to a reseller plan of its parent. It is verified that the reseller\'s reseller subscription has quota left to create the new subscription (because the new subscription draws from the reseller\'s reseller subscription). After this call, the sold resources are recorded in the reseller subscription by increasing each sold resource\'s usage by the sold amount. In combination with the quota check, this is how it is ensured that the reseller doesn\'t sell more resources than it has paid for. The MO may optionally override the package default servers or server group. All resources of this subscription will then be created on those servers. Otherwise the subscribed to plan\'s servers are used, or if those aren\'t defined either, the usual website placement rules are used every time a website is created under this subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createCustomerSubscription: async (reqParams: SubscriptionsApiCreateCustomerSubscriptionReq, options?: any): Promise<AxiosResponse<SubscriptionsApiCreateCustomerSubscriptionRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).createCustomerSubscription(reqParams.orgId, reqParams.customerOrgId, reqParams.newSubscription, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete subscription - Soft or force deletes the subscription and its resources. All resources under the subscription (websites, customers in case of a reseller) will be deleted too. If the subscription is soft-deleted (or marked as deleted), its data is not removed.  For removing all data and metadata, pass the `force=true` query parameter. This can only be done by a privileged MO member. In this case, all data is wiped, so use carefully. If the `force` parameter is set, session holder must be an MO Owner, SuperAdmin, or Support member, otherwise it suffices for them to be such a member in a parent org. */
    deleteSubscription: async (reqParams: SubscriptionsApiDeleteSubscriptionReq, options?: any): Promise<AxiosResponse<SubscriptionsApiDeleteSubscriptionRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).deleteSubscription(reqParams.orgId, reqParams.subscriptionId, reqParams.force, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get customer subscriptions - Lists a customer\'s subscriptions to packages belonging to the selected organization. */
    getCustomerSubscriptions: async (reqParams: SubscriptionsApiGetCustomerSubscriptionsReq, options?: any): Promise<AxiosResponse<SubscriptionsApiGetCustomerSubscriptionsRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getCustomerSubscriptions(reqParams.orgId, reqParams.customerOrgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get subscription - Queries the organization\'s subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getSubscription: async (reqParams: SubscriptionsApiGetSubscriptionReq, options?: any): Promise<AxiosResponse<SubscriptionsApiGetSubscriptionRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getSubscription(reqParams.orgId, reqParams.subscriptionId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get subscription bandwidth - Queries the organization\'s subscription bandwidth for the current month. This includes all customer subscriptions if this subscription is a reseller. By default the usage is cached for 12 hours unless `refreshCache` is `true`. The value is in bytes. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getSubscriptionBandwidthUsage: async (reqParams: SubscriptionsApiGetSubscriptionBandwidthUsageReq, options?: any): Promise<AxiosResponse<SubscriptionsApiGetSubscriptionBandwidthUsageRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getSubscriptionBandwidthUsage(reqParams.orgId, reqParams.subscriptionId, reqParams.refreshCache, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get subscriptions to parent - Lists subscriptions to the packages of the parent organization to which the currently selected organization is subscribed. */
    getSubscriptionsToParent: async (reqParams: SubscriptionsApiGetSubscriptionsToParentReq, options?: any): Promise<AxiosResponse<SubscriptionsApiGetSubscriptionsToParentRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).getSubscriptionsToParent(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update subscription - Updates the organization\'s subscription. This endpoint is used to update the subscription\'s status and suspension, by a parent organization admin. */
    updateSubscription: async (reqParams: SubscriptionsApiUpdateSubscriptionReq, options?: any): Promise<AxiosResponse<SubscriptionsApiUpdateSubscriptionRes>> => {
        const localVarAxiosArgs = await SubscriptionsApiAxiosParamCreator(configuration).updateSubscription(reqParams.orgId, reqParams.subscriptionId, reqParams.updateSubscription, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface SubscriptionsApiReq {
    calculateResourceUsage: SubscriptionsApiCalculateResourceUsageReq;
    createCustomerSubscription: SubscriptionsApiCreateCustomerSubscriptionReq;
    deleteSubscription: SubscriptionsApiDeleteSubscriptionReq;
    getCustomerSubscriptions: SubscriptionsApiGetCustomerSubscriptionsReq;
    getSubscription: SubscriptionsApiGetSubscriptionReq;
    getSubscriptionBandwidthUsage: SubscriptionsApiGetSubscriptionBandwidthUsageReq;
    getSubscriptionsToParent: SubscriptionsApiGetSubscriptionsToParentReq;
    updateSubscription: SubscriptionsApiUpdateSubscriptionReq;
}

export interface SubscriptionsApiRes {
    calculateResourceUsage: SubscriptionsApiCalculateResourceUsageRes;
    createCustomerSubscription: SubscriptionsApiCreateCustomerSubscriptionRes;
    deleteSubscription: SubscriptionsApiDeleteSubscriptionRes;
    getCustomerSubscriptions: SubscriptionsApiGetCustomerSubscriptionsRes;
    getSubscription: SubscriptionsApiGetSubscriptionRes;
    getSubscriptionBandwidthUsage: SubscriptionsApiGetSubscriptionBandwidthUsageRes;
    getSubscriptionsToParent: SubscriptionsApiGetSubscriptionsToParentRes;
    updateSubscription: SubscriptionsApiUpdateSubscriptionRes;
}


/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Create tag - Creates a new tag for the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createTag: async (orgId: string, newTag: NewTag, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/tags`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newTag !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newTag !== undefined ? newTag : {}) : (newTag || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get tags - Returns all tags belonging to the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getTags: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/tags`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for createTag operation in TagsApi. */
export type TagsApiCreateTagReq = {
    /** The id of the organization. */
    orgId: string
    /** New tag details */
    newTag: NewTag
};

/** Response parameters for createTag operation in TagsApi. */
export type TagsApiCreateTagRes = NewResourceId;


/** Request parameters for getTags operation in TagsApi. */
export type TagsApiGetTagsReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getTags operation in TagsApi. */
export type TagsApiGetTagsRes = TagsFullListing;

/**
 * TagsApi - service client interface
 */
export const TagsApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Create tag - Creates a new tag for the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createTag: async (reqParams: TagsApiCreateTagReq, options?: any): Promise<AxiosResponse<TagsApiCreateTagRes>> => {
        const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).createTag(reqParams.orgId, reqParams.newTag, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get tags - Returns all tags belonging to the organization. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getTags: async (reqParams: TagsApiGetTagsReq, options?: any): Promise<AxiosResponse<TagsApiGetTagsRes>> => {
        const localVarAxiosArgs = await TagsApiAxiosParamCreator(configuration).getTags(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface TagsApiReq {
    createTag: TagsApiCreateTagReq;
    getTags: TagsApiGetTagsReq;
}

export interface TagsApiRes {
    createTag: TagsApiCreateTagRes;
    getTags: TagsApiGetTagsRes;
}


/**
 * WebsitesApi - axios parameter creator
 * @export
 */
export const WebsitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Add Nginx FastCGI excluded path -  */
        addDomainNginxFastCgiExcludedPath: async (domainId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/nginx_fastcgi_excluded_paths`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Authorize a new SSH key. - This operation will authorize the given public SSH key by appending its content to the website\'s .ssh/authorized_keys file. */
        authorizeWebsiteSshKey: async (orgId: string, websiteId: string, newSshKey: NewSshKey, sanitize?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ssh/keys`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (sanitize !== undefined) {
                localVarQueryParameter['sanitize'] = sanitize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newSshKey !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newSshKey !== undefined ? newSshKey : {}) : (newSshKey || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Authorize a new SSH password for website. - This operation will authorize the given SSH password for the website\'s unix user. this request will replace the existing password for the user if already set. */
        authorizeWebsiteSshPassword: async (orgId: string, websiteId: string, newPassword: NewPassword, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ssh/password`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPassword !== undefined ? newPassword : {}) : (newPassword || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Clear FastCGI cache for domain -  */
        clearDomainNginxFastCgi: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/nginx_fastcgi`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Clone website or push live - Clone a website or push live to overwrite an existing live website with content from the source website. Operation is asynchronous and returns a clone id. If includeDatabases is ommited, the all databases will be cloned from the source website. If includeDatabaseUsers is ommited, the all database users will be cloned from the source website. */
        cloneWebsite: async (orgId: string, websiteCloneRequest: WebsiteCloneRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/clone`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof websiteCloneRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(websiteCloneRequest !== undefined ? websiteCloneRequest : {}) : (websiteCloneRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Creates a new FTP user for a given website - Endpoint for creating a new FTP user. NOTE: user.account well get appended with website\'s primary domain. i.e. `username` will become `username@domain.com` Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createFtpUser: async (orgId: string, websiteId: string, newFtpUser: NewFtpUser, createHome?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (createHome !== undefined) {
                localVarQueryParameter['createHome'] = createHome;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newFtpUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newFtpUser !== undefined ? newFtpUser : {}) : (newFtpUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a preview domain - Creates a preview domain for the given website and returns its full domain.  If a preview domain already exists, returns that instead.  Will error if a preview domain cannot be created. Returns 200 if an existing preview domain is returned and 201 if one has been created. */
        createPreviewDomain: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/preview`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a new website or clone an existing one. - Creates or clone a website under the organization. If the org is the MO, the request need not contain a subscription ID, but if it\'s a customer and the session holder is not an MO admin, the subscription to which to attach the website must be supplied. If the website to be created is \'staging\' kind then the subscription must include stagingWebsites resource > 1.  If \'normal\' then the subscription must include websites > 1. If the website to be created is a control panel, the reseller\'s subscription id must match the reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        createWebsite: async (orgId: string, newWebsite: NewWebsite, kind?: WebsiteKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newWebsite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newWebsite !== undefined ? newWebsite : {}) : (newWebsite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Generate and setup letsencrypt ssl certificates for website\'s domain - Generates letsencrypt certificates for the domain. This is a longer running task, that will do a complete ssl setup for a given domain. Once completed any given domain will get served over `https`. Given domain must be publicly accessible and being served from our service. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteDomainLetsencryptCerts: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Generate and setup letsencrypt ssl certificates for website\'s domain with mail. prefix. - Generates letsencrypt certificate for the mail server at mail.customerdomain. */
        createWebsiteMailDomainLetsencryptCerts: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt_mail`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create website mapped domain - Creates a domain mapping, where subscription resources are sufficient. The mapping kind will default to \'alias\' if unspecified. */
        createWebsiteMappedDomain: async (orgId: string, websiteId: string, newMappedDomain: NewMappedDomain, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMappedDomain !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMappedDomain !== undefined ? newMappedDomain : {}) : (newMappedDomain || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create a MySQL database for website - Creates a new MySQL database for the given website. The supplied name must conform to the following regular expression: `^[0-9a-z$_]+$`. That is, a name may only contain alphanumerical characters, dollar signs, and underscores. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWebsiteMySQLDB: async (orgId: string, websiteId: string, newMySQLDB: NewMySQLDB, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newMySQLDB !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newMySQLDB !== undefined ? newMySQLDB : {}) : (newMySQLDB || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete Nginx FastCGI excluded path -  */
        deleteDomainNginxFastCgiExcludedPath: async (domainId: string, path: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/nginx_fastcgi_excluded_paths`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete web server rewrite -  */
        deleteDomainWebserverRewrite: async (domainId: string, path: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/webserver_rewrites`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes given FTP user - Endpoint for deleting FTP user for a given website. User homeDir can only be deleted if it is a subdir for the website home. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteFtpUser: async (orgId: string, websiteId: string, username: string, deleteHome?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users/{username}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (deleteHome !== undefined) {
                localVarQueryParameter['deleteHome'] = deleteHome;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete user\'s crontab - Delete user\'s crontab. Remove crontab file from disk. */
        deleteUserCrontab: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/crontab`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website - Mark a website as deleted, which does not remove its data, or force remove all its data. For removing all data and metadata belonging to a website (including DB records), pass the `force=true` query parameter. This can only be done by a privileged MO member. In this case, all data is wiped, so use carefully. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        deleteWebsite: async (orgId: string, websiteId: string, force?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website domain mapping - Deletes the domain mapping and decrements the domain alias quota usage in the website\'s subscription, if applicable. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWebsiteDomainMapping: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Deletes domain\'s custom vhost file if any -  */
        deleteWebsiteDomainVhost: async (domainId: string, inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/vhost`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {}) : (inlineObject1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a single override setting - Delete a single website level service setting */
        deleteWebsiteSetting: async (orgId: string, websiteId: string, settingKind: SettingKind, settingKey: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/settings/{setting_kind}/{setting_key}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)))
                .replace(`{${"setting_key"}}`, encodeURIComponent(String(settingKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete websites - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
        deleteWebsites: async (orgId: string, uuidListing: UuidListing, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof uuidListing !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(uuidListing !== undefined ? uuidListing : {}) : (uuidListing || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Disable a PHP extension -  */
        disableWebsitePhpExtension: async (websiteId: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/php_extensions`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Enable a PHP extension -  */
        enableWebsitePhpExtension: async (websiteId: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/php_extensions`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get status of Nginx FastCGI enablement -  */
        getDomainNginxFastCgi: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/nginx_fastcgi`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get Nginx FastCGI excluded paths -  */
        getDomainNginxFastCgiExcludedPaths: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/nginx_fastcgi_excluded_paths`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get web server rewrites for specified domain -  */
        getDomainWebserverRewrites: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/webserver_rewrites`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns all ftp users data for a given website - Endpoint for retreaving ftp users for a given website Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getFtpUsers: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get last screeshot file\'s Timestamp - Returns Unix Timestamp for the last screenshot png file, if no screenshot found returns undefined */
        getScreenshotTimestamp: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/screenshot/timestamp`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get an access token for the given website - Request an access token for the given website. Note that access tokens may only be requested for normal websites, not for control panel websites. The access token is returned as an encrypted JWT in the response body. Session holder must either be a parent organization admin, or be a member with Site Access role of the given organization. */
        getSiteAccessToken: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/access-tokens`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get user\'s crontab - Get user\'s crontab. Only editable parts of crontab is returned. */
        getUserCrontab: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/crontab`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website - Returns detailed information about a single website. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        getWebsite: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get available PHP extensions for a website -  */
        getWebsiteAvailablePhpExtensions: async (websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/available_php_extensions`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the status of an ongoing website backup operation - Returns the status of the currently ongoing backup or restore, if any. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteBackupStatus: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/status/backup`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the active cgroup limits for a website -  */
        getWebsiteCgroupLimits: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/cgroup_limits`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get\'s detail about a single push live - Fetches details about a single website clone. cloneWebsite call operation just enqueues the request and returns immediately. Use this endpoint to monitor how the clone finishes. */
        getWebsiteClone: async (orgId: string, cloneId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/clone/{clone_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clone_id"}}`, encodeURIComponent(String(cloneId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the log for a given clone id.. - Fetches the import migration log for a single website clone. */
        getWebsiteCloneLog: async (orgId: string, cloneId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/clone/{clone_id}/log`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clone_id"}}`, encodeURIComponent(String(cloneId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** List website clones for given OrgId - List of all webiste clones for the given OrgId. */
        getWebsiteClones: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/clone`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Recursively query Dns servers for given domain - Returns details about the dns servers of given domain. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteDomainDnsQuery: async (orgId: string, websiteId: string, domainId: string, resolveDepth?: 'short' | 'detailed' | 'queryAllTldNs', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-query`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (resolveDepth !== undefined) {
                localVarQueryParameter['resolveDepth'] = resolveDepth;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns website domain mapping - Returns a domain by its id that is mapped to this website. Requires login to have admin privileges in this org. Since only the MO can create standalone domains, session holder must be at least a `SuperAdmin` in the MO. */
        getWebsiteDomainMapping: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns website domain mapping DNS status - Returns an indication of whether the domain correctly resolves to the server this website is on. */
        getWebsiteDomainMappingDnsStatus: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/dns-status`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website\'s mapped domains - Returns a list of domains that are mapped to this website. Requires login to have admin privileges in this org. */
        getWebsiteDomainMappings: async (orgId: string, websiteId: string, withSsl?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (withSsl !== undefined) {
                localVarQueryParameter['withSsl'] = withSsl;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get mod security status for a single domain -  */
        getWebsiteDomainModSecStatus: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/modsec_status`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns the SSL for this website domain - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
        getWebsiteDomainSslCert: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get domain\'s custom vhost file, if the file does not exist return empty string  -  */
        getWebsiteDomainVhost: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/vhost`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get enabled PHP extensions -  */
        getWebsiteEnabledPhpExtensions: async (websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/php_extensions`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the active FS quoa limits for a website -  */
        getWebsiteFsQuotaLimits: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/fs_quota_limits`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns current rules of blocked/whitelisted IPs - Allowlisting or blocklisting IPs via .htaccess is done using Require ip rule. This endpoint reads the rules from the .htaccess in the website\'s public_html. Note that this is not the same .htaccess that is used for writing redirect rules. In future this endpoint might be merged with the htaccess endpoint to allow writing rewrite rules and ip allow lists to any website directory. */
        getWebsiteHtaccessIpsRule: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/htaccess/ips`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Reads chains of rewrite rules - Rewrites are `RewriteRule`s in website\'s .htaccess. We use terminology \"rewrite chain\" to refer to 0 or more `RewriteCond`s directive ended by a `RewriteRule`. To identify a rewrite chain in the .htaccess file, we use line numbers. Line numbers serve two purposes. It acts loosely as an identifier, i.e. if you want to remove some chain or replace it by another, you would use the same line number you received when you read the `GET` endpoint. Second purpose is that of ordering. If you want some chain to be above another in the file, you have to make sure that the line number is less. */
        getWebsiteHtaccessRewrites: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/htaccess`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get ioncube status for an existing website -  */
        getWebsiteIoncubeStatus: async (websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{website_id}/ioncube`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns the SSL for this website domain with the mail.prefix - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
        getWebsiteMailDomainSslCert: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/mail_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website metrics - Returns website metrics between the optional start and end date. Defaults to last 24 hours. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteMetrics: async (orgId: string, websiteId: string, start?: string, end?: string, granularity?: 'hour' | 'day', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/metrics`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website MySQL databases - Returns all MySQL databases belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWebsiteMySQLDBs: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/mysql-dbs`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get redis state for a website -  */
        getWebsiteRedisState: async (websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{website_id}/redis`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Fetch website server domains - This endpoint will return the server domain for the application, email and database roles to which this website is assigned. */
        getWebsiteServerDomains: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/server_domains`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the value for a particular setting - Returns the value for a particular setting as a JSON object */
        getWebsiteSetting: async (orgId: string, websiteId: string, settingKind: SettingKind, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/settings/{setting_kind}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website\'s authorized SSH keys - Returns a list of authorized public SSH keys. Invalid SSH keys entries in the authorized_keys file will be ignored. If the authorized_keys file does not exists, an empty set will be returned. */
        getWebsiteSshKeys: async (orgId: string, websiteId: string, sanitize?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ssh/keys`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (sanitize !== undefined) {
                localVarQueryParameter['sanitize'] = sanitize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get web server kind for a given website -  */
        getWebsiteWebserverKind: async (websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{website_id}/webserver_kind`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get websites - Returns all websites belonging to the organization. The results may optionally be sorted and paginated. If the recursive flag is set, the websites of customers of reseller customers are returned as well recursively, up to an optional max depth value. Session holder must be at least a `SuperAdmin` in this org or a parent org, or must have access to at least one website in this org. If the member is not an admin but has access to one or more websites, only those websites are returned. */
        getWebsites: async (orgId: string, offset?: number, limit?: number, sortBy?: 'domain' | 'subscription' | 'org' | 'size' | 'createdAt', sortOrder?: 'asc' | 'desc', search?: string, recursion?: Recursion, planId?: number, subscriptionId?: number, status?: WebsiteStatus, isSuspended?: boolean, roles?: Array<ServerRole>, servers?: Array<string>, kind?: WebsiteKind, showDeleted?: boolean, showAliases?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (recursion !== undefined) {
                localVarQueryParameter['recursion'] = recursion;
            }

            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isSuspended !== undefined) {
                localVarQueryParameter['isSuspended'] = isSuspended;
            }

            if (roles !== undefined) {
                localVarQueryParameter['roles'] = roles;
            }

            if (servers !== undefined) {
                localVarQueryParameter['servers'] = servers;
            }

            if (kind !== undefined) {
                localVarQueryParameter['kind'] = kind;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (showAliases !== undefined) {
                localVarQueryParameter['showAliases'] = showAliases;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Perform the LetsEncrypt preflight check - Will attempt to verify that the domain will successfully achieve a LetsEncrypt certificate if attempted.  Provides debug information. */
        performLetsEncryptPreflightCheck: async (domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/letsencrypt_preflight`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Making a staging website live - Validates that the operation is allowed for the website. Session holder must be at least a `SuperAdmin` in the org which owns the website, or a parent org. If the website is not owned by the MO, the subscription must have sufficient available \'websites\' resource */
        pushWebsiteLive: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/push-live`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Restart PHP container for a website -  */
        restartWebsitePhp: async (websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{website_id}/restart_php`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set Nginx FastCGI enablement -  */
        setDomainNginxFastCgi: async (domainId: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/nginx_fastcgi`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set web server rewrite to file -  */
        setDomainWebserverRewrite: async (domainId: string, webServerRewrite: WebServerRewrite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/webserver_rewrites`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof webServerRewrite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(webServerRewrite !== undefined ? webServerRewrite : {}) : (webServerRewrite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the active cgroup limits for a website (Master org only) -  */
        setWebsiteCgroupLimits: async (orgId: string, websiteId: string, setCgroupLimits: SetCgroupLimits, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/cgroup_limits`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof setCgroupLimits !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(setCgroupLimits !== undefined ? setCgroupLimits : {}) : (setCgroupLimits || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set \"force ssl\" status for domain mapping -  */
        setWebsiteDomainForceSsl: async (domainId: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl/force_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set mod security status on a single domain -  */
        setWebsiteDomainModSecStatus: async (domainId: string, modSecStatus: ModSecStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/modsec_status`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof modSecStatus !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(modSecStatus !== undefined ? modSecStatus : {}) : (modSecStatus || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a custom vhost file -  */
        setWebsiteDomainVhost: async (domainId: string, vhost: Vhost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/vhost`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof vhost !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(vhost !== undefined ? vhost : {}) : (vhost || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set the active FS quota limits for a website (Master org only) -  */
        setWebsiteFsQuotaLimits: async (orgId: string, websiteId: string, fsQuotaLimit: FsQuotaLimit, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/fs_quota_limits`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof fsQuotaLimit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(fsQuotaLimit !== undefined ? fsQuotaLimit : {}) : (fsQuotaLimit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set ioncube status for an existing website -  */
        setWebsiteIoncubeStatus: async (websiteId: string, body?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{website_id}/ioncube`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set Redis state for an existing website -  */
        setWebsiteRedisState: async (websiteId: string, body?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/websites/{website_id}/redis`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a single override setting - Set or replace a single website level service setting */
        setWebsiteSetting: async (orgId: string, websiteId: string, settingKind: SettingKind, settingKey: string, serviceSettingValue: ServiceSettingValue, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/settings/{setting_kind}/{setting_key}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"setting_kind"}}`, encodeURIComponent(String(settingKind)))
                .replace(`{${"setting_key"}}`, encodeURIComponent(String(settingKey)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof serviceSettingValue !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(serviceSettingValue !== undefined ? serviceSettingValue : {}) : (serviceSettingValue || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Take website screenshot immediately - Take website screenshot immediately */
        takeScreenshot: async (orgId: string, websiteId: string, domainId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}/screenshot/take`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Unauthorize the public SSH key with the given ID. - This operation will unauthorize the given public SSH key by deleting its content from the website\'s .ssh/authorized_keys file. */
        unauthorizeWebsiteSshKey: async (orgId: string, websiteId: string, keyId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ssh/keys/{key_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"key_id"}}`, encodeURIComponent(String(keyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update given FTP user - Endpoint for updating FTP user for a given website We only allow user\'s homeDir and password to be updated. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateFtpUser: async (orgId: string, websiteId: string, username: string, ftpUserUpdate: FtpUserUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ftp/users/{username}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof ftpUserUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(ftpUserUpdate !== undefined ? ftpUserUpdate : {}) : (ftpUserUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update user\'s crontab - Update user\'s crontab. Note that users are able to update only cron expressions and environment variables. */
        updateUserCrontab: async (orgId: string, websiteId: string, updateCrontabFullListing: UpdateCrontabFullListing, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/crontab`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateCrontabFullListing !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCrontabFullListing !== undefined ? updateCrontabFullListing : {}) : (updateCrontabFullListing || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update website - Updates the website. It may be used to enable or disable a specific version of PHP for a website, in which case a corresponding `PhpCd` instance is installed or uninstalled on the same server of the website. When enabling the website PHP it is also possible to specify whether the SSH daemon will need to be enabled in the `PhpCd` service at startup, via the `ssh` boolean flag. Moreover, if PHP is already enabled for a website, it is possible to enable or disable its SSH by only specifying the `ssh` flag. The endpoint is also responsible for assigning tags to a website. Note that the input overwrites all existing tags, so when adding a new tag, the `tags` property also has to include existing tags that are to be kept. Only a parent organization or MO admin may suspend websites. The website may be moved to another subscription, if that subscription has enough quota to accommodate the new website, unless the MO is performing the action, in which case they may move any website off a subscription or to a subscription that doesn\'t necessary have quota left. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        updateWebsite: async (orgId: string, websiteId: string, updateWebsite: UpdateWebsite, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateWebsite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateWebsite !== undefined ? updateWebsite : {}) : (updateWebsite || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update website domain mapping - Partially updates the domain mapping. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWebsiteDomainMapping: async (orgId: string, websiteId: string, domainId: string, domainMappingUpdate: DomainMappingUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/{domain_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof domainMappingUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(domainMappingUpdate !== undefined ? domainMappingUpdate : {}) : (domainMappingUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Sets a rule over provided ips - either block or allow - Allowlisting or blocklisting IPs via .htaccess is done using Require ip rule. This endpoint puts the rule (creating .htaccess if it doesn\'t exist in process) into the public_html/.htaccess file. Note that this is not the same .htaccess that is used for writing redirect rules. In future this endpoint might be merged with the htaccess endpoint to allow writing rewrite rules and ip allow lists to any website directory. */
        updateWebsiteHtaccessIpsRule: async (orgId: string, websiteId: string, requireIpsRule: RequireIpsRule, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/htaccess/ips`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof requireIpsRule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requireIpsRule !== undefined ? requireIpsRule : {}) : (requireIpsRule || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates rewrite rules - Rewrites are `RewriteRule`s in website\'s .htaccess file. We use terminology \"rewrite chain\" to refer to 0 or more `RewriteCond`s directive ended by `RewriteRule`. */
        updateWebsiteHtaccessRewrites: async (orgId: string, websiteId: string, updateRewriteChainFullListing: UpdateRewriteChainFullListing, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/htaccess`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateRewriteChainFullListing !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateRewriteChainFullListing !== undefined ? updateRewriteChainFullListing : {}) : (updateRewriteChainFullListing || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update primary domain mapping - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). Domain and website has to belong to this organization. */
        updateWebsitePrimaryDomain: async (orgId: string, websiteId: string, newPrimaryDomainMapping: NewPrimaryDomainMapping, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/domains/primary`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newPrimaryDomainMapping !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newPrimaryDomainMapping !== undefined ? newPrimaryDomainMapping : {}) : (newPrimaryDomainMapping || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update an existing website public SSH key. - This operation will update the given public SSH key value, and/or its associated friendly name. An error will be returned if none of the expected arguments of the request body is specified. */
        updateWebsiteSshKey: async (orgId: string, websiteId: string, keyId: string, sshKeyUpdate: SshKeyUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/ssh/keys/{key_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"key_id"}}`, encodeURIComponent(String(keyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sshKeyUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sshKeyUpdate !== undefined ? sshKeyUpdate : {}) : (sshKeyUpdate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload custom ssl certificate, key and optional fullchain for a given website - Endpoint for uploading custom SSL certificate for a given website. Verifies the cert key and maps to relevant domains that the certificate can be applied to. Returns error if no domain match is found. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        uploadWebsiteDomainSslCert: async (domainId: string, sslCert: SslCert, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sslCert !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sslCert !== undefined ? sslCert : {}) : (sslCert || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Upload SSL for mail.customerdomain. -  */
        uploadWebsiteMailDomainSslCert: async (domainId: string, sslCert: SslCert, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/domains/{domain_id}/mail_ssl`
                .replace(`{${"domain_id"}}`, encodeURIComponent(String(domainId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof sslCert !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(sslCert !== undefined ? sslCert : {}) : (sslCert || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Validate whether a website operation is allowed - Validates that the operation is allowed for the website. Currently this is only for verifying whether a website may be moved to another subscription, but this could later be expanded with other actions that can be verified. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
        validateWebsiteOperation: async (orgId: string, websiteId: string, websiteOperationValidation: WebsiteOperationValidation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/validate-operation`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof websiteOperationValidation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(websiteOperationValidation !== undefined ? websiteOperationValidation : {}) : (websiteOperationValidation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for addDomainNginxFastCgiExcludedPath operation in WebsitesApi. */
export type WebsitesApiAddDomainNginxFastCgiExcludedPathReq = {
    /** The id of the domain. */
    domainId: string
    /** Exclude the following path from nginx fastcgi cache. */
    body: string
};

/** Response parameters for addDomainNginxFastCgiExcludedPath operation in WebsitesApi. */
export type WebsitesApiAddDomainNginxFastCgiExcludedPathRes = void;


/** Request parameters for authorizeWebsiteSshKey operation in WebsitesApi. */
export type WebsitesApiAuthorizeWebsiteSshKeyReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The public SSH key to authorize. */
    newSshKey: NewSshKey
    /** If set to true, the SSH keys with unrecognized comments will be sanitized by changing the comment to a valid format that can be used to store metadata. If any of the keys requires sanitization the content of the authorized_keys file will be edited accordingly before returning the set of keys. If instead set to false, only the SSH keys that are recognized as valid (that is, contain valid metadata in their comments), will be returned, all the other keys will be skipped. */
    sanitize?: boolean
};

/** Response parameters for authorizeWebsiteSshKey operation in WebsitesApi. */
export type WebsitesApiAuthorizeWebsiteSshKeyRes = NewSshKeyId;


/** Request parameters for authorizeWebsiteSshPassword operation in WebsitesApi. */
export type WebsitesApiAuthorizeWebsiteSshPasswordReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The SSH password to authorize. */
    newPassword: NewPassword
};

/** Response parameters for authorizeWebsiteSshPassword operation in WebsitesApi. */
export type WebsitesApiAuthorizeWebsiteSshPasswordRes = void;


/** Request parameters for clearDomainNginxFastCgi operation in WebsitesApi. */
export type WebsitesApiClearDomainNginxFastCgiReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for clearDomainNginxFastCgi operation in WebsitesApi. */
export type WebsitesApiClearDomainNginxFastCgiRes = void;


/** Request parameters for cloneWebsite operation in WebsitesApi. */
export type WebsitesApiCloneWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    websiteCloneRequest: WebsiteCloneRequest
};

/** Response parameters for cloneWebsite operation in WebsitesApi. */
export type WebsitesApiCloneWebsiteRes = NewResourceUuid;


/** Request parameters for createFtpUser operation in WebsitesApi. */
export type WebsitesApiCreateFtpUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** FTP User */
    newFtpUser: NewFtpUser
    /** If set to true we will try to crete a new home_dir for the user if does not exist. */
    createHome?: boolean
};

/** Response parameters for createFtpUser operation in WebsitesApi. */
export type WebsitesApiCreateFtpUserRes = NewResourceUuid;


/** Request parameters for createPreviewDomain operation in WebsitesApi. */
export type WebsitesApiCreatePreviewDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for createPreviewDomain operation in WebsitesApi. */
export type WebsitesApiCreatePreviewDomainRes = string;


/** Request parameters for createWebsite operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    /** New website details. If the organization is the MO, they need not have a subscription to create a website. In all other cases organization needs to be subscribed to a plan that allows creating websites. */
    newWebsite: NewWebsite
    /** The kind of a *special* website that needs to be created. Whether this website is to be a *control panel* website or a *phpMyAdmin* website. Note: in order to create a new *phpMyAdmin* website the control panel website needs to be created first, since the new phpMyAdmin website will be under the control panel domain. */
    kind?: WebsiteKind
};

/** Response parameters for createWebsite operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteRes = NewResourceUuid;


/** Request parameters for createWebsiteDomainLetsencryptCerts operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteDomainLetsencryptCertsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for createWebsiteDomainLetsencryptCerts operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteDomainLetsencryptCertsRes = void;


/** Request parameters for createWebsiteMailDomainLetsencryptCerts operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteMailDomainLetsencryptCertsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for createWebsiteMailDomainLetsencryptCerts operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteMailDomainLetsencryptCertsRes = void;


/** Request parameters for createWebsiteMappedDomain operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteMappedDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Domain details. */
    newMappedDomain: NewMappedDomain
};

/** Response parameters for createWebsiteMappedDomain operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteMappedDomainRes = NewResourceUuid;


/** Request parameters for createWebsiteMySQLDB operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteMySQLDBReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** New database details. */
    newMySQLDB: NewMySQLDB
};

/** Response parameters for createWebsiteMySQLDB operation in WebsitesApi. */
export type WebsitesApiCreateWebsiteMySQLDBRes = void;


/** Request parameters for deleteDomainNginxFastCgiExcludedPath operation in WebsitesApi. */
export type WebsitesApiDeleteDomainNginxFastCgiExcludedPathReq = {
    /** The id of the domain. */
    domainId: string
    path: string
};

/** Response parameters for deleteDomainNginxFastCgiExcludedPath operation in WebsitesApi. */
export type WebsitesApiDeleteDomainNginxFastCgiExcludedPathRes = void;


/** Request parameters for deleteDomainWebserverRewrite operation in WebsitesApi. */
export type WebsitesApiDeleteDomainWebserverRewriteReq = {
    /** The id of the domain. */
    domainId: string
    path: string
};

/** Response parameters for deleteDomainWebserverRewrite operation in WebsitesApi. */
export type WebsitesApiDeleteDomainWebserverRewriteRes = void;


/** Request parameters for deleteFtpUser operation in WebsitesApi. */
export type WebsitesApiDeleteFtpUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    username: string
    /** If set to true we will try to delete the homeDir for the user. User homeDir can only be deleted if it is a subdir for the website home. */
    deleteHome?: boolean
};

/** Response parameters for deleteFtpUser operation in WebsitesApi. */
export type WebsitesApiDeleteFtpUserRes = void;


/** Request parameters for deleteUserCrontab operation in WebsitesApi. */
export type WebsitesApiDeleteUserCrontabReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for deleteUserCrontab operation in WebsitesApi. */
export type WebsitesApiDeleteUserCrontabRes = void;


/** Request parameters for deleteWebsite operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    force?: boolean
};

/** Response parameters for deleteWebsite operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteRes = void;


/** Request parameters for deleteWebsiteDomainMapping operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteDomainMappingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for deleteWebsiteDomainMapping operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteDomainMappingRes = void;


/** Request parameters for deleteWebsiteDomainVhost operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteDomainVhostReq = {
    /** The id of the domain. */
    domainId: string
    inlineObject1: InlineObject1
};

/** Response parameters for deleteWebsiteDomainVhost operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteDomainVhostRes = void;


/** Request parameters for deleteWebsiteSetting operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteSettingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The type of setting being applied */
    settingKind: SettingKind
    /** A key for updating an existing setting, some known values are - hard_delete_after_secs - letsencrypt_enabled - org_websites_same_server - screenshot_driver_pool_size - screenshot_interval - sged_smtp - smtp_smart_host - website_backup - default_dns_ttl */
    settingKey: string
};

/** Response parameters for deleteWebsiteSetting operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteSettingRes = void;


/** Request parameters for deleteWebsites operation in WebsitesApi. */
export type WebsitesApiDeleteWebsitesReq = {
    /** The id of the organization. */
    orgId: string
    /** The ids of the websites to delete. */
    uuidListing: UuidListing
};

/** Response parameters for deleteWebsites operation in WebsitesApi. */
export type WebsitesApiDeleteWebsitesRes = void;


/** Request parameters for disableWebsitePhpExtension operation in WebsitesApi. */
export type WebsitesApiDisableWebsitePhpExtensionReq = {
    /** The id of the website. */
    websiteId: string
    body?: string
};

/** Response parameters for disableWebsitePhpExtension operation in WebsitesApi. */
export type WebsitesApiDisableWebsitePhpExtensionRes = void;


/** Request parameters for enableWebsitePhpExtension operation in WebsitesApi. */
export type WebsitesApiEnableWebsitePhpExtensionReq = {
    /** The id of the website. */
    websiteId: string
    body?: string
};

/** Response parameters for enableWebsitePhpExtension operation in WebsitesApi. */
export type WebsitesApiEnableWebsitePhpExtensionRes = void;


/** Request parameters for getDomainNginxFastCgi operation in WebsitesApi. */
export type WebsitesApiGetDomainNginxFastCgiReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getDomainNginxFastCgi operation in WebsitesApi. */
export type WebsitesApiGetDomainNginxFastCgiRes = boolean;


/** Request parameters for getDomainNginxFastCgiExcludedPaths operation in WebsitesApi. */
export type WebsitesApiGetDomainNginxFastCgiExcludedPathsReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getDomainNginxFastCgiExcludedPaths operation in WebsitesApi. */
export type WebsitesApiGetDomainNginxFastCgiExcludedPathsRes = Array<string>;


/** Request parameters for getDomainWebserverRewrites operation in WebsitesApi. */
export type WebsitesApiGetDomainWebserverRewritesReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getDomainWebserverRewrites operation in WebsitesApi. */
export type WebsitesApiGetDomainWebserverRewritesRes = Array<WebServerRewrite>;


/** Request parameters for getFtpUsers operation in WebsitesApi. */
export type WebsitesApiGetFtpUsersReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getFtpUsers operation in WebsitesApi. */
export type WebsitesApiGetFtpUsersRes = FtpUsersFullListing;


/** Request parameters for getScreenshotTimestamp operation in WebsitesApi. */
export type WebsitesApiGetScreenshotTimestampReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getScreenshotTimestamp operation in WebsitesApi. */
export type WebsitesApiGetScreenshotTimestampRes = UnixTimestamp;


/** Request parameters for getSiteAccessToken operation in WebsitesApi. */
export type WebsitesApiGetSiteAccessTokenReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getSiteAccessToken operation in WebsitesApi. */
export type WebsitesApiGetSiteAccessTokenRes = string;


/** Request parameters for getUserCrontab operation in WebsitesApi. */
export type WebsitesApiGetUserCrontabReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getUserCrontab operation in WebsitesApi. */
export type WebsitesApiGetUserCrontabRes = CrontabFullListing;


/** Request parameters for getWebsite operation in WebsitesApi. */
export type WebsitesApiGetWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsite operation in WebsitesApi. */
export type WebsitesApiGetWebsiteRes = Website;


/** Request parameters for getWebsiteAvailablePhpExtensions operation in WebsitesApi. */
export type WebsitesApiGetWebsiteAvailablePhpExtensionsReq = {
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteAvailablePhpExtensions operation in WebsitesApi. */
export type WebsitesApiGetWebsiteAvailablePhpExtensionsRes = Array<string>;


/** Request parameters for getWebsiteBackupStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteBackupStatusReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteBackupStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteBackupStatusRes = BackupStatus;


/** Request parameters for getWebsiteCgroupLimits operation in WebsitesApi. */
export type WebsitesApiGetWebsiteCgroupLimitsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteCgroupLimits operation in WebsitesApi. */
export type WebsitesApiGetWebsiteCgroupLimitsRes = CgroupLimits;


/** Request parameters for getWebsiteClone operation in WebsitesApi. */
export type WebsitesApiGetWebsiteCloneReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website push live. */
    cloneId: string
};

/** Response parameters for getWebsiteClone operation in WebsitesApi. */
export type WebsitesApiGetWebsiteCloneRes = WebsiteCloneResponse;


/** Request parameters for getWebsiteCloneLog operation in WebsitesApi. */
export type WebsitesApiGetWebsiteCloneLogReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website push live. */
    cloneId: string
};

/** Response parameters for getWebsiteCloneLog operation in WebsitesApi. */
export type WebsitesApiGetWebsiteCloneLogRes = Array<WebsiteCloneLogEntry>;


/** Request parameters for getWebsiteClones operation in WebsitesApi. */
export type WebsitesApiGetWebsiteClonesReq = {
    /** The id of the organization. */
    orgId: string
};

/** Response parameters for getWebsiteClones operation in WebsitesApi. */
export type WebsitesApiGetWebsiteClonesRes = WebsiteCloneFullListing;


/** Request parameters for getWebsiteDomainDnsQuery operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainDnsQueryReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    /** DNS query resolve depth, defaults to `short` if not provided. `short` -> takes the shortest path to resolve domain IP. `detailed` -> queries and returns output from all found Authoritative name servers. `queryAllTldNs` -> queries and returns results from all TLD name servers (very expensive). */
    resolveDepth?: 'short' | 'detailed' | 'queryAllTldNs'
};

/** Response parameters for getWebsiteDomainDnsQuery operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainDnsQueryRes = DnsQueryOutcome;


/** Request parameters for getWebsiteDomainMapping operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainMappingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainMapping operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainMappingRes = DomainMapping;


/** Request parameters for getWebsiteDomainMappingDnsStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainMappingDnsStatusReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainMappingDnsStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainMappingDnsStatusRes = DnsStatus;


/** Request parameters for getWebsiteDomainMappings operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainMappingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** If set to true, domains are returned with applicable ssl certificates. */
    withSsl?: boolean
};

/** Response parameters for getWebsiteDomainMappings operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainMappingsRes = DomainMappingsFullListing;


/** Request parameters for getWebsiteDomainModSecStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainModSecStatusReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainModSecStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainModSecStatusRes = ModSecStatus;


/** Request parameters for getWebsiteDomainSslCert operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainSslCert operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainSslCertRes = DomainSslCertWithData;


/** Request parameters for getWebsiteDomainVhost operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainVhostReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteDomainVhost operation in WebsitesApi. */
export type WebsitesApiGetWebsiteDomainVhostRes = Vhost;


/** Request parameters for getWebsiteEnabledPhpExtensions operation in WebsitesApi. */
export type WebsitesApiGetWebsiteEnabledPhpExtensionsReq = {
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteEnabledPhpExtensions operation in WebsitesApi. */
export type WebsitesApiGetWebsiteEnabledPhpExtensionsRes = Array<string>;


/** Request parameters for getWebsiteFsQuotaLimits operation in WebsitesApi. */
export type WebsitesApiGetWebsiteFsQuotaLimitsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteFsQuotaLimits operation in WebsitesApi. */
export type WebsitesApiGetWebsiteFsQuotaLimitsRes = FsQuotaInfo;


/** Request parameters for getWebsiteHtaccessIpsRule operation in WebsitesApi. */
export type WebsitesApiGetWebsiteHtaccessIpsRuleReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteHtaccessIpsRule operation in WebsitesApi. */
export type WebsitesApiGetWebsiteHtaccessIpsRuleRes = RequireIpsRule;


/** Request parameters for getWebsiteHtaccessRewrites operation in WebsitesApi. */
export type WebsitesApiGetWebsiteHtaccessRewritesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteHtaccessRewrites operation in WebsitesApi. */
export type WebsitesApiGetWebsiteHtaccessRewritesRes = RewriteChainFullListing;


/** Request parameters for getWebsiteIoncubeStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteIoncubeStatusReq = {
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteIoncubeStatus operation in WebsitesApi. */
export type WebsitesApiGetWebsiteIoncubeStatusRes = boolean;


/** Request parameters for getWebsiteMailDomainSslCert operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMailDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for getWebsiteMailDomainSslCert operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMailDomainSslCertRes = DomainSslCertWithData;


/** Request parameters for getWebsiteMetrics operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMetricsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Start datetime UTC. */
    start?: string
    /** End datetime UTC. */
    end?: string
    /** Takes one of `hour`, `day`, defaults to `day` */
    granularity?: 'hour' | 'day'
};

/** Response parameters for getWebsiteMetrics operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMetricsRes = WebsiteMetricsFullListing;


/** Request parameters for getWebsiteMySQLDBs operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMySQLDBsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteMySQLDBs operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMySQLDBsRes = MySQLDBsFullListing;


/** Request parameters for getWebsiteRedisState operation in WebsitesApi. */
export type WebsitesApiGetWebsiteRedisStateReq = {
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteRedisState operation in WebsitesApi. */
export type WebsitesApiGetWebsiteRedisStateRes = boolean;


/** Request parameters for getWebsiteServerDomains operation in WebsitesApi. */
export type WebsitesApiGetWebsiteServerDomainsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteServerDomains operation in WebsitesApi. */
export type WebsitesApiGetWebsiteServerDomainsRes = WebsiteServerDomains;


/** Request parameters for getWebsiteSetting operation in WebsitesApi. */
export type WebsitesApiGetWebsiteSettingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The type of setting being applied */
    settingKind: SettingKind
};

/** Response parameters for getWebsiteSetting operation in WebsitesApi. */
export type WebsitesApiGetWebsiteSettingRes = any;


/** Request parameters for getWebsiteSshKeys operation in WebsitesApi. */
export type WebsitesApiGetWebsiteSshKeysReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** If set to true, the SSH keys with unrecognized comments will be sanitized by changing the comment to a valid format that can be used to store metadata. If any of the keys requires sanitization the content of the authorized_keys file will be edited accordingly before returning the set of keys. If instead set to false, only the SSH keys that are recognized as valid (that is, contain valid metadata in their comments), will be returned, all the other keys will be skipped. */
    sanitize?: boolean
};

/** Response parameters for getWebsiteSshKeys operation in WebsitesApi. */
export type WebsitesApiGetWebsiteSshKeysRes = SshKeyFullListing;


/** Request parameters for getWebsiteWebserverKind operation in WebsitesApi. */
export type WebsitesApiGetWebsiteWebserverKindReq = {
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWebsiteWebserverKind operation in WebsitesApi. */
export type WebsitesApiGetWebsiteWebserverKindRes = WebserverKind;


/** Request parameters for getWebsites operation in WebsitesApi. */
export type WebsitesApiGetWebsitesReq = {
    /** The id of the organization. */
    orgId: string
    /** The offset from which to return items. */
    offset?: number
    /** The maximum number of items to return. */
    limit?: number
    /** The field by which to sort. */
    sortBy?: 'domain' | 'subscription' | 'org' | 'size' | 'createdAt'
    /** The direction in which to sort. Possible values are \'asc\' and \'desc\', defaulting to \'asc\'. */
    sortOrder?: 'asc' | 'desc'
    /** Limit the result set to the resources whose names, partially and case insensitively, match the specified search term. E.g. for websites, this is their domain or tag, for databases the database name, for emails the email address or mailbox name, etc. A website will also be returned if the search term exactly matches the website\'s uuid. */
    search?: string
    /** If set to directCustomers then websites belonging to direct customers of the orgId will be returned.  If set to infinite then websites belonging to customers of customers (and so on) will be returned.  If unset then no recursion will be performed. */
    recursion?: Recursion
    /** Limit the result set to resources under subscriptions to the plan. */
    planId?: number
    /** Limit the result set to resources under subscription. */
    subscriptionId?: number
    /** Limit the result set to websites with the specified status. Only applicable if `recursive` is set to true. */
    status?: WebsiteStatus
    /** Limit the result set to websites which are currently suspended or not suspended. */
    isSuspended?: boolean
    /** Limit the result set to websites having one of these roles assigned to a server. */
    roles?: Array<ServerRole>
    /** Limit the result set to websites having one of the selected roles (or all roles) on one of these servers. */
    servers?: Array<string>
    /** Limit the results to websites of the specified type. */
    kind?: WebsiteKind
    /** Filters out deleted websites, which are otherwise returned in the result. Defaults to `showDeleted=true` if not set. Can only be set by MO, if set by others, a 403 is returned. */
    showDeleted?: boolean
    /** Includes domain aliases in search results and listings in addition to the website\'s primary domain. */
    showAliases?: boolean
};

/** Response parameters for getWebsites operation in WebsitesApi. */
export type WebsitesApiGetWebsitesRes = WebsitesListing;


/** Request parameters for performLetsEncryptPreflightCheck operation in WebsitesApi. */
export type WebsitesApiPerformLetsEncryptPreflightCheckReq = {
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for performLetsEncryptPreflightCheck operation in WebsitesApi. */
export type WebsitesApiPerformLetsEncryptPreflightCheckRes = LetsEncryptPreflightResult;


/** Request parameters for pushWebsiteLive operation in WebsitesApi. */
export type WebsitesApiPushWebsiteLiveReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for pushWebsiteLive operation in WebsitesApi. */
export type WebsitesApiPushWebsiteLiveRes = void;


/** Request parameters for restartWebsitePhp operation in WebsitesApi. */
export type WebsitesApiRestartWebsitePhpReq = {
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for restartWebsitePhp operation in WebsitesApi. */
export type WebsitesApiRestartWebsitePhpRes = void;


/** Request parameters for setDomainNginxFastCgi operation in WebsitesApi. */
export type WebsitesApiSetDomainNginxFastCgiReq = {
    /** The id of the domain. */
    domainId: string
    /** Boolean value, set true to enable and false to disable FastCGI cache. */
    body: boolean
};

/** Response parameters for setDomainNginxFastCgi operation in WebsitesApi. */
export type WebsitesApiSetDomainNginxFastCgiRes = void;


/** Request parameters for setDomainWebserverRewrite operation in WebsitesApi. */
export type WebsitesApiSetDomainWebserverRewriteReq = {
    /** The id of the domain. */
    domainId: string
    /** Rewrite a path to a file */
    webServerRewrite: WebServerRewrite
};

/** Response parameters for setDomainWebserverRewrite operation in WebsitesApi. */
export type WebsitesApiSetDomainWebserverRewriteRes = void;


/** Request parameters for setWebsiteCgroupLimits operation in WebsitesApi. */
export type WebsitesApiSetWebsiteCgroupLimitsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Cgroup limits. */
    setCgroupLimits: SetCgroupLimits
};

/** Response parameters for setWebsiteCgroupLimits operation in WebsitesApi. */
export type WebsitesApiSetWebsiteCgroupLimitsRes = void;


/** Request parameters for setWebsiteDomainForceSsl operation in WebsitesApi. */
export type WebsitesApiSetWebsiteDomainForceSslReq = {
    /** The id of the domain. */
    domainId: string
    /** Boolean \"force ssl\" setting */
    body: boolean
};

/** Response parameters for setWebsiteDomainForceSsl operation in WebsitesApi. */
export type WebsitesApiSetWebsiteDomainForceSslRes = void;


/** Request parameters for setWebsiteDomainModSecStatus operation in WebsitesApi. */
export type WebsitesApiSetWebsiteDomainModSecStatusReq = {
    /** The id of the domain. */
    domainId: string
    modSecStatus: ModSecStatus
};

/** Response parameters for setWebsiteDomainModSecStatus operation in WebsitesApi. */
export type WebsitesApiSetWebsiteDomainModSecStatusRes = void;


/** Request parameters for setWebsiteDomainVhost operation in WebsitesApi. */
export type WebsitesApiSetWebsiteDomainVhostReq = {
    /** The id of the domain. */
    domainId: string
    vhost: Vhost
};

/** Response parameters for setWebsiteDomainVhost operation in WebsitesApi. */
export type WebsitesApiSetWebsiteDomainVhostRes = void;


/** Request parameters for setWebsiteFsQuotaLimits operation in WebsitesApi. */
export type WebsitesApiSetWebsiteFsQuotaLimitsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** FS quota limits. */
    fsQuotaLimit: FsQuotaLimit
};

/** Response parameters for setWebsiteFsQuotaLimits operation in WebsitesApi. */
export type WebsitesApiSetWebsiteFsQuotaLimitsRes = void;


/** Request parameters for setWebsiteIoncubeStatus operation in WebsitesApi. */
export type WebsitesApiSetWebsiteIoncubeStatusReq = {
    /** The id of the website. */
    websiteId: string
    body?: boolean
};

/** Response parameters for setWebsiteIoncubeStatus operation in WebsitesApi. */
export type WebsitesApiSetWebsiteIoncubeStatusRes = void;


/** Request parameters for setWebsiteRedisState operation in WebsitesApi. */
export type WebsitesApiSetWebsiteRedisStateReq = {
    /** The id of the website. */
    websiteId: string
    body?: boolean
};

/** Response parameters for setWebsiteRedisState operation in WebsitesApi. */
export type WebsitesApiSetWebsiteRedisStateRes = void;


/** Request parameters for setWebsiteSetting operation in WebsitesApi. */
export type WebsitesApiSetWebsiteSettingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The type of setting being applied */
    settingKind: SettingKind
    /** A key for updating an existing setting, some known values are - hard_delete_after_secs - letsencrypt_enabled - org_websites_same_server - screenshot_driver_pool_size - screenshot_interval - sged_smtp - smtp_smart_host - website_backup - default_dns_ttl */
    settingKey: string
    serviceSettingValue: ServiceSettingValue
};

/** Response parameters for setWebsiteSetting operation in WebsitesApi. */
export type WebsitesApiSetWebsiteSettingRes = void;


/** Request parameters for takeScreenshot operation in WebsitesApi. */
export type WebsitesApiTakeScreenshotReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
};

/** Response parameters for takeScreenshot operation in WebsitesApi. */
export type WebsitesApiTakeScreenshotRes = void;


/** Request parameters for unauthorizeWebsiteSshKey operation in WebsitesApi. */
export type WebsitesApiUnauthorizeWebsiteSshKeyReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The unique ID of the SSH key within the same authorized_keys file. */
    keyId: string
};

/** Response parameters for unauthorizeWebsiteSshKey operation in WebsitesApi. */
export type WebsitesApiUnauthorizeWebsiteSshKeyRes = void;


/** Request parameters for updateFtpUser operation in WebsitesApi. */
export type WebsitesApiUpdateFtpUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    username: string
    /** FTP User */
    ftpUserUpdate: FtpUserUpdate
};

/** Response parameters for updateFtpUser operation in WebsitesApi. */
export type WebsitesApiUpdateFtpUserRes = void;


/** Request parameters for updateUserCrontab operation in WebsitesApi. */
export type WebsitesApiUpdateUserCrontabReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** List of crontab expressions to be inserted into user\'s crontab. To identify a crontab expressions in the crontab file, we use line numbers. */
    updateCrontabFullListing: UpdateCrontabFullListing
};

/** Response parameters for updateUserCrontab operation in WebsitesApi. */
export type WebsitesApiUpdateUserCrontabRes = void;


/** Request parameters for updateWebsite operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Website update details. */
    updateWebsite: UpdateWebsite
};

/** Response parameters for updateWebsite operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteRes = void;


/** Request parameters for updateWebsiteDomainMapping operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteDomainMappingReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the domain. */
    domainId: string
    domainMappingUpdate: DomainMappingUpdate
};

/** Response parameters for updateWebsiteDomainMapping operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteDomainMappingRes = void;


/** Request parameters for updateWebsiteHtaccessIpsRule operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteHtaccessIpsRuleReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** List of ips and a rule which should apply to them - either block all these ips or allow only these ips. */
    requireIpsRule: RequireIpsRule
};

/** Response parameters for updateWebsiteHtaccessIpsRule operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteHtaccessIpsRuleRes = void;


/** Request parameters for updateWebsiteHtaccessRewrites operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteHtaccessRewritesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** List of rewrite chains to be inserted into the .htaccess file. To identify a rewrite chain in the .htaccess file, we use line numbers. Line numbers serve two purposes. It acts loosely as an identifier, i.e. if you want to remove some chain or replace it by another, you would use the same line number you received when you read the `GET` endpoint. Second purpose is that of ordering. If you want some chain to be above another in the file, you have to make sure that the line number is less. To delete a rewrite chain, just send a line number without any additional information for a single `RewriteChain` object. */
    updateRewriteChainFullListing: UpdateRewriteChainFullListing
};

/** Response parameters for updateWebsiteHtaccessRewrites operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteHtaccessRewritesRes = void;


/** Request parameters for updateWebsitePrimaryDomain operation in WebsitesApi. */
export type WebsitesApiUpdateWebsitePrimaryDomainReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** Domain mapping details. */
    newPrimaryDomainMapping: NewPrimaryDomainMapping
};

/** Response parameters for updateWebsitePrimaryDomain operation in WebsitesApi. */
export type WebsitesApiUpdateWebsitePrimaryDomainRes = void;


/** Request parameters for updateWebsiteSshKey operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteSshKeyReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The unique ID of the SSH key within the same authorized_keys file. */
    keyId: string
    /** The public SSH key updatable fields. */
    sshKeyUpdate: SshKeyUpdate
};

/** Response parameters for updateWebsiteSshKey operation in WebsitesApi. */
export type WebsitesApiUpdateWebsiteSshKeyRes = void;


/** Request parameters for uploadWebsiteDomainSslCert operation in WebsitesApi. */
export type WebsitesApiUploadWebsiteDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
    /** Cert, private key and optional fullchain. */
    sslCert: SslCert
};

/** Response parameters for uploadWebsiteDomainSslCert operation in WebsitesApi. */
export type WebsitesApiUploadWebsiteDomainSslCertRes = NewSslCert;


/** Request parameters for uploadWebsiteMailDomainSslCert operation in WebsitesApi. */
export type WebsitesApiUploadWebsiteMailDomainSslCertReq = {
    /** The id of the domain. */
    domainId: string
    /** Cert, private key and optional fullchain. */
    sslCert: SslCert
};

/** Response parameters for uploadWebsiteMailDomainSslCert operation in WebsitesApi. */
export type WebsitesApiUploadWebsiteMailDomainSslCertRes = NewSslCert;


/** Request parameters for validateWebsiteOperation operation in WebsitesApi. */
export type WebsitesApiValidateWebsiteOperationReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    websiteOperationValidation: WebsiteOperationValidation
};

/** Response parameters for validateWebsiteOperation operation in WebsitesApi. */
export type WebsitesApiValidateWebsiteOperationRes = ValidationResult;

/**
 * WebsitesApi - service client interface
 */
export const WebsitesApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Add Nginx FastCGI excluded path -  */
    addDomainNginxFastCgiExcludedPath: async (reqParams: WebsitesApiAddDomainNginxFastCgiExcludedPathReq, options?: any): Promise<AxiosResponse<WebsitesApiAddDomainNginxFastCgiExcludedPathRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).addDomainNginxFastCgiExcludedPath(reqParams.domainId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Authorize a new SSH key. - This operation will authorize the given public SSH key by appending its content to the website\'s .ssh/authorized_keys file. */
    authorizeWebsiteSshKey: async (reqParams: WebsitesApiAuthorizeWebsiteSshKeyReq, options?: any): Promise<AxiosResponse<WebsitesApiAuthorizeWebsiteSshKeyRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).authorizeWebsiteSshKey(reqParams.orgId, reqParams.websiteId, reqParams.newSshKey, reqParams.sanitize, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Authorize a new SSH password for website. - This operation will authorize the given SSH password for the website\'s unix user. this request will replace the existing password for the user if already set. */
    authorizeWebsiteSshPassword: async (reqParams: WebsitesApiAuthorizeWebsiteSshPasswordReq, options?: any): Promise<AxiosResponse<WebsitesApiAuthorizeWebsiteSshPasswordRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).authorizeWebsiteSshPassword(reqParams.orgId, reqParams.websiteId, reqParams.newPassword, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Clear FastCGI cache for domain -  */
    clearDomainNginxFastCgi: async (reqParams: WebsitesApiClearDomainNginxFastCgiReq, options?: any): Promise<AxiosResponse<WebsitesApiClearDomainNginxFastCgiRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).clearDomainNginxFastCgi(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Clone website or push live - Clone a website or push live to overwrite an existing live website with content from the source website. Operation is asynchronous and returns a clone id. If includeDatabases is ommited, the all databases will be cloned from the source website. If includeDatabaseUsers is ommited, the all database users will be cloned from the source website. */
    cloneWebsite: async (reqParams: WebsitesApiCloneWebsiteReq, options?: any): Promise<AxiosResponse<WebsitesApiCloneWebsiteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).cloneWebsite(reqParams.orgId, reqParams.websiteCloneRequest, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Creates a new FTP user for a given website - Endpoint for creating a new FTP user. NOTE: user.account well get appended with website\'s primary domain. i.e. `username` will become `username@domain.com` Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createFtpUser: async (reqParams: WebsitesApiCreateFtpUserReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateFtpUserRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createFtpUser(reqParams.orgId, reqParams.websiteId, reqParams.newFtpUser, reqParams.createHome, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a preview domain - Creates a preview domain for the given website and returns its full domain.  If a preview domain already exists, returns that instead.  Will error if a preview domain cannot be created. Returns 200 if an existing preview domain is returned and 201 if one has been created. */
    createPreviewDomain: async (reqParams: WebsitesApiCreatePreviewDomainReq, options?: any): Promise<AxiosResponse<WebsitesApiCreatePreviewDomainRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createPreviewDomain(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a new website or clone an existing one. - Creates or clone a website under the organization. If the org is the MO, the request need not contain a subscription ID, but if it\'s a customer and the session holder is not an MO admin, the subscription to which to attach the website must be supplied. If the website to be created is \'staging\' kind then the subscription must include stagingWebsites resource > 1.  If \'normal\' then the subscription must include websites > 1. If the website to be created is a control panel, the reseller\'s subscription id must match the reseller subscription. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    createWebsite: async (reqParams: WebsitesApiCreateWebsiteReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateWebsiteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createWebsite(reqParams.orgId, reqParams.newWebsite, reqParams.kind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Generate and setup letsencrypt ssl certificates for website\'s domain - Generates letsencrypt certificates for the domain. This is a longer running task, that will do a complete ssl setup for a given domain. Once completed any given domain will get served over `https`. Given domain must be publicly accessible and being served from our service. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteDomainLetsencryptCerts: async (reqParams: WebsitesApiCreateWebsiteDomainLetsencryptCertsReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateWebsiteDomainLetsencryptCertsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createWebsiteDomainLetsencryptCerts(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Generate and setup letsencrypt ssl certificates for website\'s domain with mail. prefix. - Generates letsencrypt certificate for the mail server at mail.customerdomain. */
    createWebsiteMailDomainLetsencryptCerts: async (reqParams: WebsitesApiCreateWebsiteMailDomainLetsencryptCertsReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateWebsiteMailDomainLetsencryptCertsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createWebsiteMailDomainLetsencryptCerts(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create website mapped domain - Creates a domain mapping, where subscription resources are sufficient. The mapping kind will default to \'alias\' if unspecified. */
    createWebsiteMappedDomain: async (reqParams: WebsitesApiCreateWebsiteMappedDomainReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateWebsiteMappedDomainRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createWebsiteMappedDomain(reqParams.orgId, reqParams.websiteId, reqParams.newMappedDomain, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create a MySQL database for website - Creates a new MySQL database for the given website. The supplied name must conform to the following regular expression: `^[0-9a-z$_]+$`. That is, a name may only contain alphanumerical characters, dollar signs, and underscores. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWebsiteMySQLDB: async (reqParams: WebsitesApiCreateWebsiteMySQLDBReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateWebsiteMySQLDBRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createWebsiteMySQLDB(reqParams.orgId, reqParams.websiteId, reqParams.newMySQLDB, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete Nginx FastCGI excluded path -  */
    deleteDomainNginxFastCgiExcludedPath: async (reqParams: WebsitesApiDeleteDomainNginxFastCgiExcludedPathReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteDomainNginxFastCgiExcludedPathRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteDomainNginxFastCgiExcludedPath(reqParams.domainId, reqParams.path, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete web server rewrite -  */
    deleteDomainWebserverRewrite: async (reqParams: WebsitesApiDeleteDomainWebserverRewriteReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteDomainWebserverRewriteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteDomainWebserverRewrite(reqParams.domainId, reqParams.path, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes given FTP user - Endpoint for deleting FTP user for a given website. User homeDir can only be deleted if it is a subdir for the website home. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteFtpUser: async (reqParams: WebsitesApiDeleteFtpUserReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteFtpUserRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteFtpUser(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.deleteHome, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete user\'s crontab - Delete user\'s crontab. Remove crontab file from disk. */
    deleteUserCrontab: async (reqParams: WebsitesApiDeleteUserCrontabReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteUserCrontabRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteUserCrontab(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website - Mark a website as deleted, which does not remove its data, or force remove all its data. For removing all data and metadata belonging to a website (including DB records), pass the `force=true` query parameter. This can only be done by a privileged MO member. In this case, all data is wiped, so use carefully. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    deleteWebsite: async (reqParams: WebsitesApiDeleteWebsiteReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteWebsiteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteWebsite(reqParams.orgId, reqParams.websiteId, reqParams.force, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website domain mapping - Deletes the domain mapping and decrements the domain alias quota usage in the website\'s subscription, if applicable. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWebsiteDomainMapping: async (reqParams: WebsitesApiDeleteWebsiteDomainMappingReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteWebsiteDomainMappingRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteWebsiteDomainMapping(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Deletes domain\'s custom vhost file if any -  */
    deleteWebsiteDomainVhost: async (reqParams: WebsitesApiDeleteWebsiteDomainVhostReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteWebsiteDomainVhostRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteWebsiteDomainVhost(reqParams.domainId, reqParams.inlineObject1, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a single override setting - Delete a single website level service setting */
    deleteWebsiteSetting: async (reqParams: WebsitesApiDeleteWebsiteSettingReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteWebsiteSettingRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteWebsiteSetting(reqParams.orgId, reqParams.websiteId, reqParams.settingKind, reqParams.settingKey, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete websites - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). */
    deleteWebsites: async (reqParams: WebsitesApiDeleteWebsitesReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteWebsitesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteWebsites(reqParams.orgId, reqParams.uuidListing, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Disable a PHP extension -  */
    disableWebsitePhpExtension: async (reqParams: WebsitesApiDisableWebsitePhpExtensionReq, options?: any): Promise<AxiosResponse<WebsitesApiDisableWebsitePhpExtensionRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).disableWebsitePhpExtension(reqParams.websiteId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Enable a PHP extension -  */
    enableWebsitePhpExtension: async (reqParams: WebsitesApiEnableWebsitePhpExtensionReq, options?: any): Promise<AxiosResponse<WebsitesApiEnableWebsitePhpExtensionRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).enableWebsitePhpExtension(reqParams.websiteId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get status of Nginx FastCGI enablement -  */
    getDomainNginxFastCgi: async (reqParams: WebsitesApiGetDomainNginxFastCgiReq, options?: any): Promise<AxiosResponse<WebsitesApiGetDomainNginxFastCgiRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getDomainNginxFastCgi(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get Nginx FastCGI excluded paths -  */
    getDomainNginxFastCgiExcludedPaths: async (reqParams: WebsitesApiGetDomainNginxFastCgiExcludedPathsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetDomainNginxFastCgiExcludedPathsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getDomainNginxFastCgiExcludedPaths(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get web server rewrites for specified domain -  */
    getDomainWebserverRewrites: async (reqParams: WebsitesApiGetDomainWebserverRewritesReq, options?: any): Promise<AxiosResponse<WebsitesApiGetDomainWebserverRewritesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getDomainWebserverRewrites(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns all ftp users data for a given website - Endpoint for retreaving ftp users for a given website Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getFtpUsers: async (reqParams: WebsitesApiGetFtpUsersReq, options?: any): Promise<AxiosResponse<WebsitesApiGetFtpUsersRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getFtpUsers(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get last screeshot file\'s Timestamp - Returns Unix Timestamp for the last screenshot png file, if no screenshot found returns undefined */
    getScreenshotTimestamp: async (reqParams: WebsitesApiGetScreenshotTimestampReq, options?: any): Promise<AxiosResponse<WebsitesApiGetScreenshotTimestampRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getScreenshotTimestamp(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get an access token for the given website - Request an access token for the given website. Note that access tokens may only be requested for normal websites, not for control panel websites. The access token is returned as an encrypted JWT in the response body. Session holder must either be a parent organization admin, or be a member with Site Access role of the given organization. */
    getSiteAccessToken: async (reqParams: WebsitesApiGetSiteAccessTokenReq, options?: any): Promise<AxiosResponse<WebsitesApiGetSiteAccessTokenRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getSiteAccessToken(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get user\'s crontab - Get user\'s crontab. Only editable parts of crontab is returned. */
    getUserCrontab: async (reqParams: WebsitesApiGetUserCrontabReq, options?: any): Promise<AxiosResponse<WebsitesApiGetUserCrontabRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getUserCrontab(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website - Returns detailed information about a single website. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    getWebsite: async (reqParams: WebsitesApiGetWebsiteReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsite(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get available PHP extensions for a website -  */
    getWebsiteAvailablePhpExtensions: async (reqParams: WebsitesApiGetWebsiteAvailablePhpExtensionsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteAvailablePhpExtensionsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteAvailablePhpExtensions(reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the status of an ongoing website backup operation - Returns the status of the currently ongoing backup or restore, if any. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteBackupStatus: async (reqParams: WebsitesApiGetWebsiteBackupStatusReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteBackupStatusRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteBackupStatus(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the active cgroup limits for a website -  */
    getWebsiteCgroupLimits: async (reqParams: WebsitesApiGetWebsiteCgroupLimitsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteCgroupLimitsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteCgroupLimits(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get\'s detail about a single push live - Fetches details about a single website clone. cloneWebsite call operation just enqueues the request and returns immediately. Use this endpoint to monitor how the clone finishes. */
    getWebsiteClone: async (reqParams: WebsitesApiGetWebsiteCloneReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteCloneRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteClone(reqParams.orgId, reqParams.cloneId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the log for a given clone id.. - Fetches the import migration log for a single website clone. */
    getWebsiteCloneLog: async (reqParams: WebsitesApiGetWebsiteCloneLogReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteCloneLogRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteCloneLog(reqParams.orgId, reqParams.cloneId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** List website clones for given OrgId - List of all webiste clones for the given OrgId. */
    getWebsiteClones: async (reqParams: WebsitesApiGetWebsiteClonesReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteClonesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteClones(reqParams.orgId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Recursively query Dns servers for given domain - Returns details about the dns servers of given domain. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteDomainDnsQuery: async (reqParams: WebsitesApiGetWebsiteDomainDnsQueryReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainDnsQueryRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainDnsQuery(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.resolveDepth, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns website domain mapping - Returns a domain by its id that is mapped to this website. Requires login to have admin privileges in this org. Since only the MO can create standalone domains, session holder must be at least a `SuperAdmin` in the MO. */
    getWebsiteDomainMapping: async (reqParams: WebsitesApiGetWebsiteDomainMappingReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainMappingRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainMapping(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns website domain mapping DNS status - Returns an indication of whether the domain correctly resolves to the server this website is on. */
    getWebsiteDomainMappingDnsStatus: async (reqParams: WebsitesApiGetWebsiteDomainMappingDnsStatusReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainMappingDnsStatusRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainMappingDnsStatus(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website\'s mapped domains - Returns a list of domains that are mapped to this website. Requires login to have admin privileges in this org. */
    getWebsiteDomainMappings: async (reqParams: WebsitesApiGetWebsiteDomainMappingsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainMappingsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainMappings(reqParams.orgId, reqParams.websiteId, reqParams.withSsl, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get mod security status for a single domain -  */
    getWebsiteDomainModSecStatus: async (reqParams: WebsitesApiGetWebsiteDomainModSecStatusReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainModSecStatusRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainModSecStatus(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns the SSL for this website domain - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
    getWebsiteDomainSslCert: async (reqParams: WebsitesApiGetWebsiteDomainSslCertReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainSslCertRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainSslCert(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get domain\'s custom vhost file, if the file does not exist return empty string  -  */
    getWebsiteDomainVhost: async (reqParams: WebsitesApiGetWebsiteDomainVhostReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteDomainVhostRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteDomainVhost(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get enabled PHP extensions -  */
    getWebsiteEnabledPhpExtensions: async (reqParams: WebsitesApiGetWebsiteEnabledPhpExtensionsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteEnabledPhpExtensionsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteEnabledPhpExtensions(reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the active FS quoa limits for a website -  */
    getWebsiteFsQuotaLimits: async (reqParams: WebsitesApiGetWebsiteFsQuotaLimitsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteFsQuotaLimitsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteFsQuotaLimits(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns current rules of blocked/whitelisted IPs - Allowlisting or blocklisting IPs via .htaccess is done using Require ip rule. This endpoint reads the rules from the .htaccess in the website\'s public_html. Note that this is not the same .htaccess that is used for writing redirect rules. In future this endpoint might be merged with the htaccess endpoint to allow writing rewrite rules and ip allow lists to any website directory. */
    getWebsiteHtaccessIpsRule: async (reqParams: WebsitesApiGetWebsiteHtaccessIpsRuleReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteHtaccessIpsRuleRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteHtaccessIpsRule(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Reads chains of rewrite rules - Rewrites are `RewriteRule`s in website\'s .htaccess. We use terminology \"rewrite chain\" to refer to 0 or more `RewriteCond`s directive ended by a `RewriteRule`. To identify a rewrite chain in the .htaccess file, we use line numbers. Line numbers serve two purposes. It acts loosely as an identifier, i.e. if you want to remove some chain or replace it by another, you would use the same line number you received when you read the `GET` endpoint. Second purpose is that of ordering. If you want some chain to be above another in the file, you have to make sure that the line number is less. */
    getWebsiteHtaccessRewrites: async (reqParams: WebsitesApiGetWebsiteHtaccessRewritesReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteHtaccessRewritesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteHtaccessRewrites(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get ioncube status for an existing website -  */
    getWebsiteIoncubeStatus: async (reqParams: WebsitesApiGetWebsiteIoncubeStatusReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteIoncubeStatusRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteIoncubeStatus(reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns the SSL for this website domain with the mail.prefix - Endpoint for retrieving SSL certificates for a given website including certificates generated by letsencrypt */
    getWebsiteMailDomainSslCert: async (reqParams: WebsitesApiGetWebsiteMailDomainSslCertReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteMailDomainSslCertRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteMailDomainSslCert(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website metrics - Returns website metrics between the optional start and end date. Defaults to last 24 hours. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteMetrics: async (reqParams: WebsitesApiGetWebsiteMetricsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteMetricsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteMetrics(reqParams.orgId, reqParams.websiteId, reqParams.start, reqParams.end, reqParams.granularity, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website MySQL databases - Returns all MySQL databases belonging to the given website. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWebsiteMySQLDBs: async (reqParams: WebsitesApiGetWebsiteMySQLDBsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteMySQLDBsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteMySQLDBs(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get redis state for a website -  */
    getWebsiteRedisState: async (reqParams: WebsitesApiGetWebsiteRedisStateReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteRedisStateRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteRedisState(reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Fetch website server domains - This endpoint will return the server domain for the application, email and database roles to which this website is assigned. */
    getWebsiteServerDomains: async (reqParams: WebsitesApiGetWebsiteServerDomainsReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteServerDomainsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteServerDomains(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the value for a particular setting - Returns the value for a particular setting as a JSON object */
    getWebsiteSetting: async (reqParams: WebsitesApiGetWebsiteSettingReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteSettingRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteSetting(reqParams.orgId, reqParams.websiteId, reqParams.settingKind, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website\'s authorized SSH keys - Returns a list of authorized public SSH keys. Invalid SSH keys entries in the authorized_keys file will be ignored. If the authorized_keys file does not exists, an empty set will be returned. */
    getWebsiteSshKeys: async (reqParams: WebsitesApiGetWebsiteSshKeysReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteSshKeysRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteSshKeys(reqParams.orgId, reqParams.websiteId, reqParams.sanitize, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get web server kind for a given website -  */
    getWebsiteWebserverKind: async (reqParams: WebsitesApiGetWebsiteWebserverKindReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteWebserverKindRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteWebserverKind(reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get websites - Returns all websites belonging to the organization. The results may optionally be sorted and paginated. If the recursive flag is set, the websites of customers of reseller customers are returned as well recursively, up to an optional max depth value. Session holder must be at least a `SuperAdmin` in this org or a parent org, or must have access to at least one website in this org. If the member is not an admin but has access to one or more websites, only those websites are returned. */
    getWebsites: async (reqParams: WebsitesApiGetWebsitesReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsitesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsites(reqParams.orgId, reqParams.offset, reqParams.limit, reqParams.sortBy, reqParams.sortOrder, reqParams.search, reqParams.recursion, reqParams.planId, reqParams.subscriptionId, reqParams.status, reqParams.isSuspended, reqParams.roles, reqParams.servers, reqParams.kind, reqParams.showDeleted, reqParams.showAliases, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Perform the LetsEncrypt preflight check - Will attempt to verify that the domain will successfully achieve a LetsEncrypt certificate if attempted.  Provides debug information. */
    performLetsEncryptPreflightCheck: async (reqParams: WebsitesApiPerformLetsEncryptPreflightCheckReq, options?: any): Promise<AxiosResponse<WebsitesApiPerformLetsEncryptPreflightCheckRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).performLetsEncryptPreflightCheck(reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Making a staging website live - Validates that the operation is allowed for the website. Session holder must be at least a `SuperAdmin` in the org which owns the website, or a parent org. If the website is not owned by the MO, the subscription must have sufficient available \'websites\' resource */
    pushWebsiteLive: async (reqParams: WebsitesApiPushWebsiteLiveReq, options?: any): Promise<AxiosResponse<WebsitesApiPushWebsiteLiveRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).pushWebsiteLive(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Restart PHP container for a website -  */
    restartWebsitePhp: async (reqParams: WebsitesApiRestartWebsitePhpReq, options?: any): Promise<AxiosResponse<WebsitesApiRestartWebsitePhpRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).restartWebsitePhp(reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set Nginx FastCGI enablement -  */
    setDomainNginxFastCgi: async (reqParams: WebsitesApiSetDomainNginxFastCgiReq, options?: any): Promise<AxiosResponse<WebsitesApiSetDomainNginxFastCgiRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setDomainNginxFastCgi(reqParams.domainId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set web server rewrite to file -  */
    setDomainWebserverRewrite: async (reqParams: WebsitesApiSetDomainWebserverRewriteReq, options?: any): Promise<AxiosResponse<WebsitesApiSetDomainWebserverRewriteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setDomainWebserverRewrite(reqParams.domainId, reqParams.webServerRewrite, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the active cgroup limits for a website (Master org only) -  */
    setWebsiteCgroupLimits: async (reqParams: WebsitesApiSetWebsiteCgroupLimitsReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteCgroupLimitsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteCgroupLimits(reqParams.orgId, reqParams.websiteId, reqParams.setCgroupLimits, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set \"force ssl\" status for domain mapping -  */
    setWebsiteDomainForceSsl: async (reqParams: WebsitesApiSetWebsiteDomainForceSslReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteDomainForceSslRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteDomainForceSsl(reqParams.domainId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set mod security status on a single domain -  */
    setWebsiteDomainModSecStatus: async (reqParams: WebsitesApiSetWebsiteDomainModSecStatusReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteDomainModSecStatusRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteDomainModSecStatus(reqParams.domainId, reqParams.modSecStatus, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a custom vhost file -  */
    setWebsiteDomainVhost: async (reqParams: WebsitesApiSetWebsiteDomainVhostReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteDomainVhostRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteDomainVhost(reqParams.domainId, reqParams.vhost, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set the active FS quota limits for a website (Master org only) -  */
    setWebsiteFsQuotaLimits: async (reqParams: WebsitesApiSetWebsiteFsQuotaLimitsReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteFsQuotaLimitsRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteFsQuotaLimits(reqParams.orgId, reqParams.websiteId, reqParams.fsQuotaLimit, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set ioncube status for an existing website -  */
    setWebsiteIoncubeStatus: async (reqParams: WebsitesApiSetWebsiteIoncubeStatusReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteIoncubeStatusRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteIoncubeStatus(reqParams.websiteId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set Redis state for an existing website -  */
    setWebsiteRedisState: async (reqParams: WebsitesApiSetWebsiteRedisStateReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteRedisStateRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteRedisState(reqParams.websiteId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a single override setting - Set or replace a single website level service setting */
    setWebsiteSetting: async (reqParams: WebsitesApiSetWebsiteSettingReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteSettingRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteSetting(reqParams.orgId, reqParams.websiteId, reqParams.settingKind, reqParams.settingKey, reqParams.serviceSettingValue, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Take website screenshot immediately - Take website screenshot immediately */
    takeScreenshot: async (reqParams: WebsitesApiTakeScreenshotReq, options?: any): Promise<AxiosResponse<WebsitesApiTakeScreenshotRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).takeScreenshot(reqParams.orgId, reqParams.websiteId, reqParams.domainId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Unauthorize the public SSH key with the given ID. - This operation will unauthorize the given public SSH key by deleting its content from the website\'s .ssh/authorized_keys file. */
    unauthorizeWebsiteSshKey: async (reqParams: WebsitesApiUnauthorizeWebsiteSshKeyReq, options?: any): Promise<AxiosResponse<WebsitesApiUnauthorizeWebsiteSshKeyRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).unauthorizeWebsiteSshKey(reqParams.orgId, reqParams.websiteId, reqParams.keyId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update given FTP user - Endpoint for updating FTP user for a given website We only allow user\'s homeDir and password to be updated. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateFtpUser: async (reqParams: WebsitesApiUpdateFtpUserReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateFtpUserRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateFtpUser(reqParams.orgId, reqParams.websiteId, reqParams.username, reqParams.ftpUserUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update user\'s crontab - Update user\'s crontab. Note that users are able to update only cron expressions and environment variables. */
    updateUserCrontab: async (reqParams: WebsitesApiUpdateUserCrontabReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateUserCrontabRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateUserCrontab(reqParams.orgId, reqParams.websiteId, reqParams.updateCrontabFullListing, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update website - Updates the website. It may be used to enable or disable a specific version of PHP for a website, in which case a corresponding `PhpCd` instance is installed or uninstalled on the same server of the website. When enabling the website PHP it is also possible to specify whether the SSH daemon will need to be enabled in the `PhpCd` service at startup, via the `ssh` boolean flag. Moreover, if PHP is already enabled for a website, it is possible to enable or disable its SSH by only specifying the `ssh` flag. The endpoint is also responsible for assigning tags to a website. Note that the input overwrites all existing tags, so when adding a new tag, the `tags` property also has to include existing tags that are to be kept. Only a parent organization or MO admin may suspend websites. The website may be moved to another subscription, if that subscription has enough quota to accommodate the new website, unless the MO is performing the action, in which case they may move any website off a subscription or to a subscription that doesn\'t necessary have quota left. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    updateWebsite: async (reqParams: WebsitesApiUpdateWebsiteReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateWebsiteRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateWebsite(reqParams.orgId, reqParams.websiteId, reqParams.updateWebsite, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update website domain mapping - Partially updates the domain mapping. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWebsiteDomainMapping: async (reqParams: WebsitesApiUpdateWebsiteDomainMappingReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateWebsiteDomainMappingRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateWebsiteDomainMapping(reqParams.orgId, reqParams.websiteId, reqParams.domainId, reqParams.domainMappingUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Sets a rule over provided ips - either block or allow - Allowlisting or blocklisting IPs via .htaccess is done using Require ip rule. This endpoint puts the rule (creating .htaccess if it doesn\'t exist in process) into the public_html/.htaccess file. Note that this is not the same .htaccess that is used for writing redirect rules. In future this endpoint might be merged with the htaccess endpoint to allow writing rewrite rules and ip allow lists to any website directory. */
    updateWebsiteHtaccessIpsRule: async (reqParams: WebsitesApiUpdateWebsiteHtaccessIpsRuleReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateWebsiteHtaccessIpsRuleRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateWebsiteHtaccessIpsRule(reqParams.orgId, reqParams.websiteId, reqParams.requireIpsRule, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates rewrite rules - Rewrites are `RewriteRule`s in website\'s .htaccess file. We use terminology \"rewrite chain\" to refer to 0 or more `RewriteCond`s directive ended by `RewriteRule`. */
    updateWebsiteHtaccessRewrites: async (reqParams: WebsitesApiUpdateWebsiteHtaccessRewritesReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateWebsiteHtaccessRewritesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateWebsiteHtaccessRewrites(reqParams.orgId, reqParams.websiteId, reqParams.updateRewriteChainFullListing, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update primary domain mapping - This operation can only be done by a logged in superadmin or owner of the organization or its parent organization(s). Domain and website has to belong to this organization. */
    updateWebsitePrimaryDomain: async (reqParams: WebsitesApiUpdateWebsitePrimaryDomainReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateWebsitePrimaryDomainRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateWebsitePrimaryDomain(reqParams.orgId, reqParams.websiteId, reqParams.newPrimaryDomainMapping, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update an existing website public SSH key. - This operation will update the given public SSH key value, and/or its associated friendly name. An error will be returned if none of the expected arguments of the request body is specified. */
    updateWebsiteSshKey: async (reqParams: WebsitesApiUpdateWebsiteSshKeyReq, options?: any): Promise<AxiosResponse<WebsitesApiUpdateWebsiteSshKeyRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).updateWebsiteSshKey(reqParams.orgId, reqParams.websiteId, reqParams.keyId, reqParams.sshKeyUpdate, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload custom ssl certificate, key and optional fullchain for a given website - Endpoint for uploading custom SSL certificate for a given website. Verifies the cert key and maps to relevant domains that the certificate can be applied to. Returns error if no domain match is found. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    uploadWebsiteDomainSslCert: async (reqParams: WebsitesApiUploadWebsiteDomainSslCertReq, options?: any): Promise<AxiosResponse<WebsitesApiUploadWebsiteDomainSslCertRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).uploadWebsiteDomainSslCert(reqParams.domainId, reqParams.sslCert, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Upload SSL for mail.customerdomain. -  */
    uploadWebsiteMailDomainSslCert: async (reqParams: WebsitesApiUploadWebsiteMailDomainSslCertReq, options?: any): Promise<AxiosResponse<WebsitesApiUploadWebsiteMailDomainSslCertRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).uploadWebsiteMailDomainSslCert(reqParams.domainId, reqParams.sslCert, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Validate whether a website operation is allowed - Validates that the operation is allowed for the website. Currently this is only for verifying whether a website may be moved to another subscription, but this could later be expanded with other actions that can be verified. Session holder must be at least a `SuperAdmin` in this org or a parent org. */
    validateWebsiteOperation: async (reqParams: WebsitesApiValidateWebsiteOperationReq, options?: any): Promise<AxiosResponse<WebsitesApiValidateWebsiteOperationRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).validateWebsiteOperation(reqParams.orgId, reqParams.websiteId, reqParams.websiteOperationValidation, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface WebsitesApiReq {
    addDomainNginxFastCgiExcludedPath: WebsitesApiAddDomainNginxFastCgiExcludedPathReq;
    authorizeWebsiteSshKey: WebsitesApiAuthorizeWebsiteSshKeyReq;
    authorizeWebsiteSshPassword: WebsitesApiAuthorizeWebsiteSshPasswordReq;
    clearDomainNginxFastCgi: WebsitesApiClearDomainNginxFastCgiReq;
    cloneWebsite: WebsitesApiCloneWebsiteReq;
    createFtpUser: WebsitesApiCreateFtpUserReq;
    createPreviewDomain: WebsitesApiCreatePreviewDomainReq;
    createWebsite: WebsitesApiCreateWebsiteReq;
    createWebsiteDomainLetsencryptCerts: WebsitesApiCreateWebsiteDomainLetsencryptCertsReq;
    createWebsiteMailDomainLetsencryptCerts: WebsitesApiCreateWebsiteMailDomainLetsencryptCertsReq;
    createWebsiteMappedDomain: WebsitesApiCreateWebsiteMappedDomainReq;
    createWebsiteMySQLDB: WebsitesApiCreateWebsiteMySQLDBReq;
    deleteDomainNginxFastCgiExcludedPath: WebsitesApiDeleteDomainNginxFastCgiExcludedPathReq;
    deleteDomainWebserverRewrite: WebsitesApiDeleteDomainWebserverRewriteReq;
    deleteFtpUser: WebsitesApiDeleteFtpUserReq;
    deleteUserCrontab: WebsitesApiDeleteUserCrontabReq;
    deleteWebsite: WebsitesApiDeleteWebsiteReq;
    deleteWebsiteDomainMapping: WebsitesApiDeleteWebsiteDomainMappingReq;
    deleteWebsiteDomainVhost: WebsitesApiDeleteWebsiteDomainVhostReq;
    deleteWebsiteSetting: WebsitesApiDeleteWebsiteSettingReq;
    deleteWebsites: WebsitesApiDeleteWebsitesReq;
    disableWebsitePhpExtension: WebsitesApiDisableWebsitePhpExtensionReq;
    enableWebsitePhpExtension: WebsitesApiEnableWebsitePhpExtensionReq;
    getDomainNginxFastCgi: WebsitesApiGetDomainNginxFastCgiReq;
    getDomainNginxFastCgiExcludedPaths: WebsitesApiGetDomainNginxFastCgiExcludedPathsReq;
    getDomainWebserverRewrites: WebsitesApiGetDomainWebserverRewritesReq;
    getFtpUsers: WebsitesApiGetFtpUsersReq;
    getScreenshotTimestamp: WebsitesApiGetScreenshotTimestampReq;
    getSiteAccessToken: WebsitesApiGetSiteAccessTokenReq;
    getUserCrontab: WebsitesApiGetUserCrontabReq;
    getWebsite: WebsitesApiGetWebsiteReq;
    getWebsiteAvailablePhpExtensions: WebsitesApiGetWebsiteAvailablePhpExtensionsReq;
    getWebsiteBackupStatus: WebsitesApiGetWebsiteBackupStatusReq;
    getWebsiteCgroupLimits: WebsitesApiGetWebsiteCgroupLimitsReq;
    getWebsiteClone: WebsitesApiGetWebsiteCloneReq;
    getWebsiteCloneLog: WebsitesApiGetWebsiteCloneLogReq;
    getWebsiteClones: WebsitesApiGetWebsiteClonesReq;
    getWebsiteDomainDnsQuery: WebsitesApiGetWebsiteDomainDnsQueryReq;
    getWebsiteDomainMapping: WebsitesApiGetWebsiteDomainMappingReq;
    getWebsiteDomainMappingDnsStatus: WebsitesApiGetWebsiteDomainMappingDnsStatusReq;
    getWebsiteDomainMappings: WebsitesApiGetWebsiteDomainMappingsReq;
    getWebsiteDomainModSecStatus: WebsitesApiGetWebsiteDomainModSecStatusReq;
    getWebsiteDomainSslCert: WebsitesApiGetWebsiteDomainSslCertReq;
    getWebsiteDomainVhost: WebsitesApiGetWebsiteDomainVhostReq;
    getWebsiteEnabledPhpExtensions: WebsitesApiGetWebsiteEnabledPhpExtensionsReq;
    getWebsiteFsQuotaLimits: WebsitesApiGetWebsiteFsQuotaLimitsReq;
    getWebsiteHtaccessIpsRule: WebsitesApiGetWebsiteHtaccessIpsRuleReq;
    getWebsiteHtaccessRewrites: WebsitesApiGetWebsiteHtaccessRewritesReq;
    getWebsiteIoncubeStatus: WebsitesApiGetWebsiteIoncubeStatusReq;
    getWebsiteMailDomainSslCert: WebsitesApiGetWebsiteMailDomainSslCertReq;
    getWebsiteMetrics: WebsitesApiGetWebsiteMetricsReq;
    getWebsiteMySQLDBs: WebsitesApiGetWebsiteMySQLDBsReq;
    getWebsiteRedisState: WebsitesApiGetWebsiteRedisStateReq;
    getWebsiteServerDomains: WebsitesApiGetWebsiteServerDomainsReq;
    getWebsiteSetting: WebsitesApiGetWebsiteSettingReq;
    getWebsiteSshKeys: WebsitesApiGetWebsiteSshKeysReq;
    getWebsiteWebserverKind: WebsitesApiGetWebsiteWebserverKindReq;
    getWebsites: WebsitesApiGetWebsitesReq;
    performLetsEncryptPreflightCheck: WebsitesApiPerformLetsEncryptPreflightCheckReq;
    pushWebsiteLive: WebsitesApiPushWebsiteLiveReq;
    restartWebsitePhp: WebsitesApiRestartWebsitePhpReq;
    setDomainNginxFastCgi: WebsitesApiSetDomainNginxFastCgiReq;
    setDomainWebserverRewrite: WebsitesApiSetDomainWebserverRewriteReq;
    setWebsiteCgroupLimits: WebsitesApiSetWebsiteCgroupLimitsReq;
    setWebsiteDomainForceSsl: WebsitesApiSetWebsiteDomainForceSslReq;
    setWebsiteDomainModSecStatus: WebsitesApiSetWebsiteDomainModSecStatusReq;
    setWebsiteDomainVhost: WebsitesApiSetWebsiteDomainVhostReq;
    setWebsiteFsQuotaLimits: WebsitesApiSetWebsiteFsQuotaLimitsReq;
    setWebsiteIoncubeStatus: WebsitesApiSetWebsiteIoncubeStatusReq;
    setWebsiteRedisState: WebsitesApiSetWebsiteRedisStateReq;
    setWebsiteSetting: WebsitesApiSetWebsiteSettingReq;
    takeScreenshot: WebsitesApiTakeScreenshotReq;
    unauthorizeWebsiteSshKey: WebsitesApiUnauthorizeWebsiteSshKeyReq;
    updateFtpUser: WebsitesApiUpdateFtpUserReq;
    updateUserCrontab: WebsitesApiUpdateUserCrontabReq;
    updateWebsite: WebsitesApiUpdateWebsiteReq;
    updateWebsiteDomainMapping: WebsitesApiUpdateWebsiteDomainMappingReq;
    updateWebsiteHtaccessIpsRule: WebsitesApiUpdateWebsiteHtaccessIpsRuleReq;
    updateWebsiteHtaccessRewrites: WebsitesApiUpdateWebsiteHtaccessRewritesReq;
    updateWebsitePrimaryDomain: WebsitesApiUpdateWebsitePrimaryDomainReq;
    updateWebsiteSshKey: WebsitesApiUpdateWebsiteSshKeyReq;
    uploadWebsiteDomainSslCert: WebsitesApiUploadWebsiteDomainSslCertReq;
    uploadWebsiteMailDomainSslCert: WebsitesApiUploadWebsiteMailDomainSslCertReq;
    validateWebsiteOperation: WebsitesApiValidateWebsiteOperationReq;
}

export interface WebsitesApiRes {
    addDomainNginxFastCgiExcludedPath: WebsitesApiAddDomainNginxFastCgiExcludedPathRes;
    authorizeWebsiteSshKey: WebsitesApiAuthorizeWebsiteSshKeyRes;
    authorizeWebsiteSshPassword: WebsitesApiAuthorizeWebsiteSshPasswordRes;
    clearDomainNginxFastCgi: WebsitesApiClearDomainNginxFastCgiRes;
    cloneWebsite: WebsitesApiCloneWebsiteRes;
    createFtpUser: WebsitesApiCreateFtpUserRes;
    createPreviewDomain: WebsitesApiCreatePreviewDomainRes;
    createWebsite: WebsitesApiCreateWebsiteRes;
    createWebsiteDomainLetsencryptCerts: WebsitesApiCreateWebsiteDomainLetsencryptCertsRes;
    createWebsiteMailDomainLetsencryptCerts: WebsitesApiCreateWebsiteMailDomainLetsencryptCertsRes;
    createWebsiteMappedDomain: WebsitesApiCreateWebsiteMappedDomainRes;
    createWebsiteMySQLDB: WebsitesApiCreateWebsiteMySQLDBRes;
    deleteDomainNginxFastCgiExcludedPath: WebsitesApiDeleteDomainNginxFastCgiExcludedPathRes;
    deleteDomainWebserverRewrite: WebsitesApiDeleteDomainWebserverRewriteRes;
    deleteFtpUser: WebsitesApiDeleteFtpUserRes;
    deleteUserCrontab: WebsitesApiDeleteUserCrontabRes;
    deleteWebsite: WebsitesApiDeleteWebsiteRes;
    deleteWebsiteDomainMapping: WebsitesApiDeleteWebsiteDomainMappingRes;
    deleteWebsiteDomainVhost: WebsitesApiDeleteWebsiteDomainVhostRes;
    deleteWebsiteSetting: WebsitesApiDeleteWebsiteSettingRes;
    deleteWebsites: WebsitesApiDeleteWebsitesRes;
    disableWebsitePhpExtension: WebsitesApiDisableWebsitePhpExtensionRes;
    enableWebsitePhpExtension: WebsitesApiEnableWebsitePhpExtensionRes;
    getDomainNginxFastCgi: WebsitesApiGetDomainNginxFastCgiRes;
    getDomainNginxFastCgiExcludedPaths: WebsitesApiGetDomainNginxFastCgiExcludedPathsRes;
    getDomainWebserverRewrites: WebsitesApiGetDomainWebserverRewritesRes;
    getFtpUsers: WebsitesApiGetFtpUsersRes;
    getScreenshotTimestamp: WebsitesApiGetScreenshotTimestampRes;
    getSiteAccessToken: WebsitesApiGetSiteAccessTokenRes;
    getUserCrontab: WebsitesApiGetUserCrontabRes;
    getWebsite: WebsitesApiGetWebsiteRes;
    getWebsiteAvailablePhpExtensions: WebsitesApiGetWebsiteAvailablePhpExtensionsRes;
    getWebsiteBackupStatus: WebsitesApiGetWebsiteBackupStatusRes;
    getWebsiteCgroupLimits: WebsitesApiGetWebsiteCgroupLimitsRes;
    getWebsiteClone: WebsitesApiGetWebsiteCloneRes;
    getWebsiteCloneLog: WebsitesApiGetWebsiteCloneLogRes;
    getWebsiteClones: WebsitesApiGetWebsiteClonesRes;
    getWebsiteDomainDnsQuery: WebsitesApiGetWebsiteDomainDnsQueryRes;
    getWebsiteDomainMapping: WebsitesApiGetWebsiteDomainMappingRes;
    getWebsiteDomainMappingDnsStatus: WebsitesApiGetWebsiteDomainMappingDnsStatusRes;
    getWebsiteDomainMappings: WebsitesApiGetWebsiteDomainMappingsRes;
    getWebsiteDomainModSecStatus: WebsitesApiGetWebsiteDomainModSecStatusRes;
    getWebsiteDomainSslCert: WebsitesApiGetWebsiteDomainSslCertRes;
    getWebsiteDomainVhost: WebsitesApiGetWebsiteDomainVhostRes;
    getWebsiteEnabledPhpExtensions: WebsitesApiGetWebsiteEnabledPhpExtensionsRes;
    getWebsiteFsQuotaLimits: WebsitesApiGetWebsiteFsQuotaLimitsRes;
    getWebsiteHtaccessIpsRule: WebsitesApiGetWebsiteHtaccessIpsRuleRes;
    getWebsiteHtaccessRewrites: WebsitesApiGetWebsiteHtaccessRewritesRes;
    getWebsiteIoncubeStatus: WebsitesApiGetWebsiteIoncubeStatusRes;
    getWebsiteMailDomainSslCert: WebsitesApiGetWebsiteMailDomainSslCertRes;
    getWebsiteMetrics: WebsitesApiGetWebsiteMetricsRes;
    getWebsiteMySQLDBs: WebsitesApiGetWebsiteMySQLDBsRes;
    getWebsiteRedisState: WebsitesApiGetWebsiteRedisStateRes;
    getWebsiteServerDomains: WebsitesApiGetWebsiteServerDomainsRes;
    getWebsiteSetting: WebsitesApiGetWebsiteSettingRes;
    getWebsiteSshKeys: WebsitesApiGetWebsiteSshKeysRes;
    getWebsiteWebserverKind: WebsitesApiGetWebsiteWebserverKindRes;
    getWebsites: WebsitesApiGetWebsitesRes;
    performLetsEncryptPreflightCheck: WebsitesApiPerformLetsEncryptPreflightCheckRes;
    pushWebsiteLive: WebsitesApiPushWebsiteLiveRes;
    restartWebsitePhp: WebsitesApiRestartWebsitePhpRes;
    setDomainNginxFastCgi: WebsitesApiSetDomainNginxFastCgiRes;
    setDomainWebserverRewrite: WebsitesApiSetDomainWebserverRewriteRes;
    setWebsiteCgroupLimits: WebsitesApiSetWebsiteCgroupLimitsRes;
    setWebsiteDomainForceSsl: WebsitesApiSetWebsiteDomainForceSslRes;
    setWebsiteDomainModSecStatus: WebsitesApiSetWebsiteDomainModSecStatusRes;
    setWebsiteDomainVhost: WebsitesApiSetWebsiteDomainVhostRes;
    setWebsiteFsQuotaLimits: WebsitesApiSetWebsiteFsQuotaLimitsRes;
    setWebsiteIoncubeStatus: WebsitesApiSetWebsiteIoncubeStatusRes;
    setWebsiteRedisState: WebsitesApiSetWebsiteRedisStateRes;
    setWebsiteSetting: WebsitesApiSetWebsiteSettingRes;
    takeScreenshot: WebsitesApiTakeScreenshotRes;
    unauthorizeWebsiteSshKey: WebsitesApiUnauthorizeWebsiteSshKeyRes;
    updateFtpUser: WebsitesApiUpdateFtpUserRes;
    updateUserCrontab: WebsitesApiUpdateUserCrontabRes;
    updateWebsite: WebsitesApiUpdateWebsiteRes;
    updateWebsiteDomainMapping: WebsitesApiUpdateWebsiteDomainMappingRes;
    updateWebsiteHtaccessIpsRule: WebsitesApiUpdateWebsiteHtaccessIpsRuleRes;
    updateWebsiteHtaccessRewrites: WebsitesApiUpdateWebsiteHtaccessRewritesRes;
    updateWebsitePrimaryDomain: WebsitesApiUpdateWebsitePrimaryDomainRes;
    updateWebsiteSshKey: WebsitesApiUpdateWebsiteSshKeyRes;
    uploadWebsiteDomainSslCert: WebsitesApiUploadWebsiteDomainSslCertRes;
    uploadWebsiteMailDomainSslCert: WebsitesApiUploadWebsiteMailDomainSslCertRes;
    validateWebsiteOperation: WebsitesApiValidateWebsiteOperationRes;
}


/**
 * WordpressApi - axios parameter creator
 * @export
 */
export const WordpressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Activate a WordPress theme -  */
        activateWordpressTheme: async (orgId: string, websiteId: string, appId: string, theme: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/themes/{theme}/activate`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"theme"}}`, encodeURIComponent(String(theme)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Create website WordPress user - Creates a new user in this wordpress app. The created user is independent from Enhance logins--it only concerns the wordpress app (which much like Enhance is its own webapp). Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        createWordpressUser: async (orgId: string, websiteId: string, appId: string, newWpUser: NewWpUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof newWpUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(newWpUser !== undefined ? newWpUser : {}) : (newWpUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete website WordPress plugin - Deletes the specified wordpress plugin. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWordpressPlugin: async (orgId: string, websiteId: string, appId: string, plugin: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/plugins/{plugin}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"plugin"}}`, encodeURIComponent(String(plugin)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete a WordPress theme -  */
        deleteWordpressTheme: async (orgId: string, websiteId: string, appId: string, theme: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/themes/{theme}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"theme"}}`, encodeURIComponent(String(theme)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete WordPress user - Deletes an existing user in this wordpress app. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        deleteWordpressUser: async (orgId: string, websiteId: string, appId: string, userId: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users/{user_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Return previously set default Wordpress SSO user. If WP users exist but none were set to be default, returns 404. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getDefaultWpSsoUser: async (orgId: string, websiteId: string, appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users/default`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the MaintenanceMode for a WordPress installation -  */
        getWordPressMaintenanceMode: async (appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/apps/{app_id}/wordpress/maintenance-mode`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Fetches the site URL for a WordPress installation -  */
        getWordPressSiteurl: async (appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/apps/{app_id}/wordpress/url`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get WordPress version - Fetches the WordPress version of a running installation in real time. */
        getWordpressAppVersion: async (orgId: string, websiteId: string, appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/version`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get the WP config value for a given option - Returns the value of a wp-config.php entry. */
        getWordpressConfig: async (orgId: string, websiteId: string, appId: string, wpOption: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/wp-config/{wp_option}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"wp_option"}}`, encodeURIComponent(String(wpOption)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Trigger discovery of WP installations - WP installations that were made manually (aside from invoking) orchd APIs aren\'t immediately discovered by orchd. Invoking this endpoint triggers the discovery and adds installation info to the database. */
        getWordpressInstallations: async (orgId: string, websiteId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/wordpress`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get WordPress latest available version - Returns the latest available WordPress version as published by the WordPress APIs. */
        getWordpressLatestVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/utils/wordpress/latest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website WordPress plugins - Returns the plugins installed on wordpress. This is a separate endpoint as it is takes longer to return than the rest of the application endpoints. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWordpressPlugins: async (orgId: string, websiteId: string, appId: string, refreshCache?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/plugins`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (refreshCache !== undefined) {
                localVarQueryParameter['refreshCache'] = refreshCache;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get Wordpress application settings - Queries an existing Wordpress application\'s settings. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWordpressSettings: async (orgId: string, websiteId: string, appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get website WordPress themes - Returns the themes installed on website\'s WordPress. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWordpressThemes: async (orgId: string, websiteId: string, appId: string, refreshCache?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/themes`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (refreshCache !== undefined) {
                localVarQueryParameter['refreshCache'] = refreshCache;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Get SSO URL for a WP user - Session holder must have write access to the website */
        getWordpressUserSsoUrl: async (orgId: string, websiteId: string, appId: string, userId: number, shouldRedirect?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users/{user_id}/sso`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (shouldRedirect !== undefined) {
                localVarQueryParameter['shouldRedirect'] = shouldRedirect;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Returns the users of this wordpress app. This is a separate endpoint as it is takes longer to return than most other endpoints. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        getWordpressUsers: async (orgId: string, websiteId: string, appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Install a plugin - Adds a specific plugin to a WordPress installation. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        installWordpressPlugin: async (orgId: string, websiteId: string, appId: string, installWpPlugin: InstallWpPlugin, refreshCache?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/plugins`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (refreshCache !== undefined) {
                localVarQueryParameter['refreshCache'] = refreshCache;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof installWpPlugin !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(installWpPlugin !== undefined ? installWpPlugin : {}) : (installWpPlugin || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Install a WordPress theme -  */
        installWordpressTheme: async (orgId: string, websiteId: string, appId: string, installWpThemeRequest: InstallWpThemeRequest, refreshCache?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/themes`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (refreshCache !== undefined) {
                localVarQueryParameter['refreshCache'] = refreshCache;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof installWpThemeRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(installWpThemeRequest !== undefined ? installWpThemeRequest : {}) : (installWpThemeRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set WP user as the default SSO user for that website. - Idempotently set WP user as the default SSO user for that website. User needs to exist. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        setDefaultWpSsoUser: async (orgId: string, websiteId: string, appId: string, body: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users/default`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Sets the MaintenanceMode for a WordPress installation -  */
        setWordPressMaintenanceMode: async (appId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/apps/{app_id}/wordpress/maintenance-mode`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Sets the site URL for a WordPress installation -  */
        setWordPressSiteurl: async (appId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/apps/{app_id}/wordpress/url`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set a single value of a wp-config.php entry. -  */
        setWordpressConfig: async (orgId: string, websiteId: string, appId: string, wordpressConfig: WordpressConfig, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/wp-config`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof wordpressConfig !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(wordpressConfig !== undefined ? wordpressConfig : {}) : (wordpressConfig || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Set WordPress theme auto-update status -  */
        setWordpressThemeAutoUpdateStatus: async (orgId: string, websiteId: string, appId: string, theme: string, body: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/themes/{theme}/auto_update`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"theme"}}`, encodeURIComponent(String(theme)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update website WP app to specific version or latest - Updates an existing website Wordpress application\'s version to given version (defaults to latest). If the installation is already on its latest version, returns 200 without doing any work. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWordpressAppVersion: async (orgId: string, websiteId: string, appId: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/version`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates website WordPress plugin settings - Updates the settings for a WP plugin, such as whether the plugin should be active. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWordpressPluginSettings: async (orgId: string, websiteId: string, appId: string, plugin: string, updateWpPlugin: UpdateWpPlugin, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/plugins/{plugin}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"plugin"}}`, encodeURIComponent(String(plugin)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateWpPlugin !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateWpPlugin !== undefined ? updateWpPlugin : {}) : (updateWpPlugin || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Updates website WordPress plugin to latest version - Updates the specified wordpress plugin to its latest version. Does nothing if the plugin is already latest. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWordpressPluginToLatest: async (orgId: string, websiteId: string, appId: string, plugin: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/plugins/{plugin}/version`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"plugin"}}`, encodeURIComponent(String(plugin)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update Wordpress app settings - Updates an existing website WP application\'s settings. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWordpressSettings: async (orgId: string, websiteId: string, appId: string, updateWpSettings: UpdateWpSettings, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateWpSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateWpSettings !== undefined ? updateWpSettings : {}) : (updateWpSettings || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update a WordPress theme -  */
        updateWordpressTheme: async (orgId: string, websiteId: string, appId: string, theme: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/themes/{theme}/update`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"theme"}}`, encodeURIComponent(String(theme)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Update WordPress user - Updates an existing user in this wordpress app. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
        updateWordpressUser: async (orgId: string, websiteId: string, appId: string, userId: number, updateWpUser: UpdateWpUser, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/orgs/{org_id}/websites/{website_id}/apps/{app_id}/wordpress/users/{user_id}`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateWpUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateWpUser !== undefined ? updateWpUser : {}) : (updateWpUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for activateWordpressTheme operation in WordpressApi. */
export type WordpressApiActivateWordpressThemeReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress theme (not file name!). */
    theme: string
};

/** Response parameters for activateWordpressTheme operation in WordpressApi. */
export type WordpressApiActivateWordpressThemeRes = void;


/** Request parameters for createWordpressUser operation in WordpressApi. */
export type WordpressApiCreateWordpressUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    newWpUser: NewWpUser
};

/** Response parameters for createWordpressUser operation in WordpressApi. */
export type WordpressApiCreateWordpressUserRes = void;


/** Request parameters for deleteWordpressPlugin operation in WordpressApi. */
export type WordpressApiDeleteWordpressPluginReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress plugin (not file name!). */
    plugin: string
};

/** Response parameters for deleteWordpressPlugin operation in WordpressApi. */
export type WordpressApiDeleteWordpressPluginRes = void;


/** Request parameters for deleteWordpressTheme operation in WordpressApi. */
export type WordpressApiDeleteWordpressThemeReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress theme (not file name!). */
    theme: string
};

/** Response parameters for deleteWordpressTheme operation in WordpressApi. */
export type WordpressApiDeleteWordpressThemeRes = void;


/** Request parameters for deleteWordpressUser operation in WordpressApi. */
export type WordpressApiDeleteWordpressUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The id of the wordpress user. */
    userId: number
};

/** Response parameters for deleteWordpressUser operation in WordpressApi. */
export type WordpressApiDeleteWordpressUserRes = void;


/** Request parameters for getDefaultWpSsoUser operation in WordpressApi. */
export type WordpressApiGetDefaultWpSsoUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
};

/** Response parameters for getDefaultWpSsoUser operation in WordpressApi. */
export type WordpressApiGetDefaultWpSsoUserRes = WpUser;


/** Request parameters for getWordPressMaintenanceMode operation in WordpressApi. */
export type WordpressApiGetWordPressMaintenanceModeReq = {
    /** The id of the app. */
    appId: string
};

/** Response parameters for getWordPressMaintenanceMode operation in WordpressApi. */
export type WordpressApiGetWordPressMaintenanceModeRes = MaintenanceModeStatus;


/** Request parameters for getWordPressSiteurl operation in WordpressApi. */
export type WordpressApiGetWordPressSiteurlReq = {
    /** The id of the app. */
    appId: string
};

/** Response parameters for getWordPressSiteurl operation in WordpressApi. */
export type WordpressApiGetWordPressSiteurlRes = string;


/** Request parameters for getWordpressAppVersion operation in WordpressApi. */
export type WordpressApiGetWordpressAppVersionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
};

/** Response parameters for getWordpressAppVersion operation in WordpressApi. */
export type WordpressApiGetWordpressAppVersionRes = InlineResponse200;


/** Request parameters for getWordpressConfig operation in WordpressApi. */
export type WordpressApiGetWordpressConfigReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The wordpress config option. */
    wpOption: string
};

/** Response parameters for getWordpressConfig operation in WordpressApi. */
export type WordpressApiGetWordpressConfigRes = WordpressConfig;


/** Request parameters for getWordpressInstallations operation in WordpressApi. */
export type WordpressApiGetWordpressInstallationsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
};

/** Response parameters for getWordpressInstallations operation in WordpressApi. */
export type WordpressApiGetWordpressInstallationsRes = Array<WpInstallation>;


/** Request parameters for getWordpressLatestVersion operation in WordpressApi. */
export type WordpressApiGetWordpressLatestVersionReq = {
};

/** Response parameters for getWordpressLatestVersion operation in WordpressApi. */
export type WordpressApiGetWordpressLatestVersionRes = WpLatestVersion;


/** Request parameters for getWordpressPlugins operation in WordpressApi. */
export type WordpressApiGetWordpressPluginsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** If set to true, it will bypass internal caching. */
    refreshCache?: boolean
};

/** Response parameters for getWordpressPlugins operation in WordpressApi. */
export type WordpressApiGetWordpressPluginsRes = WpPluginsFullListing;


/** Request parameters for getWordpressSettings operation in WordpressApi. */
export type WordpressApiGetWordpressSettingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
};

/** Response parameters for getWordpressSettings operation in WordpressApi. */
export type WordpressApiGetWordpressSettingsRes = WpSettings;


/** Request parameters for getWordpressThemes operation in WordpressApi. */
export type WordpressApiGetWordpressThemesReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** If set to true, it will bypass internal caching. */
    refreshCache?: boolean
};

/** Response parameters for getWordpressThemes operation in WordpressApi. */
export type WordpressApiGetWordpressThemesRes = WpThemesFullListing;


/** Request parameters for getWordpressUserSsoUrl operation in WordpressApi. */
export type WordpressApiGetWordpressUserSsoUrlReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The id of the wordpress user. */
    userId: number
    /** If set to true, the endpoint will send a 307 redirect to the SSO URL. */
    shouldRedirect?: boolean
};

/** Response parameters for getWordpressUserSsoUrl operation in WordpressApi. */
export type WordpressApiGetWordpressUserSsoUrlRes = string;


/** Request parameters for getWordpressUsers operation in WordpressApi. */
export type WordpressApiGetWordpressUsersReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
};

/** Response parameters for getWordpressUsers operation in WordpressApi. */
export type WordpressApiGetWordpressUsersRes = WpUsersFullListing;


/** Request parameters for installWordpressPlugin operation in WordpressApi. */
export type WordpressApiInstallWordpressPluginReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    installWpPlugin: InstallWpPlugin
    /** If set to true, it will bypass internal caching. */
    refreshCache?: boolean
};

/** Response parameters for installWordpressPlugin operation in WordpressApi. */
export type WordpressApiInstallWordpressPluginRes = void;


/** Request parameters for installWordpressTheme operation in WordpressApi. */
export type WordpressApiInstallWordpressThemeReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    installWpThemeRequest: InstallWpThemeRequest
    /** If set to true, it will bypass internal caching. */
    refreshCache?: boolean
};

/** Response parameters for installWordpressTheme operation in WordpressApi. */
export type WordpressApiInstallWordpressThemeRes = void;


/** Request parameters for setDefaultWpSsoUser operation in WordpressApi. */
export type WordpressApiSetDefaultWpSsoUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    body: number
};

/** Response parameters for setDefaultWpSsoUser operation in WordpressApi. */
export type WordpressApiSetDefaultWpSsoUserRes = void;


/** Request parameters for setWordPressMaintenanceMode operation in WordpressApi. */
export type WordpressApiSetWordPressMaintenanceModeReq = {
    /** The id of the app. */
    appId: string
    body: string
};

/** Response parameters for setWordPressMaintenanceMode operation in WordpressApi. */
export type WordpressApiSetWordPressMaintenanceModeRes = void;


/** Request parameters for setWordPressSiteurl operation in WordpressApi. */
export type WordpressApiSetWordPressSiteurlReq = {
    /** The id of the app. */
    appId: string
    body: string
};

/** Response parameters for setWordPressSiteurl operation in WordpressApi. */
export type WordpressApiSetWordPressSiteurlRes = void;


/** Request parameters for setWordpressConfig operation in WordpressApi. */
export type WordpressApiSetWordpressConfigReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    wordpressConfig: WordpressConfig
};

/** Response parameters for setWordpressConfig operation in WordpressApi. */
export type WordpressApiSetWordpressConfigRes = void;


/** Request parameters for setWordpressThemeAutoUpdateStatus operation in WordpressApi. */
export type WordpressApiSetWordpressThemeAutoUpdateStatusReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress theme (not file name!). */
    theme: string
    body: boolean
};

/** Response parameters for setWordpressThemeAutoUpdateStatus operation in WordpressApi. */
export type WordpressApiSetWordpressThemeAutoUpdateStatusRes = void;


/** Request parameters for updateWordpressAppVersion operation in WordpressApi. */
export type WordpressApiUpdateWordpressAppVersionReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
};

/** Response parameters for updateWordpressAppVersion operation in WordpressApi. */
export type WordpressApiUpdateWordpressAppVersionRes = void;


/** Request parameters for updateWordpressPluginSettings operation in WordpressApi. */
export type WordpressApiUpdateWordpressPluginSettingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress plugin (not file name!). */
    plugin: string
    updateWpPlugin: UpdateWpPlugin
};

/** Response parameters for updateWordpressPluginSettings operation in WordpressApi. */
export type WordpressApiUpdateWordpressPluginSettingsRes = void;


/** Request parameters for updateWordpressPluginToLatest operation in WordpressApi. */
export type WordpressApiUpdateWordpressPluginToLatestReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress plugin (not file name!). */
    plugin: string
};

/** Response parameters for updateWordpressPluginToLatest operation in WordpressApi. */
export type WordpressApiUpdateWordpressPluginToLatestRes = void;


/** Request parameters for updateWordpressSettings operation in WordpressApi. */
export type WordpressApiUpdateWordpressSettingsReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    updateWpSettings: UpdateWpSettings
};

/** Response parameters for updateWordpressSettings operation in WordpressApi. */
export type WordpressApiUpdateWordpressSettingsRes = void;


/** Request parameters for updateWordpressTheme operation in WordpressApi. */
export type WordpressApiUpdateWordpressThemeReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The name of the wordpress theme (not file name!). */
    theme: string
};

/** Response parameters for updateWordpressTheme operation in WordpressApi. */
export type WordpressApiUpdateWordpressThemeRes = void;


/** Request parameters for updateWordpressUser operation in WordpressApi. */
export type WordpressApiUpdateWordpressUserReq = {
    /** The id of the organization. */
    orgId: string
    /** The id of the website. */
    websiteId: string
    /** The id of the app. */
    appId: string
    /** The id of the wordpress user. */
    userId: number
    updateWpUser: UpdateWpUser
};

/** Response parameters for updateWordpressUser operation in WordpressApi. */
export type WordpressApiUpdateWordpressUserRes = void;

/**
 * WordpressApi - service client interface
 */
export const WordpressApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Activate a WordPress theme -  */
    activateWordpressTheme: async (reqParams: WordpressApiActivateWordpressThemeReq, options?: any): Promise<AxiosResponse<WordpressApiActivateWordpressThemeRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).activateWordpressTheme(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.theme, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Create website WordPress user - Creates a new user in this wordpress app. The created user is independent from Enhance logins--it only concerns the wordpress app (which much like Enhance is its own webapp). Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    createWordpressUser: async (reqParams: WordpressApiCreateWordpressUserReq, options?: any): Promise<AxiosResponse<WordpressApiCreateWordpressUserRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).createWordpressUser(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.newWpUser, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete website WordPress plugin - Deletes the specified wordpress plugin. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWordpressPlugin: async (reqParams: WordpressApiDeleteWordpressPluginReq, options?: any): Promise<AxiosResponse<WordpressApiDeleteWordpressPluginRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).deleteWordpressPlugin(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.plugin, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete a WordPress theme -  */
    deleteWordpressTheme: async (reqParams: WordpressApiDeleteWordpressThemeReq, options?: any): Promise<AxiosResponse<WordpressApiDeleteWordpressThemeRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).deleteWordpressTheme(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.theme, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete WordPress user - Deletes an existing user in this wordpress app. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    deleteWordpressUser: async (reqParams: WordpressApiDeleteWordpressUserReq, options?: any): Promise<AxiosResponse<WordpressApiDeleteWordpressUserRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).deleteWordpressUser(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.userId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Return previously set default Wordpress SSO user. If WP users exist but none were set to be default, returns 404. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getDefaultWpSsoUser: async (reqParams: WordpressApiGetDefaultWpSsoUserReq, options?: any): Promise<AxiosResponse<WordpressApiGetDefaultWpSsoUserRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getDefaultWpSsoUser(reqParams.orgId, reqParams.websiteId, reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the MaintenanceMode for a WordPress installation -  */
    getWordPressMaintenanceMode: async (reqParams: WordpressApiGetWordPressMaintenanceModeReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordPressMaintenanceModeRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordPressMaintenanceMode(reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Fetches the site URL for a WordPress installation -  */
    getWordPressSiteurl: async (reqParams: WordpressApiGetWordPressSiteurlReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordPressSiteurlRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordPressSiteurl(reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get WordPress version - Fetches the WordPress version of a running installation in real time. */
    getWordpressAppVersion: async (reqParams: WordpressApiGetWordpressAppVersionReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressAppVersionRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressAppVersion(reqParams.orgId, reqParams.websiteId, reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get the WP config value for a given option - Returns the value of a wp-config.php entry. */
    getWordpressConfig: async (reqParams: WordpressApiGetWordpressConfigReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressConfigRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressConfig(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.wpOption, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Trigger discovery of WP installations - WP installations that were made manually (aside from invoking) orchd APIs aren\'t immediately discovered by orchd. Invoking this endpoint triggers the discovery and adds installation info to the database. */
    getWordpressInstallations: async (reqParams: WordpressApiGetWordpressInstallationsReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressInstallationsRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressInstallations(reqParams.orgId, reqParams.websiteId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get WordPress latest available version - Returns the latest available WordPress version as published by the WordPress APIs. */
    getWordpressLatestVersion: async (reqParams: WordpressApiGetWordpressLatestVersionReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressLatestVersionRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressLatestVersion(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website WordPress plugins - Returns the plugins installed on wordpress. This is a separate endpoint as it is takes longer to return than the rest of the application endpoints. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWordpressPlugins: async (reqParams: WordpressApiGetWordpressPluginsReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressPluginsRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressPlugins(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.refreshCache, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get Wordpress application settings - Queries an existing Wordpress application\'s settings. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWordpressSettings: async (reqParams: WordpressApiGetWordpressSettingsReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressSettingsRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressSettings(reqParams.orgId, reqParams.websiteId, reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get website WordPress themes - Returns the themes installed on website\'s WordPress. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWordpressThemes: async (reqParams: WordpressApiGetWordpressThemesReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressThemesRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressThemes(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.refreshCache, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Get SSO URL for a WP user - Session holder must have write access to the website */
    getWordpressUserSsoUrl: async (reqParams: WordpressApiGetWordpressUserSsoUrlReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressUserSsoUrlRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressUserSsoUrl(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.userId, reqParams.shouldRedirect, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Returns the users of this wordpress app. This is a separate endpoint as it is takes longer to return than most other endpoints. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    getWordpressUsers: async (reqParams: WordpressApiGetWordpressUsersReq, options?: any): Promise<AxiosResponse<WordpressApiGetWordpressUsersRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).getWordpressUsers(reqParams.orgId, reqParams.websiteId, reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Install a plugin - Adds a specific plugin to a WordPress installation. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    installWordpressPlugin: async (reqParams: WordpressApiInstallWordpressPluginReq, options?: any): Promise<AxiosResponse<WordpressApiInstallWordpressPluginRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).installWordpressPlugin(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.installWpPlugin, reqParams.refreshCache, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Install a WordPress theme -  */
    installWordpressTheme: async (reqParams: WordpressApiInstallWordpressThemeReq, options?: any): Promise<AxiosResponse<WordpressApiInstallWordpressThemeRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).installWordpressTheme(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.installWpThemeRequest, reqParams.refreshCache, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set WP user as the default SSO user for that website. - Idempotently set WP user as the default SSO user for that website. User needs to exist. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    setDefaultWpSsoUser: async (reqParams: WordpressApiSetDefaultWpSsoUserReq, options?: any): Promise<AxiosResponse<WordpressApiSetDefaultWpSsoUserRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).setDefaultWpSsoUser(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Sets the MaintenanceMode for a WordPress installation -  */
    setWordPressMaintenanceMode: async (reqParams: WordpressApiSetWordPressMaintenanceModeReq, options?: any): Promise<AxiosResponse<WordpressApiSetWordPressMaintenanceModeRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).setWordPressMaintenanceMode(reqParams.appId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Sets the site URL for a WordPress installation -  */
    setWordPressSiteurl: async (reqParams: WordpressApiSetWordPressSiteurlReq, options?: any): Promise<AxiosResponse<WordpressApiSetWordPressSiteurlRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).setWordPressSiteurl(reqParams.appId, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set a single value of a wp-config.php entry. -  */
    setWordpressConfig: async (reqParams: WordpressApiSetWordpressConfigReq, options?: any): Promise<AxiosResponse<WordpressApiSetWordpressConfigRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).setWordpressConfig(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.wordpressConfig, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Set WordPress theme auto-update status -  */
    setWordpressThemeAutoUpdateStatus: async (reqParams: WordpressApiSetWordpressThemeAutoUpdateStatusReq, options?: any): Promise<AxiosResponse<WordpressApiSetWordpressThemeAutoUpdateStatusRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).setWordpressThemeAutoUpdateStatus(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.theme, reqParams.body, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update website WP app to specific version or latest - Updates an existing website Wordpress application\'s version to given version (defaults to latest). If the installation is already on its latest version, returns 200 without doing any work. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWordpressAppVersion: async (reqParams: WordpressApiUpdateWordpressAppVersionReq, options?: any): Promise<AxiosResponse<WordpressApiUpdateWordpressAppVersionRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).updateWordpressAppVersion(reqParams.orgId, reqParams.websiteId, reqParams.appId, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates website WordPress plugin settings - Updates the settings for a WP plugin, such as whether the plugin should be active. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWordpressPluginSettings: async (reqParams: WordpressApiUpdateWordpressPluginSettingsReq, options?: any): Promise<AxiosResponse<WordpressApiUpdateWordpressPluginSettingsRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).updateWordpressPluginSettings(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.plugin, reqParams.updateWpPlugin, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Updates website WordPress plugin to latest version - Updates the specified wordpress plugin to its latest version. Does nothing if the plugin is already latest. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWordpressPluginToLatest: async (reqParams: WordpressApiUpdateWordpressPluginToLatestReq, options?: any): Promise<AxiosResponse<WordpressApiUpdateWordpressPluginToLatestRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).updateWordpressPluginToLatest(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.plugin, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update Wordpress app settings - Updates an existing website WP application\'s settings. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWordpressSettings: async (reqParams: WordpressApiUpdateWordpressSettingsReq, options?: any): Promise<AxiosResponse<WordpressApiUpdateWordpressSettingsRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).updateWordpressSettings(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.updateWpSettings, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update a WordPress theme -  */
    updateWordpressTheme: async (reqParams: WordpressApiUpdateWordpressThemeReq, options?: any): Promise<AxiosResponse<WordpressApiUpdateWordpressThemeRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).updateWordpressTheme(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.theme, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Update WordPress user - Updates an existing user in this wordpress app. Session holder must be at least a `SuperAdmin` in this org or a parent org, or be a member in this org that has access to the website. */
    updateWordpressUser: async (reqParams: WordpressApiUpdateWordpressUserReq, options?: any): Promise<AxiosResponse<WordpressApiUpdateWordpressUserRes>> => {
        const localVarAxiosArgs = await WordpressApiAxiosParamCreator(configuration).updateWordpressUser(reqParams.orgId, reqParams.websiteId, reqParams.appId, reqParams.userId, reqParams.updateWpUser, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface WordpressApiReq {
    activateWordpressTheme: WordpressApiActivateWordpressThemeReq;
    createWordpressUser: WordpressApiCreateWordpressUserReq;
    deleteWordpressPlugin: WordpressApiDeleteWordpressPluginReq;
    deleteWordpressTheme: WordpressApiDeleteWordpressThemeReq;
    deleteWordpressUser: WordpressApiDeleteWordpressUserReq;
    getDefaultWpSsoUser: WordpressApiGetDefaultWpSsoUserReq;
    getWordPressMaintenanceMode: WordpressApiGetWordPressMaintenanceModeReq;
    getWordPressSiteurl: WordpressApiGetWordPressSiteurlReq;
    getWordpressAppVersion: WordpressApiGetWordpressAppVersionReq;
    getWordpressConfig: WordpressApiGetWordpressConfigReq;
    getWordpressInstallations: WordpressApiGetWordpressInstallationsReq;
    getWordpressLatestVersion: WordpressApiGetWordpressLatestVersionReq;
    getWordpressPlugins: WordpressApiGetWordpressPluginsReq;
    getWordpressSettings: WordpressApiGetWordpressSettingsReq;
    getWordpressThemes: WordpressApiGetWordpressThemesReq;
    getWordpressUserSsoUrl: WordpressApiGetWordpressUserSsoUrlReq;
    getWordpressUsers: WordpressApiGetWordpressUsersReq;
    installWordpressPlugin: WordpressApiInstallWordpressPluginReq;
    installWordpressTheme: WordpressApiInstallWordpressThemeReq;
    setDefaultWpSsoUser: WordpressApiSetDefaultWpSsoUserReq;
    setWordPressMaintenanceMode: WordpressApiSetWordPressMaintenanceModeReq;
    setWordPressSiteurl: WordpressApiSetWordPressSiteurlReq;
    setWordpressConfig: WordpressApiSetWordpressConfigReq;
    setWordpressThemeAutoUpdateStatus: WordpressApiSetWordpressThemeAutoUpdateStatusReq;
    updateWordpressAppVersion: WordpressApiUpdateWordpressAppVersionReq;
    updateWordpressPluginSettings: WordpressApiUpdateWordpressPluginSettingsReq;
    updateWordpressPluginToLatest: WordpressApiUpdateWordpressPluginToLatestReq;
    updateWordpressSettings: WordpressApiUpdateWordpressSettingsReq;
    updateWordpressTheme: WordpressApiUpdateWordpressThemeReq;
    updateWordpressUser: WordpressApiUpdateWordpressUserReq;
}

export interface WordpressApiRes {
    activateWordpressTheme: WordpressApiActivateWordpressThemeRes;
    createWordpressUser: WordpressApiCreateWordpressUserRes;
    deleteWordpressPlugin: WordpressApiDeleteWordpressPluginRes;
    deleteWordpressTheme: WordpressApiDeleteWordpressThemeRes;
    deleteWordpressUser: WordpressApiDeleteWordpressUserRes;
    getDefaultWpSsoUser: WordpressApiGetDefaultWpSsoUserRes;
    getWordPressMaintenanceMode: WordpressApiGetWordPressMaintenanceModeRes;
    getWordPressSiteurl: WordpressApiGetWordPressSiteurlRes;
    getWordpressAppVersion: WordpressApiGetWordpressAppVersionRes;
    getWordpressConfig: WordpressApiGetWordpressConfigRes;
    getWordpressInstallations: WordpressApiGetWordpressInstallationsRes;
    getWordpressLatestVersion: WordpressApiGetWordpressLatestVersionRes;
    getWordpressPlugins: WordpressApiGetWordpressPluginsRes;
    getWordpressSettings: WordpressApiGetWordpressSettingsRes;
    getWordpressThemes: WordpressApiGetWordpressThemesRes;
    getWordpressUserSsoUrl: WordpressApiGetWordpressUserSsoUrlRes;
    getWordpressUsers: WordpressApiGetWordpressUsersRes;
    installWordpressPlugin: WordpressApiInstallWordpressPluginRes;
    installWordpressTheme: WordpressApiInstallWordpressThemeRes;
    setDefaultWpSsoUser: WordpressApiSetDefaultWpSsoUserRes;
    setWordPressMaintenanceMode: WordpressApiSetWordPressMaintenanceModeRes;
    setWordPressSiteurl: WordpressApiSetWordPressSiteurlRes;
    setWordpressConfig: WordpressApiSetWordpressConfigRes;
    setWordpressThemeAutoUpdateStatus: WordpressApiSetWordpressThemeAutoUpdateStatusRes;
    updateWordpressAppVersion: WordpressApiUpdateWordpressAppVersionRes;
    updateWordpressPluginSettings: WordpressApiUpdateWordpressPluginSettingsRes;
    updateWordpressPluginToLatest: WordpressApiUpdateWordpressPluginToLatestRes;
    updateWordpressSettings: WordpressApiUpdateWordpressSettingsRes;
    updateWordpressTheme: WordpressApiUpdateWordpressThemeRes;
    updateWordpressUser: WordpressApiUpdateWordpressUserRes;
}


