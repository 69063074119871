import { createActionCreator } from 'deox';

import { filerdApi } from 'src/api_services/filerd/service';
import { createActionsFromMethod, createRequestStateActions } from 'src/store/helpers';
import { LooseAutocomplete } from 'src/utils/types';

import { FileEntry, OverwriteStatus } from '../constants';
import { CreateEntriesRequest, DowloadEntriesRequest, MapEntriesRequest, UploadStatus } from './types';

const namespace = '@fileManager';

/**
 * API Actions.
 */

const getEntries = createActionsFromMethod<typeof filerdApi.getDirectoryEntries>(`getEntries(${namespace})`);

const checkEntry = createActionsFromMethod<typeof filerdApi.checkEntry>(`checkEntry(${namespace})`);

const deleteEntries = createActionsFromMethod<typeof filerdApi.deleteWebsiteEntries>(`deleteEntries(${namespace})`);

const modifyEntries = createActionsFromMethod<typeof filerdApi.mapWebsiteEntries>(`modifyEntries(${namespace})`);

const mapEntries = createRequestStateActions<{ params: MapEntriesRequest }>(`mapEntries(${namespace})`);

const createEntries = createRequestStateActions<{ params: CreateEntriesRequest }>(`createEntries(${namespace})`);

const setMetadata = createActionsFromMethod<typeof filerdApi.setEntryMetadata>(`setMetadata(${namespace})`);

const getContent = createActionsFromMethod<typeof filerdApi.getFileContent>(`getContent(${namespace})`);

const downloadEntries = createRequestStateActions<{ params: DowloadEntriesRequest }>(`downloadEntries(${namespace})`);

const refreshEntries = createActionCreator(
  `refreshEntries(${namespace})`,
  (resolve) => (payload: { paths: string[] }) => resolve(payload)
);

/**
 * Selections.
 */

const toggleSelect = createActionCreator(
  `toggleSelect(${namespace})`,
  (resolve) =>
    (payload: { path: string; type: LooseAutocomplete<'file' | 'folder'>; shift?: boolean; entries?: FileEntry[] }) =>
      resolve(payload)
);

const resetSelect = createActionCreator(`resetSelect(${namespace})`);

const selectAll = createActionCreator(
  `selectAll(${namespace})`,
  (resolve) => (payload: { entries: FileEntry[] }) => resolve(payload)
);

/**
 * Uploader.
 */

const setUploaderFiles = createActionCreator(
  `setUploaderFiles(${namespace})`,
  (resolve) => (payload: { websiteId: string; files: File[] }) => resolve(payload)
);

const updateFileProgress = createActionCreator(
  `updateFileProgress(${namespace})`,
  (resolve) => (payload: { websiteId: string; file: File; status: UploadStatus; current?: number; total?: number }) =>
    resolve(payload)
);

const clearUploader = createActionCreator(
  `clearUploader(${namespace})`,
  (resolve) => (payload: { websiteId: string }) => resolve(payload)
);

/**
 * Overwrites.
 */

const setOverwrite = createActionCreator(
  `setOverwrite(${namespace})`,
  (resolve) => (payload: { path: string; status: OverwriteStatus }) => resolve(payload)
);

/**
 * Clipboard.
 */

const setClipboard = createActionCreator(
  `setClipboard(${namespace})`,
  (resolve) => (payload: { items: string[]; cut?: true }) => resolve(payload)
);

const clearClipboard = createActionCreator(`clearClipboard(${namespace})`);

/**
 * Context menu.
 */

const openContextMenu = createActionCreator(
  `openContextMenu(${namespace})`,
  (resolve) => (payload: { x: number; y: number; type: 'file' | 'folder' | 'container'; target: string }) =>
    resolve(payload)
);

const closeContextMenu = createActionCreator(`closeContextMenu(${namespace})`);

/**
 * Text editor.
 */

const openEditor = createActionCreator(
  `openEditor(${namespace})`,
  (resolve) => (payload: { path: string }) => resolve(payload)
);

const closeEditor = createActionCreator(`closeEditor(${namespace})`);

/**
 * Misc.
 */

const toggleRename = createActionCreator(
  `toggleRename(${namespace})`,
  (resolve) => (payload: { path: string }) => resolve(payload)
);

const resetRename = createActionCreator(`resetRename(${namespace})`);

const setChmodTarget = createActionCreator(
  `setChmodTarget(${namespace})`,
  (resolve) => (payload: { path: string }) => resolve(payload)
);

const showFileInfo = createActionCreator(
  `showFileInfo(${namespace})`,
  (resolve) => (payload: { path: string }) => resolve(payload)
);

export const fileManagerActions = {
  checkEntry,
  clearClipboard,
  clearUploader,
  closeContextMenu,
  closeEditor,
  createEntries,
  deleteEntries,
  getContent,
  downloadEntries,
  getEntries,
  mapEntries,
  modifyEntries,
  openContextMenu,
  openEditor,
  refreshEntries,
  resetRename,
  resetSelect,
  selectAll,
  setChmodTarget,
  showFileInfo,
  setClipboard,
  setMetadata,
  setOverwrite,
  setUploaderFiles,
  toggleRename,
  toggleSelect,
  updateFileProgress,
};
