import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { loginsActions } from '../logins/actions';
import { sessionActions } from './actions';

export const sessionPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(sessionActions.setCurrentMemberId):
    case getType(loginsActions.createSession.success): {
      // persisting only currentMemberId as persisting isLoggedIn can cause infinite loading spinner
      persister.set({ key: 'session', item: { currentMemberId: store.getState().session.currentMemberId } });
      break;
    }

    case getType(sessionActions.loggedOut): {
      persister.clear({ key: 'session' });
      break;
    }
  }

  return res;
};
