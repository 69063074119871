import { ServerRole } from 'src/orchd-client';

import type { ServerIdsFormFields } from 'src/components/ServerSelect/ServerSelect';

export interface MoveServerSchema extends ServerIdsFormFields {
  //
}

export const TRANSFERRABLE_ROLES: ServerRole[] = [
  ServerRole.application,
  ServerRole.database,
  ServerRole.email,
  ServerRole.backup,
];
