import { createActionCreator } from 'deox';

import { loginsApi } from 'src/api_services/logins/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@logins';

const getLogin = createActionsFromMethod<typeof loginsApi.getLogin>(`getLogin(${namespace})`);
const getLogins = createActionsFromMethod<typeof loginsApi.getLogins>(`getLogins(${namespace})`);
const getLoginMemberships = createActionsFromMethod<typeof loginsApi.getLoginMemberships>(
  `getLoginMemberships(${namespace})`
);
const updateLoginInfo = createActionsFromMethod<typeof loginsApi.updateLoginInfo>(`updateLoginInfo(${namespace})`);
const updateAvatar = createActionsFromMethod<typeof loginsApi.setLoginAvatar>(`updateAvatar(${namespace})`);
const deleteAvatar = createActionsFromMethod<typeof loginsApi.deleteLoginAvatar>(`deleteAvatar(${namespace})`);
const getSessions = createActionsFromMethod<typeof loginsApi.getSessions>(`getSessions(${namespace})`);
const createSession = createActionsFromMethod<typeof loginsApi.createSession>(`createSession(${namespace})`);
const deleteSessions = createActionsFromMethod<typeof loginsApi.deleteSessions>(`deleteSessions(${namespace})`);
const deleteCurrentSession = createActionsFromMethod<typeof loginsApi.deleteCurrentSession>(
  `deleteCurrentSession(${namespace})`
);
const startPasswordRecovery = createActionsFromMethod<typeof loginsApi.startPasswordRecovery>(
  `startPasswordRecovery(${namespace})`
);
const validatePasswordRecovery = createActionsFromMethod<typeof loginsApi.validatePasswordRecovery>(
  `validatePasswordRecovery(${namespace})`
);
const finishPasswordRecovery = createActionsFromMethod<typeof loginsApi.finishPasswordRecovery>(
  `finishPasswordRecovery(${namespace})`
);
const setAvatarCacheInvalidate = createActionCreator(
  `setAvatarCacheInvalidate(${namespace})`,
  (resolve) => (payload: number) => resolve(payload)
);

const verify2FA = createActionsFromMethod<typeof loginsApi.verify2FA>(`verify2FA(${namespace})`);

export const loginsActions = {
  getLogin,
  getLogins,
  updateLoginInfo,
  updateAvatar,
  deleteAvatar,
  getSessions,
  deleteSessions,
  deleteCurrentSession,
  startPasswordRecovery,
  validatePasswordRecovery,
  finishPasswordRecovery,
  setAvatarCacheInvalidate,
  createSession,
  getLoginMemberships,
  verify2FA,
};
