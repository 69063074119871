import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';

const container = (theme: Theme) => css`
  max-height: 150px;

  &:hover {
    border-color: ${theme.colors.accent};
    box-shadow: 0 0 4px 0 ${theme.colors.accent};
  }
`;

const card = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  padding: ${theme.paddings['2xl']}px ${theme.paddings.sm}px;
`;

export const style = { container, card };
