import { createReducer } from 'deox';

import { loginsActions } from '../logins/actions';
import { sessionActions } from './actions';

export interface SessionState {
  currentMemberId: string;
  isLoggedIn?: boolean;
  isLoggingOut?: boolean;
}

export const initialState: SessionState = {
  currentMemberId: '',
  isLoggedIn: undefined,
  isLoggingOut: undefined,
};

export const sessionReducer = createReducer(initialState, (handleAction) => [
  handleAction(sessionActions.setCurrentMemberId, (_, { payload }) => ({
    isLoggedIn: true,
    currentMemberId: payload.id,
    isLoggingOut: undefined,
  })),

  handleAction(sessionActions.logout, (state) => ({
    ...state,
    isLoggingOut: true,
  })),

  handleAction([loginsActions.createSession.success], (state) => ({
    ...state,
    isLoggedIn: true,
  })),

  handleAction([loginsActions.deleteSessions.request, loginsActions.deleteCurrentSession.request], (state) => ({
    ...state,
    isLoggedIn: false,
  })),

  handleAction(sessionActions.loggedOut, () => initialState),
]);
