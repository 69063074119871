import { css } from '@emotion/core';
import { CSSInterpolation } from '@emotion/serialize';

import { breakpoints } from './constants';
import { Breakpoints, Colors, Normal } from './style-types';

const colors: Colors[] = ['blue', 'azure', 'green', 'yellow', 'orange', 'fuschia', 'accent'];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

// will pick color based on an id
const cache: Record<string, Colors> = {};
export const getStickyRandomColor = (id: string | number) => {
  if (!cache[id]) {
    cache[id] = getRandomColor();
  }
  return cache[id];
};

export function isResponsiveProp<T>(prop: Normal<T> | Breakpoints<T>): prop is Breakpoints<T> {
  if (
    typeof prop === 'object' &&
    prop !== null &&
    ('xs' in prop || 'sm' in prop || 'md' in prop || 'lg' in prop || 'xl' in prop)
  ) {
    return true;
  }
  return false;
}

export function responsive<T>(prop: Normal<T> | Breakpoints<T>, propHandler: (prop: Normal<T>) => CSSInterpolation) {
  const styles = [];
  if (isResponsiveProp(prop)) {
    if (typeof prop.xs !== 'undefined') {
      styles.push(propHandler(prop.xs));
    }
    if (typeof prop.sm !== 'undefined') {
      styles.push(css`
        @media (min-width: ${breakpoints.sm}px) {
          ${propHandler(prop.sm)}
        }
      `);
    }
    if (typeof prop.md !== 'undefined') {
      styles.push(css`
        @media (min-width: ${breakpoints.md}px) {
          ${propHandler(prop.md)}
        }
      `);
    }
    if (typeof prop.lg !== 'undefined') {
      styles.push(css`
        @media (min-width: ${breakpoints.lg}px) {
          ${propHandler(prop.lg)}
        }
      `);
    }
    if (typeof prop.xl !== 'undefined') {
      styles.push(css`
        @media (min-width: ${breakpoints.xl}px) {
          ${propHandler(prop.xl)}
        }
      `);
    }
  } else {
    styles.push(propHandler(prop));
  }
  return styles;
}

export function widthHelper(w: number | string) {
  const styles = [];

  if (typeof w === 'string') {
    styles.push(css`
      width: ${w};
    `);
  } else if (w < 1) {
    styles.push(css`
      width: ${w * 100}%;
    `);
  } else {
    styles.push(css`
      width: ${(w / 12) * 100}%;
    `);
  }

  return styles;
}
