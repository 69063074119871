import { createReducer } from 'deox';

import { updateViewport } from './actions';

interface ViewportState {
  width: number;
  height: number;
}

export const initialState: ViewportState = {
  width: 0,
  height: 0,
};

export const viewportReducer = createReducer(initialState, (handleAction) => [
  handleAction(updateViewport, (_, { payload }) => ({
    width: payload.width,
    height: payload.height,
  })),
]);
