import { emailsApi } from 'src/api_services/emails/service';
import { extendedOrgsApi } from 'src/api_services/orgs/service';

import { createActionsFromMethod } from '../helpers';

const namespace = '@emails';

const getEmails = createActionsFromMethod<typeof emailsApi.getEmails>(`getEmails(${namespace})`);
const getWebsiteEmail = createActionsFromMethod<typeof emailsApi.getWebsiteEmail>(`getWebsiteEmail(${namespace})`);
const getWebsiteEmails = createActionsFromMethod<typeof emailsApi.getWebsiteEmails>(`getWebsiteEmails(${namespace})`);
const updateWebsiteEmail = createActionsFromMethod<typeof emailsApi.updateWebsiteEmail>(
  `updateWebsiteEmail(${namespace})`
);
const deleteWebsiteEmail = createActionsFromMethod<typeof emailsApi.deleteWebsiteEmail>(
  `deleteWebsiteEmail(${namespace})`
);
export const getOrgEmails = createActionsFromMethod<typeof extendedOrgsApi.getOrgEmails>(`getOrgEmails(${namespace})`);

export const emailsActions = {
  getEmails,
  getWebsiteEmail,
  getWebsiteEmails,
  updateWebsiteEmail,
  deleteWebsiteEmail,
  getOrgEmails,
};
