import { getType } from 'deox';
import { takeLatest } from 'redux-saga/effects';

import { serversApi } from 'src/api_services/servers/service';
import { createRequestStateSaga } from 'src/store/helpers';

import { getUnconfiguredServers } from './actions';

export const [getUnconfiguredServersSaga] = createRequestStateSaga(getUnconfiguredServers, serversApi.getServers);

export function* unconfiguredServersSaga() {
  yield takeLatest(getType(getUnconfiguredServers.request), getUnconfiguredServersSaga);
}
