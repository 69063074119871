import { getType } from 'deox';
import { combineReducers } from 'redux';
import { takeEvery } from 'redux-saga/effects';

import { websitesApi } from 'src/api_services/websites/service';
import { AppState } from 'src/store';
import { createActionsFromMethod, createRequestStateReducer, createRequestStateSaga } from 'src/store/helpers';

const PREFIX = 'ipBlocker';

const getWebsiteHtaccessIpsRule = createActionsFromMethod<typeof websitesApi.getWebsiteHtaccessIpsRule>(
  `getWebsiteHtaccessIpsRule(${PREFIX})`
);

const updateWebsiteHtaccessIpsRule = createActionsFromMethod<typeof websitesApi.updateWebsiteHtaccessIpsRule>(
  `updateWebsiteHtaccessIpsRule(${PREFIX})`
);

export const ipBlockerActions = {
  getWebsiteHtaccessIpsRule,
  updateWebsiteHtaccessIpsRule,
};

const [getWebsiteHtaccessIpsRuleSaga] = createRequestStateSaga(
  getWebsiteHtaccessIpsRule,
  websitesApi.getWebsiteHtaccessIpsRule
);
const [updateWebsiteHtaccessIpsRuleSaga] = createRequestStateSaga(
  updateWebsiteHtaccessIpsRule,
  websitesApi.updateWebsiteHtaccessIpsRule
);

export function* websiteHtaccessIpsRuleSaga() {
  yield takeEvery(getType(getWebsiteHtaccessIpsRule.request), getWebsiteHtaccessIpsRuleSaga);
  yield takeEvery(getType(updateWebsiteHtaccessIpsRule.request), updateWebsiteHtaccessIpsRuleSaga);
}

export const getWebsiteHtaccessIpsRuleReducer = createRequestStateReducer(getWebsiteHtaccessIpsRule);
export const updateWebsiteHtaccessIpsRuleReducer = createRequestStateReducer(getWebsiteHtaccessIpsRule);

export const ipBlockerReducer = combineReducers({
  ipRules: getWebsiteHtaccessIpsRuleReducer,
  updateIpRules: updateWebsiteHtaccessIpsRuleReducer,
});

export const ipBlockerSelectors = {
  ipRules: (state: AppState) => state.ipBlocker.ipRules,
};
