import { css } from '@emotion/core';

import { boxShadowGrey } from 'src/design-system/mixins';
import { Colors, Theme } from 'src/design-system/theme';

import type { ToastVariant } from './types';

const colors = {
  neutral: 'dark' as Colors,
  success: 'success' as Colors,
  warning: 'warning' as Colors,
  info: 'info' as Colors,
  error: 'error' as Colors,
};

const toastContent =
  ({ variant }: { variant: ToastVariant }) =>
  (theme: Theme) =>
    css`
      ${boxShadowGrey(1)}
      background-color: ${theme.colors[colors[variant]]};
      padding: ${theme.paddings.md}px;
      width: fit-content;

      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
      will-change: transform, opacity;

      &[data-state='open'] {
        animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
      }

      &[data-state='closed'] {
        animation: hide 100ms ease-in;
      }

      &[data-swipe='move'] {
        transform: translateX(var(--radix-toast-swipe-move-x));
      }

      &[data-swipe='cancel'] {
        transform: translateX(0);
        transition: transform 200ms ease-out;
      }

      &[data-swipe='end'] {
        animation: swipeOut 100ms ease-out;
      }

      @keyframes hide {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      @keyframes slideIn {
        from {
          transform: translateX(calc(-100% + ${theme.paddings.xl}px));
        }
        to {
          transform: translateX(0);
        }
      }

      @keyframes swipeOut {
        from {
          transform: translateX(var(--radix-toast-swipe-end-x));
        }
        to {
          transform: translateX(calc(-100% + ${theme.paddings.xl}px));
        }
      }

      ${theme.settings.rtl &&
      `
        @keyframes slideIn {
          from {
            transform: translateX(calc(100% - ${theme.paddings.xl}px));
          }
          to {
            transform: translateX(0);
          }
        }

        @keyframes swipeOut {
          from {
            transform: translateX(var(--radix-toast-swipe-end-x));
          }
          to {
            transform: translateX(calc(100% - ${theme.paddings.xl}px));
          }
        }
      `}
    `;

const toastDismiss = () =>
  css`
    cursor: pointer;
    padding-right: 0px;
  `;

export const style = { toastContent, toastDismiss };
