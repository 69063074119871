import React from 'react';
import { useIntl } from 'react-intl';

import { SquarePill } from '../SquarePill/SquarePill';

interface Props {
  disabled?: boolean;
}

export const DedicatedPackagePill = ({ disabled = false }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <SquarePill
      size="sm"
      text={formatMessage({ id: 'dedicated_package_pill.text', defaultMessage: 'dedi' })}
      tooltipText={formatMessage({
        id: 'dedicated_package_pill.tooltip',
        defaultMessage:
          "A dedicated (DEDI) server hosts websites under a single subscription belonging to a single customer. All websites under this subscription will be placed on it, including those belonging to client's of the customer if they are a reseller. No other customer's websites will be placed there. A customer can have multiple dedicated subscriptions.",
      })}
      showTooltip
      color="azure"
      disabled={disabled}
    />
  );
};
