import { SubscriptionsApiGetSubscriptionsToParentReq } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { subscriptionsApi } from './service';

export const useGetActiveSubscriptionsQuery = ({
  enabled = true,
  ...params
}: SubscriptionsApiGetSubscriptionsToParentReq & { enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.getActiveSubscriptions(params),
    queryFn: () => subscriptionsApi.getSubscriptionsToParent(params),
    select: ({ data }) => data.items.filter((item) => item.status === 'active' && !item.suspendedBy),
    refetchOnMount: true,
    enabled,
  });

export const useGetSubscriptionsQuery = ({
  enabled = true,
  ...params
}: SubscriptionsApiGetSubscriptionsToParentReq & { enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.getSubscriptions(params),
    queryFn: () => subscriptionsApi.getSubscriptionsToParent(params),
    select: ({ data }) => data.items,
    enabled,
  });

export const useGetSubscriptionBandwidthUsageQuery = (params: {
  orgId: string;
  subscriptionId: number;
  refreshCache?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getSubscriptionBandwidthUsage(params),
    queryFn: () => subscriptionsApi.getSubscriptionBandwidthUsage(params),
    select: (data) => data.data,
    staleTime: 120_000,
  });
};

export const useDeleteSubscriptionMutation = () => useMutation({ mutationFn: subscriptionsApi.deleteSubscription });

export const useUpdateSubscriptionMutation = () => useMutation({ mutationFn: subscriptionsApi.updateSubscription });

export const useCreateSubscriptionMutation = () =>
  useMutation({ mutationFn: subscriptionsApi.createCustomerSubscription });
