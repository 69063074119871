import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { SelectOption } from 'src/components/SelectNew';
import { messages } from 'src/messages';
import { domainRegex, noHttpOrWww } from 'src/utils/regex';

export const getSchema = (formatMessage: IntlShape['formatMessage'], isMasterOrg: boolean) =>
  Yup.object({
    domain: Yup.string()
      .matches(domainRegex, { message: formatMessage(messages['domain_name.invalid']) })
      .matches(noHttpOrWww, {
        message: formatMessage({
          id: 'websites.push_live_modal.domain.invalid',
          defaultMessage: 'Please enter a domain name without www.',
        }),
      })
      .required(formatMessage(messages['domain_name.required'])),
    subscription: Yup.array()
      .of(Yup.mixed<SelectOption>().required())
      .required()
      .when([], {
        is: () => !isMasterOrg,
        then: (schema) =>
          schema
            .min(
              1,
              formatMessage({
                id: 'websites.push_live_modal.subscription_error',
                defaultMessage: 'Please select a package',
              })
            )
            .required(),
      }),
    serverIds: Yup.object({
      application: Yup.array().of(Yup.mixed<SelectOption>().required()).required(),
      email: Yup.array().of(Yup.mixed<SelectOption>().required()).required(),
      backup: Yup.array().of(Yup.mixed<SelectOption>().required()).required(),
      database: Yup.array().of(Yup.mixed<SelectOption>().required()).required(),
      dns: Yup.array().of(Yup.mixed<SelectOption>().required()).required(),
    }),
    runWpSearchReplace: Yup.boolean().required(),
  });

export type PushLiveToNewFields = Yup.InferType<ReturnType<typeof getSchema>>;
