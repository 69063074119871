import { ServerInfo } from 'src/orchd-client';
import { AxiosResponse } from 'axios';
import { getType } from 'deox';
import { PutEffect } from 'redux-saga/effects';
import { all, call, delay, put, select, takeLatest } from 'typed-redux-saga/macro';

import { serversApi } from 'src/api_services/servers/service';
import { TEN_SECONDS } from 'src/utils/constants';

import { updatingServersActions } from './actions';
import { updatingServersSelectors } from './selectors';

type ServerAction = Generator<PutEffect<ReturnType<typeof updatingServersActions.removeUpdatingServer>>>;

export function* pollUpdatingServersSaga() {
  while (yield* select(updatingServersSelectors.selectIsUpdatingServers)) {
    const serverIds = yield* select(updatingServersSelectors.selectUpdatingServerIds);
    const requests = serverIds.map((serverId) => call(serversApi.getServerInfo, { serverId }));
    const responses: AxiosResponse<ServerInfo>[] = yield* all(requests);

    const removeUpdatingServerActions = responses.reduce<ServerAction[]>(
      (acc, { data, status }) =>
        status === 503 ? acc : [...acc, put(updatingServersActions.removeUpdatingServer(data.id))],
      []
    );

    if (removeUpdatingServerActions.length) {
      yield all(removeUpdatingServerActions);
    }

    yield delay(TEN_SECONDS);
  }
}
export function* updatingServersSaga() {
  yield takeLatest(getType(updatingServersActions.addUpdatingServer), pollUpdatingServersSaga);
}
