import { createActionCreator } from 'deox';

import type { BasicInternalFilters } from './reducer';

const namespace = `@tableSettings`;

const updateFilters = createActionCreator(
  `updateFilters(${namespace})`,
  (resolve) => (payload: { tableId: string; filters: BasicInternalFilters }) => resolve(payload)
);

export const tableSettingsActions = {
  updateFilters,
};
