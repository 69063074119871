import { shade, tint } from 'polished';

import type {
  ColorOverrides,
  Easings,
  FontWeights,
  Margins,
  Paddings,
  Sizes,
  ThemeSettings,
  ZeroSize,
} from './style-types';

export interface GenericTheme {
  colors: Record<Colors, string>;
  colorOverrides: Record<ColorOverrides, string>;
  sizes: Record<Sizes, number>;
  fontColors: Record<FontColors, string>;
  fontSizes: Record<Sizes, number>;
  lineHeights: Record<Sizes, number>;
  margins: Record<Margins, number | string>;
  paddings: Record<Paddings, number>;
  gutters: Record<ZeroSize | Sizes, number>;
  fontWeights: Record<FontWeights, number>;
  fontFamily: string;
  easings: Record<Easings, string>;
  settings: Record<ThemeSettings, boolean>;
  buttonBorderRadius: string;
}

export const colors = {
  accent: '#00bed9',
  accentB10: '#00aac2',
  accentB25: '#008ea2',
  accentB50: '#424242',
  accentB90: '#001316',
  accentB95: '#00090b',
  accentBackground: '#f2f4f5',
  accentOnBlack: '#00e0f7',
  accentOnWhite: '#00bed9',
  accentW25: '#3fd6e1',
  accentW40: '#F5F9FA',
  accentW50: '#80deec',
  accentW70: '#b2ebf3',
  accentW80: '#ccf2f7',
  aqua: '#00eccf',
  aquaB10: '#45C8C6',
  azure: '#36A7FF',
  blue: '#4D55FF',
  dark: '#000',
  darkAlways: '#000',
  disabled: '#7f7f7f',
  error: '#d82121',
  error10: '#FBE8E7',
  error25: tint(0.75, '#d82121'),
  errorB25: '#a21818',
  fuschia: '#f44661',
  green: '#71eb77',
  greenBright: '#1BB341',
  grey: '#7f7f7f',
  grey3: '#f7f7f7',
  grey5: '#f2f2f2',
  grey10: '#e5e5e5',
  grey20: '#ccc',
  grey25: '#b2b2b2',
  grey40: '#999',
  grey50: '#7f7f7f',
  grey70: '#4c4c4c',
  grey75: '#404040',
  grey90: '#1a1a1a',
  grey95: '#191919',
  info: '#36A7FF',
  info10: '#eaf6ff',
  light: '#fff',
  lightAlways: '#fff',
  none: 'none',
  orange: '#FF8E53',
  placeholder: '#7f7f7f',
  purple: '#9400D3',
  purpleBright: '#6236FF',
  success: '#1BB341',
  success10: tint(0.9, '#1BB341'),
  success25: tint(0.75, '#1BB341'),
  successB25: shade(0.25, '#1BB341'),
  transparent: 'transparent',
  warning: '#FFB43E',
  warning10: '#FFF4E2',
  yellow: '#ffda5e',
  yellow2: '#ffd03e',
} as const;

export type Colors = keyof typeof colors;
export const themeColors = Object.keys(colors) as Colors[];

const colorOverrides = {
  secondaryButtonColor: colors.dark,
} as const;

const fontColors = {
  dark: colors.dark,
  darkAlways: colors.dark,
  light: colors.light,
  grey: colors.grey50,
  placeholder: colors.grey50,
  disabled: colors.grey25,
  success: colors.success,
  warning: colors.warning,
  info: colors.info,
  error: colors.error,
  accent: colors.accent,
  azure: colors.azure,
  orange: colors.orange,
  blue: colors.blue,
  purple: colors.purple,
} as const;

export type FontColors = keyof typeof fontColors;
export const themeFontColors = Object.keys(fontColors) as FontColors[];

const fontSizes = {
  xs: 11,
  sm: 13,
  md: 16,
  lg: 19,
  xl: 23,
  '2xl': 27,
  '3xl': 33,
  '4xl': 40,
  '5xl': 48,
} as const;

const lineHeights = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 24,
  xl: 30,
  '2xl': 34,
  '3xl': 42,
  '4xl': 50,
  '5xl': 60,
} as const;

const margins = {
  '-5xl': -128,
  '-4xl': -72,
  '-3xl': -48,
  '-2xl': -32,
  '-xl': -24,
  '-lg': -16,
  '-md': -12,
  '-sm': -8,
  '-xs': -4,
  auto: 'auto',
  '0': 0,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  '2xl': 32,
  '3xl': 48,
  '4xl': 72,
  '5xl': 128,
} as const;

const paddings = {
  '0': 0,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  '2xl': 32,
  '3xl': 48,
  '4xl': 72,
  '5xl': 128,
} as const;

const gutters = {
  '0': 0,
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  '2xl': 32,
  '3xl': 48,
  '4xl': 72,
  '5xl': 128,
} as const;

const sizes = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  '2xl': 32,
  '3xl': 48,
  '4xl': 72,
  '5xl': 128,
} as const;

const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700,
} as const;

const fontFamily = `"Noto Sans", "Helvetica Neue", Arial, Helvetica, sans-serif` as const;

// Source: https://material.io/design/motion/speed.html#easing
const easings = {
  standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
} as const;

const settings = {
  rtl: false,
  darkMode: false,
} as const;

const buttonBorderRadius = '2px';

const theme = {
  colors,
  colorOverrides,
  fontColors,
  fontSizes,
  lineHeights,
  margins,
  paddings,
  gutters,
  sizes,
  fontWeights,
  fontFamily,
  easings,
  settings,
  buttonBorderRadius,
} as const;

// Check if the defined theme matches the GenericTheme interface.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _: GenericTheme = theme;

/**
 * The `Theme` type is a subset of the `GenericTheme` type, with literal values from the default enhance theme inline
 * for a quick reference. It should not be taken as a guarantee that the provided theme will contain the same values and
 * should only be used as a rough guide.
 */
export type Theme = typeof theme;
export default theme;
