import React, { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { DomainMappingKind, ResourceName, ServerRole, WebserverKind, WebsiteKind } from 'src/orchd-client';
import { useSetAtom } from 'jotai';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetWebsiteDomainMappingsQuery } from 'src/api_services/domains/query';
import { useGetWebsiteQuery, useGetWebsiteWebserverKindQuery } from 'src/api_services/websites/query';
import { Await } from 'src/components/Await/Await';
import { Box } from 'src/components/Box';
import { Container } from 'src/components/Container';
import { IconName } from 'src/components/Icon/Icon.types';
import { SimpleSubscriptionCard } from 'src/components/PackageSubscriptionCard/SimpleSubscriptionCard';
import Search from 'src/components/Search';
import { H3, Text } from 'src/components/Text';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { useWebsiteSubscription } from 'src/hooks/useSubscriptions/useSubscriptions';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { brandingActions } from 'src/store/branding/actions';
import { brandingSelectors } from 'src/store/branding/selectors';
import { sessionSelectors } from 'src/store/session/selectors';
import { persistentAtom } from 'src/utils/atoms/persistentAtom';
import { serverHasRole } from 'src/utils/helpers';
import { PackageAllowanceName, PackageSelectionName } from 'src/utils/packages/types';
import { getSubscriptionAllowance, getSubscriptionSelection, subscriptionHasResource } from 'src/utils/packages/utils';
import { hasAtLeastOneRole, permissions } from 'src/utils/permissions/utils';

import { SimpleAtAGlance } from './AtAGlance/SimpleAtAGlance';
import { Hero } from './Hero';
import { NonResolvedDomainsAlert } from './NonResolvedDomainsAlert/NonResolvedDomainsAlert';
import { QuickLinkCard } from './QuickLinks/QuickLinkCard';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

export interface Props extends ActionProps, StateProps {
  //
}

export const lastVisitedWebsiteAtom = persistentAtom<string>('lastVisitedWebsite', '');

export const _AlternativeWebsiteDashboard = ({ member, nameServers, isDesktopUp, getBranding }: Props) => {
  const { websiteId } = useParams<{ websiteId: string }>();
  const setLastVisitedWebsite = useSetAtom(lastVisitedWebsiteAtom);
  const { orgId } = member;
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');

  const { data: websiteDomainMappings = [] } = useGetWebsiteDomainMappingsQuery({ orgId, websiteId, withSsl: true });
  const websiteQuery = useGetWebsiteQuery({ orgId, websiteId });
  const { data: webserverKind } = useGetWebsiteWebserverKindQuery({ websiteId });
  const { subscription, isLoading: subscriptionLoading } = useWebsiteSubscription({
    orgId,
    website: websiteQuery.data,
  });

  useEffect(() => {
    setLastVisitedWebsite(websiteId);
  });

  useEffect(() => {
    getBranding({ params: { orgId } });
  }, [getBranding, orgId]);

  const websiteDomainMapping = websiteDomainMappings?.find(
    (domainMapping) => domainMapping.mappingKind === DomainMappingKind.primary
  );

  const categories = useMemo(() => {
    return [
      {
        id: 'applications',
        label: formatMessage(messages.applications),
      },
      {
        id: 'backups',
        label: formatMessage(messages.backups),
      },
      {
        id: 'domains',
        label: formatMessage(messages.domains),
      },
      {
        id: 'analytics',
        label: formatMessage(messages.analytics),
      },
      {
        id: 'devtools',
        label: formatMessage(messages.developer_tools),
      },
      {
        id: 'security',
        label: formatMessage(messages.security),
      },
    ];
  }, [formatMessage]);

  // Build the list of quick links taking permissions into consideration.
  const quickLinks = useMemo(() => {
    const website = websiteQuery.data;

    if (subscriptionLoading || !website) {
      return [];
    }

    const { isMasterOrg } = member;
    const preinstalledApp = getSubscriptionSelection(subscription, PackageSelectionName.applicationsPreinstalledApp);
    const hasMailboxes = subscriptionHasResource(subscription, ResourceName.mailboxes);
    const hasForwarders = subscriptionHasResource(subscription, ResourceName.forwarders);

    const showFtp =
      !!website?.canUse?.ftp && (subscriptionHasResource(subscription, ResourceName.ftpUsers) || isMasterOrg);
    const showFileManager = !!website?.canUse?.fileManager;
    const showBackups = !!website?.canUse?.backup;
    const showRestoreBackup =
      !!website?.canUse?.backup &&
      (getSubscriptionAllowance(subscription, PackageAllowanceName.backupsAllowSelfRestore) || isMasterOrg);
    const showGenerateBackup =
      !!website?.canUse?.backup &&
      (getSubscriptionAllowance(subscription, PackageAllowanceName.backupsAllowManual) || isMasterOrg);
    const showApps =
      isMasterOrg ||
      getSubscriptionAllowance(subscription, PackageAllowanceName.applicationsSoftwareInstaller) ||
      (preinstalledApp && preinstalledApp.value !== 'None');
    const showAnalytics = hasAtLeastOneRole(permissions.showWebsiteAnalytics, member);
    const showDatabases = serverHasRole(website, ServerRole.database);
    const showEmails =
      (hasMailboxes || hasForwarders || isMasterOrg) &&
      serverHasRole(website, ServerRole.email) &&
      website?.kind !== WebsiteKind.staging;
    const showPhpIniEditor =
      getSubscriptionAllowance(subscription, PackageAllowanceName.featurePhpEditor) || isMasterOrg;
    const showNginxManager = webserverKind === WebserverKind.nginx;
    const showRedisManager = !!website.canUse?.redis;
    const showSsh = getSubscriptionAllowance(subscription, PackageAllowanceName.featureSSH) || isMasterOrg;
    const showIpManager = webserverKind === WebserverKind.apache;
    const showModSecurity = !!website.canUse?.modSec;

    const links: {
      id: string;
      label: string;
      url: string;
      icon: IconName;
      category: string;
    }[] = [];

    if (showApps) {
      links.push({
        id: 'apps',
        label: formatMessage(messages.apps),
        icon: 'application',
        url: `/websites/${websiteId}/apps`,
        category: 'applications',
      });
    }

    if (showEmails) {
      links.push({
        id: 'emails',
        label: formatMessage(messages.emails),
        icon: 'nav email',
        url: `/websites/${websiteId}/emails`,
        category: 'applications',
      });
    }

    if (showFileManager) {
      links.push({
        id: 'files',
        label: formatMessage(messages.file_manager),
        icon: 'folder',
        url: `/websites/${websiteId}/files`,
        category: 'applications',
      });
    }

    if (showDatabases) {
      links.push({
        id: 'databases',
        label: formatMessage(messages.databases),
        icon: 'database',
        url: `/websites/${websiteId}/databases`,
        category: 'applications',
      });
    }

    if (showFtp) {
      links.push({
        id: 'ftp',
        label: formatMessage(messages.ftp),
        icon: 'download',
        url: `/websites/${websiteId}/ftp`,
        category: 'applications',
      });
    }

    links.push({
      id: 'logs',
      label: formatMessage(messages.logs),
      icon: 'notebook',
      url: `/logs`,
      category: 'applications',
    });

    links.push({
      id: 'integrations',
      label: formatMessage(messages.integrations),
      icon: 'nav_integrations',
      url: `/integrations`,
      category: 'applications',
    });

    if (showBackups) {
      links.push({
        id: 'backups',
        label: formatMessage(messages.backups),
        icon: 'backup',
        url: `/websites/${websiteId}/backups`,
        category: 'backups',
      });
    }

    if (showRestoreBackup) {
      links.push({
        id: 'restore-backup',
        label: formatMessage(messages.restore_backup),
        icon: 'restore_backup',
        url: `/websites/${websiteId}/backups`,
        category: 'backups',
      });
    }

    if (showGenerateBackup) {
      links.push({
        id: 'generate-backup',
        label: formatMessage({
          id: 'website.dashboard.generate_backup',
          defaultMessage: 'Generate backup',
        }),
        icon: 'restore_backup',
        url: `/websites/${websiteId}/backups`,
        category: 'backups',
      });
    }

    links.push({
      id: 'domains',
      label: formatMessage(messages.domains),
      icon: 'domains',
      url: `/websites/${websiteId}/domains`,
      category: 'domains',
    });

    if (showFileManager) {
      links.push({
        id: 'redirects',
        label: formatMessage(messages.redirects),
        icon: 'link',
        url: `/websites/${websiteId}/redirects`,
        category: 'domains',
      });
    }

    if (showAnalytics) {
      links.push(
        {
          id: 'bandwidth',
          label: formatMessage(messages.bandwidth),
          icon: 'bandwidth',
          url: `/websites/${websiteId}/analytics#bandwidth`,
          category: 'analytics',
        },
        {
          id: 'visitors',
          label: formatMessage(messages.visitors),
          icon: 'visitors',
          url: `/websites/${websiteId}/analytics#visitors`,
          category: 'analytics',
        }
      );
    }

    links.push(
      {
        id: 'php-engine',
        label: formatMessage(messages.php_engine),
        icon: 'php',
        url: `/websites/${websiteId}/devtools#phpEngineManager`,
        category: 'devtools',
      },
      {
        id: 'php-extensions',
        label: formatMessage({ id: 'website.dashboard.php_extensions', defaultMessage: 'PHP extensions' }),
        icon: 'extension',
        url: `/websites/${websiteId}/devtools#phpEngineManager`,
        category: 'devtools',
      },
      {
        id: 'restart-php-container',
        label: formatMessage({
          id: 'website.dashboard.restart_php_container',
          defaultMessage: 'Restart PHP container',
        }),
        icon: 'restart',
        url: `/websites/${websiteId}/devtools#phpEngineManager`,
        category: 'devtools',
      }
    );

    if (showPhpIniEditor) {
      links.push({
        id: 'php-ini-editor',
        label: formatMessage(messages.php_ini_editor),
        icon: 'code',
        url: `/websites/${websiteId}/devtools#phpEditor`,
        category: 'devtools',
      });
    }

    links.push(
      {
        id: 'cron-jobs',
        label: formatMessage(messages.cron_jobs),
        icon: 'schedule',
        url: `/websites/${websiteId}/devtools#cronJobsManager`,
        category: 'devtools',
      },
      {
        id: 'ion-cube-loader',
        label: formatMessage(messages.ion_cube_loader),
        icon: 'code',
        url: `/websites/${websiteId}/devtools#ionCubeLoader`,
        category: 'devtools',
      }
    );

    if (showNginxManager) {
      links.push({
        id: 'nginx',
        label: formatMessage(messages.nginx),
        icon: 'code_blocks',
        url: `/websites/${websiteId}/devtools#nginxManager`,
        category: 'devtools',
      });
    }

    if (showRedisManager) {
      links.push({
        id: 'redis',
        label: formatMessage(messages.redis),
        icon: 'acute',
        url: `/websites/${websiteId}/devtools#redis`,
        category: 'devtools',
      });
    }

    if (showSsh) {
      links.push(
        {
          id: 'ssh-key-manager',
          label: formatMessage(messages.ssh_key_manager),
          icon: 'key',
          url: `/websites/${websiteId}/devtools#sslKeysManager`,
          category: 'devtools',
        },
        {
          id: 'ssh-password',
          label: formatMessage({
            id: 'website.dashboard.ssh_password',
            defaultMessage: 'SSH password',
          }),
          icon: 'lock',
          url: `/websites/${websiteId}/devtools#sshPassword`,
          category: 'devtools',
        }
      );
    }

    links.push({
      id: 'ssl-certificaties',
      label: formatMessage(messages.ssl_certificates),
      icon: 'security',
      url: `/websites/${websiteId}/security#sslManager`,
      category: 'security',
    });

    if (showIpManager) {
      links.push({
        id: 'ip-manager',
        label: formatMessage(messages.ip_manager),
        icon: 'block',
        url: `/websites/${websiteId}/security#ipBlocker`,
        category: 'security',
      });
    }

    if (showModSecurity) {
      links.push({
        id: 'mod-security',
        label: formatMessage(messages.mod_sec),
        icon: 'lock',
        url: `/websites/${websiteId}/security#modSecurity`,
        category: 'security',
      });
    }

    return links;
  }, [websiteId, subscription, websiteQuery.data, member, formatMessage, subscriptionLoading, webserverKind]);

  const renderCategory = (category: { id: string; label: ReactNode }) => {
    const items = quickLinks.filter(
      (link) => link.category === category.id && link.label.toLowerCase().includes(search.toLowerCase())
    );

    if (items.length === 0) {
      return null;
    }

    return (
      <Box key={`category-${category.id}`} gap="lg" d="column" mb="xl">
        <H3 size="xl" weight="light">
          {category.label}
        </H3>

        <Box g="sm">
          {items.map((link) => (
            <Box key={`link-${link.id}`} w={{ xs: 6, md: 3 }} mb="sm">
              <QuickLinkCard {...link} highlightText={search} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Await queryResult={websiteQuery}>
      {(website) => (
        <Fragment>
          {website.kind !== WebsiteKind.staging && (
            <NonResolvedDomainsAlert member={member} website={website} nameServers={nameServers ?? []} />
          )}

          <Hero member={member} website={website} websiteDomainMapping={websiteDomainMapping} isSinglePageDashboard />

          <Container pb="4xl">
            <Box j="flex-end">
              <Search value={search} onChange={(e) => setSearch(e.target.value)} maxWidth={300} />
            </Box>

            <Box g="lg">
              <Box w={{ xs: 12, md: 7 }} role="grid">
                {categories.map(renderCategory)}
              </Box>

              <Box w={{ xs: 12, md: 5 }}>
                {/* This is to align the card. */}
                {isDesktopUp && (
                  <Text size="xl" marginBottom="lg">
                    &nbsp;
                  </Text>
                )}

                <Box d="column" gap="lg">
                  <SimpleAtAGlance member={member} website={website} />
                  {subscription && <SimpleSubscriptionCard subscription={subscription} />}
                </Box>
              </Box>
            </Box>
          </Container>
        </Fragment>
      )}
    </Await>
  );
};

const stateToProps = (state: AppState) => ({
  member: sessionSelectors.getMember(state),
  nameServers: brandingSelectors.selectNameServers(state),
  isDesktopUp: viewportSelectors.isDesktopUp(state),
});

export const dispatchToProps = {
  getBranding: brandingActions.getBranding.request,
};

export default connect(stateToProps, dispatchToProps)(_AlternativeWebsiteDashboard);
