import { ReactNode } from 'react';
import { useBreadcrumb } from 'src/hooks/useBreadcrumb/useBreadcrumb';

interface Option {
  label: ReactNode;
  url: string;
  active?: boolean;
}

export interface Props {
  id?: string;
  label: ReactNode;
  url: string;
  options?: Option[];
}

/** @deprecated useBreadcrumb hook to be used instead */
export const BreadcrumbsItem = (props: Props) => {
  useBreadcrumb(props);

  return null;
};
