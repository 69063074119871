import { createReducer } from 'deox';

import { tableSettingsActions } from './actions';

import type { PossibleValues } from 'src/hooks/useFilters/useFilters';

const { updateFilters } = tableSettingsActions;

export type BasicInternalFilters = Record<string, PossibleValues>;

export interface TableSettingsState {
  filters: { [tableId: string]: BasicInternalFilters };
}

export const initialState: TableSettingsState = {
  filters: {},
};

export const tableSettingsReducer = createReducer(initialState, (handleAction) => [
  handleAction(updateFilters, (state, { payload }) => {
    const { tableId, filters } = payload;
    return {
      ...state,
      filters: { ...state.filters, [tableId]: filters },
    };
  }),
]);
