/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { EmailStatus, Status, WebsiteStatus } from 'src/orchd-client';
import { FormattedMessage } from 'react-intl';

import Pill, { tagColorMap } from 'src/components/Pill/Pill';
import { Margins, Responsive } from 'src/design-system/style-types';
import { messages } from 'src/messages';

export interface RequiredProps {
  status: WebsiteStatus | EmailStatus | Status | 'suspended';
  className?: string;
  mr?: Responsive<Margins>;
  mt?: Responsive<Margins>;
  mb?: Responsive<Margins>;
  ml?: Responsive<Margins>;
}

export type Props = RequiredProps;

export const StatusPill = ({ mr, mt, mb, ml, status, className }: Props) => {
  switch (status) {
    // @TODO: waiting for orchd support
    case 'transfer_pending' as WebsiteStatus:
      return (
        <Pill
          marginRight={mr}
          marginLeft={ml}
          marginTop={mt}
          marginBottom={mb}
          textTransform="uppercase"
          background="warning"
          className={className}
        >
          <FormattedMessage id="transfer_pending" defaultMessage="Transfer Pending" />
        </Pill>
      );
    case 'suspended':
      return (
        <Pill
          marginRight={mr}
          marginLeft={ml}
          marginTop={mt}
          marginBottom={mb}
          textTransform="uppercase"
          borderColor="error"
          fontColor="error"
          className={className}
        >
          <FormattedMessage {...messages.suspended} />
        </Pill>
      );
    case 'deleted':
      return (
        <Pill
          marginRight={mr}
          marginLeft={ml}
          marginTop={mt}
          marginBottom={mb}
          textTransform="uppercase"
          {...tagColorMap.disabled}
          className={className}
        >
          <FormattedMessage {...messages.deleted} />
        </Pill>
      );
    case 'disabled':
      return (
        <Pill
          marginRight={mr}
          marginLeft={ml}
          marginTop={mt}
          marginBottom={mb}
          textTransform="uppercase"
          {...tagColorMap.disabled}
          className={className}
        >
          <FormattedMessage {...messages.disabled} />
        </Pill>
      );
    case 'active':
      return null;
    default:
      throw new Error(`Invalid value for 'status'`);
  }
};
