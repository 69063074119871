import React, { Fragment, StrictMode } from 'react';
import { Provider as RadixTooltipProvider } from '@radix-ui/react-tooltip';
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Provider as JotaiProvider } from 'jotai';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';

import { OrchdStatusToast } from 'src/components/OrchdStatusToast/OrchdStatusToast';
import { CustomIntlProvider } from 'src/containers/CustomIntlProvider/CustomIntlProvider';
import { DemoModeProvider } from 'src/containers/DemoModeProvider/DemoModeProvider';
import GlobalStyles from 'src/containers/GlobalStyles';
import { ThemeGate } from 'src/containers/ThemeGate/ThemeGate';
import ToastHub from 'src/containers/ToastHub';
import { Viewport } from 'src/containers/Viewport';
import * as serviceWorker from 'src/serviceWorker';
import { store } from 'src/store';
import { history } from 'src/store/history';
import { axiosInstance } from 'src/utils/axios';
import { handleInterceptorError } from 'src/utils/axios/handleInterceptorError/handleInterceptorError';

import { App } from './App';
import { AuthProvider } from './containers/AuthProvider/AuthProvider';
import { ModalProvider } from './containers/ModalProvider/ModalProvider';
import { getIsTouchDevice } from './hooks/useIsTouchDevice/useIsTouchDevice';
import { Devtools } from './pages/devtools/Devtools';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');

  if (worker) {
    // mock API responses via MockServiceWorker
    worker.start();
  }
}

axiosInstance.interceptors.response.use((response) => response, handleInterceptorError);

const settings: QueryClientConfig = {
  defaultOptions: { queries: { refetchOnMount: false, refetchOnWindowFocus: false, retry: 1 } },
};

const queryClient = new QueryClient(settings);

const root = createRoot(document.getElementById('root') as HTMLElement);

const isTouch = getIsTouchDevice();

root.render(
  <StrictMode>
    <JotaiProvider>
      <ReduxProvider store={store}>
        <ConnectedRouter history={history}>
          <CustomIntlProvider>
            <QueryClientProvider client={queryClient}>
              <HelmetProvider>
                <DndProvider backend={isTouch ? TouchBackend : HTML5Backend}>
                  <RadixTooltipProvider delayDuration={0}>
                    <Fragment>
                      <AuthProvider>
                        <ThemeGate>
                          <DemoModeProvider>
                            <GlobalStyles />
                            <App />
                            <ModalProvider />
                            <ToastHub />
                            <OrchdStatusToast />
                            <Devtools />
                          </DemoModeProvider>
                        </ThemeGate>
                      </AuthProvider>
                      <Viewport />
                    </Fragment>
                  </RadixTooltipProvider>
                </DndProvider>
              </HelmetProvider>
            </QueryClientProvider>
          </CustomIntlProvider>
        </ConnectedRouter>
      </ReduxProvider>
    </JotaiProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
