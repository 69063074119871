import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { installApi } from './service';

import type { OrchdStatus } from 'src/components/OrchdStatusToast/OrchdStatusToast';

export const useOrchdStatusQuery = () => {
  return useQuery({
    queryKey: queryKeys.orchdStatus(),
    queryFn: () => installApi.orchdStatus({}),
    select: (data) => data.data as OrchdStatus,
    refetchInterval: (data: any) => {
      const status: OrchdStatus = data;
      if (status === 'ready') {
        return false;
      }
      return 5000;
    },
    retry: false,
    refetchOnMount: true,
  });
};
