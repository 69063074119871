import { CPLocale } from 'src/orchd-client';
import { createActionCreator } from 'deox';

import { DateTimeConfig } from 'src/utils/intl/getMessages/getMessages';

import type { TableItemsPerPage } from 'src/store/ui/reducer';

const namespace = `@ui`;

const toggleOnboardingCard = createActionCreator(`toggleOnboardingCard(${namespace})`);

export const updateTempLocale = createActionCreator(
  `updateTempLocale(${namespace})`,
  (resolve) => (payload: { locale: CPLocale | undefined }) => resolve(payload)
);

export const updateDateTimeConfig = createActionCreator(
  `updateDateTimeConfig(${namespace})`,
  (resolve) => (payload: { dateTimeConfig: DateTimeConfig }) => resolve(payload)
);

export const setServerGroupsOpen = createActionCreator(
  `setServerGroupsOpen(${namespace})`,
  (resolve) => (payload: Record<string, boolean>) => resolve(payload)
);

export const setTableItemsPerPage = createActionCreator(
  `setTableItemsPerPage(${namespace})`,
  (resolve) => (payload: TableItemsPerPage) => resolve(payload)
);

export const uiActions = {
  setServerGroupsOpen,
  setTableItemsPerPage,
  toggleOnboardingCard,
  updateTempLocale,
  updateDateTimeConfig,
};
