import { Setting } from 'src/orchd-client';
import merge from 'lodash/merge';

import { BrandingConfig, BrandingSettings } from 'src/api_services/branding/types';
import theme from 'src/design-system/theme';

export const getBrandingFromSettings = (settings: Setting[] = []) =>
  settings.find((setting) => setting.name === 'branding')?.value as BrandingSettings | BrandingConfig | undefined;

/**
 * getDefaultBranding creates a complete branding config with default values filling in the gaps where needed. Here, the default locale's
 * branding is used to get the default values necessary.
 */
export const getDefaultBranding = (brandingConfig: Partial<BrandingConfig> = {}): BrandingConfig => {
  return merge(
    // a set of default values to be used where none are present (based on the default Enhance theme)
    {
      websiteUrl: '',
      contactUrl: '',
      supportUrl: '',
      billingUrl: '',
      createAccountUrl: '',
      logoutUrl: '',
      defaultDarkMode: false,
      singlePageDashboard: false,
      vpsUrl: '',
      domainUrl: '',
      mailboxUrl: '',
      sharedHostingUrl: '',
      resourceUsageTrigger: '',

      colorOverrides: theme.colorOverrides,
      buttonBorderRadius: theme.buttonBorderRadius,
      colors: theme.colors,
      fontFamily: theme.fontFamily,
      fontColors: theme.fontColors,
    },
    brandingConfig
  );
};
