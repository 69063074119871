import { ServerIdsFormFields } from 'src/components/ServerSelect/ServerSelect';
import { getSelectFieldValue } from 'src/utils/helpers';

export const parseServerIdFields = (values: ServerIdsFormFields, isMasterOrg: boolean) => {
  const servers: {
    appServerId?: string;
    backupServerId?: string;
    dbServerId?: string;
    emailServerId?: string;
  } = {};

  if (!isMasterOrg) {
    return servers;
  }

  if (getSelectFieldValue(values.serverIds.application)) {
    servers.appServerId = getSelectFieldValue(values.serverIds.application) as string;
  }

  if (getSelectFieldValue(values.serverIds.backup)) {
    servers.backupServerId = getSelectFieldValue(values.serverIds.backup) as string;
  }

  if (getSelectFieldValue(values.serverIds.database)) {
    servers.dbServerId = getSelectFieldValue(values.serverIds.database) as string;
  }

  if (getSelectFieldValue(values.serverIds.email)) {
    servers.emailServerId = getSelectFieldValue(values.serverIds.email) as string;
  }

  return servers;
};
