import React from 'react';
import { Box } from 'src/components/Box';
import { Loading } from 'src/components/Loading';
import Overlay from 'src/components/Overlay';
import { zIndex } from 'src/utils/zIndex';

interface Props {
  alpha?: number;
  zIndexOverlay?: zIndex;
  darkMode?: boolean;
}

export const LoadingOverlay = ({ alpha = 0.5, zIndexOverlay = zIndex.DefaultOverlay, darkMode = false }: Props) => (
  <Overlay
    zIndex={zIndexOverlay}
    show="always"
    backgroundColor={darkMode ? 'grey90' : 'light'}
    alpha={alpha}
    data-qa="loadingOverlay"
  >
    <Box a="center" j="center" data-qa="container" minH="100%">
      <Loading data-qa="loader" />
    </Box>
  </Overlay>
);
