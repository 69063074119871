import { Fragment, ReactNode, useCallback, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import { useAtom, useAtomValue } from 'jotai';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useCreateSessionMutation } from 'src/api_services/logins/query';
import { useGetDemoModeQuery } from 'src/api_services/settings/query';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { useSearchParams } from 'src/hooks/useSearchParams/useSearchParams';
import { persistentAtom } from 'src/utils/atoms/persistentAtom';
import { getBrowserFingerprint } from 'src/utils/getBrowserFingerprint/getBrowserFingerprint';

import { demoModeAtom } from '../../pages/devtools/atoms';
import { style } from './DemoModeProvider.style';

export interface Props {
  children: ReactNode[] | ReactNode;
}

const pillDismissedAtom = persistentAtom('demoModePillDismissed', false);

export const DemoModeProvider = ({ children }: Props) => {
  const demoModeDevtools = useAtomValue(demoModeAtom);
  const { data } = useGetDemoModeQuery();
  const { pathname } = useLocation();
  const search = useSearchParams();
  const [pillDismissed, setPillDismissed] = useAtom(pillDismissedAtom);

  const { mutate: createSession } = useCreateSessionMutation();

  const isDemoModeActive = demoModeDevtools || data.status;

  const preventEnterSubmission = useCallback((e: KeyboardEvent) => {
    const element = e.target as HTMLElement;

    const enterPressedOutsideTextarea = ['Enter'].includes(e.key) && !['TEXTAREA'].includes(element.tagName);
    const spacePressedOutsideInput = [' '].includes(e.key) && !['INPUT', 'TEXTAREA'].includes(element.tagName);

    if (enterPressedOutsideTextarea || spacePressedOutsideInput) {
      e.preventDefault();
      e.stopPropagation();

      return false;
    }
  }, []);

  // Prevent users from submitting by hitting enter.
  useEffect(() => {
    isDemoModeActive
      ? window.addEventListener('keydown', preventEnterSubmission, { capture: true })
      : window.removeEventListener('keydown', preventEnterSubmission, { capture: true });

    return () => {
      window.removeEventListener('keydown', preventEnterSubmission, { capture: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoModeActive]);

  useEffect(() => {
    if (pathname !== '/login/demo' || !data.status) {
      return;
    }

    const id = search.get('id');

    if (!id || !data.status) {
      window.location.href = '/login';

      return;
    }

    let email, password: string;

    try {
      const credentials = JSON.parse(atob(id ?? ''));

      email = credentials.email;
      password = credentials.password;
    } catch {
      window.location.href = '/login';

      return;
    }

    if (!email || !password) {
      window.location.href = '/login';

      return;
    }

    createSession(
      {
        loginCreds: { email, password, deviceId: getBrowserFingerprint() },
      },
      {
        onSuccess: () => {
          window.location.href = '/';
        },
      }
    );
  }, [data.status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {isDemoModeActive && (
        <Fragment>
          {!pillDismissed && (
            <div css={style.demoToast}>
              <Icon name="warning" />

              <Text size="sm">
                <FormattedMessage id="demo_mode.toast" defaultMessage="Some features are disabled in demo mode." />
              </Text>

              <Icon name="close" onClick={() => setPillDismissed(true)} />
            </div>
          )}

          <Global styles={style.container} />
        </Fragment>
      )}

      {children}
    </Fragment>
  );
};
