import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

import { breadcrumbsAtom } from './atoms';

import type { Props as BreadcrumbItem } from 'src/containers/Breadcrumbs/BreadcrumbsItem';

export const useBreadcrumb = (item: BreadcrumbItem) => {
  const setBreadcrumbs = useSetAtom(breadcrumbsAtom);

  useEffect(() => {
    setBreadcrumbs((breadcrumbs) => [item, ...breadcrumbs].sort((a, b) => a.url.length - b.url.length));

    return () => {
      setBreadcrumbs((breadcrumbs) => breadcrumbs.filter(({ url }) => url !== item.url));
    };
  }, [item, setBreadcrumbs]);
};
