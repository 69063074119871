import { css } from '@emotion/core';
import { BoundingRect } from 'react-measure';

import { Theme } from 'src/design-system/theme';

import type { Props } from './Portal';

const container = (props: Props, dimensions: Partial<BoundingRect>) => (theme: Theme) =>
  css`
    overflow: ${props.overflow};
    position: fixed;
    ${props.top &&
    css`
      top: ${props.top};
    `}
    ${props.bottom &&
    css`
      bottom: ${props.bottom};
    `}
  right: 0px;
    left: ${dimensions.left ? dimensions.left : '0'}px;
    z-index: ${props.zIndex};
    background-color: ${theme.colors[props.backgroundColor]};
    width: ${dimensions.width ? `${dimensions.width}px` : '100%'};
  `;

const style = {
  container,
};

export { style };
