import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { padding, right } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './CardBody';

const container = (props: Props) => (theme: Theme) =>
  css`
    position: relative;

    ${padding({
      left: props.paddingLeft || props.paddingX || props.padding,
      right: props.paddingRight || props.paddingX || props.padding,
      top: props.paddingTop || props.paddingY || props.padding,
      bottom: props.paddingBottom || props.paddingY || props.padding,
    })(theme)}

    ${props.pageCardPadding &&
    padding({
      left: { xs: 'lg', md: '2xl' },
      right: { xs: 'lg', md: '2xl' },
      top: { xs: 'xl', md: '2xl' },
      bottom: '2xl',
    })(theme)}

  ${props.fullHeight &&
    css`
      flex: 1;
      height: 100%;
    `}
  `;

const dropdownIcon = (props: Props) => (theme: Theme) => {
  return css`
    position: absolute;

    ${props.padding &&
    responsive(
      props.padding,
      (padding) => css`
        top: calc(${theme.paddings[padding]}px - 8px);
        ${right(`${theme.paddings.md}px`)(theme)}
      `
    )}

    ${props.paddingTop &&
    responsive(
      props.paddingTop,
      (paddingTop) => css`
        top: calc(${theme.paddings[paddingTop]}px - 8px);
      `
    )}

    ${props.paddingRight && responsive(props.paddingRight, (paddingRight) => right(`${theme.paddings.md}px`)(theme))}

    ${props.pageCardPadding &&
    css`
      top: calc(${theme.sizes.lg}px - 2px);
      ${right(`${theme.sizes.md}px`)(theme)}
      @media (min-width: ${breakpoints.md}px) {
        top: calc(${theme.sizes['2xl'] - 8}px);
        ${right(`${theme.sizes.md}px`)(theme)}
      }
    `}

  ${props.dropdownCentered &&
    css`
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 32px;
    `}
  `;
};

export const style = { container, dropdownIcon };
