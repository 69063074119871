export enum dateTimeFormat {
  'DD/MM/YYYY HH:mm' = 'DD/MM/YYYY HH:mm',
  'MM/DD/YYYY HH:mm' = 'MM/DD/YYYY HH:mm',
  'YYYY/MM/DD HH:mm' = 'YYYY/MM/DD HH:mm',
}

export enum timeFormat {
  'HH:mm' = 'HH:mm',
}

export enum dateFormat {
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
}

export const dateTimeConfig = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',

  minute: '2-digit',
  hour: '2-digit',
  hour12: false,
} as const;

export const dateConfig = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
} as const;

export const timeConfig = {
  minute: '2-digit',
  hour: '2-digit',
  hour12: false,
} as const;
