import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'span',
};

/**
 * # Text Span
 */
const Span = (props: Props) => <Text {...props} />;

Span.defaultProps = updatedDefaultProps;

export default Span;
