import { AppState } from '../';

export const getImpersonation = (state: AppState) => state.impersonation;
export const getImpersonationReturnUrl = (state: AppState) => getImpersonation(state).returnUrl;
export const getImpersonationMember = (state: AppState) => getImpersonation(state).member;
export const getImpersonationParentId = (state: AppState) => getImpersonation(state).parentId;
export const getImpersonationLoading = (state: AppState) => getImpersonation(state).loading;
export const getImpersonationStartRouterKey = (state: AppState) => getImpersonation(state).startImpersonationRouterKey;

export const impersonationSelectors = {
  getImpersonation,
  getImpersonationLoading,
  getImpersonationMember,
  getImpersonationParentId,
  getImpersonationReturnUrl,
  getImpersonationStartRouterKey,
};
