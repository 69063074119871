import { parseToRgb } from 'polished';

export const checkColorParses = (color: string, fallback = 'white') => {
  try {
    parseToRgb(color);
    return color;
  } catch {
    try {
      parseToRgb(fallback);
      return fallback;
    } catch {
      return 'white';
    }
  }
};
