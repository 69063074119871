import React, { AriaRole, Fragment } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import classNames from 'classnames';

import { Colors, Margins, Paddings, Responsive, Sizes } from 'src/design-system/style-types';
import { zIndex } from 'src/utils/zIndex';

import style from './Box.style';

export type GutterSizes = '0' | Sizes;

// Props which are both in the main component, and also allowed
// to be passed down as child props
interface ChildSupportedProps {
  /**
   * Which dom element should the component render as
   * @default "div"
   **/
  el?: 'div' | 'section' | 'form' | 'span' | 'ul' | 'li' | 'button'; // feel free to add stuff here

  /**
   * How wide should the Box be x/12
   * @default 12
   **/
  w?: Responsive<number | string>;

  /** How high should the Box be */
  h?: Responsive<string>;

  /** Margin */
  m?: Responsive<Margins>;
  /** Margin Left and Right */
  mx?: Responsive<Margins>;
  /** Margin Top and Bottom */
  my?: Responsive<Margins>;
  /** Margin Top */
  mt?: Responsive<Margins>;
  /** Margin Right */
  mr?: Responsive<Margins>;
  /** Margin Bottom */
  mb?: Responsive<Margins>;
  /** Margin Left */
  ml?: Responsive<Margins>;

  /** Padding */
  p?: Responsive<Paddings>;
  /** Padding Left and Right */
  px?: Responsive<Paddings>;
  /** Padding Top and Bottom */
  py?: Responsive<Paddings>;
  /** Padding Top */
  pt?: Responsive<Paddings>;
  /** Padding Right */
  pr?: Responsive<Paddings>;
  /** Padding Bottom */
  pb?: Responsive<Paddings>;
  /** Padding Left */
  pl?: Responsive<Paddings>;

  /** Border */
  b?: Responsive<number>;
  /** Border Left and Right */
  bx?: Responsive<number>;
  /** Border Top and Bottom */
  by?: Responsive<number>;
  /** Border Top */
  bt?: Responsive<number>;
  /** Border Right */
  br?: Responsive<number>;
  /** Border Bottom */
  bb?: Responsive<number>;
  /** Border Left */
  bl?: Responsive<number>;
  /** Border Color */
  bc?: Colors;
  /** ARIA role */
  role?: AriaRole;
  /** tabIndex - allows keyboard focus of elements */
  tabIndex?: number;
}

export interface Props extends ChildSupportedProps {
  /**
   * Flexbox direction
   * @default "row"
   **/
  d?: Responsive<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  /**
   * Flexbox justify-content
   * @default "flex-start"
   **/
  j?: Responsive<
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right'
  >;
  /**
   * Flexbox align-items
   * @default "flex-start"
   **/
  a?: Responsive<
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
  >;

  /**
   * Flexbox align-self
   * @default "auto"
   **/
  alignSelf?: Responsive<'auto' | 'flex-start' | 'flex-end' | 'center' | 'stretch'>;

  /**
   * Flexbox flex-wrap
   * @default "wrap"
   **/
  wrap?: Responsive<'nowrap' | 'wrap' | 'wrap-reverse'>;
  /**
   * Row gap sizes for wrapped flexbox
   **/
  rowGap?: Responsive<Sizes>;
  /**
   * Gap sizes for wrapped flexbox
   **/
  gap?: Responsive<'0' | Sizes>;
  /**
   * Flexbox flex-shrink
   * @default 1
   **/
  shrink?: Responsive<number>;

  /**
   * Flex Grow
   **/
  grow?: Responsive<number>;

  /** Flex Order */
  o?: Responsive<number | 'initial'>;

  /** Min width */
  minW?: Responsive<string>;

  /** Max width */
  maxW?: Responsive<string>;

  /** Min height */
  minH?: Responsive<string>;

  /** Max height */
  maxH?: Responsive<string>;

  /** Background */
  bg?: Responsive<Colors>;

  /** Gutter handles neg margin on parent, padding on children, and correcting width */
  g?: Responsive<GutterSizes>;

  /** Used by gutter logic to set gutter on child, basically px divided by 2 for semantics */
  gcpx?: Responsive<'0' | Sizes>;

  /**
   * Child Props
   * having an object here, empty or not, will turn on handling of child components
   * any component which is not Box will be wrapped in a Box
   * @default {}
   **/
  c?: false | Partial<ChildSupportedProps>;

  /**
   * Css classes to add
   * @default ""
   **/
  className?: string;

  /**
   * Allows you to add hover styles from a parent
   * @default false
   **/
  hasHoverClass?: boolean;

  /**
   * Allows you to show transparent indicator but keep the height/width of the content
   * @default false
   **/
  transparent?: boolean;

  /**
   * Allows you to show change opacity of the box and it's content
   * @default undefined
   **/
  opacity?: Responsive<number>;

  /**
   * Allows you to set the position value
   * @default false
   **/
  position?: Responsive<'initial' | 'inherit' | 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'>;

  /**
   * Allows you to set the position top value
   * @default auto
   **/
  top?: Responsive<Margins | number>;

  /**
   * Allows you to set the position right value
   * @default auto
   **/
  right?: Responsive<Margins | number>;

  /**
   * Allows you to set the position bottom value
   * @default auto
   **/
  bottom?: Responsive<Margins | number>;

  /**
   * Allows you to set the position left value
   * @default auto
   **/
  left?: Responsive<Margins | number>;

  /**
   * Allows you to set the z index value
   * @default undefined
   **/
  zIndex?: Responsive<zIndex>;

  /**
   * Allows you to set the border-radius value
   * @default undefined
   **/
  radius?: Responsive<number | string>;

  /**
   * Allows you to set the overflow value
   * @default undefined
   **/
  overflow?: Responsive<'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit'>;

  /**
   * Allows you to hide element at different breakpoints
   * @default undefined
   **/
  hide?: Responsive<boolean>;

  /**
   * On click function handler
   **/
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;

  /**
   * Ability to set a ref
   **/
  innerRef?: React.Ref<HTMLElement>;

  /** React children */
  children?: React.ReactNode;

  /**
   * data-qa tag - to prevent it from being passed down to children and skewing item counts in tests
   **/
  'data-qa'?: string;
  /**
   * dataQa tag - for use with RTL tests when other options are not viable
   **/
  dataQa?: string;

  dataDemoDisabled?: boolean;
  dataDemoEnabled?: boolean;

  id?: string;
  title?: string;
}

/**
 * # Box
 * Box covers all your layout needs.
 */
export const Box = ({
  a = 'flex-start',
  alignSelf = 'auto',
  b,
  bb,
  bc = 'grey10',
  bg,
  bl,
  br,
  bt,
  bx,
  by,
  c,
  children,
  className = '',
  d = 'row',
  el = 'div',
  g,
  gcpx,
  grow,
  h = 'auto',
  hasHoverClass = false,
  hide,
  id,
  innerRef,
  j = 'flex-start',
  m,
  maxH,
  maxW,
  mb,
  minH,
  minW,
  ml,
  mr,
  mt,
  mx,
  my,
  o,
  onClick,
  opacity,
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
  title,
  transparent,
  w = 12,
  wrap = 'wrap',
  rowGap,
  gap,
  shrink,
  position,
  zIndex,
  radius,
  overflow,
  top,
  right,
  bottom,
  left,
  'data-qa': dataQa,
  dataQa: dataQaRTL,
  dataDemoDisabled,
  dataDemoEnabled,
  ...restProps
}: Props) => {
  const propsWithDefaults = {
    a,
    alignSelf,
    b,
    bb,
    bc,
    bg,
    bl,
    br,
    bt,
    bx,
    by,
    c,
    children,
    className,
    d,
    el,
    g,
    gcpx,
    grow,
    h,
    hasHoverClass,
    hide,
    id,
    innerRef,
    j,
    m,
    maxH,
    maxW,
    mb,
    minH,
    minW,
    ml,
    mr,
    mt,
    mx,
    my,
    o,
    onClick,
    opacity,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    transparent,
    w,
    wrap,
    rowGap,
    gap,
    shrink,
    position,
    zIndex,
    radius,
    overflow,
    top,
    right,
    bottom,
    left,
    'data-qa': dataQa,
    'data-demo-disabled': dataDemoDisabled,
    'data-demo-enabled': dataDemoEnabled,
    ...restProps,
  };

  // we want to add props to child boxes
  // and wrap anything which is not a box
  let wrappedChildren = children;
  if (c || g) {
    wrappedChildren = React.Children.toArray(children).map((child, i): React.ReactElement => {
      if (React.isValidElement(child) && (child.type === Box || (child as any)?.type?.isBox === true)) {
        const props = {
          key: i,
          c: false,
          gcpx: g,
          ...(c || {}),
          ...child.props,
        };
        return React.cloneElement(child, props);
      }

      if (!child) {
        // eslint-disable-next-line react/no-array-index-key
        return <Fragment key={i} />;
      }

      const props = {
        gcpx: g,
        ...(c || {}),
      };
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={i} c={false} {...props}>
          {child}
        </Box>
      );
    });
  }

  return jsx(
    el,
    {
      css: style(propsWithDefaults),
      id,
      className: classNames({ 'ui-box-hoverable': hasHoverClass }, className),
      onClick,
      ref: innerRef,
      title,
      'data-qa': dataQaRTL ?? 'box',
      'data-demo-disabled': dataDemoDisabled,
      'data-demo-enabled': dataDemoEnabled,
      ...restProps,
    },
    wrappedChildren
  );
};

Box.isBox = true;
