import { createElement } from 'react';
import { CPLocale } from 'src/orchd-client';

import { LocaleSelectLabel } from 'src/components/LocaleSelectLabel/LocaleSelectLabel';
import { SelectOption } from 'src/components/Select/Select.types';

type LocaleConfig = {
  label: string;
  description?: string;
  beta?: true;
};

// locale list
// https://gist.github.com/msikma/8912e62ed866778ff8cd

export const supportedLocaleConfigs: Partial<Record<CPLocale, LocaleConfig>> = {
  [CPLocale.en_GB]: { label: 'English (UK)' },
  [CPLocale.en]: { label: 'English (US)' },
  // [CPLocale.ca_ES]: { label: 'Català', description: 'Catalan (ES)', beta },
  [CPLocale.cs_CZ]: { label: 'Czech (CZ)', description: 'Czech (Czech Republic)' },
  [CPLocale.de_DE]: { label: 'Deutsch (DE)', description: 'German (Germany)' },
  [CPLocale.es_ES]: { label: 'Español (ES)', description: 'Spanish (Spain)', beta: true },
  [CPLocale.el_GR]: { label: 'Ελληνικά (GR)', description: 'Greek (Greece)' },
  [CPLocale.fr_FR]: { label: 'Français (FR)', description: 'French (France)' },
  [CPLocale.it_IT]: { label: 'Italiano (IT)', description: 'Italian (Italy)', beta: true },
  [CPLocale.he_IL]: { label: 'Hebrew (IL)', description: 'Hebrew (Israel)', beta: true },
  [CPLocale.hu_HU]: { label: 'Magyar (HU)', description: 'Hungarian (Hungary)' },
  [CPLocale.ro_RO]: { label: 'Română (RO)', description: 'Romanian (Romania)', beta: true },
  [CPLocale.fa_IR]: { label: 'زبان فارسی (IR)', description: 'Persian (Iran)', beta: true },
  [CPLocale.ar_EG]: { label: 'العربية (EG)', description: 'Arabic (Egypt)', beta: true },
  [CPLocale.sk_SK]: { label: 'Slovenčina (SK)', description: 'Slovakian (Slovakia)', beta: true },
  [CPLocale.hr_HR]: { label: 'Hrvatski (HR)', description: 'Croatian (Croatia)', beta: true },
  [CPLocale.bs_BA]: { label: 'Bwosanski (BA)', description: 'Bosnian (Bosnia and Herzegovina)', beta: true },
  [CPLocale.sr_Cyrl_SP]: { label: 'Српски (SP)', description: 'Serbian (Cyrillic) (Serbia & Montenegro)', beta: true },
  [CPLocale.sr_SP]: { label: 'Srpski (SP)', description: 'Serbian (Latin) (Serbia & Montenegro)', beta: true },
  [CPLocale.uk_UA]: { label: 'Українська (UA)', description: 'Ukrainian (Ukraine)', beta: true },
  [CPLocale.da_DK]: { label: 'Dansk (DK)', description: 'Danish (Denmark)', beta: true },
  [CPLocale.ka_GE]: { label: 'ქართული ენა (GE)', description: 'Georgian (Georgia)', beta: true },
  [CPLocale.pt_BR]: { label: 'Português (BR)', description: 'Portuguese (Brazil)' },
  [CPLocale.pt_PT]: { label: 'Português (PT)', description: 'Portuguese (Portugal)' },
  [CPLocale.nl_NL]: { label: 'Nederlands (NL)', description: 'Dutch (Netherlands)', beta: true },
  [CPLocale.id_ID]: { label: 'Indonesia (ID)', description: 'Indonesian (Indonesia)' },
  [CPLocale.bn_IN]: { label: 'বাংলা (IN)', description: 'Bengali / Bangla (India)', beta: true },
  [CPLocale.pl_PL]: { label: 'Polski (PL)', description: 'Polish (Poland)' },
  [CPLocale.tr_TR]: { label: 'Türkçe (TR)', description: 'Turkish (Turkey)', beta: true },
  [CPLocale.et_EE]: { label: 'Eesti (EE)', description: 'Estonian (Estonia)', beta: true },
  [CPLocale.nb_NO]: { label: 'Norsk (Bokmål) (NO)', description: 'Norwegian (Norway)', beta: true },
  [CPLocale.sv_SE]: { label: 'Svenska (SE)', description: 'Swedish (Sweden)', beta: true },
  // [CPLocale.fi_FI]: { label: 'Suomi (FI)', description: 'Finnish (Finland)', beta: true },
  [CPLocale.ru_RU]: { label: 'Русский (RU)', description: 'Russian (Russia)', beta: true },
  [CPLocale.ko_KR]: { label: '한국어 (KR)', description: 'Korean (Korea)', beta: true },
  [CPLocale.zh_CN]: { label: '中文 (CH)', description: 'Chinese (China)', beta: true },
  [CPLocale.zh_HK]: { label: '中文 (HK)', description: 'Chinese (Hong Kong)', beta: true },
  [CPLocale.hi_IN]: { label: 'हिंदी (IN)', description: 'Hindi (India)', beta: true },
  [CPLocale.kn_IN]: { label: 'ಕನ್ನಡ (IN)', description: 'Kannada (India)', beta: true },
  [CPLocale.bg_BG]: { label: 'Български (BG)', description: 'Bulgarian (Bulgaria)', beta: true },
  [CPLocale.vi_VN]: { label: 'Tiếng Việt (VN)', description: 'Vietnamese (Vietnam)', beta: true },
};

export const supportedLocales = Object.keys(supportedLocaleConfigs) as CPLocale[];

export const dropdownLocales: SelectOption[] = Object.entries(supportedLocaleConfigs).map(
  ([locale, { label, beta = false }]) => ({
    value: locale,
    label: createElement(LocaleSelectLabel, { beta, label, locale }),
  })
);
