import { ReactNode, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-tooltip';
import isFunction from 'lodash/isFunction';

import { IconButton } from '../IconButton';
import { style } from './Tooltip.style';

export type Placement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

type Side = 'top' | 'bottom' | 'right' | 'left';
type Align = 'start' | 'center' | 'end';

export interface Controls {
  show: () => void;
  hide: () => void;
}

export interface Props {
  children: ReactNode | ((controls: Controls) => ReactNode);
  button: ReactNode | ((controls: Controls) => ReactNode);
  variant?: 'basic' | 'full';
  delayDuration?: number;
  disableTooltipOnClick?: boolean;
  placement?: Placement;
  sideOffset?: number;
  alignOffset?: number;
  // This will nudge the tooltip's z-index above modal's.
  inModal?: boolean;
  // This prop is a bandaid for our "bad" implementation of low level components where we don't correctly forward props
  // and refs. If the trigger is a component that does correctly forward these, this can be set to true and the trigger
  // will be the button prop itself instead of being wrapped in a div. Mainly needed for `position: fixed` triggers.
  forwarded?: boolean;
  fullWidthButton?: boolean;
  ellipsisButton?: boolean;
}

export const Tooltip = ({
  children,
  button = <IconButton icon="dot dot dot" size="md" variant="tertiary" />,
  variant = 'basic',
  disableTooltipOnClick = false,
  delayDuration = 0,
  placement = 'bottom-end',
  sideOffset = 0,
  alignOffset = 0,
  inModal = false,
  forwarded = false,
  fullWidthButton = false,
  ellipsisButton = false,
}: Props) => {
  const delayRef = useRef<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);
  const [side = 'bottom', align = 'center'] = placement.split('-') as [Side, Align];

  const controls = {
    show: () => {
      delayRef.current = setTimeout(() => setOpen(true), delayDuration);
    },
    hide: () => {
      if (delayRef.current) {
        clearTimeout(delayRef.current);

        delayRef.current = null;
      }

      setOpen(false);
    },
  };

  const isControlled = isFunction(button);
  const renderButton = () => (isControlled ? button(controls) : button);
  const container = document.getElementById('modal-radix-root') ?? document.getElementById('radix-root');

  return (
    <Root open={open} delayDuration={delayDuration}>
      <Trigger
        // the isControlled conditional is because we are using tooltip for the copy component instead of a popover
        onMouseEnter={
          isControlled
            ? undefined
            : (e) => {
                controls.show();
              }
        }
        onMouseLeave={
          isControlled
            ? undefined
            : (e) => {
                controls.hide();
              }
        }
        onClick={
          isControlled || disableTooltipOnClick
            ? undefined
            : (e) => {
                controls.show();
                e.preventDefault();
                e.stopPropagation();
              }
        }
        asChild
      >
        {forwarded ? (
          renderButton()
        ) : (
          <div css={style.tooltipTrigger({ fullWidth: fullWidthButton, ellipsis: ellipsisButton })}>
            {renderButton()}
          </div>
        )}
      </Trigger>

      <Portal container={container}>
        <Content
          onEscapeKeyDown={controls.hide}
          css={style.tooltipContent({ variant, inModal })}
          side={side}
          align={align}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          role="menu"
        >
          {isFunction(children) ? children(controls) : children}

          <Arrow css={style.tooltipArrow} />
        </Content>
      </Portal>
    </Root>
  );
};
