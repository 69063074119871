import React, { useMemo } from 'react';
import { NetworkStatus, ServerInfoBrief, ServerRole } from 'src/orchd-client';
import { connect } from 'react-redux';

import { useGetServerRolesQuery } from 'src/api_services/servers/query';
import { Box } from 'src/components/Box';
import { DedicatedPackagePill } from 'src/components/DedicatedPackagePill/DedicatedPackagePill';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { AppState } from 'src/store';
import { sessionSelectors } from 'src/store/session/selectors';
import { getRoleServiceStatus } from 'src/utils/helpers';
import { getServerStatusIcon } from 'src/utils/servers/getServerStatusIcon/getServerStatusIcon';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  server: ServerInfoBrief;
  role: ServerRole;
  isClickDisabled: boolean;
  roleDisabled: boolean;
  isUpdating: boolean;
}

const stateToProps = (state: AppState) => ({
  member: sessionSelectors.getMember(state),
});

export const _ServerSelectLabel = ({ server, role, isClickDisabled, isUpdating, roleDisabled, member }: Props) => {
  const { isMasterOrg } = member;

  const { data: rolesInfo, isLoading: rolesInfoLoading } = useGetServerRolesQuery({
    serverId: server.id,
    enabled: isMasterOrg,
  });

  const roleState = useMemo(() => {
    if (rolesInfoLoading || !rolesInfo) {
      return 'loading';
    }

    return getRoleServiceStatus(role, rolesInfo) === NetworkStatus.online ? 'online' : 'offline';
  }, [rolesInfo, rolesInfoLoading, role]);

  const { name, color } = getServerStatusIcon({ roleState, isUpdating });

  return (
    <Box wrap="nowrap" a="center">
      <Icon name={name} color={color} marginRight="sm" />

      <Text
        textDecoration={roleDisabled ? 'line-through' : 'none'}
        color={isClickDisabled || roleState !== 'online' || isUpdating ? 'grey' : undefined}
        ellipsis
        marginRight="sm"
      >
        {server.friendlyName}
      </Text>

      {server.dedicatedSubscription && <DedicatedPackagePill disabled={isUpdating} />}
    </Box>
  );
};

export const ServerSelectLabel = connect(stateToProps)(_ServerSelectLabel);
