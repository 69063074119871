export interface FileEntry {
  id: string;
  name: string;
  size: number;
  modified: number;
  type: string;
  permissions: number;
}

export enum FileType {
  ExistingFile = 'existingFile',
  ExistingDirectory = 'existingDirectory',
  UploadedFile = 'uploadedFile',
}

export enum UploadMode {
  Silent = 'silent',
  HideUploader = 'hideUploader',
  Full = 'full',
}

export enum OverwriteStatus {
  NoConflict = 'noConflict',
  Staged = 'staged',
  Overwrite = 'overwrite',
  Skip = 'skip',
}

export interface DraggableFile {
  type: FileType;
  path: string;
  name: string;
}

export interface UploadableFile {
  type: FileType;
  files: File[];
  items: DataTransferItemList;
}
