import { persister } from 'src/utils/persister/persister';

// gives more accurate description of return types
const paramsHelper =
  <T extends string>(name: T, perUser: boolean = true) =>
  <P>(params?: P) => {
    const sessionMemberId = persister.get({ key: 'session' })?.currentMemberId ?? 'anonymous';
    const impersonationMemberId = persister.get({ key: 'impersonation' })?.member?.orgId;
    const currentMemberId = impersonationMemberId ?? sessionMemberId;

    if (perUser) {
      return params
        ? ([name, currentMemberId, params] as [typeof name, typeof currentMemberId, typeof params])
        : ([name, currentMemberId] as [typeof name, typeof currentMemberId]);
    }

    return params ? ([name, params] as [typeof name, typeof params]) : ([name] as [typeof name]);
  };

export const queryKeys = {
  // metrics
  websiteMetrics: paramsHelper('WebsiteMetrics'),
  // install
  validateInstallation: paramsHelper('ValidateInstallation', false),
  orchdStatus: paramsHelper('OrchdStatus', false),
  // apps
  websiteApps: paramsHelper('WebsiteApps'),
  // wordpress
  wordpressPlugins: paramsHelper('WordpressPlugins'),
  wordpressUsers: paramsHelper('WordpressUsers'),
  wordpressAppVersion: paramsHelper('WordpressAppVersion'),
  wordpressSettings: paramsHelper('WordpressSettings'),
  defaultWpSsoUser: paramsHelper('DefaultWpSsoUser'),
  installableApps: paramsHelper('InstallableApps'),
  wordpressConfig: paramsHelper('WordpressConfig'),
  wordpressSiteUrl: paramsHelper('WordpressSiteUrl'),
  wordpressMaintenanceMode: paramsHelper('WordpressMaintenanceMode'),
  wordpressThemes: paramsHelper('WordpressThemes'),
  // api.wordpress.org
  wordpressPluginList: paramsHelper('WordpressPluginList'),
  wordpressPluginInfo: paramsHelper('WordpressPluginInfo'),
  wordpressThemeList: paramsHelper('WordpressThemeList'),
  // ftp
  ftpUsers: paramsHelper('FtpUsers'),
  // subscriptions
  getSubscriptions: paramsHelper('GetSubscriptions'),
  getActiveSubscriptions: paramsHelper('GetActiveSubscriptions'),
  getSubscriptionBandwidthUsage: paramsHelper('GetSubscriptionBandwidthUsage'),
  // website
  website: paramsHelper('Website'),
  websites: paramsHelper('Websites'),
  websiteCgroupLimits: paramsHelper('WebsiteCgroupLimits'),
  websiteFsQuotaLimits: paramsHelper('WebsiteFsQuotaLimits'),
  websiteDomainMappingDnsStatus: paramsHelper('WebsiteDomainMappingDnsStatus'),
  userCrontab: paramsHelper('UserCrontab'),
  websiteWebserverKind: paramsHelper('WebsiteWebserverKind'),
  nginxFastCgi: paramsHelper('NginxFastCgi'),
  nginxFastCgiExcludedPaths: paramsHelper('NginxFastCgiExcludedPaths'),
  nginxWebserverRewrites: paramsHelper('NginxWebserverRewrites'),
  ionCubeStatus: paramsHelper('IonCubeStatus'),
  redisState: paramsHelper('RedisState'),
  websiteHtaccessRewrites: paramsHelper('WebsiteHtaccessRewrites'),
  websiteAvailablePhpExtensions: paramsHelper('WebsiteAvailablePhpExtensions'),
  websiteEnabledPhpExtensions: paramsHelper('WebsiteEnabledPhpExtensions'),
  websiteClone: paramsHelper('WebsiteClone'),
  websiteCloneLog: paramsHelper('WebsiteCloneLog'),
  // backups
  websiteBackups: paramsHelper('WebsiteBackups'),
  // mysql
  websiteMySQLDBs: paramsHelper('WebsiteMySQLDBs'),
  websiteMySQLUsers: paramsHelper('WebsiteMySQLUsers'),
  // website imports
  uploadImportMigration: paramsHelper('UploadImportMigration'),
  getImportMigrations: paramsHelper('GetImportMigrations'),
  getImportMigration: paramsHelper('GetImportMigration'),
  getImportMigrationLog: paramsHelper('GetImportMigrationLog'),
  analyzeImportMigration: paramsHelper('AnalyzeImportMigration'),
  checkImportMigrationResources: paramsHelper('CheckImportMigrationResources'),
  getImportMigrationData: paramsHelper('GetImportMigrationData'),
  createImportMigration: paramsHelper('CreateImportMigration'),
  deleteImportMigration: paramsHelper('DeleteImportMigration'),
  getImportServerDomainsCached: paramsHelper('GetImportServerDomainsCached'),
  listImportServerSettings: paramsHelper('ListImportServerSettings'),
  // orgs api
  websitesAdded: paramsHelper('WebsitesAdded'),
  customersAdded: paramsHelper('CustomersAdded'),
  getOrg: paramsHelper('GetOrg'),
  orgEmails: paramsHelper('OrgEmails'),
  getCloudflareApiKeys: paramsHelper('GetCloudflareApiKeys'),
  deleteCloudflareApiKeys: paramsHelper('DeleteCloudflareApiKeys'),
  updateCloudflareApiKeys: paramsHelper('UpdateCloudflareApiKeys'),
  createCloudflareApiKeys: paramsHelper('CreateCloudFlareApiKeys'),
  getCloudFlareKeyAffectedDomains: paramsHelper('GetCloudFlareKeyAffectedDomains'),
  orgActivities: paramsHelper('OrgActivities'),
  // customers api
  customerSubscriptions: paramsHelper('CustomerSubscriptions'),
  // plans api
  plans: paramsHelper('Plans'),
  // dns api
  defaultDnsRecords: paramsHelper('DefaultDnsRecords'),
  websiteDomainDnsZone: paramsHelper('WebsiteDomainDnsZone'),
  // domains api
  getDomainResolves: paramsHelper('GetDomainResolves'),
  websiteDomainMappings: paramsHelper('websiteDomainMappings'),
  getCloudflareNameServers: paramsHelper('GetCloudflareNameServers'),
  getCloudflareApiKeyDomain: paramsHelper('GetCloudflareApiKeyDomain'),
  domainAuthNs: paramsHelper('DomainAuthNs'),
  domainModSecStatus: paramsHelper('DomainModSecStatus'),
  domainVhost: paramsHelper('DomainVhost'),
  // members
  getMember: paramsHelper('GetMember'),
  getMembers: paramsHelper('GetMembers'),
  // settings
  orchdLogSettings: paramsHelper('OrchdLogSettings'),
  dockerRegistry: paramsHelper('DockerRegistry'),
  loginPolicySettings: paramsHelper('LoginPolicySettings'),
  loginPolicyEmailBlacklist: paramsHelper('LoginPolicyEmailBlacklist'),
  loginPolicyEmailWhitelist: paramsHelper('LoginPolicyEmailWhitelist'),
  loginPolicyIpBlacklist: paramsHelper('LoginPolicyIpBlacklist'),
  loginPolicyIpWhitelist: paramsHelper('LoginPolicyIpWhitelist'),
  backupRemoteStorageS3: paramsHelper('BackupRemoteStorageS3'),
  prohibitedDomains: paramsHelper('ProhibitedDomains'),
  globalServiceSetting: paramsHelper('GlobalServiceSetting'),
  demoMode: paramsHelper('DemoMode'),
  // ssl
  websiteDomainSslCert: paramsHelper('WebsiteDomainSslCert'),
  websiteMailDomainSslCert: paramsHelper('WebsiteMailDomainSslCert'),
  // servers
  appcdVersion: paramsHelper('AppcdVersion'),
  appcdScreenshotConfig: paramsHelper('AppcdScreenshotConfig'),
  systemPackageUpdateInfo: paramsHelper('SystemPackageUpdateInfo'),
  validateRegistrationKey: paramsHelper('ValidateRegistrationKey'),
  serverHostnameWebsite: paramsHelper('ServerHostnameWebsite'),
  serviceSetting: paramsHelper('ServiceSetting'),
  serverRoles: paramsHelper('ServerRoles'),
  serverInfo: paramsHelper('ServerInfo'),
  fsQuotaStatus: paramsHelper('FsQuotaStatus'),
  serverStats: paramsHelper('ServerStats'),
  serverDiskUsage: paramsHelper('ServerDiskUsage'),
  serverMemoryUsage: paramsHelper('ServerMemoryUsage'),
  serverNetworkStats: paramsHelper('ServerNetworkStats'),
  serverWebserverKind: paramsHelper('ServerWebserverKind'),
  serverModSecurityStatus: paramsHelper('ServerModSecurityStatus'),
  serverModSecurityConfig: paramsHelper('ServerModSecurityConfig'),
  owaspRulesVersion: paramsHelper('OwaspRulesVersion'),
  serverGroups: paramsHelper('ServerGroups'),
  setServiceStatus: paramsHelper('SetServiceStatus'),
  mysqlMyCnf: paramsHelper('MysqlMyCnf'),
  mysqlKind: paramsHelper('MysqlKind'),
  servers: paramsHelper('Servers'),
  restartMysql: paramsHelper('RestartMysql'),
  dnsPoolIps: paramsHelper('DnsPoolIps'),
  httpdStatus: paramsHelper('HttpdStatus'),
  smtpRateLimitHourly: paramsHelper('SmtpRateLimitHourly'),
  websiteGeneratedRateLimitHourly: paramsHelper('WebsiteGeneratedRateLimitHourly'),
  spamIpWhiteList: paramsHelper('SpamIpWhiteList'),
  outboundSpamScanningSettings: paramsHelper('OutboundSpamScanningSettings'),
  emailServerHostnameOverride: paramsHelper('EmailServerHostnameOverride'),
  // emails api
  websiteEmails: paramsHelper('WebsiteEmails'),
  websiteEmail: paramsHelper('WebsiteEmail'),
  websiteEmailAutoresponder: paramsHelper('WebsiteEmailAutoresponder'),
  domainEmailAuth: paramsHelper('DomainEmailAuth'),
  validateDomainEmailAuth: paramsHelper('ValidateDomainEmailAuth'),
  domainLocalRemote: paramsHelper('DomainLocalRemote'),
  emailSpamThresholds: paramsHelper('EmailSpamThresholds'),
  // migrations api
  migration: paramsHelper('Migration'),
  migrations: paramsHelper('Migrations'),
  migrationSessions: paramsHelper('MigrationSessions'),
  migrationLog: paramsHelper('MigrationLog'),
  // branding api
  branding: paramsHelper('Branding'),
  brandingSetting: paramsHelper('BrandingSetting'),
  // logins api
  otpUrl: paramsHelper('OtpUrl'),
  uiPreferences: paramsHelper('UiPreferences'),
};
