import { css } from '@emotion/core';
import { rgba } from 'polished';

import { fontAlign, fontColor, fontSize, fontWeight, margin } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './Text';

const container = (props: Props) => (theme: Theme) =>
  css`
    margin: 0;
    ${props.size && responsive(props.size, (size) => fontSize(size)(theme))}
    ${responsive(props.weight, (weight) => fontWeight(weight)(theme))}
  ${responsive(props.color, (color) => fontColor(color)(theme))}
  ${responsive(props.align, (align) => fontAlign(align)(theme))}
  ${margin({
      marginBottom: props.marginBottom,
      marginLeft: props.marginLeft,
      marginRight: props.marginRight,
      marginTop: props.marginTop,
    })(theme)}
  ${props.textDecoration &&
    css`
      text-decoration: ${props.textDecoration};
    `};
    ${props.wordBreak &&
    css`
      word-break: ${props.wordBreak};
    `};
    ${props.display &&
    responsive(
      props.display,
      (display) =>
        css`
          display: ${display};
        `
    )}
    ${props.block &&
    responsive(
      props.block,
      (block) =>
        css`
          display: block;
        `
    )}
  ${props.transform &&
    css`
      text-transform: ${props.transform};
    `};
    ${props.fullWidth &&
    css`
      width: 100%;
    `}
    ${props.maxWidth &&
    responsive(
      props.maxWidth,
      (maxWidth) =>
        css`
          max-width: ${maxWidth};
        `
    )}
  ${props.ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
    ${props.mono &&
    css`
      font-family: monospace;
    `}
  ${props.whiteSpace &&
    css`
      white-space: ${props.whiteSpace};
    `}
  ${responsive(
      props.italic,
      (italic) => css`
        font-style: ${italic ? 'italic' : 'normal'};
      `
    )}

  &:last-child {
      margin-bottom: ${responsive(props.marginBottom, (marginBottom) => theme.margins[marginBottom])}px;
    }
  `;

const highlightText = (theme: Theme) => css`
  background-color: ${rgba(theme.colors.accent, 0.3)};
`;

const styles = { container, highlightText };
export default styles;
