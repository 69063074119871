import { MysqlApiUploadSqlReq } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'src/utils/axios';

import { queryKeys } from '../query-keys';
import { mysqlApi } from './service';

export const useGetWebsiteMySQLDBs = ({
  orgId,
  websiteId,
  enabled = true,
}: {
  orgId: string;
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.websiteMySQLDBs({ orgId, websiteId }),
    queryFn: () => mysqlApi.getWebsiteMySQLDBs({ orgId, websiteId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useGetWebsiteMySQLDBUsers = ({
  orgId,
  websiteId,
  enabled = true,
}: {
  orgId: string;
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.websiteMySQLUsers({ orgId, websiteId }),
    queryFn: () => mysqlApi.getWebsiteMySQLUsers({ orgId, websiteId }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useUploadSqlMutation = () =>
  useMutation({
    mutationFn: ({ data, websiteId, dbName }: { dbName: string; websiteId: string; data: FormData }) => {
      // TODO: Changed to upload a file instead of plain text. The API spec doesn't support this yet but Adam requested
      // the change. This satisfies statement ensures that the type is still correct after any API updates.

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _ = {
        websiteId,
        dbName,
        sql: '',
        force: true,
      } satisfies MysqlApiUploadSqlReq;

      return axiosInstance.post(`/api/v2/websites/${websiteId}/mysql/${dbName}/sql?force=true`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
  });

export const useDownloadSqlMutation = () => useMutation({ mutationFn: mysqlApi.downloadSql });
