import { css } from '@emotion/core';

import { boxShadowGrey, fontSize, fontWeight } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const tooltipContent =
  ({ variant, inModal }: { variant: 'basic' | 'full'; inModal: boolean }) =>
  (theme: Theme) =>
    css`
      z-index: ${inModal ? zIndex.TooltipInModal : zIndex.Tooltip};
      max-width: 500px;

      ${variant === 'basic'
        ? css`
            padding: ${theme.paddings.md}px ${theme.paddings.lg}px;
            border-radius: 3px;
            color: ${theme.fontColors.light};
            background: ${theme.colors.dark};
            ${fontSize('sm')(theme)}
            ${fontWeight('normal')(theme)}
          `
        : css`
            padding: ${theme.paddings.lg}px;
            background: ${theme.colors.light};
            color: ${theme.fontColors.dark};
            ${fontSize('sm')(theme)}
            ${fontWeight('normal')(theme)}
            ${boxShadowGrey(1)}
          `}

      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
      will-change: transform, opacity;

      &[data-state='delayed-open'][data-side='top'] {
        animation-name: fade;
        border-bottom: 1px solid ${theme.colors.dark};
      }
      &[data-state='delayed-open'][data-side='right'] {
        animation-name: fade;
        border-left: 1px solid ${theme.colors.dark};
      }
      &[data-state='delayed-open'][data-side='bottom'] {
        animation-name: fade;
        border-top: 1px solid ${theme.colors.dark};
      }
      &[data-state='delayed-open'][data-side='left'] {
        animation-name: fade;
        border-right: 1px solid ${theme.colors.dark};
      }

      &[data-state='instant-open'][data-side='top'] {
        animation-name: fade;
        border-bottom: 1px solid ${theme.colors.dark};
      }
      &[data-state='instant-open'][data-side='right'] {
        animation-name: fade;
        border-left: 1px solid ${theme.colors.dark};
      }
      &[data-state='instant-open'][data-side='bottom'] {
        animation-name: fade;
        border-top: 1px solid ${theme.colors.dark};
      }
      &[data-state='instant-open'][data-side='left'] {
        animation-name: fade;
        border-right: 1px solid ${theme.colors.dark};
      }

      @keyframes fade {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `;

const tooltipArrow = (theme: Theme) =>
  css`
    fill: ${theme.colors.dark};
  `;

const tooltipTrigger = ({ fullWidth, ellipsis }: { fullWidth: boolean; ellipsis: boolean }) => css`
  display: flex;
  cursor: pointer;
  ${fullWidth && 'width: 100%'}
  ${ellipsis &&
  `white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;`}
`;

export const style = { tooltipContent, tooltipArrow, tooltipTrigger };
