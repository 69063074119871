import { createSelector } from 'reselect';

import { AppState } from 'src/store';

const getOrg = (state: AppState) => state.orgs.org;
const getOrgData = (state: AppState) => state.orgs.org.data;
const isOrgLoading = (state: AppState) => state.orgs.org.loading;
const getOrgMembers = (state: AppState) => state.orgs.orgMembers;

export const selectOrgName = createSelector(getOrgData, (org) => org?.name);
export const selectOrgLocale = createSelector(getOrgData, (org) => org?.locale);

export const orgsSelectors = {
  getOrg,
  getOrgMembers,
  isOrgLoading,
  selectOrgName,
  selectOrgLocale,
};
