import { GetServersReq, GetServersRes } from 'src/api_services/servers/types';
import { websitesApi } from 'src/api_services/websites';
import { createActionsFromMethod, createRequestStateActions } from 'src/store/helpers';

const namespace = '@websitesDahboard';

const getWebsites = createActionsFromMethod<typeof websitesApi.getWebsites>(`getWebsites(${namespace})`);

const getServers = createRequestStateActions<{ params: GetServersReq; data: GetServersRes }>(
  `getServers(${namespace})`
);

export const websitesDashboardActions = {
  getWebsites,
  getServers,
};
