import { DemoMode, SettingKind } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { settingsApi } from './service';

export const useGetLoginPolicySettingsQuery = () =>
  useQuery({
    queryKey: queryKeys.loginPolicySettings(),
    queryFn: () => settingsApi.getOrchdLoginPolicySettings({}),
    select: ({ data }) => data,
    refetchOnMount: true,
  });

export const useGetLoginPolicyIpWhitelistQuery = () =>
  useQuery({
    queryKey: queryKeys.loginPolicyIpWhitelist(),
    queryFn: () => settingsApi.getOrchdLoginPolicyIpWhitelist({}),
    select: ({ data }) => data.ipList,
    refetchOnMount: true,
  });

export const useGetLoginPolicyIpBlacklistQuery = () =>
  useQuery({
    queryKey: queryKeys.loginPolicyIpBlacklist(),
    queryFn: () => settingsApi.getOrchdLoginPolicyIpBlacklist({}),
    select: ({ data }) => data.ipList,
    refetchOnMount: true,
  });

export const useGetLoginPolicyEmailWhitelistQuery = () =>
  useQuery({
    queryKey: queryKeys.loginPolicyEmailWhitelist(),
    queryFn: () => settingsApi.getOrchdLoginPolicyEmailWhitelist({}),
    select: ({ data }) => data.emailList,
    refetchOnMount: true,
  });

export const useGetLoginPolicyEmailBlacklistQuery = () =>
  useQuery({
    queryKey: queryKeys.loginPolicyEmailBlacklist(),
    queryFn: () => settingsApi.getOrchdLoginPolicyEmailBlacklist({}),
    select: ({ data }) => data.emailList,
    refetchOnMount: true,
  });

export const useGetProhibitedDomainsQuery = () =>
  useQuery({
    queryKey: queryKeys.prohibitedDomains(),
    queryFn: () => settingsApi.getProhibitedDomains({}),
    select: ({ data }) => data,
    refetchOnMount: true,
  });

export const useGetGlobalServiceSettingQuery = ({
  enabled = true,
  settingKind,
}: {
  enabled?: boolean;
  settingKind: SettingKind;
}) =>
  useQuery({
    queryKey: queryKeys.globalServiceSetting({ settingKind }),
    queryFn: () => settingsApi.getGlobalServiceSetting({ settingKind }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
  });

export const useGetDemoModeQuery = () =>
  useQuery({
    queryKey: queryKeys.globalServiceSetting(),
    queryFn: () => settingsApi.getDemoMode({}),
    select: ({ data }) => data,
    initialData: () => ({ data: { status: false } as DemoMode }),
    refetchOnMount: true,
  });

export const useSetLoginPolicySettingsMutation = () =>
  useMutation({ mutationFn: settingsApi.addOrchdLoginPolicySettings });

export const useAddLoginPolicyIpWhitelistMutation = () =>
  useMutation({ mutationFn: settingsApi.addOrchdLoginPolicyIpWhitelist });

export const useAddLoginPolicyIpBlacklistMutation = () =>
  useMutation({ mutationFn: settingsApi.addOrchdLoginPolicyIpBlacklist });

export const useDeleteLoginPolicyIpWhitelistMutation = () =>
  useMutation({ mutationFn: settingsApi.deleteOrchdLoginPolicyIpWhitelist });

export const useDeleteLoginPolicyIpBlacklistMutation = () =>
  useMutation({ mutationFn: settingsApi.deleteOrchdLoginPolicyIpBlacklist });

export const useAddLoginPolicyEmailWhitelistMutation = () =>
  useMutation({ mutationFn: settingsApi.addOrchdLoginPolicyEmailWhitelist });

export const useAddLoginPolicyEmailBlacklistMutation = () =>
  useMutation({ mutationFn: settingsApi.addOrchdLoginPolicyEmailBlacklist });

export const useDeleteLoginPolicyEmailWhitelistMutation = () =>
  useMutation({ mutationFn: settingsApi.deleteOrchdLoginPolicyEmailWhitelist });

export const useDeleteLoginPolicyEmailBlacklistMutation = () =>
  useMutation({ mutationFn: settingsApi.deleteOrchdLoginPolicyEmailBlacklist });

export const useGetBackupRemoteStorageS3Query = ({ enabled = true }) =>
  useQuery({
    queryKey: queryKeys.backupRemoteStorageS3(),
    queryFn: () => settingsApi.getBackupRemoteStorageS3({}),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
    retry: false,
  });

export const useCreateBackupRemoteStorageS3Mutation = () =>
  useMutation({ mutationFn: settingsApi.createBackupRemoteStorageS3 });

export const useUpdateBackupRemoteStorageS3Mutation = () =>
  useMutation({ mutationFn: settingsApi.updateBackupRemoteStorageS3 });

export const useDeleteBackupRemoteStorageS3Mutation = () =>
  useMutation({ mutationFn: settingsApi.deleteBackupRemoteStorageS3 });

export const useSetProhibitedDomainsMutation = () => useMutation({ mutationFn: settingsApi.setProhibitedDomains });

export const useSetGlobalServiceSettingMutation = () =>
  useMutation({ mutationFn: settingsApi.setGlobalServiceSetting });

export const useGetOrchdLogSettingsQuery = () => {
  return useQuery({
    queryKey: queryKeys.orchdLogSettings(),
    queryFn: () => settingsApi.getOrchdLogSettings({}),
    select: ({ data }) => data,
    refetchOnMount: true,
  });
};

export const useSetOrchdLogSettingsMutation = () => useMutation({ mutationFn: settingsApi.setOrchdLogSettings });
