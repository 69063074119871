/** @jsxRuntime classic */
/** @jsx jsx */
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { jsx } from '@emotion/core';
import { Close, Description, Root } from '@radix-ui/react-toast';
import { useIntl } from 'react-intl';

import { Box } from 'src/components/Box';
import { Copy } from 'src/components/Copy/Copy';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { getDurationAsMilliseconds } from 'src/utils/dateTimes/dateTimes';
import { useToast } from 'src/utils/toast/useToast';

import { style } from './Toast.style';

import type { ToastVariant } from './types';
export interface Props {
  id: string;
  children: ReactNode;
  variant?: ToastVariant;
  dismissible?: boolean;
  persist?: boolean;
}

const icons = {
  neutral: <Icon name="loading" color="light" />,
  success: <Icon name="check" color="light" />,
  warning: <Icon name="warning" color="light" />,
  info: <Icon name="info" color="light" />,
  error: <Icon name="error alt" color="light" />,
};

export const Toast = ({ children, id, variant = 'neutral', dismissible = true, persist = false }: Props) => {
  const toast = useToast();
  const [open, setOpen] = useState(true);
  const shouldDismissRef = useRef(true);
  const { formatMessage } = useIntl();

  const messageAsString = useMemo(() => {
    if (!children) {
      return '';
    }

    if (typeof children === 'object' && 'props' in children && children.props.id && children.props.defaultMessage) {
      return formatMessage(children.props, children.props.values);
    }

    return String(children);
  }, [children, formatMessage]);

  // Clean the toast reference from state.
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        if (shouldDismissRef.current) {
          toast.dismiss(id);
        }
      }, 1000);
      // required as package look away handler doesn't wake up on focus
    } else if (open && !persist) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  }, [open, id, toast, persist]);

  useEffect(() => {
    return () => {
      shouldDismissRef.current = false;
    };
  }, []);

  return (
    <Root
      // TODO: Add duration back in when package focus handler is fixed.
      // duration={persist ? getDurationAsMilliseconds(1, 'hour') : getDurationAsMilliseconds(5, 'seconds')}
      duration={getDurationAsMilliseconds(1, 'hour')}
      open={open}
      onOpenChange={setOpen}
      css={style.toastContent({ variant })}
      aria-label={`toast-${variant}`}
    >
      <Description>
        <Box p="0" wrap="nowrap" minW="0" maxW="550px" a="center">
          <Box css={{ marginTop: 2 }} w="16px" mr="sm">
            {icons[variant]}
          </Box>

          <Box a="center" j="center" p="0" minW="0" w="auto" css={{ flex: 1 }}>
            <Text size="sm" color="light" tag="div">
              {children}
            </Text>
          </Box>

          {/*
            The object check is there to hide the copy button if we can't extract proper text content. In the future we
            should add a toast prop that provides the text content or otherwise adjust this.
          */}
          {variant === 'error' && messageAsString !== '[object Object]' && (
            <Copy iconColor="light" inModal noLabel value={messageAsString} variant="red" />
          )}

          {dismissible && (
            <Close css={style.toastDismiss}>
              <Icon marginLeft="xs" name="close" color="light" hoverName="close" size="sm" />
            </Close>
          )}
        </Box>
      </Description>
    </Root>
  );
};
