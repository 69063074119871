import { css } from '@emotion/core';

import { margin } from 'src/design-system/mixins';

import { Theme } from '../../design-system/theme';

import type { Props } from './StatusIcon';

const container = (props: Props) => (theme: Theme) =>
  css`
    ${margin({
      marginBottom: props.marginBottom,
      marginLeft: props.marginLeft,
      marginRight: props.marginRight,
      marginTop: props.marginTop,
    })(theme)}
  `;

export const style = { container };
