export const reducers = ['breadcrumbs', 'fileManager', 'router', 'poppers'];
export const slice = '<<STATE_TOO_BIG_FOR_DEVTOOLS>>';

export const stateSanitizer = <S>(state: S) => {
  const sanitizedState = reducers.reduce<Record<string, string>>(
    (acc, curr) => ({
      ...acc,
      [curr]: slice,
    }),
    {}
  );

  return {
    ...state,
    ...sanitizedState,
  };
};
