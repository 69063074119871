import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { leftString, margin } from 'src/design-system/mixins';
import { Colors } from 'src/design-system/style-types';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './PercentageBar';

const stripedBars = (color: Colors) => (theme: Theme) =>
  css`
    background-image: repeating-linear-gradient(
      45deg,
      ${theme.colors[color]},
      ${theme.colors.grey70} 1px,
      ${theme.colors[color]} 2px,
      ${theme.colors[color]} 4px
    );
  `;

const container = (props: Props) => (theme: Theme) =>
  css`
    width: 100%;
    height: 22px;
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: hidden;
    font-size: 16px;
  `;

const outerBar = (props: Props) => (theme: Theme) =>
  css`
    border-radius: 2px;
    width: 100%;
    ${props.variant === 'simple' ? `background: ${theme.colors.grey5}` : `border: 1px solid ${theme.colors.grey25}`};
    ${props.variant === 'simple' ? '' : 'padding: 1px'};
    ${props.showBreakdown &&
    css`
      margin-bottom: ${theme.margins.sm}px;
    `}
    ${responsive(
      props.height,
      (height) => css`
        height: ${height === 'normal' ? '18px' : '8px'};
      `
    )}
  `;

const bar =
  (
    props: Props & {
      color: Colors | undefined;
      percentage: number;
      withPattern?: boolean;
      index?: number;
      totalBars?: number;
    }
  ) =>
  (theme: Theme) => {
    return css`
      min-width: 8px;
      ${props.variant === 'simple' ? '' : 'border-radius: 2px;'}
      background-color: ${theme.colors[props.color || 'accent']};
      height: 100%;
      width: ${props.percentage}%;
      cursor: pointer;
      position: relative;
      ${props.variant === 'simple' &&
      css`
        transition: transform 0.3s ease;
        &:hover {
          transform: scaleY(1.5);
        }
      `}
      ${props.index !== undefined &&
      props.index > 0 &&
      css`
          margin-${leftString(theme)}: ${props.variant === 'simple' ? '0' : '1px'};
        `}
      ${(props.index === undefined || props.index === 0) &&
      css`
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      `}
      ${(props.index === undefined || (props.totalBars !== undefined && props.index === props.totalBars - 1)) &&
      css`
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      `}
      ${props.withPattern && stripedBars(props.color || 'accent')(theme)}
    `;
  };

const legendWrapper = (theme: Theme) => css`
  display: flex;
  margin-top: ${theme.margins.sm}px;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const legendItemWrapper = (theme: Theme) => css`
  ${margin({ right: 'md' })(theme)}
  display: flex;
  align-content: center;
  cursor: pointer;
  margin-bottom: ${theme.margins.sm}px;
`;

const legendItem =
  (props: Props, { color, withPattern }: { color: Colors; percentage: number; withPattern?: boolean }) =>
  (theme: Theme) =>
    css`
      display: flex;
      height: 14px;
      width: 14px;
      background-color: ${theme.colors[color]};
      border-radius: 9999px;
      ${margin({ right: 'xs' })(theme)};
      ${withPattern && stripedBars(color)(theme)}
    `;

export const style = { container, outerBar, bar, legendWrapper, legendItemWrapper, legendItem };
