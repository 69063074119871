import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { Theme } from 'src/design-system/theme';

import type { ScreenshotConfig } from './Screenshot';

const imageContainer =
  ({ imageUrl, aspectRatio, fixedHeight, isError }: ScreenshotConfig) =>
  (theme: Theme) =>
    css`
      background-color: ${theme.colors.grey50};
      display: block;
      width: 100%;
      position: relative;

      ${!fixedHeight &&
      css`
        padding: ${aspectRatio} 0 0 0;
        height: auto;
      `}

      ${fixedHeight &&
      css`
        height: ${fixedHeight}px;
      `}

  ${!isError &&
      css`
        background-image: url(${imageUrl});
      `}

  background-size: cover;
      background-position: center;
    `;

const image = css`
  display: none;
`;

const refreshIcon = (theme: Theme) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  background: ${theme.colors.dark};
  padding: ${theme.paddings.md}px;
  opacity: 0.5;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${breakpoints.md}px) {
    bottom: auto;
    top: 0;
  }
`;

export const style = { image, imageContainer, refreshIcon };
