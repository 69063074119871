import { AppState } from 'src/store';

export const fileManagerSelectors = {
  chmod: (state: AppState) => state.fileManager.chmod,
  clipboard: (state: AppState) => state.fileManager.clipboard,
  contextMenu: (state: AppState) => state.fileManager.contextMenu,
  editor: (state: AppState) => state.fileManager.editor,
  isTreeLoading: (state: AppState) => state.fileManager.tree.loading,
  rename: (state: AppState) => state.fileManager.rename,
  selected: (state: AppState) => state.fileManager.selected,
  tree: (state: AppState) => state.fileManager.tree.data,
  uploader: (state: AppState) => state.fileManager.uploader,
  overwrites: (state: AppState) => state.fileManager.overwrites,
  fileInfo: (state: AppState) => state.fileManager.fileInfo,
};
