import { createActionCreator } from 'deox';

import { backupsApi } from 'src/api_services/backups/service';
import { emailsApi } from 'src/api_services/emails/service';
import { mysqlApi } from 'src/api_services/mysql/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@website-backups';

export const backupWebsite = createActionsFromMethod<typeof backupsApi.backupWebsite>(`backupWebsite(${namespace})`);

export const deleteWebsiteBackup = createActionsFromMethod<typeof backupsApi.deleteWebsiteBackup>(
  `deleteWebsiteBackup(${namespace})`
);
export const getWebsiteBackup = createActionsFromMethod<typeof backupsApi.getWebsiteBackup>(
  `getWebsiteBackup(${namespace})`
);
export const getWebsiteBackups = createActionsFromMethod<typeof backupsApi.getWebsiteBackups>(
  `getWebsiteBackups(${namespace})`
);
export const getWebsiteMySQLDBs = createActionsFromMethod<typeof mysqlApi.getWebsiteMySQLDBs>(
  `getWebsiteMySQLDBs(${namespace})`
);
export const getWebsiteEmails = createActionsFromMethod<typeof emailsApi.getWebsiteEmails>(
  `getWebsiteEmails(${namespace})`
);
export const restoreWebsite = createActionsFromMethod<typeof backupsApi.restoreWebsite>(`restoreWebsite(${namespace})`);
export const setRestoringWebsites = createActionCreator(
  `setRestoringWebsites(${namespace})`,
  (resolve) => (payload: { websiteId: string; restoring: boolean }) => {
    return resolve(payload);
  }
);

export const websiteBackupsActions = {
  backupWebsite,
  deleteWebsiteBackup,
  getWebsiteBackup,
  getWebsiteBackups,
  restoreWebsite,
  getWebsiteMySQLDBs,
  setRestoringWebsites,
  getWebsiteEmails,
};
