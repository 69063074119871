import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { settingsActions } from './actions';

export const settingsReducer = combineReducers({
  setting: createRequestStateReducer(settingsActions.getSetting),
  settings: createRequestStateReducer(settingsActions.getSettings),
  globalServiceSetting: createRequestStateReducer(settingsActions.getGlobalServiceSetting),
});
