import { css } from '@emotion/core';

import { boxShadowGrey } from 'src/design-system/mixins';
import { Margins, Paddings, Responsive } from 'src/design-system/style-types';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './Card';

const margin =
  (marginBottom: Responsive<Margins>) =>
  ({ margins }: Theme) =>
    css`
      ${responsive(
        marginBottom,
        (marginBottom) => css`
          margin-bottom: ${margins[marginBottom]}px;
        `
      )}
    `;

const padding =
  (padding: Responsive<Paddings>) =>
  ({ paddings }: Theme) =>
    css`
      ${responsive(
        padding,
        (padding) => css`
          padding: ${paddings[padding]}px;
        `
      )}
    `;

const container =
  ({
    background,
    borderColor,
    fullHeight,
    fullWidth,
    marginBottom,
    marginLeft,
    minWidth,
    noShadow,
    relative,
    stretch,
  }: Props) =>
  (theme: Theme) => {
    const width = fullWidth ? '100%' : 'auto';

    return css`
      border-radius: 2px;
      background-color: ${theme.colors[background]};
      display: flex;
      flex-direction: column;
      width: ${width};
      ${responsive(
        marginBottom,
        (marginBottom) =>
          css`
            margin-bottom: ${theme.margins[marginBottom]}px;
          `
      )}
      ${responsive(
        marginLeft,
        (marginLeft) =>
          css`
            margin-left: ${theme.margins[marginLeft]}px;
          `
      )}



      ${responsive(noShadow, (noShadow) => !noShadow && boxShadowGrey(1))}

      ${borderColor !== 'transparent' &&
      css`
        border: 1px solid ${theme.colors[borderColor]};
      `}

    ${fullHeight &&
      css`
        flex: 1;
        height: 100%;
      `}

    ${minWidth &&
      responsive(
        minWidth,
        (minWidth) => css`
          min-width: ${minWidth};
        `
      )}

${relative &&
      css`
        position: relative;
      `}

    ${stretch &&
      css`
        align-self: stretch;
      `}
    `;
  };

export const style = { margin, padding, container };
