import { getType } from 'deox';
import { Middleware } from 'redux';

import { impersonationActions } from 'src/store/impersonation/actions';
import { persister } from 'src/utils/persister/persister';

import { globalSearchActions } from './actions';

export const recentSearchesPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(globalSearchActions.pushRecentSearch):
    case getType(globalSearchActions.removeRecentSearch): {
      persister.set({ key: 'globalSearch', item: store.getState().globalSearch.recentSearches, isPerUser: true });
      break;
    }
    case getType(impersonationActions.stopImpersonating):
      persister.clear({ key: 'globalSearch', isPerUser: true });
  }

  return res;
};
