import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'h2',
  weight: 'light',
  size: '2xl',
};

/**
 * # Text H2
 * Use H2 to present the main headline
 */
const H2 = (props: Props) => <Text {...props} />;

H2.defaultProps = updatedDefaultProps;

export default H2;
