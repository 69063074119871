import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { boxShadowGrey, shadowBorder, shadowBorderBottom } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

import { SelectVariant } from './types';

const trigger =
  ({
    open,
    variant,
    invalid,
    touched,
    searchable,
    maxWidth,
    flex,
  }: {
    open: boolean;
    variant?: SelectVariant;
    invalid?: boolean;
    touched?: boolean;
    searchable?: boolean;
    maxWidth?: number;
    flex: number;
  }) =>
  (theme: Theme) =>
    css`
      white-space: nowrap;
      min-height: ${variant === 'small' ? '32px' : '40px'};
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.paddings.md}px;
      padding: 6px ${theme.paddings.lg}px;
      user-select: none;
      cursor: pointer;
      flex: ${flex};
      width: 100%;

      &[aria-disabled='true'] {
        opacity: 0.5;
        pointer-events: none;
      }

      ${maxWidth &&
      `
        max-width: ${maxWidth}px;
      `}

      ${searchable &&
      `
        cursor: text;
      `}

      background: ${theme.colors.grey5};
      ${shadowBorderBottom('dark')(theme)}

      ${variant === 'filter' &&
      css`
        background: transparent;
        ${shadowBorderBottom('grey20')(theme)}
      `}

      ${variant === 'transparent' &&
      css`
        background: transparent;
      `}

      ${open &&
      variant !== 'filter' &&
      css`
        background: ${theme.colors.light};
        ${shadowBorder('dark')(theme)}
      `}

      ${invalid && touched && shadowBorder('error', 2)(theme)}

      @media (max-width: ${breakpoints.sm}px) {
        flex: 1;
      }
    `;

const dropdown =
  ({ width, maxHeight }: { width: number; maxHeight: number }) =>
  (theme: Theme) =>
    css`
      ${boxShadowGrey(1)}
      background: ${theme.colors.light};
      z-index: ${zIndex.Dropdown};
      outline: none;
      width: ${width}px;
      padding: ${theme.paddings.xs}px 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      max-height: ${maxHeight}px;
      overflow-y: auto;

      &[data-state='open'][data-side='top'] {
        border-bottom: 1px solid ${theme.fontColors.dark};

        svg:last-of-type {
          transform: translateX(${width / 2 - 23}px);
        }

        input:first-of-type {
          bottom: -28px;
        }
      }

      &[data-state='open'][data-side='bottom'] {
        border-top: 1px solid ${theme.fontColors.dark};

        svg:last-of-type {
          transform: translateX(-${width / 2 - 23}px);
        }

        input:first-of-type {
          top: -28px;
        }
      }
    `;

const dropdownInner = ({ height }: { height: number }) => css`
  position: relative;
  width: 100%;
  height: ${height}px;
  min-height: ${height}px;
`;

const dropdownItem =
  ({ active, rowStart }: { active: boolean; rowStart?: number }) =>
  (theme: Theme) =>
    css`
      padding: ${theme.paddings.sm}px ${theme.paddings.md}px;
      cursor: pointer;
      user-select: none;
      width: 100%;

      ${rowStart !== undefined &&
      `
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(${rowStart}px);
      `}

      &:hover {
        background: ${theme.colors.grey5};
      }

      ${active &&
      `
        background: ${theme.colors.grey5};
      `}

      &[aria-disabled='true'] {
        opacity: 0.5;
        pointer-events: none;
      }
    `;

const arrow = (theme: Theme) =>
  css`
    fill: ${theme.fontColors.dark};
  `;

const searchable =
  ({ hidden }: { hidden: boolean }) =>
  (theme: Theme) =>
    css`
      position: absolute;
      left: 0;
      padding: 0 0 0 ${theme.paddings.lg}px;
      background: transparent;
      border: none;
      color: ${theme.fontColors.dark};
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizes.md}px;
      outline: none;
      width: calc(100% - 64px);

      ${hidden &&
      `
        width: 0;
        height: 0;
        top: -9999px;
      `}
    `;

const pill =
  ({ open, variant }: { open: boolean; variant?: SelectVariant }) =>
  (theme: Theme) =>
    css`
      font-family: ${theme.fontFamily};
      font-size: ${variant === 'filter' ? theme.fontSizes.sm : theme.fontSizes.md}px;
      display: flex;
      gap: ${theme.paddings.sm}px;
      padding: 1px 6px;
      background: ${theme.colors.light};
      border: 1px solid transparent;

      ${open &&
      `
        border-color: ${theme.colors.grey};
      `}
    `;

const closeIcon = css`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const style = { trigger, dropdown, dropdownInner, dropdownItem, arrow, searchable, pill, closeIcon };
