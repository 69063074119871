import { CPLocale } from 'src/orchd-client';
import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import { AppState } from 'src/store';
import { brandingSelectors } from 'src/store/branding/selectors';
import { supportedLocales } from 'src/store/intl/locales';
import { uiSelectors } from 'src/store/ui/selectors';
import { DEFAULT_LOCALE } from 'src/utils/constants';

const selectLoginState = (state: AppState) => state.logins.login;
const selectLogin = (state: AppState) => state.logins?.login.data;
const selectSessionsState = (state: AppState) => state.logins.sessions;
const passwordRecoveryState = (state: AppState) => state.logins.passwordRecovery;
const selectUpdateLoginState = (state: AppState) => state.logins.updateLoginInfo;
const selectIsAvatarDeleting = (state: AppState) => state.logins.deleteAvatar.loading;
const selectAvatarCacheInvalidate = (state: AppState) => state.logins.avatarCacheInvalidate;

const logins = (state: AppState) => state.logins.logins.data?.items;
const loginsLoading = (state: AppState) => state.logins.logins.loading;
const loginsTotal = (state: AppState) => state.logins.logins.data?.total;
const loginsError = (state: AppState) => state.logins.logins.error;

const loginMemberships = (state: AppState) => state.logins.loginMemberships.data?.memberships;

export const selectLoginLocale = createSelector(selectLogin, (login) => login?.locale);

const preferredBrowserLocales = navigator?.languages ?? [navigator?.language];

const safeBrowserLocale = intersection(preferredBrowserLocales, supportedLocales)?.[0] as CPLocale | undefined;

export const selectLocale = (browserLocale: CPLocale | undefined) =>
  createSelector(
    uiSelectors.tempLocale,
    selectLoginLocale,
    brandingSelectors.selectBrandingData,
    (tempLocale, loginLocale, brandingData): CPLocale => {
      return tempLocale ?? loginLocale ?? browserLocale ?? brandingData?.locale ?? DEFAULT_LOCALE;
    }
  );

export const loginsSelectors = {
  logins,
  loginsError,
  loginsLoading,
  loginsTotal,
  loginMemberships,
  passwordRecoveryState,
  selectAvatarCacheInvalidate,
  selectIsAvatarDeleting,
  selectLocale: selectLocale(safeBrowserLocale),
  selectLoginLocale,
  selectLogin,
  selectLoginState,
  selectSessionsState,
  selectUpdateLoginState,
};
