import { DomainMapping, DomainMappingKind } from 'src/orchd-client';
import dayjs from 'dayjs';

import { SslStatusTypes } from 'src/utils/types';

export const isSelfSignedSsl = (domainMapping?: DomainMapping) => {
  if (!domainMapping?.cert) return false;
  const { issuer } = domainMapping.cert;

  return [domainMapping.domain, 'Enhance', 'Hosting Control Panel'].includes(issuer);
};

export const getPrimaryDomainMapping = (domainMappings: DomainMapping[]) =>
  domainMappings.find(({ mappingKind }) => mappingKind === 'primary')!;

const isCertificateValid = (validFrom: string, validTo: string) =>
  dayjs().isBetween(validFrom, validTo, undefined, '[]');

export const getSslStatus = (domainMapping?: DomainMapping): SslStatusTypes => {
  if (!domainMapping?.cert) {
    return 'disabled';
  }

  const { cert } = domainMapping;
  const { issued, expires } = cert;

  if (isCertificateValid(issued, expires)) {
    return 'enabled';
  }

  return 'expired';
};

export const getCanMakePrimary = (mappingKind: DomainMappingKind | undefined): boolean => {
  if (!mappingKind) return false;
  return [DomainMappingKind.addon, DomainMappingKind.alias].includes(mappingKind);
};
