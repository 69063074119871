import { getType } from 'deox';
import { combineReducers } from 'redux';
import { takeEvery } from 'redux-saga/effects';

import { invitesApi } from 'src/api_services/invites/service';
import { AppState } from 'src/store';
import { createActionsFromMethod, createRequestStateReducer, createRequestStateSaga } from 'src/store/helpers';

const PREFIX = `invites`;

const validateInvite = createActionsFromMethod<typeof invitesApi.validateInvite>(`validateInvite(${PREFIX})`);
const acceptInvite = createActionsFromMethod<typeof invitesApi.acceptInvite>(`acceptInvite(${PREFIX})`);

export const invitesActions = {
  validateInvite,
  acceptInvite,
};

const [validateInviteSaga] = createRequestStateSaga(validateInvite, invitesApi.validateInvite);
const [acceptInviteSaga] = createRequestStateSaga(acceptInvite, invitesApi.acceptInvite);

export function* invitesSaga() {
  yield takeEvery(getType(validateInvite.request), validateInviteSaga);
  yield takeEvery(getType(acceptInvite.request), acceptInviteSaga);
}

const getValidateInviteReducer = createRequestStateReducer(validateInvite);
const getAcceptInviteReducer = createRequestStateReducer(acceptInvite);

export const invitesReducer = combineReducers({
  acceptInvite: getAcceptInviteReducer,
  validateInvite: getValidateInviteReducer,
});

export const invitesSelectors = {
  acceptInviteLoading: (state: AppState) => state.invites.acceptInvite.loading,
  validateInvite: (state: AppState) => state.invites.validateInvite,
};
