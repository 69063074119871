import { AnyAction } from 'redux';

export const actionTypes = [
  'getBranding',
  'getDashboardWebsites',
  'getGlobalInstallableApps',
  'getPlans',
  'getServerStats',
  'getServers',
  'getServerRoles',
  'getWebsite',
  'getWebsiteMySQLUsers',
  'getWebsites',
  'getWordPressThemes',
  'getWordPressPlugins',
  'setSingleServerStatuses',
  'UPDATE_BREADCRUMB',
];

export const payload = '<<PAYLOAD_TOO_BIG_FOR_DEVTOOLS>>';

export const actionSanitizer = <A extends AnyAction>(action: A) => {
  const isExpensiveAction = actionTypes.some((type) => action.type.indexOf(type) > -1);
  return isExpensiveAction && action.payload ? { ...action, payload } : action;
};
