/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Icon } from 'src/components/Icon';
import { Margins, Sizes } from 'src/design-system/style-types';
import { StatusTypes } from 'src/utils/types';

import { style } from './StatusIcon.style';

interface RequireProps {
  type: StatusTypes;
}

interface DefaultProps {
  className: string;
  size: Sizes;
  marginRight: Margins;
  marginLeft: Margins;
  marginBottom: Margins;
  marginTop: Margins;
}

export type Props = RequireProps & DefaultProps;

const defaultProps: DefaultProps = {
  className: '',
  size: 'lg',
  marginRight: '0',
  marginLeft: '0',
  marginBottom: '0',
  marginTop: '0',
};

const StatusIcon = (props: RequireProps & DefaultProps) => {
  const { type, className, size } = props;

  return (
    <span className={className} css={style.container(props)}>
      {type === 'decommissioned' && <Icon size={size} name="cancel alt" data-qa="decommissioned" color="disabled" />}
      {type === 'info' && <Icon size={size} name="info alt" data-qa="info" color="info" />}
      {type === 'warning' && <Icon size={size} name="warning alt" data-qa="warning" color="warning" />}
      {type === 'error' && <Icon size={size} name="error alt" color="error" data-qa="error" />}
      {type === 'success' && <Icon size={size} name="check circle alt" color="success" data-qa="success" />}
      {type === 'loading' && <Icon size={size} name="loading" color="grey" data-qa="loading" />}
      {type === 'unknown' && <Icon size={size} name="question alt" color="disabled" data-qa="question" />}
    </span>
  );
};

StatusIcon.defaultProps = defaultProps;

export default StatusIcon;
