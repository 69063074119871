import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { CloudFlareStatus, DomainMapping, LoginMembership, Website, WebsiteKind } from 'src/orchd-client';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetWebsiteAppsQuery } from 'src/api_services/apps/query';
import { useGetDomainResolvesQuery } from 'src/api_services/domains/query';
import { useDeleteWebsiteMutation } from 'src/api_services/websites/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Container } from 'src/components/Container';
import { Copy } from 'src/components/Copy/Copy';
import { DropdownMenu, DropdownOption } from 'src/components/DropdownMenu/DropdownMenu';
import { MiddleTruncate } from 'src/components/MiddleTruncate';
import { Screenshot } from 'src/components/Screenshot';
import { SslStatus } from 'src/components/SslStatus/SslStatus';
import { Text } from 'src/components/Text';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { useWebsiteSubscription } from 'src/hooks/useSubscriptions/useSubscriptions';
import { messages } from 'src/messages';
import { KindPillMap } from 'src/pages/websites/dashboard/Domains/KindPill';
import { AppState } from 'src/store';
import { alertModalActions } from 'src/store/alert-modal';
import { brandingSelectors } from 'src/store/branding/selectors';
import { promisifyRequestAction } from 'src/store/helpers';
import { toastActions } from 'src/store/toast';
import { websitesActions } from 'src/store/websites/actions';
import { getErrorInfo } from 'src/utils/errors';
import { isWebsiteNormalOrStaging } from 'src/utils/isWebsiteNormal';
import { useModal } from 'src/utils/modal/useModal';
import { PackageAllowanceName } from 'src/utils/packages/types';
import { getSubscriptionAllowance } from 'src/utils/packages/utils';
import { getSslStatus, isSelfSignedSsl } from 'src/utils/sslDomainMapping/utils';
import { useToast } from 'src/utils/toast/useToast';
import { isWebsiteActive } from 'src/utils/website';
import { zIndex } from 'src/utils/zIndex';

import { WpAdminButton } from '../../apps/manage-wordpress/WpAdminButton/WpAdminButton';
import { PushLiveToExistingModal } from './PushLiveToExistingModal';
import { PushLiveToNewModal } from './PushLiveToNewModal';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

export interface Props extends ActionProps, StateProps {
  website: Website;
  member: LoginMembership;
  websiteDomainMapping?: DomainMapping;
  isSinglePageDashboard?: boolean;
}

const stateToProps = (state: AppState) => ({
  isDesktopUp: viewportSelectors.isDesktopUp(state),
  stagingDomain: brandingSelectors.selectStagingDomain(state),
});

export const dispatchToProps = {
  createPreviewDomain: promisifyRequestAction(websitesActions.createPreviewDomain.request),
  resetPreviewDomain: websitesActions.createPreviewDomain.reset,
  getWebsite: promisifyRequestAction(websitesActions.getWebsite.request),
  getScreenshotTimestamp: promisifyRequestAction(websitesActions.getScreenshotTimestamp.request),
  takeScreenshot: promisifyRequestAction(websitesActions.takeScreenshot.request),
  errorToast: toastActions.errorToast,
  showAlertModal: alertModalActions.showAlertModal,
};

export const _Hero = ({
  website,
  member,
  stagingDomain,
  createPreviewDomain,
  errorToast,
  getScreenshotTimestamp,
  getWebsite,
  isDesktopUp,
  resetPreviewDomain,
  takeScreenshot,
  websiteDomainMapping,
  showAlertModal,
  isSinglePageDashboard = false,
}: Props) => {
  const { orgId, isMasterOrg } = member;
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();
  const showPushLiveToNewModal = useModal(PushLiveToNewModal);
  const showPushLiveToExistingModal = useModal(PushLiveToExistingModal);
  const { push } = useHistory();
  const { subscription } = useWebsiteSubscription({ orgId, website });

  const showClone = useMemo(() => {
    return getSubscriptionAllowance(subscription, PackageAllowanceName.featureWebsiteClone) || isMasterOrg;
  }, [isMasterOrg, subscription]);

  const sslIsSelfSigned = isSelfSignedSsl(websiteDomainMapping);
  const sslStatus = getSslStatus(websiteDomainMapping);

  const { mutateAsync: deleteWebsite } = useDeleteWebsiteMutation();

  const { domain } = website;

  const isCloudflare = [CloudFlareStatus.Connected, CloudFlareStatus.Error].includes(domain.cloudflareStatus);

  const { data: domainResolvesData, isFetching: isFetchingDnsResolves } = useGetDomainResolvesQuery({
    isCloudflare,
    domainId: domain.id,
    orgId,
    websiteId: website.id,
  });

  const { data: websiteApps = [] } = useGetWebsiteAppsQuery({ orgId, websiteId: website.id });

  const websiteAppsSortedByRoute = [...websiteApps].sort((a, b) => {
    return (a.path?.length ?? 0) - (b.path?.length ?? 0);
  });

  const latestWordpress = websiteAppsSortedByRoute?.[0];

  useEffect(
    () => () => {
      resetPreviewDomain();
    },
    [orgId, resetPreviewDomain, website.id]
  );

  const isStagingWebsite = website.kind === WebsiteKind.staging;
  const isResolves = !!domainResolvesData?.resolves;

  const showVisit = isResolves && isWebsiteNormalOrStaging(website.kind);

  const handleDeleteClick = () => {
    showAlertModal({
      variant: 'warning',
      titleText: formatMessage(messages.delete_website),
      bodyText: (
        <Fragment>
          <Text size="sm" marginBottom="md" tag="div">
            <FormattedMessage
              id="websites.website_dashboard.hero.delete_modal.body_1"
              defaultMessage="{domain} and all associated email accounts will be permanently deleted."
              values={{ domain: website.domain.domain }}
            />
          </Text>
          <Text size="sm" tag="div">
            <FormattedMessage
              id="websites.website_dashboard.hero.delete_modal.body_2"
              defaultMessage="You will not be able to recover this website once deleted."
            />
          </Text>
        </Fragment>
      ),
      primaryButton: {
        text: formatMessage(messages.delete),
        onClick: async ({ hide, loadingStart, loadingStop }) => {
          try {
            loadingStart();
            await deleteWebsite({
              orgId,
              websiteId: website.id,
            });

            toast.success(
              <FormattedMessage
                id="websites.website_dashboard.deleted_successfully"
                defaultMessage="Website ''{domain}'' deleted"
                values={{ domain: website.domain.domain }}
              />
            );

            push('/websites');
            hide();
          } catch (e) {
            const { message } = getErrorInfo(e);

            toast.error(
              <FormattedMessage
                id="websites.website_dashboard.unable_to_delete_detail"
                defaultMessage="''{website}'' failed to delete: {error}"
                values={{ website: website.domain.domain, error: message }}
              />
            );
          } finally {
            loadingStop();
          }
        },
      },
      closeButton: {
        text: formatMessage(messages.cancel),
      },
    });
  };

  const handlePreview = async () => {
    setIsLoading(true);

    try {
      const previewDomain = await createPreviewDomain({ orgId, websiteId: website.id });
      window.open(`https://${previewDomain}`);
    } catch (e) {
      errorToast(
        <FormattedMessage
          id="websites.website_dashboard.hero.create_preview_domain.error"
          defaultMessage="Website preview failed"
        />
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleVisit = async () => {
    window.open(`https://${website.domain.domain}`);
  };

  const handleRefreshScreenshot = async () => {
    let newScreenshotTimestamp;

    try {
      const screenshotTimestamp = await getScreenshotTimestamp({
        orgId,
        websiteId: website.id,
        domainId: website.domain.id,
      });

      await takeScreenshot({ orgId, websiteId: website.id, domainId: website.domain.id });

      do {
        // eslint-disable-next-line no-await-in-loop
        newScreenshotTimestamp = await getScreenshotTimestamp({
          orgId,
          websiteId: website.id,
          domainId: website.domain.id,
        });

        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } while (screenshotTimestamp.seconds === newScreenshotTimestamp.seconds);

      await getWebsite({ orgId, websiteId: website.id });
    } catch (e) {
      const { message } = getErrorInfo(e);

      errorToast(
        <FormattedMessage
          id="website_dashboard.hero.screenshot_refresh_failed_detail"
          defaultMessage="Screenshot failed to refresh: {error}"
          values={{ error: message }}
        />
      );
    }
  };

  const dropdownOptions: DropdownOption[] | undefined = isWebsiteNormalOrStaging(website.kind)
    ? [
        {
          label: formatMessage(messages.delete),
          onClick: handleDeleteClick,
        },
      ]
    : undefined;

  const renderImage = () => (
    <Box w={{ xs: 12, md: '40%' }} my={{ xs: '-lg', md: '-xl' }}>
      <Screenshot
        fixedHeight={270}
        imageUrl={`/screenshots/${website.domain.domain}.png`}
        onRefresh={handleRefreshScreenshot}
      />
    </Box>
  );

  return (
    <Fragment>
      {!isDesktopUp && renderImage()}

      <Container>
        <Box mt={{ xs: '-4xl', md: '0' }} zIndex={zIndex.One} mb="xl">
          <Card
            paddingLeft={{ xs: 'lg', md: '0' }}
            paddingRight={{ xs: 'lg', md: 'xl' }}
            paddingTop={{ xs: 'lg', md: 'xl' }}
            paddingBottom={{ xs: 'lg', md: 'xl' }}
            dropdownId="website-hero-dropdown"
            dropdownOptions={dropdownOptions}
          >
            <Box>
              {isDesktopUp && renderImage()}
              <Box
                w={{ xs: 12, md: '60%' }}
                pl={{ xs: '0', md: '2xl' }}
                alignSelf="stretch"
                d="column"
                j="space-between"
              >
                <Box my="2xl" pr={{ xs: 'xl', sm: '0' }} d="column" gap="md">
                  <Box>
                    <SslStatus name={website.domain.domain} status={sslStatus} isSelfSigned={sslIsSelfSigned} />
                  </Box>

                  <Box a="center">
                    <MiddleTruncate
                      text={website.domain.domain}
                      size="2xl"
                      tag="h2"
                      weight="light"
                      suffix={<Copy noLabel value={website.domain.domain} />}
                    />
                  </Box>

                  <KindPillMap
                    disabled={!isWebsiteActive(website)}
                    domain={website.domain.domain}
                    kind={website.kind}
                  />
                </Box>

                <Box j="flex-end">
                  {stagingDomain && !isFetchingDnsResolves && !isStagingWebsite && !isResolves && (
                    <Button
                      loading={isLoading}
                      data-qa="previewButton"
                      size="small"
                      onClick={handlePreview}
                      variant="secondary"
                      icon="open in new"
                      iconPosition="right"
                    >
                      <FormattedMessage {...messages.preview} />
                    </Button>
                  )}

                  {latestWordpress && (
                    <Box w="auto" ml="lg">
                      <WpAdminButton app={latestWordpress} />
                    </Box>
                  )}

                  {(isStagingWebsite || showClone) && (
                    <DropdownMenu
                      data-qa="pushLiveDropdown"
                      button={
                        <Button marginLeft="lg" size="small" icon="chevron down" iconPosition="right">
                          {isStagingWebsite ? (
                            <FormattedMessage {...messages.push_live} />
                          ) : (
                            <FormattedMessage {...messages.clone} />
                          )}
                        </Button>
                      }
                      options={[
                        {
                          label: formatMessage({
                            id: 'website.hero.push_existing',
                            defaultMessage: 'To an existing website',
                          }),
                          onClick: () =>
                            showPushLiveToExistingModal({
                              orgId: member.orgId,
                              websiteId: website.id,
                              isCloning: !isStagingWebsite,
                            }),
                        },
                        {
                          label: formatMessage({ id: 'website.hero.push_new', defaultMessage: 'To a new website' }),
                          onClick: () =>
                            showPushLiveToNewModal({ member, websiteId: website.id, isCloning: !isStagingWebsite }),
                        },
                      ]}
                    />
                  )}

                  {showVisit && (
                    <Button
                      data-qa="visitButton"
                      size="small"
                      onClick={handleVisit}
                      marginLeft="lg"
                      icon="open in new"
                      iconPosition="right"
                    >
                      <FormattedMessage id="website.hero.visit" defaultMessage="Visit site" />
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Container>
    </Fragment>
  );
};

export const Hero = connect(stateToProps, dispatchToProps)(memo(_Hero));
