import { createSelector } from 'reselect';

import { BrandingConfig, BrandingSettings } from 'src/api_services/branding/types';
import { AppState } from 'src/store';
import { subscriptionsSelectors } from 'src/store/subscriptions/selectors';
import { getBrandingFromSettings, getDefaultBranding } from 'src/utils/branding/branding';

import { impersonationSelectors } from '../impersonation/selectors';

/**
 * selects the loading status for branding (impersonation branding loading status will be returned if impersonation is active)
 */
const selectIsBrandingLoading = (state: AppState): boolean => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return state.theme.impersonationBranding.loading;
  }
  return state.theme.branding.loading;
};

/**
 * selects the current branding settings, taking in to account whether impersonation is active.
 * Does NOT guarantee that a complete branding config is present
 */
const selectBrandingUnsafe = (state: AppState): BrandingSettings | Partial<BrandingConfig> | null | undefined => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return getBrandingFromSettings(state.theme.impersonationBranding.data?.settings);
  }
  return getBrandingFromSettings(state.theme.branding.data?.settings);
};

const selectOrgBrandingUnsafe = (state: AppState): BrandingSettings | Partial<BrandingConfig> | null | undefined => {
  return getBrandingFromSettings(state.theme.branding.data?.settings);
};

/**
 * selects the logoPath from branding (impersonation branding logoPath will be returned if impersonation is active)
 */
const selectBrandingLogoPath = (state: AppState): string | undefined => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return state.theme.impersonationBranding.data?.logoPath;
  }
  return state.theme.branding.data?.logoPath;
};

const selectBrandingInverseLogoPath = (state: AppState): string | undefined => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return state.theme.impersonationBranding.data?.inverseLogoPath;
  }
  return state.theme.branding.data?.inverseLogoPath;
};

const selectBrandingInverseIconPath = (state: AppState): string | undefined => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return state.theme.impersonationBranding.data?.inverseIconPath;
  }
  return state.theme.branding.data?.inverseIconPath;
};

/**
 * selects the faviconPath from branding (impersonation branding faviconPath will be returned if impersonation is active)
 */
const selectBrandingFaviconPath = (state: AppState): string | undefined => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return state.theme.impersonationBranding.data?.faviconPath;
  }
  return state.theme.branding.data?.faviconPath;
};

/**
 * selects the loginImagePath from branding (impersonation branding loginImagePath will be returned if impersonation is active)
 */
const selectBrandingLoginImagePath = (state: AppState): string | undefined => {
  if (impersonationSelectors.getImpersonationMember(state)) {
    return state.theme.impersonationBranding.data?.loginImagePath;
  }
  return state.theme.branding.data?.loginImagePath;
};

const selectBrandingSgeFromEmail = (state: AppState): string | undefined => {
  const settings = impersonationSelectors.getImpersonationMember(state)
    ? state.theme.impersonationBranding.data?.settings
    : state.theme.branding.data?.settings;

  const email = settings?.find((setting) => setting.name === 'sge_from_email');

  return email ? String(email.value) : undefined;
};

const selectBrandingEcommerceUrl = (state: AppState): string | undefined => {
  const settings = impersonationSelectors.getImpersonationMember(state)
    ? state.theme.impersonationBranding.data?.settings
    : state.theme.branding.data?.settings;

  const url = settings?.find((setting) => setting.name === 'ecommerceUrl');

  return url ? String(url.value) : undefined;
};

export const backwardsCompatibleBrandingHelper = (
  branding: BrandingSettings | Partial<BrandingConfig> | null | undefined
): BrandingConfig => {
  let usedBranding: Partial<BrandingConfig>;
  if (!branding) {
    usedBranding = {};
  } else if ('locales' in branding) {
    usedBranding = branding.locales?.en ?? {};
  } else {
    usedBranding = branding;
  }

  const defaultBranding = getDefaultBranding();
  return { ...defaultBranding, ...usedBranding };
};

const selectBranding = createSelector([selectBrandingUnsafe], backwardsCompatibleBrandingHelper);

const selectOrgBranding = createSelector([selectOrgBrandingUnsafe], backwardsCompatibleBrandingHelper);

/**
 * selects the current branding marketingUrl (user or impersonation)
 */
const selectMarketingUrl = (state: AppState): string | undefined => {
  const branding = selectBranding(state);
  return branding.websiteUrl;
};

/**
 * selects the current branding supportUrl (user or impersonation)
 */
const selectSupportUrl = (state: AppState): string | undefined => {
  const branding = selectBranding(state);
  return branding.supportUrl;
};

/**
 * selects the current branding billingUrl (user or impersonation)
 */
const selectBillingUrl = (state: AppState): string | undefined => {
  const branding = selectBranding(state);
  return branding.billingUrl;
};

const selectCreateAccountUrl = (state: AppState): string | undefined => {
  const branding = selectBranding(state);
  return branding.createAccountUrl;
};

/**
 * selects the branded org name
 */
const selectParentOrgName = (state: AppState): string | undefined => {
  return state.theme.impersonationBranding.data?.parent || state.theme.branding.data?.parent;
};

const selectStagingDomain = createSelector(
  impersonationSelectors.getImpersonationMember,
  (state: AppState) => state.theme.impersonationBranding.data,
  (state: AppState) => state.theme.branding.data,
  (impersonationMember, impersonationBranding, branding) => {
    if (impersonationMember) {
      return impersonationBranding?.stagingDomain || '';
    }

    return branding?.stagingDomain || '';
  }
);

const selectImpersonationBrandingData = (state: AppState) => state.theme.impersonationBranding.data;

const selectBrandingData = (state: AppState) => state.theme.branding.data;

const selectControlPanelDomain = createSelector(
  selectBrandingData,
  selectImpersonationBrandingData,
  impersonationSelectors.getImpersonationMember,
  (brandingData, impersonationMember, isImpersonating) => {
    const controlPanelDomain =
      isImpersonating && impersonationMember?.controlPanelDomain
        ? impersonationMember?.controlPanelDomain
        : brandingData?.controlPanelDomain;

    return controlPanelDomain;
  }
);

const selectLogoutUrl = createSelector(selectBranding, (localeBranding) => {
  return localeBranding.logoutUrl;
});

const selectOrgLogoutUrl = createSelector(selectOrgBranding, (localeBranding) => {
  return localeBranding.logoutUrl;
});

const selectDefaultDarkMode = createSelector(selectBranding, (localeBranding) => {
  return localeBranding.defaultDarkMode;
});

const selectSinglePageDashboard = createSelector(selectBranding, (localeBranding) => {
  return localeBranding.singlePageDashboard;
});

const selectNameServers = createSelector(
  selectBrandingData,
  selectImpersonationBrandingData,
  impersonationSelectors.getImpersonationMember,
  (branding, impersonationBranding, isImpersonating) => {
    return isImpersonating && impersonationBranding?.nameServers
      ? impersonationBranding?.nameServers
      : branding?.nameServers;
  }
);

const selectRoundcubeDomain = createSelector(
  selectBrandingData,
  selectImpersonationBrandingData,
  impersonationSelectors.getImpersonationMember,
  subscriptionsSelectors.selectIsReseller,
  (branding, impersonationBranding, isImpersonating, isReseller) => {
    return isImpersonating && isReseller ? impersonationBranding?.roundcubeDomain : branding?.roundcubeDomain;
  }
);

const selectIsNameServersLoading = (state: AppState) =>
  state.theme.branding.loading || state.theme.impersonationBranding.loading;

const selectBrandingOrgName = (state: AppState) => state.theme.branding.data?.orgName;

export const brandingSelectors = {
  selectBrandingOrgName,
  selectBranding,
  selectBrandingData,
  selectBrandingFaviconPath,
  selectBrandingLoginImagePath,
  selectBrandingLogoPath,
  selectBrandingInverseLogoPath,
  selectBrandingInverseIconPath,
  selectControlPanelDomain,
  selectIsBrandingLoading,
  selectIsNameServersLoading,
  selectMarketingUrl,
  selectNameServers,
  selectRoundcubeDomain,
  selectParentOrgName,
  selectStagingDomain,
  selectSupportUrl,
  selectBillingUrl,
  selectCreateAccountUrl,
  selectLogoutUrl,
  selectDefaultDarkMode,
  selectSinglePageDashboard,
  selectOrgBranding,
  selectOrgLogoutUrl,
  selectBrandingSgeFromEmail,
  selectBrandingEcommerceUrl,
};
