import { useSelector } from 'react-redux';

import { BrandingConfig } from 'src/api_services/branding/types';
import { useGetBrandingQuery } from 'src/store/branding/query';
import { sessionSelectors } from 'src/store/session/selectors';

import { useReseller } from '../useSubscriptions/useSubscriptions';

export const useLayoutSettings = () => {
  const member = useSelector(sessionSelectors.getMemberOrUndefined);

  const { isReseller, isLoading: resellerLoading } = useReseller({ orgId: member?.orgId });

  const { data: branding, isLoading: brandingLoading } = useGetBrandingQuery();
  const brandingSettings = branding?.settings.find((setting) => setting.name === 'branding')?.value as
    | BrandingConfig
    | undefined;

  if (!member || !brandingSettings) {
    return { isSinglePageDashboard: false, hideLeftHandMenu: false, loading: resellerLoading || brandingLoading };
  }

  const isEndUser = !member?.isMasterOrg && !isReseller;

  return {
    isSinglePageDashboard: brandingSettings.singlePageDashboard,
    hideLeftHandMenu: brandingSettings.singlePageDashboard && isEndUser,
    isLoading: resellerLoading || brandingLoading,
  };
};
