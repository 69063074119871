import { defineMessages } from 'react-intl';

export type MessagesKeys = keyof typeof messages;

export const messages = defineMessages({
  add_directive: {
    defaultMessage: 'Add directive',
    id: 'add_directive',
  },
  add_server: {
    defaultMessage: 'Add server',
    id: 'add_server',
  },
  access: {
    defaultMessage: 'Access',
    id: 'access',
  },
  access_token: {
    defaultMessage: 'Access token',
    id: 'access_token',
  },
  access_tokens: {
    defaultMessage: 'Access tokens',
    id: 'access_tokens',
  },
  accept_invite: {
    defaultMessage: 'Accept invite',
    id: 'accept_invite',
  },
  activate: {
    defaultMessage: 'Activate',
    id: 'activate',
  },
  account: {
    id: 'account',
    defaultMessage: 'Account',
  },
  add: {
    defaultMessage: 'Add',
    id: 'add',
  },
  add_customer: {
    defaultMessage: 'Add customer',
    id: 'add_customer',
  },
  admin: {
    defaultMessage: 'Admin',
    id: 'admin',
  },
  admin_lowecase: {
    defaultMessage: 'dmin',
    id: 'admin_lowecase',
  },
  add_nameservers_for_customers: {
    defaultMessage: 'Add nameservers for your customers to use',
    id: 'add_nameservers_for_customers',
  },
  add_account: {
    defaultMessage: 'Add account',
    id: 'add_account',
  },
  add_database: {
    defaultMessage: 'Add database',
    id: 'add_database',
  },
  add_database_user: {
    defaultMessage: 'Add database user',
    id: 'add_database_user',
  },
  add_domain: {
    defaultMessage: 'Add domain',
    id: 'add_domain',
  },
  add_out_of_office: {
    defaultMessage: 'Add out of office',
    id: 'add_out_of_office',
  },
  add_package: {
    defaultMessage: 'Add package',
    id: 'add_package',
  },
  add_record: {
    defaultMessage: 'Add record',
    id: 'add_record',
  },
  add_redirect: {
    defaultMessage: 'Add redirect',
    id: 'add_redirect',
  },
  add_rewrite: {
    defaultMessage: 'Add rewrite',
    id: 'add_rewrite',
  },
  add_servers_heading: {
    defaultMessage: 'Add and configure servers',
    id: 'add_servers_heading',
  },
  add_server_group: {
    defaultMessage: 'Add server group',
    id: 'add_server_group',
  },
  add_setting: {
    defaultMessage: 'Add setting',
    id: 'add_setting',
  },
  add_user: {
    defaultMessage: 'Add user',
    id: 'add_user',
  },
  add_website: {
    defaultMessage: 'Add website',
    id: 'add_website',
  },
  add_zone: {
    defaultMessage: 'Add zone',
    id: 'add_zone',
  },
  addon: {
    defaultMessage: 'Addon',
    id: 'addon',
  },
  advanced: {
    defaultMessage: 'Advanced',
    id: 'advanced',
  },
  alias: {
    defaultMessage: 'Alias',
    id: 'alias',
  },
  all: {
    defaultMessage: 'All',
    id: 'all',
  },
  allowed: {
    defaultMessage: 'allowed',
    id: 'allowed',
  },
  all_operations: {
    defaultMessage: 'All operations',
    id: 'all_operations',
  },
  all_packages: {
    defaultMessage: 'All packages',
    id: 'all_packages',
  },
  all_roles: {
    defaultMessage: 'All roles',
    id: 'all_roles',
  },
  all_servers: {
    defaultMessage: 'All servers',
    id: 'all_servers',
  },
  all_statuses: {
    defaultMessage: 'All statuses',
    id: 'all_statuses',
  },
  all_types: {
    defaultMessage: 'All types',
    id: 'all_types',
  },
  all_websites: {
    defaultMessage: 'All websites',
    id: 'all_websites',
  },
  allow: {
    defaultMessage: 'Allow',
    id: 'allow',
  },
  allow_lowercase: {
    defaultMessage: 'allow',
    id: 'allow_lowercase',
  },
  allow_feature: {
    defaultMessage: 'Allow {feature}',
    id: 'allow_feature',
  },
  allowed_backup_hours: {
    defaultMessage: 'Allowed backup hours',
    id: 'allowed_backup_hours',
  },
  analytics: {
    defaultMessage: 'Analytics',
    id: 'analytics',
  },
  apache: {
    defaultMessage: 'Apache web server',
    id: 'apache',
  },
  api_token: {
    defaultMessage: 'API token',
    id: 'api_token',
  },
  api_token_required: {
    defaultMessage: 'Please enter an API token',
    id: 'api_token_required',
  },
  application: {
    defaultMessage: 'Application',
    id: 'application',
  },
  applications: {
    defaultMessage: 'Applications',
    id: 'applications',
  },
  apps: {
    defaultMessage: 'Apps',
    id: 'apps',
  },
  authentication: {
    defaultMessage: 'Authentication',
    id: 'authentication',
  },
  authentication_plugin: {
    defaultMessage: 'Authentication plugin',
    id: 'authentication_plugin',
  },
  automatic: {
    defaultMessage: 'Automatic',
    id: 'automatic',
  },
  back: {
    defaultMessage: 'Back',
    id: 'back',
  },
  backup: {
    defaultMessage: 'Backup',
    id: 'backup',
  },
  backups: {
    defaultMessage: 'Backups',
    id: 'backups',
  },
  backup_count: {
    defaultMessage: '{count, plural, one {<b>#</b> backup} other {<b>#</b> backups}}',
    id: 'backup_count',
  },
  backup_emails: {
    defaultMessage: 'Backup emails',
    id: 'backup_emails',
  },
  bandwidth: {
    defaultMessage: 'Bandwidth',
    id: 'bandwidth',
  },
  beta: {
    defaultMessage: 'Beta',
    id: 'beta',
  },
  billing: {
    defaultMessage: 'Billing',
    id: 'billing',
  },
  blacklist: {
    defaultMessage: 'Blacklist',
    id: 'blacklist',
  },
  block: {
    defaultMessage: 'Block',
    id: 'block',
  },
  block_lowercase: {
    defaultMessage: 'block',
    id: 'block_lowercase',
  },
  boolean: {
    defaultMessage: 'Boolean',
    id: 'boolean',
  },
  branding_settings: {
    defaultMessage: 'Branding settings',
    id: 'branding_settings',
  },
  'breadcrumb.loading': {
    defaultMessage: 'Loading...',
    id: 'breadcrumb.loading',
  },
  brute_force_protection: {
    defaultMessage: 'Brute force protection',
    id: 'brute_force_protection',
  },
  Business: {
    defaultMessage: 'Business',
    id: 'Business',
  },
  buy_package: {
    defaultMessage: 'Buy package',
    id: 'buy_package',
  },
  ca_bundle: {
    defaultMessage: 'CA bundle',
    id: 'ca_bundle',
  },
  caching: {
    defaultMessage: 'Caching',
    id: 'caching',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'cancel',
  },
  certificate: {
    defaultMessage: 'Certificate',
    id: 'certificate',
  },
  certificate_details: {
    defaultMessage: 'Certificate details',
    id: 'certificate_details',
  },
  change: {
    defaultMessage: 'Change',
    id: 'change',
  },
  change_package: {
    defaultMessage: 'Change package',
    id: 'change_package',
  },
  change_now: {
    defaultMessage: 'Change now',
    id: 'change_now',
  },
  check_status: {
    defaultMessage: 'Check status',
    id: 'check_status',
  },
  check_progress: {
    defaultMessage: 'Check progress',
    id: 'check_progress',
  },
  clear: {
    defaultMessage: 'Clear',
    id: 'clear',
  },
  clone: {
    defaultMessage: 'Clone',
    id: 'clone',
  },
  cloning: {
    defaultMessage: 'Cloning',
    id: 'cloning',
  },
  close: {
    defaultMessage: 'Close',
    id: 'close',
  },
  cloudflare: {
    defaultMessage: 'Cloudflare',
    id: 'cloudflare',
  },
  company_name: {
    defaultMessage: 'Company name',
    id: 'company_name',
  },
  company_name_optional: {
    defaultMessage: 'Company name (Optional)',
    id: 'company_name_optional',
  },
  'company.required': {
    defaultMessage: 'Please enter a company name',
    id: 'company.required',
  },
  configuration: {
    defaultMessage: 'Configuration',
    id: 'configuration',
  },
  configure: {
    defaultMessage: 'Configure',
    id: 'configure',
  },
  configured: {
    defaultMessage: 'Configured',
    id: 'configured',
  },
  configure_now: {
    defaultMessage: 'Configure now',
    id: 'configure_now',
  },
  confirm_password: {
    defaultMessage: 'Confirm password',
    id: 'confirm_password',
  },
  connect: {
    defaultMessage: 'Connect',
    id: 'connect',
  },
  connection_speed: {
    defaultMessage: 'Connection Speed',
    id: 'connection_speed',
  },
  contact_us: {
    defaultMessage: 'contact us',
    id: 'contact_us',
  },
  continue: {
    defaultMessage: 'Continue',
    id: 'continue',
  },
  control: {
    defaultMessage: 'Control',
    id: 'control',
  },
  control_panel: {
    defaultMessage: 'Control panel',
    id: 'control_panel',
  },
  control_panel_domain: {
    defaultMessage: 'Control panel domain',
    id: 'control_panel_domain',
  },
  copy: {
    defaultMessage: 'Copy',
    id: 'copy',
  },
  copied: {
    defaultMessage: 'Copied',
    id: 'copied',
  },
  cpanel_importer: {
    defaultMessage: 'cPanel importer',
    id: 'cpanel_importer',
  },
  cpanel_imports: {
    defaultMessage: 'cPanel imports',
    id: 'cpanel_imports',
  },
  create: {
    defaultMessage: 'Create',
    id: 'create',
  },
  created_at: {
    defaultMessage: 'Created at',
    id: 'created_at',
  },
  cron_jobs: {
    defaultMessage: 'Cron jobs',
    id: 'cron_jobs,',
  },
  cron_invalid: {
    defaultMessage: 'Invalid cron expression',
    id: 'cron_invalid',
  },
  custom: {
    defaultMessage: 'Custom',
    id: 'custom',
  },
  customers: {
    defaultMessage: 'Customers',
    id: 'customers',
  },
  customers_details: {
    defaultMessage: "Customer's details",
    id: 'customers_details',
  },
  customer_stickiness: {
    defaultMessage: 'Customer stickiness',
    id: 'customer_stickiness',
  },
  customer_ssh_server: {
    defaultMessage: 'Customer SSH server',
    id: 'customer_ssh_server',
  },
  cut: {
    defaultMessage: 'Cut',
    id: 'cut',
  },
  daily: {
    defaultMessage: 'Daily',
    id: 'daily',
  },
  data_transfer: {
    defaultMessage: 'Data transfer',
    id: 'data_transfer',
  },
  database: {
    defaultMessage: 'Database',
    id: 'database',
  },
  databases: {
    defaultMessage: 'Databases',
    id: 'databases',
  },
  database_user: {
    defaultMessage: 'Database user',
    id: 'database_user',
  },
  database_users: {
    defaultMessage: 'Database users',
    id: 'database_users',
  },
  database_access: {
    defaultMessage: 'Database access',
    id: 'database_access',
  },

  date: {
    defaultMessage: 'Date',
    id: 'date',
  },
  date_at_time: {
    defaultMessage: '{date} at {time}',
    id: 'date_at_time',
  },
  'date.invalid': {
    defaultMessage: 'Invalid date',
    id: 'date.invalid',
  },
  day: {
    defaultMessage: 'Day',
    id: 'day',
  },
  day_count: {
    defaultMessage: '{count, plural, one {1 day} other {# days}}',
    id: 'day_count',
  },

  days: {
    defaultMessage: 'Days',
    id: 'days',
  },
  'DD/MM/YYYY': {
    defaultMessage: 'dd/mm/yyyy',
    id: 'DD/MM/YYYY',
  },
  'MM/DD/YYYY': {
    defaultMessage: 'mm/dd/yyyy',
    id: 'MM/DD/YYYY',
  },
  'YYYY/MM/DD': {
    defaultMessage: 'yyyy/mm/dd',
    id: 'YYYY/MM/DD',
  },
  debug: {
    defaultMessage: 'Debug',
    id: 'debug',
  },
  deactivate: {
    defaultMessage: 'Deactivate',
    id: 'deactivate',
  },
  decommission: {
    defaultMessage: 'Decommission',
    id: 'decommission',
  },
  default: {
    defaultMessage: 'Default',
    id: 'default',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'delete',
  },
  delete_user: {
    defaultMessage: 'Delete user',
    id: 'delete_user',
  },
  delete_website: {
    defaultMessage: 'Delete website',
    id: 'delete_website',
  },
  details: {
    defaultMessage: 'Details',
    id: 'details',
  },
  destination_url: {
    defaultMessage: 'Destination URL',
    id: 'destination_url',
  },
  deselect_all_count: {
    defaultMessage: 'Deselect all ({total})',
    id: 'deselect_all_count',
  },
  deselect_all: {
    defaultMessage: 'Deselect all',
    id: 'deselect_all',
  },
  destination_path: {
    defaultMessage: 'Destination path',
    id: 'destination_path',
  },
  developer_tools: {
    defaultMessage: 'Developer tools',
    id: 'developer_tools',
  },
  devices: {
    defaultMessage: 'Devices',
    id: 'devices',
  },
  device_path_required: {
    defaultMessage: 'Please enter a device path',
    id: 'device_path_required',
  },
  'dir.invalid': {
    defaultMessage: 'Directory must contain only letters, numbers, hyphens, fullstops or the underscore character',
    id: 'dir.invalid',
  },
  directive: {
    defaultMessage: 'Directive',
    id: 'directive',
  },
  directive_deleted: {
    defaultMessage: 'Directive deleted',
    id: 'directive_deleted',
  },
  directive_deleted_error: {
    defaultMessage: 'Failed to delete directive',
    id: 'directive_deleted_error',
  },
  directive_deleted_error_detail: {
    defaultMessage: 'Failed to delete directive: {error}',
    id: 'directive_deleted_error_detail',
  },
  directive_added: {
    defaultMessage: 'Directive added',
    id: 'directive_added',
  },
  directive_added_error: {
    defaultMessage: 'Failed to add directive',
    id: 'directive_added_error',
  },
  directive_added_error_detail: {
    defaultMessage: 'Failed to add directive: {error}',
    id: 'directive_added_error_detail',
  },
  directory: {
    defaultMessage: 'Directory',
    id: 'directory',
  },
  directory_access: {
    defaultMessage: 'Directory access',
    id: 'directory_access',
  },
  disable: {
    defaultMessage: 'Disable',
    id: 'disable',
  },
  disabled: {
    defaultMessage: 'Disabled',
    id: 'disabled',
  },
  disable_placement: {
    defaultMessage: 'Disable placement',
    id: 'disable_placement',
  },
  enable_placement: {
    defaultMessage: 'Enable placement',
    id: 'enable_placement',
  },
  diskspace: {
    defaultMessage: 'Diskspace',
    id: 'diskspace',
  },
  dns: {
    defaultMessage: 'DNS',
    id: 'dns',
  },
  dnssec: {
    defaultMessage: 'DNSSEC',
    id: 'dnssec',
  },
  dns_provider: {
    defaultMessage: 'DNS provider',
    id: 'dns_provider',
  },
  dns_records: {
    defaultMessage: 'DNS records',
    id: 'dns_records',
  },
  dns_status: {
    defaultMessage: 'DNS status',
    id: 'dns_status',
  },
  dns_templating: {
    defaultMessage: 'DNS zone templating',
    id: 'dns_templating',
  },
  domain: {
    defaultMessage: 'Domain',
    id: 'domain',
  },
  domain_name: {
    defaultMessage: 'Domain name',
    id: 'domain_name',
  },
  'domain_name.invalid': {
    defaultMessage: 'Please enter a valid domain name',
    id: 'domain_name.invalid',
  },
  'domain_name.required': {
    defaultMessage: 'Please enter a domain name',
    id: 'domain_name.required',
  },
  document_root: {
    defaultMessage: 'Document root',
    id: 'document_root',
  },
  docker_credentials: {
    defaultMessage: 'Docker credentials',
    id: 'docker_credentials',
  },
  domains: {
    defaultMessage: 'Domains',
    id: 'domains',
  },
  domain_already_in_use_try_another: {
    defaultMessage: 'Domain already in use. Please try a different domain',
    id: 'domain_already_in_use_try_another',
  },
  domain_prohibited_by_platform: {
    defaultMessage:
      'This domain is prohibited and can not be added to the platform. If you own this domain please contact support',
    id: 'domain_prohibited_by_platform',
  },
  download: {
    defaultMessage: 'Download',
    id: 'download',
  },
  duplicate: {
    defaultMessage: 'Duplicate',
    id: 'duplicate',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'edit',
  },
  edit_configuration: {
    defaultMessage: 'Edit configuration',
    id: 'edit_configuration',
  },
  edit_privileges: {
    defaultMessage: 'Edit privileges',
    id: 'edit_privileges',
  },
  force_https: {
    id: 'force_https',
    defaultMessage: 'Force https',
  },
  editing: {
    defaultMessage: 'Editing',
    id: 'editing',
  },
  email: {
    defaultMessage: 'Email',
    id: 'email',
  },
  email_address: {
    defaultMessage: 'Email address',
    id: 'email_address',
  },
  email_authentication: {
    defaultMessage: 'Email authentication',
    id: 'email_authentication',
  },
  email_and_password: {
    defaultMessage: 'Email and password',
    id: 'email_and_password',
  },
  'email.in_use': {
    defaultMessage: 'Email address already in use',
    id: 'email.in_use',
  },
  'email.invalid': {
    defaultMessage: 'Please enter a valid email address',
    id: 'email.invalid',
  },
  'email.required': {
    defaultMessage: 'Please enter an email address',
    id: 'email.required',
  },
  emails: {
    defaultMessage: 'Emails',
    id: 'emails',
  },
  email_settings: {
    defaultMessage: 'Email settings',
    id: 'email_settings',
  },
  enable: {
    defaultMessage: 'Enable',
    id: 'enable',
  },
  enabled: {
    defaultMessage: 'Enabled',
    id: 'enabled',
  },
  enhance: {
    defaultMessage: 'Enhance',
    id: 'enhance',
  },
  enhance_version_is_available: {
    defaultMessage: 'Enhance {version} is available',
    id: 'enhance_version_is_available',
  },
  'enhance_update_notification.blurb': {
    defaultMessage: 'This update improves the security and functionality of your {orgName} estate.',
    id: 'enhance_update_notification.blurb',
  },
  'enhance_update_notification.metadata': {
    defaultMessage: 'from {author}',
    id: 'enhance_update_notification.metadata',
  },
  error: {
    defaultMessage: 'Error',
    id: 'error',
  },
  'error.general': {
    defaultMessage: 'An error has occurred. Please try again.',
    id: 'error.general',
  },
  'error.general_heading': {
    defaultMessage: 'There has been an error',
    id: 'error.general_heading',
  },
  expiry_date: {
    defaultMessage: 'Expiry date',
    id: 'expiry_date',
  },
  event: {
    defaultMessage: 'Event',
    id: 'event',
  },
  every_15_mins: {
    defaultMessage: 'Every 15 minutes',
    id: 'every_15_mins',
  },
  every_minute: {
    defaultMessage: 'Every minute',
    id: 'every_minute',
  },
  every_day: {
    defaultMessage: 'Every day',
    id: 'every_day',
  },
  every_hour: {
    defaultMessage: 'Every hour',
    id: 'every_hour',
  },
  every_month: {
    defaultMessage: 'Every month',
    id: 'every_month',
  },
  every_week: {
    defaultMessage: 'Every week',
    id: 'every_week',
  },
  features: {
    defaultMessage: 'Features',
    id: 'features',
  },
  feature_limit_exceeded: {
    defaultMessage: '{feature} ({newLimit} available {usage} used)',
    id: 'feature_limit_exceeded',
  },
  grid: {
    defaultMessage: 'Grid',
    id: 'grid',
  },
  redis: {
    defaultMessage: 'Redis',
    id: 'redis',
  },
  reselling: {
    defaultMessage: 'Reselling',
    id: 'reselling',
  },
  resources: {
    defaultMessage: 'Resources',
    id: 'resources',
  },
  dedicated: {
    defaultMessage: 'Dedicated',
    id: 'dedicated',
  },
  shared: {
    defaultMessage: 'Shared',
    id: 'shared',
  },
  skip: {
    defaultMessage: 'Skip',
    id: 'skip',
  },
  spam_settings: {
    defaultMessage: 'Spam settings',
    id: 'spam_settings',
  },
  subdomain: {
    defaultMessage: 'Subdomain',
    id: 'subdomain',
  },
  subdomains: {
    defaultMessage: 'Subdomains',
    id: 'subdomains',
  },
  file: {
    defaultMessage: 'File',
    id: 'file',
  },
  file_manager: {
    defaultMessage: 'File manager',
    id: 'file_manager',
  },
  filerd: {
    defaultMessage: 'Filerd',
    id: 'filerd',
  },
  files: {
    defaultMessage: 'Files',
    id: 'files',
  },
  filter: {
    defaultMessage: 'Filter',
    id: 'filter',
  },
  finish: {
    defaultMessage: 'Finish',
    id: 'finish',
  },
  folder: {
    defaultMessage: 'Folder',
    id: 'folder',
  },
  forceful_reboot: {
    defaultMessage: 'Forceful reboot',
    id: 'forceful_reboot',
  },
  forceful_restart: {
    defaultMessage: 'Forceful restart',
    id: 'forceful_restart',
  },
  forwarder: {
    defaultMessage: 'Forwarder',
    id: 'forwarder',
  },
  forwarders: {
    defaultMessage: 'Forwarders',
    id: 'forwarders',
  },
  friendly_name: {
    defaultMessage: 'Friendly name',
    id: 'friendly_name',
  },
  friendly_name_required: {
    defaultMessage: 'Please enter a friendly name',
    id: 'friendly_name_required',
  },
  from: {
    defaultMessage: 'From',
    id: 'from',
  },
  ftp: {
    defaultMessage: 'FTP',
    id: 'ftp',
  },
  ftp_users: {
    defaultMessage: 'FTP users',
    id: 'ftp_users',
  },
  ftp_user: {
    defaultMessage: 'FTP user',
    id: 'ftp_user',
  },
  full_name: {
    defaultMessage: 'Full name',
    id: 'full_name',
  },
  GB: {
    defaultMessage: 'GB',
    id: 'GB',
  },
  general: {
    defaultMessage: 'General',
    id: 'general',
  },
  general_details: {
    defaultMessage: 'General details',
    id: 'general_details',
  },
  global: {
    defaultMessage: 'Global',
    id: 'global',
  },
  graceful_reboot: {
    defaultMessage: 'Graceful reboot',
    id: 'graceful_reboot',
  },
  graceful_restart: {
    defaultMessage: 'Graceful restart',
    id: 'graceful_restart',
  },
  group: {
    defaultMessage: 'Group',
    id: 'group',
  },
  group_name: {
    defaultMessage: 'Group name',
    id: 'group_name',
  },
  group_name_required: {
    defaultMessage: 'Please enter a group name',
    id: 'group_name_required',
  },
  help: {
    defaultMessage: 'Help',
    id: 'help',
  },
  here: {
    defaultMessage: 'here',
    id: 'here',
  },
  hide: {
    defaultMessage: 'Hide',
    id: 'hide',
  },
  home: {
    defaultMessage: 'Home',
    id: 'home',
  },
  hostname: {
    defaultMessage: 'Hostname',
    id: 'hostname',
  },
  hostnames: {
    defaultMessage: 'Hostnames',
    id: 'hostnames',
  },
  'hostname.invalid': {
    defaultMessage: 'Please enter a valid hostname',
    id: 'hostname.invalid',
  },
  'hostname.required': {
    defaultMessage: 'Please enter a hostname',
    id: 'hostname.required',
  },
  hosts: {
    defaultMessage: 'hosts',
    id: 'hosts',
  },
  hour: {
    defaultMessage: 'Hour',
    id: 'hour',
  },
  hour_count: {
    defaultMessage: '{count, plural, one {1 hour} other {# hours}}',
    id: 'hour_count',
  },
  hours: {
    defaultMessage: 'Hours',
    id: 'hours',
  },
  httpd_status: {
    defaultMessage: 'httpd status',
    id: 'httpd_status',
  },
  id: {
    defaultMessage: 'ID',
    id: 'id',
  },
  include_customers_of_resellers: {
    id: 'include_customers_of_resellers',
    defaultMessage: 'Include customers of resellers',
  },
  inherited: {
    defaultMessage: 'Inherited',
    id: 'inherited',
  },
  inactive: {
    defaultMessage: 'Inactive',
    id: 'inactive',
  },
  invalid: {
    defaultMessage: 'Invalid',
    id: 'invalid',
  },
  invite: {
    defaultMessage: 'Invite',
    id: 'invite',
  },
  invite_pending: {
    defaultMessage: 'Invite pending',
    id: 'invite_pending',
  },
  invite_user: {
    defaultMessage: 'Invite user',
    id: 'invite_user',
  },
  invite_your_team: {
    defaultMessage: 'Invite your team',
    id: 'invite_your_team',
  },
  impersonate: {
    defaultMessage: 'Impersonate',
    id: 'impersonate',
  },
  import: {
    defaultMessage: 'Import',
    id: 'import',
  },
  important_information: {
    defaultMessage: 'Important information',
    id: 'important_information',
  },
  interval: {
    defaultMessage: 'Interval',
    id: 'interval',
  },
  integrations: {
    defaultMessage: 'Integrations',
    id: 'integrations',
  },
  install: {
    defaultMessage: 'Install',
    id: 'install',
  },
  install_custom_ssl: {
    defaultMessage: 'Install custom SSL',
    id: 'install_custom_ssl',
  },
  install_custom_ssl_cert: {
    defaultMessage: 'Install custom SSL certificate',
    id: 'install_custom_ssl_cert',
  },
  ion_cube_loader: {
    id: 'ion_cube_loader',
    defaultMessage: 'IonCube loader',
  },
  ip: {
    defaultMessage: 'IP',
    id: 'ip',
  },
  ipv4: {
    defaultMessage: 'IPv4',
    id: 'ipv4',
  },
  ipv6: {
    defaultMessage: 'IPv6',
    id: 'ipv6',
  },
  ip_address: {
    defaultMessage: 'IP address',
    id: 'ip_address',
  },
  ip_address_invalid: {
    defaultMessage: 'Please enter a valid IP address',
    id: 'ip_address_invalid',
  },
  ip_addresses: {
    defaultMessage: 'IP addresses',
    id: 'ip_addresses',
  },
  ip_manager: {
    defaultMessage: 'IP manager',
    id: 'ip_manager',
  },
  is_required: {
    defaultMessage: '{field} is required',
    id: 'is_required',
  },
  item_activated: {
    defaultMessage: "''{item}'' activated",
    id: 'item_activated',
  },
  item_activated_error: {
    defaultMessage: "Failed to activate ''{item}''",
    id: 'item_activated_error',
  },
  item_activated_error_detail: {
    defaultMessage: "Failed to activate ''{item}'': {error}",
    id: 'item_activated_error_detail',
  },
  item_added: {
    defaultMessage: "''{item}'' added",
    id: 'item_added',
  },
  item_added_error: {
    defaultMessage: "Failed to add ''{item}''",
    id: 'item_added_error',
  },
  item_added_error_detail: {
    defaultMessage: "Failed to add ''{item}'': {error}",
    id: 'item_added_error_detail',
  },
  item_updating: {
    defaultMessage: "Updating ''{item}''",
    id: 'item_updating',
  },
  item_updated: {
    defaultMessage: "''{item}'' updated",
    id: 'item_updated',
  },
  item_updated_error: {
    defaultMessage: "Failed to update ''{item}''",
    id: 'item_updated_error',
  },
  item_updated_error_detail: {
    defaultMessage: "Failed to update ''{item}'': {error}",
    id: 'item_updated_error_detail',
  },
  item_deleted: {
    defaultMessage: "''{item}'' deleted",
    id: 'item_deleted',
  },
  item_deleted_error: {
    defaultMessage: "Failed to delete ''{item}''",
    id: 'item_deleted_error',
  },
  item_deleted_error_detail: {
    defaultMessage: "Failed to delete ''{item}'': {error}",
    id: 'item_deleted_error_detail',
  },
  item_restored: {
    defaultMessage: "''{item}'' restored",
    id: 'item_restored',
  },
  item_restored_error: {
    defaultMessage: "Failed to restore ''{item}''",
    id: 'item_restored_error',
  },
  item_restored_error_detail: {
    defaultMessage: "Failed to restore ''{item}'': {error}",
    id: 'item_restored_error_detail',
  },
  item_revoked: {
    defaultMessage: "''{item}'' revoked",
    id: 'item_revoked',
  },
  item_revoked_error: {
    defaultMessage: "Failed to revoke ''{item}''",
    id: 'item_revoked_error',
  },
  item_revoked_error_detail: {
    defaultMessage: "Failed to revoke ''{item}'': {error}",
    id: 'item_revoked_error_detail',
  },
  item_saved: {
    defaultMessage: "''{item}'' saved",
    id: 'item_saved',
  },
  item_saved_error: {
    defaultMessage: "Failed to save ''{item}''",
    id: 'item_saved_error',
  },
  item_saved_error_detail: {
    defaultMessage: "Failed to save ''{item}'': {error}",
    id: 'item_saved_error_detail',
  },
  item_suspended: {
    defaultMessage: "''{item}'' suspended",
    id: 'item_suspended',
  },
  item_suspended_error: {
    defaultMessage: "Failed to suspend ''{item}''",
    id: 'item_suspended_error',
  },
  item_suspended_error_detail: {
    defaultMessage: "Failed to suspend ''{item}'': {error}",
    id: 'item_suspended_error_detail',
  },
  item_unsuspended: {
    defaultMessage: "''{item}'' unsuspended",
    id: 'item_unsuspended',
  },
  item_unsuspended_error: {
    defaultMessage: "Failed to unsuspend ''{item}''",
    id: 'item_unsuspended_error',
  },
  item_unsuspended_error_detail: {
    defaultMessage: "Failed to unsuspend ''{item}'': {error}",
    id: 'item_unsuspended_error_detail',
  },
  item_edited: {
    defaultMessage: "''{item}'' edited",
    id: 'item_edited',
  },
  item_edited_error: {
    defaultMessage: "Failed to edit ''{item}''",
    id: 'item_edited_error',
  },
  item_edited_error_detail: {
    defaultMessage: "Failed to edit ''{item}'': {error}",
    id: 'item_edited_error_detail',
  },
  item_enabled: {
    defaultMessage: "''{item}'' enabled",
    id: 'item_enabled',
  },
  item_enabled_error: {
    defaultMessage: "Failed to enable ''{item}''",
    id: 'item_enabled_error',
  },
  item_enabled_error_detail: {
    defaultMessage: "Failed to enable ''{item}'': {error}",
    id: 'item_enabled_error_detail',
  },
  item_disabled: {
    defaultMessage: "''{item}'' disabled",
    id: 'item_disabled',
  },
  item_disabled_error: {
    defaultMessage: "Failed to disable ''{item}''",
    id: 'item_disabled_error',
  },
  item_disabled_error_detail: {
    defaultMessage: "Failed to disable ''{item}'': {error}",
    id: 'item_disabled_error_detail',
  },
  item_permanently_deleted: {
    defaultMessage: "''{item}'' permanently deleted",
    id: 'item_permanently_deleted',
  },
  item_permanently_deleted_error: {
    defaultMessage: "Failed to permanently delete ''{item}''",
    id: 'item_permanently_deleted_error',
  },
  item_permanently_deleted_error_detail: {
    defaultMessage: "Failed to permanently delete ''{item}'': {error}",
    id: 'item_permanently_deleted_error_detail',
  },
  item_search: {
    defaultMessage: '{item} search',
    id: 'item_search',
  },
  item_in_list: {
    defaultMessage: '{position, select, only {} first {} last { and } other {, }}{item}',
    id: 'item_in_list',
  },
  key: {
    defaultMessage: 'Key',
    id: 'key',
  },
  kind: {
    defaultMessage: 'Kind',
    id: 'kind',
  },
  language: {
    defaultMessage: 'Language',
    id: 'language',
  },
  latency: {
    defaultMessage: 'Latency',
    id: 'latency',
  },
  latest: {
    defaultMessage: 'Latest',
    id: 'latest',
  },
  litespeed_cache: {
    defaultMessage: 'LiteSpeed cache',
    id: 'litespeed_cache',
  },
  licence: {
    defaultMessage: 'Licence',
    id: 'licence',
  },
  limit: {
    defaultMessage: 'Limit',
    id: 'limit',
  },
  list: {
    defaultMessage: 'List',
    id: 'list',
  },
  loading: {
    defaultMessage: 'Loading',
    id: 'loading',
  },
  log: {
    defaultMessage: 'Log',
    id: 'log',
  },
  login: {
    defaultMessage: 'Login',
    id: 'login',
  },
  login_to_admin: {
    defaultMessage: 'Login to admin',
    id: 'login_to_admin',
  },
  login_credentials: {
    defaultMessage: 'Login credentials',
    id: 'login_credentials',
  },
  logout: {
    defaultMessage: 'Logout',
    id: 'logout',
  },
  logs: {
    defaultMessage: 'Logs',
    id: 'logs',
  },
  magento: {
    defaultMessage: 'Magento',
    id: 'magento',
  },
  mailbox: {
    defaultMessage: 'Mailbox',
    id: 'mailbox',
  },
  mail_certificate_details: {
    defaultMessage: 'Mail certificate details',
    id: 'mail_certificate_details',
  },
  mailbox_usage: {
    defaultMessage: 'Mailbox usage',
    id: 'mailbox_usage',
  },
  mailbox_size: {
    defaultMessage: 'Mailbox size',
    id: 'mailbox_size',
  },
  mailboxes: {
    defaultMessage: 'Mailboxes',
    id: 'mailboxes',
  },
  mail_routing_settings: {
    defaultMessage: 'Mail routing settings',
    id: 'mail_routing_settings',
  },

  manage: {
    defaultMessage: 'Manage',
    id: 'manage',
  },
  manage_servers: {
    defaultMessage: 'Manage servers',
    id: 'manage_servers',
  },
  manage_customers: {
    defaultMessage: 'Manage customers',
    id: 'manage_customers',
  },
  manage_website: {
    defaultMessage: 'Manage website',
    id: 'manage_website',
  },
  manage_package_name: {
    defaultMessage: 'Manage {name}',
    id: 'manage_package_name',
  },
  manage_database_users: {
    defaultMessage: 'Manage database users',
    id: 'manage_database_users',
  },
  manual: {
    defaultMessage: 'Manual',
    id: 'manual',
  },
  mappings: {
    defaultMessage: 'Mappings',
    id: 'mappings',
  },
  map_sni_hostnames: {
    defaultMessage: 'Map SNI hostnames',
    id: 'map_sni_hostnames',
  },
  mark_decommissioned: {
    defaultMessage: 'Mark as decommissioned',
    id: 'mark_decommissioned',
  },
  mbps: {
    defaultMessage: 'mbps',
    id: 'mbps',
  },
  'message.required': {
    defaultMessage: 'Please enter a message',
    id: 'required.message',
  },
  message: {
    defaultMessage: 'Message',
    id: 'message',
  },
  messages: {
    defaultMessage: 'Messages',
    id: 'messages',
  },
  my_account: {
    defaultMessage: 'My account',
    id: 'my_account',
  },
  my_cnf: {
    defaultMessage: 'my.cnf',
    id: 'my_cnf',
  },
  outgoing_spam_filtering: {
    defaultMessage: 'Outgoing spam filtering',
    id: 'outgoing_spam_filtering',
  },
  'package_card.databases.tooltip': {
    id: 'package_card.databases.tooltip',
    defaultMessage: 'The number of database which may be created across all of your websites.',
  },
  'package_name.required': {
    id: 'package_name.required',
    defaultMessage: 'A package name is required',
  },
  'package_name.unique': {
    id: 'package_name.unique',
    defaultMessage: 'A package with this name already exists',
  },
  prohibited_domains: {
    defaultMessage: 'Prohibited domains',
    id: 'prohibited_domains',
  },
  proxy: {
    defaultMessage: 'Proxy',
    id: 'proxy',
  },
  services: {
    id: 'services',
    defaultMessage: 'Services',
  },
  role_status_server_name: {
    defaultMessage: 'Role status (server name)',
    id: 'role_status_server_name',
  },
  service_websites: {
    id: 'service_websites',
    defaultMessage: 'Service websites',
  },
  service_settings_ssl: {
    defaultMessage: 'SSL service settings',
    id: 'service_settings_ssl',
  },
  service_settings_email: {
    defaultMessage: 'Email service settings',
    id: 'service_settings_email',
  },
  service_settings_application: {
    defaultMessage: 'Application service settings',
    id: 'service_settings_application',
  },
  service_settings_backup: {
    defaultMessage: 'Backup service settings',
    id: 'service_settings_backup',
  },
  'settings.licence.toast_error': {
    defaultMessage:
      'Licence key cannot be applied. Please check this licence is not already in use. If it has been used on a previous installation of Enhance you can re-issue it through {a}',
    id: 'settings.licence.toast_error',
  },
  'settings.licence.toast_show': {
    defaultMessage: 'Updating licence key',
    id: 'settings.licence.toast_show',
  },
  'settings.licence.toast_success': {
    defaultMessage: 'Licence details saved',
    id: 'settings.licence.toast_success',
  },
  server_hostname: {
    defaultMessage: 'Server hostname',
    id: 'server_hostname',
  },
  maintenance_mode: {
    defaultMessage: 'Maintenance mode',
    id: 'maintenance_mode',
  },
  minute: {
    defaultMessage: 'Minute',
    id: 'minute',
  },
  minutes: {
    defaultMessage: 'Minutes',
    id: 'minutes',
  },
  minute_count: {
    defaultMessage: '{count, plural, one {1 minute} other {# minutes}}',
    id: 'minute_count',
  },
  mod_sec: {
    defaultMessage: 'ModSecurity',
    id: 'mod_sec',
  },
  month: {
    defaultMessage: 'Month',
    id: 'month',
  },
  monthly: {
    defaultMessage: 'Monthly',
    id: 'monthly',
  },
  move_server: {
    defaultMessage: 'Move server',
    id: 'move_server',
  },
  move_websites: {
    defaultMessage: 'Move websites',
    id: 'move_websites',
  },
  mount_point_required: {
    defaultMessage: 'Please enter a mount point',
    id: 'mount_point_required',
  },
  ms: {
    defaultMessage: 'ms',
    id: 'ms',
  },
  must_be_unique: {
    defaultMessage: 'Must be unique',
    id: 'must_be_unique',
  },
  name: {
    defaultMessage: 'Name',
    id: 'name',
  },
  name_email: {
    defaultMessage: 'Name or email',
    id: 'name_email',
  },
  'name.required': {
    defaultMessage: 'Please enter a full name',
    id: 'name.required',
  },
  nameserver: {
    defaultMessage: 'Nameserver',
    id: 'nameserver',
  },
  nameservers: {
    defaultMessage: 'Nameservers',
    id: 'nameservers',
  },
  new: {
    defaultMessage: 'New',
    id: 'new',
  },
  new_import: {
    id: 'new_import',
    defaultMessage: 'New import',
  },
  new_migration: {
    id: 'new_migration',
    defaultMessage: 'New migration',
  },
  new_file: {
    id: 'new_file',
    defaultMessage: 'New file',
  },
  new_folder: {
    id: 'new_folder',
    defaultMessage: 'New folder',
  },
  new_password: {
    defaultMessage: 'New password',
    id: 'new_password',
  },
  new_user: {
    defaultMessage: 'New user',
    id: 'new_user',
  },
  new_email_address: {
    defaultMessage: 'New email address',
    id: 'new_email_address',
  },
  next: {
    defaultMessage: 'Next',
    id: 'next',
  },
  next_step: {
    defaultMessage: 'Next step',
    id: 'next_step',
  },
  nginx: {
    defaultMessage: 'Nginx',
    id: 'nginx',
  },
  no: {
    defaultMessage: 'No',
    id: 'no',
  },
  no_email_accounts: {
    defaultMessage: 'No email accounts',
    id: 'no_email_accounts',
  },
  no_ssl_certificates: {
    defaultMessage: 'No SSL certificates',
    id: 'no_ssl_certificates',
  },
  no_databases: {
    defaultMessage: 'No databases',
    id: 'no_databases',
  },
  no_database_users: {
    defaultMessage: 'No database users',
    id: 'no_database_users',
  },
  not_needed: {
    defaultMessage: 'This is not needed',
    id: 'not_needed',
  },
  not_validated: {
    defaultMessage: 'Not validated',
    id: 'not_validated',
  },
  none: {
    defaultMessage: 'None',
    id: 'none',
  },

  notifications: {
    defaultMessage: 'Notifications',
    id: 'notifications',
  },
  notify: {
    defaultMessage: 'Notify',
    id: 'notify',
  },
  object_storage: {
    defaultMessage: 'Object storage',
    id: 'object_storage',
  },
  off: {
    defaultMessage: 'Off',
    id: 'off',
  },
  offline: {
    defaultMessage: 'Offline',
    id: 'offline',
  },
  ok: {
    defaultMessage: 'Ok',
    id: 'ok',
  },
  ok_got_it: {
    defaultMessage: 'Ok, got it',
    id: 'ok_got_it',
  },
  on: {
    defaultMessage: 'On',
    id: 'on',
  },
  opcode_caching: {
    id: 'opcode_caching',
    defaultMessage: 'Opcode caching',
  },
  operation: {
    defaultMessage: 'Operation',
    id: 'operation',
  },
  optimisation: {
    defaultMessage: 'Optimisation',
    id: 'optimisation',
  },
  optional: {
    defaultMessage: '(Optional)',
    id: 'optional',
  },
  or: {
    defaultMessage: 'or',
    id: 'or',
  },
  organisation: {
    defaultMessage: 'Organisation',
    id: 'organisation',
  },
  organisation_name: {
    defaultMessage: 'Organisation name',
    id: 'organisation_name',
  },
  'organisation.required': {
    defaultMessage: 'Organisation is required',
    id: 'organisation.required',
  },
  os: {
    defaultMessage: 'OS',
    id: 'os',
  },
  other: {
    defaultMessage: 'Other',
    id: 'other',
  },
  out_of_office: {
    defaultMessage: 'Out of office',
    id: 'out_of_office',
  },
  overrides: {
    defaultMessage: 'Overrides',
    id: 'overrides',
  },
  overview: {
    defaultMessage: 'Overview',
    id: 'overview',
  },
  Owner: {
    defaultMessage: 'Owner',
    id: 'Owner',
  },
  path_invalid: {
    defaultMessage: 'Please enter a valid path',
    id: 'path_invalid',
  },
  package: {
    defaultMessage: 'Package',
    id: 'package',
  },
  packages: {
    defaultMessage: 'Packages',
    id: 'packages',
  },
  package_settings: {
    defaultMessage: 'Package settings',
    id: 'package_settings',
  },
  packet_loss: {
    defaultMessage: 'Packet Loss',
    id: 'packet_loss',
  },
  password: {
    defaultMessage: 'Password',
    id: 'password',
  },
  'password.match': {
    defaultMessage: 'Passwords match',
    id: 'password.match',
  },
  'password.must_match': {
    defaultMessage: 'Passwords must match',
    id: 'password.must_match',
  },
  'password.required': {
    defaultMessage: 'Please enter a password',
    id: 'password.required',
  },
  password_incorrect: {
    defaultMessage: 'The password you have entered is incorrect. Please try again.',
    id: 'password_incorrect',
  },
  password_save_error: {
    defaultMessage: 'Failed to save password',
    id: 'password_save_error',
  },
  password_save_error_detail: {
    defaultMessage: 'Failed to save password: {error}',
    id: 'password_save_error_detail',
  },
  pending: {
    defaultMessage: 'Pending',
    id: 'pending',
  },
  pending_active: {
    defaultMessage: 'Pending &amp; active',
    id: 'pending_active',
  },
  permanently_delete: {
    defaultMessage: 'Permanently delete',
    id: 'permanently_delete',
  },
  pushing: {
    defaultMessage: 'Pushing',
    id: 'pushing',
  },
  push_live: {
    defaultMessage: 'Push live',
    id: 'push_live',
  },
  plugins: {
    defaultMessage: 'Plugins',
    id: 'plugins',
  },
  php: {
    defaultMessage: 'PHP',
    id: 'php',
  },
  php_engine: {
    defaultMessage: 'PHP engine',
    id: 'php_engine',
  },
  php_error_log: {
    defaultMessage: 'PHP error log',
    id: 'php_error_log',
  },
  php_ini_editor: {
    defaultMessage: 'php.ini editor',
    id: 'php_ini_editor',
  },
  php_fpm_settings: {
    defaultMessage: 'PHP-FPM settings',
    id: 'php_fpm_settings',
  },
  php_my_admin_domain: {
    defaultMessage: 'phpMyAdmin domain',
    id: 'php_my_admin_domain',
  },
  php_fpm_empty_title: {
    id: 'php_fpm_empty_title',
    defaultMessage: 'No PHP-FPM directives',
  },
  php_fpm_empty_blurb: {
    id: 'php_fpm_empty_blurb',
    defaultMessage: 'Add PHP-FPM directives for the FPM pool that your website runs under.',
  },
  php_ini_title: {
    defaultMessage: 'php.ini settings',
    id: 'php_ini_title',
  },
  php_settings: {
    defaultMessage: 'PHP settings',
    id: 'php_settings',
  },
  php_version: {
    defaultMessage: 'PHP version',
    id: 'php_version',
  },
  platform: {
    defaultMessage: 'Platform',
    id: 'platform',
  },
  platform_settings: {
    defaultMessage: 'Platform settings',
    id: 'platform_settings',
  },
  field_required: {
    defaultMessage: 'Please enter a {field}',
    id: 'field_required',
  },
  field_invalid: {
    defaultMessage: 'Please enter a valid {field}',
    id: 'field_invalid',
  },
  permissions: {
    defaultMessage: 'Permissions',
    id: 'permissions',
  },
  permissive: {
    defaultMessage: 'Permissive',
    id: 'permissive',
  },
  plural_api_tokens: {
    defaultMessage: '{count, plural, one {API token} other {API tokens}}',
    id: 'plural_api_tokens',
  },
  plural_customers: {
    defaultMessage: '{count, plural, one {customer} other {customers}}',
    id: 'plural_customers',
  },
  plural_emails: {
    defaultMessage: '{count, plural, one {email} other {emails}}',
    id: 'plural_emails',
  },
  plural_items: {
    defaultMessage: '{count, plural, one {item} other {items}}',
    id: 'plural_items',
  },
  plural_plugins_new: {
    defaultMessage: '{count, plural, one {plugin} other {plugins}}',
    id: 'plural_plugins_new',
  },
  plural_users: {
    defaultMessage: '{count, plural, one {user} other {users}}',
    id: 'plural_users',
  },
  plural_websites: {
    defaultMessage: '{count, plural, one {website} other {websites}}',
    id: 'plural_websites',
  },
  plural_databases: {
    defaultMessage: '{count, plural, one {database} other {databases}}',
    id: 'plural_databases',
  },
  plural_logs: {
    defaultMessage: '{count, plural, one {log} other {logs}}',
    id: 'plural_logs',
  },
  plural_packages: {
    defaultMessage: '{count, plural, one {package} other {packages}}',
    id: 'plural_packages',
  },
  plural_results: {
    defaultMessage: '{count, plural, one {result} other {results}}',
    id: 'plural_results',
  },
  port: {
    defaultMessage: 'Port',
    id: 'port',
  },
  preferences: {
    defaultMessage: 'Preferences',
    id: 'preferences',
  },
  preview: {
    defaultMessage: 'Preview',
    id: 'preview',
  },
  previous_page: {
    defaultMessage: 'Previous page',
    id: 'previous_page',
  },
  primary: {
    defaultMessage: 'Primary',
    id: 'primary',
  },
  primary_ip: {
    defaultMessage: 'Primary IP',
    id: 'primary_ip',
  },
  priority: {
    defaultMessage: 'Priority',
    id: 'priority',
  },
  privileges: {
    defaultMessage: 'Privileges',
    id: 'privileges',
  },
  private_key: {
    defaultMessage: 'Private key',
    id: 'private_key',
  },
  profile: {
    defaultMessage: 'Profile',
    id: 'profile',
  },
  protocol: {
    defaultMessage: 'Protocol',
    id: 'protocol',
  },
  purge: {
    defaultMessage: 'Purge',
    id: 'purge',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'quantity',
  },
  reboot: {
    defaultMessage: 'Reboot',
    id: 'reboot',
  },
  recent: {
    defaultMessage: 'Recent',
    id: 'recent',
  },
  redirects: {
    defaultMessage: 'Redirects',
    id: 'redirects',
  },
  remaining: {
    defaultMessage: 'remaining',
    id: 'remaining',
  },
  remove: {
    defaultMessage: 'Remove',
    id: 'remove',
  },
  remove_role: {
    defaultMessage: 'Remove role',
    id: 'remove_role',
  },
  remove_from_group: {
    defaultMessage: 'Remove from group',
    id: 'remove_from_group',
  },
  removing: {
    defaultMessage: 'Removing',
    id: 'removing',
  },
  required: {
    defaultMessage: 'Required',
    id: 'required',
  },
  required_field: {
    defaultMessage: 'This is a required field',
    id: 'required_field',
  },
  request: { defaultMessage: 'Request', id: 'request' },
  request_url: { defaultMessage: 'Request URL', id: 'request_url' },
  request_lets_encrypt_cert: { defaultMessage: "Request Let's Encrypt certificate", id: 'request_lets_encrypt_cert' },
  reseller: {
    defaultMessage: 'Reseller',
    id: 'reseller',
  },
  reset: {
    defaultMessage: 'Reset',
    id: 'reset',
  },
  reset_password: {
    defaultMessage: 'Reset password',
    id: 'reset_password',
  },
  resetToDefault: {
    defaultMessage: 'Reset to default',
    id: 'reset_to_default',
  },
  restart: {
    defaultMessage: 'Restart',
    id: 'restart',
  },
  restore: {
    defaultMessage: 'Restore',
    id: 'restore',
  },
  restore_backup: {
    defaultMessage: 'Restore backup',
    id: 'restore_backup',
  },
  restore_website: {
    defaultMessage: 'Restore website',
    id: 'restore_website',
  },
  restore_websites: {
    defaultMessage: 'Restore websites',
    id: 'restore_websites',
  },
  retry: {
    defaultMessage: 'Retry',
    id: 'retry',
  },
  refresh_table: {
    defaultMessage: 'Refresh table',
    id: 'refresh_table',
  },
  revoke_access: {
    defaultMessage: 'Revoke access',
    id: 'revoke_access',
  },
  role: {
    defaultMessage: 'Role',
    id: 'role',
  },
  'role_description.business': {
    defaultMessage: 'can manage packages and branding settings.',
    id: 'role_description.business',
  },
  'role_description.collaborator': {
    defaultMessage: 'can manage the website(s) they have been granted access to.',
    id: 'role_description.collaborator',
  },
  'role_description.owner': {
    defaultMessage:
      'is the primary contact for an organisation with unrestricted permissions. This user cannot be deleted.',
    id: 'role_description.owner',
  },
  'role_description.super_admin': {
    defaultMessage: 'has unrestricted access.',
    id: 'role_description.super_admin',
  },
  'role_description.support': {
    defaultMessage: 'can manage customers and customer websites.',
    id: 'role_description.support',
  },
  'role_description.system_admin': {
    defaultMessage: 'can manage servers, customers, websites, service and platform settings.',
    id: 'role_description.system_admin',
  },
  'role_tooltip.application': {
    defaultMessage:
      'The Application role is responsible for serving websites and PHP applications. In addition the role includes a file management service, a screenshot service and an FTP server. Customer websites are deployed to /var/www and you should ensure the partition containing this directory has adequate space for the websites you wish to host.',
    id: 'role_tooltip.application',
  },
  'role_tooltip.backup': {
    defaultMessage:
      'The Backup role acts as a backup target for websites on your cluster. You will require a directory with plenty of space, ideally on a dedicated server in a separate data centre to your main cluster.',
    id: 'role_tooltip.backup',
  },
  'role_tooltip.database': {
    defaultMessage:
      'The database role installs the popular MySQL database engine. We recommend installing the database roles on each of your application servers. This will allow customers to run databases local to their PHP application and generally gives the best performance.',
    id: 'role_tooltip.database',
  },
  'role_tooltip.dns': {
    defaultMessage:
      'The DNS role will enable this server to serve DNS for all of the websites in your cluster. You can map a "nameserver hostname" ie. ns1.yourbrand.com which will have an A record created automatically and will be shown to customers. Your resellers will also be able to create branded nameserver hostnames for each of your DNS role instances.',
    id: 'role_tooltip.dns',
  },
  'role_tooltip.email': {
    defaultMessage:
      'The Email role is responsible for sending and receiving customer emails. It includes a POP/IMAP server (Dovecot), a mail transfer agent (Postfix) and an anti-spam server (rspamd). Webmail (Roundcube) resides on your control panel server and can be used to access mail on any instance of the Email role.',
    id: 'role_tooltip.email',
  },
  roles: {
    defaultMessage: 'Roles',
    id: 'roles',
  },
  roundcube_domain: {
    defaultMessage: 'Roundcube domain',
    id: 'roundcube_domain',
  },
  's3.region': {
    defaultMessage: 'Region',
    id: 's3.region',
  },
  's3.endpoint': {
    defaultMessage: 'Endpoint',
    id: 's3.endpoint',
  },
  's3.bucket_name': {
    defaultMessage: 'Bucket name',
    id: 's3.bucket_name',
  },
  's3.prefix': {
    defaultMessage: 'Prefix',
    id: 's3.prefix',
  },
  's3.prefix_info': {
    defaultMessage:
      'All backups within this bucket will be pre-fixed with this name. This prefix can be anything you choose.',
    id: 's3.prefix_info',
  },
  's3.access_key_id': {
    defaultMessage: 'Access key ID',
    id: 's3.access_key_id',
  },
  's3.access_key_secret': {
    defaultMessage: 'Access key secret',
    id: 's3.access_key_secret',
  },
  save: {
    defaultMessage: 'Save',
    id: 'save',
  },
  save_error: {
    defaultMessage: 'Save error',
    id: 'save_error',
  },
  saved: {
    defaultMessage: 'Saved',
    id: 'saved',
  },
  saving: {
    defaultMessage: 'Saving',
    id: 'saving',
  },
  screenshot: {
    defaultMessage: 'Screenshot',
    id: 'screenshot',
  },
  screenshots: {
    defaultMessage: 'Screenshots',
    id: 'screenshots',
  },
  search: {
    defaultMessage: 'Search',
    id: 'search',
  },
  search_type: {
    defaultMessage: 'Search {type}',
    id: 'search_type',
  },
  'search.required': {
    defaultMessage: 'Search term is required',
    id: 'search.required',
  },
  search_customers: {
    defaultMessage: 'Search customers',
    id: 'search_customers',
  },
  search_emails: {
    defaultMessage: 'Search emails',
    id: 'search_emails',
  },
  secondary: {
    defaultMessage: 'Secondary',
    id: 'secondary',
  },
  seconds: {
    defaultMessage: 'Seconds',
    id: 'seconds',
  },
  security: {
    defaultMessage: 'Security',
    id: 'security',
  },
  see_all_websites: {
    defaultMessage: 'See all websites',
    id: 'see_all_websites',
  },
  select: {
    defaultMessage: 'Select',
    id: 'select',
  },
  select_server: {
    defaultMessage: 'Select server',
    id: 'select_server',
  },
  select_all_count: {
    defaultMessage: 'Select all ({total})',
    id: 'select_all_count',
  },
  select_all: {
    defaultMessage: 'Select all',
    id: 'select_all',
  },
  select_a_package: {
    defaultMessage: 'Select a package',
    id: 'select_a_package',
  },
  select_a_role: {
    defaultMessage: 'Please select at least one role',
    id: 'select_a_role',
  },
  select_a_website: {
    defaultMessage: 'Select a website',
    id: 'select_a_website',
  },
  select_more_than_one: {
    defaultMessage: 'You can select more than one',
    id: 'select_more_than_one',
  },
  selected: {
    defaultMessage: 'Selected',
    id: 'selected',
  },
  selected_count: {
    defaultMessage: '{count} selected',
    id: 'selected_count',
  },
  semver: {
    defaultMessage: 'v{version}',
    id: 'semver',
  },
  send: {
    defaultMessage: 'Send',
    id: 'send',
  },
  select_a_group: {
    defaultMessage: 'Select a group',
    id: 'select_a_group',
  },
  server: {
    defaultMessage: 'Server',
    id: 'server',
  },
  server_domain: {
    defaultMessage: 'Server domain',
    id: 'server_domain',
  },
  server_domains: {
    defaultMessage: 'Server domains',
    id: 'server_domains',
  },
  server_domains_blurb: {
    defaultMessage:
      "A server hostname mapped to this server will create a website and relevant DNS records pointing to the server.  A Let's Encrypt certificate will be automatically issued where possible.  This will enable your customers to use this hostname for incoming / outgoing email in place of mail.customerdomain.com if the DNS for that domain is not yet pointed.",
    id: 'server_domains_blurb',
  },
  server_ip_address: {
    defaultMessage: 'Server IP address',
    id: 'server_ip_address',
  },
  server_name: {
    defaultMessage: 'Server name',
    id: 'server_name',
  },
  servers: {
    defaultMessage: 'Servers',
    id: 'servers',
  },
  server_placement: {
    defaultMessage: 'Server placement',
    id: 'server_placement',
  },
  service: {
    defaultMessage: 'Service',
    id: 'service',
  },
  service_locations: {
    defaultMessage: 'Service locations',
    id: 'service_locations',
  },
  service_ssls: {
    defaultMessage: 'Service SSLs',
    id: 'service_ssls',
  },
  setting_save_error: {
    defaultMessage: 'An error occurred while saving this setting',
    id: 'setting_save_error',
  },
  setting_save_success: {
    defaultMessage: 'Setting has been saved',
    id: 'setting_save_success',
  },
  settings: {
    defaultMessage: 'Settings',
    id: 'settings',
  },
  set_platform_nameservers: {
    defaultMessage: 'Set platform nameservers',
    id: 'set_platform_nameservers',
  },
  show: {
    defaultMessage: 'Show',
    id: 'show',
  },
  slug: {
    defaultMessage: 'Slug',
    id: 'slug',
  },
  single_sign_on: {
    id: 'single_sign_on',
    defaultMessage: 'Single sign on',
  },
  SiteAccess: {
    defaultMessage: 'Collaborator',
    id: 'SiteAccess',
  },
  size: {
    defaultMessage: 'Size',
    id: 'size',
  },
  source_path: {
    defaultMessage: 'Source path',
    id: 'source_path',
  },
  ssh: {
    defaultMessage: 'SSH',
    id: 'ssh',
  },
  ssh_key_manager: {
    defaultMessage: 'SSH key manager',
    id: 'ssh_key_manager',
  },
  ssl: {
    defaultMessage: 'SSL',
    id: 'ssl',
  },
  ssl_certificate: {
    defaultMessage: 'SSL certificate',
    id: 'ssl_certificate',
  },
  ssl_certificates: {
    defaultMessage: 'SSL certificates',
    id: 'ssl_certificates',
  },
  staging_websites: {
    defaultMessage: 'Staging websites',
    id: 'staging_websites',
  },
  statistics: {
    defaultMessage: 'Statistics',
    id: 'statistics',
  },
  strict: {
    defaultMessage: 'Strict',
    id: 'strict',
  },
  styling: {
    defaultMessage: 'Styling',
    id: 'styling',
  },
  status: {
    defaultMessage: 'Status',
    id: 'status',
  },
  active: {
    defaultMessage: 'Active',
    id: 'active',
  },
  cancelled: {
    defaultMessage: 'Cancelled',
    id: 'cancelled',
  },
  completed: {
    defaultMessage: 'Completed',
    id: 'completed',
  },
  deleted: {
    defaultMessage: 'Deleted',
    id: 'deleted',
  },
  delete_package: {
    defaultMessage: 'Delete package',
    id: 'delete_package',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'failed',
  },
  in_progress: {
    defaultMessage: 'In progress',
    id: 'in_progress',
  },
  suspended: {
    defaultMessage: 'Suspended',
    id: 'suspended',
  },
  themes: {
    defaultMessage: 'Themes',
    id: 'themes',
  },
  trial: {
    defaultMessage: 'Trial',
    id: 'trial',
  },
  ttl: {
    defaultMessage: 'TTL',
    id: 'ttl',
  },
  unknown: {
    defaultMessage: 'Unknown',
    id: 'unknown',
  },
  unknown_object: {
    defaultMessage: 'Unknown object',
    id: 'unknown_object',
  },
  unknown_user: {
    defaultMessage: 'Unknown user',
    id: 'unknown_user',
  },
  unpaid: {
    defaultMessage: 'Unpaid',
    id: 'unpaid',
  },
  staging_domain: {
    defaultMessage: 'Staging domain',
    id: 'staging_domain',
  },
  statuses: {
    defaultMessage: 'Statuses',
    id: 'statuses',
  },
  storage: {
    defaultMessage: 'Storage',
    id: 'storage',
  },
  subject: {
    defaultMessage: 'Subject',
    id: 'subject',
  },
  'subject.required': {
    defaultMessage: 'Please enter a subject',
    id: 'subject.required',
  },
  'sub_domain_name.invalid': {
    defaultMessage: 'Please enter a valid staging domain name',
    id: 'sub_domain_name.invalid',
  },
  'sub_domain_name.required': {
    defaultMessage: 'Please enter a staging domain name',
    id: 'sub_domain_name.required',
  },
  subscribe: {
    defaultMessage: 'Subscribe',
    id: 'subscribe',
  },
  subscriptions_count: {
    defaultMessage: '{subscriptionsCount} {subscriptionsCount, plural, one {subscription} other {subscriptions}}',
    id: 'subscriptions_count',
  },
  subscription_stickiness: {
    defaultMessage: 'Subscription stickiness',
    id: 'subscription_stickiness',
  },
  SuperAdmin: {
    defaultMessage: 'Super admin',
    id: 'SuperAdmin',
  },
  Support: {
    defaultMessage: 'Support',
    id: 'Support',
  },
  suspend: {
    defaultMessage: 'Suspend',
    id: 'suspend',
  },
  Sysadmin: {
    defaultMessage: 'System administrator',
    id: 'Sysadmin',
  },
  system: {
    defaultMessage: 'System',
    id: 'system',
  },
  system_resources: {
    defaultMessage: 'System resources',
    id: 'system_resources',
  },
  tags: {
    defaultMessage: 'Tags',
    id: 'tags',
  },
  text: {
    defaultMessage: 'Text',
    id: 'text',
  },
  time_range: {
    defaultMessage: '{from} to {to}',
    id: 'time_range',
  },
  to: {
    defaultMessage: 'to',
    id: 'to',
  },
  toggle_navigation_menu: {
    defaultMessage: 'Toggle navigation menu',
    id: 'toggle_navigation_menu',
  },
  try_again: {
    defaultMessage: 'Try again',
    id: 'try_again',
  },
  type: {
    id: 'type',
    defaultMessage: 'Type',
  },
  unchanged: {
    defaultMessage: 'Unchanged',
    id: 'unchanged',
  },
  unlimited: {
    defaultMessage: 'Unlimited',
    id: 'unlimited',
  },
  unsuspend: {
    defaultMessage: 'Unsuspend',
    id: 'unsuspend',
  },
  update: {
    defaultMessage: 'Update',
    id: 'update',
  },
  updating: {
    defaultMessage: 'Updating',
    id: 'updating',
  },
  update_php_version: {
    defaultMessage: 'Update PHP version',
    id: 'update_php_version',
  },
  update_now: {
    defaultMessage: 'Update now',
    id: 'update_now',
  },
  upload: {
    defaultMessage: 'Upload',
    id: 'upload',
  },
  upload_file: {
    defaultMessage: 'Upload file',
    id: 'upload_file',
  },
  upload_logo_etc: {
    defaultMessage: 'Upload your logo, customise colours and more',
    id: 'upload_logo_etc',
  },
  unable_to_fetch_roles: {
    defaultMessage: 'Unable to fetch roles',
    id: 'unable_to_fetch_roles',
  },
  url: {
    defaultMessage: 'URL',
    id: 'url',
  },
  'url.required': {
    defaultMessage: 'Please enter a URL',
    id: 'url.required',
  },
  'url.invalid': {
    defaultMessage: 'Please enter a valid URL',
    id: 'url.invalid',
  },
  usage: {
    defaultMessage: 'Usage',
    id: 'usage',
  },
  usage_of_total: {
    defaultMessage: '{usage} of {total}',
    id: 'usage_of_total',
  },
  usage_of_total_infinite: {
    defaultMessage: '{usage} of {total, plural, =-1 {∞} other {#}}',
    id: 'usage_of_total_infinite',
  },
  usage_tooltip: {
    defaultMessage: '{mountPoint}: <b>{usedText}</b> of {totalText} used ({percentage}%)',
    id: 'usage_tooltip',
  },
  used: {
    defaultMessage: 'used',
    id: 'used',
  },
  user: {
    defaultMessage: 'User',
    id: 'user',
  },
  user_roles: {
    defaultMessage: 'User roles',
    id: 'user_roles',
  },
  username: {
    defaultMessage: 'Username',
    id: 'username',
  },
  'username.invalid': {
    defaultMessage: 'Username can only contain alphanumeric characters, hyphens, underscores & full stops',
    id: 'username.invalid',
  },
  'username.required': {
    defaultMessage: 'Please enter a username',
    id: 'username.required',
  },
  users: {
    defaultMessage: 'Users',
    id: 'users',
  },
  valid: {
    defaultMessage: 'Valid',
    id: 'valid',
  },
  validated: {
    defaultMessage: 'Validated',
    id: 'validated',
  },
  value: {
    defaultMessage: 'Value',
    id: 'value',
  },
  version: {
    defaultMessage: 'Version',
    id: 'version',
  },
  vhost_config: {
    defaultMessage: 'VirtualHost config',
    id: 'vhost_config',
  },
  webmail: {
    defaultMessage: 'Webmail',
    id: 'webmail',
  },
  website: {
    defaultMessage: 'Website',
    id: 'website',
  },
  website_control_panel: {
    defaultMessage: 'Control Panel',
    id: 'website_control_panel',
  },
  website_dashboard: {
    defaultMessage: 'Website dashboard',
    id: 'website_dashboard',
  },
  website_imports: {
    defaultMessage: 'Website imports',
    id: 'website_imports',
  },
  website_importer: {
    defaultMessage: 'Import website',
    id: 'website_importer',
  },
  website_resources: {
    defaultMessage: 'Website resources',
    id: 'website_resources',
  },
  websites: {
    defaultMessage: 'Websites',
    id: 'websites',
  },
  websites_and_subscriptions: {
    defaultMessage: 'Websites and subscriptions',
    id: 'websites_and_subscriptions',
  },
  web_server: {
    defaultMessage: 'Web server',
    id: 'web_server',
  },
  week: {
    defaultMessage: 'Week',
    id: 'week',
  },
  weekday: {
    defaultMessage: 'Weekday',
    id: 'weekday',
  },
  visitors: {
    defaultMessage: 'Visitors',
    id: 'visitors',
  },
  whitelist: {
    defaultMessage: 'Whitelist',
    id: 'whitelist',
  },
  woocommerce: {
    defaultMessage: 'WooCommerce',
    id: 'woocommerce',
  },
  woocommerce_wordpress: {
    defaultMessage: 'WooCommerce + WordPress',
    id: 'woocommerce_wordpress',
  },
  wordpress: {
    defaultMessage: 'WordPress',
    id: 'wordpress',
  },
  wordpress_version: {
    defaultMessage: 'WordPress version',
    id: 'wordpress_version',
  },
  wordpress_update_count: {
    defaultMessage: '{count, plural, one {# update} other {# updates}}',
    id: 'wordpress_update_count',
  },
  wordpress_updates_minor_help: {
    id: 'wordpress_updates_minor_help',
    defaultMessage: 'Update only to minor versions',
  },
  wordpress_updates_major_help: {
    id: 'wordpress_updates_major_help',
    defaultMessage: 'Always update to the latest available version',
  },
  wp_admin: {
    defaultMessage: 'wp admin',
    id: 'wp_admin',
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'yes',
  },
  you: {
    defaultMessage: 'You',
    id: 'you',
  },
  yoast: {
    defaultMessage: 'Yoast',
    id: 'yoast',
  },
});
