import { Fragment, MouseEvent, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

import { IconName } from 'src/components/Icon/Icon.types';
import { getIsTouchDevice } from 'src/hooks/useIsTouchDevice/useIsTouchDevice';
import { LinkItem } from 'src/utils/types';

import { LinkContent } from '../LinkContent/LinkContent';
import { style } from './DashboardSpineItem.style';

interface Props {
  expanded: boolean;
  icon: IconName;
  isExternalLink?: boolean;
  text: string;
  url: string;
  className?: string;
  trailing?: boolean;
  onClick?: (event: MouseEvent) => void;
  nested?: LinkItem[];
  isChild?: boolean;
}

const isTouch = getIsTouchDevice();

const DashboardSpineItem = ({
  className,
  expanded,
  trailing,
  icon,
  isExternalLink,
  onClick,
  text,
  url,
  nested,
  isChild = false,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  const isIndexLink = url === '/';
  const linkProps = {
    className,
    css: style.item({ expanded, trailing: !!trailing, isChild, isTouch }),
    title: text,
    'aria-label': text,
  };
  const hasChildren = nested && nested.length > 0;

  // Collapse open sub-menus when the spine is toggle to expanded or collapsed.
  useEffect(() => {
    if (!collapsed) {
      setCollapsed(true);
    }
  }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isExternalLink) {
    return (
      <a href={url} rel="noopener noreferrer" target="_blank" {...linkProps}>
        <LinkContent expanded={expanded} icon={icon} text={text} />
      </a>
    );
  }

  return (
    <Fragment>
      <NavLink
        data-qa="navLink"
        onClick={(e) => {
          setCollapsed(false);
          onClick?.(e);
        }}
        activeClassName="selected"
        exact={isIndexLink}
        to={url}
        {...linkProps}
      >
        <LinkContent
          expanded={expanded}
          icon={icon}
          text={text}
          hasChildren={hasChildren}
          collapsed={collapsed}
          onToggle={() => setCollapsed(!collapsed)}
          isChild={isChild}
        />
      </NavLink>

      {hasChildren &&
        !collapsed &&
        nested.map(({ url, icon, label }) => (
          <DashboardSpineItem
            key={url}
            url={url}
            text={label}
            expanded={expanded}
            icon={icon}
            onClick={onClick}
            isChild
          />
        ))}
    </Fragment>
  );
};

export { DashboardSpineItem };
