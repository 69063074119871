import { useEffect, useRef } from 'react';
import { ImportMigrationEntry, ImportMigrationEntryStatusEnum } from 'src/orchd-client';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { useGetImportMigrationsQuery } from 'src/api_services/importers/query';
import { Span, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { autosaveToast } from 'src/store/autosave-toast/actions';
import { sessionSelectors } from 'src/store/session/selectors';

import { websiteImportsPollingInterval } from '../constants';

export const dispatchToProps = {
  autosaveToastShow: autosaveToast.show,
  autosaveToastSuccess: autosaveToast.success,
  autosaveToastError: autosaveToast.error,
};

export const stateToProps = (state: AppState) => ({
  member: sessionSelectors.getMember(state),
});

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

interface Props extends ActionProps, StateProps {}

const statuses = [ImportMigrationEntryStatusEnum.queued, ImportMigrationEntryStatusEnum.inProgress];

export const _WebsiteImportToast = ({ member, autosaveToastShow, autosaveToastSuccess }: Props) => {
  const { orgId } = member;
  const queuedCount = useRef(0);
  const inProgressCount = useRef(0);

  const show = (queued: number, inProgress: number) =>
    autosaveToastShow({
      text: (
        <Text color="light" size="sm">
          <FormattedMessage
            id="website_import.progress_new"
            defaultMessage="Website import in progress - {inProgress} in progress · {queued} queued"
            values={{ inProgress, queued }}
          />
          &nbsp;&middot;&nbsp;
          <Link to="/website-imports">
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_status} />
            </Span>
          </Link>
        </Text>
      ),
      id: 'cpanel-import',
    });

  const success = () =>
    autosaveToastSuccess({
      text: (
        <Text color="light" size="sm">
          <FormattedMessage id="website_import.progress.success" defaultMessage="Website import completed" />
          &nbsp;&middot;&nbsp;
          <Link to="/website-imports">
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_status} />
            </Span>
          </Link>
        </Text>
      ),
      id: 'cpanel-import',
      persistent: true,
    });

  const { data } = useGetImportMigrationsQuery({
    refetchInterval: (data?: ImportMigrationEntry[]) => {
      if ((data?.filter((item) => statuses.includes(item.status))?.length ?? 0) > 0) {
        return websiteImportsPollingInterval;
      }

      return false;
    },
    enabled: !!orgId,
    orgId,
    status: statuses,
  });

  useEffect(() => {
    const queued = data?.filter((item) => item.status === ImportMigrationEntryStatusEnum.queued);
    const inProgress = data?.filter((item) => item.status === ImportMigrationEntryStatusEnum.inProgress);
    const isImporting = queued?.length || inProgress?.length;
    const wasPreviouslyImporting = inProgressCount.current || queuedCount.current;
    if (isImporting) {
      show(queued?.length ?? 0, inProgress?.length ?? 0);
    } else if (!isImporting && wasPreviouslyImporting) {
      success();
    }
    inProgressCount.current = inProgress?.length ?? 0;
    queuedCount.current = queued?.length ?? 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return null;
};

export const WebsiteImportToast = connect(stateToProps, dispatchToProps)(_WebsiteImportToast);
