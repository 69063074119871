import { Role } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { membersApi } from './service';

export const useGetMemberQuery = ({ orgId, memberId }: { orgId: string; memberId: string }) => {
  return useQuery({
    queryKey: queryKeys.getMember({ orgId, memberId }),
    queryFn: () => membersApi.getMember({ orgId, memberId }),
    select: (data) => data.data,
    retry: false,
    refetchOnMount: true,
    keepPreviousData: true,
  });
};

export const useGetMembersQuery = ({
  orgId,
  enabled = true,
  limit = 10,
  offset = 0,
  role,
  sortBy,
  sortOrder,
}: Parameters<typeof membersApi.getMembers>[0] & { enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.getMembers({ orgId, limit, offset, role, sortBy, sortOrder }),
    queryFn: () => membersApi.getMembers({ orgId, limit, offset, role, sortBy, sortOrder }),
    select: (data) => data.data,
    enabled,
    retry: false,
    refetchOnMount: true,
    keepPreviousData: true,
  });
};

export const useGetOwnerQuery = ({ orgId }: { orgId: string }, { enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: queryKeys.getMembers({ orgId }),
    queryFn: () => membersApi.getMembers({ orgId }),
    enabled,
    select: (data) => data.data.items.find((member) => member.roles.includes(Role.Owner)),
    retry: false,
  });
};

export const useDeleteMemberMutation = () => {
  return useMutation(membersApi.deleteMember);
};

export const useUpdateMemberMutation = () => {
  return useMutation(membersApi.updateMember);
};

export const useUpdateOwnerMutation = () => {
  return useMutation(membersApi.updateOwner);
};
