import { RolesInfo, ServerInfo } from 'src/orchd-client';
import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { FetchingState } from 'src/store/helpers';
import { updatingServersReducer } from 'src/store/servers/updatingServers/reducer';

import { createRequestStateReducer } from '../helpers';
import { serversActions } from './actions';

export type ServerStatus = FetchingState<{ roles: RolesInfo; info: ServerInfo } | undefined>;

export type AllServerStatuses = Record<string, ServerStatus>;

interface ServerStatusesState {
  serverStatuses: AllServerStatuses | undefined;
}

export const initialAllServerStatusesState: ServerStatusesState = {
  serverStatuses: undefined,
};

const allServerStatuses = createReducer(initialAllServerStatusesState, (handleAction) => [
  handleAction(serversActions.setAllServerStatusesLoading, (state, { payload }) => {
    const { servers = [] } = payload;
    const loadingServers = servers.reduce<AllServerStatuses>((acc, server) => {
      acc[server.id] = { loading: true, error: '', data: undefined };
      return acc;
    }, {});
    return { ...state, serverStatuses: loadingServers };
  }),
  handleAction(serversActions.setSingleServerStatuses, (state, { payload }) => ({
    ...state,
    serverStatuses: { ...state.serverStatuses, [payload.serverId]: payload.resp },
  })),
  handleAction(serversActions.resetAllServerStatuses, (state) => ({ ...state, serverStatuses: undefined })),
]);

export const serversReducer = combineReducers({
  servers: createRequestStateReducer(serversActions.getServers),
  groupedServers: createRequestStateReducer(serversActions.getGroupedServers),
  serverInfo: createRequestStateReducer(serversActions.getServerInfo),
  installCmd: createRequestStateReducer(serversActions.getInstallCmd),
  configureServer: createRequestStateReducer(serversActions.configureServer),
  serviceSetting: createRequestStateReducer(serversActions.getServiceSetting),
  serverRoles: createRequestStateReducer(serversActions.getServerRoles),
  allServerStatuses,
  updatingServers: updatingServersReducer,
  webserverKind: createRequestStateReducer(serversActions.getWebserverKind),
});
