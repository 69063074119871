import { LoginMembership, Member, Role } from 'src/orchd-client';
import memoize from 'lodash/memoize';

export const rolesWithSiteAccessAdded = memoize((member: LoginMembership | Member) => {
  const usedSiteAccessCount = 'siteAccesses' in member ? member.siteAccesses?.length : member.siteAccessCount;
  return usedSiteAccessCount ? [...member.roles, Role.SiteAccess] : member.roles;
});

export const hasAtLeastOneRole = (roles: Role[] | RoleTuple, member: LoginMembership | Member) => {
  const memberRolesToCheck = rolesWithSiteAccessAdded(member);
  return roles.some((role) => memberRolesToCheck.includes(role));
};

const { Owner, SuperAdmin, Sysadmin, Support, Business, SiteAccess } = Role;

type RoleTuple =
  | readonly [Role]
  | readonly [Role, Role]
  | readonly [Role, Role, Role]
  | readonly [Role, Role, Role, Role]
  | readonly [Role, Role, Role, Role, Role]
  | readonly [Role, Role, Role, Role, Role, Role];

export const permissions = {
  admin: [Owner, SuperAdmin, Sysadmin] as const,
  addWebsites: [Owner, SuperAdmin, Sysadmin] as const,
  showServers: [Owner, SuperAdmin, Sysadmin] as const,
  showLogs: [Owner, SuperAdmin, Sysadmin] as const,
  showCustomers: [Owner, SuperAdmin, Sysadmin, Support] as const,
  showWebsiteImports: [Owner, SuperAdmin, Sysadmin, Support] as const,
  showWebsiteAnalytics: [Owner, SuperAdmin, Sysadmin, Business, SiteAccess] as const,
};
