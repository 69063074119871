import { AxiosError } from 'axios';

import { websitesApi } from 'src/api_services/websites/service';
import { store } from 'src/store';
import { sessionSelectors } from 'src/store/session/selectors';
import { websitesActions } from 'src/store/websites/actions';
import { websitesSelectors } from 'src/store/websites/selectors';
import { getBearerToken } from 'src/utils/getBearerToken';

import { axiosInstance } from '../index';

export const handleTokenRefresh = async (error: AxiosError) => {
  const { config } = error;
  const state = store.getState();

  const member = sessionSelectors.getMember(state);
  const website = websitesSelectors.getWebsite(state);

  if (!member || !website) {
    return Promise.reject(error);
  }

  // Append retry count to the request config.
  if (!config.data) {
    config.data = {};
  }

  config.data.retryCount = config.data.retryCount ? config.data.retryCount + 1 : 1;

  // Sleep for 1s, 2s and 4s on each subsequent retry before aborting.
  if (config.data.retryCount > 3) {
    return Promise.reject();
  }

  await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, config.data.retryCount - 1)));

  try {
    const data = await websitesApi.getAccessToken({ orgId: member.orgId, websiteId: website.id });
    store.dispatch(websitesActions.getAccessToken.success(data));

    const updatedConfig = {
      ...config,
      headers: { ...config.headers, Authorization: getBearerToken(data.data) },
    };

    return axiosInstance(updatedConfig);
  } catch (e) {
    return Promise.reject(error);
  }
};
