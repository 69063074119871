import { useMemo } from 'react';
import { ResourceName, Subscription } from 'src/orchd-client';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetSubscriptionBandwidthUsageQuery } from 'src/api_services/subscriptions/query';
import { Text } from 'src/components/Text';
import { UsageBars } from 'src/components/UsageBars';
import { Metric } from 'src/components/UsageBars/UsageBars';
import { messages } from 'src/messages';
import { getSubscriptionResource, isUnlimitedResource, subscriptionHasResource } from 'src/utils/packages/utils';

import { CardCollapse } from '../CardCollapse';
import { constructUsage } from './helpers';

export interface Props {
  subscription: Subscription;
}

export const SimpleSubscriptionCard = ({ subscription }: Props) => {
  const { formatMessage } = useIntl();
  const { subscriberId: orgId } = subscription;

  const { data: subscriptionBandwidth, isLoading: isLoadingBandwidth } = useGetSubscriptionBandwidthUsageQuery({
    orgId,
    subscriptionId: subscription.id,
  });

  const metrics = useMemo(() => {
    const metrics: Metric[] = [];

    const websites = getSubscriptionResource(subscription, ResourceName.websites);
    const hasWebsites = subscriptionHasResource(subscription, ResourceName.websites);
    const isWebsitesUnlimited = isUnlimitedResource(websites);
    const hasMoreThanOneWebsite = isWebsitesUnlimited || (websites?.total && websites.total > 1);

    if (websites && hasWebsites && hasMoreThanOneWebsite) {
      metrics.push(
        constructUsage({
          label: formatMessage(messages.websites),
          tooltip: formatMessage({
            id: 'package_card.websites.tooltip',
            defaultMessage: 'The number of websites which may be created on this package.',
          }),
          resource: websites,
          unit: 'quantity',
        })
      );
    }

    const staging = getSubscriptionResource(subscription, ResourceName.stagingWebsites);
    const hasStaging = subscriptionHasResource(subscription, ResourceName.stagingWebsites);
    const isStagingUnlimited = isUnlimitedResource(staging);
    const hasMoreThanOneStaging = isStagingUnlimited || (staging?.total && staging.total > 1);

    if (staging && hasStaging && hasMoreThanOneStaging) {
      metrics.push(
        constructUsage({
          label: formatMessage(messages.staging_websites),
          tooltip: formatMessage({
            id: 'package_card.staging_websites.tooltip',
            defaultMessage: 'The number of staging websites which may be created on this package.',
          }),
          resource: staging,
          unit: 'quantity',
        })
      );
    }

    const diskspace = getSubscriptionResource(subscription, ResourceName.diskspace);
    const hasDiskspace = subscriptionHasResource(subscription, ResourceName.diskspace);

    if (diskspace && hasDiskspace) {
      metrics.push(
        constructUsage({
          label: formatMessage({ id: 'package_card.disk_space', defaultMessage: 'Disk space' }),
          tooltip: formatMessage({
            id: 'package_card.disk_space.tooltip',
            defaultMessage: 'The amount of storage space your website files can consume.',
          }),
          resource: diskspace,
          unit: 'bytes',
        })
      );
    }

    const transfer = getSubscriptionResource(subscription, ResourceName.transfer);
    const hasTransfer = subscriptionHasResource(subscription, ResourceName.transfer);

    if (transfer && hasTransfer && !isLoadingBandwidth) {
      metrics.push({
        ...constructUsage({
          label: formatMessage(messages.bandwidth),
          tooltip: formatMessage({
            id: 'package_card.bandwidth.tooltip',
            defaultMessage: 'The amount of data which can be transferred by your websites in a single calendar month.',
          }),
          resource: { ...transfer, usage: subscriptionBandwidth ?? 0 },
          unit: 'bytes',
        }),
      });
    }

    const mailboxes = getSubscriptionResource(subscription, ResourceName.mailboxes);
    const hasMailboxes = subscriptionHasResource(subscription, ResourceName.mailboxes);

    if (mailboxes && hasMailboxes) {
      metrics.push(
        constructUsage({
          label: formatMessage(messages.emails),
          tooltip: formatMessage({
            id: 'package_card.emails.tooltip',
            defaultMessage: 'The number of email addresses which may be created across all of your websites.',
          }),
          resource: mailboxes,
          unit: 'quantity',
        })
      );
    }

    const mysql = getSubscriptionResource(subscription, ResourceName.mysqlDbs);

    if (mysql && !isUnlimitedResource(mysql)) {
      metrics.push(
        constructUsage({
          label: formatMessage(messages.databases),
          tooltip: formatMessage(messages['package_card.databases.tooltip']),
          resource: mysql,
          unit: 'quantity',
        })
      );
    }

    return metrics;
  }, [subscription, subscriptionBandwidth, isLoadingBandwidth, formatMessage]);

  return (
    <CardCollapse
      noShadow
      startCollapsed={false}
      headerBackground="grey75"
      iconColor="light"
      marginBottom="0"
      header={
        <Text size="lg" color="light" ellipsis>
          <FormattedMessage
            id="simple_subscription_card.title"
            defaultMessage="{name} resource usage"
            values={{ name: subscription.friendlyName ?? subscription.planName }}
          />
        </Text>
      }
    >
      <UsageBars name={`${subscription.id}-usage`} metrics={metrics} />
    </CardCollapse>
  );
};
