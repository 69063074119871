import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { NavLink } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { Text } from 'src/components/Text';
import { FontColors, Responsive } from 'src/design-system/style-types';

import { Popover, Props as PopoverProps } from '../Popover/Popover';
import { style } from './DropdownMenu.style';

export type DropdownOption = {
  label: React.ReactNode;
  labelComponent?: React.JSX.Element;
  url?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  // only needed for active style if not a react-router link
  active?: boolean;
  disabled?: boolean;
  iconColor?: Responsive<FontColors>;
  dataDemoDisabled?: boolean;
};

export type MaybeOption = DropdownOption | null | undefined | false;

interface Props extends Omit<PopoverProps, 'children'> {
  limitHeight?: boolean;
  options: MaybeOption[];
  virtualize?: boolean;
  disabled?: boolean;
  minWidth?: number;
}

const DropdownMenu = ({
  disabled = false,
  limitHeight = true,
  options,
  virtualize = false,
  minWidth = 250,
  ...popoverProps
}: Props) => {
  const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
  const opts = options.filter(Boolean) as DropdownOption[];

  const renderVirtualItem = (item: VirtualItem, hide: () => void) => {
    return renderItem(opts[item.index], item.index, hide, item.start);
  };

  const renderItem = (option: DropdownOption, index: number, hide: () => void, rowStart?: number) => {
    const Label = () => {
      return (
        option.labelComponent ?? (
          <Box py="md" pl="lg" pr="2xl">
            <Text
              color={option?.iconColor}
              size="sm"
              whiteSpace="nowrap"
              css={{ color: option.disabled ? 'inherit' : undefined }}
              fullWidth
            >
              {option.label}
            </Text>
          </Box>
        )
      );
    };

    if (option.url) {
      return (
        <NavLink
          role="link"
          key={index}
          to={option.url || ''}
          css={style.dropdownOption({ disabled: !!option.disabled, rowStart })}
          data-qa="dropdownMenuLink"
          data-demo-disabled={option.dataDemoDisabled ? true : undefined}
          activeClassName="active"
          onClick={(e) => {
            e.stopPropagation();
            hide();
          }}
        >
          <Label />
        </NavLink>
      );
    }

    return (
      <button
        type="button"
        aria-disabled={option.disabled}
        data-demo-disabled={option.dataDemoDisabled ? true : undefined}
        key={index}
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (option.onClick) option.onClick(e);
          hide();
        }}
        css={style.dropdownOption({ disabled: !!option.disabled, rowStart })}
        data-qa="dropdownMenuButton"
        className={option.active ? 'active' : ''}
      >
        <Label />
      </button>
    );
  };

  const virtualizer = useVirtualizer({
    count: opts.length,
    getScrollElement: () => dropdownRef,
    estimateSize: () => 44,
    overscan: 4,
  });

  if (virtualize) {
    return (
      <Popover {...popoverProps} disabled={disabled}>
        {({ hide }) => (
          <div css={style.dropdownContainer({ limitHeight })} ref={(ref) => setDropdownRef(ref)}>
            <div css={style.dropdownInner({ height: virtualizer.getTotalSize(), minWidth })}>
              {virtualizer.getVirtualItems().map((item) => renderVirtualItem(item, hide))}
            </div>
          </div>
        )}
      </Popover>
    );
  }

  return (
    <Popover {...popoverProps} disabled={disabled}>
      {({ hide }) => (
        <div css={style.dropdownContainer({ limitHeight })}>
          {opts.map((option, key) => renderItem(option, key, hide))}
        </div>
      )}
    </Popover>
  );
};

export { DropdownMenu };
