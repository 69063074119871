import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { mysqlApi } from 'src/api_services/mysql/service';

import { createRequestStateSaga } from '../helpers';
import { mysqlActions } from './actions';

const [createWebsiteMySQLDBSaga] = createRequestStateSaga(
  mysqlActions.createWebsiteMySQLDB,
  mysqlApi.createWebsiteMySQLDB
);

const [createWebsiteMySQLUserSaga] = createRequestStateSaga(
  mysqlActions.createWebsiteMySQLUser,
  mysqlApi.createWebsiteMySQLUser
);

const [createWebsiteMySQLUserAccessHostsSaga] = createRequestStateSaga(
  mysqlActions.createWebsiteMySQLUserAccessHosts,
  mysqlApi.createWebsiteMySQLUserAccessHosts
);

const [setWebsiteMySQLUserPrivilegesSaga] = createRequestStateSaga(
  mysqlActions.setWebsiteMySQLUserPrivileges,
  mysqlApi.setWebsiteMySQLUserPrivileges
);

const [deleteWebsiteMySQLDBSaga] = createRequestStateSaga(
  mysqlActions.deleteWebsiteMySQLDB,
  mysqlApi.deleteWebsiteMySQLDB
);

const [deleteWebsiteMySQLUserSaga] = createRequestStateSaga(
  mysqlActions.deleteWebsiteMySQLUser,
  mysqlApi.deleteWebsiteMySQLUser
);

const [deleteWebsiteMySQLUserAccessHostsSaga] = createRequestStateSaga(
  mysqlActions.deleteWebsiteMySQLUserAccessHosts,
  mysqlApi.deleteWebsiteMySQLUserAccessHosts
);

const [getWebsiteMySQLUsersSaga] = createRequestStateSaga(
  mysqlActions.getWebsiteMySQLUsers,
  mysqlApi.getWebsiteMySQLUsers
);

const [updateWebsiteMySQLUserSaga] = createRequestStateSaga(
  mysqlActions.updateWebsiteMySQLUser,
  mysqlApi.updateWebsiteMySQLUser
);

export function* mysqlSaga() {
  yield takeEvery(getType(mysqlActions.createWebsiteMySQLDB.request), createWebsiteMySQLDBSaga);
  yield takeEvery(getType(mysqlActions.createWebsiteMySQLUser.request), createWebsiteMySQLUserSaga);
  yield takeEvery(
    getType(mysqlActions.createWebsiteMySQLUserAccessHosts.request),
    createWebsiteMySQLUserAccessHostsSaga
  );
  yield takeEvery(getType(mysqlActions.setWebsiteMySQLUserPrivileges.request), setWebsiteMySQLUserPrivilegesSaga);
  yield takeEvery(getType(mysqlActions.deleteWebsiteMySQLDB.request), deleteWebsiteMySQLDBSaga);
  yield takeEvery(getType(mysqlActions.deleteWebsiteMySQLUser.request), deleteWebsiteMySQLUserSaga);
  yield takeEvery(
    getType(mysqlActions.deleteWebsiteMySQLUserAccessHosts.request),
    deleteWebsiteMySQLUserAccessHostsSaga
  );

  yield takeEvery(getType(mysqlActions.getWebsiteMySQLUsers.request), getWebsiteMySQLUsersSaga);
  yield takeEvery(getType(mysqlActions.updateWebsiteMySQLUser.request), updateWebsiteMySQLUserSaga);
}
