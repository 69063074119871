import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import { loginsActions } from './actions';

export const avatarCacheInvalidateReducer = createReducer(new Date().getTime(), (handleAction) => [
  handleAction(loginsActions.setAvatarCacheInvalidate, (_, { payload }) => payload),
]);

export const loginsReducer = combineReducers({
  avatarCacheInvalidate: avatarCacheInvalidateReducer,
  deleteAvatar: createRequestStateReducer(loginsActions.deleteAvatar),
  login: createRequestStateReducer(loginsActions.getLogin),
  logins: createRequestStateReducer(loginsActions.getLogins),
  loginMemberships: createRequestStateReducer(loginsActions.getLoginMemberships),
  passwordRecovery: createRequestStateReducer(loginsActions.validatePasswordRecovery),
  sessions: createRequestStateReducer(loginsActions.getSessions),
  updateLoginInfo: createRequestStateReducer(loginsActions.updateLoginInfo),
});
