/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';

import { modalSelectors } from 'src/store/modals';

export const ModalProvider = () => {
  const { Component, props } = useSelector(modalSelectors.activeModal);

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
};
