/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { style } from 'src/components/Overlay/Overlay.style';
import { Colors } from 'src/design-system/style-types';
import { zIndex } from 'src/utils/zIndex';

export interface DefaultProps {
  disabled: boolean;
  show: 'always' | 'hover';
  alpha: number;
  backgroundColor: Colors;
  zIndex: zIndex;
  children?: React.ReactNode;
  unclickable: boolean;
}

export type Props = DefaultProps;

export const defaultProps: DefaultProps = {
  disabled: false,
  show: 'hover',
  backgroundColor: 'transparent',
  alpha: 1,
  zIndex: zIndex.DefaultOverlay,
  unclickable: false,
};

export const Overlay = (props: Props) => {
  const { disabled, children } = props;
  return !disabled ? <div css={style.container(props)}>{children}</div> : null;
};

Overlay.defaultProps = defaultProps;
