import { css } from '@emotion/core';

import { fontSize, margin, padding, shadowBorderBottom } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';

import type { Props } from './Search';

const expandedStyles = (theme: Theme) => css`
  width: 100%;
  ${padding({ left: 'sm', right: '2xl' })(theme)}
  opacity: 1;
`;

const container =
  ({ marginLeft, marginBottom, maxWidth }: Props) =>
  (theme: Theme) =>
    css`
      ${margin({ bottom: marginBottom, left: marginLeft })(theme)}
      ${maxWidth && `max-width: ${maxWidth}px`};
      position: relative;
      width: 100%;

      input[aria-disabled='true'] {
        pointer-events: none;
        opacity: 0.5;
      }
    `;

const field = (props: Props) => (theme: Theme) =>
  css`
    ${fontSize(props.fontSize)(theme)}
    ${shadowBorderBottom('dark')(theme)}
  font-family: ${theme.fontFamily};
    border: none;
    color: ${theme.fontColors.dark};
    background-color: transparent;
    height: 40px;
    line-height: 40px;
    outline: none;
    width: 0;
    opacity: 0;

    ::placeholder {
      color: ${theme.fontColors.grey};
    }

    :disabled {
      box-shadow: none;
      color: ${theme.fontColors.disabled};
    }

    :focus {
      ${expandedStyles(theme)}
    }

    ${(props.value || props.alwaysExpanded) && expandedStyles(theme)}
  `;

const icon =
  ({ disabled }: { disabled: boolean }) =>
  (theme: Theme) =>
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;

      ${!theme.settings.rtl &&
      css`
        right: ${theme.margins.sm}px;
      `}

      ${theme.settings.rtl &&
      css`
        left: ${theme.margins.sm}px;
      `}

  ${disabled &&
      `
    pointer-events: none;
    opacity: 0.5;
  `}
    `;

export const style = { container, icon, field };
