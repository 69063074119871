/** @jsxRuntime classic */
import { useMemo } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Card, CardBody, CardFooter } from 'src/components/Card';
import { Modal } from 'src/components/Modal';
import { StatusIcon } from 'src/components/Status';
import { H1, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { AppState } from 'src/store';

import { fileManagerActions, fileManagerSelectors } from '../_redux';
import { OverwriteStatus } from '../constants';
import { getEntryName } from '../helpers';
import { style } from './OverwritesModal.style';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

interface Props extends ActionProps, StateProps {
  //
}

export const stateToProps = (state: AppState) => ({
  overwrites: fileManagerSelectors.overwrites(state),
});

export const dispatchToProps = {
  setOverwrite: fileManagerActions.setOverwrite,
};

export const _OverwritesModal = ({ overwrites, setOverwrite }: Props) => {
  // Map overwrites objects to an array of path and name.
  const files = useMemo(() => {
    return Object.keys(overwrites)
      .filter((key) => overwrites[key] === OverwriteStatus.Staged)
      .map((key) => ({ name: getEntryName(key), path: key }));
  }, [overwrites]);

  const overwriteAll = () => {
    files.forEach(({ path }) => {
      setOverwrite({ path, status: OverwriteStatus.Overwrite });
    });
  };

  if (files.length === 0) {
    return null;
  }

  return (
    <Modal>
      {({ hide }) => (
        <Card css={style.card}>
          <CardBody padding="xl">
            <Box mb="xl" wrap="nowrap" a="center">
              <StatusIcon marginRight="md" type="warning" />

              <H1 size="xl" weight="light" fullWidth ellipsis>
                <FormattedMessage id="website.files.overwrite.modal.title" defaultMessage="File already exists" />
              </H1>
            </Box>

            <Text marginBottom="md" size="sm">
              {files.length === 1 ? (
                <FormattedMessage
                  id="website.files.overwrite.modal.body"
                  defaultMessage="''{name}'' already exists. This will overwrite the contents of the existing file."
                  values={{ name: files[0].name }}
                />
              ) : (
                <FormattedMessage
                  id="website.files.overwrite.modal.body.multiple"
                  defaultMessage="''{name}'' and {count, plural, one {# other file} other {# other files}} already exist. This will overwrite the contents of the existing files."
                  values={{ name: files[0].name, count: files.length - 1 }}
                />
              )}
            </Text>
          </CardBody>

          <CardFooter>
            <Box a="center" px="lg" j="flex-end">
              <Button variant="tertiary" size="small" marginRight="2xl" onClick={hide}>
                <FormattedMessage {...messages.cancel} />
              </Button>

              <Button
                variant="primary"
                size="small"
                onClick={() => {
                  overwriteAll();
                  hide();
                }}
              >
                {files.length === 1 ? (
                  <FormattedMessage id="website.files.overwrite.modal.overwrite" defaultMessage="Overwrite" />
                ) : (
                  <FormattedMessage id="website.files.overwrite.modal.overwrite_all" defaultMessage="Overwrite all" />
                )}
              </Button>
            </Box>
          </CardFooter>
        </Card>
      )}
    </Modal>
  );
};

export const OverwritesModal = connect(stateToProps, dispatchToProps)(_OverwritesModal);
