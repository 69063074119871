import { css } from '@emotion/core';

import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './BackHeading';

const container =
  ({ fullWidth = false, marginBottom = 'xl', marginRight, minWidth }: Props) =>
  (theme: Theme) =>
    css`
      cursor: pointer;
      flex: 1;

      ${marginBottom &&
      responsive(
        marginBottom,
        (marginBottom) => css`
          margin-bottom: ${theme.margins[marginBottom]}px;
        `
      )}

      ${marginRight &&
      css`
        margin-right: ${theme.margins[marginRight]}px;
      `}

      ${fullWidth &&
      css`
        width: 100%;
      `}

      ${minWidth &&
      responsive(
        minWidth,
        (minWidth) => css`
          min-width: ${minWidth};
        `
      )}
    `;

const bottomContent = (theme: Theme) => css`
  margin-top: ${theme.margins.xs}px;

  ${!theme.settings.rtl &&
  css`
    margin-left: 30px;
  `}

  ${theme.settings.rtl &&
  css`
    margin-right: 30px;
  `}
`;

export const style = { container, bottomContent };
