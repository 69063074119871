import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Action, ActionCreator, ActionType, createActionCreator, createReducer, getType } from 'deox';
import { Middleware } from 'redux';
import { call, put, race, take } from 'redux-saga/effects';

import { SelectOption } from 'src/components/Select/Select.types';
import { getErrorInfo, isError } from 'src/utils/errors';
import { ResolvedPayload } from 'src/utils/types';

export type ApiFn = (arg: any, options?: any) => Promise<any>;

export type ResolvedDataPayload<T extends ApiFn> = ResolvedPayload<T>['data'];
export type ParamsWithOptions<T extends ApiFn> = Parameters<T>[0] & { options?: AxiosRequestConfig };

export type ParamsAndData<T extends ApiFn> = {
  params: ParamsWithOptions<T>;
  data: ResolvedPayload<T>['data'];
};

/**
 * createActionsFromMethod returns a set of actions for handling the request, success, and error stages of any network request which conforms to the ApiFn type.
 */
export function createActionsFromMethod<T extends ApiFn, K extends string = string>(type: K) {
  return createRequestStateActions<ParamsAndData<T>>(type);
}

export interface RequestActions<T extends Record<string, any> = any, K extends Record<string, any> = any> {
  request: ((payload: T) => Action<string, T>) & { type: string };
  success: ((payload: K) => Action<string, K>) & { type: string };
  error: ActionCreator<Action<string, { error: string }>>;
  reset: ActionCreator<Action<string>>;
}

export interface Data<T> {
  data: T;
  meta?: any;
}

export type ItemType<T> = T extends RequestActions<any, infer K> ? (K extends Data<infer U> ? U : undefined) : never;

export interface FetchingState<T> {
  loading: boolean;
  error: string;
  data: T;
}

const defaultInitialState = {
  loading: false,
  error: '',
  data: undefined,
};

/**
 * Creates a reducer which handles basic data fetching logic for the provided
 * request actions
 */
export function createRequestStateReducer<T extends RequestActions, K extends FetchingState<ItemType<T>> = any>(
  actions: T,
  initialState?: K
) {
  const finalInitialState = { ...defaultInitialState, ...initialState };
  const { data } = finalInitialState;
  return createReducer(
    finalInitialState as any as K extends FetchingState<ItemType<T>>
      ? FetchingState<ItemType<T>>
      : FetchingState<ItemType<T> | undefined>,
    (handleAction) => [
      handleAction(actions.request, (state) => ({
        ...state,
        loading: true,
      })),
      handleAction(actions.success, (state, action) => {
        const { payload }: { payload: { data: ItemType<T> } } = action as any;
        return {
          ...state,
          loading: false,
          error: '',
          data: payload && payload.data ? payload.data : data,
        };
      }),
      handleAction(actions.error, (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload.error,
      })),
      handleAction(
        actions.reset,
        () =>
          finalInitialState as any as K extends FetchingState<ItemType<T>>
            ? FetchingState<ItemType<T>>
            : FetchingState<ItemType<T> | undefined>
      ),
    ]
  );
}

export interface RequestActionPayload<T> {
  params: T;
  onSuccess?: (data?: any) => void;
  onError?: (e?: Error) => void;
  onSettled?: () => void;
}

export type SuccessActionPayload<T> = Action<string, Data<T>>;

export type Parameter<T extends (arg: Record<string, any>) => any> = T extends (arg: infer U) => any ? U : never;

const isTest = process.env.NODE_ENV === 'test';

/**
 * Returns a tuple of sagas
 *  the first handles data fetching, the second replays
 *  the previous invocation of the first saga
 */
export const createRequestStateSaga = <
  T extends ApiFn,
  K extends RequestActions<RequestActionPayload<ParamsWithOptions<T>>, Data<ResolvedPayload<T>['data']>>
>(
  actions: K,
  apiFn: T
) => {
  let prevParams: Parameter<T> | null = null;

  function* repeat() {
    if (prevParams === null) {
      return;
    }
    yield put(actions.request({ params: prevParams }));
  }

  function* requestStateSaga(actionType: ActionType<K['request']>) {
    const { payload } = actionType;

    if (payload?.params) {
      prevParams = payload.params;
    }

    try {
      const { options, ...params } = payload?.params ?? ({} as any);
      const res: ResolvedPayload<T> = yield call(apiFn as ApiFn, params, options);

      yield put(actions.success({ data: res.data, meta: { statusCode: res.status } }));

      if (payload?.onSuccess) {
        payload.onSuccess(res.data);
      }
    } catch (e) {
      try {
        const { message, code, status, axiosCode } = getErrorInfo(e);

        yield put(actions.error({ error: message || code || axiosCode, meta: { statusCode: status } }));

        if (!isTest && payload?.onError) {
          const usedError = isError(e) ? e : undefined;
          payload.onError(usedError);
        }
      } catch (e) {
        if (isTest) {
          return;
        }
        // eslint-disable-next-line no-console
        console.warn(`Error handling error in 'createRequestStateSaga'`, e);
      }
    } finally {
      payload?.onSettled?.();
    }
  }
  return [requestStateSaga, repeat];
};

/**
 * Creates an object with `request`, `success` and `error` action creators
 * based on the provided generic type arguments
 *
 * - `params` is equivalent to the argument used to call the API layer function
 * - `data` is equivalent to the data payload returned in the response of the API layer function
 *
 */
export function createRequestStateActions<
  T extends {
    params?: any;
    data?: any;
  }
>(actionType: string) {
  return {
    request: createActionCreator(
      `⏱ ${actionType}`,
      (resolve) =>
        (
          payload: T extends RequestActionPayload<infer U> ? RequestActionPayload<U> : RequestActionPayload<undefined>
        ) =>
          resolve(payload)
    ),
    success: createActionCreator(
      `✅ ${actionType}`,
      (resolve) => (payload: T extends Data<infer K> ? { data: K; meta?: any } : void) => resolve(payload)
    ),
    error: createActionCreator(
      `❌ ${actionType}`,
      (resolve) => (payload: { error: string; meta?: any }) => resolve(payload)
    ),
    reset: createActionCreator(`⏮ ${actionType}`),
  };
}

export type RequestAction<T> = RequestActions<RequestActionPayload<T>>['request'];

export type PromisifiedRequestActionCreator<T> = (
  params: T extends RequestAction<infer U> ? RequestActionPayload<U>['params'] : never
) => ReturnType<ReturnType<typeof promisifyRequestAction>>;

export type PromisifiedActionCreator<T> = (
  params: T extends (...args: any[]) => void ? Omit<Parameters<T>[0], keyof ActionCallbacks> : never
) => Promise<void>;

/**
 * Promisify a request action creator
 * `onSuccess` & `onError` become `.then` and `.catch`
 * NOTE: only to be used with request actions created with createActionsFromMethod
 */
export function promisifyRequestAction<T extends ApiFn>(requestActionCreator: RequestAction<ParamsWithOptions<T>>) {
  const promisified = promisifyAction(requestActionCreator);
  return (params: ParamsWithOptions<T>): Promise<ResolvedDataPayload<T>> => promisified({ params });
}

export interface ActionCallbacks {
  onSuccess?: (data?: any) => void;
  onError?: (e?: any) => void;
}
interface PromiseWithAction<T> extends Promise<void> {
  action: T;
}

/**
 * Promisify any action which accepts `onSuccess` and `onError` callback functions
 * `onSuccess` & `onError` become `.then` and `.catch`
 *
 * A promise is returned with a `.action` property which contains the plain object action.
 */
export function promisifyAction<T extends ActionCallbacks, K>(actionCreator: (params: T) => K) {
  return (params: Omit<T, keyof ActionCallbacks>): PromiseWithAction<K> => {
    let action: any = null;
    const p = new Promise((resolve, reject) => {
      action = actionCreator({
        ...(params as any),
        onSuccess: resolve,
        onError: reject,
      });
    });
    (p as PromiseWithAction<K>).action = action;
    return p as PromiseWithAction<K>;
  };
}

/**
 * Redux middleware which allows promises to be passed to dispatch, providing the plain
 * object action is present as the `.action` property. The plain object action is then passed to
 * the reducer and any further middlewares. It's important to attach this middleware before other
 * middlewares which rely on a plain object action, for example redux-saga's middleware.
 */
export const promiseWithActionMiddleware: Middleware = (store) => (next) => (action) => {
  if (action instanceof Promise) {
    const requestActionPromise = action as PromiseWithAction<any>;
    if (requestActionPromise.action) {
      store.dispatch(requestActionPromise.action);
      return requestActionPromise;
    }
  }
  return next(action);
};

export function mockAxiosResponse<T>(data: T): AxiosResponse<T> {
  return {
    data,
    status: 200,
    statusText: 'Mock Status Text',
    headers: {},
    config: {},
  };
}

export function mockAxiosPromise<T>(data: T, timeout = 2000): AxiosPromise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(mockAxiosResponse<T>(data)), timeout));
}

export const createSelectOption = ({ value, label }: { value: string; label: string }): SelectOption[] => [
  { value, label },
];

export const getSelectOptionValue = (selectOption: SelectOption[]) =>
  Array.isArray(selectOption) && selectOption[0] && typeof selectOption[0].value === 'string'
    ? selectOption[0].value
    : '';

export const getSelectOptionLabel = (selectOption: SelectOption[]) =>
  Array.isArray(selectOption) && selectOption[0] && typeof selectOption[0].label === 'string'
    ? selectOption[0].label
    : '';

/**
 * Wrapper around redux-saga's `race` to simplify request action control flow in sagas. Can be used instead of
 * direct API calls to keep all data fetching tied to redux actions. This ensures that any subsequent action-triggered
 * saga refreshing, reducer logic, etc are fired as expected.
 *
 * Returns the success action or throws an error with the message from the `.error` action
 *
 * Dispatches the `.request` action and waits for the next `.success` or `.error` of the same type. There is no
 * guarantee that the resolved `.success` or `.error` action will correspond to the exact instance of the
 * `.request` action you dispatch.
 *
 */
export function* requestActionSaga<T extends RequestActions<RequestActionPayload<any>, Data<any>>>(
  actions: T,
  params: Parameters<T['request']>[0]['params']
) {
  yield put(actions.request({ params }));

  const { success, error }: { success: ReturnType<T['success']>; error: ReturnType<T['error']> } = yield race({
    success: take(getType(actions.success)),
    error: take(getType(actions.error)),
  });

  if (error) {
    throw new Error(error.payload.error);
  }

  return success;
}
