import { memo } from 'react';
import { WebsiteKind } from 'src/orchd-client';
import { useIntl } from 'react-intl';

import { SquarePill } from 'src/components/SquarePill/SquarePill';

interface Props {
  domain: string;
  kind: WebsiteKind;
  disabled?: boolean;
  showTooltip?: boolean;
}

export const KindPillMap = memo(({ domain, kind, disabled = false, showTooltip = true }: Props) => {
  const { formatMessage } = useIntl();

  if (kind === WebsiteKind.controlPanel) {
    return (
      <SquarePill
        mr="xs"
        text="CTL"
        tooltipText={formatMessage({
          id: 'websites.control_panel.tooltip',
          defaultMessage: 'This is a Control Panel service website.',
        })}
        color="purple"
        disabled={disabled}
        showTooltip={showTooltip}
      />
    );
  }

  if (kind === WebsiteKind.phpMyAdmin) {
    return (
      <SquarePill
        mr="xs"
        text="PMA"
        tooltipText={formatMessage({
          id: 'websites.phpMyAdmin.tooltip',
          defaultMessage: 'This is a phpMyAdmin service website.',
        })}
        color="purple"
        disabled={disabled}
        showTooltip={showTooltip}
      />
    );
  }

  if (kind === WebsiteKind.roundcube) {
    return (
      <SquarePill
        mr="xs"
        text="WML"
        tooltipText={formatMessage({
          id: 'websites.roundcube.tooltip',
          defaultMessage: 'This is a Webmail service website.',
        })}
        color="purple"
        disabled={disabled}
        showTooltip={showTooltip}
      />
    );
  }

  if (kind === WebsiteKind.staging) {
    return (
      <SquarePill
        mr="xs"
        text="STG"
        tooltipText={formatMessage({
          id: 'websites.staging.tooltip',
          defaultMessage: 'This is a Staging website.',
        })}
        color="blue"
        disabled={disabled}
        showTooltip={showTooltip}
      />
    );
  }

  if (kind === WebsiteKind.serverHostname) {
    return (
      <SquarePill
        mr="xs"
        text="SHN"
        tooltipText={formatMessage({
          id: 'websites.smart_hostname.tooltip',
          defaultMessage: 'This is a server domain.',
        })}
        color="purple"
        disabled={disabled}
        showTooltip={showTooltip}
      />
    );
  }

  return null;
});
