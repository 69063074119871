import { Fragment } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import { CPLocale } from 'src/orchd-client';

import { SelectField } from 'src/components/Select';
import { SelectOnChange } from 'src/components/Select/Select.types';
import theme from 'src/design-system/theme';
import { supportedLocales } from 'src/store/intl/locales';
import { DEFAULT_LOCALE } from 'src/utils/constants';

export interface FontConfig {
  label: string;
  fontFamily: string;
}

export type LocaleFonts = Record<CPLocale, FontConfig[]>;

export const getEmptyFontsByLocaleConfig = (): LocaleFonts =>
  supportedLocales.reduce(
    (acc, locale) => ({
      ...acc,
      [locale]: [],
    }),
    {} as LocaleFonts
  );

const supportedFonts: FontConfig[] = [
  {
    label: 'Noto',
    fontFamily: theme.fontFamily,
  },
  {
    label: 'Roboto',
    fontFamily: `'Roboto', sans-serif`,
  },
  {
    label: 'Open Sans',
    fontFamily: `'Open Sans', sans-serif`,
  },
  { label: 'Lato', fontFamily: `'Lato', sans-serif` },
  { label: 'Source Sans Pro', fontFamily: `'Source Sans Pro', sans-serif` },
  { label: 'Vazirmatn', fontFamily: `'Vazirmatn', sans-serif` },
];

// TODO this needs sorting?
export const fontsByLocale: LocaleFonts = {
  ...getEmptyFontsByLocaleConfig(),
  'en-US': supportedFonts,
  'en-GB': supportedFonts,
  en: supportedFonts,
};

interface RequiredProps {
  name: string;
  label: string;
  err?: string;
  touched?: boolean;
  value: string;
  locale?: CPLocale;
  onChange: (fontFamily: string) => void;
}

interface DefaultProps {}

const familyTermsRe = new RegExp(/(\w+ ?)+/);
export const getUrlForFont = ({
  fontFamily,
  weights = [100, 300, 400, 500, 700, 900],
}: {
  fontFamily: string;
  weights?: number[];
}) => {
  const sortedWeights = weights.sort();
  const weightsParam = [
    ...sortedWeights.map((weight) => `0,${weight}`),
    ...sortedWeights.map((weight) => `1,${weight}`),
  ].join(';');
  const res = familyTermsRe.exec(fontFamily);
  if (!res) {
    return '';
  }
  const familyParam = res[0].replace(/ /g, '+');
  return `https://fonts.googleapis.com/css2?family=${familyParam}:ital,wght@${weightsParam}&display=swap`;
};

export type Props = RequiredProps & DefaultProps;
export const FontPreview = ({ label, fontFamily }: FontConfig) => {
  return (
    <span
      css={css`
        font-family: ${fontFamily};
      `}
    >
      {label}
    </span>
  );
};

export const FontPicker = ({ name, label, err, touched, value, onChange, locale = DEFAULT_LOCALE }: Props) => {
  const fonts = fontsByLocale[locale];
  const fontOptions = fonts.map(({ label, fontFamily }) => ({
    label: <FontPreview label={label} fontFamily={fontFamily} />,
    value: fontFamily,
  }));
  const currentFont = fontOptions.find((font) => font.value === value) || fontOptions[0];
  const handleChange: SelectOnChange = ({ value }) => {
    onChange(value[0].value as string);
  };

  return (
    <Fragment>
      {fonts.map(({ fontFamily }) => (
        <Global key={fontFamily} styles={`@import url('${getUrlForFont({ fontFamily })}');`} />
      ))}
      <SelectField
        options={fontOptions}
        name={name}
        err={err}
        touched={touched}
        onChange={handleChange}
        value={currentFont}
        label={label}
      />
    </Fragment>
  );
};
