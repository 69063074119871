import { UploadMode } from '../constants';

export interface MapEntriesRequest {
  websiteId: string;
  paths: string[];
  action: 'compress' | 'uncompress' | 'rename' | 'copy';
  destination?: string;
  overwrite?: boolean;
  names?: string[];
  format?: 'gz' | 'tar' | 'tar.gz' | 'zip';
}

export interface CreateEntriesRequest {
  websiteId: string;
  files?: File[];
  folders?: string[];
  path: string;
  overwrite?: boolean;
  uncompress?: boolean;
  mode?: UploadMode;
}

export interface DowloadEntriesRequest {
  websiteId: string;
  paths: string[];
}

export enum UploadStatus {
  Started,
  Uploading,
  Success,
  Error,
}

export interface UploadedFile {
  file: File;
  status: UploadStatus;
  current?: number;
  total?: number;
}

export enum DownloadStatus {
  Pending,
  Downloading,
  Success,
  Error,
}
export interface DownloadedFile {
  name: string;
  status: DownloadStatus;
  current?: number;
  total?: number;
  data?: any;
}
