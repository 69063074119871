import { ReactNode } from 'react';
import { UsedResource } from 'src/orchd-client';

import { isUnlimitedResource } from 'src/utils/packages/utils';

export const constructUsage = ({
  label,
  resource,
  tooltip,
  unit,
  barTooltip,
}: {
  label: string;
  tooltip: string;
  resource: UsedResource;
  unit: 'quantity' | 'bytes';
  barTooltip?: ReactNode;
}) => {
  const { usage } = resource;
  const infinite = isUnlimitedResource(resource);

  return {
    label,
    tooltip,
    usage,
    total: resource.total || 0,
    infinite,
    unit,
    zeroUnit: unit === 'bytes' ? 'GB' : '',
    singleBar:
      usage === 0
        ? undefined
        : {
            amount: usage,
          },
    barTooltip,
  };
};
