import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'h3',
  weight: 'light',
  size: 'xl',
};

/**
 * # Text H3
 * Use H3 to present the main headline
 */
const H3 = (props: Props) => <Text {...props} />;

H3.defaultProps = updatedDefaultProps;

export default H3;
