import { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IntlShape } from 'react-intl';

import { Copy } from 'src/components/Copy/Copy';

import { style } from './HighlightOverlay.style';

interface Props {
  id: string;
  defaultMessage: string;
  formatter: IntlShape;
  values: Record<string, string>;
}

export const HighlightOverlay = ({ id, defaultMessage, values, formatter }: Props) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const [ref, setRef] = useState<null | HTMLSpanElement>(null);

  return (
    <span ref={setRef} onMouseEnter={() => setPopperOpen(true)} onMouseLeave={() => setPopperOpen(false)}>
      {ref && (
        <span css={style.highlightOutline(ref)}>
          {popperOpen && (
            <div css={style.highlightId}>
              <Copy label={id} value={id} iconColor="dark" />
            </div>
          )}
        </span>
      )}

      {formatter.formatMessage({ id, defaultMessage }, values)}
    </span>
  );
};
