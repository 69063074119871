import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { connect } from 'react-redux';

import { useGetUiPreferencesQuery } from 'src/api_services/logins/query';
import { useGetSubscriptionsQuery } from 'src/api_services/subscriptions/query';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import defaultTheme from 'src/design-system/theme';
import { AppState } from 'src/store';
import { globalUiSelectors } from 'src/store/globalUi/selectors';
import { promisifyRequestAction } from 'src/store/helpers';
import { impersonationSelectors } from 'src/store/impersonation';
import { sessionActions } from 'src/store/session/actions';
import { sessionSelectors } from 'src/store/session/selectors';
import { getErrorInfo } from 'src/utils/errors';

type StateProps = ReturnType<typeof stateToProps>;
type ActionProps = typeof dispatchToProps;

export interface Props extends ActionProps, StateProps {
  children: ReactNode[] | ReactNode;
}

const stateToProps = (state: AppState) => ({
  isLoggedIn: sessionSelectors.getIsLoggedIn(state),
  isLoggingOut: sessionSelectors.getIsLoggingOut(state),
  member: sessionSelectors.getMemberOrUndefined(state),
  impersonationMember: impersonationSelectors.getImpersonationMember(state),
  darkMode: globalUiSelectors.darkMode(state),
});

const dispatchToProps = {
  findActiveMember: promisifyRequestAction(sessionActions.findActiveMember.request),
  logout: sessionActions.logout,
};

export const _AuthProvider = ({
  children,
  isLoggedIn,
  isLoggingOut,
  member,
  impersonationMember,
  findActiveMember,
  logout,
  darkMode,
}: Props) => {
  const [setupComplete, setSetupComplete] = useState(false);
  const orgId = impersonationMember?.orgId ?? member?.orgId;

  // Pre-fetch subscriptions and preferences.
  useGetSubscriptionsQuery({
    orgId: orgId ?? '',
    enabled: !!orgId,
  });

  useGetUiPreferencesQuery();

  useEffect(() => {
    fetchDefaultMember();
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDefaultMember = async () => {
    setSetupComplete(false);

    try {
      await findActiveMember({});

      // A single user can have multiple memberships but which one is active is controlled by the frontend. If there is
      // none selected, we find the membership with the highest access level as default.
    } catch (e) {
      const { message } = getErrorInfo(e);

      // Do not trigger if a request is client-side cancelled.
      if (message === 'ECONNABORTED') {
        return;
      }

      // Do not trigger logout on certain pages.
      if (['/setup', '/invites', '/login'].some((uri) => window.location.pathname.startsWith(uri))) {
        return;
      }

      // If anything fails in the initial auth setup, redirect the user to login.
      logout();
    } finally {
      setSetupComplete(true);
    }
  };

  if ((!member && isLoggedIn) || !setupComplete || isLoggingOut) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <LoadingOverlay alpha={0} darkMode={darkMode} />
      </ThemeProvider>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export const AuthProvider = connect(stateToProps, dispatchToProps)(_AuthProvider);
