import { memo, useEffect } from 'react';
import { MigrationSessionDetails, MigrationStatus } from 'src/orchd-client';
import { atom, useAtom } from 'jotai';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { useGetMigrationSessionsQuery } from 'src/api_services/migrations/query';
import { migrationsApi } from 'src/api_services/migrations/service';
import { Span, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { loginsSelectors } from 'src/store/logins/selectors';
import { sessionSelectors } from 'src/store/session/selectors';
import { useToast } from 'src/utils/toast/useToast';

const moveServerToastId = 'move-server';

export const migrationsInProgressAtom = atom<{ [sessionId: string]: boolean }>({});

type StateProps = ReturnType<typeof stateToProps>;

export const _MoveServerToast = ({ login, member }: StateProps) => {
  const [migrationsInProgress, setMigrationsInProgress] = useAtom(migrationsInProgressAtom);
  const toast = useToast();
  const autosaveToast = toast.autosave({ id: moveServerToastId });

  const { data: [session] = [], isLoading } = useGetMigrationSessionsQuery({
    createdBy: login?.email,
    limit: 1,
    enabled: !!member?.isMasterOrg,
    refetchInterval: (data?: MigrationSessionDetails[]) => {
      if (!!data?.[0]?.notReadyCount) {
        return 3000;
      }

      return false;
    },
  });

  const isMigrating = session && session.notReadyCount > 0;
  const migrationInProgress =
    session &&
    (isMigrating ||
      (typeof migrationsInProgress[session.id] === 'boolean' && migrationsInProgress[session.id] === true));

  useEffect(() => {
    // If there are migrations in progress, show the toast and also change the
    // atom value in case this was called after a hard refresh.
    if (isMigrating) {
      setMigrationsInProgress((prev) => ({ ...prev, [session.id]: true }));

      return void autosaveToast.neutral(
        <Text color="light" size="sm">
          <FormattedMessage
            id="migrations.progress_new"
            defaultMessage="Website migrations in progress - {remaining} remaining"
            values={{ remaining: session?.notReadyCount }}
          />
          &nbsp;&middot;&nbsp;
          <Link to="/migrations">
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_progress} />
            </Span>
          </Link>
        </Text>
      );
    }

    // If a migration was just initialized and we haven't fetched the migration
    // progress yet, just show a generic toast with no counts.
    if (migrationsInProgress?.[session?.id] && isLoading) {
      return void autosaveToast.neutral(
        <Text color="light" size="sm">
          <FormattedMessage id="migrations.progress_plain" defaultMessage="Website migrations in progress" />
          &nbsp;&middot;&nbsp;
          <Link to="/migrations">
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_progress} />
            </Span>
          </Link>
        </Text>
      );
    }

    // Show error/success toast if there is a migration in progress but they are
    // all finished. Change the atom value.
    if (!migrationInProgress) {
      return;
    }

    setMigrationsInProgress((prev) => ({ ...prev, [session.id]: false }));

    migrationsApi
      .getMigrations({
        sessionId: session.id,
        limit: 0,
        migrationStatus: MigrationStatus.failed,
      })
      .then((data) => {
        const failedCount = data.data.total;

        if (failedCount) {
          autosaveToast.error(
            <Text color="light" size="sm">
              <FormattedMessage
                id="migrations.progress.partial_error"
                defaultMessage="Website migrations failed - {failed} failed · {completed} completed"
                values={{ failed: failedCount, completed: session.migrationsCount - failedCount }}
              />
              &nbsp;&middot;&nbsp;
              <Link to="/migrations">
                <Span color="light" size="sm" textDecoration="underline">
                  <FormattedMessage {...messages.check_status} />
                </Span>
              </Link>
            </Text>
          );
        } else {
          autosaveToast.success(
            <Text color="light" size="sm">
              <FormattedMessage id="migrations.progress.success" defaultMessage="Website migrations completed" />
              &nbsp;&middot;&nbsp;
              <Link to="/migrations">
                <Span color="light" size="sm" textDecoration="underline">
                  <FormattedMessage {...messages.check_status} />
                </Span>
              </Link>
            </Text>
          );
        }
      })
      .catch((err) => {
        // do nothing
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, migrationInProgress]);

  return null;
};

const stateToProps = (state: AppState) => ({
  login: loginsSelectors.selectLogin(state),
  member: sessionSelectors.getMemberOrUndefined(state),
});

export const MoveServerToast = connect(stateToProps)(memo(_MoveServerToast));
