import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';

const dropdownContainer =
  ({ limitHeight }: { limitHeight: boolean }) =>
  () =>
    css`
      overflow-y: auto;

      ${limitHeight &&
      `
        max-height: 320px;
      `};
    `;

const dropdownInner = ({ height, minWidth }: { height: number; minWidth: number }) => css`
  position: relative;
  overflow: hidden;
  min-width: ${minWidth}px;
  height: ${height}px;
`;

const dropdownOption =
  ({ disabled, rowStart }: { disabled?: boolean; rowStart?: number }) =>
  (theme: Theme) =>
    css`
      display: block;
      padding: 0;
      cursor: pointer;
      min-width: 128px;
      width: 100%;
      color: ${theme.fontColors.dark};

      &:focus-visible {
        background: ${theme.colors.grey3};
        outline: blue auto 1px;
      }

      &:hover {
        background: ${theme.colors.grey3};
      }
      &.active {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
      }
      ${disabled &&
      `
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
      `}
      ${rowStart &&
      `
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(${rowStart}px);
      `}
    `;

export const style = { dropdownContainer, dropdownInner, dropdownOption };
