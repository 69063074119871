import { css } from '@emotion/core';

import { left, margin } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';

import type { AlertVariant } from './AlertModal';

const card =
  ({ variant }: { variant: AlertVariant }) =>
  (theme: Theme) =>
    css`
      max-width: 600px;
      margin: 0 auto;
      border-top: 1px solid ${theme.colors[variant || 'grey40']};
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: calc(100vh - 12px);
      overflow-y: auto;
    `;

const titleTextWrapper = (theme: Theme) => css`
  position: relative;
`;

const titleText = (theme: Theme) => css`
  position: relative;
  ${margin({ left: 'xl' })(theme)}
`;

const titleTextIcon = (theme: Theme) => css`
  position: absolute;
  top: 6px;
  ${left('0')(theme)};
`;

export const style = { card, titleTextWrapper, titleText, titleTextIcon };
