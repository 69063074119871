import { mysqlApi } from 'src/api_services/mysql/service';

import { createActionsFromMethod } from '../helpers';

const namespace = '@mysql';

const createWebsiteMySQLDB = createActionsFromMethod<typeof mysqlApi.createWebsiteMySQLDB>(
  `createWebsiteMySQLDB(${namespace})`
);

const createWebsiteMySQLUser = createActionsFromMethod<typeof mysqlApi.createWebsiteMySQLUser>(
  `createWebsiteMySQLUser(${namespace})`
);

const createWebsiteMySQLUserAccessHosts = createActionsFromMethod<typeof mysqlApi.createWebsiteMySQLUserAccessHosts>(
  `createWebsiteMySQLUserAccessHosts(${namespace})`
);

const setWebsiteMySQLUserPrivileges = createActionsFromMethod<typeof mysqlApi.setWebsiteMySQLUserPrivileges>(
  `setWebsiteMySQLUserPrivileges(${namespace})`
);

const deleteWebsiteMySQLDB = createActionsFromMethod<typeof mysqlApi.deleteWebsiteMySQLDB>(
  `deleteWebsiteMySQLDB(${namespace})`
);

const deleteWebsiteMySQLUser = createActionsFromMethod<typeof mysqlApi.deleteWebsiteMySQLUser>(
  `deleteWebsiteMySQLUser(${namespace})`
);

const deleteWebsiteMySQLUserAccessHosts = createActionsFromMethod<typeof mysqlApi.deleteWebsiteMySQLUserAccessHosts>(
  `deleteWebsiteMySQLUserAccessHosts(${namespace})`
);

const getWebsiteMySQLUsers = createActionsFromMethod<typeof mysqlApi.getWebsiteMySQLUsers>(
  `getWebsiteMySQLUsers(${namespace})`
);

const updateWebsiteMySQLUser = createActionsFromMethod<typeof mysqlApi.updateWebsiteMySQLUser>(
  `updateWebsiteMySQLUser(${namespace})`
);

export const mysqlActions = {
  createWebsiteMySQLDB,
  createWebsiteMySQLUser,
  createWebsiteMySQLUserAccessHosts,
  setWebsiteMySQLUserPrivileges,
  deleteWebsiteMySQLDB,
  deleteWebsiteMySQLUser,
  deleteWebsiteMySQLUserAccessHosts,
  getWebsiteMySQLUsers,
  updateWebsiteMySQLUser,
};
