import { NetworkStatus, ServerRole, SystemPackageName } from 'src/orchd-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { getDurationAsMilliseconds } from 'src/utils/dateTimes/dateTimes';

import { queryKeys } from '../query-keys';
import { serversRestartingAtom } from './atoms';
import { serversApi } from './service';

export const useGetServerInfoQuery = ({
  serverId,
  enabled = true,
  refetchInterval = undefined,
}: {
  serverId: string;
  enabled?: boolean;
  refetchInterval?: number | ((data: any) => number | false);
}) => {
  const serverRestarting = useAtomValue(serversRestartingAtom);

  return useQuery({
    queryKey: queryKeys.serverInfo({ serverId }),
    queryFn: () => serversApi.getServerInfo({ serverId }, { timeout: getDurationAsMilliseconds(10, 'seconds') }),
    select: ({ data }) => {
      if (serverRestarting[data?.id]) {
        return { ...data, status: NetworkStatus.restarting };
      }
      return data;
    },
    refetchOnMount: true,
    enabled,
    refetchInterval,
  });
};

export const useGetServerRolesQuery = ({
  serverId,
  enabled = true,
  refetchInterval = undefined,
}: {
  serverId: string;
  enabled?: boolean;
  refetchInterval?: number | ((data: any) => number | false);
}) => {
  return useQuery({
    queryKey: queryKeys.serverRoles({ serverId }),
    queryFn: () => serversApi.getServerRoles({ serverId }, { timeout: getDurationAsMilliseconds(10, 'seconds') }),
    select: ({ data }) => data,
    refetchOnMount: true,
    enabled,
    refetchInterval,
  });
};

export const useValidateRegistrationKeyQuery = (key: string) => {
  return useQuery({
    queryKey: queryKeys.validateRegistrationKey({ key }),
    queryFn: () => serversApi.validateRegistrationKey({ key }),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetAppcdVersionQuery = (serverId: string) => {
  return useQuery({
    queryKey: queryKeys.appcdVersion({ serverId }),
    queryFn: () => serversApi.getAppcdVersion({ serverId }),
    refetchOnMount: true,
    retryOnMount: true,
    select: (resp) => resp.data,
  });
};

export const useGetSystemPackageUpdateInfoQuery = ({
  serverId,
  systemPackageName,
}: {
  serverId: string;
  systemPackageName: SystemPackageName;
}) => {
  return useQuery({
    queryKey: queryKeys.systemPackageUpdateInfo({ serverId }),
    queryFn: () => serversApi.getSystemPackageUpdateInfo({ serverId, systemPackageName }),
    retryOnMount: true,
    refetchOnWindowFocus: false,
    select: (resp) => resp.data,
  });
};

export const useUpdateSystemPackageMutation = ({
  serverId,
  systemPackageName,
}: {
  serverId: string;
  systemPackageName: SystemPackageName;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: queryKeys.appcdVersion({ serverId }),
    mutationFn: () => serversApi.updateSystemPackage({ serverId, systemPackage: { name: systemPackageName } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.appcdVersion({ serverId }) });
      queryClient.invalidateQueries({ queryKey: queryKeys.systemPackageUpdateInfo({ serverId }) });
    },
  });
};

export const useGetServerHostnameWebsiteQuery = ({ serverId }: { serverId: string }) =>
  useQuery({
    queryKey: queryKeys.serverHostnameWebsite({ serverId }),
    queryFn: () => serversApi.getServerHostnameWebsite({ serverId }),
    select: (data) => data.data,
    refetchOnMount: true,
    retry: false,
  });

export const useGetServiceSettingQuery = ({
  enabled = true,
  ...params
}: Parameters<typeof serversApi.getServiceSetting>[0] & { enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.serviceSetting(params),
    queryFn: () => serversApi.getServiceSetting(params),
    select: (data) => data.data,
    refetchOnMount: true,
    enabled,
  });
};

export const useCreateServerDomainMutation = () => useMutation({ mutationFn: serversApi.createServerDomain });

export const useDeleteServerDomainMutation = () => useMutation({ mutationFn: serversApi.deleteServerDomain });

export const useGetFsQuotaStatusQuery = ({ serverId }: { serverId: string }) =>
  useQuery({
    queryKey: queryKeys.fsQuotaStatus({ serverId }),
    queryFn: () => serversApi.getFsQuotaStatus({ serverId }),
    refetchOnMount: true,
    select: (data) => data.data,
  });

export const useGetServerStatsQuery = ({
  serverId,
  refetchInterval = false,
}: {
  serverId: string;
  refetchInterval: number | false;
}) => {
  return useQuery({
    queryKey: queryKeys.serverStats({ serverId }),
    queryFn: () => serversApi.getServerStats({ serverId }),
    refetchOnMount: true,
    select: (data) => data.data.items,
    refetchInterval,
  });
};

export const useGetServerMemoryUsageQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.serverMemoryUsage({ serverId }),
    queryFn: () => serversApi.getServerMemoryUsage({ serverId }),
    refetchOnMount: true,
    select: (data) => data.data,
  });
};

export const useGetServerDiskUsageQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.serverDiskUsage({ serverId }),
    queryFn: () => serversApi.getServerDiskUsage({ serverId }),
    refetchOnMount: true,
    select: (data) => data.data,
  });
};

export const useGetServerNetworkStatsQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.serverNetworkStats({ serverId }),
    queryFn: () => serversApi.getServerNetworkStats({ serverId }),
    refetchOnMount: true,
    select: (data) => data.data,
  });
};

export const useGetServerWebserverKindQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.serverWebserverKind({ serverId }),
    queryFn: () => serversApi.getWebserverKind({ serverId }),
    refetchOnMount: true,
    select: (data) => data.data,
  });
};

export const useEnableFsQuotaLimitsMutation = () => useMutation({ mutationFn: serversApi.enableFsQuotaLimits });

export const useResetWebServerConfigMutation = () => useMutation({ mutationFn: serversApi.resetWebServerConfig });

export const useSetLiteSpeedAdminPasswordMutation = () =>
  useMutation({ mutationFn: serversApi.setLiteSpeedAdminPassword });

export const useSetServiceSettingMutation = () => useMutation({ mutationFn: serversApi.setServiceSetting });

export const useDeleteServiceSettingMutation = () => useMutation({ mutationFn: serversApi.deleteServiceSetting });

export const useSetServerDecommissionedMutation = () => useMutation({ mutationFn: serversApi.setServerDecommissioned });

export const useGetServerModSecurityStatusQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.serverModSecurityStatus({ serverId }),
    queryFn: () => serversApi.getServerModSecurityStatus({ serverId }),
    select: (data) => data.data,
  });
};

export const useGetServerModSecurityConfigQuery = ({
  serverId,
  enabled = true,
}: {
  serverId: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.serverModSecurityConfig({ serverId }),
    queryFn: () => serversApi.getServerModSecurityConfig({ serverId }),
    select: (data) => data.data,
    enabled,
  });
};

export const useGetOwaspRulesVersionQuery = ({ serverId, enabled = true }: { serverId: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.owaspRulesVersion({ serverId }),
    queryFn: () => serversApi.getOwaspRulesVersion({ serverId }),
    select: (data) => data.data,
    enabled,
  });
};

export const useSetServerModSecurityStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: serversApi.setServerModSecurityStatus,
    onMutate: async (request) => {
      const queryKey = queryKeys.serverModSecurityStatus({ serverId: request.serverId });
      const newStatus = { data: request.modSecStatus };

      await queryClient.cancelQueries({ queryKey });

      const previousStatus = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, newStatus);

      return { previousStatus, newStatus };
    },
    onSettled: async (_response, error, request, context) => {
      const serverId = request.serverId;
      const queryKey = queryKeys.serverModSecurityStatus({ serverId: request.serverId });

      if (error) {
        return queryClient.setQueryData(queryKey, context?.previousStatus);
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queryKeys.serverModSecurityStatus({ serverId }) }),
        queryClient.invalidateQueries({ queryKey: queryKeys.serverModSecurityConfig({ serverId }) }),
        queryClient.invalidateQueries({ queryKey: queryKeys.owaspRulesVersion({ serverId }) }),
      ]);
    },
  });
};

export const useSetServerModSecurityConfigMutation = () =>
  useMutation({ mutationFn: serversApi.setServerModSecurityConfig });

export const useResetServerModSecurityConfigMutation = () =>
  useMutation({ mutationFn: serversApi.resetServerModSecurityConfig });

export const useUpdateOwaspRulesMutation = () => useMutation({ mutationFn: serversApi.updateOwaspRules });

export const useUpdateServerPrimaryIPv6Mutation = () => useMutation({ mutationFn: serversApi.updateServerPrimaryIpv6 });

export const useDeleteServerPrimaryIPv6Mutation = () => useMutation({ mutationFn: serversApi.deleteServerPrimaryIpv6 });

// server groups
export const useGetServerGroupsQuery = ({ enabled = true }: { enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.serverGroups(),
    queryFn: () => serversApi.getServerGroups({}),
    refetchOnMount: true,
    select: (data) => data.data.items,
    enabled,
  });
};
export const useCreateServerGroupMutation = () => useMutation({ mutationFn: serversApi.createServerGroup });
export const useUpdateServerGroupMutation = () => useMutation({ mutationFn: serversApi.updateServerGroup });
export const useDeleteServerGroupMutation = () => useMutation({ mutationFn: serversApi.deleteServerGroup });
export const useDeleteServerFromGroupMutation = () => useMutation({ mutationFn: serversApi.deleteServerFromGroup });

export const useGetMysqlMyCnfQuery = ({ serverId, enabled = true }: { serverId: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.mysqlMyCnf({ serverId }),
    queryFn: () => serversApi.getMysqlMyCnf({ serverId }),
    select: (data) => data.data,
    enabled,
  });
};

export const useGetMysqlKindQuery = ({ serverId, enabled = true }: { serverId: string; enabled?: boolean }) => {
  return useQuery({
    queryKey: queryKeys.mysqlKind({ serverId }),
    queryFn: () => serversApi.getDatabaseRoleMysqlKind({ serverId }),
    select: (data) => data.data,
    enabled,
  });
};

export const useSetMysqlMyCnfMutation = () => useMutation({ mutationFn: serversApi.saveMysqlMyCnf });
export const useRestartMysqlMutation = ({ serverId }: { serverId: string }) =>
  useMutation({
    mutationFn: () => serversApi.restartMysql({ serverId }),
    mutationKey: queryKeys.restartMysql({ serverId }),
  });

export const useInstallDatabaseRoleMutation = () => useMutation({ mutationFn: serversApi.installDatabaseRole });

export const useGetServersQuery = ({
  enabled = true,
  refetchInterval,
}: {
  enabled?: boolean;
  refetchInterval?: number;
}) => {
  return useQuery({
    queryKey: queryKeys.servers(),
    queryFn: () => serversApi.getServers({}),
    select: (data) => data.data.items,
    enabled,
    refetchInterval,
  });
};

export const useConfigureServerMutation = () => useMutation({ mutationFn: serversApi.configureServer });
export const useInstallServerRoleMutation = () => useMutation({ mutationFn: serversApi.installServerRole });
export const useSetWebserverKindMutation = () => useMutation({ mutationFn: serversApi.setWebserverKind });

export const useGetDnsPoolIps = ({
  enabled = true,
  refetchInterval,
}: {
  enabled?: boolean;
  refetchInterval?: number;
}) => {
  return useQuery({
    queryKey: queryKeys.dnsPoolIps(),
    queryFn: () => serversApi.getDnsPoolIps({}),
    select: (data) => data.data,
    enabled,
    refetchInterval,
  });
};

export const useGetAppcdScreenshotConfigQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.appcdScreenshotConfig({ serverId }),
    queryFn: () => serversApi.getAppcdScreenshotConfig({ serverId }),
    select: (data) => data.data,
  });
};

export const useUpdateAppcdScreenshotConfigMutation = () =>
  useMutation({ mutationFn: serversApi.updateAppcdScreenshotConfig });

export const useGetHttpdStatusQuery = ({
  serverId,
  role = ServerRole.application,
  enabled = true,
}: {
  serverId: string;
  role?: ServerRole;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.httpdStatus({ serverId, role }),
    queryFn: () => serversApi.getHttpdStatus({ serverId, role }),
    select: (data) => data.data,
    enabled,
  });
};

// smtp rate limits
export const useGetSmtpRateLimitHourlyQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.smtpRateLimitHourly({ serverId }),
    queryFn: () => serversApi.getSmtpRateLimitHourly({ serverId }),
    select: (data) => data.data,
  });
};

export const useSetSmtpRateLimitHourlyMutation = () => useMutation({ mutationFn: serversApi.setSmtpRateLimitHourly });

export const useGetWebsiteGeneratedRateLimitHourlyQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.websiteGeneratedRateLimitHourly({ serverId }),
    queryFn: () => serversApi.getWebsiteGeneratedRateLimitHourly({ serverId }),
    select: (data) => data.data,
  });
};

export const useSetWebsiteGeneratedRateLimitHourlyMutation = () =>
  useMutation({ mutationFn: serversApi.setWebsiteGeneratedRateLimitHourly });

export const useAddSpamIpWhiteListMutation = () => useMutation({ mutationFn: serversApi.addSpamIpWhitelist });

export const useDeleteFromSpamIpWhiteListMutation = () =>
  useMutation({ mutationFn: serversApi.deleteFromSpamIpWhitelist });

export const useGetSpamIpWhiteListQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.spamIpWhiteList({ serverId }),
    queryFn: () => serversApi.getSpamIpWhitelist({ serverId }),
    select: (data) => data.data,
  });
};

export const useGetOutboundSpamScanningSettingsQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.outboundSpamScanningSettings({ serverId }),
    queryFn: () => serversApi.getOutboundSpamScanningSettings({ serverId }),
    select: (data) => data.data,
    keepPreviousData: true,
  });
};

export const useSetOutboundSpamScanningSettingsMutation = () => {
  return useMutation({ mutationFn: serversApi.setOutboundSpamScanningSettings });
};

export const useGetEmailServerHostnameOverrideQuery = ({ serverId }: { serverId: string }) => {
  return useQuery({
    queryKey: queryKeys.emailServerHostnameOverride({ serverId }),
    queryFn: () => serversApi.getEmailServerHostnameOverride({ serverId }),
    select: (data) => data.data,
  });
};

export const useSetEmailServerHostnameOverrideMutation = () =>
  useMutation({ mutationFn: serversApi.setEmailServerHostnameOverride });
