import React from 'react';
import { connect, FormikContextType } from 'formik';

import { isTabletUp } from 'src/utils/breakpointHelpers';

import { scrollVerticalToElementByRef } from '../ScrollManager/scrollHelper';

interface Props {
  formik: FormikContextType<Record<string, unknown>>;
}

export const keyify = (obj: Record<string, any>, prefix = ''): string[] =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    }
    if (typeof obj[el] === 'object' && obj[el] !== null && !React.isValidElement(obj[el])) {
      return [...res, ...keyify(obj[el], `${prefix + el}.`)];
    }
    return [...res, prefix + el];
  }, [] as string[]);

class ErrorFocusInternal extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;

    const keys = keyify(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"], [data-name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector) as HTMLElement;
      const desktop = isTabletUp(window.innerWidth);
      const FIXED_HEADER_HEIGHT = 64;
      if (errorElement) {
        scrollVerticalToElementByRef(errorElement, desktop ? 70 : 70 + FIXED_HEADER_HEIGHT, () => {
          errorElement.focus();
        });
      }
    }
  }

  render() {
    return null;
  }
}

export const ErrorFocus = connect<Record<string, unknown>>(ErrorFocusInternal);
