import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'h1',
  weight: 'light',
  size: '3xl',
};

/**
 * # Text H1
 * Use H1 to present the main headline
 */
const H1 = (props: Props) => <Text {...props} />;

H1.defaultProps = updatedDefaultProps;

export default H1;
