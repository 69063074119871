import React, { Fragment, memo, useEffect, useMemo, useRef } from 'react';
import { ResourceName, ServerRole } from 'src/orchd-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Action, Location } from 'history';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { DashboardLayout } from 'src/components/DashboardLayout/DashboardLayout';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { useMountEffect } from 'src/hooks/useMountEffect/useMountEffect';
import enhanceLogoInverse from 'src/images/enhance_blue_e.svg';
import enhancewhiteblue from 'src/images/enhance_whiteblue.svg';
import favicon from 'src/images/favicon.svg';
import favicon2 from 'src/images/favicon2.svg';
import { Routes } from 'src/navigation/Routes';
import { calcLoggedInNavigationItems } from 'src/navigation/SpineNavItems';
import { AppState } from 'src/store';
import { brandingSelectors } from 'src/store/branding/selectors';
import { promisifyRequestAction } from 'src/store/helpers';
import { impersonationSelectors } from 'src/store/impersonation';
import { impersonationActions } from 'src/store/impersonation/actions';
import { loginsSelectors } from 'src/store/logins/selectors';
import { membersActions } from 'src/store/members/actions';
import { hideAllPoppers } from 'src/store/poppers/actions';
import { poppersSelectors } from 'src/store/poppers/selectors';
import { serversActions } from 'src/store/servers/actions';
import { serversSelectors } from 'src/store/servers/selectors';
import { sessionActions } from 'src/store/session/actions';
import { sessionSelectors } from 'src/store/session/selectors';
import { extendDayJs } from 'src/utils/dateTimes/dateTimes';
import { addLeadingSlash, serverHasRole } from 'src/utils/helpers';
import { getLocaleTextDirection } from 'src/utils/i18n/getLocaleTextDirection';
import { isLink } from 'src/utils/types';

import { usePreferences } from './hooks/usePreferences/usePreferences';
import { useRefetchSubscriptions, useReseller, useSubscriptions } from './hooks/useSubscriptions/useSubscriptions';
import { MoveServerToast } from './pages/migrations/MoveServerToast';
import { WebsiteImportToast } from './pages/website-imports/WebsiteImportsProgress/WebsiteImportToast';
import { CloneToast } from './pages/websites/clone/CloneToast';
import { UpdatePhpVersionToast } from './pages/websites/updatePhpVersion/UpdatePhpVersionToast';
import { devtoolsSelectors } from './store/devtools/selectors';
import { subscriptionHasResource } from './utils/packages/utils';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

// needs to executed before any of the other modules load
extendDayJs();

export type Props = ActionProps & StateProps;

const usedFavicon = document.location.hostname === 'localhost' ? favicon2 : favicon;

export const _App = ({
  appLoaded,
  canAccessServers,
  faviconPath,
  getMembers,
  getServers,
  impersonationMember,
  impersonationStartRouterKey,
  isDesktopUp,
  isLoggedIn,
  isPoppers,
  isReactQueryDevtoolsActive,
  locale,
  loginMemberships,
  inverseLogoPath: inverseLogoPathRelative,
  inverseIconPath: inverseIconPathRelative,
  logoPath: logoPathRelative,
  brandingOrgName,
  logout,
  member,
  servers,
  stopImpersonating,
  supportUrl,
  billingUrl,
}: Props) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const impersonationStartRouterKeyRef = useRef(impersonationStartRouterKey);
  const { setPreference, preferences } = usePreferences();

  useRefetchSubscriptions({ member: impersonationMember ?? member });

  const serverHasEmailRole = useMemo(() => {
    return !!(member?.isMasterOrg && (servers ?? []).some((server) => serverHasRole(server, ServerRole.email)));
  }, [member, servers]);

  const { subscriptions } = useSubscriptions({ orgId: member?.orgId });
  const { isReseller } = useReseller({ orgId: member?.orgId });

  const subscriptionHasEmail = useMemo(() => {
    return subscriptions.some(
      (subscription) =>
        subscriptionHasResource(subscription, ResourceName.mailboxes) ||
        subscriptionHasResource(subscription, ResourceName.forwarders)
    );
  }, [subscriptions]);

  const hasEmail = serverHasEmailRole || subscriptionHasEmail;

  useEffect(() => {
    impersonationStartRouterKeyRef.current = impersonationStartRouterKey;
  }, [impersonationStartRouterKey]);

  useMountEffect(() => {
    appLoaded();

    if (impersonationMember) {
      handleValidateImpersonationMember({ orgId: impersonationMember.orgId });
    }

    if (canAccessServers) {
      getServers({ params: {} });
    }

    const stopListen = history.listen(handleHistoryListen);
    return () => stopListen();
  });

  const handleHistoryListen = (location: Location, action: Action) => {
    handlePopperHide();
    handleStopImpersonateOnBack(location, action);
  };

  const handlePopperHide = () => {
    if (isPoppers) {
      hideAllPoppers();
    }
  };

  const handleStopImpersonateOnBack = (location: Location, action: Action) => {
    if (action === 'POP' && location.key === impersonationStartRouterKeyRef.current) {
      stopImpersonating();
    }
  };

  const handleValidateImpersonationMember = async ({ orgId }: { orgId: string }) => {
    try {
      await getMembers({ orgId });
    } catch (e) {
      stopImpersonating();
      history.push('/');
    }
  };

  const handleLogout = async () => {
    logout();
  };

  const loggedInNavigationItems = useMemo(() => {
    return calcLoggedInNavigationItems({ intl, hasEmail, isReseller, member, impersonationMember });
  }, [intl, hasEmail, isReseller, member, impersonationMember]);

  const spineLinks = useMemo(() => loggedInNavigationItems.filter(isLink), [loggedInNavigationItems]);

  const routes = <Routes isLoggedIn={isLoggedIn ?? false} loggedInNavigationItems={loggedInNavigationItems} />;
  const hideSpine = ['/setup', '/invites', '/login'].some((uri) => location.pathname.startsWith(uri));

  const inverseLogoPath = useMemo(() => {
    if (inverseLogoPathRelative) return addLeadingSlash(inverseLogoPathRelative);
    if (logoPathRelative) return addLeadingSlash(logoPathRelative);
    return enhancewhiteblue;
  }, [inverseLogoPathRelative, logoPathRelative]);

  const inverseIconPath = useMemo(() => {
    if (inverseIconPathRelative) return addLeadingSlash(inverseIconPathRelative);
    return enhanceLogoInverse;
  }, [inverseIconPathRelative]);

  const metaTitle = brandingOrgName;

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{ lang: locale, dir: getLocaleTextDirection(locale) }}
        link={[{ rel: 'shortcut icon', href: faviconPath ? addLeadingSlash(faviconPath) : usedFavicon }]}
        title={metaTitle}
      />
      {isLoggedIn && member && loginMemberships && !hideSpine ? (
        <Fragment>
          <DashboardLayout
            member={member}
            isImpersonationActive={!!impersonationMember}
            spineLinks={spineLinks}
            loginMemberships={loginMemberships}
            logout={handleLogout}
            logoPath={inverseLogoPath}
            iconPath={inverseIconPath}
            supportUrl={supportUrl}
            billingUrl={billingUrl}
            isDesktopUp={isDesktopUp}
            isSpineOpen={preferences.spineOpen}
            toggleSpine={() => setPreference('spineOpen', !preferences.spineOpen)}
          >
            {routes}
          </DashboardLayout>
          {/* Toast controllers */}
          <WebsiteImportToast />
          <UpdatePhpVersionToast />
          <CloneToast />
          <MoveServerToast />
          {/* ----------------- */}
        </Fragment>
      ) : (
        routes
      )}
      {isReactQueryDevtoolsActive && (
        <ReactQueryDevtools
          initialIsOpen={false}
          toggleButtonProps={{
            style: { width: '20px', height: '20px', overflow: 'hidden', margin: '0', paddingBottom: '30px' },
          }}
        />
      )}
    </Fragment>
  );
};

const stateToProps = (state: AppState) => {
  const member = sessionSelectors.getMemberOrUndefined(state);

  return {
    isPoppers: poppersSelectors.selectIsPoppers(state),
    isLoggedIn: sessionSelectors.getIsLoggedIn(state),
    brandingOrgName: brandingSelectors.selectBrandingOrgName(state),
    canAccessServers: sessionSelectors.canAccessServers(state),
    loginMemberships: loginsSelectors.loginMemberships(state),
    impersonationMember: impersonationSelectors.getImpersonationMember(state),
    member,
    locale: loginsSelectors.selectLocale(state),
    faviconPath: brandingSelectors.selectBrandingFaviconPath(state),
    inverseLogoPath: brandingSelectors.selectBrandingInverseLogoPath(state),
    inverseIconPath: brandingSelectors.selectBrandingInverseIconPath(state),
    logoPath: brandingSelectors.selectBrandingLogoPath(state),
    supportUrl: brandingSelectors.selectSupportUrl(state),
    billingUrl: brandingSelectors.selectBillingUrl(state),
    servers: serversSelectors.servers(state)?.items,
    impersonationStartRouterKey: impersonationSelectors.getImpersonationStartRouterKey(state),

    isDesktopUp: viewportSelectors.isDesktopUp(state),
    isReactQueryDevtoolsActive: devtoolsSelectors.isReactQueryDevtoolsActive(state),
  };
};

export const dispatchToProps = {
  hideAllPoppers,
  getMembers: promisifyRequestAction(membersActions.getMembers.request),
  logout: sessionActions.logout,
  stopImpersonating: impersonationActions.stopImpersonating,
  getServers: serversActions.getServers.request,
  appLoaded: sessionActions.appLoaded,
};

export const App = connect(stateToProps, dispatchToProps)(memo(_App));
