import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { membersApi } from 'src/api_services/members/service';

import { createRequestStateSaga } from '../helpers';
import { membersActions } from './actions';

const [createMemberSaga] = createRequestStateSaga(membersActions.createMember, membersApi.createMember);
const [getMemberSaga] = createRequestStateSaga(membersActions.getMember, membersApi.getMember);
const [getMembersSaga] = createRequestStateSaga(membersActions.getMembers, membersApi.getMembers);
const [getAccessTokensSaga] = createRequestStateSaga(membersActions.getAccessTokens, membersApi.getAccessTokens);
const [createAccessTokenSaga] = createRequestStateSaga(membersActions.createAccessToken, membersApi.createAccessToken);
const [deleteAccessTokenSaga] = createRequestStateSaga(membersActions.deleteAccessToken, membersApi.deleteAccessToken);

export function* membersSaga() {
  yield takeEvery(getType(membersActions.createMember.request), createMemberSaga);
  yield takeEvery(getType(membersActions.getMember.request), getMemberSaga);
  yield takeEvery(getType(membersActions.getMembers.request), getMembersSaga);
  yield takeEvery(getType(membersActions.getAccessTokens.request), getAccessTokensSaga);
  yield takeEvery(getType(membersActions.createAccessToken.request), createAccessTokenSaga);
  yield takeEvery(getType(membersActions.deleteAccessToken.request), deleteAccessTokenSaga);
}
