import { memo, ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { style } from './ExternalLink.style';

export interface Props {
  to: string;
  children?: ReactNode;
  fullWidth?: boolean;
}

const _ExternalLink = ({ to, fullWidth, children }: Props) => {
  // Prefix https if needed.
  const link = /^https?:\/\//.test(to) ? to : `https://${to}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" data-qa="link" css={style.linkTag(fullWidth)}>
      {children}
    </a>
  );
};

export const ExternalLink = memo(_ExternalLink);
