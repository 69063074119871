import { css } from '@emotion/core';

import { Colors, Theme } from 'src/design-system/theme';

const squarePill =
  ({ color, disabled, size }: { color: Colors; disabled: boolean; size: 'sm' | 'md' }) =>
  (theme: Theme) =>
    css`
      border-radius: ${theme.buttonBorderRadius};
      background: ${theme.colors[color]};
      color: ${theme.fontColors.light};
      text-transform: uppercase;
      padding: ${size === 'md' ? `${theme.paddings.xs}px ${theme.paddings.sm}px` : `0 ${theme.paddings.sm}px`};
      opacity: ${disabled ? 0.5 : 1};
      white-space: nowrap;
    `;

export const style = { squarePill };
