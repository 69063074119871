import { getType } from 'deox';
import { Middleware } from 'redux';

import { impersonationActions } from 'src/store/impersonation/actions';
import { persister } from 'src/utils/persister/persister';

import * as actions from './actions';

export const searchPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);
  switch (action.type) {
    case getType(actions.clearRecentSearch):
    case getType(actions.clearRecentSearches):
    case getType(actions.saveSearchToRecents):
      persister.set({ key: 'search', item: store.getState().search, isPerUser: true });
      break;
    case getType(impersonationActions.stopImpersonating):
      persister.clear({ key: 'search', isPerUser: true });
  }
  return res;
};
