import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { LooseAutocomplete } from 'src/utils/types';

import { OverwriteStatus } from '../constants';
import { Directory } from '../Tree';
import { fileManagerActions } from './actions';
import { UploadedFile, UploadStatus } from './types';

export interface DirectoryTreeState {
  data: Directory;
  loading: boolean;
  error: string;
}

export const initialTreeState: DirectoryTreeState = {
  data: Directory.from({ path: '' }),
  loading: false,
  error: '',
};

export interface ContextMenuState {
  x: number;
  y: number;
  open: boolean;
  type: 'file' | 'folder' | 'container';
  target: string;
}

export const initialContextMenuState: ContextMenuState = {
  x: 0,
  y: 0,
  open: false,
  type: 'file',
  target: '',
};

export interface EditorState {
  open: boolean;
  path: string;
  content: string;
}

export const initialEditorState: EditorState = {
  open: false,
  path: '',
  content: '',
};

const treeReducer = createReducer(initialTreeState, (handleAction) => [
  handleAction(fileManagerActions.getEntries.request, (state) => {
    return { ...state, loading: true };
  }),
  handleAction(fileManagerActions.getEntries.success, (state, { payload: { data } }) => {
    return { data: data.dir ? state.data.append(Directory.from(data.dir)) : state.data, loading: false, error: '' };
  }),
  handleAction(fileManagerActions.getEntries.error, (state, { payload: { error } }) => {
    return { ...state, loading: false, error };
  }),
  handleAction(fileManagerActions.getEntries.reset, () => initialTreeState),
]);

type FileType = LooseAutocomplete<'file' | 'folder'>;
type Selected = { path: string; type: FileType; first?: boolean };

const selectionReducer = createReducer([] as Selected[], (handleAction) => [
  handleAction(fileManagerActions.toggleSelect, (state, { payload }) => {
    const { path, type, shift, entries } = payload;

    if (shift && entries && state.length > 0) {
      const firstSelected = state.find((entry) => entry.first);
      const startOffset = entries.findIndex((item) => item.id === firstSelected?.path);
      const endOffset = entries.findIndex((item) => item.id === path);
      const bounds = [startOffset, endOffset];

      return entries.slice(Math.min(...bounds), Math.max(...bounds) + 1).map((item) => ({
        path: item.id,
        type: item.type,
        first: item.id === firstSelected?.path,
      }));
    }

    if (state.length === 0) {
      return state.concat({ path, type, first: true });
    }

    return state.find(({ path: currPath }) => currPath === path)
      ? state.filter(({ path: currPath }) => currPath !== path)
      : state.concat({ path, type });
  }),
  handleAction(fileManagerActions.resetSelect, () => {
    return [] as Selected[];
  }),
  handleAction(fileManagerActions.selectAll, (state, { payload: { entries } }) => {
    return entries.reduce<Selected[]>((carry, { id, type }) => {
      return carry.find(({ path: currPath }) => currPath === id)
        ? carry
        : carry.concat({ path: id, type: type as FileType });
    }, state);
  }),
]);

const clipboardReducer = createReducer({ items: [] as string[], cut: false }, (handleAction) => [
  handleAction(fileManagerActions.setClipboard, (_, { payload: { items, cut = false } }) => {
    return { items, cut };
  }),
  handleAction(fileManagerActions.clearClipboard, () => {
    return { items: [], cut: false };
  }),
]);

const uploaderReducer = createReducer({} as Record<string, UploadedFile[]>, (handleAction) => [
  handleAction(fileManagerActions.setUploaderFiles, (state, { payload: { websiteId, files } }) => {
    return { ...state, [websiteId]: files.map((file) => ({ file, status: UploadStatus.Started })) };
  }),
  handleAction(fileManagerActions.clearUploader, (state, { payload: { websiteId } }) => {
    return { ...state, [websiteId]: [] };
  }),
  handleAction(
    fileManagerActions.updateFileProgress,
    (state, { payload: { websiteId, file, status, current, total } }) => {
      return {
        ...state,
        [websiteId]: state[websiteId]?.map((item) => {
          return item.file.name === file.name ? { file, status, current, total } : item;
        }),
      };
    }
  ),
]);

const overwritesReducer = createReducer({} as Record<string, OverwriteStatus>, (handleAction) => [
  handleAction(fileManagerActions.setOverwrite, (state, { payload: { path, status } }) => {
    state[path] = status;

    return state;
  }),
  handleAction([fileManagerActions.createEntries.success, fileManagerActions.createEntries.error], () => {
    return {} as Record<string, OverwriteStatus>;
  }),
]);

const contextMenuReducer = createReducer(initialContextMenuState, (handleAction) => [
  handleAction(fileManagerActions.openContextMenu, (_, { payload: { x, y, type, target } }) => {
    return { x, y, type, target, open: true };
  }),
  handleAction(fileManagerActions.closeContextMenu, () => {
    return initialContextMenuState;
  }),
]);

const renameReducer = createReducer('', (handleAction) => [
  handleAction(fileManagerActions.toggleRename, (state, { payload: { path } }) => {
    return state === path ? '' : path;
  }),
  handleAction(fileManagerActions.resetRename, () => {
    return '';
  }),
]);

const chmodReducer = createReducer('', (handleAction) => [
  handleAction(fileManagerActions.setChmodTarget, (_, { payload: { path } }) => {
    return path;
  }),
]);

const fileInfoReducer = createReducer('', (handleAction) => [
  handleAction(fileManagerActions.showFileInfo, (_, { payload: { path } }) => {
    return path;
  }),
]);

const editorReducer = createReducer(initialEditorState, (handleAction) => [
  handleAction(fileManagerActions.openEditor, (_, { payload: { path } }) => {
    return { open: true, path, content: '' };
  }),
  handleAction(fileManagerActions.closeEditor, () => {
    return initialEditorState;
  }),
  handleAction(fileManagerActions.getContent.success, (state, { payload: { data } }) => {
    return { ...state, content: String(data) };
  }),
]);

export const fileManagerReducer = combineReducers({
  tree: treeReducer,
  selected: selectionReducer,
  clipboard: clipboardReducer,
  uploader: uploaderReducer,
  overwrites: overwritesReducer,
  contextMenu: contextMenuReducer,
  rename: renameReducer,
  chmod: chmodReducer,
  editor: editorReducer,
  fileInfo: fileInfoReducer,
});
