import { css } from '@emotion/core';

import { leftString } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';

const line = () => (theme: Theme) =>
  css`
    background: 'transparent';
    position: absolute;
    ${leftString(theme)}: 0;
    top: 50%;
    bottom: 0;
    transform: translateY(-10px);
    height: 20px;
    width: 3px;

    .selected && {
      background: ${theme.colors.accentOnBlack};
    }
  `;

export const style = { line };
