import { CPLocale } from 'src/orchd-client';
import { createReducer } from 'deox';

import { DateTimeConfig, defaultDateTimeConfig } from 'src/utils/intl/getMessages/getMessages';

import { uiActions } from './actions';

export interface TableItemsPerPage {
  [key: string]: number;
}

export interface UiState {
  showOnboardingCard: boolean;
  tempLocale?: CPLocale;
  dateTimeConfig: DateTimeConfig;
  tableItemsPerPage: TableItemsPerPage;
  serverGroupsCollapsed: Record<string, boolean>;
  hydrated: boolean;
}

export const initialState: UiState = {
  showOnboardingCard: true,
  tempLocale: undefined,
  dateTimeConfig: defaultDateTimeConfig,
  tableItemsPerPage: {},
  serverGroupsCollapsed: {},
  hydrated: false,
};

const { setServerGroupsOpen, setTableItemsPerPage, toggleOnboardingCard, updateDateTimeConfig, updateTempLocale } =
  uiActions;

export const uiReducer = createReducer(initialState, (handleAction) => [
  handleAction(toggleOnboardingCard, (state) => {
    return {
      ...state,
      showOnboardingCard: !state.showOnboardingCard,
    };
  }),
  handleAction(updateTempLocale, (state, { payload }) => {
    return {
      ...state,
      tempLocale: payload.locale,
    };
  }),
  handleAction(updateDateTimeConfig, (state, { payload }) => {
    return {
      ...state,
      dateTimeConfig: payload.dateTimeConfig,
    };
  }),
  handleAction(setServerGroupsOpen, (state, { payload }) => {
    return {
      ...state,
      serverGroupsCollapsed: {
        ...state.serverGroupsCollapsed,
        ...payload,
      },
    };
  }),
  handleAction(setTableItemsPerPage, (state, { payload }) => ({
    ...state,
    tableItemsPerPage: {
      ...state.tableItemsPerPage,
      ...payload,
    },
  })),
]);
