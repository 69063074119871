import { createSelector } from 'reselect';

import { AppState } from 'src/store';

const selectUpdatingServerIds = (state: AppState) => state.servers.updatingServers.ids;
export const selectIsUpdatingServers = createSelector(selectUpdatingServerIds, (ids) => !!ids.length);

export const updatingServersSelectors = {
  selectIsUpdatingServers,
  selectUpdatingServerIds,
};
