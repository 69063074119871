import { WebsitesApiCreatePreviewDomainRes } from 'src/orchd-client';
import { AxiosResponse } from 'axios';
import { ActionType, getType } from 'deox';
import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { websitesApi } from 'src/api_services/websites/service';
import { createRequestStateSaga } from 'src/store/helpers';
import { getErrorInfo, isError } from 'src/utils/errors';

import { websitesActions } from './actions';

export const CREATE_PREVIEW_DOMAIN_DELAY_TIME = 2000;

const [getWebsitesRequestSaga] = createRequestStateSaga(websitesActions.getWebsites, websitesApi.getWebsites);
const [getWebsiteRequestSaga] = createRequestStateSaga(websitesActions.getWebsite, websitesApi.getWebsite);

const [updateWebsiteRequestSaga] = createRequestStateSaga(websitesActions.updateWebsite, websitesApi.updateWebsite);
const [deleteWebsiteRequestSaga] = createRequestStateSaga(websitesActions.deleteWebsite, websitesApi.deleteWebsite);

const [getAccessTokenSaga] = createRequestStateSaga(websitesActions.getAccessToken, websitesApi.getAccessToken);

const [updateWebsiteDomainMappingRequestSaga] = createRequestStateSaga(
  websitesActions.updateWebsiteDomainMapping,
  websitesApi.updateWebsiteDomainMapping
);
const [pushWebsiteLiveSaga] = createRequestStateSaga(websitesActions.pushWebsiteLive, websitesApi.pushWebsiteLive);
const [getWebsiteMySQLDBsSaga] = createRequestStateSaga(
  websitesActions.getWebsiteMySQLDBs,
  websitesApi.getWebsiteMySQLDBs
);
const [createWebsiteMySQLDBSaga] = createRequestStateSaga(
  websitesActions.createWebsiteMySQLDB,
  websitesApi.createWebsiteMySQLDB
);
const [getWebsiteHtaccessRewritesSaga, refreshWebsiteHtaccessRewritesSaga] = createRequestStateSaga(
  websitesActions.getWebsiteHtaccessRewrites,
  websitesApi.getWebsiteHtaccessRewrites
);
const [updateWebsiteHtaccessRewritesSaga] = createRequestStateSaga(
  websitesActions.updateWebsiteHtaccessRewrites,
  websitesApi.updateWebsiteHtaccessRewrites
);

const [getWebsiteSettingSaga] = createRequestStateSaga(
  websitesActions.getWebsiteSetting,
  websitesApi.getWebsiteSetting
);

const [setWebsiteSettingSaga] = createRequestStateSaga(
  websitesActions.setWebsiteSetting,
  websitesApi.setWebsiteSetting
);

const [deleteServiceSettingSaga] = createRequestStateSaga(
  websitesActions.deleteWebsiteSetting,
  websitesApi.deleteWebsiteSetting
);

const [getWebsiteServerDomainsSaga] = createRequestStateSaga(
  websitesActions.getWebsiteServerDomains,
  websitesApi.getWebsiteServerDomains
);

const [authorizeWebsiteSshPasswordSaga] = createRequestStateSaga(
  websitesActions.authorizeWebsiteSshPassword,
  websitesApi.authorizeWebsiteSshPassword
);

const [getScreenshotTimestampSaga] = createRequestStateSaga(
  websitesActions.getScreenshotTimestamp,
  websitesApi.getScreenshotTimestamp
);

const [takeScreenshotSaga] = createRequestStateSaga(websitesActions.takeScreenshot, websitesApi.takeScreenshot);

const [getWebsiteDomainMappingDnsStatusSaga] = createRequestStateSaga(
  websitesActions.getWebsiteDomainMappingDnsStatus,
  websitesApi.getWebsiteDomainMappingDnsStatus
);

export function* createPreviewDomainRequestSaga({
  payload,
}: ActionType<typeof websitesActions.createPreviewDomain.request>) {
  const { onSuccess, onError, params } = payload;
  const { orgId, websiteId } = params;

  try {
    const { data, status }: AxiosResponse<WebsitesApiCreatePreviewDomainRes> = yield call(
      websitesApi.createPreviewDomain,
      {
        orgId,
        websiteId,
      }
    );

    if (status === 201) {
      // allow time for created staging url DNS record to sync
      yield delay(CREATE_PREVIEW_DOMAIN_DELAY_TIME);
    }

    yield put(websitesActions.createPreviewDomain.success({ data }));

    if (onSuccess) {
      onSuccess(data);
    }
  } catch (e) {
    const { message } = getErrorInfo(e);
    yield put(websitesActions.createPreviewDomain.error({ error: message }));
    if (onError) {
      const usedError = isError(e) ? e : undefined;
      onError(usedError);
    }
  }
}

export function* websitesSaga() {
  yield takeEvery(getType(websitesActions.getWebsites.request), getWebsitesRequestSaga);
  yield takeEvery(getType(websitesActions.getWebsite.request), getWebsiteRequestSaga);
  yield takeEvery(getType(websitesActions.updateWebsite.request), updateWebsiteRequestSaga);
  yield takeEvery(getType(websitesActions.deleteWebsite.request), deleteWebsiteRequestSaga);
  yield takeLatest(getType(websitesActions.getAccessToken.request), getAccessTokenSaga);
  yield takeLatest(getType(websitesActions.updateWebsiteDomainMapping.request), updateWebsiteDomainMappingRequestSaga);
  yield takeLatest(getType(websitesActions.pushWebsiteLive.request), pushWebsiteLiveSaga);
  yield takeLatest(getType(websitesActions.getWebsiteMySQLDBs.request), getWebsiteMySQLDBsSaga);
  yield takeLatest(getType(websitesActions.createWebsiteMySQLDB.request), createWebsiteMySQLDBSaga);
  yield takeLatest(getType(websitesActions.getWebsiteHtaccessRewrites.request), getWebsiteHtaccessRewritesSaga);
  yield takeLatest(getType(websitesActions.updateWebsiteHtaccessRewrites.request), updateWebsiteHtaccessRewritesSaga);
  yield takeLatest(getType(websitesActions.updateWebsiteHtaccessRewrites.success), refreshWebsiteHtaccessRewritesSaga);
  yield takeEvery(getType(websitesActions.getWebsiteSetting.request), getWebsiteSettingSaga);
  yield takeEvery(getType(websitesActions.setWebsiteSetting.request), setWebsiteSettingSaga);
  yield takeEvery(getType(websitesActions.deleteWebsiteSetting.request), deleteServiceSettingSaga);
  yield takeEvery(getType(websitesActions.getWebsiteServerDomains.request), getWebsiteServerDomainsSaga);
  yield takeEvery(getType(websitesActions.createPreviewDomain.request), createPreviewDomainRequestSaga);
  yield takeEvery(getType(websitesActions.authorizeWebsiteSshPassword.request), authorizeWebsiteSshPasswordSaga);
  yield takeEvery(getType(websitesActions.getScreenshotTimestamp.request), getScreenshotTimestampSaga);
  yield takeEvery(getType(websitesActions.takeScreenshot.request), takeScreenshotSaga);

  yield takeEvery(
    getType(websitesActions.getWebsiteDomainMappingDnsStatus.request),
    getWebsiteDomainMappingDnsStatusSaga
  );
}
