import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetWebsitesQuery } from 'src/api_services/websites/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';
import { DropdownMenu } from 'src/components/DropdownMenu';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { Domains } from 'src/pages/websites/dashboard/Domains';
import { lastVisitedWebsiteAtom } from 'src/pages/websites/website/dashboard/AlternativeWebsiteDashboard';
import { zIndex } from 'src/utils/zIndex';

import { MiddleTruncate } from '../MiddleTruncate';

interface Props {
  orgId: string;
  isImpersonationActive: boolean;
}

export const AddWebsiteBanner = ({ orgId, isImpersonationActive }: Props) => {
  const { formatMessage } = useIntl();
  const lastVisitedWebsiteId = useAtomValue(lastVisitedWebsiteAtom);

  const { data: { items: websites } = { items: [] } } = useGetWebsitesQuery({
    orgId,
    showDeleted: false,
    showAliases: false,
  });

  const currentWebsite = useMemo(
    () => websites.find((website) => website.id === lastVisitedWebsiteId),
    [websites, lastVisitedWebsiteId]
  );

  return (
    <Box
      bg="purpleBright"
      py="sm"
      position="sticky"
      top={isImpersonationActive ? 48 : '0'}
      zIndex={zIndex.DashboardHeaderXS}
    >
      <Container>
        <Box j="space-between" a="center" wrap="nowrap">
          <Box a="center" gap="md">
            {websites.length > 1 && (
              <MiddleTruncate
                color="light"
                size="sm"
                text={currentWebsite ? currentWebsite.domain.domain : formatMessage(messages.manage_website)}
                prefix={<Icon name="nav websites" color="light" marginRight="sm" />}
                suffix={
                  <DropdownMenu
                    button={<Icon name="chevron down" color="light" onClick={() => null} marginLeft="sm" />}
                    options={websites.map((website) => ({
                      label: <Domains website={website} hideAdditionalDomains highlightText="" />,
                      url: `/websites/${website.id}`,
                      disabled: website.id === lastVisitedWebsiteId,
                    }))}
                  />
                }
              />
            )}
          </Box>

          <DropdownMenu
            button={
              <Button
                variant="secondary"
                size="small"
                borderColor="light"
                color="light"
                backgroundColorHover="light"
                colorHover="dark"
                icon="chevron down"
                iconPosition="right"
              >
                <FormattedMessage id="layout.add_site" defaultMessage="Add site" />
              </Button>
            }
            options={[
              {
                labelComponent: (
                  <Box py="md" px="lg" a="center" gap="md" wrap="nowrap">
                    <Icon name="nav websites" />

                    <Box d="column">
                      <Text size="sm" whiteSpace="nowrap" fullWidth>
                        <FormattedMessage {...messages.add_website} />
                      </Text>

                      <Text size="xs" color="grey">
                        <FormattedMessage id="layout.add_website.help" defaultMessage="Add a brand new website" />
                      </Text>
                    </Box>
                  </Box>
                ),
                label: 'add-website',
                url: '/websites/add-website',
              },
              {
                labelComponent: (
                  <Box py="md" px="lg" a="center" gap="md" wrap="nowrap">
                    <Icon name="nav imports" />

                    <Box d="column">
                      <Text size="sm" whiteSpace="nowrap" fullWidth>
                        <FormattedMessage id="layout.import_website" defaultMessage="Import website" />
                      </Text>

                      <Text size="xs" color="grey">
                        <FormattedMessage
                          id="layout.import_website.help"
                          defaultMessage="Transfer an existing cPanel or Plesk website"
                        />
                      </Text>
                    </Box>
                  </Box>
                ),
                label: 'import-website',
                url: '/website-imports/new/cPanel',
              },
            ]}
          />
        </Box>
      </Container>
    </Box>
  );
};
