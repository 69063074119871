import { css } from '@emotion/core';

import { Margins, Responsive, Sizes } from 'src/design-system/style-types';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

export interface StyleProps {
  rowGap: Sizes | Responsive<Sizes>;
  columnGap: Sizes | Responsive<Sizes>;
  columns: number | Responsive<number>;
  marginBottom: Margins | Responsive<Margins>;
  marginTop: Margins | Responsive<Margins>;
}

const grid =
  ({ marginBottom, marginTop, rowGap, columnGap, columns }: StyleProps) =>
  (theme: Theme) =>
    css`
      width: 100%;
      display: grid;
      grid-auto-flow: row;

      ${responsive(marginBottom, (marginBottom) => `margin-bottom: ${theme.margins[marginBottom]}px;`)}
      ${responsive(marginTop, (marginTop) => `margin-top: ${theme.margins[marginTop]}px;`)}
  ${responsive(rowGap, (gap) => `row-gap: ${theme.paddings[gap]}px;`)}
  ${responsive(columnGap, (gap) => `column-gap: ${theme.paddings[gap]}px;`)}
  ${responsive(
        columns,
        (columnCount) => `
      grid-template-columns: repeat(${columnCount}, minmax(0, 1fr));
      justify-content: center;
    `
      )}
    `;

export const style = { grid };
