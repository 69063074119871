import { desaturate, saturate, shade, tint } from 'polished';
import { Subtract } from 'utility-types';

import { Colors, FontColors } from 'src/design-system/style-types';
import { checkColorParses } from 'src/utils/colors/colors';

export type RequiredColors =
  | 'dark'
  | 'light'
  | 'accent'
  | 'grey'
  | 'disabled'
  | 'placeholder'
  | 'azure'
  | 'aqua'
  | 'green'
  | 'success'
  | 'success25'
  | 'yellow'
  | 'yellow2'
  | 'info'
  | 'warning'
  | 'orange'
  | 'blue'
  | 'error'
  | 'error25'
  | 'fuschia'
  | 'purpleBright'
  | 'purple'
  | 'transparent'
  | 'none';

type BrandingColors = Record<RequiredColors, string>;
type OptionalColors = Subtract<Record<Colors, string>, BrandingColors>;

/**
 * A liberal type allowing the minimum required colors to be passed, and also any other
 * non-required colors
 */
export type BrandingThemeColors = BrandingColors & Partial<OptionalColors>;
export type BrandingThemeFontColors = Partial<Record<FontColors, string>>;

/**
 * Generates a palette of accent colors based on a principal accent color code
 */
export const buildAccentShades = (passedAccent: string) => {
  const accent = checkColorParses(passedAccent);
  return {
    accentOnBlack: tint(0.3, accent),
    accentOnWhite: shade(0.6, accent),
    accentBackground: tint(0.95, accent),
    accentB10: shade(0.1, accent),
    accentB25: shade(0.25, accent),
    accentB50: shade(0.5, accent),
    accentB90: shade(0.9, accent),
    accentB95: shade(0.95, accent),
    accentW25: tint(0.25, accent),
    accentW40: tint(0.4, accent),
    accentW50: tint(0.5, accent),
    accentW70: tint(0.7, accent),
    accentW80: tint(0.8, accent),
  };
};

/**
 * Infers grey shades from the main accent color
 */
export const buildGreyShadesFromAccent = (accent: string) => buildGreyShades(desaturate(1, checkColorParses(accent)));

/**
 * Generates a palette of greys based on a medium grey color code
 */
export const buildGreyShades = (passedGrey: string) => {
  const grey = checkColorParses(passedGrey, 'grey');
  return {
    placeholder: grey,
    disabled: grey,
    grey50: grey,
    grey3: tint(0.97, grey),
    grey5: tint(0.95, grey),
    grey10: tint(0.9, grey),
    grey20: tint(0.8, grey),
    grey25: tint(0.75, grey),
    grey40: tint(0.6, grey),
    grey60: shade(0.6, grey),
    grey70: shade(0.7, grey),
    grey75: shade(0.75, grey),
    grey90: shade(0.9, grey),
    grey95: shade(0.95, grey),
    grey97: shade(0.97, grey),
  };
};

/**
 * Creates a light variant of a given status color (used for errors, warnings etc)
 */
export const buildLightStatusColor = (color: string) => tint(0.9, checkColorParses(color));

/**
 * Brightens a standard green color
 */
export const buildBrightGreenColor = (green: string) => saturate(0.5, tint(0.3, checkColorParses(green, 'green')));

/**
 * Darkens a standard aqua color
 */
export const buildDarkAquaColor = (aqua: string) => shade(0.1, checkColorParses(aqua, 'blue'));

/**
 * Helper class for constructing a full theme color palette based on an initial (partial or complete)
 *  set of colors.
 */
export class ColorBuilder {
  constructor(
    private colors: BrandingThemeColors,
    private fontColors: BrandingThemeFontColors = {},
    private darkMode: boolean = false
  ) {
    //
  }

  accentShades() {
    const { accent, grey } = this.colors;
    const builtColors = {
      ...buildAccentShades(accent),
      ...this.colors,
    };

    if (!this.darkMode) {
      return builtColors;
    }

    const base = checkColorParses(grey, 'grey');

    return {
      ...builtColors,
      accentBackground: shade(0.7, base),
    };
  }

  greyShades() {
    const { grey } = this.colors;

    const builtColors = {
      ...buildGreyShades(grey),
      ...this.colors,
      darkAlways: this.colors.dark,
      lightAlways: this.colors.light,
    };

    if (!this.darkMode) {
      return builtColors;
    }

    const base = checkColorParses(grey, 'grey');

    return {
      light: shade(0.78, base),
      lightAlways: this.colors.light,
      dark: shade(0.65, base),
      darkAlways: this.colors.dark,
      placeholder: base,
      disabled: base,
      grey50: shade(0.7, base),
      grey3: shade(0.8, base),
      grey5: shade(0.95, base),
      grey10: shade(0.7, base),
      grey20: shade(0.7, base),
      grey25: shade(0.5, base),
      grey40: shade(0.2, base),
      grey70: shade(0.7, base),
      grey75: this.colors.dark,
      grey90: shade(0.8, base),
      grey95: shade(0.78, base),
      secondaryButtonColor: this.colors.light,
    };
  }

  statusShades() {
    const {
      error,
      error10 = buildLightStatusColor(error),
      error25 = tint(0.75, error),
      errorB25 = shade(0.25, error),
      warning,
      warning10 = buildLightStatusColor(warning),
      success,
      success10 = buildLightStatusColor(success),
      successB25 = shade(0.25, success),
      info,
      info10 = buildLightStatusColor(info),
    } = this.colors;

    return {
      error,
      error10,
      error25,
      errorB25,
      warning,
      warning10,
      success,
      success10,
      successB25,
      info,
      info10,
    };
  }

  greenShades() {
    const { green, greenBright = buildBrightGreenColor(green) } = this.colors;

    return {
      green,
      greenBright,
    };
  }

  aquaShades() {
    const { aqua, aquaB10 = buildDarkAquaColor(aqua) } = this.colors;

    return {
      aqua,
      aquaB10,
    };
  }

  private buildColors(): Record<Colors, string> {
    const completeColors = {
      ...this.colors,
      ...this.accentShades(),
      ...this.greyShades(),
      ...this.statusShades(),
      ...this.greenShades(),
      ...this.aquaShades(),
    };
    this.colors = completeColors;
    return completeColors;
  }

  private buildFontColors(): Record<FontColors, string> {
    const {
      dark = this.colors.dark,
      light = this.colors.light,
      error = this.colors.error,
      warning = this.colors.warning,
      success = this.colors.success,
      info = this.colors.info,
      disabled = this.colors.disabled,
      placeholder = this.colors.placeholder,
      grey = this.colors.grey,
      azure = this.colors.azure,
      orange = this.colors.orange,
      blue = this.colors.blue,
      purple = this.colors.purple,
    } = this.fontColors;

    if (this.darkMode) {
      const fontColors = {
        light,
        dark: light,
        accent: this.colors.accent,
        error,
        warning,
        success,
        info,
        disabled: this.colors.grey,
        placeholder,
        grey,
        azure,
        orange,
        blue,
        purple,
        darkAlways: dark,
      };

      this.fontColors = fontColors;
      return fontColors;
    }

    const fontColors = {
      dark,
      light,
      accent: this.colors.accent,
      error,
      warning,
      success,
      info,
      disabled,
      placeholder,
      grey,
      azure,
      orange,
      blue,
      purple,
      darkAlways: dark,
    };

    this.fontColors = fontColors;
    return fontColors;
  }

  build(): { colors: Record<Colors, string>; fontColors: Record<FontColors, string> } {
    const colors = this.buildColors();
    const fontColors = this.buildFontColors();

    return { colors, fontColors };
  }
}
