import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';

const container = () =>
  css`
    width: 100px;
    height: 26px;
    display: flex;
    alien-items: center;
  `;

const mardiaDbIcon = (theme: Theme) => css`
  fill: ${theme.settings.darkMode ? theme.fontColors.light : '#003545'};
`;

export const style = { container, mardiaDbIcon };
