import { InstallableWebsiteApp, WebsiteAppKind } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { padToPatchVersion } from 'src/utils/version/version';

import { queryKeys } from '../query-keys';
import { appsApi } from './service';

export const useGetWebsiteAppsQuery = ({ orgId, websiteId }: Parameters<typeof appsApi.getWebsiteApps>[0]) => {
  return useQuery({
    queryKey: queryKeys.websiteApps({ orgId, websiteId }),
    queryFn: () => appsApi.getWebsiteApps({ orgId, websiteId }),
    select: (data) => data.data.items,
  });
};

const getWordPressVersions = (apps: InstallableWebsiteApp[]) =>
  apps.filter(({ app }) => app === WebsiteAppKind.wordpress).map(({ version }) => padToPatchVersion(version));

// possibly need to pass in website here not just subscription ID to delay both queries
export const useGetInstallableAppsQuery = ({
  orgId,
  subscriptionId,
  websiteLoading = false,
}: {
  orgId: string;
  subscriptionId?: number;
  websiteLoading?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.installableApps({ orgId, subscriptionId }),
    queryFn: () => {
      if (subscriptionId === undefined) {
        return appsApi.getGlobalInstallableApps({});
      } else {
        return appsApi.getInstallableApps({ orgId, subscriptionId });
      }
    },
    select: (data) => getWordPressVersions(data.data.items),
    enabled: !websiteLoading,
  });
};

export const useDeleteWebsiteAppMutation = () => useMutation({ mutationFn: appsApi.deleteWebsiteApp });

export const useCreateWebsiteAppMutation = () => useMutation({ mutationFn: appsApi.createWebsiteApp });
