import { brandingApi } from 'src/api_services/branding/service';
import { UpdateBrandingReq, UpdateBrandingRes } from 'src/api_services/branding/types';
import { createActionsFromMethod, createRequestStateActions } from 'src/store/helpers';

const namespace = '@branding';

const getBranding = createActionsFromMethod<typeof brandingApi.getBranding>(`getBranding(${namespace})`);

const updateBranding = createRequestStateActions<{ params: UpdateBrandingReq; data: UpdateBrandingRes }>(
  `updateBranding(${namespace})`
);

const updateBrandingSetting = createActionsFromMethod<typeof brandingApi.updateBrandingSetting>(
  `updateBrandingSetting(${namespace})`
);

const resetBranding = createRequestStateActions<{ params: { orgId: string }; data: void }>(
  `resetBranding(${namespace})`
);

const getImpersonationBranding = createActionsFromMethod<typeof brandingApi.getBranding>(
  `getImpersonationBranding(${namespace})`
);

const updateImpersonationBranding = createRequestStateActions<{
  params: UpdateBrandingReq;
  data: UpdateBrandingRes;
}>(`updateImpersonationBranding(${namespace})`);

const setBrandInverseLogo = createActionsFromMethod<typeof brandingApi.setBrandInverseLogo>(
  `setBrandInverseLogo(${namespace})`
);

const setBrandLogo = createActionsFromMethod<typeof brandingApi.setBrandLogo>(`setBrandLogo(${namespace})`);

const setBrandFavicon = createActionsFromMethod<typeof brandingApi.setBrandFavicon>(`setBrandFavicon(${namespace})`);

const setBrandInverseIcon = createActionsFromMethod<typeof brandingApi.setBrandInverseIcon>(
  `setBrandInverseIcon(${namespace})`
);

const setBrandLoginImage = createActionsFromMethod<typeof brandingApi.setBrandLoginImage>(
  `setBrandLoginImage(${namespace})`
);

const deleteBrandLogo = createActionsFromMethod<typeof brandingApi.deleteBrandLogo>(`deleteBrandLogo(${namespace})`);

const deleteInverseBrandLogo = createActionsFromMethod<typeof brandingApi.deleteInverseBrandLogo>(
  `deleteInverseBrandLogo(${namespace})`
);

const deleteBrandFavicon = createActionsFromMethod<typeof brandingApi.deleteBrandFavicon>(
  `deleteBrandFavicon(${namespace})`
);

const deleteBrandInverseIcon = createActionsFromMethod<typeof brandingApi.deleteBrandInverseIcon>(
  `deleteBrandInverseIcon(${namespace})`
);

const deleteBrandLoginImage = createActionsFromMethod<typeof brandingApi.deleteBrandLoginImage>(
  `deleteBrandLoginImage(${namespace})`
);

const createNameServerDomain = createActionsFromMethod<typeof brandingApi.createNameServerDomain>(
  `createNameServerDomain(${namespace})`
);

const updateNameServerDomain = createActionsFromMethod<typeof brandingApi.updateNameServerDomain>(
  `updateNameServerDomain(${namespace})`
);

const setStagingDomain = createActionsFromMethod<typeof brandingApi.setStagingDomain>(`setStagingDomain(${namespace})`);

const getStagingDomain = createActionsFromMethod<typeof brandingApi.getStagingDomain>(`getStagingDomain(${namespace})`);

export const brandingActions = {
  createNameServerDomain,
  deleteBrandFavicon,
  deleteBrandInverseIcon,
  deleteBrandLoginImage,
  deleteBrandLogo,
  deleteInverseBrandLogo,
  getBranding,
  getImpersonationBranding,
  getStagingDomain,
  resetBranding,
  setBrandFavicon,
  setBrandInverseIcon,
  setBrandLoginImage,
  setBrandLogo,
  setBrandInverseLogo,
  setStagingDomain,
  updateBranding,
  updateBrandingSetting,
  updateImpersonationBranding,
  updateNameServerDomain,
};
