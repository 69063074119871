import { breakpoints } from 'src/design-system/constants';

export const isMobile = (viewportWidth: number) => viewportWidth >= breakpoints.xs && viewportWidth < breakpoints.sm;
export const isMobileUp = (viewportWidth: number) => viewportWidth >= breakpoints.xs;

export const isTablet = (viewportWidth: number) => viewportWidth >= breakpoints.sm && viewportWidth < breakpoints.md;
export const isTabletUp = (viewportWidth: number) => viewportWidth >= breakpoints.sm;
export const isBelowTablet = (viewportWidth: number) => viewportWidth < breakpoints.sm;

export const isDesktop = (viewportWidth: number) => viewportWidth >= breakpoints.md && viewportWidth < breakpoints.lg;
export const isDesktopUp = (viewportWidth: number) => viewportWidth >= breakpoints.md;
export const isBelowDesktop = (viewportWidth: number) => viewportWidth < breakpoints.md;

export const isLargeDesktop = (viewportWidth: number) =>
  viewportWidth >= breakpoints.lg && viewportWidth < breakpoints.xl;
export const isLargeDesktopUp = (viewportWidth: number) => viewportWidth >= breakpoints.lg;
export const isBelowLargeDesktop = (viewportWidth: number) => viewportWidth < breakpoints.lg;

export const isExtraLargeDesktop = (viewportWidth: number) => viewportWidth >= breakpoints.xl;
export const isExtraLargeDesktopUp = (viewportWidth: number) => viewportWidth >= breakpoints.xl;
export const isBelowExtraLargeDesktop = (viewportWidth: number) => viewportWidth < breakpoints.xl;
