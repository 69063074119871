import React, { memo, ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { Box } from 'src/components/Box';
import { Text } from 'src/components/Text';
import { Tooltip } from 'src/components/Tooltip';
import { FontSizes } from 'src/design-system/style-types';
import { useResizeObserver } from 'src/hooks/useResizeObserver/useResizeObserver';
import { truncateName } from 'src/utils/middleTruncationHelper/utils';

import { DefaultProps as TextProps } from '../Text/Text';

interface Props extends Partial<TextProps> {
  text: string;
  type?: 'file' | 'domain';
  showTooltip?: boolean;
  delayDuration?: number;
  suffix?: ReactNode;
  prefix?: ReactNode;
  size?: FontSizes;
  disableTooltipOnClick?: boolean;
  enableRecalculateWidth?: boolean;
  splitRatio?: number;
  j?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'center';
}
export const _MiddleTruncate = ({
  text,
  showTooltip = true,
  delayDuration = 400,
  suffix,
  prefix,
  size = 'sm',
  disableTooltipOnClick = true,
  enableRecalculateWidth = true,
  splitRatio = -10,
  j = 'flex-start',
  ...textProps
}: Props) => {
  const containerRef = useRef<HTMLElement | null>(null);
  const suffixRef = useRef<HTMLElement | null>(null);
  const prefixRef = useRef<HTMLElement | null>(null);

  const [width, setWidth] = useState(0);

  const recalculateWidth = useCallback(
    () => {
      const prefixWidth = prefixRef.current?.offsetWidth ?? 0;
      const suffixWidth = suffixRef.current?.offsetWidth ?? 0;

      if (containerRef.current?.offsetWidth) {
        setWidth(containerRef.current?.offsetWidth - prefixWidth - suffixWidth);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useLayoutEffect(() => {
    recalculateWidth();
  }, [recalculateWidth]);

  useResizeObserver({
    elRef: containerRef,
    onResizeCallback: enableRecalculateWidth ? recalculateWidth : () => {},
  });

  const truncatedName = useMemo(() => {
    const maxCharactersRatio = {
      xs: 7,
      sm: 8,
      md: 9,
      lg: 10,
      xl: 13,
      '2xl': 15,
      '3xl': 20,
      '4xl': 24,
      '5xl': 34,
    }[size ?? 'sm'];

    return truncateName({ text, width, splitRatio, maxCharactersRatio });
  }, [text, width, size, splitRatio]);

  return (
    <Box innerRef={containerRef} a="center" wrap="nowrap" j={j}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {prefix && (
          <Box innerRef={prefixRef} a="center" w="auto">
            {prefix}
          </Box>
        )}

        <Box a="center" w="auto" minW="0" wrap="nowrap">
          {showTooltip && truncatedName !== text ? (
            <Tooltip
              disableTooltipOnClick={disableTooltipOnClick}
              placement="top-start"
              delayDuration={delayDuration}
              button={
                <Box a="flex-start" wrap="nowrap" minW="0">
                  <Text ariaLabel={text} {...textProps} size={size} fullWidth whiteSpace="nowrap">
                    {truncatedName}
                  </Text>
                </Box>
              }
            >
              <Text size="sm" color="light">
                {text}
              </Text>
            </Tooltip>
          ) : (
            <Text ariaLabel={text} {...textProps} size={size} fullWidth whiteSpace="nowrap">
              {truncatedName}
            </Text>
          )}
        </Box>
      </div>

      {suffix && (
        <Box innerRef={suffixRef} a="center" w="auto">
          {suffix}
        </Box>
      )}
    </Box>
  );
};

export const MiddleTruncate = memo(_MiddleTruncate);
