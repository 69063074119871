import { useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface Props {
  elRef: React.MutableRefObject<HTMLElement | null>;
  onResizeCallback: () => void;
}

export const useResizeObserver = ({ elRef, onResizeCallback }: Props) => {
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        // see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        return onResizeCallback();
      });
    });

    const watchedRef = elRef.current;

    if (watchedRef) {
      resizeObserver.observe(watchedRef);
    }

    return () => {
      if (watchedRef) {
        resizeObserver.unobserve(watchedRef);
      }
      resizeObserver.disconnect();
    };
  }, [onResizeCallback, elRef]);

  return null;
};
