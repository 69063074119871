import { css } from '@emotion/core';

import { margin, shadowBorder } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './IconButton';

const base = (props: Props) => (theme: Theme) =>
  css`
    &:focus-visible {
      outline: blue auto 1px;
    }
    cursor: pointer;
    position: relative;
    user-select: none;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${props.negativeMargin &&
    css`
      margin: -6px;
    `}

    ${margin(props)(theme)};

    ${props.size === 'lg' &&
    css`
      height: ${theme.sizes['2xl']}px;
      width: ${theme.sizes['2xl']}px;
      min-width: ${theme.sizes['2xl']}px;
      min-height: ${theme.sizes['2xl']}px;
    `}
    ${props.size === 'md' &&
    css`
      height: ${theme.sizes['2xl']}px;
      width: ${theme.sizes['2xl']}px;
      min-width: ${theme.sizes['2xl']}px;
      min-height: ${theme.sizes['2xl']}px;
    `}

    ${responsive(props.padding, (padding) => {
      return css`
        padding: ${padding};
      `;
    })}

  ${props.disabled &&
    css`
      cursor: default;
    `}

    ${!props.disabled &&
    css`
      :hover {
        ${(props.variant === 'primary' || props.variant === 'secondary' || props.variant === 'red') &&
        css`
          .ui-svg-hoverable {
            color: ${theme.fontColors.light};
            fill: ${theme.fontColors.light};
          }
        `}
        ${props.variant === 'delete' &&
        css`
          .ui-svg-hoverable {
            color: ${theme.fontColors.error};
            fill: ${theme.fontColors.error};
          }
        `}
      }
    `}
    ${props.disabled &&
    css`
      cursor: auto;
      pointer-events: none;
    `}

    ${props.showNotificationBadge &&
    css`
      :after {
        content: '';
        position: absolute;
        top: 5px;
        right: 1px;
        background: ${theme.colors.error};
        color: white;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    `}
  `;

const primaryVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: ${theme.colors.accent};

    ${!props.disabled &&
    css`
      :hover {
        background-color: ${theme.colors.accentB25};
      }
    `}

    ${props.disabled &&
    css`
      background-color: ${theme.colors.grey10};
    `}
  `;

const secondaryVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;
    ${shadowBorder('secondaryButtonColor')(theme)}

    ${!props.disabled &&
    css`
      :hover {
        background-color: ${theme.colors.dark};
      }
    `}

  ${props.disabled &&
    css`
      ${shadowBorder('grey10')(theme)}
    `}
  `;

const tertiaryVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;

    ${!props.disabled &&
    css`
      :hover {
        background-color: ${theme.colors.grey3};
      }
    `}
  `;

const darkVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;

    ${!props.disabled &&
    css`
      :hover {
        .ui-svg-hoverable {
          color: ${theme.fontColors.light};
          fill: ${theme.fontColors.light};
        }

        background-color: ${theme.colors.dark};
      }
    `}
  `;

const lightVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;

    ${!props.disabled &&
    css`
      :hover {
        background-color: ${theme.colors.grey70};
      }
    `}
  `;

const redVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;
    ${shadowBorder('error')(theme)}

    ${!props.disabled &&
    css`
      :hover {
        background-color: ${theme.colors.error};
      }
    `}

    ${props.disabled &&
    css`
      ${shadowBorder('grey10')(theme)}
    `}
  `;

const deleteAltVariant = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;
    border: 1px solid transparent;

    svg {
      fill: ${theme.fontColors.dark};
    }

    ${!props.disabled &&
    css`
      &:hover {
        border-color: ${theme.colors.error};
        svg {
          fill: ${theme.fontColors.error};
        }
      }

      &:active {
        background-color: ${theme.colors.error};
        svg {
          fill: ${theme.fontColors.light};
        }
      }
    `}
  `;

const container = (props: Props) => (theme: Theme) =>
  css`
    ${base(props)(theme)}

    ${responsive(props.variant, (variant) => {
      switch (variant) {
        case 'primary':
          return primaryVariant(props)(theme);
        case 'secondary':
          return secondaryVariant(props)(theme);
        case 'tertiary':
        case 'delete':
          return tertiaryVariant(props)(theme);
        case 'light':
          return lightVariant(props)(theme);
        case 'red':
          return redVariant(props)(theme);
        case 'delete-alt':
          return deleteAltVariant(props)(theme);
        case 'dark':
          return darkVariant(props)(theme);
      }
    })}

    ${props.hoverBackgroundColor &&
    css`
      :hover {
        background-color: ${theme.colors[props.hoverBackgroundColor]};
      }
    `}
  `;

export const style = { container };
