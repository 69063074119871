import { customersApi } from 'src/api_services/customers/service';
import { extendedOrgsApi } from 'src/api_services/orgs/service';
import { websitesApi } from 'src/api_services/websites';
import { createActionsFromMethod, createRequestStateActions } from 'src/store/helpers';

import { AddCustomerReq } from './types';

const namespace = '@customers';

const getCustomers = createActionsFromMethod<typeof customersApi.getOrgCustomers>(`getCustomers(${namespace})`);

const getCustomer = createActionsFromMethod<typeof extendedOrgsApi.getOrg>(`getCustomer(${namespace})`);

const addCustomer = createRequestStateActions<{ params: AddCustomerReq; data: { newCustomerId: string } }>(
  `addCustomer(${namespace})`
);

const updateCustomer = createActionsFromMethod<typeof extendedOrgsApi.updateOrg>(`updateCustomer(${namespace})`);

const deleteCustomer = createActionsFromMethod<typeof extendedOrgsApi.deleteOrg>(`deleteCustomer(${namespace})`);

const getCustomerSubscriptions = createActionsFromMethod<typeof customersApi.getCustomerSubscriptions>(
  `getCustomerSubscriptions(${namespace})`
);

const createCustomerSubscription = createActionsFromMethod<typeof customersApi.createCustomerSubscription>(
  `createCustomerSubscription(${namespace})`
);

const getCustomerWebsites = createActionsFromMethod<typeof websitesApi.getWebsites>(
  `getCustomerWebsites(${namespace})`
);

export const customersActions = {
  getCustomers,
  getCustomer,
  addCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerSubscriptions,
  createCustomerSubscription,
  getCustomerWebsites,
};
