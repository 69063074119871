/** @jsxRuntime classic */
/** @jsx jsx */
import React, { RefObject } from 'react';
import { jsx } from '@emotion/core';

import { Box } from 'src/components/Box';
import { Margins, Responsive } from 'src/design-system/style-types';

import { style } from './TextInput.style';

interface DefaultProps {
  name: string;
  autoComplete: string;
  className: string;
  disabled: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  err: string | boolean;
  icon?: React.ReactNode;
  iconClick?: () => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  placeholder: string;
  prefix?: string;
  suffix?: string;
  touched?: boolean;
  autoFocus?: boolean;
  type: 'text' | 'email' | 'password' | 'number' | 'search';
  value: string;
  marginBottom: Responsive<Margins>;
  size: 'small' | 'normal';
  // min and max only useful when input type is number
  min?: number;
  max?: number;
  inputWidth?: Responsive<string>;
  inputMaxWidth: Responsive<string>;
  suffixMaxWidth?: Responsive<string>;
  forceSmallText?: boolean;
}

export type Props = DefaultProps;

const defaultProps: DefaultProps = {
  name: '',
  autoComplete: '',
  className: '',
  disabled: false,
  err: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  onPaste: () => {},
  onClick: () => {},
  placeholder: '',
  touched: false,
  type: 'text',
  value: '',
  marginBottom: '0',
  size: 'normal',
  inputMaxWidth: '100%',
  suffixMaxWidth: '30%',
  forceSmallText: false,
};

export const TextInput = (props: Props) => {
  const {
    className,
    err,
    icon,
    iconClick,
    size,
    inputRef,
    prefix,
    suffix,
    autoComplete,
    disabled,
    name,
    onBlur,
    onFocus,
    onChange,
    onKeyPress,
    onKeyDown,
    onKeyUp,
    onPaste,
    onClick,
    placeholder,
    autoFocus,
    type,
    value,
    min,
    max,
    forceSmallText,
  } = props;

  const iconSize = size === 'normal' ? '40px' : '32px';

  const prefixRef = React.useRef<HTMLDivElement>(null);
  const suffixRef = React.useRef<HTMLDivElement>(null);

  const renderInput = () => {
    return (
      <input
        ref={inputRef}
        aria-label={name}
        aria-labelledby={name}
        aria-invalid={err ? 'true' : 'false'}
        aria-describedby={`err-${name}`}
        autoComplete={autoComplete}
        css={style.input(props, {
          prefixWidth: prefixRef.current?.clientWidth || 0,
          suffixWidth: suffixRef.current?.clientWidth || 0,
          forceSmallText,
        })}
        disabled={disabled}
        autoFocus={autoFocus}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
        onClick={onClick}
        placeholder={placeholder}
        type={type}
        step={type === 'number' ? 'any' : undefined}
        value={value}
        min={min}
        max={max}
      />
    );
  };

  if (icon || prefix || suffix) {
    return (
      <div css={style.container} className={className}>
        {prefix && (
          <div ref={prefixRef} css={style.prefix(props)}>
            {prefix}
          </div>
        )}
        {renderInput()}
        {icon && (
          <Box w={iconSize} h={iconSize} j="center" a="center" css={style.icon}>
            <button
              type="button"
              css={style.iconButton}
              onClick={(e) => {
                e.preventDefault();
                iconClick?.();
              }}
            >
              {icon}
            </button>
          </Box>
        )}
        {suffix && (
          <div ref={suffixRef} css={style.suffix(props)}>
            {suffix}
          </div>
        )}
      </div>
    );
  }

  return renderInput();
};

TextInput.defaultProps = defaultProps;
