import { AxiosError } from 'axios';

export function isAxiosError(e: any): e is AxiosError {
  return !!e?.isAxiosError;
}

interface ErrorWithMessage extends Error {
  message: string;
}

export const isError = (error: unknown): error is Error => {
  return Object.prototype.toString.call(error) === '[object Error]';
};

export const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return isError(error) && 'message' in error && typeof (error as Error).message === 'string';
};

export interface UsefulErrorInfo {
  message: string;
  code: string;
  detail: string;
  statusText: string;
  status?: number;
  axiosCode?: string;
  data?: any;
}

export const getErrorInfo = (e: unknown): UsefulErrorInfo => {
  const errorInfo: UsefulErrorInfo = {
    message: '',
    code: '',
    detail: '',
    statusText: '',
    axiosCode: '',
  };

  if (isAxiosError(e)) {
    const { response, code } = e;
    if (response?.data?.message) errorInfo.message = response.data.message;
    if (response?.data?.code) errorInfo.code = response.data.code;
    if (response?.data?.detail) errorInfo.detail = response.data.detail;
    if (response?.statusText) errorInfo.statusText = response.statusText;
    if (response?.status) errorInfo.status = response.status;
    if (code) errorInfo.axiosCode = code;
  } else if (isErrorWithMessage(e)) {
    errorInfo.message = e.message;
  }
  return errorInfo;
};
