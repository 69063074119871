import React from 'react';
import { connect } from 'react-redux';

import { Box } from 'src/components/Box';
import { Checkbox } from 'src/components/Checkbox';
import { Text } from 'src/components/Text';
import { AppState } from 'src/store';
import { devtoolsActions } from 'src/store/devtools/actions';
import { devtoolsSelectors } from 'src/store/devtools/selectors';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

type Props = ActionProps & StateProps;

export const _ReactQueryDevtools = ({ isReactQueryDevtoolsActive, toggleReactQueryDevtools }: Props) => {
  return (
    <Box p="lg">
      <Text size="sm" weight="bold" marginBottom="sm">
        React query devtools
      </Text>

      <Checkbox
        name="showReactQueryDevtools"
        onChange={({ checked }) => toggleReactQueryDevtools({ open: checked })}
        checked={isReactQueryDevtoolsActive}
        size="md"
        label={<Text size="sm">Show react query devtools</Text>}
        marginBottom="0"
      />
    </Box>
  );
};

const stateToProps = (state: AppState) => ({
  isReactQueryDevtoolsActive: devtoolsSelectors.isReactQueryDevtoolsActive(state),
});

export const dispatchToProps = {
  toggleReactQueryDevtools: devtoolsActions.toggleReactQueryDevtools,
};

export const ReactQueryDevtools = connect(stateToProps, dispatchToProps)(_ReactQueryDevtools);
