import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { Icon } from 'src/components/Icon';
import { IconName } from 'src/components/Icon/Icon.types';
import { Text } from 'src/components/Text';
import { Tooltip } from 'src/components/Tooltip';
import { FontColors } from 'src/design-system/style-types';
import { SslStatusTypes } from 'src/utils/types';

interface Props {
  iconOnly?: boolean;
  name: string;
  status: SslStatusTypes;
  isSelfSigned: boolean;
}

interface StatusProps {
  color: FontColors;
  icon: IconName;
  text: string;
}

export const SslStatus = ({ name, isSelfSigned, status = 'disabled' }: Props) => {
  const history = useHistory();
  const { websiteId } = useParams<{ websiteId: string }>();

  const { formatMessage } = useIntl();

  const getStatusProps = (): StatusProps => {
    if (isSelfSigned) {
      return {
        color: 'warning',
        icon: 'lock ssl',
        text: formatMessage({
          id: 'website.domains.ssl_self_signed',
          defaultMessage: 'This website is secured by a self signed SSL Certificate',
        }),
      };
    }
    if (status === 'enabled') {
      return {
        color: 'success',
        icon: 'lock ssl',
        text: formatMessage({
          id: 'website.domains.ssl_protected',
          defaultMessage: 'This website is secured by a signed SSL Certificate',
        }),
      };
    }
    if (status === 'expired') {
      return {
        color: 'error',
        icon: 'lock open',
        text: formatMessage({ id: 'website.domains.ssl_expired', defaultMessage: 'SSL expired' }),
      };
    }
    return {
      color: 'grey',
      icon: 'lock open',
      text: formatMessage({
        id: 'website.domains.no_ssl',
        defaultMessage: 'This website is not protected by an SSL Certificate',
      }),
    };
  };

  const { color, icon, text } = getStatusProps();

  return (
    <Tooltip
      disableTooltipOnClick
      placement="bottom"
      button={
        <Icon
          color={color}
          name={icon}
          size="lg"
          marginRight="sm"
          onClick={() => {
            history.push(`/websites/${websiteId}/security`);
          }}
        />
      }
    >
      <Text size="sm" maxWidth="250px" color="light">
        {text}
      </Text>
    </Tooltip>
  );
};
