import { getType } from 'deox';
import { combineReducers } from 'redux';
import { takeEvery } from 'redux-saga/effects';

import { websitesApi } from 'src/api_services/websites/service';
import { AppState } from 'src/store';
import { createActionsFromMethod, createRequestStateReducer, createRequestStateSaga } from 'src/store/helpers';

const PREFIX = `sshKeysManager`;

const getWebsiteSshKeys = createActionsFromMethod<typeof websitesApi.getWebsiteSshKeys>(`getWebsiteSshKeys(${PREFIX})`);
const authorizeWebsiteSshKey = createActionsFromMethod<typeof websitesApi.authorizeWebsiteSshKey>(
  `authorizeWebsiteSshKey(${PREFIX})`
);
const unauthorizeWebsiteSshKey = createActionsFromMethod<typeof websitesApi.unauthorizeWebsiteSshKey>(
  `unauthorizeWebsiteSshKey(${PREFIX})`
);
const updateWebsiteSshKey = createActionsFromMethod<typeof websitesApi.updateWebsiteSshKey>(
  `updateWebsiteSshKey(${PREFIX})`
);

export const sshKeysManagerActions = {
  getWebsiteSshKeys,
  authorizeWebsiteSshKey,
  unauthorizeWebsiteSshKey,
  updateWebsiteSshKey,
};

const [getWebsiteSshKeysSaga] = createRequestStateSaga(getWebsiteSshKeys, websitesApi.getWebsiteSshKeys);
const [authorizeWebsiteSshKeySaga] = createRequestStateSaga(authorizeWebsiteSshKey, websitesApi.authorizeWebsiteSshKey);
const [unauthorizeWebsiteSshKeySaga] = createRequestStateSaga(
  unauthorizeWebsiteSshKey,
  websitesApi.unauthorizeWebsiteSshKey
);
const [updateWebsiteSshKeySaga] = createRequestStateSaga(updateWebsiteSshKey, websitesApi.updateWebsiteSshKey);

export function* sshKeysManagerSaga() {
  yield takeEvery(getType(getWebsiteSshKeys.request), getWebsiteSshKeysSaga);
  yield takeEvery(getType(authorizeWebsiteSshKey.request), authorizeWebsiteSshKeySaga);
  yield takeEvery(getType(unauthorizeWebsiteSshKey.request), unauthorizeWebsiteSshKeySaga);
  yield takeEvery(getType(updateWebsiteSshKey.request), updateWebsiteSshKeySaga);
}

const getWebsiteSshKeysReducer = createRequestStateReducer(getWebsiteSshKeys);

export const sshKeysManagerReducer = combineReducers({
  sshKeys: getWebsiteSshKeysReducer,
});

export const sshKeysManagerSelectors = {
  selectSshKeys: (state: AppState) => state.sshKeysManager.sshKeys,
};
