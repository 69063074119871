import {
  CloudFlareNameServersStatusEnum,
  DnsStatus,
  DomainMappingKind,
  NewMappedDomain,
  Website,
} from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { websitesApi } from '../websites';
import { domainsApi } from './service';

export const useGetDomainResolvesQuery = ({
  isCloudflare,
  orgId,
  domainId,
  websiteId,
}: {
  isCloudflare: boolean;
  orgId: string;
  domainId: string;
  websiteId: string;
}) => {
  return useQuery({
    queryKey: queryKeys.getDomainResolves({ orgId, domainId }),
    queryFn: async () => {
      if (isCloudflare) {
        return domainsApi.getCloudflareNameServers({ orgId, domainId });
      } else {
        return websitesApi.getWebsiteDomainMappingDnsStatus({ orgId, domainId, websiteId });
      }
    },
    select: ({ data }) => ({
      resolves:
        typeof data === 'string' ? data === DnsStatus.Resolved : data.status === CloudFlareNameServersStatusEnum.active,
      data,
    }),
    refetchOnMount: true,
  });
};

export const useGetCloudflareNameServersQuery = ({
  orgId,
  domainId,
  enabled,
}: {
  orgId: string;
  domainId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getCloudflareNameServers({ orgId, domainId }),
    queryFn: () => domainsApi.getCloudflareNameServers({ orgId, domainId }),
    select: (data) => data.data,
    refetchOnMount: true,
    enabled,
  });
};

export const useGetCloudflareApiKeyDomainQuery = ({
  orgId,
  domainId,
  enabled,
}: {
  orgId: string;
  domainId: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getCloudflareApiKeyDomain({ orgId, domainId }),
    queryFn: () => domainsApi.getCloudflareApiKeyDomain({ orgId, domainId }),
    select: (data) => data.data,
    retry: false,
    refetchOnMount: true,
    enabled,
  });
};

export const useSetCloudflareApiKeyIdMutation = () => useMutation({ mutationFn: domainsApi.setCloudflareApiKeyId });

export const useDeleteCloudflareApiKeyIdMutation = () =>
  useMutation({ mutationFn: domainsApi.deleteCloudflareApiKeyId });

export const useGetWebsiteDomainMappingsQuery = ({
  filterPreviewDomains = true,
  enabled = true,
  ...params
}: Parameters<typeof domainsApi.getWebsiteDomainMappings>[0] & {
  enabled?: boolean;
  filterPreviewDomains?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.websiteDomainMappings(params),
    queryFn: () => domainsApi.getWebsiteDomainMappings(params),
    select: filterPreviewDomains
      ? (data) => data.data.items.filter((domain) => domain.mappingKind !== DomainMappingKind.preview)
      : (data) => data.data.items,
    refetchOnMount: true,
    enabled,
  });
};

export const useGetWebsiteDomainMappingQuery = (params: Parameters<typeof domainsApi.getWebsiteDomainMapping>[0]) => {
  return useQuery({
    queryKey: queryKeys.websiteDomainMappings(params),
    queryFn: () => domainsApi.getWebsiteDomainMapping(params),
    select: (data) => data.data,
    refetchOnMount: true,
  });
};

export const useGetDomainAuthNsQuery = ({
  orgId,
  domainId,
  enabled = true,
}: {
  orgId: string;
  domainId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.domainAuthNs({ orgId, domainId }),
    queryFn: () => domainsApi.getDomainAuthNs({ orgId, domainId }),
    select: (data) => data.data,
    refetchOnMount: true,
    enabled,
  });

export const useCreateWebsiteDomainLetsencryptCertsMutation = () =>
  useMutation({ mutationFn: domainsApi.createWebsiteDomainLetsencryptCerts });

export const useGetWebsiteDomainModSecStatusQuery = ({ domainId }: { domainId: string }) =>
  useQuery({
    queryKey: queryKeys.domainModSecStatus({ domainId }),
    queryFn: () => domainsApi.getWebsiteDomainModSecStatus({ domainId }),
    select: (data) => data.data,
    refetchOnMount: true,
  });

export const useSetWebsiteDomainModSecStatusMutation = () =>
  useMutation({ mutationFn: domainsApi.setWebsiteDomainModSecStatus });

export const useCheckDomainMutation = () => useMutation({ mutationFn: domainsApi.checkDomain });

export const useCreateWebsiteMappedDomainMutation = () =>
  useMutation({ mutationFn: domainsApi.createWebsiteMappedDomain });

export const useGetWebsiteDomainVhostQuery = ({ domainId, enabled = true }: { domainId: string; enabled?: boolean }) =>
  useQuery({
    queryKey: queryKeys.domainVhost({ domainId }),
    queryFn: () => domainsApi.getWebsiteDomainVhost({ domainId }),
    select: (data) => data.data,
    refetchOnMount: true,
    enabled,
  });

export const useSetWebsiteDomainVhostMutation = () => useMutation({ mutationFn: domainsApi.setWebsiteDomainVhost });

export const useDeleteWebsiteDomainVhostMutation = () =>
  useMutation({ mutationFn: domainsApi.deleteWebsiteDomainVhost });

// - Adds the new domain to the website
// - Makes the new domain the website's primary domain
// - Deletes the previous primary domain website
export const useCustomRestoreDeletedWebsiteOldDomainMutation = () => {
  return useMutation({
    mutationFn: async ({
      website,
      orgId,
      newMappedDomain,
    }: {
      orgId: string;
      website: Website;
      newMappedDomain: NewMappedDomain;
    }) => {
      const { id: websiteId } = website;
      const { domain } = newMappedDomain;
      const { data } = await domainsApi.createWebsiteMappedDomain({
        orgId,
        websiteId,
        newMappedDomain: {
          domain,
        },
      });
      const { id } = data;
      await domainsApi.updateWebsitePrimaryDomain({ orgId, websiteId, newPrimaryDomainMapping: { domainId: id } });

      const domainIdToDelete = website.domain.id;
      await domainsApi.deleteDomain({ orgId, domainId: domainIdToDelete });
    },
  });
};

export const useUpdateWebsitePrimaryDomainMutation = () =>
  useMutation({ mutationFn: domainsApi.updateWebsitePrimaryDomain });

export const useCreateWebsiteMailDomainLetsEncryptCertsMutation = () =>
  useMutation({ mutationFn: domainsApi.createWebsiteMailDomainLetsencryptCerts });
