import { ServerRoleState } from 'src/utils/types';

interface Props {
  isUpdating?: boolean;
  roleState: ServerRoleState;
}

type Colors = 'success' | 'error' | 'grey';
type Icons = 'check circle alt' | 'warning alt' | 'loading';

export const getServerStatusIcon = ({ isUpdating = false, roleState }: Props): { name: Icons; color: Colors } => {
  if (roleState === 'online' && !isUpdating) {
    return { name: 'check circle alt', color: 'success' };
  }
  if (roleState === 'offline' && !isUpdating) {
    return { name: 'warning alt', color: 'error' };
  }
  return { name: 'loading', color: 'grey' };
};
