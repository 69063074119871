import React, { ChangeEvent, FocusEvent, ReactNode, RefObject } from 'react';
import FormField from 'src/components/FormField';
import { InformationTooltip } from 'src/components/InformationTooltip/InformationTooltip';
import { Margins, Responsive } from 'src/design-system/style-types';

import { TextInput } from './TextInput';

export interface Props {
  autoComplete?: 'on' | 'off';
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  err?: string | boolean;
  constraint?: ReactNode;
  /** Help text - don't use more than 80 chars */
  help?: ReactNode;
  icon?: ReactNode;
  iconClick?: () => void;
  infoText?: ReactNode;
  infoMaxWidth?: string;
  inputMaxWidth?: Responsive<string>;
  inputRef?: RefObject<HTMLInputElement>;
  label?: ReactNode;
  requiredIndicator?: boolean | ReactNode;
  marginBottom?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  name: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  prefix?: string;
  size?: 'small' | 'normal';
  suffix?: string;
  suffixMaxWidth?: Responsive<string>;
  touched?: boolean;
  type?: 'text' | 'email' | 'password' | 'number' | 'search';
  value?: string;
  inModal?: boolean;
  forceSmallText?: boolean;
}

export const TextInputField = ({
  autoComplete = 'on',
  autoFocus,
  disabled,
  inputRef,
  err,
  constraint = undefined,
  help,
  icon,
  iconClick,
  infoText,
  infoMaxWidth = '350px',
  inputMaxWidth = '100%',
  label,
  name,
  onBlur,
  onFocus,
  onChange,
  placeholder,
  requiredIndicator,
  suffixMaxWidth,
  className = '',
  marginBottom = '0',
  marginRight = '0',
  prefix = '',
  size = 'normal',
  suffix = '',
  touched = false,
  type = 'text',
  value = '',
  inModal = false,
  forceSmallText = false,
}: Props) => {
  return (
    <FormField
      className={className}
      disabled={disabled}
      err={err}
      constraint={constraint}
      help={help}
      name={name}
      label={label}
      requiredIndicator={requiredIndicator}
      marginBottom={marginBottom}
      marginRight={marginRight}
      touched={touched}
    >
      <TextInput
        autoFocus={autoFocus}
        disabled={disabled}
        err={err}
        icon={icon}
        iconClick={iconClick}
        inputRef={inputRef}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        touched={touched}
        size={size}
        type={type}
        value={value}
        prefix={prefix}
        suffix={suffix}
        autoComplete={autoComplete}
        inputMaxWidth={inputMaxWidth}
        suffixMaxWidth={suffixMaxWidth}
        forceSmallText={forceSmallText}
      />
      {infoText && <InformationTooltip inModal={inModal} text={infoText} maxWidth={infoMaxWidth} />}
    </FormField>
  );
};
