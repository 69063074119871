import { ReactNode, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import isFunction from 'lodash/isFunction';

import { FontColors } from 'src/design-system/theme';

import { IconButton } from '../IconButton';
import { style } from './Popover.style';

export type Placement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

type Side = 'top' | 'bottom' | 'right' | 'left';
type Align = 'start' | 'center' | 'end';

export interface Controls {
  toggle: () => void;
  show: () => void;
  hide: () => void;
}

export interface Props {
  children: ReactNode | ((controls: Controls) => ReactNode);
  button?: ReactNode | ((controls: Controls) => ReactNode);
  color?: FontColors;
  placement?: Placement;
  sideOffset?: number;
  alignOffset?: number;
  disabled?: boolean;
  // This prop is a bandaid for our "bad" implementation of low level components where we don't correctly forward props
  // and refs. If the trigger is a component that does correctly forward these, this can be set to true and the trigger
  // will be the button prop itself instead of being wrapped in a div. Mainly needed for `position: fixed` triggers.
  forwarded?: boolean;
  dataDemoDisabled?: boolean;
}

export const Popover = ({
  children,
  button = <IconButton ariaLabel="dropdownMenuButton" icon="dot dot dot" size="md" variant="tertiary" />,
  color = 'dark',
  placement = 'bottom-end',
  sideOffset = 4,
  alignOffset = 0,
  forwarded = false,
  disabled = false,
  dataDemoDisabled,
}: Props) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [side = 'bottom', align = 'center'] = placement.split('-') as [Side, Align];

  const controls = {
    toggle: () => {
      if (open) {
        controls.hide();
        return;
      }
      controls.show();
    },
    show: () => setOpen(true),
    hide: () => {
      setOpen(false);
    },
    hideRefocus: () => {
      triggerRef.current?.querySelector('button')?.focus();
      setOpen(false);
    },
  };

  // If the click outside is the click on the trigger, prevent hiding it as clicking on trigger also toggles.
  const hideUnlessTriggerClick = (event: CustomEvent) => {
    if (
      triggerRef.current &&
      (triggerRef.current.contains(event.target as Node) || triggerRef.current === event.target)
    ) {
      return;
    }

    controls.hide();
  };

  const renderButton = () => (isFunction(button) ? button(controls) : button);
  const container = document.getElementById('modal-radix-root') ?? document.getElementById('radix-root');

  return (
    <Root open={open}>
      <Trigger
        asChild
        ref={triggerRef}
        onClick={(e) => {
          e.stopPropagation();

          if (!disabled) {
            controls.toggle();
          }
        }}
      >
        {forwarded ? renderButton() : <div css={style.popoverTrigger({ disabled })}>{renderButton()}</div>}
      </Trigger>

      <Portal container={container}>
        <Content
          onInteractOutside={hideUnlessTriggerClick}
          onEscapeKeyDown={controls.hideRefocus}
          css={style.popoverContent({ color })}
          side={side}
          align={align}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          role="menu"
          data-demo-disabled={dataDemoDisabled}
        >
          {isFunction(children) ? children(controls) : children}

          <Arrow css={style.popoverArrow({ color })} />
        </Content>
      </Portal>
    </Root>
  );
};
