import React from 'react';
import { WebsiteApp } from 'src/orchd-client';
import orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { useGetDefaultWpSsoUserQuery, useGetWordpressUsersQuery } from 'src/api_services/wordpress/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { sessionSelectors } from 'src/store/session/selectors';
import { toastActions } from 'src/store/toast';
import { websitesSelectors } from 'src/store/websites/selectors';

export const stateToProps = (state: AppState) => ({
  website: websitesSelectors.getWebsite(state),
  websiteLoading: websitesSelectors.getWebsiteLoading(state),
  member: sessionSelectors.getMember(state),
});

export const dispatchToProps = {
  errorToast: toastActions.errorToast,
};

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;
export interface Props extends ActionProps, StateProps {
  app: WebsiteApp;
  usedInDropdown?: boolean;
}

export const loginToAdminUrl = ({
  orgId,
  websiteId,
  appId,
  userId,
}: {
  orgId: string;
  websiteId: string;
  appId: string;
  userId: number;
}) => {
  return `/api/orgs/${orgId}/websites/${websiteId}/apps/${appId}/wordpress/users/${userId}/sso?shouldRedirect=true`;
};

export const _WpAdminButton = ({ app, member, website, websiteLoading, usedInDropdown = false }: Props) => {
  const { orgId } = member;
  const websiteId = website?.id ?? '';
  const appId = app?.id ?? '';

  const { data: defaultWpSsoUser, isLoading } = useGetDefaultWpSsoUserQuery({ appId, orgId, websiteId });
  const { data: wpUsers, isLoading: isLoadingUsers } = useGetWordpressUsersQuery({ appId, orgId, websiteId });

  const firstUserId = orderBy(wpUsers, 'id', 'asc')?.[0]?.id;

  const userId = defaultWpSsoUser?.id ?? firstUserId;

  const isDisabled = !appId || !websiteId || !orgId || isLoading || websiteLoading || !userId || isLoadingUsers;

  const component = usedInDropdown ? (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      py="md"
      pl="lg"
      pr="2xl"
      opacity={isDisabled ? 0.5 : 1}
    >
      <Text size="sm" whiteSpace="nowrap" css={{ color: isDisabled ? 'inherit' : undefined }} fullWidth>
        <FormattedMessage {...messages.login_to_admin} />
      </Text>
    </Box>
  ) : (
    <Button
      iconPosition="right"
      variant="secondary"
      size="small"
      type="submit"
      icon={<Icon name="open in new" hasHoverClass />}
      disabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FormattedMessage {...messages.login_to_admin} />
    </Button>
  );

  if (isDisabled) {
    return component;
  }

  return (
    <a
      href={loginToAdminUrl({ orgId, websiteId, appId, userId })}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {component}
    </a>
  );
};

export const WpAdminButton = connect(stateToProps, dispatchToProps)(_WpAdminButton);
