import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { tableSettingsActions } from './actions';

export const tableSettingsPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(tableSettingsActions.updateFilters):
      persister.set({
        key: 'tableSettings',
        item: store.getState().tableSettings,
        isPerUser: true,
        usePerUserImpersonation: false,
      });
  }

  return res;
};
