import { createReducer } from 'deox';

import { globalUiActions } from './actions';

export interface GlobalUiState {
  darkMode: boolean | undefined;
}

export const initialState: GlobalUiState = {
  darkMode: undefined,
};

const { setDarkMode } = globalUiActions;

export const globalUiReducer = createReducer(initialState, (handleAction) => [
  handleAction(setDarkMode, (state, { payload: { darkMode } }) => {
    return {
      ...state,
      darkMode,
    };
  }),
]);
