/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import Divider from 'src/components/Divider';
import { Paddings, Responsive } from 'src/design-system/style-types';

import { style } from './CardFooter.style';

interface RequiredProps {
  children: React.ReactNode;
}

interface DefaultProps {
  className: string;
  padding: Responsive<Paddings>;
  paddingX?: Responsive<Paddings>;
  paddingY?: Responsive<Paddings>;
  divider: boolean;
}

export type Props = RequiredProps & DefaultProps;

const CardFooter = (props: RequiredProps & DefaultProps) => {
  const { children, className, divider } = props;

  return (
    <div css={style.container} className={className}>
      {divider && <Divider />}
      <div css={style.body(props)}>{children}</div>
    </div>
  );
};

CardFooter.defaultProps = {
  className: '',
  padding: 'lg',
  divider: true,
};

export default CardFooter;
