import { memo, ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { PercentageBar } from 'src/components/PercentageBar';
import { PercentageBarItem } from 'src/components/PercentageBar/PercentageBar';
import { Text } from 'src/components/Text';
import { Tooltip } from 'src/components/Tooltip';
import { Margins, Responsive } from 'src/design-system/style-types';
import { ByteUnits, getByteString, getPercentage } from 'src/utils/helpers';

export interface Props {
  name: string;
  metrics: Metric[];
  isDisabled?: boolean;
  importCheckVariant?: boolean;
  marginBottom?: Responsive<Margins>;
}

export interface Metric {
  bars?: PercentageBarItem[];
  infinite?: boolean;
  label: ReactNode;
  singleBar?: PercentageBarItem;
  tooltip: string;
  total: number;
  barTotal?: number;
  usage: number;
  unit: 'bytes' | 'quantity';
  zeroUnit?: string;
  full?: boolean;
  barTooltip?: ReactNode;
}

interface AdjustBar {
  bar: PercentageBarItem;
  full: boolean;
  unitStr: ByteUnits | undefined;
  total: number;
  infinite?: boolean;
  barTooltip?: ReactNode;
}

const _UsageBars = ({ name, metrics, isDisabled = false, marginBottom = '0', importCheckVariant = false }: Props) => {
  const adjustBar = ({ bar, full, unitStr, total, infinite, barTooltip }: AdjustBar) => {
    const barColor = isDisabled ? 'grey' : bar.color;

    return {
      ...bar,
      color: full && !importCheckVariant ? 'error' : barColor,
      tooltipContent: (
        <Box g="lg" d="column" wrap="nowrap">
          {bar.title && (
            <Text size="xs" color="dark" weight="bold">
              {bar.title}
            </Text>
          )}
          {typeof bar.amount === 'number' && (
            <Text color={bar.title ? 'grey' : 'dark'} size="xs" whiteSpace="nowrap">
              {unitStr ? getByteString(bar.amount, { fixedUnit: unitStr as ByteUnits }) : bar.amount} used
              {!infinite && ` (${getPercentage(bar.amount, total)}%)`}
            </Text>
          )}

          {barTooltip && (
            <Box mt="sm" d="column" wrap="nowrap">
              {barTooltip}
            </Box>
          )}
        </Box>
      ),
    };
  };

  return (
    <Box mb={marginBottom}>
      {metrics.map(
        (
          {
            label,
            tooltip,
            usage,
            total,
            barTotal,
            unit,
            zeroUnit,
            infinite,
            bars,
            singleBar,
            full: overrideFull,
            barTooltip,
          },
          i
        ) => {
          const full = overrideFull ?? (usage >= total && !infinite);

          let usageStr = String(usage);
          let totalStr = String(total);
          let unitStr = '' as ByteUnits;
          if (unit === 'bytes') {
            if (usage === 0 && infinite) {
              usageStr = `0 ${zeroUnit}`;
              totalStr = `0 ${zeroUnit}`;
            } else {
              totalStr = getByteString(total);
              usageStr = getByteString(usage, { fixedUnit: unitStr as ByteUnits });
              unitStr = (usageStr.split(' ')[1] as ByteUnits) ?? (totalStr.split(' ')[1] as ByteUnits);
            }
          }

          const totalWithInfiniteAdjustment = infinite && usage !== 0 ? usage : total;
          const textColor = isDisabled ? 'grey' : 'dark';

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box mb={i + 1 === metrics.length ? '0' : 'lg'} key={i} data-qa="bar">
              <Tooltip
                placement="top-start"
                button={
                  <Text color={textColor} size="xs" transform="uppercase">
                    {label}
                  </Text>
                }
              >
                <Text size="sm" color="light">
                  {tooltip}
                </Text>
              </Tooltip>

              <PercentageBar
                name={`${name}-${i}`}
                total={barTotal ?? totalWithInfiniteAdjustment}
                bars={bars?.map((bar) =>
                  adjustBar({ bar, unitStr, full, total: barTotal ?? total, infinite, barTooltip })
                )}
                singleBar={
                  singleBar &&
                  adjustBar({ bar: singleBar, unitStr, full, total: barTotal ?? total, infinite, barTooltip })
                }
                variant="simple"
                tooltipVariant="full"
                height="small"
                infinite={infinite}
              />
              <Box a="center">
                <Text size="xs" color={full ? 'error' : 'grey'}>
                  {usageStr} <FormattedMessage id="usage_bars.of" defaultMessage="of" />
                </Text>
                {infinite ? (
                  <Icon
                    name="infinite"
                    color="grey"
                    marginLeft="xs"
                    css={css`
                      position: relative;
                      top: 1px;
                    `}
                  />
                ) : (
                  <Text size="xs" color={full ? 'error' : 'grey'}>
                    &nbsp;{totalStr} <FormattedMessage id="usage_bars.used" defaultMessage="used" />
                  </Text>
                )}
              </Box>
            </Box>
          );
        }
      )}
    </Box>
  );
};

export const UsageBars = memo(_UsageBars);
