import { css } from '@emotion/core';

import * as mixins from 'src/design-system/mixins';
import { Sizes } from 'src/design-system/style-types';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { DefaultProps, RequiredProps } from './Icon';

const mixinSize = (size: Sizes | number) => (theme: Theme) =>
  css`
    width: ${typeof size === 'string' ? theme.sizes[size] : size}px;
    height: ${typeof size === 'string' ? theme.sizes[size] : size}px;
  `;

const container = (props: RequiredProps & DefaultProps) => (theme: Theme) =>
  css`
    ${props.onClick &&
    css`
      cursor: pointer;
    `}
    ${props.size && responsive(props.size, (size) => mixinSize(size)(theme))}
    ${props.color && responsive(props.color, (color) => mixins.fontColor(color)(theme))}

    display: flex;
    align-items: center;
    justify-content: center;
    ${props.enableRtl &&
    theme.settings.rtl &&
    css`
      transform: rotate(180deg);
    `}

    ${mixins.margin({
      top: props.marginTop,
      right: props.marginRight,
      bottom: props.marginBottom,
      left: props.marginLeft,
    })(theme)}

  ${props.background &&
    css`
      background: ${theme.colors[props.background]};
    `};

    ${props.borderColor &&
    css`
      border: ${props.borderWidth}px ${props.borderStyle} ${theme.colors[props.borderColor]};
    `};

    ${props.round &&
    css`
      border-radius: 50%;
    `}

    ${props.name === 'loading' &&
    css`
      animation: spin 1s infinite linear;

      @keyframes spin {
        0% {
          transform: scaleX(-1) rotate(360deg);
        }
        100% {
          transform: scaleX(-1) rotate(0deg);
        }
      }
    `}

    ${mixins.hiding(props.hide, 'flex')}

    ${props.height &&
    responsive(
      props.height,
      (height) => css`
        height: ${height};
      `
    )}

    ${props.width &&
    responsive(
      props.width,
      (width) => css`
        width: ${width};
      `
    )}
  `;

const svg = (props: RequiredProps & DefaultProps) => (theme: Theme) =>
  css`
    ${props.rotate && `transform: rotate(${props.rotate}deg);`}

    ${props.size && responsive(props.size, (size) => mixinSize(size)(theme))}

    ${props.color && responsive(props.color, (color) => mixins.fillColor(color)(theme))}

    ${props.round &&
    props.size === '2xl' &&
    css`
      ${mixinSize('lg')(theme)};
    `}

  ${props.round &&
    props.size === 'xl' &&
    css`
      ${mixinSize('md')(theme)};
    `}

  ${props.round &&
    props.size === 'lg' &&
    css`
      ${mixinSize('md')(theme)};
    `}

  ${props.round &&
    props.size === 'md' &&
    css`
      ${mixinSize('sm')(theme)};
    `}

  ${props.round &&
    props.size === 'sm' &&
    css`
      ${mixinSize('xs')(theme)};
    `}

  ${props.height &&
    responsive(
      props.height,
      (height) => css`
        height: ${height};
      `
    )}

  ${props.width &&
    responsive(
      props.width,
      (width) => css`
        width: ${width};
      `
    )}
  `;

export const style = { container, svg };
