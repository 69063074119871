import { FunctionComponent } from 'react';
import { createReducer } from 'deox';
import { ConnectedComponent } from 'react-redux';

import { AlertModal } from 'src/components/AlertModal';

import { alertModalActions } from '../alert-modal';
import { modalActions } from './actions';

const { showModal, hideModal } = modalActions;

export interface ModalReducer<T> {
  Component: FunctionComponent<T> | ConnectedComponent<FunctionComponent<any>, T> | null;
  props: T;
}

export const initialState: ModalReducer<any> = {
  Component: null,
  props: {},
};

export const modalReducer = createReducer(initialState, (handleAction) => [
  handleAction(alertModalActions.showAlertModal, (_, { payload }) => {
    return {
      Component: AlertModal,
      props: payload,
    };
  }),
  handleAction(showModal, (_, { payload }) => {
    return payload;
  }),
  handleAction([hideModal, alertModalActions.hideAlertModal], (_) => {
    return {
      Component: null,
      props: {},
    };
  }),
]);
