import { RefObject } from 'react';
import {
  ApplicationRoleInfo,
  BackupRoleInfo,
  CoreServiceInfo,
  DatabaseRoleInfo,
  DnsRoleInfo,
  DomainMapping,
  NetworkStatus,
  Org,
  RolesInfo,
  ServerInfo,
  ServerInfoBrief,
  ServerIp,
  ServerRole,
  ServiceInfo,
  Website,
  WebsiteKind,
} from 'src/orchd-client';
import clamp from 'lodash/clamp';
import memoize from 'memoize-one';
import rcompare from 'semver/functions/rcompare';
import { UAParser } from 'ua-parser-js';

import { CheckboxChecked } from 'src/components/Checkbox/types';
import { SelectOption } from 'src/components/Select/Select.types';
import { SortOrder } from 'src/components/Sort/types';
import { Colors, Responsive, Stack } from 'src/design-system/style-types';
import { isResponsiveProp } from 'src/design-system/style-utils';
import { AllServerStatuses } from 'src/store/servers/reducer';
import {
  isDesktopUp,
  isExtraLargeDesktopUp,
  isLargeDesktopUp,
  isMobileUp,
  isTabletUp,
} from 'src/utils/breakpointHelpers';
import { ServerRoleNames, ServerStatus, StatusTypes } from 'src/utils/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortByKey(
  direction: SortOrder,
  name: string,
  data: Record<string, any>[],
  transform?: (a: any, b: any) => { a: any; b: any }
): Record<string, any>[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.sort((a: Record<string, any>, b: Record<string, any>): number => {
    let transformedA = a;
    let transformedB = b;

    if (transform) {
      const transformed = transform(a, b);
      transformedA = transformed.a;
      transformedB = transformed.b;
    }

    const statusA =
      transformedA[name] && transformedA[name].toUpperCase ? transformedA[name].toUpperCase() : transformedA[name];
    const statusB =
      transformedB[name] && transformedB[name].toUpperCase ? transformedB[name].toUpperCase() : transformedB[name];

    let comparison = 0;

    if (direction === 'asc') {
      if (statusA > statusB) comparison = 1;
      else if (statusA < statusB) comparison = -1;
      else comparison = 0;
    } else if (direction === 'desc') {
      if (statusA < statusB) comparison = 1;
      else if (statusA > statusB) comparison = -1;
      else comparison = 0;
    }

    return comparison;
  });
  return data;
}

export function getStackValue(size: Stack, noStack: boolean): Stack {
  if (noStack) {
    return 'noStack';
  }
  return size;
}

export const titleCase = (string: string) => {
  if (typeof string !== 'string') {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export type ByteUnits = 'TB' | 'GB' | 'MB' | 'KB' | 'bytes';

const byteUnits: Record<ByteUnits, number> = {
  TB: 12,
  GB: 9,
  MB: 6,
  KB: 3,
  bytes: 0,
};

// takes in a number, and a unit and returns bytes
export const getBytesFromUnits = (num: number, unit: ByteUnits) => {
  return num * Math.pow(10, byteUnits[unit]);
};

// takes a number of bytes and returns the same value in the specified unit
export const getUnitsFromBytes = (bytes: number, unit: ByteUnits) => {
  return bytes / Math.pow(10, byteUnits[unit]);
};

interface OptionalProps {
  fixedUnit?: ByteUnits;
  round?: boolean;
}

// takes in bytes and puts them into a format like "100 GB"
export const getByteString = (num?: number, optionalProps = {} as OptionalProps): string => {
  if (typeof num === 'undefined' || num === null) {
    return '';
  }

  const { fixedUnit, round = true } = optionalProps;

  const { length } = num.toString();
  let returnValue = num;
  let byteSize = 'b';

  if (fixedUnit) {
    returnValue = num / Math.pow(10, byteUnits[fixedUnit]);
    byteSize = fixedUnit;
  } else {
    switch (true) {
      case length > byteUnits.TB:
        returnValue = num / Math.pow(10, byteUnits.TB);
        byteSize = 'TB';
        break;
      case length > byteUnits.GB:
        returnValue = num / Math.pow(10, byteUnits.GB);
        byteSize = 'GB';
        break;
      case length > byteUnits.MB:
        returnValue = num / Math.pow(10, byteUnits.MB);
        byteSize = 'MB';
        break;
      case length > byteUnits.KB:
        returnValue = num / Math.pow(10, byteUnits.KB);
        byteSize = 'KB';
        break;
    }
  }

  return round ? `${Math.round(returnValue * 100) / 100} ${byteSize}` : `${returnValue} ${byteSize}`;
};

export const isGb = (value: number): boolean => {
  return value >= getBytesFromUnits(1, 'GB');
};

export const isTb = (value: number): boolean => {
  return value >= getBytesFromUnits(1, 'TB');
};

export const getPercentage = (part?: number, whole?: number, infinite = false) => {
  if (!part || !whole) {
    return 0;
  }

  const returnValue = (part / whole) * 100;

  const percentage = Math.round(returnValue * 100) / 100;
  if (infinite) {
    // if bar is representing an infinite resource show overall usage at 20%
    return clamp(percentage / 5, 0, 100);
  }
  return clamp(percentage, 0, 100);
};

export const getFlatServerList = (serversListInGroups: Record<string, ServerInfoBrief[]>) => {
  const flatServerList: ServerInfoBrief[] = [];

  Object.keys(serversListInGroups)
    .map((key) => serversListInGroups[key])
    .map((serverArray) =>
      Array.isArray(serverArray) ? serverArray.forEach((server) => flatServerList.push(server)) : []
    );

  return flatServerList;
};

export const stringMatchAll = (
  str: string,
  query: string,
  caseSensitive = false
): { value: string; index: number; match: boolean }[] => {
  let text = str;
  let match = query;
  if (!caseSensitive) {
    text = text.toLowerCase();
    match = match.toLowerCase();
  }
  if (!match) {
    return [{ value: str, index: 0, match: false }];
  }
  let i = 0;
  let prev = 0;
  const step = match.length;
  const matches = [];

  while ((i = text.indexOf(match, prev)) > -1) {
    if (i > prev) {
      matches.push({ value: str.slice(prev, i), match: false, index: prev });
    }
    matches.push({ value: str.slice(i, i + step), index: i, match: true });
    prev = i + step;
  }

  if (prev < text.length) {
    matches.push({ value: str.slice(prev, text.length), match: false, index: prev });
  }

  return matches;
};

export const getServerStatusIconType = (serverRoleStatus: ServerStatus) => {
  const map: Record<ServerStatus, StatusTypes> = {
    online: 'success',
    critical: 'error',
    warning: 'warning',
    restarting: 'loading',
    no_services: 'info',
    unknown: 'unknown',
  };

  return map[serverRoleStatus];
};

export const getPrimaryServerIp = (serverIps: ServerIp[] | undefined) => {
  if (!serverIps) return '';

  const primaryIp = serverIps.find((serverIp) => serverIp.isPrimary);

  return primaryIp ? primaryIp.ip : '';
};

export const getColorFromHex = (hex?: string): Colors | undefined => {
  const _hex = hex?.toLowerCase();

  if (!_hex) {
    return undefined;
  }

  const map: Record<string, Colors> = {
    '4d55ff': 'blue',
    '36a7ff': 'azure',
    '00eccf': 'aqua',
    '71eb77': 'green',
    ffda5e: 'yellow',
    ff8e53: 'orange',
    f44661: 'fuschia',
  };

  return map[_hex];
};

export const getUserAgentSummary = (userAgent: string) => {
  const parser = new UAParser(userAgent);
  const os = parser.getOS();
  const browser = parser.getBrowser();

  let output = '';

  if (os.name) {
    output = os.name;
  }

  if (os.name && browser.name) {
    output += ` · `;
  }

  if (browser.name) {
    output += browser.name;
  }

  return output || userAgent;
};

export const getOrgsByParentId = (customers: Org[], memberOrgId: string): Record<string, Org[]> => {
  return customers.reduce((acc, customer) => {
    const { parentId = memberOrgId } = customer;
    if (acc[parentId]) {
      acc[parentId].push(customer);
    } else {
      acc[parentId] = [customer];
    }
    return acc;
  }, {} as Record<string, Org[]>);
};

export const getWebsitesByOrgId = (websites: Website[]) =>
  websites.reduce((acc, website) => {
    if (acc[website.orgId]) {
      acc[website.orgId].push(website);
    } else {
      acc[website.orgId] = [website];
    }
    return acc;
  }, {} as Record<string, Website[]>);

export function handleBulkItems<T>(items: T | T[], single: (item: T) => void, bulk: (items: T[]) => void) {
  if (Array.isArray(items)) {
    if (items.length === 0) {
      throw new Error(`Empty array of items`);
    }
    if (items.length > 1) {
      return bulk(items);
    }
    return single(items[0]);
  }
  return single(items);
}

type OrgWithChildren = Org & { children: OrgWithChildren[] };

const unravelOrgsNode = ({ children, ...org }: OrgWithChildren): Org[] => {
  return children.reduce(
    (acc, n) => {
      const { children, ...org } = n;
      if (children.length) {
        return [...acc, ...unravelOrgsNode(n)];
      }
      return [...acc, org];
    },
    [org] as Org[]
  );
};

export const sortOrgsByParent = (orgs: Org[], memberOrgId: string) => {
  const orgsHash = orgs.reduce(
    (acc, org) => ({ ...acc, [org.id]: { ...org, children: [] } }),
    {} as Record<string, OrgWithChildren>
  );
  const orderedOrgs: OrgWithChildren[] = [];
  orgs.forEach((org) => {
    const { parentId, id } = org;
    if (parentId && parentId !== memberOrgId && orgsHash[parentId]) {
      orgsHash[parentId].children.push(orgsHash[id]);
    } else {
      orderedOrgs.push(orgsHash[id]);
    }
  });
  return orderedOrgs.reduce((acc, org) => [...acc, ...unravelOrgsNode(org)], [] as Org[]);
};

export const delay = (timeout = 0) =>
  new Promise<void>((resolve) => {
    setTimeout(resolve, timeout);
  });

/* soft-formats an SSL cert by removing line-breaks and adding them back in the right places */
export const formatCertificate = (cert: string): string => {
  const separator = '-----';

  const [begin, content, end, ...rest] = cert
    .replace(/(\r\n|\n|\r)/gm, '')
    .split(separator)
    .filter((item) => !!item);

  if (!begin || !content || !end) {
    return cert;
  }

  const formatted = `${separator}${begin}${separator}\n${content}\n${separator}${end}${separator}`;

  if (rest.length > 0) {
    return `${formatted}\n${formatCertificate(rest.join(separator))}`;
  }

  return formatted;
};

/* gets the full domain object using the id and an array of domains */
export const getDomainById = ({
  domainId,
  websiteDomainMappings,
}: {
  domainId: string;
  websiteDomainMappings?: DomainMapping[];
}) => websiteDomainMappings?.find((domain) => domain.domainId === domainId);

/* scrolls the window using a ref and an offset value */
export const scrollToRef = (ref: RefObject<any>, offset = -80) => {
  if (ref && ref.current) {
    window.scrollTo(0, ref.current.offsetTop + offset);
  }
};

export function sortInstallableAppByVersionDesc<T extends { version: string; isLatest: boolean }>(items: T[]): T[] {
  return items.sort((a, b) => {
    if (a.isLatest) {
      return 1;
    }

    return rcompare(a.version, b.version);
  });
}

export const addTrailingSlash = (s: string) => (s.endsWith('/') ? s : `${s}/`);
export const removeTrailingSlash = (s: string) => (s.endsWith('/') ? s.substr(0, s.length - 1) : s);
export const addLeadingSlash = (s: string) => (s.startsWith('/') ? s : `/${s}`);
export const removeLeadingSlash = (s: string) => (s.startsWith('/') ? s.substr(1) : s);

/* Returns true, false or "mixed". This value can be passwed to the Checkbox component to give a checked, unchecked or indeterminate state */
export const getAllCheckedValue = (selectedItems: Record<string, boolean>, allItemsLength: number): CheckboxChecked => {
  const checkedLength = Object.values(selectedItems).filter((checked) => checked).length;

  if (checkedLength === allItemsLength && allItemsLength > 0) {
    return true;
  }

  if (checkedLength === 0) {
    return false;
  }

  return 'mixed';
};

export function dedupeArray<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}
export function dedupeArraysInObject<T extends Record<any, any>>(obj: T): T {
  return Object.entries(obj).reduce((acc, [k, v]) => ({ ...acc, [k]: Array.isArray(v) ? dedupeArray(v) : v }), {} as T);
}

export const getSelectFieldData = (rawValue: SelectOption[]) => rawValue && rawValue[0]?.data;

export const getSelectFieldValue = (rawValue: SelectOption[]) => rawValue && rawValue[0]?.value;

export const openInNewTab = (url: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  a.click();
  a.remove();
};

export function getWebsiteKindName(kind: WebsiteKind) {
  switch (kind) {
    case WebsiteKind.controlPanel:
      return 'Control Panel';
    case WebsiteKind.phpMyAdmin:
      return 'phpMyAdmin';
    case WebsiteKind.roundcube:
      return 'Webmail';
    default:
      return '';
  }
}

export function getResponsivePropValue<T>(prop: Responsive<T>, viewportWidth: number): T | undefined {
  if (isResponsiveProp(prop)) {
    if (typeof prop.xl !== 'undefined' && isExtraLargeDesktopUp(viewportWidth)) {
      return prop.xl;
    }

    if (typeof prop.lg !== 'undefined' && isLargeDesktopUp(viewportWidth)) {
      return prop.lg;
    }

    if (typeof prop.md !== 'undefined' && isDesktopUp(viewportWidth)) {
      return prop.md;
    }

    if (typeof prop.sm !== 'undefined' && isTabletUp(viewportWidth)) {
      return prop.sm;
    }

    if (typeof prop.xs !== 'undefined' && isMobileUp(viewportWidth)) {
      return prop.xs;
    }
  }

  if (!isResponsiveProp(prop)) {
    return prop as T;
  }

  return undefined;
}

export function serverHasRole(hay: Website | ServerInfoBrief | undefined, role: ServerRole): boolean {
  if (!hay) {
    return false;
  }
  const server = hay as ServerInfoBrief;

  if (server.roles) {
    return (
      (role === ServerRole.email && !!server.roles.email) ||
      (role === ServerRole.database && !!server.roles.database) ||
      (role === ServerRole.backup && !!server.roles.backup) ||
      (role === ServerRole.application && !!server.roles.application)
    );
  }

  const website = hay as Website;

  return (
    (role === ServerRole.email && !!website.emailServerIps && website.emailServerIps.length > 0) ||
    (role === ServerRole.database && !!website.dbServerIps && website.dbServerIps.length > 0) ||
    (role === ServerRole.backup && !!website.backupServerIps && website.backupServerIps.length > 0) ||
    (role === ServerRole.application && !!website.serverIps && website.serverIps.length > 0)
  );
}

export const getServerInfo = (
  website: Website,
  role: string,
  allServerStatuses?: AllServerStatuses
): { info: ServerInfo; roles: RolesInfo } | undefined => {
  if (!allServerStatuses) {
    return;
  }

  switch (role) {
    case 'application':
      return allServerStatuses?.[website.appServerId as string]?.data;
    case 'email':
      return allServerStatuses?.[website.emailServerId as string]?.data;
    case 'backup':
      return allServerStatuses?.[website.backupServerId as string]?.data;
    case 'database':
      return allServerStatuses?.[website.dbServerId as string]?.data;
  }
};

export const statusGravityMap: Record<NetworkStatus, number> = {
  [NetworkStatus.online]: 0,
  [NetworkStatus.unknown]: 1,
  [NetworkStatus.restarting]: 2,
  [NetworkStatus.warning]: 3,
  [NetworkStatus.critical]: 4,
};

export const findHighestGravityStatus = (services: (ServiceInfo | CoreServiceInfo)[]) => {
  return services.filter(Boolean).reduce((status, service) => {
    return statusGravityMap[service.status] > statusGravityMap[status] ? service.status : status;
  }, NetworkStatus.online);
};

export const getRoleServiceStatus = (roleName: ServerRoleNames, serverRoles: RolesInfo) => {
  const serverRole = serverRoles[roleName];

  if (!serverRole) {
    return NetworkStatus.unknown;
  }

  switch (roleName) {
    case 'application': {
      const application = serverRole as ApplicationRoleInfo;

      return findHighestGravityStatus([
        // application.apachecd,
        application.filerd,
        application.ftpcd,
        // application.nginxcd,
        // application.sshcd,
      ]);
    }
    case 'backup': {
      const backup = serverRole as BackupRoleInfo;

      return findHighestGravityStatus([backup.bkupd]);
    }
    case 'database': {
      const database = serverRole as DatabaseRoleInfo;

      return findHighestGravityStatus([database.mysqlcd]);
    }
    case 'dns': {
      const dns = serverRole as DnsRoleInfo;

      return findHighestGravityStatus([dns.dnscd]);
    }
    case 'email': {
      return NetworkStatus.online;
    }
    default:
      return NetworkStatus.critical;
  }
};

export const groupWebsitesBySubscriptionId = memoize((websites?: Website[]): Record<number, Website[]> => {
  return (
    websites?.reduce<Record<number, Website[]>>((acc, website) => {
      const { subscriptionId = 0 } = website;
      if (!acc[subscriptionId]) {
        acc[subscriptionId] = [];
      }
      acc[subscriptionId].push(website);
      return acc;
    }, {}) ?? {}
  );
});
