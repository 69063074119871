import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { domainsApi } from 'src/api_services/domains/service';

import { createRequestStateSaga } from '../helpers';
import { domainsActions } from './actions';

const [createWebsiteMappedDomainSaga] = createRequestStateSaga(
  domainsActions.createWebsiteMappedDomain,
  domainsApi.createWebsiteMappedDomain
);
const [createWebsiteDomainLetsencryptCertsSaga] = createRequestStateSaga(
  domainsActions.createWebsiteDomainLetsencryptCerts,
  domainsApi.createWebsiteDomainLetsencryptCerts
);
const [deleteDomainSaga] = createRequestStateSaga(domainsActions.deleteDomain, domainsApi.deleteDomain);

const [getWebsiteDomainMappingSaga] = createRequestStateSaga(
  domainsActions.getWebsiteDomainMapping,
  domainsApi.getWebsiteDomainMapping
);
const [getWebsiteDomainMappingsSaga] = createRequestStateSaga(
  domainsActions.getWebsiteDomainMappings,
  domainsApi.getWebsiteDomainMappings
);
const [updateWebsiteDomainMappingSaga] = createRequestStateSaga(
  domainsActions.updateWebsiteDomainMapping,
  domainsApi.updateWebsiteDomainMapping
);
const [updateWebsitePrimaryDomainSaga] = createRequestStateSaga(
  domainsActions.updateWebsitePrimaryDomain,
  domainsApi.updateWebsitePrimaryDomain
);
const [checkDomainSaga] = createRequestStateSaga(domainsActions.checkDomain, domainsApi.checkDomain);

export function* domainsSaga() {
  yield takeEvery(getType(domainsActions.createWebsiteMappedDomain.request), createWebsiteMappedDomainSaga);
  yield takeEvery(
    getType(domainsActions.createWebsiteDomainLetsencryptCerts.request),
    createWebsiteDomainLetsencryptCertsSaga
  );
  yield takeEvery(getType(domainsActions.deleteDomain.request), deleteDomainSaga);
  yield takeEvery(getType(domainsActions.getWebsiteDomainMapping.request), getWebsiteDomainMappingSaga);
  yield takeEvery(getType(domainsActions.getWebsiteDomainMappings.request), getWebsiteDomainMappingsSaga);
  yield takeEvery(getType(domainsActions.updateWebsiteDomainMapping.request), updateWebsiteDomainMappingSaga);
  yield takeEvery(getType(domainsActions.updateWebsitePrimaryDomain.request), updateWebsitePrimaryDomainSaga);
  yield takeEvery(getType(domainsActions.checkDomain.request), checkDomainSaga);
}
