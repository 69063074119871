import { createReducer } from 'deox';

import { devtoolsActions } from './actions';

export interface DevtoolsState {
  isDevtoolsOpen: boolean;
  isTranslationsHighlighted: boolean;
  isReactQueryDevtoolsActive: boolean;
}

export const initialState: DevtoolsState = {
  isDevtoolsOpen: false,
  isTranslationsHighlighted: false,
  isReactQueryDevtoolsActive: false,
};

export const devtoolsReducer = createReducer(initialState, (handleAction) => [
  handleAction(devtoolsActions.toggleDevtools, (state, { payload }) => {
    return {
      ...state,
      isDevtoolsOpen: payload.open,
    };
  }),
  handleAction(devtoolsActions.toggleTranslationHighlights, (state, { payload }) => {
    return {
      ...state,
      isTranslationsHighlighted: payload.highlighted,
    };
  }),
  handleAction(devtoolsActions.toggleReactQueryDevtools, (state, { payload }) => {
    return {
      ...state,
      isReactQueryDevtoolsActive: payload.open,
    };
  }),
]);
