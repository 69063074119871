import { css } from '@emotion/core';
import ReactSelect, { ControlProps, GroupTypeBase, OptionTypeBase } from 'react-select';

import { Theme as ReactSelectTheme } from 'react-select/src/types';
import {
  boxShadowGrey,
  leftString,
  margin,
  rightString,
  shadowBorder,
  shadowBorderBottom,
} from 'src/design-system/mixins';
import { FontSizes, Sizes } from 'src/design-system/style-types';
import { Theme } from 'src/design-system/theme';
import { titleCase } from 'src/utils/helpers';
import { zIndex } from 'src/utils/zIndex';

import type { Props, VariantsKind } from './Select';

type ReselectSelectControl = ControlProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>;

export const variantMap: Record<VariantsKind, { fontSize: FontSizes; padding?: Sizes }> = {
  normal: {
    fontSize: 'md',
  },
  small: {
    fontSize: 'sm',
    padding: 'sm',
  },
  filter: {
    fontSize: 'sm',
  },
  pagination: {
    fontSize: 'sm',
  },
  clear: {
    fontSize: 'md',
  },
  pill: {
    fontSize: 'sm',
  },
};

const container = (props: Props) => (theme: Theme) =>
  css`
    ${props.width !== 'fit-content' && `width: 100%;`}
    ${props.maxWidth && `max-width: ${props.maxWidth}px;`}
    ${props.minWidth && `min-width: ${props.minWidth}px;`}
  position: relative;
    ${margin({
      left: props.marginLeft,
      right: props.marginRight,
      bottom: props.marginBottom,
    })(props.theme)}
    font-family: ${theme.fontFamily};
  `;

const loader = () => (theme: Theme) =>
  css`
    position: absolute;
    right: 36px;
    top: ${theme.margins.lg}px;
  `;

const getControlShadowBorder = (selectFieldState: any, props: Props) => {
  const { err, touched, variant, theme } = props;
  const { isDisabled } = selectFieldState;

  if (isDisabled || variant === 'clear' || variant === 'pill') {
    return { boxShadow: 'none' };
  }

  if (err && touched) {
    return shadowBorder('error', 2)(theme);
  }

  if (variant === 'filter') {
    return shadowBorderBottom('grey20')(theme);
  }

  return shadowBorderBottom('dark')(theme);
};

const getInputFontColor = ({ variant, theme: { fontColors } }: Props, state: any) => {
  const color = variant === 'filter' ? fontColors.grey : fontColors.dark;
  return state.isDisabled ? fontColors.disabled : color;
};

const getFontSize = ({ variant, theme, fontSize }: Props) => theme.fontSizes[fontSize || variantMap[variant].fontSize];

const getPadding = ({ theme, padding }: Props) => theme.paddings[padding || 'sm'];

const downChevron = (props: Props, state: any) => css`
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    bottom: ${props.variant === 'small' ? '-8px' : '-12px'};
    right: 2px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid
      ${!props.multi && state.selectProps.menuIsOpen && !!state.options.length
        ? props.theme.fontColors.dark
        : 'transparent'};
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
`;

const reactSelectThemeOverrides = (props: Props) => (reactSelectTheme: ReactSelectTheme) => ({
  ...reactSelectTheme,
  borderRadius: props.variant === 'pill' ? 50 : 0,
  colors: {
    ...props.theme.colors,
    primary25: props.theme.colors.grey3,
    primary: props.theme.colors.grey5,
  },
});

const getControlBackground = ({ menuIsOpen }: ReselectSelectControl, { variant, theme }: Props) => {
  if (menuIsOpen || ['filter', 'clear'].includes(variant)) {
    return theme.colors.transparent;
  }

  return theme.colors.grey5;
};

const getControlBorder = ({ menuIsOpen, isFocused }: ReselectSelectControl, { variant, theme }: Props) => {
  const borderStyle = {
    boxShadow: `inset 0 0 0 1px ${theme.fontColors.dark}`,
    border: 'none',
  };

  if (isFocused && !menuIsOpen) {
    return {
      ...borderStyle,
      '&:hover': {
        ...borderStyle,
      },
    };
  }

  if (menuIsOpen && !['filter', 'clear'].includes(variant)) {
    return {
      ...borderStyle,
      '&:hover': {
        ...borderStyle,
      },
    };
  }

  return {
    border: 'none',
  };
};

const reactSelectStyleOverrides = (props: Props): React.ComponentProps<typeof ReactSelect>['styles'] => ({
  container: (base) => ({
    ...base,
    width: '100%',
    cursor: 'pointer',
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 'auto',
    paddingRight: !props.theme.settings.rtl ? props.theme.paddings.xl : 0,
    paddingLeft: props.theme.settings.rtl ? props.theme.paddings.xl : 0,
    background: getControlBackground(state, props),
    ...getControlShadowBorder(state, props),
    ...getControlBorder(state, props),
  }),
  menu: (base) => ({
    ...base,
    ...boxShadowGrey(2),
    background: props.theme.colors.light,
    width: props.width === 'fit-content' ? 'auto' : '100%',
    minWidth: '100%',
    borderRadius: 0,
    marginTop: -1,
    borderTop: `1px solid ${props.theme.fontColors.dark}`,
    padding: `${props.theme.paddings.xs}px 0`,
    zIndex: zIndex.SelectMenu,
  }),
  option: (base, state) => {
    const backgroundColor = (() => {
      if (state.isDisabled) return;
      if (state.isFocused) return props.theme.colors.grey5;
      return props.theme.colors.transparent;
    })();

    const hoverBackground = (() => {
      if (state.isDisabled) return;
      if (state.isSelected) return props.theme.colors.transparent;
      return props.theme.colors.grey5;
    })();

    return {
      ...base,
      cursor: state.isDisabled ? 'auto' : 'pointer',
      fontFamily: props.theme.fontFamily,
      color: state.isSelected ? props.theme.fontColors.grey : props.theme.fontColors.dark,
      backgroundColor,
      padding: `${props.theme.paddings.sm}px ${
        props.variant === 'normal' ? props.theme.paddings.lg : props.theme.paddings.sm
      }px`,
      fontSize: getFontSize(props),
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ':hover': {
        backgroundColor: hoverBackground,
      },
      paddingRight: '24px',
    };
  },
  singleValue: (base, state) => ({
    ...(props.width !== 'fit-content' ? base : {}),
    fontFamily: props.theme.fontFamily,
    fontSize: getFontSize(props),
    color: getInputFontColor(props, state),
    marginLeft: 0,
    marginRight: 0,
  }),
  menuList: (base) => ({
    ...base,
    backgroundColor: props.theme.colors.light,
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    lineHeight: 1.5,
    minHeight: ['small', 'pill'].includes(props.variant) ? 32 : 40,
    cursor: 'pointer',
    minWidth: '40px',
    paddingLeft: props.variant === 'small' ? props.theme.paddings.sm : props.theme.paddings.lg,
    paddingRight: props.multi ? props.theme.paddings.xl : '12px',
    paddingTop: props.multi ? props.theme.paddings.xs : '0',
    paddingBottom: props.multi ? props.theme.paddings.xs : '0',
    flexWrap: props.multi ? 'wrap' : 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'initial',
    justifyContent: props.variant === 'pill' ? 'center' : 'flex-start',
  }),
  input: (base) => ({
    ...base,
    lineHeight: '24px',
    padding: 0,
    margin: 0,
    color: props.theme.fontColors.dark,
    fontSize: getFontSize(props),
    fontFamily: props.theme.fontFamily,
  }),
  placeholder: (base) => ({
    ...(props.width !== 'fit-content' ? base : {}),
    color: props.theme.fontColors.placeholder,
    fontSize: getFontSize(props),
    fontFamily: props.theme.fontFamily,
    marginLeft: 0,
    marginRight: 0,
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    [`padding${titleCase(leftString(props.theme))}`]: 0,
    [`padding${titleCase(rightString(props.theme))}`]: `${getPadding(props)}px`,
    color: state.isDisabled ? props.theme.fontColors.disabled : props.theme.fontColors.dark,
    position: 'absolute',
    right: props.theme.settings.rtl ? 'auto' : 0,
    left: props.theme.settings.rtl ? 0 : 'auto',
    top: 0,
    bottom: 0,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    position: 'absolute',
    top: 0,
  }),
  multiValue: (base, state) => {
    return {
      ...base,
      alignItems: 'center',
      border: state.selectProps.menuIsOpen ? `1px solid ${props.theme.colors.grey50}` : 0,
      color: getInputFontColor(props, state),
      background: props.theme.colors.light,
      padding: state.selectProps.menuIsOpen ? '0px' : '1px',
      margin: '4px',
    };
  },
  multiValueLabel: (base, state) => ({
    ...base,
    fontFamily: props.theme.fontFamily,
    padding: '0px',
    fontSize: '12px',
    color: getInputFontColor(props, state),
    background: props.theme.colors.light,
  }),
});

const menuItem = (props: Props) => (theme: Theme) =>
  css`
    max-height: 300px;
    overflow-y: auto;
  `;

export const style = {
  container,
  loader,
  reactSelectThemeOverrides,
  reactSelectStyleOverrides,
  downChevron,
  menuItem,
};
