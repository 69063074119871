/** @jsxRuntime classic */
/** @jsx jsx */
import { Component, ComponentProps, Fragment, ReactNode } from 'react';
import { jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import isNumber from 'lodash/isNumber';
import { FormattedMessage } from 'react-intl';
import ReactSelect, {
  components,
  FormatOptionLabelMeta,
  MenuListComponentProps,
  MenuProps,
  OptionProps,
} from 'react-select';
import Creatable from 'react-select/creatable';

import { Box } from 'src/components/Box';
import { Text } from 'src/components/Text';
import { FontSizes, Margins } from 'src/design-system/style-types';
import { Theme } from 'src/design-system/theme';

import { Icon } from '../Icon';
import { Loading } from '../Loading';
import { style } from './Select.style';
import { SelectOnChange, SelectOption, SelectValue } from './Select.types';

export type VariantsKind = 'normal' | 'small' | 'filter' | 'pagination' | 'clear' | 'pill';

interface RequiredProps {
  theme: Theme;
  onChange: SelectOnChange;
  value: SelectValue;
  name: string;
  options: SelectOption[];
  formatOptionLabel?: (option: any, labelMeta: FormatOptionLabelMeta<any, boolean>) => ReactNode;
}

interface DefaultProps {
  className: string;
  placeholder: ReactNode;
  err: any | string | undefined;
  touched: any | boolean;
  onBlur: (e: any) => void;
  disabled: boolean;
  multi: boolean;
  padding: 'sm' | 'lg' | undefined;
  creatable: boolean;
  clearable: boolean;
  searchable: boolean;
  loading: boolean;
  onInputChange: (value: string) => void;
  onCreateOption: () => void;
  filterOption: ((option: SelectOption, value: string) => boolean) | undefined;
  fontSize: FontSizes | undefined;
  variant: VariantsKind;
  marginBottom: Margins;
  marginLeft: Margins;
  marginRight: Margins;
  maxWidth?: number;
  minWidth?: number;
  maxMenuHeight?: number;
  width?: 'fit-content';
  optionComponent?: (props: OptionProps<any, false>) => JSX.Element;
  autoFocus: boolean;
  forceOpen?: boolean;
}

export type Props = RequiredProps & DefaultProps;

class Select extends Component<RequiredProps & DefaultProps> {
  static defaultProps: DefaultProps = {
    className: '',
    placeholder: '',
    err: '',
    touched: false,
    onBlur: () => {},
    disabled: false,
    multi: false,
    padding: undefined,
    clearable: false,
    searchable: false,
    creatable: false,
    loading: false,
    onInputChange: () => {},
    onCreateOption: () => {},
    filterOption: undefined,
    fontSize: undefined,
    variant: 'normal',
    marginBottom: '0',
    marginLeft: '0',
    marginRight: '0',
    autoFocus: false,
    forceOpen: undefined,
  };

  getIconColor = () => {
    const { variant, err, touched, disabled } = this.props;

    if (variant === 'filter' || disabled) {
      return 'grey';
    }

    if (err && touched) {
      return 'error';
    }

    return 'dark';
  };

  renderDownChevron = (state: any) => (
    <Icon
      hoverName={this.props.name}
      css={style.downChevron(this.props, state)}
      size="lg"
      name="chevron down"
      color={this.getIconColor()}
    />
  );

  renderClear = (props: any) => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
        <Icon hoverName={`${this.props.name}-clear`} size="lg" name="close" />
      </div>
    );
  };

  renderMenu = (props: MenuProps<any, false>) => {
    const { children, innerRef, getStyles } = props;
    return (
      <div ref={innerRef} style={getStyles('menu', props)} role="menu">
        {children}
      </div>
    );
  };

  renderMenuList = (props: MenuListComponentProps<any, false>) => {
    const { children, innerRef } = props;
    return (
      <div ref={innerRef} role="menu" css={style.menuItem(this.props)}>
        {children}
      </div>
    );
  };

  renderNoOptionsMessage = (props: ComponentProps<typeof components.NoOptionsMessage>) => (
    <components.NoOptionsMessage {...props}>
      <Box a="center" j="center" p="lg">
        <Text size="sm" color="grey">
          <FormattedMessage id="select.no_results" defaultMessage="Not found" />
        </Text>
      </Box>
    </components.NoOptionsMessage>
  );

  renderSingleValue = (props: ComponentProps<typeof components.SingleValue>) => {
    const { children, ...rest } = props;

    if (rest.selectProps.menuIsOpen && rest.selectProps.isSearchable) {
      return <Fragment></Fragment>;
    }

    return <components.SingleValue {...rest}>{children}</components.SingleValue>;
  };

  ensureArray = (value: SelectValue): SelectOption[] => {
    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      if (value[0] && (value[0].value === undefined || value[0].value === '')) {
        return [];
      }
      return value;
    }

    if (value && !value.value && !isNumber(value.value)) {
      return [];
    }

    return [value];
  };

  render() {
    const {
      options,
      value,
      name,
      multi,
      onChange,
      onCreateOption,
      onBlur,
      onInputChange,
      filterOption,
      formatOptionLabel,
      disabled,
      placeholder,
      clearable,
      searchable,
      creatable,
      loading,
      theme,
      className,
      optionComponent,
      maxMenuHeight,
      autoFocus,
      forceOpen,
    } = this.props;

    const val = this.ensureArray(value);

    const selectProps = {
      'aria-labelledby': name,
      'data-qa': 'select',
      isMulti: multi,
      isRtl: theme.settings.rtl,
      name,
      onChange: (selectedOption: any) => onChange({ name, value: this.ensureArray(selectedOption) }),
      onBlur,
      onInputChange,
      onCreateOption,
      placeholder,
      value: val,
      styles: style.reactSelectStyleOverrides(this.props),
      options,
      theme: style.reactSelectThemeOverrides(this.props),
      components: {
        DropdownIndicator: this.renderDownChevron,
        ClearIndicator: this.renderClear,
        NoOptionsMessage: this.renderNoOptionsMessage,
        MenuList: this.renderMenuList,
        SingleValue: this.renderSingleValue,
      } as typeof components,
      filterOption,
      isDisabled: disabled,
      isClearable: clearable,
      isSearchable: searchable,
      maxMenuHeight,
      formatOptionLabel,
      autoFocus,
    };

    if (optionComponent) {
      selectProps.components.Option = optionComponent as any;
    }

    return (
      <div aria-label={name} className={className} css={style.container(this.props)}>
        {!creatable && (
          <ReactSelect
            {...selectProps}
            menuIsOpen={forceOpen}
            onBlurResetsInput={false}
            closeMenuOnSelect={!multi}
            // required as e2e tests on multi select are failing without this
            blurInputOnSelect={!multi}
            noOptionsMessage={multi ? () => null : undefined}
          />
        )}
        {creatable && <Creatable {...selectProps} />}
        {loading && (
          <span css={style.loader()}>
            <Loading size="md" />
          </span>
        )}
      </div>
    );
  }
}

/**
 * @deprecated
 */
export { Select as UnwrappedSelect };

/**
 * @deprecated
 */
export default withTheme(Select);
