import { BackupRestoreOptions } from 'src/orchd-client';
import { AxiosResponse } from 'axios';
import { ActionType, getType } from 'deox';
import { call, put, takeEvery } from 'redux-saga/effects';

import { backupsApi } from 'src/api_services/backups/service';
import { emailsApi } from 'src/api_services/emails/service';
import { mysqlApi } from 'src/api_services/mysql/service';
import { createRequestStateSaga } from 'src/store/helpers';

import {
  backupWebsite,
  deleteWebsiteBackup,
  getWebsiteBackup,
  getWebsiteBackups,
  getWebsiteEmails,
  getWebsiteMySQLDBs,
  restoreWebsite,
} from './actions';

const [backupWebsiteSaga] = createRequestStateSaga(backupWebsite, backupsApi.backupWebsite);
const [deleteWebsiteBackupSaga] = createRequestStateSaga(deleteWebsiteBackup, backupsApi.deleteWebsiteBackup);
const [getWebsiteBackupSaga] = createRequestStateSaga(getWebsiteBackup, backupsApi.getWebsiteBackup);
const [getWebsiteBackupsSaga] = createRequestStateSaga(getWebsiteBackups, backupsApi.getWebsiteBackups);
const [getWebsiteMySQLDBsSaga] = createRequestStateSaga(getWebsiteMySQLDBs, mysqlApi.getWebsiteMySQLDBs);
const [getWebsiteEmailsSaga] = createRequestStateSaga(getWebsiteEmails, emailsApi.getWebsiteEmails);

export function* websiteBackupsSaga() {
  yield takeEvery(getType(backupWebsite.request), backupWebsiteSaga);
  yield takeEvery(getType(deleteWebsiteBackup.request), deleteWebsiteBackupSaga);
  yield takeEvery(getType(getWebsiteBackup.request), getWebsiteBackupSaga);
  yield takeEvery(getType(getWebsiteBackups.request), getWebsiteBackupsSaga);
  yield takeEvery(getType(restoreWebsite.request), customRestoreWebsiteSaga);
  yield takeEvery(getType(getWebsiteMySQLDBs.request), getWebsiteMySQLDBsSaga);
  yield takeEvery(getType(getWebsiteEmails.request), getWebsiteEmailsSaga);
}

export function* customRestoreWebsiteSaga({ payload }: ActionType<typeof restoreWebsite.request>) {
  const { onSuccess, onError, params } = payload;
  const { backupId, orgId, websiteId, backupRestoreOptions } = params;
  const { restoreFiles, restoreDatabases, restoreEmails, restoreAllEmails } = backupRestoreOptions ?? {};

  const newBackupRestoreOptions: BackupRestoreOptions = {};
  if (restoreFiles !== undefined) newBackupRestoreOptions.restoreFiles = restoreFiles;
  if (restoreDatabases !== undefined) newBackupRestoreOptions.restoreDatabases = restoreDatabases;
  if (restoreEmails !== undefined) newBackupRestoreOptions.restoreEmails = restoreEmails;
  if (restoreAllEmails !== undefined) newBackupRestoreOptions.restoreAllEmails = restoreAllEmails;

  try {
    const response: AxiosResponse<void> = yield call(backupsApi.restoreWebsite, {
      orgId,
      websiteId,
      backupId,
      backupRestoreOptions: newBackupRestoreOptions,
    });

    yield put(restoreWebsite.success({ data: response.data }));

    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (e) {
    const error = e as Error;

    yield put(restoreWebsite.error({ error: error.message }));

    if (onError) {
      onError(error);
    }
  }
}
