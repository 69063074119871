import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { backupsApi } from './service';

export const useGetWebsiteBackupsQuery = ({
  orgId,
  websiteId,
  enabled = true,
}: {
  orgId: string;
  websiteId: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: queryKeys.websiteBackups({ orgId, websiteId }),
    queryFn: () => backupsApi.getWebsiteBackups({ orgId, websiteId }),
    select: ({ data }) => data.items,
    refetchOnMount: true,
    keepPreviousData: true,
    enabled,
  });

export const useBackupWebsiteMutation = () => useMutation({ mutationFn: backupsApi.backupWebsite });
