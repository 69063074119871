import { createReducer } from 'deox';
import { ServerInfoBrief } from 'src/orchd-client';

import { getUnconfiguredServers } from './actions';

export interface State {
  loading: boolean;
  error: string;
  data: ServerInfoBrief[];
}

export const initialState: State = {
  data: [],
  loading: false,
  error: '',
};

export const unconfiguredServersReducer = createReducer(initialState, (handleAction) => [
  handleAction(getUnconfiguredServers.request, (state) => ({
    ...state,
    loading: true,
  })),
  handleAction(getUnconfiguredServers.success, (state, { payload }) => ({
    ...state,
    data: payload.data.items.filter((server) => !server.groupId),
    loading: false,
  })),
  handleAction(getUnconfiguredServers.error, (state) => ({
    ...state,
    error: 'TBC',
    loading: false,
  })),
]);
