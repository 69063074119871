import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import { websitesActions } from './actions';

export type WebsitesState = ReturnType<typeof websitesReducer>;

export const websitesReducer = combineReducers({
  createPreviewDomain: createRequestStateReducer(websitesActions.createPreviewDomain),
  deleteWebsite: createRequestStateReducer(websitesActions.deleteWebsite),
  getAccessToken: createRequestStateReducer(websitesActions.getAccessToken, {
    data: '',
    loading: false,
    error: '',
  }),
  getWebsite: createRequestStateReducer(websitesActions.getWebsite),
  getWebsiteHtaccessRewrites: createRequestStateReducer(websitesActions.getWebsiteHtaccessRewrites),
  getWebsiteMySQLDBs: createRequestStateReducer(websitesActions.getWebsiteMySQLDBs),
  getWebsites: createRequestStateReducer(websitesActions.getWebsites, {
    loading: false,
    error: '',
    data: {
      total: 0,
      items: [],
    },
  }),
  serviceSetting: createRequestStateReducer(websitesActions.getWebsiteSetting),
  updateWebsite: createRequestStateReducer(websitesActions.updateWebsite),
  websiteServerDomains: createRequestStateReducer(websitesActions.getWebsiteServerDomains),
  screenshotTimestamp: createRequestStateReducer(websitesActions.getScreenshotTimestamp),
  websiteDomainMappingDnsStatus: createRequestStateReducer(websitesActions.getWebsiteDomainMappingDnsStatus),
});
