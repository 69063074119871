import { extendedOrgsApi } from 'src/api_services/orgs/service';
import { GetOrgMembersReq, GetOrgMembersRes } from 'src/api_services/orgs/types';

import { createActionsFromMethod, createRequestStateActions } from '../helpers';

const getOrg = createActionsFromMethod<typeof extendedOrgsApi.getOrg>('@ORGS/GET_ORG');

const getOrgMembers =
  createRequestStateActions<{ params: GetOrgMembersReq; data: GetOrgMembersRes }>('@ORGS/GET_ORG_MEMBERS');

const updateOrg = createActionsFromMethod<typeof extendedOrgsApi.updateOrg>('@ORGS/UPDATE_ORG');

export const orgsActions = {
  getOrg,
  getOrgMembers,
  updateOrg,
};
