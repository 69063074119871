/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/core';
import classNames from 'classnames';

import { DropdownOption } from 'src/components/DropdownMenu/DropdownMenu';
import { Colors, Margins, Paddings, Responsive } from 'src/design-system/style-types';
import { Theme } from 'src/design-system/theme';

import CardBody from '../CardBody/CardBody';
import CardFooter from '../CardFooter/CardFooter';
import { CardHeader } from '../CardHeader/CardHeader';
import { style } from './Card.style';

interface RequiredProps {
  children: React.ReactNode;
  title?: string;
}

export type CardDropdownOptions = {
  label: string;
  url?: string;
  onClick: () => void;
}[];

export interface DefaultProps {
  className: string;
  padding: Responsive<Paddings>;
  paddingX?: Responsive<Paddings>;
  paddingY?: Responsive<Paddings>;
  paddingTop?: Responsive<Paddings>;
  paddingRight?: Responsive<Paddings>;
  paddingBottom?: Responsive<Paddings>;
  paddingLeft?: Responsive<Paddings>;
  pageCardPadding: boolean;
  marginBottom: Responsive<Margins>;
  marginLeft: Responsive<Margins>;
  fullWidth: boolean;
  noShadow: Responsive<boolean>;
  hasHoverClass: boolean;
  fullHeight: boolean;
  background: Colors;
  borderColor: Colors;
  dataTestId?: string;
  dataDemoDisabled?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
  'data-name'?: string;
  dropdownOptions: DropdownOption[];
  dropdownId: string;
  dropdownCentered: boolean;
  relative?: boolean;
  stretch: boolean;
  minWidth?: Responsive<string>;
}

export type Props = RequiredProps & DefaultProps;

export const defaultProps: DefaultProps = {
  className: '',
  padding: 'lg',
  marginBottom: '0',
  marginLeft: '0',
  fullWidth: true,
  fullHeight: false,
  noShadow: false,
  background: 'light',
  hasHoverClass: true,
  borderColor: 'transparent',
  pageCardPadding: false,
  dropdownOptions: [],
  dropdownId: '',
  dropdownCentered: false,
  stretch: false,
};

/**
 * # Card
 * Card can be used by itself or optionally with CardHeader, CardBody or CardFooter
 */
const isAdvancedCard = (children: React.ReactNode): boolean =>
  React.Children.toArray(children).some((child) => {
    return (
      React.isValidElement(child) && (child.type === CardHeader || child.type === CardBody || child.type === CardFooter)
    );
  });

const Card = (props: RequiredProps & DefaultProps) => {
  const {
    children,
    className,
    dataTestId,
    dataDemoDisabled,
    dropdownCentered,
    dropdownId,
    dropdownOptions,
    fullHeight,
    hasHoverClass,
    innerRef,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    pageCardPadding,
    title,
  } = props;

  const isAdvanced = isAdvancedCard(children);

  return (
    <div
      title={title}
      data-qa="card"
      ref={innerRef}
      css={(theme: Theme) => css`
        ${style.container(props)(theme)}
      `}
      className={classNames(className, { 'ui-card-hoverable': hasHoverClass })}
      // eslint-disable-next-line react/destructuring-assignment
      data-name={props['data-name']}
      data-testid={dataTestId}
      data-demo-disabled={dataDemoDisabled}
      role="gridcell"
    >
      {isAdvanced ? (
        children
      ) : (
        <CardBody
          padding={padding}
          paddingX={paddingX}
          paddingY={paddingY}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          pageCardPadding={pageCardPadding}
          fullHeight={fullHeight}
          data-qa="card-body-basic-mode"
          dropdownOptions={dropdownOptions}
          dropdownId={dropdownId}
          dropdownCentered={dropdownCentered}
        >
          {children}
        </CardBody>
      )}
    </div>
  );
};

Card.defaultProps = defaultProps;

export default Card;
