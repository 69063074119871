import { createSelector } from 'reselect';

import { AppState } from 'src/store';
import { impersonationSelectors } from 'src/store/impersonation/selectors';
import { subscriptionsSelectors } from 'src/store/subscriptions/selectors';
import { hasAtLeastOneRole, permissions } from 'src/utils/permissions/utils';

import { loginsSelectors } from '../logins/selectors';

export const getSession = (state: AppState) => state.session;
export const getIsLoggedIn = (state: AppState) => getSession(state).isLoggedIn;
export const getIsLoggingOut = (state: AppState) => getSession(state).isLoggingOut;
export const getCurrentMemberId = (state: AppState) => getSession(state).currentMemberId;

/**
 * @deprecated
 * AVOID!
 * almost always preferable to use getMember as it will take the impersonated member first
 */
export const getLoginMemberOrUndefined = (state: AppState) => {
  const currentMemberId: string = getCurrentMemberId(state);
  const loginMemberships = loginsSelectors.loginMemberships(state);

  return loginMemberships?.find((membership) => membership.memberId === currentMemberId);
};

/**
 * @deprecated
 * AVOID!
 * almost always preferable to use getMember as it will take the impersonated member first
 */
export const getLoginMember = (state: AppState) => {
  const member = getLoginMemberOrUndefined(state);

  if (!member) {
    throw new Error("Undefined member, shouldn't happen");
  }

  return member;
};

// get impersonation if impersonating else login member
export const getMember = (state: AppState) => {
  const member = impersonationSelectors.getImpersonationMember(state);

  if (member) {
    return member;
  }

  return getLoginMember(state);
};

// SAFE get member or undefined (developer tools)
export const getMemberOrUndefined = (state: AppState) => {
  try {
    return getMember(state);
  } catch {
    return undefined;
  }
};

export const selectIsMasterOrg = createSelector(getMemberOrUndefined, (member) => !!member?.isMasterOrg);

export const selectIsEndUser = createSelector(
  [selectIsMasterOrg, subscriptionsSelectors.selectIsReseller, impersonationSelectors.getImpersonationMember],
  (isMo, isReseller, impersonationMember) => !isMo || (!!impersonationMember && !isReseller)
);

export const canAccessServers = createSelector(
  getMemberOrUndefined,
  (member) => !!(member && member.isMasterOrg && hasAtLeastOneRole(permissions.showServers, member))
);

export const selectOrgId = createSelector(getMember, ({ orgId }) => orgId);

export const sessionSelectors = {
  getIsLoggedIn,
  getIsLoggingOut,
  getLoginMember,
  getLoginMemberOrUndefined,
  getMember,
  getMemberOrUndefined,
  getSession,
  getCurrentMemberId,
  canAccessServers,
  selectIsEndUser,
  selectIsMasterOrg,
  selectOrgId,
};
