import React, { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Span, Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { useToast } from 'src/utils/toast/useToast';

import { bulkUpdatePhpAtom } from './atoms';

export const UpdatePhpVersionToast = () => {
  const toast = useToast();
  const [{ version, websites = [], statuses = {}, completed }, setBulkUpdatePhp] = useAtom(bulkUpdatePhpAtom);

  const changeableWebsites = useMemo(() => {
    return websites.filter((website) => website.phpVersion !== version);
  }, [websites, version]);

  const autosave = useMemo(() => toast.autosave({}), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (completed) {
      return;
    }

    const countCompleted = Object.keys(statuses).length;

    if (countCompleted < changeableWebsites.length) {
      return void autosave.neutral(
        <Text color="light" size="sm">
          <FormattedMessage
            id="websites.update_php.toast.progress"
            defaultMessage="Bulk PHP version update in progress - {inProgress} in progress · {completed} completed"
            values={{ inProgress: changeableWebsites.length - countCompleted, completed: countCompleted }}
          />
          &nbsp;&middot;&nbsp;
          <Link to="/websites/update-php-version">
            <Span color="light" size="sm" textDecoration="underline">
              <FormattedMessage {...messages.check_progress} />
            </Span>
          </Link>
        </Text>
      );
    }

    setBulkUpdatePhp((state) => ({ ...state, completed: true }));

    autosave.success(
      <Text color="light" size="sm">
        <FormattedMessage id="websites.update_php.toast.success" defaultMessage="Bulk PHP version update completed" />
        &nbsp;&middot;&nbsp;
        <Link to="/websites/update-php-version">
          <Span color="light" size="sm" textDecoration="underline">
            <FormattedMessage {...messages.check_status} />
          </Span>
        </Link>
      </Text>
    );
  }, [websites, statuses]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
