import { GetServersReq, GetServersRes } from 'src/api_services/servers/types';
import { SERVERS_NAMESPACE } from 'src/pages/servers/constants';
import { createRequestStateActions } from 'src/store/helpers';

/**
 * todo - move code/refactor to src/store/servers
 */
export const getUnconfiguredServers = createRequestStateActions<{ params: GetServersReq; data: GetServersRes }>(
  `${SERVERS_NAMESPACE}/GET_UNCONFIGURED_SERVERS`
);
