import { CPLocale } from 'src/orchd-client';

const rtlLocalesSet = new Set([
  CPLocale.ar,
  CPLocale.ar_AE,
  CPLocale.ar_BH,
  CPLocale.ar_DZ,
  CPLocale.ar_EG,
  CPLocale.ar_IQ,
  CPLocale.ar_JO,
  CPLocale.ar_KW,
  CPLocale.ar_LB,
  CPLocale.ar_LY,
  CPLocale.ar_MA,
  CPLocale.ar_OM,
  CPLocale.ar_QA,
  CPLocale.ar_SA,
  CPLocale.ar_SY,
  CPLocale.ar_TN,
  CPLocale.dv,
  CPLocale.dv_MV,
  CPLocale.fa,
  CPLocale.fa_IR,
  CPLocale.ps,
]);

export type LocaleTextDirection = 'ltr' | 'rtl';

export const getLocaleTextDirection = (locale: CPLocale): LocaleTextDirection => {
  return rtlLocalesSet.has(locale) ? 'rtl' : 'ltr';
};
