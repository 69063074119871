import { PhpVersion, Website } from 'src/orchd-client';

import { persistentAtom } from 'src/utils/atoms/persistentAtom';

export interface PhpVersionUpdateStatus {
  status: 'error' | 'success';
  error?: string;
}

export const bulkUpdatePhpAtom = persistentAtom<{
  version?: PhpVersion;
  websites: Website[];
  statuses: Record<string, PhpVersionUpdateStatus>;
  completed: boolean;
}>(
  'bulkUpdatePhp',
  {
    version: undefined,
    websites: [],
    statuses: {},
    completed: true,
  },
  { isPerUser: true }
);
