import { createIntl, createIntlCache } from 'react-intl';

import { store } from 'src/store';
import { loginsSelectors } from 'src/store/logins/selectors';
import { getMessages } from 'src/utils/intl/getMessages/getMessages';

export const intl = async () => {
  const cache = createIntlCache();

  const locale = loginsSelectors.selectLocale(store.getState());

  const { messages } = await getMessages(locale);

  return createIntl(
    {
      locale,
      messages,
    },
    cache
  );
};
