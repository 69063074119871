import { RolesInfo, RolesSummary, ServerInfo, ServerInfoBrief } from 'src/orchd-client';
import { AxiosError } from 'axios';
import { createActionCreator } from 'deox';

import { serversApi } from 'src/api_services/servers/service';
import { FetchingState } from 'src/store/helpers';

import { createActionsFromMethod } from '../helpers';

export interface DeleteServerPayload {
  id: string;
  friendlyName: string;
  roles?: RolesSummary;
  isControlPanel?: boolean;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

const namespace = '@servers';

const getServers = createActionsFromMethod<typeof serversApi.getServers>(`getServers(${namespace})`);

const getGroupedServers = createActionsFromMethod<typeof serversApi.getServers>(`getGroupedServers(${namespace})`);

const getServerInfo = createActionsFromMethod<typeof serversApi.getServerInfo>(`getServerInfo(${namespace})`);

const configureServer = createActionsFromMethod<typeof serversApi.configureServer>(`configureServer(${namespace})`);

const getInstallCmd = createActionsFromMethod<typeof serversApi.getInstallCmd>(`getInstallCmd(${namespace})`);

const installServerRole = createActionsFromMethod<typeof serversApi.installServerRole>(
  `installServerRole(${namespace})`
);

const setServerStatus = createActionsFromMethod<typeof serversApi.setServerStatus>(`setServerStatus(${namespace})`);

const getServiceSetting = createActionsFromMethod<typeof serversApi.getServiceSetting>(
  `getServiceSetting(${namespace})`
);

const setServiceSetting = createActionsFromMethod<typeof serversApi.setServiceSetting>(
  `setServiceSetting(${namespace})`
);

const deleteServiceSetting = createActionsFromMethod<typeof serversApi.deleteServiceSetting>(
  `deleteServiceSetting(${namespace})`
);

const getServerRoles = createActionsFromMethod<typeof serversApi.getServerRoles>(`getServerRoles(${namespace})`);

const getAllServerStatuses = createActionCreator(`getAllServerStatuses(${namespace})`);

const setAllServerStatusesLoading = createActionCreator(
  `setAllServerStatusesLoading(${namespace})`,
  (resolve) => (payload: { servers: ServerInfoBrief[] }) => resolve(payload)
);

const setSingleServerStatuses = createActionCreator(
  `setSingleServerStatuses(${namespace})`,
  (resolve) =>
    (payload: { serverId: string; resp: FetchingState<{ roles: RolesInfo; info: ServerInfo } | undefined> }) =>
      resolve(payload)
);

const resetAllServerStatuses = createActionCreator(`resetAllServerStatuses(${namespace})`);

const createServerDomain = createActionsFromMethod<typeof serversApi.createServerDomain>(
  `createServerDomain(${namespace})`
);

const deleteServerDomain = createActionsFromMethod<typeof serversApi.deleteServerDomain>(
  `deleteServerDomain(${namespace})`
);

const deleteServer = {
  request: createActionCreator(
    `deleteServer(${namespace})`,
    (resolve) => (payload: DeleteServerPayload) => resolve(payload)
  ),
};

const updateServerPrimaryIp = createActionsFromMethod<typeof serversApi.updateServerPrimaryIp>(
  `updateServerPrimaryIp(${namespace})`
);

const getWebserverKind = createActionsFromMethod<typeof serversApi.getWebserverKind>(`getWebserverKind(${namespace})`);

const setWebserverKind = createActionsFromMethod<typeof serversApi.setWebserverKind>(`setWebserverKind(${namespace})`);

export const serversActions = {
  configureServer,
  createServerDomain,
  deleteServer,
  deleteServerDomain,
  deleteServiceSetting,
  getAllServerStatuses,
  getGroupedServers,
  getInstallCmd,
  getServerInfo,
  getServerRoles,
  getServers,
  getServiceSetting,
  installServerRole,
  resetAllServerStatuses,
  setAllServerStatusesLoading,
  setServerStatus,
  setServiceSetting,
  setSingleServerStatuses,
  updateServerPrimaryIp,
  getWebserverKind,
  setWebserverKind,
};
