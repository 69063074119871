import { createActionCreator } from 'deox';

const namespace = '@notifications';

const addReadNotification = createActionCreator(
  `addReadNotification(${namespace})`,
  (resolve) => (payload: string) => resolve(payload)
);

export const notificationsActions = {
  addReadNotification,
};
