import { ActionType, getType } from 'deox';
import { call, put, select, take, takeLatest } from 'typed-redux-saga/macro';

import { filerdApi } from 'src/api_services/filerd/service';
import { sessionSelectors } from 'src/store/session/selectors';
import { websitesActions } from 'src/store/websites/actions';
import { websitesSelectors } from 'src/store/websites/selectors';
import { getErrorInfo } from 'src/utils/errors';
import { getBearerToken } from 'src/utils/getBearerToken';

import { filerdActions } from './actions';
import { getFilerdUrl } from './helpers';

export function* getWebsiteEntriesSaga({ payload }: ActionType<typeof filerdActions.getWebsiteEntries.request>) {
  try {
    const { orgId } = yield* select(sessionSelectors.getMember);
    yield put(websitesActions.getAccessToken.request({ params: { orgId, websiteId: payload.params.websiteId } }));
    yield take(websitesActions.getAccessToken.success);

    const website = yield* select(websitesSelectors.getWebsite);
    if (!website) {
      throw new Error('No website');
    }
    const accessToken = yield* select(websitesSelectors.getAccessToken);
    // Call filerd.
    const res = yield* call(filerdApi.getWebsiteEntries, payload.params, {
      baseURL: getFilerdUrl(website),
      headers: { Authorization: getBearerToken(accessToken.data) },
    });
    yield put(filerdActions.getWebsiteEntries.success({ data: res.data }));
    if (payload.onSuccess) {
      payload.onSuccess();
    }
  } catch (e) {
    const { code } = getErrorInfo(e);
    const error = code || String(e);
    yield put(filerdActions.getWebsiteEntries.error({ error }));
    if (payload.onError) {
      payload.onError();
    }
  }
}

export function* checkEntrySaga({ payload }: ActionType<typeof filerdActions.checkEntry.request>) {
  try {
    const { orgId } = yield* select(sessionSelectors.getMember);
    yield put(websitesActions.getAccessToken.request({ params: { orgId, websiteId: payload.params.websiteId } }));
    yield take(websitesActions.getAccessToken.success);

    const website = yield* select(websitesSelectors.getWebsite);
    if (!website) {
      throw new Error('No website');
    }
    const accessToken = yield* select(websitesSelectors.getAccessToken);

    const res = yield* call(filerdApi.checkEntry, payload.params, {
      baseURL: getFilerdUrl(website),
      headers: { Authorization: getBearerToken(accessToken.data) },
    });

    yield put(filerdActions.checkEntry.success({ data: res.data }));

    if (payload.onSuccess) {
      payload.onSuccess();
    }
  } catch (e) {
    const { code } = getErrorInfo(e);
    const error = code || String(e);
    yield put(filerdActions.checkEntry.error({ error }));

    if (payload.onError) {
      payload.onError();
    }
  }
}

export function* filerdSaga() {
  yield takeLatest(getType(filerdActions.getWebsiteEntries.request), getWebsiteEntriesSaga);
  yield takeLatest(getType(filerdActions.checkEntry.request), checkEntrySaga);
}
