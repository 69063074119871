import { CPLocale, Role, ServerRole } from 'src/orchd-client';

import { RoleIconsMap, ServerRoleNames, UsageLevel } from './types';

export const LETS_ENCRYPT = "Let's Encrypt";

export const DEFAULT_LOCALE = CPLocale.en_GB;

export const iconsRoleMapping: RoleIconsMap = {
  Sysadmin: 'support',
  SiteAccess: 'dev',
  Support: 'chat',
  SuperAdmin: 'star',
  Business: 'work',
  Owner: 'user',
};

export const MEMBER_ROLES: Role[] = [
  Role.Owner,
  Role.SuperAdmin,
  Role.Sysadmin,
  Role.Support,
  Role.Business,
  Role.SiteAccess,
];

export const rolesObj = <T,>(defaultValue: T): Record<Role, T> => ({
  [Role.Owner]: defaultValue,
  [Role.SuperAdmin]: defaultValue,
  [Role.Sysadmin]: defaultValue,
  [Role.Support]: defaultValue,
  [Role.Business]: defaultValue,
  [Role.SiteAccess]: defaultValue,
});

export const SEARCH_DEBOUNCE_DELAY = 600;
export const SAVE_SEARCH_DEBOUNCE_DELAY = 2000;
export const SEARCH_TABLE_ITEMS_PER_PAGE = 10;
export const TOAST_EXPIRY_MS = 4000;
export const MAX_TOASTS = 5;

export const CORE_SERVER_ROLE_NAMES: ServerRole[] = [
  ServerRole.application,
  ServerRole.database,
  ServerRole.email,
  ServerRole.backup,
  ServerRole.dns,
];

export const CORE_WEBSITE_SERVER_ROLE_NAMES: ServerRole[] = [
  ServerRole.application,
  ServerRole.database,
  ServerRole.email,
  ServerRole.backup,
];

export const SERVER_ROLE_NAMES: ServerRoleNames[] = [
  ServerRole.application,
  ServerRole.database,
  ServerRole.email,
  ServerRole.backup,
  ServerRole.dns,
];

export const SERVER_USAGE: Record<string, UsageLevel> = {
  low: {
    level: 'low',
    color: 'success',
  },
  medium: {
    level: 'medium',
    color: 'warning',
  },
  high: {
    level: 'high',
    color: 'error',
  },
};

// @TODO: this will eventually be fetched from orchd
export const RESTORE_DAY_COUNT = 5;

export const DEFAULT_ITEMS_STATE = {
  loading: false,
  error: '',
  data: {
    total: 0,
    items: [],
  },
};

export const HOUR_IN_SECONDS = 3600;
export const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
export const TEN_SECONDS = 10000;

export const WOOCOMMERCE = 'woocommerce';
