/* tslint:disable */
/* eslint-disable */
/**
 * filerd
 * Filesystem operations service API docs. 
 *
 * The version of the OpenAPI document: 3.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosResponse, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Backup
 */
export interface Backup {
    /**
     * The action to be taken in regards to the website backup, to either create a new backup or restore an existing one.
     * @type {string}
     * @memberof Backup
     */
    action: BackupActionEnum;
    /**
     * The private SSH key to use for the rsync.
     * @type {string}
     * @memberof Backup
     */
    key: string;
    /**
     * The path used by rsync as destination (when creating a new backup) or source (when restoring an existing backup) with the format `{account-name}@{backups-external-ip}:{website-subvolume-path}`.
     * @type {string}
     * @memberof Backup
     */
    path: string;
    /**
     * The port the sshd daemon in the backups host is listening to.
     * @type {number}
     * @memberof Backup
     */
    port?: number;
    /**
     * The pattern to exclude when remote syncing.
     * @type {string}
     * @memberof Backup
     */
    exclude?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BackupActionEnum {
    backup = 'backup',
    restore = 'restore'
}

/**
 * 
 * @export
 * @interface Dir
 */
export interface Dir {
    /**
     * 
     * @type {string}
     * @memberof Dir
     */
    path?: string;
    /**
     * List of sub-entries of this directory. If the array is empty it means that the directory contains sub-entries, but due to the recursion limit the directory was not explored. If the directory does not contain any sub-entry, or its file permission bits do not allow to check its content, this field won\'t be reported.
     * @type {Array<DirEntries>}
     * @memberof Dir
     */
    entries?: Array<DirEntries>;
    /**
     * 
     * @type {Metadata}
     * @memberof Dir
     */
    metadata?: Metadata;
}
/**
 * 
 * @export
 * @interface DirEntries
 */
export interface DirEntries {
    /**
     * 
     * @type {any}
     * @memberof DirEntries
     */
    file?: any;
    /**
     * 
     * @type {Dir}
     * @memberof DirEntries
     */
    dir?: Dir;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject
     */
    entries: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof InlineObject1
     */
    entries: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Dir}
     * @memberof InlineResponse200
     */
    dir?: Dir;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * Size of the entry in bytes
     * @type {number}
     * @memberof Metadata
     */
    size?: number;
    /**
     * Last modified time as elapsed seconds since the UNIX_EPOCH (this field will not be reported if not supported by the platform)
     * @type {number}
     * @memberof Metadata
     */
    modified?: number;
    /**
     * Unix file permission bits (base 10)
     * @type {number}
     * @memberof Metadata
     */
    permissions?: number;
    /**
     * The type of the entry.
     * @type {string}
     * @memberof Metadata
     */
    kind?: MetadataKindEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MetadataKindEnum {
    file = 'file',
    directory = 'directory',
    symlink = 'symlink'
}

/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string;
    /**
     * 
     * @type {Metadata}
     * @memberof ModelFile
     */
    metadata?: Metadata;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Gets the version of the service -  */
        getServiceVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for getServiceVersion operation in DefaultApi. */
export type DefaultApiGetServiceVersionReq = {
};

/** Response parameters for getServiceVersion operation in DefaultApi. */
export type DefaultApiGetServiceVersionRes = string;

/**
 * DefaultApi - service client interface
 */
export const DefaultApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Gets the version of the service -  */
    getServiceVersion: async (reqParams: DefaultApiGetServiceVersionReq, options?: any): Promise<AxiosResponse<DefaultApiGetServiceVersionRes>> => {
        const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getServiceVersion(options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface DefaultApiReq {
    getServiceVersion: DefaultApiGetServiceVersionReq;
}

export interface DefaultApiRes {
    getServiceVersion: DefaultApiGetServiceVersionRes;
}


/**
 * WebsitesApi - axios parameter creator
 * @export
 */
export const WebsitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /** Checks if the entry in {path} exists -  */
        checkEntry: async (websiteId: string, path: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Creates a new file or empty directory in {path} - If the request body exists as multipart/form-data, a new file will be created with the content coming from the multipart field stream, otherwise a new directory will be created in the provided path. */
        createEntry: async (websiteId: string, path: string, overwrite?: boolean, uncompress?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }

            if (uncompress !== undefined) {
                localVarQueryParameter['uncompress'] = uncompress;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Creates or restore the {website_id} backup - Depending on the request body, this endpoint will perform a full backup of the website home directory in {website_id} to a remote host via rsync over SSH, or restore the home directory from a remote host. */
        createOrRestoreBackup: async (websiteId: string, backup?: Backup, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/backups`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof backup !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(backup !== undefined ? backup : {}) : (backup || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Delete all the entries in {website_id} listed in the request body -  */
        deleteWebsiteEntries: async (websiteId: string, inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/entries`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {}) : (inlineObject1 || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the entries in {path} -  */
        getDirectoryEntries: async (websiteId: string, path: string, recursive?: boolean, maxDepth?: number, fetchMetadata?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}/entries`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['maxDepth'] = maxDepth;
            }

            if (fetchMetadata !== undefined) {
                localVarQueryParameter['fetchMetadata'] = fetchMetadata;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the {path} metadata - When querying a directory, the size field of the response will correspond to the size of the whole directory, computed by summing the size of its sub-entries following a recursive call with not depth limit. */
        getEntryMetadata: async (websiteId: string, path: string, fetchSize?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}/metadata`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (fetchSize !== undefined) {
                localVarQueryParameter['fetchSize'] = fetchSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the content of a file in {path} -  */
        getFileContent: async (websiteId: string, path: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the entries in the {website_id} directory -  */
        getWebsiteEntries: async (websiteId: string, recursive?: boolean, maxDepth?: number, fetchMetadata?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/entries`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }

            if (maxDepth !== undefined) {
                localVarQueryParameter['maxDepth'] = maxDepth;
            }

            if (fetchMetadata !== undefined) {
                localVarQueryParameter['fetchMetadata'] = fetchMetadata;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Gets the {website_id} directory metadata - The size field of the response will correspond to the size of the whole directory, computed by summing the size of its sub-entries following a recursive call with not depth limit. */
        getWebsiteMetadata: async (websiteId: string, fetchSize?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/metadata`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (fetchSize !== undefined) {
                localVarQueryParameter['fetchSize'] = fetchSize;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Maps the {path} according to the query - The purpose of this API is to apply the specified mandatory action (part of the query arguments) to the the resource specified in the URL. */
        mapEntry: async (websiteId: string, path: string, action: 'compress' | 'uncompress' | 'rename' | 'copy', path2?: string, overwrite?: boolean, format?: 'gz' | 'tar' | 'tar.gz' | 'zip', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (path2 !== undefined) {
                localVarQueryParameter['path'] = path2;
            }

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Maps the entries in {website_id} listed in the request body according to the query - The purpose of this API is to apply the specified mandatory action (part of the query arguments) to the the resources specified in the request body. */
        mapWebsiteEntries: async (websiteId: string, action: 'compress' | 'rename' | 'copy', path: string, inlineObject: InlineObject, overwrite?: boolean, format?: 'tar' | 'tar.gz' | 'zip', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/entries`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inlineObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject !== undefined ? inlineObject : {}) : (inlineObject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Removes the entry in {path} -  */
        removeEntry: async (websiteId: string, path: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Sets the {path} entry permissions -  */
        setEntryMetadata: async (websiteId: string, path: string, permissions: number, recursive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/fs/{path}/metadata`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (permissions !== undefined) {
                localVarQueryParameter['permissions'] = permissions;
            }

            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /** Sets the {website_id} directory permissions -  */
        setWebsiteMetadata: async (websiteId: string, permissions: number, recursive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/websites/{website_id}/metadata`
                .replace(`{${"website_id"}}`, encodeURIComponent(String(websiteId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (permissions !== undefined) {
                localVarQueryParameter['permissions'] = permissions;
            }

            if (recursive !== undefined) {
                localVarQueryParameter['recursive'] = recursive;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};


/** Request parameters for checkEntry operation in WebsitesApi. */
export type WebsitesApiCheckEntryReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the entry */
    path: string
};

/** Response parameters for checkEntry operation in WebsitesApi. */
export type WebsitesApiCheckEntryRes = void;


/** Request parameters for createEntry operation in WebsitesApi. */
export type WebsitesApiCreateEntryReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the file to be uploaded */
    path: string
    /** If true the operation will overwrite the destination entry if it exists. */
    overwrite?: boolean
    /** If true this operation will uncompress the uploaded file according to its extension, and then delete the source archive if the uncompression is successful (the value of the overwrite flag will be propagated to the content of the archive, but not to the archive itself). */
    uncompress?: boolean
};

/** Response parameters for createEntry operation in WebsitesApi. */
export type WebsitesApiCreateEntryRes = string;


/** Request parameters for createOrRestoreBackup operation in WebsitesApi. */
export type WebsitesApiCreateOrRestoreBackupReq = {
    /** The UUID of the website */
    websiteId: string
    /** The information required to perform a backup or restore an existing one. */
    backup?: Backup
};

/** Response parameters for createOrRestoreBackup operation in WebsitesApi. */
export type WebsitesApiCreateOrRestoreBackupRes = void;


/** Request parameters for deleteWebsiteEntries operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteEntriesReq = {
    /** The UUID of the website */
    websiteId: string
    inlineObject1: InlineObject1
};

/** Response parameters for deleteWebsiteEntries operation in WebsitesApi. */
export type WebsitesApiDeleteWebsiteEntriesRes = void;


/** Request parameters for getDirectoryEntries operation in WebsitesApi. */
export type WebsitesApiGetDirectoryEntriesReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the directory */
    path: string
    /** If true, perform a recursive visit of the directory. */
    recursive?: boolean
    /** Maximum depth of the recursive directory visit. */
    maxDepth?: number
    /** If true the entries returned will include metadata information. In the case of directories, the size field of the metadata will not include the size of its sub-entries, but will be equal to the block size of the inode on the disk. In order to get the size of the directory including its sub-entries you need to query its metadata explicitly. */
    fetchMetadata?: boolean
};

/** Response parameters for getDirectoryEntries operation in WebsitesApi. */
export type WebsitesApiGetDirectoryEntriesRes = InlineResponse200;


/** Request parameters for getEntryMetadata operation in WebsitesApi. */
export type WebsitesApiGetEntryMetadataReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the entry */
    path: string
    /** If false, the metadata returned will not contain information about the size of the entry. */
    fetchSize?: boolean
};

/** Response parameters for getEntryMetadata operation in WebsitesApi. */
export type WebsitesApiGetEntryMetadataRes = Metadata;


/** Request parameters for getFileContent operation in WebsitesApi. */
export type WebsitesApiGetFileContentReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the file to be downloaded */
    path: string
};

/** Response parameters for getFileContent operation in WebsitesApi. */
export type WebsitesApiGetFileContentRes = any;


/** Request parameters for getWebsiteEntries operation in WebsitesApi. */
export type WebsitesApiGetWebsiteEntriesReq = {
    /** The UUID of the website */
    websiteId: string
    /** If true, performs a recursive visit of the directory. */
    recursive?: boolean
    /** Maximum depth of the recursive directory visit. */
    maxDepth?: number
    /** If true the entries returned will include metadata information. In the case of directories, the size field of the metadata will not include the size of its sub-entries, but will be equal to the block size of the inode on the disk. In order to get the size of the directory including its sub-entries you need to query its metadata explicitly. */
    fetchMetadata?: boolean
};

/** Response parameters for getWebsiteEntries operation in WebsitesApi. */
export type WebsitesApiGetWebsiteEntriesRes = InlineResponse200;


/** Request parameters for getWebsiteMetadata operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMetadataReq = {
    /** The UUID of the website */
    websiteId: string
    /** If false, the metadata returned will not contain information about the size of the website directory. */
    fetchSize?: boolean
};

/** Response parameters for getWebsiteMetadata operation in WebsitesApi. */
export type WebsitesApiGetWebsiteMetadataRes = Metadata;


/** Request parameters for mapEntry operation in WebsitesApi. */
export type WebsitesApiMapEntryReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the entry to map */
    path: string
    /** The filesystem operation to apply to the source entry. */
    action: 'compress' | 'uncompress' | 'rename' | 'copy'
    /** The path of the destination entry, valid only if the action is `rename` or `copy`. In the case of `copy`, this path can refer to either a local path, or a remote path (pointing to a different instance of `filerd`) depending on its format. */
    path2?: string
    /** If true the operation will overwrite the destination entry if it exists (in case of uncompression the destination path may be stored in the archive metadata). */
    overwrite?: boolean
    /** The compression format, valid only if the action is `compress`. */
    format?: 'gz' | 'tar' | 'tar.gz' | 'zip'
};

/** Response parameters for mapEntry operation in WebsitesApi. */
export type WebsitesApiMapEntryRes = string;


/** Request parameters for mapWebsiteEntries operation in WebsitesApi. */
export type WebsitesApiMapWebsiteEntriesReq = {
    /** The UUID of the website */
    websiteId: string
    /** The filesystem operation to apply to the source entries listed in the request body. */
    action: 'compress' | 'rename' | 'copy'
    /** The path of the destination directory (where to store the compressed archive, or move/copy all the specified entries). */
    path: string
    inlineObject: InlineObject
    /** If true the operation will overwrite the destination entry if it exists. */
    overwrite?: boolean
    /** The compression format, valid only if the action is `compress`. */
    format?: 'tar' | 'tar.gz' | 'zip'
};

/** Response parameters for mapWebsiteEntries operation in WebsitesApi. */
export type WebsitesApiMapWebsiteEntriesRes = string;


/** Request parameters for removeEntry operation in WebsitesApi. */
export type WebsitesApiRemoveEntryReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the entry to remove */
    path: string
};

/** Response parameters for removeEntry operation in WebsitesApi. */
export type WebsitesApiRemoveEntryRes = void;


/** Request parameters for setEntryMetadata operation in WebsitesApi. */
export type WebsitesApiSetEntryMetadataReq = {
    /** The UUID of the website */
    websiteId: string
    /** The path of the entry */
    path: string
    /** Entry permission bits (base 10) */
    permissions: number
    /** If true, sets the permissions of all entries in the directory, recursively (top-bottom). */
    recursive?: boolean
};

/** Response parameters for setEntryMetadata operation in WebsitesApi. */
export type WebsitesApiSetEntryMetadataRes = void;


/** Request parameters for setWebsiteMetadata operation in WebsitesApi. */
export type WebsitesApiSetWebsiteMetadataReq = {
    /** The UUID of the website */
    websiteId: string
    /** Entry permission bits (base 10) */
    permissions: number
    /** If true, sets the permissions of all entries in the directory, recursively (top-bottom). */
    recursive?: boolean
};

/** Response parameters for setWebsiteMetadata operation in WebsitesApi. */
export type WebsitesApiSetWebsiteMetadataRes = void;

/**
 * WebsitesApi - service client interface
 */
export const WebsitesApi = (configuration?: Configuration, basePath: string = BASE_PATH, axios: AxiosInstance = globalAxios) => ({
    /** Checks if the entry in {path} exists -  */
    checkEntry: async (reqParams: WebsitesApiCheckEntryReq, options?: any): Promise<AxiosResponse<WebsitesApiCheckEntryRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).checkEntry(reqParams.websiteId, reqParams.path, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Creates a new file or empty directory in {path} - If the request body exists as multipart/form-data, a new file will be created with the content coming from the multipart field stream, otherwise a new directory will be created in the provided path. */
    createEntry: async (reqParams: WebsitesApiCreateEntryReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateEntryRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createEntry(reqParams.websiteId, reqParams.path, reqParams.overwrite, reqParams.uncompress, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Creates or restore the {website_id} backup - Depending on the request body, this endpoint will perform a full backup of the website home directory in {website_id} to a remote host via rsync over SSH, or restore the home directory from a remote host. */
    createOrRestoreBackup: async (reqParams: WebsitesApiCreateOrRestoreBackupReq, options?: any): Promise<AxiosResponse<WebsitesApiCreateOrRestoreBackupRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).createOrRestoreBackup(reqParams.websiteId, reqParams.backup, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Delete all the entries in {website_id} listed in the request body -  */
    deleteWebsiteEntries: async (reqParams: WebsitesApiDeleteWebsiteEntriesReq, options?: any): Promise<AxiosResponse<WebsitesApiDeleteWebsiteEntriesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).deleteWebsiteEntries(reqParams.websiteId, reqParams.inlineObject1, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the entries in {path} -  */
    getDirectoryEntries: async (reqParams: WebsitesApiGetDirectoryEntriesReq, options?: any): Promise<AxiosResponse<WebsitesApiGetDirectoryEntriesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getDirectoryEntries(reqParams.websiteId, reqParams.path, reqParams.recursive, reqParams.maxDepth, reqParams.fetchMetadata, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the {path} metadata - When querying a directory, the size field of the response will correspond to the size of the whole directory, computed by summing the size of its sub-entries following a recursive call with not depth limit. */
    getEntryMetadata: async (reqParams: WebsitesApiGetEntryMetadataReq, options?: any): Promise<AxiosResponse<WebsitesApiGetEntryMetadataRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getEntryMetadata(reqParams.websiteId, reqParams.path, reqParams.fetchSize, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the content of a file in {path} -  */
    getFileContent: async (reqParams: WebsitesApiGetFileContentReq, options?: any): Promise<AxiosResponse<WebsitesApiGetFileContentRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getFileContent(reqParams.websiteId, reqParams.path, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the entries in the {website_id} directory -  */
    getWebsiteEntries: async (reqParams: WebsitesApiGetWebsiteEntriesReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteEntriesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteEntries(reqParams.websiteId, reqParams.recursive, reqParams.maxDepth, reqParams.fetchMetadata, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Gets the {website_id} directory metadata - The size field of the response will correspond to the size of the whole directory, computed by summing the size of its sub-entries following a recursive call with not depth limit. */
    getWebsiteMetadata: async (reqParams: WebsitesApiGetWebsiteMetadataReq, options?: any): Promise<AxiosResponse<WebsitesApiGetWebsiteMetadataRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).getWebsiteMetadata(reqParams.websiteId, reqParams.fetchSize, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Maps the {path} according to the query - The purpose of this API is to apply the specified mandatory action (part of the query arguments) to the the resource specified in the URL. */
    mapEntry: async (reqParams: WebsitesApiMapEntryReq, options?: any): Promise<AxiosResponse<WebsitesApiMapEntryRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).mapEntry(reqParams.websiteId, reqParams.path, reqParams.action, reqParams.path2, reqParams.overwrite, reqParams.format, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Maps the entries in {website_id} listed in the request body according to the query - The purpose of this API is to apply the specified mandatory action (part of the query arguments) to the the resources specified in the request body. */
    mapWebsiteEntries: async (reqParams: WebsitesApiMapWebsiteEntriesReq, options?: any): Promise<AxiosResponse<WebsitesApiMapWebsiteEntriesRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).mapWebsiteEntries(reqParams.websiteId, reqParams.action, reqParams.path, reqParams.inlineObject, reqParams.overwrite, reqParams.format, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Removes the entry in {path} -  */
    removeEntry: async (reqParams: WebsitesApiRemoveEntryReq, options?: any): Promise<AxiosResponse<WebsitesApiRemoveEntryRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).removeEntry(reqParams.websiteId, reqParams.path, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Sets the {path} entry permissions -  */
    setEntryMetadata: async (reqParams: WebsitesApiSetEntryMetadataReq, options?: any): Promise<AxiosResponse<WebsitesApiSetEntryMetadataRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setEntryMetadata(reqParams.websiteId, reqParams.path, reqParams.permissions, reqParams.recursive, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
    /** Sets the {website_id} directory permissions -  */
    setWebsiteMetadata: async (reqParams: WebsitesApiSetWebsiteMetadataReq, options?: any): Promise<AxiosResponse<WebsitesApiSetWebsiteMetadataRes>> => {
        const localVarAxiosArgs = await WebsitesApiAxiosParamCreator(configuration).setWebsiteMetadata(reqParams.websiteId, reqParams.permissions, reqParams.recursive, options);
        const axiosReqArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
        return axios.request(axiosReqArgs);
    },
});

export interface WebsitesApiReq {
    checkEntry: WebsitesApiCheckEntryReq;
    createEntry: WebsitesApiCreateEntryReq;
    createOrRestoreBackup: WebsitesApiCreateOrRestoreBackupReq;
    deleteWebsiteEntries: WebsitesApiDeleteWebsiteEntriesReq;
    getDirectoryEntries: WebsitesApiGetDirectoryEntriesReq;
    getEntryMetadata: WebsitesApiGetEntryMetadataReq;
    getFileContent: WebsitesApiGetFileContentReq;
    getWebsiteEntries: WebsitesApiGetWebsiteEntriesReq;
    getWebsiteMetadata: WebsitesApiGetWebsiteMetadataReq;
    mapEntry: WebsitesApiMapEntryReq;
    mapWebsiteEntries: WebsitesApiMapWebsiteEntriesReq;
    removeEntry: WebsitesApiRemoveEntryReq;
    setEntryMetadata: WebsitesApiSetEntryMetadataReq;
    setWebsiteMetadata: WebsitesApiSetWebsiteMetadataReq;
}

export interface WebsitesApiRes {
    checkEntry: WebsitesApiCheckEntryRes;
    createEntry: WebsitesApiCreateEntryRes;
    createOrRestoreBackup: WebsitesApiCreateOrRestoreBackupRes;
    deleteWebsiteEntries: WebsitesApiDeleteWebsiteEntriesRes;
    getDirectoryEntries: WebsitesApiGetDirectoryEntriesRes;
    getEntryMetadata: WebsitesApiGetEntryMetadataRes;
    getFileContent: WebsitesApiGetFileContentRes;
    getWebsiteEntries: WebsitesApiGetWebsiteEntriesRes;
    getWebsiteMetadata: WebsitesApiGetWebsiteMetadataRes;
    mapEntry: WebsitesApiMapEntryRes;
    mapWebsiteEntries: WebsitesApiMapWebsiteEntriesRes;
    removeEntry: WebsitesApiRemoveEntryRes;
    setEntryMetadata: WebsitesApiSetEntryMetadataRes;
    setWebsiteMetadata: WebsitesApiSetWebsiteMetadataRes;
}


