import { CPLocale, Role, Setting, Website } from 'src/orchd-client';
import * as Yup from 'yup';

import { MoveServerState } from 'src/pages/migrations/_redux/reducer';
import { BrandingState, ImpersonationBrandingState } from 'src/store/branding/reducer';
import { DevtoolsState } from 'src/store/devtools/reducer';
import { RecentSearchState } from 'src/store/global-search/reducer';
import { GlobalUiState } from 'src/store/globalUi';
import { ImpersonationState } from 'src/store/impersonation';
import { NotificationsState } from 'src/store/notifications/reducer';
import { RecentSearchItem, SearchState } from 'src/store/searches';
import { SessionState } from 'src/store/session';
import { BasicInternalFilters, TableSettingsState } from 'src/store/table-settings/reducer';
import { UiState } from 'src/store/ui';
import { dateFormat, dateTimeFormat, timeFormat } from 'src/translations/constants';

export const models = {
  branding: Yup.object({
    orgName: Yup.string().required(),
    locale: Yup.mixed<CPLocale>().required(),
    parent: Yup.string().notRequired(),
    controlPanelDomain: Yup.string().notRequired(),
    phpMyAdminDomain: Yup.string().notRequired(),
    roundcubeDomain: Yup.string().notRequired(),
    logoPath: Yup.string().notRequired(),
    inverseLogoPath: Yup.string().notRequired(),
    loginImagePath: Yup.string().notRequired(),
    faviconPath: Yup.string().notRequired(),
    stagingDomain: Yup.string().notRequired(),
    settings: Yup.array().min(0).of(Yup.mixed<Setting>().required()).required(),
    nameServers: Yup.array().of(Yup.string()).notRequired(),
  }),
  globalSearch: Yup.array().min(0).of(Yup.string().required()).required(),
  impersonation: Yup.object({
    loading: Yup.boolean().required(),
    error: Yup.string().required(),
    returnUrl: Yup.string().required(),
    masterOrgName: Yup.string().required(),
    parentId: Yup.string().required(),
    startImpersonationRouterKey: Yup.string(),
    member: Yup.object({
      roles: Yup.array().min(0).of(Yup.mixed<Role>().required()).required(),
      memberId: Yup.string().required(),
      orgId: Yup.string().required(),
      orgName: Yup.string().required(),
      isMasterOrg: Yup.boolean().required(),
      siteAccessCount: Yup.number().min(0).required(),
    }),
  }),
  impersonationBranding: Yup.object({
    orgName: Yup.string().required(),
    locale: Yup.mixed<CPLocale>().required(),
    parent: Yup.string().notRequired(),
    controlPanelDomain: Yup.string().notRequired(),
    phpMyAdminDomain: Yup.string().notRequired(),
    roundcubeDomain: Yup.string().notRequired(),
    logoPath: Yup.string().notRequired(),
    inverseLogoPath: Yup.string().notRequired(),
    loginImagePath: Yup.string().notRequired(),
    faviconPath: Yup.string().notRequired(),
    stagingDomain: Yup.string().notRequired(),
    settings: Yup.array().min(0).of(Yup.mixed<Setting>().required()).required(),
    nameServers: Yup.array().of(Yup.string().required()).notRequired(),
  }),
  moveServer: Yup.object({
    selectedWebsites: Yup.object({
      items: Yup.array().min(0).of(Yup.mixed<Website>().required()).required(),
    }),
  }),
  notifications: Yup.object({
    read: Yup.array().min(0).of(Yup.string().required()).required(),
  }),
  orchdStatusToast: Yup.object({
    toastDismissed: Yup.boolean().required(),
  }),
  search: Yup.mixed<Record<string, RecentSearchItem[]>>().required(),
  session: Yup.object({
    currentMemberId: Yup.string().required(),
  }),
  ui: Yup.object({
    serverGroupsCollapsed: Yup.mixed<Record<string, boolean>>().required(),
    showOnboardingCard: Yup.boolean().required(),
    tempLocale: Yup.mixed<CPLocale>().strict().notRequired(),
    dateTimeConfig: Yup.object({
      dateTime: Yup.mixed<dateTimeFormat>().required(),
      date: Yup.mixed<dateFormat>().required(),
      time: Yup.mixed<timeFormat>().required(),
    }).required(),
    tableItemsPerPage: Yup.mixed<Record<string, number>>().required(),
    hydrated: Yup.boolean().required(),
  }),
  globalUi: Yup.object({
    darkMode: Yup.boolean(),
  }),
  devtools: Yup.object({
    isDevtoolsOpen: Yup.boolean().required(),
    isTranslationsHighlighted: Yup.boolean().required(),
    isReactQueryDevtoolsActive: Yup.boolean().required(),
  }),
  tableSettings: Yup.object({
    filters: Yup.mixed<Record<string, BasicInternalFilters>>().required(),
  }),
};

// Type checking for models against reducer types. Note that this only works for type changes and additions to the
// reducer types. Fields removed from the reducers will not throw an error here.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assert<T extends never>() {}

assert<Exclude<Yup.InferType<typeof models.branding>, BrandingState>>();
assert<Exclude<Yup.InferType<typeof models.globalSearch>, RecentSearchState>>();
assert<Exclude<Yup.InferType<typeof models.impersonation>, ImpersonationState>>();
assert<Exclude<Yup.InferType<typeof models.impersonationBranding>, ImpersonationBrandingState>>();
assert<Exclude<Yup.InferType<typeof models.moveServer>, MoveServerState>>();
assert<Exclude<Yup.InferType<typeof models.notifications>, NotificationsState>>();
assert<Exclude<Yup.InferType<typeof models.orchdStatusToast>, { toastDismissed: boolean }>>();
assert<Exclude<Yup.InferType<typeof models.search>, SearchState>>();
assert<Exclude<Yup.InferType<typeof models.session>, SessionState>>();
assert<Exclude<Yup.InferType<typeof models.ui>, UiState>>();
assert<Exclude<Yup.InferType<typeof models.globalUi>, GlobalUiState>>();
assert<Exclude<Yup.InferType<typeof models.devtools>, DevtoolsState>>();
assert<Exclude<Yup.InferType<typeof models.tableSettings>, TableSettingsState>>();
