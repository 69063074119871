import { useCallback, useEffect, useRef } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as Dialog from '@radix-ui/react-dialog';
import isFunction from 'lodash/isFunction';

import { useHideModal } from 'src/utils/modal/useModal';

import { style } from './Modal.style';

export interface Props {
  children: React.ReactNode | ((data: { hide: () => void }) => React.ReactNode);
  onCancel?: () => void;
  disableClickAway?: boolean;
  alpha?: number;
  width?: number;
}

export const Modal = ({ children, onCancel, disableClickAway, alpha = 0.65, width }: Props) => {
  const previousElement = useRef<any>(null);
  const hide = useHideModal();
  const cancel = useCallback(() => {
    hide();
    if (onCancel) {
      onCancel();
    }
  }, [hide, onCancel]);

  useEffect(() => {
    // keeps track of the previously focused element
    previousElement.current = document.activeElement;
  }, []);

  return (
    <Dialog.Root open>
      <Dialog.Portal>
        <Dialog.Overlay css={style.overlay} onClick={cancel} />
        <Dialog.Content
          // required due to incompatibility with react-select focus logic
          tabIndex={undefined}
          onCloseAutoFocus={() => {
            previousElement.current?.focus();
          }}
          css={style.content({ width })}
          onEscapeKeyDown={cancel}
        >
          {isFunction(children) ? children({ hide }) : children}

          <div id="modal-radix-root" />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
