import { createActionCreator } from 'deox';

import { createRequestStateActions } from '../helpers';

const namespace = '@session';

const appLoaded = createActionCreator(`appLoaded(${namespace})`);

const logout = createActionCreator(`logout(${namespace})`);

const loggedOut = createActionCreator(`loggedOut(${namespace})`);

const findActiveMember = createRequestStateActions(`findActiveMember(${namespace})`);

const setCurrentMemberId = createActionCreator(
  `setCurrentMemberId(${namespace})`,
  (resolve) => (payload: { id: string }) => resolve(payload)
);

export const sessionActions = {
  appLoaded,
  logout,
  loggedOut,
  findActiveMember,
  setCurrentMemberId,
};
