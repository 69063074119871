import { WebsiteAppKind } from 'src/orchd-client';

import { AppState } from 'src/store';
import { sortInstallableAppByVersionDesc } from 'src/utils/helpers';

const selectInstallableWebsiteAppsState = (state: AppState) => state.createWebsite.installableWebsiteApps;
export const selectInstallableWebsiteApps = (state: AppState) => selectInstallableWebsiteAppsState(state).data.items;
export const selectInstallableWebsiteAppsLoading = (state: AppState) =>
  selectInstallableWebsiteAppsState(state).loading;
export const selectInstallableWebsiteAppsError = (state: AppState) => selectInstallableWebsiteAppsState(state).error;

export const selectInstallableWebsiteAppsByVersionDesc = (state: AppState) =>
  sortInstallableAppByVersionDesc(selectInstallableWebsiteApps(state));

export const selectLatestAvailableWordpressVersion = (state: AppState) =>
  selectInstallableWebsiteAppsByVersionDesc(state).find((appItem) => appItem.app === WebsiteAppKind.wordpress);

export const createdWebsiteId = (state: AppState) => state.createWebsite.websiteCreated.websiteId;
