import { css } from '@emotion/core';

import { breakpoints } from 'src/design-system/constants';
import { Theme } from 'src/design-system/theme';

const item =
  ({
    expanded,
    trailing,
    isChild,
    isTouch,
  }: {
    expanded: boolean;
    trailing: boolean;
    isChild: boolean;
    isTouch: boolean;
  }) =>
  (theme: Theme) =>
    css`
      width: 100%;
      height: 48px;
      display: flex;
      flex-shrink: 0;
      position: relative;
      justify-content: ${expanded ? 'flex-start' : 'center'};
      align-items: center;
      padding: 0 ${theme.paddings.lg}px;

      ${!isTouch && `&:hover,`}
      &.selected {
        background: ${theme.colors.dark};
        .ui-text-hoverable {
          color: ${theme.fontColors.light};
        }
        .ui-svg-hoverable {
          fill: ${theme.fontColors.light};
        }
      }
      ${isChild &&
      expanded &&
      `
        padding-left: ${theme.paddings['2xl']}px;

      `}
      ${trailing &&
      `
        @media (min-width: ${breakpoints.sm}px) {
          margin-top: auto;
        }
      `}
    `;

export const style = { item };
