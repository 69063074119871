import { Fragment, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { WebsiteKind, WebsiteStatus } from 'src/orchd-client';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetWebsiteAppsQuery } from 'src/api_services/apps/query';
import { useGetWebsiteMySQLDBs, useGetWebsiteMySQLDBUsers } from 'src/api_services/mysql/query';
import { useCloneWebsiteMutation, useGetWebsitesQuery } from 'src/api_services/websites/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Card, CardBody, CardFooter } from 'src/components/Card';
import { Checkbox } from 'src/components/Checkbox';
import Divider from 'src/components/Divider';
import { Icon } from 'src/components/Icon';
import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';
import { SelectField } from 'src/components/SelectNew';
import { H3, Text } from 'src/components/Text';
import { TextInput } from 'src/components/TextInput';
import { Form } from 'src/containers/Form';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { getSelectOptionValue } from 'src/store/helpers';
import { getErrorInfo } from 'src/utils/errors';
import { useHideModal } from 'src/utils/modal/useModal';
import { useToast } from 'src/utils/toast/useToast';

import { getSchema, PushLiveToExistingFields } from './schema';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  orgId: string;
  websiteId: string;
  isCloning: boolean;
}

const stateToProps = (state: AppState) => ({
  isMobile: viewportSelectors.isMobile(state),
});

export const _PushLiveToExistingModal = ({ orgId, websiteId, isCloning, isMobile }: Props) => {
  const toast = useToast();
  const hide = useHideModal();
  const [fileList, setFileList] = useState<string[]>([]);
  const [fileToAdd, setFileToAdd] = useState('');
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  const { mutateAsync: cloneWebsite } = useCloneWebsiteMutation();

  const { data: { items: websites = [] } = {} } = useGetWebsitesQuery({
    orgId,
    kind: WebsiteKind.normal,
    status: WebsiteStatus.active,
  });

  const { data: apps = [], isLoading: isAppsLoading } = useGetWebsiteAppsQuery({ orgId, websiteId });
  const { data: { items: databases = [] } = {}, isLoading: isDBsLoading } = useGetWebsiteMySQLDBs({ orgId, websiteId });

  const { data: { items: databaseUsers = [] } = {}, isLoading: isDBUsersLoading } = useGetWebsiteMySQLDBUsers({
    orgId,
    websiteId,
  });

  useEffect(() => {
    const files = apps.reduce<string[]>((carry, item) => {
      return [...carry, `${item.path}/wp-config.php`, `${item.path}/wp-content`];
    }, []);

    if (files.every((file) => fileList.includes(file))) {
      return;
    }

    setFileList(files);
  }, [apps]); // eslint-disable-line react-hooks/exhaustive-deps

  const websitesOptions = useMemo(() => {
    return websites
      .filter((website) => website.id !== websiteId)
      .map((website) => ({
        label: website.domain.domain,
        value: website.id,
      }));
  }, [websites, websiteId]);

  const handleSubmit = async (values: PushLiveToExistingFields) => {
    try {
      const destinationWebsiteId = getSelectOptionValue(values.website);

      const {
        data: { id: cloneId },
      } = await cloneWebsite({
        orgId,
        websiteCloneRequest: {
          sourceWebsiteId: websiteId,
          destWebsiteId: destinationWebsiteId,
          excludePaths: values.files,
          includeDatabases: values.databases,
          includeDatabaseUsers: values.databaseUsers,
          deleteFilesFromDestination: values.deleteFilesFromDestination,
          syncPhpVersion: values.syncPhpVersion,
          runWpSearchReplace: values.runWpSearchReplace,
        },
      });

      isCloning ? push(`/websites/clone/${cloneId}`) : push(`/websites/push-live/${cloneId}`);

      hide();
    } catch (e) {
      const { message } = getErrorInfo(e);

      toast.error(
        isCloning
          ? formatMessage(
              { id: 'websites.push_live_to_existing.clone_error', defaultMessage: 'Failed to clone website: {error}' },
              { error: message }
            )
          : formatMessage(
              { id: 'websites.push_live_to_existing.error', defaultMessage: 'Failed to push website live: {error}' },
              { error: message }
            )
      );
    }
  };

  if (isAppsLoading || isDBsLoading || isDBUsersLoading) {
    return (
      <Modal width={1000}>
        <Card>
          <Loading height="400px" />
        </Card>
      </Modal>
    );
  }

  return (
    <Modal width={1000}>
      <Form<PushLiveToExistingFields>
        initialValues={{
          website: [],
          files: apps.reduce<string[]>((carry, item) => {
            return [...carry, `${item.path}/wp-config.php`, `${item.path}/wp-content`];
          }, []),
          databases: databases.map((db) => db.name),
          databaseUsers: databaseUsers.map((user) => user.username),
          deleteFilesFromDestination: true,
          syncPhpVersion: true,
          runWpSearchReplace: true,
        }}
        onSubmit={handleSubmit}
        validationSchema={getSchema(formatMessage)}
        validateOnMount
      >
        {(form) => (
          <Card marginBottom="lg">
            <CardBody css={{ maxHeight: 'calc(100vh - 96px)', overflowY: 'auto' }}>
              <Box j="center" pt="lg" mb="2xl">
                <H3>
                  {isCloning ? (
                    <FormattedMessage
                      id="websites.push_live_to_existing.clone_title"
                      defaultMessage="Clone to existing site"
                    />
                  ) : (
                    <FormattedMessage
                      id="websites.push_live_to_existing.title"
                      defaultMessage="Push staging to existing site"
                    />
                  )}
                </H3>
              </Box>

              <Box px="lg" pb="lg">
                <Box w={{ xs: 12, md: 5.5 }} d="column" gap="lg">
                  <Text size="sm">
                    <FormattedMessage
                      id="websites.push_live_to_existing.website_help"
                      defaultMessage="This process will copy the contents (files and databases) of the source website to the target website, overwriting any data which already exists."
                    />
                  </Text>

                  <SelectField
                    name="website"
                    label={formatMessage({
                      id: 'websites.push_live_to_existing.destination',
                      defaultMessage: 'Destination website',
                    })}
                    placeholder={formatMessage(messages.select_a_website)}
                    value={form.values.website}
                    options={websitesOptions}
                    onChange={({ name, value }) => form.setFieldValue(name, value)}
                    err={form.errors.website}
                    touched={form.touched.website}
                    requiredIndicator
                    searchable
                  />

                  {websites.some(
                    ({ id, canUse }) => id === getSelectOptionValue(form.values.website) && canUse?.backup
                  ) && (
                    <Box a="center" wrap="nowrap" gap="sm">
                      <Icon name="shield tick" />

                      <Text size="sm">
                        <FormattedMessage
                          id="websites.push_live_to_existing.backed_up"
                          defaultMessage="<b>A full backup of your destination website will be taken</b>, so you can revert this process if needed"
                          values={{ b: (chunks) => <b>{chunks}</b> }}
                        />
                      </Text>
                    </Box>
                  )}
                </Box>

                <Box w={{ xs: 12, md: 1 }} alignSelf="stretch" j="center">
                  <Divider type={isMobile ? 'horizontal' : 'vertical'} marginTop="2xl" marginBottom="2xl" />
                </Box>

                <Box
                  w={{ xs: 12, md: 5.5 }}
                  d="column"
                  gap="lg"
                  wrap="nowrap"
                  css={{ maxHeight: 800, overflowX: 'hidden', overflowY: 'auto' }}
                >
                  <Text size="sm">
                    <FormattedMessage
                      id="websites.push_live_to_existing.files_help"
                      defaultMessage="The files selected below will not be copied.  For instance, you may wish to exclude media content or specific config files."
                    />
                  </Text>

                  <Text size="xs" transform="uppercase" color="grey">
                    <FormattedMessage
                      id="websites.push_live_to_existing.exclude_files"
                      defaultMessage="Exclude files"
                    />
                  </Text>

                  {fileList.length > 0 && (
                    <Box d="column" gap="xs">
                      {fileList.map((file) => (
                        <Checkbox
                          key={file}
                          name={file}
                          label={file}
                          size="sm"
                          fontSize="sm"
                          checked={form.values.files.includes(file)}
                          onChange={() =>
                            form.setFieldValue(
                              'files',
                              form.values.files.includes(file)
                                ? form.values.files.filter((f) => f !== file)
                                : [...form.values.files, file]
                            )
                          }
                        />
                      ))}
                    </Box>
                  )}

                  <Box d="column" gap="xs" a="flex-end">
                    <TextInput
                      name="addFile"
                      inputWidth="100%"
                      placeholder={formatMessage({
                        id: 'websites.push_live_to_existing.add_file',
                        defaultMessage: 'Add custom file',
                      })}
                      onChange={(e) => setFileToAdd(e.target.value)}
                      value={fileToAdd}
                      onKeyDown={(e) => {
                        if (e.key !== 'Enter') {
                          return;
                        }

                        e.preventDefault();
                        e.stopPropagation();

                        if (fileToAdd && !fileList.includes(fileToAdd)) {
                          setFileList([...fileList, fileToAdd]);
                          setFileToAdd('');
                          form.setFieldValue('files', [...form.values.files, fileToAdd]);
                        }
                      }}
                    />

                    <Button
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        if (fileToAdd && !fileList.includes(fileToAdd)) {
                          setFileList([...fileList, fileToAdd]);
                          setFileToAdd('');
                          form.setFieldValue('files', [...form.values.files, fileToAdd]);
                        }
                      }}
                    >
                      <FormattedMessage {...messages.add} />
                    </Button>
                  </Box>

                  {databases.length > 0 && (
                    <Fragment>
                      <Text size="xs" transform="uppercase" color="grey">
                        <FormattedMessage {...messages.databases} />
                      </Text>

                      <Box d="column" gap="xs">
                        {databases.length > 1 && (
                          <Checkbox
                            name="databases"
                            label={formatMessage({
                              id: 'websites.push_live_to_existing.all_databases',
                              defaultMessage: 'All databases',
                            })}
                            size="sm"
                            fontSize="sm"
                            checked={databases.length === form.values.databases.length}
                            onChange={({ name, checked }) =>
                              form.setFieldValue(name, checked ? databases.map((db) => db.name) : [])
                            }
                          />
                        )}

                        {(databases.length > form.values.databases.length || databases.length === 1) && (
                          <Box d="column" gap="xs" ml={databases.length === 1 ? '0' : 'lg'}>
                            {databases.map((db) => (
                              <Checkbox
                                key={db.name}
                                name={db.name}
                                label={db.name}
                                size="sm"
                                fontSize="sm"
                                checked={form.values.databases.includes(db.name)}
                                onChange={() =>
                                  form.setFieldValue(
                                    'databases',
                                    form.values.databases.includes(db.name)
                                      ? form.values.databases.filter((name) => name !== db.name)
                                      : [...form.values.databases, db.name]
                                  )
                                }
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Fragment>
                  )}

                  {databaseUsers.length > 0 && (
                    <Fragment>
                      <Text size="xs" transform="uppercase" color="grey">
                        <FormattedMessage {...messages.database_users} />
                      </Text>

                      <Box d="column" gap="xs">
                        {databaseUsers.length > 1 && (
                          <Checkbox
                            name="databaseUsers"
                            label={formatMessage({
                              id: 'websites.push_live_to_existing.all_database_users',
                              defaultMessage: 'All database users',
                            })}
                            size="sm"
                            fontSize="sm"
                            checked={databaseUsers.length === form.values.databaseUsers.length}
                            onChange={({ name, checked }) =>
                              form.setFieldValue(name, checked ? databaseUsers.map((user) => user.username) : [])
                            }
                          />
                        )}

                        {(databaseUsers.length > form.values.databaseUsers.length || databaseUsers.length === 1) && (
                          <Box d="column" gap="xs" ml={databaseUsers.length === 1 ? '0' : 'lg'}>
                            {databaseUsers.map((user) => (
                              <Checkbox
                                key={user.username}
                                name={user.username}
                                label={user.username}
                                size="sm"
                                fontSize="sm"
                                checked={form.values.databaseUsers.includes(user.username)}
                                onChange={() =>
                                  form.setFieldValue(
                                    'databaseUsers',
                                    form.values.databaseUsers.includes(user.username)
                                      ? form.values.databaseUsers.filter((name) => name !== user.username)
                                      : [...form.values.databaseUsers, user.username]
                                  )
                                }
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Fragment>
                  )}

                  <Text size="xs" transform="uppercase" color="grey">
                    <FormattedMessage id="websites.push_live_to_existing.options" defaultMessage="Other options" />
                  </Text>

                  <Box gap="xs" d="column">
                    <Checkbox
                      name="deleteFilesFromDestination"
                      label={formatMessage({
                        id: 'websites.push_live_to_existing.delete_files',
                        defaultMessage: 'Delete files from the destination website if they do not exist on the source',
                      })}
                      size="sm"
                      fontSize="sm"
                      checked={form.values.deleteFilesFromDestination}
                      onChange={({ name, checked }) => form.setFieldValue(name, checked)}
                    />

                    <Checkbox
                      name="syncPhpVersion"
                      label={formatMessage({
                        id: 'websites.push_live_to_existing.sync_php_version',
                        defaultMessage: 'Sync PHP version',
                      })}
                      size="sm"
                      fontSize="sm"
                      checked={form.values.syncPhpVersion}
                      onChange={({ name, checked }) => form.setFieldValue(name, checked)}
                    />

                    <Checkbox
                      name="runWpSearchReplace"
                      label={formatMessage({
                        id: 'run_wp_search_replace',
                        defaultMessage: 'Perform a WordPress search and replace',
                      })}
                      size="sm"
                      fontSize="sm"
                      checked={form.values.runWpSearchReplace}
                      onChange={({ name, checked }) => form.setFieldValue(name, checked)}
                      infoTooltip={formatMessage({
                        id: 'run_wp_search_replace.tooltip',
                        defaultMessage:
                          'Using wp-cli this will search every table in the database replacing the old domain with the new domain.',
                      })}
                    />
                  </Box>
                </Box>
              </Box>
            </CardBody>

            <CardFooter>
              <Box a="center" px="lg" j="flex-end" c={{ w: 'auto', ml: '2xl' }}>
                <Button variant="tertiary" size="small" onClick={hide}>
                  <FormattedMessage {...messages.cancel} />
                </Button>

                <Button type="submit" size="small" faded={!form.isValid} loading={form.isSubmitting}>
                  {isCloning ? <FormattedMessage {...messages.clone} /> : <FormattedMessage {...messages.push_live} />}
                </Button>
              </Box>
            </CardFooter>
          </Card>
        )}
      </Form>
    </Modal>
  );
};

export const PushLiveToExistingModal = connect(stateToProps)(_PushLiveToExistingModal);
