/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { FontColors, Margins, Paddings, Responsive, Sizes } from 'src/design-system/style-types';
import { Colors } from 'src/design-system/theme';

import { Icon } from '../Icon';
import { IconName } from '../Icon/Icon.types';
import { style } from './IconButton.style';

interface RequiredProps {
  icon: IconName;
}

interface DefaultProps {
  innerRef?: React.Ref<HTMLButtonElement>;
  marginBottom?: Responsive<Margins>;
  marginLeft?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  padding?: Responsive<Paddings>;
  className?: string;
  variant: IconButtonVariants;
  size: Sizes;
  disabled: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  negativeMargin?: boolean;
  iconColor?: FontColors;
  hoverBackgroundColor?: Colors;
  type: 'button' | 'reset' | 'submit';
  showNotificationBadge?: boolean;
  title?: string;
  dataQa?: string;
  ariaLabel?: string;
}

export type Props = RequiredProps & DefaultProps;

export type IconButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'light'
  | 'red'
  | 'delete'
  | 'delete-alt'
  | 'dark';

const defaultProps: DefaultProps = {
  variant: 'primary',
  size: 'lg',
  disabled: false,
  onClick: undefined,
  negativeMargin: false,
  showNotificationBadge: false,
  type: 'button',
  title: '',
};

export const IconButton = (props: Props) => {
  const { ariaLabel, dataQa, className, icon, onClick, innerRef, disabled, size, type, variant, iconColor, title } =
    props;

  const getIconColor = (): FontColors => {
    if (disabled) return 'grey';
    if (iconColor) return iconColor;

    switch (variant) {
      case 'primary':
      case 'light':
        return 'light';
      case 'red':
        return 'error';
      case 'dark':
        return 'grey';
      default:
        return 'dark';
    }
  };

  return (
    <button
      aria-label={ariaLabel}
      title={title}
      data-qa={dataQa ?? 'iconButton'}
      ref={innerRef}
      className={className}
      css={style.container(props)}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick(e);
        }
      }}
      disabled={disabled}
      type={type}
    >
      <Icon data-qa="icon" hasHoverClass name={icon} size={size} color={getIconColor()} />
    </button>
  );
};

IconButton.defaultProps = defaultProps;
