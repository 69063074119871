import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { emailsApi } from 'src/api_services/emails/service';
import { extendedOrgsApi } from 'src/api_services/orgs/service';

import { createRequestStateSaga } from '../helpers';
import { emailsActions } from './actions';

const [getEmailsSaga] = createRequestStateSaga(emailsActions.getEmails, emailsApi.getEmails);
const [getWebsiteEmailSaga] = createRequestStateSaga(emailsActions.getWebsiteEmail, emailsApi.getWebsiteEmail);
const [getWebsiteEmailsSaga] = createRequestStateSaga(emailsActions.getWebsiteEmails, emailsApi.getWebsiteEmails);
const [updateWebsiteEmailSaga] = createRequestStateSaga(emailsActions.updateWebsiteEmail, emailsApi.updateWebsiteEmail);
const [deleteWebsiteEmailSaga] = createRequestStateSaga(emailsActions.deleteWebsiteEmail, emailsApi.deleteWebsiteEmail);
const [getOrgEmailsSaga] = createRequestStateSaga(emailsActions.getOrgEmails, extendedOrgsApi.getOrgEmails);

export function* emailsSaga() {
  yield takeEvery(getType(emailsActions.getEmails.request), getEmailsSaga);
  yield takeEvery(getType(emailsActions.getWebsiteEmail.request), getWebsiteEmailSaga);
  yield takeEvery(getType(emailsActions.getWebsiteEmails.request), getWebsiteEmailsSaga);
  yield takeEvery(getType(emailsActions.updateWebsiteEmail.request), updateWebsiteEmailSaga);
  yield takeEvery(getType(emailsActions.deleteWebsiteEmail.request), deleteWebsiteEmailSaga);
  yield takeEvery(getType(emailsActions.getOrgEmails.request), getOrgEmailsSaga);
}
