import React from 'react';
import { useAtom } from 'jotai';

import { Box } from 'src/components/Box';
import { Checkbox } from 'src/components/Checkbox';
import { Text } from 'src/components/Text';

import { demoModeAtom } from './atoms';

export const DemoModeDevtools = () => {
  const [demoMode, setDemoMode] = useAtom(demoModeAtom);

  return (
    <Box p="lg">
      <Text size="sm" weight="bold" marginBottom="sm">
        Demo mode devtools
      </Text>

      <Checkbox
        name="toggleDemoMode"
        onChange={({ checked }) => setDemoMode(checked)}
        checked={demoMode}
        size="md"
        label={<Text size="sm">Toggle demo mode</Text>}
        marginBottom="0"
      />
    </Box>
  );
};
