import { getType } from 'deox';
import { fork, takeEvery, takeLatest } from 'redux-saga/effects';

import { extendedOrgsApi } from 'src/api_services/orgs/service';

import { createRequestStateSaga } from '../helpers';
import { orgsActions } from './actions';

const [getOrgSaga, refreshGetOrgSaga] = createRequestStateSaga(orgsActions.getOrg, extendedOrgsApi.getOrg);

const [getOrgMembersSaga, refreshGetOrgMembersSaga] = createRequestStateSaga(
  orgsActions.getOrgMembers,
  extendedOrgsApi.getMembers
);

const [updateOrgSaga] = createRequestStateSaga(orgsActions.updateOrg, extendedOrgsApi.updateOrg);

function* refreshOrgsSaga() {
  yield fork(refreshGetOrgSaga);
  yield fork(refreshGetOrgMembersSaga);
}

export function* orgsSaga() {
  yield takeLatest(getType(orgsActions.getOrg.request), getOrgSaga);
  yield takeLatest(getType(orgsActions.getOrgMembers.request), getOrgMembersSaga);
  yield takeEvery(getType(orgsActions.updateOrg.request), updateOrgSaga);
  yield takeLatest(getType(orgsActions.updateOrg.success), refreshOrgsSaga);
}
