import { AppState } from 'src/store';
import { brandingSelectors } from 'src/store/branding/selectors';

export const globalUiSelectors = {
  darkMode: (state: AppState) => {
    return state.globalUi.darkMode === undefined
      ? brandingSelectors.selectDefaultDarkMode(state)
      : state.globalUi.darkMode;
  },
};
