import { UpdateLoginAuthMethodEnum } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { loginsApi } from './service';

export const useGetOtpUrlQuery = () =>
  useQuery({
    queryKey: queryKeys.otpUrl(),
    queryFn: () =>
      loginsApi.updateLoginInfo({
        updateLogin: { authMethod: UpdateLoginAuthMethodEnum.otp },
      }),
    select: (data) => data.data,
    staleTime: 60000,
  });

export const useGetUiPreferencesQuery = () =>
  useQuery({
    queryKey: queryKeys.uiPreferences(),
    queryFn: () => loginsApi.getUiPreferences({}),
    select: (data) => data.data,
    refetchOnMount: true,
    retry: false,
  });

export const useUpdateLoginInfoMutation = () => useMutation({ mutationFn: loginsApi.updateLoginInfo });

export const useVerify2FAMutation = () => useMutation({ mutationFn: loginsApi.verify2FA });

export const useCreateSessionMutation = () => useMutation({ mutationFn: loginsApi.createSession });

export const useSetCustomerLoginPasswordMutation = () =>
  useMutation({ mutationFn: loginsApi.setCustomerLoginPassword });

export const useSetUiPreferencesMutation = () => useMutation({ mutationFn: loginsApi.setUiPreferences });

export const useUpdateUiPreferencesMutation = () => useMutation({ mutationFn: loginsApi.updateUiPreferences });
