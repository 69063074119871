import React, { memo } from 'react';
import { LoginMembership } from 'src/orchd-client';
import { useAtomValue } from 'jotai';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BackHeading } from 'src/components/BackHeading';
import { Box } from 'src/components/Box';
import { Container } from 'src/components/Container';
import { DarkModeToggle } from 'src/components/DarkModeToggle/DarkModeToggle';
import { DashboardNotifications } from 'src/components/DashboardNotifications/DashboardNotifications';
import { DashboardProfile } from 'src/components/DashboardProfile';
import { ExternalLink } from 'src/components/ExternalLink';
import { Icon } from 'src/components/Icon';
import { IconButton } from 'src/components/IconButton';
import { Text } from 'src/components/Text';
import { Breadcrumbs } from 'src/containers/Breadcrumbs';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { useLayoutSettings } from 'src/hooks/useLayoutSettings/useLayoutSettings';
import { messages } from 'src/messages';
import { lastVisitedWebsiteAtom } from 'src/pages/websites/website/dashboard/AlternativeWebsiteDashboard';
import { AppState } from 'src/store';
import { brandingSelectors } from 'src/store/branding/selectors';
import { globalUiActions } from 'src/store/globalUi';
import { globalUiSelectors } from 'src/store/globalUi/selectors';
import { zIndex } from 'src/utils/zIndex';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

interface Props extends ActionProps, StateProps {
  handleToggle: () => void;
  logout: () => void;
  member: LoginMembership;
  loginMemberships: LoginMembership[];
  isImpersonating: boolean
}

const _DashboardHeader = ({
  supportUrl,
  loginMemberships,
  logout,
  member,
  handleToggle,
  darkMode,
  setDarkMode,
  isMobile,
  isImpersonating
}: Props) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const lastVisitedWebsite = useAtomValue(lastVisitedWebsiteAtom);
  
  const { hideLeftHandMenu } = useLayoutSettings();
  const isOnWebsiteDashboard = location.pathname.startsWith('/websites');

  return (
    <Box h={{ xs: '64px', sm: 'auto' }} zIndex={{ xs: zIndex.DashboardHeaderXS, sm: zIndex.DashboardHeaderSM }}>
      <Container
        d="row"
        wrap="nowrap"
        py={{ xs: 'sm', sm: 'xl' }}
        bg={{ xs: 'grey95', sm: 'transparent' }}
        h={{ xs: '64px', sm: 'auto' }}
        a="center"
        position={{ xs: 'fixed', sm: 'static' }}
        zIndex={zIndex.DashboardHeaderContainer}
      >
        <Breadcrumbs hide={{ xs: true, sm: hideLeftHandMenu }} />

        <Box role="button" w="auto" mr="auto" pr="lg" hide={{ xs: hideLeftHandMenu, sm: true }} onClick={handleToggle}>
          <Icon ariaLabel={formatMessage(messages.toggle_navigation_menu)} name="hamburger alt" color="light" />
        </Box>

        {lastVisitedWebsite && hideLeftHandMenu && !isOnWebsiteDashboard && (
          <BackHeading to={`/websites/${lastVisitedWebsite}`} marginBottom="0" iconColor={isMobile ? 'light' : 'dark'}>
            <Text whiteSpace="nowrap" color={isMobile ? 'light' : 'dark'}>
              <FormattedMessage {...messages.website_dashboard} />
            </Text>
          </BackHeading>
        )}

        <Box gap="lg" wrap="nowrap" a="center" j="flex-end">
          {supportUrl && hideLeftHandMenu && (
            <ExternalLink to={supportUrl}>
              <IconButton
                icon="question"
                iconColor={isMobile ? 'light' : 'dark'}
                hoverBackgroundColor={isMobile ? 'grey75' : undefined}
                variant="tertiary"
              />
            </ExternalLink>
          )}

          <DashboardNotifications />

          <Box dataDemoEnabled w="auto">
            <DarkModeToggle
              iconColor={isMobile ? 'light' : 'dark'}
              hoverBackgroundColor={isMobile ? 'grey75' : undefined}
              darkMode={darkMode}
              toggleDarkMode={() => setDarkMode({ darkMode: !darkMode })}
            />
          </Box>
          {
          !isImpersonating && (<DashboardProfile
            iconColor={isMobile ? 'light' : 'dark'}
            hoverBackgroundColor={isMobile ? 'grey75' : undefined}
            loginMemberships={loginMemberships}
            member={member}
            logout={logout}
          />)
          }     
        </Box>
      </Container>
    </Box>
  );
};

const stateToProps = (state: AppState) => ({
  darkMode: globalUiSelectors.darkMode(state),
  isMobile: viewportSelectors.isMobile(state),
  supportUrl: brandingSelectors.selectSupportUrl(state),
});

export const dispatchToProps = {
  setDarkMode: globalUiActions.setDarkMode,
};

export const DashboardHeader = connect(stateToProps, dispatchToProps)(memo(_DashboardHeader));
