/** @jsxRuntime classic */
/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx } from '@emotion/core';

import Divider from 'src/components/Divider';
import { Text } from 'src/components/Text';
import { Colors, Paddings, Responsive } from 'src/design-system/style-types';

import { DefaultProps as TextProps } from '../../Text/Text';
import { style } from './CardHeader.style';

export interface Props {
  children?: ReactNode;
  className?: string;
  padding?: Responsive<Paddings>;
  px?: Responsive<Paddings>;
  py?: Responsive<Paddings>;
  backgroundColor?: Responsive<Colors>;
  divider?: boolean;
  title?: string;
  titleProps?: Partial<TextProps>;
  onClick?: () => void;
}

export const CardHeader = (props: Props) => {
  const { children, className, divider = true, title, titleProps, onClick } = props;
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return (
    <div className={className} onClick={onClick}>
      <div css={style.header(props)}>{children || <Text {...titleProps}>{title}</Text>}</div>
      {divider && <Divider />}
    </div>
  );
};
