import { ReactElement, ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { Colors, FontColors, Margins, Paddings, Responsive } from 'src/design-system/style-types';

import { style } from './Pill.style';

export type Positions = 'left' | 'right' | 'floating';

export interface Props {
  children: ReactNode;
  className?: string;
  background?: Colors | '';
  fontColor?: Responsive<FontColors>;
  paddingLeft?: undefined | Responsive<Paddings>;
  paddingRight?: undefined | Responsive<Paddings>;
  paddingX?: undefined | Responsive<Paddings>;
  borderColor?: undefined | Responsive<Colors>;
  iconPosition?: Positions;
  marginTop?: Responsive<Margins>;
  marginBottom?: Responsive<Margins>;
  marginLeft?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  textTransform?: 'uppercase' | undefined;
  removable?: boolean;
  onRemove?: () => void;
  highlightText?: string;
  icon?: ReactElement;
  onClick?: (e: React.MouseEvent) => void;
  ellipsis?: boolean;
  title?: string;
  shrink?: Responsive<number>;
}

export const tagColorMap: Record<string, Record<string, Colors | FontColors | string>> = {
  disabled: {
    background: 'grey5',
    fontColor: 'disabled',
    borderColor: 'grey20',
  },
  active: {
    background: 'greenBright',
    fontColor: 'light',
  },
};

const Pill = (props: Props) => {
  const {
    children,
    fontColor = 'dark',
    highlightText = '',
    icon,
    iconPosition = 'left',
    onClick,
    onRemove = () => {},
    removable = false,
    textTransform = undefined,
    ellipsis = true,
    title,
    shrink,
  } = props;

  return (
    <Box
      role="status"
      w="auto"
      css={style.container(props)}
      d="row"
      wrap="nowrap"
      a="center"
      j="center"
      g="xs"
      onClick={onClick}
      minW="0"
      title={title}
      shrink={shrink}
    >
      {iconPosition === 'left' && icon}
      <Text
        ellipsis={ellipsis}
        size="xs"
        color={fontColor}
        transform={textTransform}
        tag="span"
        highlightText={highlightText}
      >
        {children}
      </Text>
      {iconPosition === 'right' && icon}
      {iconPosition === 'floating' && <div css={style.floatingIcon}>{icon}</div>}
      {removable && <Icon name="close" color={fontColor} onClick={onRemove} data-qa="remove" />}
    </Box>
  );
};

export default Pill;
