import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { globalSearchActions } from './actions';

export type RecentSearchState = string[];

export const initialState: RecentSearchState = [];

const recentSearchesReducer = createReducer(initialState, (handleAction) => [
  handleAction(globalSearchActions.pushRecentSearch, (state, { payload: { term } }) => {
    return [term]
      .concat(state)
      .reduce<string[]>((c, i) => (c.includes(i) ? c : c.concat(i)), [])
      .slice(0, 5);
  }),
  handleAction(globalSearchActions.removeRecentSearch, (state, { payload: { term } }) => {
    return state.filter((item) => item !== term);
  }),
]);

export const globalSearchReducer = combineReducers({
  recentSearches: recentSearchesReducer,
});
