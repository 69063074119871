/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';

import { Icon } from 'src/components/Icon';
import { IconButton } from 'src/components/IconButton';
import { Text } from 'src/components/Text';

import { style } from './LinkContent.style';

export interface Props {
  expanded: boolean;
  icon: any;
  text: string;
  hasChildren?: boolean;
  collapsed?: boolean;
  onToggle?: () => void;
  isChild?: boolean;
}

export const LinkContent = ({
  expanded,
  icon,
  text,
  hasChildren = false,
  collapsed,
  onToggle,
  isChild = false,
}: Props) => (
  <Fragment>
    <div css={style.line()} />

    {(!isChild || !expanded) && <Icon color="grey" hasHoverClass name={icon} />}

    {expanded && (
      <Text color="grey" hasHoverClass marginLeft="sm" size="sm">
        {text}
      </Text>
    )}

    {hasChildren && onToggle && expanded && (
      <Fragment>
        <div css={{ flex: 1 }} />

        <IconButton
          icon={collapsed ? 'chevron down' : 'chevron up'}
          variant="dark"
          onClick={(e) => {
            e.preventDefault();
            onToggle();
          }}
        />
      </Fragment>
    )}
  </Fragment>
);
