import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { Link } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { DropdownMenu } from 'src/components/DropdownMenu';
import { Margins, Paddings, Responsive } from 'src/design-system/style-types';

import { style } from './PanelList.style';

interface SingleAction {
  icon: React.ReactNode;
  url?: string;
  onClick?: () => void;
  delStyle?: boolean;
}

interface MultipleAction {
  label: React.ReactNode;
  url?: string;
  onClick?: () => void;
}

export interface Panel {
  ariaLabel?: string;
  content: React.ReactNode;
  action?: SingleAction | MultipleAction[];
  customAction?: React.ReactNode;
  noHover?: boolean;
  isStatic?: boolean;
  disabled?: boolean;
}

interface Props {
  panels: Panel[];
  name?: string;
  itemPaddingX?: Responsive<Paddings>;
  itemWidth?: Responsive<number | string>;
  marginBottom?: Responsive<Margins>;
  noLastBorder?: boolean;
  scrollAt?: number;
  virtualize?: boolean;
}

export const PanelList = ({
  panels = [],
  itemPaddingX = 'lg',
  itemWidth = 12,
  marginBottom = '0',
  noLastBorder = false,
  scrollAt = 0,
  virtualize = false,
}: Props) => {
  const [panelListRef, setPanelListRef] = useState<HTMLDivElement | null>(null);

  const getTotalPanelsHeight = () => {
    if (!panelListRef) {
      return 0;
    }

    const { children } = panelListRef.children[0];
    const visibleChildren = [...children].slice(0, scrollAt);

    return visibleChildren.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.clientHeight;
    }, visibleChildren[0]?.clientHeight / 2);
  };

  const isScrollable = () => {
    if (!scrollAt) {
      return false;
    }

    if (!panelListRef) {
      return true;
    }

    return panels.length > scrollAt;
  };

  const renderDropdown = (panel: Panel, i: number) => {
    if (panel.action && Array.isArray(panel.action)) {
      return <DropdownMenu options={panel.action} data-qa="panel-list-multi-action" disabled={panel.disabled} />;
    }

    return null;
  };

  const renderLink = (panel: Panel) => {
    if (panel.action && !Array.isArray(panel.action) && !!panel.action.url) {
      return (
        <Link
          to={panel.action.url}
          href={panel.action.url}
          className={`panel-list-single-action ${panel.action.delStyle ? 'del-style' : ''}`}
          data-qa="panel-list-single-action"
        >
          {panel.action.icon}
        </Link>
      );
    }

    return null;
  };

  const renderButton = (panel: Panel) => {
    if (panel.action && !Array.isArray(panel.action) && panel.action.onClick) {
      return (
        <button
          aria-label={panel.ariaLabel}
          type="button"
          onClick={panel.action.onClick}
          className={`panel-list-single-action ${panel.action.delStyle ? 'del-style' : ''}`}
          data-qa="panel-list-single-action"
        >
          {panel.action.icon}
        </button>
      );
    }

    return null;
  };

  const renderCustomAction = (panel: Panel) => {
    if (panel.customAction) {
      return panel.customAction;
    }

    return null;
  };

  const isSingleAction = (action?: SingleAction | MultipleAction[]): action is SingleAction => !Array.isArray(action);

  const renderContent = (panel: Panel) => {
    const actionUrl = panel?.action && isSingleAction(panel.action) ? panel.action.url : undefined;

    const content = () => <Box minW="0">{panel.content}</Box>;

    return actionUrl ? (
      <Link css={style.container} to={actionUrl}>
        {content()}
      </Link>
    ) : (
      content()
    );
  };

  const renderVirtualItem = (item: VirtualItem) => {
    return renderItem(panels[item.index], item.index, item.start);
  };

  const renderItem = (panel: Panel, index: number, rowStart?: number) => {
    return (
      <Box
        minW="0"
        role="row"
        key={typeof panel.content === 'string' ? panel.content : index}
        bg="light"
        w={itemWidth}
        minH="48px"
        px={itemPaddingX}
        a="center"
        wrap="nowrap"
        css={style.panel({
          noHover: panel.noHover,
          noLastBorder,
          isStatic: panel.isStatic,
          rowStart,
        })}
        data-qa="panel-list-panel"
      >
        {renderContent(panel)}

        {renderDropdown(panel, index)}

        {renderLink(panel)}

        {renderButton(panel)}

        {renderCustomAction(panel)}
      </Box>
    );
  };

  const panelListStyleProps = {
    isScrollable: isScrollable(),
    height: getTotalPanelsHeight(),
    marginBottom,
  };

  const virtualizer = useVirtualizer({
    count: panels.length,
    getScrollElement: () => panelListRef,
    estimateSize: () => 48,
    overscan: 4,
  });

  if (virtualize) {
    return (
      <div data-demo-disabled css={style.panelList(panelListStyleProps)} ref={(ref) => setPanelListRef(ref)}>
        <div css={style.panelListInner({ height: virtualizer.getTotalSize() })}>
          {virtualizer.getVirtualItems().map(renderVirtualItem)}
        </div>
      </div>
    );
  }

  return (
    <div data-demo-disabled ref={(ref) => setPanelListRef(ref)} css={style.container}>
      <div css={style.panelList(panelListStyleProps)}>{panels.map((panel, key) => renderItem(panel, key))}</div>
    </div>
  );
};
