import React from 'react';
import { connect } from 'react-redux';

import { Box } from 'src/components/Box';
import { Checkbox } from 'src/components/Checkbox';
import { Text } from 'src/components/Text';
import { AppState } from 'src/store';
import { devtoolsActions } from 'src/store/devtools/actions';
import { devtoolsSelectors } from 'src/store/devtools/selectors';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

type Props = ActionProps & StateProps;

export const _IntlDevtools = ({ isTranslationsHighlighted, toggleTranslationHighlights }: Props) => {
  return (
    <Box p="lg">
      <Text size="sm" weight="bold" marginBottom="sm">
        Translations
      </Text>

      <Checkbox
        name="showIds"
        onChange={({ checked }) => toggleTranslationHighlights({ highlighted: checked })}
        checked={isTranslationsHighlighted}
        size="md"
        label={<Text size="sm">Show translation IDs</Text>}
        marginBottom="0"
      />
    </Box>
  );
};

const stateToProps = (state: AppState) => ({
  isTranslationsHighlighted: devtoolsSelectors.isTranslationsHighlighted(state),
});

export const dispatchToProps = {
  toggleTranslationHighlights: devtoolsActions.toggleTranslationHighlights,
};

export const IntlDevtools = connect(stateToProps, dispatchToProps)(_IntlDevtools);
