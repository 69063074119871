import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { CPLocale } from 'src/orchd-client';
import { IntlProvider, RawIntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { createFakeIntl } from 'src/pages/devtools/Intl/fakeIntl';
import { AppState } from 'src/store';
import { devtoolsSelectors } from 'src/store/devtools/selectors';
import { loginsSelectors } from 'src/store/logins/selectors';
import { uiActions } from 'src/store/ui/actions';
import { getMessages } from 'src/utils/intl/getMessages/getMessages';

type StateProps = ReturnType<typeof stateToProps>;
type ActionProps = typeof dispatchToProps;

export interface Props extends ActionProps, StateProps {
  children: ReactNode[] | ReactNode;
}

const stateToProps = (state: AppState) => ({
  locale: loginsSelectors.selectLocale(state),
  isTranslationsHighlighted:
    devtoolsSelectors.isDevtoolsOpen(state) && devtoolsSelectors.isTranslationsHighlighted(state),
});

const dispatchToProps = { updateDateTimeConfig: uiActions.updateDateTimeConfig };

export const _CustomIntlProvider = ({ locale, children, isTranslationsHighlighted, updateDateTimeConfig }: Props) => {
  const [localeConfig, setLocaleConfig] = useState<{
    locale: CPLocale | undefined;
    messages: Record<string, string> | undefined;
  }>({
    locale,
    messages: undefined,
  });
  const [switchingMessages, setSwitchingMessages] = useState(true);
  const isDevelopment = process.env.NODE_ENV === 'development';

  useEffect(() => {
    // IntlProvider does not change over locale and messages simultaneously
    // this means conditional logic within the handleError does not work as
    // intended around the time the messages object is changed
    if (switchingMessages) {
      const cancelRef = setTimeout(() => {
        setSwitchingMessages(false);
      }, 1000);

      return () => clearTimeout(cancelRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeConfig.messages]);

  // prevent 'missing translation' errors clogging up console during development
  const handleError = (error: any) => {
    if (switchingMessages) {
      return;
    }
    // ignore partial en-US.json translations
    if (localeConfig.locale === CPLocale.en) {
      return;
    }

    if (localeConfig.locale === CPLocale.en_GB && isDevelopment) {
      return;
    }
    console.error(error);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const { messages, dateTimeConfig } = await getMessages(locale);
      updateDateTimeConfig({ dateTimeConfig });
      setSwitchingMessages(true);
      if (locale === CPLocale.en_GB && isDevelopment) {
        return setLocaleConfig({ locale, messages: undefined });
      }
      setLocaleConfig({ locale, messages });
    };

    fetchMessages();
  }, [isDevelopment, locale, updateDateTimeConfig]);

  if (isTranslationsHighlighted) {
    return <RawIntlProvider value={createFakeIntl(localeConfig)}>{children}</RawIntlProvider>;
  }

  return (
    <IntlProvider
      locale={localeConfig.locale ?? locale}
      messages={localeConfig.messages}
      onError={handleError}
      textComponent={Fragment}
      // required for more complex translations such as plural / singular
      defaultLocale={CPLocale.en_GB}
    >
      {children}
    </IntlProvider>
  );
};

export const CustomIntlProvider = connect(stateToProps, dispatchToProps)(_CustomIntlProvider);
