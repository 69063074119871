import { ReactElement, ReactNode, Ref, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';

import { Box } from 'src/components/Box';
import { Card, CardBody, CardHeader } from 'src/components/Card';
import { style } from 'src/components/CardCollapse/CardCollapse.style';
import { Icon } from 'src/components/Icon';
import { H6 } from 'src/components/Text';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { Colors, Margins, Paddings, Responsive } from 'src/design-system/style-types';
import { FontColors } from 'src/design-system/theme';
import { AppState } from 'src/store';
import { isTabletUp } from 'src/utils/breakpointHelpers';
import { getResponsivePropValue } from 'src/utils/helpers';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  dataTestId?: string;
  dataDemoDisabled?: boolean;
  title?: string | ReactElement;
  startCollapsed?: boolean;
  children?: ReactNode;
  header?: ReactNode;
  className?: string;
  padding?: Responsive<Paddings>;
  marginBottom?: Responsive<Margins>;
  fullWidth?: boolean;
  noShadow?: Responsive<boolean>;
  background?: Colors;
  headerBackground?: Colors;
  iconColor?: FontColors;
  borderColor?: Colors;
  innerRef?: Ref<HTMLDivElement>;
  pageCardPadding?: boolean;
  collapsible?: Responsive<boolean>;
  tabletDown?: boolean;
}

const _CardCollapse = ({
  title = '',
  startCollapsed = true,
  children = null,
  header = null,
  className = '',
  padding = 'lg',
  marginBottom = 'lg',
  fullWidth = true,
  noShadow = false,
  background = 'light',
  headerBackground = 'light',
  iconColor = 'dark',
  borderColor = 'transparent',
  pageCardPadding = false,
  collapsible = true,
  tabletDown = false,
  innerRef,
  dataTestId,
  dataDemoDisabled,
  viewportWidth,
}: Props) => {
  const [collapsed, setCollapsed] = useState(startCollapsed);

  const isCollapsible = useMemo(() => {
    let isCollapsible = getResponsivePropValue(collapsible, viewportWidth);

    if (isTabletUp(viewportWidth)) {
      if (tabletDown) {
        isCollapsible = false;
      }
    }

    return isCollapsible;
  }, [viewportWidth, collapsible, tabletDown]);

  return (
    <Card
      dataTestId={dataTestId}
      dataDemoDisabled={dataDemoDisabled}
      marginBottom={marginBottom}
      fullWidth={fullWidth}
      noShadow={noShadow}
      background={background}
      borderColor={borderColor}
      className={className}
      innerRef={innerRef}
    >
      <CardHeader
        divider={(!collapsed || !isCollapsible) && !!children}
        onClick={() => setCollapsed(!collapsed)}
        padding="0"
      >
        <Box
          j="space-between"
          bg={headerBackground}
          a="center"
          css={children && isCollapsible && style.cursorPointer}
          p="lg"
          wrap="nowrap"
        >
          {!header && (
            <H6 size="md" weight="normal">
              {title}
            </H6>
          )}
          {header && <Box>{header}</Box>}
          {isCollapsible && children && <Icon name={collapsed ? 'chevron down' : 'chevron up'} color={iconColor} />}
        </Box>
      </CardHeader>
      {((!collapsed && children) || !isCollapsible) && (
        <CardBody pageCardPadding={pageCardPadding} padding={padding}>
          {children}
        </CardBody>
      )}
    </Card>
  );
};

const stateToProps = (state: AppState) => ({
  viewportWidth: viewportSelectors.selectWidth(state),
});

export const CardCollapse = connect(stateToProps)(_CardCollapse);
