/** @jsxRuntime classic */
import { memo, useEffect, useRef, useState } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useAtom } from 'jotai';
import { FormattedMessage } from 'react-intl';
import { animated, Transition } from 'react-spring/renderprops.cjs';

import { useOrchdStatusQuery } from 'src/api_services/install/query';
import { Box } from 'src/components/Box/Box';
import { Icon } from 'src/components/Icon/Icon';
import { style } from 'src/components/OrchdStatusToast/OrchdStatusToast.style';
import { Text } from 'src/components/Text';
import { useGetOrgName } from 'src/store/branding/query';
import { persistentAtom } from 'src/utils/atoms/persistentAtom';
import { useToast } from 'src/utils/toast/useToast';

export type OrchdStatus = 'ready' | 'initialising' | 'initialisingServers' | 'updating' | undefined;

export const orchdStatusToastAtom = persistentAtom<{
  platformUpdated: boolean;
  platformUpdating: boolean;
  toastDismissed: boolean;
}>('orchdStatusToast', {
  platformUpdated: false,
  platformUpdating: false,
  toastDismissed: false,
});

const selectStatusMap: {
  [key: string]: JSX.Element;
} = {
  initialising: <FormattedMessage id="install.orchd.initialising" defaultMessage="initialising" />,
  updating: <FormattedMessage id="install.orchd.updating" defaultMessage="updating" />,
  initialisingServers: (
    <FormattedMessage id="install.orchd.initialising_servers" defaultMessage="initialising servers" />
  ),
};

export const _OrchdStatusToast = () => {
  const toast = useToast();
  const [{ platformUpdating, platformUpdated, toastDismissed }, setState] = useAtom(orchdStatusToastAtom);
  const { data: orchdStatus, isFetched } = useOrchdStatusQuery();
  const [initialPlatformUpdated, setInitialPlatformUpdated] = useState(platformUpdated);
  const previousOrchdStatus = useRef<OrchdStatus>();
  const orgName = useGetOrgName();

  useEffect(() => {
    // has completed updating
    if (orchdStatus === 'ready' && platformUpdating) {
      setState((prev) => ({ ...prev, platformUpdating: false, platformUpdated: true }));
      // timeout ensures setState gets a chance to write out to localStorage
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    // after reload has completed
    if (orchdStatus === 'ready' && initialPlatformUpdated) {
      setState({ toastDismissed: false, platformUpdating: false, platformUpdated: false });
      setInitialPlatformUpdated(false);
      toast.success(
        <FormattedMessage
          id="software.enhance_updated"
          defaultMessage="{orgName} has successfully updated"
          values={{ orgName }}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPlatformUpdated, platformUpdating, orchdStatus]);

  useEffect(() => {
    // shows the toast and resets the ability to dismiss it
    if (previousOrchdStatus.current !== 'updating' && orchdStatus === 'updating') {
      setState((prev) => ({ ...prev, toastDismissed: false }));
    }

    previousOrchdStatus.current = orchdStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orchdStatus]);

  const appHasBeenRefreshed =
    orchdStatus && ['updating', 'initialising', 'initialisingServers'].includes(orchdStatus) && !toastDismissed;

  const serverHasBeenUpdated =
    isFetched && orchdStatus !== 'ready' && !toastDismissed && platformUpdating && !platformUpdated;

  const showToast = appHasBeenRefreshed || serverHasBeenUpdated;

  const items = showToast ? ['showToast'] : [];

  const statusMessage =
    orchdStatus && selectStatusMap[orchdStatus] ? selectStatusMap[orchdStatus] : selectStatusMap.updating;

  return (
    <div css={style.toastContainerStyles}>
      <Transition
        items={items}
        keys={(item) => item}
        from={{ opacity: 0, transform: 'translateY(-100%)' }}
        enter={{ opacity: 1, transform: 'translateY(0)' }}
        leave={{ opacity: 0, transform: 'translateY(-100%)' }}
        config={{ tension: 170, friction: 26, precision: 0.1 }}
        unique
      >
        {(item) => (styleLocal) => {
          return (
            <animated.div style={styleLocal} key={item} css={style.toastAnimatedDivStyles}>
              <Box a="flex-start" px="lg" py="sm" wrap="nowrap">
                <Box w="25px" h="20px" a="center">
                  <Icon name="loading" size="lg" color="dark" />
                </Box>
                <Box w="auto" a="center" pl="sm" pr="sm">
                  <Text size="sm" fullWidth weight="bold">
                    <FormattedMessage
                      id="install.orchd.status_message"
                      defaultMessage="The hosting platform is currently {status}"
                      values={{
                        status: statusMessage,
                      }}
                    />
                  </Text>
                  <Text size="sm" fullWidth>
                    <FormattedMessage
                      id="install.orchd.status_performance_message"
                      defaultMessage="Performance and functionality may be temporarily affected"
                    />
                  </Text>
                </Box>
                <Box
                  css={style.toastCloseIconStyle}
                  a="center"
                  w="25px"
                  h="22px"
                  onClick={() => setState((prev) => ({ ...prev, toastDismissed: true }))}
                  aria-label="close-orchd-updating-toast"
                >
                  <Icon size={22} name="close" color="dark" />
                </Box>
              </Box>
            </animated.div>
          );
        }}
      </Transition>
    </div>
  );
};

export const OrchdStatusToast = memo(_OrchdStatusToast);
