import { createReducer } from 'deox';

import { updatingServersActions } from './actions';

const { addUpdatingServer, removeUpdatingServer } = updatingServersActions;

export interface UpdatingServersState {
  ids: string[];
}

export const initialState: UpdatingServersState = {
  ids: [],
};

export const updatingServersReducer = createReducer(initialState, (handleAction) => [
  handleAction(addUpdatingServer, (state, { payload }) =>
    state.ids.includes(payload)
      ? state
      : {
          ...state,
          ids: [...state.ids, payload],
        }
  ),

  handleAction(removeUpdatingServer, (state, { payload }) => ({
    ...state,
    ids: state.ids.filter((serverId) => serverId !== payload),
  })),
]);
