import { Website } from 'src/orchd-client';

export const getFilerdUrl = (website: Website) => {
  const url = website.filerdAddress;

  if (!url) {
    throw new Error('Could not get filerd URL');
  }

  return url;
};
