import { ActionType, getType } from 'deox';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import uuid from 'uuid/v4';

import { SAVE_SEARCH_DEBOUNCE_DELAY } from 'src/utils/constants';

import * as actions from './actions';
import { RecentSearchItem } from './types';

export const createRecentSearchItem = ({ key, term }: { key: string; term: string }): RecentSearchItem => ({
  id: uuid(),
  createdAt: Date.now(),
  key,
  term,
});

export function* saveSearchToRecentsSaga({ payload }: ActionType<typeof actions.debounceSaveSearchToRecents>) {
  // todo will remove this in the next part of the ticket
  if (!payload.term) {
    return;
  }

  yield put({ type: getType(actions.saveSearchToRecents), payload: createRecentSearchItem(payload) });
}

export function* debounceSaveSearchToRecentsSaga() {
  yield debounce(SAVE_SEARCH_DEBOUNCE_DELAY, getType(actions.debounceSaveSearchToRecents), saveSearchToRecentsSaga);
}

export function* searchSaga() {
  yield takeEvery(getType(actions.debounceSaveSearchToRecents), debounceSaveSearchToRecentsSaga);
}
