import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'h5',
  weight: 'light',
  size: 'lg',
};

/**
 * # Text H5
 * Use H5to present the main headline
 */
const H5 = (props: Props) => <Text {...props} />;

H5.defaultProps = updatedDefaultProps;

export default H5;
