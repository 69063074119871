import React, { Fragment, memo, ReactElement, useState } from 'react';
import { ServerInfoBrief, ServerRole } from 'src/orchd-client';
import { FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Grid } from 'src/components/Grid';
import { SelectField } from 'src/components/Select';
import { Text } from 'src/components/Text';
import { FormProps } from 'src/containers/Form';
import { Responsive } from 'src/design-system/style-types';
import { messages } from 'src/messages';
import { TRANSFERRABLE_ROLES } from 'src/pages/migrations/types';

import { SelectOption } from '../Select/Select.types';
import { ServerSelectLabel } from './ServerSelectLabel';
import { ServerSelectOptionLabel } from './ServerSelectOptionLabel';

export interface ServerIdsFormFields {
  serverIds: {
    application: SelectOption[];
    email: SelectOption[];
    backup: SelectOption[];
    database: SelectOption[];
    dns: SelectOption[];
  };
}

export interface Props {
  servers: ServerInfoBrief[];
  form: Pick<FormikProps<ServerIdsFormFields>, FormProps>;
  help?: Record<ServerRole, ReactElement | string>;
  updatingServerIds: string[];
  defaultOption?: SelectOption;
  dedicatedSubscriptionsCheck?: boolean;
  columns?: Responsive<number>;
}

export const DEFAULT_OPTION = {
  label: <FormattedMessage {...messages.default} />,
  value: '',
};

export const SELECT_SERVER = {
  label: <FormattedMessage {...messages.select_server} />,
  value: '',
};

export const SHARED_OPTION = {
  label: <FormattedMessage {...messages.shared} />,
  value: '',
};

export const _ServerSelect = ({
  form,
  help,
  servers,
  updatingServerIds,
  defaultOption = DEFAULT_OPTION,
  dedicatedSubscriptionsCheck = false,
  columns = { xs: 1, sm: 2 },
}: Props) => {
  const { initialValues } = form;
  const [search, setSearch] = useState('');

  const getRoleDisabled = (server: ServerInfoBrief, role: ServerRole): boolean => {
    return server.roles?.[role] !== 'enabled';
  };

  const buildOptions = (role: ServerRole, value: string) => {
    const unchangedDisabled = defaultOption.value === value;
    const unchangedLabel = unchangedDisabled ? (
      <Box wrap="nowrap" a="center">
        <Text color={'grey'}>{defaultOption.label}</Text>
      </Box>
    ) : (
      defaultOption.label
    );

    return [
      { value: defaultOption.value, label: unchangedLabel, name: defaultOption.value, isDisabled: unchangedDisabled },
      ...servers
        .filter((server) => server.roles[role] && !server.isDecommissioned)
        .filter((server) => {
          if (!dedicatedSubscriptionsCheck) {
            return true;
          }
          return (
            !server.dedicatedSubscription ||
            (server.dedicatedSubscription && server.id === initialValues.serverIds[role][0].value)
          );
        })
        .map((server) => {
          const roleDisabled = getRoleDisabled(server, role);
          const isUpdating = updatingServerIds.includes(server.id);
          const isClickDisabled = server.id === value || isUpdating;

          const ip = server.ips.find((ip) => ip.isPrimary)?.ip;

          return {
            label: (
              <ServerSelectLabel
                server={server}
                role={role}
                isClickDisabled={isClickDisabled}
                roleDisabled={roleDisabled}
                isUpdating={isUpdating}
              />
            ),
            value: server.id,
            data: {
              name: server.friendlyName,
              server,
              ip,
              isUpdating,
              role,
              isClickDisabled,
            },
            isDisabled: isClickDisabled,
          };
        }),
    ];
  };

  return (
    <Fragment>
      <Grid columnGap="lg" rowGap="xl" columns={columns}>
        {TRANSFERRABLE_ROLES.map((role) => {
          const roleValue = form.values.serverIds[role];
          const value = (roleValue?.[0]?.value as string) ?? '';
          return (
            <Box key={role}>
              <SelectField
                data-qa={`${role}ServerSelect`}
                label={<FormattedMessage {...messages[role]} />}
                name={`${role}Server`}
                value={form.values.serverIds[role]}
                err={form.errors.serverIds?.[role]}
                placeholder={defaultOption.label}
                options={buildOptions(role, value)}
                onChange={async ({ value }) => {
                  await form.setFieldValue(`serverIds.${role}`, value);
                  await form.setFieldTouched(`serverIds.${role}`);
                }}
                searchable
                filterOption={(candidate, input) => {
                  setSearch(input);
                  if (!input) return true;
                  return (
                    candidate.data?.data?.name?.toLowerCase().includes(input) ||
                    candidate.data?.data?.ip?.includes(input)
                  );
                }}
                optionComponent={(props) => <ServerSelectOptionLabel {...props} search={search} />}
              />

              {help && help[role] && (
                <Box mt="sm" data-qa={`${role}ServerSelectChanges`}>
                  <Text size="xs" color="grey">
                    {help[role]}
                  </Text>
                </Box>
              )}
            </Box>
          );
        })}
      </Grid>

      {form.errors.serverIds && form.touched.serverIds && (
        <Box mt="lg">
          <Text size="sm" color="error">
            {String(form.errors.serverIds)}
          </Text>
        </Box>
      )}
    </Fragment>
  );
};

export const ServerSelect = memo(_ServerSelect);
