export const viewportHeight = () => {
  return document.compatMode === 'CSS1Compat' ? document.documentElement.clientHeight : document.body.clientHeight;
};

export const documentHeight = () => {
  return document.body.offsetHeight;
};

export const documentMaximumScrollPosition = () => {
  return documentHeight() - viewportHeight();
};

export const documentVerticalScrollPosition = () => {
  if (window.self.pageYOffset) {
    return window.self.pageYOffset;
  } // Firefox, Chrome, Opera, Safari.
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  } // Internet Explorer 6 (standards mode).
  if (document.body.scrollTop) {
    return document.body.scrollTop;
  } // Internet Explorer 6, 7 and 8.
  return 0; // None of the above.
};

export const elementVerticalClientPositionByRef = (ref: HTMLElement | null) => {
  const element = ref;
  const rectangle = element !== null && element.getBoundingClientRect();
  return rectangle ? rectangle.top : 0;
};

/**
 * Animation tick.
 */
export const scrollVerticalTickToPosition = (currentPosition: number, targetPosition: number, callback: () => void) => {
  const filter = 0.2;
  const fps = 60;
  const difference = targetPosition - currentPosition;

  // Snap, then stop if arrived.
  const arrived = Math.abs(difference) <= 0.5;
  if (arrived) {
    // Apply target.
    window.scrollTo(0.0, targetPosition);
    callback();
    return;
  }
  // Filtered position.
  currentPosition = currentPosition * (1.0 - filter) + targetPosition * filter;

  // Apply target.
  window.scrollTo(0.0, Math.round(currentPosition));

  // Schedule next tick.
  setTimeout(() => scrollVerticalTickToPosition(currentPosition, targetPosition, callback), 1000 / fps);
};

export const scrollVerticalToElementByRef = (ref: HTMLElement | null, padding = 0, callback: () => void) => {
  const element = ref;
  if (element == null) {
    console.warn('Cannot find that ref.'); // eslint-disable-line no-console
    return;
  }

  const targetPosition = documentVerticalScrollPosition() + elementVerticalClientPositionByRef(ref) - padding;
  const currentPosition = documentVerticalScrollPosition();

  // NOTE: this is commented out because there are situations where body height is not accurate like
  // when using the PageSlideDown component, doesn't seem to cause any bugs
  // -----------
  // Clamp.
  // const maximumScrollPosition = documentMaximumScrollPosition();
  // if (targetPosition > maximumScrollPosition) {
  //   targetPosition = maximumScrollPosition;
  // }

  // Start animation.
  scrollVerticalTickToPosition(currentPosition, targetPosition, callback);
};
