import { css } from '@emotion/core';

import { margin, padding } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './Pill';

const container =
  ({
    marginTop = '0',
    marginBottom = '0',
    marginLeft = '0',
    marginRight = '0',
    background = '',
    borderColor = undefined,
    paddingLeft = undefined,
    paddingRight = undefined,
    paddingX = 'sm',
    fontColor = 'dark',
    iconPosition,
  }: Props) =>
  (theme: Theme) =>
    css`
      display: inline-flex;
      align-items: center;
      border-radius: 9999px;
      height: 20px;

      ${padding({ left: paddingLeft || paddingX, right: paddingRight || paddingX })(theme)}
      ${margin({ bottom: marginBottom, right: marginRight, left: marginLeft, top: marginTop })(theme)}

      ${iconPosition === 'floating' &&
      css`
        position: relative;
      `}

      ${background &&
      css`
        background: ${theme.colors[background]};
      `}

      ${borderColor
        ? responsive(
            borderColor,
            (bc) => css`
              border: 1px solid ${theme.colors[bc]};
            `
          )
        : css`
            border: 1px solid ${background ? theme.colors[background] : theme.colors.dark};
          `}

      .ui-svg-hoverable {
        ${responsive(
          fontColor,
          (fc) => css`
            fill: ${theme.colors[fc]};
          `
        )}
      }
    `;

const floatingIcon = (theme: Theme) => css`
  position: absolute;
  top: -6px;
  right: -5px;
`;
export const style = { container, floatingIcon };
