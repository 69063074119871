import { settingsApi } from 'src/api_services/settings/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@serviceSettings';

const getGlobalServiceSetting = createActionsFromMethod<typeof settingsApi.getGlobalServiceSetting>(
  `getGlobalServiceSetting(${namespace})`
);

const setGlobalServiceSetting = createActionsFromMethod<typeof settingsApi.setGlobalServiceSetting>(
  `setGlobalServiceSetting(${namespace})`
);

const deleteGlobalServiceSetting = createActionsFromMethod<typeof settingsApi.deleteGlobalServiceSetting>(
  `deleteGlobalServiceSetting(${namespace})`
);

export const serviceSettingsActions = {
  deleteGlobalServiceSetting,
  getGlobalServiceSetting,
  setGlobalServiceSetting,
};
