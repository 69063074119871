/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { EnhanceUpdateNotification } from 'src/components/DashboardNotifications/EnhanceUpdateNotification';
import { IconButton } from 'src/components/IconButton';
import { Popover } from 'src/components/Popover/Popover';
import { Text } from 'src/components/Text';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { useMountEffect } from 'src/hooks/useMountEffect/useMountEffect';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { installActions } from 'src/store/install/actions';
import { notificationsSelectors } from 'src/store/notifications/selectors';
import { sessionSelectors } from 'src/store/session/selectors';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

export type Props = ActionProps & StateProps;

export const _DashboardNotifications = ({
  getOrchdVersion,
  isMobile,
  member,
  readNotifications,
}: Props) => {
  const { formatMessage } = useIntl();

  useMountEffect(() => {
    if (member?.isMasterOrg) {
      getOrchdVersion({ params: {} });
    }
  });

  const showUpdateNotification = false;

  return (
    <Box w="auto">
      <Popover
        button={
          <IconButton
            iconColor={isMobile ? 'light' : 'dark'}
            hoverBackgroundColor={isMobile ? 'grey75' : undefined}
            variant="tertiary"
            icon="notification"
            showNotificationBadge={showUpdateNotification}
            title={formatMessage(messages.notifications)}
          />
        }
        sideOffset={isMobile ? 10 : 4}
      >
        {({ hide }) => (
          <Box d="column" w={{ xs: '100vw', sm: '300px' }}>
            {showUpdateNotification ? (
              <Fragment>
                <EnhanceUpdateNotification onClick={hide} id="x" version="12.0.0" />

                <Box j="center" my="lg">
                  <Link onClick={hide} to="/notifications">
                    <Text size="sm" textDecoration="underline">
                      <FormattedMessage id="notifications.link" defaultMessage="See all notifications" />
                    </Text>
                  </Link>
                </Box>
              </Fragment>
            ) : (
              <Box a="center" j="center" minH="200px">
                <Text size="sm" color="grey">
                  <FormattedMessage id="notifications.up-to-date" defaultMessage="You're all up to date" />
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Popover>
    </Box>
  );
};

export const dispatchToProps = {
  getOrchdVersion: installActions.getOrchdVersion.request,
};

const stateToProps = (state: AppState) => ({
  isMobile: viewportSelectors.isMobile(state),
  member: sessionSelectors.getMember(state),
  readNotifications: notificationsSelectors.selectReadNotifications(state),
});

export const DashboardNotifications = connect(stateToProps, dispatchToProps)(_DashboardNotifications);
