import { css } from '@emotion/core';

import * as mixins from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';

import type { Props } from './Alert';

const container =
  ({ marginBottom, padding, variant }: Props) =>
  (theme: Theme) =>
    css`
      width: 100%;
      ${mixins.margin({ bottom: marginBottom })(theme)}
      ${mixins.padding({ left: padding, bottom: padding, right: padding, top: padding })(theme)}
      border-width: 1px;
      border-left-width: 6px;
      border-style: solid;
      border-radius: 3px;
      background: ${theme.colors.light};

      ${variant === 'success' &&
      css`
        border-color: ${theme.colors.success};
      `}
      ${variant === 'info' &&
      css`
        border-color: ${theme.colors.info};
      `}
      ${variant === 'warning' &&
      css`
        border-color: ${theme.colors.warning};
      `}
      ${variant === 'error' &&
      css`
        border-color: ${theme.colors.error};
      `}
    `;

const titleText = css`
  margin-top: 2px;
`;

export const style = { container, titleText };
