import { getType } from 'deox';
import { takeLatest } from 'redux-saga/effects';

import { emailsApi } from 'src/api_services/emails/service';
import { createRequestStateSaga } from 'src/store/helpers';

import * as actions from './actions';

export const [checkEmailSaga] = createRequestStateSaga(actions.checkEmail, emailsApi.getWebsiteEmails);
export const [getWebsiteEmailSaga, refreshWebsiteEmailSaga] = createRequestStateSaga(
  actions.getWebsiteEmail,
  emailsApi.getWebsiteEmail
);
export const [createWebsiteEmailSaga] = createRequestStateSaga(
  actions.createWebsiteEmail,
  emailsApi.createWebsiteEmail
);
export const [updateWebsiteEmailSaga] = createRequestStateSaga(
  actions.updateWebsiteEmail,
  emailsApi.updateWebsiteEmail
);
export const [deleteWebsiteEmailSaga] = createRequestStateSaga(
  actions.deleteWebsiteEmail,
  emailsApi.deleteWebsiteEmail
);
export const [getDomainEmailAuthSaga] = createRequestStateSaga(
  actions.getDomainEmailAuth,
  emailsApi.getDomainEmailAuth
);

export function* websiteEmailsPageSaga() {
  yield takeLatest(getType(actions.checkEmail.request), checkEmailSaga);
  yield takeLatest(getType(actions.getWebsiteEmail.request), getWebsiteEmailSaga);
  yield takeLatest(getType(actions.createWebsiteEmail.request), createWebsiteEmailSaga);
  yield takeLatest(getType(actions.updateWebsiteEmail.request), updateWebsiteEmailSaga);
  yield takeLatest(getType(actions.deleteWebsiteEmail.request), deleteWebsiteEmailSaga);
  yield takeLatest(getType(actions.getDomainEmailAuth.request), getDomainEmailAuthSaga);
}
