import { AppState } from 'src/store';
import {
  isBelowDesktop,
  isDesktopUp,
  isExtraLargeDesktopUp,
  isLargeDesktopUp,
  isMobile,
  isTablet,
  isTabletUp,
} from 'src/utils/breakpointHelpers';

export const viewportSelectors = {
  selectViewport: (state: AppState) => state.viewport,
  /** @deprecated */
  selectWidth: (state: AppState) => state.viewport.width,
  selectHeight: (state: AppState) => state.viewport.height,
  isBelowDesktop: (state: AppState) => isBelowDesktop(state.viewport.width),
  isMobile: (state: AppState) => isMobile(state.viewport.width),
  isTablet: (state: AppState) => isTablet(state.viewport.width),
  isTabletUp: (state: AppState) => isTabletUp(state.viewport.width),
  isDesktopUp: (state: AppState) => isDesktopUp(state.viewport.width),
  isLargeDesktopUp: (state: AppState) => isLargeDesktopUp(state.viewport.width),
  isExtraLargeDesktopUp: (state: AppState) => isExtraLargeDesktopUp(state.viewport.width),
};
