import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { scrollToTop } from 'src/utils/scrolling/scrollToTop';

interface Props {
  component: React.ComponentType<any>;
  isLoggedIn: boolean;
  path: string;
  [restProps: string]: unknown;
}

const LoggedInRoute = ({ component: Component, isLoggedIn, path, ...rest }: Props) => {
  useEffect(() => {
    scrollToTop();
  }, [path]);

  return (
    <Route
      {...rest}
      render={(props) => (isLoggedIn ? <Component path={path} {...props} /> : <Redirect to="/login" />)}
    />
  );
};

export default LoggedInRoute;
