import { css } from '@emotion/core';

import { titleCase } from 'src/utils/helpers';

import {
  BoxShadowLevels,
  ColorOverrides,
  Colors,
  FontAlign,
  FontColors,
  FontSizes,
  FontWeights,
  Margins,
  Paddings,
  Responsive,
} from './style-types';
import { responsive } from './style-utils';
import { GenericTheme, Theme } from './theme';

export const fontSize = (size: FontSizes) => (theme: Theme) =>
  css`
    font-size: ${theme.fontSizes[size]}px;
    line-height: ${theme.lineHeights[size]}px;
  `;

export const fontWeight = (weight: FontWeights) => (theme: Theme) =>
  css`
    font-weight: ${theme.fontWeights[weight]};
    font-family: ${theme.fontFamily};
  `;

export const fontColor = (color: FontColors) => (theme: Theme) =>
  css`
    color: ${theme.fontColors[color]};
  `;

export const fillColor = (color: FontColors) => (theme: Theme) =>
  css`
    fill: ${theme.fontColors[color]};
    color: ${theme.fontColors[color]};
  `;

export const fontAlign = (align: FontAlign) => (theme: Theme) =>
  css`
    text-align: ${adjustDir(align)(theme)};
  `;

/**
 * getColorFromTheme selects a named color from the theme colors, or the separate theme override colors (used in branding)
 * The advantage of this helper is to keep things DRY & avoid losses of type specificity
 */
export function getColorFromTheme<T extends Colors | ColorOverrides | FontColors, K extends GenericTheme>(
  color: T,
  { colors, colorOverrides, fontColors }: K
): T extends Colors
  ? K['colors'][T]
  : T extends ColorOverrides
  ? K['colorOverrides'][T]
  : T extends FontColors
  ? K['fontColors'][T]
  : never {
  if (color in fontColors) {
    return fontColors[color as FontColors] as any;
  }
  if (color in colors) {
    return colors[color as Colors] as any;
  }
  if (color in colorOverrides) {
    return colorOverrides[color as ColorOverrides] as any;
  }

  throw new Error(`Unknown color key: ${color}`);
}

export const shadowBorder =
  (color: Colors | ColorOverrides, width = 1) =>
  (theme: Theme) =>
    css`
      box-shadow: inset 0 0 0 ${width}px ${getColorFromTheme(color, theme)};
    `;

export const shadowBorderRight =
  (color: Colors | ColorOverrides, width = 1) =>
  (theme: Theme) =>
    css`
      box-shadow: inset -${width * 2}px 0 0 -${width}px ${getColorFromTheme(color, theme)};
    `;

export const shadowBorderBottom =
  (color: Colors | ColorOverrides, width = 1) =>
  (theme: Theme) => ({
    /* using an object so we can pass these styles to react-select */
    boxShadow: `inset 0 -${width * 2}px 0 -${width}px ${
      color in theme.colors ? theme.colors[color as Colors] : theme.colorOverrides[color as ColorOverrides]
    }`,
  });

export const boxShadowGrey = (level: BoxShadowLevels, height = 4) => {
  /* using an object so we can pass these styles to react-select */
  let boxShadow;

  if (level === 0) {
    boxShadow = 'none';
  }

  if (level === 0.5) {
    boxShadow = `0 ${height}px 8px 0 rgba(0, 0, 0, 0.05)`;
  }

  if (level === 1) {
    boxShadow = `0 ${height}px 8px 0 rgba(0, 0, 0, 0.1)`;
  }

  if (level === 2) {
    boxShadow = `0 ${height}px 8px 0 rgba(0, 0, 0, 0.2)`;
  }

  return {
    boxShadow,
  };
};

export const pseudoBorderLeft =
  (color: Colors | ColorOverrides, width = 1) =>
  (theme: Theme) =>
    css`
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: ${width}px solid ${getColorFromTheme(color, theme)};
    `;

const adjustDir = (value: string) => (theme: Theme) => {
  if (theme.settings.rtl) {
    if (value === 'left') {
      return 'right';
    }
    if (value === 'right') {
      return 'left';
    }
  }
  return value;
};

export const leftString = adjustDir('left');

export const rightString = adjustDir('right');

export const right = (value: string) => (theme: Theme) => ({
  [rightString(theme)]: value,
});

export const left = (value: string) => (theme: Theme) => ({
  [leftString(theme)]: value,
});

export const borderRight = (value: string) => (theme: Theme) => ({
  [`border${titleCase(rightString(theme))}`]: value,
});

export const borderLeft = (value: string) => (theme: Theme) => ({
  [`border${titleCase(leftString(theme))}`]: value,
});

export const border =
  ({
    left,
    right,
    top,
    bottom,
  }: {
    left?: Responsive<string>;
    right?: Responsive<string>;
    top?: Responsive<string>;
    bottom?: Responsive<string>;
  }) =>
  (theme: Theme) => {
    return css`
      ${left &&
      responsive(
        left,
        (_left) =>
          css`
          border-${leftString(theme)}: ${_left};
        `
      )}

      ${right &&
      responsive(
        right,
        (_right) =>
          css`
          border-${rightString(theme)}: ${_right};
        `
      )}

${top &&
      responsive(
        top,
        (_top) =>
          css`
            border-top: ${_top};
          `
      )}

${bottom &&
      responsive(
        bottom,
        (_bottom) =>
          css`
            border-bottom: ${_bottom};
          `
      )}
    `;
  };

export const margin =
  ({
    left,
    right,
    top,
    bottom,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
  }: {
    left?: Responsive<Margins | number | undefined>;
    right?: Responsive<Margins | number | undefined>;
    top?: Responsive<Margins | number | undefined>;
    bottom?: Responsive<Margins | number | undefined>;
    marginLeft?: Responsive<Margins | number | undefined>;
    marginRight?: Responsive<Margins | number | undefined>;
    marginTop?: Responsive<Margins | number | undefined>;
    marginBottom?: Responsive<Margins | number | undefined>;
  }) =>
  (theme: Theme) => {
    const leftValue = left || marginLeft;
    const rightValue = right || marginRight;
    const topValue = top || marginTop;
    const bottomValue = bottom || marginBottom;

    return css`
      ${leftValue &&
      responsive(
        leftValue,
        (leftVal) =>
          css`
          margin-${leftString(theme)}: ${leftVal === 'auto' ? 'auto' : `${theme.margins[leftVal as Margins]}px`};
        `
      )}

      ${rightValue &&
      responsive(
        rightValue,
        (rightVal) =>
          css`
          margin-${rightString(theme)}: ${rightVal === 'auto' ? 'auto' : `${theme.margins[rightVal as Margins]}px`};
        `
      )}

${topValue &&
      responsive(
        topValue,
        (topVal) =>
          css`
            margin-top: ${topVal === 'auto' ? 'auto' : `${theme.margins[topVal as Margins]}px`};
          `
      )}

${bottomValue &&
      responsive(
        bottomValue,
        (bottomVal) =>
          css`
            margin-bottom: ${bottomVal === 'auto' ? 'auto' : `${theme.margins[bottomVal as Margins]}px`};
          `
      )}
    `;
  };

export const padding =
  ({
    left,
    right,
    top,
    bottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
  }: {
    left?: Responsive<Paddings | undefined>;
    right?: Responsive<Paddings | undefined>;
    top?: Responsive<Paddings | undefined>;
    bottom?: Responsive<Paddings | undefined>;
    paddingLeft?: Responsive<Paddings | undefined>;
    paddingRight?: Responsive<Paddings | undefined>;
    paddingTop?: Responsive<Paddings | undefined>;
    paddingBottom?: Responsive<Paddings | undefined>;
  }) =>
  (theme: Theme) => {
    const leftValue = left || paddingLeft;
    const rightValue = right || paddingRight;
    const topValue = top || paddingTop;
    const bottomValue = bottom || paddingBottom;

    return css`
      ${leftValue &&
      responsive(
        leftValue,
        (leftVal) =>
          css`
          padding-${leftString(theme)}: ${`${theme.paddings[leftVal as Paddings]}px`};
        `
      )}

      ${rightValue &&
      responsive(
        rightValue,
        (rightVal) =>
          css`
          padding-${rightString(theme)}: ${`${theme.paddings[rightVal as Paddings]}px`};
        `
      )}

  ${topValue &&
      responsive(
        topValue,
        (topVal) =>
          css`
            padding-top: ${`${theme.paddings[topVal as Paddings]}px`};
          `
      )}

  ${bottomValue &&
      responsive(
        bottomValue,
        (bottomVal) =>
          css`
            padding-bottom: ${`${theme.paddings[bottomVal as Paddings]}px`};
          `
      )}
    `;
  };

export const hiding = (
  responsiveHide: Responsive<boolean>,
  display: 'block' | 'inline-block' | 'flex' | 'inline-flex'
) =>
  responsive(
    responsiveHide,
    (hide) =>
      css`
        display: ${hide ? 'none' : display};
      `
  );

export const fadeIn = (duration = 0.2) => css`
  animation: fadein ${duration}s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
