import React, { useMemo } from 'react';
import {
  DomainInUseStatusStatusEnum,
  LoginMembership,
  ResourceName,
  Subscription,
  WebsiteKind,
} from 'src/orchd-client';
import { FormikHelpers } from 'formik';
import sortBy from 'lodash/sortBy';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useCheckDomainMutation } from 'src/api_services/domains/query';
import { useGetActiveSubscriptionsQuery } from 'src/api_services/subscriptions/query';
import { useCloneWebsiteMutation } from 'src/api_services/websites/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Card, CardBody, CardFooter } from 'src/components/Card';
import { Checkbox } from 'src/components/Checkbox';
import { Modal } from 'src/components/Modal';
import { SelectField } from 'src/components/SelectNew';
import { DEFAULT_OPTION } from 'src/components/ServerSelect/ServerSelect';
import { ServerSelectContainer } from 'src/components/ServerSelect/ServerSelectContainer';
import { H3, Text } from 'src/components/Text';
import { TextInputField } from 'src/components/TextInput';
import { Form } from 'src/containers/Form';
import { messages } from 'src/messages';
import { getErrorInfo } from 'src/utils/errors';
import { getSelectFieldValue } from 'src/utils/helpers';
import { useHideModal } from 'src/utils/modal/useModal';
import { getSubscriptionResource, isUnlimitedResourceTotal } from 'src/utils/packages/utils';
import { parseServerIdFields } from 'src/utils/parseServerIdFields/parseServerIdFields';
import { useToast } from 'src/utils/toast/useToast';

import { getSchema, PushLiveToNewFields } from './schema';

interface Props {
  member: LoginMembership;
  websiteId: string;
  isCloning: boolean;
}

export const PushLiveToNewModal = ({ member, websiteId, isCloning }: Props) => {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const hide = useHideModal();
  const { push } = useHistory();
  const { orgId, isMasterOrg } = member;

  const { mutateAsync: cloneWebsite } = useCloneWebsiteMutation();
  const { mutateAsync: checkDomain } = useCheckDomainMutation();

  const { data: subscriptions } = useGetActiveSubscriptionsQuery({ orgId, enabled: !isMasterOrg });

  const getRemainingWebsites = (subscription: Subscription) => {
    const resource = getSubscriptionResource(subscription, ResourceName.websites);

    if (!resource) {
      return 0;
    }

    if (isUnlimitedResourceTotal(resource.total)) {
      return -1;
    }

    const remaining = (resource.total || 0) - resource.usage;

    return remaining || 0;
  };

  const getSubscriptionUsage = (subscription: Subscription) => {
    const resource = getSubscriptionResource(subscription, ResourceName.websites);

    if (!resource) {
      return { usage: 0, total: 0 };
    }

    if (isUnlimitedResourceTotal(resource.total)) {
      return { usage: resource.usage, total: -1 };
    }

    return { usage: resource.usage, total: resource.total || 0 };
  };

  const subscriptionsOptions = useMemo(
    () =>
      sortBy(subscriptions, (subscription) => -getRemainingWebsites(subscription)).map((subscription) => {
        const disabled = !!(getRemainingWebsites(subscription) === 0 || subscription.suspendedBy);

        return {
          value: subscription.id,
          label: subscription.friendlyName || subscription.planName,
          isDisabled: disabled,
          data: subscription,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptions]
  );

  const handleSubmit = async (values: PushLiveToNewFields, form: FormikHelpers<PushLiveToNewFields>) => {
    const { domain, subscription } = values;
    const subscriptionId = getSelectFieldValue(subscription);
    const serverIds = parseServerIdFields(values, member.isMasterOrg);

    try {
      const {
        data: { status },
      } = await checkDomain({ orgId, newDomain: { domain } });

      if (status === DomainInUseStatusStatusEnum.prohibited) {
        return form.setFieldError('domain', formatMessage(messages.domain_prohibited_by_platform));
      }

      if (
        status === DomainInUseStatusStatusEnum.inUseCurrentOrg ||
        status === DomainInUseStatusStatusEnum.inUseAnotherOrg
      ) {
        return form.setFieldError(
          'domain',
          formatMessage({
            id: 'websites.push_live_modal.validation.domain.exists',
            defaultMessage: 'This domain already exists on the platform',
          })
        );
      }

      const {
        data: { id: cloneId },
      } = await cloneWebsite({
        orgId,
        websiteCloneRequest: {
          sourceWebsiteId: websiteId,
          excludePaths: [],
          deleteFilesFromDestination: false,
          syncPhpVersion: false,
          runWpSearchReplace: values.runWpSearchReplace,
          newWebsite: {
            domain,
            kind: WebsiteKind.normal,
            subscriptionId: isMasterOrg ? undefined : Number(subscriptionId),
            ...serverIds,
          },
        },
      });

      isCloning ? push(`/websites/clone/${cloneId}`) : push(`/websites/push-live/${cloneId}`);

      hide();
    } catch (e) {
      const { message } = getErrorInfo(e);

      toast.error(
        isCloning
          ? formatMessage(
              { id: 'websites.push_live_modal.clone_error', defaultMessage: 'Failed to clone website: {error}' },
              { error: message }
            )
          : formatMessage(
              { id: 'websites.push_live_to_existing.error', defaultMessage: 'Failed to push website live: {error}' },
              { error: message }
            )
      );
    }
  };

  return (
    <Modal>
      <Form<PushLiveToNewFields>
        initialValues={{
          domain: '',
          subscription: [],
          serverIds: {
            application: [DEFAULT_OPTION],
            email: [DEFAULT_OPTION],
            backup: [DEFAULT_OPTION],
            database: [DEFAULT_OPTION],
            dns: [DEFAULT_OPTION],
          },
          runWpSearchReplace: true,
        }}
        onSubmit={(values, form) => handleSubmit(values, form)}
        validationSchema={getSchema(formatMessage, isMasterOrg)}
        validateOnMount
      >
        {(form) => (
          <Card marginBottom="lg">
            <CardBody>
              <Box j="center" p="lg">
                <H3 marginBottom="2xl">
                  {isCloning ? (
                    <FormattedMessage id="websites.push_live_modal.clone_title" defaultMessage="Clone website" />
                  ) : (
                    <FormattedMessage id="websites.push_live_modal.title" defaultMessage="Push staging website live" />
                  )}
                </H3>

                <Text size="sm" marginBottom="2xl">
                  <FormattedMessage
                    id="websites.push_live_modal.body"
                    defaultMessage="Enter your production domain name to push your staging domain live. Pushing a staging website live will consume 1 website from your package resources."
                  />
                </Text>

                {!isMasterOrg && (
                  <Box mb="xl">
                    <SelectField
                      name="subscription"
                      placeholder={formatMessage(messages.select_a_package)}
                      label={formatMessage(messages.package)}
                      value={form.values.subscription}
                      err={form.errors.subscription}
                      touched={form.touched.subscription}
                      onChange={({ name, value }) => form.setFieldValue(name, value)}
                      options={subscriptionsOptions}
                      requiredIndicator
                      clearable
                      searchable
                      estimatedOptionHeight={56}
                      renderOption={(option) => {
                        const subscription = option.option.data as Subscription;
                        const isDisabled = option.option.isDisabled;
                        const search = option.search;

                        return (
                          <Box>
                            <Box wrap="nowrap" j="space-between">
                              <Text ellipsis fullWidth color={isDisabled ? 'disabled' : 'dark'} highlightText={search}>
                                {subscription.friendlyName || subscription.planName}
                              </Text>
                            </Box>

                            <Text size="sm" color="grey">
                              <FormattedMessage
                                id="websites.push_live_modal.website_usage"
                                defaultMessage="{usage} of {total, plural, =-1 {∞} other {#}} websites used"
                                values={{ ...getSubscriptionUsage(subscription) }}
                              />
                            </Text>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                )}

                <Box mb="lg">
                  <TextInputField
                    name="domain"
                    label="Domain"
                    err={form.errors.domain}
                    touched={form.touched.domain}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.domain}
                    requiredIndicator
                    prefix="www."
                  />
                </Box>

                <Checkbox
                  name="runWpSearchReplace"
                  label={formatMessage({
                    id: 'run_wp_search_replace',
                    defaultMessage: 'Perform a WordPress search and replace',
                  })}
                  size="sm"
                  fontSize="sm"
                  checked={form.values.runWpSearchReplace}
                  onChange={({ name, checked }) => form.setFieldValue(name, checked)}
                  infoTooltip={formatMessage({
                    id: 'run_wp_search_replace.tooltip',
                    defaultMessage:
                      'Using wp-cli this will search every table in the database replacing the old domain with the new domain.',
                  })}
                />

                {isMasterOrg && (
                  <Box mt="xl">
                    <ServerSelectContainer />
                  </Box>
                )}
              </Box>
            </CardBody>

            <CardFooter>
              <Box a="center" px="lg" j="flex-end" c={{ w: 'auto', ml: '2xl' }}>
                <Button variant="tertiary" size="small" onClick={hide}>
                  <FormattedMessage {...messages.cancel} />
                </Button>

                <Button type="submit" size="small" faded={!form.isValid} loading={form.isSubmitting}>
                  {isCloning ? <FormattedMessage {...messages.clone} /> : <FormattedMessage {...messages.push_live} />}
                </Button>
              </Box>
            </CardFooter>
          </Card>
        )}
      </Form>
    </Modal>
  );
};
