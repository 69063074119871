import { atom } from 'jotai';
import isFunction from 'lodash/isFunction';

import { getItemKey } from '../persister/persister';

export const persistentAtom = <T extends unknown>(
  key: string,
  initialValue: T,
  options: { isPerUser?: boolean } = { isPerUser: false }
) => {
  // The cast is necessary here as the helper only takes specific keys that
  // have yup models behind them. The type safety is guaranteed by jotai here
  // though.
  const itemKey = getItemKey({ key: key as any, isPerUser: options.isPerUser });

  const getInitialValue = () => {
    const item = localStorage.getItem(itemKey);

    if (item === null) {
      return initialValue;
    }

    try {
      const parsed: T = JSON.parse(item);

      return parsed;
    } catch {
      return initialValue;
    }
  };

  const baseAtom = atom(getInitialValue());

  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: T | ((update: T) => T)) => {
      const nextValue = isFunction(update) ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      localStorage.setItem(itemKey, JSON.stringify(nextValue));
    }
  );
  return derivedAtom;
};
