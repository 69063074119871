import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Avatar } from 'src/components/Avatar';
import { Box } from 'src/components/Box';
import { Text } from 'src/components/Text';
import { Colors, Margins, Responsive } from 'src/design-system/style-types';

export interface Props {
  users: UserAvatarConfig[];
  size: 'xs' | 'sm' | 'md';
  marginBottom?: Responsive<Margins>;
  border?: boolean;
  shadow?: boolean;
  shownUsers?: number;
  totalUsers?: number;
}

export interface UserAvatarConfig {
  id: string;
  name: string;
  avatarPath?: string;
  colorCode?: Colors;
}

const _AvatarStack = ({
  border = false,
  marginBottom = '0',
  shadow = false,
  shownUsers = 3,
  size,
  totalUsers,
  users,
}: Props) => {
  const displayUsers = users.slice(0, shownUsers);

  const getAdditionalUsersCount = () => {
    const total = totalUsers || users.length;
    const count = total - shownUsers;

    return count < 0 ? 0 : count;
  };

  const additionalUsersCount = getAdditionalUsersCount();

  return (
    <Box w="auto" a="center" mb={marginBottom}>
      {displayUsers.map((user) => (
        <Avatar
          key={user.id}
          size={size}
          src={user.avatarPath}
          name={user.name}
          border={border}
          shadow={shadow}
          marginRight={`-${size}` as Margins}
          colorCode={user.colorCode}
        />
      ))}

      {!!additionalUsersCount && (
        <Text size="xs" color="grey" marginLeft="md">
          <FormattedMessage
            id="avatar_stack.count_more"
            defaultMessage="+ {count} more"
            values={{ count: additionalUsersCount }}
          />
        </Text>
      )}
    </Box>
  );
};

export const AvatarStack = memo(_AvatarStack);
