import { LoginMembership, Role } from 'src/orchd-client';
import { push } from 'connected-react-router';
import { ActionType, getType } from 'deox';
import { call, delay, put, race, select, take, takeEvery, takeLeading } from 'typed-redux-saga/macro';

import { installApi } from 'src/api_services/install/service';
import { brandingSelectors } from 'src/store/branding/selectors';
import { impersonationActions } from 'src/store/impersonation/actions';
import { loginsActions } from 'src/store/logins/actions';
import { getLoginMembershipsSaga, getLoginSaga } from 'src/store/logins/sagas';
import { loginsSelectors } from 'src/store/logins/selectors';
import { getErrorInfo } from 'src/utils/errors';

import { impersonationSelectors } from '../impersonation';
import { sessionActions } from './actions';
import { sessionSelectors } from './selectors';

export const rolePower: Record<Role, number> = {
  [Role.Owner]: 100,
  [Role.SuperAdmin]: 75,
  [Role.Business]: 50,
  [Role.SiteAccess]: 50,
  [Role.Support]: 50,
  [Role.Sysadmin]: 50,
};

export const getMemberRolePower = (membership: LoginMembership) =>
  Math.max(...membership.roles.map((role) => rolePower[role]));

export const sortMembershipsByRolePower = (memberships: LoginMembership[]) =>
  memberships.sort((a, b) => {
    const aPower = getMemberRolePower(a);
    const bPower = getMemberRolePower(b);
    if (aPower > bPower) {
      return -1;
    }
    return 1;
  });

export function* findActiveMemberSaga({ payload }: ActionType<typeof sessionActions.findActiveMember.request>) {
  try {
    // used to block the app rendering until the API is available
    while (true) {
      try {
        yield* call(installApi.orchdStatus, {});
        break;
      } catch (e) {
        const { status } = getErrorInfo(e);
        if (status === 401) {
          break;
        }
        yield delay(500);
      }
    }

    // Fetch login memberships.
    yield put(loginsActions.getLoginMemberships.request({ params: {} }));

    const { error } = yield* race({
      success: take(loginsActions.getLoginMemberships.success),
      error: take(loginsActions.getLoginMemberships.error),
    });

    if (error) {
      throw new Error(error.payload.error);
    }

    const loginMemberships: LoginMembership[] | undefined = yield select(loginsSelectors.loginMemberships);
    const currentMemberId: string = yield select(sessionSelectors.getCurrentMemberId);
    let member = loginMemberships?.find((member) => member.memberId === currentMemberId);

    // If there is no current member ID stored or we couldn't find a matching membership, find the highest access level
    // one
    if (!member) {
      member = loginMemberships && sortMembershipsByRolePower(loginMemberships)[0];
    }

    // If we couldn't find any member to set as default, log the user out.
    if (!member) {
      throw new Error();
    }

    yield put(sessionActions.setCurrentMemberId({ id: member.memberId }));

    // Fetching subscriptions has been moved to RQ and useSubscriptions.

    yield put(sessionActions.findActiveMember.success());

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const error = getErrorInfo(e);

    yield put(sessionActions.findActiveMember.error({ error: error.message }));

    payload.onError && payload.onError(e as Error);
  }
}

export function* logoutSaga() {
  const isLoggedIn = yield* select(sessionSelectors.getIsLoggedIn);
  const orgLogoutUrl = yield* select(brandingSelectors.selectOrgLogoutUrl);
  const impersonationMember = yield* select(impersonationSelectors.getImpersonationMember);

  if (impersonationMember) {
    yield put(impersonationActions.stopImpersonating());
  }

  if (!isLoggedIn) {
    yield put(sessionActions.loggedOut());

    yield put(push('/login'));

    return;
  }

  yield put(loginsActions.deleteCurrentSession.request({ params: {} }));

  yield take([getType(loginsActions.deleteCurrentSession.success), getType(loginsActions.deleteCurrentSession.error)]);

  yield put(sessionActions.loggedOut());

  if (orgLogoutUrl) {
    return void window.open(orgLogoutUrl, '_self');
  }

  yield put(push('/login'));
}

export function* sessionSaga() {
  yield takeEvery(
    [
      getType(sessionActions.appLoaded),
      getType(loginsActions.createSession.success),
      getType(loginsActions.updateLoginInfo.success),
    ],
    getLoginSaga
  );

  yield takeEvery(
    [
      getType(sessionActions.appLoaded),
      getType(loginsActions.createSession.success),
      getType(loginsActions.updateLoginInfo.success),
    ],
    getLoginMembershipsSaga
  );

  yield takeLeading([getType(sessionActions.logout)], logoutSaga);
  yield takeEvery([getType(sessionActions.findActiveMember.request)], findActiveMemberSaga);
}
