import { getType } from 'deox';
import { takeEvery, takeLatest } from 'typed-redux-saga/macro';

import { serversApi } from 'src/api_services/servers/service';
import { createRequestStateSaga } from 'src/store/helpers';

import { addServerRole, removeServerRole, updateServerRole } from './actions';

export const [updateRoleStateSaga] = createRequestStateSaga(updateServerRole, serversApi.updateServerRole);
export const [addServerRoleSaga] = createRequestStateSaga(addServerRole, serversApi.installServerRole);
export const [removeServerRoleSaga] = createRequestStateSaga(removeServerRole, serversApi.uninstallServerRole);

export function* serverRolesSaga() {
  yield takeLatest(getType(updateServerRole.request), updateRoleStateSaga);
  yield takeEvery(getType(addServerRole.request), addServerRoleSaga);
  yield takeEvery(getType(removeServerRole.request), removeServerRoleSaga);
}
