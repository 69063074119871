import { NewWebsite, NewWebsiteApp, WebsiteKind } from 'src/orchd-client';
import { createActionCreator } from 'deox';
import { Optional } from 'utility-types';

import { appsApi } from 'src/api_services/apps';
import { GetServersReq, GetServersRes } from 'src/api_services/servers/types';
import { websitesApi } from 'src/api_services/websites/service';
import { createActionsFromMethod, createRequestStateActions } from 'src/store/helpers';

export interface CreateWebsiteWithAppParams {
  orgId: string;
  newWebsite: NewWebsite;
  newWebsiteApp: Optional<NewWebsiteApp, 'version'>; // version will be latest available if unspecified
  appPlugins?: string[];
  kind?: WebsiteKind;
}

const namespace = '@createWebsite';

export const getServers = createRequestStateActions<{ params: GetServersReq; data: GetServersRes }>(
  `getServers(${namespace})`
);

export const createWebsite = createActionsFromMethod<typeof websitesApi.createWebsite>(`createWebsite(${namespace}`);

export const createWebsiteWithApp = createRequestStateActions<{ params: CreateWebsiteWithAppParams }>(
  `createWebsiteWithApp(${namespace})`
);

export const getInstallableApps = createActionsFromMethod<typeof appsApi.getInstallableApps>(
  `getInstallableApps(${namespace})`
);

export const setCreatedWebsiteId = createActionCreator(
  `setCreatedWebsiteId(${namespace})`,
  (resolve) => (payload: { websiteId?: string }) => resolve(payload)
);
