export enum zIndex {
  // Common z-index values for simple tweaks
  MinusOne = -1,
  Zero = 0,
  One = 1,

  // Elements which need a z-index higher than 1 but are mostly self-contained
  LoginPage,
  SelectChain,
  AvatarEditIcon,
  AvatarLoading,
  RangeSlider,
  VerticalTabsLabel,
  VerticalTabsLabelActive,
  WebsitePreviewCardIcon,
  WebsitePreviewCardFocus,
  ColorPicker,
  DefaultOverlay,
  DimmerOverlay,
  WebsitePreviewCardScreenshotPending,
  WebsitePreviewCardRestoringMask,
  DatabaseOverviewOverlay,
  FtpUsersOverviewOverlay,
  StatusPill,

  // Elements which are more global or interact with other UI components

  ChartOverlay,
  FloatingButton,
  VerticalTabsPortal,
  BulkActionBar,
  MobileSearchPortal,
  SideNav,
  SecondaryNavFixed,
  SecondaryNavMobileDropdown,
  SelectMenu,
  DashboardHeaderSM,
  DashboardHeaderContainer,
  ImpersonationBanner,
  Portal,
  DashboardSpine,
  Bubble,
  DashboardHeaderXS,
  Dropdown,
  TextEditor,
  Tooltip,
  ModalOverlay,
  Modal,
  ToastHub,
  MobileSearch,
  DatePickerModal,
  OrchdStatusToast,
  DemoModeToast,
  FileManagerDropdown,
  TooltipInModal,
  WebsiteAnimation,
  Devtools,
}
