import { emailsApi } from 'src/api_services/emails/service';
import { createActionsFromMethod } from 'src/store/helpers';

const WEBSITE_EMAILS = 'WEBSITE_EMAILS';

const CHECK_EMAIL = `${WEBSITE_EMAILS}/CHECK_EMAIL`;
const GET_WEBSITE_EMAIL = `${WEBSITE_EMAILS}/GET_WEBSITE_EMAIL`;
const CREATE_WEBSITE_EMAIL = `${WEBSITE_EMAILS}/CREATE_WEBSITE_EMAIL`;
const UPDATE_WEBSITE_EMAIL = `${WEBSITE_EMAILS}/UPDATE_WEBSITE_EMAIL`;
const DELETE_WEBSITE_EMAIL = `${WEBSITE_EMAILS}/DELETE_WEBSITE_EMAIL`;

const GET_DOMAIN_EMAIL_AUTH = `${WEBSITE_EMAILS}/GET_DOMAIN_EMAIL_AUTH`;

export const checkEmail = createActionsFromMethod<typeof emailsApi.getWebsiteEmails>(CHECK_EMAIL);

export const getWebsiteEmail = createActionsFromMethod<typeof emailsApi.getWebsiteEmail>(GET_WEBSITE_EMAIL);

export const createWebsiteEmail = createActionsFromMethod<typeof emailsApi.createWebsiteEmail>(CREATE_WEBSITE_EMAIL);

export const updateWebsiteEmail = createActionsFromMethod<typeof emailsApi.updateWebsiteEmail>(UPDATE_WEBSITE_EMAIL);

export const deleteWebsiteEmail = createActionsFromMethod<typeof emailsApi.deleteWebsiteEmail>(DELETE_WEBSITE_EMAIL);

export const getDomainEmailAuth = createActionsFromMethod<typeof emailsApi.getDomainEmailAuth>(GET_DOMAIN_EMAIL_AUTH);

export const websiteEmailsActions = {
  checkEmail,
  getWebsiteEmail,
  createWebsiteEmail,
  updateWebsiteEmail,
  deleteWebsiteEmail,
  getDomainEmailAuth,
};
