import * as Yup from 'yup';

import { models } from './models';

export type LocalStorageKey =
  | 'branding'
  | 'globalSearch'
  | 'impersonation'
  | 'impersonationBranding'
  | 'moveServer'
  | 'notifications'
  | 'orchdStatusToast'
  | 'search'
  | 'session'
  | 'ui'
  | 'globalUi'
  | 'devtools'
  | 'tableSettings';

interface Props<T extends LocalStorageKey> {
  key: T;
  isPerUser?: boolean;
  usePerUserImpersonation?: boolean;
}

interface SetProps<T extends LocalStorageKey> extends Props<T> {
  item: any;
}

export const clear = <T extends LocalStorageKey>(props: Props<T>) => {
  try {
    localStorage.removeItem(getItemKey(props));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('persister.clear', props.key, e);
  }
};

export const get = <T extends LocalStorageKey>({
  key,
  isPerUser,
  usePerUserImpersonation = true,
}: Props<T>): Yup.InferType<(typeof models)[T]> | undefined => {
  try {
    const item = localStorage.getItem(getItemKey({ key, isPerUser, usePerUserImpersonation }));

    // Skip validation if the state is empty.
    if (!item) {
      return;
    }

    const parsed = JSON.parse(item ?? '{}');
    const model = models[key];

    // Throws if invalid
    model.validateSync(parsed, { strict: true });

    return model.cast(parsed, { stripUnknown: true }) as Yup.InferType<(typeof models)[T]>;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('persister.get', key, e);

    // Flush the key and return undefined.
    clear({ key, isPerUser, usePerUserImpersonation });
  }
};

export const getItemKey = <T extends LocalStorageKey>({
  key,
  isPerUser,
  usePerUserImpersonation = true,
}: Props<T>): string => {
  if (!isPerUser) {
    return key;
  }

  const session = get({ key: 'session' });
  const impersonation = get({ key: 'impersonation' });

  if (!session) {
    return `${key}-anonymous`;
  }

  if (usePerUserImpersonation && impersonation) {
    return `${key}-impersonation-${session.currentMemberId}`;
  }

  return `${key}-${session.currentMemberId}`;
};

export const set = <T extends LocalStorageKey>({
  key,
  isPerUser,
  item,
  usePerUserImpersonation = true,
}: SetProps<T>) => {
  try {
    localStorage.setItem(getItemKey({ key, isPerUser, usePerUserImpersonation }), JSON.stringify(item));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('persister.set', key, e);
  }
};

export const persister = {
  clear,
  get,
  set,
};
