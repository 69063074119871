import { Disk } from 'src/orchd-client';
import sortBy from 'lodash/sortBy';

import { uuidRegex } from 'src/utils/regex';

export const getServerIdFromUrl = (url = '') => {
  const serverId = url.match(uuidRegex)?.[0];
  return serverId;
};

export const getSortedDiskUsage = (disks?: Disk[], asc = false): Disk[] => {
  if (!disks) return [];
  const filteredPartitions = disks.filter((disk) => disk.name?.[0] === '/');
  const sortedPartitions = sortBy(filteredPartitions, [
    (disk) => {
      return asc ? disk.mountPoint.length : -disk.mountPoint.length;
    },
    'mountPoint',
  ]);
  return sortedPartitions;
};
