import { css } from '@emotion/core';

import { boxShadowGrey } from 'src/design-system/mixins';
import { FontColors, Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const popoverContent =
  ({ color }: { color: FontColors }) =>
  (theme: Theme) =>
    css`
      ${boxShadowGrey(1)}
      background: ${theme.colors.light};
      z-index: ${zIndex.Dropdown};

      animation-duration: 400ms;
      animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
      will-change: transform, opacity;

      &[data-state='open'][data-side='top'] {
        animation-name: fade;
        border-bottom: 1px solid ${theme.fontColors[color]};
      }
      &[data-state='open'][data-side='right'] {
        animation-name: fade;
        border-left: 1px solid ${theme.fontColors[color]};
      }
      &[data-state='open'][data-side='bottom'] {
        animation-name: fade;
        border-top: 1px solid ${theme.fontColors[color]};
      }
      &[data-state='open'][data-side='left'] {
        animation-name: fade;
        border-right: 1px solid ${theme.fontColors[color]};
      }

      @keyframes fade {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `;

const popoverArrow =
  ({ color }: { color: FontColors }) =>
  (theme: Theme) =>
    css`
      fill: ${theme.fontColors[color]};
    `;

const popoverTrigger =
  ({ disabled }: { disabled?: boolean }) =>
  (theme: Theme) =>
    css`
      display: flex;
      ${disabled &&
      `
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
        `}
    `;

export const style = { popoverContent, popoverArrow, popoverTrigger };
