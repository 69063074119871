import { filerdApi } from 'src/api_services/filerd/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@filerd';

const checkEntry = createActionsFromMethod<typeof filerdApi.checkEntry>(`checkEntry(${namespace})`);

const getWebsiteEntries = createActionsFromMethod<typeof filerdApi.getWebsiteEntries>(
  `getWebsiteEntries(${namespace})`
);

const getEntryMetadata = createActionsFromMethod<typeof filerdApi.getEntryMetadata>(`getEntryMetadata(${namespace})`);

export const filerdActions = {
  checkEntry,
  getWebsiteEntries,
  getEntryMetadata,
};
