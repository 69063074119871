import { createReducer } from 'deox';

import * as actions from './actions';
import { SearchState } from './types';

const initialState: SearchState = {};

const MAX_SAVED_SEARCHES = 25;

export const searchReducer = createReducer(initialState, (handleAction) => [
  handleAction(actions.saveSearchToRecents, (state, { payload }) => ({
    ...state,
    [payload.key]: state[payload.key] ? [payload, ...state[payload.key].slice(0, MAX_SAVED_SEARCHES)] : [payload],
  })),
  handleAction(actions.clearRecentSearch, (state, { payload }) => ({
    ...state,
    [payload.key]: state[payload.key]
      ? state[payload.key].filter((searchItem) => searchItem.term !== payload.term)
      : [],
  })),
  handleAction(actions.clearRecentSearches, (state, { payload }) => ({
    ...state,
    [payload.key]: [],
  })),
]);
