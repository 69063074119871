import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';

const highlightOutline = (dimensions: HTMLSpanElement) => (theme: Theme) =>
  css`
    position: absolute;
    width: ${dimensions.offsetWidth + 8}px;
    height: ${dimensions.offsetHeight + 8}px;
    border: 2px solid ${theme.colors.fuschia};
    border-radius: 4px;
    margin: -4px;
    z-index: 1000;
  `;

const highlightId = (theme: Theme) => css`
  font-family: monospace;
  font-size: 12px;
  font-weight: normal;
  text-transform: lowercase;
  color: ${theme.fontColors.dark};
  position: absolute;
  background: ${theme.colors.fuschia};
  min-width: 100%;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  top: -2px;
  left: -2px;
`;

export const style = {
  highlightOutline,
  highlightId,
};
