import { useState } from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { useGetServersQuery } from 'src/api_services/servers/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { messages } from 'src/messages';
import { AppState } from 'src/store';
import { updatingServersSelectors } from 'src/store/servers/updatingServers/selectors';

import { ServerIdsFormFields, ServerSelect } from './ServerSelect';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  columns?: number;
}

const stateToProps = (state: AppState) => ({
  updatingServerIds: updatingServersSelectors.selectUpdatingServerIds(state),
});

export const _ServerSelectContainer = ({ columns, updatingServerIds }: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const form = useFormikContext<ServerIdsFormFields>();

  const { data: servers = [] } = useGetServersQuery({});

  return (
    <Box>
      <Button
        variant="tertiary"
        size="small"
        icon={collapsed ? 'chevron down' : 'chevron up'}
        iconPosition="right"
        onClick={() => setCollapsed(!collapsed)}
      >
        <FormattedMessage {...messages.server_placement} />
      </Button>

      {!collapsed && (
        <Box mt="lg">
          <Box mb="lg">
            <Text size="sm" marginBottom="lg" fullWidth>
              <FormattedMessage
                id="server_select_container.description"
                defaultMessage="By default all websites are placed according to your global server placement rules. Below you can overide the global server placement rules by selecting a server for placement. Each website service can be placed on a different server."
              />
            </Text>
          </Box>

          <ServerSelect servers={servers} form={form} updatingServerIds={updatingServerIds} columns={columns} />
        </Box>
      )}
    </Box>
  );
};

export const ServerSelectContainer = connect(stateToProps)(_ServerSelectContainer);
