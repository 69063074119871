import { Fragment, ReactNode, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Box } from 'src/components/Box';
import { Text } from 'src/components/Text';

import { BetaPill } from '../SquarePill/SquarePill';
import { style } from './LocaleSelectLabel.style';

export interface Props {
  beta: boolean;
  label: ReactNode;
  locale: string;
}

export const LocaleSelectLabel = ({ beta, label, locale }: Props) => {
  const flagCode = useMemo(() => {
    // Some flags need to be manually mapped.
    const flagMap: Record<string, string> = {
      en: 'us',
      sp: 'rs',
      cyrl: 'rs',
    };

    const code = locale.split('-')[1]?.toLowerCase() ?? locale;

    return code in flagMap ? flagMap[code] : code;
  }, [locale]);

  return (
    <Fragment>
      <Box d="row" a="center" role="option" gap="sm">
        <span className={`fi fi-${flagCode}`} css={style.flag}></span>

        <Text>{label}</Text>

        {beta && <BetaPill />}
      </Box>
    </Fragment>
  );
};
