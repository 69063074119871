import { FunctionComponent } from 'react';
import { ConnectedComponent, useDispatch } from 'react-redux';

import { modalActions } from 'src/store/modals';

export const useModal = <T>(Component: FunctionComponent<T> | ConnectedComponent<FunctionComponent<any>, T>) => {
  const dispatch = useDispatch();

  return (props: T) => {
    dispatch(modalActions.showModal({ Component, props }));
  };
};

export const useHideModal = () => {
  const dispatch = useDispatch();

  return () => {
    dispatch(modalActions.hideModal());
  };
};
