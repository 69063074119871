import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { plansApi } from 'src/api_services/plans/service';

import { createRequestStateSaga } from '../helpers';
import { plansActions } from './actions';

const [createPlanSaga] = createRequestStateSaga(plansActions.createPlan, plansApi.createPlan);
const [deletePlanSaga] = createRequestStateSaga(plansActions.deletePlan, plansApi.deletePlan);
const [getPlanSaga] = createRequestStateSaga(plansActions.getPlan, plansApi.getPlan);
const [getPlansSaga] = createRequestStateSaga(plansActions.getPlans, plansApi.getPlans);
const [createPlanAllowancesSaga] = createRequestStateSaga(
  plansActions.createPlanAllowances,
  plansApi.createPlanAllowances
);
const [createPlanResourcesSaga] = createRequestStateSaga(
  plansActions.createPlanResources,
  plansApi.createPlanResources
);
const [createPlanSelectionsSaga] = createRequestStateSaga(
  plansActions.createPlanSelections,
  plansApi.createPlanSelections
);
const [updatePlanSaga] = createRequestStateSaga(plansActions.updatePlan, plansApi.updatePlan);

const [updatePlanResourceSaga] = createRequestStateSaga(plansActions.updatePlanResource, plansApi.updatePlanResource);
const [updatePlanSelectionSaga] = createRequestStateSaga(
  plansActions.updatePlanSelection,
  plansApi.updatePlanSelection
);
const [deletePlanAllowanceSaga] = createRequestStateSaga(
  plansActions.deletePlanAllowance,
  plansApi.deletePlanAllowance
);

export function* plansSaga() {
  yield takeEvery(getType(plansActions.createPlan.request), createPlanSaga);
  yield takeEvery(getType(plansActions.deletePlan.request), deletePlanSaga);
  yield takeEvery(getType(plansActions.getPlan.request), getPlanSaga);
  yield takeEvery(getType(plansActions.getPlans.request), getPlansSaga);
  yield takeEvery(getType(plansActions.createPlanAllowances.request), createPlanAllowancesSaga);
  yield takeEvery(getType(plansActions.createPlanResources.request), createPlanResourcesSaga);
  yield takeEvery(getType(plansActions.createPlanSelections.request), createPlanSelectionsSaga);
  yield takeEvery(getType(plansActions.updatePlan.request), updatePlanSaga);
  yield takeEvery(getType(plansActions.updatePlanResource.request), updatePlanResourceSaga);
  yield takeEvery(getType(plansActions.updatePlanSelection.request), updatePlanSelectionSaga);
  yield takeEvery(getType(plansActions.deletePlanAllowance.request), deletePlanAllowanceSaga);
}
