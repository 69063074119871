import { AxiosError } from 'axios';

export const isUnauthorised = ({ response }: AxiosError) => {
  if (!response) {
    return false;
  }

  // Do not trigger logout on certain pages.
  if (['/setup', '/invites', '/login'].some((uri) => window.location.pathname.startsWith(uri))) {
    return false;
  }

  // Do not trigger for certain requests.
  if (['/api/login/sessions', '/api/login/2fa'].some((url) => response.config.url?.startsWith(url))) {
    return false;
  }

  // do not trigger for filerd (which uses a different base url so we can't use the above logic)
  if (response.config.baseURL?.startsWith('/filerd')) {
    return false;
  }

  return response.status === 401;
};
