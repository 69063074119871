import { css } from '@emotion/core';

import { padding } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';

import type { Props } from './CardFooter';

const container = css`
  margin-top: auto;
`;

const body = (props: Props) => (theme: Theme) =>
  css`
    ${padding({
      left: props.paddingX || props.padding,
      right: props.paddingX || props.padding,
      top: props.paddingY || props.padding,
      bottom: props.paddingY || props.padding,
    })(theme)}
  `;

export const style = { container, body };
