import { css } from '@emotion/core';

const linkTag = (fullWidth?: boolean) => () =>
  css`
    ${fullWidth &&
    `
    display: flex;
    flex: 1;
  `}
  `;

export const style = { linkTag };
