import { LoginMembership, Role } from 'src/orchd-client';
import { ActionType, getType } from 'deox';
import { put, select, takeLatest } from 'typed-redux-saga/macro';

import { requestActionSaga } from 'src/store/helpers';
import { sessionSelectors } from 'src/store/session/selectors';
import { getErrorInfo } from 'src/utils/errors';

import { brandingActions } from '../branding/actions';
import { orgsActions } from '../orgs/actions';
import { orgsSelectors } from '../orgs/selectors';
import { impersonationActions } from './actions';

export function* startImpersonatingSaga({ payload }: ActionType<typeof impersonationActions.startImpersonating>) {
  try {
    const { orgId } = payload;
    const member = yield* select(sessionSelectors.getMember);
    const masterOrgName = yield* select(orgsSelectors.selectOrgName);

    // fetch the org to be impersonated
    const {
      payload: { data: org },
    } = yield* requestActionSaga(orgsActions.getOrg, { orgId });

    // the branding of the user being impersonated must be fetched before impersonation starts
    // so the transition is seamless
    yield* requestActionSaga(brandingActions.getImpersonationBranding, { orgId });

    // build the new member
    const impersonation: LoginMembership = {
      ...member, // memberId remains the same
      orgId,
      orgName: org.name,
      isMasterOrg: false,
      // role during impersonation is always SuperAdmin (because you're using the permissions of the parent org)
      roles: [Role.SuperAdmin],
    };

    yield put(
      impersonationActions.startImpersonatingSuccess({
        parentId: org.parentId,
        orgId: payload.orgId,
        impersonation,
        masterOrgName,
      })
    );

    if (payload.onSuccess) {
      payload.onSuccess(impersonation);
    }
  } catch (error) {
    const { message } = getErrorInfo(error);
    yield put(impersonationActions.startImpersonatingError({ orgId: payload.orgId, error: message }));
    if (payload.onError) {
      payload.onError();
    }
  }
}

export function* stopImpersonatingSaga() {
  yield put(impersonationActions.stopImpersonatingSuccess());
}

export function* impersonationSaga() {
  yield takeLatest(getType(impersonationActions.startImpersonating), startImpersonatingSaga);
  yield takeLatest(getType(impersonationActions.stopImpersonating), stopImpersonatingSaga);
}
