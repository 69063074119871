import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { domainsActions } from './actions';

const getWebsiteDomainMappingReducer = createRequestStateReducer(domainsActions.getWebsiteDomainMapping);
const getWebsiteDomainMappingsReducer = createRequestStateReducer(domainsActions.getWebsiteDomainMappings);

export const domainsReducer = combineReducers({
  websiteDomainMapping: getWebsiteDomainMappingReducer,
  websiteDomainMappings: getWebsiteDomainMappingsReducer,
});
