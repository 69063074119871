/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { ColorOverrides, Colors, Responsive, Sizes } from 'src/design-system/style-types';

import styles from './Loading.style';

export interface DefaultProps {
  className: string;
  fullWidth: boolean;
  size: Sizes;
  color: Responsive<Colors | ColorOverrides>;
  top?: Sizes;
  bottom?: Sizes;
  absoluteCenter?: boolean;
  height: string;
}

const defaultProps: DefaultProps = {
  className: '',
  fullWidth: true,
  size: '2xl',
  color: 'accent',
  height: 'auto',
};

const Loading = (props: DefaultProps) => {
  const { className } = props;
  return (
    <div role="alert" aria-label="loading" data-qa="loadingSpinner" className={className} css={styles.container(props)}>
      <div css={styles.loading(props)}>
        {[1, 2, 3, 4].map((num) => (
          <div key={num} />
        ))}
      </div>
    </div>
  );
};

Loading.defaultProps = defaultProps;

export default Loading;
