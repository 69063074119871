import { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';

import { AppState } from 'src/store';
import {
  isBelowDesktop,
  isBelowLargeDesktop,
  isBelowTablet,
  isDesktop,
  isDesktopUp,
  isLargeDesktop,
  isLargeDesktopUp,
  isMobile,
  isMobileUp,
  isTablet,
  isTabletUp,
} from 'src/utils/breakpointHelpers';

import { viewportSelectors } from './_redux/selectors';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  children?: ReactNode;
  mobileOnly?: boolean;
  mobileUp?: boolean;
  tabletOnly?: boolean;
  tabletUp?: boolean;
  belowTablet?: boolean;
  desktopOnly?: boolean;
  desktopUp?: boolean;
  belowDesktop?: boolean;
  bigDesktopOnly?: boolean;
  bigDesktopUp?: boolean;
  belowBigDesktop?: boolean;
}

export const _Viewport = ({
  viewportWidth,
  children,
  mobileOnly = false,
  mobileUp = false,
  tabletOnly = false,
  tabletUp = false,
  belowTablet = false,
  desktopOnly = false,
  desktopUp = false,
  belowDesktop = false,
  bigDesktopOnly = false,
  bigDesktopUp = false,
  belowBigDesktop = false,
}: Props) => {
  if (
    (mobileOnly && isMobile(viewportWidth)) ||
    (mobileUp && isMobileUp(viewportWidth)) ||
    (tabletOnly && isTablet(viewportWidth)) ||
    (tabletUp && isTabletUp(viewportWidth)) ||
    (belowTablet && isBelowTablet(viewportWidth)) ||
    (desktopOnly && isDesktop(viewportWidth)) ||
    (desktopUp && isDesktopUp(viewportWidth)) ||
    (belowDesktop && isBelowDesktop(viewportWidth)) ||
    (bigDesktopOnly && isLargeDesktop(viewportWidth)) ||
    (bigDesktopUp && isLargeDesktopUp(viewportWidth)) ||
    (belowBigDesktop && isBelowLargeDesktop(viewportWidth))
  ) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

const stateToProps = (state: AppState) => ({
  viewportWidth: viewportSelectors.selectWidth(state),
});

export const Viewport = connect(stateToProps)(_Viewport);
