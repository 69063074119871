import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { settingsApi } from 'src/api_services/settings/service';

import { createRequestStateSaga } from '../helpers';
import { settingsActions } from './actions';

const [getSettingSaga] = createRequestStateSaga(settingsActions.getSetting, settingsApi.getSetting);
const [getSettingsSaga] = createRequestStateSaga(settingsActions.getSettings, settingsApi.getSettings);
const [createSettingsSaga] = createRequestStateSaga(settingsActions.createSettings, settingsApi.createSettings);
const [deleteSettingSaga] = createRequestStateSaga(settingsActions.deleteSetting, settingsApi.deleteSetting);
const [updateSettingSaga] = createRequestStateSaga(settingsActions.updateSetting, settingsApi.updateSetting);
const [getGlobalServiceSettingSaga] = createRequestStateSaga(
  settingsActions.getGlobalServiceSetting,
  settingsApi.getGlobalServiceSetting
);
const [setGlobalServiceSettingSaga] = createRequestStateSaga(
  settingsActions.setGlobalServiceSetting,
  settingsApi.setGlobalServiceSetting
);

export function* settingsSaga() {
  yield takeEvery(getType(settingsActions.getSetting.request), getSettingSaga);
  yield takeEvery(getType(settingsActions.getSettings.request), getSettingsSaga);
  yield takeEvery(getType(settingsActions.createSettings.request), createSettingsSaga);
  yield takeEvery(getType(settingsActions.deleteSetting.request), deleteSettingSaga);
  yield takeEvery(getType(settingsActions.updateSetting.request), updateSettingSaga);
  yield takeEvery(getType(settingsActions.setGlobalServiceSetting.request), setGlobalServiceSettingSaga);
  yield takeEvery(getType(settingsActions.getGlobalServiceSetting.request), getGlobalServiceSettingSaga);
}
