import { css } from '@emotion/core';

import { hiding, leftString, rightString } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './Box';

const style = (props: Props) => (theme: Theme) => {
  return css`
    display: flex;
    ${(typeof props.b !== 'undefined' ||
      typeof props.bx !== 'undefined' ||
      typeof props.by !== 'undefined' ||
      typeof props.bt !== 'undefined' ||
      typeof props.br !== 'undefined' ||
      typeof props.bb !== 'undefined' ||
      typeof props.bl !== 'undefined') &&
    css`
      ${props.bc && `border-color: ${theme.colors[props.bc]};`}
      border-style: solid;
      border-width: 0;
    `}

    /* Hiding */
    ${props.hide && hiding(props.hide, 'flex')}
    /* Flex wrap */
    ${responsive(
      props.wrap,
      (wrap) => css`
        flex-wrap: ${wrap};
      `
    )}
    /* Flex wrap row gap */
    ${props.rowGap &&
    responsive(
      props.rowGap,
      (rowGap) => css`
        row-gap: ${theme.margins[rowGap]}px;
      `
    )}
    /* Gap */
    ${props.gap &&
    responsive(
      props.gap,
      (gap) => css`
        gap: ${theme.margins[gap]}px;
      `
    )}
    /* Flex shrink */
    ${typeof props.shrink === 'number' &&
    responsive(
      props.shrink,
      (shrink) => css`
        flex-shrink: ${shrink};
      `
    )}
    /* Width */
    ${responsive(props.w, (w) => {
      const styles = [];

      if (typeof w === 'undefined') {
        return [];
      }

      if (typeof w === 'string') {
        styles.push(css`
          width: ${w};
        `);
      } else if (props.g) {
        styles.push(
          responsive(props.g, (g) => {
            if (w < 1) {
              return css`
                width: calc(${w * 100}% + ${theme.gutters[g]}px);
              `;
            }
            return css`
              width: calc(${(w / 12) * 100}% + ${theme.gutters[g]}px);
            `;
          })
        );
      } else if (w < 1) {
        styles.push(css`
          width: ${w * 100}%;
        `);
      } else {
        styles.push(css`
          width: ${(w / 12) * 100}%;
        `);
      }

      return styles;
    })}

    /* Gutter */
    ${props.g &&
    responsive(
      props.g,
      (g) => css`
        margin-left: -${theme.margins[g] / 2}px;
        margin-right: -${theme.margins[g] / 2}px;
      `
    )}
    ${props.gcpx &&
    responsive(
      props.gcpx,
      (gcpx) => css`
        padding-left: ${theme.paddings[gcpx] / 2}px;
        padding-right: ${theme.paddings[gcpx] / 2}px;
      `
    )}

    /* Flex Direction */
    ${responsive(
      props.d,
      (d) => css`
        flex-direction: ${d};
      `
    )}
    /* Flex Justify Content */
    ${responsive(
      props.j,
      (j) => css`
        justify-content: ${j};
      `
    )}
    /* Flex Align Items */
    ${responsive(
      props.a,
      (a) => css`
        align-items: ${a};
      `
    )}
    /* Flex Align Self */
    ${responsive(
      props.alignSelf,
      (alignSelf) => css`
        align-self: ${alignSelf};
      `
    )}
    /* Flex Grow */
    ${typeof props.grow !== 'undefined' &&
    responsive(
      props.grow,
      (grow) => css`
        flex-grow: ${grow};
      `
    )}

    /* Margins */
    ${props.m &&
    responsive(
      props.m,
      (m) => css`
        margin: ${m === 'auto' ? 'auto' : `${theme.margins[m]}px`};
      `
    )}
    ${props.mx &&
    responsive(
      props.mx,
      (mx) => css`
        margin-left: ${mx === 'auto' ? 'auto' : `${theme.margins[mx]}px`};
        margin-right: ${mx === 'auto' ? 'auto' : `${theme.margins[mx]}px`};
      `
    )}
    ${props.my &&
    responsive(
      props.my,
      (my) => css`
        margin-top: ${my === 'auto' ? 'auto' : `${theme.margins[my]}px`};
        margin-bottom: ${my === 'auto' ? 'auto' : `${theme.margins[my]}px`};
      `
    )}
    ${props.mt &&
    responsive(
      props.mt,
      (mt) => css`
        margin-top: ${mt === 'auto' ? 'auto' : `${theme.margins[mt]}px`};
      `
    )}
    ${props.mr &&
    responsive(
      props.mr,
      (mr) => css`
          margin-${rightString(theme)}: ${mr === 'auto' ? 'auto' : `${theme.margins[mr]}px`};
        `
    )}
    ${props.mb &&
    responsive(
      props.mb,
      (mb) => css`
        margin-bottom: ${mb === 'auto' ? 'auto' : `${theme.margins[mb]}px`};
      `
    )}
    ${props.ml &&
    responsive(
      props.ml,
      (ml) => css`
          margin-${leftString(theme)}: ${ml === 'auto' ? 'auto' : `${theme.margins[ml]}px`};
        `
    )}

    /* Padding */
    ${props.p &&
    responsive(
      props.p,
      (p) => css`
        padding: ${theme.paddings[p]}px;
      `
    )}
    ${props.px &&
    responsive(
      props.px,
      (px) => css`
        padding-left: ${theme.paddings[px]}px;
        padding-right: ${theme.paddings[px]}px;
      `
    )}
    ${props.py &&
    responsive(
      props.py,
      (py) => css`
        padding-top: ${theme.paddings[py]}px;
        padding-bottom: ${theme.paddings[py]}px;
      `
    )}
    ${props.pt &&
    responsive(
      props.pt,
      (pt) => css`
        padding-top: ${theme.paddings[pt]}px;
      `
    )}
    ${props.pr &&
    responsive(
      props.pr,
      (pr) => css`
          padding-${rightString(theme)}: ${theme.paddings[pr]}px;
        `
    )}
    ${props.pb &&
    responsive(
      props.pb,
      (pb) => css`
        padding-bottom: ${theme.paddings[pb]}px;
      `
    )}
    ${props.pl &&
    responsive(
      props.pl,
      (pl) => css`
          padding-${leftString(theme)}: ${theme.paddings[pl]}px;
        `
    )}

      /* Border */
    ${typeof props.b !== 'undefined' &&
    responsive(
      props.b,
      (b) => css`
        border-width: ${b}px;
      `
    )}
    ${typeof props.bx !== 'undefined' &&
    responsive(
      props.bx,
      (bx) => css`
        border-left-width: ${bx}px;
        border-right-width: ${bx}px;
      `
    )}
    ${typeof props.by !== 'undefined' &&
    responsive(
      props.by,
      (by) => css`
        border-top-width: ${by}px;
        border-bottom-width: ${by}px;
      `
    )}
    ${typeof props.bt !== 'undefined' &&
    responsive(
      props.bt,
      (bt) => css`
        border-top-width: ${bt}px;
      `
    )}
    ${typeof props.br !== 'undefined' &&
    responsive(
      props.br,
      (br) => css`
          border-${rightString(theme)}-width: ${br}px;
        `
    )}
    ${typeof props.bb !== 'undefined' &&
    responsive(
      props.bb,
      (bb) => css`
        border-bottom-width: ${bb}px;
      `
    )}
    ${typeof props.bl !== 'undefined' &&
    responsive(
      props.bl,
      (bl) => css`
          border-${leftString(theme)}-width: ${bl}px;
        `
    )}

    /* Order */
    ${props.o &&
    responsive(
      props.o,
      (o) => css`
        order: ${o};
      `
    )}

    /* Position */
    ${props.position &&
    responsive(
      props.position,
      (position) => css`
        position: ${position};
      `
    )}

    /* Z index */
    ${typeof props.zIndex !== 'undefined' &&
    responsive(
      props.zIndex,
      (zIndex) => css`
        z-index: ${zIndex};
      `
    )}

    /* Border radius */
    ${typeof props.radius !== 'undefined' &&
    responsive(
      props.radius,
      (radius) => css`
        border-radius: ${typeof radius === 'string' ? radius : `${radius}px`};
      `
    )}

    /* Overflow */
    ${props.overflow &&
    responsive(
      props.overflow,
      (overflow) => css`
        overflow: ${overflow};
      `
    )}

    /* Position top */
    ${props.top &&
    responsive(
      props.top,
      (top) => css`
        top: ${typeof top === 'number' ? top : theme.margins[top]}px;
      `
    )}

    /* Position right */
    ${props.right &&
    responsive(
      props.right,
      (rightVal) => css`
        ${rightString(theme)}: ${typeof rightVal === 'number' ? rightVal : theme.margins[rightVal]}px;
      `
    )}

    /* Position bottom */
    ${props.bottom &&
    responsive(
      props.bottom,
      (bottom) => css`
        bottom: ${typeof bottom === 'number' ? bottom : theme.margins[bottom]}px;
      `
    )}



    /* Position left */
    ${props.left &&
    responsive(
      props.left,
      (left) => css`
        left: ${typeof left === 'number' ? left : theme.margins[left]}px;
      `
    )}

    /* Min width */
    ${props.minW &&
    responsive(
      props.minW,
      (minW) => css`
        min-width: ${minW};
      `
    )}

    /* Max width */
    ${props.maxW &&
    responsive(
      props.maxW,
      (maxW) => css`
        max-width: ${maxW};
      `
    )}

    /* Min height */
    ${props.minH &&
    responsive(
      props.minH,
      (minH) => css`
        min-height: ${minH};
      `
    )}

    /* Max height */
    ${props.maxH &&
    responsive(
      props.maxH,
      (maxH) => css`
        max-height: ${maxH};
      `
    )}

    /* Background */
    ${props.bg &&
    responsive(
      props.bg,
      (bg) => css`
        background: ${theme.colors[bg]};
      `
    )}

    /* Height */
    ${props.h &&
    responsive(
      props.h,
      (h) => css`
        height: ${h};
      `
    )}

    /* onClick */
    ${props.onClick &&
    css`
      cursor: pointer;
    `}

    /* transparent */
    ${props.transparent &&
    css`
      opacity: 0;
    `}

    /* opacity */
    ${typeof props.opacity !== 'undefined' &&
    responsive(
      props.opacity,
      (opacity) => css`
        opacity: ${opacity};
      `
    )}
  `;
};

export default style;
