import { css } from '@emotion/core';

import * as mixins from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

import type { ButtonSizes, Props } from './Button';

const redStyle = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;
    ${mixins.shadowBorder('error')(theme)}

    ${!props.loading &&
    css`
      :hover {
        background-color: ${theme.colors.error};
      }

      :disabled {
        background-color: ${theme.colors.transparent};
        ${mixins.shadowBorder('grey20')(theme)}
      }
    `}
  `;

const successStyle = (props: Props) => (theme: Theme) =>
  css`
    box-shadow: none;
    background-color: ${theme.colors.success};

    ${!props.loading &&
    css`
      :hover {
        background-color: ${theme.colors.successB25};
      }
    `}

    ${props.disabled &&
    css`
      :disabled {
        background-color: ${theme.colors.grey10};
      }
    `}
  `;

const errorStyle = (props: Props) => (theme: Theme) =>
  css`
    box-shadow: none;
    background-color: ${theme.colors.error};

    ${!props.loading &&
    css`
      :hover {
        background-color: ${theme.colors.errorB25};
      }
    `}

    ${props.disabled &&
    css`
      :disabled {
        background-color: ${theme.colors.grey10};
      }
    `}
  `;

const tertiaryStyle = (props: Props) => (theme: Theme) =>
  css`
    background-color: transparent;
    border-radius: 0;
    height: auto;
    padding: 0;
    box-shadow: none;

    :hover {
      position: relative;
      box-shadow: none;

      :before {
        content: '';
        ${mixins.shadowBorderBottom('secondaryButtonColor')(theme)}

        ${props.borderColorHover &&
        responsive(
          props.borderColorHover,
          (borderColorHover) => borderColorHover && mixins.shadowBorderBottom(borderColorHover)(theme)
        )}

      width: 100%;
        position: absolute;
        height: 1px;
        bottom: -${theme.margins.xs}px;
        left: 0;
      }

      ${(props.disabled || props.loading) &&
      css`
        :before {
          display: none;
        }
      `}
    }
  `;

const secondaryStyle = (props: Props) => (theme: Theme) =>
  css`
    ${mixins.shadowBorder('secondaryButtonColor')(theme)}
    ${props.borderColor &&
    responsive(props.borderColor, (borderColor) => borderColor && mixins.shadowBorder(borderColor)(theme))}

    background-color: transparent;
    color: ${mixins.getColorFromTheme('secondaryButtonColor', theme)};

    ${!props.loading &&
    !props.forceNoHoverState &&
    css`
      :hover {
        background-color: ${mixins.getColorFromTheme('secondaryButtonColor', theme)};
      }

      :disabled {
        background-color: ${theme.colors.transparent};
        ${mixins.shadowBorder('grey20')(theme)}
      }
    `}
  `;

const primaryStyle = (props: Props, size?: ButtonSizes) => (theme: Theme) =>
  css`
    box-shadow: none;

    ${size === 'normal' && [
      css`
        background-color: ${theme.colors.accent};
      `,

      !props.loading &&
        css`
          :hover {
            background-color: ${theme.colors.accentB25};
          }
        `,

      props.disabled &&
        css`
          :disabled {
            background-color: ${theme.colors.grey10};
          }
        `,
    ]}

    ${(size === 'small' || size === 'tiny') && [
      css`
        background-color: ${theme.colorOverrides.secondaryButtonColor};
      `,

      !props.loading &&
        css`
          :hover {
            background-color: ${theme.colors.grey70};
          }
        `,

      props.disabled &&
        css`
          :disabled {
            background-color: ${theme.colors.grey10};
          }
        `,
    ]}

  ${props.backgroundColor &&
    responsive(
      props.backgroundColor,
      (backgroundColor) =>
        backgroundColor &&
        css`
          background-color: ${theme.colors[backgroundColor]};
        `
    )}
  `;

const roundStyle = (props: Props) => (theme: Theme) =>
  css`
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${responsive(props.size, (size) => [
      size === 'tiny' &&
        css`
          padding: ${theme.sizes.xs}px;
        `,

      size === 'small' &&
        css`
          width: ${theme.sizes['2xl']}px;
        `,

      size === 'normal' &&
        css`
          width: ${theme.sizes['3xl']}px;
        `,
    ])}
  `;

const hoverStyle = (props: Props) => (theme: Theme) =>
  css`
    ${props.borderColor &&
    responsive(props.borderColor, (borderColor) => borderColor && mixins.shadowBorder(borderColor)(theme))}

    ${!props.loading &&
    !props.disabled &&
    css`
      :hover {
        ${props.borderColorHover &&
        responsive(
          props.borderColorHover,
          (borderColorHover) => borderColorHover && mixins.shadowBorder(borderColorHover)(theme)
        )}

        ${props.backgroundColorHover &&
        responsive(
          props.backgroundColorHover,
          (backgroundColorHover) =>
            backgroundColorHover &&
            css`
              background-color: ${theme.colors[backgroundColorHover]};
            `
        )}
      }
    `}
  `;

const childHoverStyle = (props: Props) => (theme: Theme) =>
  css`
    ${responsive(props.variant, (variant) => [
      !props.loading &&
        !props.disabled &&
        css`
          :hover {
            ${(variant === 'primary' || variant === 'red') &&
            css`
              .ui-text-hoverable {
                color: ${theme.fontColors.light};
              }

              .ui-svg-hoverable {
                color: ${theme.fontColors.light};
                fill: ${theme.fontColors.light};
              }
            `}

            ${variant === 'secondary' &&
            css`
              .ui-text-hoverable {
                color: ${theme.colors.light};
              }

              .ui-svg-hoverable {
                color: ${theme.colors.light};
                fill: ${theme.colors.light};
              }
            `}

            ${props.colorHover &&
            responsive(
              props.colorHover,
              (colorHover) =>
                colorHover &&
                css`
                  .ui-text-hoverable {
                    color: ${theme.fontColors[colorHover]};
                  }

                  .ui-svg-hoverable {
                    color: ${theme.fontColors[colorHover]};
                    fill: ${theme.fontColors[colorHover]};
                  }
                `
            )}
          }
        `,
      props.disabled &&
        css`
          .ui-text-hoverable {
            color: ${theme.fontColors.disabled};
          }

          .ui-svg-hoverable {
            color: ${theme.fontColors.disabled};
            fill: ${theme.fontColors.disabled};
          }
        `,
    ])}
  `;

const buttonBorderRadius = (props: Props) => (theme: Theme) => {
  const { buttonBorderRadius } = theme;

  let borderRadiusValue: string = buttonBorderRadius;

  if (props.isLeftButton) {
    if (theme.settings.rtl) {
      borderRadiusValue = `0 ${buttonBorderRadius} ${buttonBorderRadius} 0`;
    } else {
      borderRadiusValue = `${buttonBorderRadius} 0 0 ${buttonBorderRadius}`;
    }
  }

  if (props.isRightButton) {
    if (theme.settings.rtl) {
      borderRadiusValue = `${buttonBorderRadius} 0 0 ${buttonBorderRadius}`;
    } else {
      borderRadiusValue = `0 ${buttonBorderRadius} ${buttonBorderRadius} 0`;
    }
  }

  if (props.isCenterButton) {
    borderRadiusValue = `0`;
  }

  return css`
    border-radius: ${borderRadiusValue};
  `;
};

const container = (props: Props) => (theme: Theme) =>
  css`
    &:focus-visible {
      outline: blue auto 1px;
    }

    ${!props.isRightButton &&
    !props.isCenterButton &&
    !props.isLeftButton &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
    position: relative;
    border: none;
    ${buttonBorderRadius(props)(theme)}
    font-family: 'Noto Sans', sans-serif;
    white-space: nowrap;
    font-size: ${theme.fontSizes.md}px;
    z-index: ${zIndex.One};

    ${props.faded &&
    css`
      opacity: 0.6;
    `};

    ${(props.isRightButton || props.isCenterButton) &&
    !theme.settings.rtl &&
    css`
      margin-left: -1px;
    `}

    ${(props.isLeftButton || props.isCenterButton) &&
    theme.settings.rtl &&
    css`
      margin-left: -1px;
    `}

${props.disabled &&
    props.isLeftButton &&
    css`
      z-index: ${zIndex.Zero};
    `};

    ${props.disabled &&
    props.isRightButton &&
    css`
      z-index: ${zIndex.Zero};
    `};

    ${mixins.margin({
      left: props.marginLeft,
      bottom: props.marginBottom,
      right: props.marginRight,
      top: props.marginTop,
    })(theme)}

    ${!props.disabled &&
    !props.loading &&
    css`
      cursor: pointer;
    `};

    ${responsive(
      props.width,
      (width) =>
        css`
          width: ${width};
        `
    )}

    ${responsive(props.size, (size) =>
      responsive(props.variant, (variant) => [
        size === 'small' &&
          css`
            height: ${theme.sizes['2xl']}px;
            padding: 0 ${theme.sizes.lg}px;
          `,

        size === 'normal' &&
          css`
            height: ${theme.sizes['3xl']}px;
            padding: 0 ${theme.sizes['2xl']}px;
          `,

        size === 'tiny' &&
          css`
            padding: 2px ${theme.sizes.md}px;
          `,

        variant === 'red' && redStyle(props)(theme),
        variant === 'success' && successStyle(props)(theme),
        variant === 'error' && errorStyle(props)(theme),
        variant === 'tertiary' && tertiaryStyle(props)(theme),
        variant === 'secondary' && secondaryStyle(props)(theme),
        variant === 'primary' && primaryStyle(props, size)(theme),
        variant !== 'tertiary' && hoverStyle(props)(theme),
      ])
    )}

  ${props.error &&
    css`
      background-color: ${theme.colors.error};
    `}

  ${props.round && roundStyle(props)(theme)}

  ${!props.forceNoHoverState && childHoverStyle(props)(theme)}
  `;

const loading = css`
  position: absolute;
  height: 16px;
  width: 16px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const style = { container, loading };
