/** @jsxRuntime classic */
/** @jsx jsx */
import { ComponentType, ReactNode } from 'react';
import { jsx } from '@emotion/core';
import isFunction from 'lodash/isFunction';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { PasswordValidationResults } from 'src/components/PasswordInput/PasswordValidation';
import { Text } from 'src/components/Text';
import { Margins, Responsive } from 'src/design-system/style-types';

interface FormattedMessageType {
  type: ComponentType<unknown>;
}

type ChildrenRenderProp = ({
  err,
  touched,
  disabled,
}: {
  err: string | boolean;
  touched: undefined | boolean;
  disabled: undefined | boolean;
}) => ReactNode;

interface Props {
  children: ReactNode | ChildrenRenderProp;
  className?: string;
  disabled?: undefined | boolean;
  err?: undefined | string | FormattedMessageType | PasswordValidationResults | boolean;
  constraint?: ReactNode;
  /** Help text - don't use more than 80 chars */
  help?: ReactNode;
  label?: ReactNode;
  name: string;
  marginBottom?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  requiredIndicator?: boolean | ReactNode;
  touched?: undefined | boolean;
  role?: 'listbox' | 'textbox' | 'combobox';
}

const FormField = ({
  children,
  className = '',
  disabled = undefined,
  err = undefined,
  constraint = undefined,
  help = undefined,
  label = undefined,
  marginBottom = '0',
  marginRight = '0',
  name,
  requiredIndicator = undefined,
  touched = false,
  role = undefined,
}: Props) => {
  const labelColor = disabled ? 'disabled' : 'grey';
  const isFormattedMessage = typeof err === 'object' && err.type === FormattedMessage;
  const isStringOrFormattedMessage = typeof err === 'string' || isFormattedMessage;

  const showErrorMessage = touched && err && isStringOrFormattedMessage;
  const textBelowField = constraint || showErrorMessage;

  const textMb = (() => {
    if (!label) {
      return '0';
    }
    return help ? 'xs' : 'sm';
  })();

  return (
    <Box d="column" mb={marginBottom} mr={marginRight} className={className} role={role}>
      {label && (
        <Text id={name} color={labelColor} tag="label" marginBottom={textMb} htmlFor={name} size="sm" fullWidth>
          {label}
          {requiredIndicator === true ? '*' : requiredIndicator}
        </Text>
      )}
      {help && (
        <Text color={labelColor} tag="span" marginBottom="sm" italic size="sm">
          {help}
        </Text>
      )}
      <Box wrap="nowrap" mb={textBelowField ? 'xs' : '0'} a="center">
        {isFunction(children) ? children({ err: !!err, touched, disabled }) : children}
      </Box>

      {constraint && !showErrorMessage && (
        <Text tag="span" size="sm" data-qa="formConstraint">
          {constraint}
        </Text>
      )}
      {showErrorMessage && (
        <Text id={`err-${name}`} color="error" tag="span" size="sm" data-qa="formError">
          {err as ReactNode}
        </Text>
      )}
    </Box>
  );
};

export default FormField;
