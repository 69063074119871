import { MigrationStatus } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { DateTimeConfig } from 'src/utils/intl/getMessages/getMessages';

import { queryKeys } from '../query-keys';
import { migrationsApi } from './service';

export const useGetMigrationsQuery = ({
  offset = 0,
  limit,
  sortOrder = 'desc',
  sortBy = 'createdAt',
  searchDomain,
  migrationStatus,
  sessionId,
  refetchInterval,
  enabled = true,
}: {
  offset?: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: 'websitePrimaryDomain' | 'websiteId' | 'createdAt' | 'lastUpdated';
  searchDomain?: string;
  migrationStatus?: MigrationStatus;
  sessionId?: string;
  refetchInterval?: number | ((data: any) => number | false);
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.migrations({ offset, limit, sortOrder, sortBy, searchDomain, migrationStatus, sessionId }),
    queryFn: () =>
      migrationsApi.getMigrations({ offset, limit, sortOrder, sortBy, searchDomain, migrationStatus, sessionId }),
    refetchOnMount: true,
    keepPreviousData: true,
    refetchInterval,
    select: (data) => data.data,
    enabled,
  });
};

export const useGetMigrationSessionsQuery = ({
  offset = 0,
  limit,
  sortOrder = 'desc',
  sortBy = 'createdAt',
  searchDomain,
  createdBy,
  refetchInterval,
  enabled = true,
}: {
  offset?: number;
  limit?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: 'createdAt' | 'updatedAt';
  searchDomain?: string;
  createdBy?: string;
  refetchInterval?: number | ((data: any) => number | false);
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.migrationSessions({ offset, limit, sortOrder, sortBy, createdBy, searchDomain }),
    queryFn: () => migrationsApi.getMigrationSessions({ offset, limit, sortOrder, sortBy, createdBy, searchDomain }),
    refetchOnMount: true,
    keepPreviousData: true,
    refetchInterval,
    select: (data) => data.data.items,
    enabled,
  });
};

export const useGetMigrationLogQuery = ({
  migrationId,
  dateTimeConfig,
  enabled,
}: {
  migrationId: string;
  dateTimeConfig: DateTimeConfig;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.migrationLog({ migrationId }),
    queryFn: () => migrationsApi.getMigrationLog({ migrationId }),
    select: (data) => {
      const log = data.data.reduce<string>((carry, item) => {
        const timestamp = dayjs(item.createdAt);

        return `[${timestamp.format(`${dateTimeConfig.time}:ss`)} ${timestamp.format(dateTimeConfig.date)}] ${
          item.logData
        }\n${carry}`;
      }, '');
      return log;
    },
    enabled,
  });
};

export const useGetMigrationQuery = ({
  refetchInterval,
  enabled = false,
  ...params
}: {
  enabled: boolean;
  migrationId: string;
  refetchInterval?: number | ((data: any) => number | false);
}) => {
  return useQuery({
    queryKey: queryKeys.migration(params),
    queryFn: () => migrationsApi.getMigration(params, { timeout: 1000 }),
    refetchInterval,
    select: (data) => data.data,
    enabled,
  });
};

export type MigrationStatusCounts = {
  queuedCount: number;
  inProgressCount: number;
  failedCount: number;
};

/**
 * @deprecated (replaced with useCreateMigrationSessionMutation)
 */
export const useCreateMigrationMutation = () => useMutation({ mutationFn: migrationsApi.createMigration });

export const useCreateMigrationSessionMutation = () =>
  useMutation({ mutationFn: migrationsApi.createMigrationSession });
