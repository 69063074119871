import { ReactNode, Ref } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';

import { ErrorFocus } from '../ErrorFocus';
import { style } from './Form.style';
import { NestedElement, NestedForm } from './NestedForm';

export type FormProps =
  | 'errors'
  | 'handleBlur'
  | 'handleChange'
  | 'handleSubmit'
  | 'submitForm'
  | 'isSubmitting'
  | 'resetForm'
  | 'setFieldTouched'
  | 'setFieldValue'
  | 'setFieldError'
  | 'setStatus'
  | 'status'
  | 'touched'
  | 'values'
  | 'isValid'
  | 'validateOnBlur'
  | 'validateOnChange'
  | 'dirty'
  | 'initialValues';

export type FormType<T extends FormikValues> = Pick<FormikProps<T>, FormProps>;

export interface Props<T extends FormikValues> {
  initialValues: T;
  validationSchema: Yup.AnySchema<unknown>;
  onSubmit: (values: T, form: FormikHelpers<T>) => void;
  children: (form: FormType<T>) => ReactNode;
  formikRef?: Ref<FormikProps<T>>;

  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  enableReinitialize?: boolean;
  fullWidth?: boolean;
  fullFlex?: boolean;
  validateOnMount?: boolean;
  scrollToError?: boolean;
  nested?: boolean;
  nestedEl?: NestedElement;
  dataDemoDisabled?: boolean;
}

export const Form = <T extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
  validateOnBlur,
  validateOnChange,
  enableReinitialize = false,
  fullWidth = true,
  fullFlex = false,
  validateOnMount = false,
  scrollToError = true,
  nested = false,
  nestedEl,
  formikRef,
  dataDemoDisabled,
}: Props<T>) => {
  return (
    <Formik<T>
      innerRef={formikRef as (instance: any) => void}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      validateOnMount={validateOnMount}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        submitForm,
        isSubmitting,
        resetForm,
        setFieldTouched,
        setFieldValue,
        setStatus,
        setFieldError,
        status,
        touched,
        values,
        isValid,
        dirty,
        initialValues,
      }) => {
        const finalChildren = children({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          submitForm,
          isSubmitting,
          resetForm,
          setFieldTouched,
          setFieldValue,
          setFieldError,
          setStatus,
          status,
          touched,
          values,
          isValid,
          dirty,
          initialValues,
        });

        if (nested) {
          return (
            <NestedForm
              data-qa="nested-form"
              onSubmit={handleSubmit}
              nestedEl={nestedEl}
              dataDemoDisabled={dataDemoDisabled}
              fullWidth={fullWidth}
              fullFlex={fullFlex}
            >
              {finalChildren}
            </NestedForm>
          );
        }

        return (
          <form
            onSubmit={handleSubmit}
            css={style.formContainer(fullWidth, fullFlex)}
            data-demo-disabled={dataDemoDisabled}
          >
            {scrollToError && <ErrorFocus />}
            {finalChildren}
          </form>
        );
      }}
    </Formik>
  );
};
