import { wordPressApi } from 'src/api_services/wordpress/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@wordPress';

const updateWordPressSettings = createActionsFromMethod<typeof wordPressApi.updateWordpressSettings>(
  `updateWordPressSettings(${namespace})`
);

const updateWordPressAppVersion = createActionsFromMethod<typeof wordPressApi.updateWordpressAppVersion>(
  `updateWordPressAppVersion(${namespace})`
);

const updateWordPressPluginToLatest = createActionsFromMethod<typeof wordPressApi.updateWordpressPluginToLatest>(
  `updateWordPressPluginToLatest(${namespace})`
);

const deleteWordPressPlugin = createActionsFromMethod<typeof wordPressApi.deleteWordpressPlugin>(
  `deleteWordPressPlugin(${namespace})`
);

const deleteWordPressUser = createActionsFromMethod<typeof wordPressApi.deleteWordpressUser>(
  `deleteWordPressUser(${namespace})`
);

const updateWordPressUser = createActionsFromMethod<typeof wordPressApi.updateWordpressUser>(
  `updateWordPressUser(${namespace})`
);

const createWordPressUser = createActionsFromMethod<typeof wordPressApi.createWordpressUser>(
  `createWordPressUser(${namespace})`
);

const updateWordPressPluginSettings = createActionsFromMethod<typeof wordPressApi.updateWordpressPluginSettings>(
  `updateWordPressPluginSettings(${namespace})`
);

const installWordPressPlugin = createActionsFromMethod<typeof wordPressApi.installWordpressPlugin>(
  `installWordpressPlugin(${namespace})`
);

export const wordPressActions = {
  updateWordPressAppVersion,
  updateWordPressPluginToLatest,
  deleteWordPressPlugin,
  deleteWordPressUser,
  updateWordPressUser,
  createWordPressUser,
  updateWordPressPluginSettings,
  updateWordPressSettings,
  installWordPressPlugin, // only one currently in use
};
