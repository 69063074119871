import { QueryKey, useQueryClient } from '@tanstack/react-query';

export const useEagerUpdate = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  const initialState: any = queryClient.getQueryData(queryKey);

  const update = async (newData: any, specifyingKey?: object) => {
    const key = [queryKey[0]];

    if (queryKey[1] || specifyingKey) {
      key.push({ ...((queryKey[1] as any) ?? {}), ...(specifyingKey ?? {}) });
    }

    await queryClient.cancelQueries({ queryKey: key });
    await queryClient.setQueryData(key, { ...initialState, data: newData });
  };

  const revert = async (specifyingKey?: object) => {
    const key = [queryKey[0]];

    if (queryKey[1] || specifyingKey) {
      key.push({ ...((queryKey[1] as any) ?? {}), ...(specifyingKey ?? {}) });
    }

    await queryClient.setQueryData(key, { ...initialState });
  };

  return { update, revert };
};
