import { OrgsApi, OrgsApiGetEmailsReq } from 'src/orchd-client';

import { axiosInstance } from 'src/utils/axios';

import { GetOrgMembersReq, GetOrgReq } from './types';

export const orgsApi = OrgsApi({}, '/api', axiosInstance);

export const extendedOrgsApi = {
  ...orgsApi,
  getOrg: ({ orgId, options }: GetOrgReq) => orgsApi.getOrg({ orgId }, options),
  getMembers: ({ options, ...params }: GetOrgMembersReq) => orgsApi.getMembers(params, options),
  getOrgEmails: (params: OrgsApiGetEmailsReq) => orgsApi.getEmails(params),
};
