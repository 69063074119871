import React, { Fragment, KeyboardEvent, ReactNode, UIEvent } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { style } from '../Form.style';

export type NestedElement = ({
  onKeyDown,
  onClick,
  children,
}: {
  onKeyDown: (e: KeyboardEvent) => void;
  onClick: (event: UIEvent) => void;
  children: ReactNode;
}) => ReactNode;

interface Props {
  onSubmit: (e: any) => void;
  dataDemoDisabled?: boolean;
  children?: ReactNode;
  nestedEl?: NestedElement;
  fullWidth?: boolean;
  fullFlex?: boolean;
}

const NestedForm = ({ onSubmit, nestedEl, dataDemoDisabled, children, fullWidth = true, fullFlex = false }: Props) => {
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if ((e.target as HTMLElement).matches('input') || (e.target as HTMLElement).matches('button[type="submit"]')) {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(e);
      }
    } else if (e.key === 'Space') {
      checkForSubmitButton(e);
    }
  };

  const checkForSubmitButton = (e: UIEvent) => {
    if ((e.target as HTMLElement).closest('button[type="submit"]')) {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(e);
    }
  };

  if (nestedEl) {
    return (
      <Fragment>
        {nestedEl({
          onKeyDown: onKeyDown,
          onClick: checkForSubmitButton,
          children,
        })}
      </Fragment>
    );
  }

  return (
    <div
      data-demo-disabled={dataDemoDisabled}
      onKeyDown={onKeyDown}
      onClick={checkForSubmitButton}
      role="form"
      children={children}
      css={style.formContainer(fullWidth, fullFlex)}
    />
  );
};

export { NestedForm };
