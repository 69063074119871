import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MysqlKind, WebserverKind } from 'src/orchd-client';

import { ReactComponent as MariaDb } from 'src/images/databases/mariadb.svg';
import { ReactComponent as Mysql } from 'src/images/databases/mysql.svg';
import { ReactComponent as Apache } from 'src/images/webservers/apache.svg';
import { ReactComponent as LiteSpeed } from 'src/images/webservers/litespeed.svg';
import { ReactComponent as Nginx } from 'src/images/webservers/nginx.svg';
import { ReactComponent as OpenLiteSpeed } from 'src/images/webservers/openlitespeed.svg';

import { style } from './ServiceKindImage.style';

export interface Props {
  kind: WebserverKind | MysqlKind;
}

export const ServiceKindImage = ({ kind }: Props) => {
  const images = {
    [WebserverKind.apache]: <Apache />,
    [WebserverKind.dummyWebServer]: <Apache />,
    [WebserverKind.liteSpeed]: <LiteSpeed />,
    [WebserverKind.openLiteSpeed]: <OpenLiteSpeed />,
    [WebserverKind.nginx]: <Nginx />,
    [MysqlKind.mariaDb11]: <MariaDb css={style.mardiaDbIcon} />,
    [MysqlKind.mariaDbLts]: <MariaDb css={style.mardiaDbIcon} />,
    [MysqlKind.mysql80]: <Mysql />,
    [MysqlKind.mysql81]: <Mysql />,
  };

  return (
    <div aria-label={kind} title={kind} css={style.container}>
      {images[kind]}
    </div>
  );
};
