import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { customersActions } from './actions';

export const customersReducer = combineReducers({
  customers: createRequestStateReducer(customersActions.getCustomers),
  customer: createRequestStateReducer(customersActions.getCustomer),
  customerSubscriptions: createRequestStateReducer(customersActions.getCustomerSubscriptions),
  customerWebsites: createRequestStateReducer(customersActions.getCustomerWebsites),
});
