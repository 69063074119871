import { ImportMigrationEntryStatusEnum } from 'src/orchd-client';
import { useMutation, useQuery } from '@tanstack/react-query';

import { queryKeys } from '../query-keys';
import { importersApi } from './service';
import { migrationAnalysisHasCompleted } from './utils';

export const useUploadImportMigrationMutation = ({
  orgId,
  onUploadProgress,
  importMigrationKind,
}: {
  orgId: string;
  onUploadProgress: (progressEvent: ProgressEvent) => void;
  importMigrationKind: 'cpanel' | 'plesk';
}) => {
  return useMutation({
    mutationKey: queryKeys.uploadImportMigration({ orgId }),
    mutationFn: ({ backup }: { backup: File }) => {
      return importersApi.uploadImportMigration({ importMigrationKind, orgId, backup }, { onUploadProgress });
    },
  });
};

export const useGetImportMigrationsQuery = ({
  orgId,
  status = undefined,
  refetchInterval = undefined,
  enabled = true,
}: {
  orgId: string;
  status?: ImportMigrationEntryStatusEnum[];
  refetchInterval?: number | ((data: any) => number | false);
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getImportMigrations({ orgId }),
    queryFn: () => importersApi.getImportMigrations({ orgId }),
    refetchOnMount: true,
    refetchInterval,
    select: (data) => data.data.items.filter((item) => !status || status.includes(item.status)),
    enabled,
  });
};

export const useGetImportMigrationLogQuery = ({
  orgId,
  importMigrationId,
  poll = undefined,
  enabled = false,
}: {
  orgId: string;
  importMigrationId: string;
  poll?: number;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getImportMigrationLog({ orgId, importMigrationId }),
    queryFn: () => importersApi.getImportMigrationLog({ orgId, importMigrationId }),
    refetchOnMount: true,
    refetchInterval: poll,
    select: (data) => data.data,
    enabled,
  });
};

export const useGetImportMigrationPollingQuery = ({
  orgId,
  importMigrationId,
  enabled = true,
}: {
  orgId: string;
  importMigrationId: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getImportMigration({ orgId, importMigrationId }),
    queryFn: () => importersApi.getImportMigration({ orgId, importMigrationId }),
    refetchOnMount: true,
    refetchInterval: (data: any) => {
      return migrationAnalysisHasCompleted(data?.status) ? false : 2500;
    },
    select: (data) => data.data,
    enabled,
  });
};

export const useDeleteImportMigrationMutation = ({ orgId }: { orgId: string }) => {
  return useMutation({
    mutationKey: queryKeys.deleteImportMigration({ orgId }),
    mutationFn: ({ importMigrationId }: { importMigrationId: string }) => {
      return importersApi.deleteImportMigration({ orgId, importMigrationId });
    },
  });
};

export const useAnalyzeImportMigrationMutation = ({ orgId }: { orgId: string }) => {
  return useMutation({
    mutationKey: queryKeys.analyzeImportMigration({ orgId }),
    mutationFn: ({ importMigrationId }: { importMigrationId: string }) => {
      return importersApi.analyzeImportMigration({ orgId, importMigrationId });
    },
  });
};

export const useGetImportMigrationDataQuery = ({
  orgId,
  importMigrationId,
  enabled = true,
}: {
  orgId: string;
  importMigrationId: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.getImportMigrationData({ orgId, importMigrationId }),
    queryFn: () => importersApi.getImportMigrationData({ orgId, importMigrationId }),
    refetchOnMount: true,
    select: (data) => data.data,
    enabled,
  });
};

export const useCheckImportMigrationResourcesQuery = ({
  orgId,
  importMigrationId,
  subscriptionId,
  enabled = true,
}: {
  orgId: string;
  importMigrationId: string;
  subscriptionId?: number;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.checkImportMigrationResources({ orgId, importMigrationId, subscriptionId }),
    queryFn: () =>
      importersApi.checkImportMigrationResources({
        orgId,
        importMigrationId,
        importerMigrationReqBody: { subscriptionId },
      }),
    refetchOnMount: true,
    select: (data) => data.data,
    enabled,
    keepPreviousData: true,
  });
};

export const useCreateImportMigrationMutation = ({ orgId }: { orgId: string }) => {
  return useMutation({
    mutationKey: queryKeys.createImportMigration({ orgId }),
    mutationFn: ({
      importMigrationId,
      subscriptionId,
      appServerId,
      backupServerId,
      dbServerId,
      emailServerId,
    }: {
      importMigrationId: string;
      subscriptionId?: number;
      appServerId?: string;
      backupServerId?: string;
      dbServerId?: string;
      emailServerId?: string;
    }) => {
      return importersApi.createImportMigration({
        orgId,
        importMigrationId,
        importerMigrationReqBody: { subscriptionId, appServerId, backupServerId, dbServerId, emailServerId },
      });
    },
  });
};

export const useScanImportMigrationsMutation = () => useMutation({ mutationFn: importersApi.scanImportMigrations });

export const useListImportServerSettingsQuery = (
  params: Parameters<typeof importersApi.listImportServerSettings>[0]
) => {
  return useQuery({
    queryKey: queryKeys.listImportServerSettings(params),
    queryFn: () => importersApi.listImportServerSettings(params),
    select: (data) => {
      return data.data.items;
    },
    refetchOnMount: true,
  });
};

export const useCreateImportServerSettingsMutation = () =>
  useMutation({ mutationFn: importersApi.createImportServerSettings });

export const useGetImportServerPullDomainsMutation = () =>
  useMutation({ mutationFn: importersApi.getImportServerPullDomains });

export const useGetImportServerDomainsCachedQuery = (
  params: Parameters<typeof importersApi.getImportServerDomainsCached>[0] & { enabled: boolean }
) => {
  return useQuery({
    queryKey: queryKeys.getImportServerDomainsCached(params),
    queryFn: () => importersApi.getImportServerDomainsCached(params),
    select: (data) => data.data,
    enabled: params.enabled,
    keepPreviousData: true,
    refetchOnMount: true,
  });
};

export const useTransferCPanelUserAccountMutation = () =>
  useMutation({ mutationFn: importersApi.transferCPanelUserAccount });

export const useTransferPleskDomainMutation = () => useMutation({ mutationFn: importersApi.transferPleskDomain });

export const useDeleteImportServerSettingsMutation = () =>
  useMutation({ mutationFn: importersApi.deleteImportServerSettings });
