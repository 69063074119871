import { PhpVersion } from 'src/orchd-client';

export interface PhpVersionOption {
  value: string;
  label: string;
  latest: boolean;
}

export const phpVersionToLabelMap = {
  php84: '8.4' as const,
  php83: '8.3' as const,
  php82: '8.2' as const,
  php81: '8.1' as const,
  php80: '8.0' as const,
  php74: '7.4' as const,
  php73: '7.3' as const,
  php72: '7.2' as const,
  php71: '7.1' as const,
  php70: '7.0' as const,
  php56: '5.6' as const,
};

export type PhpVersionLabel = (typeof phpVersionToLabelMap)[keyof typeof phpVersionToLabelMap];

export const availablePhpVersionsObj: Record<PhpVersion, { value: PhpVersion; label: string; latest: boolean }> = {
  php84: { value: PhpVersion.php84, label: phpVersionToLabelMap.php84, latest: false },
  php83: { value: PhpVersion.php83, label: phpVersionToLabelMap.php83, latest: false },
  php82: { value: PhpVersion.php82, label: phpVersionToLabelMap.php82, latest: false },
  php81: { value: PhpVersion.php81, label: phpVersionToLabelMap.php81, latest: false },
  php80: { value: PhpVersion.php80, label: phpVersionToLabelMap.php80, latest: false },
  php74: { value: PhpVersion.php74, label: phpVersionToLabelMap.php74, latest: false },
  php73: { value: PhpVersion.php73, label: phpVersionToLabelMap.php73, latest: false },
  php72: { value: PhpVersion.php72, label: phpVersionToLabelMap.php72, latest: false },
  php71: { value: PhpVersion.php71, label: phpVersionToLabelMap.php71, latest: false },
  php70: { value: PhpVersion.php70, label: phpVersionToLabelMap.php70, latest: false },
  php56: { value: PhpVersion.php56, label: phpVersionToLabelMap.php56, latest: false },
};

export const availablePhpVersions: readonly PhpVersionOption[] = Object.values(availablePhpVersionsObj);

export const availablePhpVersionsValues: PhpVersion[] = Object.keys(availablePhpVersionsObj) as PhpVersion[];

export const latestPhpVersion = availablePhpVersions.find((item) => item.latest)?.value as PhpVersion;
export const defaultPhpVersion = PhpVersion.php81;
