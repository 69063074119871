import { FunctionComponent } from 'react';
import { createActionCreator } from 'deox';
import { ConnectedComponent } from 'react-redux';

const showModal = createActionCreator(
  'showModal',
  (resolve) =>
    <T>(payload: {
      Component: FunctionComponent<T> | ConnectedComponent<FunctionComponent<any>, T> | null;
      props: T;
    }) => {
      return resolve(payload);
    }
);

const hideModal = createActionCreator('hideModal', (resolve) => () => {
  return resolve();
});

/**
 * @deprecated
 */
export const modalActions = {
  showModal,
  hideModal,
};
