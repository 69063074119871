import { plansApi } from 'src/api_services/plans/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = `@plans`;

const createPlan = createActionsFromMethod<typeof plansApi.createPlan>(`createPlan(${namespace})`);
const deletePlan = createActionsFromMethod<typeof plansApi.deletePlan>(`deletePlan(${namespace})`);
const getPlan = createActionsFromMethod<typeof plansApi.getPlan>(`getPlan(${namespace})`);
const getPlans = createActionsFromMethod<typeof plansApi.getPlans>(`getPlans(${namespace})`);
const createPlanAllowances = createActionsFromMethod<typeof plansApi.createPlanAllowances>(
  `createPlanAllowances(${namespace})`
);
const createPlanResources = createActionsFromMethod<typeof plansApi.createPlanResources>(
  `createPlanResources(${namespace})`
);

const createPlanSelections = createActionsFromMethod<typeof plansApi.createPlanSelections>(
  `createPlanSelections(${namespace})`
);
const updatePlan = createActionsFromMethod<typeof plansApi.updatePlan>(`updatePlan(${namespace})`);

const updatePlanResource = createActionsFromMethod<typeof plansApi.updatePlanResource>(
  `updatePlanResource(${namespace})`
);
const updatePlanSelection = createActionsFromMethod<typeof plansApi.updatePlanSelection>(
  `updatePlanSelection(${namespace})`
);
const deletePlanAllowance = createActionsFromMethod<typeof plansApi.deletePlanAllowance>(
  `deletePlanAllowance(${namespace})`
);

export const plansActions = {
  createPlan,
  deletePlan,
  getPlan,
  getPlans,
  createPlanAllowances,
  createPlanResources,
  createPlanSelections,
  updatePlan,
  updatePlanResource,
  updatePlanSelection,
  deletePlanAllowance,
};
