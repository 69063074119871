import { ServerInfoBrief } from 'src/orchd-client';

import { AppState } from '../';

const groupServers = (servers?: ServerInfoBrief[]) => {
  if (!servers) {
    return undefined;
  }

  return servers.reduce<Record<string, ServerInfoBrief[]>>((carry, item) => {
    const groupId = item.groupId ?? 'ungrouped';

    carry[groupId] === undefined ? (carry[groupId] = [item]) : carry[groupId].push(item);

    return carry;
  }, {});
};

const serversState = (state: AppState) => state.servers.servers;
const servers = (state: AppState) => state.servers.servers.data;
const serversItems = (state: AppState) => state.servers.servers.data?.items || [];
const isServersLoading = (state: AppState) => state.servers.servers.loading;
const serversError = (state: AppState) => state.servers.servers.error;

const groupedServersState = (state: AppState) => state.servers.groupedServers;
const groupedServers = (state: AppState) => groupServers(state.servers.groupedServers.data?.items);
const isGroupedServersLoading = (state: AppState) => state.servers.groupedServers.loading;
const groupedServersError = (state: AppState) => state.servers.groupedServers.error;

const serverInfoState = (state: AppState) => state.servers.serverInfo;
const serverInfo = (state: AppState) => state.servers.serverInfo.data;
const isServerInfoLoading = (state: AppState) => state.servers.serverInfo.loading;
const serverInfoError = (state: AppState) => state.servers.serverInfo.error;

const installCmdState = (state: AppState) => state.servers.installCmd;
const installCmd = (state: AppState) => state.servers.installCmd.data;
const isInstallCmdLoading = (state: AppState) => state.servers.installCmd.loading;
const installCmdError = (state: AppState) => state.servers.installCmd.error;

const serviceSetting = (state: AppState) => state.servers.serviceSetting.data;
const serviceSettingLoading = (state: AppState) => state.servers.serviceSetting.loading;

const serverRoles = (state: AppState) => state.servers.serverRoles.data;
const serverRolesLoading = (state: AppState) => state.servers.serverRoles.loading;

const allServerStatuses = (state: AppState) => state.servers.allServerStatuses.serverStatuses;
const selectTotalServers = (state: AppState) => state.servers.servers.data?.total || 0;

const webserverKindState = (state: AppState) => state.servers.webserverKind;
const webserverKind = (state: AppState) => state.servers.webserverKind.data;
const webserverKindLoading = (state: AppState) => state.servers.webserverKind.loading;
const webserverKindError = (state: AppState) => state.servers.webserverKind.error;

export const serversSelectors = {
  allServerStatuses,
  groupedServers,
  groupedServersError,
  groupedServersState,
  installCmd,
  installCmdError,
  installCmdState,
  isGroupedServersLoading,
  isInstallCmdLoading,
  isServerInfoLoading,
  isServersLoading,
  selectTotalServers,
  serverInfo,
  serverInfoError,
  serverInfoState,
  serverRoles,
  serverRolesLoading,
  servers,
  serversError,
  serversItems,
  serversState,
  serviceSetting,
  serviceSettingLoading,
  webserverKindState,
  webserverKind,
  webserverKindLoading,
  webserverKindError,
};
