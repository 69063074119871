import { memo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Provider as RadixToastProvider, Viewport } from '@radix-ui/react-toast';
import { connect } from 'react-redux';

import { Toast } from 'src/components/Toast/Toast';
import { AppState } from 'src/store';
import { loginsSelectors } from 'src/store/logins/selectors';
import { getLocaleTextDirection } from 'src/utils/i18n/getLocaleTextDirection';

import { style } from './ToastHub.style';

type StateProps = ReturnType<typeof stateToProps>;

interface Props extends StateProps {
  //
}

export const _ToastHub = ({ toasts, locale }: Props) => {
  const isRtl = getLocaleTextDirection(locale) === 'rtl';

  return (
    <RadixToastProvider swipeDirection={isRtl ? 'right' : 'left'}>
      {toasts.map(({ id, message, ...toast }) => (
        <Toast key={id} id={id} {...toast}>
          {message}
        </Toast>
      ))}

      <Viewport css={style.toastViewport} />
    </RadixToastProvider>
  );
};

export const stateToProps = (state: AppState) => ({
  toasts: state.toasts.toasts,
  locale: loginsSelectors.selectLocale(state),
});

export const ToastHub = connect(stateToProps)(memo(_ToastHub));
