/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/core';

import { boxShadowGrey } from 'src/design-system/mixins';
import { Theme } from 'src/design-system/theme';
import { zIndex } from 'src/utils/zIndex';

const toastContainerStyles = css`
  position: fixed;
  padding: 20px;
  z-index: ${zIndex.OrchdStatusToast};
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const toastAnimatedDivStyles = (theme: Theme) => css`
  overflow: hidden;
  ${boxShadowGrey(2)}
  border-radius: 30px;
  background-color: ${theme.colors.light};
  max-width: 450px;
  pointer-events: all;
`;

const toastCloseIconStyle = css`
  cursor: pointer;
`;

export const style = {
  toastContainerStyles,
  toastAnimatedDivStyles,
  toastCloseIconStyle,
};
