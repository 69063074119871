import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { installApi } from 'src/api_services/install/service';

import { createRequestStateSaga } from '../helpers';
import { installActions } from './actions';


const [getOrchdVersionSaga] = createRequestStateSaga(installActions.getOrchdVersion, installApi.orchdVersion);


export function* installSaga() {
  yield takeEvery(getType(installActions.getOrchdVersion.request), getOrchdVersionSaga);
}
