/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/core';

import { DropdownMenu } from 'src/components/DropdownMenu';
import { DropdownOption } from 'src/components/DropdownMenu/DropdownMenu';
import { IconButton } from 'src/components/IconButton';
import { Paddings, Responsive } from 'src/design-system/style-types';

import { style } from './CardBody.style';

interface RequiredProps {
  children: React.ReactNode;
}

interface DefaultProps {
  className: string;
  padding: Responsive<Paddings>;
  paddingX?: Responsive<Paddings>;
  paddingY?: Responsive<Paddings>;
  paddingTop?: Responsive<Paddings>;
  paddingRight?: Responsive<Paddings>;
  paddingBottom?: Responsive<Paddings>;
  paddingLeft?: Responsive<Paddings>;
  pageCardPadding: boolean;
  fullHeight: boolean;
  dropdownOptions: DropdownOption[];
  dropdownId: string;
  dropdownCentered: boolean;
}

export type Props = RequiredProps & DefaultProps;

const CardBody = (props: RequiredProps & DefaultProps) => {
  const { children, className, dropdownOptions, dropdownId } = props;

  return (
    <div className={className} css={style.container(props)}>
      {dropdownOptions.length > 0 && dropdownId && (
        <DropdownMenu
          forwarded
          button={
            <span css={style.dropdownIcon(props)}>
              <IconButton ariaLabel="dropdownMenuButton" icon="dot dot dot" size="md" variant="tertiary" />
            </span>
          }
          options={dropdownOptions}
        />
      )}

      {children}
    </div>
  );
};

CardBody.defaultProps = {
  className: '',
  padding: 'lg',
  fullHeight: false,
  pageCardPadding: false,
  dropdownOptions: [],
  dropdownId: '',
  dropdownCentered: false,
};

export default CardBody;
