import { createActionCreator } from 'deox';

const PREFIX = '@VIEWPORT';

export interface ViewportPayload {
  width: number;
  height: number;
}

export const updateViewport = createActionCreator(
  `${PREFIX}/UPDATE_VIEWPORT`,
  (resolve) => (payload: ViewportPayload) => resolve(payload)
);
