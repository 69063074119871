import React, { memo, ReactNode, useEffect, useState } from 'react';
import { DirectionProvider } from '@radix-ui/react-direction';
import { ThemeProvider } from 'emotion-theming';
import { connect } from 'react-redux';

import { LoadingOverlay } from 'src/components/LoadingOverlay';
import defaultTheme, { GenericTheme } from 'src/design-system/theme';
import { AppState } from 'src/store';
import { brandingActions } from 'src/store/branding/actions';
import { getThemeFromBranding } from 'src/store/branding/getThemeFromBranding';
import { useGetBrandingQuery } from 'src/store/branding/query';
import { brandingSelectors } from 'src/store/branding/selectors';
import { globalUiSelectors } from 'src/store/globalUi/selectors';
import { promisifyRequestAction } from 'src/store/helpers';
import { loginsSelectors } from 'src/store/logins/selectors';
import { sessionSelectors } from 'src/store/session/selectors';
import { getLocaleTextDirection } from 'src/utils/i18n/getLocaleTextDirection';

type ActionProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export interface Props extends ActionProps, StateProps {
  children?: ReactNode;
}

export const _ThemeGate = ({ branding, children, getBranding, locale, member, darkMode }: Props) => {
  const [fetchingComplete, setFetchingComplete] = useState(false);
  const [theme, setTheme] = useState<GenericTheme>(defaultTheme);
  const { orgId } = member ?? {};

  // early load of branding for orgName
  useGetBrandingQuery();

  const fetchBranding = async () => {
    setFetchingComplete(false);

    try {
      await getBranding({ orgId });
    } catch {
      // do nothing
    } finally {
      setFetchingComplete(true);
    }
  };

  useEffect(() => {
    fetchBranding();
  }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isRtlLocale = getLocaleTextDirection(locale) === 'rtl';
    const updatedTheme = getThemeFromBranding(branding, isRtlLocale, darkMode);
    setTheme(updatedTheme);
  }, [branding, locale, darkMode]);

  if (!fetchingComplete) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <LoadingOverlay alpha={0} darkMode={darkMode} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <DirectionProvider dir={theme.settings.rtl ? 'rtl' : 'ltr'}>{children}</DirectionProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  branding: brandingSelectors.selectBranding(state),
  locale: loginsSelectors.selectLocale(state),
  member: sessionSelectors.getMemberOrUndefined(state),
  darkMode: globalUiSelectors.darkMode(state),
});

export const mapDispatchToProps = {
  getBranding: promisifyRequestAction(brandingActions.getBranding.request),
};

export const ThemeGate = connect(mapStateToProps, mapDispatchToProps)(memo(_ThemeGate));
