import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { brandingApi } from 'src/api_services/branding/service';
import { BrandingConfig } from 'src/api_services/branding/types';
import { queryKeys } from 'src/api_services/query-keys';

import { AppState } from '../';
import { sessionSelectors } from '../session/selectors';
import { backwardsCompatibleBrandingHelper } from './selectors';

export const useGetBrandingQuery = () => {
  const member = useSelector((state: AppState) => sessionSelectors.getMemberOrUndefined(state));
  const { orgId = '' } = member ?? {};

  return useQuery({
    queryKey: queryKeys.branding({ orgId }),
    queryFn: () => brandingApi.getBranding({ orgId }),
    select: (data) => data.data,
    enabled: !!orgId,
    staleTime: 5000,
  });
};

export const useGetOrgName = () => {
  const { data: branding } = useGetBrandingQuery();
  const orgName = branding?.orgName ?? 'Enhance';
  return orgName;
};

export const useGetBrandingSettingQuery = ({
  orgId,
  name,
  enabled = true,
}: {
  orgId?: string;
  name: 'branding';
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: queryKeys.brandingSetting({ orgId, name }),
    queryFn: () => brandingApi.getBrandingSetting({ orgId: orgId!, name }),
    // NOTE must use this function when porting other branding APIs
    select: (data) => backwardsCompatibleBrandingHelper(data.data.value as BrandingConfig),
    refetchOnMount: true,
    enabled,
    staleTime: 5000,
  });
};

export const useCreateNameserverDomainMutation = () => useMutation({ mutationFn: brandingApi.createNameServerDomain });
export const useUpdateNameserverDomainMutation = () => useMutation({ mutationFn: brandingApi.updateNameServerDomain });
export const useDeleteNameserverDomainMutation = () => useMutation({ mutationFn: brandingApi.deleteNameServerDomain });
