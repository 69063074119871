/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Colors, Margins, Responsive } from 'src/design-system/style-types';

import { style } from './Avatar.style';

export interface Props {
  name?: string;
  size: 'xs' | 'sm' | 'md' | 'lg' | '5xl';
  src?: string;
  marginBottom?: Responsive<Margins>;
  marginRight?: Responsive<Margins>;
  marginLeft?: Responsive<Margins>;
  border?: Responsive<boolean | Colors>;
  shadow?: Responsive<boolean>;
  colorCode?: Colors;
  className?: string;
}

const getInitials = (name?: string) => {
  const names = name?.split(' ') ?? [''];
  let initials = names?.[0]?.substring(0, 1)?.toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const Avatar = ({
  name,
  size,
  src = '',
  marginBottom = '0',
  marginRight = '0',
  marginLeft = '0',
  border = false,
  shadow = false,
  colorCode,
  className,
}: Props) => {
  let initials = getInitials(name);

  if (size === 'xs' || size === 'sm') {
    initials = initials.charAt(0);
  }

  return (
    <div
      aria-label="accountAvatar"
      className={className}
      css={style.avatar({ name, size, src, marginBottom, marginRight, marginLeft, border, shadow, colorCode })}
    >
      <span>{!src && initials}</span>
    </div>
  );
};
