import { licenceApi } from 'src/api_services/licence/service';
import { createActionsFromMethod } from 'src/store/helpers';

const namespace = '@licence';

const getLicenceInfo = createActionsFromMethod<typeof licenceApi.getLicenceInfo>(`getLicenceInfo(${namespace})`);

const refreshLicence = createActionsFromMethod<typeof licenceApi.refreshLicence>(`refreshLicence(${namespace})`);

export const licenceActions = {
  getLicenceInfo,
  refreshLicence,
};
