/** @jsxRuntime classic */
/** @jsx jsx */

import { Fragment, ReactNode } from 'react';
import { jsx } from '@emotion/core';
import classNames from 'classnames';

import { FontAlign, FontColors, FontSizes, FontWeights, Margins, Responsive } from 'src/design-system/style-types';
import { stringMatchAll } from 'src/utils/helpers';

import style from './Text.style';

const headerTagsSizeMap: Record<string, FontSizes> = {
  h1: '3xl',
  h2: '2xl',
  h3: 'xl',
  h4: 'lg',
  h5: 'lg',
  h6: 'lg',
};

const getSizeValue = (tag: string, size: Responsive<FontSizes> | undefined): Responsive<FontSizes> =>
  size || headerTagsSizeMap[tag] || 'md';

interface RequiredProps {
  children: ReactNode;
}

export interface DefaultProps {
  id: string;
  className: string;
  tag: string;
  size?: Responsive<FontSizes>;
  weight: Responsive<FontWeights>;
  color: Responsive<FontColors>;
  align: Responsive<FontAlign>;
  verticalAlign?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'center' | 'stretch';
  italic: boolean;
  htmlFor: string;
  hasHoverClass: boolean;
  transform?: 'uppercase' | 'lowercase' | 'initial' | 'capitalize';
  innerRef: React.Ref<HTMLElement>;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  marginBottom: Responsive<Margins>;
  marginLeft: Responsive<Margins>;
  marginRight: Responsive<Margins>;
  marginTop: Responsive<Margins>;
  fullWidth: boolean;
  whiteSpace?: 'normal' | 'nowrap' | 'pre-line';
  textDecoration?: 'initial' | 'inherit' | 'line-through' | 'underline' | 'none';
  display?: Responsive<'initial' | 'inherit' | 'inline-block' | 'block' | 'flex' | 'none'>;
  block?: Responsive<boolean>;
  highlightText: string;
  ellipsis?: boolean;
  mono?: boolean;
  maxWidth?: Responsive<string>;
  wordBreak?: 'normal' | 'break-all' | 'break-word' | 'keep-all';
  title?: string;
  role?: string;
  ariaLabel?: string;
}

export type Props = RequiredProps & DefaultProps;

export const defaultProps: DefaultProps = {
  className: '',
  id: '',
  tag: 'p',
  weight: 'normal',
  color: 'dark',
  align: 'left',
  italic: false,
  htmlFor: '',
  hasHoverClass: false,
  innerRef: () => {},
  marginBottom: '0',
  marginLeft: '0',
  marginRight: '0',
  marginTop: '0',
  fullWidth: false,
  highlightText: '',
};

const getText = ({ children, highlightText }: RequiredProps & DefaultProps): ReactNode => {
  if (typeof children === 'string' && highlightText) {
    const chunks = stringMatchAll(children, highlightText);

    return chunks.map((chunk) => (
      <Fragment key={chunk.index}>
        {chunk.match ? (
          <span data-qa="highlight-text" css={style.highlightText}>
            {chunk.value}
          </span>
        ) : (
          chunk.value
        )}
      </Fragment>
    ));
  }

  return children;
};

const Text = (props: RequiredProps & DefaultProps) => {
  const { className, id, role, tag, size, htmlFor, hasHoverClass, innerRef, onMouseEnter, onMouseLeave, title } = props;

  const styleProps = {
    ...props,
    size: getSizeValue(tag, size),
  };

  const modifiedChildren = getText(props);

  return jsx(
    tag,
    {
      id,
      css: style.container(styleProps),
      'data-qa': 'text',
      ref: innerRef,
      role,
      className: classNames(className, { 'ui-text-hoverable': hasHoverClass }),
      size: styleProps.size,
      htmlFor,
      onMouseEnter,
      onMouseLeave,
      title,
      'aria-label': props.ariaLabel,
    },
    modifiedChildren
  );
};

Text.defaultProps = defaultProps;

export default Text;
