import { settingsApi } from 'src/api_services/settings/service';

import { createActionsFromMethod } from '../helpers';

const namespace = '@settings';

const getSetting = createActionsFromMethod<typeof settingsApi.getSetting>(`getSetting(${namespace})`);
const getSettings = createActionsFromMethod<typeof settingsApi.getSettings>(`getSettings(${namespace})`);
const createSettings = createActionsFromMethod<typeof settingsApi.createSettings>(`createSettings(${namespace})`);
const deleteSetting = createActionsFromMethod<typeof settingsApi.deleteSetting>(`deleteSetting(${namespace})`);
const updateSetting = createActionsFromMethod<typeof settingsApi.updateSetting>(`updateSetting(${namespace})`);
const setGlobalServiceSetting = createActionsFromMethod<typeof settingsApi.setGlobalServiceSetting>(
  `setGlobalServiceSetting(${namespace})`
);
const getGlobalServiceSetting = createActionsFromMethod<typeof settingsApi.getGlobalServiceSetting>(
  `getGlobalServiceSetting(${namespace})`
);

export const settingsActions = {
  getSetting,
  getSettings,
  createSettings,
  deleteSetting,
  updateSetting,
  setGlobalServiceSetting,
  getGlobalServiceSetting,
};
