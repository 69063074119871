/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment, ReactNode } from 'react';
import { jsx } from '@emotion/core';

import { Tooltip } from 'src/components/Tooltip';
import { Colors, Margins, Responsive } from 'src/design-system/style-types';
import { getByteString, getPercentage } from 'src/utils/helpers';

import { Box } from '../Box';
import { CollapseContent } from '../CollapseContent';
import { Text } from '../Text';
import { style } from './PercentageBar.style';

export interface PercentageBarItem {
  color?: Colors;
  amount?: number;
  title?: string | ReactNode;
  withPattern?: boolean;
  tooltipContent?: ReactNode;
  breakdownContent?: ReactNode;
}

interface RequiredProps {
  name: string;
  total?: number;
}

interface DefaultProps {
  marginBottom: Margins;
  bars: PercentageBarItem[] | undefined;
  singleBar: PercentageBarItem | undefined;
  showLegend: boolean;
  showBreakdown: boolean;
  height: Responsive<'normal' | 'small'>;
  legendDefaultCollapsed: boolean;
  variant: 'simple' | 'outlined';
  tooltipVariant: 'basic' | 'full';
  infinite: boolean;
}

export type Props = RequiredProps & DefaultProps;

const defaultProps: DefaultProps = {
  marginBottom: '0',
  bars: undefined,
  singleBar: undefined,
  showLegend: false,
  showBreakdown: false,
  height: 'normal',
  legendDefaultCollapsed: true,
  variant: 'outlined',
  tooltipVariant: 'basic',
  infinite: false,
};

const renderLegendItem = ({ color, amount, withPattern, tooltipContent, title }: PercentageBarItem, props: Props) => {
  const { total, tooltipVariant } = props;

  return (
    <Tooltip
      placement="bottom"
      variant={tooltipVariant}
      button={
        <div css={style.legendItemWrapper}>
          <div
            data-qa="legend-item"
            css={style.legendItem(props, {
              color: color || 'accent',
              percentage: getPercentage(amount, total),
              withPattern,
            })}
          />
          <Text size="xs">{title}</Text>
        </div>
      }
    >
      {tooltipContent || (
        <span>
          {title}: {getPercentage(amount, total)}%
        </span>
      )}
    </Tooltip>
  );
};

const PercentageBar = (props: Props) => {
  const { bars, singleBar, total, showLegend, showBreakdown, name, legendDefaultCollapsed, tooltipVariant, infinite } =
    props;

  return (
    <div css={style.container(props)}>
      <div css={style.outerBar(props)}>
        {bars &&
          bars.map(({ color, amount, withPattern, tooltipContent, title }, index) => (
            <Tooltip
              placement="bottom"
              variant={tooltipVariant}
              // eslint-disable-next-line react/no-array-index-key
              key={`bar-tooltip-${name}-${index}`}
              forwarded
              button={
                <div
                  data-qa="multi-bar"
                  css={style.bar({
                    ...props,
                    ...{
                      color,
                      percentage: getPercentage(amount, total, infinite),
                      withPattern,
                      index,
                      totalBars: bars.length,
                    },
                  })}
                />
              }
            >
              {tooltipContent || (
                <span>
                  {title}: {getByteString(amount)} ({getPercentage(amount, total)}%)
                </span>
              )}
            </Tooltip>
          ))}
        {singleBar && !isNaN(getPercentage(singleBar.amount, total)) && (
          <Tooltip
            data-test={singleBar.amount}
            placement="bottom"
            variant={tooltipVariant}
            forwarded
            button={
              <div
                data-qa="single-bar"
                css={style.bar({
                  ...props,
                  ...{
                    color: singleBar.color ? singleBar.color : 'accent',
                    percentage: getPercentage(singleBar.amount, total, infinite),
                  },
                })}
              />
            }
          >
            {singleBar.tooltipContent || <span>{getPercentage(singleBar.amount, total)}%</span>}
          </Tooltip>
        )}
      </div>
      {bars && showLegend && (
        <div css={style.legendWrapper}>
          {bars.map((barItem, index) => (
            <Fragment
              key={index} // eslint-disable-line react/no-array-index-key
            >
              {renderLegendItem(barItem, props)}
            </Fragment>
          ))}
        </div>
      )}
      {bars && showBreakdown && (
        <CollapseContent
          data-qa="breakdown"
          enableShowHideText={false}
          labelText="Breakdown"
          defaultCollapsed={legendDefaultCollapsed}
        >
          <Box d="column" mt="sm">
            {bars.map((barItem, index) => (
              <Box
                key={index} // eslint-disable-line react/no-array-index-key
              >
                {renderLegendItem(barItem, props)}
                {barItem.breakdownContent || (
                  <Text size="xs" color="grey">
                    {getPercentage(barItem.amount, total)}%
                  </Text>
                )}
              </Box>
            ))}
          </Box>
        </CollapseContent>
      )}
    </div>
  );
};

PercentageBar.defaultProps = defaultProps;

export default PercentageBar;
