import { getType } from 'deox';
import { takeEvery } from 'redux-saga/effects';

import { loginsApi } from 'src/api_services/logins/service';
import { createRequestStateSaga } from 'src/store/helpers';

import { loginsActions } from './actions';

export const [getLoginSaga] = createRequestStateSaga(loginsActions.getLogin, loginsApi.getLogin);
export const [getLoginsSaga] = createRequestStateSaga(loginsActions.getLogins, loginsApi.getLogins);
export const [getLoginMembershipsSaga] = createRequestStateSaga(
  loginsActions.getLoginMemberships,
  loginsApi.getLoginMemberships
);
export const [updateLoginSaga] = createRequestStateSaga(loginsActions.updateLoginInfo, loginsApi.updateLoginInfo);
export const [updateAvatarSaga] = createRequestStateSaga(loginsActions.updateAvatar, loginsApi.setLoginAvatar);
export const [deleteAvatarSaga] = createRequestStateSaga(loginsActions.deleteAvatar, loginsApi.deleteLoginAvatar);
export const [getSessionsSaga] = createRequestStateSaga(loginsActions.getSessions, loginsApi.getSessions);
export const [createSessionSaga] = createRequestStateSaga(loginsActions.createSession, loginsApi.createSession);
export const [deleteSessionsSaga] = createRequestStateSaga(loginsActions.deleteSessions, loginsApi.deleteSessions);
export const [deleteCurrentSessionSaga] = createRequestStateSaga(
  loginsActions.deleteCurrentSession,
  loginsApi.deleteCurrentSession
);
export const [startPasswordRecoverySaga] = createRequestStateSaga(
  loginsActions.startPasswordRecovery,
  loginsApi.startPasswordRecovery
);
export const [validatePasswordRecoverySaga] = createRequestStateSaga(
  loginsActions.validatePasswordRecovery,
  loginsApi.validatePasswordRecovery
);
export const [finishPasswordRecoverySaga] = createRequestStateSaga(
  loginsActions.finishPasswordRecovery,
  loginsApi.finishPasswordRecovery
);

export const [verify2FASaga] = createRequestStateSaga(loginsActions.verify2FA, loginsApi.verify2FA);

export function* loginsSaga() {
  yield takeEvery(getType(loginsActions.getLogin.request), getLoginSaga);
  yield takeEvery(getType(loginsActions.getLogins.request), getLoginsSaga);
  yield takeEvery(getType(loginsActions.getLoginMemberships.request), getLoginMembershipsSaga);
  yield takeEvery(getType(loginsActions.updateLoginInfo.request), updateLoginSaga);
  yield takeEvery(getType(loginsActions.updateAvatar.request), updateAvatarSaga);
  yield takeEvery(getType(loginsActions.deleteAvatar.request), deleteAvatarSaga);
  yield takeEvery(getType(loginsActions.getSessions.request), getSessionsSaga);
  yield takeEvery(getType(loginsActions.createSession.request), createSessionSaga);
  yield takeEvery(getType(loginsActions.deleteSessions.request), deleteSessionsSaga);
  yield takeEvery(getType(loginsActions.startPasswordRecovery.request), startPasswordRecoverySaga);
  yield takeEvery(getType(loginsActions.validatePasswordRecovery.request), validatePasswordRecoverySaga);
  yield takeEvery(getType(loginsActions.finishPasswordRecovery.request), finishPasswordRecoverySaga);
  yield takeEvery(getType(loginsActions.deleteCurrentSession.request), deleteCurrentSessionSaga);
  yield takeEvery(getType(loginsActions.verify2FA.request), verify2FASaga);
}
