import { Allowance, Plan, Resource, ResourceName, Selection, Subscription, UsedResource } from 'src/orchd-client';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';

import { PackageAllowanceName, PackageSelectionName } from 'src/utils/packages/types';

interface Item {
  name: string;
}

interface PlanOrSubscription {
  allowances: Item[];
  resources: Item[];
  selections: Item[];
}

export const getPlanAllowance = (plan: Plan | undefined, name: PackageAllowanceName) =>
  getItemHelper<Plan, Allowance>(plan, name, 'allowances');

export const getPlanResource = (plan: Plan | undefined, name: ResourceName) =>
  getItemHelper<Plan, Resource>(plan, name, 'resources');

export const getPlanSelection = (plan: Plan | undefined, name: PackageSelectionName) =>
  getItemHelper<Plan, Selection>(plan, name, 'selections');

export const getSubscriptionAllowance = (subscription: Subscription | undefined, name: PackageAllowanceName) =>
  getItemHelper<Subscription, Allowance>(subscription, name, 'allowances');

export const getSubscriptionResource = (subscription: Subscription | undefined, name: ResourceName) =>
  getItemHelper<Subscription, UsedResource>(subscription, name, 'resources');

export const getSubscriptionSelection = (subscription: Subscription | undefined, name: PackageSelectionName) =>
  getItemHelper<Subscription, Selection>(subscription, name, 'selections');

function getItemHelper<T extends PlanOrSubscription, K extends Item>(
  planOrSubscription: T | undefined,
  name: string,
  itemKind: 'allowances' | 'resources' | 'selections'
): K | undefined {
  if (!planOrSubscription) return undefined;

  const found = planOrSubscription[itemKind].find((item: Item) => item.name === name);

  if (found) {
    return found as K;
  }

  return undefined;
}

export const isUnlimitedResource = (resource: Resource | UsedResource | undefined) =>
  Boolean(resource?.name && !isNumber(resource.total));

// orchd sends `total` as `null` if the package resource has been configured to be unlimited
export const isUnlimitedResourceTotal = (total: number | undefined | null) =>
  typeof total === 'undefined' || isNull(total);

export const isResellerSubscription = (subscription?: Subscription) => {
  if (!subscription) {
    return false;
  }
  if (subscription.suspendedBy) {
    return false;
  }
  return isResellerSubscriptionIgnoreSuspended(subscription);
};

export const isResellerSubscriptionIgnoreSuspended = (subscription?: Subscription) => {
  if (!subscription) {
    return false;
  }

  const customers = getSubscriptionResource(subscription, ResourceName.customers);

  if (customers && typeof customers.total === 'number' && customers.total > 0) {
    return true;
  }

  return false;
};

export const isUnlimitedSelection = (selection: Selection | undefined) => selection?.value === '-1';

export const subscriptionHasResourcesAvailable = (
  subscription: Subscription | undefined,
  name: ResourceName
): boolean => {
  const resource = getSubscriptionResource(subscription, name);

  if (!resource) return false;

  if (isUnlimitedResource(resource)) return true;

  const { usage, total = 0 } = resource;

  return total - usage > 0;
};

export const getResourceInfo = (
  subscription: Subscription | undefined,
  name: ResourceName,
  isMasterOrg: boolean
): {
  totalAllowance: number | 'unlimited';
  usedAllowance: number;
  isResourceEmpty: boolean;
} => {
  const resource = getSubscriptionResource(subscription, name);
  const isUnlimited = isUnlimitedResource(resource);
  const totalAllowance = isUnlimited || isMasterOrg ? 'unlimited' : resource?.total ?? 0;
  const usedAllowance = resource?.usage ?? 0;
  const isResourceEmpty = totalAllowance !== 'unlimited' && usedAllowance >= totalAllowance;

  return {
    totalAllowance,
    usedAllowance,
    isResourceEmpty,
  };
};

export const subscriptionHasResource = (subscription: Subscription | undefined, name: ResourceName) => {
  const resource = getSubscriptionResource(subscription, name);

  if (!resource) return false;

  if (isUnlimitedResource(resource)) return true;

  const { total = 0 } = resource;

  return total > 0;
};
