import { Website } from 'src/orchd-client';
import { createActionCreator } from 'deox';

const namespace = '@moveServer';

const addMoveServerWebsites = createActionCreator(
  `addMoveServerWebsites(${namespace})`,
  (resolve) => (payload: { websites: Website[]; truncate?: boolean }) => resolve(payload)
);

const removeMoveServerWebsites = createActionCreator(
  `removeMoveServerWebsites(${namespace})`,
  (resolve) => (payload: { websites: Website[] }) => resolve(payload)
);

const toggleMoveServerWebsite = createActionCreator(
  `toggleMoveServerWebsite(${namespace})`,
  (resolve) => (payload: { website: Website }) => resolve(payload)
);

const clearMoveServers = createActionCreator(`clearMoveServers(${namespace})`);

export const moveServerActions = {
  clearMoveServers,
  toggleMoveServerWebsite,
  addMoveServerWebsites,
  removeMoveServerWebsites,
};
