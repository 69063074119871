import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Card, CardHeader } from 'src/components/Card';
import Divider from 'src/components/Divider';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { useMountEffect } from 'src/hooks/useMountEffect/useMountEffect';
import { useSearchParams } from 'src/hooks/useSearchParams/useSearchParams';
import { AppState } from 'src/store';
import { devtoolsActions } from 'src/store/devtools/actions';
import { devtoolsSelectors } from 'src/store/devtools/selectors';

import { DemoModeDevtools } from './DemoModeDevtools';
import { style } from './Devtools.style';
import { IntlDevtools } from './Intl/IntlDevtools';
import { ReactQueryDevtools } from './ReactQueryDevtools';

type ActionProps = typeof dispatchToProps;
type StateProps = ReturnType<typeof stateToProps>;

type Props = ActionProps & StateProps;

export const _Devtools = ({ isDevtoolsOpen, toggleDevtools }: Props) => {
  const query = useSearchParams();
  const [expanded, setExpanded] = useState(false);

  useMountEffect(() => {
    if (query.get('devtools') === '') {
      toggleDevtools({ open: true });
    }
  });

  if (!isDevtoolsOpen) {
    return null;
  }

  if (!expanded) {
    return (
      <Box css={style.devtoolsContainer}>
        <Box css={style.devtoolsToggle} onClick={() => setExpanded(true)}>
          <Card>
            <Box a="center" wrap="nowrap">
              <Icon name="settings" marginRight="sm" /> <Text size="sm">Devtools</Text>
            </Box>
          </Card>
        </Box>
      </Box>
    );
  }

  return (
    <Box css={style.devtoolsContainer}>
      <Box css={style.devtoolsExpanded}>
        <Card>
          <CardHeader onClick={() => setExpanded(false)}>
            <Box a="center" j="space-between">
              <Box a="center" wrap="nowrap" w="auto">
                <Icon name="settings" marginRight="sm" /> <Text size="sm">Devtools</Text>
              </Box>

              <Box w="auto">
                <Button variant="tertiary" color="error" size="small" onClick={() => toggleDevtools({ open: false })}>
                  Close
                </Button>
              </Box>
            </Box>
          </CardHeader>

          <IntlDevtools />

          <ReactQueryDevtools />

          <DemoModeDevtools />

          <Box px="lg" pb="lg">
            <Divider />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

const stateToProps = (state: AppState) => ({
  isDevtoolsOpen: devtoolsSelectors.isDevtoolsOpen(state),
});

export const dispatchToProps = {
  toggleDevtools: devtoolsActions.toggleDevtools,
};

export const Devtools = connect(stateToProps, dispatchToProps)(_Devtools);
