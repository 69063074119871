import { composeWithDevTools } from '@redux-devtools/extension';
import { routerMiddleware } from 'connected-react-router/immutable';
import merge from 'lodash/fp/merge';
import { applyMiddleware, createStore, DeepPartial } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { moveServerPersisterMiddleware } from 'src/pages/migrations/_redux/persister';
import { AppState } from 'src/store';
import { brandingImpersonationMiddleware } from 'src/store/branding/middleware';
import { brandingPersisterMiddleware } from 'src/store/branding/persister';
import { recentSearchesPersisterMiddleware } from 'src/store/global-search/persister';
import { globalUiPersisterMiddleware } from 'src/store/globalUi/persister';
import { promiseWithActionMiddleware } from 'src/store/helpers';
import { history } from 'src/store/history';
import { impersonationPersisterMiddleware } from 'src/store/impersonation';
import { notificationsPersisterMiddleware } from 'src/store/notifications/persister';
import rootReducer from 'src/store/rootReducer';
import rootSaga from 'src/store/rootSaga';
import { searchPersisterMiddleware } from 'src/store/searches';
import { sessionPersisterMiddleware } from 'src/store/session';
import { uiPersisterMiddleware } from 'src/store/ui/persister';
import { actionSanitizer } from 'src/utils/store/actionSanitizer/actionSanitizer';
import { stateSanitizer } from 'src/utils/store/stateSanitizer/stateSanitizer';

import { devtoolsPersisterMiddleware } from './devtools/persister';
import { tableSettingsPersisterMiddleware } from './table-settings/persister';

export const configureStore = (initialState: DeepPartial<AppState> = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers = composeWithDevTools({
    actionsDenylist: ['@@router/LOCATION_CHANGE', 'REMOVE_POPPER', 'ADD_POPPER'], // prevents common actions clogging up the log
    actionSanitizer,
    stateSanitizer,
    maxAge: 20,
    latency: 1000,
  });

  const middleware = [
    sessionPersisterMiddleware,
    brandingImpersonationMiddleware,
    promiseWithActionMiddleware, // must come before saga middleware
    sagaMiddleware,
    routerMiddleware(history),
    impersonationPersisterMiddleware,
    brandingPersisterMiddleware,
    searchPersisterMiddleware,
    moveServerPersisterMiddleware,
    recentSearchesPersisterMiddleware,
    uiPersisterMiddleware,
    globalUiPersisterMiddleware,
    notificationsPersisterMiddleware,
    devtoolsPersisterMiddleware,
    tableSettingsPersisterMiddleware,
  ];

  const _initialState = rootReducer(undefined, { type: 'IGNORE_ME' });

  const store = createStore(
    rootReducer,
    merge(_initialState, initialState),
    composeEnhancers(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
};
