import { css } from '@emotion/core';

import { Theme } from 'src/design-system/theme';

const breadcrumb = css`
  display: flex;
  align-items: center;
`;

const option = (theme: Theme) => css`
  display: block;
  :hover {
    background: ${theme.colors.grey5};
  }
`;

export const style = { breadcrumb, option };
