import { Fragment, ReactNode } from 'react';
/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/core';
import { UseQueryResult } from '@tanstack/react-query';

import { FailedToLoad } from 'src/components/FailedToLoad';
import { Loading } from 'src/components/Loading';
import { LoadingOverlay } from 'src/components/LoadingOverlay';

interface Props<T> {
  queryResult: UseQueryResult<T>;
  children: (data: T) => ReactNode | ReactNode[];
  cardHeight?: string;
  hideFetching?: boolean;
}

export const Await = <T extends unknown>({
  queryResult,
  children,
  cardHeight = '400px',
  hideFetching = false,
}: Props<T>) => {
  const { data, isLoading, isError, isFetching, isSuccess } = queryResult;

  if (isError) {
    return <FailedToLoad height={cardHeight} />;
  }

  if (isLoading || !isSuccess) {
    return <Loading height={cardHeight} />;
  }

  if (hideFetching) {
    return <Fragment>{children(data)}</Fragment>;
  }

  return (
    <div css={{ position: 'relative', width: '100%' }}>
      {children(data)}

      {!hideFetching && isFetching && <LoadingOverlay />}
    </div>
  );
};

export const MaybeAwait = <T extends unknown>({
  queryResult,
  children,
  cardHeight = '400px',
  hideFetching = false,
}: Props<T | undefined>) => {
  const { data, isLoading, isFetching } = queryResult;

  if (isLoading) {
    return <Loading height={cardHeight} />;
  }

  if (hideFetching) {
    return <Fragment>{children(data)}</Fragment>;
  }

  return (
    <div css={{ position: 'relative', width: '100%' }}>
      {children(data)}

      {!hideFetching && isFetching && <LoadingOverlay />}
    </div>
  );
};
