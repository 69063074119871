import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import { websitesDashboardActions } from './actions';

export const websitesDashboardReducer = combineReducers({
  websites: createRequestStateReducer(websitesDashboardActions.getWebsites, {
    loading: false,
    error: '',
    data: {
      total: 0,
      items: [],
    },
  }),
  servers: createRequestStateReducer(websitesDashboardActions.getServers, {
    loading: false,
    error: '',
    data: {
      total: 0,
      items: [],
    },
  }),
});
