import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Box } from 'src/components/Box';
import { Loading } from 'src/components/Loading';
import { Text } from 'src/components/Text';
import { FontColors } from 'src/design-system/style-types';

import { Colors, FontWeights, Margins, Responsive } from '../../design-system/style-types';
import { Icon } from '../Icon';
import { IconName } from '../Icon/Icon.types';
import { style } from './Button.style';

type TypesKind = 'submit' | 'button' | 'reset';
type IconPositions = 'left' | 'right';
type Widths = 'auto' | '100%';
export type VariantsKind = 'primary' | 'secondary' | 'tertiary' | 'red' | 'success' | 'error';

export type ButtonSizes = 'tiny' | 'small' | 'normal';

export interface Props {
  variant?: Responsive<VariantsKind>;
  size?: Responsive<ButtonSizes>;
  disabled?: boolean;
  faded?: boolean;
  width?: Responsive<Widths>;
  marginTop?: Responsive<Margins> | undefined;
  marginBottom?: Responsive<Margins> | undefined;
  marginLeft?: Responsive<Margins> | undefined;
  marginRight?: Responsive<Margins> | undefined;
  className?: string;
  children?: React.ReactNode;
  borderColor?: Responsive<Colors>;
  borderColorHover?: Responsive<Colors>;
  backgroundColor?: Responsive<Colors>;
  backgroundColorHover?: Responsive<Colors>;
  color?: Responsive<FontColors>;
  colorHover?: Responsive<FontColors>;
  round?: boolean;
  iconPosition?: IconPositions | '';
  innerRef?: React.Ref<HTMLButtonElement>;
  loading?: boolean;
  type?: TypesKind;
  error?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconName | React.ReactElement;
  isLeftButton?: boolean;
  isRightButton?: boolean;
  isCenterButton?: boolean;
  forceNoHoverState?: boolean;
  ariaLabel?: string;
  fontWeight?: FontWeights;
  dataQa?: string;
  tabIndex?: number;
  autoFocus?: boolean;
}

/**
 * # Button
 * Buttons allow users to take actions, and make choices, with a single tap.
 */
export const Button = ({
  borderColor,
  borderColorHover,
  backgroundColor,
  backgroundColorHover,
  color,
  colorHover,
  onClick,
  icon,
  dataQa,
  disabled = false,
  faded = false,
  width = 'auto',
  children = '',
  variant = 'primary',
  size = 'normal',
  className = '',
  type = 'button',
  marginTop = undefined,
  marginBottom = undefined,
  marginLeft = undefined,
  marginRight = undefined,
  round = false,
  iconPosition = 'left',
  innerRef = undefined,
  loading = false,
  error = '',
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  isLeftButton = false,
  isRightButton = false,
  isCenterButton = false,
  forceNoHoverState = false,
  ariaLabel = undefined,
  fontWeight = 'normal',
  tabIndex = undefined,
  autoFocus = false,
}: Props) => {
  const styleProps = {
    borderColor,
    borderColorHover,
    backgroundColor,
    backgroundColorHover,
    color,
    colorHover,
    disabled,
    faded,
    width,
    variant,
    size,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    round,
    loading,
    error,
    isLeftButton,
    isRightButton,
    isCenterButton,
    forceNoHoverState,
    fontWeight,
  };

  const getColor = () => {
    let colorValue: Responsive<FontColors> = 'dark';

    if (variant === 'primary') {
      colorValue = 'light';
    }

    if (variant === 'secondary') {
      colorValue = 'dark';
    }

    if (variant === 'red') {
      colorValue = 'error';
    }

    if (variant === 'success') {
      colorValue = 'light';
    }

    if (variant === 'error') {
      colorValue = 'light';
    }

    if (color) {
      colorValue = color;
    }

    if (disabled && !loading) {
      colorValue = 'disabled';
    }

    return colorValue;
  };

  const getFontSize = () => {
    if (size === 'small') {
      return 'sm';
    }
    if (size === 'tiny') {
      return 'xs';
    }
    return 'md';
  };

  const getLoadingSize = () => {
    if (size === 'small' || size === 'tiny') {
      return 'md';
    }
    return 'lg';
  };

  const getIconSize = () => {
    if (size === 'tiny') {
      return 'md';
    }
    return 'lg';
  };

  const getMargins = () => {
    if (loading) {
      return '0';
    }
    if (size === 'small' || variant === 'tertiary') {
      return 'sm';
    }
    if (size === 'tiny') {
      return 'xs';
    }
    return 'lg';
  };

  const iconSize = getIconSize();
  const fontSize = getFontSize();
  const loadingSize = getLoadingSize();
  const usedColor = getColor();
  const g = getMargins();

  return (
    <button
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      aria-label={ariaLabel}
      css={style.container(styleProps)}
      className={className}
      // all buttons within a <form> tag are considered submit buttons unless you specify type="button".
      type={onClick || disabled ? 'button' : type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled || loading}
      ref={innerRef}
      data-qa={dataQa}
    >
      <Box
        d={iconPosition === 'left' ? 'row' : 'row-reverse'}
        a="center"
        j="center"
        wrap="nowrap"
        g={g}
        w="auto"
        h="100%"
      >
        {loading && <Loading css={style.loading} size={loadingSize} color={usedColor} data-qa="loading" />}
        {icon && (
          <Box transparent={loading} w="auto">
            {typeof icon !== 'string' &&
              React.cloneElement(icon, { color: usedColor, size: iconSize, hasHoverClass: true })}
            {typeof icon === 'string' && (
              <Icon color={usedColor} size={iconSize} name={icon} hasHoverClass showAriaLabel={false} />
            )}
          </Box>
        )}
        {children && (
          <Box transparent={loading} w="auto" j="center">
            <Text weight={fontWeight} color={usedColor} size={fontSize} hasHoverClass data-qa="text" tag="div">
              {children}
            </Text>
          </Box>
        )}
      </Box>
    </button>
  );
};
