import { ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Colors, FontColors, IconSizes, Margins, Responsive, Sizes } from 'src/design-system/style-types';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { IconName } from '../Icon/Icon.types';
import { Text } from '../Text';
import { style } from './CheckboxIcon.style';
import { CheckboxChangeParams } from './types';

export interface Props {
  name: string;
  iconName: IconName;
  label?: ReactNode;
  onChange?: (data: CheckboxChangeParams) => void;
  fontColor?: FontColors;
  iconBackground?: Colors | '';
  iconColor?: FontColors;
  iconBorderColor?: Colors;
  iconRound?: boolean;
  fontCheckedColor?: FontColors;
  iconCheckedName?: IconName;
  iconCheckedBackground?: Colors;
  iconCheckedBorderColor?: Colors;
  iconCheckedColor?: FontColors;
  iconCheckedRound?: boolean;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  checked?: boolean;
  size?: Sizes;
  iconSize?: IconSizes;
  marginBottom?: Responsive<Margins>;
}

export const CheckboxIcon = ({
  name,
  iconName,
  onChange = () => {},
  className = '',
  fontColor = 'dark',
  iconBackground = '',
  iconBorderColor = 'dark',
  iconColor = 'dark',
  iconRound = true,
  fontCheckedColor = 'dark',
  iconCheckedName = 'check',
  iconCheckedBorderColor = 'accent',
  iconCheckedBackground = 'accent',
  iconCheckedColor = 'light',
  iconCheckedRound = true,
  label = '',
  required = false,
  disabled = false,
  checked = false,
  iconSize = '2xl',
  size = 'md',
  marginBottom = 'md',
}: Props) => {
  const handleOnChange = (): void => {
    if (!disabled) {
      onChange({ name, checked: !checked });
    }
  };

  let color = checked ? fontCheckedColor : fontColor;

  let iconProps = {
    size: iconSize,
    name: iconName,
    background: iconBackground,
    borderColor: iconBorderColor,
    round: iconRound,
    color: iconColor,
  };

  if (disabled) {
    color = 'disabled';
    iconProps = {
      ...iconProps,
      borderColor: 'grey50',
      color: 'grey',
    };
  }

  return (
    <div css={style.container({ disabled, marginBottom })} className={className}>
      <input
        css={style.input}
        onChange={handleOnChange}
        id={name}
        name={name}
        required={required}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        data-qa="checkbox"
      />
      <label htmlFor={name}>
        <Box a="center" g="lg" wrap="nowrap" c={{ w: 'auto' }}>
          {!checked && <Icon {...iconProps} data-qa="icon" />}
          {checked && (
            <Icon
              size={iconSize}
              name={iconCheckedName}
              background={iconCheckedBackground}
              borderColor={iconCheckedBorderColor}
              round={iconCheckedRound}
              color={iconCheckedColor}
              data-qa="iconChecked"
            />
          )}
          <Text tag="span" size={size} color={color}>
            {label}
          </Text>
        </Box>
      </label>
    </div>
  );
};
