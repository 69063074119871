import React, { ReactNode } from 'react';
import { Box } from 'src/components/Box';
import { useLayoutSettings } from 'src/hooks/useLayoutSettings/useLayoutSettings';

import { Props as BoxProps } from '../Box/Box';

interface Props extends Partial<BoxProps> {
  children?: ReactNode;
  small?: boolean;
  fullWidth?: boolean;
}

export const Container = ({ children = null, small = false, fullWidth = false, ...boxProps }: Props) => {
  let width = { xs: 12, lg: 11, xl: 8 };

  if (small) width = { xs: 12, lg: 8, xl: 6 };
  if (fullWidth) width = { xs: 12, lg: 12, xl: 12 };

  const { px, pl, pr, bg, mb, h, position, className, zIndex, ...restProps } = boxProps;

  const { hideLeftHandMenu } = useLayoutSettings();

  return (
    <Box
      pl={px || pl || { xs: 'lg', md: hideLeftHandMenu ? 'lg' : '2xl' }}
      pr={px || pr || 'lg'}
      j="center"
      bg={bg}
      mb={mb}
      h={h}
      position={position}
      className={className}
      zIndex={zIndex}
      data-qa="container"
    >
      <Box {...restProps} h={h} w={width}>
        {children}
      </Box>
    </Box>
  );
};
