import { css } from '@emotion/core';

import { margin } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';

import { Theme } from '../../design-system/theme';

import type { DefaultProps } from './Divider';

const style = (props: DefaultProps) => (theme: Theme) =>
  css`
    display: flex;

    ${responsive(props.type, (type) => [
      type === 'horizontal' &&
        css`
          margin-left: 0;
          align-self: auto;
          margin-top: -${props.borderWidth}px;
          width: 100%;
          height: ${props.borderWidth}px;
          border: 0 ${props.borderStyle} ${theme.colors[props.borderColor]};
          border-bottom-width: ${props.borderWidth}px;
        `,
      type === 'vertical' &&
        css`
          margin-top: 0;
          align-self: stretch;
          margin-left: -${props.borderWidth}px;
          width: ${props.borderWidth}px;
          height: auto;
          border: 0 ${props.borderStyle} ${theme.colors[props.borderColor]};
          border-right-width: ${props.borderWidth}px;
        `,
    ])}

    ${margin({ left: props.marginLeft, right: props.marginRight, bottom: props.marginBottom, top: props.marginTop })(
      theme
    )}
  `;

export default style;
