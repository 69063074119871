import { AppState } from 'src/store';
import { defaultDateTimeConfig } from 'src/utils/intl/getMessages/getMessages';

export const uiSelectors = {
  serverGroupsCollapsed: (state: AppState) => state.ui.serverGroupsCollapsed,
  showOnboardingCard: (state: AppState): boolean => state.ui.showOnboardingCard,
  tempLocale: (state: AppState) => state.ui?.tempLocale,
  dateTimeConfig: (state: AppState) => state.ui.dateTimeConfig ?? defaultDateTimeConfig,
  selectTableItemsPerPage: (state: AppState) => state.ui.tableItemsPerPage,
  hydrated: (state: AppState) => state.ui.hydrated,
};
