import { useEffect, useMemo } from 'react';
import { LoginMembership, Website } from 'src/orchd-client';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { queryKeys } from 'src/api_services/query-keys';
import { useGetSubscriptionsQuery } from 'src/api_services/subscriptions/query';
import { subscriptionsActions } from 'src/store/subscriptions/actions';
import { axiosInstance } from 'src/utils/axios';
import { isResellerSubscription } from 'src/utils/packages/utils';

export const useSubscriptions = ({ orgId }: { orgId?: string }) => {
  const dispatch = useDispatch();

  const {
    data: subscriptions = [],
    isLoading,
    isError,
  } = useGetSubscriptionsQuery({
    orgId: orgId ?? '',
    enabled: !!orgId,
  });

  // We need to populate the redux store for now because selectIsReseller is used in branding and session selectors.
  // This can be later removed.
  useEffect(() => {
    dispatch(
      subscriptionsActions.getSubscriptionsToParent.success({
        data: { items: subscriptions, total: subscriptions.length },
      })
    );
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return { subscriptions, isLoading, isError };
};

export const useReseller = ({ orgId }: { orgId?: string }) => {
  const { subscriptions, isLoading, isError } = useSubscriptions({ orgId });

  const resellerSubscription = useMemo(() => {
    return subscriptions.find(isResellerSubscription);
  }, [subscriptions]);

  return { isLoading, isError, resellerSubscription, isReseller: !!resellerSubscription, subscriptions };
};

export const useWebsiteSubscription = ({ orgId, website }: { orgId?: string; website?: Website }) => {
  const { subscriptions, isLoading, isError } = useSubscriptions({ orgId });

  const subscription = useMemo(() => {
    return subscriptions.find(({ id }) => id === website?.subscriptionId);
  }, [subscriptions, website]);

  return { isLoading, isError, subscription, subscriptions };
};

// This hook automatically refetches subscriptions to update usage.
export const useRefetchSubscriptions = ({ member }: { member?: LoginMembership }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    // If the user is MO or logged out, we don't really care about
    // subscriptions.
    if (!member || member.isMasterOrg) {
      return;
    }

    const interceptorId = axiosInstance.interceptors.response.use((response) => {
      const method = response.config.method;

      // Invalidate subscriptions on each delete or post request.
      if (method?.toLowerCase() !== 'delete' && method?.toLowerCase() !== 'post') {
        return response;
      }

      queryClient.invalidateQueries({ queryKey: queryKeys.getSubscriptions({ orgId: member.orgId }) });

      return response;
    });

    return () => {
      axiosInstance.interceptors.response.eject(interceptorId);
    };
  }, [member, queryClient]);
};
