/** @jsxRuntime classic */
/** @jsx jsx */
import { memo } from 'react';
import { jsx } from '@emotion/core';

import { IconButton } from 'src/components/IconButton';
import { Colors } from 'src/design-system/theme';

export type Props = {
  darkMode: boolean;
  toggleDarkMode: () => void;
  iconColor?: 'light' | 'dark';
  hoverBackgroundColor?: Colors;
};

export const _DarkModeToggle = ({ darkMode, toggleDarkMode, iconColor, hoverBackgroundColor }: Props) => {
  return (
    <IconButton
      ariaLabel="themeToggle"
      iconColor={iconColor}
      icon={darkMode ? 'sun' : 'moon'}
      hoverBackgroundColor={hoverBackgroundColor}
      variant="tertiary"
      onClick={toggleDarkMode}
    />
  );
};

export const DarkModeToggle = memo(_DarkModeToggle);
