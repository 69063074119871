import { getType } from 'deox';
import { takeEvery, takeLatest } from 'redux-saga/effects';

import { settingsApi } from 'src/api_services/settings/service';
import { createRequestStateSaga } from 'src/store/helpers';

import { serviceSettingsActions } from './actions';

export const [getGlobalServiceSettingSaga] = createRequestStateSaga(
  serviceSettingsActions.getGlobalServiceSetting,
  settingsApi.getGlobalServiceSetting
);

export const [setGlobalServiceSettingSaga] = createRequestStateSaga(
  serviceSettingsActions.setGlobalServiceSetting,
  settingsApi.setGlobalServiceSetting
);

export const [deleteGlobalServiceSettingSaga] = createRequestStateSaga(
  serviceSettingsActions.deleteGlobalServiceSetting,
  settingsApi.deleteGlobalServiceSetting
);

export function* serviceSettingsSaga() {
  yield takeEvery(getType(serviceSettingsActions.getGlobalServiceSetting.request), getGlobalServiceSettingSaga);
  yield takeLatest(getType(serviceSettingsActions.setGlobalServiceSetting.request), setGlobalServiceSettingSaga);
  yield takeLatest(getType(serviceSettingsActions.deleteGlobalServiceSetting.request), deleteGlobalServiceSettingSaga);
}
