import { createSelector } from 'reselect';

import { AppState } from 'src/store';
import { isDomainAlias } from 'src/utils/website';

const selectedWebsites = (state: AppState) => state.moveServer.selectedWebsites.items;

export const selectedEligibleWebsites = createSelector(selectedWebsites, (websites) =>
  websites.filter((website) => !isDomainAlias(website))
);

export const moveServerSelectors = {
  selectedWebsites,
  selectedEligibleWebsites,
};
