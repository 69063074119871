import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import * as actions from './actions';

export interface WebsiteCreatedState {
  websiteId?: string;
}

export const initalWebsiteCreatedState: WebsiteCreatedState = {
  websiteId: undefined,
};

export type WebsiteSetupState = ReturnType<typeof createWebsiteReducer>;

const installableWebsiteAppsReducer = createRequestStateReducer(actions.getInstallableApps, {
  loading: false,
  error: '',
  data: {
    items: [],
  },
});

const websiteCreatedReducer = createReducer(initalWebsiteCreatedState, (handleAction) => [
  handleAction(actions.setCreatedWebsiteId, (_, { payload: { websiteId } }) => {
    return { websiteId };
  }),
  handleAction([actions.createWebsiteWithApp.request], () => {
    return { websiteId: undefined };
  }),
]);

export const createWebsiteReducer = combineReducers({
  installableWebsiteApps: installableWebsiteAppsReducer,
  websiteCreated: websiteCreatedReducer,
});
