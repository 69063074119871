import { css } from '@emotion/core';

import { fontSize, fontWeight, leftString, padding, right, rightString, shadowBorder } from 'src/design-system/mixins';
import { responsive } from 'src/design-system/style-utils';
import { Theme } from 'src/design-system/theme';

import type { Props } from './TextInput';

export const container = css`
  position: relative;
  width: 100%;
  display: flex;
`;

export const icon = (theme: Theme) => css`
  position: absolute;
  top: 0;
  color: ${theme.fontColors.dark};
  fill: ${theme.fontColors.dark};
  ${right('0')(theme)};
`;

export const iconButton = (theme: Theme) => css`
  display: flex;
  align-items: center;
`;

export const prefix = (props: Props) => (theme: Theme) => {
  const size = props.size === 'normal' ? 40 : 32;
  return css`
    ${fontWeight('normal')(theme)};
    ${fontSize('md')(theme)}
    background: ${theme.colors.grey5};
    height: ${size}px;
    line-height: 40px;
    ${padding({ right: 'sm', left: 'sm' })(theme)}
    color: ${theme.fontColors.grey};
    white-space: nowrap;
  `;
};

export const suffix = (props: Props) => (theme: Theme) => {
  const size = props.size === 'normal' ? 40 : 32;
  return css`
    ${fontWeight('normal')(theme)};
    ${fontSize('md')(theme)}
    background: ${theme.colors.grey5};
    height: ${size}px;
    line-height: 40px;
    ${padding({ right: 'sm', left: 'sm' })(theme)}
    color: ${theme.fontColors.grey};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${responsive(
      props.suffixMaxWidth,
      (suffixMaxWidth) =>
        css`
          max-width: ${suffixMaxWidth};
        `
    )};
  `;
};

export const input =
  (
    props: Props,
    {
      prefixWidth = 0,
      suffixWidth = 0,
      forceSmallText = false,
    }: { prefixWidth?: number; suffixWidth?: number; forceSmallText?: boolean }
  ) =>
  (theme: Theme) => {
    const { inputWidth } = props;
    const size = props.size === 'normal' ? 40 : 32;
    const fontSizing = props.size === 'small' || forceSmallText ? 'sm' : 'md';
    const paddingSize = props.size === 'small' ? 'sm' : 'lg';
    return css`
    ${fontWeight('normal')(theme)};
    ${fontSize(fontSizing)(theme)}
    border-radius: 0;
    -webkit-appearance: none;
    background: ${theme.colors.grey5};
    border: none;
    border-bottom: 1px solid ${props.disabled ? theme.colors.grey25 : theme.colors.dark};
    border-top: 1px solid transparent;
    color: ${theme.fontColors.dark};
    height: ${size}px;
    line-height: 20px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    ${responsive(
      props.marginBottom,
      (marginBottom) =>
        css`
          margin-bottom: ${theme.margins[marginBottom]}px;
        `
    )};
    /* for some reason ts-styled-plugin doesn't understand without this extra layer :( */
    ${css`
    padding-${leftString(theme)}: ${props.prefix ? theme.paddings.sm : theme.paddings[paddingSize]}px;
    padding-${rightString(theme)}: ${props.icon ? size : theme.paddings[paddingSize]}px;
    `}
    flex-grow: 1;
    ${
      inputWidth &&
      responsive(
        inputWidth,
        (width) =>
          css`
            width: ${width};
          `
      )
    }};
    ${responsive(
      props.inputMaxWidth,
      (inputMaxWidth) =>
        css`
          max-width: calc(${inputMaxWidth} - ${suffixWidth}px - ${prefixWidth}px);
        `
    )};
    ::placeholder {
      color: ${theme.fontColors.grey};
    }

    :focus {
      border-color: transparent;
      ${shadowBorder('dark')(theme)}
      background: ${theme.colors.light};
    }

    ${
      props.err &&
      props.touched &&
      css`
        border-color: transparent !important;
        ${shadowBorder('error', 2)(theme)}
        :placeholder-shown {
          ${shadowBorder('error', 2)(theme)}
        }
        :focus {
          ${shadowBorder('error', 2)(theme)}
        }
      `
    }

    :disabled {
      box-shadow: none;
      color: ${theme.fontColors.disabled};
    }
  `;
  };

export const style = {
  container,
  icon,
  iconButton,
  prefix,
  suffix,
  input,
};
