import { ReactElement } from 'react';
import { createActionCreator } from 'deox';

export interface Payload {
  text?: string | ReactElement;
  id?: string;
  persistent?: boolean;
  filled?: boolean;
}

export const autosaveToast = {
  show: createActionCreator('autosaveToastShow', (resolve) => (payload: Payload = {}) => {
    return resolve(payload);
  }),
  success: createActionCreator('autosaveToastSuccess', (resolve) => (payload: Payload = {}) => {
    return resolve(payload);
  }),
  error: createActionCreator('autosaveToastError', (resolve) => (payload: Payload = {}) => {
    return resolve(payload);
  }),
  neutral: createActionCreator('autosaveToastNeutral', (resolve) => (payload: Payload = {}) => {
    return resolve(payload);
  }),
};
