import { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/components/Box';
import { Icon } from 'src/components/Icon';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import { Tooltip } from 'src/components/Tooltip';
import pendingSandClock from 'src/images/pending-sand-clock.png';
import { zIndex } from 'src/utils/zIndex';

import { style } from './Screenshot.style';

interface Props {
  imageUrl: string;
  baseHeight?: number;
  baseWidth?: number;
  fixedHeight?: number;
  onRefresh?: () => Promise<void>;
}
export interface ScreenshotConfig {
  imageUrl: string;
  aspectRatio: string;
  fixedHeight: number | undefined;
  isError: boolean;
}

export const Screenshot = ({ imageUrl, baseWidth = 400, baseHeight = 200, fixedHeight, onRefresh }: Props) => {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const aspectRatio = `${100 / (baseWidth / baseHeight)}%`;

  const handleError = () => {
    setIsError(true);
  };

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const handleOnRefreshClick = () => {
    if (!onRefresh || isRefreshing) {
      return;
    }

    setIsRefreshing(true);

    onRefresh().then(
      () => setIsRefreshing(false),
      () => setIsRefreshing(false)
    );
  };
  return (
    <Box position="relative">
      <Box
        css={style.imageContainer({
          // Ensure the imageUrl updates when loading is finished.
          imageUrl: isLoaded && !isError ? imageUrl : '',
          aspectRatio,
          fixedHeight,
          isError,
        })}
        d="column"
      >
        {!isLoaded && !isError && <LoadingOverlay zIndexOverlay={zIndex.One} />}

        {!isError && <img alt="" css={style.image} src={imageUrl} onLoad={handleLoad} onError={handleError} />}

        {isError && (
          <Box position="absolute" top="0" bottom="0" left="0" right="0" a="center" j="center">
            <img style={{ height: '32px' }} src={pendingSandClock} alt="" />
          </Box>
        )}

        {onRefresh && (
          <Tooltip
            placement="right-end"
            forwarded
            button={
              <div css={style.refreshIcon} onClick={handleOnRefreshClick}>
                <Icon name={isRefreshing ? 'loading' : 'refresh'} size="lg" color="light" />
              </div>
            }
          >
            <FormattedMessage id="refresh_website_screenshot" defaultMessage="Refresh website screenshot" />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
