import { all, call, fork } from 'redux-saga/effects';

import { watchViewportSaga } from 'src/containers/Viewport/_redux/saga';
import { serverRolesSaga } from 'src/pages/servers/manage-server/_redux/server-roles/sagas';
import { unconfiguredServersSaga } from 'src/pages/servers/servers-list/_redux/unconfigured-servers/sagas';
import { serviceSettingsSaga } from 'src/pages/settings/Service/_redux/sagas';
import { websitesDashboardSaga } from 'src/pages/websites/dashboard/redux/sagas';
import { sshKeysManagerSaga } from 'src/pages/websites/website/devtools/SshKeysManager/_redux';
import { websiteEmailsPageSaga } from 'src/pages/websites/website/emails/redux/sagas';
import { fileManagerSaga } from 'src/pages/websites/website/files/_redux';
import { websiteHtaccessIpsRuleSaga } from 'src/pages/websites/website/security/IpBlocker/_redux';

import { invitesSaga } from '../pages/invites/_redux';
import { createWebsiteSaga } from '../pages/websites/add-website/redux';
import { appsSaga } from './apps';
import { brandingSaga } from './branding/saga';
import { customersSaga } from './customers/saga';
import { domainsSaga } from './domains/saga';
import { emailsSaga } from './emails/saga';
import { filerdSaga } from './filerd/saga';
import { impersonationSaga } from './impersonation';
import { installSaga } from './install/saga';
import { licenceSaga } from './licence/saga';
import { loginsSaga } from './logins/sagas';
import { membersSaga } from './members/saga';
import { mysqlSaga } from './mysql/saga';
import { orgsSaga } from './orgs/saga';
import { plansSaga } from './plans/saga';
import { searchSaga } from './searches';
import { serversSaga } from './servers/saga';
import { updatingServersSaga } from './servers/updatingServers/saga';
import { sessionSaga } from './session';
import { settingsSaga } from './settings/saga';
import { subscriptionsSaga } from './subscriptions/sagas';
import { websiteBackupsSaga } from './website-backups/sagas';
import { websitesSaga } from './websites/saga';
import { wordPressSaga } from './word-press/saga';

export const sagas = {
  appsSaga,
  createWebsiteSaga,
  customersSaga,
  domainsSaga,
  emailsSaga,
  fileManagerSaga,
  filerdSaga,
  impersonationSaga,
  installSaga,
  invitesSaga,
  licenceSaga,
  loginsSaga,
  membersSaga,
  mysqlSaga,
  orgsSaga,
  plansSaga,
  searchSaga,
  serverRolesSaga,
  serversSaga,
  serviceSettingsSaga,
  settingsSaga,
  sshKeysManagerSaga,
  subscriptionsSaga,
  unconfiguredServersSaga,
  updatingServersSaga,
  watchViewportSaga,
  websiteBackupsSaga,
  websiteEmailsPageSaga,
  websiteHtaccessIpsRuleSaga,
  websitesDashboardSaga,
  websitesSaga,
  wordPressSaga,
};

export const forkedSagas = Object.values(sagas).map((saga) => fork(saga));

function* allSagas() {
  yield all(forkedSagas);
}

function* rootSaga() {
  yield fork(sessionSaga);
  yield fork(brandingSaga);

  yield call(allSagas);
}

export default rootSaga;
