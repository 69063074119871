import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { SelectOption } from 'src/components/SelectNew';

export const getSchema = (formatMessage: IntlShape['formatMessage']) =>
  Yup.object({
    website: Yup.array()
      .of(Yup.mixed<SelectOption>().required())
      .required()
      .min(
        1,
        formatMessage({
          id: 'websites.push_live_to_existing.required',
          defaultMessage: 'Please select a destination website',
        })
      ),
    files: Yup.array().of(Yup.string().required()).required(),
    databases: Yup.array().of(Yup.string().required()).required(),
    databaseUsers: Yup.array().of(Yup.string().required()).required(),
    deleteFilesFromDestination: Yup.boolean().required(),
    syncPhpVersion: Yup.boolean().required(),
    runWpSearchReplace: Yup.boolean().required(),
  });

export type PushLiveToExistingFields = Yup.InferType<ReturnType<typeof getSchema>>;
