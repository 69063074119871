import { AxiosError } from 'axios';

export const isFilerdSessionExpired = ({ response, config }: AxiosError) => {
  if (!config.baseURL || !response?.status) {
    return false;
  }

  const regex = new RegExp(`^/filerd/`);
  return [400, 401, 403].includes(response?.status) && regex.test(config.baseURL);
};
