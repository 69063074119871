import { installApi } from 'src/api_services/install/service';

import { createActionsFromMethod } from '../helpers';

const namespace = '@install';

const getOrchdVersion = createActionsFromMethod<typeof installApi.orchdVersion>(`getOrchdVersion(${namespace})`);

export const installActions = {
  getOrchdVersion,
};
