import React, { memo, useMemo } from 'react';
import { NetworkStatus, ServerInfoBrief, ServerRole } from 'src/orchd-client';
import { connect } from 'react-redux';
import { components, OptionProps } from 'react-select';

import { useGetServerRolesQuery } from 'src/api_services/servers/query';
import { Box } from 'src/components/Box';
import { DedicatedPackagePill } from 'src/components/DedicatedPackagePill/DedicatedPackagePill';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { AppState } from 'src/store';
import { sessionSelectors } from 'src/store/session/selectors';
import { getRoleServiceStatus } from 'src/utils/helpers';
import { getServerStatusIcon } from 'src/utils/servers/getServerStatusIcon/getServerStatusIcon';

type StateProps = ReturnType<typeof stateToProps>;

interface Props
  extends StateProps,
    OptionProps<
      {
        data: {
          server: ServerInfoBrief;
          ip?: string;
          role: ServerRole;
          isClickDisabled: boolean;
          roleDisabled: boolean;
          isUpdating: boolean;
        };
      },
      false
    > {
  search: string;
}

const stateToProps = (state: AppState) => ({
  member: sessionSelectors.getMember(state),
});

export const _ServerSelectOptionLabel = (props: Props) => {
  const { isMasterOrg } = props.member;
  const data = props.data.data ?? {};

  const { server = {}, ip, role, isClickDisabled, roleDisabled, isUpdating } = data;
  const { data: rolesInfo, isLoading: rolesInfoLoading } = useGetServerRolesQuery({
    serverId: server.id,
    enabled: !!(server.id && isMasterOrg),
  });

  const roleState = useMemo(() => {
    if (rolesInfoLoading || !rolesInfo) {
      return 'loading';
    }

    return getRoleServiceStatus(role, rolesInfo) === NetworkStatus.online ? 'online' : 'offline';
  }, [rolesInfo, rolesInfoLoading, role]);

  if (!props.data.data) return <components.Option {...props} />;

  const { name, color } = getServerStatusIcon({ roleState, isUpdating });

  return (
    <components.Option {...props}>
      <Box wrap="nowrap" a="center">
        <Icon name={name} color={color} marginRight="sm" />

        <Text
          textDecoration={roleDisabled ? 'line-through' : 'none'}
          color={isClickDisabled || roleState !== 'online' || isUpdating ? 'grey' : undefined}
          ellipsis
          marginRight="sm"
          highlightText={props.search}
        >
          {server.friendlyName}
        </Text>

        {server.dedicatedSubscription && <DedicatedPackagePill disabled={isUpdating} />}
      </Box>
      {!!ip && (
        <Text
          size="xs"
          highlightText={props.search}
          textDecoration={roleDisabled ? 'line-through' : 'none'}
          color="grey"
          ellipsis
          marginRight="sm"
        >
          {ip}
        </Text>
      )}
    </components.Option>
  );
};

export const ServerSelectOptionLabel = connect(stateToProps)(memo(_ServerSelectOptionLabel));
