import { createReducer } from 'deox';
import { combineReducers } from 'redux';

import { createRequestStateReducer } from 'src/store/helpers';

import {
  getWebsiteBackup,
  getWebsiteBackups,
  getWebsiteEmails,
  getWebsiteMySQLDBs,
  setRestoringWebsites,
} from './actions';

export type RestoringWebsites = Record<string, boolean>;
export const websiteRestoreStatusInitial: RestoringWebsites = {};

export const restoringWebsitesReducer = createReducer(websiteRestoreStatusInitial, (handleAction) => [
  handleAction(setRestoringWebsites, (state, { payload: { websiteId, restoring } }) => ({
    ...state,
    [websiteId]: restoring,
  })),
]);

export const websiteBackupsReducer = combineReducers({
  websiteBackup: createRequestStateReducer(getWebsiteBackup),
  websiteBackups: createRequestStateReducer(getWebsiteBackups),
  websiteMySQLDBs: createRequestStateReducer(getWebsiteMySQLDBs),
  restoringWebsites: restoringWebsitesReducer,
  websiteEmails: createRequestStateReducer(getWebsiteEmails),
});
