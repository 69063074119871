import React from 'react';
import Text, { defaultProps, DefaultProps, Props } from '../Text';

const updatedDefaultProps: DefaultProps = {
  ...defaultProps,
  tag: 'p',
};

/**
 * # Text P
 * Use P for body text
 */
const P = (props: Props) => <Text {...props} />;

P.defaultProps = updatedDefaultProps;

export default P;
