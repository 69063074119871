import {
  WordpressApiInstallWordpressPluginReq,
  WordpressApiInstallWordpressPluginRes,
  WPPluginStatus,
} from 'src/orchd-client';
import { AxiosError } from 'axios';
import { ActionType, getType } from 'deox';
import { all, call, put, race, select, take, takeEvery, takeLatest, takeLeading } from 'typed-redux-saga/macro';

import { appsApi } from 'src/api_services/apps';
import { serversApi } from 'src/api_services/servers/service';
import { websitesApi } from 'src/api_services/websites/service';
import { wordPressApi } from 'src/api_services/wordpress/service';
import { appsActions, appsSelectors } from 'src/store/apps';
import { createRequestStateSaga } from 'src/store/helpers';
import { wordPressActions } from 'src/store/word-press/actions';
import { getErrorInfo } from 'src/utils/errors';
import { getErrorCodeOrResponse } from 'src/utils/getErrorCodeOrResponse';

import * as actions from './actions';

export const [getServersRequestSaga] = createRequestStateSaga(actions.getServers, serversApi.getServers);

export const [createWebsiteRequestSaga] = createRequestStateSaga(actions.createWebsite, websitesApi.createWebsite);
export const [getInstallableWebsiteAppsSaga] = createRequestStateSaga(
  actions.getInstallableApps,
  appsApi.getInstallableApps
);

export enum CreateWebsiteWithAppErrorCode {
  CreateWebsite,
  CreateApp,
  GetInstallableWebsiteApps,
}

export class CreateWebsiteError extends Error {
  code = CreateWebsiteWithAppErrorCode.CreateWebsite;
}

export class CreateWebsiteAppError extends Error {
  code = CreateWebsiteWithAppErrorCode.CreateApp;
}

export class GetInstallableWebsiteAppsError extends Error {
  code = CreateWebsiteWithAppErrorCode.GetInstallableWebsiteApps;
}

export function* installWordpressPlugin(payload: WordpressApiInstallWordpressPluginReq) {
  try {
    const data: WordpressApiInstallWordpressPluginRes = yield call(wordPressApi.installWordpressPlugin, payload);
    yield put(wordPressActions.installWordPressPlugin.success({ data }));
  } catch (err) {
    const { message } = getErrorInfo(err);
    yield put(actions.createWebsiteWithApp.error({ error: message }));
    yield put(wordPressActions.installWordPressPlugin.error({ error: message }));
  }
}

export function* createWebsiteWithAppSaga({ payload }: ActionType<typeof actions.createWebsiteWithApp.request>): any {
  const { onError, onSuccess } = payload;
  const { orgId, newWebsite, newWebsiteApp, kind, appPlugins } = payload.params;

  yield put(actions.createWebsite.request({ params: { orgId, newWebsite, kind } }));

  const {
    createWebsiteSuccess,
    createWebsiteError,
  }: {
    createWebsiteSuccess: ActionType<typeof actions.createWebsite.success>;
    createWebsiteError: ActionType<typeof actions.createWebsite.error>;
  } = yield race({
    createWebsiteSuccess: take(getType(actions.createWebsite.success)),
    createWebsiteError: take(getType(actions.createWebsite.error)),
  });

  if (createWebsiteError) {
    const { error } = createWebsiteError.payload;
    yield put(actions.createWebsiteWithApp.error({ error }));
    if (onError) {
      onError(new CreateWebsiteError(error));
    }
    return;
  }

  const websiteId = createWebsiteSuccess.payload.data.id;

  let { version } = newWebsiteApp;

  if (!version && newWebsite.subscriptionId) {
    yield put(actions.getInstallableApps.request({ params: { subscriptionId: newWebsite.subscriptionId, orgId } }));
    const {
      getInstallableWebsiteAppsSuccess,
      getInstallableWebsiteAppsError,
    }: {
      getInstallableWebsiteAppsSuccess: ActionType<typeof actions.getInstallableApps.success>;
      getInstallableWebsiteAppsError: ActionType<typeof actions.getInstallableApps.error>;
    } = yield race({
      getInstallableWebsiteAppsSuccess: take(getType(actions.getInstallableApps.success)),
      getInstallableWebsiteAppsError: take(getType(actions.getInstallableApps.error)),
    });

    if (getInstallableWebsiteAppsError) {
      const { error } = getInstallableWebsiteAppsError.payload;
      yield put(actions.createWebsiteWithApp.error({ error }));
      if (onError) {
        onError(new GetInstallableWebsiteAppsError(error));
      }
      return;
    }

    const latestInstallableApp = getInstallableWebsiteAppsSuccess.payload.data.items
      .filter(({ app }) => app === newWebsiteApp.app)
      .find(({ isLatest }) => isLatest);

    if (!latestInstallableApp) {
      const error = `Unable to find any installable version of app: ${newWebsiteApp.app}`;
      yield put(actions.createWebsiteWithApp.error({ error }));
      if (onError) {
        onError(new GetInstallableWebsiteAppsError(error));
      }
      return;
    }

    version = latestInstallableApp.version;
  }

  if (!version) {
    return;
  }

  yield put(
    appsActions.createWebsiteApp.request({ params: { orgId, websiteId, newWebsiteApp: { ...newWebsiteApp, version } } })
  );

  const {
    createWebsiteAppError,
    createWebsiteAppSuccess,
  }: {
    createWebsiteAppSuccess: ActionType<typeof appsActions.createWebsiteApp.success>;
    createWebsiteAppError: ActionType<typeof appsActions.createWebsiteApp.error>;
  } = yield race({
    createWebsiteAppSuccess: take(getType(appsActions.createWebsiteApp.success)),
    createWebsiteAppError: take(getType(appsActions.createWebsiteApp.error)),
  });

  if (createWebsiteAppError) {
    const { error } = createWebsiteAppError.payload;
    yield put(actions.createWebsiteWithApp.error({ error }));
    if (onError) {
      onError(new CreateWebsiteAppError(error));
    }
    return;
  }

  const appId = createWebsiteAppSuccess.payload.data.id;

  if (appPlugins?.length) {
    yield all(
      appPlugins.map((pluginName: string) =>
        call(installWordpressPlugin, {
          orgId,
          websiteId,
          appId,
          installWpPlugin: { pluginName },
        })
      )
    );

    // Try to enable plugins after installing.
    for (const slug of appPlugins) {
      try {
        yield call(wordPressApi.updateWordpressPluginSettings, {
          orgId,
          websiteId,
          appId,
          plugin: slug,
          updateWpPlugin: { status: WPPluginStatus.active },
        });
      } catch {
        //
      }
    }
  }

  if (onSuccess) {
    onSuccess();
  }

  yield put(actions.createWebsiteWithApp.success());
  yield put(actions.setCreatedWebsiteId({ websiteId }));
}

export function* createWebsiteAppSaga({ payload }: ActionType<typeof appsActions.createWebsiteApp.request>) {
  const { onError, onSuccess, params } = payload;
  const { newWebsiteApp } = params;
  const { version, app } = newWebsiteApp;

  try {
    const { data } = yield* call(appsApi.createWebsiteApp, payload.params);

    // Check whether the requested app version is the latest.
    const installableApps = yield* select(appsSelectors.selectInstallableWebsiteApps);
    const globalInstallableApps = yield* select(appsSelectors.globalInstallableApps);

    const allApps = installableApps && installableApps.length > 0 ? installableApps : globalInstallableApps || [];

    const requestedApp = allApps.find((application) => application.app === app && application.version === version);
    const isLatest = !requestedApp || requestedApp.isLatest;

    // Set software updates to manual if the version is not latest.
    if (!isLatest) {
      // TODO: API for setting this is missing.
    }

    yield put(appsActions.createWebsiteApp.success({ data }));

    if (onSuccess) {
      onSuccess({ data });
    }
  } catch (e) {
    const error = getErrorCodeOrResponse(e as AxiosError<{ code: string }>);

    yield put(appsActions.createWebsiteApp.error({ error }));

    if (onError) {
      onError(e as Error);
    }
  }
}

export function* createWebsiteSaga() {
  yield takeLatest(getType(actions.getServers.request), getServersRequestSaga);
  yield takeLatest(getType(actions.getInstallableApps.request), getInstallableWebsiteAppsSaga);

  yield takeEvery(getType(actions.createWebsite.request), createWebsiteRequestSaga);
  yield takeEvery(getType(appsActions.createWebsiteApp.request), createWebsiteAppSaga);

  yield takeLeading(getType(actions.createWebsiteWithApp.request), createWebsiteWithAppSaga);
}
