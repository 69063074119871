import React, { memo, ReactNode, RefObject } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { connect } from 'react-redux';

import { FontSizes, Margins, Paddings, Responsive } from 'src/design-system/style-types';
import { AppState } from 'src/store';
import { isMobile } from 'src/utils/breakpointHelpers';

import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { StatusIcon } from '../Status';
import { Text } from '../Text';
import { style } from './Alert.style';

import type { AlertVariant } from 'src/components/AlertModal/AlertModal';

interface DefaultProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  fontSize: FontSizes;
  iconCloseSize: FontSizes;
  marginBottom: Responsive<Margins>;
  padding: Responsive<Paddings>;
  iconClose: React.ReactNode;
  onClose: undefined | (() => void);
  lightBackground: boolean;
  innerRef?: RefObject<any>;
  showIcon: boolean;
  ctaText?: ReactNode;
  ctaOnClick?: () => void;
  iconOverride?: ReactNode;
}

type StateProps = ReturnType<typeof stateToProps>;

export interface Props extends DefaultProps, StateProps {
  variant: AlertVariant;
}

const defaultProps: DefaultProps = {
  fontSize: 'sm',
  iconCloseSize: 'lg',
  marginBottom: '0',
  padding: 'lg',
  iconClose: '',
  onClose: undefined,
  lightBackground: false,
  showIcon: true,
};

const Alert = (props: Props) => {
  const {
    title,
    children,
    variant,
    fontSize,
    iconCloseSize,
    iconClose,
    onClose,
    innerRef,
    showIcon,
    ctaText,
    ctaOnClick,
    isMobile,
    iconOverride,
  } = props;

  const cta = ctaText && ctaOnClick && (
    <Button size="small" variant="tertiary" fontWeight="bold" onClick={ctaOnClick}>
      {ctaText}
    </Button>
  );

  const displayTopCta = cta && !onClose && !isMobile;
  const displayBottomCta = cta && (onClose || isMobile);

  return (
    <div css={style.container(props)} ref={innerRef}>
      <Box wrap="nowrap">
        <Box>
          <Box wrap="nowrap" a="flex-start">
            {showIcon && (iconOverride ?? <StatusIcon marginTop="xs" type={variant} data-qa="status-icon" />)}
            <Box wrap="wrap" ml={showIcon ? 'md' : undefined} css={style.titleText}>
              <Text tag="span" size={fontSize}>
                <Text tag="span" weight="bold" size={fontSize}>
                  {title}
                </Text>
                {title && children && ' '}
                {children}
              </Text>
            </Box>
          </Box>
        </Box>
        {onClose && (
          <Box w="auto" pt="xs" pr="xs" pl="sm" pb="sm" onClick={onClose}>
            {iconClose ? iconClose : <Icon name="close big" size={iconCloseSize} data-qa="close-icon" />}
          </Box>
        )}
        {displayTopCta && (
          <Box ml="2xl" w="auto">
            {cta}
          </Box>
        )}
      </Box>
      {displayBottomCta && (
        <Box mt="sm" j="flex-end">
          {cta}
        </Box>
      )}
    </div>
  );
};

Alert.defaultProps = defaultProps;

export const stateToProps = (state: AppState) => ({
  isMobile: isMobile(state.viewport.width),
});

export default connect(stateToProps)(memo(Alert));
