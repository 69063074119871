/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import classNames from 'classnames';

import { FontColors } from 'src/design-system/style-types';

import { BorderStyle, Colors, Margins, Responsive, Sizes } from '../../design-system/style-types';
import { ICON_COMPONENT_MAP } from './Icon.list';
import { style } from './Icon.style';
import { IconName } from './Icon.types';

export interface RequiredProps {
  name: IconName;
  hoverName?: string;
}

export interface DefaultProps {
  className: string;
  tabIndex: number;
  round: boolean;
  size: Responsive<Sizes> | number;
  height?: Responsive<string>;
  width?: Responsive<string>;
  color: Responsive<FontColors>;
  background: Colors | '';
  borderColor: Colors | '';
  borderWidth: number;
  borderStyle: BorderStyle;
  hasHoverClass: boolean;
  marginTop: Responsive<Margins> | undefined;
  marginBottom: Responsive<Margins> | undefined;
  marginRight: Responsive<Margins> | undefined;
  marginLeft: Responsive<Margins> | undefined;
  hide: Responsive<boolean>;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  onMouseEnter: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave: (e: React.MouseEvent<HTMLElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  innerRef?: React.Ref<HTMLElement>;
  enableRtl: boolean;
  dataQa?: string;
  ariaLabel?: string;
  showAriaLabel?: boolean;
  rotate?: number;
}

export interface IconProps extends RequiredProps, Partial<DefaultProps> {}

const defaultProps: DefaultProps = {
  className: '',
  tabIndex: 0,
  size: 'lg',
  color: 'dark',
  round: false,
  background: '',
  borderColor: '',
  borderWidth: 1,
  borderStyle: 'solid',
  hasHoverClass: false,
  marginTop: undefined,
  marginRight: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  hide: false,
  onKeyDown: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  enableRtl: false,
  dataQa: '',
};

const Icon = (props: RequiredProps & DefaultProps) => {
  const {
    ariaLabel,
    name,
    hoverName,
    dataQa,
    onClick,
    tabIndex,
    className,
    hasHoverClass,
    innerRef,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    showAriaLabel = true,
  } = props;

  const IconComponent = ICON_COMPONENT_MAP[name];

  let otherProps = {};
  if (onClick) {
    otherProps = {
      role: 'button',
      tabIndex,
      onClick,
    };
  }

  return (
    <i
      {...otherProps}
      css={style.container(props)}
      className={className}
      ref={innerRef}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="img"
      title={hoverName}
      data-qa={dataQa}
      {...(showAriaLabel ? { 'aria-label': ariaLabel ?? hoverName ?? name } : {})}
    >
      <IconComponent
        css={style.svg(props)}
        className={classNames({ 'ui-svg-hoverable': hasHoverClass })}
        aria-label={name}
        data-qa="icon-svg"
      />
    </i>
  );
};

Icon.defaultProps = defaultProps;

export { Icon };
