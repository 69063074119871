import React from 'react';
import { CPLocale } from 'src/orchd-client';
import { createIntl, createIntlCache } from 'react-intl';

import { HighlightOverlay } from './HighlightOverlay';

export const createFakeIntl = (localeConfig: { locale?: CPLocale; messages?: Record<string, string> }) => {
  const intl = createIntl(
    {
      locale: localeConfig.locale ?? CPLocale.en_GB,
      messages: localeConfig.messages ?? {},
    },
    createIntlCache()
  );

  const realIntl = createIntl(
    {
      locale: localeConfig.locale ?? CPLocale.en_GB,
      messages: localeConfig.messages ?? {},
    },
    createIntlCache()
  );

  // We need to provide a fake implementation for the "formatMessage" function.
  (intl as any).formatMessage = (
    { id, defaultMessage }: { id: string; defaultMessage: string },
    values: Record<string, string>
  ) => {
    return <HighlightOverlay id={id} defaultMessage={defaultMessage} values={values} formatter={realIntl} />;
  };

  return intl;
};
