import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface RequiredProps {
  component: React.ComponentType<any>;
  isLoggedIn: boolean;
  [restProps: string]: unknown;
}

const LoggedOutRoute = ({ component: Component, isLoggedIn, member, ...rest }: RequiredProps) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? <Redirect to={{ pathname: '/', state: { from: props.location } }} /> : <Component {...props} />
    }
  />
);

export default LoggedOutRoute;
