import { CPLocale } from 'src/orchd-client';

import { dateFormat, dateTimeFormat, timeFormat } from 'src/translations/constants';

export interface DateTimeConfig {
  dateTime: dateTimeFormat;
  time: timeFormat;
  date: dateFormat;
}

export const defaultDateTimeConfig = {
  dateTime: dateTimeFormat['DD/MM/YYYY HH:mm'],
  time: timeFormat['HH:mm'],
  date: dateFormat['DD/MM/YYYY'],
};
interface Config {
  messages: Record<string, string>;
  dateTimeConfig: DateTimeConfig;
  configureTimeLibraries?: () => void;
}

const safeGetMessages = async (locale: CPLocale) => {
  try {
    const {
      messages,
      dateTimeConfig = defaultDateTimeConfig,
      configureTimeLibraries,
    }: Config = await import(`src/translations/configs/${locale}`);
    return { messages, dateTimeConfig, configureTimeLibraries };
  } catch {
    const { messages, dateTimeConfig, configureTimeLibraries }: Config = await import(`src/translations/configs/en-GB`);
    return { messages, dateTimeConfig, configureTimeLibraries };
  }
};

export const getMessages = async (locale: CPLocale): Promise<Config> => {
  const { messages, dateTimeConfig, configureTimeLibraries } = await safeGetMessages(locale);

  configureTimeLibraries?.();

  try {
    const draftMessages = await import(`src/translations/messages/${locale}_draft.json`);

    return { messages: { ...messages, ...draftMessages }, dateTimeConfig };
  } catch {
    return {
      messages,
      dateTimeConfig,
    };
  }
};
