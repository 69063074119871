import { Fragment, memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { LoginMembership, ResourceName } from 'src/orchd-client';
import { useLocation } from 'react-router-dom';

import { Box } from 'src/components/Box';
import { DashboardHeader } from 'src/components/DashboardHeader/DashboardHeader';
import { DashboardSpine } from 'src/components/DashboardSpine/DashboardSpine';
import { ImpersonationBanner } from 'src/containers/ImpersonationBanner';
import { useLayoutSettings } from 'src/hooks/useLayoutSettings/useLayoutSettings';
import { useSubscriptions } from 'src/hooks/useSubscriptions/useSubscriptions';
import { getSubscriptionResource, isUnlimitedResource } from 'src/utils/packages/utils';
import { LinkItem } from 'src/utils/types';

import { AddWebsiteBanner } from './AddWebsiteBanner';

export interface Props {
  children: ReactNode;
  logout: () => void;
  member: LoginMembership;
  isImpersonationActive: boolean;
  loginMemberships: LoginMembership[];
  logoPath?: string;
  iconPath?: string;
  supportUrl?: string;
  billingUrl?: string;
  spineLinks: LinkItem[];
  isSpineOpen: boolean;
  isDesktopUp: boolean;
  toggleSpine: () => void;
}

const _DashboardLayout = ({
  children,
  isDesktopUp,
  isImpersonationActive,
  isSpineOpen,
  loginMemberships,
  logoPath,
  iconPath,
  logout,
  member,
  spineLinks,
  supportUrl,
  billingUrl,
  toggleSpine,
}: Props) => {
  const [expanded, setExpanded] = useState(isDesktopUp ? isSpineOpen : false);
  const location = useLocation();

  const { hideLeftHandMenu } = useLayoutSettings();
  const { subscriptions } = useSubscriptions({ orgId: member.orgId });

  useEffect(() => {
    const isExpanded = isDesktopUp ? isSpineOpen : false;
    setExpanded(isExpanded);
  }, [isDesktopUp, isSpineOpen]);

  const handleToggle = () => (isDesktopUp ? toggleSpine() : setExpanded(!expanded));
  const shrink = () => setExpanded(false);

  const spineWidth = expanded ? '220px' : '70px';

  // Whether to show purple add website/import website bar.
  const showAddWebsiteBar = useMemo(() => {
    if (
      location.pathname.startsWith('/websites/add-website') ||
      location.pathname.startsWith('/website-imports') ||
      location.pathname.startsWith('/websites/empty-state')
    ) {
      return false;
    }

    if (subscriptions.length === 1) {
      const websites = getSubscriptionResource(subscriptions[0], ResourceName.websites);
      const staging = getSubscriptionResource(subscriptions[0], ResourceName.stagingWebsites);
      const isWebsitesUnlimited = isUnlimitedResource(websites);
      const isStagingUnlimited = isUnlimitedResource(staging);

      return isWebsitesUnlimited || isStagingUnlimited || (websites?.total ?? 0) + (staging?.total ?? 0) > 1;
    }

    return subscriptions.length > 1;
  }, [subscriptions, location.pathname]);

  // Hide header when on specific pages.
  const showHeader = !location.pathname.endsWith('/files') || !isDesktopUp;
  const offsetPage = location.pathname.endsWith('/files') || !isDesktopUp;

  if (hideLeftHandMenu) {
    return (
      <Fragment>
        <div id="content-portal-root" style={{ width: '100%' }} />
        <div id="bulk-action-bar" style={{ width: '100%', height: '0px' }} />

        <ImpersonationBanner />

        {showAddWebsiteBar && <AddWebsiteBanner orgId={member.orgId} isImpersonationActive={isImpersonationActive} />}

        {showHeader && (
          <DashboardHeader
            handleToggle={handleToggle}
            member={member}
            loginMemberships={loginMemberships}
            logout={logout}
            isImpersonating={isImpersonationActive}
          />
        )}

        <Box mt={offsetPage ? '2xl' : '0'}>{children}</Box>
      </Fragment>
    );
  }

  return (
    <Box wrap="nowrap" j="space-between">
      <DashboardSpine
        expanded={expanded}
        handleToggle={handleToggle}
        shrink={shrink}
        isDesktopUp={isDesktopUp}
        logoPath={logoPath}
        iconPath={iconPath}
        supportUrl={supportUrl}
        billingUrl={billingUrl}
        roles={member.roles}
        spineLinks={spineLinks}
        isImpersonationActive={isImpersonationActive}
      />
      <Box d="column" w={{ xs: '100%', sm: `calc(100% - ${spineWidth})` }}>
        <div id="content-portal-root" style={{ width: '100%' }} />
        <div id="bulk-action-bar" style={{ width: '100%', height: '0px' }} />

        <ImpersonationBanner />

        {showHeader && (
          <DashboardHeader
            handleToggle={handleToggle}
            member={member}
            loginMemberships={loginMemberships}
            logout={logout}
            isImpersonating={isImpersonationActive}
          />
        )}

        <Box mt={offsetPage ? '2xl' : '0'}>{children}</Box>
      </Box>
    </Box>
  );
};

export const DashboardLayout = memo(_DashboardLayout);
