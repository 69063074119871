import { combineReducers } from 'redux';

import { createRequestStateReducer } from '../helpers';
import { membersActions } from './actions';

export const membersReducer = combineReducers({
  member: createRequestStateReducer(membersActions.getMember),
  members: createRequestStateReducer(membersActions.getMembers),
  accessTokens: createRequestStateReducer(membersActions.getAccessTokens),
});
