import { getType } from 'deox';
import { Middleware } from 'redux';

import { persister } from 'src/utils/persister/persister';

import { impersonationActions } from './actions';

export const impersonationPersisterMiddleware: Middleware = (store) => (next) => (action) => {
  const res = next(action);

  switch (action.type) {
    case getType(impersonationActions.startImpersonatingSuccess): {
      persister.set({ key: 'impersonation', item: store.getState().impersonation });
      break;
    }
    case getType(impersonationActions.stopImpersonating): {
      persister.clear({ key: 'impersonation' });
      break;
    }
  }
  return res;
};
