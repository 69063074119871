import { memo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { LoginMembership } from 'src/orchd-client';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetOwnerQuery } from 'src/api_services/members/query';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Container } from 'src/components/Container';
import { Icon } from 'src/components/Icon';
import { Text } from 'src/components/Text';
import { viewportSelectors } from 'src/containers/Viewport/_redux/selectors';
import { AppState } from 'src/store';
import { impersonationSelectors } from 'src/store/impersonation';
import { impersonationActions } from 'src/store/impersonation/actions';
import { zIndex } from 'src/utils/zIndex';

interface Props {
  impersonationMember?: LoginMembership;
  stopImpersonating: typeof impersonationActions.stopImpersonating;
  isMobile: boolean;
  returnUrl?: string;
}

export const _ImpersonationBanner = ({ impersonationMember, stopImpersonating, returnUrl, isMobile }: Props) => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const queryOrgId = impersonationMember?.orgId ?? '';

  const { data: member, isFetching: isFetchingMember } = useGetOwnerQuery(
    { orgId: queryOrgId },
    { enabled: !!queryOrgId }
  );

  const handleReturnToAdmin = () => {
    stopImpersonating();

    if (returnUrl) {
      history.push(returnUrl);
    }
  };

  if (!impersonationMember || isFetchingMember) {
    return null;
  }

  return (
    <Box
      bg="yellow2"
      h="48px"
      py="sm"
      a="center"
      position="sticky"
      left="0"
      right="0"
      top="0"
      zIndex={zIndex.ImpersonationBanner}
    >
      <Container>
        <Box wrap="nowrap" a="center" gap="md">
          <Icon name="supervised user" />
          <Text color="darkAlways" ellipsis fullWidth size="sm">
            {isMobile ? (
              `'${impersonationMember.orgName}'`
            ) : (
              <FormattedMessage
                id="impersonation.heading.desktop"
                defaultMessage="You are currently impersonating ''{orgName}'' - ''{email}'' (Owner)"
                values={{
                  orgName: impersonationMember.orgName,
                  email: member?.email ?? formatMessage({ id: 'no_email', defaultMessage: 'No email' }),
                }}
              />
            )}
          </Text>

          <Button size="small" onClick={handleReturnToAdmin}>
            <FormattedMessage id="impersonation.return_to_admin" defaultMessage="Return to admin" />
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state: AppState) => ({
  impersonationMember: impersonationSelectors.getImpersonationMember(state),
  returnUrl: impersonationSelectors.getImpersonationReturnUrl(state),
  isMobile: viewportSelectors.isMobile(state),
});

const mapDispatchToProps = {
  stopImpersonating: impersonationActions.stopImpersonating,
};

export const ImpersonationBanner = connect(mapStateToProps, mapDispatchToProps)(memo(_ImpersonationBanner));
