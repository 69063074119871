import { LoginMembership } from 'src/orchd-client';
import { createActionCreator } from 'deox';

const namespace = '@impersonation';

const startImpersonating = createActionCreator(
  `startImpersonating(${namespace})`,
  (resolve) =>
    ({
      masterOrgName,
      orgId,
      returnUrl = window.location.pathname,
      onSuccess,
      onError,
      routerKey,
    }: {
      orgId: string;
      masterOrgName?: string;
      returnUrl?: string;
      onSuccess?: (impersonation: LoginMembership) => void;
      onError?: () => void;
      routerKey: string | undefined;
    }) =>
      resolve({ masterOrgName, orgId, returnUrl, onSuccess, onError, routerKey })
);

const startImpersonatingSuccess = createActionCreator(
  `startImpersonatingSuccess(${namespace})`,
  (resolve) =>
    (payload: { impersonation: LoginMembership; masterOrgName?: string; orgId: string; parentId?: string }) =>
      resolve(payload)
);

const startImpersonatingError = createActionCreator(
  `startImpersonatingError(${namespace}`,
  (resolve) => (payload: { orgId: string; error: string }) => resolve(payload)
);

const stopImpersonating = createActionCreator(`stopImpersonating(${namespace})`, (resolve) => () => resolve());
const stopImpersonatingSuccess = createActionCreator(`stopImpersonatingSuccess(${namespace})`);

export const impersonationActions = {
  startImpersonating,
  startImpersonatingError,
  startImpersonatingSuccess,
  stopImpersonating,
  stopImpersonatingSuccess,
};
