import { getType } from 'deox';
import { takeEvery, takeLatest } from 'redux-saga/effects';

import { brandingApi } from 'src/api_services/branding/service';
import { BrandingConfig } from 'src/api_services/branding/types';
import { createRequestStateSaga } from 'src/store/helpers';

import { brandingActions } from './actions';

// arbitrary key that the FE determines - used for BrandingSettings, this key could also be used to manage branding versioning in the future if necessary
const brandingSettingKey = 'branding';

const updateBranding = ({ branding, ...params }: { orgId: string; branding: BrandingConfig }) =>
  brandingApi.updateBrandingSetting({
    ...params,
    name: brandingSettingKey,
    body: branding as any, // @TODO: type of body in api spec should be arbitrary json object
  });

const resetBranding = ({ orgId }: { orgId: string }) =>
  brandingApi.deleteBrandingSetting({ orgId, name: brandingSettingKey });

const [getBrandingSaga, refreshGetBrandingSaga] = createRequestStateSaga(
  brandingActions.getBranding,
  brandingApi.getBranding
);

const [updateBrandingSaga] = createRequestStateSaga(brandingActions.updateBranding, updateBranding);

const [updateBrandingSettingSaga] = createRequestStateSaga(
  brandingActions.updateBrandingSetting,
  brandingApi.updateBrandingSetting
);

const [getImpersonationBrandingSaga, refreshGetImpersonationBrandingSaga] = createRequestStateSaga(
  brandingActions.getImpersonationBranding,
  brandingApi.getBranding
);

const [updateImpersonationBrandingSaga] = createRequestStateSaga(
  brandingActions.updateImpersonationBranding,
  updateBranding
);

const [resetBrandingSaga] = createRequestStateSaga(brandingActions.resetBranding, resetBranding);

const [setBrandLogoSaga] = createRequestStateSaga(brandingActions.setBrandLogo, brandingApi.setBrandLogo);

const [setBrandInverseLogoSaga] = createRequestStateSaga(
  brandingActions.setBrandInverseLogo,
  brandingApi.setBrandInverseLogo
);

const [setBrandFaviconSaga] = createRequestStateSaga(brandingActions.setBrandFavicon, brandingApi.setBrandFavicon);

const [setBrandInverseIconSaga] = createRequestStateSaga(
  brandingActions.setBrandInverseIcon,
  brandingApi.setBrandInverseIcon
);

const [setBrandLoginImageSaga] = createRequestStateSaga(
  brandingActions.setBrandLoginImage,
  brandingApi.setBrandLoginImage
);

const [deleteBrandLogoSaga] = createRequestStateSaga(brandingActions.deleteBrandLogo, brandingApi.deleteBrandLogo);

const [deleteInverseBrandLogo] = createRequestStateSaga(
  brandingActions.deleteInverseBrandLogo,
  brandingApi.deleteInverseBrandLogo
);

const [deleteBrandFaviconSaga] = createRequestStateSaga(
  brandingActions.deleteBrandFavicon,
  brandingApi.deleteBrandFavicon
);

const [deleteBrandInverseIconSaga] = createRequestStateSaga(
  brandingActions.deleteBrandInverseIcon,
  brandingApi.deleteBrandInverseIcon
);

const [deleteBrandLoginImageSaga] = createRequestStateSaga(
  brandingActions.deleteBrandLoginImage,
  brandingApi.deleteBrandLoginImage
);

const [createNameServerDomainSaga] = createRequestStateSaga(
  brandingActions.createNameServerDomain,
  brandingApi.createNameServerDomain
);

const [updateNameServerDomainSaga] = createRequestStateSaga(
  brandingActions.updateNameServerDomain,
  brandingApi.updateNameServerDomain
);

const [setStagingDomainSaga] = createRequestStateSaga(brandingActions.setStagingDomain, brandingApi.setStagingDomain);

const [getStagingDomainSaga] = createRequestStateSaga(brandingActions.getStagingDomain, brandingApi.getStagingDomain);

export function* brandingSaga() {
  yield takeEvery(getType(brandingActions.getBranding.request), getBrandingSaga);
  yield takeLatest(getType(brandingActions.updateBranding.request), updateBrandingSaga);
  yield takeEvery(getType(brandingActions.updateBrandingSetting.request), updateBrandingSettingSaga);
  yield takeLatest(
    [getType(brandingActions.updateBranding.success), getType(brandingActions.resetBranding.success)],
    refreshGetBrandingSaga
  );

  yield takeLatest(getType(brandingActions.getImpersonationBranding.request), getImpersonationBrandingSaga);
  yield takeLatest(getType(brandingActions.updateImpersonationBranding.request), updateImpersonationBrandingSaga);
  yield takeLatest(getType(brandingActions.updateImpersonationBranding.success), refreshGetImpersonationBrandingSaga);
  yield takeLatest(getType(brandingActions.resetBranding.request), resetBrandingSaga);

  yield takeEvery(getType(brandingActions.setBrandFavicon.request), setBrandFaviconSaga);
  yield takeEvery(getType(brandingActions.setBrandInverseIcon.request), setBrandInverseIconSaga);
  yield takeEvery(getType(brandingActions.setBrandLogo.request), setBrandLogoSaga);
  yield takeEvery(getType(brandingActions.setBrandInverseLogo.request), setBrandInverseLogoSaga);
  yield takeEvery(getType(brandingActions.setBrandLoginImage.request), setBrandLoginImageSaga);
  yield takeEvery(getType(brandingActions.deleteBrandFavicon.request), deleteBrandFaviconSaga);
  yield takeEvery(getType(brandingActions.deleteBrandInverseIcon.request), deleteBrandInverseIconSaga);
  yield takeEvery(getType(brandingActions.deleteInverseBrandLogo.request), deleteInverseBrandLogo);
  yield takeEvery(getType(brandingActions.deleteBrandLogo.request), deleteBrandLogoSaga);
  yield takeEvery(getType(brandingActions.deleteBrandLoginImage.request), deleteBrandLoginImageSaga);
  yield takeEvery(getType(brandingActions.createNameServerDomain.request), createNameServerDomainSaga);
  yield takeEvery(getType(brandingActions.updateNameServerDomain.request), updateNameServerDomainSaga);
  yield takeEvery(getType(brandingActions.setStagingDomain.request), setStagingDomainSaga);
  yield takeEvery(getType(brandingActions.getStagingDomain.request), getStagingDomainSaga);
}
