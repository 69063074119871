import { createReducer } from 'deox';

import { notificationsActions } from './actions';

export interface NotificationsState {
  read: string[];
}

export const initialState: NotificationsState = {
  read: [],
};

export const notificationsReducer = createReducer(initialState, (handleAction) => [
  handleAction(notificationsActions.addReadNotification, (state, { payload }) => ({
    ...state,
    read: [...state.read, payload],
  })),
]);
