import React, { ReactNode } from 'react';
import { FormField } from 'src/components/FormField';
import { InformationTooltip } from 'src/components/InformationTooltip/InformationTooltip';
import { Margins, Responsive } from 'src/design-system/style-types';

import { Props as SelectProps, Select } from './Select';

export interface Props extends SelectProps {
  label: ReactNode;
  err?: any;
  touched?: any;

  help?: ReactNode;
  infoText?: ReactNode;
  infoMaxWidth?: string;

  marginBottom?: Responsive<Margins>;
  requiredIndicator?: ReactNode;
}

export const SelectField = ({
  label,
  infoText,
  infoMaxWidth,
  err,
  help,
  marginBottom,
  requiredIndicator,
  ...selectProps
}: Props) => (
  <FormField
    label={label}
    help={help}
    name={selectProps.name}
    err={err}
    touched={selectProps.touched}
    requiredIndicator={requiredIndicator}
    marginBottom={marginBottom}
    role="listbox"
  >
    <Select {...selectProps} invalid={!!err} />

    {infoText && <InformationTooltip text={infoText} maxWidth={infoMaxWidth} />}
  </FormField>
);
