import { Website } from 'src/orchd-client';
import { buffers, END, EventChannel, eventChannel } from '@redux-saga/core';
import { ActionType, getType } from 'deox';
import { FormattedMessage } from 'react-intl';
import { call, put, race, select, take, takeEvery, takeLatest } from 'typed-redux-saga/macro';

import { filerdApi } from 'src/api_services/filerd/service';
import { autosaveToast } from 'src/store/autosave-toast/actions';
import { getFilerdUrl } from 'src/store/filerd/helpers';
import { modalActions } from 'src/store/modals';
import { websitesSelectors } from 'src/store/websites/selectors';
import { getErrorInfo } from 'src/utils/errors';
import { getBearerToken } from 'src/utils/getBearerToken';
import { getByteString, removeLeadingSlash } from 'src/utils/helpers';

import { OverwriteStatus, UploadMode } from '../constants';
import { getDirectoryFromFilePath, getEntryName } from '../helpers';
import { OverwritesModal } from '../OverwritesModal/OverwritesModal';
import { fileManagerActions } from './actions';
import { fileManagerSelectors } from './selectors';
import { DownloadedFile, DownloadStatus, MapEntriesRequest, UploadedFile, UploadStatus } from './types';

interface RequestOptions {
  baseURL: string;
  headers: Record<string, string>;
}

export function* authorize() {
  const website: Website = yield select(websitesSelectors.getWebsite);
  const accessToken = yield* select(websitesSelectors.getAccessToken);

  return {
    baseURL: getFilerdUrl(website),
    headers: { Authorization: getBearerToken(accessToken.data) },
  };
}

export function* getEntriesSaga({ payload }: ActionType<typeof fileManagerActions.getEntries.request>) {
  try {
    const options: RequestOptions = yield call(authorize);

    // Simplifies usage of website/dir entries based on the absence/presence of
    // the path param.
    const { data } = payload.params.path
      ? yield call(filerdApi.getDirectoryEntries, payload.params, options)
      : yield call(filerdApi.getWebsiteEntries, payload.params, options);

    yield put(fileManagerActions.getEntries.success({ data }));

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.getEntries.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* refreshEntriesSaga({ payload }: ActionType<typeof fileManagerActions.refreshEntries>) {
  const website: Website = yield select(websitesSelectors.getWebsite);

  // Make paths unique.
  const paths = payload.paths.reduce<string[]>(
    (carry, item) => (carry.includes(item) ? carry : carry.concat([item])),
    []
  );

  try {
    for (const path of paths) {
      yield put(
        fileManagerActions.getEntries.request({
          params: { websiteId: website.id, path, fetchMetadata: true, recursive: true, maxDepth: 1 },
        })
      );

      yield take(fileManagerActions.getEntries.success);
    }
  } catch (e) {
    const { code } = getErrorInfo(e);

    yield put(fileManagerActions.getEntries.error({ error: code || String(e) }));
  }
}

export function* checkEntrySaga({ payload }: ActionType<typeof fileManagerActions.checkEntry.request>) {
  try {
    const options: RequestOptions = yield call(authorize);

    const { data } = yield call(filerdApi.checkEntry, payload.params, options);

    yield put(fileManagerActions.checkEntry.success({ data }));

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.checkEntry.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* deleteEntriesSaga({ payload }: ActionType<typeof fileManagerActions.deleteEntries.request>) {
  try {
    const options: RequestOptions = yield call(authorize);

    const { data } = yield call(filerdApi.deleteWebsiteEntries, payload.params, options);

    yield put(fileManagerActions.deleteEntries.success({ data }));

    yield put(
      fileManagerActions.refreshEntries({ paths: payload.params.inlineObject1.entries.map(getDirectoryFromFilePath) })
    );

    // Reset any selected files.
    yield put(fileManagerActions.resetSelect());

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.deleteEntries.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* modifyEntriesSaga({ payload }: ActionType<typeof fileManagerActions.modifyEntries.request>) {
  try {
    const options: RequestOptions = yield call(authorize);

    const { data } = yield call(filerdApi.mapWebsiteEntries, payload.params, options);
    const paths = [payload.params.path];
    yield put(fileManagerActions.modifyEntries.success({ data }));
    yield put(fileManagerActions.refreshEntries({ paths }));

    // Reset any selected files.
    yield put(fileManagerActions.resetSelect());

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.modifyEntries.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* mapEntrySaga(params: MapEntriesRequest & { path: string; key: number }) {
  const options: RequestOptions = yield call(authorize);

  // Need to add each file name to the destination directory.
  const { names } = params;
  const name = names && names[params.key] ? names[params.key] : params.path.split('/').pop();

  let destination = removeLeadingSlash(`${params.destination}/${name}`);

  if (params.path === destination) {
    if (params.action === 'rename') {
      return 'rename_cancelled';
    }

    destination += ' - copy';
  }

  const overwrite: boolean = yield call(checkOverwrite, { websiteId: params.websiteId, path: destination });

  if (!overwrite) {
    return;
  }

  yield call(
    filerdApi.mapEntry,
    {
      websiteId: params.websiteId,
      path: params.path,
      path2: destination,
      action: params.action,
      overwrite: params.overwrite,
      format: params.format,
    },
    options
  );
}

export function* mapEntriesSaga({ payload }: ActionType<typeof fileManagerActions.mapEntries.request>) {
  try {
    let returnValue: string | undefined;

    for (const key in payload.params.paths) {
      if (payload.params.paths[key]) {
        returnValue = yield call(mapEntrySaga, {
          ...payload.params,
          path: payload.params.paths[key],
          key: Number(key),
        });
      }
    }

    yield put(fileManagerActions.mapEntries.success());

    // Determine folders to refresh.
    const paths: string[] = [];

    if (payload.params.destination !== undefined) {
      paths.push(payload.params.destination);
    }

    if (payload.params.paths) {
      paths.push(...payload.params.paths.map(getDirectoryFromFilePath));
    }

    yield put(fileManagerActions.refreshEntries({ paths }));

    // Reset various states.
    yield put(fileManagerActions.clearClipboard());
    yield put(fileManagerActions.resetSelect());
    yield put(fileManagerActions.resetRename());

    if (payload.params.paths.length === 1 && returnValue === 'rename_cancelled') {
      return;
    }

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.mapEntries.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* createEntrySaga(params: {
  websiteId: string;
  path: string;
  file?: File;
  folder?: string;
  overwrite?: boolean;
  uncompress?: boolean;
  mode?: UploadMode;
}) {
  const options: RequestOptions = yield call(authorize);
  const path = removeLeadingSlash(`${params.path}/${params.file ? params.file.name : params.folder}`);
  const overwrites: Record<string, OverwriteStatus> = yield select(fileManagerSelectors.overwrites);

  const createUploadChannel = () =>
    eventChannel<UploadedFile>((emit) => {
      const formData = new FormData();

      if (params.file) {
        formData.append('file', params.file);
      }

      filerdApi
        .createEntry(
          { websiteId: params.websiteId, overwrite: params.overwrite, uncompress: params.uncompress, path },
          {
            ...options,
            data: formData,
            onUploadProgress: (progress: ProgressEvent) => {
              if (!params.file) {
                return;
              }

              emit({
                file: params.file,
                status: UploadStatus.Uploading,
                current: progress.loaded,
                total: progress.total,
              });
            },
          }
        )
        .then(() => params.file && emit({ file: params.file, status: UploadStatus.Success }))
        .catch(() => params.file && emit({ file: params.file, status: UploadStatus.Error }))
        .then(() => emit(END));

      return () => emit(END);
    }, buffers.sliding(2));

  if (params.mode !== UploadMode.Silent) {
    if ([OverwriteStatus.Skip, OverwriteStatus.Staged].includes(overwrites[path])) {
      if (params.file) {
        yield put(
          fileManagerActions.updateFileProgress({
            websiteId: params.websiteId,
            file: params.file,
            status: UploadStatus.Error,
          })
        );
      }

      return UploadStatus.Error;
    }
  }

  const uploadChannel: EventChannel<UploadedFile> = yield call(createUploadChannel);

  while (true) {
    const item: UploadedFile = yield take(uploadChannel);

    if (params.mode === UploadMode.Full) {
      yield put(fileManagerActions.updateFileProgress({ ...item, websiteId: params.websiteId }));
    }

    if ([UploadStatus.Started, UploadStatus.Error, UploadStatus.Success].includes(item.status)) {
      return item.status;
    }
  }
}

export function* createEntriesSaga({ payload }: ActionType<typeof fileManagerActions.createEntries.request>) {
  try {
    const statuses = [];
    const items = (payload.params.files?.map((file) => file.name) ?? []).concat(payload.params.folders ?? []);

    // If there are files and the upload mode is full, show the uploader window.
    if (payload.params.files && payload.params.mode === UploadMode.Full) {
      yield put(
        fileManagerActions.setUploaderFiles({ websiteId: payload.params.websiteId, files: payload.params.files })
      );
    }

    if (payload.params.mode !== UploadMode.Silent) {
      for (const item of items) {
        const path = removeLeadingSlash(`${payload.params.path}/${item}`);
        const status: OverwriteStatus = yield call(checkOverwrite, { websiteId: payload.params.websiteId, path });

        yield put(fileManagerActions.setOverwrite({ path, status }));
      }
    }

    const overwrites: Record<string, OverwriteStatus> = yield select(fileManagerSelectors.overwrites);

    // If there are any overwrites, show modal and await confirmation or modal being hidden.
    if (Object.values(overwrites).some((status) => status === OverwriteStatus.Staged)) {
      yield put(modalActions.showModal({ Component: OverwritesModal, props: {} }));

      yield take(getType(modalActions.hideModal));
    }

    if (payload.params.files) {
      for (const file of payload.params.files) {
        const status: UploadStatus = yield call(createEntrySaga, { ...payload.params, file });

        statuses.push(status);
      }
    }

    if (payload.params.folders) {
      for (const folder of payload.params.folders) {
        const status: UploadStatus = yield call(createEntrySaga, { ...payload.params, folder });

        statuses.push(status);
      }
    }

    if (statuses.some((status) => status === UploadStatus.Error)) {
      throw new Error();
    }

    yield put(fileManagerActions.createEntries.success());
    yield put(fileManagerActions.refreshEntries({ paths: [payload.params.path] }));

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.createEntries.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* setMetadataSaga({ payload }: ActionType<typeof fileManagerActions.setMetadata.request>) {
  try {
    const options: RequestOptions = yield call(authorize);

    const { data } = yield call(filerdApi.setEntryMetadata, payload.params, options);

    yield put(fileManagerActions.setMetadata.success({ data }));
    yield put(fileManagerActions.refreshEntries({ paths: [getDirectoryFromFilePath(payload.params.path)] }));

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.setMetadata.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* checkOverwrite({ websiteId, path }: { websiteId: string; path: string }) {
  yield put(fileManagerActions.checkEntry.request({ params: { websiteId, path } }));

  const exists: { success: boolean; error: boolean } = yield race({
    success: take(fileManagerActions.checkEntry.success),
    error: take(fileManagerActions.checkEntry.error),
  });

  return exists.error ? OverwriteStatus.NoConflict : OverwriteStatus.Staged;
}

export function* getContentSaga({ payload }: ActionType<typeof fileManagerActions.getContent.request>) {
  try {
    const options: RequestOptions = yield call(authorize);

    const { data } = yield call(filerdApi.getFileContent, payload.params, {
      ...options,
      // Tell axios not to parse JSON in case we receive a JSON file.
      transformResponse: [],
    });

    yield put(fileManagerActions.getContent.success({ data }));
    yield put(fileManagerActions.refreshEntries({ paths: [getDirectoryFromFilePath(payload.params.path)] }));

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.getContent.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* downloadEntrySaga({ websiteId, path }: { websiteId: string; path: string }) {
  const options: RequestOptions = yield call(authorize);

  const { data: metadata } = yield* call(filerdApi.getEntryMetadata, { websiteId, path }, { ...options });

  const name = getEntryName(path);
  const size = metadata.size ?? 0;
  const id = `download-${path}`;

  const createDownloadChannel = () =>
    eventChannel<DownloadedFile>((emit) => {
      emit({ name, status: DownloadStatus.Pending });

      filerdApi
        .getFileContent(
          { websiteId, path },
          {
            ...options,
            responseType: 'arraybuffer',
            onDownloadProgress: (progress: ProgressEvent) => {
              emit({
                status: DownloadStatus.Downloading,
                current: progress.loaded,
                total: size,
                name,
              });
            },
          }
        )
        .then(({ data }) => emit({ name, status: DownloadStatus.Success, data }))
        .catch(() => emit({ name, status: DownloadStatus.Error }))
        .then(() => emit(END));

      return () => emit(END);
    }, buffers.sliding(2));

  const downloadChannel: EventChannel<UploadedFile> = yield call(createDownloadChannel);

  while (true) {
    const { name, status, current = 0, total = 0, data }: DownloadedFile = yield take(downloadChannel);

    if (status === DownloadStatus.Pending) {
      yield put(
        autosaveToast.show({
          id,
          text: (
            <FormattedMessage
              id="website.files.download_progress.pending"
              defaultMessage="Download of ''{name}'' pending"
              values={{ name }}
            />
          ),
        })
      );
    }

    if (status === DownloadStatus.Downloading) {
      yield put(
        autosaveToast.show({
          id,
          text: (
            <FormattedMessage
              id="website.files.download_progress.downloading"
              defaultMessage="Downloading ''{name}'' ({current} of {total}) · {percentage}%"
              values={{
                name,
                current: getByteString(current),
                total: getByteString(total),
                percentage: Math.round((current / total) * 100),
              }}
            />
          ),
        })
      );
    }

    if (status === DownloadStatus.Success) {
      if (!data) {
        return DownloadStatus.Error;
      }

      // Hacky-ish way to work around async authenticated downloads.
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();

      yield put(
        autosaveToast.success({
          id,
          text: (
            <FormattedMessage
              id="website.files.download_progress.success"
              defaultMessage="''{name}'' downloaded"
              values={{ name }}
            />
          ),
        })
      );
    }

    if (status === DownloadStatus.Error) {
      yield put(
        autosaveToast.error({
          id,
          text: (
            <FormattedMessage
              id="website.files.download_progress.error"
              defaultMessage="Failed to download ''{name}''"
              values={{ name }}
            />
          ),
        })
      );
    }

    if ([DownloadStatus.Error, DownloadStatus.Success].includes(status)) {
      return status;
    }
  }
}

export function* downloadEntriesSaga({ payload }: ActionType<typeof fileManagerActions.downloadEntries.request>) {
  try {
    const { websiteId } = payload.params;

    const statuses = [];

    if (payload.params.paths) {
      for (const path of payload.params.paths) {
        const status: DownloadStatus = yield call(downloadEntrySaga, { websiteId, path });

        statuses.push(status);
      }
    }

    if (statuses.some((status) => status === DownloadStatus.Error)) {
      throw new Error();
    }

    yield put(fileManagerActions.downloadEntries.success());

    payload.onSuccess && payload.onSuccess();
  } catch (e) {
    const { code } = getErrorInfo(e);
    yield put(fileManagerActions.downloadEntries.error({ error: code || String(e) }));

    payload.onError && payload.onError();
  }
}

export function* fileManagerSaga() {
  yield takeEvery(getType(fileManagerActions.getEntries.request), getEntriesSaga);
  yield takeEvery(getType(fileManagerActions.checkEntry.request), checkEntrySaga);
  yield takeLatest(getType(fileManagerActions.deleteEntries.request), deleteEntriesSaga);
  yield takeLatest(getType(fileManagerActions.modifyEntries.request), modifyEntriesSaga);
  yield takeLatest(getType(fileManagerActions.mapEntries.request), mapEntriesSaga);
  yield takeLatest(getType(fileManagerActions.createEntries.request), createEntriesSaga);
  yield takeLatest(getType(fileManagerActions.setMetadata.request), setMetadataSaga);
  yield takeLatest(getType(fileManagerActions.getContent.request), getContentSaga);
  yield takeLatest(getType(fileManagerActions.downloadEntries.request), downloadEntriesSaga);
  yield takeEvery(getType(fileManagerActions.refreshEntries), refreshEntriesSaga);
}
